/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

export default class Team {
  @observable
  id;
  @observable
  accountId;
  @observable
  isDefaultAccount;
  @observable
  name;
  @observable
  description;
  @observable
  imageFileName;
  @observable
  backgroundImageFileName;
  @observable
  privateTeam;
  @observable
  isCurrentUserTeamOwner;
  @observable
  isCurrentUserTeamMember;
  @observable
  rowOrder;
  @observable
  userCount;
  @observable
  commentCount;
  @observable
  importId;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    accountId,
    isDefaultAccount,
    name,
    description,
    imageFileName,
    backgroundImageFileName,
    privateTeam,
    isCurrentUserTeamOwner,
    isCurrentUserTeamMember,
    rowOrder,
    userCount,
    commentCount,
    importId,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    let teamImage = imageFileName || '';
    if (privateTeam && !isCurrentUserTeamMember) {
      teamImage = _.replace(imageFileName, '/t_profile_image', '/t_profile_image/t_locked_overlay');
    }
    this.id = id || '';
    this.accountId = accountId || '';
    typeof isDefaultAccount === 'undefined' ? (this.isDefaultAccount = false) : (this.isDefaultAccount = isDefaultAccount);
    this.name = name || '';
    this.description = description || '';
    this.imageFileName = teamImage || '';
    this.backgroundImageFileName = backgroundImageFileName || '';
    // FIXME this probably wasn't meant to be an expression
    // eslint-disable-next-line no-unused-expressions
    typeof privateTeam === 'undefined' ? (this.privateTeam = false) : (this.privateTeam = privateTeam);
    // eslint-disable-next-line no-unused-expressions
    typeof isCurrentUserTeamOwner === 'undefined'
      ? (this.isCurrentUserTeamOwner = false)
      : (this.isCurrentUserTeamOwner = isCurrentUserTeamOwner);
    // eslint-disable-next-line no-unused-expressions
    typeof isCurrentUserTeamMember === 'undefined'
      ? (this.isCurrentUserTeamMember = false)
      : (this.isCurrentUserTeamMember = isCurrentUserTeamMember);
    this.rowOrder = rowOrder || '';
    this.userCount = userCount || 0;
    this.commentCount = commentCount || 0;
    this.importId = importId || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get descriptionValid() {
    if (this.description.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get descriptionError() {
    if (this.description.length < 1) {
      return I18n.t('js.description_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
