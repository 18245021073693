import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, Intent, MenuItem, Icon } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import classNames from 'classnames';
import Avatar from 'react-avatar';
import Team from '../../models/TeamModel';
import _ from 'lodash';

const TeamSuggest = Suggest.ofType(Team);

@inject('TeamsStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class TeamsTeamSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { query: '' };
  }

  componentWillReceiveProps(nextProps) {
    this.setInitialQuery(nextProps);
  }

  componentDidMount() {
    this.setInitialQuery(this.props);
  }

  setInitialQuery(props) {
    if (props.TeamsStore.selectedTeamId) {
      this.setState({ query: props.TeamsStore.selectedTeam.name });
    }
  }

  renderTeam(item, details) {
    let menuClass = classNames('popover-larger');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>
            <Flexbox marginLeft="10px" marginTop="2px">
              {item.name}
            </Flexbox>
          </Flexbox>
        }
      />
    );
  }

  renderNoResults() {
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-people push-5-r" /> <span> {I18n.t('js.no_teams_found')} </span>
          </span>
        }
      />
    );
  }

  renderClearIcon() {
    if (this.state.query.length > 0) {
      return (
        <Icon icon="cross" className="bp3-cursor-pointer push-10-r" intent={Intent.DANGER} onClick={this.handleCancel.bind(this)}></Icon>
      );
    } else {
      return <Icon icon="filter"></Icon>;
    }
  }

  renderInputValue(team) {
    return team.name;
  }

  handleItemSelect(team) {
    this.props.handleTeamSelectChange(team);
    this.setState({ query: team.name });
  }

  handleCancel() {
    this.props.TeamsStore.selectedTeamId = '';
    this.setState({ query: '' });
  }

  handleQueryChange(query) {
    this.setState({ query });
  }

  filterTeams() {
    const store = this.props.TeamsStore;
    let possibleTeams = store.teams.slice();
    if (this.state.query.length > 0) {
      possibleTeams = _.filter(possibleTeams, team => {
        return team.name.toLowerCase().includes(this.state.query.toLowerCase());
      });
    }
    return possibleTeams;
  }

  render() {
    const store = this.props.TeamsStore;
    const { selectedTeamId } = store;
    return (
      <TeamSuggest
        className="max-width"
        items={store.teams.slice()}
        itemListPredicate={this.filterTeams.bind(this)}
        itemRenderer={this.renderTeam.bind(this)}
        onItemSelect={this.handleItemSelect.bind(this)}
        validateInput={() => null}
        intent={null}
        selectedItem={selectedTeamId}
        inputValueRenderer={this.renderInputValue.bind(this)}
        noResults={this.renderNoResults()}
        query={this.state.query}
        onQueryChange={this.handleQueryChange.bind(this)}
        inputProps={{
          placeholder: I18n.t('js.search_teams_simple'),
          leftIcon: this.renderClearIcon(),
        }}
        popoverProps={{
          usePortal: false,
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
        }}
      />
    );
  }
}
