/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Button, Drawer, Position, Classes } from '@blueprintjs/core';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementHyperlink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
    };
  }

  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  renderHyperlink(hyperlink, title, displayAs) {
    if (displayAs === 'text') {
      return (
        <Flexbox justifyContent="center">
          <span className="bp3-icon bp3-icon-globe push-10-r push-5-t bp3-text-muted" />
          <p>
            <a href={`${hyperlink}`} rel="noopener noreferrer" target="_blank">
              {title}
            </a>
          </p>
        </Flexbox>
      );
    }
    if (displayAs === 'iframe') {
      return (
        <Flexbox>
          <Button icon="add-column-right" text={title} onClick={this.toggleDrawer.bind(this)} />
        </Flexbox>
      );
    }
    return (
      <AnchorButton
        onClick={() => {
          window.location = hyperlink;
        }}
      >
        {title}
      </AnchorButton>
    );
  }

  renderIframe() {
    const iframe = `<iframe src="${this.props.element.elementHyperlink.hyperlink}" width="100%" height="${window.innerHeight}px"></iframe>`;

    return {
      __html: iframe,
    };
  }

  renderDrawer() {
    return (
      <Drawer
        portalContainer={document.body}
        position={Position.RIGHT}
        size={Drawer.SIZE_LARGE}
        onClose={this.toggleDrawer.bind(this)}
        isOpen={this.state.drawerOpen}
        title={this.props.element.elementHyperlink.title}
      >
        <Flexbox className={Classes.DRAWER_BODY} flexDirection="column">
          <div dangerouslySetInnerHTML={this.renderIframe()} />
        </Flexbox>
      </Drawer>
    );
  }

  renderDisplayedElement() {
    return (
      <Flexbox paddingTop="10px" paddingBottom="10px">
        {this.renderHyperlink(
          this.props.element.elementHyperlink.hyperlink,
          this.props.element.elementHyperlink.title,
          this.props.element.elementHyperlink.displayAs
        )}
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
          {this.renderDrawer()}
        </Flexbox>
      </Flexbox>
    );
  }
}
