import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  EditableText,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';
import ElementGridColumn from '../../../models/elements/ElementGridColumnModel';
import {
  arrayMove,
  sortableContainer,
  sortableElement,
} from 'react-sortable-hoc';

const SortableItem = sortableElement(
  ({
    column,
    handleConfirmColumnName,
    handleConfirmNumberValue,
    handleDeleteColumn,
  }) => (
    <tr key={column.id}>
      <td className="td-width-40">
        <EditableText
          multiline={true}
          confirmOnEnterKey={true}
          defaultValue={column.name}
          intent={Intent.DEFAULT}
          onConfirm={value => handleConfirmColumnName(value, column)}
          placeholder={I18n.t('js.click_to_edit')}
        />
      </td>
      <td className="td-width-40">
        <EditableText
          confirmOnEnterKey={true}
          defaultValue={column.numberValue}
          intent={Intent.DEFAULT}
          onConfirm={value => handleConfirmNumberValue(value, column)}
          placeholder={I18n.t('js.click_to_edit')}
        />
      </td>
      <td className="td-width-20">
        <AnchorButton
          className="bp3-small push-5-l"
          icon="trash"
          text={I18n.t('js.remove_column')}
          onClick={() => handleDeleteColumn(column)}
        />
      </td>
    </tr>
  )
);

const SortableArea = sortableContainer(
  ({
    columns,
    handleConfirmColumnName,
    handleConfirmNumberValue,
    handleDeleteColumn,
  }) => (
    <tbody>
      {columns.map(column => (
        <SortableItem
          key={column.id}
          handleConfirmColumnName={handleConfirmColumnName}
          handleConfirmNumberValue={handleConfirmNumberValue}
          handleDeleteColumn={handleDeleteColumn}
          index={columns.indexOf(column)}
          column={column}
        />
      ))}
    </tbody>
  )
);

@inject(
  'AppStore',
  'UserProfileTemplateStore',
  'TemplateStore',
  'TemplateActions',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class ElementGridColumnOptions extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { newColumnName: '' };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({ newColumnName: e.target.value });
  }

  handleConfirmColumnName(value, column) {
    const newColumn = _.find(
      this.store.activeElement.elementGrid.elementGridColumns,
      o => o.id === column.id
    );
    newColumn.name = value;
    newColumn.changed = true;
  }

  handleConfirmNumberValue(value, column) {
    const newColumn = _.find(
      this.store.activeElement.elementGrid.elementGridColumns,
      o => o.id === column.id
    );
    newColumn.numberValue = value;
    newColumn.changed = true;
  }

  handleAddNewColumn() {
    const newColumn = new ElementGridColumn(
      '',
      this.store.activeElement.elementGrid.id,
      this.state.newColumnName
    );
    newColumn.added = true;
    const newElement = _.clone(this.store.activeElement);
    newElement.elementGrid.elementGridColumns.push(newColumn);
    this.store.activeElement = newElement;
    this.setState({ newColumnName: '' });
  }

  handleDeleteColumn(column) {
    const newActiveElement = _.clone(this.store.activeElement);
    newActiveElement.elementGrid.elementGridColumns = _.filter(
      newActiveElement.elementGrid.elementGridColumns,
      o => o.id !== column.id
    );
    this.store.activeElement = newActiveElement;
  }

  handleSortEnd(column, newIndex) {
    const newColumn = _.find(
      this.store.activeElement.elementGrid.elementGridColumns,
      o => o.id === column.id
    );
    newColumn.rowOrder = newIndex;
    newColumn.changed = true;
    newColumn.rowOrderChanged = true;
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the section
    const findColumn = this.store.activeElement.elementGrid
      .elementGridColumns[oldIndex];

    // Reorder the current UI
    const newColumns = arrayMove(
      this.store.activeElement.elementGrid.elementGridColumns.slice(),
      oldIndex,
      newIndex
    );
    const newElement = _.clone(this.store.activeElement);
    newElement.elementGrid.elementGridColumns = newColumns;
    this.store.activeElement = newElement;

    // Process reorder on server
    this.handleSortEnd(findColumn, newIndex);
  }

  renderNewColumn() {
    return (
      <Flexbox flexDirection="column" marginTop="10px">
        <Flexbox className="bp3-control-group">
          <InputGroup
            autoComplete="off"
            type="text"
            intent={Intent.DEFAULT}
            placeholder={I18n.t('js.add_a_new_column_here')}
            name="name"
            value={this.state.newColumnName}
            onChange={this.handleInputChange.bind(this)}
            dir="auto"
            className="bp3-fill"
          />
          <AnchorButton
            onClick={this.handleAddNewColumn.bind(this)}
            text={I18n.t('js.add_new_column')}
            className="bp3-intent-primary"
            disabled={this.state.newColumnName.length === 0}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox
        className="bp3-form-group"
        flexDirection="column"
        flexGrow={1}
        flexBasis="1"
      >
        <label className="bp3-label" htmlFor="columnType">
          {I18n.t('js.add_remove_or_edit_columns')}
          <span className="bp3-text-muted push-5-l">
            ({I18n.t('js.required')})
          </span>
        </label>
        <div className="bp3-form-content">
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-html-table-striped bp3-interactive bp3-small full-table">
              <thead>
                <tr>
                  <th>{I18n.t('js.column_name')}</th>
                  <th>{I18n.t('js.number_value_optional')}</th>
                  <th>{I18n.t('js.action')}</th>
                </tr>
              </thead>
              <SortableArea
                columns={
                  this.store.activeElement.elementGrid
                    .elementGridColumns
                }
                onSortEnd={this.onSortEnd.bind(this)}
                lockToContainerEdges={true}
                lockAxis="y"
                handleConfirmColumnName={(value, column) =>
                  this.handleConfirmColumnName(value, column)
                }
                handleConfirmNumberValue={(value, column) =>
                  this.handleConfirmNumberValue(value, column)
                }
                handleDeleteColumn={column =>
                  this.handleDeleteColumn(column)
                }
                useDragHandle={false}
                distance={5}
              />
            </table>
            {this.renderNewColumn()}
          </Flexbox>
        </div>
      </Flexbox>
    );
  }
}
