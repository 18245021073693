import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class AccountBillingChangePlan extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleDialog() {
    this.props.toggleDialog();
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleCancel() {
    // reset form inputs
    this.toggleDialog();
  }

  handleSubmit(e) {
    this.toggleLoading();
    e.preventDefault();

    const subscriptionObj = {
      id: this.props.AccountBillingStore.activeSubscription.id,
      plan_id: this.props.AccountBillingStore.selectedPlan.id,
    };
    this.props.AccountBillingActions.changeSubscription(
      subscriptionObj
    )
      .then(response => {
        this.props.AccountBillingStore.addSubscription(response.data);
        this.toggleLoading();
        this.toggleDialog();
        this.props.AccountBillingStore.setActiveTab('subscription');
        this.props.ToastStore.showToast(
          I18n.t('js.you_are_now_subscribed_to_the_plan', {
            nickname: this.props.AccountBillingStore.selectedPlan.nickname.toLowerCase(),
          }),
          'success'
        );
        this.props.AccountBillingStore.resetNewSubscription();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="credit-card"
        isOpen={this.props.dialogOpen}
        canOutsideClickClose={false}
        onClose={this.toggleDialog.bind(this)}
        title={I18n.t('js.subscribe_to_plan', {
          plan: this.props.AccountBillingStore.selectedPlan.nickname.toLowerCase(),
        })}
      >
        <Flexbox flexDirection="column" flexGrow={1}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox
              className="bp3-dialog-body"
              flexDirection="column"
              flexGrow={1}
            >
              {I18n.t('js.change_subscription_plan_text')}
            </Flexbox>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <Button
                  text={I18n.t('js.cancel')}
                  onClick={() => this.handleCancel()}
                />
                <Button
                  intent={Intent.SUCCESS}
                  type="submit"
                  loading={this.state.isLoading}
                  text={I18n.t('js.subscribe_to_plan', {
                    plan: this.props.AccountBillingStore.selectedPlan.nickname.toLowerCase(),
                  })}
                />
              </div>
            </div>
          </form>
        </Flexbox>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        {this.renderDialog()}
      </Flexbox>
    );
  }
}
