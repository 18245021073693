import React from 'react';
import { observable } from 'mobx';
import axios from 'axios';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import User from '../../models/UserModel';
import Cookies from 'js-cookie';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';

@inject('CurrentUserStore', 'ToastStore')
@observer
export default class StoreTemplateAuthor extends React.Component {
  @observable
  user = '';

  componentDidMount() {
    if (this.props.storeTemplate.user.id === '') {
      if (this.props.CurrentUserStore.userSignedIn) {
        this.searchUser(this.props.storeTemplate.userId);
      }
    } else {
      this.user = this.props.storeTemplate.user;
    }
  }

  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
    if (newUser.id === this.props.storeTemplate.userId) {
      this.user = newUser;
    }
  }

  searchUser(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${userId}`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            this.addUser(user);
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  render() {
    if (typeof this.user === 'object') {
      return (
        <Flexbox flexDirection="row" flexGrow={0} marginTop="20px">
          <Flexbox marginRight="10px">
            <a href={`/users/${this.user.id}`}>
              <Avatar src={this.user.imageFileName} size={40} round={true} />
            </a>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Flexbox className="bp3-text-muted">
              {I18n.t('js.created_by')}
              <a href={`/users/${this.user.id}`} className="push-5-l">
                <strong>{this.user.fullName}</strong>
              </a>
            </Flexbox>
            <Flexbox alignItems="center">
              <small className="bp3-icon bp3-icon-calendar bp3-text-muted push-5-r" />
              <small className="bp3-text-muted">{moment.utc(this.props.storeTemplate.createdAt).format('MMMM Do YYYY, h:mm a')}</small>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }
}
