import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class PlanWidget extends React.Component {
  renderPlanActions() {
    if (this.props.AccountBillingStore.subscribedPlan.id === this.props.plan.id) {
      return (
        <Flexbox justifyContent="center" alignItems="center" flexDirection="column" height="80px">
          <Flexbox>
            <Button
              text={I18n.t('js.manage_subscription')}
              icon="edit"
              className="bp3-intent-primary bp3-large"
              onClick={() => this.props.handleSelectPlan(this.props.plan)}
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox justifyContent="center" alignItems="center" flexDirection="column" height="80px">
        <Flexbox>
          <Button
            text={I18n.t('js.subscribe_to_this_plan')}
            icon="endorsed"
            className="bp3-intent-success bp3-large"
            onClick={() => this.props.handleSelectPlan(this.props.plan)}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  renderSubscribedIcon() {
    if (this.props.AccountBillingStore.subscribedPlan.id === this.props.plan.id) {
      return (
        <Flexbox className="subscribed-icon">
          <Icon icon="endorsed" className="bp3-intent-primary" iconSize={50} />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    if (!this.props.AccountBillingStore.isLoading) {
      let cardClass = classNames('bp3-card bp3-card-no-padding plan-widget bp3-cursor-pointer');
      if (this.props.AccountBillingStore.subscribedPlan.id === this.props.plan.id) {
        cardClass = classNames('bp3-card bp3-card-no-padding plan-widget bp3-cursor-pointer');
      }
      return (
        <Flexbox className={cardClass} flexDirection="column" flexGrow={1} onClick={() => this.props.handleSelectPlan(this.props.plan)}>
          <Flexbox flexGrow={1} flexDirection="column" padding="20px" className="position-relative">
            <Flexbox flexGrow={0} justifyContent="center" alignItems="center" marginBottom="10px">
              <h3 className={`${Classes.HEADING} item-widget-header`}>{this.props.plan.nickname}</h3>
            </Flexbox>
            <Flexbox flexGrow={0} flexDirection="column" justifyContent="center" alignItems="center">
              <Flexbox>
                <h1
                  className={
                    this.props.AccountBillingStore.subscribedPlan.id === this.props.plan.id
                      ? `bp3-price bp3-primary ${Classes.HEADING}`
                      : `bp3-price bp3-success ${Classes.HEADING}`
                  }
                >
                  $4
                </h1>
              </Flexbox>
              <Flexbox>
                <p className="bp3-text-muted">User/Month.</p>
              </Flexbox>
              <Flexbox>
                <p className="bp3-text-muted">Minimum charge $199.99 per month.</p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px" flexGrow={1}>
              {_.map(this.props.plan.features, (value, key) => (
                <p key={key}>
                  <span
                    className={
                      this.props.AccountBillingStore.subscribedPlan.id === this.props.plan.id
                        ? 'bp3-icon bp3-icon-tick push-5-r bp3-intent-primary'
                        : 'bp3-icon bp3-icon-tick push-5-r bp3-intent-success'
                    }
                  />
                  <span>
                    <strong>{key}: </strong>
                    <span>{value}</span>
                  </span>
                </p>
              ))}
            </Flexbox>
          </Flexbox>
          {this.renderPlanActions()}
        </Flexbox>
      );
    }
    return undefined;
  }
}
