import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import StoreTemplateWidget from '../store_templates/StoreTemplateWidget';
import { Classes, NonIdealState, Tab, Tabs } from '@blueprintjs/core';

@inject('StoreCategoryStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreCategoryStoreTemplates extends React.Component {
  componentDidMount() {
    this.props.StoreCategoryStore.tabSelection = 'App';
  }

  handleFilterChange(e) {
    e.preventDefault();
    this.props.StoreCategoryStore.filterQuery = e.target.value;
  }

  renderStoreTemplates() {
    if (this.props.StoreCategoryStore.viewedStoreTemplates.length === 0) {
      return (
        <Flexbox flexGrow={1} marginTop="20px" paddingTop="20px" justifyContent="center" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_templates_to_show')}
            icon="search-text"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox marginTop="20px" flexDirection="row" flexGrow={1} flexWrap="wrap">
        {this.props.StoreCategoryStore.viewedStoreTemplates.map(storeTemplate => (
          <StoreTemplateWidget
            storeTemplate={storeTemplate}
            key={storeTemplate.id}
            storeCategoryView={true}
            storeCategory={this.props.StoreCategoryStore.activeStoreCategory}
          />
        ))}
      </Flexbox>
    );
  }

  renderTabs() {
    return (
      <Tabs id="storeTemplateTypeTabs" onChange={newTabId => (this.props.StoreCategoryStore.tabSelection = newTabId)} selectTabId="App">
        <Tab id="App" title={I18n.t('js.apps')} />
        <Tab id="Workspace" title={I18n.t('js.workspaces')} />
        <Tab id="ProfileTemplate" title={I18n.t('js.profile_templates')} />
      </Tabs>
    );
  }

  render() {
    if (this.props.StoreCategoryStore.activeStoreCategory.fields.storeTemplates.length > 0) {
      return (
        <Flexbox flexGrow={1} marginTop="20px" justifyContent="center" flexDirection="column">
          <h3 className={Classes.HEADING}>{I18n.t('js.templates')}</h3>
          <Flexbox
            flexGrow={1}
            style={{
              borderTop: '1px solid #eee',
              marginBottom: '1rem',
            }}
            paddingTop="10px"
          >
            <p>{I18n.t('js.templates_subtext')}</p>
          </Flexbox>
          {this.renderTabs()}
          <Flexbox flexDirection="row" flexGrow={1} style={{ marginTop: '1rem' }}>
            <div className="bp3-input-group max-width">
              <span className="bp3-icon bp3-icon-search" />
              <input
                className="bp3-input"
                type="search"
                placeholder={I18n.t('js.filter_templates')}
                dir="auto"
                value={this.props.StoreCategoryStore.filterQuery}
                onChange={this.handleFilterChange.bind(this)}
              />
            </div>
          </Flexbox>
          {this.renderStoreTemplates()}
        </Flexbox>
      );
    }
    return null;
  }
}
