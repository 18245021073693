/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Spinner, Tab, Tabs, Intent, Tag } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

@inject('DashboardStore', 'DynamicViewStore', 'WidgetStore', 'ToastStore')
@observer
export default class DashboardTabs extends React.Component {
  renderInboxTitle() {
    return (
      <Flexbox flexDirection="row" alignItems="center">
        <Flexbox marginRight="10px">{`${_.startCase(_.toLower(I18n.t('js.home')))}`}</Flexbox>
        {this.props.DashboardStore.items.length > 0 && !this.props.DashboardStore.showDone ? (
          <Flexbox className="bp3-inbox-unread">
            <Flexbox flexDirection="column">
              <Tag intent={Intent.DANGER} className={'animated zoomIn'} round={true}>
                {this.props.DashboardStore.items.length}
              </Tag>
            </Flexbox>
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }

  renderNewsTitle() {
    const { widgets } = this.props.WidgetStore;
    return (
      <Flexbox flexDirection="row" alignItems="center">
        <Flexbox marginRight="10px">{_.startCase(_.toLower(I18n.t('js.company_news')))}</Flexbox>
        {widgets.length > 0 ? (
          <Flexbox className="bp3-inbox-unread">
            <Flexbox flexDirection="column">
              <Tag minimal round={true}>
                {widgets.length}
              </Tag>
            </Flexbox>
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }

  renderRightLinks() {
    if (this.props.loading) {
      return <Spinner size={20} />;
    }
    return (
      <Flexbox>
        <Tabs
          id="dashboardTabs"
          selectedTabId={this.props.DashboardStore.activeTab}
          vertical={false}
          onChange={tab => this.props.DashboardStore.setActiveTab(tab)}
        >
          <Tab id="inbox" title={this.renderInboxTitle()} />
          <Tab id="you" title={_.startCase(_.toLower(I18n.t('js.you')))} />
          <Tab id="your_colleagues" title={_.startCase(_.toLower(I18n.t('js.your_colleagues')))} />
          <Tab id="your_teams" title={_.startCase(_.toLower(I18n.t('js.teams')))} />
          <Tab id="your_timeline" title={_.startCase(_.toLower(I18n.t('js.timeline')))} />
          <Tab id="company_news" title={this.renderNewsTitle()} />
          <Tab id="activity" title={_.startCase(_.toLower(I18n.t('js.activity')))} />
          <Tabs.Expander />
        </Tabs>
      </Flexbox>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
