/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Cookies from 'js-cookie';
import _ from 'lodash';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Button, Callout, Classes, Dialog, Icon, Intent, Spinner, NonIdealState } from '@blueprintjs/core';
import scrollToElement from 'scroll-to-element';
import ItemActions from '../actions/ItemActions';
import TemplateActions from '../actions/TemplateActions';
import ItemStore from '../stores/ItemStore';
import UserProfileStore from '../stores/UserProfileStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import AppStore from '../stores/AppStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import CommentStore from '../stores/CommentStore';
import ToastStore from '../stores/ToastStore';
import ItemSections from '../components/items/ItemSections';
import Utilities from '../utils/Utilities';
import classNames from 'classnames';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import moment from 'moment';

@observer
export default class WebformView extends React.Component {
  @observable isLoading = true;
  @observable isSubmitting = false;
  @observable isSaving = false;
  @observable showDialog = false;
  @observable showErrors = false;

  constructor(props) {
    super(props);

    this.state = { isBlurred: [] };
  }

  componentDidMount() {
    ItemStore.resetStartingAttributes();
    ItemStore.isWebformView = true;
    Cookies.set('webform_partial_item_id', this.props.itemId);
    if (this.props.itemSectionInvite) {
      ItemActions.fetchWebformItemWithSectionInvite(this.props.itemId, this.props.itemSectionInvite.id)
        .then(response => {
          ItemStore.addItem(response.data);
          this.toggleLoading();
          // ItemStore.startCountDown();
        })
        .catch(() => {
          this.toggleLoading();
        });
    } else {
      ItemActions.fetchWebformItem(this.props.itemId)
        .then(response => {
          ItemStore.addItem(response.data);
          this.toggleLoading();
          // ItemStore.startCountDown();
        })
        .catch(() => {
          this.toggleLoading();
        });
    }
  }

  componentWillUnmount() {
    ItemStore.pauseCountDown();
    ItemStore.resetCountDown();
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  toggleSubmitting() {
    this.isSubmitting = !this.isSubmitting;
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }

  toggleShowErrors() {
    this.showErrors = !this.showErrors;
  }

  hideErrors() {
    ItemStore.submissionAttempted = false;
    this.showErrors = false;
  }

  handleFullNameChange(e) {
    ItemStore.webformFullName = e.target.value;
  }

  handleEmailChange(e) {
    ItemStore.webformEmail = e.target.value;
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  handleScrollToElement(elementId) {
    if (elementId === 'item-participant-chart') {
      ItemStore.isShowingParticipants = true;
    }
    scrollToElement(`.element_${elementId}`, {
      offset: 0,
      ease: 'out-circ',
      duration: 500,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (ItemStore.itemHasError) {
      ItemStore.submissionAttempted = true;
      this.toggleShowErrors();
    } else {
      if (this.props.anonymous) {
        this.handleItemSubmission();
      } else {
        this.toggleDialog();
      }
    }
  }

  handleItemSubmission() {
    this.toggleSubmitting();
    ItemActions.handleSave(false)
      .then(() => {
        this.submitItem();
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  submitItem() {
    const itemObj = {
      id: ItemStore.item.id,
      full_name: ItemStore.webformFullName,
      email: ItemStore.webformEmail,
    };
    if (CurrentUserStore.userSignedIn && !this.props.anonymous) {
      itemObj.full_name = CurrentUserStore.currentUser.fullName;
      itemObj.email = CurrentUserStore.currentUser.email;
    }
    if (this.props.itemSectionInvite) {
      ItemActions.submitItemSectionInvite(this.props.itemSectionInvite.id)
        .then(() => {
          Utilities.navigate(`/item-invitation-submitted`);
        })
        .catch(() => {
          this.toggleSubmitting();
        });
    } else {
      ItemActions.submitWebformItem(itemObj)
        .then(() => {
          Utilities.navigate(`/wf/${this.props.webformToken}/${this.props.itemId}/submitted`);
        })
        .catch(() => {
          this.toggleSubmitting();
        });
    }
  }

  renderEmailErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !ItemStore.webformEmailValid && this.props.emailRequired) {
      return <div className="bp3-form-helper-text">{I18n.t('js.email_is_required')}</div>;
    }
    return undefined;
  }

  renderItemParticipantInputs() {
    if (!CurrentUserStore.userSignedIn) {
      return (
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column">
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="name">
                {I18n.t('js.your_name')}
                <div className="bp3-form-content">
                  <div className="bp3-input-group">
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.john_doe')}
                      name="name"
                      id="name"
                      value={ItemStore.webformFullName}
                      onChange={this.handleFullNameChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                </div>
              </label>
            </div>
          </Flexbox>
          <Flexbox flexDirection="column">
            <div
              className={
                _.includes(this.state.isBlurred, 'email') && !ItemStore.webformEmailValid && this.props.emailRequired
                  ? classNames('bp3-form-group bp3-intent-danger')
                  : classNames('bp3-form-group')
              }
            >
              <label className="bp3-label" htmlFor="email">
                {I18n.t('js.your_email')}
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'email') && !ItemStore.webformEmailValid && this.props.emailRequired
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.someone_example_com')}
                      name="email"
                      value={ItemStore.webformEmail}
                      onChange={this.handleEmailChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderEmailErrors('email')}
                </div>
              </label>
            </div>
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        {I18n.t('js.are_you_ready_to_submit_this_item', {
          item: this.props.itemName,
        })}
      </Flexbox>
    );
  }

  renderSubmissionDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        enforceFocus={false}
        title={I18n.t('js.submit_item', {
          name: this.props.itemName,
        })}
      >
        <div className="bp3-dialog-body">{this.renderItemParticipantInputs()}</div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
            <Button
              intent={Intent.PRIMARY}
              onClick={this.handleItemSubmission.bind(this)}
              text={I18n.t('js.submit_item', {
                name: AppStore.activeApp.item,
              })}
              loading={this.isSubmitting}
              disabled={this.props.emailRequired && !ItemStore.webformEmailValid && !CurrentUserStore.userSignedIn}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  renderSubmission() {
    if (ItemStore.userIsOwner) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" marginTop="20px">
          <Button
            text={I18n.t('js.submit')}
            intent={Intent.PRIMARY}
            className="bp3-large push-10-r"
            onClick={this.handleSubmit.bind(this)}
            loading={this.isSubmitting}
            disabled={ItemStore.itemHasError && ItemStore.submissionAttempted}
          />
          <Button
            text={!ItemStore.hasChanges ? I18n.t('js.your_changes_have_been_saved') : I18n.t('js.save_changes')}
            icon="floppy-disk"
            onClick={() => ItemActions.handleSave(true)}
            disabled={!ItemStore.hasChanges}
            loading={ItemStore.isSaving}
          />
        </Flexbox>
      );
    }
    return null;
  }

  renderErrors() {
    if (this.showErrors && ItemStore.itemHasError) {
      return (
        <Flexbox flexGrow={1} marginBottom="40px">
          <Callout intent={Intent.DANGER} icon="error" className="max-width">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>
              <Flexbox flexGrow={1}>
                <Flexbox flexGrow={1}>
                  {I18n.t('js.please_correct_the_following_errors_before_submitting_this_item', { name: AppStore.activeApp.item })}
                </Flexbox>
                <Flexbox flexGrow={0} justifyContent="flex-end">
                  <Button intent={Intent.DANGER} className="bp3-small bp3-minimal" onClick={this.hideErrors.bind(this)}>
                    <span className="bp3-icon bp3-icon-cross error-clear-button" />
                  </Button>
                </Flexbox>
              </Flexbox>
            </h4>
            <ul className={`${Classes.LIST} bp3-list bp3-intent-danger`}>
              {_.map(ItemStore.itemErrors.reverse(), (error, index) => (
                <li key={index}>
                  {/*
                      lazy fix for legacy code
                      FIXME make accessible
                    */}
                  {/* eslint-disable-next-line */}
                  <a onClick={() => this.handleScrollToElement(error.elementId)}>{error.errorMessageFull}</a>
                </li>
              ))}
            </ul>
          </Callout>
        </Flexbox>
      );
    }
    return null;
  }

  renderUnsavedChangesBar() {
    if (ItemStore.hasChanges) {
      return (
        <Flexbox width="100hw" className="sticky-footer sticky-footer-primary bp3-elevation-3 animated zoomInDown">
          <Flexbox className="fixed-width-container" flexGrow={1} justifyContent="space-between">
            <Flexbox flexGrow={1} alignContent="center" alignItems="center">
              <Icon icon="issue-new" className="text-white push-10-r" iconSize={25} />
              <Flexbox flexDirection="column" justifyContent="center" paddingTop="10px">
                <p>{I18n.t('js.you_have_unsaved_changes')}</p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" justifyContent="center">
              <Button
                text={I18n.t('js.save_changes_now')}
                icon="tick"
                className="bp3-large bp3-intent-primary push-60-r"
                onClick={() => ItemActions.handleSave(true)}
                disabled={!ItemStore.hasChanges}
                loading={ItemStore.isSaving}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }

  renderItemSectionInviteDisplay() {
    if (this.props.itemSectionInvite) {
      return (
        <>
          <h2 className="bp3-heading push-20-b">{this.props.itemTitle}</h2>
          <Flexbox className="bp3-card" flexDirection="column" marginBottom="20px">
            <p className="bp3-running-text">
              <strong>Invitation from {this.props.invitationFrom}</strong>
            </p>
            {this.props.itemSectionInvite.due_date && (
              <p>
                {I18n.t('js.due_date')}: {moment(this.props.itemSectionInvite.due_date).format('MMMM Do YYYY')}
              </p>
            )}
            {this.props.itemSectionInvite.message && (
              <p>
                <i>{this.props.itemSectionInvite.message}</i>
              </p>
            )}
          </Flexbox>
        </>
      );
    }
    return null;
  }

  renderContent() {
    if (this.isLoading) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} justifyContent="center" alignItems="center">
          <NonIdealState
            title={I18n.t('js.one_sec')}
            description={I18n.t('js.loading_form_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column" paddingTop="50px">
        {this.renderItemSectionInviteDisplay()}
        <form onSubmit={this.handleSubmit.bind(this)}>
          <ItemSections />
          {this.renderErrors()}
          {this.renderSubmission()}
          {this.renderSubmissionDialog()}
        </form>
      </Flexbox>
    );
  }

  render() {
    const stores = {
      ItemStore,
      UserProfileStore,
      UserProfileTemplateStore,
      ItemActions,
      TemplateActions,
      AppStore,
      CurrentUserStore,
      CommentStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox
            flexGrow={1}
            width="100hw"
            minHeight="95vh"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Flexbox flexDirection="column" className="webform-item-container" marginTop="10px" marginBottom="50px">
              {this.renderContent()}
            </Flexbox>
            {this.renderUnsavedChangesBar()}
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
