import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import Flexbox from 'flexbox-react';
import { Button, Classes, HTMLSelect, NonIdealState, Spinner } from '@blueprintjs/core';
import ReportList from './ReportList';
import Utilities from '../../utils/Utilities';

@inject('ReportStore', 'ReportActions', 'ReportTemplateActions', 'ToastStore', 'CurrentUserStore')
@observer
export default class ReportSelector extends Component {
  @observable
  isLoadingReports = false;
  @observable
  isRunningReport = false;

  componentDidMount() {
    if (this.props.runReport && this.props.ReportStore.activeReportTemplate !== '') {
      this.handleRunReport();
    }
  }

  handleEdit = () => {
    const { activeReportTemplate } = this.props.ReportStore;
    if (activeReportTemplate.reportTemplateType === 'app') {
      const { appId } = activeReportTemplate;
      Utilities.navigate(`/apps/${appId}?tab=dataview&report_template_id=${activeReportTemplate.id}`);
    } else {
      Utilities.navigate(`/account/users?tab=dataview&report_template_id=${activeReportTemplate.id}`);
    }
  };

  toggleLoadingReports() {
    this.isLoadingReports = !this.isLoadingReports;
  }

  toggleRunReport() {
    this.isRunningReport = !this.isRunningReport;
  }

  handleSelectReportTemplate(e) {
    this.props.ReportStore.setActiveReportTemplate(e.target.value);
    this.toggleLoadingReports();
    this.props.ReportStore.reports = [];
    const appNum = 1,
      perPage = 25,
      offset = 0;
    this.props.ReportActions.fetchReports(e.target.value, appNum, perPage, offset)
      .then(response => {
        this.props.ReportStore.createPaginator(response.headers);
        _.map(response.data, report => {
          this.props.ReportStore.addReport(report);
        });
        this.toggleLoadingReports();
      })
      .catch(() => {
        this.toggleLoadingReports();
      });
  }

  handleRunReport() {
    this.toggleRunReport();
    this.props.ReportActions.createReport(this.props.ReportStore.activeReportTemplate.id)
      .then(response => {
        this.props.ReportStore.addReport(response);
        this.props.ReportStore.pagination.xTotal++;
        this.toggleRunReport();
      })
      .catch(() => {
        this.toggleRunReport();
      });
  }

  renderReports() {
    if (this.isLoadingReports) {
      return (
        <Flexbox flexGrow={1} alignContent="center" justifyContent="center">
          <NonIdealState
            title={I18n.t('js.fetching_reports')}
            description={I18n.t('js.loading')}
            visual={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.ReportStore.reports.length === 0) {
      return (
        <Flexbox flexGrow={1} className="bp3-card" alignContent="center" justifyContent="center" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.run_a_report_to_generate_a_new_report')}
            icon="play"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return <ReportList toggleLoadingReports={this.toggleLoadingReports.bind(this)} />;
  }

  renderTotal() {
    if (this.props.ReportStore.pagination.xTotal > 0) {
      return <span>({this.props.ReportStore.pagination.xTotal})</span>;
    }
    return null;
  }

  renderActiveReportTemplate() {
    if (this.props.ReportStore.activeReportTemplate !== '') {
      return (
        <Flexbox flexDirection="column" marginTop="20px">
          <h2 className={Classes.HEADING}>
            {this.props.ReportStore.activeReportTemplate.name} {this.renderTotal()}
          </h2>
          <p>{this.props.ReportStore.activeReportTemplate.description}</p>
          <Flexbox flexDirection="row" flexGrow={1}>
            <Button
              text={I18n.t('js.run_report')}
              icon="play"
              className="bp3-intent-success push-10-r"
              onClick={this.handleRunReport.bind(this)}
              loading={this.isRunningReport}
            />
            <Button text={I18n.t('js.edit_report_template')} icon="edit" onClick={this.handleEdit} />
          </Flexbox>
          {this.renderReports()}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} paddingTop="50px" marginTop="20px" className="bp3-card">
        <NonIdealState
          title={I18n.t('js.select_a_report')}
          description={I18n.t('js.select_a_report_to_get_started')}
          icon="chart"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexDirection="row" flexGrow={0}>
          <HTMLSelect
            name="reportTemplateSelect"
            onChange={this.handleSelectReportTemplate.bind(this)}
            value={this.props.ReportStore.activeReportTemplate.id}
          >
            <option value="" disabled={this.props.ReportStore.activeReportTemplate !== ''} defaultValue>
              {I18n.t('js.select_a_report')}
            </option>
            {_.map(this.props.ReportStore.reportTemplates, reportTemplate => (
              <option value={reportTemplate.id} key={reportTemplate.id}>
                {reportTemplate.name}
              </option>
            ))}
          </HTMLSelect>
        </Flexbox>
        {this.renderActiveReportTemplate()}
      </Flexbox>
    );
  }
}
