import nanoid from 'nanoid';
import Turbolinks from 'turbolinks';
import ColorDefinitions from '../static/ColorDefinitions';
import moment from 'moment';
import _ from 'lodash';

class Utilities {
  static navigate(url, crossDomain) {
    if (this.IsSafari && crossDomain) {
      window.location = url;
    } else {
      Turbolinks.start();
      // Add Turbolinks to the global namespace
      window.Turbolinks = Turbolinks;
      Turbolinks.visit(url);
      // Remove previous (native) events, and add Turbolinks'
      ReactRailsUJS.detectEvents();
      // (Optional) Clean up global namespace
      delete window.Turbolinks;
    }
  }

  static getGreetingTime = m => {
    var g = null; //return g

    if (!m || !m.isValid()) {
      return;
    } //if we can't find a valid or filled moment, we return.

    var split_afternoon = 12; //24hr time to split the afternoon
    var split_evening = 17; //24hr time to split the evening
    var currentHour = parseFloat(m.format('HH'));

    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      g = I18n.t('js.afternoon');
    } else if (currentHour >= split_evening) {
      g = I18n.t('js.evening');
    } else {
      g = I18n.t('js.morning');
    }

    return g;
  };

  static enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = [];
    const currDate = moment.utc(startDate).startOf('day');
    const lastDate = moment.utc(endDate).startOf('day');
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    return dates;
  };

  static getObjectValues = dataObject => {
    const result = _.map(dataObject, (value, key) => {
      return value;
    });
    return result;
  };

  static generateRandomImgName = () => {
    const radix = 36;
    const decimalCutOff = 2;
    // disabled becuase my code highlighting is broken otherwise
    // prettier-ignore
    // eslint-disable-next-line  no-extra-parens
    return (`${Math.random()
      .toString(radix)
      .slice(decimalCutOff)}.png`);
  };

  static generateRandomFileName = () => {
    const radix = 36;
    const decimalCutOff = 2;
    // disabled becuase my code highlighting is broken otherwise
    // prettier-ignore
    // eslint-disable-next-line  no-extra-parens
    return (`${Math.random()
      .toString(radix)
      .slice(decimalCutOff)}`);
  };

  static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static getDisplayInitials = email =>
    // eslint-disable-next-line no-magic-numbers
    email.substring(0, 2).toLowerCase();

  static IsSafari() {
    return navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
  }

  static getFileName(fullPath) {
    return fullPath.replace(/^.*[\\/]/u, '');
  }

  static validateEmail(email) {
    // lazy fix
    // eslint-disable-next-line no-useless-escape
    const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
    return expression.test(email.toLowerCase());
  }

  static validateUrl(url) {
    const expression = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/u;
    return expression.test(url);
  }

  static validateSubdomain(subdomain) {
    const expression = /^[a-z]+$/u;
    return expression.test(subdomain);
  }

  static validateApiKey(api_key) {
    const expression = /^([A-Za-z0-9])\w/u;
    return expression.test(api_key);
  }

  // Azure integration validations
  static validateAzureClientId(client_id) {
    const expression = /^([a-z0-9]*[\-][a-z0-9]*[\-][a-z0-9]*[\-][a-z0-9]*[\-][a-z0-9]*)$/u;
    return expression.test(client_id);
  }

  static validateAzureClientSecret(client_secret) {
    return true;
  }

  static validateAzureClientUri(client_uri) {
    // const expression = /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.#?&//=]*))$/u;
    // return expression.test(client_uri);
    return true;
  }

  static validateAzureClientTenant(client_tenant) {
    const expression = /^([a-z0-9]*[\-][a-z0-9]*[\-][a-z0-9]*[\-][a-z0-9]*[\-][a-z0-9]*)$/u;
    return expression.test(client_tenant);
  }

  static makeId() {
    return nanoid();
  }

  static getRandomColor() {
    const definitions = ColorDefinitions.ptColors;
    const max = definitions.length - 1;
    const integer = this.getRandomInt(0, max);
    return definitions[integer];
  }

  static hexToRgb(hex) {
    /* eslint-disable no-magic-numbers */
    if (hex.charAt(0) === '#') {
      hex = hex.substr(1);
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    /* eslint-enable */
    return `${r},${g},${b}`;
  }

  static decimalToInt(decimal) {
    if (decimal % 1 === 0) {
      return Math.floor(decimal);
    }
    return decimal;
  }

  static isNumeric(str) {
    if (str === '.' || str === '') {
      return true;
    }
    return /^\d+$/u.test(str);
  }

  static humanize(str) {
    const frags = str.split('_');
    let i = 0;
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  static darkenHex(color, percent) {
    /* eslint-disable no-magic-numbers */
    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = (f >> 8) & 0x00ff;
    const B = f & 0x0000ff;
    return `#${(0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B))
      .toString(16)
      .slice(1)}`;
    /* eslint-enable */
  }

  static getMetaContent(metaName) {
    const metas = document.getElementsByTagName('meta');
    // eslint-disable-next-line require-unicode-regexp
    const re = new RegExp(`\\b${metaName}\\b`, 'i');
    let i = 0;
    const mLength = metas.length;
    for (i; i < mLength; i++) {
      if (re.test(metas[i].getAttribute('name'))) {
        return metas[i].getAttribute('content');
      }
    }
    return '';
  }

  static copyStringToClipboard(str) {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }
}

export default Utilities;
