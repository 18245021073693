/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from '../../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import User from '../../../models/UserModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect, Suggest } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

axiosCancel(axios);

const UserMultiSelect = MultiSelect.ofType(User);
const UserSuggest = Suggest.ofType(User);

@inject('ToastStore')
@observer
export default class ElementObjectSelectDisplayUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchUsers: [],
      selectedUsers: [],
      selectedUser: '',
      isLoading: false,
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.setState({
      searchUsers: _.unionBy([newUser], this.state.searchUsers, 'id'),
    });
  }

  searchUsers(query) {
    this.setState({ searchUsers: [], query });
    if (query.length > 1) {
      this.setState({ isLoading: true });
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${query}`, {
          requestId: 'userSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            this.addUser(user);
            total--;
          });
          if (total === 0) {
            this.setState({ isLoading: false });
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.setState({ isLoading: false });
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            this.setState({ isLoading: false });
          }
        });
    }
  }

  renderUser(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.fullName}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-graph push-5-r" /> <span> {I18n.t('js.no_users_found')} </span>
          </span>
        }
      />
    );
  }

  renderTag(user) {
    return <span id={user.id}>{user.fullName}</span>;
  }

  renderInputValue(user) {
    return user.fullName;
  }

  handleUsersSelect(user) {
    this.setState({
      selectedUsers: _.concat(this.state.selectedUsers.slice(), [user]),
    });
    this.handlePopoverClose();
  }

  handleUserSelect(user) {
    this.setState({ selectedUser: user });
    this.handlePopoverClose();
  }

  handlePopoverClose() {
    this.setState({ searchUsers: [], query: '' });
  }

  handleUserRemove(user) {
    const userId = user.props.id;
    const newUsers = _.filter(this.state.selectedUsers.slice(), o => o.id !== userId);
    this.setState({ selectedUsers: newUsers });
  }

  filterUser() {
    let possibleUsers = this.state.searchUsers;
    // filter out selected users
    if (this.props.element.elementObjectSelect.optionType === 'multiple') {
      _.map(this.state.selectedUsers, user => {
        possibleUsers = _.filter(possibleUsers, o => o.id !== user.id);
      });
    }
    possibleUsers = _.orderBy(possibleUsers, ['fullName'], ['asc']);
    return possibleUsers;
  }

  renderRightElement() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    if (this.props.element.elementObjectSelect.optionType === 'multiple') {
      return (
        <UserMultiSelect
          className="max-width"
          resetOnSelect={true}
          items={this.state.searchUsers}
          itemListPredicate={this.filterUser.bind(this)}
          selectedItems={this.state.selectedUsers}
          tagRenderer={this.renderTag.bind(this)}
          itemRenderer={this.renderUser.bind(this)}
          onItemSelect={this.handleUsersSelect.bind(this)}
          tagInputProps={{
            disabled: this.props.disabled,
            inputProps: {
              disabled: this.props.disabled,
              placeholder: this.props.element.elementObjectSelect.placeholderText,
            },
            leftIcon: 'search',
            rightElement: this.renderRightElement(),
            className: 'bp3-tag-input',
            onRemove: this.handleUserRemove.bind(this),
          }}
          query={this.state.query}
          onQueryChange={this.searchUsers.bind(this)}
          popoverProps={{
            usePortal: false,
            disabled: this.props.disabled,
            position: 'bottom-left',
            className: '',
            popoverClassName: 'bp3-minimal',
            onClose: this.handlePopoverClose.bind(this),
          }}
          noResults={this.renderResultSearch()}
        />
      );
    }
    return (
      <UserSuggest
        className="max-width"
        resetOnSelect={true}
        items={this.state.searchUsers}
        itemListPredicate={this.filterUser.bind(this)}
        inputValueRenderer={this.renderInputValue.bind(this)}
        itemRenderer={this.renderUser.bind(this)}
        validateInput={() => null}
        intent={null}
        onItemSelect={this.handleUserSelect.bind(this)}
        inputProps={{
          disabled: this.props.disabled,
          leftIcon: 'search',
          placeholder: this.props.element.elementObjectSelect.placeholderText,
        }}
        query={this.state.query}
        onQueryChange={this.searchUsers.bind(this)}
        popoverProps={{
          usePortal: false,
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
          disabled: this.props.disabled,
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
