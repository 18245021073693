/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class AuthorizedAccount {
  @observable id;
  @observable accountId;
  @observable accountName;
  @observable accountImage;
  @observable accountOwner;
  @observable accountDummyData;
  @observable accountEnterprise;
  @observable accountSubdomain;
  @observable activeAccount;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable lastActive;
  @observable updatedAt;
  @observable updatedBy;
  @observable userId;
  @observable profileTemplateId;

  constructor(
    id,
    accountId,
    accountName,
    accountImage,
    accountOwner,
    accountDummyData,
    accountEnterprise,
    accountSubdomain,
    activeAccount,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    lastActive,
    updatedAt,
    updatedBy,
    userId,
    profileTemplateId
  ) {
    this.id = id || '';
    this.accountId = accountId || '';
    this.accountName = accountName || '';
    this.accountImage = accountImage || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof accountOwner === 'undefined' ? (this.accountOwner = false) : (this.accountOwner = accountOwner);
    typeof accountDummyData === 'undefined' ? (this.accountDummyData = false) : (this.accountDummyData = accountDummyData);
    typeof accountEnterprise === 'undefined' ? (this.accountEnterprise = false) : (this.accountEnterprise = accountEnterprise);
    this.accountSubdomain = accountSubdomain || '';
    // eslint-disable-next-line no-unused-expressions
    typeof activeAccount === 'undefined' ? (this.activeAccount = false) : (this.activeAccount = activeAccount);
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.lastActive = lastActive || '';
    this.userId = userId || '';
    this.profileTemplateId = profileTemplateId || '';
  }

  @computed
  get accountNameValid() {
    if (this.accountName.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get accountNameError() {
    if (this.accountName.length < 1) {
      return I18n.t('js.account_name_is_required');
    }
    return null;
  }

  @computed
  get accountSubdomainValid() {
    if (this.accountSubdomain.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get accountSubdomainError() {
    if (this.accountSubdomain.length < 1) {
      return I18n.t('js.account_subdomain_is_required');
    }
    return null;
  }

  @computed
  get lastActiveToDate() {
    return moment.utc(this.lastActive).format('MMMM Do YYYY, h:mm:ss a');
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
