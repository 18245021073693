import React from 'react';
import { Provider } from 'mobx-react';
import { AnchorButton, Button, Classes, NonIdealState, Tooltip } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import CommentStore from '../stores/CommentStore';
import CommentList from '../components/comments/CommentList';
import NewComment from '../components/comments/NewComment';
import Paginator from '../models/PaginatorModel';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

export default class CommentsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
      isLoading: false,
      isLoadingMore: false,
      isCommenting: false,
      comments: [],
      paginator: new Paginator(),
    };
  }

  toggleCommenting() {
    this.setState({ isCommenting: !this.state.isCommenting });
  }

  handleAfterCreate(comment) {
    let comments = this.state.comments.concat(comment);
    const paginator = _.clone(this.state.paginator);
    paginator.xTotal++;
    comments = _.orderBy(comments, ['createdAt'], ['asc']);
    this.setState({ comments, paginator });
  }

  handleAfterDelete(commentId) {
    const comments = _.filter(this.state.comments, o => o.id !== commentId);
    const paginator = _.clone(this.state.paginator);
    paginator.xTotal--;
    this.setState({ comments, paginator });
  }

  handleAfterChange(comment) {
    let comments = _.filter(this.state.comments, o => o.id !== comment.id);
    comments = comments.concat(comment);
    comments = _.orderBy(comments, ['createdAt'], ['asc']);
    this.setState({ comments });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.commentable.id !== this.props.commentable.id) {
      if (nextProps.commentable.id) {
        this.toggleLoading();
        const pageNum = 1;
        const perPage = 10;
        const offset = 0;
        this.getData(nextProps.commentable.id, nextProps.commentableType, pageNum, perPage, offset)
          .then(() => {
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      }
    }
  }

  componentDidMount() {
    this.toggleLoading();
    if (this.props.commentable.id) {
      const pageNum = 1;
      const perPage = 10;
      const offset = 0;
      this.getData(this.props.commentable.id, this.props.commentableType, pageNum, perPage, offset)
        .then(() => {
          this.toggleLoading();
        })
        .catch(() => {
          this.toggleLoading();
        });
    }
  }

  loadMore() {
    this.toggleLoadingMore();
    const perPage = 10;
    const offset = 0;
    this.getData(this.props.commentable.id, this.props.commentableType, this.state.paginator.xPage + 1, perPage, offset)
      .then(() => {
        this.toggleLoadingMore();
      })
      .catch(() => {
        this.toggleLoadingMore();
      });
  }

  getData(commentableId, commentableType, page, total, offset) {
    if (this.props.sectionId) {
      return new Promise((resolve, reject) => {
        CommentStore.fetchCommentsWithReference(commentableId, commentableType, this.props.sectionId, 'Section', page, total, offset)
          .then(response => {
            CommentStore.createPaginator(this.props.commentable.id, response.headers);
            let totalLength = response.data.length;
            _.map(response.data, comment => {
              CommentStore.addComment(comment);
              totalLength--;
            });
            if (totalLength === 0) {
              this.setPaginator();
              this.setComments();
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        CommentStore.fetchComments(commentableId, commentableType, page, total, offset)
          .then(response => {
            CommentStore.createPaginator(this.props.commentable.id, response.headers);
            let totalLength = response.data.length;
            _.map(response.data, comment => {
              CommentStore.addComment(comment);
              totalLength--;
            });
            if (totalLength === 0) {
              this.setPaginator();
              this.setComments();
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }

  setPaginator() {
    const paginator = _.find(CommentStore.paginators, o => o.id === this.props.commentable.id);
    if (paginator) {
      this.setState({ paginator });
    }
  }

  setComments() {
    let comments = _.filter(
      CommentStore.comments,
      o => o.commentableId === this.props.commentable.id && o.commentableReferenceId == this.props.sectionId
    );
    this.setState({ comments });
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  toggleLoadingMore() {
    this.setState({ isLoadingMore: !this.state.isLoadingMore });
  }

  renderCommentList() {
    if (this.state.isLoading) {
      return <Flexbox flexGrow={1} />;
    }
    return (
      <CommentList
        commentable={this.props.commentable}
        commentableType={this.props.commentableType}
        sectionId={this.props.sectionId}
        canPin={this.props.canPin}
        comments={this.state.comments}
        sortByNew={this.props.sortByNew}
        afterCreate={this.handleAfterCreate.bind(this)}
        afterDelete={this.handleAfterDelete.bind(this)}
        afterChange={this.handleAfterChange.bind(this)}
      />
    );
  }

  renderLoadMore() {
    if (this.state.comments.length < this.state.paginator.xTotal) {
      return (
        <Flexbox flexGrow={1} marginTop="20px" justifyContent="center">
          <Button
            className="bp3-minimal bp3-large bp3-text-muted"
            icon="more"
            onClick={this.loadMore.bind(this)}
            loading={this.state.isLoadingMore}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderComments() {
    if (this.props.sortByNew) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" marginTop="20px">
          {this.renderCommentList()}
          {/* {this.renderLoadMore()} */}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column" marginTop="20px">
        {/* {this.renderLoadMore()} */}
        {this.renderCommentList()}
      </Flexbox>
    );
  }

  renderCommentLink() {
    if (this.state.isHovering) {
      return (
        <Flexbox flexGrow={0} flexDirection="column" justifyContent="flex-start" marginLeft="10px" marginTop="-10px">
          <Tooltip portalContainer={document.body} content={this.props.buttonText}>
            <AnchorButton onClick={this.toggleCommenting.bind(this)} icon="plus" small={true} minimal={true} />
          </Tooltip>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      WorkspaceStore,
      CommentStore,
      CurrentUserStore,
      ToastStore,
    };
    if (this.props.stub) {
      return (
        <Provider {...stores}>
          <ErrorBoundary>
            <Flexbox
              flexGrow={1}
              flexDirection="column"
              className="comment-container"
              onMouseEnter={() => this.setState({ isHovering: true })}
              onMouseLeave={() => this.setState({ isHovering: false })}
            >
              <Flexbox flexDirection="column" flexGrow={1}>
                <Flexbox flexDirection="row" flexGrow={1}>
                  <Flexbox flexGrow={1}>
                    <Flexbox flexDirection="row" alignItems="center" alignContent="center">
                      <Flexbox>
                        <h6 className={Classes.HEADING}>
                          {I18n.t('js.comments')} ({this.state.paginator.xTotal})
                        </h6>
                      </Flexbox>
                      {this.renderCommentLink()}
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
                <Flexbox flexGrow={1} flexDirection="column">
                  <Flexbox flexDirection="row" flexGrow={1}>
                    <NewComment
                      commentableId={this.props.commentable.id}
                      commentableType={this.props.commentableType}
                      commentEnabled={this.state.isCommenting}
                      sectionId={this.props.sectionId}
                      afterCreate={this.handleAfterCreate.bind(this)}
                      handleCancel={this.toggleCommenting.bind(this)}
                    />
                  </Flexbox>
                  {this.renderComments()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </Provider>
      );
    }
    return (
      <ErrorBoundary>
        <Flexbox flexGrow={1} flexDirection="column">
          {this.renderComments()}
        </Flexbox>
      </ErrorBoundary>
    );
  }
}
