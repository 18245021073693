// @flow
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from '../stores/ToastStore';
import type { DataViewLocation, UUID } from '../types/DataViewTypes';

const apiEnv = Cookies.get('apiEnv');
const dataViewEndpoint = (
  dataViewLocation: DataViewLocation
): string => {
  switch (dataViewLocation) {
    case 'profile':
      return `${apiEnv}/users/dataview?profile_template_id=`;
    case 'app':
      return `${apiEnv}/items/dataview?app_id=`;
    default:
      throw new Error(
        `location (${dataViewLocation}) is not accounted for`
      );
  }
};
const reportTemplatesEndpoint = `${apiEnv}/report_templates`;
const reportTemplateColumnsEndpoint = `${apiEnv}/report_template_columns`;
const reportTemplateFiltersEndpoint = `${apiEnv}/report_template_filters`;

export default class DataViewActions {
  static fetchDataViewData({
    dataViewLocation,
    appOrProfileId,
  }: {
    dataViewLocation: DataViewLocation,
    appOrProfileId: UUID,
  }): Promise<any> {
    return axios
      .get(`${dataViewEndpoint(dataViewLocation)}${appOrProfileId}`)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static fetchReportTemplate(reportTemplateId: string): Promise<any> {
    return axios
      .get(`${reportTemplatesEndpoint}/${reportTemplateId}`)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static createReportTemplate(data: any): Promise<any> {
    return axios
      .post(`${reportTemplatesEndpoint}`, data)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static changeReportTemplate(data: any): Promise<any> {
    return axios
      .put(`${reportTemplatesEndpoint}/${data.id}`, data)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static deleteReportTemplate(id: string): Promise<any> {
    return axios
      .delete(`${reportTemplatesEndpoint}/${id}`)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static changeReportTemplateColumn(data: any): Promise<any> {
    return axios
      .put(`${reportTemplateColumnsEndpoint}/${data.id}`, data)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static createReportTemplateFilter(data: any): Promise<any> {
    return axios
      .post(`${reportTemplateFiltersEndpoint}`, data)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static changeReportTemplateFilter(data: any): Promise<any> {
    return axios
      .put(`${reportTemplateFiltersEndpoint}/${data.id}`, data)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static deleteReportTemplateFilter(id: string): Promise<any> {
    return axios
      .delete(`${reportTemplateFiltersEndpoint}/${id}`)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static errorHandler(error: any) {
    const errors = error.response.data.error.join(', ');
    ToastStore.showToast(errors, 'danger');
  }
}
