import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import ColorSelector from '../shared/ColorSelector';
import IconSelector from '../shared/IconSelector';
import classNames from 'classnames';

@inject('WorkspaceStore')
@observer
export default class WorkspaceEditStyle extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isOpen: false };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit() {
    this.toggleLoading();
    const formData = new FormData();
    if (this.props.WorkspaceStore.newWorkspace.icon !== this.props.WorkspaceStore.activeWorkspace.icon) {
      formData.append('icon', this.props.WorkspaceStore.newWorkspace.icon);
    }
    if (this.props.WorkspaceStore.newWorkspace.color !== this.props.WorkspaceStore.activeWorkspace.color) {
      formData.append('color', this.props.WorkspaceStore.newWorkspace.color);
    }
    this.props.WorkspaceStore.changeWorkspace(formData)
      .then(() => {
        this.setState({ image: '' });
        this.toggleLoading();
        this.toggleOpen();
      })
      .catch(this.toggleLoading);
  }

  handleCancel() {
    this.setState({ isOpen: false, hasImage: false });
  }

  handleChangeIcon(icon) {
    this.props.WorkspaceStore.updateNewWorkspace(icon, 'icon');
  }

  handleChangeColor(color) {
    this.props.WorkspaceStore.updateNewWorkspace(color, 'color');
  }

  renderColorSelector() {
    if (this.state.isOpen) {
      return (
        <ColorSelector activeColor={this.props.WorkspaceStore.newWorkspace.color} handleChangeColor={this.handleChangeColor.bind(this)} />
      );
    }
    return undefined;
  }

  renderIconSelector() {
    if (this.state.isOpen) {
      return <IconSelector activeIcon={this.props.WorkspaceStore.newWorkspace.icon} handleChangeIcon={this.handleChangeIcon.bind(this)} />;
    }
    return undefined;
  }

  render() {
    const newColorClass = classNames('bp3-icon bp3-icon-full-circle push-5-r');
    const newIconClass = classNames('push-5-r fa', this.props.WorkspaceStore.newWorkspace.icon);
    const newColor = {
      color: this.props.WorkspaceStore.newWorkspace.color,
    };
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.style')}
            </h4>
            <p className="noselect">{I18n.t('js.workspace_style_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form>
            <Flexbox flexDirection="column">
              <Flexbox flexDirection="column">
                <Flexbox flexDirection="column" marginTop="20px">
                  <h5 className={Classes.HEADING}>
                    <span className={newColorClass} style={newColor} />
                    {I18n.t('js.workspace_color')}
                  </h5>
                  <Flexbox width="460px" flexDirection="column">
                    {this.renderColorSelector()}
                  </Flexbox>
                </Flexbox>
                <Flexbox flexDirection="column" marginTop="20px">
                  <h5 className={Classes.HEADING}>
                    <span className={newIconClass} />
                    {I18n.t('js.workspace_icon')}
                  </h5>
                  {this.renderIconSelector()}
                </Flexbox>
                <div className="push-10-t">
                  <Button
                    intent={Intent.PRIMARY}
                    onClick={this.handleSubmit.bind(this)}
                    text={I18n.t('js.save_changes')}
                    loading={this.state.isSubmitting}
                    icon="tick"
                  />
                  <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
                </div>
              </Flexbox>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
