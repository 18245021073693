import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Alert, Classes, Intent, Tag, Tooltip, AnchorButton } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import RichTextDisplay from '../shared/RichTextDisplay';
import renderHTML from 'react-render-html';
import CommentsView from '../../views/CommentsView';
import NewComment from './NewComment';
import EditComment from './EditComment';
import classNames from 'classnames';

@inject('CommentStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class ShowComment extends React.Component {
  @observable isDeleting = false;
  @observable isEditing = false;
  @observable isReplying = false;

  constructor(props) {
    super(props);

    this.state = { actionsEnabled: false };
  }

  addHover() {
    this.setState({ actionsEnabled: true });
  }

  removeHover() {
    this.setState({ actionsEnabled: false });
  }

  toggleDeleting() {
    this.isDeleting = !this.isDeleting;
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
  }

  toggleReyplying() {
    this.isReplying = !this.isReplying;
  }

  handleAfterCommentCreated() {
    this.handleCancelComment();
  }

  handleCancelComment() {
    this.isReplying = false;
  }

  handleDelete() {
    this.props.CommentStore.deleteComment(this.props.comment.id).then(response => {
      this.toggleDeleting();
      this.props.CommentStore.removeComment(response.data.id);
      this.props.afterDelete(response.data.id);
      this.props.ToastStore.showToast(I18n.t('js.comment_has_been_deleted'), 'success');
    });
  }

  handlePinComment() {
    const commentObj = {
      id: this.props.comment.id,
      pinned: true,
    };
    this.props.CommentStore.changeComment(commentObj).then(response => {
      this.props.CommentStore.addComment(response.data).then(comment => {
        this.props.afterChange(comment);
        this.props.ToastStore.showToast(I18n.t('js.comment_has_been_pinned'), 'success');
      });
    });
  }

  handleUnpinComment() {
    const commentObj = {
      id: this.props.comment.id,
      pinned: false,
    };
    this.props.CommentStore.changeComment(commentObj).then(response => {
      this.props.CommentStore.addComment(response.data).then(comment => {
        this.props.afterChange(comment);
        this.props.ToastStore.showToast(I18n.t('js.comment_has_been_unpinned'), 'success');
      });
    });
  }

  handleVote(reaction) {
    const commentReactionObj = {
      comment_id: this.props.comment.id,
      user_id: this.props.CurrentUserStore.currentUser.id,
      reaction_type: reaction,
    };
    this.props.CommentStore.createCommentReaction(commentReactionObj).then(() => {
      if (reaction === 'upvote') {
        this.props.comment.voteCount++;
        this.props.ToastStore.showToast(I18n.t('js.comment_has_been_upvoted'), 'success');
      } else {
        this.props.comment.voteCount--;
        this.props.ToastStore.showToast(I18n.t('js.comment_has_been_downvoted'), 'success');
      }
    });
  }

  renderDelete() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_comment')}
        onConfirm={this.handleDelete.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_comment')}
      </Alert>
    );
  }

  renderOwnerActions() {
    if (this.props.comment.userId === this.props.CurrentUserStore.currentUser.id && !this.isEditing) {
      return [
        <Tooltip portalContainer={document.body} key={0} content={I18n.t('js.edit_comment')} position="bottom">
          <AnchorButton minimal={true} key={0} onClick={this.toggleEditing.bind(this)} className="bp3-button bp3-icon-edit" />
        </Tooltip>,
        <Tooltip portalContainer={document.body} key={1} content={I18n.t('js.delete_comment')} position="bottom">
          <AnchorButton minimal={true} key={1} onClick={this.toggleDeleting.bind(this)} className="bp3-button bp3-icon-trash" />
        </Tooltip>,
      ];
    }
    return undefined;
  }

  renderPinnable() {
    if (this.props.canPin) {
      if (this.props.comment.pinned) {
        return (
          <Tooltip portalContainer={document.body} content={I18n.t('js.unpin_comment')} position="bottom">
            <AnchorButton minimal={true} onClick={this.handleUnpinComment.bind(this)} className="bp3-button bp3-icon-unpin" />
          </Tooltip>
        );
      }
      return (
        <Tooltip portalContainer={document.body} content={I18n.t('js.pin_comment')} position="bottom">
          <AnchorButton minimal={true} onClick={this.handlePinComment.bind(this)} className="bp3-button bp3-icon-pin" />
        </Tooltip>
      );
    }
    return undefined;
  }

  renderActions() {
    if (!this.isEditing && this.state.actionsEnabled) {
      return (
        <Flexbox flexGrow={0} justifyContent="flex-end" className="bp3-button-group bp3-minimal bp3-small">
          <Tooltip portalContainer={document.body} content={I18n.t('js.reply')} position="bottom">
            <AnchorButton minimal={true} onClick={this.toggleReyplying.bind(this)} className="bp3-button bp3-icon-key-enter" />
          </Tooltip>
          <Tooltip portalContainer={document.body} content={I18n.t('js.upvote_comment')} position="bottom">
            <AnchorButton minimal={true} onClick={() => this.handleVote('upvote')} className="bp3-button bp3-icon-thumbs-up" />
          </Tooltip>
          <Tooltip portalContainer={document.body} content={I18n.t('js.downvote_comment')} position="bottom">
            <AnchorButton minimal={true} onClick={() => this.handleVote('downvote')} className="bp3-button bp3-icon-thumbs-down" />
          </Tooltip>
          {this.renderPinnable()}
          {this.renderOwnerActions()}
          {this.renderDelete()}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderComment() {
    if (this.isEditing) {
      return <EditComment comment={this.props.comment} handleCancel={this.toggleEditing.bind(this)} afterChange={this.props.afterChange} />;
    }
    if (this.props.comment.pinned) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="pinned-text">
          <RichTextDisplay content={this.props.comment.body} type="comment" />
        </Flexbox>
      );
    }
    return <RichTextDisplay content={this.props.comment.body} type="comment" />;
  }

  renderText() {
    if (this.props.comment.commentableType === 'Comment') {
      return (
        <span className="push-5-t">
          {renderHTML(
            I18n.t(`js.user_replied`, {
              userName: this.props.comment.user.fullName,
              userLink: `/users/${this.props.comment.user.id}`,
            })
          )}
        </span>
      );
    }
    switch (this.props.comment.commentableType) {
      case 'Activity':
        return (
          <span className="push-5-t">
            {renderHTML(
              I18n.t(`js.user_commented_on_activity`, {
                userName: this.props.comment.user.fullName,
                userLink: `/users/${this.props.comment.user.id}`,
                objectLink: `/activities/${this.props.comment.commentableId}`,
              })
            )}
          </span>
        );
      case 'Workspace':
        return (
          <span className="push-5-t">
            {renderHTML(
              I18n.t(`js.user_commented_on_workspace`, {
                userName: this.props.comment.user.fullName,
                userLink: `/users/${this.props.comment.user.id}`,
                objectLink: `/workspaces/${this.props.comment.commentableId}`,
              })
            )}
          </span>
        );
      case 'WorkspaceFolder':
        return (
          <span className="push-5-t">
            {renderHTML(
              I18n.t(`js.user_commented_on_workspace_folder`, {
                userName: this.props.comment.user.fullName,
                userLink: `/users/${this.props.comment.user.id}`,
                objectLink: `/workspace_folders/${this.props.comment.commentableId}`,
              })
            )}
          </span>
        );
      case 'App':
        return (
          <span className="push-5-t">
            {renderHTML(
              I18n.t(`js.user_commented_on_app`, {
                userName: this.props.comment.user.fullName,
                userLink: `/users/${this.props.comment.user.id}`,
                objectLink: `/apps/${this.props.comment.commentableId}`,
              })
            )}
          </span>
        );
      case 'Item':
        return (
          <span className="push-5-t">
            {renderHTML(
              I18n.t(`js.user_commented_on_item`, {
                userName: this.props.comment.user.fullName,
                userLink: `/users/${this.props.comment.user.id}`,
                objectLink: `/items/${this.props.comment.commentableId}`,
              })
            )}
          </span>
        );
      case 'Team':
        return (
          <span className="push-5-t">
            {renderHTML(
              I18n.t(`js.user_commented_on_team`, {
                userName: this.props.comment.user.fullName,
                userLink: `/users/${this.props.comment.user.id}`,
                objectLink: `/teams/${this.props.comment.commentableId}`,
              })
            )}
          </span>
        );
      default:
        return (
          <span className="push-5-t">
            {renderHTML(
              I18n.t(`js.user_commented`, {
                userName: this.props.comment.user.fullName,
                userLink: `/users/${this.props.comment.user.id}`,
              })
            )}
          </span>
        );
    }
  }

  renderPin() {
    if (this.props.comment.pinned) {
      return (
        <Flexbox flexDirection="column" marginLeft="10px">
          <Tag className="bp3-intent-primary bp3-minimal" icon="pin">
            {I18n.t('js.pinned')}
          </Tag>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderVoteCount() {
    if (this.props.comment.voteCount !== 0) {
      let iconClass = classNames('bp3-icon bp3-icon-thumbs-up bp3-intent-success');
      let voteClass = classNames('push-5-r bp3-tag bp3-small bp3-intent-success bp3-minimal bp3-comment-vote-count');
      if (this.props.comment.voteCount < 0) {
        iconClass = classNames('bp3-icon bp3-icon-thumbs-down bp3-intent-danger');
        voteClass = classNames('push-5-r bp3-tag bp3-small bp3-intent-danger bp3-minimal bp3-comment-vote-count');
      }
      return (
        <Flexbox flexDirection="column" marginLeft="10px">
          <Flexbox className="position-relative">
            <span className={iconClass} />
            <small className={voteClass}>{this.props.comment.voteCount}</small>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column" marginBottom="10px">
        <Flexbox flexGrow={1} flexDirection="column" onMouseEnter={this.addHover.bind(this)} onMouseLeave={this.removeHover.bind(this)}>
          <Flexbox flexGrow={1} flexDirection="row">
            <a href={`/users/${this.props.comment.user.id}`}>
              <Avatar src={this.props.comment.user.imageFileName} size={40} round={true} />
            </a>
            <Flexbox flexGrow={1} marginLeft="10px" flexDirection="column" alignItems="flex-start" justifyContent="center">
              <Flexbox className="no-wrap" alignItems="center">
                <span className="bp3-icon bp3-icon-time bp3-text-muted push-5-r" />
                <small className="bp3-text-muted">{this.props.comment.createdAtToDate}</small>
                {this.renderPin()}
                {this.renderVoteCount()}
              </Flexbox>
              <Flexbox>{this.renderText()}</Flexbox>
            </Flexbox>
            <Flexbox flexGrow={0} flexDirection="row" alignItems="flex-end">
              {this.renderActions()}
            </Flexbox>
          </Flexbox>
          <Flexbox flexGrow={1} flexDirection="column" paddingBottom="5px" marginLeft="50px">
            {this.renderComment()}
          </Flexbox>
        </Flexbox>
        <Flexbox flexGrow={1} marginLeft="50px" flexDirection="column">
          <Flexbox>
            <NewComment
              commentableId={this.props.comment.id}
              commentableType="Comment"
              commentEnabled={this.isReplying}
              afterCreate={this.handleAfterCommentCreated.bind(this)}
              handleCancel={this.handleCancelComment.bind(this)}
            />
          </Flexbox>
          <CommentsView commentable={this.props.comment} commentableType="Comment" sortByNew={true} showNone={false} />
        </Flexbox>
      </Flexbox>
    );
  }
}
