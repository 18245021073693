import React from 'react';
import { observer, Provider } from 'mobx-react';
import ToastStore from '../stores/ToastStore';
import Toasts from '../components/layout/Toasts';

@observer
export default class ToastView extends React.Component {
  render() {
    const stores = {
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <Toasts
          flash={this.props.flash}
          toasts={ToastStore.toasts}
          latestToast={ToastStore.latestToast}
        />
      </Provider>
    );
  }
}
