import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Classes, Spinner, Button, Tooltip, Position } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import SkeletonTitle from '../skeletons/SkeletonTitle';
import User from '../../models/UserModel';
import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';

@inject('AppStore', 'ItemStore')
@observer
export default class ItemHistory extends React.Component {
  @observable
  isLoading = true;
  @observable
  createdByUser = new User('', 'anonymous@peoplegoal.com', 'Anonymous', 'User', 'Anonymous User');
  @observable
  updatedByUser = new User('', 'anonymous@peoplegoal.com', 'Anonymous', 'User', 'Anonymous User');

  componentDidMount() {
    this.searchUser(this.props.ItemStore.item.createdBy)
      .then(() => {
        if (this.props.ItemStore.item.createdBy !== this.props.ItemStore.item.updatedBy) {
          this.searchUser(this.props.ItemStore.item.updatedBy)
            .then(() => {
              this.toggleLoading();
            })
            .catch(() => {
              this.toggleLoading();
            });
        } else {
          this.toggleLoading();
        }
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
    if (newUser.id === this.props.ItemStore.item.createdBy) {
      this.createdByUser = newUser;
    }
    if (newUser.id === this.props.ItemStore.item.updatedBy) {
      this.updatedByUser = newUser;
    }
  }

  searchUser(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${userId}`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            this.addUser(user);
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  renderHistory() {
    if (this.isLoading) {
      return (
        <Flexbox
          flexGrow={0}
          flexDirection="row"
          justifyContent="space-between"
          style={{ borderTop: '1px solid #e6e6e6', height: '55px' }}
        ></Flexbox>
      );
    }
    const createdBy = this.createdByUser.fullName;
    const updatedBy = this.updatedByUser.fullName;
    return (
      <Flexbox flexGrow={0} flexDirection="row" justifyContent="flex-end" alignContent="flex-end">
        <Flexbox flexDirection="column" flexGrow={1} alignItems="flex-end">
          <Flexbox className="bp3-text-muted" marginTop="10px" alignItems="center">
            <small
              dangerouslySetInnerHTML={{
                __html: I18n.t('js.item_updated_by_on_date', {
                  item: this.props.AppStore.activeApp.item,
                  full_name: updatedBy,
                  link: `/users/${this.props.ItemStore.item.updatedBy}`,
                  date: this.props.ItemStore.item.updatedAtToShortDate,
                }),
              }}
            />
          </Flexbox>
          <Flexbox className="bp3-text-muted" marginBottom="5px" alignItems="center">
            <small
              dangerouslySetInnerHTML={{
                __html: I18n.t('js.item_created_by_on_date', {
                  item: this.props.AppStore.activeApp.item,
                  full_name: createdBy,
                  link: `/users/${this.props.ItemStore.item.createdBy}`,
                  date: this.props.ItemStore.item.createdAtToShortDate,
                }),
              }}
            />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column" justifyContent="flex-end">
        {this.renderHistory()}
      </Flexbox>
    );
  }
}
