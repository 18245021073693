/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from '../../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Team from '../../../models/TeamModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect, Suggest } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

axiosCancel(axios);

const TeamMultiSelect = MultiSelect.ofType(Team);
const TeamSuggest = Suggest.ofType(Team);

@inject('ToastStore')
@observer
export default class ElementObjectSelectDisplayTeam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchTeams: [],
      selectedTeams: [],
      selectedTeam: '',
      isLoading: false,
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addTeam(data) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.setState({
      searchTeams: _.unionBy([newTeam], this.state.searchTeams, 'id'),
    });
  }

  searchTeams(query) {
    this.setState({ searchTeams: [], query });
    if (query.length > 1) {
      this.setState({ isLoading: true });
      axios
        .get(`${Cookies.get('apiEnv')}/teams?query=${query}`, {
          requestId: 'teamSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, team => {
            this.addTeam(team);
            total--;
          });
          if (total === 0) {
            this.setState({ isLoading: false });
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.setState({ isLoading: false });
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            this.setState({ isLoading: false });
          }
        });
    }
  }

  renderTeam(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.name}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-graph push-5-r" /> <span> {I18n.t('js.no_teams_found')} </span>
          </span>
        }
      />
    );
  }

  renderTag(team) {
    return <span id={team.id}>{team.name}</span>;
  }

  renderInputValue(team) {
    return team.name;
  }

  handleTeamsSelect(team) {
    this.setState({
      selectedTeams: _.concat(this.state.selectedTeams.slice(), [team]),
    });
    this.handlePopoverClose();
  }

  handleTeamSelect(team) {
    this.setState({ selectedTeam: team });
    this.handlePopoverClose();
  }

  handlePopoverClose() {
    this.setState({ searchTeams: [], query: '' });
  }

  handleTeamRemove(team) {
    const teamId = team.props.id;
    const newTeams = _.filter(this.state.selectedTeams.slice(), o => o.id !== teamId);
    this.setState({ selectedTeams: newTeams });
  }

  filterTeam() {
    let possibleTeams = this.state.searchTeams;
    // filter out selected teams
    if (this.props.element.elementObjectSelect.optionType === 'multiple') {
      _.map(this.state.selectedTeams, team => {
        possibleTeams = _.filter(possibleTeams, o => o.id !== team.id);
      });
    }
    possibleTeams = _.orderBy(possibleTeams, ['name'], ['asc']);
    return possibleTeams;
  }

  renderRightElement() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    if (this.props.element.elementObjectSelect.optionType === 'multiple') {
      return (
        <TeamMultiSelect
          className="max-width"
          resetOnSelect={true}
          items={this.state.searchTeams}
          itemListPredicate={this.filterTeam.bind(this)}
          selectedItems={this.state.selectedTeams}
          tagRenderer={this.renderTag.bind(this)}
          itemRenderer={this.renderTeam.bind(this)}
          onItemSelect={this.handleTeamsSelect.bind(this)}
          tagInputProps={{
            disabled: this.props.disabled,
            inputProps: {
              disabled: this.props.disabled,
              placeholder: this.props.element.elementObjectSelect.placeholderText,
            },
            leftIcon: 'search',
            rightElement: this.renderRightElement(),
            className: 'bp3-tag-input',
            onRemove: this.handleTeamRemove.bind(this),
          }}
          query={this.state.query}
          onQueryChange={this.searchTeams.bind(this)}
          popoverProps={{
            usePortal: false,
            disabled: this.props.disabled,
            position: 'bottom-left',
            className: '',
            popoverClassName: 'bp3-minimal',
            onClose: this.handlePopoverClose.bind(this),
          }}
          noResults={this.renderResultSearch()}
        />
      );
    }
    return (
      <TeamSuggest
        className="max-width"
        resetOnSelect={true}
        items={this.state.searchTeams}
        itemListPredicate={this.filterTeam.bind(this)}
        validateInput={() => null}
        intent={null}
        inputValueRenderer={this.renderInputValue.bind(this)}
        itemRenderer={this.renderTeam.bind(this)}
        onItemSelect={this.handleTeamSelect.bind(this)}
        inputProps={{
          disabled: this.props.disabled,
          leftIcon: 'search',
          placeholder: this.props.element.elementObjectSelect.placeholderText,
        }}
        query={this.state.query}
        onQueryChange={this.searchTeams.bind(this)}
        popoverProps={{
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
          disabled: this.props.disabled,
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
