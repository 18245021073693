import React from 'react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Intent, Dialog } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore',
  'WidgetStore',
  'WorkspaceStore'
)
@observer
export default class DashboardNewUserWelcome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggleDialog() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentDidMount() {
    if (this.props.showWelcome) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    return (
      <Flexbox>
        <Dialog
          portalContainer={document.body}
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog.bind(this)}
          canOutsideClickClose={false}
          inline={true}
          title="Welcome to PeopleGoal!"
        >
          <Flexbox flexDirection="column" padding="20px" paddingTop="10px">
            <p>
              We're excited to have you onboard! PeopleGoal is a powerful tool for building custom applications and HR workflows. To begin,
              we recommend reading our gettiog started guide:
            </p>
            <ul>
              <li>
                <a href="https://www.peoplegoal.com/support/article/install-a-workspace" target="_blank">
                  Installing a workspace
                </a>
              </li>
              <li>
                <a href="https://www.peoplegoal.com/support/article/install-an-app" target="_blank">
                  Installing an app
                </a>
              </li>
              <li>
                <a href="https://www.peoplegoal.com/support/article/create-an-item" target="_blank">
                  Creating an item
                </a>
              </li>
            </ul>
            <p>
              There are many more resources available on your{' '}
              <a href="https://www.peoplegoal.com/support" target="_blank">
                support center
              </a>{' '}
              enabling you to master PeopleGoal.
            </p>
            <h3>Demo Video</h3>
            <p>We also have a detailed demonstration of PeopleGoal from one of our team members available to watch below.</p>
            <a href="https://evermind-digital.wistia.com/medias/tc3hn4wwex" target="_blank">
              <img
                src="https://res.cloudinary.com/dkctgehrc/image/upload/v1638195015/peoplegoal-site/img_demo_cover.png"
                style={{ maxWidth: '100%' }}
              ></img>
            </a>
            <p>Should you have any further questions or need further support please reach out to us from our support center.</p>
            <Flexbox flexDirection="row">
              <AnchorButton href="/workspaces" intent={Intent.PRIMARY}>
                Get started with your first workspace
              </AnchorButton>
            </Flexbox>
          </Flexbox>
        </Dialog>
      </Flexbox>
    );
  }
}
