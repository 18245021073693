import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  Classes,
  Collapse,
  Intent,
} from '@blueprintjs/core';
import Cookies from 'js-cookie';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class SlackIntegration extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDisconnecting: false, isOpen: true };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleDisconnecting() {
    this.setState({
      isDisconnecting: !this.state.isDisconnecting,
    });
  }

  handleDisconnect() {
    this.toggleDisconnecting();
    this.props.AccountSettingsStore.deleteIntegrationToken(
      this.props.AccountSettingsStore.slackIntegration.id
    )
      .then(response => {
        this.props.AccountSettingsStore.removeIntegrationToken(
          response.data.id
        );
        this.toggleDisconnecting();
        this.toggleOpen();
        this.props.ToastStore.showToast(
          I18n.t('js.your_account_is_now_disconnected_from_slack'),
          'success'
        );
      })
      .catch(() => {
        this.toggleDisconnecting();
      });
  }

  renderDisconnectAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDisconnecting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDisconnecting()}
        confirmButtonText={I18n.t('js.disconnect_account')}
        onConfirm={() => this.handleDisconnect()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t(
          'js.are_you_sure_you_wish_to_disconnect_this_account_from_slack'
        )}
      </Alert>
    );
  }

  renderSlackIntegration() {
    if (
      this.props.AccountSettingsStore.slackIntegration !== undefined
    ) {
      const token = JSON.parse(
        this.props.AccountSettingsStore.slackIntegration.token
      );
      return (
        <Flexbox
          className="bp3-callout bp3-icon-info-sign"
          flexDirection="column"
          flexGrow={1}
        >
          <h4 className={`${Classes.HEADING} bp3-callout-title`}>
            {I18n.t('js.connected_to_the_slack_workspace', {
              name: token.team_name,
            })}
          </h4>
          {I18n.t('js.your_account_is_connected_to_slack_workspace', {
            name: token.team_name,
          })}
          <Flexbox marginTop="20px">
            <Button
              text={I18n.t('js.disconnect_account')}
              icon="offline"
              onClick={this.toggleDisconnecting.bind(this)}
            />
          </Flexbox>
          {this.renderDisconnectAlert()}
        </Flexbox>
      );
    }
    const slackRef = `https://slack.com/oauth/authorize?scope=bot,channels:read,chat:write:bot&client_id=${Cookies.get(
      'slackClientId'
    )}&redirect_uri=${Cookies.get('slackRedirectUri')}`;
    return (
      <Flexbox>
        <a href={slackRef}>
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* jsx-a11y disabled as alternative toggler avaiable */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.slack')}
            </h4>
            <p className="noselect">
              {I18n.t('js.slack_integration_text')}
            </p>
          </Flexbox>
          {/* <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox> */}
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          {this.renderSlackIntegration()}
        </Collapse>
      </Flexbox>
    );
  }
}
