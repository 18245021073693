/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { AnchorButton, Checkbox, Classes, FormGroup, Intent, MenuDivider, MenuItem, Radio, RadioGroup } from '@blueprintjs/core';
import { MultiSelect, Select } from '@blueprintjs/select';
import classNames from 'classnames';
import ElementSelectOption from '../../models/elements/ElementSelectOptionModel';
import _ from 'lodash';

const SingleSelectOption = Select.ofType(ElementSelectOption);
const MultipleSelectOption = MultiSelect.ofType(ElementSelectOption);

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementSelect extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  singleSelectOption = '';
  @observable
  multipleSelectOptions = [];

  componentDidMount() {
    this.setStoreAndActions();
    this.addPlaceholderOptions();
    if (this.props.element.elementSelect.optionType === 'single') {
      const activeFormValue = _.find(this.props.element.formValues, o => o.booleanValue === true);
      if (activeFormValue) {
        const findOption = _.find(
          this.props.element.elementSelect.elementSelectOptions,
          o => o.id === activeFormValue.elementSelectOptionId
        );
        if (findOption) {
          this.singleSelectOption = findOption;
        }
      }
      this.props.validateInput(this.singleSelectOption);
    } else {
      const activeFormValues = _.filter(this.props.element.formValues, o => o.booleanValue);
      _.map(activeFormValues, formValue => {
        const findOption = _.find(this.props.element.elementSelect.elementSelectOptions, o => o.id === formValue.elementSelectOptionId);
        if (findOption) {
          this.multipleSelectOptions = this.multipleSelectOptions.concat(findOption);
        }
      });
      this.sortMultipleSelectOptions();
      this.props.validateInput(this.multipleSelectOptions);
    }
    this.mountDisplayValuesInFlight();
  }

  mountDisplayValuesInFlight() {
    if (this.props.element.elementSelect.optionType === 'single') {
      _.map(this.store.formValuesToCreate, formValue => {
        const findOption = _.find(this.props.element.elementSelect.elementSelectOptions, o => o.id === formValue.element_select_option_id);
        if (findOption) {
          this.singleSelectOption = findOption;
        }
      });
      _.map(this.store.formValuesToChange, formValue => {
        if (formValue.boolean_value) {
          const findOption = _.find(
            this.props.element.elementSelect.elementSelectOptions,
            o => o.id === formValue.element_select_option_id
          );
          this.singleSelectOption = findOption;
        }
      });
    } else {
      _.map(this.store.formValuesToCreate, formValue => {
        const findOption = _.find(this.props.element.elementSelect.elementSelectOptions, o => o.id === formValue.element_select_option_id);
        if (findOption) {
          this.multipleSelectOptions = this.multipleSelectOptions.concat(findOption);
        }
      });
      _.map(this.store.formValuesToChange, formValue => {
        if (formValue.boolean_value) {
          const findOption = _.find(
            this.props.element.elementSelect.elementSelectOptions,
            o => o.id === formValue.element_select_option_id
          );
          this.multipleSelectOptions = this.multipleSelectOptions.concat(findOption);
        }
      });
    }
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'boolean';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'boolean';
        return object;
    }
  }

  handleSingleSelect(option) {
    // Delete existing formValues
    _.map(this.props.element.formValues, formValue => {
      if (formValue.booleanValue && formValue.elementSelectOptionId !== option.id) {
        const removedFormValue = {
          id: formValue.id,
          element_select_option_id: option.id,
          boolean_value: false,
        };
        this.store.addFormValueToDelete(removedFormValue);
      }
    });
    this.store.formValuesToCreate = _.filter(this.store.formValuesToCreate, o => o.element_id != this.props.element.id);

    // We are creating a new input value here
    let newFormValue = {
      id: option.id,
      element_id: this.props.element.id,
      element_select_option_id: option.id,
      boolean_value: true,
      decimal_value: parseFloat(option.numberValue),
    };
    newFormValue = this.appendAttributes(newFormValue);
    this.store.addFormValueToCreate(newFormValue);
    this.singleSelectOption = option;

    this.props.validateInput(this.singleSelectOption);
    // console.log('formValuesToCreate: ', this.store.formValuesToCreate)
    // console.log('formValuesToChange: ', this.store.formValuesToChange)
    // console.log('formValuesToDelete: ', this.store.formValuesToDelete)
    // console.log('formValuesToCreate: ', this.store.formValuesToCreate.length)
    // console.log('formValuesToChange: ', this.store.formValuesToChange.length)
    // console.log('formValuesToDelete: ', this.store.formValuesToDelete.length)
  }

  handleMultiSelect(option) {
    const findInputValue = this.findInputValue(option);
    if (findInputValue) {
      // we are changing an input value here
      const changedFormValue = {
        id: findInputValue.id,
        element_select_option_id: option.id,
        boolean_value: true,
        number_value: option.numberValue,
      };
      this.store.addFormValueToChange(changedFormValue);
      this.multipleSelectOptions = this.multipleSelectOptions.concat(option);
    } else {
      // we are creating a new input value here
      let newFormValue = {
        id: option.id,
        element_id: this.props.element.id,
        element_select_option_id: option.id,
        boolean_value: true,
        number_value: option.numberValue,
      };
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
      this.multipleSelectOptions = this.multipleSelectOptions.concat(option);
    }
    this.sortMultipleSelectOptions();
    this.props.validateInput(this.multipleSelectOptions);
  }

  handleMultiSelectRemove(option) {
    console.log(option);
    const optionId = option.props.id;
    const newOptions = _.filter(this.multipleSelectOptions, o => o.id !== optionId);
    this.multipleSelectOptions = newOptions;
    const findInputValue = _.find(this.props.element.formValues, o => o.elementSelectOptionId === optionId && o.booleanValue);
    if (findInputValue) {
      findInputValue.booleanValue = false;
      const changedFormValue = {
        id: findInputValue.id,
        element_select_option_id: optionId,
        boolean_value: false,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      this.store.formValuesToCreate = _.filter(this.store.formValuesToCreate, o => {
        if (o.element_id === this.props.element.id) {
          if (o.element_select_option_id === optionId) {
            return false;
          }
          return true;
        }
        return true;
      });
      this.store.formValuesToChange = _.filter(this.store.formValuesToChange, o => {
        if (o.element_id === this.props.element.id) {
          if (o.element_select_option_id === optionId) {
            return false;
          }
          return true;
        }
        return true;
      });
    }
    this.sortMultipleSelectOptions();
    this.props.validateInput(this.multipleSelectOptions);
  }

  sortMultipleSelectOptions() {
    const newOptions = [];
    _.map(this.props.element.elementSelect.elementSelectOptions, option => {
      const findValue = _.find(this.multipleSelectOptions, o => o.id === option.id);
      if (findValue) {
        newOptions.push(findValue);
      }
    });
    this.multipleSelectOptions = newOptions;
  }

  addPlaceholderOptions() {
    if (!this.props.element.isRequired && this.props.element.elementSelect.optionType === 'single') {
      const placeholderOption = new ElementSelectOption(
        null,
        this.props.element.elementSelect.id,
        this.props.element.elementSelect.placeholderText,
        null,
        null,
        null
      );
      this.props.element.elementSelect.elementSelectOptions = _.filter(
        this.props.element.elementSelect.elementSelectOptions,
        o => o.name !== this.props.element.elementSelect.placeholderText
      );
      this.props.element.elementSelect.elementSelectOptions.push(placeholderOption);
    }
  }

  findInputValue(option) {
    return _.find(this.props.element.formValues, o => o.elementSelectOptionId === option.id);
  }

  handleRadioChange(e) {
    const findOption = _.find(this.props.element.elementSelect.elementSelectOptions, o => o.id === e.target.value);
    this.handleSingleSelect(findOption);
  }

  handleCheckChange(option, checked) {
    if (checked) {
      option.props = {
        id: option.id,
      };
      this.handleMultiSelectRemove(option);
    } else {
      this.handleMultiSelect(option);
    }
  }

  filterOptions(query) {
    let possibleOptions = _.filter(this.props.element.elementSelect.elementSelectOptions, o =>
      _.includes(o.name.toLowerCase(), query.toLowerCase())
    );
    // filter out selected options
    _.map(this.multipleSelectOptions, option => {
      possibleOptions = _.filter(possibleOptions, o => o.id !== option.id);
    });

    return possibleOptions;
  }

  renderSingleOption(item, details) {
    if (item.name === this.props.element.elementSelect.placeholderText) {
      if (this.singleSelectOption !== '') {
        const menuClass = classNames('popover-larger');
        return [
          <MenuDivider key={0} />,
          <MenuItem
            className={menuClass}
            key={item.id}
            onClick={details.handleClick}
            text={<span className="bp3-text-muted">{I18n.t('js.reset_selection')}</span>}
          />,
        ];
      }
    } else {
      let menuClass = classNames('popover-larger');
      if (item.name === this.singleSelectOption.name) {
        menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
      }

      return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={item.name} />;
    }
    return undefined;
  }

  renderMultipleOption(item, details) {
    let menuClass = classNames('popover-larger');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={item.name} />;
  }

  renderInputValue(option) {
    return option.name;
  }

  renderTag(option) {
    return <span id={option.id}>{_.truncate(option.name, { length: 40 })}</span>;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderNoResults() {
    return <MenuItem disabled text={I18n.t('js.no_results_found')} />;
  }

  renderRightElement() {
    return <AnchorButton className="bp3-minimal bp3-small" icon="caret-down" disabled={!this.props.element.isEditable} />;
  }

  renderDropDownSelect() {
    if (this.props.element.elementSelect.optionType === 'single') {
      return (
        <Flexbox>
          <SingleSelectOption
            resetOnSelect={true}
            filterable={false}
            disabled={!this.props.element.isEditable}
            items={this.props.element.elementSelect.elementSelectOptions}
            itemRenderer={this.renderSingleOption.bind(this)}
            onItemSelect={this.handleSingleSelect.bind(this)}
            inputValueRenderer={this.renderInputValue.bind(this)}
            inputProps={{
              rightElement: this.renderDropDown(),
            }}
            popoverProps={{
              position: 'bottom-left',
              className: '',
              popoverClassName: 'bp3-minimal',
              portalContainer: document.body,
            }}
          >
            <AnchorButton
              text={
                typeof this.singleSelectOption !== 'object'
                  ? this.props.element.elementSelect.placeholderText
                  : this.singleSelectOption.name
              }
              rightIcon="caret-down"
              disabled={!this.props.element.isEditable}
            />
          </SingleSelectOption>
        </Flexbox>
      );
    }
    const options = this.props.element.elementSelect.elementSelectOptions.slice();
    return (
      <Flexbox>
        <MultipleSelectOption
          className="max-width"
          resetOnSelect={true}
          items={options}
          itemListPredicate={this.filterOptions.bind(this)}
          selectedItems={this.multipleSelectOptions}
          tagRenderer={this.renderTag.bind(this)}
          itemRenderer={this.renderMultipleOption.bind(this)}
          onItemSelect={this.handleMultiSelect.bind(this)}
          tagInputProps={{
            disabled: !this.props.element.isEditable,
            inputProps: {
              placeholder: this.props.element.elementSelect.placeholderText,
            },
            rightElement: this.renderRightElement(),
            className: 'bp3-tag-input',
            onRemove: this.handleMultiSelectRemove.bind(this),
          }}
          popoverProps={{
            portalContainer: document.body,
            position: 'bottom-left',
            className: '',
            popoverClassName: 'bp3-minimal',
          }}
          noResults={this.renderNoResults()}
        />
      </Flexbox>
    );
  }

  renderListSelect() {
    const { element } = this.props;
    const { elementSelect } = element;
    if (elementSelect.optionType === 'single') {
      return (
        <Flexbox>
          <RadioGroup onChange={this.handleRadioChange.bind(this)} selectedValue={this.singleSelectOption.id}>
            {elementSelect.elementSelectOptions
              .filter(opt => opt.name !== elementSelect.placeholderText || this.singleSelectOption !== '')
              .map(opt =>
                opt.name !== elementSelect.placeholderText ? (
                  <Radio key={opt.id} label={opt.name} value={opt.id} disabled={!this.props.element.isEditable} />
                ) : (
                  // i.e. this.singleSelectOption !== ''
                  <AnchorButton
                    key={opt.id}
                    text={I18n.t('js.reset_selection')}
                    onClick={() => this.handleSingleSelect(opt)}
                    className="bp3-small push-10-t"
                  />
                )
              )}
          </RadioGroup>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column">
        {_.map(this.props.element.elementSelect.elementSelectOptions, option => {
          let checked = false;
          const findOption = _.find(this.multipleSelectOptions, o => o.id === option.id);
          if (findOption) {
            checked = true;
          }
          return (
            <Checkbox
              id={option.id}
              key={option.id}
              checked={checked}
              onChange={() => this.handleCheckChange(option, checked)}
              label={option.name}
              disabled={!this.props.element.isEditable}
            />
          );
        })}
      </Flexbox>
    );
  }

  renderSelect() {
    if (this.props.element.elementSelect.displayType === 'list') {
      return this.renderListSelect();
    }
    return this.renderDropDownSelect();
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          label={this.props.element.elementSelect.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          {this.renderSelect()}
        </FormGroup>
      </Flexbox>
    );
  }
}
