import { action, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import _ from 'lodash';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import Comment from '../models/CommentModel';
import User from '../models/UserModel';
import Paginator from '../models/PaginatorModel';

axiosCancel(axios, {
  debug: false, // default
});

class CommentStore {
  @observable comments = [];
  @observable paginators = [];

  @action
  createPaginator(id, headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    newPagination.id = id;
    this.paginators = _.unionBy([newPagination], this.paginators, 'id');
  }

  @action
  addComment(data) {
    return new Promise(resolve => {
      const newComment = new Comment(
        data.id,
        data.user_id,
        data.body,
        data.commentable_id,
        data.commentable_type,
        data.commentable_reference_id,
        data.commentable_reference_type,
        data.comment_count,
        data.vote_count,
        data.pinned,
        data.created_at,
        data.created_by,
        data.deleted_at,
        data.deleted_by,
        data.updated_at,
        data.updated_by
      );
      newComment.user = new User(
        data.user.id,
        data.user.email,
        data.user.first_name,
        data.user.last_name,
        data.user.full_name,
        data.user.account_id,
        data.user.human_friendly_id,
        data.user.is_current_user,
        data.user.is_account_owner,
        data.user.image_file_name,
        data.user.background_image_file_name,
        data.user.last_sign_in_at,
        data.user.locale,
        data.user.theme,
        data.user.created_at,
        data.user.updated_at,
        data.user.deleted_at,
        data.user.created_by,
        data.user.updated_by,
        data.user.deleted_by
      );
      this.comments = _.unionBy([newComment], this.comments, 'id');
      this.sortComments();
      resolve(newComment);
    });
  }

  @action
  removeComment(commentId) {
    this.comments = _.filter(this.comments, o => o.id !== commentId);
  }

  @action
  fetchComments(commentableId, commentableType, xPage, xPerPage, xOffset) {
    // console.log(`Fetching comments for ${commentableType} with id ${commentableId}`)
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/comments?commentable_id=${commentableId}&commentable_type=${commentableType}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchCommentsWithReference(commentableId, commentableType, commentableReferenceId, commentableReferenceType, xPage, xPerPage, xOffset) {
    // console.log(`Fetching comments for ${commentableType} with id ${commentableId}`)
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/comments?commentable_id=${commentableId}&commentable_type=${commentableType}&commentable_reference_id=${commentableReferenceId}&commentable_reference_type=${commentableReferenceType}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchComment(commentId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/comments/${commentId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  createComment(commentObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/comments`, commentObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeComment(commentObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/comments/${commentObj.id}`, commentObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteComment(commentId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/comments/${commentId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createCommentReaction(commentReactionObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/comment_reactions`, commentReactionObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  findPaginator(id) {
    return _.find(this.paginators, o => o.id === id);
  }

  @action
  sortComments() {
    let newComments = this.comments;
    newComments = _.orderBy(newComments, ['createdAt'], ['asc']);
    this.comments = newComments;
  }

  @action
  resetComments() {
    this.comments = [];
    this.paginators = [];
  }
}

const store = new CommentStore();
export default store;
