import React from 'react';
import Flexbox from 'flexbox-react';

export default class FormValueLengthValidator extends React.Component {
  render() {
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={1}
        justifyContent="flex-start"
        className="bp3-form-helper-text bp3-intent-danger"
      >
        {this.props.element.validateLengthMessage(this.props.input)}
      </Flexbox>
    );
  }
}
