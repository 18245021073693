/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from '../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Team from '../../models/TeamModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

axiosCancel(axios);

const TeamMultiSelect = MultiSelect.ofType(Team);

@inject('TeamsStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class TeamEditRelationshipsChildren extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      childTeamsToCreate: [],
      childTeamsToDelete: [],
      query: '',
      searchTeams: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({
      childTeamsToCreate: this.props.TeamsStore.activeTeamRelationshipsChildren,
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addTeam(data) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.setState({
      searchTeams: _.unionBy([newTeam], this.state.searchTeams, 'id'),
    });
    this.sortTeams();
  }

  sortTeams() {
    let newTeams = this.state.searchTeams;
    newTeams = _.orderBy(newTeams, ['name'], ['asc']);
    this.setState({ searchTeams: newTeams });
  }

  searchTeams(e) {
    const query = e.target.value;
    this.setState({ searchTeams: [], query });
    if (query.length > 0) {
      this.toggleLoading();
      axios
        .get(`${Cookies.get('apiEnv')}/teams?query=${query}`, {
          requestId: 'teamSearch',
        })
        .then(response => {
          _.map(response.data, team => {
            if (team.id !== this.props.TeamsStore.activeTeam.id) {
              this.addTeam(team);
            }
          });
          this.toggleLoading();
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.toggleLoading();
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            this.toggleLoading();
          }
        });
    }
  }

  renderTeam(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.name}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-graph push-5-r" /> <span> {I18n.t('js.no_teams_found')} </span>
          </span>
        }
      />
    );
  }

  renderTag(team) {
    return <span id={team.id}>{team.name}</span>;
  }

  handleTeamSelect(team) {
    this.setState({
      childTeamsToCreate: _.concat(this.state.childTeamsToCreate.slice(), [team]),
    });
    // Check if team exists in list to be deleted. If so, just remove it from that list.
    this.props.TeamsStore.childTeamsToDelete = _.filter(this.props.TeamsStore.childTeamsToDelete, o => o.id !== team.id);
    // Check if team exists in existing list. If not, add it to the list to be created in the store.
    const findTeam = _.find(this.props.TeamsStore.activeTeamRelationshipsChildren, o => o.id === team.id);
    if (!findTeam) {
      this.props.TeamsStore.childTeamsToCreate = _.unionBy([team], this.props.TeamsStore.childTeamsToCreate, 'id');
    }
    this.handlePopoverClose();
  }

  handlePopoverClose() {
    this.setState({ searchTeams: [], query: '' });
  }

  handleTeamRemove(team) {
    const teamId = team.props.id;
    const newTeams = _.filter(this.state.childTeamsToCreate.slice(), o => o.id !== teamId);
    this.setState({ childTeamsToCreate: newTeams });
    // Check if team exists in list to be added. If so, just remove it from that list.
    this.props.TeamsStore.childTeamsToCreate = _.filter(this.props.TeamsStore.childTeamsToCreate, o => o.id !== teamId);
    // Check if team exists in existing list. If it does, add it to the list to be deleted in the store.
    const findTeam = _.find(this.props.TeamsStore.activeTeamRelationshipsChildren, o => o.id === teamId);
    if (findTeam) {
      this.props.TeamsStore.childTeamsToDelete = _.unionBy([findTeam], this.props.TeamsStore.childTeamsToDelete, 'id');
    }
  }

  filterTeam() {
    let possibleTeams = _.filter(this.state.searchTeams, o => o.id !== this.props.TeamsStore.activeTeam.id);

    // filter out selected teams
    _.map(this.state.childTeamsToCreate, teamToCreate => {
      possibleTeams = _.filter(possibleTeams, o => o.id !== teamToCreate.id);
    });

    return possibleTeams;
  }

  renderRightElement() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    return (
      <TeamMultiSelect
        className="max-width"
        resetOnSelect={true}
        items={this.state.searchTeams}
        itemListPredicate={this.filterTeam.bind(this)}
        selectedItems={this.state.childTeamsToCreate}
        tagRenderer={this.renderTag.bind(this)}
        itemRenderer={this.renderTeam.bind(this)}
        onItemSelect={this.handleTeamSelect.bind(this)}
        tagInputProps={{
          inputProps: {
            placeholder: I18n.t('js.search_for_a_team'),
            onChange: this.searchTeams.bind(this),
          },
          rightElement: this.renderRightElement(),
          className: 'bp3-tag-input',
          onRemove: this.handleTeamRemove.bind(this),
        }}
        popoverProps={{
          usePortal: false,
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
