/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
  Popover,
  PopoverInteractionKind,
  Tooltip,
} from '@blueprintjs/core';
import classNames from 'classnames';
import StoreBrowser from '../shared/StoreBrowser';
import NewWidget from '../widgets/NewWidget';
import _ from 'lodash';
import Utilities from '../../utils/Utilities';

@inject('AppStore', 'WorkspaceStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppsNewApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isStoreDialogOpen: false,
      isAdding: false,
      isBlurred: [],
    };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleStoreDialog() {
    this.setState({
      isStoreDialogOpen: !this.state.isStoreDialogOpen,
    });
  }

  toggleAdding() {
    this.setState({ isAdding: !this.state.isAdding });
  }

  handleFilterChange(filter) {
    this.props.AppStore.activeFilter = filter;
  }

  handleAddFolder() {
    this.toggleAdding();
    const folderObj = {
      workspace_id: this.props.WorkspaceStore.activeWorkspace.id,
      name: I18n.t('js.home'),
    };
    this.props.WorkspaceStore.createWorkspaceFolder(folderObj)
      .then(response => {
        this.props.WorkspaceStore.activeFolderId = response.data.id;
        this.toggleAdding();
      })
      .catch(() => {
        this.toggleAdding();
      });
  }

  handleSubmit(e) {
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    e.preventDefault();
    const appObj = {};
    appObj.workspace_id = this.props.AppStore.activeWorkspace.id;
    appObj.name = this.props.AppStore.newApp.name;
    appObj.description = this.props.AppStore.newApp.description;
    appObj.item = this.props.AppStore.newApp.item;
    appObj.item_plural = this.props.AppStore.newApp.itemPlural;
    appObj.icon = this.props.AppStore.activeWorkspace.icon;
    appObj.color = this.props.AppStore.activeWorkspace.color;
    appObj.permission_type = this.props.AppStore.activeWorkspace.permissionType;
    appObj.status = 'draft';
    if (this.props.WorkspaceStore.activeFolderId !== '') {
      appObj.workspace_folder_id = this.props.WorkspaceStore.activeFolderId;
    }
    this.props.AppStore.createApp(appObj)
      .then(response => {
        this.toggleDialog();
        this.toggleAdding();
        this.props.AppStore.resetStartingAttributes();
        Utilities.navigate(`/apps/${response.data.id}?tab=template`);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.AppStore.updateNewApp(e.target.value, e.target.name);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.AppStore.newApp[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.AppStore.newApp[`${field}Error`]}</div>;
    }
    return undefined;
  }

  renderDialog() {
    const { activeWorkspace, newApp } = this.props.AppStore;
    if (activeWorkspace.userPermissionLevel.name === 'owner') {
      return (
        <Dialog
          portalContainer={document.body}
          icon="add"
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog.bind(this)}
          canOutsideClickClose={false}
          inline={false}
          title={I18n.t('js.create_a_new_app')}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="bp3-dialog-body">
              <Flexbox flexDirection="column">
                <p>{I18n.t('js.new_apps_description')}</p>
              </Flexbox>
              <Flexbox flexDirection="column">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !newApp.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.app_name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !newApp.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_a_name_for_your_app')}
                        name="name"
                        value={newApp.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'description') && !newApp.descriptionValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="description">
                    {I18n.t('js.app_description')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'description') && !newApp.descriptionValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <textarea
                        className="bp3-input bp3-textarea bp3-fill"
                        type="textarea"
                        placeholder={I18n.t('js.enter_a_description_for_your_app')}
                        name="description"
                        value={newApp.description}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('description')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column">
                <h5 className={Classes.HEADING}>{I18n.t('js.item_details')}</h5>
                <p className="">{I18n.t('js.item_details_description')}</p>
              </Flexbox>
              <Flexbox flexDirection="row">
                <Flexbox flexGrow={1} marginRight="10px">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'item') && !newApp.itemValid
                        ? classNames('bp3-form-group max-width bp3-intent-danger')
                        : classNames('bp3-form-group max-width')
                    }
                  >
                    <label className="bp3-label" htmlFor="item">
                      {I18n.t('js.item_name')}
                      <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                    </label>
                    <div className="bp3-form-content">
                      <div
                        className={
                          _.includes(this.state.isBlurred, 'item') && !newApp.itemValid
                            ? classNames('bp3-input-group bp3-intent-danger')
                            : classNames('bp3-input-group')
                        }
                      >
                        <input
                          autoComplete="off"
                          className="bp3-input bp3-fill"
                          type="text"
                          placeholder={I18n.t('js.enter_item_name')}
                          name="item"
                          value={newApp.item}
                          onChange={this.handleInputChange.bind(this)}
                          onBlur={this.handleBlurChange.bind(this)}
                          dir="auto"
                        />
                      </div>
                      {this.renderErrors('item')}
                    </div>
                  </div>
                </Flexbox>
                <Flexbox flexGrow={1}>
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'itemPlural') && !newApp.itemValid
                        ? classNames('bp3-form-group max-width bp3-intent-danger')
                        : classNames('bp3-form-group max-width')
                    }
                  >
                    <label className="bp3-label" htmlFor="itemPlural">
                      {I18n.t('js.item_plural_name')}
                      <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                    </label>
                    <div className="bp3-form-content">
                      <div
                        className={
                          _.includes(this.state.isBlurred, 'itemPlural') && !newApp.itemValid
                            ? classNames('bp3-input-group bp3-intent-danger')
                            : classNames('bp3-input-group')
                        }
                      >
                        <input
                          autoComplete="off"
                          className="bp3-input bp3-fill"
                          type="text"
                          placeholder={I18n.t('js.enter_item_name_plural')}
                          name="itemPlural"
                          value={newApp.itemPlural}
                          onChange={this.handleInputChange.bind(this)}
                          onBlur={this.handleBlurChange.bind(this)}
                          dir="auto"
                        />
                      </div>
                      {this.renderErrors('itemPlural')}
                    </div>
                  </div>
                </Flexbox>
              </Flexbox>
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.handleSubmit.bind(this)}
                  disabled={!this.props.AppStore.newAppFormValid}
                  text={I18n.t('js.create_app')}
                  loading={this.state.isSubmitting}
                />
              </div>
            </div>
          </form>
        </Dialog>
      );
    }
    return undefined;
  }

  renderFilterMenu() {
    return (
      <Menu>
        <MenuItem icon="share" text={I18n.t('js.published_apps')} onClick={() => this.handleFilterChange('published')} />
        <MenuItem icon="annotation" text={I18n.t('js.draft_apps')} onClick={() => this.handleFilterChange('draft')} />
        <MenuItem icon="disable" text={I18n.t('js.restricted_apps')} onClick={() => this.handleFilterChange('restricted')} />
        <MenuDivider />
        <MenuItem icon="applications" text={I18n.t('js.all_apps')} onClick={() => this.handleFilterChange('all')} />
      </Menu>
    );
  }

  renderFilter() {
    if (!this.props.WorkspaceStore.activeWorkspace.isStoreTemplate) {
      let filterText = I18n.t('js.all_apps');
      if (this.props.AppStore.activeFilter === 'published') {
        filterText = I18n.t('js.published_apps');
      }
      if (this.props.AppStore.activeFilter === 'draft') {
        filterText = I18n.t('js.draft_apps');
      }
      if (this.props.AppStore.activeFilter === 'restricted') {
        filterText = I18n.t('js.restricted_apps');
      }

      return (
        <Popover
          portalContainer={document.body}
          content={this.renderFilterMenu()}
          position="right"
          interactionKind={PopoverInteractionKind.CLICK}
        >
          <AnchorButton outlined={false} icon="filter-list" className="" text={filterText} />
        </Popover>
      );
    }
    return undefined;
  }

  renderAddFolder() {
    if (!this.props.WorkspaceStore.hasFolders) {
      return (
        <Tooltip
          portalContainer={document.body}
          content={I18n.t('js.only_workspace_owners_can_create_folders')}
          disabled={this.props.AppStore.activeWorkspace.userPermissionLevel.name === 'owner'}
          position="right"
        >
          <AnchorButton
            icon="folder-open"
            outlined={false}
            className="push-10-l"
            text={I18n.t('js.use_folders')}
            disabled={this.props.AppStore.activeWorkspace.userPermissionLevel.name !== 'owner'}
            onClick={this.handleAddFolder.bind(this)}
          />
        </Tooltip>
      );
    }
    return undefined;
  }

  renderNewWidget() {
    if (!this.props.WorkspaceStore.hasFolders) {
      return (
        <Flexbox marginLeft="10px">
          <NewWidget
            editable={this.props.AppStore.activeWorkspace.userPermissionLevel.name === 'owner'}
            widgetableType="Workspace"
            widgetableId={this.props.AppStore.activeWorkspace.id}
          />
        </Flexbox>
      );
    } else {
      if (this.props.WorkspaceStore.activeFolder.id != 9999) {
        return (
          <Flexbox marginLeft="10px">
            <NewWidget
              editable={this.props.AppStore.activeWorkspace.userPermissionLevel.name === 'owner'}
              widgetableType="WorkspaceFolder"
              widgetableId={this.props.WorkspaceStore.activeFolder.id}
            />
          </Flexbox>
        );
      }
    }
  }

  renderNewWorkspaceMenu() {
    return (
      <Menu>
        <MenuItem icon="new-text-box" text={`${I18n.t('js.blank_template')}...`} onClick={() => this.toggleDialog()} />
        <MenuItem icon="cloud-download" text={`${I18n.t('js.store_template')}...`} onClick={() => this.toggleStoreDialog()} />
      </Menu>
    );
  }

  render() {
    const { activeWorkspace, newApp } = this.props.AppStore;
    return (
      <Flexbox flexGrow={1} flexDirection="row">
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox flexDirection="row" flexGrow={1}>
            <Popover
              content={this.renderNewWorkspaceMenu()}
              position={Position.BOTTOM}
              portalContainer={document.body}
              disabled={activeWorkspace.userPermissionLevel.name != 'owner'}
            >
              <Button
                outlined={false}
                text={I18n.t('js.create_a_new_app')}
                icon="application"
                rightIcon="caret-down"
                className="push-10-r"
                intent={Intent.PRIMARY}
                disabled={activeWorkspace.userPermissionLevel.name != 'owner'}
              />
            </Popover>
            <StoreBrowser isOpen={this.state.isStoreDialogOpen} toggleDialog={this.toggleStoreDialog.bind(this)} type="App" />
            {this.renderFilter()}
            {this.renderNewWidget()}
            {this.renderAddFolder()}
          </Flexbox>
          {this.renderDialog()}
        </Flexbox>
      </Flexbox>
    );
  }
}
