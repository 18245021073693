/*
 *
 * NotificationHeaderView.js
 *
 * This view is the little bell & accompanying notification
 * count.
 *
 * @flow
 */

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Intent, Popover, PopoverInteractionKind, Position, Icon, Tag } from '@blueprintjs/core';
import NotificationHeaderList from '../notifications/NotificationHeaderList';

const NotificationCount = ({ unreadCount, animated }) => (
  <Tag intent={Intent.DANGER} className={animated && 'animated bounce bp3-notification-unread'} round={true}>
    <strong>{unreadCount}</strong>
  </Tag>
);

type NotificationHeaderViewProps = {
  NotificationStore: any,
};

@inject('NotificationStore', 'CurrentUserStore')
@observer
class NotificationHeaderView extends React.Component<NotificationHeaderViewProps> {
  componentDidMount() {
    this.props.NotificationStore.setUpActionCable();
    const { xPage, xPerPage, xOffset } = this.props.NotificationStore.pagination;
    this.props.NotificationStore.fetchNotifications(xPage, xPerPage, xOffset);
  }

  render() {
    const { unreadCount, notifications } = this.props.NotificationStore;
    const PopoverContent = <NotificationHeaderList notifications={notifications} unreadCount={unreadCount} />;
    return (
      <Popover
        portalContainer={document.body}
        content={PopoverContent}
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.BOTTOM_RIGHT}
        minimal={true}
        boundary="viewport"
      >
        <Button minimal className="push-10-l">
          <Icon icon="notifications" />
          {unreadCount > 0 && <NotificationCount unreadCount={unreadCount} animated={this.props.NotificationStore.playSound} />}
        </Button>
      </Popover>
    );
  }
}

export default NotificationHeaderView;
