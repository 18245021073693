import * as React from 'react';
import HeaderNav from '../index';

const evermindLogo =
  'https://res.cloudinary.com/dkctgehrc/image/upload/v1/accounts/a59732c1-98fa-4b39-82ef-379595e077c5/a5731e3375f.png';
const james =
  'https://res.cloudinary.com/dkctgehrc/image/upload/t_profile_image/v1/users/9225ebaf-210a-40bf-9b13-674d8d921e07/ce5b87082f7.png';

const mockCurrentUserStore = {
  // an instance of UserModel
  currentUser: {
    imageFileName: james,
    accountId: '123',
  },
  // an instance of AuthorizedAccount
  activeAccount: {
    accountImage: evermindLogo,
    accountName: 'Evermind',
  },
  userSignedIn: false,
  userIsAccountOwner: false,
};

describe('Tests for <HeaderNav/>', () => {
  it('renders a link to the dashboard', () => {
    const wrapper = mount(
      <HeaderNav
        controller="secured"
        action="dashboard"
        CurrentUserStore={mockCurrentUserStore}
      />
    );
    expect(wrapper.find('[href="/dashboard"]').exists()).toBe(true);
  });
});
