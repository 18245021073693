/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { AnchorButton, Button, Classes, Collapse, HTMLSelect, Intent, Drawer, Position } from '@blueprintjs/core';
import classNames from 'classnames';
import ElementIdSelector from '../../shared/ElementIdSelector';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementHyperlink extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = {
      isBlurred: [],
      isLoading: false,
      drawerOpen: false,
    };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
    };
    const elementHyperlinkObj = {
      id: this.store.activeElement.elementHyperlink.id,
      hyperlink: this.store.activeElement.elementHyperlink.hyperlink,
      title: this.store.activeElement.elementHyperlink.title,
      display_as: this.store.activeElement.elementHyperlink.displayAs,
    };
    this.props.TemplateActions.changeSubElement(elementHyperlinkObj, 'element_hyperlinks')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleDisplayChange(e) {
    this.store.updateActiveElement(e.target.value, 'displayAs');
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementHyperlink[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementHyperlink[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-link push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.hyperlink_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'hyperlink') && !this.store.activeElement.elementHyperlink.hyperlinkValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="hyperlink">
                    {I18n.t('js.hyperlink_full_url')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'hyperlink') && !this.store.activeElement.elementHyperlink.hyperlinkValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.insert_url_here')}
                        name="hyperlink"
                        value={this.store.activeElement.elementHyperlink.hyperlink}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('hyperlink')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'title') && !this.store.activeElement.elementHyperlink.titleValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="title">
                    {I18n.t('js.hyperlink_title')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'title') && !this.store.activeElement.elementHyperlink.titleValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.click_here')}
                        name="title"
                        value={this.store.activeElement.elementHyperlink.title}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('title')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={1}>
              <div className="bp3-form-group">
                <label className="bp3-label">
                  {I18n.t('js.display_hyperlink_as')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div className="bp3-select">
                    <HTMLSelect
                      name="displaySelect"
                      onChange={this.handleDisplayChange.bind(this)}
                      defaultValue={this.store.activeElement.elementHyperlink.displayAs}
                    >
                      <option value="text">{I18n.t('js.text')}</option>
                      <option value="button">{I18n.t('js.button')}</option>
                      <option value="iframe">iFrame</option>
                    </HTMLSelect>
                  </div>
                </div>
              </div>
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
                disabled={!this.store.activeElement.elementHyperlink.elementValid()}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderIframe(hyperlink) {
    const iframe = `<iframe src="${hyperlink}" width="100%" height="${window.innerHeight}px"></iframe>`;

    return {
      __html: iframe,
    };
  }

  renderDrawer(hyperlink, title, displayAs) {
    return (
      <Drawer
        portalContainer={document.body}
        position={Position.RIGHT}
        size={Drawer.SIZE_LARGE}
        onClose={this.toggleDrawer.bind(this)}
        isOpen={this.state.drawerOpen}
        title={title}
      >
        <Flexbox className={Classes.DRAWER_BODY} flexDirection="column">
          <div dangerouslySetInnerHTML={this.renderIframe(hyperlink)} />
        </Flexbox>
      </Drawer>
    );
  }

  renderHyperlink(hyperlink, title, displayAs) {
    if (displayAs === 'text') {
      return (
        <Flexbox justifyContent="center">
          <span className="bp3-icon bp3-icon-globe push-10-r push-5-t bp3-text-muted" />
          <p>
            <a rel="noopener noreferrer" href={`${hyperlink}`} target="_blank">
              {title}
            </a>
          </p>
        </Flexbox>
      );
    }
    if (displayAs === 'iframe') {
      return (
        <Flexbox>
          <Button icon="add-column-right" text={title} onClick={this.toggleDrawer.bind(this)} />
          {this.renderDrawer(
            this.props.element.elementHyperlink.hyperlink,
            this.props.element.elementHyperlink.title,
            this.props.element.elementHyperlink.displayAs
          )}
        </Flexbox>
      );
    }
    return (
      <AnchorButton
        onClick={() => {
          window.location = hyperlink;
        }}
      >
        {title}
      </AnchorButton>
    );
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <Flexbox paddingTop="10px" paddingBottom="10px">
          {this.renderHyperlink(
            this.store.activeElement.elementHyperlink.hyperlink,
            this.store.activeElement.elementHyperlink.title,
            this.store.activeElement.elementHyperlink.displayAs
          )}
        </Flexbox>
      );
    }
    return (
      <Flexbox paddingTop="10px" paddingBottom="10px">
        {this.renderHyperlink(
          this.props.element.elementHyperlink.hyperlink,
          this.props.element.elementHyperlink.title,
          this.props.element.elementHyperlink.displayAs
        )}
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
