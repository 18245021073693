/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import moment from 'moment';

export default class UserRelationship {
  @observable
  id;
  @observable
  userId;
  @observable
  counterpartUserId;
  @observable
  relationshipId;
  @observable
  relationshipName;
  @observable
  relationshipCounterpartName;

  constructor(
    id,
    userId,
    user,
    counterpartUserId,
    counterpartUser,
    relationshipId,
    relationshipName,
    relationshipCounterpartName,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    deletedAt,
    deletedBy
  ) {
    this.id = id || Utilities.makeId();
    this.userId = userId || '';
    this.user = user || '';
    this.counterpartUserId = counterpartUserId || '';
    this.counterpartUser = counterpartUser || '';
    this.relationshipId = relationshipId || '';
    this.relationshipName = relationshipName || '';
    this.relationshipCounterpartName = relationshipCounterpartName || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
