// @flow
import * as React from 'react';
import { AnchorButton, Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Flexbox from 'flexbox-react';

export type MiniFeatureProps = {
  description: string,
  icon: IconNames,
  title: string,
};

const MiniFeatureIconStyle = {
  margin: '2px 8px 0 0',
  color: '#db2c6f',
};

const MiniFeatureTitleStyle = {
  marginTop: '0',
};

const MiniFeature = ({
  description,
  icon,
  title,
}: MiniFeatureProps) => (
  <Flexbox
    flexDirection="column"
    paddingTop="50px"
    paddingBottom="50px"
    width="100%"
  >
    <Flexbox justifyContent="center">
      <Icon icon={icon} style={MiniFeatureIconStyle} />
      <h1 className="feature__title" style={MiniFeatureTitleStyle}>
        {title}
      </h1>
    </Flexbox>
    <Flexbox
      className="mini-feature__text-area"
      justifyContent="center"
      marginTop="20px"
    >
      <p className={`${Classes.RUNNING_TEXT}`}>{description}</p>
    </Flexbox>
    <Flexbox justifyContent="center" marginTop="20px">
      <AnchorButton
        // $FlowFixMe
        text={I18n.t('js.request_a_demo')}
        className="bp3-intent-primary bp3-large"
        icon="desktop"
        href="/request-a-demo"
      />
    </Flexbox>
  </Flexbox>
);

export default MiniFeature;
