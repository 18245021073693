import { action, computed, observable } from 'mobx';
import StoreCategory from '../models/StoreCategoryModel';
import AccountDomain from '../models/AccountDomainModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import _ from 'lodash';

class AdminStore {
  @observable
  activeTab = 'accounts';
  @observable
  storeCategories = [];
  @observable
  accountDomains = [];
  @observable
  newStoreCategory = new StoreCategory();
  @observable
  newAccountDomain = new AccountDomain();
  @observable
  activeItem = null;
  @observable
  activeItemType = null;
  @observable
  news = [];
  @observable
  storeCategoryColumns = _.clone(ColumnDefinitions.storeCategoryColumns);
  @observable
  accountDomainColumns = _.clone(ColumnDefinitions.accountDomainColumns);

  @computed
  get newStoreCategoryValid() {
    return this.newStoreCategory.titleValid;
  }

  @computed
  get newAccountDomainValid() {
    return this.newAccountDomain.domainValid && this.newAccountDomain.versionValid;
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  setActiveItem(item, type) {
    this.activeItem = _.clone(item);
    this.activeItemType = type;
  }

  @action
  updateActiveItem(data, attribute) {
    this.activeItem[attribute] = data;
  }

  @action
  updateNewStoreCategory(data, attribute) {
    this.newStoreCategory[attribute] = data;
  }

  @action
  updateNewAccountDomain(data, attribute) {
    this.newAccountDomain[attribute] = data;
  }

  @action
  addNews(news) {
    this.news = _.unionBy([news], this.news, 'sys.id');
    this.sortNews();
  }

  @action
  addStoreCategory(data) {
    const newStoreCategory = new StoreCategory(
      data.id,
      data.title,
      data.comment_count,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.storeCategories = _.unionBy([newStoreCategory], this.storeCategories, 'id');
    this.sortStoreCategory();
  }

  @action
  addAccountDomain(data) {
    const newAccountDomain = new AccountDomain(data.id, data.domain, data.version);
    this.accountDomains = _.unionBy([newAccountDomain], this.accountDomains, 'id');
    this.sortAccountDomains();
  }

  @action
  removeStoreCategory(id) {
    this.storeCategories = _.filter(this.storeCategories, o => o.id !== id);
  }

  @action
  removeAccountDomain(id) {
    this.accountDomains = _.filter(this.accountDomains, o => o.id !== id);
  }

  @action
  resetNewStoreCategory() {
    this.newStoreCategory = new StoreCategory();
  }

  @action
  resetNewAccountDomain() {
    this.newAccountDomain = new AccountDomain();
  }

  @action
  sortStoreCategory() {
    let newStoreCategory = this.storeCategories;
    newStoreCategory = _.orderBy(newStoreCategory, ['title'], ['desc']);
    this.storeCategories = newStoreCategory;
  }

  @action
  sortAccountDomains() {
    let newAccountDomains = this.accountDomains;
    newAccountDomains = _.orderBy(newAccountDomains, ['domain'], ['asc']);
    this.accountDomains = newAccountDomains;
  }

  @action
  sortNews() {
    let newNews = this.news;
    newNews = _.orderBy(newNews, ['fields.publishedOn'], ['desc']);
    this.news = newNews;
  }
}

const store = new AdminStore();
export default store;
