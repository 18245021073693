/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Classes, NonIdealState, Collapse, Button, Alert, Intent, Tooltip, Position } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import ElementTag from '../shared/ElementTag';
import UserProfileElement from './UserProfileElement';
import _ from 'lodash';

@inject('UserProfileTemplateStore')
@observer
export default class UserProfileSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRemoveExposedAlert: false,
    };
  }

  @computed
  get isCollapsed() {
    if (_.find(this.props.UserProfileTemplateStore.collapsedSections, o => o === this.props.section.id)) {
      return true;
    }
    return false;
  }

  @computed
  get isSectionExposed() {
    if (this.props.section.optional) {
      return this.props.UserProfileTemplateStore.exposedSections.includes(this.props.section.id);
    }
    return true;
  }

  @computed
  get hasData() {
    let hasData = false;
    _.map(this.props.section.elements, element => {
      if (element.formValues.length > 0) {
        hasData = true;
      }
    });
    return hasData;
  }

  toggleCollapse() {
    this.props.UserProfileTemplateStore.toggleSectionCollapse(this.props.section.id);
  }

  toggleRemovalDialog() {
    this.setState({ showRemoveExposedAlert: !this.state.showRemoveExposedAlert });
  }

  handleRemoveExposedOption() {
    this.props.UserProfileTemplateStore.removeExposedSection(this.props.section.id);
    this.toggleRemovalDialog();
  }

  renderPrivacyTag(section) {
    if (section.privateView) {
      return <ElementTag tagType="private_section" tagIcon="eye-on" tagTooltip={I18n.t('js.private_section_description')} />;
    }
  }

  renderProtectedTag(section) {
    if (section.protectedView) {
      return <ElementTag tagType="protected_section" tagIcon="lock" tagTooltip={I18n.t('js.protected_section_description')} />;
    }
  }

  renderAccountOwnerOnlyTag(section) {
    if (section.accountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only_section"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_section_description')}
        />
      );
    }
  }

  renderSection(section) {
    if (section.elements.length === 0) {
      return (
        <Flexbox flexDirection="column" className="template-area" marginTop="20px" marginBottom="20px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_elements_in_this_section')}
            icon="multi-select"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {_.map(section.elements, element => (
          <UserProfileElement
            key={element.id}
            element={element}
            sectionPrivateView={section.privateView}
            sectionProtectedView={section.protectedView}
            sectionAccountOwnerOnlyView={section.accountOwnerOnlyView}
            sectionExposed={this.isSectionExposed}
          />
        ))}
      </Flexbox>
    );
  }

  render() {
    const { section } = this.props;
    let cardClass = classNames('bp3-card bp3-card-no-padding');
    let sectionClass = classNames('template-section-header');
    let textClass = classNames('bp3-text-muted push-10-r');
    if (section.privateView) {
      sectionClass = classNames(sectionClass, 'template-section-private');
    }
    if (this.props.section.optional) {
      cardClass = classNames(cardClass, 'optional-section');
    }
    if (!this.props.UserProfileTemplateStore.isEditingProfile && this.props.section.optional && !this.hasData) {
      return null;
    }
    if (this.props.UserProfileTemplateStore.isEditingProfile && this.props.section.optional) {
      if (!this.isSectionExposed) {
        return (
          <Flexbox marginTop="10px">
            <Button
              icon="add"
              text={I18n.t('js.add_section_with_title', { title: this.props.section.title })}
              onClick={() => this.props.UserProfileTemplateStore.exposeSection(this.props.section.id)}
            ></Button>
          </Flexbox>
        );
      }
    }
    return (
      <Flexbox key={section.id} className={cardClass} flexDirection="column" marginTop="20px">
        <Flexbox
          flexGrow={1}
          className={sectionClass}
          paddingLeft="30px"
          paddingRight="30px"
          paddingTop="10px"
          paddingBottom="10px"
          maxHeight="50px"
        >
          <Flexbox flexGrow={1} paddingTop="10px">
            <h4 className={`${Classes.HEADING} push-10-r`}>{section.title}</h4>
            {this.renderPrivacyTag(section)}
            {this.renderProtectedTag(section)}
            {this.renderAccountOwnerOnlyTag(section)}
          </Flexbox>
          <Flexbox flexGrow={0} flexDirection="row" alignItems="center" justifyContent="center">
            <small className={textClass}>
              {I18n.t('js.item_section_x_of_y', {
                x: this.props.UserProfileTemplateStore.visibleSections.indexOf(section) + 1,
                y: this.props.UserProfileTemplateStore.visibleSections.length,
              })}
            </small>
            <Flexbox flexDirection="row" justifyContent="center">
              <Tooltip
                content={this.isCollapsed ? I18n.t('js.expand') : I18n.t('js.collapse')}
                portalContainer={document.body}
                position={Position.TOP}
              >
                <Button icon={this.isCollapsed ? 'expand-all' : 'collapse-all'} minimal={true} onClick={this.toggleCollapse.bind(this)} />
              </Tooltip>
            </Flexbox>
            {this.props.section.optional && this.props.UserProfileTemplateStore.isEditingProfile ? (
              <Flexbox flexDirection="row" justifyContent="center">
                <Tooltip
                  content={I18n.t('js.remove_section_with_title', { title: this.props.section.title })}
                  portalContainer={document.body}
                  position={Position.TOP}
                >
                  <Button icon="trash" minimal={true} onClick={this.toggleRemovalDialog.bind(this)}></Button>
                </Tooltip>
                <Alert
                  portalContainer={document.body}
                  isOpen={this.state.showRemoveExposedAlert}
                  cancelButtonText={I18n.t('js.cancel')}
                  onCancel={() => this.toggleRemovalDialog()}
                  confirmButtonText={I18n.t('js.remove')}
                  onConfirm={() => this.handleRemoveExposedOption()}
                  intent={Intent.DANGER}
                >
                  <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
                  {I18n.t('js.are_you_sure_you_want_to_remove_section_with_title', {
                    title: this.props.section.title,
                  })}
                </Alert>
              </Flexbox>
            ) : null}
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1} className="user-profile-section">
          <Collapse isOpen={!this.isCollapsed} keepChildrenMounted={true}>
            {this.renderSection(section)}
          </Collapse>
        </Flexbox>
      </Flexbox>
    );
  }
}
