import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, HTMLSelect, Button, Popover, Classes, Intent, NumericInput } from '@blueprintjs/core';
import ColorSelector from '../../shared/ColorSelector';
import Utilities from '../../../utils/Utilities';
import Flexbox from 'flexbox-react';
import { NonIdealState } from '@blueprintjs/core';
import nanoid from 'nanoid';

@inject('AppStore', 'DataViewStore', 'ToastStore')
@observer
export default class ChartRadarConfiguration extends React.Component {
  handleAttrChange(e) {
    const value = e.target.value;
    const attribute = e.target.name;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attribute] = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleNumericChange(value, name) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[name] = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleToggleBool(attr) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attr] = !newDataConfig[attr];
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleDatasetValChange(e, dataset) {
    const { activeChart, displayedTableData } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    dataset[e.target.name] = e.target.value;

    if (e.target.name === 'elementId') {
      const findElement = _.find(displayedTableData[0], o => o.column_id === e.target.value);
      if (findElement) {
        dataset['name'] = findElement.column_name;
      }
    }
    newDataConfig.datasets = _.merge(newDataConfig.datasets, dataset);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleAddDataset() {
    const newDataset = {
      id: nanoid(),
      name: I18n.t('js.current_owner'),
      elementId: 'item_attributes#current_owner',
      fill: true,
    };
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.datasets.push(newDataset);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  removeDataset(dataset) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.datasets = _.filter(newDataConfig.datasets, o => o.id != dataset.id);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  renderDataset(dataset) {
    const { activeChart } = this.props.DataViewStore;
    return (
      <Flexbox flexDirection="column" style={{ border: '1px dashed lightgray' }} padding="10px" marginBottom="10px">
        <Flexbox flexDirection="column" flexGrow={1}>
          <div className="bp3-form-group">
            <label className="bp3-label" htmlFor="name">
              {I18n.t('js.name')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <div className="bp3-form-content">
              <div className="bp3-input-group">
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="text"
                  name="name"
                  value={dataset.name}
                  onChange={e => this.handleDatasetValChange(e, dataset)}
                  dir="auto"
                />
              </div>
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="column" marginRight="20px">
          <label className="bp3-label" htmlFor="elementId">
            {I18n.t('js.select_data_element')}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <div className="bp3-form-content">
            <div className="bp3-input-group">
              <HTMLSelect
                className="push-10-r"
                name="elementId"
                onChange={e => this.handleDatasetValChange(e, dataset)}
                value={dataset.elementId}
              >
                {_.map(this.props.DataViewStore.availableElements, element => {
                  return (
                    <option key={element.id} id={element.id} value={element.id}>
                      {element.name}
                    </option>
                  );
                })}
              </HTMLSelect>
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="row" justifyContent="space-between" marginTop="20px">
          <Flexbox flexDirection="column"></Flexbox>
          <Button icon="cross" text={I18n.t('js.remove_dataset')} onClick={() => this.removeDataset(dataset)} />
        </Flexbox>
      </Flexbox>
    );
  }

  renderNoDatasets() {
    const { activeChart } = this.props.DataViewStore;
    const { datasets } = activeChart.dataConfig;
    if (datasets.length === 0) {
      return (
        <Flexbox flexDirection="column" style={{ border: '1px dashed lightgray' }} padding="20px" marginBottom="10px">
          <NonIdealState
            title={I18n.t('js.no_datasets')}
            description={I18n.t('js.click_the_button_below_to_add_a_dataset')}
            icon="bring-data"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
  }

  render() {
    const { activeChart, availableNumberElements } = this.props.DataViewStore;
    return (
      <Flexbox flexDirection="column" marginTop="20px">
        <Flexbox flexDirection="column" marginRight="20px">
          <label className="bp3-label" htmlFor="groupByElementId">
            {I18n.t('js.group_data_by')}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <div className="bp3-form-content">
            <div className="bp3-input-group">
              <HTMLSelect
                className="push-10-r"
                name="groupByElementId"
                onChange={this.handleAttrChange.bind(this)}
                value={activeChart.dataConfig.groupByElementId}
              >
                {_.map(this.props.DataViewStore.availableElements, element => {
                  return (
                    <option key={element.id} id={element.id} value={element.id}>
                      {element.name}
                    </option>
                  );
                })}
              </HTMLSelect>
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1} marginTop="20px">
          <Flexbox flexDirection="column" marginRight="20px">
            <label className="bp3-label" htmlFor="suggestedMin">
              {I18n.t('js.suggested_minimum')}
            </label>
            <div className="bp3-form-content">
              <NumericInput
                allowNumericCharactersOnly={true}
                name="suggestedMin"
                value={activeChart.dataConfig.suggestedMin}
                onValueChange={val => this.handleNumericChange(val, 'suggestedMin')}
              />
            </div>
          </Flexbox>
          <Flexbox flexDirection="column" marginRight="20px">
            <label className="bp3-label" htmlFor="suggestedMax">
              {I18n.t('js.suggested_maximum')}
            </label>
            <div className="bp3-form-content">
              <NumericInput
                allowNumericCharactersOnly={true}
                name="suggestedMax"
                value={activeChart.dataConfig.suggestedMax}
                onValueChange={val => this.handleNumericChange(val, 'suggestedMax')}
              />
            </div>
          </Flexbox>
        </Flexbox>
        <h3>{I18n.t('js.datasets')}</h3>
        {_.map(activeChart.dataConfig.datasets, dataset => {
          return (
            <Flexbox flexDirection="column" key={dataset.id}>
              {this.renderDataset(dataset)}
            </Flexbox>
          );
        })}
        {this.renderNoDatasets()}
        <Flexbox>
          <Button text={I18n.t('js.add_dataset')} icon="bring-data" onClick={this.handleAddDataset.bind(this)} />
        </Flexbox>
      </Flexbox>
    );
  }
}
