/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 complexity: 0 */
import { action } from 'mobx';
import ElementMappingActions from './ElementMappingActions';
import Activity from '../models/ActivityModel';
import User from '../models/UserModel';
import Team from '../models/TeamModel';
import UserTeam from '../models/UserTeamModel';
import UserTeamRequest from '../models/UserTeamRequestModel';
import Workspace from '../models/WorkspaceModel';
import WorkspaceFolder from '../models/WorkspaceFolderModel';
import App from '../models/AppModel';
import Item from '../models/ItemModel';
import ItemValue from '../models/ItemValueModel';
import ProfileValue from '../models/ProfileValueModel';
import StatusFlag from '../models/StatusFlagModel';
import _ from 'lodash';

class ActivityMappings {
  @action
  mapActivity(data) {
    const newActivity = new Activity(
      data.id,
      data.user_id,
      data.account_id,
      data.event_name,
      data.additional_information,
      data.activity_object_id,
      data.activity_object_type,
      data.activity_reference_object_id,
      data.activity_reference_object_type,
      data.comment_count,
      data.email,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    newActivity.user = this.mapUser(data.user);
    switch (data.activity_object_type) {
      case 'Workspace':
        newActivity.workspace = this.mapWorkspace(data.workspace);
        break;
      case 'WorkspaceFolder':
        newActivity.workspaceFolder = this.mapWorkspaceFolder(data.workspace_folder);
        break;
      case 'App':
        newActivity.app = this.mapApp(data.app);
        break;
      case 'Team':
        newActivity.team = this.mapTeam(data.team);
        break;
      case 'UserTeam':
        newActivity.userTeam = this.mapUserTeam(data.user_team);
        break;
      case 'UserTeamRequest':
        newActivity.userTeamRequest = this.mapUserTeamRequest(data.user_team_request);
        break;
      case 'Item':
        newActivity.item = this.mapItem(data.item);
        break;
      case 'ItemValue':
        newActivity.formValues = this.mapItemValues(JSON.parse(data.additional_information));
        break;
      case 'ProfileValue':
        newActivity.formValues = this.mapProfileValues(JSON.parse(data.additional_information));
        break;
      default:
        break; // do nothing;
    }
    switch (data.activity_reference_object_type) {
      case 'StatusFlag':
        newActivity.statusFlag = this.mapStatusFlag(data.status_flag);
        break;
      case 'Element':
        newActivity.element = _.head(ElementMappingActions.mapElements([data.element]));
        newActivity.element.isEditable = false;
        newActivity.element.formValues = newActivity.formValues;
        break;
      default:
        // do nothing
        break;
    }
    return newActivity;
  }

  @action
  mapUser(data) {
    return new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
  }

  @action
  mapWorkspace(data) {
    return new Workspace(
      data.id,
      data.account_id,
      data.name,
      data.description,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.user_access_level,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapWorkspaceFolder(data) {
    return new WorkspaceFolder(
      data.id,
      data.workspace_id,
      data.name,
      data.row_order,
      data.comment_count,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapApp(data) {
    return new App(
      data.id,
      data.workspace_id,
      data.workspace_folder_id,
      data.name,
      data.description,
      data.item,
      data.item_plural,
      data.item_layout,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.row_order_folder,
      data.user_access_level,
      data.webform_enabled,
      data.webform_email_required,
      data.webform_anonymous,
      data.webform_path,
      data.default_view_folder,
      data.default_process_visible,
      data.include_in_quick_create_menu,
      data.dummy_data,
      data.lock_participants,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapTeam(data) {
    return new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapUserTeam(data) {
    const userTeam = new UserTeam(
      data.id,
      data.team_id,
      data.user_id,
      data.is_primary,
      data.is_owner,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    userTeam.team = this.mapTeam(data.team);
    return userTeam;
  }

  @action
  mapUserTeamRequest(data) {
    const newRequest = new UserTeamRequest(
      data.id,
      data.team_id,
      data.approved,
      data.approved_by,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by,
      data.user_id
    );
    newRequest.user = this.mapUser(data.user);
    newRequest.team = this.mapTeam(data.team);
    return newRequest;
  }

  @action
  mapItem(data) {
    return new Item(
      data.id,
      data.app_id,
      data.state_id,
      data.user_id,
      data.status_flag_id,
      data.name,
      data.item_type,
      data.item_icon,
      data.item_color,
      data.item_layout,
      data.image_file_name,
      data.comment_count,
      data.is_done_for_current_user,
      data.is_webform,
      data.import_id,
      data.webform_user_email,
      data.webform_user_full_name,
      data.webform_user_image_file_name,
      data.dataview,
      data.simple_dataview,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapItemValues(information) {
    const itemValues = [];
    let arrayValues = [];
    if (information.length > 0) {
      arrayValues = information;
    } else {
      arrayValues = [information];
    }
    _.map(arrayValues, data => {
      const newItemValue = new ItemValue(
        data.id,
        data.item_id,
        data.element_id,
        data.item_value_type,
        data.string_value,
        data.number_value,
        data.decimal_value,
        data.boolean_value,
        data.date_value,
        data.image_file_name,
        data.attachment_file_name,
        data.element_select_option_id,
        data.element_object_select_item_id,
        data.element_object_select_user_id,
        data.element_object_select_team_id,
        data.element_grid_column_id,
        data.element_grid_row_id,
        data.element_multiline_input_id,
        data.row_order,
        data.created_at,
        data.created_by,
        data.deleted_at,
        data.deleted_by,
        data.updated_at,
        data.updated_by
      );
      if (data.reference_user) {
        newItemValue.referenceUser = new User(
          data.reference_user.id,
          data.reference_user.email,
          data.reference_user.first_name,
          data.reference_user.last_name,
          data.reference_user.full_name,
          data.reference_user.account_id,
          data.reference_user.human_friendly_id,
          data.reference_user.is_current_user,
          data.reference_user.is_account_owner,
          data.reference_user.image_file_name,
          data.reference_user.background_image_file_name,
          data.reference_user.last_sign_in_at,
          data.reference_user.locale,
          data.reference_user.theme,
          data.reference_user.created_at,
          data.reference_user.updated_at,
          data.reference_user.deleted_at,
          data.reference_user.created_by,
          data.reference_user.updated_by,
          data.reference_user.deleted_by
        );
      }
      if (data.reference_team) {
        newItemValue.referenceTeam = new Team(
          data.reference_team.id,
          data.reference_team.account_id,
          data.referenec_team.is_default_account,
          data.reference_team.name,
          data.reference_team.description,
          data.reference_team.image_file_name,
          data.reference_team.background_image_file_name,
          data.reference_team.private,
          data.reference_team.is_current_user_team_owner,
          data.reference_team.is_current_user_team_member,
          data.reference_team.row_order,
          data.reference_team.user_count,
          data.reference_team.comment_count,
          data.reference_team.import_id,
          data.reference_team.created_at,
          data.reference_team.created_by,
          data.reference_team.deleted_at,
          data.reference_team.deleted_by,
          data.reference_team.updated_at,
          data.reference_team.updated_by
        );
      }
      if (data.reference_item) {
        newItemValue.referenceItem = new Item(
          data.reference_item.id,
          data.reference_item.app_id,
          data.reference_item.state_id,
          data.reference_item.user_id,
          data.reference_item.status_flag_id,
          data.reference_item.name,
          data.reference_item.item_type,
          data.reference_item.item_icon,
          data.reference_item.item_color,
          data.reference_item.item_layout,
          data.reference_item.image_file_name,
          data.reference_item.comment_count,
          data.reference_item.is_done_for_current_user,
          data.reference_item.is_webform,
          data.reference_item.import_id,
          data.reference_item.webform_user_email,
          data.reference_item.webform_user_full_name,
          data.reference_item.webform_user_image_file_name,
          data.reference_item.dataview,
          data.reference_item.simple_dataview,
          data.reference_item.created_at,
          data.reference_item.created_by,
          data.reference_item.deleted_at,
          data.reference_item.deleted_by,
          data.reference_item.updated_at,
          data.reference_item.updated_by
        );
      }
      itemValues.push(newItemValue);
    });
    return itemValues;
  }

  @action
  mapProfileValues(information) {
    const profileValues = [];
    let arrayValues = [];
    if (information.length > 0) {
      arrayValues = information;
    } else {
      arrayValues = [information];
    }
    _.map(arrayValues, data => {
      const newProfileValue = new ProfileValue(
        data.id,
        data.user_id,
        data.profile_template_id,
        data.element_id,
        data.profile_value_type,
        data.string_value,
        data.number_value,
        data.decimal_value,
        data.boolean_value,
        data.date_value,
        data.image_file_name,
        data.attachment_file_name,
        data.element_select_option_id,
        data.element_object_select_item_id,
        data.element_object_select_user_id,
        data.element_object_select_team_id,
        data.element_grid_column_id,
        data.element_grid_row_id,
        data.element_multiline_input_id,
        data.row_order,
        data.created_at,
        data.created_by,
        data.deleted_at,
        data.deleted_by,
        data.updated_at,
        data.updated_by
      );
      if (data.reference_user) {
        newProfileValue.referenceUser = new User(
          data.reference_user.id,
          data.reference_user.email,
          data.reference_user.first_name,
          data.reference_user.last_name,
          data.reference_user.full_name,
          data.reference_user.account_id,
          data.reference_user.human_friendly_id,
          data.reference_user.is_current_user,
          data.reference_user.is_account_owner,
          data.reference_user.image_file_name,
          data.reference_user.background_image_file_name,
          data.reference_user.last_sign_in_at,
          data.reference_user.locale,
          data.reference_user.theme,
          data.reference_user.created_at,
          data.reference_user.updated_at,
          data.reference_user.deleted_at,
          data.reference_user.created_by,
          data.reference_user.updated_by,
          data.reference_user.deleted_by
        );
      }
      if (data.reference_team) {
        newProfileValue.referenceTeam = new Team(
          data.reference_team.id,
          data.reference_team.account_id,
          data.reference_team.data.is_default_account,
          data.reference_team.name,
          data.reference_team.description,
          data.reference_team.image_file_name,
          data.reference_team.background_image_file_name,
          data.reference_team.private,
          data.reference_team.is_current_user_team_owner,
          data.reference_team.is_current_user_team_member,
          data.reference_team.row_order,
          data.reference_team.user_count,
          data.reference_team.comment_count,
          data.reference_team.import_id,
          data.reference_team.created_at,
          data.reference_team.created_by,
          data.reference_team.deleted_at,
          data.reference_team.deleted_by,
          data.reference_team.updated_at,
          data.reference_team.updated_by
        );
      }
      if (data.reference_item) {
        newProfileValue.referenceItem = new Item(
          data.reference_item.id,
          data.reference_item.app_id,
          data.reference_item.state_id,
          data.reference_item.user_id,
          data.reference_item.status_flag_id,
          data.reference_item.name,
          data.reference_item.item_type,
          data.reference_item.item_icon,
          data.reference_item.item_color,
          data.reference_item.item_layout,
          data.reference_item.image_file_name,
          data.reference_item.is_done_for_current_user,
          data.reference_item.is_webform,
          data.reference_item.import_id,
          data.reference_item.webform_user_email,
          data.reference_item.webform_user_full_name,
          data.reference_item.webform_user_image_file_name,
          data.reference_item.dataview,
          data.reference_item.simple_dataview,
          data.reference_item.comment_count,
          data.reference_item.created_at,
          data.reference_item.created_by,
          data.reference_item.deleted_at,
          data.reference_item.deleted_by,
          data.reference_item.updated_at,
          data.reference_item.updated_by
        );
      }
      profileValues.push(newProfileValue);
    });
    return profileValues;
  }

  @action
  mapStatusFlag(data) {
    return new StatusFlag(
      data.id,
      data.app_id,
      data.name,
      data.color,
      data.is_archived,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }
}

const activityMappings = new ActivityMappings();
export default activityMappings;
