import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  Button,
  Dialog,
  Intent,
  HTMLSelect,
} from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

@inject(
  'AdminActions',
  'AdminStore',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class AdminNewAccountDomain extends React.Component {
  @observable
  showDialog = false;
  @observable
  isSubmitting = false;

  constructor(props) {
    super(props);

    this.state = { isBlurred: [] };
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }

  toggleSubmitting() {
    this.isSubmitting = !this.isSubmitting;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    const accountDomainObj = {
      domain: this.props.AdminStore.newAccountDomain.domain,
      version: this.props.AdminStore.newAccountDomain.version,
    };
    this.props.AdminActions.createAccountDomain(
      accountDomainObj
    ).then(response => {
      this.props.AdminStore.addAccountDomain(response.data);
      this.props.AdminStore.resetNewAccountDomain();
      this.toggleDialog();
      this.toggleSubmitting();
      this.setState({ isBlurred: [] });
    });
  }

  handleInputChange(e) {
    this.props.AdminStore.updateNewAccountDomain(
      e.target.value,
      e.target.name
    );
  }

  handleCancel() {
    this.props.AdminStore.resetNewAccountDomain();
    this.toggleDialog();
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.AdminStore.newAccountDomain[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.AdminStore.newAccountDomain[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title="Add account domain"
      >
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'domain') &&
                  !this.props.AdminStore.newAccountDomain.domainValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  Domain name
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'domain') &&
                      !this.props.AdminStore.newAccountDomain
                        .domainValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      autoFocus={true}
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder="Enter domain, for example 'peoplegoal.com'"
                      name="domain"
                      value={
                        this.props.AdminStore.newAccountDomain.domain
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('domain')}
                </div>
              </div>
              <div
                className={
                  _.includes(this.state.isBlurred, 'version') &&
                  !this.props.AdminStore.newAccountDomain.versionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  Select version
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <HTMLSelect
                    className="bp3-fill"
                    fill={true}
                    name="version"
                    value={
                      this.props.AdminStore.newAccountDomain.version
                    }
                    onChange={this.handleInputChange.bind(this)}
                    dir="auto"
                  >
                    <option value="v1">v1</option>
                    <option value="v2">v2</option>
                    <option value="All">All</option>
                  </HTMLSelect>
                  {this.renderErrors('version')}
                </div>
              </div>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.handleCancel.bind(this)}
              />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  !this.props.AdminStore.newAccountDomainValid
                }
                text="Create account domain"
                loading={this.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox>
        <Button
          text="Add account domain"
          icon="add"
          intent={Intent.PRIMARY}
          onClick={this.toggleDialog.bind(this)}
        />
        {this.renderDialog()}
      </Flexbox>
    );
  }
}
