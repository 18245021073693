// @flow
import * as React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import CurrentUserStore from '../../stores/CurrentUserStore';
import Utilities from '../../utils/Utilities';
import HeaderButton from './HeaderButton';
import { Spinner } from '@blueprintjs/core';
import AccountIcon from './AccountIcon';
import Hero from './Hero';

@observer
export default class LandingHeader extends React.Component<any> {
  renderHeaderButton() {
    if (CurrentUserStore.isLoading) {
      return <Flexbox minWidth="100px" justifyContent="center" />;
    }

    const {
      userSignedIn,
      activeAccount,
      currentUser,
    } = CurrentUserStore;
    const navigate = Utilities.navigate.bind(this);
    return !userSignedIn ? (
      // Case 1. User needs to sign in
      <HeaderButton
        icon="log-in"
        navigateTo={() => navigate('/sign-in')}
        // $FlowFixMe
        text={I18n.t('js.sign_in')}
      />
    ) : !currentUser.accountId.length > 0 &&
      this.props.controller !== 'setup' ? (
      // Case 2. User needs to setup
      <HeaderButton
        icon="new-object"
        navigateTo={() => navigate('/setup')}
        // $FlowFixMe
        text={I18n.t('js.create_an_account')}
      />
    ) : (
      // Case 3. User is logged in
      <HeaderButton
        icon={<AccountIcon imgSrc={activeAccount.accountImage} />}
        navigateTo={() => navigate('/dashboard')}
        text={activeAccount.accountName}
      />
    );
  }

  render() {
    const { navigate } = Utilities;
    const stores = { CurrentUserStore };
    return (
      <Provider {...stores}>
        <Flexbox
          className="landing-header"
          minHeight="70vh"
          flexDirection="column"
          paddingBottom="50px"
        >
          <Flexbox
            className="landing-header__content"
            flexDirection="column"
            flexGrow={1}
          >
            <Flexbox flexGrow={1}>
              <a href="/" className="landing-header__logo-link">
                <img
                  alt="logo"
                  src={I18n.t('js.or_icon_blue')}
                  className="img-logo"
                />
              </a>
            </Flexbox>
            <Flexbox
              className="fixed-width-container"
              flexGrow={1}
              alignItems="center"
              justifyContent="flex-start"
              paddingTop="100px"
              paddingBottom="200px"
            >
              <Hero
                isLoading={false}
                navigateTo={navigate}
                // $FlowFixMe
                ctaDescription={I18n.t('js.request_a_demo')}
                ctaLink="/request-a-demo"
                description={I18n.t(
                  'js.peoplegoal_product_description'
                )}
                tagline={I18n.t('js.peoplegoal_tagline')}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Provider>
    );
  }
}
