const operands = [
  {
    id: 0,
    name: 'is_not_blank',
    text: I18n.t('js.operand_is_not_blank'),
    disabled: false,
  },
  {
    id: 1,
    name: 'is_blank',
    text: I18n.t('js.operand_is_blank'),
    disabled: false,
  },

  {
    id: 2,
    name: 'equals',
    text: I18n.t('js.operand_equals'),
    disabled: false,
  },
  {
    id: 3,
    name: 'contains',
    text: I18n.t('js.operand_contains'),
    disabled: false,
  },
  {
    id: 4,
    name: 'does_not_contain',
    text: I18n.t('js.operand_does_not_contain'),
    disabled: false,
  },
  {
    id: 5,
    name: 'starts_with',
    text: I18n.t('js.operand_starts_with'),
    disabled: false,
  },
  {
    id: 6,
    name: 'ends_with',
    text: I18n.t('js.operand_ends_with'),
    disabled: false,
  },
  {
    id: 7,
    name: 'is_greater_than',
    text: I18n.t('js.is_greater_than'),
    disabled: false,
  },
  {
    id: 8,
    name: 'is_less_than',
    text: I18n.t('js.is_less_than'),
    disabled: false,
  },
  {
    id: 9,
    name: 'is_within_last_days',
    text: I18n.t('js.operand_is_within_last_days'),
    disabled: false,
  },
  {
    id: 10,
    name: 'is_within_next_days',
    text: I18n.t('js.operand_is_within_next_days'),
    disabled: false,
  },
];

class OperandDefinitions {
  static get operands() {
    return operands;
  }
}

export default OperandDefinitions;
