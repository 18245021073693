import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Paginator from '../shared/Paginator';
import StoreTemplateWidget from './StoreTemplateWidget';
import { NonIdealState } from '@blueprintjs/core';
import _ from 'lodash';

@inject('StoreTemplateActions', 'StoreTemplateStore')
@observer
export default class UserStoreTemplateList extends React.Component {
  handlePageChange(pageNumber) {
    this.props.StoreTemplateStore.resetStartingAttributes();
    this.props.StoreTemplateActions.fetchYourStoreTemplates(
      pageNumber,
      this.props.StoreTemplateStore.pagination.xPerPage,
      this.props.StoreTemplateStore.pagination.xOffset
    )
      .then(response => {
        this.props.StoreTemplateStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.StoreTemplateStore.addItem(item);
        });
        this.props.StoreTemplateStore.toggleLoading();
      })
      .catch(() => {
        this.props.StoreTemplateStore.toggleLoading();
      });
  }

  handlePerPageChange(e) {
    this.props.StoreTemplateStore.resetStartingAttributes();
    const appNum = 1;
    this.props.StoreTemplateActions.fetchYourStoreTemplates(appNum, e.target.value, this.props.StoreTemplateStore.pagination.xOffset)
      .then(response => {
        this.props.StoreTemplateStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.StoreTemplateStore.addItem(item);
        });
        this.props.StoreTemplateStore.toggleLoading();
      })
      .catch(() => {
        this.props.StoreTemplateStore.toggleLoading();
      });
  }

  renderPagination() {
    if (this.props.StoreTemplateStore.pagination.xTotal > this.props.StoreTemplateStore.pagination.xPerPage) {
      return (
        <Paginator
          paginationObject={this.props.StoreTemplateStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  render() {
    if (this.props.StoreTemplateStore.filteredStoreTemplates.length === 0) {
      switch (this.props.StoreTemplateStore.activeHeaderTab) {
        case 'apps':
          return (
            <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="20px" className="bp3-card">
              <NonIdealState
                title={I18n.t('js.nothing_to_show')}
                description={I18n.t('js.there_are_no_store_template_apps_to_show')}
                icon="document"
                className="bp3-text-muted"
              />
            </Flexbox>
          );
        case 'workspaces':
          return (
            <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="20px" className="bp3-card">
              <NonIdealState
                title={I18n.t('js.nothing_to_show')}
                description={I18n.t('js.there_are_no_store_template_workspaces_to_show')}
                icon="grid-view"
                className="bp3-text-muted"
              />
            </Flexbox>
          );
        case 'profile_templates':
          return (
            <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="20px" className="bp3-card">
              <NonIdealState
                title={I18n.t('js.nothing_to_show')}
                description={I18n.t('js.there_are_no_store_template_profile_templates_to_show')}
                icon="new-person"
                className="bp3-text-muted"
              />
            </Flexbox>
          );
        default:
          throw new Error(`case not accounted for: ${this.props.StoreTemplateStore.activeHeaderTab}`);
      }
    } else {
      return (
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap">
          {_.map(this.props.StoreTemplateStore.filteredStoreTemplates, storeTemplate => (
            <StoreTemplateWidget key={storeTemplate.id} storeTemplate={storeTemplate} />
          ))}
          {this.renderPagination()}
        </Flexbox>
      );
    }
  }
}
