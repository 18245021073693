import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Position } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import Flexbox from 'flexbox-react';
import SkeletonRows from '../skeletons/SkeletonRows';
import Utilities from '../../../react/utils/Utilities';

import TeamUsers from '../teams/TeamUsers';
import TeamUsersStore from '../../stores/TeamUsersStore';
import Paginator from '../shared/Paginator';

const TeamInfoDrawer = observer(props => {
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const handlePageChange = pageNumber => {
    setIsPaginationLoading(true);
    TeamUsersStore.fetchUserTeams(props.team.id, pageNumber, TeamUsersStore.pagination.xPerPage, TeamUsersStore.pagination.xOffset)
      .then(() => {
        setIsPaginationLoading(false);
      })
      .catch(() => {
        setIsPaginationLoading(false);
      });
  };

  const handlePerPageChange = e => {
    setIsPaginationLoading(true);
    const appNum = 1;
    TeamUsersStore.fetchUserTeams(props.team.id, appNum, e.target.value, TeamUsersStore.pagination.xOffset)
      .then(() => {
        setIsPaginationLoading(false);
      })
      .catch(() => {
        setIsPaginationLoading(false);
      });
  };

  const navigateToUrl = () => {
    props.toggleBool('isTeamDrawerOpen');
    Utilities.navigate(`/teams/${props.team.id}`);
  };

  return (
    <Drawer
      portalContainer={document.body}
      position={Position.RIGHT}
      size={Drawer.SIZE_STANDARD}
      onClose={() => props.toggleBool('isTeamDrawerOpen')}
      isOpen={props.isOpen}
    >
      <Flexbox className="pad-20 scroll" flexDirection="column">
        <Flexbox flexDirection="row" alignItems="center" className="push-40-b pointer" onClick={() => navigateToUrl()}>
          <Avatar src={props.team.imageFileName} size={100} round={true} className="push-5-r" />
          <Flexbox flexDirection="column" className="push-10-l">
            <span className="font-s-28 font-w-600">{props.team.name}</span>
          </Flexbox>
        </Flexbox>
        {props.isLoading || isPaginationLoading ? (
          <Flexbox justifyContent="center" alignItems="center" paddingTop="50px" paddingBottom="50px" flexGrow={1}>
            <SkeletonRows numRows={4} />
          </Flexbox>
        ) : (
          <Flexbox className="push-50-b" flexDirection="column">
            <RenderContent data={TeamUsersStore.userTeams} />
            <Paginator
              paginationObject={TeamUsersStore.pagination}
              pageRangeDisplayed={10}
              handlePageChange={handlePageChange}
              handlePerPageChange={handlePerPageChange}
            />
          </Flexbox>
        )}
      </Flexbox>
    </Drawer>
  );
});

const RenderContent = data => {
  const teamData = data.data;
  return (
    <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
      <thead>
        <tr>
          <th>{I18n.t('js.users')}</th>
          <th>{I18n.t('js.joined')}</th>
        </tr>
      </thead>
      <tbody>
        {_.map(teamData, dataItem => {
          return (
            <tr onClick={() => Utilities.navigate(`/users/${dataItem.user.id}`)} key={dataItem.user.fullName}>
              <td>
                <Avatar src={dataItem.user.imageFileName} size={20} round={true} className="push-5-r" />
                {dataItem.user.fullName}
              </td>
              <td>{dataItem.user.createdAtToDate}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default TeamInfoDrawer;
