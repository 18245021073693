import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import RichTextDisplay from '../shared/RichTextDisplay';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementRichTextareaDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        className="bp3-running-text"
      >
        <RichTextDisplay
          content={
            this.props.element.elementRichTextareaDisplay.content
          }
        />
      </Flexbox>
    );
  }
}
