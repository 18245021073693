import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, computed, action } from 'mobx';
import Flexbox from 'flexbox-react';
import {
  FormGroup,
  HTMLSelect,
  NumericInput,
  Classes,
  InputGroup,
  Intent,
  Button,
  Alert,
  Switch,
  Collapse,
  Tabs,
  Tab,
} from '@blueprintjs/core';
import { DatePicker, DateInput } from '@blueprintjs/datetime';
import RichTextEditor from '../shared/RichTextEditor';
import WidgetStore from '../../stores/WidgetStore';
import AppStore from '../../stores/AppStore';
import TemplateStore from '../../stores/TemplateStore';
import TemplateActions from '../../actions/TemplateActions';
import ProfileTemplateActions from '../../actions/ProfileTemplateActions';
import ElementMappingActions from '../../actions/ElementMappingActions';
import moment from 'moment';
import Widget from './Widget';

@observer
export default class ScheduledEvent extends Component {
  @observable profileTemplates = [];
  @observable activeProfileTemplate = null;

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isDeleting: false,
      showDeletionAlert: false,
      selectedTabId: 'fixed',
    };
  }

  @computed
  get minDate() {
    return new Date(new Date().setFullYear(new Date().getFullYear() - 10));
  }

  @computed
  get maxDate() {
    return new Date(new Date().setFullYear(new Date().getFullYear() + 10));
  }

  @computed get elementOptions() {
    let elements = [];
    if (this.activeProfileTemplate !== null) {
      _.map(this.activeProfileTemplate.sections, section => {
        section.elements.map(element => {
          if (element.element_type == 'date') {
            elements.push(element);
          }
        });
      });
    }
    return ElementMappingActions.mapElements(elements);
  }

  @action setActiveProfileTemplate(profileTemplateId) {
    this.activeProfileTemplate = _.find(this.profileTemplates, o => o.id === profileTemplateId);
  }

  @action setInitialProfileTemplateElement() {
    const { activeEvent, activeWidget } = WidgetStore;
    if (activeEvent) {
      if (activeEvent.element_id) {
        _.map(this.profileTemplates, profileTemplate => {
          _.map(profileTemplate.sections, section => {
            _.map(section.elements, element => {
              if (element.id === activeEvent.element_id) {
                this.activeProfileTemplate = profileTemplate;
                return;
              }
            });
          });
        });
      } else {
        const defaultProfileTemplate = _.find(this.profileTemplates, o => o.default === true);
        if (defaultProfileTemplate) {
          this.activeProfileTemplate = defaultProfileTemplate;
        } else {
          this.activeProfileTemplate = _.head(this.profileTemplates);
        }
      }
    }
  }

  componentDidMount() {
    const { activeEvent, activeWidget } = WidgetStore;
    this.setState({ selectedTabId: activeEvent.event_type });

    ProfileTemplateActions.fetchAllProfileTemplates().then(response => {
      this.profileTemplates = response;
      if (response.length > 0) {
        this.setInitialProfileTemplateElement();
      }
    });

    if (activeWidget.widgetable_type === 'App') {
      TemplateActions.fetchParticipants(activeWidget.widgetable_id);
    }
  }

  handleTabChange(tab) {
    WidgetStore.updateActiveEvent(tab, 'event_type');
    this.setState({ selectedTabId: tab });
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    const { activeEvent, activeWidget } = WidgetStore;
    if (activeEvent.created_at) {
      // Save changes
      const eventObj = {
        id: activeEvent.id,
        event_type: activeEvent.event_type,
        element_id: activeEvent.element_id,
        target_date: moment.utc(activeEvent.target_date).local(),
        end_date: activeEvent.end_date != null ? moment.utc(activeEvent.end_date).local() : null,
        reminder_period: activeEvent.reminder_period,
        title: activeEvent.title,
        content: activeEvent.content,
        recurs: activeEvent.recurs,
        frequency: activeEvent.frequency,
        interval: activeEvent.interval,
        offset: activeEvent.offset,
        creates_item: activeEvent.creates_item,
        participant_id: activeEvent.participant_id,
      };
      WidgetStore.changeScheduledEvent(eventObj)
        .then(response => {
          this.setState({ isSaving: false });
          WidgetStore.setActiveEvent(null);
        })
        .catch(err => {
          this.setState({ isSaving: false });
        });
    } else {
      const eventObj = {
        widget_id: activeWidget.id,
        event_type: activeEvent.event_type,
        element_id: activeEvent.element_id,
        target_date: moment.utc(activeEvent.target_date).local(),
        reminder_period: activeEvent.reminder_period,
        title: activeEvent.title,
        content: activeEvent.content,
        recurs: activeEvent.recurs,
        frequency: activeEvent.frequency,
        interval: activeEvent.interval,
        offset: activeEvent.offset,
        creates_item: activeEvent.creates_item,
        participant_id: activeEvent.participant_id,
      };
      if (activeEvent.end_date != null) {
        eventObj.end_date = moment.utc(activeEvent.end_date).local();
      }
      WidgetStore.createScheduledEvent(eventObj)
        .then(response => {
          this.setState({ isSaving: false });
          WidgetStore.setActiveEvent(null);
        })
        .catch(err => {
          this.setState({ isSaving: false });
        });
    }
  }

  handleCancel() {
    WidgetStore.setActiveEvent(null);
  }

  toggleDeleteAlert() {
    this.setState({ showDeletionAlert: !this.state.showDeletionAlert });
  }

  handleDelete() {
    this.toggleDeleteAlert();
    WidgetStore.deleteScheduledEvent(WidgetStore.activeEvent.id);
    WidgetStore.setActiveEvent(null);
  }

  handleToggleBool(attr) {
    WidgetStore.updateActiveEvent(!WidgetStore.activeEvent[attr], attr);
  }

  handleAttrChange(e) {
    const { value, name } = e.target;
    WidgetStore.updateActiveEvent(value, name);
  }

  handleRichTextAreaChange(content) {
    WidgetStore.updateActiveEvent(content, 'content');
  }

  handleTargetDateChange(date) {
    if (date != null) {
      let newDate = date.setMinutes(0);
      WidgetStore.updateActiveEvent(newDate, 'target_date');
    }
  }

  handleEndDateChange(date) {
    let newDate = date;
    if (newDate != null) {
      newDate = newDate.setHours(newDate.getHours() + 1);
    }
    WidgetStore.updateActiveEvent(newDate, 'end_date');
  }

  handleProfileTemplateChange(e) {
    const id = e.target.value;
    const findProfileTemplate = _.find(this.profileTemplates, o => o.id === id);
    WidgetStore.updateActiveEvent('', 'element_id');
    this.activeProfileTemplate = findProfileTemplate;
  }

  renderCreatesItem() {
    const { activeEvent, activeWidget } = WidgetStore;

    if (activeWidget.widgetable_type === 'App') {
      return (
        <>
          <Flexbox flexDirection="column">
            <Switch
              className="bp3-control-no-margin"
              checked={activeEvent.creates_item}
              label={I18n.t('js.automatically_create_items', { items: AppStore.activeApp.item })}
              onChange={() => this.handleToggleBool('creates_item')}
            />
          </Flexbox>
          <Collapse isOpen={activeEvent.creates_item}>
            <Flexbox flexDirection="column" marginTop="20px">
              <FormGroup
                helperText={null}
                label={I18n.t('js.select_participant_simple')}
                labelFor="participant_id"
                labelInfo={`(${I18n.t('js.required')})`}
              >
                <HTMLSelect
                  className="push-10-r"
                  name="participant_id"
                  onChange={e => this.handleAttrChange(e)}
                  value={activeEvent.participant_id ? activeEvent.participant_id : ''}
                  disabled={!activeEvent.creates_item}
                >
                  <option value="">{I18n.t('js.select_participant_simple')}</option>
                  {_.map(TemplateStore.participants, participant => {
                    return (
                      <option key={participant.id} value={participant.id}>
                        {participant.name}
                      </option>
                    );
                  })}
                </HTMLSelect>
              </FormGroup>
            </Flexbox>
          </Collapse>
        </>
      );
    }
  }

  renderProfileDateSelect() {
    const { activeEvent } = WidgetStore;

    if (this.profileTemplates.length > 0 && this.activeProfileTemplate) {
      return (
        <Flexbox flexDirection="column" flexGrow={0}>
          <Flexbox flexDirection="column">
            <FormGroup
              helperText={null}
              label={I18n.t('js.select_profile_template')}
              labelFor="profile_template_id"
              labelInfo={`(${I18n.t('js.required')})`}
            >
              <HTMLSelect
                className="push-10-r"
                name="profile_template_id"
                onChange={e => this.handleProfileTemplateChange(e)}
                value={this.activeProfileTemplate.id}
              >
                {_.map(this.profileTemplates, profileTemplate => {
                  return (
                    <option key={profileTemplate.id} value={profileTemplate.id}>
                      {profileTemplate.name}
                    </option>
                  );
                })}
              </HTMLSelect>
            </FormGroup>
          </Flexbox>
          <Flexbox flexDirection="column">
            <FormGroup
              helperText={null}
              label={I18n.t('js.select_date_element')}
              labelFor="element_id"
              labelInfo={`(${I18n.t('js.required')})`}
            >
              <HTMLSelect
                name="element_id"
                onChange={e => this.handleAttrChange(e)}
                value={activeEvent.element_id}
                disabled={this.elementOptions.length === 0}
              >
                <option value="">{I18n.t('js.select_date_element')}</option>
                {_.map(this.elementOptions, element => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.elementDate.name}
                    </option>
                  );
                })}
              </HTMLSelect>
            </FormGroup>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }

  renderFixedDateSelect() {
    const { activeEvent } = WidgetStore;

    return (
      <Flexbox flexDirection="row" flexGrow={0}>
        <FormGroup label={I18n.t('js.select_date')} labelFor="target_date" labelInfo={`(${I18n.t('js.required')})`}>
          <DatePicker
            className={Classes.ELEVATION_1}
            value={new Date(activeEvent.target_date)}
            onChange={date => this.handleTargetDateChange(date)}
            highlightCurrentDay={true}
            timePickerProps={{
              showArrowButtons: true,
            }}
            minDate={this.minDate}
            maxDate={this.maxDate}
            showActionsBar={true}
            popoverProps={{
              usePortal: false,
              inline: false,
            }}
          />
        </FormGroup>
      </Flexbox>
    );
  }

  renderDateSelect() {
    return (
      <Flexbox flexDirection="row" flexGrow={0}>
        <Tabs id="dateTypeTab" onChange={this.handleTabChange.bind(this)} selectedTabId={this.state.selectedTabId}>
          <Tab id="fixed" title={I18n.t('js.fixed_date')} panel={this.renderFixedDateSelect()} />
          <Tab id="profile_template_date" title={I18n.t('js.date_based_on_profile')} panel={this.renderProfileDateSelect()} />
        </Tabs>
      </Flexbox>
    );
  }

  render() {
    const { activeEvent } = WidgetStore;

    return (
      <Flexbox flexDirection="column">
        <Flexbox marginBottom="20px">
          <Button text={I18n.t('js.back')} icon="arrow-left" onClick={() => this.handleCancel()}></Button>
        </Flexbox>
        <form onSubmit={this.handleSave.bind(this)}>
          <Flexbox flexDirection="column">
            <FormGroup label={I18n.t('js.title')} labelFor="title" labelInfo={`(${I18n.t('js.required')})`}>
              <InputGroup
                name="title"
                className="bp3-fill"
                onChange={e => this.handleAttrChange(e)}
                placeholder={I18n.t('js.event_title')}
                value={activeEvent.title}
              />
            </FormGroup>
          </Flexbox>
          <Flexbox flexDirection="column" marginTop="20px">
            <FormGroup label={I18n.t('js.event_description')} labelFor="content" labelInfo={`(${I18n.t('js.required')})`}>
              <RichTextEditor
                content={activeEvent.content}
                handleChange={content => this.handleRichTextAreaChange(content)}
                placeholder={I18n.t('js.click_here_to_edit_content')}
                placeholderDisabled={true}
              />
            </FormGroup>
          </Flexbox>
          <Flexbox flexDirection="column">
            {this.renderDateSelect()}
            <hr />
            <Flexbox flexDirection="column">
              <Flexbox flexDirection="column">
                <FormGroup
                  helperText={I18n.t('js.set_to_zero_for_no_offset')}
                  label={I18n.t('js.offset_description')}
                  labelFor="offset"
                  labelInfo={`(${I18n.t('js.required')})`}
                >
                  <NumericInput
                    allowNumericCharactersOnly={true}
                    name="offset"
                    value={activeEvent.offset}
                    onValueChange={val => WidgetStore.updateActiveEvent(val, 'offset')}
                  />
                </FormGroup>
              </Flexbox>
              <Flexbox flexDirection="column">
                <FormGroup
                  helperText={I18n.t('js.set_to_zero_for_no_reminders')}
                  label={I18n.t('js.reminder_period')}
                  labelFor="reminder_period"
                  labelInfo={`(${I18n.t('js.required')})`}
                >
                  <NumericInput
                    allowNumericCharactersOnly={true}
                    name="reminder_period"
                    min={0}
                    value={activeEvent.reminder_period}
                    onValueChange={val => WidgetStore.updateActiveEvent(val, 'reminder_period')}
                  />
                </FormGroup>
              </Flexbox>
              <Flexbox flexDirection="column" marginBottom="20px">
                <Switch
                  className="bp3-control-no-margin"
                  checked={activeEvent.recurs}
                  label={I18n.t('js.event_recurs')}
                  onChange={() => this.handleToggleBool('recurs')}
                />
              </Flexbox>
              <Collapse isOpen={activeEvent.recurs}>
                <Flexbox flexDirection="column" flexGrow={1}>
                  <Flexbox flexDirection="row" marginBottom="20px">
                    <Flexbox paddingTop="5px" marginRight="10px">
                      {I18n.t('js.recurs_every')}
                    </Flexbox>
                    <NumericInput
                      allowNumericCharactersOnly={true}
                      name="interval"
                      min={0}
                      value={activeEvent.interval}
                      onValueChange={val => WidgetStore.updateActiveEvent(val, 'interval')}
                      disabled={!activeEvent.recurs}
                    />
                    <HTMLSelect
                      className="push-10-l"
                      name="frequency"
                      onChange={e => this.handleAttrChange(e)}
                      value={activeEvent.frequency}
                      disabled={!activeEvent.recurs}
                    >
                      <option value="daily">day(s)</option>
                      <option value="weekly">week(s)</option>
                      <option value="monthly">month(s)</option>
                      <option value="yearly">year(s)</option>
                    </HTMLSelect>
                  </Flexbox>
                  <Flexbox flexDirection="row">
                    <Flexbox paddingTop="5px" marginRight="10px">
                      {I18n.t('js.end_date')}
                    </Flexbox>
                    <DateInput
                      value={activeEvent.end_date ? new Date(activeEvent.end_date) : null}
                      onChange={date => this.handleEndDateChange(date)}
                      minDate={this.minDate}
                      maxDate={this.maxDate}
                      formatDate={date => moment.utc(date).format('DD/MM/YYYY')}
                      parseDate={str => new Date(str)}
                      showActionsBar={false}
                      canClearSelection={true}
                      popoverProps={{
                        usePortal: false,
                        inline: false,
                      }}
                    />
                  </Flexbox>
                </Flexbox>
              </Collapse>
              {this.renderCreatesItem()}
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="row" justifyContent="space-between" marginTop="20px">
            <Flexbox flexDirection="row">
              <Button
                type="submit"
                intent={Intent.PRIMARY}
                loading={this.state.isSaving}
                icon="floppy-disk"
                onClick={this.handleSave.bind(this)}
                text={I18n.t('js.save_event')}
                className="push-10-r"
              />
              <Button icon="cross" className="push-10-r" onClick={this.handleCancel.bind(this)} text={I18n.t('js.cancel')} />
              <Button icon="trash" onClick={this.toggleDeleteAlert.bind(this)} text={I18n.t('js.delete')} />
              <Alert
                portalContainer={document.body}
                isOpen={this.state.showDeletionAlert}
                cancelButtonText={I18n.t('js.cancel')}
                onCancel={() => this.toggleDeleteAlert()}
                confirmButtonText={I18n.t('js.delete')}
                onConfirm={() => this.handleDelete()}
                intent={Intent.DANGER}
              >
                <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
                {I18n.t('js.are_you_sure_you_wish_to_delete_this_event')}
              </Alert>
            </Flexbox>
          </Flexbox>
        </form>
      </Flexbox>
    );
  }
}
