/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import AppStore from './AppStore';
import Workspace from '../models/WorkspaceModel';
import WorkspaceFolder from '../models/WorkspaceFolderModel';
import axios from 'axios';
import _ from 'lodash';

const defaultFolderId = 9999;

class WorkspaceStore {
  @observable
  newWorkspace = new Workspace();
  @observable
  newWorkspaceFolder = new WorkspaceFolder();
  @observable
  uncategorizedFolder = new WorkspaceFolder(
    defaultFolderId, // 9999
    '',
    I18n.t('js.uncategorized')
  );
  @observable
  activeWorkspace = new Workspace();
  @observable
  activeTab = 'apps';
  @observable
  activeFilter = 'published';
  @observable
  activeFolderId = '';
  @observable
  workspaces = [];
  @observable
  workspaceFolders = [];

  @computed
  get hasFolders() {
    return this.activeWorkspaceFolders.length > 0;
  }

  @computed
  get activeWorkspaceFolders() {
    const folders = [];
    _.map(this.workspaceFolders, folder => {
      if (folder.workspaceId === this.activeWorkspace.id) {
        folders.push(folder);
      }
    });
    return folders;
  }

  @computed
  get activeFolder() {
    if (this.activeFolderId === defaultFolderId) {
      return this.uncategorizedFolder;
    }
    return _.find(this.workspaceFolders, o => o.id === this.activeFolderId);
  }

  @computed
  get draftWorkspaces() {
    const draftWorkspaces = _.filter(
      this.workspaces,
      workspace => workspace.status === 'draft' && workspace.userPermissionLevel.name !== 'access_denied'
    );
    return draftWorkspaces;
  }

  @computed
  get publishedWorkspaces() {
    const publishedWorkspaces = _.filter(
      this.workspaces,
      workspace => workspace.status === 'published' && workspace.userPermissionLevel.name !== 'access_denied'
    );
    return publishedWorkspaces;
  }

  @computed
  get restrictedWorkspaces() {
    const restrictedWorkspaces = _.filter(this.workspaces, workspace => workspace.userPermissionLevel.name === 'access_denied');
    return restrictedWorkspaces;
  }

  @computed
  get filteredWorkspaces() {
    if (this.activeFilter === 'published') {
      return this.publishedWorkspaces;
    } else if (this.activeFilter === 'draft') {
      return this.draftWorkspaces;
    } else if (this.activeFilter === 'restricted') {
      return this.restrictedWorkspaces;
    }
    return this.workspaces;
  }

  @computed
  get folderWorkspaces() {
    if (this.activeFilter === 'published') {
      return this.publishedWorkspaces;
    } else if (this.activeFilter === 'draft') {
      return this.draftWorkspaces;
    } else if (this.activeFilter === 'restricted') {
      return this.restrictedWorkspaces;
    }
    return this.workspaces;
  }

  @computed
  get newWorkspaceFormValid() {
    return this.newWorkspace.nameValid && this.newWorkspace.descriptionValid;
  }

  @action
  setActiveFolderId(folderId) {
    this.activeFolderId = folderId;
  }

  @action
  updateNewWorkspace(data, attribute) {
    this.newWorkspace[attribute] = data;
  }

  @action
  resetWorkspaceAttribute(attribute) {
    this.newWorkspace[attribute] = this.activeWorkspace[attribute];
  }

  @action
  addWorkspace(data, type) {
    const newWorkspace = new Workspace(
      data.id,
      data.account_id,
      data.name,
      data.description,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.user_access_level,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    if (type === 'single') {
      this.activeWorkspace = _.clone(newWorkspace);
      this.newWorkspace = _.clone(newWorkspace);
      AppStore.activeWorkspace = _.clone(newWorkspace);
    }
    this.workspaces = _.unionBy([newWorkspace], this.workspaces, 'id');
    this.sortWorkspaces();
  }

  @action
  removeWorkspace(data) {
    this.workspaces = _.filter(this.workspaces, o => o.id !== data.id);
  }

  @action
  addWorkspaceFolder(data) {
    const newWorkspaceFolder = new WorkspaceFolder(
      data.id,
      data.workspace_id,
      data.name,
      data.row_order,
      data.comment_count,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.workspaceFolders = _.unionBy([newWorkspaceFolder], this.workspaceFolders, 'id');
    this.sortWorkspaceFolders();
  }

  @action
  removeWorkspaceFolder(data) {
    AppStore.setAppsToUncategorized(data.id);
    this.workspaceFolders = _.filter(this.workspaceFolders, o => o.id !== data.id);
    if (this.hasFolders) {
      this.setActiveFolderId(this.workspaceFolders[0].id);
    }
  }

  @action
  createWorkspace(workspaceObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/workspaces`, workspaceObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  copyWorkspace(workspaceId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/workspaces/${workspaceId}/copy`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWorkspaces() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/workspaces`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, workspace => {
            this.addWorkspace(workspace, 'all');
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWorkspace(workspaceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/workspaces/${workspaceId}`)
        .then(response => {
          this.addWorkspace(response.data, 'single');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeWorkspace(workspaceObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/workspaces/${this.activeWorkspace.id}`, workspaceObj)
        .then(response => {
          this.addWorkspace(response.data, 'single');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  reorderWorkspace(workspaceObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/workspaces/${workspaceObj.id}`, workspaceObj)
        .then(response => {
          this.addWorkspace(response.data, 'single');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteWorkspace() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/workspaces/${this.activeWorkspace.id}`)
        .then(response => {
          this.removeWorkspace(response.data);
          this.resetStartingAttributes();
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWorkspaceFolders(workspaceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/workspace_folders?workspace_id=${workspaceId}`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, workspaceFolder => {
            this.addWorkspaceFolder(workspaceFolder);
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createWorkspaceFolder(workspaceFolderObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/workspace_folders`, workspaceFolderObj)
        .then(response => {
          this.addWorkspaceFolder(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeWorkspaceFolder(workspaceFolderObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/workspace_folders/${workspaceFolderObj.id}`, workspaceFolderObj)
        .then(response => {
          this.addWorkspaceFolder(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteWorkspaceFolder(workspaceFolderId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/workspace_folders/${workspaceFolderId}`)
        .then(response => {
          if (this.workspaceFolders.length <= 1) {
            location.reload();
          } else {
            this.removeWorkspaceFolder(response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  sortWorkspaces() {
    this.workspaces = _.orderBy(this.workspaces, ['rowOrder'], ['asc']);
  }

  @action
  sortWorkspaceFolders() {
    this.workspaceFolders = _.orderBy(this.workspaceFolders, ['rowOrder'], ['asc']);
  }

  @action
  resetStartingAttributes() {
    this.newWorkspace = new Workspace();
    this.newWorkspaceFolder = new WorkspaceFolder();
    this.uncategorizedFolder = new WorkspaceFolder(defaultFolderId, '', I18n.t('js.uncategorized'));
    this.activeWorkspace = new Workspace();
    this.activeFilter = 'published';
    this.activeTab = 'apps';
    this.activeFolderId = '';
    // this.workspaces = [];
    this.workspaceFolders = [];
  }
}

const store = new WorkspaceStore();
export default store;
