import React from 'react';
import Flexbox from 'flexbox-react';
import Utilities from '../../../utils/Utilities';
import { observer } from 'mobx-react';
import {
  AnchorButton,
  EditableText,
  HTMLSelect,
  Intent,
  Switch,
} from '@blueprintjs/core';
import classNames from 'classnames';

@observer
export default class ElementMultilineInputRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { optionsExposed: false };
  }

  toggleOptions() {
    this.setState({ optionsExposed: !this.state.optionsExposed });
  }

  render() {
    let optionRowClass = classNames('tr-hidden');
    let toggleText = I18n.t('js.show_options');
    if (this.state.optionsExposed) {
      optionRowClass = classNames('');
      toggleText = I18n.t('js.hide_options');
    }
    return [
      <tr key={this.props.input.id}>
        <td className="td-width-30">
          <EditableText
            multiline={true}
            confirmOnEnterKey={true}
            defaultValue={this.props.input.name}
            intent={Intent.DEFAULT}
            onConfirm={value =>
              this.props.handleConfirmInputChange(
                'name',
                value,
                this.props.input
              )
            }
            placeholder={I18n.t('js.click_to_edit')}
          />
        </td>
        <td className="td-width-30">
          <EditableText
            multiline={true}
            confirmOnEnterKey={true}
            defaultValue={this.props.input.placeholderText}
            intent={Intent.DEFAULT}
            onConfirm={value =>
              this.props.handleConfirmInputChange(
                'placeholderText',
                value,
                this.props.input
              )
            }
            placeholder={I18n.t('js.na')}
          />
        </td>
        <td className="td-width-40">
          <Flexbox flexGrow={1} justifyContent="flex-end">
            <AnchorButton
              className="bp3-small"
              active={this.state.optionsExposed}
              icon="settings"
              text={toggleText}
              onClick={() => this.toggleOptions()}
            />
            <AnchorButton
              className="bp3-small push-5-l"
              icon="trash"
              text={I18n.t('js.delete')}
              onClick={() =>
                this.props.handleDeleteInput(this.props.input)
              }
            />
          </Flexbox>
        </td>
      </tr>,
      <tr key={Utilities.makeId()} className={optionRowClass}>
        <td className="td-width-30">
          <Flexbox flexDirection="column">
            <Flexbox>
              <Switch
                className="bp3-control-no-margin"
                checked={this.props.input.isRequired}
                label={I18n.t('js.response_required_short')}
                onChange={() =>
                  this.props.handleConfirmInputChange(
                    'isRequired',
                    !this.props.input.isRequired,
                    this.props.input
                  )
                }
              />
            </Flexbox>
            <Flexbox marginTop="10px">
              <Switch
                className="bp3-control-no-margin"
                checked={this.props.input.displayLabel}
                label={I18n.t('js.show_label')}
                onChange={() =>
                  this.props.handleConfirmInputChange(
                    'displayLabel',
                    !this.props.input.displayLabel,
                    this.props.input
                  )
                }
                className="push-20-t"
              />
            </Flexbox>
          </Flexbox>
        </td>
        <td className="td-width-30">
          <div className="bp3-form-group">
            <label className="bp3-label">
              {I18n.t('js.label_display_type')}
            </label>
            <div className="bp3-form-content">
              <div className="bp3-select bp3-fill">
                <HTMLSelect
                  name="displayLabelType"
                  onChange={e =>
                    this.props.handleConfirmInputChange(
                      'displayLabelType',
                      e.target.value,
                      this.props.input
                    )
                  }
                  defaultValue={this.props.input.displayLabelType}
                >
                  <option value="inline">
                    {I18n.t('js.inline')}
                  </option>
                  <option value="block">{I18n.t('js.block')}</option>
                </HTMLSelect>
              </div>
            </div>
          </div>
        </td>
        <td className="td-width-40">
          <div className="bp3-form-group">
            <label className="bp3-label">
              {I18n.t('js.input_width')}
            </label>
            <div className="bp3-form-content">
              <div className="bp3-select bp3-fill">
                <HTMLSelect
                  name="displayWidth"
                  onChange={e =>
                    this.props.handleConfirmInputChange(
                      'displayWidth',
                      e.target.value,
                      this.props.input
                    )
                  }
                  defaultValue={this.props.input.displayWidth}
                >
                  <option value="full">{I18n.t('js.full')}</option>
                  <option value="half">{I18n.t('js.half')}</option>
                </HTMLSelect>
              </div>
            </div>
          </div>
        </td>
      </tr>,
    ];
  }
}
