import React from 'react';
import Flexbox from 'flexbox-react';
import { NonIdealState } from '@blueprintjs/core';
import ColorDefinitions from '../../static/ColorDefinitions';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

export default class ColorSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      colors: _.sortBy(ColorDefinitions.ptColors, ['id', 'asc']),
      query: '',
      activeColor: '',
    };
  }

  handleFilterChange(e) {
    const query = e.target.value.toLowerCase();
    let newColors = [];
    if (query === '') {
      newColors = _.sortBy(ColorDefinitions.ptColors, ['id', 'asc']);
    } else {
      newColors = _.filter(_.sortBy(ColorDefinitions.ptColors), color => _.includes(color.name, query));
    }
    this.setState({ query: e.target.value, colors: newColors });
  }

  resetFilter(e) {
    e.preventDefault();
    this.setState({
      query: '',
      colors: _.sortBy(ColorDefinitions.ptColors, ['id', 'asc']),
    });
  }

  setActiveColor(color) {
    this.setState({ activeColor: color });
    this.props.handleChangeColor(color.code);
  }

  renderColor(color) {
    let containerClass = classNames('bp3-card bp3-interactive');
    let bgStyle = {
      backgroundColor: `rgba(${Utilities.hexToRgb(_.replace(color.code, '#', ''))}, 0.5)`,
    };
    const textClass = classNames('text-white');
    const textStyle = {
      fontSize: 12,
    };
    if (color === this.state.activeColor || color.code === this.props.activeColor) {
      containerClass = classNames('bp3-card bp3-interactive bp3-elevation-4');
      bgStyle = {
        backgroundColor: color.code,
      };
    }
    return (
      <Flexbox
        flexDirection="column"
        width="160px"
        flexGrow={1}
        alignContent="space-between"
        justifyContent="space-between"
        padding="10px"
        className={containerClass}
        style={bgStyle}
        onClick={() => this.setActiveColor(color)}
        marginBottom="10px"
        marginRight="10px"
      >
        <Flexbox>
          <small className={textClass}>{color.name}</small>
        </Flexbox>
        <Flexbox>
          <p className={textClass} style={textStyle}>
            {color.code}
          </p>
        </Flexbox>
      </Flexbox>
    );
  }

  renderFilterColors() {
    return (
      <Flexbox flexDirection="column">
        <div className="bp3-input-group">
          <span className="bp3-icon bp3-icon-search" />
          <input
            autoComplete="off"
            type="text"
            className="bp3-input"
            value={this.state.query}
            onChange={this.handleFilterChange.bind(this)}
            placeholder={I18n.t('js.filter_colors')}
          />
          <button className="bp3-button bp3-minimal bp3-intent-primary bp3-icon-cross" onClick={this.resetFilter.bind(this)} />
        </div>
      </Flexbox>
    );
  }

  renderColorOverride() {
    return (
      <Flexbox flexDirection="column" marginTop="20px">
        <div className="bp3-input-group">
          <span className="bp3-icon bp3-icon-tint" style={{ color: this.props.activeColor }} />
          <input
            autoComplete="off"
            type="text"
            className="bp3-input"
            value={this.props.activeColor}
            onChange={e => this.props.handleChangeColor(e.target.value)}
            placeholder={I18n.t('js.color')}
          />
        </div>
      </Flexbox>
    );
  }

  renderColorList() {
    if (this.state.colors.length === 0) {
      return (
        <Flexbox flexDirection="column" marginTop="10px" height="400px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_is_nothing_to_show_right_now')}
            icon="disable"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" marginTop="10px">
        <Scrollbars
          autoHide={true}
          style={{
            height: 250,
          }}
        >
          <Flexbox flexDirection="row" flexWrap="wrap" marginTop="10px">
            {_.map(this.state.colors, color => (
              <Flexbox key={color.id}>{this.renderColor(color)}</Flexbox>
            ))}
          </Flexbox>
        </Scrollbars>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        {this.renderFilterColors()}
        {this.renderColorOverride()}
        {this.renderColorList()}
      </Flexbox>
    );
  }
}
