/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class Sync {
  @observable
  id;
  @observable
  duration;
  @observable
  status;
  @observable
  startedAt;
  @observable
  syncs;

  constructor(id, duration, status, started_at, syncs) {
    this.id = id || '';
    this.duration = duration || '';
    this.status = status || '';
    this.startedAt = started_at || '';
    this.syncs = syncs || '';
  }
}
