import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import TeamEditRelationshipsParents from './TeamEditRelationshipsParents';
import TeamEditRelationshipsChildren from './TeamEditRelationshipsChildren';
import _ from 'lodash';

@inject('CurrentUserStore', 'TeamsStore', 'ToastStore')
@observer
export default class TeamEditRelationships extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isOpen: false };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    let totalLength =
      this.props.TeamsStore.parentTeamsToCreate.length +
      this.props.TeamsStore.parentTeamsToDelete.length +
      this.props.TeamsStore.childTeamsToCreate.length +
      this.props.TeamsStore.childTeamsToDelete.length;
    this.determineCloseDialog(totalLength);
    _.map(this.props.TeamsStore.parentTeamsToCreate, parentTeam => {
      this.props.TeamsStore.createTeamRelationship(
        parentTeam.id,
        this.props.TeamsStore.activeTeam.id
      )
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleLoading();
        });
    });
    _.map(this.props.TeamsStore.childTeamsToCreate, childTeam => {
      this.props.TeamsStore.createTeamRelationship(
        this.props.TeamsStore.activeTeam.id,
        childTeam.id
      )
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleLoading();
        });
    });
    _.map(this.props.TeamsStore.parentTeamsToDelete, parentTeam => {
      this.props.TeamsStore.deleteTeamRelationship(
        parentTeam.id,
        this.props.TeamsStore.activeTeam.id
      )
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleLoading();
        });
    });
    _.map(this.props.TeamsStore.childTeamsToDelete, childTeam => {
      this.props.TeamsStore.deleteTeamRelationship(
        this.props.TeamsStore.activeTeam.id,
        childTeam.id
      )
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleLoading();
        });
    });
  }

  determineCloseDialog(length) {
    if (length === 0) {
      this.props.TeamsStore.parentTeamsToCreate = [];
      this.props.TeamsStore.parentTeamsToDelete = [];
      this.props.TeamsStore.childTeamsToCreate = [];
      this.props.TeamsStore.childTeamsToDelete = [];
      this.props.TeamsStore.activeTeamRelationshipsParents = [];
      this.props.TeamsStore.activeTeamRelationshipsChildren = [];
      this.props.TeamsStore.fetchTeam(
        this.props.TeamsStore.activeTeam.id
      )
        .then(() => {
          this.toggleLoading();
          this.toggleOpen();
        })
        .catch(() => {
          this.toggleLoading();
        });
    }
  }

  handleCancel() {
    this.toggleOpen();
    this.props.TeamsStore.parentTeamsToCreate = [];
    this.props.TeamsStore.parentTeamsToDelete = [];
    this.props.TeamsStore.childTeamsToCreate = [];
    this.props.TeamsStore.childTeamsToDelete = [];
    this.props.TeamsStore.activeTeamRelationshipsParents = [];
    this.props.TeamsStore.activeTeamRelationshipsChildren = [];
    this.props.TeamsStore.fetchTeam(
      this.props.TeamsStore.activeTeam.id
    );
  }

  render() {
    const totalLength =
      this.props.TeamsStore.parentTeamsToCreate.length +
      this.props.TeamsStore.parentTeamsToDelete.length +
      this.props.TeamsStore.childTeamsToCreate.length +
      this.props.TeamsStore.childTeamsToDelete.length;
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* ignore jsx-a11y as toggle functionality included eslewhere for */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.team_relationships')}
            </h4>
            <p className="noselect">
              {I18n.t('js.team_relationships_text')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form>
            <Flexbox
              flexDirection="column"
              flexGrow={1}
              className="bp3-form-group"
            >
              <label className="bp3-label">
                {I18n.t('js.select_parent_team_label_individual', {
                  name: this.props.TeamsStore.activeTeam.name,
                })}
              </label>
              <Flexbox>
                <TeamEditRelationshipsParents />
              </Flexbox>
            </Flexbox>
            <Flexbox
              flexDirection="column"
              flexGrow={1}
              className="bp3-form-group"
            >
              <label className="bp3-label">
                {I18n.t('js.select_child_team_label_individual', {
                  name: this.props.TeamsStore.activeTeam.name,
                })}
              </label>
              <Flexbox>
                <TeamEditRelationshipsChildren />
              </Flexbox>
            </Flexbox>
            <Flexbox marginTop="20px">
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  disabled={totalLength === 0}
                  onClick={this.handleSubmit.bind(this)}
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button
                  className="push-10-l"
                  icon="cross"
                  text={I18n.t('js.cancel_changes')}
                  onClick={this.handleCancel.bind(this)}
                />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
