/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from '../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import User from '../../models/UserModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import classNames from 'classnames';
import nanoid from 'nanoid';
import _ from 'lodash';

axiosCancel(axios);

const UserMultiSelect = MultiSelect.ofType(User);

@inject('UserProfileRelationshipsStore', 'ToastStore', 'UserProfileStore')
@observer
export default class UserRelationshipsNewRelationshipSelectParents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      superUserRelationshipsToCreate: [],
      superUserRelationshipsToDelete: [],
      query: '',
      searchUsers: [],
      isLoading: false,
      requestId: nanoid(),
    };
  }

  componentDidMount() {
    this.setState({
      superUserRelationshipsToCreate: this.props.UserProfileRelationshipsStore.superOrdinateUserDelta,
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.setState({
      searchUsers: _.unionBy([newUser], this.state.searchUsers, 'id'),
    });
  }

  searchUsers(e) {
    const query = e.target.value;
    const requestId = nanoid();
    this.setState({
      searchUsers: [],
      query,
      requestId,
    });
    const minQueryLength = 1;
    if (query.length > minQueryLength) {
      this.toggleLoading();
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${query}`, {
          requestId: 'userSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            if (user.id !== this.props.UserProfileStore.user.id && this.state.requestId === requestId) {
              this.addUser(user);
            }
            total--;
          });
          if (total === 0) {
            this.toggleLoading();
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.toggleLoading();
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            this.toggleLoading();
          }
        });
    }
  }

  renderMenuItem(item) {
    return (
      <Flexbox flexDirection="row" alignContent="center">
        <Flexbox>
          <Avatar src={item.imageFileName} size={22} round={true} />
        </Flexbox>
        <Flexbox marginLeft="10px" marginTop="2px">
          {item.fullName}
        </Flexbox>
      </Flexbox>
    );
  }

  renderUser(item, details) {
    let menuClass = classNames('popover-larger');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={this.renderMenuItem(item, details)} />;
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              <span className="bp3-icon-search push-5-r" />
              <span>{I18n.t('js.start_typing')}</span>
            </span>
          }
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              <span className="bp3-icon-search push-5-r" />
              <span>{I18n.t('js.fetching_data')}</span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            <span className="bp3-icon-people push-5-r" />
            <span>{I18n.t('js.no_users_found')}</span>
          </span>
        }
      />
    );
  }

  renderTag(user) {
    return <span id={user.id}>{user.fullName}</span>;
  }

  handleUserSelect(user) {
    this.setState({
      superUserRelationshipsToCreate: _.concat(this.state.superUserRelationshipsToCreate.slice(), [user]),
    });
    // Check if user exists in list to be deleted. If so, just remove it from that list.
    this.props.UserProfileRelationshipsStore.superUserRelationshipsToDelete = _.filter(
      this.props.UserProfileRelationshipsStore.superUserRelationshipsToDelete,
      o => o.id !== user.id
    );
    // Check if user exists in existing list. If not, add it to the list to be created in the store.
    const findUser = _.find(this.props.UserProfileRelationshipsStore.superOrdinateUserDelta, o => o.id === user.id);
    if (!findUser) {
      this.props.UserProfileRelationshipsStore.superUserRelationshipsToCreate = _.unionBy(
        [user],
        this.props.UserProfileRelationshipsStore.superUserRelationshipsToCreate,
        'id'
      );
    }
    this.handlePopoverClose();
  }

  handlePopoverClose() {
    this.setState({ searchUsers: [], query: '' });
  }

  handleUserRemove(user) {
    const userId = user.props.id;
    const newUsers = _.filter(this.state.superUserRelationshipsToCreate.slice(), o => o.id !== userId);
    this.setState({ superUserRelationshipsToCreate: newUsers });
    // Check if user exists in list to be added. If so, just remove it from that list.
    this.props.UserProfileRelationshipsStore.superUserRelationshipsToCreate = _.filter(
      this.props.UserProfileRelationshipsStore.superUserRelationshipsToCreate,
      o => o.id !== userId
    );
    // Check if user exists in existing list. If it does, add it to the list to be deleted in the store.
    const findUser = _.find(this.props.UserProfileRelationshipsStore.superOrdinateUserDelta, o => o.id === userId);
    if (findUser) {
      this.props.UserProfileRelationshipsStore.superUserRelationshipsToDelete = _.unionBy(
        [findUser],
        this.props.UserProfileRelationshipsStore.superUserRelationshipsToDelete,
        'id'
      );
    }
  }

  filterUser() {
    let possibleUsers = _.filter(this.state.searchUsers, o => o.id !== this.props.UserProfileStore.user.id);
    // filter out selected users
    _.map(this.state.superUserRelationshipsToCreate, userToCreate => {
      possibleUsers = _.filter(possibleUsers, o => o.id !== userToCreate.id);
    });
    possibleUsers = _.orderBy(possibleUsers, ['fullName'], ['asc']);
    return possibleUsers;
  }

  renderRightElement() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    return (
      <UserMultiSelect
        className="max-width"
        resetOnSelect={true}
        items={this.state.searchUsers}
        itemListPredicate={this.filterUser.bind(this)}
        selectedItems={this.state.superUserRelationshipsToCreate}
        tagRenderer={this.renderTag.bind(this)}
        itemRenderer={this.renderUser.bind(this)}
        onItemSelect={this.handleUserSelect.bind(this)}
        tagInputProps={{
          inputProps: {
            placeholder: I18n.t('js.search_users'),
            onChange: this.searchUsers.bind(this),
          },
          rightElement: this.renderRightElement(),
          className: 'bp3-tag-input',
          onRemove: this.handleUserRemove.bind(this),
        }}
        popoverProps={{
          usePortal: false,
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
