import { computed, observable } from 'mobx';
import Utilities from '../../utils/Utilities';

export default class ElementGridRow {
  @observable
  id;
  @observable
  elementGridId;
  @observable
  name;
  @observable
  rowOrder;

  constructor(id, elementGridId, name, rowOrder) {
    this.id = id || Utilities.makeId();
    this.elementGridId = elementGridId || '';
    this.name = name || '';
    this.rowOrder = rowOrder || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }
}
