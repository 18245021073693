import { action, computed, observable } from 'mobx';
import _ from 'lodash';

class TermsStore {
  @observable
  isLoading = true;
  @observable
  selectedTerms = '';
  @observable
  terms = [];

  @computed
  get hasNoData() {
    return this.terms.length === 0;
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  setActiveTerms(terms) {
    this.selectedTerms = terms;
  }
  @action
  addTerms(terms) {
    this.terms = _.unionBy([terms], this.terms, 'sys.id');
    this.sortTerms();
  }

  @action
  sortTerms() {
    let newTerms = this.terms;
    newTerms = _.orderBy(newTerms, ['fields.sortOrder'], ['asc']);
    this.terms = newTerms;
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.selectedTerms = '';
    this.terms = [];
  }
}

const store = new TermsStore();
export default store;
