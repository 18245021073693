import * as React from 'react';
import { action, observable, computed } from 'mobx';
import DataViewStore from '../stores/DataViewStore';
import AppStore from '../stores/AppStore';
import FormulaStore from '../stores/FormulaStore';
import moment from 'moment';
import Utilities from '../utils/Utilities';
import ColorDefinitions from '../static/ColorDefinitions';
import { create, all } from 'mathjs';
import nanoid from 'nanoid';

export default class ReportChartModel {
  @observable id;
  @observable name;
  @observable type;
  @observable icon;
  @observable options;
  @observable dataConfig;

  constructor({ id, name, type, icon, options, dataConfig }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.icon = icon;
    this.options = options === '' ? this.defaultOptions() : options;
    this.dataConfig = dataConfig === '' ? this.defaultDataConfig(type) : dataConfig;
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  formatDataForChart(data) {
    try {
      let formattedData = {};
      switch (this.type) {
        case 'line':
          formattedData = this.formatDataForLineChart(data);
          break;
        case 'bar':
          formattedData = this.formatDataForBarChart(data);
          break;
        case 'pie':
          formattedData = this.formatDataForPieChart(data);
          break;
        case 'polar':
          formattedData = this.formatDataForPieChart(data);
          break;
        case 'radar':
          formattedData = this.formatDataForRadarChart(data);
          break;
        case 'metric':
          formattedData = this.formatDataForMetricChart(data);
          break;
        default:
          throw new Error(`Data could not be formatted for ${this.type} chart`);
      }
      return formattedData;
    } catch (e) {
      return false;
    }
  }

  mergedOptionsForChart() {
    let options = {};
    switch (this.type) {
      case 'line':
        options = this.mergedOptionsForLineChart();
        break;
      case 'bar':
        options = this.mergedOptionsForBarChart();
        break;
      case 'pie':
        options = this.mergedOptionsForPieChart();
        break;
      case 'polar':
        options = this.mergedOptionsForPieChart();
        break;
      case 'radar':
        options = this.mergedOptionsForRadarChart();
        break;
      case 'metric':
        options = this.mergedOptionsForMetricChart();
        break;
      default:
        throw new Error(`Options could not be generated for ${this.type} chart`);
        break;
    }
    return options;
  }

  defaultDataConfig(type) {
    switch (type) {
      case 'line':
        return this.defaultLineDataConfig();
      case 'bar':
        return this.defaultBarDataConfig();
      case 'pie':
        return this.defaultPieDataConfig();
      case 'polar':
        return this.defaultPieDataConfig();
      case 'radar':
        return this.defaultRadarDataConfig();
      case 'metric':
        return this.defaultMetricDataConfig();
      default:
        return {};
    }
  }

  defaultOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'top',
        align: 'center',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    };
  }

  // Line Chart =======================================================================================

  defaultLineDataConfig() {
    let itemName = 'Item';
    let itemColor = '#0000FA';
    let startingYAttribute = 'item_attributes#id';
    let startingXAttribute = 'item_attributes#created_at';
    if (DataViewStore.dataViewLocation === 'app') {
      itemName = AppStore.activeApp.itemPlural;
      itemColor = AppStore.activeApp.color;
      startingYAttribute = 'item_attributes#id';
      startingXAttribute = 'item_attributes#created_at';
    }
    if (DataViewStore.dataViewLocation === 'profile') {
      itemName = 'Users';
      itemColor = '#0000FA';
      startingYAttribute = 'user_attributes#id';
      startingXAttribute = 'user_attributes#created_at';
    }

    return {
      groupBy: 'month',
      annotation: '',
      xAxisEnabled: true,
      xAxisLabelEnabled: true,
      xAxisGridEnabled: false,
      xAxisLabel: 'Date',
      yAxisElementId: startingYAttribute,
      method: 'default',
      yAxisLabel: itemName,
      yAxisEnabled: true,
      yAxisLabelEnabled: true,
      yAxisGridEnabled: false,
      datasets: [
        {
          id: nanoid(),
          name: itemName,
          elementId: startingXAttribute,
          color: itemColor,
          backgroundColor: `rgba(${Utilities.hexToRgb(itemColor)}, 0.75)`,
          fill: true,
        },
      ],
    };
  }

  mergedOptionsForLineChart() {
    const { dataConfig } = this;
    const additionalOptions = {
      title: {
        display: dataConfig.annotation.length > 0,
        text: dataConfig.annotation,
        position: 'bottom',
        fontStyle: 'normal',
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: dataConfig.xAxisGridEnabled,
            },
            display: dataConfig.xAxisEnabled,
            scaleLabel: {
              display: dataConfig.xAxisLabelEnabled,
              labelString: dataConfig.xAxisLabel,
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: dataConfig.yAxisGridEnabled,
            },
            display: dataConfig.yAxisEnabled,
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: dataConfig.yAxisLabelEnabled,
              labelString: dataConfig.yAxisLabel,
              fontStyle: 'bold',
            },
          },
        ],
      },
    };
    const newOptions = _.merge(this.options, additionalOptions);
    return newOptions;
  }

  formatDataForLineChart(data) {
    // Line chart has numbers on yAxis and dates on xAxis
    const { dataConfig } = this;
    let labels = [];
    let datasets = [];
    let rawData = [];
    let enhancedData = [];

    _.map(dataConfig.datasets, dataset => {
      _.map(data, item => {
        let xAxisDatum = _.find(item, o => o.column_id === dataset.elementId);
        let yAxisDatum = _.find(item, o => o.column_id === dataConfig.yAxisElementId);

        if (xAxisDatum.column_value) {
          let numberValue = 0;
          if (yAxisDatum.column_id === 'item_attributes#id' || yAxisDatum.column_id == 'user_attributes#id') {
            numberValue = 1;
          } else {
            let int = _.parseInt(yAxisDatum.column_number_value);
            numberValue = isNaN(int) ? 0 : int;
          }
          rawData.push({
            date: moment
              .utc(xAxisDatum.column_value)
              .startOf('day')
              .unix(),
            number: numberValue,
            elementId: dataset.elementId,
          });
        }
      });
    });
    rawData = _.orderBy(rawData, ['date'], ['asc']);

    const startDate = moment.unix(_.head(rawData).date);
    const endDate = moment.unix(_.last(rawData).date);
    const dateRange = Utilities.enumerateDaysBetweenDates(startDate.subtract(1, 'day'), endDate.add(1, 'day'));

    _.map(dataConfig.datasets, dataset => {
      _.map(dateRange, date => {
        const findDataPoints = _.filter(rawData, o => {
          return o.date === moment.utc(date).unix() && o.elementId === dataset.elementId;
        });
        if (findDataPoints.length > 0) {
          const sum = _.sumBy(findDataPoints, 'number');
          enhancedData.push({
            date: moment.utc(date),
            number: sum,
            elementId: dataset.elementId,
          });
        } else {
          enhancedData.push({
            date: moment.utc(date),
            number: 0,
            elementId: dataset.elementId,
          });
        }
      });
    });

    const groupedData = _.groupBy(enhancedData, 'elementId');
    _.map(dataConfig.datasets, dataset => {
      const findDataSet = groupedData[dataset.elementId];
      const grouped = _.groupBy(findDataSet, o => o.date.startOf(dataConfig.groupBy));

      let data = [];
      _.mapKeys(grouped, (value, key) => {
        const sum = _.sumBy(value, 'number');

        switch (dataConfig.groupBy) {
          case 'day':
            labels.push(moment.utc(key).format('DD-MMM-YYYY'));
            break;
          case 'week':
            labels.push(moment.utc(key).format('DD-MMM'));
            break;
          case 'month':
            labels.push(moment.utc(key).format('MMM'));
            break;
          case 'year':
            labels.push(moment.utc(key).format('YYYY'));
            break;
        }
        data.push(sum);
      });

      if (dataConfig.method === 'cumulative') {
        let newData = [];
        _.map(data, (datum, index) => {
          let newDatum = datum;
          if (index > 0) {
            newDatum = datum + newData[index - 1];
          }
          newData.push(newDatum);
        });
        data = newData;
      }

      datasets.push({
        label: dataset.name,
        borderColor: dataset.color,
        backgroundColor: dataset.backgroundColor,
        fill: dataset.fill,
        data: data,
      });
    });

    labels = _.uniq(labels);

    return {
      labels: labels,
      datasets: datasets,
    };
  }

  // Bar Chart =======================================================================================

  defaultBarDataConfig() {
    let itemName = 'Item';
    let itemColor = '#0000FA';
    let startingYAttribute = 'item_attributes#id';
    let startingXAttribute = 'item_attributes#created_at';
    if (DataViewStore.dataViewLocation === 'app') {
      itemName = AppStore.activeApp.itemPlural;
      itemColor = AppStore.activeApp.color;
      startingYAttribute = 'item_attributes#id';
      startingXAttribute = 'item_attributes#created_at';
    }
    if (DataViewStore.dataViewLocation === 'profile') {
      itemName = 'Users';
      itemColor = '#0000FA';
      startingYAttribute = 'user_attributes#id';
      startingXAttribute = 'user_attributes#created_at';
    }

    return {
      type: 'bar',
      annotation: '',
      stacked: false,
      yAxisElementId: startingYAttribute,
      method: 'default',
      yAxisLabel: itemName,
      yAxisEnabled: true,
      yAxisLabelEnabled: true,
      yAxisGridEnabled: false,
      ticks: {
        beginAtZero: true,
      },
      datasets: [
        {
          id: nanoid(),
          name: itemName,
          elementId: startingXAttribute,
          color: itemColor,
          borderColor: itemColor,
          borderWidth: 1,
          backgroundColor: `rgba(${Utilities.hexToRgb(itemColor)}, 0.75)`,
          fill: true,
        },
      ],
    };
  }

  mergedOptionsForBarChart() {
    const { dataConfig } = this;
    const additionalOptions = {
      type: dataConfig.type,
      title: {
        display: dataConfig.annotation.length > 0,
        text: dataConfig.annotation,
        position: 'bottom',
        fontStyle: 'normal',
      },
      elements: {
        rectangle: {
          borderWidth: 2,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: dataConfig.stacked,
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: dataConfig.xAxisGridEnabled,
            },
            ticks: {
              beginAtZero: true,
            },
            display: dataConfig.xAxisEnabled,
            scaleLabel: {
              display: dataConfig.xAxisLabelEnabled,
              labelString: dataConfig.xAxisLabel,
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            stacked: dataConfig.stacked,
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: dataConfig.yAxisGridEnabled,
            },
            display: dataConfig.yAxisEnabled,
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: dataConfig.yAxisLabelEnabled,
              labelString: dataConfig.yAxisLabel,
              fontStyle: 'bold',
            },
          },
        ],
      },
    };
    const newOptions = _.merge(this.options, additionalOptions);
    return newOptions;
  }

  formatDataForBarChart(data) {
    // Line chart has numbers on yAxis and dates on xAxis
    const { dataConfig } = this;
    let labels = [];
    let datasets = [];

    _.map(dataConfig.datasets, dataset => {
      let numberResults = [];
      let rawData = [];

      _.map(data, item => {
        let xAxisDatum = _.find(item, o => o.column_id === dataset.elementId);
        let yAxisDatum = _.find(item, o => o.column_id === dataConfig.yAxisElementId);

        if (xAxisDatum.column_value) {
          let numberValue = 0;
          if (yAxisDatum.column_id === 'item_attributes#id' || yAxisDatum.column_id == 'user_attributes#id') {
            numberValue = 1;
          } else {
            let int = _.parseInt(yAxisDatum.column_number_value);
            numberValue = isNaN(int) ? 0 : int;
          }
          rawData.push({ label: xAxisDatum.column_value, number: dataConfig.method === 'default' ? 1 : numberValue });
        }
      });

      const result = _.groupBy(rawData, 'label');

      _.mapKeys(result, (value, key) => {
        // This is a mapping of each grouping
        if (!labels.includes(key)) {
          labels.push(key);
        }
        let calculatedResultForGroup = 0;
        if (dataConfig.method == 'average') {
          calculatedResultForGroup = _.meanBy(value, val => {
            return val.number;
          });
        } else {
          _.map(value, val => {
            calculatedResultForGroup += val.number;
          });
        }
        numberResults.push(calculatedResultForGroup);
      });

      datasets.push({
        label: dataset.name,
        borderColor: dataset.color,
        backgroundColor: dataset.backgroundColor,
        fill: dataset.fill,
        data: numberResults,
      });
    });

    return {
      labels: labels,
      datasets: datasets,
    };
  }

  // Pie Chart =======================================================================================

  defaultPieDataConfig() {
    let startingAttribute = 'item_attributes#status';
    if (DataViewStore.dataViewLocation === 'app') {
      startingAttribute = 'item_attributes#status';
    }
    if (DataViewStore.dataViewLocation === 'profile') {
      startingAttribute = 'user_attributes#email';
    }

    return {
      annotation: '',
      elementId: startingAttribute,
      datasets: [],
      availableColors: [],
      cutoutPercentage: 0,
      includeNullValues: true,
    };
  }

  mergedOptionsForPieChart() {
    const { dataConfig } = this;
    const additionalOptions = {
      title: {
        display: dataConfig.annotation.length > 0,
        text: dataConfig.annotation,
        position: 'bottom',
        fontStyle: 'normal',
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20,
        },
      },
      cutoutPercentage: dataConfig.cutoutPercentage,
    };
    const newOptions = _.merge(this.options, additionalOptions);
    return newOptions;
  }

  formatDataForPieChart(data) {
    // First, we have to extract the colors (if present) and set these as config options
    let { dataConfig } = this;
    let availableColors = dataConfig.availableColors ? dataConfig.availableColors : [];
    _.map(data, item => {
      let findDatum = _.find(item, o => o.column_id === dataConfig.elementId);
      // First determine if we are looking at a grid element
      if (findDatum && findDatum.column_value && findDatum.column_value.includes('|')) {
        const splitValue = findDatum.column_value.split(`|`);
        _.map(splitValue, dataPoint => {
          let label = dataPoint;
          if (dataPoint.includes(': ')) {
            // Grid
            const splitPoint = dataPoint.split(': ');
            label = splitPoint[1];
          }
          const findColor = _.find(dataConfig.availableColors, o => o.label === label);
          if (!findColor) {
            availableColors.push({
              label: label,
              color: Utilities.getRandomColor().code,
              elementId: findDatum.column_id,
            });
          }
        });
      } else {
        if (findDatum) {
          const findAvailableColor = _.find(availableColors, o => o.elementId == findDatum.column_id);
          if (!findAvailableColor) {
            availableColors.push({
              label: findDatum.column_value,
              color: findDatum.column_color ? findDatum.column_color : Utilities.getRandomColor().code,
              elementId: findDatum.column_id,
            });
          }
        }
      }
    });
    this.dataConfig = _.merge(this.dataConfig, { availableColors: _.uniqBy(availableColors, 'label') });

    let labels = [];
    let rawData = [];
    let newDataSetData = [];
    let newDataSetBackgroundColors = [];
    let newDataSetBorderColors = [];

    _.map(data, item => {
      let findDatum = _.find(item, o => o.column_id === dataConfig.elementId);
      if (findDatum && findDatum.column_value && findDatum.column_value.includes('|')) {
        const splitValue = findDatum.column_value.split(`|`);
        _.map(splitValue, dataPoint => {
          let label = dataPoint;
          if (dataPoint.includes(': ')) {
            // Grid
            const splitPoint = dataPoint.split(': ');
            label = splitPoint[1];
          }
          const findColor = _.find(dataConfig.availableColors, o => o.label === label);
          if (findColor) {
            rawData.push({ label: label, color: findColor.color });
          } else {
            rawData.push({ label: label, color: Utilities.getRandomColor().code });
          }
        });
      } else {
        if (findDatum) {
          const findColor = _.find(dataConfig.availableColors, o => o.label === findDatum.column_value);
          if (findColor) {
            if (!dataConfig.includeNullValues) {
              if (findDatum.column_value) {
                rawData.push({ label: findDatum.column_value, color: findColor.color });
              }
            } else {
              if (findDatum.column_value) {
                rawData.push({ label: findDatum.column_value, color: findColor.color });
              } else {
                rawData.push({ label: 'Blank', color: '#808080' });
              }
            }
          } else {
            const color = Utilities.getRandomColor().code;
            availableColors.push({
              label: findDatum.column_value,
              color: color,
              elementId: findDatum.column_id,
            });
            rawData.push({ label: findDatum.column_value, color: color });
          }
        }
      }
    });

    rawData = _.filter(rawData, o => o.label != null && o.color != null);
    const result = _.groupBy(rawData, 'label');

    _.mapKeys(result, (value, key) => {
      labels.push(`${key} (${_.round((value.length / rawData.length) * 100, 1)}%)`);
      newDataSetData.push(value.length);
      newDataSetBackgroundColors.push(`rgba(${Utilities.hexToRgb(value[0].color)}, 0.75)`);
      newDataSetBorderColors.push(value[0].color);
    });

    return {
      labels: labels,
      datasets: [
        {
          data: newDataSetData,
          backgroundColor: newDataSetBackgroundColors,
          borderColor: newDataSetBorderColors,
        },
      ],
    };
  }

  // Radar Chart =======================================================================================

  defaultRadarDataConfig() {
    let itemName = 'Item';
    let itemColor = '#0000FA';
    let startingAttribute = 'item_attributes#status';
    if (DataViewStore.dataViewLocation === 'app') {
      itemName = AppStore.activeApp.itemPlural;
      itemColor = AppStore.activeApp.color;
      startingAttribute = 'item_attributes#status';
    }
    if (DataViewStore.dataViewLocation === 'profile') {
      itemName = 'Users';
      itemColor = '#0000FA';
      startingAttribute = 'user_attributes#created_at';
    }

    return {
      groupByElementId: startingAttribute,
      availableColors: ['#DB2C6F', '#7157D9', '#00B3A4', '#9BBF30', '#D99E0B', '#29A634', '#2965CC', '#8F398F', '#D13913'],
      annotation: '',
      suggestedMin: '',
      suggestedMax: '',
      datasets: [],
    };
  }

  mergedOptionsForRadarChart() {
    const { dataConfig } = this;
    const additionalOptions = {
      title: {
        display: dataConfig.annotation.length > 0,
        text: dataConfig.annotation,
        position: 'bottom',
        fontStyle: 'normal',
      },
      scale: {
        ticks: {
          beginAtZero: true,
          suggestedMin: dataConfig.suggestedMin,
          suggestedMax: dataConfig.suggestedMax,
          fontStyle: 'bold',
        },
      },
    };
    const newOptions = _.merge(this.options, additionalOptions);
    return newOptions;
  }

  formatDataForRadarChart(data) {
    const { dataConfig } = this;
    let labels = [];
    let datasets = [];
    let rawData = [];

    _.map(dataConfig.datasets, dataset => {
      // First determine if we are looking at a grid element
      const findExample = _.find(data[0], o => o.column_id === dataset.elementId);
      if (findExample.column_value && findExample.column_value.includes('|')) {
        // We are looking at a grid element
        _.map(data, item => {
          _.map(item, datum => {
            if (datum.column_id === dataset.elementId) {
              const splitValue = datum.column_number_value.split(`|`);
              _.map(splitValue, dataPoint => {
                const splitPoint = dataPoint.split(': ');
                const label = splitPoint[0];
                labels.push(label);

                const groupedDatum = _.find(item, o => o.column_id === dataConfig.groupByElementId);
                const numberValue = parseFloat(splitPoint[1]);
                const newRawData = {
                  datasetName: groupedDatum.column_value,
                  datasetLabel: label,
                  numberValue: numberValue ? numberValue : 0,
                };
                rawData.push(newRawData);
              });
            }
          });
        });
      } else {
        labels.push(dataset.name);
        _.map(data, item => {
          _.map(item, datum => {
            if (datum.column_id === dataset.elementId) {
              const groupedDatum = _.find(item, o => o.column_id === dataConfig.groupByElementId);
              const numberValue = parseFloat(datum.column_number_value);
              const newRawData = {
                datasetName: groupedDatum.column_value,
                datasetLabel: datum.column_name,
                numberValue: datum.column_number_value ? numberValue : 0,
              };
              rawData.push(newRawData);
            }
          });
        });
      }
    });
    labels = _.uniq(labels);
    const groupedByName = _.groupBy(rawData, 'datasetName');
    const colors = ColorDefinitions.ptColors;
    let colorIndex = 0;

    _.mapKeys(groupedByName, (value, key) => {
      // Colors for the dataset
      let color = dataConfig.availableColors[colorIndex];
      if (!color) {
        color = Utilities.getRandomColor().code;
      }
      colorIndex += 1;
      let findColorDatum = null;
      _.map(data, item => {
        _.map(item, datum => {
          if (datum.column_id === dataConfig.groupByElementId) {
            if (datum.column_value === key) {
              if (datum.column_color) {
                color = datum.column_color;
              }
            }
          }
        });
      });

      // Data for the dataset
      const newData = [];
      const groupedValues = _.groupBy(value, 'datasetLabel');

      _.mapKeys(groupedValues, (key, val) => {
        const average = Math.round(_.meanBy(key, 'numberValue') * 100) / 100;
        newData.push(average);
      });
      datasets.push({
        label: key,
        borderColor: color,
        backgroundColor: `rgba(${Utilities.hexToRgb(color)}, 0.75)`,
        fill: true,
        data: newData,
      });
    });

    return {
      labels: labels,
      datasets: datasets,
    };
  }

  // Metric Chart =======================================================================================

  defaultMetricDataConfig() {
    return {
      annotation: '',
      fontSize: 80,
      prefix: '',
      suffix: '',
      formula: '',
      decimalPlaces: 2,
      useCommas: false,
    };
  }

  mergedOptionsForMetricChart() {
    const { dataConfig } = this;
    const additionalOptions = {};
    const newOptions = _.merge(this.options, additionalOptions);
    return newOptions;
  }

  formatDataForMetricChart(data) {
    const { dataConfig } = this;
    const math = create(all, {});
    let resultObj = FormulaStore.analyzeFormula(this.dataConfig.formula, data);
    if (typeof resultObj.result === 'number') {
      resultObj.result = math.round(resultObj.result, dataConfig.decimalPlaces);
    }
    if (dataConfig.useCommas) {
      let num_parts = resultObj.result.toString().split('.');
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      resultObj.result = num_parts.join('.');
    }
    return resultObj;
  }
}
