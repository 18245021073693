/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import ToastStore from '../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import User from '../../models/UserModel';
import { Button, Classes, MenuItem, PopoverInteractionKind, Spinner } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import nanoid from 'nanoid';
import _ from 'lodash';

const UserSelect = Select.ofType(User);

@inject('PermissionStore', 'ToastStore')
@observer
export default class PermissionAddUserPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchUsers: [],
      isLoading: false,
      requestId: nanoid(),
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.setState({
      searchUsers: _.unionBy([newUser], this.state.searchUsers, 'id'),
    });
    this.sortSearchUsers();
  }

  sortSearchUsers() {
    let newUsers = this.state.searchUsers;
    newUsers = _.orderBy(newUsers, ['fullName'], ['asc']);
    this.setState({ searchUsers: newUsers });
  }

  searchUsers(query) {
    const requestId = nanoid();
    this.setState({
      searchUsers: [],
      query,
      requestId,
    });
    if (query.length > 1) {
      this.setState({ isLoading: true });
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/users?query=${query}`)
          .then(response => {
            console.log(response.data);
            let total = response.data.length;
            _.map(response.data, user => {
              if (this.state.requestId === requestId) {
                this.addUser(user);
              }
              total--;
            });
            if (total === 0) {
              resolve(response);
              this.setState({ isLoading: false });
            }
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
            this.setState({ isLoading: false });
          });
      });
    }
    return undefined;
  }

  renderInputValue(user) {
    return user.fullName;
  }

  renderUser(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.fullName}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length > 1 && !this.state.isLoading) {
      return <MenuItem className="popover-larger" disabled text={I18n.t('js.no_users_found')} />;
    }
    return undefined;
  }

  handleUserSelect(user) {
    this.setState({ query: '' });
    this.props.handleUserSelectChange(user);
    this.handleCancel();
  }

  handleCancel() {
    this.setState({ searchUsers: [], query: '' });
  }

  renderCancel() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    if (this.state.query.length > 0) {
      return <button type="button" className="bp3-button bp3-icon-cross bp3-minimal" onClick={this.handleCancel.bind(this)} />;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox marginTop="10px">
          <UserSelect
            resetOnSelect={false}
            items={this.state.searchUsers}
            itemRenderer={this.renderUser.bind(this)}
            onItemSelect={this.handleUserSelect.bind(this)}
            inputValueRenderer={this.renderInputValue.bind(this)}
            inputProps={{
              placeholder: I18n.t('js.start_typing'),
              className: 'popover-larger bp3-no-focus',
              rightElement: this.renderCancel(),
            }}
            query={this.state.query}
            onQueryChange={this.searchUsers.bind(this)}
            popoverProps={{
              usePortal: false,
              position: 'top-left',
              className: '',
              interactionKind: PopoverInteractionKind.CLICK,
              onClose: this.handleCancel.bind(this),
              popoverClassName: 'bp3-no-padding',
            }}
            noResults={this.renderResultSearch()}
          >
            <Button icon="new-person" text={I18n.t('js.add_user')} />
          </UserSelect>
        </Flexbox>
      </Flexbox>
    );
  }
}
