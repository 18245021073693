import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';

@inject('TemplateActions')
@observer
export default class TemplateAddSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleAddSection() {
    this.toggleLoading();
    const sectionObj = {
      section_type: this.props.type,
      title: I18n.t('js.new_section'),
    };
    if (this.props.type === 'app') {
      sectionObj.app_id = this.props.appId;
    } else {
      sectionObj.profile_template_id = this.props.profileTemplateId;
    }
    this.props.TemplateActions.createSection(sectionObj)
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          text={I18n.t('js.add_section')}
          className="bp3-large bp3-intent-primary"
          icon="add"
          onClick={this.handleAddSection.bind(this)}
          loading={this.state.isLoading}
        />
      </Flexbox>
    );
  }
}
