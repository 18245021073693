import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Button, Dialog, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AdminActions', 'AdminStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AdminNewStoreCategory extends React.Component {
  @observable
  showDialog = false;
  @observable
  isSubmitting = false;

  constructor(props) {
    super(props);

    this.state = { isBlurred: [] };
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }

  toggleSubmitting() {
    this.isSubmitting = !this.isSubmitting;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    const storeCategoryObj = {
      title: this.props.AdminStore.newStoreCategory.title,
      body: this.props.AdminStore.newStoreCategory.body,
      url: this.props.AdminStore.newStoreCategory.url,
    };
    this.props.AdminActions.createStoreCategory(storeCategoryObj).then(response => {
      this.props.AdminStore.addStoreCategory(response.data);
      this.props.AdminStore.resetNewStoreCategory();
      this.toggleDialog();
      this.toggleSubmitting();
      this.setState({ isBlurred: [] });
    });
  }

  handleInputChange(e) {
    this.props.AdminStore.updateNewStoreCategory(e.target.value, e.target.name);
  }

  handleCancel() {
    this.props.AdminStore.resetNewStoreCategory();
    this.toggleDialog();
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.AdminStore.newStoreCategory[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.AdminStore.newStoreCategory[`${field}Error`]}</div>;
    }
    return undefined;
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title="Add storeCategory"
      >
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'title') && !this.props.AdminStore.newStoreCategory.titleValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  Title
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'title') && !this.props.AdminStore.newStoreCategory.titleValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder="Enter title..."
                      name="title"
                      value={this.props.AdminStore.newStoreCategory.title}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('title')}
                </div>
              </div>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.props.AdminStore.newStoreCategoryValid}
                text="Create category"
                loading={this.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox>
        <Button text="Add category" icon="add" intent={Intent.PRIMARY} onClick={this.toggleDialog.bind(this)} />
        {this.renderDialog()}
      </Flexbox>
    );
  }
}
