import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import NotificationRow from './NotificationRow';
import _ from 'lodash';
@inject('NotificationStore', 'ToastStore')
@observer
export default class NotificationList extends React.Component {
  markAllAsRead() {
    if (this.props.NotificationStore.unreadCount > 0) {
      _.map(
        this.props.NotificationStore.notifications,
        notification => {
          if (!notification.read) {
            this.props.NotificationStore.markAsRead(notification.id);
          }
        }
      );
    }
  }

  handleColumnSort(column) {
    this.props.NotificationStore.setNotificationSortParams(
      column.id,
      column.sortDir
    );
  }

  renderNotifications() {
    if (this.props.NotificationStore.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.NotificationStore.notifications.length === 0) {
      return (
        <Flexbox
          flexDirection="row"
          flexWrap="wrap"
          className="bp3-card"
        >
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t(
              'js.there_are_no_notifications_to_show'
            )}
            icon="notifications"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        <table className="bp3-html-table bp3-html-table-striped bp3-interactive full-table">
          <thead>
            <SimpleTableHeader
              columns={
                this.props.NotificationStore.notificationColumns
              }
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          <tbody>
            {_.map(
              this.props.NotificationStore.notifications,
              notification => (
                <NotificationRow
                  notification={notification}
                  key={notification.id}
                />
              )
            )}
          </tbody>
        </table>
      </Flexbox>
    );
  }
  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexDirection="row" marginBottom="10px">
          <button
            className="bp3-button bp3-icon-tick"
            disabled={this.props.NotificationStore.unreadCount === 0}
            onClick={this.markAllAsRead.bind(this)}
          >
            {I18n.t('js.mark_all_as_read')}
          </button>
        </Flexbox>
        <Flexbox flexDirection="column" alignItems="stretch">
          {this.renderNotifications()}
        </Flexbox>
      </Flexbox>
    );
  }
}
