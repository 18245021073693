import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import Flexbox from 'flexbox-react';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import Paginator from '../shared/Paginator';
import ReportRow from './ReportRow';

@inject('ReportStore', 'ReportActions')
@observer
export default class ReportList extends Component {
  handleColumnSort(column) {
    this.props.ReportStore.setReportSortParams(
      column.id,
      column.sortDir
    );
  }

  handlePageChange(pageNumber) {
    this.props.toggleLoadingReports();
    this.props.ReportStore.reports = [];
    this.props.ReportActions.fetchReports(
      this.props.ReportStore.activeReportTemplate.id,
      pageNumber,
      this.props.ReportStore.pagination.xPerPage,
      this.props.ReportStore.pagination.xOffset
    )
      .then(response => {
        this.props.ReportStore.createPaginator(response.headers);
        _.map(response.data, report => {
          this.props.ReportStore.addReport(report);
        });
        this.props.toggleLoadingReports();
      })
      .catch(() => {
        this.props.toggleLoadingReports();
      });
  }

  handlePerPageChange(e) {
    this.props.toggleLoadingReports();
    this.props.ReportStore.reports = [];
    const appNum = 1;
    this.props.ReportActions.fetchReports(
      this.props.ReportStore.activeReportTemplate.id,
      appNum,
      e.target.value,
      this.props.ReportStore.pagination.xOffset
    )
      .then(response => {
        this.props.ReportStore.createPaginator(response.headers);
        _.map(response.data, report => {
          this.props.ReportStore.addReport(report);
        });
        this.props.toggleLoadingReports();
      })
      .catch(() => {
        this.props.toggleLoadingReports();
      });
  }

  renderPagination() {
    if (
      this.props.ReportStore.pagination.xTotal >=
      this.props.ReportStore.pagination.xPerPage
    ) {
      return (
        <Paginator
          paginationObject={this.props.ReportStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginTop="20px">
        <Flexbox
          flexDirection="column"
          className="bp3-card bp3-card-no-padding"
          flexGrow={1}
        >
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <SimpleTableHeader
                columns={this.props.ReportStore.reportColumns}
                handleColumnSort={this.handleColumnSort.bind(this)}
              />
            </thead>
            <tbody>
              {_.map(this.props.ReportStore.reports, report => (
                <ReportRow report={report} key={report.id} />
              ))}
            </tbody>
          </table>
        </Flexbox>
        {this.renderPagination()}
      </Flexbox>
    );
  }
}
