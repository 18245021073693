import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import RelationshipPermissionRow from './RelationshipPermissionRow';
import PermissionAddRelationshipPermission from './PermissionAddRelationshipPermission';
import RelationshipPermission from '../../models/RelationshipPermissionModel';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('PermissionStore', 'WorkspaceStore', 'AppStore', 'ToastStore')
@observer
export default class RelationshipPermissions extends React.Component {
  handleColumnSort(column) {
    this.props.PermissionStore.setRelationshipPermissionSortParams(column.id, column.sortDir);
  }

  handleRelationshipSelectChange(relationship) {
    const newRelationshipPermission = new RelationshipPermission(
      Utilities.makeId(),
      relationship.id,
      this.props.type,
      'create_for_subordinates',
      this.props.WorkspaceStore.activeWorkspace.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (this.props.type === 'app') {
      newRelationshipPermission.workspaceId = null;
      newRelationshipPermission.appId = this.props.AppStore.activeApp.id;
    }
    newRelationshipPermission.relationship = relationship;
    this.props.PermissionStore.relationshipPermissions = _.unionBy(
      [newRelationshipPermission],
      this.props.PermissionStore.relationshipPermissions,
      'id'
    );
    this.props.PermissionStore.sortRelationshipPermissions();
  }

  renderPermissions() {
    if (this.props.PermissionStore.relationshipPermissions.length === 0) {
      return (
        <tbody>
          <tr>
            <td className="td-width-40" valign="center">
              <span className="bp3-text-muted">{I18n.t('js.there_are_no_relationships_to_show')}</span>
            </td>
            <td className="td-width-40" />
            <td className="td-width-20" />
          </tr>
        </tbody>
      );
    }
    return (
      <tbody>
        {_.map(this.props.PermissionStore.relationshipPermissions, relationshipPermission => (
          <RelationshipPermissionRow
            relationshipPermission={relationshipPermission}
            key={relationshipPermission.id}
            type={this.props.type}
          />
        ))}
      </tbody>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <h5 className={Classes.HEADING}>{I18n.t('js.relationship_permissions')}</h5>
        <p>{I18n.t('js.only_following_relationships')}</p>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader
              columns={this.props.PermissionStore.relationshipPermissionColumns}
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          {this.renderPermissions()}
        </table>
        <Flexbox flexDirection="row" flexGrow={1}>
          <PermissionAddRelationshipPermission handleRelationshipSelectChange={this.handleRelationshipSelectChange.bind(this)} />
        </Flexbox>
      </Flexbox>
    );
  }
}
