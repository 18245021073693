/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observer } from 'mobx-react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import CurrentUserStore from '../../stores/CurrentUserStore';
import Cookies from 'js-cookie';

@observer
export default class UserNavMenu extends React.Component {
  handleToggleTheme() {
    const theme = CurrentUserStore.currentUser.theme === 'dark' ? 'light' : 'dark';
    CurrentUserStore.setTheme(theme).then(() => {
      location.reload();
    });
  }

  render() {
    const { currentUser } = this.props;

    return (
      <Menu>
        <MenuItem
          text={I18n.t('js.profile')}
          icon="person"
          onClick={() => Utilities.navigate(`/users/${currentUser.humanFriendlyId}`, true)}
          href={`/users/${currentUser.humanFriendlyId}`}
        />
        {/* <MenuItem text={I18n.t('js.calendar')} icon="calendar" onClick={() => Utilities.navigate(`/calendar`, true)} href="/calendar" /> */}
        <MenuItem
          text={I18n.t('js.templates')}
          icon="social-media"
          onClick={() => Utilities.navigate(`/store-templates`, true)}
          href="/store-templates"
        />
        <MenuDivider />
        {Cookies.get('super_admin') === 'true' && (
          <>
            <MenuItem text="Admin" icon="lock" onClick={() => Utilities.navigate('/admin')} href="/admin" />
          </>
        )}
        <MenuItem text={I18n.t('js.support')} icon="help" onClick={() => window.open('https://www.peoplegoal.com/support')} />
        <MenuItem text={I18n.t('js.sign_out')} icon="log-out" onClick={() => Utilities.navigate('/sign-out')} href="/sign-out" />
        <MenuDivider />
        <MenuItem
          text={currentUser.theme == 'dark' ? I18n.t('js.light_mode') : I18n.t('js.dark_mode')}
          icon={currentUser.theme == 'dark' ? 'flash' : 'moon'}
          onClick={this.handleToggleTheme.bind(this)}
        />
      </Menu>
    );
  }
}
