import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { Intent, Alert, Classes } from '@blueprintjs/core';

@inject(
  'AzureIntegrationStore',
  'ToastStore',
  'AccountSettingsStore',
  'IntegrationStore'
)
@observer
export default class DeleteIntegrationAlert extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Alert
        portalContainer={document.body}
        cancelButtonText={I18n.t('js.cancel')}
        confirmButtonText={I18n.t('js.delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={this.props.isDeleteAlertOpen}
        onCancel={() => this.props.toggleBoolean('isDeleteAlertOpen')}
        onConfirm={() => this.props.deleteIntegration()}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t(
          'js.are_you_sure_you_wish_to_delete_this_integration'
        )}
      </Alert>
    );
  }
}
