/* eslint no-magic-numbers: 0 */

// spacing should be relative to this
const gridSize = 10; // px
const zIndexBase = 0;
const zIndexContent = zIndexBase + 10;

export default {
  maxWidth: 930,
  smallWidth: 500,
  gridSize,
  borderRadius: Math.floor(gridSize / 3),
  buttonHeight: gridSize * 3,
  buttonHeightSmall: gridSize * 2.4,
  buttonHeightSmaller: gridSize * 2,
  buttonHeightLarge: gridSize * 4,
  inputHeight: gridSize * 3,
  inputHeightLarge: gridSize * 4,
  inputHeightSmall: gridSize * 2.4,
  navBarHeight: gridSize * 5,
  zIndexBase,
  zIndexContent,
  zIndexOverlay: zIndexContent + 10,
  // Font spacing
  fontSize: gridSize * 1.4,
  fontSizeLarge: gridSize * 1.6,
  fontSizeSmall: gridSize * 1.2,
};
