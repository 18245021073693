import React from 'react';
import { inject, observer } from 'mobx-react';
import AccountUsersStore from '../../stores/AccountUsersStore';
import { InputGroup } from '@blueprintjs/core';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class AccountUsersFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { query: '', isQuerying: false };
  }

  toggleQuerying() {
    this.setState({
      isQuerying: !this.state.isQuerying,
    });
  }

  handleSearchChange(e) {
    const query = e.target.value;
    this.setState({ query });
    if (query.length === 0) {
      this.toggleQuerying();
      this.props.toggleUserLoading();
      const pageNumber = 1;
      AccountUsersStore.fetchUsers(
        pageNumber,
        this.props.AccountUsersStore.pagination.xPerPage,
        this.props.AccountUsersStore.pagination.xOffset,
        '',
        ''
      )
        .then(() => {
          this.props.toggleUserLoading();
          this.toggleQuerying();
        })
        .catch(() => {
          this.props.toggleUserLoading();
          this.toggleQuerying();
        });
    } else {
      this.toggleQuerying();
      this.props.toggleUserLoading();
      const pageNumber = 1;
      AccountUsersStore.fetchUsers(
        pageNumber,
        this.props.AccountUsersStore.pagination.xPerPage,
        this.props.AccountUsersStore.pagination.xOffset,
        query,
        ''
      )
        .then(() => {
          this.props.toggleUserLoading();
          this.toggleQuerying();
        })
        .catch(() => {
          this.props.toggleUserLoading();
          this.toggleQuerying();
        });
    }
  }

  handleCancel() {
    this.setState({ query: '' });
    this.toggleQuerying();
    this.props.toggleUserLoading();
    const pageNumber = 1;
    AccountUsersStore.fetchUsers(
      pageNumber,
      this.props.AccountUsersStore.pagination.xPerPage,
      this.props.AccountUsersStore.pagination.xOffset,
      ''
    )
      .then(() => {
        this.props.toggleUserLoading();
        this.toggleQuerying();
      })
      .catch(() => {
        this.props.toggleUserLoading();
        this.toggleQuerying();
      });
  }

  renderQuerying() {
    if (this.state.query.length > 0) {
      return <button type="button" className="bp3-button bp3-icon-cross bp3-minimal" onClick={this.handleCancel.bind(this)} />;
    }
    return undefined;
  }

  render() {
    return (
      <InputGroup
        autoComplete="off"
        className="max-width"
        value={this.state.query}
        placeholder={I18n.t('js.filter_users')}
        onChange={this.handleSearchChange.bind(this)}
        leftIcon="search"
        rightElement={this.renderQuerying()}
      />
    );
  }
}
