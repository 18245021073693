import React from 'react';
import Flexbox from 'flexbox-react';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';
import classNames from 'classnames';

@inject('ItemStore', 'ItemActions', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions')
@observer
export default class FormElementMultilineInput extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  inputValue = '';
  @observable
  findInputValue = '';
  @observable
  hasError = false;
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  @computed
  get showError() {
    return this.hasError && this.store.submissionAttempted;
  }

  componentDidMount() {
    this.setStoreAndActions();
    const findInputValue = _.find(this.props.element.formValues, o => o.elementMultilineInputId === this.props.input.id);
    if (findInputValue) {
      this.actionType = 'updating';
      this.findInputValue = findInputValue;
      this.inputValue = _.clone(findInputValue.stringValue);
    }
    this.mountDisplayValuesInFlight();
    this.validateInput(this.inputValue);
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_multiline_input_id === this.props.input.id) {
        this.inputValue = formValue.string_value;
      }
    });
    // FIXME
    // eslint-disable-next-line
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === this.findInputValue.id);
      if (findFormValueToChange) {
        this.inputValue = findFormValueToChange.string_value;
      }
    });
  }

  setStoreAndActions() {
    this.newId = this.props.input.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'string';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'string';
        return object;
    }
  }

  handleInputChange(e) {
    this.inputValue = e.target.value;
    if (this.actionType === 'updating') {
      const changedFormValue = {
        id: this.findInputValue.id,
        string_value: this.inputValue,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      let newFormValue = {
        id: this.newId,
        element_id: this.props.element.id,
        element_multiline_input_id: this.props.input.id,
        string_value: this.inputValue,
      };
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
    }
    this.validateInput(this.inputValue);
  }

  validateInput(input) {
    if (!this.props.input.validateRequired(input)) {
      const errorObj = {
        elementId: this.props.element.id,
        elementMultilineInputId: this.props.input.id,
        errorMessage: this.props.input.validateRequiredMessage(input, false),
        errorMessageFull: this.props.input.validateRequiredMessage(input, true),
      };
      this.hasError = true;
      this.store.profileErrors = _.filter(this.store.profileErrors, o => o.elementMultilineInputId !== this.props.input.id);
      this.store.itemErrors = _.filter(this.store.itemErrors, o => o.elementMultilineInputId !== this.props.input.id);
      this.store.profileErrors.push(errorObj);
      this.store.itemErrors.push(errorObj);
    } else {
      this.clearErrors();
    }
  }

  clearErrors() {
    this.store.profileErrors = _.filter(this.store.profileErrors, o => o.elementMultilineInputId !== this.props.input.id);
    this.store.itemErrors = _.filter(this.store.itemErrors, o => o.elementMultilineInputId !== this.props.input.id);
    this.hasError = false;
  }

  renderHelperText() {
    if (this.showError) {
      return (
        <Flexbox flexDirection="column">
          {this.props.errors
            .filter(err => err.elementMultilineInputId === this.props.input.id)
            .map((err, i) => (
              <span key={i}>{err.errorMessage}</span>
            ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    let inputClass = classNames('');
    let marginAdjustment = '0px';
    if (this.props.input.displayWidth === 'half') {
      inputClass = classNames('multiline-half-width');
    }
    if (!this.props.input.displayLabel) {
      marginAdjustment = '-10px';
    }
    return (
      <Flexbox
        key={this.props.input.id}
        flexGrow={1}
        flexDirection="column"
        className={inputClass}
        marginRight="10px"
        marginTop={marginAdjustment}
      >
        <FormGroup
          label={this.props.input.displayLabel ? this.props.input.name : null}
          labelInfo={this.props.input.isRequired && this.props.input.displayLabel ? I18n.t('js.required') : ''}
          intent={this.showError ? Intent.DANGER : null}
          inline={this.props.input.displayLabelType === 'inline' && this.props.input.displayLabel}
          helperText={this.renderHelperText()}
        >
          <InputGroup
            autoComplete="off"
            disabled={!this.props.element.isEditable}
            placeholder={this.props.input.placeholderText}
            onChange={this.handleInputChange.bind(this)}
            value={this.inputValue}
            intent={this.showError ? Intent.DANGER : null}
          />
        </FormGroup>
      </Flexbox>
    );
  }
}
