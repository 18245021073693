import React from 'react';
import { inject, observer } from 'mobx-react';
import { Intent, Button } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import Utilities from '../../utils/Utilities';
import moment from 'moment';
import _ from 'lodash';

@inject('AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class InviteWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isHovering: false, shouldBeRemoved: false };
  }

  render() {
    const { invite } = this.props;
    let parentClass = classNames('position-relative');

    return (
      <tr className={`${parentClass} item-table-row`} style={{ fontSize: '13px' }}>
        <td onClick={() => Utilities.navigate(invite.link_path)}>
          <Flexbox flexDirection="row" alignItems="center">
            <Flexbox>
              <Flexbox flexDirection="column">
                <h4 className="push-10-l bp3-label-no-margin">{_.truncate(invite.item_name, { length: 60 })}</h4>
                <small className="bp3-text-muted">{invite.message}</small>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </td>
        <td onClick={() => Utilities.navigate(invite.link_path)}>
          <Flexbox flexDirection="row" alignItems="center" paddingTop="5px">
            {invite.due_date ? (
              <Flexbox paddingTop="6px">
                Due <strong className="push-5-l">{moment(invite.due_date).format('MMMM Do YYYY, h:mm a')}</strong>
              </Flexbox>
            ) : (
              <Flexbox paddingTop="6px">Created {moment(invite.created_at).format('MMMM Do YYYY, h:mm a')}</Flexbox>
            )}
          </Flexbox>
        </td>
        <td>
          <Flexbox flexDirection="row" paddingTop="5px">
            <Button
              outlined={false}
              intent={Intent.PRIMARY}
              text={I18n.t('js.open')}
              className="push-10-r"
              onClick={() => Utilities.navigate(invite.link_path)}
            />
          </Flexbox>
        </td>
      </tr>
    );
  }
}
