import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import TeamUsersListUserRow from './TeamUsersListUserRow';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import Paginator from '../shared/Paginator';
import _ from 'lodash';
import TeamUsersStore from '../../stores/TeamUsersStore';

@inject('CurrentUserStore', 'TeamUsersStore', 'ToastStore')
@observer
export default class TeamUsersNewUser extends React.Component {
  handleColumnSort(column) {
    this.props.TeamUsersStore.setUserTeamSortParams(
      column.id,
      column.sortDir
    );
  }

  handlePageChange(pageNumber) {
    this.props.toggleLoading();
    this.props.TeamUsersStore.fetchUserTeams(
      this.props.teamId,
      pageNumber,
      this.props.TeamUsersStore.pagination.xPerPage,
      this.props.TeamUsersStore.pagination.xOffset
    )
      .then(() => {
        this.props.toggleLoading();
      })
      .catch(() => {
        this.props.toggleLoading();
      });
  }

  handlePerPageChange(e) {
    this.props.toggleLoading();
    const appNum = 1;
    this.props.TeamUsersStore.fetchUserTeams(
      this.props.teamId,
      appNum,
      e.target.value,
      this.props.TeamUsersStore.pagination.xOffset
    )
      .then(() => {
        this.props.toggleLoading();
      })
      .catch(() => {
        this.props.toggleLoading();
      });
  }

  renderPagination() {
    if (
      !this.props.loading &&
      this.props.TeamUsersStore.pagination.xTotal >
        this.props.TeamUsersStore.pagination.xPerPage
    ) {
      return (
        <Paginator
          paginationObject={this.props.TeamUsersStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
        <Flexbox>
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <SimpleTableHeader
                columns={this.props.TeamUsersStore.userTeamColumns}
                handleColumnSort={this.handleColumnSort.bind(this)}
              />
            </thead>
            <tbody>
              {_.map(
                this.props.TeamUsersStore.userTeams,
                userTeam => (
                  <TeamUsersListUserRow
                    userTeam={userTeam}
                    key={userTeam.id}
                  />
                )
              )}
            </tbody>
          </table>
        </Flexbox>
        <Flexbox flexGrow={1} justifyContent="center">
          {this.renderPagination()}
        </Flexbox>
      </Flexbox>
    );
  }
}
