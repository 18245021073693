import React from 'react';
import Flexbox from 'flexbox-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import TeamUsersNewUser from './TeamUsersNewUser';
import TeamUsersListUsers from './TeamUsersListUsers';

@inject('CurrentUserStore', 'TeamsStore', 'TeamUsersStore', 'ToastStore')
@observer
export default class TeamUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  componentDidMount() {
    this.toggleLoading();
    this.props.TeamUsersStore.resetStartingAttributes();
    this.props.TeamUsersStore.fetchUserTeams(
      this.props.TeamsStore.activeTeam.id,
      1,
      this.props.TeamUsersStore.pagination.xPerPage,
      this.props.TeamUsersStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderUsers() {
    if (this.props.TeamUsersStore.userTeams.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_users_to_show')}
            icon="people"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <TeamUsersListUsers teamId={this.props.teamId} loading={this.state.loading} toggleLoading={() => this.toggleLoading()} />
      </Flexbox>
    );
  }

  renderNewUserTeam() {
    if (this.props.TeamsStore.activeTeam.isCurrentUserTeamOwner) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <TeamUsersNewUser />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderUserCount() {
    if (this.props.TeamUsersStore.pagination.xTotal > 0) {
      return <span>({this.props.TeamUsersStore.pagination.xTotal})</span>;
    }
    return undefined;
  }

  render() {
    if (this.state.loading) {
      return (
        <Flexbox flexGrow={1} justifyContent="center" alignItems="center" paddingTop="50px" paddingBottom="50px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderNewUserTeam()}
        {this.renderUsers()}
      </Flexbox>
    );
  }
}
