import { observable } from 'mobx';

export default class ElementFileDownload {
  @observable
  id;
  @observable
  elementId;
  @observable
  attachmentFileName;
  @observable
  attachmentContentType;
  @observable
  attachmentUpdatedAt;
  @observable
  attachmentFileSize;
  @observable
  attachmentFilePath;

  constructor(
    id,
    elementId,
    attachmentFileName,
    attachmentContentType,
    attachmentUpdatedAt,
    attachmentFileSize,
    attachmentFilePath
  ) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.attachmentFileName = attachmentFileName || '';
    this.attachmentContentType = attachmentContentType || '';
    this.attachmentUpdatedAt = attachmentUpdatedAt || '';
    this.attachmentFileSize = attachmentFileSize || '';
    this.attachmentFilePath = attachmentFilePath || '';
  }
}
