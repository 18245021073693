// @flow
import * as React from 'react';
import Utilities from '../../utils/Utilities';
import { Menu, MenuDivider, MenuItem, Popover, PopoverInteractionKind, Position, Tooltip, Button, Icon } from '@blueprintjs/core';

export default ({ action, userIsAccountOwner, account }) => {
  if (!userIsAccountOwner) {
    return (
      <Button
        className="bp3-minimal bp3-sidebar-button"
        active={action.includes(`account`) && action !== 'account_relationships'}
        disabled={true}
        icon="cog"
        text={I18n.t('js.settings')}
      />
    );
  }
  return (
    <Popover
      portalContainer={document.body}
      position={Position.RIGHT}
      interactionKind={PopoverInteractionKind.HOVER}
      content={
        <Menu>
          <li className="bp3-menu-header">
            <h6 className="bp3-heading">{I18n.t('js.account')}</h6>
          </li>
          <MenuDivider />
          <MenuItem text={I18n.t('js.users')} icon="people" onClick={() => Utilities.navigate('/account/users')} href="/account/users" />
          <MenuItem
            text={I18n.t('js.settings')}
            icon="settings"
            onClick={() => Utilities.navigate('/account/settings')}
            href="/account/settings"
          />
          {!account.accountEnterprise && (
            <>
              <MenuDivider />
              <MenuItem
                text={I18n.t('js.billing')}
                icon="credit-card"
                href="/account/billing"
                onClick={() => Utilities.navigate('/account/billing')}
              />
            </>
          )}
        </Menu>
      }
    >
      <Button
        className="bp3-minimal bp3-sidebar-button"
        active={action.includes(`account`) && action !== 'account_relationships'}
        text={I18n.t('js.account')}
        icon="cog"
        rightIcon="chevron-right"
      ></Button>
    </Popover>
  );
};
