import React from 'react';
import Flexbox from 'flexbox-react';
import { action, observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Drawer, Classes, Position, Tag, Intent, NonIdealState, Spinner, Button, Alert } from '@blueprintjs/core';
import AppItemsListItems from '../app_items/AppItemsListItems';
import AccountUsers from '../accounts/AccountUsers';
import TeamsList from '../teams/TeamsList';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('CurrentUserStore', 'AccountUsersStore', 'TeamsStore', 'AppItemStore', 'ImportStore', 'ImportActions', 'ToastStore')
@observer
export default class ImportDrawer extends React.Component {
  @observable showRollBackAlert = false;
  @observable showDeleteAlert = false;

  @action toggleShowRollBackAlert() {
    this.showRollBackAlert = !this.showRollBackAlert;
  }

  @action toggleShowDeleteAlert() {
    this.showDeleteAlert = !this.showDeleteAlert;
  }

  @action rollBackImport() {
    this.toggleShowRollBackAlert();
    this.props.toggleDrawer();
    this.props.ImportActions.rollBackImport(this.props.import.id).then(response => {
      this.props.ImportStore.addImport(response.data);
    });
  }

  @action deleteImport() {
    const importId = this.props.import.id;
    this.toggleShowDeleteAlert();
    this.props.toggleDrawer();
    this.props.ImportActions.deleteImport(this.props.import.id).then(response => {
      this.props.ImportStore.removeImport(importId);
      this.props.ToastStore.showToast(I18n.t('js.import_has_been_deleted'), 'success');
    });
  }

  renderImportErrors() {
    if (this.props.import.importErrors.length > 0) {
      return (
        <Flexbox flexDirection="column" className="push-20-b">
          <h4 className={`${Classes.HEADING}`}>{I18n.t('js.import_errors')}</h4>
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <tr>
                <th>{I18n.t('js.error_type')}</th>
                <th>{I18n.t('js.message')}</th>
                <th>{I18n.t('js.row_number')}</th>
                <th>{I18n.t('js.column_number')}</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.props.import.importErrors, importError => {
                const { errorSeverity, message, templateRowNumber, templateColumnNumber } = importError;
                return (
                  <tr key={importError.id}>
                    <td>
                      <Tag intent={Intent.DANGER}>{_.capitalize(errorSeverity)}</Tag>
                    </td>
                    <td>
                      <Tag className={Classes.MINIMAL}>{message}</Tag>
                    </td>
                    <td>
                      <Tag className={Classes.MINIMAL}>{templateRowNumber}</Tag>
                    </td>
                    <td>{templateColumnNumber ? <Tag className={Classes.MINIMAL}>{templateColumnNumber}</Tag> : 'N/A'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Flexbox>
      );
    }
  }

  renderImports() {
    const { newObjectCount, totalObjectCount } = this.props.import;

    if (this.props.isFetchingImportedItems) {
      return (
        <Flexbox flexDirection="column">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    } else {
      switch (this.props.ImportStore.importableType) {
        case 'App':
          if (this.props.AppItemStore.items.length == 0) {
            return (
              <Flexbox className="bp3-card">
                <NonIdealState
                  title={I18n.t('js.no_data')}
                  description={I18n.t('js.there_has_been_no_data_imported')}
                  icon="cloud-upload"
                  className="bp3-text-muted"
                />
              </Flexbox>
            );
          } else {
            return (
              <Flexbox flexDirection="column" flexGrow={1}>
                <AppItemsListItems toggleLoading={this.props.toggleFetchingImportedItems.bind(this)} showHeader={false} />
              </Flexbox>
            );
          }
        case 'ProfileTemplate':
          if (this.props.AccountUsersStore.users.length == 0) {
            return (
              <Flexbox className="bp3-card">
                <NonIdealState
                  title={I18n.t('js.no_data')}
                  description={I18n.t('js.there_has_been_no_data_imported')}
                  icon="cloud-upload"
                  className="bp3-text-muted"
                />
              </Flexbox>
            );
          } else {
            return (
              <Flexbox flexDirection="column" flexGrow={1}>
                <AccountUsers
                  toggleLoading={this.props.toggleFetchingImportedItems.bind(this)}
                  importId={this.props.import.id}
                  showHeader={false}
                  showFilter={false}
                  loadUsers={false}
                />
              </Flexbox>
            );
          }
        case 'Team':
          if (this.props.TeamsStore.teams.length == 0) {
            return (
              <Flexbox className="bp3-card">
                <NonIdealState
                  title={I18n.t('js.no_data')}
                  description={I18n.t('js.there_has_been_no_data_imported')}
                  icon="cloud-upload"
                  className="bp3-text-muted"
                />
              </Flexbox>
            );
          } else {
            return (
              <Flexbox flexDirection="column" flexGrow={1}>
                <TeamsList importId={this.props.import.id} />
              </Flexbox>
            );
          }
        case 'TeamRelationship':
          return (
            <Flexbox className="bp3-card" flexDirection="column">
              <NonIdealState
                title={I18n.t('js.team_relationships_are_not_shown', {
                  count: newObjectCount,
                })}
                description={I18n.t('js.click_below_to_view_teams')}
                icon="layout-hierarchy"
                className="bp3-text-muted"
              />
              <Flexbox flexDirection="row" flexGrow={1} justifyContent="center" marginTop="10px">
                <Button onClick={() => Utilities.navigate('/teams')} text={I18n.t('js.show_teams')} />
              </Flexbox>
            </Flexbox>
          );
        case 'UserTeam':
          return (
            <Flexbox className="bp3-card" flexDirection="column">
              <NonIdealState
                title={I18n.t('js.user_team_relationships_are_not_shown', { count: newObjectCount })}
                description={I18n.t('js.click_below_to_view_teams')}
                icon="layout-hierarchy"
                className="bp3-text-muted"
              />
              <Flexbox flexDirection="row" flexGrow={1} justifyContent="center" marginTop="10px">
                <Button onClick={() => Utilities.navigate('/teams')} text={I18n.t('js.show_teams')} />
              </Flexbox>
            </Flexbox>
          );
        case 'UserRelationship':
          return (
            <Flexbox className="bp3-card" flexDirection="column">
              <NonIdealState
                title={I18n.t('js.user_relationships_are_not_shown', {
                  count: newObjectCount,
                })}
                description={I18n.t('js.click_below_to_view_relationships')}
                icon="layout-hierarchy"
                className="bp3-text-muted"
              />
              <Flexbox flexDirection="row" flexGrow={1} justifyContent="center" marginTop="10px">
                <Button onClick={() => Utilities.navigate('/account/relationships')} text={I18n.t('js.show_relationships')} />
              </Flexbox>
            </Flexbox>
          );
      }
    }
  }

  renderImportActions() {
    return (
      <Flexbox flexDirection="row" className="push-20-b">
        {/* <Button
          text={I18n.t('js.roll_back_import')}
          onClick={() => this.toggleShowRollBackAlert()}
          icon="undo"
          disabled={this.props.import.status === 'In progress' || this.props.import.rolledBack}
        />
        <Button
          text={I18n.t('js.delete_import')}
          onClick={() => this.toggleShowDeleteAlert()}
          icon="trash"
          className="push-10-l"
          disabled={!this.props.import.rolledBack}
        /> */}
        <Alert
          portalContainer={document.body}
          icon="warning-sign"
          isOpen={this.showRollBackAlert}
          cancelButtonText={I18n.t('js.cancel')}
          onCancel={() => this.toggleShowRollBackAlert()}
          confirmButtonText={I18n.t('js.roll_back_import')}
          onConfirm={() => this.rollBackImport()}
          intent={Intent.WARNING}
        >
          <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
          {I18n.t('js.roll_back_import_confirmation')}
        </Alert>
        <Alert
          portalContainer={document.body}
          icon="trash"
          isOpen={this.showDeleteAlert}
          cancelButtonText={I18n.t('js.cancel')}
          onCancel={() => this.toggleShowDeleteAlert()}
          confirmButtonText={I18n.t('js.delete_import')}
          onConfirm={() => this.deleteImport()}
          intent={Intent.DANGER}
        >
          <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
          {I18n.t('js.delete_import_confirmation')}
        </Alert>
      </Flexbox>
    );
  }

  render() {
    const { newObjectCount, totalObjectCount } = this.props.import;

    return (
      <Drawer
        icon="cloud-upload"
        onClose={this.props.toggleDrawer.bind(this)}
        title={I18n.t('js.items_imported', {
          current: newObjectCount,
          total: totalObjectCount,
        })}
        isOpen={this.props.isDrawerOpen}
        position={Position.RIGHT}
        portalContainer={document.body}
        usePortal={true}
        size={Drawer.SIZE_LARGE}
      >
        <Flexbox className={Classes.DRAWER_BODY} flexDirection="column">
          <Flexbox className={Classes.DIALOG_BODY} flexDirection="column">
            {this.renderImportActions()}
            {this.renderImportErrors()}
            <Flexbox flexDirection="column">
              <h4 className={`${Classes.HEADING}`}>{I18n.t('js.imported_items')}</h4>
              {this.renderImports()}
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Drawer>
    );
  }
}
