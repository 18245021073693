// @flow
import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Alignment, Button, Classes, HTMLSelect, InputGroup, Intent, NumericInput, Popover, Position } from '@blueprintjs/core';
import moment from 'moment';
import { DateInput } from '@blueprintjs/datetime';
import Flexbox from 'flexbox-react';
import ReportTemplateColumn from '../../models/ReportTemplateColumnModel';
import ReportTemplateFilter from '../../models/ReportTemplateFilterModel';
import OperandDefinitions from '../../static/OperandDefinitions';
import type { DataViewStore } from '../../stores/DataViewStore';
import type { Operand } from '../../types/DataViewTypes';

type AddNewFilterButtonProps = {
  addNewFilter: () => void,
};

const AddNewFilterButton = (props: AddNewFilterButtonProps) => (
  <Button
    // $FlowFixMe
    text={`${I18n.t('js.add_a_new_filter')}`}
    alignText={Alignment.LEFT}
    minimal
    icon="add"
    onClick={props.addNewFilter}
  />
);

type TestValueInputProps = {
  selectedFilter: ReportTemplateFilter,
  activeColumn: ReportTemplateColumn,
  changeFilterAttribute: (filterId: string, attribute: string, newValue: string) => void,
};

class TestValueInput extends React.Component<TestValueInputProps> {
  changeHandler = event => {
    const newValue = event.currentTarget.value;
    const { changeFilterAttribute, selectedFilter } = this.props;
    changeFilterAttribute(selectedFilter.id, 'testValue', newValue);
  };

  dateChangeHandler = (selectedDate: Date) => {
    if (!selectedDate) {
      return;
    }
    const { changeFilterAttribute, selectedFilter } = this.props;
    changeFilterAttribute(selectedFilter.id, 'testValue', selectedDate.toString());
  };

  numericChangeHandler = (valueAsNumber: number, valueAsString: string) => {
    const { changeFilterAttribute, selectedFilter } = this.props;
    changeFilterAttribute(selectedFilter.id, 'testValue', valueAsString);
  };

  render() {
    const { selectedFilter, activeColumn } = this.props;
    if (selectedFilter.operand !== 'is_blank' && selectedFilter.operand !== 'is_not_blank') {
      switch (activeColumn.columnType) {
        case 'number':
          return <NumericInput value={selectedFilter.testValue} onValueChange={this.numericChangeHandler.bind(this)} />;
        case 'datetime':
          if (
            selectedFilter.operand === 'is_greater_than' ||
            selectedFilter.operand === 'is_less_than' ||
            selectedFilter.operand === 'equals'
          ) {
            let dateValue = new Date();
            if (Date.parse(selectedFilter.testValue)) {
              dateValue = new Date(selectedFilter.testValue);
            } else {
              this.props.changeFilterAttribute(selectedFilter.id, 'testValue', dateValue.toString());
            }
            return (
              <DateInput
                value={dateValue}
                onChange={this.dateChangeHandler}
                formatDate={date => moment.utc(date).format('DD/MM/YYYY')}
                parseDate={str => new Date(str)}
              />
            );
          }
          return <NumericInput value={selectedFilter.testValue} onValueChange={this.numericChangeHandler.bind(this)} />;

        default:
          return <InputGroup value={selectedFilter.testValue} onChange={this.changeHandler.bind(this)} />;
      }
    }
    return null;
  }
}

type OperandInputProps = {
  selectedFilter: ReportTemplateFilter,
  activeColumn: ReportTemplateColumn,
  operands: Array<Operand>,
  changeFilterAttribute: (filterId: string, attribute: string, newValue: string) => void,
};

class OperandInput extends React.Component<OperandInputProps> {
  changeHandler = event => {
    const newOperand = event.currentTarget.value;
    const { changeFilterAttribute, selectedFilter } = this.props;
    changeFilterAttribute(selectedFilter.id, 'operand', newOperand);
    if (newOperand === 'is_within_last_days' || newOperand === 'is_within_next') {
      changeFilterAttribute(selectedFilter.id, 'testValue', '0');
    }
  };

  render() {
    const { operands, selectedFilter, activeColumn } = this.props;
    return (
      <HTMLSelect onChange={this.changeHandler} value={selectedFilter.operand}>
        {operands.map(operand => (
          <option value={operand.name} key={operand.id} disabled={activeColumn.shouldOperandBeDisabled(operand.name)}>
            {operand.text.toLowerCase()}
          </option>
        ))}
      </HTMLSelect>
    );
  }
}

type ColumnPickerProps = {
  selectedFilter: ReportTemplateFilter,
  columns: Array<ReportTemplateColumn>,
  changeFilterAttribute: (filterId: string, attribute: string, newValue: string) => void,
};

class ColumnPicker extends React.Component<ColumnPickerProps> {
  changeHandler = event => {
    const newColumnId = event.currentTarget.value;
    const { changeFilterAttribute, selectedFilter } = this.props;
    changeFilterAttribute(selectedFilter.id, 'reportTemplateColumnId', newColumnId);
  };

  render() {
    const { columns, selectedFilter } = this.props;
    return (
      <HTMLSelect onChange={this.changeHandler} value={selectedFilter.reportTemplateColumnId}>
        {columns.map(column => (
          <option value={column.id} key={column.id}>
            {_.truncate(column.columnName, { length: 25 })}
          </option>
        ))}
      </HTMLSelect>
    );
  }
}

type DeleteButtonProps = {
  deleteHandler: () => void,
};

const DeleteButton = (props: DeleteButtonProps) => (
  <Button icon="small-cross" minimal onClick={props.deleteHandler} style={{ marginLeft: 'auto' }} />
);

type FilterProps = {
  columns: Array<ReportTemplateColumn>,
  filter: ReportTemplateFilter,
  changeFilterAttribute: (filterId: string, attribute: string, newValue: string) => void,
  deleteHandler: () => void,
  activeColumn: ReportTemplateColumn,
};

const Filter = (props: FilterProps) => {
  const { changeFilterAttribute, columns, filter, activeColumn, deleteHandler } = props;
  return (
    <Flexbox className="sort-filter-popover-filter" alignItems="center">
      {/* $FlowFixMe */}
      <p>{I18n.t('js.where')}</p>
      <ColumnPicker selectedFilter={filter} columns={columns} changeFilterAttribute={changeFilterAttribute} />
      <OperandInput
        selectedFilter={filter}
        activeColumn={activeColumn}
        operands={OperandDefinitions.operands}
        changeFilterAttribute={changeFilterAttribute}
      />
      <TestValueInput selectedFilter={filter} activeColumn={activeColumn} changeFilterAttribute={changeFilterAttribute} />
      <DeleteButton deleteHandler={deleteHandler} />
    </Flexbox>
  );
};

type FilterPickerProps = {
  DataViewStore: DataViewStore,
};

@inject('DataViewStore', 'DataViewActions')
@observer
export default class FilterPicker extends React.Component<FilterPickerProps> {
  render() {
    const { activeFilters, activeColumns, addFilter, changeFilterAttribute, removeFilter } = this.props.DataViewStore;
    const targetButton = (
      <Button
        // $FlowFixMe
        text={
          activeFilters.length > 0
            ? // $FlowFixMe
              `${I18n.t('js.filters')} (${activeFilters.length})`
            : // $FlowFixMe
              I18n.t('js.filter')
        }
        icon="filter"
      />
    );
    const filters = activeFilters.map(filter => {
      const activeColumn: any = _.find(activeColumns, o => o.id === filter.reportTemplateColumnId);
      return (
        <Filter
          className="filter"
          key={filter.id}
          changeFilterAttribute={changeFilterAttribute}
          columns={activeColumns}
          activeColumn={activeColumn}
          filter={filter}
          deleteHandler={() => removeFilter(filter.id)}
        />
      );
    });
    const popoverContent = (
      <Flexbox minWidth="300px" flexDirection="column" className="sort-filter-popover">
        {activeFilters === [] && (
          <p className={Classes.TEXT_DISABLED}>
            {/* $FlowFixMe */}
            {`${I18n.t('js.no_filters_active')}`}
          </p>
        )}
        {filters}
        <AddNewFilterButton addNewFilter={addFilter} />
      </Flexbox>
    );
    return (
      <Popover
        portalContainer={document.body}
        captureDismiss
        target={targetButton}
        content={popoverContent}
        position={Position.BOTTOM_LEFT}
      />
    );
  }
}
