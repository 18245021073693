import React, { Component } from 'react';
import { observable, computed } from 'mobx';
import { NonIdealState, Classes, Icon, Tag, Intent } from '@blueprintjs/core';
import WidgetStore from '../../stores/WidgetStore';
import Flexbox from 'flexbox-react';
import CommentsView from '../../views/CommentsView';
import RichTextDisplay from '../shared/RichTextDisplay';
import { inject, observer } from 'mobx-react';
import { DatePicker } from '@blueprintjs/datetime';
import moment from 'moment';

@observer
export default class WidgetSchedule extends Component {
  @observable
  selectedEventIndex = 0;

  @computed
  get minDate() {
    return new Date(new Date().setFullYear(new Date().getFullYear() - 10));
  }

  @computed
  get maxDate() {
    return new Date(new Date().setFullYear(new Date().getFullYear() + 10));
  }

  @computed
  get events() {
    const { widget, editable } = this.props;
    let events = [];
    if (widget.scheduled_events) {
      _.map(widget.scheduled_events, event => {
        let target_date = new Date(event.target_date);
        target_date.setDate(target_date.getDate() + event.offset);
        target_date = moment.utc(target_date);

        if (event.recurs) {
          const now_date = moment.utc(new Date());
          if (now_date.isSameOrAfter(target_date)) {
            const next_date = this.findNextAvailableDate(target_date, event.end_date, event.frequency, event.interval);
            events.push({
              date: new Date(next_date),
              event_type: event.event_type,
              includeTime: true,
              label: _.truncate(event.title, { length: 30 }),
              fullLabel: event.title,
              content: event.content,
              recurs: event.recurs,
              frequency: event.frequency,
              interval: event.interval,
              creates_item: event.creates_item,
            });
          } else {
            events.push({
              date: new Date(moment.utc(target_date)),
              event_type: event.event_type,
              includeTime: true,
              label: _.truncate(event.title, { length: 30 }),
              fullLabel: event.title,
              content: event.content,
              recurs: event.recurs,
              frequency: event.frequency,
              interval: event.interval,
              creates_item: event.creates_item,
            });
          }
        } else {
          events.push({
            date: new Date(moment.utc(target_date)),
            event_type: event.event_type,
            includeTime: true,
            label: _.truncate(event.title, { length: 30 }),
            fullLabel: event.title,
            content: event.content,
            recurs: event.recurs,
            frequency: event.frequency,
            interval: event.interval,
            creates_item: event.creates_item,
          });
        }
      });
    }
    events = _.orderBy(events, ['date'], ['asc']);
    return events;
  }

  findNextAvailableDate(target_date, end_date, frequency, interval) {
    let result = this.incrementDateByFrequency(target_date, frequency, interval);
    if (result.isSameOrAfter(moment.utc(new Date()))) {
      return result;
    } else {
      if (result.isSameOrAfter(moment.utc(new Date(end_date)))) {
        return result;
      } else {
        this.findNextAvailableDate(result, end_date, frequency, interval);
      }
    }
    return result;
  }

  incrementDateByFrequency(date, frequency, interval) {
    let result = date;
    switch (frequency) {
      case 'daily':
        result = date.add(interval, 'days');
        break;
      case 'weekly':
        result = date.add(interval, 'weeks');
        break;
      case 'monthly':
        result = date.add(interval, 'months');
        break;
      case 'yearly':
        result = date.add(interval, 'years');
        break;
    }
    return result;
  }

  handleEventChange(event, index) {
    this.selectedEventIndex = index;
  }

  handleDateChange(date) {}

  render() {
    const { widget, editable } = this.props;
    let activeEvent = null;
    let textFrequency = '';

    if (widget.content) {
      if (widget.scheduled_events.length > 0) {
        activeEvent = this.events[this.selectedEventIndex];
        textFrequency = 'day(s)';
        switch (activeEvent.frequency) {
          case 'weekly':
            textFrequency = 'week(s)';
            break;
          case 'monthly':
            textFrequency = 'month(s)';
            break;
          case 'yearly':
            textFrequency = 'year(s)';
            break;
        }
      }

      return (
        <Flexbox flexDirection="column" flexGrow={1} marginBottom="10px">
          {widget.title ? <h1>{widget.title}</h1> : null}
          {widget.content ? <RichTextDisplay content={widget.content} /> : null}
          {widget.scheduled_events.length > 0 && activeEvent ? (
            <Flexbox marginTop="20px" flexDirection="row" flexWrap="wrap">
              <DatePicker
                highlightCurrentDay={true}
                showActionsBar={false}
                value={activeEvent.event_type === 'fixed' ? activeEvent.date : null}
                onChange={date => this.handleDateChange(date)}
                minDate={this.minDate}
                maxDate={this.maxDate}
                shortcuts={this.events}
                showActionsBar={false}
                selectedShortcutIndex={this.selectedEventIndex}
                onShortcutChange={this.handleEventChange.bind(this)}
              />
              <Flexbox flexDirection="column" className="" marginLeft="20px">
                <h3 className="bp3-label-no-margin">{activeEvent.fullLabel}</h3>
                {activeEvent.event_type === 'fixed' ? (
                  <Flexbox className="bp3-text-muted" justifyContent="flex-start" alignItems="center" marginTop="10px">
                    <Icon icon="calendar" iconSize={12}></Icon>
                    <small className="push-5-l">{moment(activeEvent.date).format('MMMM Do YYYY, h:mm a')}</small>
                  </Flexbox>
                ) : null}
                <Flexbox className="bp3-text-muted" justifyContent="flex-start" alignItems="center" marginTop="10px">
                  {activeEvent.event_type === 'profile_template_date' ? (
                    <Tag minimal intent={Intent.DEFAULT} icon="people" className="push-10-r">
                      {I18n.t('js.based_on_values_in_your_profile')}
                    </Tag>
                  ) : null}
                  {activeEvent.recurs ? (
                    <Tag minimal intent={Intent.SUCCESS} icon="repeat" className="push-10-r">
                      {I18n.t('js.event_recurs_frequency', { frequency: textFrequency, interval: activeEvent.interval })}
                    </Tag>
                  ) : null}
                  {activeEvent.creates_item ? (
                    <Tag minimal intent={Intent.DANGER} icon="cog">
                      {I18n.t('js.automatically_creates_items')}
                    </Tag>
                  ) : null}
                </Flexbox>
                <RichTextDisplay content={activeEvent.content} />
              </Flexbox>
            </Flexbox>
          ) : null}
        </Flexbox>
      );
    }
    if (editable) {
      return (
        <Flexbox
          flexDirection="row"
          flexGrow={1}
          justifyContent="center"
          paddingBottom="20px"
          style={{ borderBottom: '1px solid #EBF1F5' }}
        >
          <NonIdealState
            title={I18n.t('js.no_content')}
            description={I18n.t('js.click_the_edit_button_to_add_content')}
            icon="calendar"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return null;
  }
}
