/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Callout, Classes, Tag } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import _ from 'lodash';

@inject('DocumentationStore', 'CurrentUserStore', 'ToastStore')
@observer
class DocumentationPath extends React.Component {
  renderConsumes() {
    const { selectedDocumentation } = this.props.DocumentationStore;
    const { consumes } = selectedDocumentation;
    if (consumes) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <p>
            {I18n.t('js.endpoint_consumes')}:{' '}
            {_.map(
              selectedDocumentation.consumes,
              (consumer, index) => (
                <code
                  className="bp3-minimal push-5-r push-5-l"
                  key={index}
                >
                  {consumer}
                </code>
              )
            )}
          </p>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderProduces() {
    const { selectedDocumentation } = this.props.DocumentationStore;
    const { produces } = selectedDocumentation;
    if (produces) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <p>
            {I18n.t('js.endpoint_produces')}:{' '}
            {_.map(
              selectedDocumentation.produces,
              (procuder, index) => (
                <code
                  className="bp3-minimal push-5-r push-5-l"
                  key={index}
                >
                  {procuder}
                </code>
              )
            )}
          </p>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderPath() {
    let calloutClass = classNames('');
    const { selectedDocumentation } = this.props.DocumentationStore;
    const { method } = selectedDocumentation;
    switch (method) {
      case 'post':
        calloutClass = classNames('bp3-intent-success');
        break;
      case 'delete':
        calloutClass = classNames('bp3-intent-danger');
        break;
      case 'put':
        calloutClass = classNames('bp3-intent-primary');
        break;
      case 'get':
        // leave with plain Intent
        break;
      default:
        throw new Error(`method: ${method} not accounted for`);
    }
    return (
      <Callout className={calloutClass}>
        <Flexbox flexGrow={1}>
          <Flexbox
            flexDirection="column"
            alignItems="center"
            marginRight="20px"
          >
            <Tag className={calloutClass}>
              {selectedDocumentation.method.toUpperCase()}
            </Tag>
          </Flexbox>
          <h4 className={`${Classes.HEADING} bp3-callout-title`}>
            {this.props.DocumentationStore.basePath}
            {selectedDocumentation.path}
          </h4>
        </Flexbox>
      </Callout>
    );
  }

  renderRequiredLabel(param) {
    if (param.required) {
      return (
        <Tag className="bp3-intent-danger bp3-minimal">
          <small>{I18n.t('js.required').toLowerCase()}</small>
        </Tag>
      );
    }
    return (
      <Tag className="bp3-minimal">
        <small>{I18n.t('js.optional').toLowerCase()}</small>
      </Tag>
    );
  }

  renderQueryParameters() {
    const { selectedDocumentation } = this.props.DocumentationStore;
    const { parameters } = selectedDocumentation;
    if (parameters) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <h4 className={Classes.HEADING}>
            {I18n.t('js.query_parameters')}
          </h4>
          <Flexbox
            className="bp3-card bp3-card-no-padding"
            marginTop="10px"
          >
            <table className="bp3-html-table bp3-html-table-striped full-table">
              <thead>
                <tr>
                  <th>{I18n.t('js.attribute')}</th>
                  <th>{_.capitalize(I18n.t('js.required'))}</th>
                  <th>{I18n.t('js.type')}</th>
                  <th>{I18n.t('js.description')}</th>
                </tr>
              </thead>
              <tbody>
                {_.map(parameters, (param, index) => (
                  <tr key={index}>
                    <td>
                      <code className="bp3-code">{param.name}</code>
                    </td>
                    <td>{this.renderRequiredLabel(param)}</td>
                    <td>
                      <code className="bp3-code">{param.type}</code>
                    </td>
                    <td>
                      <small className="bp3-text-muted">
                        {param.description}
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderCodeLabel(code) {
    const ok = '200';
    const noContent = '204';
    if (code === ok || code === noContent) {
      return (
        <Tag className="bp3-intent-success bp3-minimal">{code}</Tag>
      );
    }
    return (
      <Tag className="bp3-intent-danger bp3-minimal">{code}</Tag>
    );
  }

  renderResponses() {
    const { selectedDocumentation } = this.props.DocumentationStore;
    const responses = [];
    _.map(selectedDocumentation.responses, (value, key) => {
      responses.push({
        code: key,
        description: value.description,
      });
    });
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginTop="20px">
        <h4 className={Classes.HEADING}>{I18n.t('js.responses')}</h4>
        <Flexbox
          className="bp3-card bp3-card-no-padding"
          marginTop="10px"
        >
          <table className="bp3-html-table bp3-html-table-striped full-table">
            <thead>
              <tr>
                <th>{I18n.t('js.code')}</th>
                <th>{I18n.t('js.description')}</th>
              </tr>
            </thead>
            <tbody>
              {_.map(responses, response => (
                <tr key={response.code}>
                  <td>{this.renderCodeLabel(response.code)}</td>
                  <td>
                    <strong>
                      <small>
                        {response.description.toUpperCase()}
                      </small>
                    </strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    const { selectedDocumentation } = this.props.DocumentationStore;
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <h3 className={Classes.HEADING}>
          {Utilities.humanize(selectedDocumentation.description)}
        </h3>
        <Flexbox flexDirection="column" marginTop="20px">
          {this.renderPath()}
        </Flexbox>
        <Flexbox flexDirection="column" marginTop="20px">
          {this.renderConsumes()}
          {this.renderProduces()}
        </Flexbox>
        <Flexbox flexDirection="column" marginTop="20px">
          {this.renderQueryParameters()}
          {this.renderResponses()}
        </Flexbox>
      </Flexbox>
    );
  }
}

export default DocumentationPath;
