// @flow
import * as React from 'react';
import Utilities from '../../utils/Utilities';
import { Button, Menu, MenuDivider, MenuItem, Popover, PopoverInteractionKind, Position, Spinner, Icon } from '@blueprintjs/core';
import AppStore from '../../stores/AppStore';
import WorkspaceStore from '../../stores/WorkspaceStore';
import Flexbox from 'flexbox-react';
import _ from 'lodash';
import { AnchorButton } from '@blueprintjs/core';

export default class WorkspaceMenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingApps: true,
    };
  }

  toggleLoadingApps() {
    this.setState({
      isLoadingApps: !this.state.isLoadingApps,
    });
  }

  componentDidMount() {
    WorkspaceStore.fetchWorkspaceFolders(this.props.workspace.id).then(() => {
      AppStore.fetchApps(this.props.workspace.id)
        .then(() => {
          this.toggleLoadingApps();
        })
        .catch(() => {
          this.toggleLoadingApps();
        });
    });
  }

  renderAppIcon(app) {
    return (
      <Flexbox flexDirection="row" width="15px" alignContent="center" justifyContent="center">
        <span className={`fa fas fa-fw ${app.icon}`} style={{ paddingTop: '3px', color: app.color }}></span>
      </Flexbox>
    );
  }

  renderWorkspaceIcon() {
    return (
      <Flexbox flexDirection="row" width="15px" alignContent="center" justifyContent="center">
        <span
          className={`fa fas fa-fw ${this.props.workspace.icon}`}
          style={{
            fontSize: this.props.displayAs === 'button' ? '15px' : '15px',
            paddingTop: this.props.displayAs === 'button' ? '0px' : '0px',
            color: this.props.workspace.color,
          }}
        ></span>
      </Flexbox>
    );
  }

  renderAppMenu(apps) {
    if (this.props.workspace.userAccessLevel === 'access_denied') {
      return <MenuItem text={I18n.t('js.access_denied')} icon="warning-sign" />;
    }
    const workspaceFolders = _.filter(WorkspaceStore.workspaceFolders, o => o.workspaceId === this.props.workspace.id);
    if (this.state.isLoadingApps) {
      return (
        <MenuItem
          text={I18n.t('js.loading')}
          icon={<Spinner size={20} />}
          popoverProps={{
            usePortal: false,
            portalContainer: document.body,
          }}
        />
      );
    } else {
      if (apps.length === 0) {
        return <MenuItem text={I18n.t('js.there_are_no_apps_to_show')} icon="warning-sign" />;
      } else {
        if (workspaceFolders.length > 0) {
          return (
            <>
              {_.map(workspaceFolders, workspaceFolder => {
                return (
                  <MenuItem
                    key={workspaceFolder.id}
                    popoverProps={{
                      usePortal: false,
                      portalContainer: document.body,
                    }}
                    onClick={() => Utilities.navigate(`/workspaces/${this.props.workspace.id}?folder=${workspaceFolder.id}`)}
                    text={workspaceFolder.name}
                    icon="folder-open"
                  >
                    {_.map(apps, app => {
                      if (
                        app.status === 'published' &&
                        app.userPermissionLevel.name !== 'access_denied' &&
                        app.workspaceId == this.props.workspace.id &&
                        app.workspaceFolderId == workspaceFolder.id
                      ) {
                        return (
                          <MenuItem
                            key={app.id}
                            popoverProps={{
                              usePortal: false,
                              portalContainer: document.body,
                            }}
                            text={app.name}
                            icon={this.renderAppIcon(app)}
                            onClick={() => Utilities.navigate(`/apps/${app.id}`)}
                            href={`/apps/${app.id}`}
                          />
                        );
                      }
                    })}
                  </MenuItem>
                );
              })}
            </>
          );
        } else {
          return (
            <>
              {_.map(apps, app => {
                if (
                  app.status === 'published' &&
                  app.userPermissionLevel.name !== 'access_denied' &&
                  app.workspaceId == this.props.workspace.id
                ) {
                  return (
                    <MenuItem
                      key={app.id}
                      popoverProps={{
                        usePortal: false,
                        portalContainer: document.body,
                      }}
                      text={app.name}
                      icon={this.renderAppIcon(app)}
                      onClick={() => Utilities.navigate(`/apps/${app.id}`)}
                      href={`/apps/${app.id}`}
                    />
                  );
                }
              })}
            </>
          );
        }
      }
    }
  }

  render() {
    const { workspace, displayAs } = this.props;
    let isActive = this.props.activeObjectId === workspace.id;
    const apps = _.filter(AppStore.apps, app => {
      return app.status === 'published' && app.userPermissionLevel.name !== 'access_denied' && app.workspaceId == this.props.workspace.id;
    });
    _.map(apps, app => {
      if (app.id === this.props.activeObjectId) {
        isActive = true;
      }
    });

    if (displayAs === 'button') {
      return (
        <Popover
          portalContainer={document.body}
          position={Position.RIGHT}
          interactionKind={PopoverInteractionKind.HOVER}
          inline={true}
          autoFocus={false}
          content={<Menu>{this.renderAppMenu(apps)}</Menu>}
        >
          <AnchorButton
            className="bp3-minimal bp3-sidebar-button"
            active={isActive}
            href={`/workspaces/${workspace.id}`}
            text={_.truncate(workspace.name, { length: 30 })}
            icon={this.renderWorkspaceIcon()}
          ></AnchorButton>
        </Popover>
      );
    } else {
      return (
        <MenuItem text={workspace.name} icon={this.renderWorkspaceIcon()} onClick={() => Utilities.navigate(`/workspaces/${workspace.id}`)}>
          {this.renderAppMenu(apps)}
        </MenuItem>
      );
    }
  }
}
