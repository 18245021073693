import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Icon, Position, Tooltip } from '@blueprintjs/core';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions'
)
@observer
export default class ItemCompletionBadge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      badgeClass: 'zoomIn',
    };
  }

  handleMarkAsComplete() {
    this.setState({ badgeClass: 'zoomOut' });
    this.props.ItemActions.markItemAsComplete(
      this.props.ItemStore.item.id
    ).then(response => {
      this.props.ItemStore.addItem(response.data);
      this.props.ToastStore.showToast(
        I18n.t('js.item_has_been_updated', {
          item: this.props.AppStore.activeApp.item,
        }),
        'success'
      );
      this.setState({ badgeClass: 'zoomIn' });
    });
  }

  render() {
    if (this.props.ItemStore.item.isDoneForCurrentUser) {
      return (
        <Flexbox
          className={`item-completion-badge animated relative ${this.state.badgeClass}`}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip
            portalContainer={document.body}
            content={I18n.t('js.you_have_marked_this_item_as_done', {
              item: this.props.AppStore.activeApp.item,
            })}
            position={Position.BOTTOM}
          >
            <Icon
              icon="endorsed"
              className="item-completion-badge-icon"
              iconSize={50}
              onClick={this.handleMarkAsComplete.bind(this)}
            />
          </Tooltip>
          <div
            style={{
              backgroundColor: '#fff',
              width: '40px',
              height: '40px',
              position: 'absolute',
              borderRadius: '100%',
              zIndex: '-1',
            }}
          ></div>
        </Flexbox>
      );
    }
    return null;
  }
}
