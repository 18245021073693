import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { MenuItem, Icon, Spinner, Tag, Button, Tooltip, Position } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { observable, action, computed } from 'mobx';
import classNames from 'classnames';
import Avatar from 'react-avatar';
import SuggestSelectedResult from './SuggestSelectedResult';
import _ from 'lodash';
import Team from '../../models/TeamModel';

axiosCancel(axios);

const SearchSuggest = Suggest.ofType();
const pageTotal = 25;

@inject('CurrentUserStore', 'ToastStore', 'AppStore', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions')
@observer
export default class TeamSuggest extends React.Component {
  constructor(props) {
    super(props);

    this.loadQuery = _.debounce(this.loadQuery, 500);
  }

  /*=======================================================================
  Setup
  =======================================================================*/

  @observable query = '';
  @observable isLoading = false;
  @observable teams = [];
  @observable selectedTeams = [];
  @observable totalAvailableTeams = 0;
  @observable currentPage = 1;

  componentDidMount() {
    this.setInitialSelection();
    this.loadInitialTeams();
  }

  @action
  setInitialSelection() {
    // Construct an array of selected teams based on element form values
    if (this.props.element) {
      _.map(this.props.element.formValues, formValue => {
        this.selectedTeams.push(formValue.referenceTeam);
      });
    }
    this.props.validateInput(this.selectedTeams);
  }

  /*=======================================================================
  Computations
  =======================================================================*/

  @computed
  get lastTeamId() {
    if (this.filteredTeams.length > 0) {
      return _.last(this.filteredTeams).id;
    }
    return '';
  }

  @computed
  get canGetMoreData() {
    return this.teams.length < this.totalAvailableTeams;
  }

  @computed
  get inputDisabled() {
    if (this.props.disabled) {
      return true;
    }
    if (this.props.element) {
      return this.props.element.elementObjectSelect.optionType == 'single' && this.selectedTeams.length > 0;
    }
    return false;
  }

  @computed
  get filteredTeams() {
    const ids = _.map(this.selectedTeams, 'id');
    return _.filter(this.teams, o => !ids.includes(o.id));
  }

  /*=======================================================================
  Data fetching
  =======================================================================*/

  @action
  async loadInitialTeams() {
    this.isLoading = true;
    this.teams = [];
    await this.fetchTeams(null, this.currentPage, pageTotal)
      .then(response => {
        this.teams = _.unionBy(this.teams, response.data, 'id');
        this.totalAvailableTeams = parseInt(response.headers['x-total']);
      })
      .catch(() => {
        this.isLoading = false;
      });
    this.isLoading = false;
  }

  @action
  async loadMoreData() {
    // Load additional data for the list
    this.isLoading = true;
    this.currentPage = this.currentPage + 1;
    await this.fetchTeams(null, this.currentPage, pageTotal)
      .then(response => {
        this.teams = _.unionBy(this.teams, response.data, 'id');
      })
      .catch(() => {
        this.isLoading = false;
      });
    this.isLoading = false;
  }

  @action
  async loadAllData() {
    // Load all data for the list
    this.isLoading = true;
    this.currentPage = 0;
    const requestCount = Math.ceil(this.totalAvailableTeams / pageTotal);
    let i;
    for (i = 0; i <= requestCount; i++) {
      this.currentPage = this.currentPage + 1;
      await this.fetchTeams(null, this.currentPage, pageTotal)
        .then(response => {
          this.teams = _.unionBy(this.teams, response.data, 'id');
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
    this.isLoading = false;
  }

  @action
  async loadQuery(query) {
    this.query = query;
    if (query.length === 0) {
      this.loadInitialTeams();
    } else {
      this.isLoading = true;
      await this.fetchTeams(query, this.currentPage, pageTotal)
        .then(response => {
          this.teams = response.data;
        })
        .catch(() => {
          this.isLoading = false;
        });
      this.isLoading = false;
    }
  }

  @action
  fetchTeams(query, page, per_page) {
    return new Promise((resolve, reject) => {
      let url = `${Cookies.get('apiEnv')}/teams?page=${page}&per_page=${per_page}&offset=0`;
      if (query) {
        url = `${Cookies.get('apiEnv')}/teams?&query=${query}`;
      }
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  /*=======================================================================
  Handlers
  =======================================================================*/

  @action
  handleTeamClick(team) {
    this.query = '';
    this.props.handleTeamClick(team);
    const newTeam = new Team(
      team.id,
      team.account_id,
      team.is_default_account,
      team.name,
      team.description,
      team.image_file_name,
      team.background_image_file_name,
      team.private,
      team.is_current_user_team_owner,
      team.is_current_user_team_member,
      team.row_order,
      team.user_count,
      team.comment_count,
      team.import_id,
      team.created_at,
      team.created_by,
      team.deleted_at,
      team.deleted_by,
      team.updated_at,
      team.updated_by
    );
    this.selectedTeams = this.selectedTeams.concat([newTeam]);
    this.props.validateInput(this.selectedTeams);
  }

  @action
  handleTeamRemove(id) {
    this.selectedTeams = _.filter(this.selectedTeams, o => o.id != id);
    this.props.handleTeamRemove(id);
    this.props.validateInput(this.selectedTeams);
  }

  /*=======================================================================
  Rendering
  =======================================================================*/

  renderInputValue(result) {
    return this.query;
  }

  renderResult(team, details) {
    let menuClass = classNames('');

    return (
      <React.Fragment key={team.id}>
        <MenuItem
          className={menuClass}
          onClick={details.handleClick}
          style={{ width: '800px' }}
          text={
            <Flexbox flexDirection="row" alignContent="center">
              <Avatar src={team.image_file_name} size={20} round={true} />
              <Flexbox marginLeft="10px">{team.name}</Flexbox>
            </Flexbox>
          }
        />
        {team.id === this.lastTeamId && this.canGetMoreData && this.query.length === 0 ? (
          <Flexbox flexDirection="row" justifyContent="center" padding="10px">
            <Button
              text={I18n.t('js.load_more')}
              icon="more"
              minimal={true}
              disabled={this.isLoading}
              onClick={this.loadMoreData.bind(this)}
            />
            <Button
              text={I18n.t('js.load_all_options', { count: this.totalAvailableTeams })}
              icon="cloud-download"
              minimal={true}
              disabled={this.isLoading}
              onClick={this.loadAllData.bind(this)}
            />
          </Flexbox>
        ) : null}
      </React.Fragment>
    );
  }

  renderNoResults() {
    return <MenuItem disabled icon="warning-sign" text={I18n.t('js.no_results_found')} />;
  }

  renderSelectedResults() {
    return _.map(this.selectedTeams, team => {
      return (
        <SuggestSelectedResult
          key={team.id}
          text={team.name}
          image={team.imageFileName}
          onClick={e => (this.props.openDrawer != undefined ? this.props.openDrawer(team) : null)}
          onRemove={e => this.handleTeamRemove(team.id)}
        />
      );
    });
  }

  render() {
    const { placeholder } = this.props;
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <SearchSuggest
          items={this.filteredTeams}
          itemRenderer={this.renderResult.bind(this)}
          onItemSelect={result => this.handleTeamClick(result)}
          inputValueRenderer={this.renderInputValue.bind(this)}
          noResults={this.renderNoResults()}
          disabled={this.inputDisabled}
          query={this.query}
          fill={true}
          onQueryChange={this.loadQuery.bind(this)}
          inputProps={{
            value: this.query,
            disabled: this.inputDisabled,
            intent: this.props.intent,
            placeholder: placeholder,
            leftElement:
              this.isLoading && !this.inputDisabled ? (
                <Flexbox padding="5px">
                  <Spinner size={18} />
                </Flexbox>
              ) : null,
          }}
          popoverProps={{
            usePortal: false,
            position: 'bottom-left',
            className: 'fill',
            popoverClassName: `bp3-minimal ${this.props.expandMenu ? 'suggest suggest-wide' : 'suggest'}`,
          }}
        />
        {this.selectedTeams.length > 0 ? (
          <Flexbox flexDirection="column" marginTop="10px">
            {this.renderSelectedResults()}
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }
}
