/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from '../../stores/ToastStore';
import Flexbox from 'flexbox-react';
import nanoid from 'nanoid';
import User from '../../models/UserModel';
import classNames from 'classnames';
import _ from 'lodash';
import Cookies from 'js-cookie';
import Avatar from 'react-avatar';

axiosCancel(axios);

@inject('UserProfileRelationshipsStore', 'UserProfileStore')
@observer
export default class UserProfileRelationshipsSelectUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      isLoading: false,
      requestId: nanoid(),
    };
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  searchUsers(e) {
    const query = e.target.value;
    const requestId = nanoid();
    this.props.UserProfileRelationshipsStore.dialogSearchedUsers = [];
    this.setState({ query, requestId });
    if (query.length > 1) {
      this.toggleLoading();
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${query}`, {
          requestId: 'userSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            if (user.id !== this.props.UserProfileStore.user.id && this.state.requestId === requestId) {
              this.addSearchedUser(user);
            }
            total--;
          });
          if (total === 0) {
            this.toggleLoading();
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.toggleLoading();
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            this.toggleLoading();
          }
        });
    }
  }

  addSearchedUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
    this.props.UserProfileRelationshipsStore.dialogSearchedUsers = _.unionBy(
      [newUser],
      this.props.UserProfileRelationshipsStore.dialogSearchedUsers,
      'id'
    );
  }

  renderUser(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }
    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.fullName}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              <span className="bp3-icon-search push-5-r" />
              <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              <span className="bp3-icon-search push-5-r" />
              <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            <span className="bp3-icon-people push-5-r" />
            <span> {I18n.t('js.no_users_found')} </span>
          </span>
        }
      />
    );
  }

  renderTag(user) {
    return <span id={user.id}>{user.fullName}</span>;
  }

  // todo make sure these are the right way round for a and b
  handleUserSelect(user) {
    // ! move this out of here and into a method that is called when the dialog closes.
    // * I want to create the user relationships only when the dialog is closed.
    // * Before then, it will serve to store just the users.s
    this.props.UserProfileRelationshipsStore.dialogSelectedUsers = this.props.UserProfileRelationshipsStore.dialogSelectedUsers.concat([
      user,
    ]);
    const isChild = this.props.UserProfileRelationshipsStore.dialogUserIsChild;
    const userId = isChild ? this.props.UserProfileRelationshipsStore.dialogUser.id : user.id;
    const counterpartUserId = isChild ? user.id : this.props.UserProfileRelationshipsStore.dialogUser.id;
    const newUserRelationship = {
      userId,
      counterpartUserId,
      relationshipId: this.props.UserProfileRelationshipsStore.dialog,
    };
    this.props.UserProfileRelationshipsStore.queuedUserRelationships = this.props.UserProfileRelationshipsStore.queuedUserRelationships.concat(
      [newUserRelationship]
    );
    // todo: compare to OrganizationNewRelatinoshipSelectParents to see what needs to be changed:
    // * check if user exists in the list to be deleted
    // * check if use exists in existing lists
    this.props.UserProfileRelationshipsStore.dialogSelectedUsers.push(user);
    this.handlePopoverClose();
  }

  handlePopoverClose() {
    this.props.UserProfileRelationshipsStore.dialogSearchedUsers = [];
    this.setState({ query: '' });
  }

  handleUserRemove(user) {
    this.props.UserProfileRelationshipsStore.queuedUserRelationships = this.props.UserProfileRelationshipsStore.queuedUserRelationships.filter(
      userRelationship => userRelationship.userId !== user.id
    );
    this.props.UserProfileRelationshipsStore.dialogSelectedUsers = this.props.UserProfileRelationshipsStore.dialogSelectedUsers.filter(
      selectedUser => selectedUser.id !== user.id
    );
    // todo: there is more in OrganizationNewRelatinoshipSelectParents as well
  }

  // todo: add filter
  // * filter out users that are already in the list. (Guidance in OrganizationNewRelatinoshipSelectParents);
  // filterUser(query, users) {
  //   let possibleUsers = this.props.UserProfileRelationshipsStore.dialogSearchedUsers
  //     .filter((user) => user.id !== this.props.UserProfileStore.user.id);
  //   return possibleUsers;
  // }

  // todo: pass this into the props (see OrganizationNewRelatinoshipSelectParents);
  renderRightElement() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    return (
      <MultiSelect
        className="max-width"
        items={this.props.UserProfileRelationshipsStore.dialogSearchedUsers}
        // itemListPredicate={this.filterUser.bind(this)}
        selectedItems={this.props.UserProfileRelationshipsStore.dialogSelectedUsers}
        tagRenderer={this.renderTag.bind(this)}
        itemRenderer={this.renderUser.bind(this)}
        onItemSelect={this.handleUserSelect.bind(this)}
        tagInputProps={{
          inputProps: {
            placeholder: I18n.t('js.search_users'),
            onChange: this.searchUsers.bind(this),
          },
          rightElement: this.renderRightElement(),
          className: 'bp3-tag-input',
          onRemove: this.handleUserRemove.bind(this),
        }}
        popOverProps={{
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
