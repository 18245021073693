import React from 'react';
import Flexbox from 'flexbox-react';
import { action, observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Card, Button, Classes, Elevation, Intent, HTMLSelect } from '@blueprintjs/core';
import ImportUploadFileButton from './ImportUploadFileButton';
import { Promise } from 'q';

@inject('CurrentUserStore', 'AccountUsersStore', 'ImportStore', 'ImportActions', 'ToastStore')
@observer
export default class ImportInstructions extends React.Component {
  @observable currentStep = 0;
  @observable minStep = 0;
  @observable maxStep = 2;
  @observable isLoadingTemplate = false;
  @observable isLoadingFileSubmission = false;

  componentWillMount() {
    this.resetAttributes();
    this.handleTemplateDownload();
  }

  componentWillUnmount() {
    this.resetAttributes();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.importableType != this.props.importableType || nextProps.importableId != this.props.importableId) {
      this.resetAttributes();
      this.handleTemplateDownload();
    }
  }

  resetAttributes() {
    this.currentStep = 0;
    this.minStep = 0;
    this.maxStep = 2;
    this.isLoadingTemplate = false;
    this.isLoadingFileSubmission = false;
  }

  componentDidMount() {
    this.props.ImportStore.setUpActionCable();
  }

  @computed get isAtMinStep() {
    return this.currentStep === this.minStep;
  }

  @computed get isAtMaxStep() {
    return this.currentStep === this.maxStep;
  }

  @computed get importTitle() {
    return I18n.t(`js.step_${this.currentStep + 1}_title`);
  }

  @computed get importInstructions() {
    return I18n.t(`js.step_${this.currentStep + 1}_paragraph`);
  }

  @action toggleLoadingTemplate() {
    this.isLoadingTemplate = !this.isLoadingTemplate;
  }

  @action toggleLoadingFileSubmission() {
    this.isLoadingFileSubmission = !this.isLoadingFileSubmission;
  }

  @action goToNextStep() {
    if (this.currentStep >= this.maxStep) {
      this.currentStep = this.maxStep;
    } else {
      this.currentStep = this.currentStep + 1;
    }
  }

  @action goToPreviousStep() {
    if (this.currentStep <= this.minStep) {
      this.currentStep = this.minStep;
    } else {
      this.currentStep = this.currentStep - 1;
    }
  }

  @action downloadTemplateToClient() {
    if (this.props.ImportStore.hasImportTemplate) {
      window.location = this.props.ImportStore.importTemplate.templateUrl;
    } else {
      this.handleTemplateDownload().then(() => {
        window.location = this.props.ImportStore.importTemplate.templateUrl;
      });
    }
  }

  @action handleSelectProfileTemplate(e) {
    /*=======================================================================
    This function should fetch change the activeProfileTemplate and download a new importTemplate for it
    =======================================================================*/
    const profileTemplateId = e.target.value;
    const findProfileTemplate = _.find(this.props.AccountUsersStore.profileTemplates, o => o.id == profileTemplateId);
    if (findProfileTemplate) {
      this.props.AccountUsersStore.setActiveProfileTemplate(findProfileTemplate);
    }
    this.props.ImportStore.importableId = profileTemplateId;
    this.handleTemplateDownload().then(() => {
      this.props.ImportStore.getImports();
    });
  }

  @action handleTemplateDownload() {
    return new Promise((resolve, reject) => {
      this.toggleLoadingTemplate();
      this.props.ImportActions.fetchImportTemplate(this.props.ImportStore.importableType, this.props.ImportStore.importableId)
        .then(response => {
          this.props.ImportStore.addImportTemplate(response.data);
          this.toggleLoadingTemplate();
          resolve(response.data);
        })
        .catch(err => {
          this.toggleLoadingTemplate();
          reject(err);
        });
    });
  }

  @action handleFileSubmission(file) {
    this.toggleLoadingFileSubmission();
    const importTemplateFile = file;
    const formData = new FormData();
    formData.append('import_template_id', this.props.ImportStore.importTemplate.id);
    formData.append('template', importTemplateFile);
    this.props.ImportActions.createImport(formData)
      .then(response => {
        this.props.ImportStore.addImport(response.data);
        this.toggleLoadingFileSubmission();
      })
      .catch(() => {
        this.toggleLoadingFileSubmission();
      });
  }

  renderNextSteps() {
    return (
      <Button
        icon="arrow-right"
        intent={this.isAtMaxStep ? Intent.DEFAULT : Intent.PRIMARY}
        disabled={this.isAtMaxStep}
        className="push-10-l"
        text={I18n.t('js.next_step')}
        onClick={() => this.goToNextStep()}
      />
    );
  }

  renderPreviousSteps() {
    return (
      <Button
        icon="arrow-left"
        disabled={this.isAtMinStep}
        className="push-10-l"
        text={I18n.t('js.previous_step')}
        onClick={() => this.goToPreviousStep()}
      />
    );
  }

  renderProfileTemplateSelector() {
    if (this.props.importableType === 'ProfileTemplate') {
      const { profileTemplates, activeProfileTemplate } = this.props.AccountUsersStore;
      return (
        <HTMLSelect
          className="profile-template-selector push-10-r"
          name="profileTemplateSelect"
          onChange={this.handleSelectProfileTemplate.bind(this)}
          value={activeProfileTemplate.id}
        >
          {profileTemplates.map(profileTemplate => (
            <option value={profileTemplate.id} key={profileTemplate.id}>
              {_.truncate(profileTemplate.name, { length: 25 })}
            </option>
          ))}
        </HTMLSelect>
      );
    }
  }

  renderImportAction() {
    switch (this.currentStep) {
      case 0:
        return (
          <div>
            {this.renderProfileTemplateSelector()}
            <Button
              icon="cloud-download"
              text={I18n.t('js.download_import_template')}
              loading={this.isLoadingTemplate}
              disabled={!this.props.ImportStore.hasValidImportable}
              onClick={() => this.downloadTemplateToClient()}
            />
          </div>
        );
      case 2:
        return <ImportUploadFileButton isLoading={this.isLoadingFileSubmission} handleSubmit={this.handleFileSubmission.bind(this)} />;
      default:
        return null;
    }
  }

  renderHeading() {
    if (this.props.heading) {
      return (
        <Flexbox marginBottom="10px">
          <h2 className="bp3-label-no-margin">{this.props.heading}</h2>
        </Flexbox>
      );
    }
  }

  render() {
    return (
      <Flexbox flexDirection="row">
        <Card interactive={false} elevation={Elevation.TWO} className="max-width">
          <Flexbox flexDirection="column">
            {this.renderHeading()}
            <h5 className={Classes.HEADING}>{this.importTitle}</h5>
            <p>{this.importInstructions}</p>
            <Flexbox flexDirection="row" flexGrow={1}>
              {this.renderImportAction()}
              <Flexbox flexDirection="row" justifyContent="flex-end" flexGrow={1}>
                {this.renderPreviousSteps()}
                {this.renderNextSteps()}
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Card>
      </Flexbox>
    );
  }
}
