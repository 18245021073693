import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tree, Classes, HTMLSelect, Button, Tooltip, NonIdealState, Spinner, Label } from '@blueprintjs/core';
import DynamicUserSuggest from './DynamicUserSuggest';
import DynamicMultiSelectActions from './DynamicMultiSelectActions';
import _ from 'lodash';
import { Position } from '@blueprintjs/core';

@inject('DynamicViewStore', 'AppStore', 'WorkspaceStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynamicRelationshipView extends React.Component {
  handleNodeInteract(nodeData) {
    switch (nodeData.nodeType) {
      case 'relationship':
        const relationshipType = nodeData.id;
        this.props.DynamicViewStore.toggleUser(relationshipType);
        break;
      case 'user':
        const userId = nodeData.id;
        this.props.DynamicViewStore.toggleUser(userId);
        break;
      case 'item':
        // const itemId = nodeData.id;
        // window.location = `/items/${itemId}`;
        break;
    }
  }

  handleNodeClick(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeCollapse(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeExpand(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleRelationshipChange(e) {
    if (this.props.DynamicViewStore.activeRelationship.id !== e.target.value) {
      const relationship = _.find(this.props.DynamicViewStore.relationships, o => o.id === e.target.value);
      this.props.DynamicViewStore.setActiveRelationship(relationship);
    }
  }

  expandAll() {
    this.props.DynamicViewStore.expandAll();
  }

  collapseAll() {
    this.props.DynamicViewStore.collapseAll();
  }

  renderAppSelect() {
    const { getSelectedAppId } = this.props.DynamicViewStore;
    const { apps } = this.props.AppStore;
    const { filteredWorkspaces } = this.props.WorkspaceStore;
    if (this.props.allowAppSelect) {
      return (
        <Flexbox className="bp3-select" marginRight="10px">
          <HTMLSelect disabled={apps.length <= 1} value={getSelectedAppId} onChange={this.props.handleAppChange.bind(this)}>
            <option key={0} value={'all'}>
              {I18n.t('js.all_apps')}
            </option>
            {_.map(filteredWorkspaces, workspace => {
              return (
                <React.Fragment key={workspace.id}>
                  <option key={workspace.id} disabled={true} value={workspace.id}>
                    {workspace.name}
                  </option>
                  {_.map(apps, app => {
                    if (app.workspaceId === workspace.id && app.userPermissionLevel.name !== 'access_denied') {
                      return (
                        <option key={app.id} value={app.id}>
                          {app.name}
                        </option>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </HTMLSelect>
        </Flexbox>
      );
    }
  }

  render() {
    const {
      activeTab,
      activeParticipant,
      relationshipTree,
      activeRelationship,
      relationships,
      participants,
      statusFlags,
      isLoadingParticipants,
      isLoadingStatusFlags,
      activeApp,
    } = this.props.DynamicViewStore;

    if (!activeRelationship) {
      return (
        <Flexbox flexDirection="column" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted push-20-t"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row" marginBottom="10px">
          <Flexbox flexDirection="row" flexGrow={1}>
            {this.renderAppSelect()}
            <Label className="bp3-label-no-margin">
              {I18n.t('js.filter_by_participant')}
              <Flexbox className="bp3-select" marginRight="10px">
                <HTMLSelect
                  disabled={activeApp === null || isLoadingParticipants}
                  onChange={this.props.handleParticipantChange.bind(this)}
                  value={activeParticipant ? activeParticipant.id : ''}
                >
                  <option value={null}>{I18n.t('js.all_participants')}</option>
                  {_.map(participants, participant => (
                    <option key={participant.id} value={participant.id}>
                      {participant.name}
                    </option>
                  ))}
                </HTMLSelect>
              </Flexbox>
            </Label>
            <Label className="bp3-label-no-margin">
              {I18n.t('js.filter_by_status')}
              <Flexbox className="bp3-select" marginRight="10px">
                <HTMLSelect
                  disabled={activeApp === null || isLoadingStatusFlags}
                  value={this.props.DynamicViewStore.activeStatusFlag?.id}
                  onChange={this.props.handleStatusFlagChange.bind(this)}
                >
                  <option defaultValue value="reset">
                    {I18n.t('js.any_current_status')}
                  </option>
                  {_.map(statusFlags, statusFlag => (
                    <option key={statusFlag.id} value={statusFlag.id}>
                      {statusFlag.name}
                    </option>
                  ))}
                </HTMLSelect>
              </Flexbox>
            </Label>
            {activeTab != 'your-items' ? (
              <Flexbox flexDirection="row" flexGrow={1} marginRight="10px" paddingTop="25px">
                <DynamicUserSuggest disabled={activeTab === 'your-tasks'} whitelistUsers={this.props.DynamicViewStore.availableUsers} />
              </Flexbox>
            ) : null}
          </Flexbox>
          <Flexbox flexDirection="row" paddingTop="25px">
            {activeTab == 'your-colleagues' ? (
              <Flexbox className="bp3-select" marginRight="10px">
                <HTMLSelect
                  disabled={relationships.length <= 1}
                  onBlur={this.handleRelationshipChange.bind(this)}
                  value={activeRelationship ? activeRelationship.id : ''}
                  onChange={this.handleRelationshipChange.bind(this)}
                >
                  {_.map(relationships, relationship => (
                    <option key={relationship.id} value={relationship.id}>
                      {relationship.name} / {relationship.counterpartName}
                    </option>
                  ))}
                </HTMLSelect>
              </Flexbox>
            ) : null}
            <Tooltip
              usePortal={false}
              disabled={!this.props.DynamicViewStore.canExpandAll}
              content={I18n.t('js.expand_all')}
              position={Position.LEFT}
            >
              <Button
                icon="expand-all"
                className="push-10-r"
                disabled={!this.props.DynamicViewStore.canExpandAll}
                onClick={this.expandAll.bind(this)}
              />
            </Tooltip>
            <Tooltip
              usePortal={false}
              disabled={!this.props.DynamicViewStore.canCollapseAll}
              content={I18n.t('js.collapse_all')}
              position={Position.LEFT}
            >
              <Button icon="collapse-all" disabled={!this.props.DynamicViewStore.canCollapseAll} onClick={this.collapseAll.bind(this)} />
            </Tooltip>
          </Flexbox>
        </Flexbox>
        <DynamicMultiSelectActions allowMultiSelect={true} />
        <Flexbox flexDirection="column" className="bp3-cursor-pointer">
          <Tree
            contents={relationshipTree}
            onNodeClick={this.handleNodeClick.bind(this)}
            onNodeCollapse={this.handleNodeCollapse.bind(this)}
            onNodeExpand={this.handleNodeExpand.bind(this)}
            className={Classes.ELEVATION_0}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
