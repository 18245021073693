import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Intent, MenuItem, Icon, Spinner, Tag, Button, Tooltip, Position } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { observable, action, computed } from 'mobx';
import classNames from 'classnames';
import SuggestSelectedResult from './SuggestSelectedResult';
import _ from 'lodash';

axiosCancel(axios);

const SearchSuggest = Suggest.ofType();
const pageTotal = 25;

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class ItemSuggest extends React.Component {
  constructor(props) {
    super(props);

    this.loadQuery = _.debounce(this.loadQuery, 500);
  }

  /*=======================================================================
  Setup
  =======================================================================*/

  @observable query = '';
  @observable isLoading = false;
  @observable items = [];
  @observable selectedItems = [];
  @observable apps = [];

  componentDidMount() {
    this.setInitialSelection();
    this.loadInitialItems();
  }

  @action
  setInitialSelection() {
    // Construct an array of selected items based on element form values
    _.map(this.props.element.formValues, formValue => {
      const dataItem = {
        id: formValue.referenceItem.id,
        name: formValue.referenceItem.name,
        icon: formValue.referenceItem.itemIcon,
        color: formValue.referenceItem.itemColor,
        type: formValue.referenceItem.itemType,
      };
      this.selectedItems.push(dataItem);
    });
    this.props.validateInput(this.selectedItems);
  }

  /*=======================================================================
  Computations
  =======================================================================*/

  @computed
  get lastItemId() {
    if (this.filteredItems.length > 0) {
      return _.last(this.filteredItems).id;
    }
    return '';
  }

  @computed
  get totalAvailableItems() {
    return _.sumBy(this.apps, o => parseInt(o.total));
  }

  @computed
  get totalDisplayedAvailableItems() {
    return this.totalAvailableItems - this.selectedItems.length;
  }

  @computed
  get canGetMoreData() {
    return this.items.length < this.totalAvailableItems;
  }

  @computed
  get inputDisabled() {
    if (this.props.disabled) {
      return true;
    }
    return this.props.element.elementObjectSelect.optionType == 'single' && this.selectedItems.length > 0;
  }

  @computed
  get filteredItems() {
    const ids = _.map(this.selectedItems, 'id');
    let items = _.filter(this.items, o => !ids.includes(o.id));
    if (this.props.ItemStore.item) {
      // Don't include the current item as selectable
      items = _.filter(items, o => o.id != this.props.ItemStore.item.id);
    }
    items = _.filter(items, o => !o.status_flag.is_archived);
    return items;
  }

  /*=======================================================================
  Data fetching
  =======================================================================*/

  @action
  async loadInitialItems() {
    // Construct an array of Apps including the total available item count
    this.isLoading = true;
    this.items = [];
    this.apps = [];
    const { elementObjectSelect } = this.props.element;
    await Promise.all(
      elementObjectSelect.elementObjectSelectItemApps.map(async obj => {
        const appObj = {
          id: obj.app.id,
          name: obj.app.name,
          total: 0,
          current_page: 1,
        };
        await this.fetchAppItems(obj.app.id, null, 1, pageTotal)
          .then(response => {
            appObj.total = response.headers['x-total'];
            this.apps.push(appObj);
            this.items = this.items.concat(response.data);
          })
          .catch(() => {
            this.isLoading = false;
          });
      })
    );
    this.isLoading = false;
  }

  @action
  async loadMoreData() {
    // Load additional data for the list
    this.isLoading = true;
    await Promise.all(
      this.apps.map(async app => {
        app.current_page = app.current_page + 1;
        await this.fetchAppItems(app.id, null, app.current_page, pageTotal)
          .then(response => {
            this.items = this.items.concat(response.data);
          })
          .catch(() => {
            this.isLoading = false;
          });
      })
    );
    this.isLoading = false;
  }

  @action
  async loadAllData() {
    // Load all data for the list
    this.isLoading = true;
    await Promise.all(
      this.apps.map(async app => {
        const requestCount = Math.ceil(app.total / pageTotal);
        let i;
        for (i = 0; i <= requestCount; i++) {
          app.current_page = app.current_page + 1;
          await this.fetchAppItems(app.id, null, app.current_page, pageTotal)
            .then(response => {
              this.items = this.items.concat(response.data);
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      })
    );
    this.isLoading = false;
  }

  @action
  async loadQuery(query) {
    this.query = query;
    if (query.length === 0) {
      this.loadInitialItems();
    } else {
      this.isLoading = true;
      await Promise.all(
        this.apps.map(async app => {
          await this.fetchAppItems(app.id, query, app.current_page, pageTotal)
            .then(response => {
              this.items = response.data;
            })
            .catch(() => {
              this.isLoading = false;
            });
        })
      );
      this.isLoading = false;
    }
  }

  @action
  fetchAppItems(app_id, query, page, per_page) {
    return new Promise((resolve, reject) => {
      let url = `${Cookies.get('apiEnv')}/items?app_id=${app_id}&page=${page}&per_page=${per_page}&offset=0`;
      if (query) {
        url = `${Cookies.get('apiEnv')}/items?app_id=${app_id}&query=${query}`;
      }
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  /*=======================================================================
  Handlers
  =======================================================================*/

  @action
  handleItemClick(item) {
    this.props.handleItemClick(item);
    const dataItem = {
      id: item.id,
      name: item.name,
      icon: item.item_icon,
      color: item.item_color,
      type: item.item_type,
    };
    this.selectedItems.push(dataItem);
    this.props.validateInput(this.selectedItems);
  }

  @action
  handleItemRemove(id) {
    this.selectedItems = _.filter(this.selectedItems, o => o.id != id);
    this.props.handleItemRemove(id);
    this.props.validateInput(this.selectedItems);
  }

  /*=======================================================================
  Rendering
  =======================================================================*/

  renderInputValue(result) {
    return this.query;
  }

  renderResult(item, details) {
    let menuClass = classNames('');
    let tagIntent = Intent.DEFAULT;

    return (
      <React.Fragment key={item.id}>
        <MenuItem
          className={menuClass}
          onClick={details.handleClick}
          style={{ width: '800px' }}
          text={
            <Flexbox flexDirection="row" alignContent="center">
              <Flexbox paddingTop="5px">
                <span className={`${item.item_icon} push-5-r`} style={{ color: item.item_color }}></span>
              </Flexbox>
              <Flexbox marginLeft="10px">
                <Tag intent={tagIntent} round={true} minimal={true}>
                  {item.item_type}
                </Tag>
              </Flexbox>
              <Flexbox marginLeft="10px">{item.name}</Flexbox>
            </Flexbox>
          }
        />
        {item.id === this.lastItemId && this.canGetMoreData && this.query.length === 0 ? (
          <Flexbox flexDirection="row" justifyContent="center" padding="10px">
            <Button
              text={I18n.t('js.load_more')}
              icon="more"
              minimal={true}
              disabled={this.isLoading}
              onClick={this.loadMoreData.bind(this)}
            />
            <Button
              text={I18n.t('js.load_all_options', { count: this.totalDisplayedAvailableItems })}
              icon="cloud-download"
              minimal={true}
              disabled={this.isLoading}
              onClick={this.loadAllData.bind(this)}
            />
          </Flexbox>
        ) : null}
      </React.Fragment>
    );
  }

  renderNoResults() {
    return <MenuItem disabled icon="warning-sign" text={I18n.t('js.no_results_found')} />;
  }

  renderSelectedResults() {
    return _.map(this.selectedItems, item => {
      return (
        <SuggestSelectedResult
          key={item.id}
          text={item.name}
          icon={item.icon}
          type={item.type}
          color={item.color}
          onClick={e => (this.props.openDrawer != undefined ? this.props.openDrawer(item) : null)}
          onRemove={e => this.handleItemRemove(item.id)}
        />
      );
    });
  }

  render() {
    const { placeholder } = this.props;
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <SearchSuggest
          items={this.filteredItems}
          itemRenderer={this.renderResult.bind(this)}
          onItemSelect={result => this.handleItemClick(result)}
          inputValueRenderer={this.renderInputValue.bind(this)}
          noResults={this.renderNoResults()}
          disabled={this.inputDisabled}
          query={this.query}
          fill={true}
          onQueryChange={this.loadQuery.bind(this)}
          inputProps={{
            value: this.query,
            disabled: this.inputDisabled,
            intent: this.props.intent,
            placeholder: placeholder,
            leftElement:
              this.isLoading && !this.inputDisabled ? (
                <Flexbox padding="5px">
                  <Spinner size={18} />
                </Flexbox>
              ) : null,
          }}
          popoverProps={{
            usePortal: false,
            position: 'bottom-left',
            className: 'fill',
            popoverClassName: `bp3-minimal ${this.props.expandMenu ? 'suggest suggest-wide' : 'suggest'}`,
          }}
        />
        {this.selectedItems.length > 0 ? (
          <Flexbox flexDirection="column" marginTop="10px">
            {this.renderSelectedResults()}
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }
}
