/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import CurrentUserStore from '../stores/CurrentUserStore';
import DocumentationStore from '../stores/DocumentationStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import DocumentationTabs from '../components/documentation/DocumentationTabs';
import DocumentationWindow from '../components/documentation/DocumentationWindow';
import SignUpBar from '../components/layout/SignUpBar';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import ContentfulClient from '../static/ContentfulClient';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class DocumentationView extends React.Component {
  componentDidMount() {
    if (DocumentationStore.hasNoData) {
      DocumentationStore.resetStartingAttributes();
      ContentfulClient.getEntries({
        content_type: 'apiGuide',
        order: '-sys.createdAt',
      }).then(response => {
        let itemCount = response.items.length;
        _.map(response.items, apiGuide => {
          DocumentationStore.addApiGuide(apiGuide);
          if (this.props.slug === apiGuide.fields.slug) {
            DocumentationStore.selectedDocumentationType = 'guide';
            DocumentationStore.selectedDocumentation = apiGuide;
          }
          itemCount--;
        });
        if (itemCount === 0) {
          DocumentationStore.parseApiDocumentation()
            .then(() => {
              DocumentationStore.buildNavTree().then(() => {
                DocumentationStore.toggleLoading();
              });
            })
            .catch(() => {
              DocumentationStore.toggleLoading();
            });
        }
      });
    }
  }

  renderContent() {
    if (CurrentUserStore.isLoading || DocumentationStore.isLoading) {
      return (
        <Flexbox
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          paddingTop="150px"
          paddingBottom="150px"
        >
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} marginTop="10px">
        <Flexbox flexGrow={0} flexDirection="column">
          <DocumentationTabs />
        </Flexbox>
        <Flexbox flexGrow={1} flexDirection="column">
          <DocumentationWindow slug={this.props.slug} />
        </Flexbox>
      </Flexbox>
    );
  }

  renderSignUp() {
    if (
      !CurrentUserStore.userSignedIn &&
      !CurrentUserStore.isLoading
    ) {
      return <SignUpBar />;
    }
    return undefined;
  }

  render() {
    const stores = {
      CurrentUserStore,
      DocumentationStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox
              flexGrow={1}
              width="100hw"
              alignContent="stretch"
              flexDirection="column"
            >
              <LayoutHeader
                title={I18n.t('js.developers')}
                subtitle={I18n.t('js.developers_description')}
                icon={IconNames.CODE}
              />
              {this.renderSignUp()}
              <Flexbox flexGrow={1} flexDirection="column">
                <Flexbox
                  className="fixed-width-container"
                  flexDirection="row"
                  flexGrow={1}
                  marginTop="20px"
                >
                  {this.renderContent()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
