import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Icon, Tab, Tabs } from '@blueprintjs/core';

@inject('AdminStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AdminTabnav extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="row">
        <Tabs
          id="adminTabnav"
          selectedTabId={this.props.AdminStore.activeTab}
          vertical={true}
          onChange={tab => this.props.AdminStore.setActiveTab(tab)}
        >
          <Tab id="accounts">Accounts</Tab>
          <Tab id="system">{I18n.t('js.system')}</Tab>
          <Tab id="storeCategories">{I18n.t('js.store')}</Tab>
          <Tab id="accountDomains">{I18n.t('js.account_domains')}</Tab>
        </Tabs>
      </Flexbox>
    );
  }
}
