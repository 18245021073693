// @flow
import { Intent } from '@blueprintjs/core';
import type { Status } from '../components/imports/Imports/Import';

const statusToIntent = (status: Status): Intent =>
  status === 'complete'
    ? Intent.SUCCESS
    : status === 'inProgress'
    ? Intent.WARNING
    : Intent.DANGER;

export default statusToIntent;
