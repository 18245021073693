import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import ProfileTemplate from '../../models/ProfileTemplateModel';
import AccountUsersInvitationsRow from './AccountUsersInvitationsRow';
import AccountUsersNewInvitation from './AccountUsersNewInvitation';
import Paginator from '../shared/Paginator';
import Imports from '../imports/Imports';
import _ from 'lodash';

@inject('AccountUsersStore', 'ProfileTemplateActions', 'ImportStore', 'ToastStore')
@observer
export default class AccountUsersImportUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = { importableId: null };
  }

  componentDidMount() {
    /*=======================================================================
    This will download all profile templates and add them to the store
    =======================================================================*/
    this.props.ProfileTemplateActions.fetchAllProfileTemplates().then(profileTemplates => {
      profileTemplates.map(profileTemplateData => {
        const newProfileTemplate = new ProfileTemplate(
          profileTemplateData.id,
          profileTemplateData.account_id,
          profileTemplateData.name,
          profileTemplateData.description,
          profileTemplateData.default_template,
          profileTemplateData.icon,
          profileTemplateData.is_store_template,
          profileTemplateData.storeTemplate_id,
          profileTemplateData.created_at,
          profileTemplateData.created_by,
          profileTemplateData.deleted_at,
          profileTemplateData.delted_by,
          profileTemplateData.updated_at,
          profileTemplateData.updated_by
        );
        this.props.AccountUsersStore.addProfileTemplate(newProfileTemplate);
      });
      /*=======================================================================
      Next, we set the activeProfileTemplate and update the local state to render the import dialog
      =======================================================================*/
      this.props.AccountUsersStore.activeProfileTemplate = _.last(this.props.AccountUsersStore.profileTemplates);
      const importableId = this.props.AccountUsersStore.activeProfileTemplate.id;
      this.props.ImportStore.importableId = importableId;
      this.setState({ importableId });
    });
  }

  render() {
    if (this.state.importableId === null) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexDirection="column" alignItems="stretch">
            <Imports importableType="ProfileTemplate" importableId={this.state.importableId} />
          </Flexbox>
        </Flexbox>
      );
    }
  }
}
