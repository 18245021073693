import { computed, observable } from 'mobx';
import Utilities from '../../utils/Utilities';

export default class ElementDate {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;
  @observable
  minDate;
  @observable
  maxDate;
  @observable
  displayType;
  @observable
  inputType;
  @observable
  triggersTimelineEvent;
  @observable
  timelineEventDescription;

  constructor(id, elementId, name, minDate, maxDate, displayType, inputType, triggersTimelineEvent, timelineEventDescription) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
    if (minDate === '0.0') {
      this.minDate = 0;
    } else {
      this.minDate = Utilities.decimalToInt(minDate) || null;
    }
    if (maxDate === '0.0') {
      this.maxDate = 0;
    } else {
      this.maxDate = Utilities.decimalToInt(maxDate) || null;
    }
    this.displayType = displayType || '';
    this.inputType = inputType || '';
    typeof triggersTimelineEvent === 'undefined' || triggersTimelineEvent === null
      ? (this.triggersTimelineEvent = false)
      : (this.triggersTimelineEvent = triggersTimelineEvent);
    this.timelineEventDescription = timelineEventDescription || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }
}
