import React from 'react';
import { inject, observer } from 'mobx-react';
import { Alert, Button, Classes, Intent } from '@blueprintjs/core';
import UserAvatar from '../shared/UserAvatar';
import Utilities from '../../utils/Utilities';

@inject('CurrentUserStore', 'TeamUsersRequestsStore', 'ToastStore')
@observer
export default class TeamRequestsListRequestsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isApproving: false, isRejecting: false };
  }

  toggleApproving() {
    this.setState({
      isApproving: !this.state.isApproving,
    });
  }

  toggleRejecting() {
    this.setState({
      isRejecting: !this.state.isRejecting,
    });
  }

  handleApproveRequest() {
    this.props.TeamUsersRequestsStore.approveRequest(
      this.props.request
    )
      .then(() => {
        this.toggleApproving();
      })
      .catch(() => {
        this.toggleApproving();
      });
  }

  handleRejectRequest() {
    this.props.TeamUsersRequestsStore.rejectRequest(
      this.props.request
    )
      .then(() => {
        this.toggleRejecting();
      })
      .catch(() => {
        this.toggleRejecting();
      });
  }

  renderApproveAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isApproving}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleApproving()}
        confirmButtonText={I18n.t('js.approve_request')}
        onConfirm={() =>
          this.handleApproveRequest(this.props.request)
        }
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t(
          'js.are_you_sure_you_wish_to_approve_this_team_request',
          { name: this.props.request.user.fullName }
        )}
      </Alert>
    );
  }

  renderRejectAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isRejecting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleRejecting()}
        confirmButtonText={I18n.t('js.reject_request')}
        onConfirm={() => this.handleRejectRequest(this.props.request)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t('js.are_you_sure_you_wish_to_reject_this_request', {
          name: this.props.request.user.fullName,
        })}
      </Alert>
    );
  }

  renderButtons() {
    if (this.props.request.approved) {
      return (
        <Button
          className="bp3-small"
          text={I18n.t('js.go_to_user')}
          intent={Intent.DEFAULT}
          onClick={() =>
            Utilities.navigate(
              `/users/${this.props.request.user.humanFriendlyId}`,
              true
            )
          }
        />
      );
    }
    return (
      <span>
        <Button
          className="bp3-small push-5-r"
          text={I18n.t('js.approve')}
          onClick={() => this.toggleApproving()}
        />
        <Button
          className="bp3-small"
          text={I18n.t('js.reject')}
          onClick={() => this.toggleRejecting()}
        />
      </span>
    );
  }

  render() {
    return (
      <tr key={this.props.request.id}>
        <td>
          <UserAvatar
            user={this.props.request.user}
            avatarSize={20}
            path={`/users/${this.props.request.user.humanFriendlyId}`}
          />
        </td>
        <td>{this.props.request.createdAtToDate}</td>
        <td>
          {this.props.request.approved ? (
            <span className="bp3-tag bp3-intent-success bp3-minimal">
              {I18n.t('js.approved')}
            </span>
          ) : (
            <span className="bp3-tag bp3-intent-warning bp3-minimal">
              {I18n.t('js.pending')}
            </span>
          )}
        </td>
        <td>{this.renderButtons()}</td>
        {this.renderApproveAlert(this.props.request)}
        {this.renderRejectAlert(this.props.request)}
      </tr>
    );
  }
}
