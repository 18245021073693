import React from 'react';
import { computed } from 'mobx';
import Utilities from '../../utils/Utilities';
import { Intent, TagInput } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

@inject('StateRuleStore', 'ToastStore')
@observer
export default class StateActionItemEmailInput extends React.Component {
  @computed
  get selectedEmails() {
    let emails = [];
    _.map(this.props.StateRuleStore.stateActionEmailRecipients, recipient => {
      if (recipient.stateActionId == this.props.stateAction.id && recipient.email !== null && recipient.changeType !== 'deleted') {
        emails = emails.concat(recipient.email);
      }
    });
    return emails;
  }

  handleAddEmail(emails) {
    _.map(emails, email => {
      if (Utilities.validateEmail(email)) {
        this.props.handleAddRecipientEmail(email);
      } else {
        this.props.ToastStore.showToast(I18n.t('js.please_enter_a_valid_email_address'), 'danger');
      }
    });
  }

  handleRemoveEmail(email) {
    this.props.handleRemoveRecipientEmail(email);
  }

  render() {
    return (
      <TagInput
        values={this.selectedEmails}
        separator=","
        leftIcon="envelope"
        onAdd={this.handleAddEmail.bind(this)}
        onRemove={this.handleRemoveEmail.bind(this)}
        placeholder={I18n.t('js.enter_email_addresses')}
        tagProps={{
          intent: Intent.DEFAULT,
        }}
      />
    );
  }
}
