// @flow
import * as React from 'react';
import Utilities from '../../utils/Utilities';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import StyledLink from './HeaderNav/styles/StyledLink';

type HeaderLinkProps = {
  active: ?boolean,
  disabled: ?boolean,
  name: string,
  link: ?string,
  icon: ?React.Node | IconNames,
};

const HeaderLink = ({
  active,
  disabled,
  name,
  link,
  icon,
}: HeaderLinkProps) => (
  <StyledLink active={active} disabled={disabled}>
    <React.Fragment>
      {link ? (
        <span
          onClick={() => Utilities.navigate(link)}
          className="bp3-cursor-pointer"
        >
          <h4>{name}</h4>
        </span>
      ) : (
        // eslint-disable-next-line
        <span>
          <h4>{name}</h4>
        </span>
      )}
      {icon && (
        <Icon
          icon={icon}
          className={disabled ? 'bp3-text-muted' : ''}
        />
      )}
    </React.Fragment>
  </StyledLink>
);

HeaderLink.defaultProps = {
  icon: null,
  link: null,
};

export default HeaderLink;
