import React from 'react';
import _ from 'lodash';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import CommentStore from '../stores/CommentStore';
import AppStore from '../stores/AppStore';
import DynamicViewStore from '../stores/DynamicViewStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import UserProfileStore from '../stores/UserProfileStore';
import ItemStore from '../stores/ItemStore';
import TeamUsersStore from '../stores/TeamUsersStore';
import TemplateActions from '../actions/TemplateActions';
import DashboardStore from '../stores/DashboardStore';
import AppItemStore from '../stores/AppItemStore';
import ItemActions from '../actions/ItemActions';
import CommentsView from './CommentsView';
import DynamicView from '../components/dynamic_view/DynamicView';
import NewWidget from '../components/widgets/NewWidget';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import Widgets from '../components/widgets/Widgets';
import AppItemTabs from '../components/app_items/AppItemTabs';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import Utilities from '../utils/Utilities';

@observer
export default class AppItemView extends React.Component {
  @observable isLoading = true;

  componentDidMount() {
    this.toggleLoading();
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  renderContent() {
    switch (DynamicViewStore.activeTab) {
      case 'home':
        return (
          <Flexbox flexDirection="column">
            <Widgets
              editable={AppStore.activeApp.userAccessLevel === 'owner'}
              widgetableType="App"
              widgetableId={AppStore.activeApp.id}
              showEmptyState={true}
            />
            <NewWidget
              editable={AppStore.activeApp.userAccessLevel === 'owner'}
              widgetableType="App"
              widgetableId={AppStore.activeApp.id}
            />
            <Flexbox flexDirection="column" flexGrow={1} marginTop="40px">
              <CommentsView
                commentable={AppStore.activeApp}
                commentableType="App"
                canPin={AppStore.activeApp.userAccessLevel.name === 'owner'}
                stub={true}
                sortByNew={true}
                showNone={true}
                buttonText={I18n.t('js.comment_on_this_app')}
              />
            </Flexbox>
          </Flexbox>
        );
      case 'your-items':
      case 'your-colleagues':
      case 'all-items':
        if (AppStore.activeApp) {
          return (
            <Flexbox flexDirection="column">
              <DynamicView selectedApp={AppStore.activeApp} activeTab={DynamicViewStore.activeTab} />
            </Flexbox>
          );
        }
      default:
        return null;
    }
  }

  render() {
    const stores = {
      CurrentUserStore,
      CommentStore,
      ToastStore,
      AppStore,
      DynamicViewStore,
      AppItemStore,
      DashboardStore,
      ItemActions,
      UserProfileTemplateStore,
      UserProfileStore,
      TemplateActions,
      ItemStore,
      TeamUsersStore,
    };
    const { activeApp } = AppStore;
    let bgStyle = {
      background: `url('${activeApp.imageFileName}')`,
      backgroundSize: 'cover',
      height: '100px',
      width: '100px',
      borderRadius: '100px',
      border: `5px solid ${activeApp.color}`,
    };
    let iconClass = classNames('display-none');
    const darkenAmount = 0.25; // %
    if (activeApp.imageFileName === '') {
      bgStyle = {
        background: `linear-gradient(${activeApp.color}, ${Utilities.darkenHex(activeApp.color, darkenAmount)})`,
        height: '100px',
        width: '100px',
        borderRadius: '100px',
        border: `5px solid ${activeApp.color}`,
      };
      iconClass = classNames('fa fa-2x text-white', activeApp.icon);
    }

    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox flexDirection="column" className="push-20-t">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox flex={1} flexDirection="column">
            <Flexbox flexDirection="column" className="bp3-profile-header-row" flexGrow={1} justifyContent="space-between">
              <Flexbox flexDirection="row">
                <Flexbox flexDirection="column">
                  <Flexbox
                    style={bgStyle}
                    className="bp3-cursor-pointer"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginRight="20px"
                    flexShrink={0}
                  >
                    <Flexbox className={iconClass} />
                  </Flexbox>
                </Flexbox>
                <Flexbox flexDirection="column" paddingTop="20px">
                  <Flexbox flexDirection="row">
                    <h2 className="bp3-heading push-10-r">{AppStore.activeApp.name}</h2>
                    {this.props.renderTags()}
                  </Flexbox>
                  <p>{AppStore.activeApp.description}</p>
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <AppItemTabs />
            <Flexbox paddingLeft="30px" paddingRight="30px" paddingTop="20px" flexDirection="column">
              {this.renderContent()}
            </Flexbox>
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
