import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementDescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox
          flexGrow={1}
          flexDirection="column"
          flexWrap="wrap"
          paddingTop="10px"
        >
          <p className="pre bp3-running-text">
            {this.props.element.elementDescription.name}
          </p>
        </Flexbox>
      </Flexbox>
    );
  }
}
