/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class StatusFlag {
  @observable id;
  @observable appId;
  @observable name;
  @observable color;
  @observable isArchived;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(id, appId, name, color, isArchived, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy) {
    this.id = id || '';
    this.appId = appId || '';
    this.name = name || '';
    this.color = color || '';
    typeof isArchived === 'undefined' || isArchived === null ? (this.isArchived = false) : (this.isArchived = isArchived);
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get colorValid() {
    if (this.color.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get colorError() {
    if (this.color.length < 1) {
      return I18n.t('js.color_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
