/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent, MenuItem, NonIdealState, Spinner, HTMLSelect } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import StatusFlag from '../../models/StatusFlagModel';
import classNames from 'classnames';
import _ from 'lodash';

const EditStatusFlagSelect = Select.ofType(StatusFlag);

@inject('ItemStore', 'ItemActions', 'AppStore')
@observer
export default class ItemEditStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isLoading: false,
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleSubmitting() {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    const newObj = {
      id: this.props.ItemStore.item.id,
      state_id: this.props.ItemStore.newItem.stateId,
    };
    this.props.ItemActions.changeItem(newObj)
      .then(response => {
        this.props.ItemStore.addItem(response.data);
        this.toggleSubmitting();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  handleInputChange(e) {
    this.props.ItemStore.newItem.stateId = e.target.value;
  }

  handleCancel() {
    this.props.ItemStore.resetItemAttribute('stateId');
    this.toggleOpen();
  }

  renderContent() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column" flexGrow={1}>
            <div className="bp3-form-group">
              <div className="bp3-form-content">
                <HTMLSelect
                  disabled={this.props.AppStore.activeApp.userPermissionLevel.name != 'owner'}
                  onBlur={this.handleInputChange.bind(this)}
                  value={this.props.ItemStore.newItem.stateId}
                  onChange={this.handleInputChange.bind(this)}
                >
                  {_.map(this.props.ItemStore.states, state => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </HTMLSelect>
              </div>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox>
          <div>
            <Button
              intent={Intent.PRIMARY}
              type="submit"
              disabled={this.props.AppStore.activeApp.userPermissionLevel.name != 'owner'}
              text={I18n.t('js.save_changes')}
              loading={this.state.isSubmitting}
              icon="tick"
            />
            <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
          </div>
        </Flexbox>
      </form>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* ignore jsx-a11y as toggle functionality included eslewhere for */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.item_state', {
                item: this.props.AppStore.activeApp.item,
              })}
            </h4>
            <p className="noselect">
              {I18n.t('js.change_item_state_text', {
                item: this.props.AppStore.activeApp.item,
              })}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>{this.renderContent()}</Collapse>
      </Flexbox>
    );
  }
}
