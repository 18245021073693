import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Classes,
  Collapse,
  Toaster,
} from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';

@inject('AppStore', 'ToastStore')
@observer
export default class AppBulkPdfExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  triggerPdfExport() {
    this.props.AppStore.exportAllItemsToPdf().then(response => {
      if (response.status == 201) {
        this.props.ToastStore.showToast(
          I18n.t('js.items_successfully_exported'),
          'success'
        );
      }
      this.toggleOpen();
    });
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.export_all_items')}
            </h4>
            <p className="noselect">
              {I18n.t('js.export_all_items_as_pdf')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <div className="bp3-callout bp3-intent-success bp3-icon-document-share">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>
              {I18n.t('js.are_you_sure')}
            </h4>
            {I18n.t('js.all_items_will_be_exported_as_zip')}
          </div>
          <Flexbox marginTop="20px">
            <Button
              text={I18n.t('js.export_all_items')}
              className="bp3-intent-success"
              icon="social-media"
              onClick={() => this.triggerPdfExport()}
            />
          </Flexbox>
        </Collapse>
      </Flexbox>
    );
  }
}
