/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import User from '../models/UserModel';

export default class UserTeam {
  @observable
  id;
  @observable
  teamId;
  @observable
  userId;
  @observable
  isPrimary;
  @observable
  isOwner;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;
  @observable
  user = new User();

  constructor(id, teamId, userId, isPrimary, isOwner, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy) {
    this.id = id || '';
    this.teamId = teamId || '';
    this.userId = userId || '';
    typeof isPrimary === 'undefined' ? (this.isPrimary = false) : (this.isPrimary = isPrimary);
    typeof isOwner === 'undefined' ? (this.isOwner = false) : (this.isOwner = isOwner);
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
