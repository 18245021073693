import { action, observable, computed } from 'mobx';
import { createConsumer } from '@rails/actioncable';
import Cookies from 'js-cookie';
import CurrentUserStore from './CurrentUserStore';
import ImportTemplate from '../models/ImportTemplateModel';
import ImportActions from '../actions/ImportActions';
import Import from '../models/ImportModel';
import _ from 'lodash';
import Utilities from '../utils/Utilities';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Paginator from '../models/PaginatorModel';
import Item from '../models/ItemModel';

class ImportStore {
  @observable importableType = null;
  @observable importableId = null;
  @observable importTemplate = null;
  @observable importData = [];
  @observable isLoadingImports = true;
  @observable importColumns = ColumnDefinitions.importColumns;
  @observable pagination = new Paginator();

  @computed get hasValidImportable() {
    if (this.importTemplate != null) {
      if (this.importTemplate.isDynamic) {
        return this.importableType != null && this.importableId != null;
      } else {
        return this.importableType != null;
      }
    }
    return this.importableType != null && this.importableId != null;
  }

  @computed get hasImportTemplate() {
    return this.importTemplate != null;
  }

  @action toggleLoadingImports() {
    this.isLoadingImports = !this.isLoadingImports;
  }

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action setImportDetails(importableType, importableId) {
    this.importableType = importableType;
    this.importableId = importableId;
  }

  @action addImportTemplate(data) {
    const newImportTemplate = new ImportTemplate(...Utilities.getObjectValues(data));
    this.importTemplate = newImportTemplate;
    this.getImports();
  }

  @action addImport(data) {
    if (data.import_template_id == this.importTemplate.id) {
      // we only want to add imports relevant to this store.

      const newImport = new Import(...Utilities.getObjectValues(data));
      this.importData = _.unionBy([newImport], this.importData, 'id');
    }
    this.sortImports();
  }

  @action removeImport(importId) {
    if (this.importData.length === 1) {
      this.importData = [];
    } else {
      this.importData = _.filter(this.importData, o => {
        return o.id != importId;
      });
      this.sortImports();
    }
  }

  @action getImports() {
    // we fetch new imports that belong to the import template here
    this.importData = [];
    this.isLoadingImports = true;
    ImportActions.fetchImports(this.importTemplate.id, 'full', 1, 25, 0)
      .then(response => {
        this.createPaginator(response.headers);
        _.map(response.data, importData => {
          this.addImport(importData);
        });
        this.isLoadingImports = false;
      })
      .catch(() => {
        this.isLoadingImports = false;
      });
  }

  @action
  setImportSortParams(param) {
    const newImportColumns = this.importColumns;
    _.map(newImportColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.importColumns = newImportColumns;
    this.sortImports();
  }

  @action
  sortImports() {
    let newImports = this.importData;
    const activeColumn = _.find(this.importColumns, o => o.active);
    newImports = _.orderBy(newImports, [activeColumn.id], [activeColumn.sortDir]);
    this.importData = newImports;
  }

  @action
  setUpActionCable() {
    const that = this;
    const importCable = createConsumer(`${Cookies.get('cablePath')}`);
    importCable.subscriptions.create(
      {
        channel: 'ImportsChannel',
        userId: CurrentUserStore.currentUser.id,
      },
      {
        received(data) {
          that.addImport(JSON.parse(data));
        },
      }
    );
  }

  @action resetStore() {
    this.importableType = null;
    this.importableId = null;
    this.importTemplate = null;
    this.importData = [];
    this.isLoadingImports = true;
    this.importColumns = ColumnDefinitions.importColumns;
    this.pagination = new Paginator();
  }
}

const store = new ImportStore();
export default store;
