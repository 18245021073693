/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class Activity {
  @observable id;
  @observable userId;
  @observable accountId;
  @observable eventName;
  @observable additionalInformation;
  @observable activityObjectId;
  @observable activityObjectType;
  @observable activityReferenceObjectId;
  @observable activityReferenceObjectType;
  @observable commentCount;
  @observable email;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    userId,
    accountId,
    eventName,
    additionalInformation,
    activityObjectId,
    activityObjectType,
    activityReferenceObjectId,
    activityReferenceObjectType,
    commentCount,
    email,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.userId = userId || '';
    this.accountId = accountId || '';
    this.eventName = eventName || '';
    this.additionalInformation = additionalInformation || '';
    this.activityObjectId = activityObjectId || '';
    this.activityObjectType = activityObjectType || '';
    this.activityReferenceObjectId = activityReferenceObjectId || '';
    this.activityReferenceObjectType = activityReferenceObjectType || '';
    this.commentCount = commentCount || 0;
    this.email = email || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get eventOccured() {
    return moment.utc(this.createdAt).fromNow();
  }
}
