import React from 'react';
import { observable, computed, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  Icon,
  Classes,
  Collapse,
  FormGroup,
  Intent,
  Button,
  Tag,
  Popover,
  Position,
  PopoverInteractionKind,
  InputGroup,
  NumericInput,
  Menu,
  MenuItem,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import LengthValidator from '../../shared/LengthValidator';
import ElementIdSelector from '../../shared/ElementIdSelector';
import classNames from 'classnames';
import FormulaBuilder from '../../shared/FormulaBuilder';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementCalculation extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);
    this.state = {
      isBlurred: [],
      isLoading: false,
    };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      min_length: this.store.activeElement.minLength,
      max_length: this.store.activeElement.maxLength,
      is_required: this.store.activeElement.isRequired,
    };
    const elementCalculationObj = {
      id: this.store.activeElement.elementCalculation.id,
      name: this.store.activeElement.elementCalculation.name,
      formula_object_string: this.store.activeElement.elementCalculation.formula_object_string,
      decimal_places: this.store.activeElement.elementCalculation.decimalPlaces,
      prefix: this.store.activeElement.elementCalculation.prefix,
      suffix: this.store.activeElement.elementCalculation.suffix,
    };
    this.props.TemplateActions.changeSubElement(elementCalculationObj, 'element_calculations')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleNumericChange(value, attr) {
    this.store.updateActiveElement(value, attr);
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleFormulaChange(formula) {
    this.store.updateActiveElement(formula, 'formula_object_string');
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementCalculation[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementCalculation[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementCalculation[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementCalculation[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      const { availableElementsForFormulaBuilder } = this.store;
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-calculator push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.calculation_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementCalculation.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementCalculation.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={this.store.activeElement.elementCalculation.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" marginRight="20px">
              <label className="bp3-label" htmlFor="decimalPlaces">
                {I18n.t('js.decimal_places')}
              </label>
              <div className="bp3-form-content">
                <NumericInput
                  allowNumericCharactersOnly={true}
                  name="decimalPlaces"
                  min={0}
                  max={10}
                  value={this.store.activeElement.elementCalculation.decimalPlaces}
                  onValueChange={val => this.handleNumericChange(val, 'decimalPlaces')}
                />
              </div>
            </Flexbox>
            <Flexbox flexDirection="row" marginTop="20px">
              <Flexbox flexDirection="column" flexGrow={1} marginRight="10px">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="prefix">
                    {I18n.t('js.prefix')}
                  </label>
                  <div className="bp3-form-content">
                    <div className="bp3-input-group">
                      <input
                        autoComplete="off"
                        rows={2}
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.optional_prefix')}
                        name="prefix"
                        value={this.store.activeElement.elementCalculation.prefix}
                        onChange={this.handleInputChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} marginLeft="10px">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="suffix">
                    {I18n.t('js.suffix')}
                  </label>
                  <div className="bp3-form-content">
                    <div className="bp3-input-group">
                      <input
                        autoComplete="off"
                        rows={2}
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.optional_suffix')}
                        name="suffix"
                        value={this.store.activeElement.elementCalculation.suffix}
                        onChange={this.handleInputChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <FormulaBuilder
              elements={availableElementsForFormulaBuilder}
              initialFormula={this.store.activeElement.elementCalculation.formula_object_string}
              handleFormulaChange={this.handleFormulaChange.bind(this)}
            />
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    return (
      <FormGroup label={this.renderLabel(this.props.element.elementCalculation.name)} labelFor={this.props.element.id}>
        <Flexbox className="bp3-card bg-gray" flex="row" alignItems="center">
          <Icon icon="calculator" />
          <span className="push-10-l">
            {this.props.element.elementCalculation.prefix}
            <strong>[{I18n.t('js.result').toUpperCase()}]</strong>
            {this.props.element.elementCalculation.suffix}
          </span>
        </Flexbox>
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
