// @flow
import { computed, observable } from 'mobx';
import type { UUID } from '../types/DataViewTypes';

export default class ReportTemplateFilter {
  @observable
  id: UUID;
  @observable
  reportTemplateColumnId: UUID;
  @observable
  operand: string; // todo type
  @observable
  testValue: string;
  @observable
  createdBy: ?string;
  @observable
  updatedBy: ?string;
  @observable
  deletedBy: ?string;
  @observable
  deletedAt: ?string;
  @observable
  createdAt: ?string;
  @observable
  updatedAt: ?string;

  // $FlowFixMe
  constructor({
    id = '',
    report_template_column_id = '',
    operand = 'equals',
    test_value = '',
    created_by,
    updated_by,
    deleted_by,
    deleted_at,
    created_at,
    updated_at,
  }) {
    this.id = id;
    this.reportTemplateColumnId = report_template_column_id;
    this.operand = operand;
    this.testValue = test_value;
    this.createdBy = created_by;
    this.updatedBy = updated_by;
    this.deletedBy = deleted_by;
    this.deletedAt = deleted_at;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
  }

  @computed
  get isPersisted(): boolean {
    return this.createdAt ? this.createdAt.length > 0 : false;
  }
}
