/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React, { Component, Fragment } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Button, Dialog, Intent, Spinner } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

@inject('CalendarStore', 'CurrentUserStore')
@observer
export default class ItemCalendar extends Component {
  componentDidMount() {
    this.props.CalendarStore.setLoading(true);
    this.props.CalendarStore.fetchAndAddItems();
  }

  isAllDay(event) {
    const charactersForTime = -10;
    return event.start_date.slice(charactersForTime) === '00:00.000Z' && event.end_date.slice(charactersForTime) === '00:00.000Z';
  }

  loadMonthsEvents(date) {
    this.props.CalendarStore.setLoading(true);
    const startOfMonth = moment.utc(date).startOf('month');
    const endOfMonth = moment.utc(date).endOf('month');
    this.props.CalendarStore.fetchAndAddItems(startOfMonth, endOfMonth);
  }

  customEventPropGetter(event) {
    return {
      style: { background: event.item_color },
    };
  }

  displayEventPopover(event) {
    this.props.CalendarStore.openDialog();
    this.props.CalendarStore.dialogCalendarItem = event;
  }

  navigateToItem(event) {
    Utilities.navigate(`/items/${event.item_id}`);
  }

  render() {
    if (this.props.CalendarStore.isLoading || this.props.CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Fragment>
        <BigCalendar
          events={this.props.CalendarStore.calendarItems}
          startAccessor="start_date"
          endAccessor="end_date"
          titleAccessor="name"
          views={['month', 'agenda']}
          drilldownView="agenda"
          onSelectEvent={this.displayEventPopover.bind(this)}
          onNavigate={this.loadMonthsEvents.bind(this)}
          allDayAccessor={this.isAllDay}
          eventPropGetter={this.customEventPropGetter}
        />
        <Dialog
          portalContainer={document.body}
          icon={this.props.CalendarStore.dialogCalendarItem.item_icon}
          isOpen={this.props.CalendarStore.dialogIsOpen}
          onClose={() => this.props.CalendarStore.closeDialog()}
          title={this.props.CalendarStore.dialogCalendarItem.name}
        >
          <div className="bp3-dialog-body">
            <p>
              <strong>Type:</strong> {this.props.CalendarStore.dialogCalendarItem.item_type}
            </p>
            <p>
              <strong>Start Date:</strong>
              &nbsp;
              {moment.utc(this.props.CalendarStore.dialogCalendarItem.start_date).format('dddd Do MMMM YYYY')}
            </p>
            <p>
              <strong>End Date:</strong>
              &nbsp;
              {moment.utc(this.props.CalendarStore.dialogCalendarItem.end_date).format('ddd Do MMMM YYYY')}
            </p>
          </div>
          <div className="bp3-dialog-footer">
            <Button
              intent={Intent.PRIMARY}
              onClick={() => this.navigateToItem(this.props.CalendarStore.dialogCalendarItem)}
              text="View full item"
            />
          </div>
        </Dialog>
      </Fragment>
    );
  }
}
