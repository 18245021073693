import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tabs, Tab } from '@blueprintjs/core';
import UsageDefinitions from '../../static/UsageDefinitions';
import UsageMetric from '../shared/UsageMetric';

@inject('WorkspaceStore', 'ToastStore')
@observer
export default class WorkspaceUsage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabId: 'users',
    };
  }

  handleTabChange(tab) {
    this.setState({ selectedTabId: tab });
  }

  render() {
    return (
      <Flexbox flexDirection="row" flexGrow={1}>
        <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
          {UsageDefinitions.workspaceUsageTypes.map(usage => {
            return <UsageMetric usage={usage} key={usage.title} workspace={this.props.WorkspaceStore.activeWorkspace} />;
          })}
        </Flexbox>
      </Flexbox>
    );
  }
}
