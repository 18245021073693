/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import PermissionDefinitions from '../static/PermissionDefinitions';
import WorkspaceStore from '../stores/WorkspaceStore';
import _ from 'lodash';

export default class App {
  @observable id;
  @observable workspaceId;
  @observable workspaceFolderId;
  @observable name;
  @observable description;
  @observable item;
  @observable itemPlural;
  @observable itemLayout;
  @observable icon;
  @observable color;
  @observable imageFileName;
  @observable permissionType;
  @observable status;
  @observable rowOrder;
  @observable rowOrderFolder;
  @observable userAccessLevel;
  @observable webformEnabled;
  @observable webformEmailRequired;
  @observable webformAnonymous;
  @observable webformPath;
  @observable defaultViewFolder;
  @observable defaultProcessVisible;
  @observable includeInQuickCreateMenu;
  @observable dummyData;
  @observable lockParticipants;
  @observable commentCount;
  @observable isStoreTemplate;
  @observable storeTemplateId;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    workspaceId,
    workspaceFolderId,
    name,
    description,
    item,
    itemPlural,
    itemLayout,
    icon,
    color,
    imageFileName,
    permissionType,
    status,
    rowOrder,
    rowOrderFolder,
    userAccessLevel,
    webformEnabled,
    webformEmailRequired,
    webformAnonymous,
    webformPath,
    defaultViewFolder,
    defaultProcessVisible,
    includeInQuickCreateMenu,
    dummyData,
    lockParticipants,
    commentCount,
    isStoreTemplate,
    storeTemplateId,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.workspaceId = workspaceId || '';
    this.workspaceFolderId = workspaceFolderId || '';
    this.name = name || '';
    this.description = description || '';
    this.item = item || '';
    this.itemPlural = itemPlural || '';
    this.itemLayout = itemLayout || '';
    this.icon = icon || '';
    this.color = color || '';
    this.imageFileName = imageFileName || '';
    this.permissionType = permissionType;
    this.status = status || 'draft';
    this.rowOrder = rowOrder || 0;
    this.rowOrderFolder = rowOrderFolder || 0;
    this.userAccessLevel = userAccessLevel || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof webformEnabled === 'undefined' || webformEnabled === null
      ? (this.webformEnabled = false)
      : (this.webformEnabled = webformEnabled);
    // eslint-disable-next-line no-unused-expressions
    typeof webformEmailRequired === 'undefined' || webformEmailRequired === null
      ? (this.webformEmailRequired = false)
      : (this.webformEmailRequired = webformEmailRequired);
    // eslint-disable-next-line no-unused-expressions
    typeof webformAnonymous === 'undefined' || webformAnonymous === null
      ? (this.webformAnonymous = false)
      : (this.webformAnonymous = webformAnonymous);
    this.webformPath = webformPath || '';
    this.defaultViewFolder = defaultViewFolder || '';
    typeof defaultProcessVisible === 'undefined' || defaultProcessVisible === null
      ? (this.defaultProcessVisible = false)
      : (this.defaultProcessVisible = defaultProcessVisible);
    typeof includeInQuickCreateMenu === 'undefined' || includeInQuickCreateMenu === null
      ? (this.includeInQuickCreateMenu = false)
      : (this.includeInQuickCreateMenu = includeInQuickCreateMenu);
    typeof dummyData === 'undefined' || dummyData === null ? (this.dummyData = false) : (this.dummyData = dummyData);
    typeof lockParticipants === 'undefined' || lockParticipants === null
      ? (this.lockParticipants = false)
      : (this.lockParticipants = lockParticipants);
    this.commentCount = commentCount || 0;
    // eslint-disable-next-line no-unused-expressions
    typeof isStoreTemplate === 'undefined' || isStoreTemplate === null
      ? (this.isStoreTemplate = false)
      : (this.isStoreTemplate = isStoreTemplate);
    this.storeTemplateId = storeTemplateId || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get strippedWebformPath() {
    // eslint-disable-next-line
    return this.webformPath.replace(/^https?\:\/\//i, '');
  }

  @computed
  get userPermissionLevel() {
    const findPerimssion = _.find(PermissionDefinitions.permissionTypes, o => o.name === this.userAccessLevel);
    if (findPerimssion) {
      return findPerimssion;
    }
    return PermissionDefinitions.noPermissions;
  }

  @computed
  get userCanEdit() {
    return this.userPermissionLevel.name === 'owner' || this.userPermissionLevel.name === 'create_view_and_edit';
  }

  @computed
  get imageFileNameWidget() {
    let replacement = _.replace(this.imageFileName, 't_profile_image', 't_workspace_widget');
    if (WorkspaceStore.hasFolders) {
      replacement = _.replace(this.imageFileName, 't_profile_image', 't_workspace_widget_larger');
      if (this.userAccessLevel === 'access_denied') {
        replacement = _.replace(this.imageFileName, 't_profile_image', 't_workspace_widget_larger/t_locked_overlay');
      }
    } else {
      if (this.userAccessLevel === 'access_denied') {
        replacement = _.replace(this.imageFileName, 't_profile_image', 't_workspace_widget/t_locked_overlay');
      }
    }
    return replacement;
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get descriptionValid() {
    if (this.description.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get descriptionError() {
    if (this.description.length < 1) {
      return I18n.t('js.description_is_required');
    }
    return null;
  }

  @computed
  get itemValid() {
    if (this.item.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get itemError() {
    if (this.item.length < 1) {
      return I18n.t('js.item_is_required');
    }
    return null;
  }

  @computed
  get itemPluralValid() {
    if (this.itemPlural.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get itemPluralError() {
    if (this.itemPlural.length < 1) {
      return I18n.t('js.item_plural_is_required');
    }
    return null;
  }

  @computed
  get isPeristed() {
    return this.createdAt.length > 0;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
