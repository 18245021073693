import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, Intent, MenuItem, Icon, Spinner, Tag } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import DashboardStore from '../../stores/DashboardStore';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';

const SearchSuggest = Suggest.ofType();

@inject('CurrentUserStore')
@observer
export default class MainSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = { query: '', isLoading: false };
    this.performQuery = _.debounce(this.performQuery, 300);
  }

  performQuery(query) {
    DashboardStore.performSearch(query)
      .then(response => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  renderResult(item, details) {
    let menuClass = classNames('');
    let minimal = true;
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY);
      minimal = false;
    }
    let tagIntent = Intent.DEFAULT;
    switch (item.type) {
      case 'app':
        tagIntent = Intent.SUCCESS;
        break;
      case 'team':
        tagIntent = Intent.WARNING;
        break;
      case 'item':
        tagIntent = Intent.DEFAULT;
        break;
      case 'user':
        tagIntent = Intent.DANGER;
        break;
    }

    return (
      <MenuItem
        className={menuClass}
        key={Math.random()}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            <Flexbox>
              <Avatar
                src={item.image ? item.image : `https://cdn.auth0.com/avatars/${item.name.substring(0, 2).toLowerCase()}.png`}
                size={22}
                round={true}
                className=""
              />
            </Flexbox>
            <Flexbox marginLeft="10px">
              <Tag intent={tagIntent} minimal={minimal}>
                {_.capitalize(item.type)}
              </Tag>
            </Flexbox>
            <Flexbox marginLeft="10px">{item.name}</Flexbox>
          </Flexbox>
        }
      />
    );
  }

  renderNoResults() {
    if (this.state.query.length > 1) {
      return (
        <MenuItem
          disabled
          icon={this.state.isLoading ? <Spinner size={20} /> : 'warning-sign'}
          text={this.state.isLoading ? I18n.t('js.searching') : I18n.t('js.no_results_found')}
        />
      );
    }
    return <MenuItem disabled icon="inbox-search" text={I18n.t('js.start_typing')} />;
  }

  renderInputValue(result) {
    return result.name;
  }

  handleResultSelect(result) {
    Utilities.navigate(result.url);
  }

  handleCancel() {
    this.setState({ query: '' });
  }

  handleQueryChange(query) {
    this.setState({ query: query });
    if (query.length > 1) {
      this.setState({ isLoading: true });
      this.performQuery(query);
    }
  }

  render() {
    const store = DashboardStore;
    return (
      <Flexbox flexDirection="row" flexGrow={1} className="main-search-container" id="bp3-input-search-box">
        <SearchSuggest
          items={store.searchResults}
          itemRenderer={this.renderResult.bind(this)}
          onItemSelect={this.handleResultSelect.bind(this)}
          inputValueRenderer={this.renderInputValue.bind(this)}
          noResults={this.renderNoResults()}
          query={this.state.query}
          fill={true}
          onQueryChange={this.handleQueryChange.bind(this)}
          inputProps={{
            placeholder: I18n.t('js.search'),
            leftIcon: 'search',
            large: true,
            className: 'bp3-main-search',
          }}
          popoverProps={{
            usePortal: false,
            position: 'bottom-left',
            className: 'fill',
            popoverClassName: 'bp3-minimal main-search',
          }}
        />
      </Flexbox>
    );
  }
}
