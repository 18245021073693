const hookTypes = [
  'item_created',
  'item_submitted',
  'item_updated',
  'item_deleted',
  'user_joined_account',
  'user_left_account',
  'user_updated',
  'team_created',
  'team_updated',
  'team_deleted',
  'user_joined_team',
  'user_left_team',
  'user_relationship_added',
  'user_relationship_deleted',
];

class HookTypes {
  static get hookTypes() {
    return hookTypes;
  }
}

export default HookTypes;
