import React, { Component } from 'react';
import { AnchorButton, Popover, Menu, MenuItem, Position, Intent } from '@blueprintjs/core';
import WidgetStore from '../../stores/WidgetStore';
import Flexbox from 'flexbox-react';
import _ from 'lodash';

export default class NewWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  toggleLoading() {
    this.setState({ isLoading: !this.state.isLoading });
  }

  handleCreateNewWidget(widget_type) {
    this.toggleLoading();
    let width = 4;
    if (WidgetStore.widgets.length > 0) {
      const lastWidget = _.last(WidgetStore.widgets);
      const lastWidth = lastWidget.width;
      if (lastWidth < 4) {
        width = 4 - lastWidth;
      }
    }
    const widgetObj = {
      widgetable_id: this.props.widgetableId,
      widgetable_type: this.props.widgetableType,
      widget_type: widget_type,
      width: width,
    };
    WidgetStore.createWidget(widgetObj)
      .then(response => {
        this.toggleLoading();
      })
      .catch(err => {
        this.toggleLoading();
      });
  }

  renderNewWidgetMenu() {
    if (this.props.editable) {
      return (
        <Menu>
          <MenuItem icon="add-to-artifact" text={`${I18n.t('js.post')}...`} onClick={() => this.handleCreateNewWidget('post')} />
          <MenuItem icon="calendar" text={`${I18n.t('js.schedule')}...`} onClick={() => this.handleCreateNewWidget('schedule')} />
        </Menu>
      );
    }
    return null;
  }

  render() {
    return (
      <Popover
        content={this.renderNewWidgetMenu()}
        position={Position.BOTTOM}
        portalContainer={document.body}
        disabled={!this.props.editable}
      >
        <AnchorButton
          outlined={false}
          text={I18n.t('js.add_new_widget')}
          icon="add-to-artifact"
          loading={this.state.isLoading}
          disabled={!this.props.editable}
        />
      </Popover>
    );
  }
}
