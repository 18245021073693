import React from 'react';
import Flexbox from 'flexbox-react';

export default class ElementReadOnly extends React.Component {
  render() {
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={1}
        className="bp3-form-helper-text bp3-intent-danger"
      >
        <span>
          {I18n.t('js.element_is_read_only_in_this_state', {
            element: this.props.element.fullTitle,
          })}
        </span>
      </Flexbox>
    );
  }
}
