/* Legacy code - ignore this and magic number errors */
/* eslint class-methods-use-this: 0 no-magic-numbers: 0*/
import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { Dialog, NonIdealState, Spinner } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import request from 'superagent';
import ImageGalleryImage from './ImageGalleryImage';
import _ from 'lodash';

const clientId =
  '95371830d63fca9460be587f57aa3697ba4c3f1d54c538eca0b255bacd81878b';

export default class UnsplashImageSearch extends React.Component {
  @observable
  results = [];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingMore: false,
      searchQuery: this.props.initialTerm,
      page: 1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dialogOpen) {
      this.loadImages();
    }
  }

  loadImages() {
    this.toggleLoading();
    this.fetchImages(this.state.searchQuery, this.state.page)
      .then(response => {
        _.map(response.body.results, result => {
          this.addResult(result);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  loadMoreImages() {
    this.toggleLoadingMore();
    this.fetchImages(this.state.searchQuery, this.state.page)
      .then(response => {
        _.map(response.body.results, result => {
          this.addResult(result);
        });
        this.toggleLoadingMore();
      })
      .catch(() => {
        this.toggleLoadingMore();
      });
  }

  fetchImages(query, page) {
    return new Promise((resolve, reject) => {
      request
        .get(
          `https://api.unsplash.com/search/photos?page=${page}&query=${this.state.searchQuery}&client_id=${clientId}`
        )
        .end((err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
    });
  }

  addResult(result) {
    const findResult = _.find(this.results, o => o.id === result.id);
    if (!findResult) {
      this.results.push(result);
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  toggleLoadingMore() {
    this.setState({ isLoadingMore: !this.state.isLoadingMore });
  }

  openDialog() {
    this.setState({
      isLoading: false,
      isLoadingMore: false,
      searchQuery: this.props.initialTerm,
      page: 1,
    });
  }

  handleSearchQueryChange(e) {
    e.preventDefault();
    this.setState({ searchQuery: e.target.value, page: 1 });
  }

  handleSearchKeyDown(e) {
    if (e.keyCode === 13 && this.state.searchQuery !== '') {
      this.handleSearch();
    }
  }

  downloadImage(result) {
    return new Promise((resolve, reject) => {
      request
        .get(
          `${result.links.download_location}?client_id=${clientId}`
        )
        .end((err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
    });
  }

  handleImageClick(result) {
    this.props.handleImageSelect(result);
    this.downloadImage(result);
  }

  handleSearch() {
    this.toggleLoading();
    this.results = [];
    this.fetchImages(this.state.searchQuery, this.state.page)
      .then(response => {
        _.map(response.body.results, result => {
          this.addResult(result);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleAboutToReachBottom() {
    if (
      !this.state.isLoading &&
      !this.state.isLoadingMore &&
      this.results.length > 9
    ) {
      let newApp = this.state.page;
      newApp++;
      this.setState({ page: newApp });
      this.loadMoreImages();
    }
  }

  handleScrollUpdate(values) {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 100;
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t > 1) {
      this.handleAboutToReachBottom();
    }
  }

  renderSearch() {
    return (
      <Flexbox flexGrow={1} marginBottom="20px">
        <Flexbox
          className="bp3-input-group bp3-large bp3-round"
          flexGrow={1}
        >
          <span className="bp3-icon bp3-icon-search" />
          <input
            className="bp3-input"
            autoFocus
            onKeyDown={this.handleSearchKeyDown.bind(this)}
            placeholder={I18n.t('js.seach_for_an_image')}
            dir="auto"
            value={this.state.searchQuery}
            onChange={this.handleSearchQueryChange.bind(this)}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  renderLoadingMore() {
    if (this.state.isLoadingMore) {
      return (
        <Flexbox
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderImages() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          <NonIdealState
            title={I18n.t('js.fetching_images')}
            description={I18n.t('js.please_wait')}
            icon={<Spinner />}
          />
        </Flexbox>
      );
    }
    if (this.results.length === 0) {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          <NonIdealState
            title={I18n.t('js.there_is_nothing_to_show_right_now')}
            description={I18n.t(
              'js.no_images_found_matching_search_term'
            )}
            icon="media"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <Scrollbars
          autoHide={true}
          onUpdate={this.handleScrollUpdate.bind(this)}
          style={{
            height: '70vh',
          }}
        >
          <Flexbox flexDirection="row" flexGrow={1} flexWrap="wrap">
            {_.map(this.results, result => (
              <ImageGalleryImage
                result={result}
                key={result.id}
                handleImageClick={this.handleImageClick.bind(this)}
              />
            ))}
          </Flexbox>
          {this.renderLoadingMore()}
        </Scrollbars>
      </Flexbox>
    );
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="search"
        className="image-view-dialog"
        isOpen={this.props.dialogOpen}
        onClose={this.props.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={true}
        title={I18n.t('js.search_for_an_image')}
      >
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox className="bp3-dialog-body" flexGrow={1}>
            <Flexbox flexDirection="column" flexGrow={1}>
              {this.renderSearch()}
              {this.renderImages()}
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Dialog>
    );
  }

  render() {
    return <Flexbox>{this.renderDialog()}</Flexbox>;
  }
}
