/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { AnchorButton, Classes, Collapse, FormGroup, Intent, MenuItem, Switch } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import Flexbox from 'flexbox-react';
import ElementSelectOptions from './ElementSelectOptions';
import ElementSelectDisplay from './ElementSelectDisplay';
import ElementTag from '../../shared/ElementTag';
import ElementIdSelector from '../../shared/ElementIdSelector';
import SelectDefinitions from '../../../static/SelectDefinitions';
import classNames from 'classnames';
import _ from 'lodash';

const OptionSelect = Select.ofType(SelectDefinitions.optionTypes);
const DisplaySelect = Select.ofType(SelectDefinitions.displayTypes);

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementSelect extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      is_required: this.store.activeElement.isRequired,
    };
    const elementSelectObj = {
      id: this.store.activeElement.elementSelect.id,
      name: this.store.activeElement.elementSelect.name,
      color: this.store.activeElement.elementSelect.color,
      placeholder_text: this.store.activeElement.elementSelect.placeholderText,
      option_type: this.store.activeElement.elementSelect.optionType,
      display_type: this.store.activeElement.elementSelect.displayType,
    };
    this.handleOptionSubmission()
      .then(() => {
        this.props.TemplateActions.changeSubElement(elementSelectObj, 'element_selects')
          .then(() => {
            this.props.TemplateActions.changeElement(elementObj)
              .then(() => {
                this.store.setActiveArea(null);
                this.toggleLoading();
              })
              .catch(() => {
                this.toggleLoading();
              });
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleOptionSubmission() {
    return new Promise((resolve, reject) => {
      const optionsToAdd = [];
      const optionsToDelete = [];
      const optionsToChange = [];

      _.map(this.store.activeElement.elementSelect.elementSelectOptions, option => {
        if (option.added) {
          optionsToAdd.push(option);
        } else {
          if (option.changed) {
            optionsToChange.push(option);
          }
        }
      });
      _.map(this.props.element.elementSelect.elementSelectOptions, option => {
        const findOption = _.find(this.store.activeElement.elementSelect.elementSelectOptions, o => o.id === option.id);
        if (!findOption) {
          optionsToDelete.push(option);
        }
      });

      let totalLength = optionsToAdd.length + optionsToChange.length + optionsToDelete.length;
      if (totalLength === 0) {
        resolve();
      }

      _.map(optionsToAdd, option => {
        const elementSelectOptionObj = {
          element_select_id: option.elementSelectId,
          name: option.name,
          color: option.color,
          number_value: option.numberValue,
          row_order_position: option.rowOrder,
        };
        this.props.TemplateActions.createElementSelectOption(elementSelectOptionObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(optionsToChange, option => {
        const elementSelectOptionObj = {
          id: option.id,
          name: option.name,
          color: option.color,
          number_value: option.numberValue,
        };
        if (option.rowOrderChanged) {
          elementSelectOptionObj.row_order_position = option.rowOrder;
        }
        this.props.TemplateActions.changeElementSelectOption(elementSelectOptionObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(optionsToDelete, option => {
        this.props.TemplateActions.deleteElementSelectOption(option.id)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });
    });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleOptionTypeSelect(type) {
    this.store.updateActiveElement(type.name, 'optionType');
  }

  handleDisplayTypeSelect(type) {
    this.store.updateActiveElement(type.name, 'displayType');
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderOption(item, details) {
    const activeOptionType = _.find(SelectDefinitions.optionTypes, o => o.name === this.store.activeElement.elementSelect.optionType);
    const activeDisplayType = _.find(SelectDefinitions.displayTypes, o => o.name === this.store.activeElement.elementSelect.displayType);
    let menuClass = classNames('popover-larger');
    if (item.text === activeOptionType.text || item.text === activeDisplayType.text) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        icon={item.icon}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox />{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.text}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementSelect[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementSelect[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderInputValue(option) {
    return option.text;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      const activeOptionType = _.find(SelectDefinitions.optionTypes, o => o.name === this.store.activeElement.elementSelect.optionType);
      const activeDisplayType = _.find(SelectDefinitions.displayTypes, o => o.name === this.store.activeElement.elementSelect.displayType);
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-select push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.select_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementSelect.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementSelect.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={this.store.activeElement.elementSelect.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'placeholderText') && !this.store.activeElement.elementSelect.placeholderTextValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="placeholderText">
                    {I18n.t('js.placeholder_text')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'placeholderText') && !this.store.activeElement.elementSelect.placeholderTextValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_placeholder_text')}
                        name="placeholderText"
                        value={this.store.activeElement.elementSelect.placeholderText}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('placeholderText')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox className="bp3-form-group" flexDirection="column" flexGrow={1} flexBasis="1">
                <label className="bp3-label" htmlFor="optionType">
                  {I18n.t('js.select_element_option_type')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <Flexbox>
                    <OptionSelect
                      resetOnSelect={true}
                      filterable={false}
                      items={SelectDefinitions.optionTypes}
                      itemRenderer={this.renderOption.bind(this)}
                      onItemSelect={this.handleOptionTypeSelect.bind(this)}
                      inputValueRenderer={this.renderInputValue.bind(this)}
                      inputProps={{
                        rightElement: this.renderDropDown(),
                      }}
                      popoverProps={{
                        usePortal: false,
                        position: 'bottom-left',
                        className: '',
                        popoverClassName: 'bp3-minimal',
                      }}
                    >
                      <AnchorButton text={activeOptionType.text} icon={activeOptionType.icon} rightIcon="caret-down" />
                    </OptionSelect>
                  </Flexbox>
                </div>
              </Flexbox>
              <Flexbox className="bp3-form-group" flexDirection="column" flexGrow={1} flexBasis="1" paddingLeft="20px">
                <label className="bp3-label" htmlFor="optionType">
                  {I18n.t('js.select_element_display_type')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <Flexbox>
                    <DisplaySelect
                      resetOnSelect={true}
                      filterable={false}
                      items={SelectDefinitions.displayTypes}
                      itemRenderer={this.renderOption.bind(this)}
                      onItemSelect={this.handleDisplayTypeSelect.bind(this)}
                      inputValueRenderer={this.renderInputValue.bind(this)}
                      inputProps={{
                        rightElement: this.renderDropDown(),
                      }}
                      popoverProps={{
                        usePortal: false,
                        position: 'bottom-left',
                        className: '',
                        popoverClassName: 'bp3-minimal',
                      }}
                    >
                      <AnchorButton text={activeDisplayType.text} icon={activeDisplayType.icon} rightIcon="caret-down" />
                    </DisplaySelect>
                  </Flexbox>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <ElementSelectOptions element={this.props.element} sectionType={this.props.sectionType} />
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.response_required')}
                onChange={this.handleResponseRequiredChange.bind(this)}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementSelect.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
        >
          <ElementSelectDisplay element={this.store.activeElement} disabled={!this.props.element.stateElement.editable} />
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementSelect.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
      >
        <ElementSelectDisplay element={this.props.element} disabled={!this.props.element.stateElement.editable} />
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
