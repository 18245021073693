import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { AnchorButton, NonIdealState } from '@blueprintjs/core';
import WidgetStore from '../../stores/WidgetStore';
import moment from 'moment';
import nanoid from 'nanoid';

@observer
export default class ScheduledEvents extends Component {
  handleEditEvent(e, event) {
    e.preventDefault();
    WidgetStore.setActiveEvent(event);
  }

  handleAddEvent() {
    let now = new Date();
    now.setMinutes(0);

    const newEventObj = {
      id: nanoid(),
      event_type: 'fixed',
      element_id: '',
      target_date: now,
      end_date: null,
      reminder_period: 1,
      title: '',
      content: '',
      recurs: false,
      frequency: 'monthly',
      interval: 1,
      offset: 0,
    };
    WidgetStore.setActiveEvent(newEventObj);
  }

  renderEvents() {
    const { scheduled_events } = WidgetStore.activeWidget;
    if (!scheduled_events || scheduled_events.length === 0) {
      return (
        <Flexbox flexDirection="row" flexGrow={1} className="bp3-card" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.no_events')}
            description={I18n.t('js.there_are_no_events_to_show')}
            icon="calendar"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row" flexGrow={1} className="bp3-card bp3-card-no-padding" marginTop="20px">
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <tr>
              <th>{I18n.t('js.title')}</th>
              <th>{I18n.t('js.date')}</th>
              <th>{I18n.t('js.action')}</th>
            </tr>
          </thead>
          <tbody>
            {scheduled_events.map(event => {
              return (
                <tr key={event.id}>
                  <td>{_.truncate(event.title, { length: 30 })}</td>
                  <td>{moment(event.target_date).format('MMMM Do YYYY, h:mm a')}</td>
                  <td>
                    <AnchorButton text={I18n.t('js.edit')} icon="edit" onClick={e => this.handleEditEvent(e, event)}></AnchorButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row" marginTop="20px">
          <AnchorButton text={I18n.t('js.add_event')} icon="timeline-events" onClick={this.handleAddEvent.bind(this)}></AnchorButton>
        </Flexbox>
        {this.renderEvents()}
      </Flexbox>
    );
  }
}
