import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { MenuItem, Icon, Spinner, Button } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { observable, action, computed } from 'mobx';
import classNames from 'classnames';
import Avatar from 'react-avatar';
import _ from 'lodash';
import User from '../../models/UserModel';
import SuggestSelectedResult from './SuggestSelectedResult';

axiosCancel(axios);

const SearchSuggest = Suggest.ofType();
const pageTotal = 25;

@inject('CurrentUserStore', 'ToastStore', 'AppStore', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions')
@observer
export default class UserSuggest extends React.Component {
  constructor(props) {
    super(props);

    this.loadQuery = _.debounce(this.loadQuery, 500);
  }

  /*=======================================================================
  Setup
  =======================================================================*/

  @observable query = '';
  @observable isLoading = false;
  @observable users = [];
  @observable selectedUsers = [];
  @observable totalAvailableUsers = 0;
  @observable currentPage = 1;

  componentDidMount() {
    this.setInitialSelection();
    this.loadInitialUsers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.whitelistUsers != this.props.whitelistUsers) {
      this.users = nextProps.whitelistUsers;
      this.totalAvailableUsers = nextProps.whitelistUsers.length;
    }
  }

  @action
  setInitialSelection() {
    // Construct an array of selected users based on element form values
    if (this.props.element) {
      _.map(this.props.element.formValues, formValue => {
        this.selectedUsers.push(formValue.referenceUser);
      });
    }
    if (this.props.preSelectedUsers) {
      _.map(this.props.preSelectedUsers, user => {
        const newUser = new User(
          user.id,
          user.email,
          user.first_name,
          user.last_name,
          user.full_name,
          user.account_id,
          user.human_friendly_id,
          user.is_current_user,
          user.is_account_owner,
          user.image_file_name,
          user.background_image_file_name,
          user.last_sign_in_at,
          user.locale,
          user.theme,
          user.created_at,
          user.updated_at,
          user.deleted_at,
          user.created_by,
          user.updated_by,
          user.deleted_by
        );
        this.selectedUsers.push(newUser);
      });
    }
    this.props.validateInput(this.selectedUsers);
  }

  /*=======================================================================
  Computations
  =======================================================================*/

  @computed
  get lastUserId() {
    if (this.filteredUsers.length > 0) {
      return _.last(this.filteredUsers).id;
    }
    return '';
  }

  @computed
  get canGetMoreData() {
    return this.users.length < this.totalAvailableUsers;
  }

  @computed
  get inputDisabled() {
    if (this.props.disabled) {
      return true;
    }
    if (this.props.element) {
      return this.props.element.elementObjectSelect.optionType == 'single' && this.selectedUsers.length > 0;
    }
    if (this.props.optionType == 'single' && this.selectedUsers.length > 0) {
      return true;
    }
    return false;
  }

  @computed
  get filteredUsers() {
    const ids = _.map(this.selectedUsers, 'id');
    let users = _.filter(this.users, o => !ids.includes(o.id));
    return users;
  }

  /*=======================================================================
  Data fetching
  =======================================================================*/

  @action
  async loadInitialUsers() {
    if (this.props.whitelistUsers) {
      this.users = this.props.whitelistUsers;
      this.totalAvailableUsers = this.props.whitelistUsers.length;
    } else {
      this.isLoading = true;
      this.users = [];
      await this.fetchUsers(null, this.currentPage, pageTotal)
        .then(response => {
          this.users = _.unionBy(this.users, response.data, 'id');
          this.totalAvailableUsers = parseInt(response.headers['x-total']);
        })
        .catch(() => {
          this.isLoading = false;
        });
      this.isLoading = false;
    }
  }

  @action
  async loadMoreData() {
    // Load additional data for the list
    this.isLoading = true;
    this.currentPage = this.currentPage + 1;
    await this.fetchUsers(null, this.currentPage, pageTotal)
      .then(response => {
        this.users = _.unionBy(this.users, response.data, 'id');
      })
      .catch(() => {
        this.isLoading = false;
      });
    this.isLoading = false;
  }

  @action
  async loadAllData() {
    // Load all data for the list
    this.isLoading = true;
    this.currentPage = 0;
    const requestCount = Math.ceil(this.totalAvailableUsers / pageTotal);
    let i;
    for (i = 0; i <= requestCount; i++) {
      this.currentPage = this.currentPage + 1;
      await this.fetchUsers(null, this.currentPage, pageTotal)
        .then(response => {
          this.users = _.unionBy(this.users, response.data, 'id');
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
    this.isLoading = false;
  }

  @action
  async loadQuery(query) {
    this.query = query;
    if (query.length === 0) {
      this.loadInitialUsers();
    } else {
      if (this.props.whitelistUsers) {
        this.users = this.props.whitelistUsers;
        this.users = _.filter(this.users, o => o.full_name.toLowerCase().includes(query.toLowerCase()));
      } else {
        this.isLoading = true;
        await this.fetchUsers(query, this.currentPage, pageTotal)
          .then(response => {
            this.users = response.data;
          })
          .catch(() => {
            this.isLoading = false;
          });
        this.isLoading = false;
      }
    }
  }

  @action
  fetchUsers(query, page, per_page) {
    return new Promise((resolve, reject) => {
      let url = `${Cookies.get('apiEnv')}/users?page=${page}&per_page=${per_page}&offset=0`;
      if (query) {
        if (this.props.element) {
          url = `${Cookies.get('apiEnv')}/webform_users?&query=${query}&query_element_id=${this.props.element.id}`;
        } else {
          url = `${Cookies.get('apiEnv')}/users?&query=${query}`;
        }
      }
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          reject(error);
        });
    });
  }

  /*=======================================================================
  Handlers
  =======================================================================*/

  @action
  handleUserClick(user) {
    this.query = '';
    this.props.handleUserClick(user);
    const newUser = new User(
      user.id,
      user.email,
      user.first_name,
      user.last_name,
      user.full_name,
      user.account_id,
      user.human_friendly_id,
      user.is_current_user,
      user.is_account_owner,
      user.image_file_name,
      user.background_image_file_name,
      user.last_sign_in_at,
      user.locale,
      user.theme,
      user.created_at,
      user.updated_at,
      user.deleted_at,
      user.created_by,
      user.updated_by,
      user.deleted_by
    );
    this.selectedUsers = this.selectedUsers.concat([newUser]);
    this.props.validateInput(this.selectedUsers);
  }

  @action
  handleUserRemove(id) {
    this.selectedUsers = _.filter(this.selectedUsers, o => o.id != id);
    this.props.handleUserRemove(id);
    this.props.validateInput(this.selectedUsers);
  }

  /*=======================================================================
  Rendering
  =======================================================================*/

  renderInputValue(result) {
    return this.query;
  }

  renderResult(user, details) {
    let menuClass = classNames('');

    return (
      <React.Fragment key={user.id}>
        <MenuItem
          className={menuClass}
          onClick={details.handleClick}
          style={{ width: '800px' }}
          text={
            <Flexbox flexDirection="row" alignContent="center">
              <Avatar src={user.image_file_name} size={20} round={true} />
              <Flexbox marginLeft="10px">{user.full_name}</Flexbox>
            </Flexbox>
          }
        />
        {user.id === this.lastUserId && this.canGetMoreData && this.query.length === 0 && !this.props.whitelistUsers ? (
          <Flexbox flexDirection="row" justifyContent="center" padding="10px">
            <Button
              text={I18n.t('js.load_more')}
              icon="more"
              minimal={true}
              disabled={this.isLoading}
              onClick={this.loadMoreData.bind(this)}
            />
            <Button
              text={I18n.t('js.load_all_options', { count: this.totalAvailableUsers })}
              icon="cloud-download"
              minimal={true}
              disabled={this.isLoading}
              onClick={this.loadAllData.bind(this)}
            />
          </Flexbox>
        ) : null}
      </React.Fragment>
    );
  }

  renderNoResults() {
    return <MenuItem disabled icon="warning-sign" text={I18n.t('js.no_results_found')} />;
  }

  renderSelectedResults() {
    return _.map(this.selectedUsers, user => {
      return (
        <SuggestSelectedResult
          key={user.id}
          text={user.fullName}
          image={user.imageFileName}
          onClick={e => (this.props.openDrawer != undefined ? this.props.openDrawer(user) : null)}
          onRemove={e => this.handleUserRemove(user.id)}
        />
      );
    });
  }

  render() {
    const { placeholder } = this.props;
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <SearchSuggest
          items={this.filteredUsers}
          itemRenderer={this.renderResult.bind(this)}
          onItemSelect={result => this.handleUserClick(result)}
          inputValueRenderer={this.renderInputValue.bind(this)}
          noResults={this.renderNoResults()}
          disabled={this.inputDisabled}
          query={this.query}
          fill={true}
          onQueryChange={this.loadQuery.bind(this)}
          inputProps={{
            value: this.query,
            disabled: this.inputDisabled,
            intent: this.props.intent,
            placeholder: placeholder,
            leftElement:
              this.isLoading && !this.inputDisabled ? (
                <Flexbox padding="5px">
                  <Spinner size={18} />
                </Flexbox>
              ) : null,
          }}
          popoverProps={{
            usePortal: false,
            position: 'bottom-left',
            className: 'fill',
            popoverClassName: `bp3-minimal ${this.props.expandMenu ? 'suggest suggest-wide' : 'suggest'}`,
          }}
        />
        {this.selectedUsers.length > 0 ? (
          <Flexbox flexDirection="column" marginTop="10px">
            {this.renderSelectedResults()}
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }
}
