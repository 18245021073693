import React from 'react';
import UserAvatar from '../shared/UserAvatar';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Alert, Button, Classes, Intent, Switch } from '@blueprintjs/core';

@inject('CurrentUserStore', 'TeamsStore', 'TeamUsersStore', 'ToastStore')
@observer
export default class TeamUsersListUserRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isRemoving: false,
      isMakingOwner: false,
      isRemovingOwner: false,
    };
  }

  toggleRemoving() {
    this.setState({
      isRemoving: !this.state.isRemoving,
    });
  }

  toggleMakingOwner() {
    this.setState({
      isMakingOwner: !this.state.isMakingOwner,
    });
  }

  toggleRemovingOwner() {
    this.setState({
      isRemovingOwner: !this.state.isRemovingOwner,
    });
  }

  handleTogglePrimary() {
    const userTeamObj = {
      id: this.props.userTeam.id,
      is_primary: !this.props.userTeam.isPrimary,
    };
    this.props.TeamUsersStore.changeUserTeam(userTeamObj)
      .then(() => {
        this.props.userTeam.isPrimary = !this.props.userTeam.isPrimary;
        this.props.ToastStore.showToast(I18n.t('js.user_team_has_been_updated'), 'success');
      })
      .catch(() => {});
  }

  handleMakeOwner() {
    const userTeamObj = {
      id: this.props.userTeam.id,
      is_owner: true,
    };
    this.props.TeamUsersStore.changeUserTeam(userTeamObj)
      .then(() => {
        this.props.userTeam.isOwner = true;
        this.toggleMakingOwner();
        this.props.ToastStore.showToast(
          I18n.t('js.user_has_been_made_team_owner', {
            name: this.props.userTeam.user.fullName,
          }),
          'success'
        );
      })
      .catch(() => {
        this.toggleMakingOwner();
      });
  }

  handleRemoveOwner() {
    const userTeamObj = {
      id: this.props.userTeam.id,
      is_owner: false,
    };
    this.props.TeamUsersStore.changeUserTeam(userTeamObj)
      .then(() => {
        this.props.userTeam.isOwner = false;
        this.toggleRemovingOwner();
        this.props.ToastStore.showToast(
          I18n.t('js.user_no_longer_has_team_ownership', {
            name: this.props.userTeam.user.fullName,
          }),
          'success'
        );
      })
      .catch(() => {
        this.toggleRemovingOwner();
      });
  }

  removeUser() {
    this.props.TeamUsersStore.deleteUserTeam(this.props.userTeam.id)
      .then(response => {
        this.props.TeamsStore.addTeam(response.data.team, 'single');
        this.toggleRemoving();
      })
      .catch(() => {
        this.toggleRemoving();
      });
  }

  renderRemoveAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isRemoving}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleRemoving()}
        confirmButtonText={I18n.t('js.remove_user')}
        onConfirm={this.removeUser.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_remove_this_user_team', {
          name: this.props.userTeam.user.fullName,
        })}
      </Alert>
    );
  }

  renderMakeOwner() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isMakingOwner}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleMakingOwner()}
        confirmButtonText={I18n.t('js.make_user_team_owner')}
        onConfirm={this.handleMakeOwner.bind(this)}
        intent={Intent.PRIMARY}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_make_this_user_a_team_owner', { name: this.props.userTeam.user.fullName })}
      </Alert>
    );
  }

  renderRemoveOwner() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isRemovingOwner}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleRemovingOwner()}
        confirmButtonText={I18n.t('js.remove_team_ownership')}
        onConfirm={this.handleRemoveOwner.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_remove_this_team_owner', { name: this.props.userTeam.user.fullName })}
      </Alert>
    );
  }

  renderOwnerSwitch() {
    if (this.props.TeamsStore.activeTeam.isCurrentUserTeamOwner) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" justifyContent="flex-start" marginTop="2px">
          <Switch
            checked={this.props.userTeam.isOwner}
            className="bp3-control-no-margin"
            onChange={this.props.userTeam.isOwner ? this.toggleRemovingOwner.bind(this) : this.toggleMakingOwner.bind(this)}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="row" justifyContent="flex-start">
        {this.props.userTeam.isOwner ? (
          <span className="bp3-tag bp3-intent-primary bp3-minimal">{I18n.t('js.team_owner')}</span>
        ) : (
          <span className="bp3-tag bp3-minimal">{I18n.t('js.user')}</span>
        )}
      </Flexbox>
    );
  }

  renderPrimarySwitch() {
    if (this.props.TeamsStore.activeTeam.isCurrentUserTeamOwner) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" justifyContent="flex-start" marginTop="2px">
          <Switch
            checked={this.props.userTeam.isPrimary}
            className="bp3-control-no-margin"
            onChange={this.handleTogglePrimary.bind(this)}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="row" justifyContent="flex-start">
        {this.props.userTeam.isPrimary ? (
          <span className="bp3-tag bp3-intent-success bp3-minimal">{I18n.t('js.primary_team')}</span>
        ) : (
          <span className="bp3-tag bp3-minimal">{I18n.t('js.non_primary_team')}</span>
        )}
      </Flexbox>
    );
  }

  renderActions() {
    if (this.props.TeamsStore.activeTeam.isCurrentUserTeamOwner) {
      return (
        <td>
          <Button className="bp3-small" icon="trash" text={I18n.t('js.remove_user')} onClick={this.toggleRemoving.bind(this)} />
          {this.renderRemoveAlert()}
          {this.renderMakeOwner()}
          {this.renderRemoveOwner()}
        </td>
      );
    }
    return (
      <td>
        <span className="bp3-text-muted">{I18n.t('js.not_applicable')}</span>
      </td>
    );
  }

  render() {
    return (
      <tr>
        <td>
          <UserAvatar user={this.props.userTeam.user} avatarSize={20} path={`/users/${this.props.userTeam.user.humanFriendlyId}`} />
        </td>
        <td>{this.props.userTeam.createdAtToDate}</td>
        {/* <td>{this.renderPrimarySwitch()}</td> */}
        <td>{this.renderOwnerSwitch()}</td>
        {this.renderActions()}
      </tr>
    );
  }
}
