/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import ToastStore from '../stores/ToastStore';
import LayoutHeader from '../components/layout/LayoutHeader';
import CurrentUserStore from '../stores/CurrentUserStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import AppStore from '../stores/AppStore';
import AppItemStore from '../stores/AppItemStore';
import ActivityStore from '../stores/ActivityStore';
import DynamicViewStore from '../stores/DynamicViewStore';
import TemplateActions from '../actions/TemplateActions';
import UserProfileStore from '../stores/UserProfileStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import ItemStore from '../stores/ItemStore';
import TeamUsersStore from '../stores/TeamUsersStore';
import AdminStore from '../stores/AdminStore';
import AdminActions from '../actions/AdminActions';
import WidgetStore from '../stores/WidgetStore';
import DashboardStore from '../stores/DashboardStore';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import DashboardTabs from '../components/dashboard/DashboardTabs';
import DashboardTeams from '../components/dashboard/DashboardTeams';
import DashboardRelationships from '../components/dashboard/DashboardRelationships';
import DashboardTaskList from '../components/dashboard/DashboardTaskList';
import DashboardPins from '../components/dashboard/DashboardPins';
import DashboardActivity from '../components/dashboard/DashboardActivity';
import DashboardNewUserWelcome from '../components/dashboard/DashboardNewUserWelcome';
import DashboardNews from '../components/dashboard/DashboardNews';
import DashboardYou from '../components/dashboard/DashboardYou';
import UserTimeline from '../components/timeline/UserTimeline';
import ItemActions from '../actions/ItemActions';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class DashboardView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    DashboardStore.resetStartingAttributes();
    DashboardStore.newItemUser = CurrentUserStore.currentUser;
    const appNum = 1,
      perPage = 25,
      offset = 1;
    ItemActions.fetchUserItems(DashboardStore.showDone, appNum, perPage, offset)
      .then(response => {
        WidgetStore.fetchWidgets(CurrentUserStore.activeAccount.accountId, 'Account');
        DashboardStore.createPaginator(response.headers);
        _.map(response.data, item => {
          DashboardStore.addItem(item);
        });
        ItemActions.fetchItemSectionInvites().then(response => {
          _.map(response.data, itemSectionInvite => {
            DashboardStore.addItemSectionInvite(itemSectionInvite);
          });
          if (CurrentUserStore.currentUser.id) {
            ItemActions.fetchPinnedItemsForUser(CurrentUserStore.currentUser.id).then(response => {
              _.map(response.data, itemPin => {
                DashboardStore.addItemPin(itemPin);
              });
              this.toggleLoading();
            });
          } else {
            this.toggleLoading();
          }       
        }); 
      })
      .catch(() => {
        WidgetStore.fetchWidgets(CurrentUserStore.activeAccount.accountId, 'Account');
        this.toggleLoading();
      });
  }

  renderCount() {
    if (DashboardStore.pagination.xTotal > 0) {
      return <span>({DashboardStore.pagination.xTotal})</span>;
    }
    return undefined;
  }

  renderTabContent() {
    switch (DashboardStore.activeTab) {
      case 'inbox':
        return (
          <Flexbox flexDirection="column">
            <DashboardTaskList />
            <DashboardPins />
          </Flexbox>
        );
      case 'you':
        return (
          <Flexbox flexDirection="column">
            <DashboardYou />
          </Flexbox>
        );
      case 'your_colleagues':
        return (
          <Flexbox flexDirection="column">
            <DashboardRelationships />
          </Flexbox>
        );
      case 'your_teams':
        return (
          <Flexbox flexDirection="column">
            <DashboardTeams />
          </Flexbox>
        );
      case 'your_timeline':
        return (
          <Flexbox flexDirection="column">
            <UserTimeline userId={CurrentUserStore.currentUser.id} isEditable={true} showTitles={false} />
          </Flexbox>
        );
      case 'company_news':
        return (
          <Flexbox flexDirection="column">
            <DashboardNews />
          </Flexbox>
        );
      case 'activity':
        return (
          <Flexbox flexDirection="column">
            <DashboardActivity />
          </Flexbox>
        );
    }
  }

  renderContent() {
    if (CurrentUserStore.isLoading || this.isLoading) {
      return <Flexbox flexDirection="column" width="100%"></Flexbox>;
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <DashboardHeader />
        <DashboardTabs />
        <Flexbox flexDirection="column" padding="20px" marginLeft="10px">
          {this.renderTabContent()}
        </Flexbox>
        <DashboardNewUserWelcome showWelcome={this.props.show_welcome} />
      </Flexbox>
    );
  }

  render() {
    const stores = {
      DynamicViewStore,
      CurrentUserStore,
      ToastStore,
      WorkspaceStore,
      AppStore,
      AppItemStore,
      DashboardStore,
      ItemActions,
      UserProfileStore,
      UserProfileTemplateStore,
      TemplateActions,
      ItemStore,
      WidgetStore,
      ActivityStore,
      AdminStore,
      AdminActions,
      TeamUsersStore,
    };

    return (
      <ErrorBoundary>
        <Provider {...stores}>
          <ThemeWrapper>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ThemeWrapper>
        </Provider>
      </ErrorBoundary>
    );
  }
}
