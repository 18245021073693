import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';

export default styled.div`
  display: flex;
  align-items: baseline;

  .${Classes.TAG} {
    margin-left: 10px;
  }
`;
