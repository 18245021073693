import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormGroup, Intent, TextArea } from '@blueprintjs/core';
import FormValueLengthValidator from '../shared/FormValueLengthValidator';
import FormElementRepeatableInput from './FormElementRepeatableInput';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('ItemStore', 'ItemActions', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions')
@observer
export default class FormElementTextarea extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  inputValue = '';
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  constructor(props) {
    super(props);

    this.performValidation = _.debounce(this.performValidation, 1000);
  }

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      this.inputValue = _.clone(this.props.element.formValues[0].stringValue);
    }
    this.mountDisplayValuesInFlight();
    this.props.validateInput(this.inputValue);
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_id === this.props.element.id) {
        this.inputValue = formValue.string_value;
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        this.inputValue = findFormValueToChange.string_value;
      }
    });
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'string';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'string';
        return object;
    }
  }

  handleInputChange(e) {
    this.inputValue = e.target.value;
    if (this.actionType === 'updating') {
      const changedFormValue = {
        id: this.props.element.formValues[0].id,
        string_value: this.inputValue,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      let newFormValue = {
        id: this.newId,
        element_id: this.props.element.id,
        string_value: this.inputValue,
      };
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
    }
    this.performValidation(this.inputValue);
  }

  performValidation(input) {
    this.props.validateInput(input);
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderLengthValidator() {
    if (!this.store.submissionAttempted) {
      return <FormValueLengthValidator input={this.inputValue} element={this.props.element} />;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          label={this.props.element.elementTextarea.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          <TextArea
            id={this.props.element.id}
            rows={this.props.element.elementTextarea.rows}
            className="bp3-fill"
            placeholder={this.props.element.elementTextarea.placeholderText}
            disabled={!this.props.element.isEditable}
            value={this.inputValue}
            onChange={this.handleInputChange.bind(this)}
            intent={this.props.showError ? Intent.DANGER : null}
          />
          {this.renderLengthValidator()}
          {this.props.element.repeatable && this.props.element.isEditable ? <FormElementRepeatableInput {...this.props} /> : null}
        </FormGroup>
      </Flexbox>
    );
  }
}
