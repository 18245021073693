import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { FormGroup, InputGroup, Intent, ButtonGroup, Button, Icon, Tag } from '@blueprintjs/core';
import FormulaStore from '../../stores/FormulaStore';
import FormulaDefinitions from '../../static/FormulaDefinitions';
import Flexbox from 'flexbox-react';
import _ from 'lodash';

@observer
export default class FormulaBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeElement: null,
      selectionStart: null,
      selectionEnd: null,
    };
  }

  componentDidMount() {
    FormulaStore.resetFormula();
    FormulaStore.setFormula(this.props.initialFormula);
    FormulaStore.setAvailableElements(this.props.elements);
  }

  handleFormulaChange(e) {
    const newFormula = e.target.value;
    FormulaStore.setFormula(newFormula);
    this.props.handleFormulaChange(newFormula);
  }

  recordCursorPosition(e) {
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    this.setState({
      selectionStart,
      selectionStart,
    });
  }

  handleContentChange(element) {
    this.setState({ activeElement: element });
  }

  handleElementClick(element) {
    this.setState({ activeElement: element });
    const newFormula =
      FormulaStore.formula.substring(0, this.state.selectionStart) +
      element.implementation +
      FormulaStore.formula.substring(this.state.selectionStart);
    FormulaStore.setFormula(newFormula);
    this.props.handleFormulaChange(newFormula);
    this.formulaInput.focus();
  }

  render() {
    return (
      <FormGroup
        helperText={
          FormulaStore.formulaState === 'valid'
            ? FormulaStore.shouldDisplayHelper
              ? `${I18n.t('js.result')}: ${FormulaStore.displayFormula}`
              : null
            : `${I18n.t('js.error')}: ${FormulaStore.error}`
        }
        label={I18n.t('js.formula')}
        labelFor="formulaInput"
        labelInfo={I18n.t('js.required')}
        intent={FormulaStore.formulaState === 'valid' ? Intent.DEFAULT : Intent.DANGER}
      >
        <Flexbox flexDirection="column" className="bp3-card bp3-card-no-padding" paddingTop="10px">
          <Flexbox flexDirection="column">
            <InputGroup
              autoComplete="off"
              large={true}
              intent={FormulaStore.formulaState === 'valid' ? Intent.DEFAULT : Intent.DANGER}
              className="max-width input-no-border"
              value={FormulaStore.formula}
              name="formulaInput"
              inputRef={c => {
                this.formulaInput = c;
              }}
              placeholder={I18n.t('js.type_a_formula')}
              onBlur={this.recordCursorPosition.bind(this)}
              onChange={this.handleFormulaChange.bind(this)}
              leftIcon="variable"
            />
          </Flexbox>
          <Flexbox marginTop="5px">
            <Flexbox
              flexDirection="column"
              flexGrow={0}
              flexShrink={0}
              padding="10px"
              style={{ borderRight: '1px solid #EBF1F5', overflowY: 'scroll' }}
              maxHeight="300px"
            >
              <Flexbox marginBottom="5px" marginTop="10px">
                <small>{I18n.t('js.functions')}</small>
              </Flexbox>
              <ButtonGroup minimal={true} vertical={true} alignText="left">
                {FormulaDefinitions.functions.map(element => {
                  return (
                    <Button
                      key={element.id}
                      icon={element.icon}
                      onMouseEnter={() => this.handleContentChange(element)}
                      onClick={() => this.handleElementClick(element)}
                    >
                      {_.truncate(element.name, { length: 20 })}
                    </Button>
                  );
                })}
              </ButtonGroup>
              <Flexbox marginBottom="5px" marginTop="10px">
                <small>{I18n.t('js.operators')}</small>
              </Flexbox>
              <ButtonGroup minimal={true} vertical={true} alignText="left">
                {FormulaDefinitions.operators.map(element => {
                  return (
                    <Button
                      key={element.id}
                      icon={element.icon}
                      onMouseEnter={() => this.handleContentChange(element)}
                      onClick={() => this.handleElementClick(element)}
                    >
                      {_.truncate(element.name, { length: 20 })}
                    </Button>
                  );
                })}
              </ButtonGroup>
              <Flexbox marginBottom="5px">
                <small>{I18n.t('js.elements')}</small>
              </Flexbox>
              <ButtonGroup minimal={true} vertical={true} alignText="left">
                {this.props.elements &&
                  this.props.elements.map(element => {
                    return (
                      <Button
                        key={element.id}
                        icon={element.icon}
                        onMouseEnter={() => this.handleContentChange(element)}
                        onClick={() => this.handleElementClick(element)}
                      >
                        {_.truncate(element.name, { length: 20 })}
                      </Button>
                    );
                  })}
              </ButtonGroup>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={1} marginLeft="20px" styl={{ borderLeft: '1px solid light-grey' }}>
              {this.state.activeElement ? (
                <Flexbox flexDirection="column" flexGrow={0}>
                  <h4>
                    <Icon icon={this.state.activeElement.icon} className="push-10-r bp3-text-muted" />
                    {this.state.activeElement.name}
                  </h4>
                  <Flexbox>
                    <Tag intent={Intent.DANGER} minimal={true}>
                      {this.state.activeElement.type}
                    </Tag>
                  </Flexbox>
                </Flexbox>
              ) : null}
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </FormGroup>
    );
  }
}
