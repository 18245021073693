import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';

export default styled.div`
  padding: 0 50px;
  padding-top: ${props => props.paddingTop && props.theme.gridSize * 2}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${props => props.theme.darkGray5};
  color: ${props => props.theme.white};
  min-height: 172px;

  h1,
  h2,
  p {
    margin-left: 0;
    color: ${props => props.theme.white};
  }

  .${Classes.ICON} {
    opacity: 0.7;
  }

  .txt-area:nth-child(2) {
    margin-left: 32px;
  }

  .txt-area__top-row {
    display: flex;
  }
`;
