import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import ToastStore from '../stores/ToastStore';
import AppStore from '../stores/AppStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import { Button, Card, Elevation } from '@blueprintjs/core';
import Utilities from '../utils/Utilities';
import Cookies from 'js-cookie';

@observer
export default class VersionSwitchView extends React.Component {
  render() {
    const stores = {
      AppStore,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <Flexbox
          flexGrow={1}
          width="100hw"
          height="80vh"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Flexbox className="fixed-width-container-original" flexDirection="column" marginTop="20px">
            <Flexbox flexGrow={1} flexDirection="column" alignContent="center" alignItems="center" className="animated fadeIn">
              {Cookies.get('theme') == 'dark' ? (
                <img alt="logo" src={I18n.t('js.pg_logo_white')} className="auth-view-logo" />
              ) : (
                <img alt="logo" src={I18n.t('js.pg_logo_black')} className="auth-view-logo" />
              )}
              <p>{I18n.t('js.select_application_version')}</p>
              <Flexbox flexDirection="row" flexGrow={0} marginTop="20px">
                <Card interactive={true} onClick={() => Utilities.navigate('/version-switch/v1')} className="push-10-l push-10-r">
                  <Flexbox flexDirection="column" justifyContent="center" alignItems="center" padding="20px">
                    <h2>{I18n.t('js.version_1')}</h2>
                  </Flexbox>
                </Card>
                <Card interactive={true} onClick={() => Utilities.navigate('/dashboard')} className="push-10-l push-10-r">
                  <Flexbox flexDirection="column" justifyContent="center" alignItems="center" padding="20px">
                    <h2>{I18n.t('js.version_2')}</h2>
                  </Flexbox>
                </Card>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Provider>
    );
  }
}
