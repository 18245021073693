import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import {
  Button,
  Intent,
  FormGroup,
  InputGroup,
  Switch,
  Tag,
} from '@blueprintjs/core';

const BambooIntegrationForm = props => {
  const apiKey = 'apiKey';
  const subdomain = 'subdomain';
  const {
    apiKeyVal,
    subdomainVal,
    includeProfilePhotos,
    includeDepartments,
    includeManagers,
    handleBlurChange,
    handleInputChange,
    errors,
    renderErrors,
    isDisabled,
    hasErrors,
  } = props;

  const renderTag = (
    <Tag large="true" minimal="true" className="push-0">
      .bamboohr.com
    </Tag>
  );

  return (
    <Flexbox flexDirection="column" flexGrow="1">
      <Flexbox flexDirection="row" justifyContent="space-between">
        <Flexbox flexGrow="4" flexDirection="column">
          <FormGroup
            helperText={hasErrors ? renderErrors(`${apiKey}`) : ''}
            intent={apiKey in errors ? 'DANGER' : 'NONE'}
            label={I18n.t(`js.${apiKey}`)}
            labelFor="api-key-input"
            labelInfo={`(${I18n.t('js.required')})`}
          >
            <InputGroup
              disabled={isDisabled}
              id="api-key-input"
              placeholder={I18n.t('js.enter_api_key')}
              intent={apiKey in errors ? 'DANGER' : 'NONE'}
              type="text"
              name={apiKey}
              dir="auto"
              value={apiKeyVal}
              onBlur={handleBlurChange}
              onChange={handleInputChange}
            />
          </FormGroup>

          <FormGroup
            helperText={hasErrors ? renderErrors(`${subdomain}`) : ''}
            intent={subdomain in errors ? 'DANGER' : 'NONE'}
            label={I18n.t(`js.${subdomain}`)}
            labelFor="subdomain-input"
            labelInfo={`(${I18n.t('js.required')})`}
          >
            <InputGroup
              disabled={isDisabled}
              id="subdomain-input"
              placeholder={I18n.t('js.enter_subdomain')}
              intent={subdomain in errors ? 'DANGER' : 'NONE'}
              type="text"
              name={subdomain}
              rightElement={renderTag}
              dir="auto"
              value={subdomainVal}
              onBlur={handleBlurChange}
              onChange={handleInputChange}
            />
          </FormGroup>
          <Flexbox flexDirection="column" alignSelf="flex-start">
            <Checkbox
              disabled={isDisabled}
              label={I18n.t('js.include_profile_photos')}
              name="includeProfilePhotos"
              checked={includeProfilePhotos}
              onChange={handleInputChange}
            />
            <Checkbox
              disabled={isDisabled}
              label={I18n.t('js.include_departments')}
              name="includeDepartments"
              checked={includeDepartments}
              onChange={handleInputChange}
            />
            <Checkbox
              disabled={isDisabled}
              label={I18n.t('js.include_managers')}
              name="includeManagers"
              checked={includeManagers}
              onChange={handleInputChange}
            />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};
export default observer(BambooIntegrationForm);
