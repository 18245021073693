import React from 'react';
import Flexbox from 'flexbox-react';
import { action, observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner, Classes, Card, Elevation } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import Paginator from '../shared/Paginator';
import ImportRow from './ImportRow';
import _ from 'lodash';

@inject('CurrentUserStore', 'ImportStore', 'ImportActions', 'ToastStore')
@observer
export default class ImportList extends React.Component {
  handleColumnSort(column) {
    this.props.ImportStore.setImportSortParams(column.id, column.sortDir);
  }

  handlePageChange(pageNumber) {
    this.props.ImportStore.toggleLoadingImports();
    this.props.ImportStore.importData = [];
    this.props.ImportActions.fetchImports(
      this.props.ImportStore.importTemplate.id,
      'full',
      pageNumber,
      this.props.ImportStore.pagination.xPerPage,
      this.props.ImportStore.pagination.xOffset,
      ''
    )
      .then(response => {
        this.props.ImportStore.createPaginator(response.headers);
        _.map(response.data, importData => {
          this.props.ImportStore.addImport(importData);
        });
        this.props.ImportStore.toggleLoadingImports();
      })
      .catch(() => {
        this.props.ImportStore.toggleLoadingImports();
      });
  }

  handlePerPageChange(e) {
    this.props.ImportStore.toggleLoadingImports();
    const pageNumber = 1;
    this.props.ImportActions.fetchImports(
      this.props.ImportStore.importTemplate.id,
      'full',
      pageNumber,
      e.target.value,
      this.props.ImportStore.pagination.xOffset,
      ''
    )
      .then(response => {
        this.props.ImportStore.createPaginator(response.headers);
        _.map(response.data, importDatum => {
          this.props.ImportStore.addImport(importDatum);
        });
        this.props.ImportStore.toggleLoadingImports();
      })
      .catch(() => {
        this.props.ImportStore.toggleLoadingImports();
      });
  }

  renderPagination() {
    if (!this.props.ImportStore.isLoadingImports && this.props.ImportStore.pagination.xTotal > this.props.ImportStore.pagination.xPerPage) {
      return (
        <Paginator
          paginationObject={this.props.ImportStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderImportTable() {
    if (this.props.ImportStore.importData.length === 0) {
      return <Flexbox flexDirection="column">{I18n.t('js.no_data_has_been_imported')}</Flexbox>;
    } else {
      return (
        <Flexbox flexDirection="column">
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <SimpleTableHeader columns={this.props.ImportStore.importColumns} handleColumnSort={this.handleColumnSort.bind(this)} />
            </thead>
            <tbody>
              {_.map(this.props.ImportStore.importData, importDatum => {
                return <ImportRow import={importDatum} key={importDatum.id} />;
              })}
            </tbody>
          </table>
          {this.renderPagination()}
        </Flexbox>
      );
    }
  }

  renderImports() {
    if (this.props.ImportStore.isLoadingImports) {
      return (
        <Flexbox flexDirection="column">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column">
          <h4 className={Classes.HEADING}>{I18n.t('js.data_imports')}</h4>
          {this.renderImportTable()}
        </Flexbox>
      );
    }
  }

  render() {
    return (
      <Card interactive={false} elevation={Elevation.TWO} className="max-width push-10-t">
        {this.renderImports()}
      </Card>
    );
  }
}
