import React from 'react';
import Flexbox from 'flexbox-react';
import TemplateNewState from './TemplateNewState';
import TemplateEditState from './TemplateEditState';
import TemplateReorderStates from './TemplateReorderStates';
import { inject, observer } from 'mobx-react';
import { Alert, Intent } from '@blueprintjs/core';
import StateRulesView from '../../views/StateRulesView';
import GuideButton from '../guides/GuideButton';
import { Classes } from '../../../../../node_modules/@blueprintjs/datetime';

@inject('TemplateStore', 'TemplateActions', 'ToastStore')
@observer
export default class TemplateHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDeleting: false };
  }

  toggleDialog() {
    this.props.TemplateStore.newStateDialogOpen = !this.props.TemplateStore.newStateDialogOpen;
  }

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting,
    });
  }

  handleDeleteState() {
    this.props.TemplateActions.deleteState(this.props.TemplateStore.activeState.id)
      .then(() => {
        this.props.TemplateStore.editStateOptions = false;
        this.props.ToastStore.showToast(I18n.t('js.state_deleted'), 'success');
        this.toggleDeleting();
      })
      .catch(() => {
        this.toggleDeleting();
      });
  }

  handleNext() {
    switch (this.props.TemplateStore.activeSubsection) {
      case 'template':
        return (this.props.TemplateStore.activeSubsection = 'state');
      case 'state':
        return (this.props.TemplateStore.activeSubsection = 'rules');
      case 'rules':
        return (this.props.TemplateStore.activeSubsection = 'template');
      default:
        throw new Error(`Did not account for: ${this.props.TemplateStore.activeSubsection} in switch statement`);
    }
  }

  handlePrevious() {
    switch (this.props.templatestore.activesubsection) {
      case 'template':
        return (this.props.TemplateStore.activeSubsection = 'rules');
      case 'state':
        return (this.props.TemplateStore.activeSubsection = 'template');
      case 'rules':
        return (this.props.TemplateStore.activeSubsection = 'state');
      default:
        throw new Error(`Did not account for: ${this.props.TemplateStore.activeSubsection} in switch statement`);
    }
  }

  renderEdit() {
    if (this.props.TemplateStore.activeSubsection === 'state') {
      return (
        <Flexbox flexDirection="column" className="template-container" flexGrow={1}>
          <Flexbox flexGrow={1} justifyContent="center" marginTop="30px" marginBottom="20px">
            <GuideButton action="configure_states" />
          </Flexbox>
          <TemplateEditState toggleDeleting={this.toggleDeleting.bind(this)} />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderRules() {
    if (this.props.TemplateStore.activeSubsection === 'rules') {
      return (
        <Flexbox flexDirection="column" className="template-container" flexGrow={1}>
          <Flexbox flexGrow={1} justifyContent="center" marginTop="30px" marginBottom="20px">
            <GuideButton action="set_rules" />
          </Flexbox>
          <StateRulesView />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderDeleteState() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={this.toggleDeleting.bind(this)}
        confirmButtonText={I18n.t('js.delete_state')}
        onConfirm={this.handleDeleteState.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_state')}
      </Alert>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="10px">
        <Flexbox
          flexDirection="column"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          marginBottom="20px"
          className="fixed-width-container"
        >
          <TemplateReorderStates />
          <TemplateNewState toggleDialog={this.toggleDialog.bind(this)} isOpen={this.props.TemplateStore.newStateDialogOpen} />
        </Flexbox>
        {this.renderEdit()}
        {this.renderRules()}
        {this.renderDeleteState()}
      </Flexbox>
    );
  }
}
