import React from 'react';
import { observer, Provider } from 'mobx-react';
import OrganizationStore from '../stores/OrganizationStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import TeamsStore from '../stores/TeamsStore';
import ItemStore from '../stores/ItemStore';
import AppStore from '../stores/AppStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import TemplateStore from '../stores/TemplateStore';
import ToastStore from '../stores/ToastStore';
import UserProfileStore from '../stores/UserProfileStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import UserProfileRelationshipsStore from '../stores/UserProfileRelationshipsStore';
import DynamicViewStore from '../stores/DynamicViewStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import DashboardStore from '../stores/DashboardStore';
import ItemActions from '../actions/ItemActions';
import TemplateActions from '../actions/TemplateActions';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import UserProfile from '../components/user_profile/UserProfile';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class UserProfileView extends React.Component {
  componentDidMount() {
    UserProfileStore.fetchUser(this.props.userId);
    if (this.props.tab) {
      UserProfileStore.activeTab = this.props.tab;
    }
  }

  render() {
    const stores = {
      OrganizationStore,
      CurrentUserStore,
      TeamsStore,
      ItemActions,
      ItemStore,
      AppStore,
      StoreTemplateActions,
      StoreTemplateStore,
      TemplateActions,
      TemplateStore,
      ToastStore,
      UserProfileStore,
      UserProfileTemplateStore,
      UserProfileRelationshipsStore,
      DynamicViewStore,
      WorkspaceStore,
      DashboardStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <UserProfile userId={this.props.userId} humanFriendlyId={this.props.humanFriendlyId} />
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
