/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import ToastStore from '../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Relationship from '../../models/RelationshipModel';
import { Button, Classes, MenuItem, PopoverInteractionKind, Spinner } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import nanoid from 'nanoid';
import _ from 'lodash';

const RelationshipSelect = Select.ofType(Relationship);

@inject('PermissionStore', 'ToastStore')
@observer
export default class PermissionAddRelationshipPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      relationships: [],
      filteredRelationships: [],
      query: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchRelationships();
  }

  fetchRelationships() {
    this.toggleLoading();
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/relationships`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, relationship => {
            this.addRelationship(relationship);
            total--;
          });
          if (total === 0) {
            resolve(response);
            this.toggleLoading();
          }
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
          this.toggleLoading();
        });
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addRelationship(data) {
    const newRelationship = new Relationship(
      data.id,
      data.account_id,
      data.name,
      data.counterpart_name,
      data.description,
      data.default,
      data.lock_user_changes,
      data.lock_counterpart_user_changes,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );

    const newRelationships = _.clone(this.state.relationships);
    newRelationships.push(newRelationship);
    this.setState({
      relationships: newRelationships,
      filteredRelationships: newRelationships,
    });
    this.sortSearchRelationships();
  }

  sortSearchRelationships() {
    let newRelationships = this.state.relationships;
    newRelationships = _.orderBy(newRelationships, ['fullName'], ['asc']);
    this.setState({ relationships: newRelationships, filterRelationships: newRelationships });
  }

  renderInputValue(relationship) {
    return relationship.name;
  }

  renderRelationship(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {item.name}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length > 1 && !this.state.isLoading) {
      return <MenuItem className="popover-larger" disabled text={I18n.t('js.no_relationships_found')} />;
    }
    return undefined;
  }

  handleRelationshipSelect(relationship) {
    this.props.handleRelationshipSelectChange(relationship);
    this.handleCancel();
  }

  handleCancel() {}

  filterRelationships(query) {
    if (query.length > 0) {
      const newRelationships = [];
      _.map(this.state.relationships, relationship => {
        if (relationship.name.toLowerCase().includes(query.toLowerCase())) {
          newRelationships.push(relationship);
        }
      });
      this.setState({ query: query, filteredRelationships: newRelationships });
    } else {
      this.setState({ query: '', filteredRelationships: _.clone(this.state.relationships) });
    }
  }

  renderCancel() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    if (this.state.query.length > 0) {
      return <button type="button" className="bp3-button bp3-icon-cross bp3-minimal" onClick={this.handleCancel.bind(this)} />;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox marginTop="10px">
          <RelationshipSelect
            resetOnSelect={false}
            items={this.state.filteredRelationships}
            itemRenderer={this.renderRelationship.bind(this)}
            onItemSelect={this.handleRelationshipSelect.bind(this)}
            inputValueRenderer={this.renderInputValue.bind(this)}
            inputProps={{
              placeholder: I18n.t('js.start_typing'),
              onChange: this.filterRelationships.bind(this),
              value: this.state.query,
              className: 'popover-larger bp3-no-focus',
            }}
            query={this.state.query}
            onQueryChange={this.filterRelationships.bind(this)}
            popoverProps={{
              usePortal: false,
              position: 'top-left',
              className: '',
              interactionKind: PopoverInteractionKind.CLICK,
              popoverClassName: 'bp3-no-padding',
            }}
            noResults={this.renderResultSearch()}
          >
            <Button icon="graph" text={I18n.t('js.add_relationship')} />
          </RelationshipSelect>
        </Flexbox>
      </Flexbox>
    );
  }
}
