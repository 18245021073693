import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AuthStore', 'ToastStore')
@observer
export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.AuthStore.forgotPasswordFormValid) {
      this.toggleLoading();
      this.setState({ isBlurred: [] });
      this.props.AuthStore.createForgotPassword()
        .then(() => {
          this.toggleLoading();
        })
        .catch(() => {
          this.toggleLoading();
        });
    } else {
      this.setState({
        isBlurred: ['email', 'password'],
      });
    }
  }

  handleInputChange(e) {
    this.props.AuthStore.updateNewForgotPassword(
      e.target.value,
      e.target.name
    );
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.AuthStore.newForgotPassword[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.AuthStore.newForgotPassword[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox
            flexDirection="row"
            flexGrow={1}
            className="push-20-t"
          >
            <Flexbox
              flexGrow={1}
              className={
                _.includes(this.state.isBlurred, 'email') &&
                !this.props.AuthStore.newForgotPassword.emailValid
                  ? classNames('bp3-form-group bp3-intent-danger')
                  : classNames('bp3-form-group')
              }
            >
              <label className="bp3-label" htmlFor="email">
                {I18n.t('js.email')}
                <span className="bp3-text-muted push-5-l">
                  ({I18n.t('js.required')})
                </span>
              </label>
              <Flexbox
                flexGrow={1}
                className="bp3-form-content"
                flexDirection="column"
              >
                <Flexbox
                  flexGrow={1}
                  className={
                    _.includes(this.state.isBlurred, 'email') &&
                    !this.props.AuthStore.newForgotPassword.emailValid
                      ? classNames(
                          'bp3-input-group bp3-intent-danger'
                        )
                      : classNames('bp3-input-group')
                  }
                >
                  <input
                    autoComplete="off"
                    className="bp3-input bp3-fill"
                    type="text"
                    placeholder={I18n.t('js.someone_example_com')}
                    name="email"
                    value={
                      this.props.AuthStore.newForgotPassword.email
                    }
                    onChange={this.handleInputChange.bind(this)}
                    onBlur={this.handleBlurChange.bind(this)}
                    dir="auto"
                  />
                </Flexbox>
                {this.renderErrors('email')}
              </Flexbox>
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="row" flexGrow={1}>
            <Button
              intent={Intent.PRIMARY}
              type="submit"
              className="bp3-fill bp3-large"
              text={I18n.t('js.send_me_a_password_reset_email')}
              loading={this.state.isSubmitting}
            />
          </Flexbox>
        </form>
      </Flexbox>
    );
  }
}
