/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { action, computed, observable } from 'mobx';
import Utilities from '../../utils/Utilities';

export default class ElementNumber {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;
  @observable
  placeholderText;
  @observable
  numericType;
  @observable
  prefix;
  @observable
  suffix;
  @observable
  minValue;
  @observable
  maxValue;

  constructor(id, elementId, name, placeholderText, numericType, prefix, suffix, minValue, maxValue) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
    this.placeholderText = placeholderText || '';
    this.numericType = numericType || '';
    this.prefix = prefix || '';
    this.suffix = suffix || '';
    if (minValue === '0.0') {
      this.minValue = 0;
    } else {
      this.minValue = Utilities.decimalToInt(minValue) || 0;
    }
    if (maxValue === '0.0') {
      this.maxValue = 0;
    } else {
      this.maxValue = Utilities.decimalToInt(maxValue) || 0;
    }
  }

  @action
  inputValid(value) {
    if (value === '') {
      return true;
    }
    if (value < this.minValue && this.minValue !== null) {
      return false;
    }
    if (value > this.maxValue && this.maxValue !== null) {
      return false;
    }
    return true;
  }

  @action
  validateNumberRange(input) {
    if (this.minValue === 0 && this.maxValue === 0) {
      return true;
    }
    if (this.minValue !== null && this.maxValue !== null) {
      if (input < this.minValue || input > this.maxValue) {
        return false;
      }
      return true;
    }
    if (input > this.maxValue && this.maxValue !== null) {
      return false;
    }
    if (this.minValue > input && this.minValue !== null) {
      return false;
    }
    return true;
  }

  @action
  validateNumberRangeMessage(input, full) {
    if (this.minValue === 0 && this.maxValue === 0) {
      return null;
    }
    if (this.minValue !== null && this.maxValue !== null) {
      if (input < this.minValue || input > this.maxValue) {
        if (full) {
          return I18n.t('js.please_enter_a_value_between_min_and_max_full', {
            min: this.minValue,
            max: this.maxValue,
            name: this.name,
            prefix: this.prefix,
            suffix: this.suffix,
          });
        }
        return I18n.t('js.please_enter_a_value_between_min_and_max', {
          min: this.minValue,
          max: this.maxValue,
          prefix: this.prefix,
          suffix: this.suffix,
        });
      }
      return null;
    }
    if (input > this.maxValue && this.maxValue !== null) {
      if (full) {
        return I18n.t('js.please_enter_a_value_less_than_x_full', {
          value: this.maxValue,
          name: this.name,
          prefix: this.prefix,
          suffix: this.suffix,
        });
      }
      return I18n.t('js.please_enter_a_value_less_than_x', {
        value: this.maxValue,
        prefix: this.prefix,
        suffix: this.suffix,
      });
    }
    if (this.minValue > input && this.minValue !== null) {
      if (full) {
        return I18n.t('js.please_enter_a_value_greater_than_x_full', {
          value: this.minValue,
          name: this.name,
          prefix: this.prefix,
          suffix: this.suffix,
        });
      }
      return I18n.t('js.please_enter_a_value_greater_than_x', {
        value: this.minValue,
        prefix: this.prefix,
        suffix: this.suffix,
      });
    }
    return null;
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get placeholderTextValid() {
    if (this.placeholderText.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get placeholderTextError() {
    if (this.placeholderText.length < 1) {
      return I18n.t('js.placeholder_text_is_required');
    }
    return null;
  }
}
