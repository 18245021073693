import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';

// components
import ActivityView from '../../views/ActivityView';
import UserProfileTemplateView from '../../views/UserProfileTemplateView';
import UserProfileTabs from './UserProfileTabs';
import UserProfileEdit from './UserProfileEdit';
import UserProfileTeams from './UserProfileTeams';
import UserProfileRelationships from './UserProfileRelationships';
import UserTimeline from '../../components/timeline/UserTimeline';
import DynamicUserView from '../../components/dynamic_view/DynamicUserView';
import Avatar from 'react-avatar';

@inject('CurrentUserStore', 'DynamicViewStore', 'UserProfileStore', 'AppStore')
@observer
export default class UserProfile extends React.Component {
  componentDidMount() {
    if (!this.props.CurrentUserStore.hasAccount && !this.props.CurrentUserStore.isLoading) {
      this.props.UserProfileStore.activeTab = 'settings';
    }
    if (this.props.userId === this.props.CurrentUserStore.currentUser.id) {
      this.props.UserProfileStore.setUser(this.props.CurrentUserStore.currentUser);
    }
  }

  handleAppChange(e) {
    if (this.props.DynamicViewStore.activeApp === null) {
      const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
      this.props.DynamicViewStore.setActiveApp(app);
    } else {
      if (this.props.DynamicViewStore.activeApp.id !== e.target.value) {
        const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
        this.props.DynamicViewStore.setActiveApp(app);
      }
    }
  }

  handleParticipantChange(e) {
    const participant = _.find(this.props.DynamicViewStore.participants, o => o.id === e.target.value);
    this.props.DynamicViewStore.setActiveParticipant(participant);
  }

  handleStatusFlagChange(e) {
    const statusFlag = _.find(this.props.DynamicViewStore.statusFlags, o => o.id === e.target.value);
    this.props.DynamicViewStore.setActiveStatusFlag(statusFlag);
  }

  renderProfileBar() {
    return (
      <Flexbox flexDirection="row" className="bp3-profile-header-row" alignItems="center">
        <Flexbox marginRight="20px">
          <Avatar src={this.props.UserProfileStore.newUser.imageFileName} round={true} size={100} />
        </Flexbox>
        <Flexbox flexDirection="column">
          <h2 className="bp3-heading">{this.props.UserProfileStore.newUser.fullName}</h2>
          <Flexbox>
            <p className="bp3-text-muted">{this.props.UserProfileStore.newUser.email}</p>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }

  renderContent() {
    if (this.props.UserProfileStore.isLoading) {
      return <Flexbox paddingTop="150px" paddingBottom="150px"></Flexbox>;
    }
    if (this.props.UserProfileStore.activeTab === 'settings') {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="fixed-width-container">
          <UserProfileEdit />
        </Flexbox>
      );
    }
    if (this.props.UserProfileStore.activeTab === 'timeline') {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="fixed-width-container">
          <UserTimeline
            userId={this.props.userId}
            isEditable={this.props.userId === this.props.CurrentUserStore.currentUser.id}
            showTitles={false}
          />
        </Flexbox>
      );
    }
    if (this.props.UserProfileStore.activeTab === 'activity') {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="fixed-width-container">
          <ActivityView activityObjectType="User" activityObjectId={this.props.userId} />
        </Flexbox>
      );
    }
    if (this.props.UserProfileStore.activeTab === 'teams') {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="fixed-width-container">
          <UserProfileTeams />
        </Flexbox>
      );
    }
    if (this.props.UserProfileStore.activeTab === 'relationships') {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="fixed-width-container">
          <UserProfileRelationships />
        </Flexbox>
      );
    }
    if (this.props.UserProfileStore.activeTab === 'account') {
      return (
        <Flexbox flexDirection="column">
          <UserProfileTemplateView />
        </Flexbox>
      );
    }
    if (this.props.UserProfileStore.activeTab === 'items') {
      this.props.DynamicViewStore.items = [];
      this.props.DynamicViewStore.isLoadingItemsAsOwner = true;
      this.props.DynamicViewStore.setFilteredUser(this.props.UserProfileStore.user);
      return (
        <Flexbox flexDirection="column" className="fixed-width-container">
          <DynamicUserView
            user={this.props.UserProfileStore.user}
            allowAppSelect={true}
            allowParticipantSelect={false}
            allowStatusFlagSelect={false}
            allowMultiSelect={false}
            disableUserButton={true}
            handleAppChange={this.handleAppChange.bind(this)}
            handleParticipantChange={this.handleParticipantChange.bind(this)}
            handleStatusFlagChange={this.handleStatusFlagChange.bind(this)}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
        <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
          {this.renderProfileBar()}
          <UserProfileTabs />
          <Flexbox flexGrow={1} flexDirection="column" marginTop="20px">
            {this.renderContent()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
