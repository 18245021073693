import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  Classes,
  Intent,
  Switch,
} from '@blueprintjs/core';
import UserAvatar from '../shared/UserAvatar';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class AccountUsersRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMakingOwner: false,
      isRemovingOwner: false,
      isRemovingUser: false,
    };
  }

  toggleMakingOwner() {
    this.setState({
      isMakingOwner: !this.state.isMakingOwner,
    });
  }

  toggleRemovingOwner() {
    this.setState({
      isRemovingOwner: !this.state.isRemovingOwner,
    });
  }

  toggleRemovingUser() {
    this.setState({
      isRemovingUser: !this.state.isRemovingUser,
    });
  }

  handleSendInvitation() {
    const email = this.props.user.email;
    this.props.AccountUsersStore.updateNewInvitation(
      this.props.user.email,
      'email'
    );
    this.props.AccountUsersStore.createInvitation().then(response => {
      this.props.ToastStore.showToast(
        I18n.t('js.invitation_has_been_sent', {
          email: email,
        }),
        'success'
      );
    });
  }

  handleMakeOwner(user) {
    const newObj = {
      account_owner: true,
    };
    this.props.AccountUsersStore.changeUser(
      this.props.user.id,
      newObj
    )
      .then(() => {
        this.props.user.isAccountOwner = true;
        this.toggleMakingOwner();
        this.props.ToastStore.showToast(
          I18n.t('js.user_has_been_made_owner', {
            name: user.fullName,
          }),
          'success'
        );
      })
      .catch(() => {
        this.toggleMakingOwner();
      });
  }

  handleRemoveOwner(user) {
    const newObj = {
      account_owner: false,
    };
    this.props.AccountUsersStore.changeUser(
      this.props.user.id,
      newObj
    )
      .then(() => {
        this.props.user.isAccountOwner = false;
        this.toggleRemovingOwner();
        this.props.ToastStore.showToast(
          I18n.t('js.user_has_been_removed_owner', {
            name: user.fullName,
          }),
          'success'
        );
      })
      .catch(() => {
        this.toggleRemovingOwner();
      });
  }

  handleRemoveUser() {
    this.props.AccountUsersStore.deleteUser(this.props.user.id)
      .then(() => {
        this.toggleRemovingUser();
        this.props.ToastStore.showToast(
          I18n.t('js.user_has_been_removed'),
          'success'
        );
      })
      .catch(() => {
        this.toggleRemovingUser();
      });
  }

  renderMakeOwner() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isMakingOwner}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleMakingOwner()}
        confirmButtonText={I18n.t('js.make_user_owner')}
        onConfirm={() => this.handleMakeOwner(this.props.user)}
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t(
          'js.are_you_sure_you_wish_to_make_this_user_an_account_owner',
          { name: this.props.user.fullName }
        )}
      </Alert>
    );
  }

  renderRemoveOwner() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isRemovingOwner}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleRemovingOwner()}
        confirmButtonText={I18n.t('js.remove_account_ownership')}
        onConfirm={() => this.handleRemoveOwner(this.props.user)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t(
          'js.are_you_sure_you_wish_to_remove_this_account_owner',
          { name: this.props.user.fullName }
        )}
      </Alert>
    );
  }

  renderRemoveUser() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isRemovingUser}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleRemovingUser()}
        confirmButtonText={I18n.t('js.remove_user')}
        onConfirm={() => this.handleRemoveUser(this.props.user)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t('js.are_you_sure_you_wish_to_remove_this_user', {
          name: this.props.user.fullName,
        })}
      </Alert>
    );
  }

  render() {
    return (
      <tr>
        <td>
          <UserAvatar
            user={this.props.user}
            avatarSize={20}
            path={`/users/${this.props.user.humanFriendlyId}`}
          />
        </td>
        <td>{this.props.user.lastSignInAtToDate}</td>
        <td>
          <Flexbox
            flexGrow={1}
            flexDirection="row"
            justifyContent="flex-start"
          >
            <Switch
              checked={this.props.user.isAccountOwner}
              className="bp3-control-no-margin"
              onChange={
                this.props.user.isAccountOwner
                  ? this.toggleRemovingOwner.bind(this)
                  : this.toggleMakingOwner.bind(this)
              }
            />
          </Flexbox>
        </td>
        <td>
          <Button
            className="bp3-small"
            text={I18n.t('js.remove_user')}
            onClick={() => this.toggleRemovingUser()}
          />
          <Button
            className="bp3-small push-5-l"
            text={I18n.t('js.send_invitation')}
            disabled={this.props.user.lastSignInAt.length > 0}
            onClick={() => this.handleSendInvitation()}
          />
        </td>
        {this.renderMakeOwner()}
        {this.renderRemoveOwner()}
        {this.renderRemoveUser()}
      </tr>
    );
  }
}
