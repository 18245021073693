import React, { Component } from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Popover, AnchorButton, PopoverInteractionKind, Button, NonIdealState, Icon, Classes, Position } from '@blueprintjs/core';
import AdminStore from '../../stores/AdminStore';
import AdminActions from '../../actions/AdminActions';
import moment from 'moment';
import StringToMarkdown from '../shared/StringToMarkdown';
import _ from 'lodash';

@observer
export default class ProductPopover extends Component {
  @observable
  isLoading = true;
  @observable
  activeNews = '';

  componentDidMount() {
    AdminActions.fetchNews()
      .then(response => {
        _.map(response.items, news => {
          AdminStore.addNews(news);
        });
        this.activeNews = AdminStore.news[0];
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  goToNext() {
    if (_.last(AdminStore.news).sys.id !== this.activeNews.sys.id) {
      this.activeNews = AdminStore.news[AdminStore.news.indexOf(this.activeNews) + 1];
    }
  }

  goToPrevious() {
    if (_.head(AdminStore.news).sys.id !== this.activeNews.sys.id) {
      this.activeNews = AdminStore.news[AdminStore.news.indexOf(this.activeNews) - 1];
    }
  }

  renderNext() {
    return (
      <Button
        type="button"
        icon="arrow-right"
        className="push-10-l"
        text={I18n.t('js.next')}
        onClick={this.goToNext.bind(this)}
        disabled={_.last(AdminStore.news).sys.id === this.activeNews.sys.id}
      />
    );
  }

  renderPrevious() {
    return (
      <Button
        type="button"
        icon="arrow-left"
        className="push-10-l"
        text={I18n.t('js.previous')}
        onClick={this.goToPrevious.bind(this)}
        disabled={_.head(AdminStore.news).sys.id === this.activeNews.sys.id}
      />
    );
  }

  renderPopoverContent() {
    if (this.isLoading || AdminStore.news.length === 0) {
      return (
        <Flexbox padding="40px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_is_no_news_to_show')}
            icon="book"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" width="400px" padding="20px">
        <p className="bp3-text-muted">{I18n.t('js.product_updates')}</p>
        <h3 className={Classes.HEADING}>{this.activeNews.fields.title}</h3>
        <small className="bp3-text-muted">
          <span className="bp3-icon-calendar push-5-r" />
          {I18n.t('js.published_on', {
            date: moment.utc(this.activeNews.fields.publishedOn).format('MMMM Do YYYY, h:mm a'),
          })}
        </small>
        {this.activeNews.fields.thumbnail.fields ? (
          <Flexbox marginTop="20px">
            <img src={this.activeNews.fields.thumbnail.fields.file.url} className="img-responsive" />
          </Flexbox>
        ) : null}
        <Flexbox flexDirection="row" marginTop="20px">
          <StringToMarkdown string={this.activeNews.fields.body} />
        </Flexbox>
        <Flexbox flexDirection="row" marginTop="20px" justifyContent="space-between">
          <Flexbox flexDirection="row">
            {this.renderPrevious()}
            {this.renderNext()}
          </Flexbox>
          <AnchorButton
            href={this.activeNews.fields.url}
            target="_blank"
            icon="share"
            className="bp3-intent-primary"
            text={I18n.t('js.read_more')}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Popover
        portalContainer={document.body}
        content={this.renderPopoverContent()}
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.BOTTOM_RIGHT}
        minimal={true}
        boundary="viewport"
      >
        <Button minimal>
          <Icon icon="lightbulb" />
        </Button>
      </Popover>
    );
  }
}
