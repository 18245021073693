import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Icon } from '@blueprintjs/core';

@inject('CurrentUserStore', 'ToastStore')
@observer
export default class SignUpBar extends React.Component {
  render() {
    return null;
    // return (
    //   <Flexbox width="100hw" className="sign-up-bar">
    //     <Flexbox className="fixed-width-container">
    //       <Flexbox
    //         flexGrow={1}
    //         alignContent="center"
    //         alignItems="center"
    //       >
    //         <Icon
    //           icon="issue-new"
    //           className="text-white push-10-r"
    //           iconSize={25}
    //         />
    //         <Flexbox
    //           flexDirection="column"
    //           justifyContent="center"
    //           paddingTop="10px"
    //         >
    //           <p>
    //             {I18n.t('js.peoplegoal_tagline')}
    //           </p>
    //         </Flexbox>
    //       </Flexbox>
    //       <Flexbox flexDirection="column" justifyContent="center">
    //         <AnchorButton
    //           href="/request-a-demo"
    //           icon="endorsed"
    //           text={I18n.t('js.request_a_demo')}
    //           className="bp3-intent-primary"
    //         />
    //       </Flexbox>
    //     </Flexbox>
    //   </Flexbox>
    // );
  }
}
