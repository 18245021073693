import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import DashboardTaskList from '../dashboard/DashboardTaskList';
import DynamicRelationshipView from './DynamicRelationshipView';
import DynamicTeamView from './DynamicTeamView';
import DynamicAllItemsView from './DynamicAllItemsView';
import DynamicViewCustomiseItemDisplay from './DynamicViewCustomiseItemDisplay';
import _ from 'lodash';

@inject('DynamicViewStore', 'AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynamicView extends React.Component {
  handleTabChange(tab) {
    this.props.DynamicViewStore.setActiveTab(tab);
  }

  componentDidMount() {
    this.props.DynamicViewStore.resetStore();
    if (this.props.selectedApp) {
      this.props.DynamicViewStore.viewArea = 'app';
      this.props.DynamicViewStore.setActiveApp(this.props.AppStore.activeApp);
      this.props.DynamicViewStore.setActiveTab(this.props.DynamicViewStore.activeTab);
    }
  }

  handleAppChange(e) {
    if (this.props.DynamicViewStore.activeApp === null) {
      const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
      this.props.DynamicViewStore.setActiveApp(app);
    } else {
      if (this.props.DynamicViewStore.activeApp.id !== e.target.value) {
        const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
        this.props.DynamicViewStore.setActiveApp(app);
      }
    }
  }

  handleParticipantChange(e) {
    const participant = _.find(this.props.DynamicViewStore.participants, o => o.id === e.target.value);
    this.props.DynamicViewStore.setActiveParticipant(participant);
  }

  handleStatusFlagChange(e) {
    if (e.target.value == 'reset') {
      this.props.DynamicViewStore.activeStatusFlag = null;
      this.props.DynamicViewStore.setActiveTab(this.props.DynamicViewStore.activeTab);
    } else {
      const statusFlag = _.find(this.props.DynamicViewStore.statusFlags, o => o.id === e.target.value);
      this.props.DynamicViewStore.setActiveStatusFlag(statusFlag);
    }
  }

  toggleLoading() {
    this.props.DynamicViewStore.toggleLoading();
  }

  renderData() {
    if (this.props.DynamicViewStore.isLoading || this.props.DynamicViewStore.isLoadingAllItems) {
      return (
        <Flexbox flexDirection="column">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted push-20-t"
          />
        </Flexbox>
      );
    } else {
      switch (this.props.DynamicViewStore.activeTab) {
        case 'home':
          return null;
        case 'your-items':
          if (this.props.selectedApp) {
            return (
              <DynamicRelationshipView
                allowAppSelect={!this.props.selectedApp}
                handleAppChange={this.handleAppChange.bind(this)}
                handleParticipantChange={this.handleParticipantChange.bind(this)}
                handleStatusFlagChange={this.handleStatusFlagChange.bind(this)}
              />
            );
          }
          return <DashboardTaskList />;
        case 'your-colleagues':
          return (
            <DynamicRelationshipView
              allowAppSelect={!this.props.selectedApp}
              handleAppChange={this.handleAppChange.bind(this)}
              handleParticipantChange={this.handleParticipantChange.bind(this)}
              handleStatusFlagChange={this.handleStatusFlagChange.bind(this)}
            />
          );
        case 'your-teams':
          return (
            <DynamicTeamView
              allowAppSelect={!this.props.selectedApp}
              handleAppChange={this.handleAppChange.bind(this)}
              handleParticipantChange={this.handleParticipantChange.bind(this)}
              handleStatusFlagChange={this.handleStatusFlagChange.bind(this)}
            />
          );
        case 'all-items':
          return (
            <DynamicAllItemsView
              allowAppSelect={!this.props.selectedApp}
              handleAppChange={this.handleAppChange.bind(this)}
              handleParticipantChange={this.handleParticipantChange.bind(this)}
              handleStatusFlagChange={this.handleStatusFlagChange.bind(this)}
            />
          );
        default:
          console.log(`Case not handled: ${this.props.DynamicViewStore.activeTab}`);
      }
    }
  }

  renderCustomiseItemDisplay() {
    const store = this.props.DynamicViewStore;
    if (!store.isLoading && store.activeApp != null && store.activeRelationship != null) {
      if (store.activeApp.userPermissionLevel.name === 'owner' && this.props.selectedApp) {
        return (
          <Flexbox flexDirection="row">
            <DynamicViewCustomiseItemDisplay />
          </Flexbox>
        );
      }
    }
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderData()}
        {this.renderCustomiseItemDisplay()}
      </Flexbox>
    );
  }
}
