import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import Utilities from '../../utils/Utilities';

@inject('CurrentUserStore', 'TeamsStore', 'ToastStore')
@observer
export default class TeamEditBackgroundImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      image: '',
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleDrop(images) {
    this.setState({ image: images[0] });
  }

  handleSubmit() {
    this.toggleLoading();
    const finalImage = this.state.image;
    const formData = new FormData();
    formData.append(
      'background_image',
      finalImage,
      Utilities.generateRandomImgName()
    );
    this.props.TeamsStore.changeTeam(formData)
      .then(() => {
        this.toggleLoading();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleCancel() {
    this.setState({ isOpen: false, hasImage: false, image: '' });
  }

  renderSelectImageText() {
    if (this.state.image === '') {
      return <span>{I18n.t('js.select_new_image')}</span>;
    }
    return <span>{this.state.image.name}</span>;
  }

  renderImage() {
    return (
      <div className="bp3-button-group">
        <Dropzone
          onDrop={this.handleDrop.bind(this)}
          className="bp3-dropzone bp3-button bp3-icon-media"
        >
          {this.renderSelectImageText()}
        </Dropzone>
      </div>
    );
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* jsx-a11y disabled as there is alternative way to expand menu */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.team_background_image')}
            </h4>
            <p className="noselect">
              {I18n.t('js.team_background_image_text')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form>
            <Flexbox flexDirection="column">
              {this.renderImage()}
              <div className="push-10-t">
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.handleSubmit.bind(this)}
                  text={I18n.t('js.save_changes')}
                  disabled={this.state.image === ''}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button
                  className="push-10-l"
                  icon="cross"
                  text={I18n.t('js.cancel_changes')}
                  onClick={this.handleCancel.bind(this)}
                />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
