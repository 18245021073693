import React from 'react';
import { observer, Provider } from 'mobx-react';
import ToastStore from '../stores/ToastStore';
import ThankYou from '../components/thankyou/ThankYou'; // Corrected the import

@observer
export default class ThankyouView extends React.Component {
  render() {
    const stores = {
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <div>   
          <ThankYou />
        </div>
      </Provider>
    );
  }
}
