import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Classes,
  Collapse,
  InputGroup,
  Intent,
  Tag,
} from '@blueprintjs/core';
import _ from 'lodash';
import classNames from 'classnames';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsDetailsAccountSubdomain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    this.props.AccountSettingsStore.changeAccount(['subdomain'])
      .then(() => {
        this.toggleLoading();
        this.toggleOpen();
        location.reload();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.AccountSettingsStore.updateAccount(
      e.target.value,
      e.target.name
    );
  }

  handleCancel() {
    this.props.AccountSettingsStore.resetAccountAttribute(
      'subdomain'
    );
    this.toggleOpen();
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.AccountSettingsStore.newAccount[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {
            this.props.AccountSettingsStore.newAccount[
              `${field}Error`
            ]
          }
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* jsx-a11y disabled as altenative toggling provider */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.subdomain')}
            </h4>
            <p className="noselect">
              {I18n.t('js.account_subdomain_text')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'subdomain') &&
                  !this.props.AccountSettingsStore.newAccount
                    .subdomainValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="subdomain">
                  {I18n.t('js.account_subdomain')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'subdomain') &&
                      !this.props.AccountSettingsStore.newAccount
                        .subdomainValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <InputGroup
                      autoComplete="off"
                      className="bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.example')}
                      name="subdomain"
                      value={
                        this.props.AccountSettingsStore.newAccount
                          .subdomain
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      leftIcon="globe"
                      rightElement={
                        <Tag className="bp3-intent-success">
                          .peoplegoal.com
                        </Tag>
                      }
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('subdomain')}
                </div>
                <div className="push-10-t">
                  <Button
                    intent={Intent.PRIMARY}
                    type="submit"
                    disabled={
                      !this.props.AccountSettingsStore.newAccount
                        .subdomainValid
                    }
                    text={I18n.t('js.save_changes')}
                    loading={this.state.isSubmitting}
                    icon="tick"
                  />
                  <Button
                    className="push-10-l"
                    icon="cross"
                    text={I18n.t('js.cancel_changes')}
                    onClick={this.handleCancel.bind(this)}
                  />
                </div>
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
