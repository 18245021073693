import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormGroup, Intent } from '@blueprintjs/core';
import { DateTimePicker } from '@blueprintjs/datetime';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementDateTime extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  inputValue = null;
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      if (this.props.element.formValues[0].dateValue) {
        this.inputValue = new Date(this.props.element.formValues[0].dateValue);
        this.props.validateInput(this.inputValue);
      } else {
        this.props.validateInput('');
      }
    } else {
      this.props.validateInput('');
    }
    this.mountDisplayValuesInFlight();
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_id === this.props.element.id) {
        this.inputValue = formValue.date_value;
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        this.inputValue = findFormValueToChange.date_value;
      }
    });
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    object.item_value_type = 'datetime';
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'datetime';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'datetime';
        return object;
    }
  }

  handleInputChange(date) {
    if (date !== null) {
      this.inputValue = new Date(date);
      if (this.actionType === 'updating') {
        const changedFormValue = {
          id: this.props.element.formValues[0].id,
          date_value: this.inputValue,
        };
        this.store.addFormValueToChange(changedFormValue);
      } else {
        let newFormValue = {
          id: this.newId,
          element_id: this.props.element.id,
          date_value: this.inputValue,
        };
        newFormValue = this.appendAttributes(newFormValue);
        this.store.addFormValueToCreate(newFormValue);
      }
      this.props.validateInput(this.inputValue);
    } else {
      this.props.validateInput('');
    }
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderInput() {
    return (
      <DateTimePicker
        className="bp3-elevation-1"
        value={this.inputValue}
        onChange={this.handleInputChange.bind(this)}
        intent={this.props.showError ? Intent.DANGER : null}
        datePickerProps={{
          minDate: new Date(this.props.element.elementDate.minDate),
          maxDate: new Date(this.props.element.elementDate.maxDate),
        }}
      />
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
        <FormGroup
          label={this.props.element.elementDate.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          <Flexbox>{this.renderInput()}</Flexbox>
        </FormGroup>
      </Flexbox>
    );
  }
}
