import React, { Component } from 'react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import WidgetStore from '../../stores/WidgetStore';
import Flexbox from 'flexbox-react';
import Widget from './Widget';
import { inject, observer } from 'mobx-react';
import { arrayMove, sortableContainer, sortableElement } from 'react-sortable-hoc';

const SortableWidget = sortableElement(({ widget, editable }) => {
  return (
    <Flexbox key={widget.id} paddingRight="10px" paddingBottom="10px" width={`${(widget.width / 4) * 100}%`}>
      <Widget widget={widget} editable={editable} />
    </Flexbox>
  );
});

const SortableWidgets = sortableContainer(({ widgets, editable }) => (
  <Flexbox flexDirection="row" flexGrow={1} flexWrap="wrap" justifyContent="flex-start">
    {widgets.map(widget => (
      <SortableWidget key={widget.id} index={widgets.indexOf(widget)} widget={widget} editable={editable} />
    ))}
  </Flexbox>
));

@observer
export default class Widgets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  toggleLoading() {
    this.setState({ isLoading: !this.state.isLoading });
  }

  componentDidMount() {
    this.loadData(this.props.widgetableId, this.props.widgetableType);
  }

  componentWillReceiveProps(nextProps) {
    // This workaround is for workspace folders
    if (nextProps.widgetableId != this.props.widgetableId) {
      this.loadData(nextProps.widgetableId, nextProps.widgetableType);
    }
  }

  loadData(id, type) {
    WidgetStore.resetStartingAttributes();
    this.toggleLoading();
    if (id && type) {
      WidgetStore.fetchWidgets(id, type)
        .then(response => {
          this.toggleLoading();
        })
        .catch(err => {
          this.toggleLoading();
        });
    } else {
      this.toggleLoading();
    }
  }

  handleSortEnd(widget, newIndex) {
    const widgetObj = {
      id: widget.id,
      row_order_position: newIndex,
    };
    WidgetStore.changeWidget(widgetObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the widget
    const widget = WidgetStore.widgets[oldIndex];

    // Reorder the current UI
    const newWidgets = arrayMove(WidgetStore.widgets.slice(), oldIndex, newIndex);
    WidgetStore.widgets = newWidgets;

    // Process reorder on server
    this.handleSortEnd(widget, newIndex);
  }

  render() {
    if (this.state.isLoading) {
      return <Flexbox flexDirection="column"></Flexbox>;
    }
    if (WidgetStore.widgets.length === 0 && this.props.showEmptyState) {
      return (
        <Flexbox flexDirection="column" marginBottom="20px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_is_nothing_to_show_right_now')}
            icon="application"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    } else {
      if (this.props.editable) {
        return (
          <Flexbox flexDirection="row" marginBottom="10px" flexGrow={1} flexWrap="wrap">
            <SortableWidgets
              widgets={WidgetStore.widgets}
              editable={this.props.editable}
              onSortEnd={this.onSortEnd.bind(this)}
              lockToContainerEdges={true}
              hideSortableGhost={true}
              axis="xy"
              distance={20}
            />
          </Flexbox>
        );
      } else {
        return (
          <Flexbox flexDirection="row" marginBottom="10px" flexGrow={1} flexWrap="wrap">
            {WidgetStore.widgets.map(widget => {
              return (
                <Flexbox key={widget.id} paddingRight="10px" paddingBottom="10px" width={`${(widget.width / 4) * 100}%`}>
                  <Widget widget={widget} editable={this.props.editable} />
                </Flexbox>
              );
            })}
          </Flexbox>
        );
      }
    }
  }
}
