import React from 'react';
import Flexbox from 'flexbox-react';
import { convertFromRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { NonIdealState } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';

import createStyles from 'draft-js-custom-styles';
const { styles, customStyleFn, exporter } = createStyles(['color'], 'CUSTOM_');

export default class RichTextDisplay extends React.Component {
  render() {
    if (this.props.content.length > 0) {
      try {
        const displayState = EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.content)));
        const inlineStyles = exporter(displayState);
        let html = stateToHTML(displayState.getCurrentContent(), {
          inlineStyles,
        });
        const content = JSON.parse(this.props.content);
        if (!_.isEmpty(content.entityMap)) {
          _.map(content.entityMap, i => {
            if (i.type == 'mention') {
              let mentionedName = i.data.mention.name;
              let mentionedUserLink = i.data.mention.link;
              let mentionedAvatar = i.data.mention.avatar;

              if (mentionedName != '') {
                let styledMention = `<a class="mention-text pointer" href='${mentionedUserLink}'>${mentionedName}</a>`;
                if (mentionedAvatar) {
                  styledMention = `<a class="mention-text pointer" href='${mentionedUserLink}'><img src="${mentionedAvatar}" width='20' heigh='20' class='comment-avatar'/><span style='margin-left: 25px'>${mentionedName}</span></a>`;
                }
                html = html.replace(mentionedName, styledMention);
              }
            }
          });
        }
        if (html === '<p><br></p>') {
          if (this.props.type === 'element') {
            return (
              <Flexbox flexDirection="column" flexGrow={1}>
                <NonIdealState
                  title={I18n.t('js.rich_textarea_element')}
                  description={I18n.t('js.configure_element_to_add_content')}
                  icon="manually-entered-data"
                  className="bp3-text-muted"
                />
              </Flexbox>
            );
          }
          return null;
        }
        return (
          <Flexbox flexDirection="column" flexGrow={1}>
            <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap" paddingTop="10px" className="rich-textarea-text">
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            </Flexbox>
          </Flexbox>
        );
      } catch (error) {
        return (
          <Flexbox flexDirection="column" flexGrow={1}>
            <p>{this.props.content.split('"text":"')[1].split('","type"')[0]}</p>
          </Flexbox>
        );
      }
    }
    if (this.props.type !== 'comment') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <NonIdealState
            title={I18n.t('js.rich_textarea_element')}
            description={I18n.t('js.configure_element_to_add_content')}
            icon="manually-entered-data"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return null;
  }
}
