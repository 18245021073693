import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Alert, Button, Classes, Intent } from '@blueprintjs/core';

@inject('CurrentUserStore', 'TeamUsersRequestsStore', 'ToastStore')
@observer
export default class TeamUsersRequestsStore extends React.Component {
  constructor(props) {
    super(props);

    this.state = { accessOpen: false };
  }

  toggleAccessOpen() {
    this.setState({
      accessOpen: !this.state.accessOpen,
    });
  }

  handleRequestAccess() {
    this.props.TeamUsersRequestsStore.createUserTeamRequest(this.props.teamId)
      .then(() => {
        this.toggleAccessOpen();
      })
      .catch(() => {
        this.toggleAccessOpen();
      });
  }

  renderRequestAccess() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.accessOpen}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleAccessOpen()}
        confirmButtonText={I18n.t('js.request_access')}
        onConfirm={() => this.handleRequestAccess()}
        intent={Intent.PRIMARY}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_request_access_to_this_team', {
          name: this.props.TeamUsersRequestsStore.activeTeam.name,
        })}
      </Alert>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginTop="40px">
        <Flexbox flexDirection="row" flexGrow={1} justifyContent="center">
          <Button
            className="bp3-intent-danger"
            icon="envelope"
            text={I18n.t('js.request_access')}
            onClick={() => this.toggleAccessOpen()}
          />
        </Flexbox>
        <Flexbox marginTop="20px" flexDirection="column">
          {this.renderRequestAccess()}
        </Flexbox>
      </Flexbox>
    );
  }
}
