import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Utilities from '../../utils/Utilities';
import {
  Button,
  Classes,
  Collapse,
  Dialog,
  Intent,
} from '@blueprintjs/core';

@inject('WorkspaceStore', 'ToastStore')
@observer
export default class WorkspaceDeleteWorkspace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isAlerted: false,
      confirmedInput: '',
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleAlerted() {
    this.setState({
      isAlerted: !this.state.isAlerted,
    });
  }

  handleDeleteWorkspace() {
    this.toggleLoading();
    this.props.WorkspaceStore.deleteWorkspace()
      .then(() => {
        Utilities.navigate('/workspaces');
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.setState({ confirmedInput: e.target.value });
  }

  handleCancel() {
    this.toggleOpen();
  }

  renderAlert() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="warning-sign"
        isOpen={this.state.isAlerted}
        onClose={this.toggleAlerted.bind(this)}
        title={I18n.t('js.are_you_sure')}
      >
        <div className="bp3-dialog-body">
          {I18n.t('js.delete_workspace_confirm', {
            name: this.props.WorkspaceStore.newWorkspace.name,
          })}
          <Flexbox flexGrow={1} marginTop="20px">
            <input
              autoComplete="off"
              type="text"
              className="bp3-input bp3-intent-danger bp3-fill"
              placeholder={
                this.props.WorkspaceStore.newWorkspace.name
              }
              onChange={this.handleInputChange.bind(this)}
            />
          </Flexbox>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button
              text={I18n.t('js.cancel')}
              onClick={() => this.toggleAlerted()}
            />
            <Button
              intent={Intent.DANGER}
              loading={this.state.isSubmitting}
              onClick={this.handleDeleteWorkspace.bind(this)}
              text={I18n.t('js.delete_workspace')}
              disabled={
                this.props.WorkspaceStore.newWorkspace.name.toLowerCase() !==
                this.state.confirmedInput.toLowerCase()
              }
            />
          </div>
        </div>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.delete_workspace')}
            </h4>
            <p className="noselect">
              {I18n.t('js.delete_workspace_text')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <div className="bp3-callout bp3-intent-danger bp3-icon-warning-sign">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>
              {I18n.t('js.caution')}
            </h4>
            {I18n.t('js.delete_workspace_details')}
          </div>
          <Button
            intent={Intent.DANGER}
            icon="trash"
            onClick={() => this.toggleAlerted()}
            className="push-10-t"
          >
            {I18n.t('js.delete_workspace')}
          </Button>
          {this.renderAlert()}
        </Collapse>
      </Flexbox>
    );
  }
}
