import React from 'react';
import Avatar from 'react-avatar';
import { inject, observer } from 'mobx-react';
import { Icon } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import Utilities from '../../utils/Utilities';
import moment from 'moment';
import _ from 'lodash';

@inject('AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class ItemPin extends React.Component {
  render() {
    const { itemPin } = this.props;

    let bgStyle = {
      background: `url('${itemPin.item_image}')`,
      backgroundSize: 'cover',
      height: '30px',
      width: '30px',
      borderRadius: '30px',
    };
    let iconClass = classNames('display-none');
    let parentClass = classNames('position-relative');
    const darkenAmount = 0.25; // %
    if (!itemPin.item_image) {
      bgStyle = {
        background: `linear-gradient(${itemPin.item_color}, ${Utilities.darkenHex(itemPin.item_color, darkenAmount)})`,
        height: '30px',
        width: '30px',
        borderRadius: '30px',
      };
      iconClass = classNames('fa text-white', itemPin.item_icon);
    }

    return (
      <tr className={`${parentClass} item-table-row`} style={{ fontSize: '12px' }}>
        <td onClick={() => Utilities.navigate(`/items/${itemPin.item_id}`)}>
          <Flexbox flexDirection="row" alignItems="center" paddingTop="5px">
            <Flexbox style={bgStyle} flexDirection="column" justifyContent="center" alignItems="center">
              <Flexbox className={iconClass} />
            </Flexbox>
            <Flexbox marginLeft="10px" flexDirection="column">
              <h4 className="push-10-l bp3-label-no-margin">{itemPin.name}</h4>
              {itemPin.item_type}
            </Flexbox>
          </Flexbox>
        </td>
        <td onClick={() => Utilities.navigate(`/items/${itemPin.item_id}`)}>
          <Flexbox flexDirection="column" paddingTop="5px">
            <Flexbox flexDirection="row" alignContent="center" alignItems="center">
              <span>
                {I18n.t('js.pinned_to', { to: itemPin.user ? I18n.t('js.user').toLowerCase() : I18n.t('js.team').toLowerCase() })}
              </span>
            </Flexbox>
            <Flexbox flexDirection="row" className="bp3-text-muted" alignContent="center" alignItems="center">
              <Avatar
                src={itemPin.user ? itemPin.user.image_file_name : itemPin.team.image_file_name}
                size={20}
                round={true}
                className="push-5-r"
              />
              <strong>{itemPin.user ? itemPin.user.full_name : itemPin.team.name}</strong>
            </Flexbox>
          </Flexbox>
        </td>
        <td onClick={() => Utilities.navigate(`/items/${itemPin.item_id}`)}>
          <Flexbox flexDirection="column" paddingTop="5px">
            <Flexbox flexDirection="row" alignContent="center" alignItems="center">
              <small>{I18n.t('js.item_pinned_by', { pinnedBy: itemPin.pinned_by })}</small>
            </Flexbox>
            <Flexbox flexDirection="row" className="bp3-text-muted" alignContent="center" alignItems="center">
              <Icon icon="calendar" size={12} className="bp3-text-muted push-10-r" />
              <strong>{moment(itemPin.created_at).format('MMMM Do YYYY, h:mm a')}</strong>
            </Flexbox>
          </Flexbox>
        </td>
      </tr>
    );
  }
}
