import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import ItemView from '../../views/ItemView';
import GuideButton from '../guides/GuideButton';
import _ from 'lodash';

@inject(
  'AppStore',
  'AppItemStore',
  'DynamicViewStore',
  'ItemActions',
  'ItemStore',
  'CurrentUserStore',
  'ToastStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions'
)
@observer
export default class AppItemsNewItemDialog extends React.Component {
  @observable
  isUserDrawerOpen = false;
  @observable
  drawerData = [];

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      itemName: I18n.t('js.new_item_with_placeholder_name', {
        item: this.props.AppStore.activeApp.item,
      }),
    };
  }

  handleCancel() {
    this.props.handleCancel();
  }

  renderAppIcon(app) {
    return (
      <Flexbox flexDirection="row" width="15px" alignContent="center" justifyContent="center" marginRight="10px">
        <span className={`fa fas fa-fw ${app.icon}`} style={{ color: app.color }}></span>
      </Flexbox>
    );
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.props.dialogOpen}
        onClose={this.handleCancel.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        icon={this.renderAppIcon(this.props.AppStore.activeApp)}
        className="item-view-full-dialog"
        title={I18n.t('js.new_draft_item', {
          item: this.props.AppStore.activeApp.item,
        })}
      >
        <Flexbox flexDirection="column">
          {this.props.itemId ? <ItemView itemId={this.props.itemId} shownInDialogView={true} /> : null}
        </Flexbox>
      </Dialog>
    );
  }

  render() {
    return <Flexbox>{this.renderDialog()}</Flexbox>;
  }
}
