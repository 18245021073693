import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Mosaic, MosaicWindow } from 'react-mosaic-component';
import { Drawer, Icon, Position, NonIdealState, Button, Classes, Intent } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ChartLineConfiguration from './chart_configurations/ChartLineConfiguration';
import ChartBarConfiguration from './chart_configurations/ChartBarConfiguration';
import ChartPieConfiguration from './chart_configurations/ChartPieConfiguration';
import ChartRadarConfiguration from './chart_configurations/ChartRadarConfiguration';
import ChartMetricConfiguration from './chart_configurations/ChartMetricConfiguration';
import ChartCommonConfig from './chart_configurations/ChartCommonConfig';
import { Line, Bar, HorizontalBar, Pie, Radar, Polar } from 'react-chartjs-2';
import Cookies from 'js-cookie';
import MetricDisplay from './MetricDisplay';

@inject('ToastStore')
@observer
export default class DataViewVisualization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
    };
  }

  handleMosaicChange(chartLayout) {
    this.props.store.currentChartLayout = chartLayout;
  }

  handleEditChart(id) {
    this.props.store.setActiveChart(id);
    this.setState({ drawerOpen: true });
  }

  handleCloseDrawer() {
    this.props.store.setActiveChart(null);
    this.setState({ drawerOpen: false });
  }

  handleRemoveChart(id) {
    this.props.store.removeChart(id);
  }

  handleSaveChanges(e) {
    this.props.store.addChart(this.props.store.activeChart);
    this.handleCloseDrawer();
  }

  handleCancelChanges() {
    this.handleCloseDrawer();
  }

  renderControls(id) {
    if (this.props.isEditable) {
      return (
        <Flexbox>
          <Button icon="edit" minimal={true} small={true} onClick={() => this.handleEditChart(id)}></Button>
          <Button icon="cross" minimal={true} small={true} onClick={() => this.handleRemoveChart(id)}></Button>
        </Flexbox>
      );
    }
    return <Flexbox></Flexbox>;
  }

  renderChartWindow(id, path) {
    const { reportCharts } = this.props.store;
    const chartObj = _.find(reportCharts, o => o.id === id);
    return (
      <MosaicWindow
        draggable={this.props.isEditable}
        title={
          <Flexbox flexDirection="row" alignItems="center" title={chartObj.name}>
            <Icon icon={chartObj.icon} className="bp3-text-muted push-10-r" />
            {chartObj.name}
          </Flexbox>
        }
        toolbarControls={this.renderControls(id)}
        path={path}
      >
        <Flexbox flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} height="100%" padding="10px">
          {this.renderChart(chartObj)}
        </Flexbox>
      </MosaicWindow>
    );
  }

  renderChart(chartObj) {
    const { displayedTableData } = this.props.store;
    const formattedData = chartObj.formatDataForChart(displayedTableData);
    if (formattedData) {
      const options = chartObj.mergedOptionsForChart();

      switch (chartObj.type) {
        case 'line':
          return <Line data={formattedData} options={options} redraw={this.state.drawerOpen ? false : true} />;
        case 'bar':
          if (options.type === 'horizontalBar') {
            return <HorizontalBar data={formattedData} options={options} redraw={this.state.drawerOpen ? false : true} />;
          }
          return <Bar data={formattedData} options={options} redraw={this.state.drawerOpen ? false : true} />;
        case 'pie':
          return <Pie data={formattedData} options={options} redraw={this.state.drawerOpen ? false : true} />;
        case 'polar':
          return <Polar data={formattedData} options={options} redraw={this.state.drawerOpen ? false : true} />;
        case 'radar':
          if (chartObj.dataConfig.datasets.length > 0) {
            return <Radar data={formattedData} options={options} redraw={this.state.drawerOpen ? false : true} />;
          } else {
            return (
              <NonIdealState
                title={I18n.t('js.chart_not_configured')}
                description={I18n.t('js.click_edit_to_configure_this_chart')}
                icon={chartObj.icon}
                className="bp3-text-muted"
              />
            );
          }
        case 'metric':
          return <MetricDisplay data={formattedData} options={options} chartObj={chartObj} />;
        default:
          return (
            <NonIdealState
              title={I18n.t('js.chart_not_configured')}
              description={I18n.t('js.click_edit_to_configure_this_chart')}
              icon={chartObj.icon}
              className="bp3-text-muted"
            />
          );
      }
    }
    return (
      <NonIdealState
        title={I18n.t('js.error')}
        description={I18n.t('js.an_error_occured_with_this_chart')}
        icon={<Icon icon="warning-sign" intent={Intent.DANGER} iconSize={50} />}
        className="bp3-intent-danger"
      />
    );
  }

  renderUnconfiguredChart(chartObj) {
    return (
      <NonIdealState
        title={I18n.t('js.chart_not_configured')}
        description={I18n.t('js.click_edit_to_configure_this_chart')}
        icon={chartObj.icon}
        className="bp3-text-muted"
      />
    );
  }

  renderNoCharts() {
    return (
      <div className="mosaic-zero-state">
        <NonIdealState
          title={I18n.t('js.no_charts_added')}
          description={I18n.t('js.add_charts_with_the_buttons_above')}
          icon="chart"
          className="bp3-text-muted"
        />
      </div>
    );
  }

  renderChartConfigurations() {
    const { activeChart } = this.props.store;
    if (activeChart) {
      switch (activeChart.type) {
        case 'line':
          return <ChartLineConfiguration />;
        case 'bar':
          return <ChartBarConfiguration />;
        case 'pie':
        case 'polar':
          return <ChartPieConfiguration />;
        case 'radar':
          return <ChartRadarConfiguration />;
        case 'bubble':
          return <ChartBubbleConfiguration />;
        case 'metric':
          return <ChartMetricConfiguration />;
      }
    }
    return null;
  }

  renderDrawer() {
    const { activeChart } = this.props.store;

    if (activeChart) {
      return (
        <form onSubmit={this.handleSaveChanges.bind(this)}>
          <Drawer
            portalContainer={document.body}
            position={Position.RIGHT}
            onClose={this.handleCloseDrawer.bind(this)}
            isOpen={this.state.drawerOpen}
            icon={activeChart.icon}
            title={I18n.t('js.chart_configuration', { type: activeChart.type })}
          >
            <Flexbox className={Classes.DRAWER_BODY} flexDirection="column" padding="15px" flexGrow={1}>
              <ChartCommonConfig />
              {this.renderChartConfigurations()}
            </Flexbox>
            <Flexbox className={Classes.DRAWER_FOOTER} flexDirection="column" padding="15px">
              <Flexbox flexDirection="row">
                <Button
                  text={I18n.t('js.save_changes')}
                  type="submit"
                  intent={Intent.PRIMARY}
                  icon="floppy-disk"
                  className="push-5-r"
                  onClick={this.handleSaveChanges.bind(this)}
                ></Button>
                <Button text={I18n.t('js.cancel_changes')} icon="cross" onClick={this.handleCancelChanges.bind(this)}></Button>
              </Flexbox>
            </Flexbox>
          </Drawer>
        </form>
      );
    }
    return null;
  }

  render() {
    const { currentChartLayout, reportCharts, displayedTableData } = this.props.store;
    Chart.defaults.global.defaultFontFamily = 'Inter';
    if (Cookies.get('theme') == 'dark') {
      Chart.defaults.global.defaultFontColor = '#fff';
    }

    let availableHeight = '600';
    if (reportCharts.length > 3) {
      availableHeight = 600 + (reportCharts.length - 3) * 200;
    }
    if (reportCharts.length > 0) {
      return (
        <Flexbox flexDirection="column" width="100%" height={`${availableHeight}px`}>
          <Mosaic
            renderTile={(id, path) => this.renderChartWindow(id, path)}
            value={currentChartLayout}
            onChange={this.handleMosaicChange.bind(this)}
            zeroStateView={this.renderNoCharts()}
          />
          {this.renderDrawer()}
        </Flexbox>
      );
    }
    return null;
  }
}
