/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  AnchorButton,
  Classes,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Icon,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ElementTitle from './elements/ElementTitle';
import ElementDescription from './elements/ElementDescription';
import ElementProfileValue from './elements/ElementProfileValue';
import ElementRichTextareaDisplay from './elements/ElementRichTextareaDisplay';
import ElementImage from './elements/ElementImage';
import ElementHyperlink from './elements/ElementHyperlink';
import ElementFileDownload from './elements/ElementFileDownload';
import ElementTextInput from './elements/ElementTextInput';
import ElementTextarea from './elements/ElementTextarea';
import ElementRichTextarea from './elements/ElementRichTextarea';
import ElementEmail from './elements/ElementEmail';
import ElementSelect from './elements/ElementSelect';
import ElementGrid from './elements/ElementGrid';
import ElementObjectSelect from './elements/ElementObjectSelect';
import ElementNumber from './elements/ElementNumber';
import ElementCurrency from './elements/ElementCurrency';
import ElementPercentage from './elements/ElementPercentage';
import ElementSlider from './elements/ElementSlider';
import ElementDate from './elements/ElementDate';
import ElementMultiline from './elements/ElementMultiline';
import ElementFileInput from './elements/ElementFileInput';
import ElementImageUpload from './elements/ElementImageUpload';
import ElementHyperlinkInput from './elements/ElementHyperlinkInput';
import ElementLocationSelect from './elements/ElementLocationSelect';
import ElementCalculation from './elements/ElementCalculation';
import classNames from 'classnames';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class TemplateElement extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = {
      deletingElement: false,
      isHovering: false,
      isLoading: false,
    };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleDeleting() {
    this.setState({
      deletingElement: !this.state.deletingElement,
    });
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  toggleOptions() {
    this.store.setActiveArea(this.props.element.id);
  }

  toggleHover() {
    this.setState({ isHovering: true });
  }

  removeHover() {
    this.setState({ isHovering: false });
  }

  handleCopy() {
    this.toggleLoading();
    this.props.TemplateActions.copyElement(this.props.element.id)
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleChangeSection(section) {
    const elementObj = {
      id: this.props.element.id,
      section_id: section.id,
      row_order_position: section.elements.length + 1,
    };
    this.props.TemplateActions.changeElement(elementObj)
      .then(() => {
        this.toggleLoading();
        const tempElement = {
          id: this.props.element.id,
          section_id: this.props.element.sectionId,
        };
        this.store.removeElement(tempElement);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleCancel() {
    this.store.activeElement = _.clone(this.store.oldActiveElement);
    this.store.setActiveArea(null);
    this.setState({ isHovering: false });
  }

  handleDelete() {
    this.toggleDeleting();
    this.toggleLoading();
    this.props.TemplateActions.deleteElement(this.props.element.id).catch(() => {
      this.toggleLoading();
    });
  }

  toggleVisibility() {
    const newVisible = !this.props.element.stateElement.visible;
    this.props.element.stateElement.visible = newVisible;
    const stateElementObj = {
      id: this.props.element.stateElement.id,
      visible: newVisible,
    };
    this.props.TemplateActions.changeStateElement(stateElementObj);
  }

  toggleEditability() {
    const newEditable = !this.props.element.stateElement.editable;
    this.props.element.stateElement.editable = newEditable;
    const stateElementObj = {
      id: this.props.element.stateElement.id,
      editable: newEditable,
    };
    this.props.TemplateActions.changeStateElement(stateElementObj);
  }

  togglePrivacy() {
    const newPrivacy = !this.props.element.privateView;
    this.props.element.privateView = newPrivacy;
    const elementObj = {
      id: this.props.element.id,
      private_view: newPrivacy,
    };
    this.props.TemplateActions.changeElement(elementObj);
  }

  toggleProtection() {
    const newProtection = !this.props.element.protectedView;
    this.props.element.protectedView = newProtection;
    const elementObj = {
      id: this.props.element.id,
      protected_view: newProtection,
    };
    this.props.TemplateActions.changeElement(elementObj);
  }

  toggleDisplayOnDashboard() {
    const newDisplay = !this.props.element.displayOnDashboard;
    this.props.element.displayOnDashboard = newDisplay;
    const elementObj = {
      id: this.props.element.id,
      display_on_dashboard: newDisplay,
    };
    this.props.TemplateActions.changeElement(elementObj);
  }

  toggleAccountOwnerOnly() {
    const newAccountOwnerOnlyView = !this.props.element.accountOwnerOnlyView;
    this.props.element.accountOwnerOnlyView = newAccountOwnerOnlyView;
    const elementObj = {
      id: this.props.element.id,
      account_owner_only_view: newAccountOwnerOnlyView,
    };
    this.props.TemplateActions.changeElement(elementObj);
  }

  renderDeleteElement() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.deletingElement}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={this.toggleDeleting.bind(this)}
        confirmButtonText={I18n.t('js.delete_element')}
        onConfirm={this.handleDelete.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_element')}
      </Alert>
    );
  }

  renderVisibleMenu() {
    if (this.props.sectionType === 'app') {
      const { visible } = this.props.element.stateElement;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.visible'))}
          icon={!visible ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleVisibility.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderEditableMenu() {
    if (this.props.sectionType === 'app') {
      if (this.props.element.isDisplayElement) {
        return null;
      }
      const { editable } = this.props.element.stateElement;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.editable'))}
          icon={!editable ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleEditability.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderPrivacyMenu() {
    if (this.props.sectionType === 'profile_template') {
      const { privateView } = this.props.element;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.private'))}
          icon={!privateView ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.togglePrivacy.bind(this)}
          disabled={this.props.sectionPrivateView}
        />
      );
    }
    return undefined;
  }

  renderProtectedMenu() {
    if (this.props.sectionType === 'profile_template') {
      const { protectedView } = this.props.element;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.protected'))}
          icon={!protectedView ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleProtection.bind(this)}
          disabled={this.props.element.isDisplayElement || this.props.sectionPrivateView}
        />
      );
    }
    return undefined;
  }

  renderAccountOwnerOnlyMenu() {
    if (this.props.sectionType === 'profile_template') {
      const { accountOwnerOnlyView } = this.props.element;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.account_owner_only'))}
          icon={!accountOwnerOnlyView ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleAccountOwnerOnly.bind(this)}
          disabled={this.props.element.isDisplayElement || this.props.sectionPrivateView}
        />
      );
    }
    return undefined;
  }

  renderDashboardDisplayMenu() {
    if (this.props.sectionType === 'profile_template') {
      const { displayOnDashboard } = this.props.element;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.display_on_dashboard'))}
          icon={!displayOnDashboard ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleDisplayOnDashboard.bind(this)}
          disabled={!this.props.element.isAllowedToDisplayOnDashboard}
        />
      );
    }
    return undefined;
  }

  renderOptionMenu() {
    if (this.store.activeAreaId === this.props.element.id) {
      return <MenuItem text={I18n.t('js.cancel_changes')} icon="disable" onClick={this.handleCancel.bind(this)} />;
    }
    return (
      <MenuItem
        text={I18n.t('js.edit_element', {
          name: this.props.element.fullTitle.toLowerCase(),
        })}
        icon="edit"
        onClick={this.toggleOptions.bind(this)}
      />
    );
  }

  renderMenu() {
    return (
      <Menu>
        <li className="bp3-menu-header">
          <h6 className="bp3-heading">{I18n.t('js.configure_element')}</h6>
        </li>
        {this.renderOptionMenu()}
        <MenuItem text={I18n.t('js.duplicate_element')} icon="duplicate" onClick={this.handleCopy.bind(this)} />
        <MenuItem text={I18n.t('js.change_section')} icon="remove-row-bottom">
          {_.map(this.store.sections, section => {
            if (this.props.sectionType === 'app') {
              return (
                <MenuItem
                  key={section.id}
                  text={section.title}
                  disabled={section.id === this.props.element.sectionId}
                  icon="widget-button"
                  onClick={() => this.handleChangeSection(section)}
                />
              );
            } else {
              if (this.props.UserProfileTemplateStore.activeProfileTemplate != '') {
                if (section.profileTemplateId == this.props.UserProfileTemplateStore.activeProfileTemplate.id) {
                  return (
                    <MenuItem
                      key={section.id}
                      text={section.title}
                      disabled={section.id === this.props.element.sectionId}
                      icon="widget-button"
                      onClick={() => this.handleChangeSection(section)}
                    />
                  );
                }
              }
            }
          })}
        </MenuItem>
        <li className="bp3-menu-header">
          <h6 className="bp3-heading">{I18n.t('js.visibility_and_control')}</h6>
        </li>
        {this.renderVisibleMenu()}
        {this.renderEditableMenu()}
        {this.renderPrivacyMenu()}
        {this.renderProtectedMenu()}
        {this.renderAccountOwnerOnlyMenu()}
        {this.renderDashboardDisplayMenu()}
        <MenuDivider />
        <MenuItem text={I18n.t('js.delete_element')} icon="trash" onClick={this.toggleDeleting.bind(this)} className="bp3-intent-danger" />
      </Menu>
    );
  }

  renderRightButtons() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexDirection="column" marginLeft="10px" marginRight="10px">
          <AnchorButton className="bp3-minimal" icon="more" loading={true} />
        </Flexbox>
      );
    }
    if (this.state.isHovering && this.props.element.stateElement.visible) {
      return (
        <Flexbox flexDirection="column" marginLeft="10px" marginRight="10px">
          <Popover
            portalContainer={document.body}
            content={this.renderMenu()}
            position="bottom"
            interactionKind={PopoverInteractionKind.CLICK}
            inline={true}
            autoFocus={false}
          >
            <AnchorButton className="bp3-minimal" icon="more" />
          </Popover>
        </Flexbox>
      );
    }
    return <Flexbox width="50px" />;
  }

  renderLeftButtons() {
    if (this.state.isHovering && this.props.element.stateElement.visible) {
      return (
        <Flexbox flexDirection="column" marginLeft="10px" marginRight="10px">
          {this.props.dragHandle}
        </Flexbox>
      );
    }
    return <Flexbox width="50px" />;
  }

  renderElement() {
    const {
      element: { stateElement, elementType },
    } = this.props;
    if (stateElement.visible) {
      switch (elementType) {
        case 'title':
          return (
            <ElementTitle
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'description':
          return (
            <ElementDescription
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'profile_value':
          return (
            <ElementProfileValue
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'rich_textarea_display':
          return (
            <ElementRichTextareaDisplay
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'image':
          return (
            <ElementImage
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'hyperlink':
          return (
            <ElementHyperlink
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'file_download':
          return (
            <ElementFileDownload
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'text_input':
          return (
            <ElementTextInput
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'textarea':
          return (
            <ElementTextarea
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'rich_textarea':
          return (
            <ElementRichTextarea
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'email':
          return (
            <ElementEmail
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'select':
          return (
            <ElementSelect
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'grid':
          return (
            <ElementGrid
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'user_select':
          return (
            <ElementObjectSelect
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'team_select':
          return (
            <ElementObjectSelect
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'item_select':
          return (
            <ElementObjectSelect
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'number':
          return (
            <ElementNumber
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'currency':
          return (
            <ElementCurrency
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'percentage':
          return (
            <ElementPercentage
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'slider':
          return (
            <ElementSlider
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'range_slider':
          return (
            <ElementSlider
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'date':
          return (
            <ElementDate
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'date_range':
          return (
            <ElementDate
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'date_time':
          return (
            <ElementDate
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'time':
          return (
            <ElementDate
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'multiline':
          return (
            <ElementMultiline
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'file_upload':
          return (
            <ElementFileInput
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'image_upload':
          return (
            <ElementImageUpload
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'hyperlink_input':
          return (
            <ElementHyperlinkInput
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'location_select':
          return (
            <ElementLocationSelect
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        case 'calculation':
          return (
            <ElementCalculation
              element={this.props.element}
              sectionType={this.props.sectionType}
              sectionPrivateView={this.props.sectionPrivateView}
              sectionProtectedView={this.props.sectionProtectedView}
              sectionAccountOwnerOnlyView={this.props.sectionAccountOwnerOnlyView}
              handleCancel={this.handleCancel.bind(this)}
              toggleOptions={this.toggleOptions.bind(this)}
            />
          );
        default:
          throw new Error(`Element type not accounted for: ${elementType}`);
      }
    } else {
      return (
        <Flexbox
          flexDirection="row"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'relative',
          }}
          onClick={this.toggleVisibility.bind(this)}
        >
          <small className="bp3-text-muted">
            {I18n.t('js.hidden_element_click_to_make_visible', {
              name: this.props.element.fullTitle,
            })}
          </small>
          <div className="white-out" />
        </Flexbox>
      );
    }
  }

  render() {
    let elementClass = classNames('template-area');
    if (this.store.activeAreaId === this.props.element.id) {
      elementClass = classNames('template-area template-element-hover');
    }
    if (this.props.sectionPrivateView) {
      elementClass = classNames(elementClass, 'template-area');
    } else {
      if (this.props.element.privateView) {
        elementClass = classNames(elementClass, 'template-area-private');
      }
    }
    return (
      <div onMouseEnter={this.toggleHover.bind(this)} onMouseLeave={this.removeHover.bind(this)}>
        <Flexbox flexDirection="row" flexGrow={1} className={elementClass} minHeight="62px">
          <Flexbox flexDirection="column" justifyContent="center">
            {this.renderLeftButtons()}
          </Flexbox>
          <Flexbox flexGrow={1}>{this.renderElement()}</Flexbox>
          <Flexbox flexDirection="column" justifyContent="center">
            {this.renderRightButtons()}
            {this.renderDeleteElement()}
          </Flexbox>
        </Flexbox>
      </div>
    );
  }
}
