// @flow
import * as React from 'react';
import Flexbox from 'flexbox-react';
import { observer } from 'mobx-react';
import Feature from '../components/landing/Feature';
import LandingHeader from '../components/landing/LandingHeader';
import CTAFooter from '../components/landing/CTAFooter';
import IntroVideo from '../components/landing/IntroVideo';

type FeatureContent = {
  icon: string,
  title: string,
  description: string,
};

const featuresContent: Array<FeatureContent> = [
  {
    icon: 'search-around',
    // $FlowFixMe
    title: I18n.t('js.modular_customisable'),
    description: I18n.t('js.modular_customisable_description'),
  },
  {
    icon: 'key',
    title: I18n.t('js.single_sign_on'),
    description: I18n.t('js.single_sign_on_description'),
  },
  {
    icon: 'layout-hierarchy',
    title: I18n.t('js.role_based_access_controls'),
    description: I18n.t('js.role_based_access_controls_description'),
  },
  {
    icon: 'join-table',
    title: I18n.t('js.integrations'),
    description: I18n.t('js.integrations_description'),
  },
  {
    icon: 'lock',
    title: I18n.t('js.security'),
    description: I18n.t('js.security_description'),
  },
  {
    icon: 'star',
    title: I18n.t('js.gdpr'),
    description: I18n.t('js.gdpr_description'),
  },
];

@observer
class IndexView extends React.Component<any> {
  render() {
    return (
      <div>
        <LandingHeader />
        <Flexbox
          flexDirection="column"
          alignContent="center"
          justifyContent="center"
          className="learn-more-container"
        >
          <img
            alt="peoplegoal logo"
            src={I18n.t(`js.pg_logo_black`)}
            width="50px"
            height="50px"
          />
        </Flexbox>
        <Flexbox justifyContent="center">
          <h2 style={{ fontSize: `40px` }}>
            {I18n.t(`js.enterprise_ready_hr`)}
          </h2>
        </Flexbox>
        <CTAFooter />
      </div>
    );
  }
}

export default IndexView;
