/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class AuthorizedAccountRequest {
  @observable
  id;
  @observable
  accountId;
  @observable
  accountSubdomain;
  @observable
  authorizedAccountId;
  @observable
  approved;
  @observable
  approvedBy;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;
  @observable
  userId;

  constructor(
    id,
    accountId,
    accountSubdomain,
    authorizedAccountId,
    approved,
    approvedBy,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy,
    userId
  ) {
    this.id = id || '';
    this.accountId = accountId || '';
    this.accountSubdomain = accountSubdomain || '';
    this.authorizedAccountId = authorizedAccountId || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof approved === 'undefined' ? (this.approved = false) : (this.approved = approved);
    this.approvedBy = approvedBy || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.userId = userId || '';
  }

  @computed
  get accountNameValid() {
    if (this.accountName.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get accountNameError() {
    if (this.accountName.length < 1) {
      return I18n.t('js.account_name_is_required');
    }
    return null;
  }

  @computed
  get accountSubdomainValid() {
    if (this.accountSubdomain.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get accountSubdomainError() {
    if (this.accountSubdomain.length < 1) {
      return I18n.t('js.account_subdomain_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
