import React from 'react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Tab, Tabs } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import WorkspaceFolderItem from './WorkspaceFolderItem';
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import classNames from 'classnames';
import _ from 'lodash';

const DragHandle = sortableHandle(() => <AnchorButton className="bp3-small bp3-cursor-move bp3-minimal" icon="drag-handle-vertical" />);

const SortableItem = sortableElement(({ folder }) => <WorkspaceFolderItem folder={folder} dragHandle={<DragHandle />} />);

const SortableArea = sortableContainer(({ folders }) => (
  <Flexbox flexDirection="column" padding="0px">
    {folders.map(folder => (
      <SortableItem key={folder.id} index={folders.indexOf(folder)} folder={folder} />
    ))}
  </Flexbox>
));

@inject('WorkspaceStore', 'AppStore')
@observer
export default class WorkspaceFolders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      isAdding: false,
      isHovering: false,
    };
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  toggleAdding() {
    this.setState({ isAdding: !this.state.isAdding });
  }

  addHover() {
    this.setState({ isHovering: true });
  }

  removeHover() {
    this.setState({ isHovering: false });
  }

  handleTabChange(tabId) {
    this.props.WorkspaceStore.setActiveFolderId(tabId);
  }

  handleAddFolder() {
    this.toggleAdding();
    const folderObj = {
      workspace_id: this.props.WorkspaceStore.activeWorkspace.id,
      name: I18n.t('js.new_folder'),
    };
    this.props.WorkspaceStore.createWorkspaceFolder(folderObj)
      .then(response => {
        this.props.WorkspaceStore.activeFolderId = response.data.id;
        this.toggleAdding();
      })
      .catch(() => {
        this.toggleAdding();
      });
  }

  handleSortEnd(folder, newIndex) {
    const folderObj = {
      id: folder.id,
      row_order_position: newIndex,
    };
    this.props.WorkspaceStore.changeWorkspaceFolder(folderObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the folder
    const findFolder = this.props.WorkspaceStore.workspaceFolders[oldIndex];

    // Reorder the current UI
    const newFolders = arrayMove(this.props.WorkspaceStore.workspaceFolders.slice(), oldIndex, newIndex);
    this.props.WorkspaceStore.workspaceFolders = newFolders;

    // Process reorder on server
    this.handleSortEnd(findFolder, newIndex);
  }

  renderUncategorizedFolders() {
    if (this.props.AppStore.hasUncategorizedApps) {
      let tagClass = classNames('bp3-tag bp3-minimal bp3-round bp3-tag-fixed');
      // lazy fix for legacy code
      // eslint-disable-next-line no-magic-numbers
      if (this.props.WorkspaceStore.activeFolderId === 9999) {
        tagClass = classNames('bp3-tag bp3-intent-primary bp3-round bp3-tag-fixed');
      }
      return (
        <Tab id={9999} className="workspace-folder-tab">
          <Flexbox flexGrow={1}>
            <Flexbox flexGrow={1}>{this.props.WorkspaceStore.uncategorizedFolder.name}</Flexbox>
            <Flexbox flexGrow={0} flexDirection="column" justifyContent="center" alignItems="center"></Flexbox>
          </Flexbox>
        </Tab>
      );
    }
    return undefined;
  }

  renderTabs() {
    if (this.state.editMode) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <SortableArea
            folders={this.props.WorkspaceStore.activeWorkspaceFolders.slice()}
            onSortEnd={this.onSortEnd.bind(this)}
            lockToContainerEdges={true}
            lockAxis="y"
            useDragHandle={true}
          />
        </Flexbox>
      );
    }
    return (
      <Tabs
        id="folders"
        animate={true}
        vertical={true}
        defaultSelectedTabId={this.props.WorkspaceStore.activeFolderId}
        selectedTabId={this.props.WorkspaceStore.activeFolderId}
        onChange={this.handleTabChange.bind(this)}
        className="noselect"
      >
        {_.map(this.props.WorkspaceStore.workspaceFolders, folder => {
          let tagClass = classNames('bp3-tag bp3-minimal bp3-round bp3-tag-fixed');
          if (this.props.WorkspaceStore.activeFolderId === folder.id) {
            tagClass = classNames('bp3-tag bp3-intent-primary bp3-round bp3-tag-fixed');
          }
          if (folder.workspaceId === this.props.WorkspaceStore.activeWorkspace.id) {
            return (
              <Tab key={folder.id} id={folder.id} className="workspace-folder-tab">
                <Flexbox flexGrow={1}>
                  <Flexbox flexGrow={1}>{_.truncate(folder.name, { length: 21 })}</Flexbox>
                  <Flexbox flexGrow={0} flexDirection="column" justifyContent="center" alignItems="center">
                    <span className={tagClass}>{folder.appSize}</span>
                  </Flexbox>
                </Flexbox>
              </Tab>
            );
          }
        })}
        {this.renderUncategorizedFolders()}
      </Tabs>
    );
  }

  renderFolderEdit() {
    if (this.props.WorkspaceStore.activeWorkspace.userPermissionLevel.name === 'owner' && this.state.isHovering) {
      return (
        <AnchorButton
          className="bp3-minimal bp3-small"
          onClick={this.toggleEditMode.bind(this)}
          disabled={this.props.WorkspaceStore.activeWorkspace.userPermissionLevel.name !== 'owner'}
          icon="edit"
          active={this.state.editMode}
        />
      );
    }
    return undefined;
  }

  renderAddFolder() {
    if (this.state.editMode) {
      return (
        <AnchorButton
          icon="plus"
          loading={this.state.isAdding}
          onClick={this.handleAddFolder.bind(this)}
          text={I18n.t('js.add_folder')}
          className="bp3-intent-primary push-10-t"
        />
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" marginRight="20px" onMouseEnter={this.addHover.bind(this)} onMouseLeave={this.removeHover.bind(this)}>
        <Flexbox flexGrow={1}>
          <Flexbox flexGrow={1}>
            <h6 className={`${Classes.HEADING} bp3-text-muted`}>
              <span className="bp3-icon bp3-icon-folder-open push-10-r bp3-text-muted" />
              {I18n.t('js.workspace_folders')}
            </h6>
          </Flexbox>
          <Flexbox flexDirection="column" justifyContent="center" alignItems="center" marginTop="-5px">
            {this.renderFolderEdit()}
          </Flexbox>
        </Flexbox>
        <Flexbox flexGrow={1} flexDirection="row" marginTop="5px">
          {this.renderTabs()}
        </Flexbox>
        <Flexbox flexDirection="row">{this.renderAddFolder()}</Flexbox>
      </Flexbox>
    );
  }
}
