import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent, EditableText } from '@blueprintjs/core';
import _ from 'lodash';
import classNames from 'classnames';

@inject('CurrentUserStore', 'ToastStore', 'AppStore', 'ItemStore', 'ItemActions')
@observer
export default class ItemEditableTitle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit() {
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    const newObj = {
      id: this.props.ItemStore.newItem.id,
      name: this.props.ItemStore.newItem.name,
    };
    this.props.ItemActions.changeItem(newObj)
      .then(response => {
        this.props.ItemStore.addItem(response.data);
        this.toggleLoading();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(value) {
    this.props.ItemStore.updateItem(value, 'name');
  }

  handleCancel() {
    this.props.ItemStore.resetItemAttribute('name');
    this.toggleOpen();
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.ItemStore.newItem[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.ItemStore.newItem[`${field}Error`]}</div>;
    }
    return undefined;
  }

  render() {
    return (
      <h2 className={`${Classes.HEADING} bp3-label-no-margin`}>
        <EditableText
          multiline={true}
          className={`${Classes.HEADING} editable-item-title`}
          placeholder={I18n.t('js.name')}
          defaultValue={this.props.ItemStore.newItem.name}
          value={this.props.ItemStore.newItem.name}
          onChange={this.handleInputChange.bind(this)}
          onBlur={this.handleBlurChange.bind(this)}
          onConfirm={this.handleSubmit.bind(this)}
          selectAllOnFocus={true}
          disabled={!this.props.ItemStore.userIsOwner}
        />
      </h2>
    );
  }
}
