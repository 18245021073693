import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { NonIdealState, AnchorButton, Intent } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import AppStore from '../stores/AppStore';
import CurrentUserStore from '../stores/CurrentUserStore';

@observer
export default class ItemAccessDeniedView extends React.Component {
  render() {
    const stores = {
      AppStore,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
          <Flexbox className="fixed-width-container" flexDirection="column" flexGrow={1} justifyContent="flex-start" marginTop="20px">
            <Flexbox flexDirection="column" marginTop="40px">
              <Flexbox flexGrow={1} flexDirection="column">
                <Flexbox flexDirection="row" flexGrow={1}>
                  <NonIdealState
                    title={I18n.t('js.item_locked')}
                    description={I18n.t('js.item_locked_description')}
                    icon="lock"
                    className="animated rubberBand"
                  />
                </Flexbox>
                <Flexbox flexDirection="row" justifyContent="center" marginTop="20px">
                  <AnchorButton icon="arrow-left" text={I18n.t('js.dashboard')} href="/dashboard" intent={Intent.SUCCESS} />
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Provider>
    );
  }
}
