/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

export default class Item {
  @observable id;
  @observable appId;
  @observable stateId;
  @observable userId;
  @observable statusFlagId;
  @observable name;
  @observable itemType;
  @observable itemIcon;
  @observable itemColor;
  @observable itemLayout;
  @observable imageFileName;
  @observable commentCount;
  @observable isDoneForCurrentUser;
  @observable isWebform;
  @observable importId;
  @observable webformUserEmail;
  @observable webformUserFullName;
  @observable webformUserImageFileName;
  @observable dataview;
  @observable simpleDataview;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    appId,
    stateId,
    userId,
    statusFlagId,
    name,
    itemType,
    itemIcon,
    itemColor,
    itemLayout,
    imageFileName,
    commentCount,
    isDoneForCurrentUser,
    isWebform,
    importId,
    webformUserEmail,
    webformUserFullName,
    webformUserImageFileName,
    dataview,
    simpleDataview,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.appId = appId || '';
    this.stateId = stateId || '';
    this.userId = userId || '';
    this.statusFlagId = statusFlagId || '';
    this.name = name || '';
    this.itemType = itemType || '';
    this.itemIcon = itemIcon || '';
    this.itemColor = itemColor || '';
    this.itemLayout = itemLayout || '';
    this.imageFileName = imageFileName || '';
    this.commentCount = commentCount || 0;
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof isDoneForCurrentUser === 'undefined' || isDoneForCurrentUser === null
      ? (this.isDoneForCurrentUser = false)
      : (this.isDoneForCurrentUser = isDoneForCurrentUser);
    // eslint-disable-next-line no-unused-expressions
    typeof isWebform === 'undefined' || isWebform === null ? (this.isWebform = false) : (this.isWebform = isWebform);
    this.importId = importId || '';
    this.webformUserEmail = webformUserEmail || '';
    this.webformUserFullName = webformUserFullName || '';
    this.webformUserImageFileName = webformUserImageFileName || '';
    this.dataview = dataview || '';
    this.simpleDataview = simpleDataview || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get imageFileNameWidget() {
    return _.replace(this.imageFileName, 't_profile_image', 't_image_widget');
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get hasWebformUserData() {
    return this.webformUserEmail !== '' && this.webformUserFullName !== '' && this.webformUserImageFileName !== '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get createdAtToShortDate() {
    return moment(this.createdAt).format('MM/DD/YYYY');
  }
  @computed
  get updatedAtToShortDate() {
    return moment(this.updatedAt).format('MM/DD/YYYY');
  }
}
