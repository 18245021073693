/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import NProgress from 'nprogress';
import _ from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';

// models
import User from '../models/UserModel';
import AuthorizedAccount from '../models/AuthorizedAccountModel';
import Team from '../models/TeamModel';
import UserRelationship from '../models/UserRelationshipModel';
import UserProfileStore from './UserProfileStore';

class CurrentUserStore {
  @observable currentUser = new User();
  @observable activeTab = 'dashboard';
  @observable authorizedAccounts = [];
  @observable teams = [];
  @observable userRelationships = [];
  @observable userSignedIn = false;
  @observable userIsAccountOwner = false;
  @observable userIsLeavingAccount = false;
  @observable isLoading = true;
  @observable sortParam = 'accountName';
  @observable sortDir = 'asc';
  @observable activeAccount = new AuthorizedAccount();
  @observable isSuperAdmin = Cookies.get('super_admin');
  @observable filteredLanguages = [];
  @observable activeLanguage = '';
  @observable availableLanguages = [
    {
      code: 'gb',
      locale: 'en',
      name: I18n.t('js.english'),
    },
    {
      code: 'fr',
      locale: 'fr',
      name: I18n.t('js.french'),
    },
    {
      code: 'it-IT',
      locale: 'it-IT',
      name: I18n.t('js.italian'),
    },
    {
      code: 'de',
      locale: 'de',
      name: I18n.t('js.german'),
    },
    {
      code: 'ko-KR',
      locale: 'ko-KR',
      name: I18n.t('js.korean'),
    },
    {
      code: 'es',
      locale: 'es',
      name: I18n.t('js.spanish'),
    },
    {
      code: 'pt',
      locale: 'pt',
      name: I18n.t('js.portuguese'),
    },
    {
      code: 'gr',
      locale: 'el',
      name: I18n.t('js.greek'),
    },
    {
      code: 'jp',
      locale: 'ja',
      name: I18n.t('js.japanese'),
    },
    {
      code: 'cn',
      locale: 'zh',
      name: I18n.t('js.chinese'),
    },
  ];

  constructor() {
    I18n.locale = Cookies.get('locale');
  }

  @computed
  get hasUserData() {
    return this.currentUser.id.length > 0;
  }

  @computed
  get hasAccount() {
    return this.authorizedAccounts.length > 0;
  }

  @action
  setAxiosAuthorizationHeader() {
    const idToken = Cookies.get('access_token');
    axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  }

  @action
  setNProgressLoader() {
    // const calculatePercentage = (loaded, total) =>
    //   Math.floor(Number(loaded)) / total;
    // const setupUpdateProgress = () => {
    //   axios.defaults.onDownloadProgress = e => {
    //     const percentage = calculatePercentage(e.loaded, e.total);
    //     NProgress.set(percentage);
    //   };
    // };
    // const setupStopProgress = () => {
    //   axios.interceptors.response.use(response => {
    //     NProgress.done(true);
    //     return response;
    //   });
    // };
    // setupUpdateProgress();
    // setupStopProgress();
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  toggleIsLeaving() {
    this.userIsLeavingAccount = !this.userIsLeavingAccount;
  }

  @action
  addUser(data) {
    this.currentUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
  }

  @action
  addAuthorizedAccount(data) {
    let newImage = '';
    if (data.account_image !== null) {
      newImage = data.account_image;
    } else {
      newImage = I18n.t(`js.pg_logo_black`);
    }
    const newAuthorizedAccount = new AuthorizedAccount(
      data.id,
      data.account_id,
      data.account_name,
      newImage,
      data.account_owner,
      data.account_dummy_data,
      data.account_enterprise,
      data.account_subdomain,
      data.active_account,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.last_active,
      data.updated_at,
      data.updated_by,
      data.user_id,
      data.profile_template_id
    );
    this.authorizedAccounts.push(newAuthorizedAccount);
    this.checkActiveAccount(newAuthorizedAccount);
    this.checkAccountOwner(newAuthorizedAccount);
    this.sortAuthorizedAccounts();
  }

  @action
  addTeam(data) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.teams = _.unionBy([newTeam], this.teams, 'id');
  }

  @action
  addUserRelationship(data) {
    const newUserRelationship = new UserRelationship(
      data.id,
      data.user_id,
      data.user,
      data.counterpart_user_id,
      data.counterpart_user,
      data.relationship_id,
      data.relationship_name,
      data.relationship_counterpart_name,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );
    this.userRelationships = _.unionBy([newUserRelationship], this.userRelationships, 'id');
  }

  @action
  checkAccountOwner(authorizedAccount) {
    if (authorizedAccount.accountOwner && this.currentUser.accountId === authorizedAccount.accountId) {
      this.userIsAccountOwner = true;
    }
  }

  @action
  checkActiveAccount(authorizedAccount) {
    if (authorizedAccount.activeAccount) {
      this.activeAccount = authorizedAccount;
    }
  }

  @action
  fetchCurrentUser(userId) {
    if (userId !== null) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/users/${userId}`)
          .then(response => {
            this.addUser(response.data);
            this.userSignedIn = true;
            this.setActiveLanguage(response.data.locale);
            this.fetchAuthorizedAccounts()
              .then(res => {
                resolve(res);
                this.isLoading = false;
              })
              .catch(error => {
                this.isLoading = false;
                const errors = error.response.data.error.join(', ');
                ToastStore.showToast(errors, 'danger');
              });
          })
          .catch(error => {
            reject(error);
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            this.isLoading = false;
          });
      });
    }
    this.isLoading = false;
    const newLocale = Cookies.get('locale') || 'en';
    this.setActiveLanguage(newLocale);
    return undefined;
  }

  @action
  fetchAuthorizedAccounts() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/authorized_accounts`)
        .then(response => {
          this.authorizedAccounts = [];
          _.map(response.data, authorizedAccount => {
            this.addAuthorizedAccount(authorizedAccount);
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchTeams() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_teams`, {
          params: { user_id: UserProfileStore.user.id },
        })
        .then(response => {
          response.data.map(userTeam => this.addTeam(userTeam.team));
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchUserRelationships(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_relationships/`, {
          params: { user_id: userId },
        })
        .then(response => {
          response.data.map(userRelationshipData => this.addUserRelationship(userRelationshipData));
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  getUserRelationshipsWith(relationshipId) {
    return this.userRelationships.filter(userRelationship => userRelationship.relationshipId === relationshipId);
  }

  @action
  switchAccount = accountId =>
    new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/users/${this.currentUser.id}`, { account_id: accountId })
        .then(res => {
          window.location = '/dashboard';
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });

  @action
  leaveCurrentAccount() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/authorized_accounts?id=${this.activeAccount.id}&user_id=${this.currentUser.id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  setActiveLanguage(locale) {
    _.map(this.availableLanguages, language => {
      if (locale === language.locale) {
        this.activeLanguage = language;
        const filter = _.filter(this.availableLanguages, o => o.locale !== locale);
        this.filteredLanguages = filter;
      }
    });
    this.updateUserLocale(this.currentUser.id).catch(() => {
      throw new Error('failed to update developer locale');
    });
  }

  @action
  setTheme(theme) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/users/${this.currentUser.id}`, {
          theme: theme,
        })
        .then(response => {
          resolve(response);
          this.addUser(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  updateUserLocale(userId) {
    return new Promise((resolve, reject) => {
      if (this.userSignedIn) {
        if (this.activeLanguage.locale !== this.currentUser.locale) {
          axios
            .put(`${Cookies.get('apiEnv')}/users/${userId}`, {
              locale: this.activeLanguage.locale,
            })
            .then(response => {
              resolve(response);
              this.addUser(response.data);
              Cookies.set('locale', this.activeLanguage.locale);
              I18n.locale = Cookies.get('locale');
            })
            .catch(error => {
              reject(error);
              const errors = error.response.data.error.join(', ');
              ToastStore.showToast(errors, 'danger');
            });
        }
      } else {
        if (this.activeLanguage.locale !== Cookies.get('locale')) {
          Cookies.set('locale', this.activeLanguage.locale);
          I18n.locale = Cookies.get('locale');
          resolve();
        }
      }
      resolve();
    });
  }

  @action
  sortAuthorizedAccounts() {
    let newAuthorizedAccounts = this.authorizedAccounts;
    newAuthorizedAccounts = _.orderBy(newAuthorizedAccounts, [this.sortParam], [this.sortDir]);
    this.authorizedAccounts = newAuthorizedAccounts;
  }
}

const store = new CurrentUserStore();
export default store;
