const trace = o => console.log('o: ', o) || o;
/**
 * Takes a string in camelCase and converts it to
 * Split Pascal case.
 */
const camelToSplitPascal = s =>
  s
    .split('')
    .map(c => (c === c.toUpperCase() ? ' '.concat(c) : c))
    .map((c, i) => (i === 0 ? c.toUpperCase() : c))
    .reduce((acc, c) => acc.concat(c), '');

export default camelToSplitPascal;
