/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import Utilities from '../utils/Utilities';
import { observer, Provider } from 'mobx-react';
import AuthNews from '../components/auth/AuthNews';
import { Tooltip } from '@blueprintjs/core';
import AdminActions from '../actions/AdminActions';
import ToastStore from '../stores/ToastStore';
import AdminStore from '../stores/AdminStore';
import AuthStore from '../stores/AuthStore';
import SignUpNew from '../components/auth/SignUpNew';
import SignInNew from '../components/auth/SignInNew';
import ForgotPassword from '../components/auth/ForgotPassword';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class SignUpView extends React.Component {
  componentDidMount() {
    if (this.props.activeTab) {
      AuthStore.updateActiveTab(this.props.activeTab);
    }
  }

  renderAuth0() {
    return (
      <Flexbox flexDirection="column" alignItems="center" className="push-20-t">
        <Tooltip portalContainer={document.body} content={I18n.t('js.user_identiy_management_provided_by_auth0')} position="bottom">
          <a className="bp3-text-muted" href="https://auth0.com/" target="_blank" rel="noopener noreferrer">
            <img
              src="https://res.cloudinary.com/dkctgehrc/image/upload/c_scale,e_grayscale,o_15,w_30/v1500995099/auth0_badge_bm737h.png"
              alt=""
              title=""
            />
          </a>
        </Tooltip>
      </Flexbox>
    );
  }

  renderLinks() {
    if (this.props.action === 'user_sign_up') {
      return (
        <Flexbox flexDirection="column" alignItems="center" className="push-10-t">
          <a className="bp3-text-muted" href="/sign-in/non-sso">
            {I18n.t('js.already_have_an_account')}
          </a>
        </Flexbox>
      );
    }

    if (this.props.action === 'user_sign_in') {
      if (AuthStore.activeTab === 'email') {
        return (
          <Flexbox flexDirection="column" alignItems="center" className="push-10-t">
            <a className="bp3-text-muted" href="/sign-up/non-sso">
              {I18n.t('js.create_a_new_account')}
            </a>
            <a className="bp3-text-muted" href="/forgot-password">
              {I18n.t('js.forgot_your_password')}
            </a>
          </Flexbox>
        );
      }
      if (AuthStore.activeTab !== 'sso') {
        return (
          <Flexbox flexDirection="column" alignItems="center" className="push-10-t">
            <a className="bp3-text-muted" href="/sign-up/non-sso">
              {I18n.t('js.create_a_new_account')}
            </a>
          </Flexbox>
        );
      }
    }

    if (this.props.action === 'forgot_password') {
      return (
        <Flexbox flexDirection="column" alignItems="center" className="push-20-t">
          <a className="bp3-text-muted" href="/sign-up/non-sso">
            {I18n.t('js.create_a_new_account')}
          </a>
          <a className="bp3-text-muted" href="/forgot-password">
            {I18n.t('js.forgot_your_password')}
          </a>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderView() {
    if (this.props.action === 'user_sign_up') {
      return (
        <Flexbox flexBasis="0" flexGrow={1} justifyContent="flex-start" alignItems="stretch" padding="20px" flexDirection="column">
          <SignUpNew /> {this.renderLinks()}
          {this.renderAuth0()}
        </Flexbox>
      );
    }

    if (this.props.action === 'user_sign_in') {
      return (
        <Flexbox flexBasis="0" flexGrow={1} justifyContent="flex-start" alignItems="stretch" padding="20px" flexDirection="column">
          <SignInNew /> {this.renderLinks()}
          {this.renderAuth0()}
        </Flexbox>
      );
    }

    if (this.props.action === 'forgot_password') {
      return (
        <Flexbox flexBasis="0" flexGrow={1} justifyContent="flex-start" padding="20px" flexDirection="column">
          <ForgotPassword /> {this.renderLinks()}
          {this.renderAuth0()}
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      AdminActions,
      AdminStore,
      AuthStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox
            flexGrow={1}
            className="fixed-width-container-original"
            alignContent="stretch"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop="50px"
            marginBottom="50px"
          >
            <Flexbox className="animated fadeIn" flexDirection="row" alignSelf="center" justifyContent="center">
              {/* ignore as functions like a regular link */}
              {/* eslint-disable-next-line */}
              <a
                onClick={() => {
                  Utilities.navigate('/');
                }}
              >
                <img alt="logo" src={I18n.t('js.pg_logo_black')} className="auth-view-logo" />
              </a>
            </Flexbox>
            <Flexbox className="bp3-card" flexDirection="row" alignSelf="center">
              {this.renderView()}
              <AuthNews />
            </Flexbox>
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
