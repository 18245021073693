import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent, Switch } from '@blueprintjs/core';
import _ from 'lodash';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsDetailsAccountEnterprise extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      newAccount: null,
    };
  }

  componentDidMount() {
    this.setState({ newAccount: this.props.account });
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.props.AccountSettingsStore.changeAccountEnterprise(this.state.newAccount)
      .then(() => {
        this.toggleLoading();
        this.toggleOpen();
        this.props.ToastStore.showToast(I18n.t('js.account_updated'), 'success');
        location.reload();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleEnterpriseChange() {
    const account = _.clone(this.state.newAccount);
    account.is_enterprise = !account.is_enterprise;
    this.setState({ newAccount: account });
  }

  handleCancel() {
    this.setState({ newAccount: this.props.account });
    this.toggleOpen();
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              Enterprise Account
            </h4>
            <p className="noselect">Set this account as enterprise.</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        {this.state.newAccount && (
          <Collapse isOpen={this.state.isOpen}>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <Flexbox flexDirection="column">
                <div className="bp3-form-group">
                  <Flexbox flexDirection="column" marginBottom="10px">
                    <Switch
                      className="bp3-control-no-margin"
                      checked={this.state.newAccount.is_enterprise}
                      label="Enterprise account"
                      onChange={this.handleEnterpriseChange.bind(this)}
                    />
                  </Flexbox>
                  <div className="push-10-t">
                    <Button
                      intent={Intent.PRIMARY}
                      type="submit"
                      text={I18n.t('js.save_changes')}
                      loading={this.state.isSubmitting}
                      icon="tick"
                    />
                    <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
                  </div>
                </div>
              </Flexbox>
            </form>
          </Collapse>
        )}
      </Flexbox>
    );
  }
}
