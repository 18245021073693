import { action, observable, computed } from 'mobx';
import React from 'react';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import CurrentUserStore from './CurrentUserStore';
import ToastStore from './ToastStore';
import Cookies from 'js-cookie';
import axios from 'axios';
import Relationship from '../models/RelationshipModel';
import User from '../models/UserModel';
import { Spinner, Tag, Icon, Tooltip, Checkbox } from '@blueprintjs/core';
import StatusTag from '../components/shared/StatusTag';
import Paginator from '../models/PaginatorModel';
import ItemActions from '../actions/ItemActions';
import _ from 'lodash';
import Utilities from '../utils/Utilities';
import moment from 'moment';

class DynamicViewStore {
  @observable activeTab = 'home';
  @observable viewArea = 'dashboard';
  @observable isLoading = false;
  @observable isLoadingAllItems = false;
  @observable isLoadingParticipants = false;
  @observable isLoadingStatusFlags = false;
  @observable isLoadingItemsAsOwner = false;
  @observable usersLoading = [];
  @observable teamsLoading = [];
  @observable activeRelationship = null;
  @observable relationships = [];
  @observable userRelationships = [];
  @observable userTeams = [];
  @observable expandedUsers = [];
  @observable expandedTeams = [];
  @observable activeApp = null;
  @observable participants = [];
  @observable statusFlags = [];
  @observable states = [];
  @observable activeParticipant = null;
  @observable activeStatusFlag = null;
  @observable selectedUserId = null;
  @observable selectedTeamId = null;
  @observable filteredUser = '';
  @observable items = [];
  @observable teams = [];
  @observable itemLayout = '';
  @observable selectedItems = [];
  @observable itemPagination = new Paginator();
  @observable canMultiSelect = true;

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.itemPagination = newPagination;
  }

  @action toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action setActiveApp(app) {
    this.activeParticipant = null;
    this.activeStatusFlag = null;
    this.participants = [];
    this.items = [];
    this.statusFlags = [];
    this.states = [];

    if (app === undefined) {
      this.activeApp = null;
    } else {
      this.activeApp = app;
      this.itemLayout = app.itemLayout;
      this.fetchParticipantsForActiveApp();
      this.fetchStatusFlagsForActiveApp();
      this.fetchStatesForActiveApp();
    }
    _.map(this.expandedUsers, userId => {
      this.fetchItemsForUser(userId);
    });
  }

  @action selectItem(item) {
    const findItem = _.find(this.selectedItems, o => o.id == item.id);
    if (findItem) {
      this.selectedItems = _.filter(this.selectedItems, o => o.id != item.id);
    } else {
      this.selectedItems = _.unionBy([item], this.selectedItems, 'id');
    }
  }

  @action setActiveTab(tab) {
    this.activeTab = tab;
    this.items = [];
    this.selectedItems = [];
    this.expandedUsers = [];
    this.expandedTeams = [];
    this.selectedUserId = null;
    this.selectedTeamId = null;
    this.filteredUser = '';
    switch (tab) {
      case 'your-items':
        this.loadCurrentUser();
        break;
      case 'your-colleagues':
        this.loadAllColleagues();
        break;
      case 'your-teams':
        this.loadTeams();
        break;
      case 'all-items':
        this.loadAllItems();
    }
  }

  @action setFilteredUser(user) {
    this.expandedUsers = [];
    this.filteredUser = user;
    if (user !== '') {
      this.selectedUserId = user.id;
      this.toggleUser(user.id);
    }
  }

  @action clearSelected() {
    this.selectedItems = [];
  }

  @action selectAllVisible() {
    if (this.activeParticipant) {
      const filteredItems = this.getActiveParticipantItems();
      this.selectedItems = this.filterItemsByStatusFlag(filteredItems);
    } else {
      this.selectedItems = this.filterItemsByStatusFlag(this.items);
    }
  }

  @action setActiveRelationship(relationship) {
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      this.activeRelationship = relationship;
      this.fetchUserRelationships()
        .then(response => {
          resolve(response);
          this.isLoading = false;
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action setActiveParticipant(participant) {
    this.activeParticipant = participant;
  }

  @action setActiveStatusFlag(statusFlag) {
    this.activeStatusFlag = statusFlag;
    if (this.activeTab == 'all-items' || this.activeTab == 'your-items') {
      this.setActiveTab(this.activeTab);
    }
    if (this.activeTab == 'your-colleagues' && statusFlag.is_archived) {
      this.setActiveTab(this.activeTab);
    }
  }

  @action expandAll() {
    if (CurrentUserStore.currentUser.id) {
      if (!this.expandedUsers.includes(CurrentUserStore.currentUser.id)) {
        this.toggleUser(CurrentUserStore.currentUser.id);
      }
      this.expandedUsers.push('subordinates');
      _.map(this.superordinateUsers, user => {
        if (user.id) {
          if (!this.expandedUsers.includes(user.id)) {
            this.toggleUser(user.id);
          }
        }
      });
      _.map(this.subordinateUsers, user => {
        if (user.id) {
          if (!this.expandedUsers.includes(user.id)) {
            this.toggleUser(user.id);
          }
        }
      });
    }
  }

  @action collapseAll() {
    this.expandedUsers = [];
  }

  @action addLeftElement(element) {
    this.itemLayout.leftElements.push(element.column_id);
  }

  @action removeLeftElement(elementId) {
    this.itemLayout.leftElements = _.filter(this.itemLayout.leftElements, o => o !== elementId);
  }

  @computed get selectedLeftElements() {
    const selectedElements = [];
    _.map(this.itemLayout.leftElements, leftElementId => {
      const findElement = _.find(this.availableElements, o => o.column_id === leftElementId);
      if (findElement) {
        selectedElements.push(findElement);
      }
    });
    return selectedElements;
  }

  @action addRightElement(element) {
    this.itemLayout.rightElements.push(element.column_id);
  }

  @action removeRightElement(elementId) {
    this.itemLayout.rightElements = _.filter(this.itemLayout.rightElements, o => o !== elementId);
  }

  @computed get archivedStatusFlag() {
    return _.find(this.statusFlags, o => o.is_archived);
  }

  @computed get selectedRightElements() {
    const selectedElements = [];
    _.map(this.itemLayout.rightElements, leftElementId => {
      const findElement = _.find(this.availableElements, o => o.column_id === leftElementId);
      if (findElement) {
        selectedElements.push(findElement);
      }
    });
    return selectedElements;
  }

  @computed get getSelectedAppId() {
    if (this.activeApp === null) {
      return 'all';
    } else {
      if (this.activeApp) {
        return this.activeApp.id;
      } else {
        return 'all';
      }
    }
  }

  @computed get canExpandAll() {
    return true;
  }

  @computed get canCollapseAll() {
    return this.expandedUsers.length !== 0;
  }

  @computed get defaultRelationship() {
    const defaultRelationship = _.find(this.relationships, o => o.defaultRelationship);
    return defaultRelationship;
  }

  @computed get availableElements() {
    if (this.items.length === 0) {
      return [];
    } else {
      // return dataview attributes except participant type
      const dataview = _.filter(this.items[0].dataview, o => {
        return o.column_id.split('#')[0] != 'item_participant_attributes';
      });
      return dataview;
    }
  }

  @computed get availableUsers() {
    let users = [];
    if (this.activeTab === 'your-colleagues') {
      _.map(this.userRelationships, userRelationship => {
        if (userRelationship.user.id != CurrentUserStore.currentUser.id) {
          users.push(userRelationship.user);
        }
        // users.push(userRelationship.counterpart_user);
      });
    }

    if (this.activeTab === 'your-teams') {
      _.map(this.userTeams, userTeam => {
        if (userTeam.team_id === this.selectedTeamId) {
          users.push(userTeam.user);
        }
      });
    }
    users = _.orderBy(_.uniqBy(users, 'id'), ['full_name'], ['asc']);
    return users;
  }

  @computed get superordinateUsers() {
    let superordinateUsers = [];
    _.map(this.userRelationships, userRelationship => {
      if (userRelationship.counterpart_user.id != CurrentUserStore.currentUser.id) {
        const newUser = new User(
          userRelationship.counterpart_user.id,
          userRelationship.counterpart_user.email,
          userRelationship.counterpart_user.first_name,
          userRelationship.counterpart_user.last_name,
          userRelationship.counterpart_user.full_name,
          userRelationship.counterpart_user.account_id,
          userRelationship.counterpart_user.human_friendly_id,
          userRelationship.counterpart_user.is_current_user,
          userRelationship.counterpart_user.is_account_owner,
          userRelationship.counterpart_user.image_file_name,
          userRelationship.counterpart_user.background_image_file_name,
          userRelationship.counterpart_user.last_sign_in_at,
          userRelationship.counterpart_user.locale,
          userRelationship.counterpart_user.theme,
          userRelationship.counterpart_user.created_at,
          userRelationship.counterpart_user.updated_at,
          userRelationship.counterpart_user.deleted_at,
          userRelationship.counterpart_user.created_by,
          userRelationship.counterpart_user.updated_by,
          userRelationship.counterpart_user.deleted_by
        );
        superordinateUsers.push(newUser);
      }
    });
    superordinateUsers = _.filter(superordinateUsers, o => o.id !== CurrentUserStore.currentUser.id);
    superordinateUsers = _.orderBy(superordinateUsers, ['fullName', 'asc']);
    return superordinateUsers;
  }

  @computed get subordinateUsers() {
    let subordinateUsers = [];
    _.map(this.userRelationships, userRelationship => {
      if (userRelationship.user.id != CurrentUserStore.currentUser.id) {
        const newUser = new User(
          userRelationship.user.id,
          userRelationship.user.email,
          userRelationship.user.first_name,
          userRelationship.user.last_name,
          userRelationship.user.full_name,
          userRelationship.user.account_id,
          userRelationship.user.human_friendly_id,
          userRelationship.user.is_current_user,
          userRelationship.user.is_account_owner,
          userRelationship.user.image_file_name,
          userRelationship.user.background_image_file_name,
          userRelationship.user.last_sign_in_at,
          userRelationship.user.locale,
          userRelationship.user.theme,
          userRelationship.user.created_at,
          userRelationship.user.updated_at,
          userRelationship.user.deleted_at,
          userRelationship.user.created_by,
          userRelationship.user.updated_by,
          userRelationship.user.deleted_by
        );
        subordinateUsers.push(newUser);
      }
    });
    subordinateUsers = _.filter(subordinateUsers, o => o.id !== CurrentUserStore.currentUser.id);
    subordinateUsers = _.orderBy(subordinateUsers, ['fullName', 'asc']);
    return subordinateUsers;
  }

  @computed get itemTree() {
    let tree = [];
    if (this.filteredUser) {
      return this.specificUserTree;
    }
    const filteredItems = this.filterItemsByStatusFlag(this.items);
    _.map(filteredItems, item => {
      const node = {
        id: item.id,
        icon: this.generateItemIcon(item),
        isSelected: this.selectedItems.includes(item),
        label: this.generateItemLabel(item, item.user, 'left'),
        secondaryLabel: this.generateItemLabel(item, item.user, 'right'),
        nodeType: 'item',
      };
      tree.push(node);
    });
    if (tree.length === 0) {
      const node = this.emptyNode();
      tree.push(node);
    }
    return tree;
  }

  @computed get teamTree() {
    if (this.filteredUser === '') {
      let tree = [];
      _.map(this.teams, team => {
        const teamExpanded = this.expandedTeams.includes(team.id);

        const node = {
          id: team.id,
          hasCaret: true,
          isExpanded: teamExpanded,
          icon: (
            <span style={{ marginRight: '10px' }}>
              <Avatar src={team.image_file_name} size={22} round={true} />
            </span>
          ),
          secondaryLabel: (
            <Tag className="push-10-r">
              {team.user_count} {I18n.t('js.users')}
            </Tag>
          ),
          label: (
            <span>
              <h4>{team.name}</h4>
            </span>
          ),
          nodeType: 'team',
          childNodes: teamExpanded ? this.userTeamTree(team) : null,
        };
        tree.push(node);
      });
      if (tree.length === 0) {
        const node = this.emptyNode();
        tree.push(node);
      }
      return tree;
    } else {
      const user = _.find(this.availableUsers, o => o.id === this.filteredUser.id);
      let tree = [this.userNode(user)];
      return tree;
    }
  }

  @computed get relationshipTree() {
    if (this.filteredUser === '') {
      const currentUser = CurrentUserStore.currentUser;
      const currentUserNode = this.userNode(currentUser);

      if (this.activeTab === 'your-items' || this.activeTab == 'your-items') {
        let tree = [currentUserNode];
        return tree;
      } else {
        let tree = [
          // {
          //   id: 'superordinates',
          //   hasCaret: true,
          //   isExpanded: this.expandedUsers.includes('superordinates'),
          //   icon: 'person',
          //   label: (
          //     <span>
          //       <h4>{this.activeRelationship.name}</h4>
          //     </span>
          //   ),
          //   nodeType: 'relationship',
          //   childNodes: this.expandedUsers.includes('superordinates') ? this.superordinateUsersTree : null,
          // },
          {
            id: 'subordinates',
            hasCaret: true,
            isExpanded: this.expandedUsers.includes('subordinates'),
            icon: 'people',
            label: (
              <span>
                <h4>{this.activeRelationship.counterpartName}</h4>
              </span>
            ),
            nodeType: 'relationship',
            childNodes: this.expandedUsers.includes('subordinates') ? this.subordinateUsersTree : null,
          },
        ];
        if (this.viewArea === 'dashboard') {
          tree.unshift(this.userNode(currentUser));
        }
        return tree;
      }
    } else {
      const user = _.find(this.availableUsers, o => o.id === this.filteredUser.id);
      let tree = [this.userNode(user)];
      return tree;
    }
  }

  @computed get specificUserTree() {
    let tree = [this.userNode(this.filteredUser)];
    return tree;
  }

  @computed get superordinateUsersTree() {
    let tree = [];
    _.map(this.superordinateUsers, user => {
      const node = this.userNode(user);
      tree.push(node);
    });
    if (tree.length === 0) {
      const node = this.emptyNode();
      tree.push(node);
    }
    return tree;
  }

  @computed get subordinateUsersTree() {
    let tree = [];
    _.map(this.subordinateUsers, user => {
      const node = this.userNode(user);
      tree.push(node);
    });
    if (tree.length === 0) {
      const node = this.emptyNode();
      tree.push(node);
    }
    return tree;
  }

  emptyNode() {
    const node = {
      id: Math.random(),
      icon: <Icon icon="warning-sign" className="bp3-text-muted push-10-r" />,
      label: <span className="bp3-text-muted">{I18n.t('js.nothing_to_show')}</span>,
      nodeType: 'empty',
    };
    return node;
  }

  loadingNode() {
    const node = {
      id: Math.random(),
      className: 'bp3-text-muted',
      icon: (
        <span style={{ marginRight: '10px' }}>
          <Spinner size={22} />
        </span>
      ),
      label: I18n.t('js.loading'),
      nodeType: 'loading',
    };
    return node;
  }

  userNode(user) {
    const userExpanded = this.expandedUsers.includes(user.id);

    const node = {
      id: user.id,
      hasCaret: true,
      isExpanded: userExpanded,
      icon: (
        <span style={{ marginRight: '10px' }}>
          <Avatar src={user.imageFileName ? user.imageFileName : user.image_file_name} size={22} round={true} />
        </span>
      ),
      secondaryLabel: <span className={'bp3-text-muted'}>{user.email}</span>,
      label: (
        <span>
          <h4>{user.fullName ? user.fullName : user.full_name}</h4>
        </span>
      ),
      nodeType: 'user',
      childNodes: this.userItemTree(user),
    };
    return node;
  }

  userTeamTree(team) {
    let tree = [];
    if (this.teamsLoading.includes(team.id)) {
      const node = this.loadingNode();
      tree.push(node);
    } else {
      _.map(this.availableUsers, user => {
        const node = this.userNode(user);
        tree.push(node);
      });
      if (tree.length === 0) {
        const node = this.emptyNode();
        tree.push(node);
      }
    }
    return tree;
  }

  userItemTree(user) {
    const childNodes = [];
    if (user) {
      if (this.expandedUsers.includes(user.id)) {
        if (this.usersLoading.includes(user.id)) {
          const node = this.loadingNode();
          childNodes.push(node);
        } else {
          let involvedItems = this.getInvolvedItemsForUser(user);

          if (involvedItems.length > 0) {
            _.map(involvedItems, item => {
              const node = {
                id: item.id,
                icon: this.generateItemIcon(item),
                isSelected: this.selectedItems.includes(item),
                label: this.generateItemLabel(item, item.user, 'left'),
                secondaryLabel: this.generateItemLabel(item, item.user, 'right'),
                nodeType: 'item',
              };
              childNodes.push(node);
            });
          } else {
            const node = this.emptyNode();
            childNodes.push(node);
          }
        }
      }
    }
    return childNodes;
  }

  getInvolvedItemsForUser(user) {
    let involvedItems = [];
    _.map(this.items, item => {
      if (this.activeApp != null) {
        if (item.app_id === this.activeApp.id) {
          _.map(item.item_participants, item_participant => {
            if (item_participant.user_id === user.id) {
              if (this.activeParticipant != null) {
                if (item_participant.participant_id === this.activeParticipant.id) {
                  involvedItems.push(item);
                }
              } else {
                involvedItems.push(item);
              }
            }
          });
          involvedItems = this.filterItemsByStatusFlag(involvedItems);
        }
      } else {
        _.map(item.item_participants, item_participant => {
          if (item_participant.user_id === user.id) {
            involvedItems.push(item);
          }
        });
      }
    });
    involvedItems = _.uniq(involvedItems);
    return involvedItems;
  }

  getActiveParticipantItems() {
    let involvedItems = [];
    _.map(this.items, item => {
      if (this.activeApp != null) {
        if (item.app_id === this.activeApp.id) {
          _.map(item.item_participants, item_participant => {
            if (this.activeParticipant != null) {
              if (item_participant.participant_id === this.activeParticipant.id) {
                if (this.activeTab == 'your-items') {
                  if (item_participant.user_id == this.selectedUserId) {
                    involvedItems.push(item);
                  }
                } else {
                  involvedItems.push(item);
                }
              }
            } else {
              involvedItems.push(item);
            }
          });
          involvedItems = this.filterItemsByStatusFlag(involvedItems);
        }
      } else {
        involvedItems.push(item);
      }
    });
    involvedItems = _.uniq(involvedItems);
    return involvedItems;
  }

  filterItemsByStatusFlag(items) {
    let filteredItems = items;
    if (this.activeStatusFlag != null) {
      filteredItems = _.filter(items, item => {
        return item.status_flag_id === this.activeStatusFlag.id;
      });
    } else {
      if (this.archivedStatusFlag) {
        filteredItems = _.filter(items, item => {
          return item.status_flag_id != this.archivedStatusFlag.id;
        });
      }
    }
    return filteredItems;
  }

  generateItemIcon(item) {
    const checked = _.find(this.selectedItems, o => o.id == item.id);
    const className = `bp3-absolute-control bp3-compact-control ${this.selectedItems.length > 0 ? '' : 'hover-checkbox'}`;
    if (item.image_file_name != null) {
      return (
        <Flexbox style={{ marginRight: '5px', position: 'relative' }} flexDirection="row" alignContent="center" alignItems="center">
          {this.canMultiSelect ? (
            <Checkbox className={className} checked={checked ? true : false} label={null} onChange={() => this.selectItem(item)} />
          ) : null}
          <Avatar src={item.image_file_name} size={22} round={true} />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox style={{ marginRight: '5px', position: 'relative' }} flexDirection="row" alignContent="center" alignItems="center">
          {this.canMultiSelect ? (
            <Checkbox className={className} checked={checked ? true : false} label={null} onChange={() => this.selectItem(item)} />
          ) : null}
          <span className={`fa fas fa-fw ${item.item_icon}`} style={{ color: item.item_color, marginRight: '10px' }}></span>
        </Flexbox>
      );
    }
  }

  generateItemLabel(item, user, labelType) {
    let elements = item.item_layout.leftElements;
    if (labelType === 'right') {
      elements = item.item_layout.rightElements;
    }
    if (item.dataview.length > 0) {
      return (
        <Flexbox flexDirection="row" href={`/items/${item.id}`}>
          {_.map(elements, elementId => {
            const data = this.getItemDataViewAttribute(item.dataview, elementId);
            if (data) {
              if (data.column_value) {
                if (data.column_id === 'item_attributes#name') {
                  return (
                    <Flexbox key={data.column_id}>
                      <Tooltip portalContainer={document.body} content={data.column_name}>
                        <a href={`/items/${item.id}`} className="unstyled" onClick={() => Utilities.navigate(`/items/${item.id}`)}>
                          {_.truncate(data.column_value, { length: 100 })}
                        </a>
                      </Tooltip>
                      {this.generateSeparator(elementId, elements, labelType)}
                    </Flexbox>
                  );
                } else {
                  if (data.column_id === 'item_attributes#status') {
                    return (
                      <Flexbox key={data.column_id}>
                        <Tooltip portalContainer={document.body} content={data.column_name}>
                          {item.status_flag ? (
                            <StatusTag name={item.status_flag.name} color={item.status_flag.color} />
                          ) : (
                            <StatusTag name="No status" color="#A7B6C2" />
                          )}
                        </Tooltip>
                        {this.generateSeparator(elementId, elements, labelType)}
                      </Flexbox>
                    );
                  } else {
                    if (data.column_id === 'item_attributes#state') {
                      return (
                        <Flexbox key={data.column_id}>
                          <Tooltip portalContainer={document.body} content={data.column_name}>
                            <Tag>{data.column_value}</Tag>
                          </Tooltip>
                          {this.generateSeparator(elementId, elements, labelType)}
                        </Flexbox>
                      );
                    }
                    switch (data.column_type) {
                      case 'datetime':
                        return (
                          <Flexbox key={data.column_id}>
                            <Tooltip portalContainer={document.body} content={data.column_name}>
                              {moment(data.column_value).format('MMMM Do YYYY, h:mm')}
                            </Tooltip>
                            {this.generateSeparator(elementId, elements, labelType)}
                          </Flexbox>
                        );
                      default:
                        return (
                          <Flexbox key={data.column_id}>
                            <Tooltip portalContainer={document.body} content={data.column_name}>
                              {_.truncate(data.column_value, { length: 100 })}
                            </Tooltip>
                            {this.generateSeparator(elementId, elements, labelType)}
                          </Flexbox>
                        );
                    }
                  }
                }
              }
            }
          })}
          {labelType === 'left' ? this.generateParticipantsLabel(item, user) : null}
        </Flexbox>
      );
    } else {
      // No dataview is present - user has limited visibility
      if (labelType === 'left') {
        return (
          <Flexbox>
            <Flexbox marginLeft="3px" marginRight="7px" paddingTop="3px">
              <Tooltip portalContainer={document.body} content={I18n.t('js.limited_visibility')}>
                <Icon icon="eye-off" className="bp3-text-muted" />
              </Tooltip>
            </Flexbox>
            <Tooltip portalContainer={document.body} content={I18n.t('js.name')}>
              <a href={`/items/${item.id}`} className="unstyled" onClick={() => Utilities.navigate(`/items/${item.id}`)}>
                {_.truncate(item.name, { length: 100 })}
              </a>
            </Tooltip>
            <Flexbox marginLeft="3px" marginRight="3px" paddingTop="3px">
              <Icon icon="dot" style={{ color: '#CED9E0' }} />
            </Flexbox>
            {this.generateParticipantsLabel(item, user)}
          </Flexbox>
        );
      } else {
        return (
          <Flexbox>
            <Tooltip portalContainer={document.body} content={I18n.t('js.status')}>
              <StatusTag name={item.status_flag.name} color={item.status_flag.color} />
            </Tooltip>
          </Flexbox>
        );
      }
    }
  }

  generateSeparator(elementId, elements, labelType) {
    if (labelType === 'left' && elements.length > 0) {
      return (
        <Flexbox marginLeft="3px" marginRight="3px" paddingTop="3px">
          <Icon icon="dot" style={{ color: '#CED9E0' }} />
        </Flexbox>
      );
    } else {
      const lastElement = _.last(elements);
      if (lastElement) {
        if (lastElement != elementId) {
          return (
            <Flexbox marginLeft="3px" marginRight="3px" paddingTop="3px">
              <Icon icon="dot" style={{ color: '#CED9E0' }} />
            </Flexbox>
          );
        }
      }
    }
  }

  generateParticipantsLabel(item, user) {
    if (this.activeTab != 'all-items') {
      return (
        <Flexbox>
          {_.map(item.item_participants, item_participant => {
            if (item_participant.user_id === user.id) {
              return (
                <Flexbox key={item_participant.id}>
                  <Tooltip
                    portalContainer={document.body}
                    content={I18n.t('js.user_is_participant', {
                      user: user.fullName ? user.fullName : user.full_name,
                      participant: item_participant.participant.name,
                    })}
                  >
                    <Tag minimal={true} className="push-10-r">
                      {item_participant.participant.name}
                    </Tag>
                  </Tooltip>
                </Flexbox>
              );
            }
          })}
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="row">
          <Tooltip portalContainer={document.body} content={I18n.t('js.current_owner')}>
            <span>
              <Avatar src={user.imageFileName ? user.imageFileName : user.image_file_name} size={22} round={true} />
              <span style={{ marginLeft: '10px' }}>{user.fullName ? user.fullName : user.full_name}</span>
            </span>
          </Tooltip>
        </Flexbox>
      );
    }
  }

  getItemDataViewAttribute(dataview, column_id) {
    const datum = _.find(dataview, o => o.column_id === column_id);
    return datum;
  }

  @action toggleTeam(teamId) {
    if (this.expandedTeams.includes(teamId)) {
      this.expandedTeams = _.filter(this.expandedTeams, o => o != teamId);
      this.selectedTeamId = null;
    } else {
      this.expandedTeams = [teamId];
      this.selectedTeamId = teamId;
      this.selectedUserId = null;
      this.fetchUsersForTeam(teamId);
    }
  }

  @action toggleUser(userId) {
    if (this.expandedUsers.includes(userId)) {
      this.expandedUsers = _.filter(this.expandedUsers, o => o != userId);
      this.selectedUserId = null;
    } else {
      this.expandedUsers.push(userId);
      this.selectedUserId = userId;
      this.fetchItemsForUser(userId);
    }
  }

  @action forceSelectUser(userId) {
    this.expandedUsers.push(userId);
    this.selectedUserId = userId;
    this.fetchItemsForUser(userId);
  }

  @action removeItem(itemId) {
    this.items = _.filter(this.items, o => o.id != itemId);
    this.selectedItems = _.filter(this.selectedItems, o => o.id != itemId);
  }

  @action addItem(data) {
    this.items = _.unionBy([data], this.items, 'id');
    this.items = _.orderBy(this.items, ['updated_at'], ['desc']);
  }

  @action addTeam(data) {
    this.teams = _.orderBy(_.unionBy([data], this.teams, 'id'), ['name'], ['asc']);
  }

  @action addUserTeam(data) {
    this.userTeams = _.unionBy([data], this.userTeams, 'id');
    this.users = _.unionBy([data.user], this.users, 'id');
  }

  @action addRelationship(data) {
    const newRelationship = new Relationship(
      data.id,
      data.account_id,
      data.name,
      data.counterpart_name,
      data.description,
      data.default,
      data.lock_user_changes,
      data.lock_counterpart_user_changes,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );
    this.relationships = _.unionBy([newRelationship], this.relationships, 'id');
  }

  @action loadCurrentUser() {
    this.isLoading = true;
    this.fetchRelationships().then(() => {
      this.setActiveRelationship(this.defaultRelationship);
      this.forceSelectUser(CurrentUserStore.currentUser.id);
    });
  }

  @action loadAllColleagues() {
    this.isLoading = true;
    this.fetchRelationships().then(() => {
      this.setActiveRelationship(this.defaultRelationship);
    });
  }

  @action loadTeams() {
    this.isLoading = true;
    this.fetchTeams().then(() => {
      this.isLoading = false;
    });
  }

  @action loadAllItems() {
    this.isLoadingAllItems = true;
    this.fetchAllItems().then(() => {
      this.isLoadingAllItems = false;
    });
  }

  @action fetchAllItems() {
    return new Promise((resolve, reject) => {
      ItemActions.fetchAppItems(
        this.activeApp.id,
        1,
        this.itemPagination.xPerPage,
        this.itemPagination.xOffset,
        this.activeStatusFlag?.name
      )
        .then(response => {
          this.createPaginator(response.headers);
          _.map(response.data, item => {
            this.addItem(item);
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action fetchItemsForUser(userId) {
    if (userId != 'subordinates' && userId != 'superordinates') {
      let type = 'user';
      if (this.isLoadingItemsAsOwner) {
        type = 'owner';
      }
      this.usersLoading.push(userId);
      let path = `${Cookies.get('apiEnv')}/items/${type}?user_id=${userId}`;
      if (this.activeApp != null) {
        path = `${Cookies.get('apiEnv')}/items/${type}?user_id=${userId}&app_id=${this.activeApp.id}`;
      }
      if (this.activeStatusFlag) {
        path = `${path}&status=${this.activeStatusFlag.name}`;
      }
      axios.get(path).then(response => {
        this.createPaginator(response.headers);
        _.map(response.data, item => {
          this.addItem(item);
        });
        this.usersLoading = _.filter(this.usersLoading, o => o != userId);
      });
    }
  }

  @action fetchUsersForTeam(teamId) {
    this.teamsLoading.push(teamId);
    let path = `${Cookies.get('apiEnv')}/user_teams?team_id=${teamId}&type=show_user&page=1&per_page=1`;

    axios.get(path).then(response => {
      const total = response.headers['x-total'];
      axios.get(`${Cookies.get('apiEnv')}/user_teams?team_id=${teamId}&type=show_user&page=1&per_page=${total}`).then(response => {
        _.map(response.data, user_team => {
          this.addUserTeam(user_team);
        });
        this.teamsLoading = _.filter(this.teamsLoading, o => o != teamId);
      });
    });
  }

  @action
  deleteAllSelected() {
    _.map(this.selectedItems, item => {
      ItemActions.deleteItem(item.id).then(() => {
        ToastStore.showToast(I18n.t('js.item_deleted'), 'success');
        this.removeItem(item.id);
      });
    });
  }

  @action
  assignStatusToSelected(statusFlag) {
    let count = this.selectedItems.length;
    _.map(this.selectedItems, item => {
      const itemObj = {
        id: item.id,
        status_flag_id: statusFlag.id,
      };
      ItemActions.changeItem(itemObj).then(response => {
        this.addItem(response.data);
        ToastStore.showToast(I18n.t('js.item_updated'), 'success');
        count = count - 1;
        this.checkCountClearSelected(count);
      });
    });
  }

  @action
  assignStateToSelected(state) {
    let count = this.selectedItems.length;
    _.map(this.selectedItems, item => {
      const itemObj = {
        id: item.id,
        state_id: state.id,
      };
      ItemActions.changeItem(itemObj).then(response => {
        this.addItem(response.data);
        ToastStore.showToast(I18n.t('js.item_updated'), 'success');
        count = count - 1;
        this.checkCountClearSelected(count);
      });
    });
  }

  @action
  checkCountClearSelected(count) {
    if (count == 0) {
      this.clearSelected();
    }
  }

  @action
  fetchTeams() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_teams?user_id=${CurrentUserStore.currentUser.id}&page=1&per_page=1`)
        .then(response => {
          const total = response.headers['x-total'];
          axios
            .get(`${Cookies.get('apiEnv')}/user_teams?user_id=${CurrentUserStore.currentUser.id}&page=1&per_page=${total}`)
            .then(response => {
              _.map(response.data, user_team => {
                this.addTeam(user_team.team);
              });
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
              const errors = error.response.data.error.join(', ');
              ToastStore.showToast(errors, 'danger');
            });
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchRelationships() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/relationships`)
        .then(response => {
          _.map(response.data, relationship => {
            this.addRelationship(relationship);
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchUserRelationships() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get('apiEnv')}/user_relationships?relationship_id=${this.activeRelationship.id}&user_id=${
            CurrentUserStore.currentUser.id
          }`
        )
        .then(response => {
          this.userRelationships = response.data;
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchParticipantsForActiveApp() {
    this.isLoadingParticipants = true;
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/participants?app_id=${this.activeApp.id}`)
        .then(response => {
          this.participants = response.data;
          _.map(response.data, participant => {
            if (participant.is_owner) {
              this.activeParticipant = participant;
            }
          });
          resolve(response.data);
          this.isLoadingParticipants = false;
        })
        .catch(error => {
          this.isLoadingParticipants = false;
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchStatusFlagsForActiveApp() {
    this.isLoadingStatusFlags = true;
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/status_flags?app_id=${this.activeApp.id}`)
        .then(response => {
          this.statusFlags = response.data;
          resolve(response.data);
          this.isLoadingStatusFlags = false;
        })
        .catch(error => {
          this.isLoadingStatusFlags = false;
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchStatesForActiveApp() {
    this.isLoadingStates = true;
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/states?app_id=${this.activeApp.id}`)
        .then(response => {
          this.states = response.data;
          resolve(response.data);
          this.isLoadingStates = false;
        })
        .catch(error => {
          this.isLoadingStates = false;
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action resetStore() {
    this.viewArea = 'dashboard';
    this.isLoadingParticipants = false;
    this.isLoadingStatusFlags = false;
    this.isLoadingItemsAsOwner = false;
    this.usersLoading = [];
    this.teamsLoading = [];
    this.activeRelationship = null;
    this.expandedTeams = [];
    this.expandedUsers = [];
    this.activeApp = null;
    this.relationships = [];
    this.userRelationships = [];
    this.userTeams = [];
    this.participants = [];
    this.statusFlags = [];
    this.states = [];
    this.activeParticipant = null;
    this.activeStatusFlag = null;
    this.selectedUserId = null;
    this.selectedTeamId = null;
    this.filteredUser = '';
    this.items = [];
    this.teams = [];
    this.itemLayout = '';
    this.itemPagination = new Paginator();
    this.canMultiSelect = true;
  }
}

const store = new DynamicViewStore();
export default store;
