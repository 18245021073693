import { computed, observable } from 'mobx';

export default class SignIn {
  @observable
  email;
  @observable
  password;

  constructor(email, password) {
    this.email = email || '';
    this.password = password || '';
  }

  @computed
  get emailValid() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email !== '' && exp.test(this.email)) {
      return true;
    }
    return false;
  }

  @computed
  get emailError() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email === '') {
      return I18n.t('js.email_is_required');
    }
    if (!exp.test(this.email)) {
      return I18n.t('js.email_is_invalid');
    }
    return null;
  }

  @computed
  get passwordValid() {
    if (this.password.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get passwordError() {
    if (this.password.length < 1) {
      return I18n.t('js.password_is_required');
    }
    return null;
  }
}
