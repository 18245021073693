import React from 'react';
import { observable, computed, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Collapse, FormGroup, InputGroup, Intent, NumericInput, HTMLSelect, Switch, Icon } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ElementTag from '../../shared/ElementTag';
import LengthValidator from '../../shared/LengthValidator';
import ProfileTemplateActions from '../../../actions/ProfileTemplateActions';
import ElementIdSelector from '../../shared/ElementIdSelector';
import ElementMappingActions from '../../../actions/ElementMappingActions';
import classNames from 'classnames';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementProfileValue extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';
  @observable profileTemplates = [];
  @observable activeProfileTemplate = null;

  constructor(props) {
    super(props);
    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  @computed get elementOptions() {
    let elements = [];
    if (this.activeProfileTemplate !== null) {
      _.map(this.activeProfileTemplate.sections, section => {
        section.elements.map(element => {
          if (!element.is_display_element) {
            elements.push(element);
          }
        });
      });
    }
    return ElementMappingActions.mapElements(elements);
  }

  @action setActiveProfileTemplate(profileTemplateId) {
    this.activeProfileTemplate = _.find(this.profileTemplates, o => o.id === profileTemplateId);
    this.store.updateActiveElement(this.elementOptions[0].id, 'profileTemplateElementId');
  }

  @action setInitialProfileTemplateElement() {
    if (this.props.element.elementProfileValue.profileTemplateElementId.length > 0) {
      _.map(this.profileTemplates, profileTemplate => {
        _.map(profileTemplate.sections, section => {
          _.map(section.elements, element => {
            if (element.id === this.props.element.elementProfileValue.profileTemplateElementId) {
              this.activeProfileTemplate = profileTemplate;
              return;
            }
          });
        });
      });
    }
  }

  @action componentDidMount() {
    ProfileTemplateActions.fetchAllProfileTemplates().then(response => {
      this.profileTemplates = response;
      if (response.length > 0) {
        this.setInitialProfileTemplateElement();
      }
    });
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
    };
    const elementProfileValueObj = {
      id: this.store.activeElement.elementProfileValue.id,
      name: this.store.activeElement.elementProfileValue.name,
      participant_id: this.store.activeElement.elementProfileValue.participantId,
      profile_template_element_id: this.store.activeElement.elementProfileValue.profileTemplateElementId,
      keep_existing_items_updated: this.store.activeElement.elementProfileValue.keepExistingItemsUpdated,
    };
    this.props.TemplateActions.changeSubElement(elementProfileValueObj, 'element_profile_values')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleParticipantChange(e) {
    this.store.updateActiveElement(e.currentTarget.value, 'participantId');
  }

  handleProfileTemplateChange(e) {
    this.setActiveProfileTemplate(e.currentTarget.value);
  }

  handleElementChange(e) {
    this.store.updateActiveElement(e.currentTarget.value, 'profileTemplateElementId');
  }

  handleKeepExistingItemsUpdatedChange() {
    this.store.updateActiveElement(!this.store.activeElement.elementProfileValue.keepExistingItemsUpdated, 'keepExistingItemsUpdated');
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementProfileValue[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementProfileValue[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderElementSelect() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
        <div className="bp3-form-group">
          <label className="bp3-label" htmlFor="profileTemplateElementId">
            {I18n.t('js.select_element_condition')}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <div className="bp3-form-content">
            <HTMLSelect
              fill={true}
              name="profileTemplateElementId"
              value={this.store.activeElement.elementProfileValue.profileTemplateElementId}
              onChange={this.handleElementChange.bind(this)}
              dir="auto"
            >
              {this.elementOptions.map(element => {
                return (
                  <option value={element.id} disabled={false} key={element.id}>
                    {_.truncate(element[element.camelCaseTitle].name, {
                      length: 40,
                    })}{' '}
                    ({element.fullTitle})
                  </option>
                );
              })}
            </HTMLSelect>
          </div>
        </div>
      </Flexbox>
    );
  }

  renderProfileTemplates() {
    if (this.profileTemplates.length > 0 && this.activeProfileTemplate !== null) {
      return (
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginRight="20px">
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="profileTemplate">
                {I18n.t('js.select_profile_template')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <div className="bp3-form-content">
                <HTMLSelect
                  fill={true}
                  name="profileTemplate"
                  value={this.activeProfileTemplate.id}
                  onChange={this.handleProfileTemplateChange.bind(this)}
                  dir="auto"
                >
                  {this.profileTemplates.map(profileTemplate => {
                    return (
                      <option value={profileTemplate.id} disabled={false} key={profileTemplate.id}>
                        {profileTemplate.name}
                      </option>
                    );
                  })}
                </HTMLSelect>
              </div>
            </div>
          </Flexbox>
          {this.renderElementSelect()}
        </Flexbox>
      );
    }
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox>
              <span className="bp3-icon bp3-icon-following push-10-r" />
              <h5 className={Classes.HEADING}>{I18n.t('js.profile_value_options')}</h5>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementProfileValue.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementProfileValue.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={this.store.activeElement.elementProfileValue.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'participantId') && !this.store.activeElement.elementProfileValue.participantValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="participant">
                    {I18n.t('js.select_participant', {
                      participant: I18n.t('js.participant'),
                    })}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'participantId') && !this.store.activeElement.elementProfileValue.participantValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <HTMLSelect
                        className="bp3-fill"
                        fill={true}
                        name="participant"
                        value={this.store.activeElement.elementProfileValue.participantId}
                        onChange={this.handleParticipantChange.bind(this)}
                        dir="auto"
                      >
                        {this.store.participants.map(participant => {
                          return (
                            <option value={participant.id} disabled={false} key={participant.id}>
                              {participant.name}
                            </option>
                          );
                        })}
                      </HTMLSelect>
                    </div>
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            {this.renderProfileTemplates()}
            <Flexbox flexDirection="column" marginTop="20px">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.elementProfileValue.keepExistingItemsUpdated}
                label={I18n.t('js.keep_existing_items_updated')}
                onChange={this.handleKeepExistingItemsUpdatedChange.bind(this)}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementProfileValue.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
          intent={this.store.activeElement.validateLength(this.displayedInput) ? null : Intent.DANGER}
        >
          <Flexbox className="bp3-card bg-gray" flex="row" alignItems="center">
            <Icon icon="following" />
            <strong className="push-10-l">{I18n.t('js.result_displayed_here')}</strong>
          </Flexbox>
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementProfileValue.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
        intent={this.props.element.validateLength(this.displayedInput) ? null : Intent.DANGER}
      >
        <Flexbox className="bp3-card bg-gray" flex="row" alignItems="center">
          <Icon icon="following" />
          <strong className="push-10-l">{I18n.t('js.result_displayed_here')}</strong>
        </Flexbox>
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
