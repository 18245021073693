import { computed, observable } from 'mobx';

export default class ElementTextarea {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;
  @observable
  placeholderText;
  @observable
  rows;

  constructor(id, elementId, name, placeholderText, rows) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
    this.placeholderText = placeholderText || '';
    // lazy fix
    // eslint-disable-next-line no-magic-numbers
    this.rows = rows || 4;
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get placeholderTextValid() {
    if (this.placeholderText.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get placeholderTextError() {
    if (this.placeholderText.length < 1) {
      return I18n.t('js.placeholder_text_is_required');
    }
    return null;
  }
}
