import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import {
  Button,
  Intent,
  Switch,
  Alert,
  Classes,
  Spinner,
  Divider,
  Checkbox,
  Label,
  Collapse,
} from '@blueprintjs/core';

@inject(
  'AzureIntegrationStore',
  'ToastStore',
  'AccountSettingsStore',
  'IntegrationStore'
)
@observer
export default class PauseIntegrationAlert extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const integration = this.props.integration;
    const active = integration.active || false;
    if (active == true) {
      return (
        <Alert
          portalContainer={document.body}
          cancelButtonText={I18n.t('js.cancel')}
          confirmButtonText={I18n.t('js.pause')}
          icon="pause"
          isOpen={this.props.isPauseAlertOpen}
          onCancel={() =>
            this.props.toggleBoolean('isPauseAlertOpen')
          }
          onConfirm={() => this.props.updateActiveStatus()}
        >
          <h4 className={Classes.HEADING}>
            {I18n.t('js.are_you_sure')}
          </h4>
          {I18n.t('js.all_the_future_synchronization_will_be_paused')}
        </Alert>
      );
    } else {
      return (
        <Alert
          portalContainer={document.body}
          cancelButtonText={I18n.t('js.cancel')}
          confirmButtonText={I18n.t('js.activate')}
          icon="play"
          intent={Intent.SUCCESS}
          isOpen={this.props.isPauseAlertOpen}
          onCancel={() =>
            this.props.toggleBoolean('isPauseAlertOpen')
          }
          onConfirm={() => this.props.updateActiveStatus()}
        >
          <h4 className={Classes.HEADING}>
            {I18n.t('js.are_you_sure')}
          </h4>
          {I18n.t('js.reactivate_your_integration')}
          <span>
            <Checkbox
              className="push-10-t"
              label={I18n.t('js.run_a_synchronization')}
              onChange={() =>
                this.props.toggleBoolean('initiateSync')
              }
            />
          </span>
        </Alert>
      );
    }
  }
}
