/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import RichTextDisplay from '../shared/RichTextDisplay';
import { observable, action } from 'mobx';
import Utilities from '../../utils/Utilities';
import Flexbox from 'flexbox-react';
import { FormGroup, Tag, Icon } from '@blueprintjs/core';
import ElementTag from '../shared/ElementTag';
import UserAvatar from '../shared/UserAvatar';
import TeamAvatar from '../shared/TeamAvatar';
import ItemAvatar from '../shared/ItemAvatar';
import moment from 'moment';
import _ from 'lodash';
import Avatar from 'react-avatar';
import UserInfoDrawer from './UserInfoDrawer';
import TeamInfoDrawer from './TeamInfoDrawer';
import TeamUsersStore from '../../stores/TeamUsersStore';
import ItemDrawerStore from '../../stores/ItemDrawerStore';
import ItemInfoDrawer from './ItemInfoDrawer';

@inject('CurrentUserStore', 'ToastStore', 'UserProfileStore', 'UserProfileTemplateStore', 'ItemStore', 'TemplateActions', 'ItemActions')
@observer
export default class FormElementDisplay extends React.Component {
  @observable displayValue = '';
  @observable displayValues = [];
  @observable isUserDrawerOpen = false;
  @observable drawerData = {};
  @observable isLoading = false;
  @observable isTeamDrawerOpen = false;
  @observable isTeamDrawerLoading = false;
  @observable isItemDrawerOpen = false;

  componentDidMount() {
    this.setDisplayValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setDisplayValues(nextProps);
  }

  @action
  openUserDrawer(user) {
    this.drawerData = user;
    const UserProfileTemplateStore = this.props.UserProfileTemplateStore;
    const UserProfileStore = this.props.UserProfileStore;

    UserProfileStore.setUser(user);

    UserProfileTemplateStore.resetStartingAttributes();
    UserProfileStore.fetchAuthorizedAccounts(user.id)
      .then(() => {
        this.props.TemplateActions.fetchProfileTemplates()
          .then(response => {
            _.map(response.data, profileTemplate => {
              UserProfileTemplateStore.addProfileTemplate(profileTemplate);
            });
            UserProfileTemplateStore.matchActiveProfileTemplate(UserProfileStore.authorizedAccount);
            UserProfileTemplateStore.toggleLoading();
          })
          .catch(() => {
            UserProfileTemplateStore.toggleLoading();
          });
      })
      .catch(() => {
        UserProfileTemplateStore.toggleLoading();
      });

    this.toggleBool('isUserDrawerOpen');
  }

  @action
  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action
  toggleItemDrawer() {
    this.isItemDrawerOpen = !this.isItemDrawerOpen;
  }

  @action
  openItemDrawer(item) {
    this.isItemDrawerOpen = true;
    ItemDrawerStore.setActiveItem(item.id);
  }

  @action
  toggleIsTeamDrawerLoading() {
    this.isTeamDrawerLoading = !this.isTeamDrawerLoading;
  }

  openTeamDrawer(team) {
    this.drawerData = team;
    this.toggleBool('isTeamDrawerLoading');
    TeamUsersStore.resetStartingAttributes();
    TeamUsersStore.fetchTeam(team.id)
      .then(() => {
        const appNum = 1;
        TeamUsersStore.fetchUserTeams(team.id, appNum, TeamUsersStore.pagination.xPerPage, TeamUsersStore.pagination.xOffset)
          .then(() => {
            this.toggleBool('isTeamDrawerLoading');
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });

    this.toggleBool('isTeamDrawerOpen');
  }

  @action
  toggleBool(value) {
    this[`${value}`] = !this[`${value}`];
  }

  setDisplayValues(props) {
    this.displayValue = '';
    this.displayValues = [];

    switch (props.element.elementType) {
      case 'text_input':
      case 'textarea':
      case 'rich_textarea':
      case 'email':
      case 'hyperlink_input':
      case 'profile_value':
        if (props.element.formValues.length > 0) {
          if (/^\d+\.\d+$/.test(props.element.formValues[0].stringValue)) {
            this.displayValue = parseFloat(props.element.formValues[0].stringValue).toFixed(2);
          } else {
            this.displayValue = _.clone(props.element.formValues[0].stringValue);
          }
        }
        if (props.element.repeatable) {
          let orderedValues = _.orderBy(props.element.formValues, ['rowOrder', 'updatedAt'], ['asc', 'desc']);
          _.map(orderedValues, formValue => {
            this.displayValues.push(formValue.stringValue);
          });
        }
        break;
      case 'image_upload':
        if (props.element.formValues.length > 0) {
          if (props.element.formValues[0].imageFileName.length > 0) {
            this.displayValue = _.clone(props.element.formValues[0].imageFileName);
          }
        }
        break;
      case 'file_upload':
        if (props.element.formValues.length > 0) {
          if (props.element.formValues[0].attachmentFileName.length > 0) {
            this.displayValue = _.clone(props.element.formValues[0].attachmentFileName);
          }
        }
        break;
      case 'multiline':
        _.map(props.element.elementMultiline.elementMultilineInputs, input => {
          const findProfileValue = _.find(props.element.formValues, o => o.elementMultilineInputId === input.id);
          if (findProfileValue) {
            this.displayValues.push(findProfileValue.stringValue);
          }
        });
        break;
      case 'select':
        if (props.element.elementSelect.optionType == 'single') {
          // Handle single select
          const formValue = _.head(props.element.formValues);
          if (formValue) {
            const findOption = _.find(
              props.element.elementSelect.elementSelectOptions,
              o => o.id == formValue.elementSelectOptionId && formValue.booleanValue
            );
            if (findOption) {
              this.displayValues.push(findOption);
            }
          }
        } else {
          // Handle multiple select
          _.map(props.element.elementSelect.elementSelectOptions, option => {
            const findItemValue = _.find(
              props.element.formValues,
              formValue => formValue.elementSelectOptionId === option.id && formValue.booleanValue
            );
            if (findItemValue) {
              this.displayValues.push(option);
            }
          });
        }
        break;
      case 'user_select':
        _.map(props.element.formValues, formValue => {
          this.displayValues.push(formValue.referenceUser);
        });
        this.displayValues = _.orderBy(this.displayValues, ['fullName', 'asc']);
        break;
      case 'team_select':
        _.map(this.props.element.formValues, formValue => {
          this.displayValues.push(formValue.referenceTeam);
        });
        this.displayValues = _.orderBy(this.displayValues, ['name', 'asc']);
        break;
      case 'item_select':
        _.map(props.element.formValues, formValue => {
          this.displayValues.push(formValue.referenceItem);
        });
        this.displayValues = _.orderBy(this.displayValues, ['name', 'asc']);
        break;
      case 'number':
      case 'currency':
      case 'percentage':
        if (props.element.formValues.length > 0) {
          let value = props.element.formValues[0].decimalValue;
          if (props.element.formValues[0].decimalValue % 1 === 0 && props.element.formValues[0].decimalValue !== '') {
            value = parseInt(props.element.formValues[0].decimalValue);
          }
          if (isNaN(value)) {
            value = '';
          }
          if (this.props.element.elementType === 'currency') {
            value = parseFloat(value).toFixed(2);
          }
          this.displayValue = value;
        }
        break;
      case 'calculation':
        if (props.element.formValues.length > 0) {
          if (props.element.formValues[0].itemValueType === 'string') {
            this.displayValue = props.element.formValues[0].stringValue;
          } else {
            let value = props.element.formValues[0].decimalValue;
            if (props.element.formValues[0].decimalValue % 1 === 0 && props.element.formValues[0].decimalValue !== '') {
              value = parseInt(props.element.formValues[0].decimalValue);
            }
            if (isNaN(value)) {
              value = '';
            }
            this.displayValue = `${this.props.element.elementCalculation.prefix}${value}${this.props.element.elementCalculation.suffix}`;
          }
        }
        break;
      case 'date':
      case 'date_time':
      case 'time':
        if (props.element.formValues.length > 0) {
          this.displayValue = _.clone(props.element.formValues[0].dateValue);
        }
        break;
      case 'date_range':
        _.map(props.element.formValues, formValue => {
          this.displayValues.push(formValue.dateValue);
        });
        this.displayValues = _.orderBy(this.displayValues);
        break;
      default:
        return null;
    }
    return undefined;
  }

  renderValue() {
    switch (this.props.element.elementType) {
      case 'text_input':
      case 'textarea':
        if (this.props.element.repeatable) {
          return (
            <Flexbox flexDirection="column">
              {_.map(this.displayValues, displayValue => {
                return (
                  <p className="pre form-text-display" key={Math.random()}>
                    {displayValue}
                  </p>
                );
              })}
            </Flexbox>
          );
        } else {
          return <p className="pre form-text-display">{this.displayValue}</p>;
        }
        break;
      case 'profile_value':
      case 'rich_textarea':
        if (this.props.element.repeatable) {
          return (
            <Flexbox flexDirection="column" marginTop="-10px">
              {_.map(this.displayValues, displayValue => {
                return (
                  <Flexbox className="form-text-display" key={Math.random()} marginTop="5px">
                    {displayValue.slice(1, 9).includes('blocks') ? (
                      <RichTextDisplay content={displayValue} />
                    ) : (
                      <p className="pre form-text-display" key={Math.random()}>
                        {displayValue}
                      </p>
                    )}
                  </Flexbox>
                );
              })}
            </Flexbox>
          );
        } else {
          return (
            <Flexbox className="form-text-display" marginTop="-10px">
              {this.displayValue.slice(1, 9).includes('blocks') ? (
                <RichTextDisplay content={this.displayValue} />
              ) : (
                <p className="pre form-text-display">{this.displayValue}</p>
              )}
            </Flexbox>
          );
        }
        break;
      case 'email':
        if (this.props.element.repeatable) {
          return (
            <Flexbox flexDirection="column">
              {_.map(this.displayValues, displayValue => {
                return (
                  <Flexbox key={Math.random()}>
                    <span className="bp3-icon bp3-icon-envelope push-10-r push-5-t bp3-text-muted" />
                    <p className="form-text-display">
                      <a href={`mailto:${displayValue}`}>{displayValue}</a>
                    </p>
                  </Flexbox>
                );
              })}
            </Flexbox>
          );
        } else {
          return (
            <Flexbox justifyContent="center">
              <span className="bp3-icon bp3-icon-envelope push-10-r push-5-t bp3-text-muted" />
              <p className="form-text-display">
                <a href={`mailto:${this.displayValue}`}>{this.displayValue}</a>
              </p>
            </Flexbox>
          );
        }
        break;
      case 'multiline':
        return (
          <Flexbox flexGrow={1} flexDirection="column">
            {_.map(this.displayValues.slice(), (value, index) => (
              <Flexbox key={index} className="form-text-display" marginBottom="0px">
                {value}
              </Flexbox>
            ))}
          </Flexbox>
        );
      case 'select':
        return (
          <Flexbox flexGrow={1} flexDirection="row">
            {_.map(this.displayValues.slice(), (value, index) => (
              <span
                key={index}
                className="push-5-r text-white"
                multiline="false"
                style={{
                  borderRadius: '3px',
                  lineHeight: '16px',
                  padding: '2px 6px',
                  backgroundColor: value.color,
                }}
              >
                {value.name}
              </span>
            ))}
          </Flexbox>
        );

      case 'user_select':
        return (
          <Flexbox flexDirection="column" className="w-95p">
            {_.map(this.displayValues.slice(), (user, index) => (
              <Flexbox
                className="relationship-user pointer"
                alignItems="center"
                justifyContent="space-between"
                padding="10px"
                key={user.id}
                onClick={() => this.openUserDrawer(user)}
              >
                <Flexbox alignItems="center">
                  <Avatar src={user.imageFileName} size={20} round={true} className="push-10-r" />
                  <span className="font-s-16">{user.fullName}</span>
                </Flexbox>
              </Flexbox>
            ))}
          </Flexbox>
        );
      case 'team_select':
        return (
          <Flexbox flexDirection="column" className="w-95p">
            {_.map(this.displayValues.slice(), (team, index) => (
              <Flexbox
                className="relationship-user pointer"
                alignItems="center"
                justifyContent="space-between"
                padding="10px"
                key={index}
                onClick={() => this.openTeamDrawer(team)}
              >
                <Flexbox alignItems="center">
                  <Avatar src={team.imageFileName} size={20} round={true} className="push-10-r" />
                  <span className="font-s-16">{team.name}</span>
                </Flexbox>
              </Flexbox>
            ))}
          </Flexbox>
        );
      case 'item_select':
        return (
          <Flexbox flexDirection="column" className="w-95p">
            {_.map(this.displayValues.slice(), (item, index) => (
              <Flexbox
                className="relationship-user pointer"
                alignItems="center"
                justifyContent="space-between"
                padding="10px"
                key={index}
                onClick={() => this.openItemDrawer(item)}
              >
                <Flexbox alignItems="center">
                  <span className={`push-5-r ${item.itemIcon}`} style={{ color: item.itemColor }} />
                  <span className="font-s-16">{item.name}</span>
                </Flexbox>
              </Flexbox>
            ))}
          </Flexbox>
        );

      case 'number':
        return <p className="form-text-display">{this.displayValue}</p>;
      case 'calculation':
        return (
          <Flexbox className="bp3-card bg-gray" flex="row" alignItems="center">
            <span className="form-text-display">{this.displayValue}</span>
          </Flexbox>
        );
      case 'currency':
        return (
          <p className="form-text-display">
            {this.props.element.elementNumber.prefix}
            {this.displayValue} {this.props.element.elementNumber.suffix}
          </p>
        );
      case 'percentage':
        return <p className="form-text-display">{this.displayValue}%</p>;
      case 'date': {
        const date = moment(this.displayValue).format('MMMM Do YYYY');
        return (
          <Flexbox justifyContent="center">
            <span className="bp3-icon bp3-icon-calendar push-10-r push-5-t bp3-text-muted" />
            <p className="form-text-display">{date}</p>
          </Flexbox>
        );
      }
      case 'date_time': {
        const dateTime = moment(this.displayValue).format('MMMM Do YYYY, h:mm:ss a');
        return (
          <Flexbox justifyContent="center">
            <span className="bp3-icon bp3-icon-calendar push-10-r push-5-t bp3-text-muted" />
            <p className="form-text-display">{dateTime}</p>
          </Flexbox>
        );
      }
      case 'time': {
        const time = moment(this.displayValue).format('h:mm a');
        return (
          <Flexbox justifyContent="center">
            <span className="bp3-icon bp3-icon-time push-10-r push-5-t bp3-text-muted" />
            <p className="form-text-display">{time}</p>
          </Flexbox>
        );
      }
      case 'date_range': {
        return (
          <Flexbox justifyContent="center">
            <span className="bp3-icon bp3-icon-timeline-events push-10-r push-5-t bp3-text-muted" />
            <p className="form-text-display">
              {moment(this.displayValues[0]).format('MMMM Do YYYY')} - {moment(this.displayValues[1]).format('MMMM Do YYYY')}
            </p>
          </Flexbox>
        );
      }
      case 'hyperlink_input':
        return (
          <Flexbox justifyContent="center">
            <span className="bp3-icon bp3-icon-globe push-10-r push-5-t bp3-text-muted" />
            <p className="form-text-display">
              <a href={`//${this.displayValue}`} rel="noreferrer noopener" target="_blank">
                {this.displayValue}
              </a>
            </p>
          </Flexbox>
        );
      case 'image_upload':
        return (
          <Flexbox flexGrow={1} justifyContent="flex-start">
            <img className="element-image" alt="element" src={this.displayValue} />
          </Flexbox>
        );
      case 'file_upload':
        return (
          <Flexbox flexGrow={1}>
            <span className="bp3-icon bp3-icon-paperclip bp3-text-muted push-10-r push-5-t" />
            <a href={this.displayValue} rel="noreferrer noopener" target="_blank">
              {I18n.t('js.download_file', {
                name: Utilities.getFileName(this.displayValue),
              })}
            </a>
          </Flexbox>
        );
      default:
        return <em className="bp3-text-muted">{this.props.element.elementType} display not yet configured</em>;
    }
  }

  renderPrivacyTag() {
    const { element, sectionPrivateView } = this.props;
    if (element.privateView && !sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    const { element, sectionProtectedView } = this.props;
    if (element.protectedView && !sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    const { element, sectionAccountOwnerOnlyView } = this.props;
    if (element.accountOwnerOnlyView && !sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name) {
    return (
      <label>
        <Flexbox flexDirection="row" alignItems="center" alignContent="center" flexGrow={0} className="bp3-display-label push-10-b">
          <span className="push-10-r">{name}</span>
          {this.props.showLabels ? (
            <>
              {this.renderPrivacyTag()}
              {this.renderProtectedTag()}
              {this.renderAccountOwnerOnlyTag()}
            </>
          ) : null}
        </Flexbox>
      </label>
    );
  }

  renderDisplay() {
    if (this.displayValue === '' && this.displayValues.length === 0) {
      return <Tag className="bp3-minimal">{I18n.t('js.no_response')}</Tag>;
    }
    return <Flexbox>{this.renderValue()}</Flexbox>;
  }

  render() {
    if (this.props.noTitle) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} marginTop="-10px">
          {this.renderDisplay()}
        </Flexbox>
      );
    }
    return (
      <FormGroup>
        {this.renderLabel(this.props.element[this.props.element.camelCaseTitle].name)}
        {this.renderDisplay()}

        {this.props.element.elementType == 'user_select' ? (
          <UserInfoDrawer
            isOpen={this.isUserDrawerOpen}
            toggleBool={this.toggleBool.bind(this)}
            drawerData={this.drawerData}
            isLoading={this.props.UserProfileTemplateStore.isLoading}
          />
        ) : (
          ''
        )}
        {this.props.element.elementType == 'team_select' ? (
          <TeamInfoDrawer
            isOpen={this.isTeamDrawerOpen}
            team={this.drawerData}
            toggleBool={this.toggleBool.bind(this)}
            isLoading={this.isTeamDrawerLoading}
            toggleIsTeamDrawerLoading={this.toggleIsTeamDrawerLoading.bind(this)}
          />
        ) : (
          ''
        )}
        {this.props.element.elementType == 'item_select' && this.props.stopOpeningMoreDrawers != true ? (
          <ItemInfoDrawer
            isItemDrawerOpen={this.isItemDrawerOpen}
            toggleDrawer={this.toggleItemDrawer.bind(this)}
            sections={ItemDrawerStore.visibleSections}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}
