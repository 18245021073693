/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Spinner, Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class AccountBillingTabs extends React.Component {
  handleTabChange(tab) {
    this.props.WorkspaceStore.activeTab = tab;
  }

  renderRightLinks() {
    if (this.props.loading) {
      return <Spinner size={20} />;
    }
    return (
      <Tabs
        id="accountBillingTabnav"
        selectedTabId={this.props.AccountBillingStore.activeTab}
        onChange={tab => this.props.AccountBillingStore.setActiveTab(tab)}
      >
        <Tab id="plans" title={I18n.t('js.plans')} />
        <Tab id="subscription" title={I18n.t('js.subscription')} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
