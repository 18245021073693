// @flow
import * as React from 'react';
import { Icon } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';

type FeatureProps = {
  title: string,
  description: string,
  icon: string | React.Element<Icon>,
};

const MiniFeatureIconStyle = {
  margin: '2px 8px 0 0',
  color: '#FAFAFA',
};

const MiniFeatureTitleStyle = {
  marginTop: '0',
};

const Feature = ({ icon, title, description }: FeatureProps) => (
  <Flexbox flexDirection="column" paddingTop="50px" paddingBottom="50px" className="feature">
    <Flexbox justifyContent="flex-start" alignItems="center" marginBottom="10px">
      <Flexbox justifyContent="center">
        <Icon icon={icon} style={MiniFeatureIconStyle} />
        <h1 className="feature__title" style={MiniFeatureTitleStyle}>
          {title}
        </h1>
      </Flexbox>
    </Flexbox>
    <p className="feature__description bp3-running-text">{description}</p>
  </Flexbox>
);

export default Feature;
