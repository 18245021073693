import React from 'react';
import Flexbox from 'flexbox-react';
import { observer } from 'mobx-react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import _ from 'lodash';
import ElementReadOnly from '../../shared/ElementReadOnly';
import classNames from 'classnames';

@observer
export default class ElementMultilineDisplay extends React.Component {
  renderDisabledText() {
    if (this.props.disabled) {
      return <ElementReadOnly element={this.props.element} />;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        {_.map(this.props.element.elementMultiline.elementMultilineInputs, input => {
          let width = classNames('');
          if (input.displayWidth === 'half') {
            width = classNames('multiline-half-width');
          }
          if (input.displayLabel) {
            return (
              <Flexbox key={input.id} flexGrow={1} flexDirection="column" className={width}>
                <FormGroup
                  disabled={this.props.disabled}
                  labelInfo={input.isRequired ? `(${I18n.t('js.required')})` : ''}
                  label={input.name}
                  inline={input.displayLabelType === 'inline'}
                >
                  <InputGroup autoComplete="off" disabled={this.props.disabled} placeholder={input.placeholderText} />
                </FormGroup>
              </Flexbox>
            );
          }
          return (
            <Flexbox key={input.id} flexGrow={1} flexDirection="column" marginBottom="10px" className={width}>
              <InputGroup autoComplete="off" className="bp3-fill" disabled={this.props.disabled} placeholder={input.placeholderText} />
            </Flexbox>
          );
        })}
        {this.renderDisabledText()}
      </Flexbox>
    );
  }
}
