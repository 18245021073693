import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import AccountUsersStore from '../stores/AccountUsersStore';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import AppItemStore from '../stores/AppItemStore';
import DataViewStore from '../stores/DataViewStore';
import ImportStore from '../stores/ImportStore';
import TeamsStore from '../stores/TeamsStore';
import ImportActions from '../actions/ImportActions';
import ProfileTemplateActions from '../actions/ProfileTemplateActions';
import DataViewActions from '../actions/DataViewActions';
import AppStore from '../stores/AppStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import AccountUsersTabs from '../components/accounts/AccountUsersTabs';
import AccountUsers from '../components/accounts/AccountUsers';
import AccountUsersRequests from '../components/accounts/AccountUsersRequests';
import AccountUsersInvitations from '../components/accounts/AccountUsersInvitations';
import AccountUsersImportUsers from '../components/accounts/AccountUsersImportUsers';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import SkeletonTable from '../components/skeletons/SkeletonTable';
import DataView from './DataView';

@observer
export default class AccountUsersView extends React.Component {
  componentDidMount() {
    if (this.props.tab !== null) {
      AccountUsersStore.setActiveTab(this.props.tab);
    }
  }

  renderUsers() {
    if (CurrentUserStore.isLoading || AccountUsersStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center">
          <SkeletonTable />
        </Flexbox>
      );
    }
    if (AccountUsersStore.activeTab === 'users') {
      return (
        <AccountUsers
          accountId={this.props.accountId}
          loadUsers={true}
          showHeader={true}
          showFilter={true}
          toggleLoading={() => null}
          importId={''}
        />
      );
    }
    if (AccountUsersStore.activeTab === 'requests') {
      return <AccountUsersRequests accountId={this.props.accountId} />;
    }
    if (AccountUsersStore.activeTab === 'invitations') {
      return <AccountUsersInvitations accountId={this.props.accountId} />;
    }
    if (AccountUsersStore.activeTab === 'import') {
      return <AccountUsersImportUsers accountId={this.props.accountId} />;
    }
    if (AccountUsersStore.activeTab === 'dataview') {
      return <DataView location="profile" userPermissionLevel="owner" />;
    }
    return undefined;
  }

  render() {
    const stores = {
      AccountUsersStore,
      CurrentUserStore,
      AppItemStore,
      DataViewStore,
      DataViewActions,
      ToastStore,
      ImportStore,
      TeamsStore,
      ImportActions,
      ProfileTemplateActions,
      AppStore,
    };
    const title =
      AccountUsersStore.pagination.xTotal === 0 || AccountUsersStore.activeTab !== 'users'
        ? I18n.t('js.users')
        : `${I18n.t('js.users')} (${AccountUsersStore.pagination.xTotal})`;
    return (
      <ErrorBoundary>
        <Provider {...stores}>
          <ThemeWrapper>
            <Flexbox flexGrow={1} alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <AccountUsersTabs />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderUsers()}
              </Flexbox>
            </Flexbox>
          </ThemeWrapper>
        </Provider>
      </ErrorBoundary>
    );
  }
}
