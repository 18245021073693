import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, PopoverInteractionKind, Popover } from '@blueprintjs/core';
import UserSuggest from '../suggests/UserSuggest';
import User from '../../models/UserModel';
import _ from 'lodash';

@inject('OrganizationStore', 'ToastStore')
@observer
export default class OrganizationChooseUsers extends React.Component {
  handleUserClick(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
    this.props.handleUserSelectChange(newUser);
  }

  handleUserRemove() {
    return null;
  }

  renderUserSuggest() {
    return (
      <Flexbox padding="10px">
        <UserSuggest
          placeholder={I18n.t('js.select_users')}
          element={null}
          handleUserClick={this.handleUserClick.bind(this)}
          handleUserRemove={this.handleUserRemove.bind(this)}
          validateInput={() => null}
          intent={null}
          expandMenu={false}
        />
      </Flexbox>
    );
  }

  render() {
    return (
      <Popover
        portalContainer={document.body}
        content={this.renderUserSuggest()}
        position="bottom"
        interactionKind={PopoverInteractionKind.CLICK}
      >
        <Button icon="new-person" text={I18n.t('js.add_colleague')} />
      </Popover>
    );
  }
}
