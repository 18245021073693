import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  PopoverInteractionKind,
  Popover,
} from '@blueprintjs/core';

@inject('AppStore', 'TemplateStore', 'TemplateActions')
@observer
export default class TemplateAddSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleAddSection() {
    this.toggleLoading();
    const sectionObj = {
      app_id: this.props.AppStore.activeApp.id,
      title: I18n.t('js.new_section'),
    };
    this.props.TemplateActions.createSection(sectionObj)
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleElementClick(elementType) {
    this.toggleLoading();
    const elementObj = {
      section_id: this.props.section.id,
      element_type: elementType,
      is_required: true,
      min_length: 0,
      max_length: 0,
    };
    this.props.TemplateActions.createElement(elementObj)
      .then(response => {
        this.toggleLoading();
        this.props.TemplateStore.setActiveArea(response.data.id);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderMenu() {
    return (
      <Menu>
        <MenuItem
          text={I18n.t('js.display_elements')}
          icon="eye-open"
        >
          <MenuItem
            text={I18n.t('js.title_element')}
            icon="header-two"
            disabled={false}
            onClick={() => this.handleElementClick('title')}
          />
          <MenuItem
            text={I18n.t('js.description_element')}
            icon="paragraph"
            disabled={false}
            onClick={() => this.handleElementClick('description')}
          />
          <MenuItem
            text={I18n.t('js.rich_textarea_element')}
            icon="manually-entered-data"
            disabled={false}
            onClick={() =>
              this.handleElementClick('rich_textarea_display')
            }
          />
          <MenuItem
            text={I18n.t('js.image_element')}
            icon="media"
            disabled={false}
            onClick={() => this.handleElementClick('image')}
          />
          <MenuItem
            text={I18n.t('js.link_element')}
            icon="link"
            disabled={false}
            onClick={() => this.handleElementClick('hyperlink')}
          />
          <MenuItem
            text={I18n.t('js.file_download_element')}
            icon="cloud-download"
            disabled={false}
            onClick={() => this.handleElementClick('file_download')}
          />
          <MenuItem
            text={I18n.t('js.profile_value_element')}
            icon="following"
            disabled={this.props.section.appId.length === 0}
            onClick={() => this.handleElementClick('profile_value')}
          />
        </MenuItem>
        <MenuDivider />
        <MenuItem text={I18n.t('js.text_elements')} icon="font">
          <MenuItem
            text={I18n.t('js.text_input_element')}
            icon="new-text-box"
            disabled={false}
            onClick={() => this.handleElementClick('text_input')}
          />
          <MenuItem
            text={I18n.t('js.textarea_element')}
            icon="align-justify"
            disabled={false}
            onClick={() => this.handleElementClick('textarea')}
          />
          <MenuItem
            text={I18n.t('js.multiline_element')}
            icon="add-to-artifact"
            disabled={false}
            onClick={() => this.handleElementClick('multiline')}
          />
          <MenuItem
            text={I18n.t('js.rich_textarea_element')}
            icon="manually-entered-data"
            disabled={false}
            onClick={() => this.handleElementClick('rich_textarea')}
          />
          <MenuItem
            text={I18n.t('js.email_element')}
            icon="envelope"
            disabled={false}
            onClick={() => this.handleElementClick('email')}
          />
        </MenuItem>
        <MenuItem text={I18n.t('js.option_elements')} icon="th-list">
          <MenuItem
            text={I18n.t('js.select_element')}
            icon="select"
            disabled={false}
            onClick={() => this.handleElementClick('select')}
          />
          <MenuItem
            text={I18n.t('js.grid_element')}
            icon="grid"
            disabled={false}
            onClick={() => this.handleElementClick('grid')}
          />
          <MenuItem
            text={I18n.t('js.user_select_element')}
            icon="person"
            disabled={false}
            onClick={() => this.handleElementClick('user_select')}
          />
          <MenuItem
            text={I18n.t('js.team_select_element')}
            icon="graph"
            disabled={false}
            onClick={() => this.handleElementClick('team_select')}
          />
          <MenuItem
            text={I18n.t('js.item_select_element')}
            icon="new-object"
            disabled={false}
            onClick={() => this.handleElementClick('item_select')}
          />
        </MenuItem>
        <MenuItem
          text={I18n.t('js.number_elements')}
          icon="numerical"
        >
          <MenuItem
            text={I18n.t('js.number_element')}
            icon="numerical"
            disabled={false}
            onClick={() => this.handleElementClick('number')}
          />
          <MenuItem
            text={I18n.t('js.currency_element')}
            icon="dollar"
            disabled={false}
            onClick={() => this.handleElementClick('currency')}
          />
          <MenuItem
            text={I18n.t('js.percentage_element')}
            icon="percentage"
            disabled={false}
            onClick={() => this.handleElementClick('percentage')}
          />
          <MenuItem
            text={I18n.t('js.slider_element')}
            icon="text-highlight"
            disabled={false}
            onClick={() => this.handleElementClick('slider')}
          />
          <MenuItem
            text={I18n.t('js.range_slider_element')}
            icon="settings"
            disabled={false}
            onClick={() => this.handleElementClick('range_slider')}
          />
          <MenuItem
            text={I18n.t('js.calculation')}
            icon="calculator"
            disabled={false}
            onClick={() => this.handleElementClick('calculation')}
          />
        </MenuItem>
        <MenuItem
          text={I18n.t('js.calendar_elements')}
          icon="calendar"
        >
          <MenuItem
            text={I18n.t('js.date_picker_element')}
            icon="calendar"
            disabled={false}
            onClick={() => this.handleElementClick('date')}
          />
          <MenuItem
            text={I18n.t('js.date_range_picker_element')}
            icon="timeline-events"
            disabled={false}
            onClick={() => this.handleElementClick('date_range')}
          />
          <MenuItem
            text={I18n.t('js.date_time_picker_element')}
            icon="notifications"
            disabled={false}
            onClick={() => this.handleElementClick('date_time')}
          />
          <MenuItem
            text={I18n.t('js.time_picker_element')}
            icon="time"
            disabled={false}
            onClick={() => this.handleElementClick('time')}
          />
        </MenuItem>
        <MenuItem text={I18n.t('js.web_elements')} icon="globe">
          <MenuItem
            text={I18n.t('js.hyperlink_input_element')}
            icon="link"
            disabled={false}
            onClick={() => this.handleElementClick('hyperlink_input')}
          />
          <MenuItem
            text={I18n.t('js.image_upload_element')}
            icon="media"
            disabled={false}
            onClick={() => this.handleElementClick('image_upload')}
          />
          <MenuItem
            text={I18n.t('js.file_upload_element')}
            icon="cloud-upload"
            disabled={false}
            onClick={() => this.handleElementClick('file_upload')}
          />
          {/* <MenuItem
            text={I18n.t('js.location_select_element')}
            icon="map-marker"
            disabled={false}
            onClick={() => this.handleElementClick('location_select')}
          /> */}
        </MenuItem>
      </Menu>
    );
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginTop="20px"
        marginBottom="20px"
      >
        <Popover
          portalContainer={document.body}
          content={this.renderMenu()}
          position="top"
          interactionKind={PopoverInteractionKind.CLICK}
          inline={true}
          autoFocus={false}
        >
          <Button
            text={I18n.t('js.add_element')}
            className="bp3-large"
            icon="add"
            loading={this.state.isLoading}
          />
        </Popover>
      </Flexbox>
    );
  }
}
