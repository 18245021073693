import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import {
  Button,
  Intent,
  Switch,
  Alert,
  Classes,
  Spinner,
  Divider,
  Checkbox,
  Label,
  Collapse,
} from '@blueprintjs/core';
import BambooIntegrationForm from './BambooIntegrationForm';
import BambooIntegrationFormLogic from './BambooIntegrationFormLogic';
import SyncTable from './SyncTable';

@inject(
  'BambooIntegrationStore',
  'ToastStore',
  'AccountSettingsStore',
  'IntegrationStore'
)
@observer
export default class BambooIntegration extends React.Component {
  @observable
  showDialog = false;
  @observable
  isLoading = false;
  @observable
  isDeleteAlert = false;
  @observable
  isPauseAlert = false;
  @observable
  isOpen = true;
  @observable
  initiateSync = false;

  constructor(props) {
    super(props);
    this.toggleBoolean = this.toggleBoolean.bind(this);
  }

  componentWillMount() {
    const BambooIntegrationStore = this.props.BambooIntegrationStore;
    if (!this.props.BambooIntegrationStore.hasBambooIntegration) {
      BambooIntegrationStore.fetchBambooIntegration()
        .then(() => {
          BambooIntegrationStore.toggleLoading();
        })
        .catch(() => {
          BambooIntegrationStore.toggleLoading();
        });
    }
    BambooIntegrationStore.fetchSyncs();
  }

  handleSaveChanges() {
    const BambooStore = this.props.BambooIntegrationStore;
    const bamboo_integration = BambooStore.bambooIntegration;
    const bambooID = bamboo_integration.id;
    const bambooIntegrationObj = {
      api_key: bamboo_integration.apiKey,
      subdomain: bamboo_integration.subdomain,
      include_profile_photos: bamboo_integration.includeProfilePhotos,
      include_departments: bamboo_integration.includeDepartments,
      include_managers: bamboo_integration.includeManagers,
      active: bamboo_integration.active,
    };
    BambooStore.updateBambooIntegration(
      bambooID,
      bambooIntegrationObj
    )
      .then(response => {})
      .catch(error => {
        reject(error);
        const errors = error.response.data.error.join(', ');
        ToastStore.showToast(errors, 'danger');
      });
  }

  updateActiveStatus() {
    this.props.BambooIntegrationStore.toggleActive();
    this.toggleBoolean('isPauseAlert');
    this.handleSaveChanges();
    if (this.initiateSync) {
      this.props.BambooIntegrationStore.runSynchronization();
    }
  }

  cancelChanges() {
    this.props.BambooIntegrationStore.cancelChanges();
  }

  deleteIntegration() {
    const bambooID = this.props.BambooIntegrationStore
      .bambooIntegration.id;
    this.props.BambooIntegrationStore.removeBambooIntegration();
    this.props.BambooIntegrationStore.deleteBambooIntegration(
      bambooID
    );
  }

  toggleBoolean(name) {
    this[`${name}`] = !this[`${name}`];
  }

  renderDeleteAlert() {
    return (
      <Alert
        portalContainer={document.body}
        cancelButtonText={I18n.t('js.cancel')}
        confirmButtonText={I18n.t('js.delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={this.isDeleteAlert}
        onCancel={() => this.toggleBoolean('isDeleteAlert')}
        onConfirm={() => this.deleteIntegration()}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t(
          'js.are_you_sure_you_wish_to_delete_this_integration'
        )}
      </Alert>
    );
  }

  renderPauseAlert() {
    const bamboo_integration = this.props.BambooIntegrationStore
      .bambooIntegration;
    const active = bamboo_integration.active || false;
    if (active == true) {
      return (
        <Alert
          portalContainer={document.body}
          cancelButtonText={I18n.t('js.cancel')}
          confirmButtonText={I18n.t('js.pause')}
          icon="pause"
          isOpen={this.isPauseAlert}
          onCancel={() => this.toggleBoolean('isPauseAlert')}
          onConfirm={() => this.updateActiveStatus()}
        >
          <h4 className={Classes.HEADING}>
            {I18n.t('js.are_you_sure')}
          </h4>
          {I18n.t('js.all_the_future_synchronization_will_be_paused')}
        </Alert>
      );
    } else {
      return (
        <Alert
          portalContainer={document.body}
          cancelButtonText={I18n.t('js.cancel')}
          confirmButtonText={I18n.t('js.activate')}
          icon="play"
          intent={Intent.SUCCESS}
          isOpen={this.isPauseAlert}
          onCancel={() => this.toggleBoolean('isPauseAlert')}
          onConfirm={() => this.updateActiveStatus()}
        >
          <h4 className={Classes.HEADING}>
            {I18n.t('js.are_you_sure')}
          </h4>
          {I18n.t('js.reactivate_your_integration')}
          <span>
            <Checkbox
              className="push-10-t"
              label={I18n.t('js.run_a_synchronization')}
              onChange={() => this.toggleBoolean('initiateSync')}
            />
          </span>
        </Alert>
      );
    }
  }

  renderForm() {
    const bamboo_integration = this.props.BambooIntegrationStore
      .bambooIntegration;
    const active = bamboo_integration.active || false;
    const canSave = this.props.BambooIntegrationStore.canSave;
    const hasNewChanges = this.props.BambooIntegrationStore
      .hasNewChanges;
    return (
      <div>
        <Flexbox flexDirection="column" className="push-10-b">
          <Flexbox
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            maxHeight="auto"
            width="100%"
            flexGrow="0"
            alignSelf="flex-start"
          >
            <h2
              className="push-0 pointer"
              onClick={() => this.toggleBoolean('isOpen')}
            >
              {I18n.t('js.configure_integration')}
            </h2>
            <Button
              text={
                this.isOpen ? I18n.t('js.close') : I18n.t('js.expand')
              }
              className="push-0 push-10-l"
              icon={this.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleBoolean('isOpen')}
            />
          </Flexbox>
        </Flexbox>

        <Collapse isOpen={this.isOpen}>
          <Flexbox justifyContent="flex-end" className="push-10-t">
            <Switch
              checked={active}
              large={false}
              label={
                active ? I18n.t('js.active') : I18n.t('js.paused')
              }
              onChange={() => this.toggleBoolean('isPauseAlert')}
            />
            {this.renderPauseAlert()}
          </Flexbox>
          <BambooIntegrationFormLogic>
            <BambooIntegrationForm />
          </BambooIntegrationFormLogic>

          <Button
            disabled={!canSave}
            className="bp3-icon-floppy-disk push-10-t push-10-r"
            text={I18n.t('js.save_changes')}
            onClick={this.handleSaveChanges.bind(this)}
          />
          <Button
            disabled={!hasNewChanges}
            className="bp3-icon-cross push-10-t push-10-r"
            text={I18n.t('js.cancel_changes')}
            onClick={this.cancelChanges.bind(this)}
          />
          <Button
            className="bp3-icon-trash push-10-t"
            text={I18n.t('js.delete_integration')}
            onClick={() => this.toggleBoolean('isDeleteAlert')}
          />
          {this.renderDeleteAlert()}
          <Divider className="push-30-t" />
        </Collapse>
      </div>
    );
  }

  render() {
    if (
      this.props.BambooIntegrationStore.isLoading ||
      this.props.AccountSettingsStore.isLoading
    ) {
      return (
        <Flexbox
          justifyContent="center"
          alignItems="center"
          paddingTop="150px"
          paddingBottom="150px"
        >
          <Spinner />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column" className="max-width">
          {this.renderForm()}
          <SyncTable />
        </Flexbox>
      );
    }
  }
}
