/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer, Provider } from 'mobx-react';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import AccountSettingsStore from '../stores/AccountSettingsStore';
import BambooIntegrationStore from '../stores/BambooIntegrationStore';
import AzureIntegrationStore from '../stores/AzureIntegrationStore';
import IntegrationStore from '../stores/IntegrationStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import AccountSettingsTabs from '../components/accounts/AccountSettingsTabs';
import AccountSettingsDetails from '../components/accounts/AccountSettingsDetails';
import AccountSettingsPreferences from '../components/accounts/AccountSettingsPreferences';
import AccountSettingsNotifications from '../components/accounts/AccountSettingsNotifications';
import AccountSettingsUsage from '../components/accounts/AccountSettingsUsage';
import IntegrationsView from './IntegrationsView';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class AccountSettingsView extends React.Component {
  componentDidMount() {
    AccountSettingsStore.resetStartingAttributes();
    if (this.props.tab) {
      AccountSettingsStore.setActiveTab(this.props.tab);
    }
    AccountSettingsStore.fetchAccount(this.props.accountId)
      .then(() => {
        AccountSettingsStore.fetchIntegrationTokens()
          .then(() => {
            AccountSettingsStore.toggleLoading();
          })
          .catch(() => {
            AccountSettingsStore.toggleLoading();
          });
      })
      .catch(() => {
        AccountSettingsStore.toggleLoading();
      });
    BambooIntegrationStore.fetchBambooIntegration()
      .then(() => {
        BambooIntegrationStore.toggleLoading();
      })
      .catch(() => {
        BambooIntegrationStore.toggleLoading();
      });
    AzureIntegrationStore.fetchAzureIntegration()
      .then(() => {
        AzureIntegrationStore.toggleLoading();
      })
      .catch(() => {
        AzureIntegrationStore.toggleLoading();
      });

    if (this.props.integrations_tab) {
      AccountSettingsStore.setActiveTab('integrations');
      IntegrationStore.setActiveTab(this.props.integrations_tab);
    }
  }

  renderSettings() {
    if (CurrentUserStore.isLoading || AccountSettingsStore.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (AccountSettingsStore.activeTab === 'details') {
      return <AccountSettingsDetails />;
    }
    if (AccountSettingsStore.activeTab === 'usage') {
      return <AccountSettingsUsage />;
    }
    if (AccountSettingsStore.activeTab === 'integrations') {
      return <IntegrationsView />;
    }
    if (AccountSettingsStore.activeTab === 'preferences') {
      return <AccountSettingsPreferences />;
    }
    if (AccountSettingsStore.activeTab === 'notifications') {
      return <AccountSettingsNotifications />;
    }
    return undefined;
  }

  render() {
    const stores = {
      AccountSettingsStore,
      CurrentUserStore,
      BambooIntegrationStore,
      ToastStore,
      IntegrationStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <AccountSettingsTabs />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderSettings()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
