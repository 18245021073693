import { action, observable, computed } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';
import Hook from '../models/HookModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Utilities from '../utils/Utilities';
import _ from 'lodash';

class HookStore {
  @observable hooks = [];
  @observable newHook = new Hook();
  @observable hookColumns = ColumnDefinitions.hookColumns;

  @action updateNewHook(data, attribute) {
    this.newHook[attribute] = data;
  }

  @action resetNewHook() {
    this.newHook = new Hook();
  }

  @action addHook(data) {
    const newHook = new Hook(...Utilities.getObjectValues(data));
    this.hooks = _.unionBy([newHook], this.hooks, 'id');
    this.sortHooks();
  }

  @action removeHook(hookId) {
    this.hooks = _.filter(this.hooks, o => {
      return o.id != hookId;
    });
    this.sortHooks();
  }

  @action
  fetchHooks() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/hooks`)
        .then(response => {
          _.map(response.data, hook => {
            this.addHook(hook);
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createHook(hookObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/hooks`, hookObj)
        .then(response => {
          this.addHook(response.data);
          this.resetNewHook();
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteHook(hookId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/hooks/${hookId}`)
        .then(response => {
          this.removeHook(hookId);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action setHookSortParams(param) {
    const newHookColumns = this.hookColumns;
    _.map(newHookColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.hookColumns = newHookColumns;
    this.sortHooks();
  }

  @action sortHooks() {
    let newHooks = this.hooks;
    const activeColumn = _.find(this.hookColumns, o => o.active);
    newHooks = _.orderBy(newHooks, [activeColumn.id], [activeColumn.sortDir]);
    this.hooks = newHooks;
  }

  @action resetStore() {
    this.hooks = [];
    this.newHook = new Hook();
    this.hookColumns = ColumnDefinitions.hookColumns;
  }
}

const store = new HookStore();
export default store;
