/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Menu, MenuItem, Popover, PopoverInteractionKind, Tab, Tabs, Tooltip, Button } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import TemplateStore from '../../stores/TemplateStore';
import _ from 'lodash';

@inject('CurrentUserStore', 'WorkspaceStore', 'AppStore', 'ToastStore')
@observer
export default class AppTabs extends React.Component {
  handleTabChange(tab) {
    if (tab == 'dataview') {
      window.location = `/apps/${this.props.AppStore.activeApp.id}?tab=dataview`;
      return;
    }
    this.props.AppStore.activeTab = tab;
  }

  renderActivityTitle() {
    return <span>{I18n.t('js.activity')}</span>;
  }

  renderUsageTitle() {
    return <span>{I18n.t('js.usage')}</span>;
  }

  renderItemsTitle() {
    return <span>{this.props.AppStore.activeApp.itemPlural}</span>;
  }

  renderTemplateTitle() {
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t('js.only_app_owners_can_edit_the_template')}
        disabled={this.props.AppStore.activeApp.userPermissionLevel.name === 'owner'}
        position="right"
      >
        <span>{I18n.t('js.edit_template')}</span>
      </Tooltip>
    );
  }

  renderEditTitle() {
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t('js.only_app_owners_can_edit_the_app')}
        disabled={this.props.AppStore.activeApp.userPermissionLevel.name === 'owner' || !this.props.CurrentUserStore.isSuperAdmin}
        position="right"
      >
        <span>{I18n.t('js.settings')}</span>
      </Tooltip>
    );
  }

  handleTemplateNavigation(tab) {
    this.props.AppStore.activeTab = 'template';
    TemplateStore.setActiveArea(null);
    TemplateStore.activeSubsection = tab;
  }

  renderPopover() {
    return (
      <Flexbox flexDirection="row" alignItems="center">
        <span>{I18n.t('js.template')}</span>
      </Flexbox>
    );
  }

  renderTemplateMenu() {
    return (
      <Tab id="template" disabled={this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner'}>
        <Tooltip
          portalContainer={document.body}
          content={I18n.t('js.only_app_owners_can_edit_the_template')}
          position="right"
          disabled={this.props.AppStore.activeApp.userPermissionLevel.name === 'owner'}
          inheritDarkTheme={true}
        >
          {this.renderPopover()}
        </Tooltip>
      </Tab>
    );
  }

  renderBreadCrumbs() {
    return (
      <Flexbox>
        <ul className={`${Classes.LIST} bp3-breadcrumbs`}>
          <li>
            <a className="bp3-breadcrumb" href="/workspaces">
              {I18n.t('js.workspaces')}
            </a>
          </li>
          <li>
            <a className="bp3-breadcrumb" href={`/workspaces/${this.props.AppStore.activeWorkspace.id}`}>
              {this.props.AppStore.activeWorkspace.name}
            </a>
          </li>
          <li>
            <span className="bp3-breadcrumb bp3-breadcrumb-current">
              {_.truncate(this.props.AppStore.activeApp.name, {
                length: 20,
              })}
            </span>
          </li>
        </ul>
      </Flexbox>
    );
  }

  renderTabs() {
    if (this.props.AppStore.activeApp.isStoreTemplate) {
      return (
        <Tabs
          id="apptabs"
          animate={true}
          selectedTabId={this.props.AppStore.activeTab}
          onChange={this.handleTabChange.bind(this)}
          className="noselect"
        >
          {this.renderTemplateMenu()}
          <Tab id="edit" disabled={this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner'} title={this.renderEditTitle()} />
          <Tabs.Expander />
        </Tabs>
      );
    }
    return (
      <Tabs
        id="apptabs"
        animate={this.props.AppStore.activeTab !== 'dataview'}
        selectedTabId={this.props.AppStore.activeTab}
        defaultSelectedTabId="items"
        onChange={this.handleTabChange.bind(this)}
        className="noselect"
      >
        <Tab id="items" title={I18n.t('js.app')} />
        <Tab id="dataview" title={I18n.t('js.data')} />
        <Tab id="activity" title={this.renderActivityTitle()} />
        <Tab id="usage" disabled={this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner'} title={this.renderUsageTitle()} />
        <Tab
          id="template"
          disabled={this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner'}
          title={I18n.t('js.template')}
          onClick={() => this.handleTemplateNavigation('template')}
        />
        <Tab id="edit" disabled={this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner'} title={this.renderEditTitle()} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  renderBackButton() {
    if (this.props.AppStore.activeApp.workspaceId === '' && this.props.AppStore.activeApp.isStoreTemplate) {
      return (
        <Flexbox flexGrow={0} flexDirection="column" alignItems="center" justifyContent="center">
          <AnchorButton href={`/store-templates/${this.props.AppStore.activeApp.storeTemplateId}`} rightIcon="undo" className="bp3-minimal">
            {I18n.t('js.store_template')}
          </AnchorButton>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={0} flexDirection="column" alignItems="center" justifyContent="center">
        <AnchorButton href={`/workspaces/${this.props.AppStore.activeApp.workspaceId}`} rightIcon="undo" className="bp3-minimal">
          {this.props.WorkspaceStore.activeWorkspace.name}
        </AnchorButton>
      </Flexbox>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <nav className="bp3-navbar bp3-navbar-bordered">
          <Flexbox className="fixed-width-container">
            <Flexbox className="bp3-navbar-group" justifyContent="center" flexGrow={1} />
          </Flexbox>
        </nav>
      );
    }
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderTabs()}
          </Flexbox>
          {this.renderBackButton()}
        </Flexbox>
      </nav>
    );
  }
}
