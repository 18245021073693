import React from 'react';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import OrganizationNewUserRelationshipSelectChildren from './OrganizationNewUserRelationshipSelectChildren';
import OrganizationNewUserRelationshipSelectParents from './OrganizationNewUserRelationshipSelectParents';
import _ from 'lodash';

@inject('OrganizationStore', 'AccountUsersStore', 'ToastStore')
@observer
export default class OrganizationNewUserRelationship extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false };
  }

  toggleSubmitting() {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    let totalLength =
      this.props.OrganizationStore.superUserRelationshipsToCreate.length +
      this.props.OrganizationStore.subUserRelationshipsToCreate.length +
      this.props.OrganizationStore.superUserRelationshipsToDelete.length +
      this.props.OrganizationStore.subUserRelationshipsToDelete.length;

    this.determineCloseDialog(totalLength);
    _.map(this.props.OrganizationStore.superUserRelationshipsToCreate, user => {
      this.props.OrganizationStore.createUserRelationship(
        this.props.OrganizationStore.activeUser.id,
        user.id,
        this.props.OrganizationStore.activeRelationship.id
      )
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleSubmitting();
        });
    });
    _.map(this.props.OrganizationStore.subUserRelationshipsToCreate, user => {
      this.props.OrganizationStore.createUserRelationship(
        user.id,
        this.props.OrganizationStore.activeUser.id,
        this.props.OrganizationStore.activeRelationship.id
      )
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleSubmitting();
        });
    });
    _.map(this.props.OrganizationStore.superUserRelationshipsToDelete, user => {
      const findRelationship = _.find(
        this.props.OrganizationStore.userRelationships,
        o => o.counterpartUserId === user.id && o.userId === this.props.OrganizationStore.activeUser.id
      );
      this.props.OrganizationStore.deleteUserRelationship(findRelationship)
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleSubmitting();
        });
    });
    _.map(this.props.OrganizationStore.subUserRelationshipsToDelete, user => {
      const findRelationship = _.find(
        this.props.OrganizationStore.userRelationships,
        o => o.counterpartUserId === this.props.OrganizationStore.activeUser.id && o.userId === user.id
      );
      this.props.OrganizationStore.deleteUserRelationship(findRelationship)
        .then(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        })
        .catch(() => {
          this.toggleSubmitting();
        });
    });
  }

  determineCloseDialog(length) {
    if (length === 0) {
      this.props.OrganizationStore.fetchNewlyAddedUsers().then(() => {
        this.props.OrganizationStore.removeHangingUsers().then(() => {
          this.props.OrganizationStore.setActiveUserRelationships();
          this.toggleSubmitting();
          this.props.toggleDialog();
        });
      });
    }
  }

  handleDialogClose() {
    this.props.OrganizationStore.resetTempArrays();
    this.props.toggleDialog();
  }

  render() {
    return (
      <Dialog
        isOpen={this.props.dialogOpen}
        onClose={() => this.handleDialogClose()}
        canOutsideClickClose={false}
        title={I18n.t('js.add_a_new_user_relationship', {
          type: this.props.OrganizationStore.activeRelationship.name.toLowerCase(),
          name: this.props.OrganizationStore.activeUser.fullName,
        })}
        portalContainer={document.body}
        inline={false}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column" flexGrow={1}>
              <Flexbox flexDirection="row" justifyContent="center" flexGrow={1}>
                <Avatar src={this.props.OrganizationStore.activeUser.imageFileName} size={100} round={true} />
              </Flexbox>
              <Flexbox flexDirection="row" justifyContent="center" flexGrow={1} marginTop="10px">
                <p className="bp3-text-muted">{this.props.OrganizationStore.activeUser.fullName}</p>
              </Flexbox>
              <Flexbox marginTop="20px" flexDirection="column" flexGrow={1}>
                <label className="bp3-label max-width">
                  {I18n.t('js.select_child_label', {
                    user: this.props.OrganizationStore.activeUser.firstName,
                    name: this.props.OrganizationStore.activeRelationship.name.toLowerCase(),
                  })}
                </label>
                <Flexbox flexGrow={1}>
                  <OrganizationNewUserRelationshipSelectChildren />
                </Flexbox>
              </Flexbox>
              <Flexbox marginTop="20px" flexDirection="column" flexGrow={1}>
                <label className="bp3-label max-width">
                  {I18n.t('js.select_parent_label', {
                    user: this.props.OrganizationStore.activeUser.firstName,
                    name: this.props.OrganizationStore.activeRelationship.name.toLowerCase(),
                  })}
                </label>
                <Flexbox flexGrow={1}>
                  <OrganizationNewUserRelationshipSelectParents />
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={() => this.handleDialogClose()} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.set_relationships')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}
