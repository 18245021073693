import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, Switch, NonIdealState, Spinner, AnchorButton, Intent, Alignment } from '@blueprintjs/core';
import ItemWidget from '../items/ItemWidget';
import Paginator from '../shared/Paginator';
import Emoji from '../shared/Emoji';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import FormElementDisplay from '../form_elements/FormElementDisplay';
import TeamAvatar from '../shared/TeamAvatar';
import moment from 'moment';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore'
)
@observer
export default class DashboardTeams extends React.Component {
  componentDidMount() {
    this.props.DynamicViewStore.loadTeams();
  }

  renderTags(team) {
    return (
      <Flexbox flexDirection="row">
        {team.private_team ? (
          <span className="bp3-tag bp3-intent-danger bp3-minimal bp3-group-tag noselect">{I18n.t('js.private')}</span>
        ) : null}
        {team.is_primary ? (
          <span className="bp3-tag bp3-intent-primary bp3-minimal bp3-group-tag noselect">{I18n.t('js.primary')}</span>
        ) : null}
        {team.is_default_account ? (
          <span className="bp3-tag bp3-intent-success bp3-minimal bp3-group-tag noselect">{I18n.t('js.default')}</span>
        ) : null}
      </Flexbox>
    );
  }

  render() {
    const { teams } = this.props.DynamicViewStore;
    if (this.props.DynamicViewStore.isLoading) {
      return <Flexbox flexDirection="column" width="360px" height="180px" marginBottom="20px" className="bp3-skeleton"></Flexbox>;
    }
    if (teams.length === 0) {
      return null;
    }
    return (
      <Flexbox flexDirection="column" marginBottom="20px">
        {_.map(teams, (team, index) => {
          if (index < 10) {
            return (
              <AnchorButton
                minimal={true}
                small={true}
                href={`/teams/${team.id}`}
                alignText={Alignment.LEFT}
                key={team.id}
                className="animated fadeIn"
              >
                <Flexbox flexDirection="row" key={team.id} className="bp3-cursor-pointer" padding="5px">
                  <Flexbox marginRight="10px">
                    <Avatar src={team.image_file_name} round={true} size={40} />
                  </Flexbox>
                  <Flexbox flexDirection="column">
                    <Flexbox flexDirection="row">
                      <h5 className="bp3-label-no-margin">{team.name}</h5>
                      {/* <span className='push-10-l'>{this.renderTags(team)}</span> */}
                    </Flexbox>
                    <Flexbox flexDirection="row">
                      <small className="bp3-text-muted">{I18n.t('js.team_member_count', { count: team.user_count })}</small>
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              </AnchorButton>
            );
          }
        })}
      </Flexbox>
    );
  }
}
