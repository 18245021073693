import React from 'react';
import Utilities from '../../utils/Utilities';
import Flexbox from 'flexbox-react';
import { Button, Classes, HTMLSelect, InputGroup, Intent, ProgressBar, Checkbox, Tag, Collapse } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import classNames from 'classnames';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Cookies from 'js-cookie';
import InjectedCheckoutForm from '../billing/AccountBillingCheckoutForm';
import _ from 'lodash';

@inject('ToastStore', 'SetupStore', 'CurrentUserStore', 'AccountBillingStore', 'AccountBillingActions')
@observer
export default class SetupCreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isBlurred: [],
      isFinishing: false,
      dialogOpen: false,
    };
  }

  setupComplete() {
    this.setState({ isFinishing: true });
    Utilities.navigate('/dashboard');
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  };

  nextStep() {
    this.props.SetupStore.setActiveStep(this.props.SetupStore.activeStep + 1);
  }

  previousStep() {
    this.props.SetupStore.setActiveStep(this.props.SetupStore.activeStep - 1);
  }

  handleAccountPurposeChange(attribute) {
    if (!_.includes(this.state.isBlurred, 'accountPurpose')) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push('accountPurpose');
      this.setState({ isBlurred: newBlurState });
    }
    const oldPurpose = this.props.SetupStore.newAccount.accountPurpose;
    let newPurpose = '';
    if (oldPurpose.includes(`, ${attribute}`) || oldPurpose.includes(attribute)) {
      if (oldPurpose.includes(`, ${attribute}`)) {
        newPurpose = oldPurpose.replace(`, ${attribute}`, '');
      } else {
        if (oldPurpose.includes(attribute)) {
          newPurpose = oldPurpose.replace(attribute, '');
        }
      }
    } else {
      if (oldPurpose.length === 0) {
        newPurpose = attribute;
      } else {
        newPurpose = this.props.SetupStore.newAccount.accountPurpose + ', ' + attribute;
      }
    }
    if (newPurpose.substring(0, 2) == ', ') {
      newPurpose = newPurpose.substring(2);
    }
    this.props.SetupStore.updateNewAccount(newPurpose, 'accountPurpose');
  }

  handleInputChange(e) {
    if (e.target.name === 'email') {
      this.props.SetupStore.updateNewInvitation(e.target.value, e.target.name);
    } else {
      this.props.SetupStore.updateNewAccount(e.target.value, e.target.name);
    }
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (field === 'email') {
      if (_.includes(this.state.isBlurred, field) && !this.props.SetupStore.newInvitation[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.props.SetupStore.newInvitation[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.props.SetupStore.newAccount[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.props.SetupStore.newAccount[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  handleSubmitStep1(e) {
    e.preventDefault();
    if (
      this.props.SetupStore.newAccount.nameValid &&
      this.props.SetupStore.newAccount.accountPurposeValid &&
      this.props.SetupStore.newAccount.referralSourceValid
    ) {
      this.nextStep();
    } else {
      this.setState({
        isBlurred: ['name', 'accountPurpose', 'accountPurposeOther', 'referralSource'],
      });
    }
  }

  handleSubmitStep2(e) {
    e.preventDefault();
    if (
      this.props.SetupStore.newAccount.ownerRoleValid &&
      this.props.SetupStore.newAccount.ownerPhoneValid &&
      this.props.SetupStore.newAccount.accountSizeValid &&
      this.props.SetupStore.newAccount.implementationTimeValid
    ) {
      this.nextStep();
    } else {
      this.setState({
        isBlurred: ['ownerRole', 'ownerPhone', 'accountSize', 'implementationTime'],
      });
    }
  }

  handleSubmitStep3(e) {
    e.preventDefault();
    if (this.props.SetupStore.newAccount.subdomainValid) {
      this.toggleLoading();
      this.props.SetupStore.createAccount()
        .then(() => {
          this.nextStep();
          this.toggleLoading();
        })
        .catch(() => {
          this.toggleLoading();
        });
    } else {
      this.setState({ isBlurred: ['subdomain'] });
    }
  }

  handleSubmitStep4(e) {
    e.preventDefault();
    if (this.props.SetupStore.newInvitation.emailValid) {
      this.toggleLoading();
      this.props.SetupStore.createInvitation()
        .then(() => {
          this.toggleLoading();
          this.setState({ isBlurred: [] });
        })
        .catch(() => {
          this.toggleLoading();
        });
    } else {
      this.setState({ isBlurred: ['email'] });
    }
  }

  renderStep1() {
    if (this.props.SetupStore.activeStep === 1) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="animated fadeIn">
          <form onSubmit={this.handleSubmitStep1.bind(this)}>
            <h1 className={`push-20-t ${Classes.HEADING}`}>{I18n.t('js.choose_an_account_name')}</h1>
            <p className="bp3-running-text push-20-t">{I18n.t('js.choose_a_name_to_represent_your_account')}</p>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'name') && !this.props.SetupStore.newAccount.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.account_name')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    className={
                      _.includes(this.state.isBlurred, 'name') && !this.props.SetupStore.newAccount.nameValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-large bp3-fill"
                      autoFocus
                      type="text"
                      placeholder={I18n.t('js.example_inc')}
                      name="name"
                      value={this.props.SetupStore.newAccount.name}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('name')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'accountPurpose') && !this.props.SetupStore.newAccount.accountPurposeValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="accountPurpose">
                  {I18n.t('js.how_are_you_planning_to_use_peoplegoal')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="column"
                    alignItems="stretch"
                    alignContent="stretch"
                    alignSelf="stretch"
                    className={
                      _.includes(this.state.isBlurred, 'accountPurpose') && !this.props.SetupStore.newAccount.accountPurposeValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <Flexbox flexDirection="row" flexGrow={1}>
                      <Flexbox flexDirection="column" className="push-40-r">
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('Employee Onboarding')}
                          label="Employee Onboarding"
                          onChange={() => this.handleAccountPurposeChange('Employee Onboarding')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('OKRs')}
                          label="OKRs"
                          onChange={() => this.handleAccountPurposeChange('OKRs')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('SMART Goals')}
                          label="SMART Goals"
                          onChange={() => this.handleAccountPurposeChange('SMART Goals')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('Engagement Surveys')}
                          label="Engagement Surveys"
                          onChange={() => this.handleAccountPurposeChange('Engagement Surveys')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('360 Feedback')}
                          label="360 Feedback"
                          onChange={() => this.handleAccountPurposeChange('360 Feedback')}
                        />
                      </Flexbox>
                      <Flexbox flexDirection="column">
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('Performance Reviews')}
                          label="Performance Reviews"
                          onChange={() => this.handleAccountPurposeChange('Performance Reviews')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('One-to-Ones')}
                          label="One-to-Ones"
                          onChange={() => this.handleAccountPurposeChange('One-to-Ones')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('HRIS')}
                          label="HRIS"
                          onChange={() => this.handleAccountPurposeChange('HRIS')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('Custom Workflows')}
                          label="Custom Workflows"
                          onChange={() => this.handleAccountPurposeChange('Custom Workflows')}
                        />
                        <Checkbox
                          checked={this.props.SetupStore.newAccount.accountPurpose.includes('Other')}
                          label="Other"
                          onChange={() => this.handleAccountPurposeChange('Other')}
                        />
                      </Flexbox>
                    </Flexbox>
                  </Flexbox>
                  {this.renderErrors('accountPurpose')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox flexGrow={1} className="bp3-form-group">
                <label className="bp3-label" htmlFor="accountPurposeOther">
                  {I18n.t('js.if_you_selected_other_please_describe')}
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="column"
                    alignItems="stretch"
                    alignContent="stretch"
                    alignSelf="stretch"
                    className="bp3-input-group"
                  >
                    <textarea
                      className="bp3-input bp3-textarea bp3-large bp3-fill"
                      type="textarea"
                      placeholder={I18n.t('js.automate_internal_processes')}
                      name="accountPurposeOther"
                      rows="10"
                      value={this.props.SetupStore.newAccount.accountPurposeOther}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'referralSource') && !this.props.SetupStore.newAccount.referralSourceValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="referralSource">
                  {I18n.t('js.how_did_you_hear_about_us')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'referralSource') && !this.props.SetupStore.newAccount.referralSourceValid
                        ? classNames('bp3-large bp3-fill bp3-intent-danger')
                        : classNames('bp3-large bp3-fill')
                    }
                  >
                    <HTMLSelect
                      name="referralSource"
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                    >
                      <option value="" defaultValue>
                        {I18n.t('js.choose_an_option')}
                      </option>
                      <option value="Capterra">Capterra</option>
                      <option value="GetApp">GetApp</option>
                      <option value="Google">Google</option>
                      <option value="Word of mouth">Word of mouth</option>
                      <option value="Twitter">Twitter</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Ad">Ad</option>
                      <option value="Event">Event</option>
                      <option value="Other">Other</option>
                    </HTMLSelect>
                  </Flexbox>
                  {this.renderErrors('referralSource')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Collapse isOpen={this.props.SetupStore.newAccount.referralSource === 'Other'}>
              <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
                <Flexbox flexGrow={1} className="bp3-form-group">
                  <label className="bp3-label" htmlFor="referralSourceOther">
                    {I18n.t('js.if_you_selected_other_please_describe_how_you_heard_about_us')}
                  </label>
                  <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                    <Flexbox
                      flexGrow={1}
                      flexDirection="column"
                      alignItems="stretch"
                      alignContent="stretch"
                      alignSelf="stretch"
                      className="bp3-input-group"
                    >
                      <textarea
                        className="bp3-input bp3-textarea bp3-large bp3-fill"
                        type="textarea"
                        name="referralSourceOther"
                        rows="1"
                        value={this.props.SetupStore.newAccount.referralSourceOther}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </Collapse>
            <Flexbox flexDirection="row">
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                className="bp3-large bp3-icon-arrow-right bp3-align-right"
                text={I18n.t('js.next_step')}
                disabled={!this.props.SetupStore.step1Valid}
              />
              <Button
                type="button"
                className="bp3-large bp3-minimal push-10-l"
                text={I18n.t('js.back')}
                onClick={() => this.props.SetupStore.setActiveSetup('none')}
              />
            </Flexbox>
          </form>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderStep2() {
    // eslint-disable-next-line
    if (this.props.SetupStore.activeStep === 2) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="animated fadeIn">
          <form onSubmit={this.handleSubmitStep2.bind(this)}>
            <h1 className={`push-20-t ${Classes.HEADING}`}>
              {I18n.t('js.tell_us_about_your_role', {
                accountName: this.props.SetupStore.newAccount.name,
              })}
            </h1>
            <p className="bp3-running-text push-20-t">{I18n.t('js.about_your_role')}</p>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'ownerRole') && !this.props.SetupStore.newAccount.ownerRoleValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="ownerRole">
                  {I18n.t('js.select_your_role')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'ownerRole') && !this.props.SetupStore.newAccount.ownerRoleValid
                        ? classNames('bp3-large bp3-fill bp3-intent-danger')
                        : classNames('bp3-large bp3-fill')
                    }
                  >
                    <HTMLSelect
                      name="ownerRole"
                      autoFocus
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                    >
                      <option value="" defaultValue>
                        {I18n.t('js.choose_an_option')}
                      </option>
                      <option value="CEO">CEO</option>
                      <option value="C-Suite Executive">C-Suite Executive</option>
                      <option value="Senior Manager">Senior Manager</option>
                      <option value="Manager">Manager</option>
                      <option value="Team leader">Team leader</option>
                      <option value="Team member">Team member</option>
                      <option value="Developer">Developer</option>
                      <option value="Consultant">Consultant</option>
                      <option value="Other">Other</option>
                    </HTMLSelect>
                  </Flexbox>
                  {this.renderErrors('ownerRole')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'accountSize') && !this.props.SetupStore.newAccount.accountSizeValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="accountSize">
                  {I18n.t('js.select_account_size')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'accountSize') && !this.props.SetupStore.newAccount.accountSizeValid
                        ? 'bp3-large bp3-fill bp3-intent-danger'
                        : 'bp3-large bp3-fill'
                    }
                  >
                    <HTMLSelect name="accountSize" onChange={this.handleInputChange.bind(this)} onBlur={this.handleBlurChange.bind(this)}>
                      <option value="" defaultValue>
                        {I18n.t('js.choose_an_option')}
                      </option>
                      <option value="0-10">0-10</option>
                      <option value="10-50">10-50</option>
                      <option value="50-100">50-100</option>
                      <option value="100-200">100-200</option>
                      <option value="200-500">200-500</option>
                      <option value="500-1000">500-1000</option>
                      <option value="1000+">1000+</option>
                    </HTMLSelect>
                  </Flexbox>
                  {this.renderErrors('accountSize')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'implementationTime') && !this.props.SetupStore.newAccount.implementationTimeValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="implementationTime">
                  {I18n.t('js.how_soon_would_you_like_to_implement_a_solution')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'implementationTime') && !this.props.SetupStore.newAccount.implementationTimeValid
                        ? 'bp3-large bp3-fill bp3-intent-danger'
                        : 'bp3-large bp3-fill'
                    }
                  >
                    <HTMLSelect
                      name="implementationTime"
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                    >
                      <option value="" defaultValue>
                        {I18n.t('js.choose_an_option')}
                      </option>
                      <option value="0-1">0 to 1 month</option>
                      <option value="1-3">1 to 3 months</option>
                      <option value="3-6">3 to 6 months</option>
                      <option value="6+">6 months or longer</option>
                    </HTMLSelect>
                  </Flexbox>
                  {this.renderErrors('implementationTime')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'ownerPhone') && !this.props.SetupStore.newAccount.ownerPhoneValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="ownerPhone">
                  {I18n.t('js.your_phone_number')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    className={
                      _.includes(this.state.isBlurred, 'ownerPhone') && !this.props.SetupStore.newAccount.ownerPhoneValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-large bp3-fill"
                      type="text"
                      placeholder="+1-555-123-456"
                      name="ownerPhone"
                      value={this.props.SetupStore.newAccount.ownerPhone}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('ownerPhone')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row">
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                className="bp3-large bp3-icon-arrow-right bp3-align-right"
                text={I18n.t('js.next_step')}
                disabled={!this.props.SetupStore.step2Valid}
              />
              <Button
                type="button"
                className="bp3-large bp3-minimal push-10-l"
                text={I18n.t('js.back')}
                onClick={() => this.previousStep()}
              />
            </Flexbox>
          </form>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderStep3() {
    // eslint-disable-next-line no-magic-numbers
    if (this.props.SetupStore.activeStep === 3) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="animated fadeIn">
          <form onSubmit={this.handleSubmitStep3.bind(this)}>
            <h1 className={`push-20-t ${Classes.HEADING}`}>{I18n.t('js.set_a_subdomain')}</h1>
            <p className="bp3-running-text push-20-t">{I18n.t('js.set_a_subdomain_details')}</p>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'subdomain') && !this.props.SetupStore.newAccount.subdomainValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="subdomain">
                  {I18n.t('js.subdomain')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'subdomain') && !this.props.SetupStore.newAccount.subdomainValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <InputGroup
                      autoComplete="off"
                      className="bp3-fill bp3-large"
                      autoFocus
                      type="text"
                      placeholder={I18n.t('js.example')}
                      name="subdomain"
                      value={this.props.SetupStore.newAccount.subdomain}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      leftIcon="globe"
                      rightElement={<Tag className="bp3-intent-success">.peoplegoal-v2.com </Tag>}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('subdomain')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row">
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                className="bp3-large bp3-icon-tick bp3-align-right"
                text={I18n.t('js.create_your_account')}
                disabled={!this.props.SetupStore.newAccount.subdomainValid}
                loading={this.state.isSubmitting}
              />
              <Button
                type="button"
                className="bp3-large bp3-minimal push-10-l"
                text={I18n.t('js.back')}
                onClick={() => this.previousStep()}
              />
            </Flexbox>
          </form>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderStep4() {
    if (this.props.SetupStore.activeStep === 4) {
      return (
        <Flexbox flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} className="animated fadeIn" width="930px">
          <h1 className={`push-20-t ${Classes.HEADING}`}>{I18n.t('js.your_free_trial')}</h1>
          <p className="bp3-running-text push-20-t">{I18n.t('js.your_free_trial_description')}</p>
          <Flexbox flexDirection="row" className="push-10-t">
            <Button
              intent={Intent.PRIMARY}
              type="button"
              className="bp3-large"
              text={I18n.t('js.start_free_trial')}
              onClick={this.toggleDialog.bind(this)}
            />
          </Flexbox>
          <StripeProvider apiKey={Cookies.get('stripePublishableKey')}>
            <Elements
              fonts={[
                {
                  cssSrc: 'https://fonts.googleapis.com/css?family=Inter:300,400,600,700',
                },
              ]}
            >
              <InjectedCheckoutForm toggleDialog={this.toggleDialog.bind(this)} dialogOpen={this.state.dialogOpen} />
            </Elements>
          </StripeProvider>
        </Flexbox>
      );
    }
  }

  renderStep4Invitations() {
    // eslint-disable-next-line no-magic-numbers
    if (this.props.SetupStore.activeStep === 4) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="animated fadeIn">
          <form onSubmit={this.handleSubmitStep4.bind(this)}>
            <h1 className={`push-20-t ${Classes.HEADING}`}>{I18n.t('js.create_account_success')}</h1>
            <p className="bp3-running-text push-20-t">{I18n.t('js.invite_team_members')}</p>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'email') && !this.props.SetupStore.newInvitation.emailValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="email">
                  {I18n.t('js.email')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'email') && !this.props.SetupStore.newInvitation.emailValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <InputGroup
                      autoComplete="off"
                      className="bp3-fill bp3-large"
                      type="text"
                      placeholder={I18n.t('js.someone_example_com')}
                      name="email"
                      value={this.props.SetupStore.newInvitation.email}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('email')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row">
              <Button
                type="submit"
                className="bp3-large bp3-icon-envelope bp3-align-right"
                text={I18n.t('js.send_invitation')}
                disabled={!this.props.SetupStore.newInvitation.emailValid}
                loading={this.state.isSubmitting}
              />
              <Button
                intent={Intent.PRIMARY}
                type="button"
                className="bp3-large push-10-l"
                text={I18n.t('js.go_to_your_account')}
                onClick={() => this.setupComplete()}
                loading={this.state.isFinishing}
              />
            </Flexbox>
            {this.renderInvitations()}
          </form>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderInvitations() {
    if (this.props.SetupStore.invitations.length > 0) {
      return (
        <Flexbox flexDirection="column" className="push-20-t">
          <h4 className={`${Classes.HEADING} push-20-t`}>{I18n.t('js.invitations_sent_to')}</h4>
          {_.map(this.props.SetupStore.invitations, invitation => (
            <Flexbox flexDirection="row" alignItems="center" className="bp3-card push-10-t" key={invitation.id}>
              <Avatar
                email={invitation.email}
                size={40}
                round={true}
                className="push-10-r"
                src={`https://i2.wp.com/cdn.auth0.com/avatars/${Utilities.getDisplayInitials(invitation.email)}.png`}
              />{' '}
              {invitation.email}
            </Flexbox>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderProgress() {
    let progressClass = classNames('push-20-t push-50-b');
    // FIXME this probably wasn't meant to be an expression
    // eslint-disable-next-line
    this.props.SetupStore.activeStep === 4 ? (progressClass = classNames('push-20-t push-50-b bp3-no-stripes')) : null;
    return (
      <Flexbox flexDirection="column" alignItems="center">
        <small className="bp3-text-muted">
          {I18n.t('js.step_of', {
            current: this.props.SetupStore.activeStep,
            total: this.props.SetupStore.totalSteps,
          })}
        </small>
        <ProgressBar
          intent={Intent.PRIMARY}
          value={this.props.SetupStore.activeStep / this.props.SetupStore.totalSteps}
          className={progressClass}
        />
      </Flexbox>
    );
  }
  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column" alignItems="stretch" alignContent="stretch" alignSelf="stretch">
        {this.renderProgress()}
        {this.renderStep1()}
        {this.renderStep2()}
        {this.renderStep3()}
        {this.renderStep4()}
      </Flexbox>
    );
  }
}
