import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Intent, Dialog } from '@blueprintjs/core';
import { observable } from 'mobx';
import classNames from 'classnames';
import ToastStore from '../../../stores/ToastStore';
import Utilities from '../../../utils/Utilities';
import AzureIntegrationForm from './AzureIntegrationForm';
import AzureIntegrationFormLogic from './AzureIntegrationFormLogic';

@inject('AzureIntegrationStore', 'IntegrationStore', 'ToastStore')
@observer
export default class NewAzureIntegration extends React.Component {
  @observable
  showDialog = false;
  @observable
  errors = {};

  constructor(props) {
    super(props);
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }

  handleSubmit() {
    const AzureStore = this.props.AzureIntegrationStore;
    const {
      clientId,
      clientSecret,
      clientUri,
      clientTenant,
      includeProfilePhotos,
      includeDepartments,
      includeManagers,
    } = AzureStore.azureIntegration;

    if (clientId != '' && clientSecret != '' && clientUri != '' && clientTenant != '') {
      const azureIntegrationObj = {
        client_id: clientId,
        client_secret: clientSecret,
        client_uri: clientUri,
        client_tenant: clientTenant,
        include_profile_photos: includeProfilePhotos,
        include_departments: includeDepartments,
        include_managers: includeManagers,
      };
      AzureStore.createAzureIntegration(azureIntegrationObj)
        .then(response => {
          AzureStore.updateIntegrationModel(response.data);
          this.props.IntegrationStore.setActiveTab('azure-integration');
        })
        .catch(error => {});
    }
  }

  handleCancel() {
    this.toggleDialog();
  }

  renderAzureIntegrationDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.connect_azure_account')}
      >
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="bp3-dialog-body">
            <AzureIntegrationFormLogic>
              <AzureIntegrationForm />
            </AzureIntegrationFormLogic>
          </div>

          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
              <Button intent={'PRIMARY'} onClick={this.handleSubmit.bind(this)} text={I18n.t('js.connect')} />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  renderAzureIntegration() {
    const { azureIntegration } = this.props.AzureIntegrationStore;

    const buttonText =
      azureIntegration != null && azureIntegration.id != '' ? I18n.t('js.configure_azure_integration') : I18n.t('js.connect_azure_account');
    const onClick =
      azureIntegration != null && azureIntegration.id != ''
        ? () => this.props.IntegrationStore.setActiveTab('azure-integration')
        : this.toggleDialog.bind(this);

    return (
      <div>
        <Flexbox>
          <Button text={buttonText} icon="offline" onClick={onClick} />
        </Flexbox>
        {this.renderAzureIntegrationDialog()}
      </div>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.azure')}
            </h4>
            <p className="noselect">{I18n.t('js.azure_integration_text')}</p>
          </Flexbox>
        </Flexbox>
        {this.renderAzureIntegration()}
      </Flexbox>
    );
  }
}
