/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { Spinner } from '@blueprintjs/core';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import AppStore from '../stores/AppStore';
import ItemStore from '../stores/ItemStore';
import TemplateStore from '../stores/TemplateStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import UserProfileStore from '../stores/UserProfileStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import ToastStore from '../stores/ToastStore';
import ItemActions from '../actions/ItemActions';
import TemplateActions from '../actions/TemplateActions';
import LayoutHeader from '../components/layout/LayoutHeader';
import UserProfileEditTemplate from '../components/user_profile_template/UserProfileEditTemplate';
import UserProfileTemplateHeader from '../components/user_profile_template/UserProfileTemplateHeader';
import StoreTemplateProfileTemplateTabs from '../components/user_profile_template/StoreTemplateProfileTemplateTabs';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class StoreTemplateProfileTemplateView extends React.Component {
  componentDidMount() {
    UserProfileTemplateStore.resetStartingAttributes();
    TemplateActions.fetchProfileTemplate(this.props.profileTemplateId)
      .then(response => {
        UserProfileTemplateStore.addProfileTemplate(response.data);
        UserProfileTemplateStore.activeProfileTemplate = _.head(UserProfileTemplateStore.profileTemplates);
        UserProfileTemplateStore.isEditingTemplate = true;
        UserProfileTemplateStore.toggleLoading();
      })
      .catch(() => {
        UserProfileTemplateStore.toggleLoading();
      });
  }

  renderContent() {
    if (UserProfileTemplateStore.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="50px" paddingBottom="50px" flexGrow={1}>
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <UserProfileTemplateHeader />
        <UserProfileEditTemplate />
      </Flexbox>
    );
  }

  render() {
    const stores = {
      CurrentUserStore,
      AppStore,
      ItemStore,
      TemplateStore,
      ItemActions,
      UserProfileStore,
      UserProfileTemplateStore,
      StoreTemplateStore,
      StoreTemplateActions,
      TemplateActions,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <LayoutHeader
                title={UserProfileTemplateStore.activeProfileTemplate.name}
                subtitle={I18n.t('js.profile_template')}
                icon={UserProfileTemplateStore.activeProfileTemplate.icon}
                loading={UserProfileTemplateStore.isLoading || CurrentUserStore.isLoading}
                tabs={<StoreTemplateProfileTemplateTabs />}
              />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
