/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';
import ToastStore from '../stores/ToastStore';
import ContentfulClient from '../static/ContentfulClient';

class StoreTemplateActions {
  @action
  installStoreTemplate(installObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/store_templates/${installObj.id}/install`, installObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchStoreCategories() {
    return new Promise((resolve, reject) => {
      ContentfulClient.getEntries({
        content_type: 'storeCategory',
        order: 'fields.title',
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchYourStoreTemplates(xPage, xPerPage, xOffset) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/store_templates?page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWorkspaces() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/workspaces`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWorkspaceFolders(workspaceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/workspace_folders?workspace_id=${workspaceId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchStoreTemplate(storeTemplateId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/store_templates/${storeTemplateId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStoreTemplate(storeTemplateObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/store_templates`, storeTemplateObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStoreTemplate(storeTemplateObj) {
    let id = storeTemplateObj.id;
    if (storeTemplateObj instanceof FormData) {
      id = storeTemplateObj.get('id');
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/store_templates/${id}`, storeTemplateObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteStoreTemplate(storeTemplateId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/store_templates/${storeTemplateId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStoreCategoryStoreTemplate(storeCategoryStoreTemplateObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/store_category_store_templates`, storeCategoryStoreTemplateObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteStoreCategoryStoreTemplate(storeCategoryStoreTemplateId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/store_category_store_templates/${storeCategoryStoreTemplateId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}

const store = new StoreTemplateActions();
export default store;
