import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsPreferences extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="column">Preferences go here</Flexbox>
    );
  }
}
