/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import TeamUsersStore from '../../stores/TeamUsersStore';
import Avatar from 'react-avatar';
import User from '../../models/UserModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import classNames from 'classnames';
import nanoid from 'nanoid';
import _ from 'lodash';

const UserSuggest = Suggest.ofType(User);

export default class TeamUsersNewUserSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedUser: '', query: '', isQuerying: false };
  }

  toggleQuerying() {
    this.setState({
      isQuerying: !this.state.isQuerying,
    });
  }

  renderUser(item, details) {
    let menuClass = classNames('popover-larger');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>
            <Flexbox marginLeft="10px" marginTop="2px">
              {item.fullName}
            </Flexbox>
          </Flexbox>
        }
      />
    );
  }

  renderInputValue(user) {
    return user.fullName;
  }

  handleItemSelect(user) {
    this.handleCancel();
    TeamUsersStore.addNewUserTeam(user);
  }

  handleSearchChange(query) {
    const requestId = nanoid();
    this.setState({ isQuerying: false });
    this.setState({ query });

    if (query.length <= 1) {
      TeamUsersStore.resetSearchUsers();
    } else {
      this.setState({ isQuerying: true });
      TeamUsersStore.resetSearchUsers().then(() => {
        TeamUsersStore.fetchUsersQuery(query, requestId)
          .then(() => {
            this.setState({ isQuerying: false });
          })
          .catch(() => {
            this.setState({ isQuerying: false });
          });
      });
    }
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.state.isQuerying) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-people push-5-r" /> <span> {I18n.t('js.no_users_found')} </span>
          </span>
        }
      />
    );
  }

  filterUsers() {
    let possibleUsers = TeamUsersStore.searchUsers.slice();
    _.map(TeamUsersStore.userTeams, userTeam => {
      const findUser = _.find(TeamUsersStore.searchUsers.slice(), o => o.id === userTeam.userId);
      if (findUser) {
        possibleUsers = _.filter(possibleUsers, o => o.id !== findUser.id);
      }
    });
    return possibleUsers;
  }

  handleCancel() {
    TeamUsersStore.resetSearchUsers();
    this.setState({ query: '' });
  }

  renderCancel() {
    if (this.state.isQuerying) {
      return <Spinner size={20} />;
    }
    if (this.state.query.length > 0) {
      return <button type="button" className="bp3-button bp3-icon-cross bp3-minimal" onClick={this.handleCancel.bind(this)} />;
    }
    return undefined;
  }

  render() {
    return (
      <UserSuggest
        className="max-width"
        resetOnSelect={true}
        items={TeamUsersStore.searchUsers.slice()}
        itemListPredicate={this.filterUsers.bind(this)}
        itemRenderer={this.renderUser.bind(this)}
        onItemSelect={this.handleItemSelect.bind(this)}
        inputValueRenderer={this.renderInputValue.bind(this)}
        validateInput={() => null}
        intent={null}
        noResults={this.renderResultSearch()}
        inputProps={{
          placeholder: I18n.t('js.search_users'),
          className: '',
          leftIcon: 'search',
          rightElement: this.renderCancel(),
        }}
        query={this.state.query}
        onQueryChange={this.handleSearchChange.bind(this)}
        popoverProps={{
          usePortal: false,
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handleCancel.bind(this),
        }}
      />
    );
  }
}
