import { action, observable } from 'mobx';
import { Intent } from '@blueprintjs/core';
import Toast from '../models/ToastModel';

class ToastStore {
  @observable
  toasts = [];
  @observable
  latestToast = new Toast();

  @action
  showToast(message, intent) {
    let newIntent = Intent.PRIMARY;
    let newIcon = 'info-sign';
    switch (intent) {
      case 'danger':
        newIntent = Intent.DANGER;
        newIcon = 'error';
        break;
      case 'success':
        newIntent = Intent.SUCCESS;
        newIcon = 'tick';
        break;
      case 'warning':
        newIntent = Intent.WARNING;
        newIcon = 'warning-sign';
        break;
      case 'primary':
        newIntent = Intent.PRIMARY;
        newIcon = 'info-sign';
        break;
      case 'default':
        newIcon = 'info-sign';
        break;
      default:
        newIcon = 'info-sign';
    }
    const newToast = new Toast('', message, newIntent, newIcon);
    this.latestToast = newToast;
    this.toasts = this.toasts.concat(newToast);
  }

  @action
  showSavingToast(message) {
    const newToast = new Toast(
      '',
      message,
      Intent.SUCCESS,
      'cloud-upload'
    );
    this.latestToast = newToast;
    this.toasts = this.toasts.concat(newToast);
  }
}

const store = new ToastStore();
export default store;
