// @flow
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import type { DataViewStore } from '../../stores/DataViewStore';

type NewReportTemplateProps = {
  dialogOpen: boolean,
  isSaving: boolean,
  toggleDialog: Function,
  handleSaveNewReportTemplate: Function,
  DataViewStore: DataViewStore,
};

type NewReportTemplateState = {
  isBlurred: Array<any>,
};

@inject('DataViewStore', 'DataViewActions')
@observer
export default class NewReportTemplate extends React.Component<
  NewReportTemplateProps,
  NewReportTemplateState
> {
  constructor(props: NewReportTemplateProps) {
    super(props);

    this.state = { isBlurred: [] };
  }

  toggleDialog() {
    this.setState({ isBlurred: [] });
    this.props.toggleDialog();
  }

  handleSave() {
    this.setState({ isBlurred: [] });
    this.props.handleSaveNewReportTemplate();
  }

  handleInputChange(e: SyntheticEvent<HTMLInputElement>) {
    this.props.DataViewStore.updateNewReportTemplate(
      e.currentTarget.value,
      e.currentTarget.name
    );
  }

  handleBlurChange(e: SyntheticEvent<HTMLInputElement>) {
    if (!_.includes(this.state.isBlurred, e.currentTarget.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.currentTarget.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(
    field: 'name' | 'description'
  ): React.Element<'div'> | void {
    if (
      _.includes(this.state.isBlurred, field) &&
      // $FlowFixMe
      !this.props.DataViewStore.newReportTemplate[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {
            // $FlowFixMe
            this.props.DataViewStore.newReportTemplate[
              `${field}Error`
            ]
          }
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="floppy-disk"
        isOpen={this.props.dialogOpen}
        onClose={this.props.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        enforceFocus={false}
        title={I18n.t('js.save_report_template')}
      >
        <form onSubmit={e => e.preventDefault()}>
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'name') &&
                  !this.props.DataViewStore.newReportTemplate
                    .nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {/* $FlowFixMe */}
                  {I18n.t('js.report_template_name')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'name') &&
                      !this.props.DataViewStore.newReportTemplate
                        .nameValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t(
                        'js.enter_a_name_for_your_report_template'
                      )}
                      name="name"
                      value={
                        this.props.DataViewStore.newReportTemplate
                          .name
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('name')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'description') &&
                  !this.props.DataViewStore.newReportTemplate
                    .descriptionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="description">
                  {I18n.t('js.report_template_description')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(
                        this.state.isBlurred,
                        'description'
                      ) &&
                      !this.props.DataViewStore.newReportTemplate
                        .descriptionValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <textarea
                      rows={4}
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t(
                        'js.enter_a_description_for_your_report_template'
                      )}
                      name="description"
                      value={
                        this.props.DataViewStore.newReportTemplate
                          .description
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('description')}
                </div>
              </div>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.toggleDialog.bind(this)}
              />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSave.bind(this)}
                disabled={
                  !this.props.DataViewStore.newReportTemplate
                    .nameValid ||
                  !this.props.DataViewStore.newReportTemplate
                    .descriptionValid
                }
                text={I18n.t('js.save_report_template')}
                loading={this.props.isSaving}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}
