/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import axios from 'axios';
import _ from 'lodash';

class IntegrationStore {
  @observable
  isLoading = false;
  @observable
  activeTab = 'webhooks';

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  resetStartingAttributes() {
    this.isLoading = false;
    this.activeTab = 'webhooks';
  }
}

const store = new IntegrationStore();
export default store;
