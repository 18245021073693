import React from 'react';
import Flexbox from 'flexbox-react';
import TeamAvatar from '../shared/TeamAvatar';
import { inject, observer } from 'mobx-react';
import { Intent, Tag } from '@blueprintjs/core';
import StatusTag from '../shared/StatusTag';
import _ from 'lodash';
import Utilities from '../../utils/Utilities';

@inject('TeamsStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class TeamsListRow extends React.Component {
  render() {
    return (
      <tr>
        <td>
          <Flexbox flexDirection="row">
            <TeamAvatar
              team={this.props.team}
              avatarSize={20}
              path={`/teams/${this.props.team.id}`}
            />
          </Flexbox>
        </td>
      </tr>
    );
  }
}
