// @flow
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import ReportTemplate from '../models/ReportTemplateModel';
import Report from '../models/ReportModel';
import ReportActions from '../actions/ReportActions';
import Paginator from '../models/PaginatorModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Notification from '../models/NotificationModel';
import ReportChart from '../models/ReportChartModel';

class ReportStore {
  @observable
  reportTemplates = [];
  @observable
  reports: Array<Report> = [];
  @observable
  activeReportTemplate = '';
  @observable
  activeReport = '';
  @observable
  pagination = new Paginator();
  @observable
  reportColumns = ColumnDefinitions.reportColumns;

  @computed
  get currentChartLayout() {
    if (this.activeReport) {
      if (this.activeReport.chartConfiguration) {
        return this.activeReport.chartConfiguration.layout;
      }
    }
    return '';
  }

  @computed
  get reportCharts() {
    if (this.activeReport) {
      if (this.activeReport.chartConfiguration) {
        const charts = [];
        _.map(this.activeReport.chartConfiguration.charts, chart => {
          const newChart = new ReportChart({
            id: chart.id,
            name: chart.name,
            type: chart.type,
            icon: chart.icon,
            options: chart.options,
            dataConfig: chart.dataConfig,
          });
          charts.push(newChart);
        });
        return charts;
      }
    }
    return [];
  }

  @computed
  get displayedTableData() {
    return JSON.parse(this.activeReport.data);
  }

  @action
  createPaginator(headers: any) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  setActiveReportTemplate(reportTemplateId: string) {
    const findReportTemplate = _.find(this.reportTemplates, (o: ReportTemplate) => o.id === reportTemplateId);
    if (findReportTemplate) {
      this.activeReportTemplate = findReportTemplate;
    }
  }

  @action
  setActiveReport(reportId: string) {
    const findReport = _.find(this.reports, (o: Report) => o.id === reportId);
    if (findReport) {
      this.activeReport = findReport;
    }
  }

  @action
  addTriggerNotification(notification: Notification) {
    const reportId = notification.trigger.split('#')[1];
    ReportActions.fetchReport(reportId).then(response => {
      this.removeReport(reportId);
      this.addReport(response.data);
    });
  }

  @action
  addReportTemplate(data: any) {
    const newReportTemplate = new ReportTemplate(data);
    this.reportTemplates = _.unionBy(this.reportTemplates, [newReportTemplate], 'id');
  }

  @action
  addReport(data: any) {
    const newReport = new Report(data);
    this.reports = _.unionBy(this.reports, [newReport], 'id');
    this.sortReports();
  }

  @action
  removeReport(reportId: string) {
    this.reports = _.filter(this.reports, o => o.id !== reportId);
  }

  @action
  setReportSortParams(param: string) {
    const newReportColumns = this.reportColumns;
    _.map(newReportColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.reportColumns = newReportColumns;
    this.sortReports();
  }

  @action
  sortReports() {
    let newReports = this.reports;
    const activeColumn: any = _.find(this.reportColumns, o => o.active);
    switch (activeColumn.id) {
      case 'fullName':
        newReports = _.orderBy(newReports, [report => report.user.fullName], [activeColumn.sortDir]);
        break;
      default:
        newReports = _.orderBy(newReports, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.reports = newReports;
  }

  @action
  resetStartingAttributes() {
    this.reportTemplates = [];
    this.reports = [];
    this.activeReportTemplate = '';
    this.activeReport = '';
    this.pagination = new Paginator();
    this.reportColumns = ColumnDefinitions.reportColumns;
  }
}

const store = new ReportStore();
export default store;
