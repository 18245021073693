import React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

const LocationSelector = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={props.defaultPosition}
      ref={props.onMapMounted}
    >
      {props.isMarkerShown && (
        <Marker
          position={props.markerPosition}
          draggable={props.markerDraggable}
          onDragEnd={props.onDragEnd}
        />
      )}
    </GoogleMap>
  ))
);

export default class LocationSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPosition: { lat: 51.509865, lng: -0.118092 },
      markerPosition: { lat: 51.509865, lng: -0.118092 },
      isMarkerShown: true,
      markerDraggable: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isDraggable) {
      this.setState({ markerDraggable: true });
    }
    if (nextProps.selectedLocation !== '') {
      this.setState({
        defaultPosition: nextProps.selectedLocation,
        markerPosition: nextProps.selectedLocation,
        isMarkerShown: true,
      });
    }
  }

  componentDidMount() {
    if (this.props.selectedLocation !== '') {
      this.setState({
        defaultPosition: this.props.selectedLocation,
        markerPosition: this.props.selectedLocation,
        isMarkerShown: true,
      });
    }
  }

  componentDidMount() {
    if (this.props.selectedLocation !== '') {
      this.setState({
        markerPosition: this.props.selectedLocation,
        isMarkerShown: true,
      });
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.setState({
              markerPosition: pos,
              isMarkerShown: true,
            });
            this.panToMarker();
          },
          () => {
            // console.log('An error occured');
          }
        );
      } else {
        console.log(
          'Error: your browser does not support Geolocation'
        );
      }
    }
    if (this.props.isDraggable) {
      this.setState({ markerDraggable: true });
    }
  }

  handleMapMounted(map) {
    this._map = map;
  }

  handleDragEnd(event) {
    const coordinates = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    this.props.handleDragEnd(coordinates);
  }

  panToMarker() {
    if (this._map) {
      this._map.panTo(this.state.markerPosition);
    }
  }

  render() {
    return (
      <LocationSelector
        isMarkerShown={this.state.isMarkerShown}
        defaultPosition={this.state.defaultPosition}
        markerPosition={this.state.markerPosition}
        markerDraggable={this.state.markerDraggable}
        panToMarker={this.panToMarker.bind(this)}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCo5PV3ib9Zrac_zKLGI-7PSSuM9pT_tb4&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        onMapMounted={this.handleMapMounted.bind(this)}
        onDragEnd={this.handleDragEnd.bind(this)}
      />
    );
  }
}
