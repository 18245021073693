/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import User from './UserModel';
import moment from 'moment';
import _ from 'lodash';

export default class StoreTemplate {
  @observable
  id;
  @observable
  userId;
  @observable
  templatableObjectId;
  @observable
  templatableObjectType;
  @observable
  title;
  @observable
  subtitle;
  @observable
  description;
  @observable
  benefits;
  @observable
  icon;
  @observable
  imageFileName;
  @observable
  status;
  @observable
  rating;
  @observable
  ratingCount;
  @observable
  commentCount;
  @observable
  slug;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;
  @observable
  user = new User();

  constructor(
    id,
    userId,
    templatableObjectId,
    templatableObjectType,
    title,
    subtitle,
    description,
    benefits,
    icon,
    imageFileName,
    status,
    rating,
    ratingCount,
    commentCount,
    slug,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.userId = userId || '';
    this.templatableObjectId = templatableObjectId || '';
    this.templatableObjectType = templatableObjectType || '';
    this.title = title || '';
    this.subtitle = subtitle || '';
    this.description = description || '';
    this.benefits = benefits || '';
    this.icon = icon || '';
    this.imageFileName = imageFileName || '';
    this.status = status || '';
    this.rating = rating || 0;
    this.ratingCount = ratingCount || 0;
    this.commentCount = commentCount || 0;
    this.slug = slug || 0;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get imageFileNameWidget() {
    const replacement = _.replace(this.imageFileName, 't_profile_image', 't_workspace_widget');
    return replacement;
  }

  @computed
  get imageFileNameFull() {
    const replacement = _.replace(this.imageFileName, '/t_profile_image', '');
    return replacement;
  }

  @computed
  get titleValid() {
    if (this.title.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get titleError() {
    if (this.title.length < 1) {
      return I18n.t('js.title_is_required');
    }
    return null;
  }

  @computed
  get subtitleValid() {
    if (this.subtitle.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get subtitleError() {
    if (this.subtitle.length < 1) {
      return I18n.t('js.subtitle_is_required');
    }
    return null;
  }

  @computed
  get descriptionValid() {
    if (this.description.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get descriptionError() {
    if (this.description.length < 1) {
      return I18n.t('js.description_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
