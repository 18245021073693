import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  AnchorButton,
  Classes,
  Collapse,
  EditableText,
  Intent,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import ElementTag from '../shared/ElementTag';
import TemplateAddElement from './TemplateAddElement';
import TemplateElement from './TemplateElement';
import _ from 'lodash';
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import classNames from 'classnames';

const DragHandle = sortableHandle(() => <AnchorButton className="bp3-cursor-move bp3-minimal" icon="drag-handle-vertical" />);

const SortableElement = sortableElement(
  ({ element, sectionType, sectionPrivateView, sectionProtectedView, sectionAccountOwnerOnlyView }) => (
    <TemplateElement
      element={element}
      sectionType={sectionType}
      sectionPrivateView={sectionPrivateView}
      sectionProtectedView={sectionProtectedView}
      sectionAccountOwnerOnlyView={sectionAccountOwnerOnlyView}
      dragHandle={<DragHandle />}
    />
  )
);

const SortableElements = sortableContainer(
  ({ elements, sectionType, sectionPrivateView, sectionProtectedView, sectionAccountOwnerOnlyView }) => (
    <Flexbox flexDirection="column" padding="0px">
      {elements.map(element => {
        return (
          <SortableElement
            key={element.id}
            index={elements.indexOf(element)}
            element={element}
            sectionType={sectionType}
            sectionPrivateView={sectionPrivateView}
            sectionProtectedView={sectionProtectedView}
            sectionAccountOwnerOnlyView={sectionAccountOwnerOnlyView}
          />
        );
      })}
    </Flexbox>
  )
);

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions', 'CurrentUserStore')
@observer
export default class TemplateSection extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = {
      deletingSection: false,
      showInvitableExplanation: false,
      isHovering: false,
      isLoading: false,
    };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleOptions() {
    this.store.setActiveArea(this.props.section.id);
  }

  toggleInvitableExplanation() {
    this.setState({ showInvitableExplanation: !this.state.showInvitableExplanation });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleHover() {
    this.setState({ isHovering: true });
  }

  removeHover() {
    this.setState({ isHovering: false });
  }

  toggleDeleting() {
    this.setState({
      deletingSection: !this.state.deletingSection,
    });
  }

  toggleOptional() {
    const newOptional = !this.props.section.optional;
    this.props.section.optional = newOptional;
    const sectionObj = {
      id: this.props.section.id,
      optional: newOptional,
    };
    this.props.TemplateActions.changeSection(sectionObj);
  }

  toggleVisibility() {
    const newVisible = !this.props.section.stateSection.visible;
    this.props.section.stateSection.visible = newVisible;
    const stateSectionObj = {
      id: this.props.section.stateSection.id,
      visible: newVisible,
    };
    this.props.TemplateActions.changeStateSection(stateSectionObj).then(() => {
      _.map(this.props.section.elements, element => {
        const stateElementObj = {
          id: element.stateElement.id,
          visible: newVisible,
        };
        this.props.TemplateActions.changeStateElement(stateElementObj);
      });
    });
  }

  toggleEditability() {
    const newEditable = !this.props.section.stateSection.editable;
    this.props.section.stateSection.editable = newEditable;
    const stateSectionObj = {
      id: this.props.section.stateSection.id,
      editable: newEditable,
    };
    this.props.TemplateActions.changeStateSection(stateSectionObj).then(() => {
      _.map(this.props.section.elements, element => {
        const stateElementObj = {
          id: element.stateElement.id,
          editable: newEditable,
        };
        this.props.TemplateActions.changeStateElement(stateElementObj);
      });
    });
  }

  toggleInvitable() {
    const newInvitable = !this.props.section.stateSection.invitable;
    this.props.section.stateSection.invitable = newInvitable;
    const stateSectionObj = {
      id: this.props.section.stateSection.id,
      invitable: newInvitable,
    };
    this.props.TemplateActions.changeStateSection(stateSectionObj);
  }

  toggleCommentable() {
    const newCommentable = !this.props.section.stateSection.commentable;
    this.props.section.stateSection.invitable = newCommentable;
    const stateSectionObj = {
      id: this.props.section.stateSection.id,
      commentable: newCommentable,
    };
    this.props.TemplateActions.changeStateSection(stateSectionObj);
  }

  toggleDefaultCollapsed() {
    if (this.props.section.sectionType == 'app') {
      const stateSectionObj = {
        id: this.props.section.stateSection.id,
        default_collapsed: !this.props.section.stateSection.defaultCollapsed,
      };
      this.props.TemplateActions.changeStateSection(stateSectionObj);
    }
    if (this.props.section.sectionType == 'profile_template') {
      const sectionObj = {
        id: this.props.section.id,
        default_collapsed: !this.props.section.defaultCollapsed,
      };
      this.props.TemplateActions.changeSection(sectionObj);
    }
  }

  togglePrivacy() {
    const newPrivacy = !this.props.section.privateView;
    this.props.section.privateView = newPrivacy;
    const sectionObj = {
      id: this.props.section.id,
      private_view: newPrivacy,
    };
    this.props.TemplateActions.changeSection(sectionObj);
  }

  toggleProtection() {
    const newProtection = !this.props.section.protectedView;
    this.props.section.protectedView = newProtection;
    const sectionObj = {
      id: this.props.section.id,
      protected_view: newProtection,
    };
    this.props.TemplateActions.changeSection(sectionObj);
  }

  toggleAccountOwnerOnly() {
    const newAccountOwnerOnlyView = !this.props.section.accountOwnerOnlyView;
    this.props.section.accountOwnerOnlyView = newAccountOwnerOnlyView;
    const sectionObj = {
      id: this.props.section.id,
      account_owner_only_view: newAccountOwnerOnlyView,
    };
    this.props.TemplateActions.changeSection(sectionObj);
  }

  handleCopy() {
    this.toggleLoading();
    this.props.TemplateActions.copySection(this.props.section.id)
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleTitleChange(value) {
    this.props.section.title = value;
  }

  handleTitleConfirm() {
    const sectionObj = {
      id: this.props.section.id,
      title: this.props.section.title,
    };
    this.props.TemplateActions.changeSection(sectionObj);
    this.store.setActiveArea(null);
  }

  handleDeleteSection() {
    this.toggleLoading();
    this.props.TemplateActions.deleteSection(this.props.section.id);
  }

  handleCancel() {
    this.store.setActiveArea(null);
  }

  handleSortEnd(element, newIndex) {
    const elementObj = {
      id: element.id,
      row_order_position: newIndex,
    };
    this.props.TemplateActions.changeElement(elementObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the element
    const findElement = this.props.section.elements[oldIndex];

    let store = this.props.TemplateStore;
    if (this.props.section.sectionType === 'profile_template') {
      store = this.props.UserProfileTemplateStore;
    }

    // Reorder the current UI
    const newElements = arrayMove(this.props.section.elements.slice(), oldIndex, newIndex);
    const newSection = _.clone(this.props.section);
    const sectionIndex = store.sections.indexOf(this.props.section);
    newSection.elements = newElements;
    store.sections[sectionIndex] = newSection;
    store.sortSections();

    // Process reorder on server
    this.handleSortEnd(findElement, newIndex);
  }

  renderDeleteSection() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.deletingSection}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={this.toggleDeleting.bind(this)}
        confirmButtonText={I18n.t('js.delete_section')}
        onConfirm={this.handleDeleteSection.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_section')}
      </Alert>
    );
  }

  renderOptionalOption() {
    const { optional } = this.props.section;
    return (
      <MenuItem
        text={_.capitalize(I18n.t('js.required'))}
        icon={optional ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
        onClick={this.toggleOptional.bind(this)}
      />
    );
  }

  renderVisibleOption() {
    if (this.props.section.sectionType === 'app') {
      const { visible } = this.props.section.stateSection;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.visible'))}
          icon={!visible ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleVisibility.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderEditableOption() {
    if (this.props.section.sectionType === 'app') {
      const { editable } = this.props.section.stateSection;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.editable'))}
          icon={!editable ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleEditability.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderInvitableOption() {
    if (this.props.section.sectionType === 'app') {
      const { invitable } = this.props.section.stateSection;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.invitable'))}
          icon={!invitable ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleInvitable.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderCommentableOption() {
    if (this.props.section.sectionType === 'app') {
      const { commentable } = this.props.section.stateSection;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.comments'))}
          icon={!commentable ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleCommentable.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderPrivacyMenu() {
    if (this.props.section.sectionType === 'profile_template') {
      const { privateView } = this.props.section;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.private'))}
          icon={!privateView ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.togglePrivacy.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderProtectedMenu() {
    if (this.props.section.sectionType === 'profile_template') {
      const { protectedView } = this.props.section;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.protected'))}
          icon={!protectedView ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleProtection.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderAccountOwnerOnlyMenu() {
    if (this.props.section.sectionType === 'profile_template') {
      const { accountOwnerOnlyView } = this.props.section;
      return (
        <MenuItem
          text={_.capitalize(I18n.t('js.account_owner_only'))}
          icon={!accountOwnerOnlyView ? <Icon icon="circle" /> : <Icon icon="tick-circle" className="bp3-success" />}
          onClick={this.toggleAccountOwnerOnly.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderMenu() {
    return (
      <Menu>
        <li className="bp3-menu-header">
          <h6 className="bp3-heading">{I18n.t('js.configure_section')}</h6>
        </li>
        <MenuItem text={I18n.t('js.change_section_title')} icon="edit" onClick={this.toggleOptions.bind(this)} />
        <MenuItem text={I18n.t('js.duplicate_section')} icon="duplicate" onClick={this.handleCopy.bind(this)} />
        <li className="bp3-menu-header">
          <h6 className="bp3-heading">{I18n.t('js.visibility_and_control')}</h6>
        </li>
        {this.renderOptionalOption()}
        {this.renderEditableOption()}
        {this.renderInvitableOption()}
        {this.renderCommentableOption()}
        {this.renderVisibleOption()}
        {this.renderPrivacyMenu()}
        {this.renderProtectedMenu()}
        {this.renderAccountOwnerOnlyMenu()}
        <MenuDivider />
        <MenuItem text={I18n.t('js.delete_section')} icon="trash" onClick={this.toggleDeleting.bind(this)} className="bp3-intent-danger" />
      </Menu>
    );
  }

  renderInvitableButton() {
    if (this.props.section.sectionType === 'app') {
      if (this.props.section.stateSection.invitable) {
        return (
          <Flexbox flexDirection="row" marginLeft="10px" marginRight="10px">
            <Tooltip
              position="top"
              portalContainer={document.body}
              content={I18n.t('js.users_will_be_able_to_invite_other_users_to_edit_the_item_in_this_state')}
            >
              <AnchorButton
                icon="envelope"
                text={I18n.t('js.invite_another_user_to_edit')}
                onClick={() => this.toggleInvitableExplanation()}
              />
            </Tooltip>
          </Flexbox>
        );
      }
    }
    return null;
  }

  renderRightButtons() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexDirection="column" marginLeft="10px" marginRight="10px">
          <AnchorButton className="bp3-minimal" icon="more" loading={true} />
        </Flexbox>
      );
    }
    if (this.state.isHovering) {
      return (
        <Flexbox flexDirection="row" marginLeft="10px" marginRight="10px">
          <Tooltip
            position="top"
            portalContainer={document.body}
            content={
              this.props.section.sectionType == 'app'
                ? this.props.section.stateSection.defaultCollapsed
                  ? I18n.t('js.collapsed_by_default')
                  : I18n.t('js.open_by_default')
                : this.props.section.defaultCollapsed
                ? I18n.t('js.collapsed_by_default')
                : I18n.t('js.open_by_default')
            }
          >
            <AnchorButton
              className="bp3-minimal"
              icon={
                this.props.section.sectionType == 'app'
                  ? this.props.section.stateSection.defaultCollapsed
                    ? 'expand-all'
                    : 'collapse-all'
                  : this.props.section.defaultCollapsed
                  ? 'expand-all'
                  : 'collapse-all'
              }
              onClick={this.toggleDefaultCollapsed.bind(this)}
            />
          </Tooltip>
          <Tooltip content={I18n.t('js.options')} portalContainer={document.body} postion="top">
            <Popover
              portalContainer={document.body}
              content={this.renderMenu()}
              position="bottom"
              interactionKind={PopoverInteractionKind.CLICK}
              inline={true}
            >
              <AnchorButton className="bp3-minimal" icon="more" />
            </Popover>
          </Tooltip>
        </Flexbox>
      );
    }
    return <Flexbox width="50px" />;
  }

  renderOptionalTag() {
    if (this.props.section.optional) {
      return (
        <Tooltip
          content={I18n.t('js.this_section_is_optional_and_can_be_exposed_by_the_user')}
          position="top"
          portalContainer={document.body}
        >
          <Icon icon="bring-data" className="bp3-text-muted" />
        </Tooltip>
      );
    }
  }

  renderLeftButtons() {
    if (this.state.isHovering) {
      return (
        <Flexbox flexDirection="row" marginLeft="10px" marginRight="10px">
          {this.props.dragHandle}
          <Flexbox paddingTop="4px" marginLeft="5px">
            {this.renderOptionalTag()}
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row">
        <Flexbox marginLeft="40px" padding="5px" marginRight="5px">
          {this.renderOptionalTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderElements() {
    if (this.props.section.elements.length > 0) {
      return (
        <SortableElements
          elements={this.props.section.elements}
          sectionType={this.props.section.sectionType}
          sectionPrivateView={this.props.section.privateView}
          sectionProtectedView={this.props.section.protectedView}
          sectionAccountOwnerOnlyView={this.props.section.accountOwnerOnlyView}
          onSortEnd={this.onSortEnd.bind(this)}
          useDragHandle={true}
          lockToContainerEdges={true}
          lockAxis="y"
        />
      );
    }
    return (
      <Flexbox flexDirection="column" className="template-area" padding="20px">
        <NonIdealState
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.there_are_no_elements_in_this_section')}
          icon="multi-select"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }

  renderPrivacyTag() {
    if (this.props.section.privateView) {
      return <ElementTag tagType="private_section" tagIcon="eye-on" tagTooltip={I18n.t('js.private_section_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.section.protectedView) {
      return <ElementTag tagType="protected_section" tagIcon="lock" tagTooltip={I18n.t('js.protected_section_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.section.accountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only_section"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_section_description')}
        />
      );
    }
  }

  renderSectionTitle() {
    if (this.store.activeAreaId === this.props.section.id) {
      return (
        <Flexbox flexDirection="row" flexGrow={1}>
          <h4
            className={Classes.HEADING}
            style={{
              margin: '0px',
              marginTop: '3px',
            }}
          >
            <EditableText
              confirmOnEnterKey={true}
              isEditing={true}
              disabled={this.store.mode === 'preview'}
              defaultValue={this.props.section.title}
              intent={Intent.DEFAULT}
              onChange={value => this.handleTitleChange(value)}
              value={this.props.section.title}
              onConfirm={this.handleTitleConfirm.bind(this)}
              placeholder={I18n.t('js.click_to_edit')}
            />
          </h4>
          <Flexbox className="push-10-l" flexDirection="row">
            {this.renderPrivacyTag()}
            {this.renderProtectedTag()}
            {this.renderAccountOwnerOnlyTag()}
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row" flexGrow={1}>
        {/* FIXME make accessible */}
        {/* eslint-disable-next-line */}
        <h4
          className={Classes.HEADING}
          style={{
            margin: '0px',
            marginTop: '5px',
          }}
          onClick={this.toggleOptions.bind(this)}
        >
          {this.props.section.title}
        </h4>
        <Flexbox className="push-10-l" flexDirection="row">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    if (this.props.section.stateSection.visible) {
      let cardClass = classNames('bp3-card bp3-card-no-padding');
      let sectionClass = classNames('template-section-header');
      let addElementClass = classNames('');
      if (this.store.activeAreaId === this.props.section.id) {
        sectionClass = classNames('template-section-header template-section-hover');
      }
      if (this.props.section.privateView) {
        sectionClass = classNames(sectionClass, 'template-section-private');
      }
      if (this.props.section.optional) {
        cardClass = classNames(cardClass, 'optional-section');
      }
      return (
        <div>
          <Alert
            portalContainer={document.body}
            isOpen={this.state.showInvitableExplanation}
            confirmButtonText={I18n.t('js.ok')}
            onConfirm={this.toggleInvitableExplanation.bind(this)}
            intent={Intent.PRIMARY}
          >
            <h4 className={Classes.HEADING}>{I18n.t('js.invitable_section')}</h4>
            {I18n.t('js.invitable_section_explanation')}
          </Alert>
          <Flexbox marginBottom="20px" className={cardClass} padding="0px" flexDirection="column">
            <Flexbox
              flexGrow={0}
              flexDirection="row"
              className={sectionClass}
              onMouseEnter={this.toggleHover.bind(this)}
              onMouseLeave={this.removeHover.bind(this)}
            >
              <Flexbox flexDirection="column" justifyContent="center">
                {this.renderLeftButtons()}
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1}>
                <Flexbox flexGrow={1} flexDirection="row" alignItems="center">
                  {this.renderSectionTitle()}
                </Flexbox>
              </Flexbox>
              <Flexbox flexDirection="row" justifyContent="center" padding="10px">
                {this.renderRightButtons()}
                {this.renderDeleteSection()}
                {this.renderInvitableButton()}
              </Flexbox>
            </Flexbox>
            <Collapse
              isOpen={
                this.props.section.sectionType === 'app'
                  ? !this.props.section.stateSection.defaultCollapsed
                  : !this.props.section.defaultCollapsed
              }
            >
              {this.renderElements()}
              <Flexbox flexDirection="column" className={addElementClass}>
                <TemplateAddElement section={this.props.section} />
              </Flexbox>
            </Collapse>
          </Flexbox>
          {this.props.section.stateSection.commentable && (
            <Flexbox flexDirection="column" flexGrow={1} marginBottom="30px" marginTop="-22px" className="bp3-card bp3-card-square bg-gray">
              <Flexbox flexGrow={1} flexDirection="column">
                <Flexbox flexDirection="row" alignItems="center" alignContent="center">
                  <Flexbox>
                    <h6 className={Classes.HEADING}>
                      <Icon icon="comment" className="bp3-text-muted push-10-r"></Icon>
                      {I18n.t('js.comments_enabled')}
                    </h6>
                  </Flexbox>
                </Flexbox>
              </Flexbox>
              <small className="bp3-text-muted">{I18n.t('js.users_are_able_to_comment')}</small>
            </Flexbox>
          )}
        </div>
      );
    }
    return (
      <div>
        <Flexbox flexDirection="row" flexGrow={1} justifyContent="center" alignItems="center" padding="10px" marginBottom="20px">
          <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
            {/* FIXME make accessible */}
            {/* eslint-disable-next-line */}
            <small className="bp3-text-muted bp3-cursor-pointer" onClick={this.toggleVisibility.bind(this)}>
              {I18n.t('js.hidden_section_click_to_make_visible', {
                name: this.props.section.title,
              })}
            </small>
          </Flexbox>
          <Flexbox flexGrow={0} className="display-none">
            <span>{this.props.dragHandle}</span>
          </Flexbox>
        </Flexbox>
      </div>
    );
  }
}
