import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent, HTMLSelect } from '@blueprintjs/core';
import _ from 'lodash';
import classNames from 'classnames';

@inject('UserProfileStore')
@observer
export default class UserProfileEditTheme extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    const newObj = {
      theme: this.props.UserProfileStore.newUser.theme,
    };
    this.props.UserProfileStore.changeUser(newObj)
      .then(() => {
        this.toggleLoading();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.UserProfileStore.updateUser(e.target.value, 'theme');
  }

  handleCancel() {
    this.props.UserProfileStore.resetUserAttribute('theme');
    this.toggleOpen();
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.theme')}
            </h4>
            <p className="noselect">{I18n.t('js.theme_description')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox className="bp3-form-group">
              <label className="bp3-label">{I18n.t('js.select_theme')}</label>
              <div className="bp3-form-content">
                <HTMLSelect
                  name="theme"
                  onChange={this.handleInputChange.bind(this)}
                  defaultValue={this.props.UserProfileStore.newUser.theme}
                >
                  <option value="light">{I18n.t('js.light_mode')}</option>
                  <option value="dark">{I18n.t('js.dark_mode')}</option>
                </HTMLSelect>
              </div>
            </Flexbox>
            <Flexbox>
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  type="submit"
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
