import React from 'react';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';
import { Tooltip, Position } from '@blueprintjs/core';
import ToastStore from '../../stores/ToastStore';

export default class ElementIdSelector extends React.Component {
  handleCopy() {
    Utilities.copyStringToClipboard(this.props.id);
    ToastStore.showToast(I18n.t('js.element_id_copied_to_clipboard'), 'success');
  }

  render() {
    return (
      <Flexbox marginTop="20px" flexDirection="row">
        <Tooltip content={I18n.t('js.copy_element_id_to_clipboard')} position={Position.RIGHT} portalContainer={document.body}>
          <Flexbox flexDirection="row" alignItems="center" className="bp3-cursor-pointer" onClick={this.handleCopy.bind(this)}>
            <span className="bp3-text-muted bp3-icon bp3-icon-key push-5-r"></span>
            <small className="bp3-text-muted">{`${this.props.id}`}</small>
          </Flexbox>
        </Tooltip>
      </Flexbox>
    );
  }
}
