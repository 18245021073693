import React from 'react';
import UserAvatar from '../shared/UserAvatar';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Switch } from '@blueprintjs/core';

@inject('AppItemStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppItemsUsersNewUserRow extends React.Component {
  removeUser() {
    this.props.AppItemStore.removeNewItemUser(this.props.user.id);
  }

  render() {
    return (
      <tr>
        <td>
          <UserAvatar
            user={this.props.user}
            avatarSize={20}
            path={`/users/${this.props.user.humanFriendlyId}`}
            noLink={true}
          />
        </td>
        <td>
          <Flexbox
            flexGrow={1}
            flexDirection="row"
            justifyContent="flex-start"
          ></Flexbox>
        </td>
        <td>
          <Button
            className="bp3-small bp3-minimal pull-right"
            icon="trash"
            onClick={this.removeUser.bind(this)}
          />
        </td>
      </tr>
    );
  }
}
