import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Classes,
  Switch,
  NonIdealState,
  Spinner,
  AnchorButton,
  Intent,
  Alignment,
  Popover,
  PopoverInteractionKind,
  Position,
  Button,
  Icon,
  Menu,
  MenuItem,
  MenuDivider,
} from '@blueprintjs/core';
import ItemWidget from '../items/ItemWidget';
import Paginator from '../shared/Paginator';
import Emoji from '../shared/Emoji';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import FormElementDisplay from '../form_elements/FormElementDisplay';
import TeamAvatar from '../shared/TeamAvatar';
import DashboardUserItems from './DashboardUserItems';
import moment from 'moment';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore'
)
@observer
export default class DashboardRelationships extends React.Component {
  componentDidMount() {
    this.props.DynamicViewStore.loadAllColleagues();
  }

  handleRelationshipChange(relationship) {
    this.props.DynamicViewStore.setActiveRelationship(relationship);
  }

  rendeRelationshipSelect() {
    const { relationships, activeRelationship } = this.props.DynamicViewStore;
    return (
      <Menu>
        <li className="bp3-menu-header">
          <h6 className="bp3-heading">{I18n.t('js.select_relationship')}</h6>
        </li>
        <MenuDivider />
        {_.map(relationships, relationship => {
          return (
            <MenuItem
              key={relationship.id}
              text={relationship.name}
              disabled={relationship.id === activeRelationship.id}
              onClick={() => this.handleRelationshipChange(relationship)}
            />
          );
        })}
      </Menu>
    );
  }

  render() {
    const { currentUser } = this.props.CurrentUserStore;
    if (this.props.DynamicViewStore.isLoading) {
      return (
        <>
          <Flexbox flexDirection="column" width="360px" height="240px" marginBottom="20px" className="bp3-skeleton"></Flexbox>
        </>
      );
    }
    if (this.props.DynamicViewStore.activeRelationship === null) {
      return null;
    }
    return (
      <Flexbox flexDirection="column">
        {/* <Flexbox flexDirection="column" marginBottom="20px">
          <p className="bp3-text-muted">
            <a className="unstyled" href={`/users/${this.props.CurrentUserStore.currentUser.humanFriendlyId}?tab=relationships`}>
              {this.props.DynamicViewStore.activeRelationship.name} ({this.props.DynamicViewStore.superordinateUsers.length})
            </a>

          </p>
          {this.props.DynamicViewStore.superordinateUsers.length === 0 ? (
          <Flexbox flexDirection="row" alignItems="flex-start" className="bp3-card">
              <NonIdealState
                icon="people"
                className="bp3-text-muted"
                title={I18n.t('js.no_data')}
                description={I18n.t('js.nothing_to_show')}
              />
            </Flexbox>
          ) : (
            _.map(this.props.DynamicViewStore.superordinateUsers, (user, index) => {
              return <DashboardUserItems key={index} user={user} />;
            })
          )}
        </Flexbox> */}
        <Flexbox flexDirection="column">
          <p className="bp3-text-muted">
            <a className="unstyled" href={`/users/${this.props.CurrentUserStore.currentUser.humanFriendlyId}?tab=relationships`}>
              {this.props.DynamicViewStore.activeRelationship.counterpartName} ({this.props.DynamicViewStore.subordinateUsers.length})
            </a>
            {this.props.DynamicViewStore.relationships.length > 1 ? (
              <span>
                <Popover
                  portalContainer={document.body}
                  content={this.rendeRelationshipSelect()}
                  interactionKind={PopoverInteractionKind.HOVER}
                  position={Position.BOTTOM}
                  boundary="viewport"
                >
                  <Button minimal className="push-5-l">
                    <Icon icon="caret-down" className="bp3-text-muted" />
                  </Button>
                </Popover>
              </span>
            ) : null}
          </p>
          {this.props.DynamicViewStore.subordinateUsers.length === 0 ? (
            <Flexbox flexDirection="row" alignItems="flex-start" className="bp3-card">
              <NonIdealState
                icon="people"
                className="bp3-text-muted"
                title={I18n.t('js.no_data')}
                description={I18n.t('js.nothing_to_show')}
              />
            </Flexbox>
          ) : (
            _.map(this.props.DynamicViewStore.subordinateUsers, (user, index) => {
              return <DashboardUserItems key={index} user={user} />;
            })
          )}
        </Flexbox>
      </Flexbox>
    );
  }
}
