import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { FormGroup } from '@blueprintjs/core';
import _ from 'lodash';

@inject('ActivityStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class ActivityMultilineDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rows: [] };
  }

  render() {
    const findMultilineInput = _.find(
      this.props.element.elementMultiline.elementMultilineInputs,
      o => o.id === this.props.element.formValues[0].elementMultilineInputId
    );
    return (
      <Flexbox>
        <FormGroup label={findMultilineInput.name} className="bp3-display-label">
          <p>{this.props.element.formValues[0].stringValue}</p>
        </FormGroup>
      </Flexbox>
    );
  }
}
