/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class Relationship {
  @observable id;
  @observable accountId;
  @observable name;
  @observable counterpartName;
  @observable description;
  @observable defaultRelationship;
  @observable lockUserChanges;
  @observable lockCounterpartUserChanges;

  constructor(
    id,
    accountId,
    name,
    counterpartName,
    description,
    defaultRelationship,
    lockUserChanges,
    lockCounterpartUserChanges,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    deletedAt,
    deletedBy
  ) {
    this.id = id || '';
    this.accountId = accountId || '';
    this.name = name || '';
    this.counterpartName = counterpartName || '';
    this.description = description || '';
    typeof defaultRelationship === 'undefined' ? (this.defaultRelationship = false) : (this.defaultRelationship = defaultRelationship);
    typeof lockUserChanges === 'undefined' ? (this.lockUserChanges = false) : (this.lockUserChanges = lockUserChanges);
    typeof lockCounterpartUserChanges === 'undefined'
      ? (this.lockCounterpartUserChanges = false)
      : (this.lockCounterpartUserChanges = lockCounterpartUserChanges);
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get counterpartNameValid() {
    if (this.counterpartName.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get counterpartNameError() {
    if (this.counterpartName.length < 1) {
      return I18n.t('js.counterpart_name_is_required');
    }
    return null;
  }

  @computed
  get descriptionValid() {
    if (this.description.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get descriptionError() {
    if (this.description.length < 1) {
      return I18n.t('js.description_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
