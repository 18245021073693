/* Legacy code - ignore this and complexity errors */
/* eslint class-methods-use-this: 0 complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { AnchorButton, Icon, Intent, NonIdealState, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import ToastStore from '../stores/ToastStore';
import TemplateStore from '../stores/TemplateStore';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import ItemActions from '../actions/ItemActions';
import AppStore from '../stores/AppStore';
import AccountUsersStore from '../stores/AccountUsersStore';
import AppItemStore from '../stores/AppItemStore';
import DynamicViewStore from '../stores/DynamicViewStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import AppUsage from '../components/apps/AppUsage';
import AppTabs from '../components/apps/AppTabs';
import AppEditApp from '../components/apps/AppEditApp';
import CurrentUserStore from '../stores/CurrentUserStore';
import DataViewStore from '../stores/DataViewStore';
import DataViewActions from '../actions/DataViewActions';
import ImportStore from '../stores/ImportStore';
import TeamsStore from '../stores/TeamsStore';
import ImportActions from '../actions/ImportActions';
import TemplateView from './TemplateView';
import AppItemView from './AppItemView';
import DataView from './DataView';
import ActivityView from './ActivityView';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import GuideButton from '../components/guides/GuideButton';

@observer
export default class AppView extends React.Component {
  @observable
  isLoading = true;
  componentDidMount() {
    AppStore.resetStartingAttributes();
    if (this.props.tab) {
      AppStore.activeTab = this.props.tab;
    }
    if (this.props.subsection) {
      TemplateStore.activeSubsection = this.props.subsection;
    } else {
      TemplateStore.activeSubsection = 'template';
    }
    AppStore.fetchApp(this.props.appId)
      .then(response => {
        if (response.data.workspace_id !== null) {
          WorkspaceStore.fetchWorkspace(response.data.workspace_id).then(() => {
            if (this.props.tab === 'edit' && AppStore.activeApp.userPermissionLevel.name !== 'owner') {
              AppStore.activeTab = 'items';
            }
            if (response.data.is_store_template) {
              AppStore.activeTab = 'template';
            }
            AppStore.fetchParticipants(this.props.appId).then(response => {
              AppStore.fetchRelationshipPermissions(this.props.appId).then(response => {
                this.toggleLoading();
              });
            });
          });
        } else {
          AppStore.activeTab = 'template';
          this.toggleLoading();
        }
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  publishApp() {
    const newObj = {
      status: 'published',
    };
    AppStore.changeApp(newObj).then(response => {
      if (response.data.status === 'published') {
        ToastStore.showToast(I18n.t('js.app_published'), 'success');
      }
    });
  }

  renderContent() {
    if (this.isLoading || WorkspaceStore.activeWorkspace === '') {
      return (
        <Flexbox margin="30px" marginTop="50px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (AppStore.activeTab === 'edit' && AppStore.activeApp.userPermissionLevel.name === 'owner') {
      return (
        <Flexbox margin="30px">
          <AppEditApp />
        </Flexbox>
      );
    }
    if (AppStore.activeTab === 'dataview') {
      return (
        <Flexbox margin="30px" flexDirection="column" flexGrow={1}>
          <Flexbox marginBottom="20px">
            <GuideButton action="dataview" />
          </Flexbox>
          <DataView
            location={'app'}
            reportTemplateId={this.props.reportTemplateId}
            userPermissionLevel={AppStore.activeApp.userPermissionLevel.name}
          />
        </Flexbox>
      );
    }
    if (AppStore.activeTab === 'template' && AppStore.activeApp.userPermissionLevel.name === 'owner') {
      return <TemplateView />;
    }
    if (AppStore.activeTab === 'items') {
      if (AppStore.activeApp.status === 'draft' && AppStore.activeApp.userPermissionLevel.name !== 'owner') {
        return (
          <Flexbox flexDirection="column" flexGrow={1}>
            <Flexbox paddingTop="50px" paddingBottom="50px">
              <NonIdealState
                title={I18n.t('js.this_app_is_in_draft_mode')}
                description={I18n.t('js.the_app_can_be_accessed_when_it_is_published')}
                icon="edit"
                className="bp3-text-muted animated rubberBand"
              />
            </Flexbox>
          </Flexbox>
        );
      }
      return (
        <Flexbox>
          <AppItemView renderTags={this.renderTags.bind(this)} />
        </Flexbox>
      );
    }
    if (AppStore.activeTab === 'activity') {
      return (
        <Flexbox margin="30px">
          <ActivityView activityObjectId={AppStore.activeApp.id} activityObjectType="App" />
        </Flexbox>
      );
    }
    if (AppStore.activeTab === 'usage') {
      return (
        <Flexbox margin="30px">
          <AppUsage />
        </Flexbox>
      );
    }
    return null;
  }

  renderPublishApp() {
    if (AppStore.activeApp.userPermissionLevel.name === 'owner') {
      return (
        <Flexbox flexDirection="column" justifyContent="center">
          <AnchorButton onClick={this.publishApp.bind(this)} text={I18n.t('js.publish_app')} className="bp3-intent-warning" />
        </Flexbox>
      );
    }
    return null;
  }

  renderDraftApp() {
    if (AppStore.activeApp.status === 'draft' && !this.isLoading) {
      return (
        <Flexbox className="sign-up-bar bp3-callout bp3-intent-warning">
          <Flexbox className="fixed-width-container" justifyContent="space-between" flexGrow={1}>
            <Flexbox flexGrow={1} alignContent="center" alignItems="center">
              <Icon icon="issue-new" className="push-10-r" iconSize={20} intent={Intent.WARNING} />
              <Flexbox flexDirection="column" justifyContent="center" paddingTop="10px">
                <p>{I18n.t('js.draft_app_text')}</p>
              </Flexbox>
            </Flexbox>
            {this.renderPublishApp()}
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }

  renderAppIcon() {
    const iconClass = classNames('fa bp3-profile-icon-large', AppStore.activeApp.icon);
    const bgStyle = {
      backgroundColor: AppStore.activeApp.color,
    };
    return (
      <Flexbox className="bp3-large-circle-icon" style={bgStyle} justifyContent="center" alignItems="center">
        <i className={iconClass} />
      </Flexbox>
    );
  }

  renderDraftTag() {
    if (AppStore.activeApp.status === 'draft') {
      return (
        <Tooltip portalContainer={document.body} content={I18n.t('js.draft_app_text')} position="right">
          <Tag className="bp3-cursor-pointer push-10-l bp3-minimal" minimal>
            {I18n.t('js.draft_app')}
          </Tag>
        </Tooltip>
      );
    }
    return undefined;
  }

  renderStoreTemplateTags() {
    if (AppStore.activeApp.isStoreTemplate) {
      return (
        <Tooltip
          portalContainer={document.body}
          content={I18n.t('js.store_template_tooltip_text', {
            object: I18n.t('js.app').toLowerCase(),
          })}
          position="right"
        >
          <Tag className="bp3-cursor-pointer push-10-l bp3-intent-success">{I18n.t('js.store_template')}</Tag>
        </Tooltip>
      );
    }
    return undefined;
  }

  renderTags() {
    return (
      <Flexbox>
        <Tooltip portalContainer={document.body} content={I18n.t('js.your_permission_type_for_this_app')} position="right">
          <Tag className="bp3-cursor-pointer" minimal>
            {AppStore.activeApp.userPermissionLevel.text}
          </Tag>
        </Tooltip>
        {this.renderDraftTag()}
        {this.renderStoreTemplateTags()}
      </Flexbox>
    );
  }

  render() {
    const stores = {
      WorkspaceStore,
      AppStore,
      AppItemStore,
      AccountUsersStore,
      DynamicViewStore,
      TemplateStore,
      StoreTemplateStore,
      StoreTemplateActions,
      ItemActions,
      CurrentUserStore,
      TeamsStore,
      ToastStore,
      DataViewStore,
      DataViewActions,
      ImportStore,
      ImportActions,
    };
    let title = AppStore.activeApp.name;
    if (AppItemStore.pagination.xTotal > 0) {
      title = `${AppStore.activeApp.name} (${AppItemStore.pagination.xTotal})`;
    }
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <AppTabs />
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderDraftApp()}
              </Flexbox>
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
