import React from 'react';
import Flexbox from 'flexbox-react';
import Cookies from 'js-cookie';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Button,
  ButtonGroup,
  NonIdealState,
  Tooltip,
  Position,
  Spinner,
} from '@blueprintjs/core';
import TeamsListRow from './TeamsListRow';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import Paginator from '../shared/Paginator';
import classNames from 'classnames';
import _ from 'lodash';

@inject('TeamsStore')
@observer
export default class TeamsList extends React.Component {
  @observable
  isSearching = false;
  @observable
  viewType = 'list';

  handleColumnSort(column) {
    this.props.TeamsStore.setTeamSortParams(
      column.id,
      column.sortDir
    );
  }

  handlePageChange(pageNumber) {
    this.toggleSearching();
    this.props.TeamsStore.resetStartingAttributes();
    this.props.TeamsStore.fetchTeams(
      this.props.TeamsStore.activeTeam.id,
      pageNumber,
      this.props.TeamsStore.pagination.xPerPage,
      this.props.TeamsStore.pagination.xOffset
    )
      .then(response => {
        this.props.TeamsStore.createPaginator(response.headers);
        _.map(response.data, team => {
          this.props.TeamsStore.addTeam(team);
        });
        this.toggleSearching();
      })
      .catch(() => {
        this.toggleSearching();
      });
  }

  handlePerPageChange(e) {
    this.toggleSearching();
    this.props.TeamsStore.resetStartingAttributes();
    const appNum = 1;
    this.props.TeamsStore.fetchTeams(
      this.props.TeamsStore.activeTeam.id,
      appNum,
      e.target.value,
      this.props.TeamsStore.pagination.xOffset
    )
      .then(response => {
        this.props.TeamsStore.createPaginator(response.headers);
        _.map(response.data, team => {
          this.props.TeamsStore.addTeam(team);
        });
        this.toggleSearching();
      })
      .catch(() => {
        this.toggleSearching();
      });
  }

  renderPagination() {
    if (
      this.props.TeamsStore.pagination.xTotal >
      this.props.TeamsStore.pagination.xPerPage
    ) {
      return (
        <Paginator
          paginationObject={this.props.TeamsStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderTeamView() {
    return (
      <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
        <thead>
          <SimpleTableHeader
            columns={this.props.TeamsStore.teamColumns}
            handleColumnSort={this.handleColumnSort.bind(this)}
          />
        </thead>
        <tbody>
          {_.map(this.props.TeamsStore.teams, team => {
            if (team.importId === this.props.importId) {
              return <TeamsListRow team={team} key={team.id} />;
            }
          })}
        </tbody>
      </table>
    );
  }
  renderTotal() {
    if (this.props.TeamsStore.pagination.xTotal > 0) {
      return <span>({this.props.TeamsStore.pagination.xTotal})</span>;
    }
    if (this.props.TeamsStore.teams.length > 0) {
      return <span>({this.props.TeamsStore.teams.length})</span>;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexDirection="column" flexGrow={1}>
            <Flexbox
              flexDirection="column"
              flexGrow={1}
              marginTop="20px"
            >
              <Flexbox>{this.renderTeamView()}</Flexbox>
              <Flexbox flexGrow={1} justifyContent="center">
                {this.renderPagination()}
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
