/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('TermsStore')
@observer
export default class TermsLeftNav extends React.Component {
  handleTabChange(tab) {
    Utilities.navigate(`/store_categories/${tab}`);
  }

  render() {
    return (
      <Flexbox flexGrow={0} flexDirection="column" marginRight="10px">
        <Flexbox marginBottom="5px">
          <span className="far fa-clipboard-check bp3-text-muted push-10-r" />
          <h6 className={Classes.HEADING}>{I18n.t('js.terms_and_conditions')}</h6>
        </Flexbox>
        {_.map(this.props.TermsStore.terms, term => {
          if (term.sys.id === this.props.TermsStore.selectedTerms.sys.id) {
            return (
              <Flexbox className="no-wrap" key={term.sys.id}>
                <a href={`/terms/${term.fields.slug}`}>
                  <strong>{term.fields.title}</strong>
                </a>
              </Flexbox>
            );
          }
          return (
            <Flexbox className="no-wrap" key={term.sys.id}>
              <a href={`/terms/${term.fields.slug}`}>
                <small>{term.fields.title}</small>
              </a>
            </Flexbox>
          );
        })}
      </Flexbox>
    );
  }
}
