/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import {
  Button,
  Classes,
  Collapse,
  FormGroup,
  InputGroup,
  HTMLSelect,
  MenuItem,
  Switch,
  NumericInput,
  Tooltip,
  Tabs,
  Tab,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import StateActionEmailRecipient from '../../models/StateActionEmailRecipientModel';
import StateActionDefinitions from '../../static/StateActionDefinitions';
import StateActionItemUserSelect from './StateActionItemUserSelect';
import StateActionItemParticipantSelect from './StateActionItemParticipantSelect';
import StateActionItemEmailInput from './StateActionItemEmailInput';
import { Select } from '@blueprintjs/select';
import StatusFlag from '../../models/StatusFlagModel';
import RichTextEditor from '../shared/RichTextEditor';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

const EditStatusFlagSelect = Select.ofType(StatusFlag);

@inject('StateRuleStore', 'StateRuleActions', 'TemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StateRuleActionItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isBlurred: [],
      showAdditionalEmailOptions: false,
      aciveItemSectionInviteDateTab: 'days',
    };
  }

  componentDidMount() {
    if (this.props.stateAction.inviteToSectionDueDateIncrement > 0) {
      this.setState({ aciveItemSectionInviteDateTab: 'days' });
    } else {
      this.setState({ aciveItemSectionInviteDateTab: 'fixed' });
    }
  }

  handleSetActiveItemSectionInviteDateTab(tab) {
    this.setState({ aciveItemSectionInviteDateTab: tab });
  }

  toggleAdditionalEmailOptions() {
    this.setState({
      showAdditionalEmailOptions: !this.state.showAdditionalEmailOptions,
    });
  }

  renderStatusFlagText(item) {
    return (
      <Flexbox flexDirection="row">
        <span
          className="bp3-cursor-pointer bp3-icon-full-circle push-10-r"
          style={{
            color: item.color,
          }}
        />
        <span>{item.name}</span>
      </Flexbox>
    );
  }

  renderStatusFlag(item, details) {
    let menuClass = classNames('popover-larger');
    if (item.id === this.props.stateAction.targetStatusFlagId) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }
    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={this.renderStatusFlagText(item, details)} />;
  }

  renderInputValue(statusFlag) {
    return statusFlag.name;
  }

  handleItemSelect(statusFlag) {
    this.props.stateAction.targetStatusFlagId = statusFlag.id;
    this.markChange();
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  handleActionDelete() {
    if (this.props.stateAction.changeType === 'added') {
      this.props.StateRuleStore.removeStateAction(this.props.stateAction.id);
    } else {
      this.props.stateAction.changeType = 'deleted';
    }
  }

  handleActionTypeChange(e) {
    this.props.stateAction.actionType = e.target.value;
    this.markChange();
  }

  handleTargetStateChange(e) {
    this.props.stateAction.targetStateId = e.target.value;
    this.markChange();
  }

  handleWebhookUrlChange(e) {
    this.props.stateAction.webhookUrl = e.target.value;
    this.markChange();
  }

  handleTargetEmailSubjectChange(e) {
    this.props.stateAction.targetEmailSubject = e.target.value;
    this.markChange();
  }

  handleCalendarElementIdChange(e) {
    this.props.stateAction.calendarElementId = e.target.value;
    this.markChange();
  }

  handleTargetEmailContentChange(content) {
    this.props.stateAction.targetEmailContent = content;
    this.markChange();
  }

  handleIncludeCalendarInvitationChange() {
    this.props.stateAction.includeCalendarInvitation = !this.props.stateAction.includeCalendarInvitation;
    this.markChange();
  }

  handleIncludePdfAttachmentChange() {
    this.props.stateAction.includePdfAttachment = !this.props.stateAction.includePdfAttachment;
    this.markChange();
  }

  handleTimelineEventParticipantIdChange(e) {
    this.props.stateAction.timelineEventParticipantId = e.target.value;
    this.markChange();
  }

  handleTimelineEventDescriptionChange(e) {
    this.props.stateAction.timelineEventDescription = e.target.value;
    this.markChange();
  }

  handleAddRecipientParticipant(participant) {
    const newRecipient = new StateActionEmailRecipient(
      null,
      this.props.stateAction.id,
      null,
      participant.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      'added'
    );
    this.props.StateRuleStore.stateActionEmailRecipientParticipants = this.props.StateRuleStore.stateActionEmailRecipientParticipants.concat(
      participant
    );
    this.addStateActionEmailRecipient(newRecipient);
  }

  handleAddRecipientUser(user) {
    const newRecipient = new StateActionEmailRecipient(
      null,
      this.props.stateAction.id,
      user.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      'added'
    );
    this.props.StateRuleStore.stateActionEmailRecipientUsers = this.props.StateRuleStore.stateActionEmailRecipientUsers.concat(user);
    this.addStateActionEmailRecipient(newRecipient);
  }

  handleAddRecipientEmail(email) {
    const newRecipient = new StateActionEmailRecipient(
      null,
      this.props.stateAction.id,
      null,
      null,
      email,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      'added'
    );
    this.addStateActionEmailRecipient(newRecipient);
  }

  addStateActionEmailRecipient(recipient) {
    this.props.StateRuleStore.stateActionEmailRecipients = this.props.StateRuleStore.stateActionEmailRecipients.concat(recipient);
  }

  handleRemoveRecipientUser(userId) {
    this.props.StateRuleStore.removeStateActionEmailRecipientUser(userId);
    const findRecipient = _.find(this.props.StateRuleStore.stateActionEmailRecipients, o => o.userId === userId);
    if (findRecipient) {
      if (findRecipient.changeType === 'added') {
        this.props.StateRuleStore.removeStateActionEmailRecipient(findRecipient.id);
      } else {
        findRecipient.changeType = 'deleted';
      }
    }
  }

  handleRemoveRecipientParticipant(participantId) {
    this.props.StateRuleStore.removeStateActionEmailRecipientParticipant(participantId);
    const findRecipient = _.find(this.props.StateRuleStore.stateActionEmailRecipients, o => o.participantId === participantId);
    if (findRecipient) {
      if (findRecipient.changeType === 'added') {
        this.props.StateRuleStore.removeStateActionEmailRecipient(findRecipient.id);
      } else {
        findRecipient.changeType = 'deleted';
      }
    }
  }

  handleRemoveRecipientEmail(email) {
    const findRecipient = _.find(this.props.StateRuleStore.stateActionEmailRecipients, o => o.email === email);
    if (findRecipient) {
      if (findRecipient.changeType === 'added') {
        this.props.StateRuleStore.removeStateActionEmailRecipient(findRecipient.id);
      } else {
        findRecipient.changeType = 'deleted';
      }
    }
  }

  handleSelectOverrideParticipantUserSelectElement(e) {
    this.props.stateAction.overrideParticipantUserSelectElementId = e.target.value;
    this.markChange();
  }

  handleSelectOverrideParticipantId(e) {
    this.props.stateAction.overrideParticipantId = e.target.value;
    this.markChange();
  }

  handleSelectUserSelectElement(e) {
    this.props.stateAction.inviteToSectionUserSelectElementId = e.target.value;
    this.markChange();
  }

  handleSelectInviteSectionId(e) {
    this.props.stateAction.inviteToSectionId = e.target.value;
    this.markChange();
  }

  handleInviteToSectionMessage(e) {
    this.props.stateAction.inviteToSectionMessage = e.target.value;
    this.markChange();
  }

  handleInviteToSectionDueDateIncrementChange(val) {
    this.props.stateAction.inviteToSectionDueDateIncrement = val;
    this.props.stateAction.inviteToSectionDueDate = new Date();
    this.markChange();
  }

  handleInviteToSectionFixedDueDateChange(date) {
    this.props.stateAction.inviteToSectionDueDate = date;
    this.props.stateAction.inviteToSectionDueDateIncrement = 0;
    this.markChange();
  }

  handleToggleHasDueDate() {
    this.props.stateAction.inviteToSectionHasDueDate = !this.props.stateAction.inviteToSectionHasDueDate;
    this.markChange();
  }

  handleToggleAnonymous() {
    this.props.stateAction.inviteToSectionAnonymous = !this.props.stateAction.inviteToSectionAnonymous;
    this.markChange();
  }

  markChange() {
    if (this.props.stateAction.changeType !== 'added') {
      this.props.stateAction.changeType = 'changed';
    }
  }

  renderCalendarElementSelect() {
    if (this.props.stateAction.includeCalendarInvitation) {
      return (
        <Flexbox marginTop="20px">
          <HTMLSelect
            name="calendarElementId"
            onChange={this.handleCalendarElementIdChange.bind(this)}
            defaultValue={this.props.stateAction.calendarElementId}
          >
            <option value={null}>{I18n.t('js.please_select_an_option')}</option>
            {_.map(this.props.TemplateStore.availableDateElements, element => (
              <option key={element.id} value={element.id}>
                {element[element.camelCaseTitle].name}
              </option>
            ))}
          </HTMLSelect>
        </Flexbox>
      );
    }
  }

  renderTimelineEventContent() {
    if (this.props.stateAction.actionType === 'create_timeline_event') {
      return (
        <Flexbox flexGrow={1} marginTop="20px" flexDirection="column">
          <Flexbox flexGrow={1} flexDirection="column">
            <FormGroup
              label={I18n.t('js.timeline_event_description')}
              labelFor="timeline_event_description"
              labelInfo={`(${I18n.t('js.required')})`}
            >
              <InputGroup
                name="timeline_event_description"
                className="bp3-fill"
                onChange={this.handleTimelineEventDescriptionChange.bind(this)}
                placeholder={I18n.t('js.timeline_event_description')}
                value={this.props.stateAction.timelineEventDescription}
              />
            </FormGroup>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderEmailContent() {
    if (this.props.stateAction.actionType === 'send_email') {
      return (
        <Flexbox flexGrow={1} marginTop="20px" flexDirection="column">
          <Flexbox flexGrow={1} flexDirection="column">
            <FormGroup label={I18n.t('js.select_participants')} className="max-width">
              <StateActionItemParticipantSelect
                handleAddRecipientParticipant={this.handleAddRecipientParticipant.bind(this)}
                handleRemoveRecipientParticipant={this.handleRemoveRecipientParticipant.bind(this)}
                stateAction={this.props.stateAction}
              />
            </FormGroup>
            <FormGroup label={I18n.t('js.email_subject_line')} className="max-width">
              <InputGroup
                autoComplete="off"
                className="max-width"
                onChange={value => this.handleTargetEmailSubjectChange(value)}
                value={this.props.stateAction.targetEmailSubject}
                placeholder={I18n.t('js.enter_subject_line_here')}
              />
            </FormGroup>
          </Flexbox>

          <RichTextEditor
            content={this.props.stateAction.targetEmailContent}
            handleChange={content => this.handleTargetEmailContentChange(content)}
            inputValid={true}
            rows={8}
            placeholder={I18n.t('js.enter_email_content_here')}
            placeholderDisabled={true}
          />

          <Flexbox marginTop="10px" marginBottom="10px">
            <Button
              onClick={this.toggleAdditionalEmailOptions.bind(this)}
              active={this.state.showAdditionalEmailOptions}
              icon="envelope"
              text={I18n.t('js.show_additional_email_options')}
            />
          </Flexbox>
          {this.renderAdditionalEmailOptions()}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderAdditionalEmailOptions() {
    return (
      <Collapse isOpen={this.state.showAdditionalEmailOptions}>
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1}>
            <FormGroup label={I18n.t('js.calendar_invitation')} className="max-width">
              <Switch
                disabled={this.props.TemplateStore.availableDateElements.length === 0}
                className="bp3-control-no-margin"
                checked={this.props.stateAction.includeCalendarInvitation}
                label={I18n.t('js.include_calendar_invitation')}
                onChange={this.handleIncludeCalendarInvitationChange.bind(this)}
              />
              {this.renderCalendarElementSelect()}
            </FormGroup>
          </Flexbox>
          <Flexbox flexGrow={1}>
            <FormGroup label={I18n.t('js.pdf_attachment')} className="max-width">
              <Switch
                className="bp3-control-no-margin"
                checked={this.props.stateAction.includePdfAttachment}
                label={I18n.t('js.include_pdf_attachment')}
                onChange={this.handleIncludePdfAttachmentChange.bind(this)}
              />
            </FormGroup>
          </Flexbox>
          <Flexbox flexGrow={1}>
            <FormGroup label={I18n.t('js.email_these_specific_users')} className="max-width">
              <StateActionItemUserSelect
                handleAddRecipientUser={this.handleAddRecipientUser.bind(this)}
                handleRemoveRecipientUser={this.handleRemoveRecipientUser.bind(this)}
                stateAction={this.props.stateAction}
              />
            </FormGroup>
          </Flexbox>
          <Flexbox flexGrow={1}>
            <FormGroup label={I18n.t('js.or_enter_email_addresses')} className="max-width">
              <StateActionItemEmailInput
                handleAddRecipientEmail={this.handleAddRecipientEmail.bind(this)}
                handleRemoveRecipientEmail={this.handleRemoveRecipientEmail.bind(this)}
                stateAction={this.props.stateAction}
              />
            </FormGroup>
          </Flexbox>
        </Flexbox>
      </Collapse>
    );
  }

  renderItemSectionDateType() {
    if (this.state.aciveItemSectionInviteDateTab == 'days') {
      return (
        <FormGroup label={I18n.t('js.due_date_day_increment')} className="max-width push-10-t">
          <NumericInput
            allowNumericCharactersOnly={true}
            name="due_date_day_increment"
            value={this.props.stateAction.inviteToSectionDueDateIncrement}
            onValueChange={val => this.handleInviteToSectionDueDateIncrementChange(val)}
          />
        </FormGroup>
      );
    }
    if (this.state.aciveItemSectionInviteDateTab == 'fixed') {
      return (
        <FormGroup label={I18n.t('js.fixed_due_date')} className="max-width push-10-t">
          <div className="flex flex-row">
            <DateInput
              popoverProps={{
                usePortal: false,
                inline: false,
              }}
              formatDate={date => moment(date).format('MMMM Do YYYY')}
              parseDate={str => new Date(str)}
              value={new Date(this.props.stateAction.inviteToSectionDueDate)}
              onChange={this.handleInviteToSectionFixedDueDateChange.bind(this)}
              placeholder={I18n.t('js.due_date')}
              minDate={new Date()}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
            />
          </div>
        </FormGroup>
      );
    }
    return null;
  }

  renderInviteToSectionContent() {
    if (this.props.stateAction.actionType === 'invite_to_section') {
      return (
        <Flexbox flexGrow={1} marginTop="20px" flexDirection="column">
          <Flexbox flexGrow={1} flexDirection="column">
            <FormGroup label={I18n.t('js.select_user_select_element')} className="max-width">
              <HTMLSelect
                name="select_user_select_element"
                onChange={this.handleSelectUserSelectElement.bind(this)}
                defaultValue={this.props.stateAction.inviteToSectionUserSelectElementId}
              >
                <option value={null}>{I18n.t('js.select_option')}</option>
                {_.map(this.props.TemplateStore.availableUserSelectElements, element => (
                  <option key={element.id} value={element.id}>
                    {element[element.camelCaseTitle].name}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
            <FormGroup label={I18n.t('js.select_invitable_section')} className="max-width">
              <HTMLSelect
                name="select_invitable_section"
                onChange={this.handleSelectInviteSectionId.bind(this)}
                defaultValue={this.props.stateAction.inviteToSectionId}
              >
                <option value={null}>{I18n.t('js.select_option')}</option>
                {_.map(this.props.TemplateStore.invitableSections, section => (
                  <option key={section.id} value={section.id}>
                    {section.title}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
            <FormGroup className="max-width">
              <Tooltip portalContainer={document.body} content={I18n.t('js.anonymous_invitation_description')} position="top">
                <Switch
                  checked={this.props.stateAction.inviteToSectionAnonymous}
                  className="bp3-control-no-margin"
                  label={I18n.t('js.anonymous_invitation')}
                  onChange={this.handleToggleAnonymous.bind(this)}
                />
              </Tooltip>
            </FormGroup>
            <FormGroup className="max-width">
              <Switch
                checked={this.props.stateAction.inviteToSectionHasDueDate}
                className="bp3-control-no-margin"
                label={I18n.t('js.due_date')}
                onChange={this.handleToggleHasDueDate.bind(this)}
              />
            </FormGroup>
            {this.props.stateAction.inviteToSectionHasDueDate && (
              <>
                <Tabs
                  id="itemSectionInviteDateTab"
                  selectedTabId={this.state.aciveItemSectionInviteDateTab}
                  vertical={false}
                  onChange={this.handleSetActiveItemSectionInviteDateTab.bind(this)}
                >
                  <Tab id="days" title={I18n.t('js.day_increment')} />
                  <Tab id="fixed" title={I18n.t('js.fixed_due_date')} />
                  <Tabs.Expander />
                </Tabs>
                {this.renderItemSectionDateType()}
              </>
            )}
            <FormGroup label={I18n.t('js.message')} className="max-width">
              <textarea
                className="bp3-input bp3-fill max-width"
                autoComplete="off"
                rows={4}
                onChange={this.handleInviteToSectionMessage.bind(this)}
                value={this.props.stateAction.inviteToSectionMessage}
                placeholder={I18n.t('js.optional_message_for_your_invitation')}
              />
            </FormGroup>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderOverrideParticipantContent() {
    if (this.props.stateAction.actionType === 'override_participant') {
      return (
        <Flexbox flexGrow={1} marginTop="20px" flexDirection="column">
          <Flexbox flexGrow={1} flexDirection="column">
            <FormGroup label={I18n.t('js.select_user_select_element')} className="max-width">
              <HTMLSelect
                name="select_user_select_element"
                onChange={this.handleSelectOverrideParticipantUserSelectElement.bind(this)}
                defaultValue={this.props.stateAction.overrideParticipantUserSelectElementId}
              >
                <option value={null}>{I18n.t('js.select_option')}</option>
                {_.map(this.props.TemplateStore.availableUserSelectElements, element => (
                  <option key={element.id} value={element.id}>
                    {element[element.camelCaseTitle].name}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
            <FormGroup label={I18n.t('js.select_participants')} className="max-width">
              <HTMLSelect
                name="select_participants"
                onChange={this.handleSelectOverrideParticipantId.bind(this)}
                defaultValue={this.props.stateAction.overrideParticipantId}
              >
                <option value={null}>{I18n.t('js.select_option')}</option>
                {_.map(this.props.TemplateStore.participants, participant => (
                  <option key={participant.id} value={participant.id}>
                    {participant.name}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderButtonText() {
    if (this.props.stateAction.targetStatusFlagId.length > 0) {
      const findStatusFlag = _.find(this.props.TemplateStore.statusFlags, o => o.id === this.props.stateAction.targetStatusFlagId);
      if (findStatusFlag) {
        return (
          <Flexbox flexDirection="row" paddingRight="20px">
            <span
              className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg"
              style={{
                marginTop: '-5px',
                marginLeft: '5px',
                position: 'relative',
                color: findStatusFlag.color,
              }}
            />
            <span>{findStatusFlag.name}</span>
          </Flexbox>
        );
      }
    }
    return (
      <Flexbox flexGrow={1}>
        {I18n.t('js.choose_from_the_following_options')}
        <Flexbox marginLeft="10px" marginTop="2px" marginRight="5px" />
      </Flexbox>
    );
  }

  renderAdditionalOptions() {
    switch (this.props.stateAction.actionType) {
      case 'change_state':
        return (
          <Flexbox className="bp3-select">
            <HTMLSelect
              name="targetStateId"
              onChange={this.handleTargetStateChange.bind(this)}
              defaultValue={this.props.stateAction.targetStateId}
            >
              <option value={null} disabled={this.props.stateAction.targetStateId}>
                {I18n.t('js.select_target_state')}
              </option>
              {_.map(this.props.TemplateStore.states, state => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </HTMLSelect>
          </Flexbox>
        );
      case 'change_status':
        return (
          <Flexbox className="bp3-select">
            <EditStatusFlagSelect
              resetOnSelect={true}
              filterable={false}
              items={this.props.TemplateStore.statusFlags}
              itemRenderer={this.renderStatusFlag.bind(this)}
              onItemSelect={this.handleItemSelect.bind(this)}
              inputValueRenderer={this.renderInputValue.bind(this)}
              inputProps={{
                rightElement: this.renderDropDown(),
              }}
              popoverProps={{
                usePortal: false,
                position: 'bottom-left',
                className: '',
                popoverClassName: 'bp3-minimal',
              }}
            >
              <Button text={this.renderButtonText()} />
            </EditStatusFlagSelect>
          </Flexbox>
        );
      case 'create_timeline_event':
        return (
          <Flexbox className="bp3-select">
            <HTMLSelect
              name="timelineEventParticipantId"
              onChange={this.handleTimelineEventParticipantIdChange.bind(this)}
              defaultValue={this.props.stateAction.timelineEventParticipantId}
            >
              <option value={null} disabled={this.props.stateAction.timelineEventParticipantId}>
                {I18n.t('js.select_participant_simple')}
              </option>
              {_.map(this.props.TemplateStore.participants, participant => (
                <option key={participant.id} value={participant.id}>
                  {participant.name}
                </option>
              ))}
            </HTMLSelect>
          </Flexbox>
        );
      case 'post_webhook':
        return (
          <Flexbox flexGrow={1}>
            <InputGroup
              autoComplete="off"
              className="max-width"
              onChange={value => this.handleWebhookUrlChange(value)}
              value={this.props.stateAction.webhookUrl}
              placeholder={I18n.t('js.insert_webhook_url_here')}
            />
          </Flexbox>
        );
      default:
        return undefined;
    }
  }

  render() {
    return (
      <Flexbox flexGrow={1} marginBottom="10px" flexDirection="column">
        <Flexbox flexGrow={1}>
          <Flexbox flexDirection="column" paddingRight="10px">
            {this.props.dragHandle}
          </Flexbox>
          <Flexbox flexDirection="row" flexGrow={1}>
            <Flexbox marginRight="10px">
              <HTMLSelect
                name="actionType"
                onChange={this.handleActionTypeChange.bind(this)}
                defaultValue={this.props.stateAction.actionType}
              >
                <option value={null} disabled={this.props.stateAction.actionType}>
                  {I18n.t('js.select_action_type')}
                </option>
                {_.map(StateActionDefinitions.actionTypes, action => (
                  <option key={action.id} value={action.name}>
                    {action.text}
                  </option>
                ))}
              </HTMLSelect>
            </Flexbox>
            <Flexbox marginRight="10px" flexGrow={1}>
              {this.renderAdditionalOptions()}
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="column" flexGrow={0}>
            <Button icon="trash" text={_.truncate(I18n.t('js.delete'), { length: 12 })} onClick={this.handleActionDelete.bind(this)} />
          </Flexbox>
        </Flexbox>
        {this.renderTimelineEventContent()}
        {this.renderEmailContent()}
        {this.renderInviteToSectionContent()}
        {this.renderOverrideParticipantContent()}
      </Flexbox>
    );
  }
}
