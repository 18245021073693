// @flow
import * as React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import {
  Alignment,
  Button,
  Popover,
  Position,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import type { DataViewStore } from '../../stores/DataViewStore';

type ColumnItemProps = {
  column: any,
  unhideColumn: Function,
};

const ColumnItem = (props: ColumnItemProps) => {
  const { column, unhideColumn } = props;
  return (
    <Button
      text={_.truncate(column.columnName, { length: 25 })}
      icon="add-column-right"
      rightIcon="small-cross"
      alignText={Alignment.LEFT}
      onClick={() => unhideColumn(column)}
      minimal
    />
  );
};

type HiddenColumnsProps = {
  DataViewStore: DataViewStore,
};

@inject('DataViewStore')
@observer
export default class HiddenColumns extends React.Component<HiddenColumnsProps> {
  unhideColumn(column: any) {
    const columnIndex = this.props.DataViewStore.activeReportTemplate.reportTemplateColumns.indexOf(
      column
    );
    this.props.DataViewStore.unhideColumn(columnIndex);
  }

  render() {
    const { hiddenColumns } = this.props.DataViewStore;
    const targetButton = (
      <Button
        // $FlowFixMe
        text={`${I18n.t('js.hidden_columns')} (${
          hiddenColumns.length
        })`}
        icon="panel-stats"
        disabled={hiddenColumns.length === 0}
      />
    );
    const columnItems = hiddenColumns.map(column => (
      <ColumnItem
        className="sort"
        key={column.id}
        column={column}
        unhideColumn={this.unhideColumn.bind(this)}
      />
    ));
    const popoverContent = (
      <Flexbox
        minWidth="200px"
        flexDirection="column"
        className="sort-filter-popover"
      >
        {columnItems}
      </Flexbox>
    );
    return (
      <Popover
        portalContainer={document.body}
        disabled={hiddenColumns.length === 0}
        target={targetButton}
        content={popoverContent}
        position={Position.BOTTOM_LEFT}
      />
    );
  }
}
