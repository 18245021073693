import React from 'react';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  AnchorButton,
  Classes,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import _ from 'lodash';
import Utilities from '../../utils/Utilities';

@inject('WorkspaceStore', 'AppStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, isDuplicating: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleDuplicating() {
    this.setState({
      isDuplicating: !this.state.isDuplicating,
    });
  }

  handleDuplicateApp() {
    this.toggleLoading();
    this.toggleDuplicating();
    this.props.AppStore.copyApp(this.props.app.id)
      .then(() => {
        this.toggleLoading();
        this.props.ToastStore.showToast(I18n.t('js.app_has_been_queued_for_duplication'), 'success');
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  moveToFolder(folder) {
    this.props.AppStore.activeApp = this.props.app;
    // eslint-disable-next-line no-magic-numbers
    if (folder.id === 9999) {
      this.props.app.workspaceFolderId = '';
    } else {
      this.props.app.workspaceFolderId = folder.id;
    }
    const newObj = {
      workspace_folder_id: folder.id,
    };
    // eslint-disable-next-line no-magic-numbers
    if (folder.id === 9999) {
      newObj.workspace_folder_id = null;
    }
    this.props.AppStore.changeApp(newObj);
  }

  renderDraftTag() {
    if (this.props.app.status === 'draft') {
      return (
        <Tag className="push-10-l bp3-minimal" intent={Intent.WARNING}>
          {I18n.t('js.draft_workspace')}
        </Tag>
      );
    }
    return undefined;
  }

  renderTags() {
    return (
      <Flexbox justifyContent="flex-end" flexGrow={1}>
        <Tooltip portalContainer={document.body} content={I18n.t('js.your_permission_type_for_this_app')} position="bottom">
          <Tag className="bp3-minimal">{this.props.app.userPermissionLevel.text}</Tag>
        </Tooltip>
        {this.renderDraftTag()}
      </Flexbox>
    );
  }

  renderUncategorizedMenuItem() {
    // eslint-disable-next-line no-magic-numbers
    if (this.props.WorkspaceStore.activeFolderId !== 9999) {
      return (
        <MenuItem
          icon="folder-close"
          className="bp3-intent-danger"
          key={this.props.WorkspaceStore.uncategorizedFolder.id}
          text={this.props.WorkspaceStore.uncategorizedFolder.name}
          onClick={() => this.moveToFolder(this.props.WorkspaceStore.uncategorizedFolder)}
        />
      );
    }
    return (
      <MenuItem
        icon="folder-close"
        className="bp3-intent-danger"
        key={this.props.WorkspaceStore.uncategorizedFolder.id}
        text={this.props.WorkspaceStore.uncategorizedFolder.name}
        disabled={true}
      />
    );
  }

  renderMenu() {
    return (
      <Menu>
        <MenuItem icon="edit" onClick={() => Utilities.navigate(`/apps/${this.props.app.id}?tab=edit`)} text={I18n.t('js.edit_app')} />
        <MenuItem icon="duplicate" onClick={() => this.toggleDuplicating()} text={I18n.t('js.duplicate_app')} />
        <MenuDivider />
        <MenuItem text={I18n.t('js.move_to_folder')} icon="add-to-folder" disabled={!this.props.WorkspaceStore.hasFolders}>
          {_.map(this.props.WorkspaceStore.activeWorkspaceFolders, folder => {
            if (folder.id !== this.props.WorkspaceStore.activeFolderId) {
              return <MenuItem icon="folder-open" key={folder.id} text={folder.name} onClick={() => this.moveToFolder(folder)} />;
            }
            return <MenuItem icon="folder-open" key={folder.id} text={folder.name} disabled={true} />;
          })}
          {this.renderUncategorizedMenuItem()}
        </MenuItem>
      </Menu>
    );
  }

  renderEdit() {
    if (this.props.app.userPermissionLevel.name === 'owner') {
      return (
        <Flexbox className="widget-edit" width="50px" marginBottom="-30px">
          <Popover
            portalContainer={document.body}
            content={this.renderMenu()}
            interactionKind={PopoverInteractionKind.CLICK}
            inline={true}
            position="bottom"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={false}
          >
            <Tooltip portalContainer={document.body} content={I18n.t('js.options')} position="bottom">
              <AnchorButton icon="cog" loading={this.state.isLoading} className="bp3-minimal bp3-text-muted" />
            </Tooltip>
          </Popover>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderConfirmDuplicate() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDuplicating}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDuplicating()}
        confirmButtonText={I18n.t('js.duplicate_app')}
        onConfirm={this.handleDuplicateApp.bind(this)}
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.duplicate_app_confirmation_text')}
      </Alert>
    );
  }

  render() {
    let bgStyle = {
      background: `url('${this.props.app.imageFileNameWidget}')`,
      borderBottom: `5px solid ${this.props.app.color}`,
    };
    if (this.props.app.status === 'draft') {
      bgStyle = {
        background: `linear-gradient( rgba(255, 2555, 255, 0.75), rgba(255, 2555, 255, 0.75) ), url('${this.props.app.imageFileNameWidget}')`,
        borderBottom: `5px solid ${this.props.app.color}`,
      };
    }
    if (this.props.app.imageFileName === '') {
      const darkenAmount = 0.25; // %
      bgStyle = {
        background: `linear-gradient(${this.props.app.color}, ${Utilities.darkenHex(this.props.app.color, darkenAmount)})`,
        borderBottom: `5px solid ${this.props.app.color}`,
      };
    }
    const iconClass = classNames('fa bp3-icon-app-widget text-white', this.props.app.icon);
    const iconStyle = {
      backgroundColor: this.props.app.color,
    };
    const widgetWidth = '290px';
    const widgetClass = classNames('workspace-widget-img');
    return (
      <Flexbox flexDirection="column" marginBottom="20px">
        <Flexbox
          className="bp3-card bp3-interactive bp3-elevation-1 bp3-card-no-padding bp3-cursor animated fadeIn"
          flexDirection="column"
          marginRight="20px"
          width={widgetWidth}
          onClick={() => Utilities.navigate(`/apps/${this.props.app.id}`)}
          flexGrow={1}
        >
          <Flexbox className={widgetClass} style={bgStyle} padding="20px" flexGrow={0} flexDirection="row" alignItems="flex-end">
            <Flexbox flexGrow={1} justifyContent="flex-end">
              <Flexbox
                className="bp3-small-circle-icon"
                style={iconStyle}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <i className={iconClass} />
              </Flexbox>
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="column" padding="20px" flexGrow={1} justifyContent="space-between">
            <Flexbox flexDirection="column" className="noselect">
              <h5 className={Classes.HEADING}>{this.props.app.name}</h5>
              <p>
                {_.truncate(this.props.app.description, {
                  length: 100,
                })}
              </p>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={0} justifyContent="flex-end" marginTop="10px">
              {this.renderTags()}
            </Flexbox>
          </Flexbox>
        </Flexbox>
        {this.renderEdit()}
        {this.renderConfirmDuplicate()}
      </Flexbox>
    );
  }
}
