import camelToSplitPascal from '../camelToSplitPascal';

describe('camelToSplitPascal', () => {
  it('translates word to Word', () => {
    expect(camelToSplitPascal('word')).toBe('Word');
  });

  it('translates someWord to Some Word', () => {
    expect(camelToSplitPascal('someWord')).toBe('Some Word');
  });

  it('translates longerCamelWords to Longer Camel Words', () => {
    expect(camelToSplitPascal('longerCamelWords')).toBe(
      'Longer Camel Words'
    );
  });
});
