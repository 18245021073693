import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';

export default class AccountDomain {
  @observable
  id;
  @observable
  domain;
  @observable
  version;

  constructor(id, domain, version) {
    this.id = id || ``;
    this.domain = domain || ``;
    this.version = version || `v1`;
  }

  @computed
  get domainValid() {
    if (this.domain.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get domainError() {
    if (this.domain.length < 1) {
      return I18n.t('js.domain_is_required');
    }
    return null;
  }

  @computed
  get versionValid() {
    if (this.version.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get versionError() {
    if (this.version.length < 1) {
      return I18n.t('js.version_is_required');
    }
    return null;
  }
}
