/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Utilities from '../utils/Utilities';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { AnchorButton, Button, Classes, Spinner, Tooltip } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import AccountBillingStore from '../stores/AccountBillingStore';
import AccountBillingActions from '../actions/AccountBillingActions';
import SetupStore from '../stores/SetupStore';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import SignupV2CreateAccount from '../components/signupAccount/SignupV2CreateAccount';

@observer
export default class SignupV2View extends React.Component {
  componentDidMount() {
    AccountBillingStore.resetStartingAttributes();
    AccountBillingActions.fetchPlans()
      .then(response => {
        let totalPlans = response.data.length;
        _.map(response.data, plan => {
          AccountBillingStore.addPlan(plan);
          totalPlans--;
        });
        AccountBillingStore.selectedPlan = AccountBillingStore.plans[0];
      })
      .catch(() => {
        AccountBillingStore.toggleLoading();
      });
  }

  renderChoice() {
    if (CurrentUserStore.isLoading) {
      return <Spinner />;
    }
    if (SetupStore.activeSetup === 'none') {
      return (
        <Flexbox flexDirection="column" alignSelf="center" justifyContent="center" alignItems="center">
          <h1 className={Classes.HEADING}>
            {I18n.t('js.hello_and_welcome_to_peoplegoal', {
              name: CurrentUserStore.currentUser.fullName,
            })}
          </h1>
          <p className="bp3-running-text push-20-t">{I18n.t('js.lets_get_started')}</p>
          <Flexbox flexDirection="row" className="push-20-t">
            {this.renderAccountCreation()}
            <Button className="bp3-large bp3-intent-primary push-10-l" onClick={() => SetupStore.setActiveSetup('newRequest')}>
              {I18n.t('js.join_an_existing_account')}
            </Button>
          </Flexbox>
          {this.renderBack()}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderAccountCreation() {
    return (
      <AnchorButton className="bp3-large bp3-intent-primary push-10-r" onClick={() => SetupStore.setActiveSetup('newAccount')}>
        {I18n.t('js.create_a_new_account')}
      </AnchorButton>
    );
  }

  renderNewAccount() {
    if (SetupStore.activeSetup === 'newAccount') {
      return <SetupCreateAccount />;
    }
    return undefined;
  }

  renderNewRequest() {
    if (SetupStore.activeSetup === 'newRequest') {
      return <SetUpCreateAccount />;
    }
    return undefined;
  }

  renderBack() {
    if (!CurrentUserStore.isLoading) {
      if (CurrentUserStore.currentUser.accountId.length > 0) {
        return (
          <Flexbox className="push-20-t">
            {/* lazy fix for legacy code */}
            {/* FIXME make accessible */}
            {/* eslint-disable-next-line */}
            <a onClick={() => Utilities.navigate('/dashboard')}>{I18n.t('js.go_back_to_dashboard')}</a>
          </Flexbox>
        );
      }
      return (
        <Flexbox className="push-20-t">
          <a href="https://www.peoplegoal.com/app-store">{I18n.t('js.browse_our_store_categories')}</a>
          <span className="push-5-l push-5-r">{I18n.t('js.or')}</span>
          <a href="/sign-out">{I18n.t('js.sign_out').toLowerCase()}</a>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      ToastStore,
      SetupStore,
      CurrentUserStore,
      AccountBillingStore,
      AccountBillingActions,
    };
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox
            flexGrow={1}
            width="100hw"
            alignContent="stretch"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop="50px"
            marginBottom="50px"
          >
            <Flexbox className="fixed-width-container animated fadeIn" flexDirection="row" alignSelf="center" justifyContent="center">
              {/* {this.renderChoice()}
              {this.renderNewAccount()}
              {this.renderNewRequest()} */}
              <SignupV2CreateAccount/>
            </Flexbox>
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
