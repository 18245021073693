import React from 'react';
import Flexbox from 'flexbox-react';
import OrganizationEditRelationship from './OrganizationEditRelationship';
import OrganizationNewRelationship from './OrganizationNewRelationship';
import { Button, HTMLSelect, Label } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Utilities from '../../utils/Utilities';
import Relationship from '../../models/RelationshipModel';
import { CSVLink } from 'react-csv';
import _ from 'lodash';

@inject('OrganizationStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class OrganizationOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditingNewRelationship: false,
      isCreatingNewRelationship: false,
      isImportingRelationships: false,
      isDownloadingRelationships: false,
    };
  }

  toggleImportingRelationships() {
    if (this.props.CurrentUserStore.userIsAccountOwner) {
      this.setState({
        isImportingRelationships: !this.state.isImportingRelationships,
      });
      Utilities.navigate('/organization/import-data');
    }
  }

  toggleDownloadingRelationships() {
    if (this.props.CurrentUserStore.userIsAccountOwner) {
      this.setState({
        isDownloadingRelationships: !this.state.isDownloadingRelationships,
      });
    }
  }

  toggleEditRelationshipDialog() {
    if (!this.state.isEditingNewRelationship) {
      this.props.OrganizationStore.newRelationship = _.cloneDeep(this.props.OrganizationStore.activeRelationship);
    }
    this.setState({
      isEditingNewRelationship: !this.state.isEditingNewRelationship,
    });
  }

  toggleNewRelationshipDialog() {
    this.props.OrganizationStore.newRelationship = new Relationship();
    this.setState({
      isCreatingNewRelationship: !this.state.isCreatingNewRelationship,
    });
  }

  handleRelationshipChange(e) {
    if (e.target.value !== 'default') {
      const relationship = _.find(this.props.OrganizationStore.relationships, o => o.id === e.target.value);
      this.props.OrganizationStore.setActiveRelationship(relationship);
      this.props.OrganizationStore.setActiveTab('individual');
    } else {
      this.props.OrganizationStore.setActiveRelationship('');
      this.props.OrganizationStore.setActiveTab('relationships');
    }
  }

  renderEditRelationshipDialog() {
    return (
      <OrganizationEditRelationship
        isOpen={this.state.isEditingNewRelationship}
        toggleDialog={this.toggleEditRelationshipDialog.bind(this)}
      />
    );
  }

  renderNewRelationshipDialog() {
    return (
      <OrganizationNewRelationship
        isOpen={this.state.isCreatingNewRelationship}
        toggleDialog={this.toggleNewRelationshipDialog.bind(this)}
      />
    );
  }

  renderSelectRelationship() {
    if (this.props.OrganizationStore.relationships.length > 0) {
      const { relationships, activeRelationship } = this.props.OrganizationStore;
      return (
        <Label className="bp3-label-no-margin">
          {I18n.t('js.select_relationship')}
          <Flexbox className="bp3-select">
            <HTMLSelect
              disabled={relationships.length <= 1}
              onBlur={this.handleRelationshipChange.bind(this)}
              value={activeRelationship.id}
              onChange={this.handleRelationshipChange.bind(this)}
            >
              {_.map(this.props.OrganizationStore.relationships, relationship => (
                <option key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </option>
              ))}
            </HTMLSelect>
          </Flexbox>
        </Label>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="row">
        <Flexbox flexGrow={1} flexDirection="row">
          {this.renderSelectRelationship()}
          {this.props.CurrentUserStore.userIsAccountOwner ? (
            <Flexbox flexDirection="column" flexGrow={0} paddingTop="25px" marginLeft="10px">
              <div className="bp3-button-group">
                <Button
                  icon="edit"
                  text={I18n.t('js.edit')}
                  disabled={!this.props.CurrentUserStore.userIsAccountOwner}
                  onClick={this.toggleEditRelationshipDialog.bind(this)}
                />
                <Button
                  icon="add"
                  text={I18n.t('js.new')}
                  disabled={!this.props.CurrentUserStore.userIsAccountOwner}
                  onClick={this.toggleNewRelationshipDialog.bind(this)}
                />
                <Button
                  text={I18n.t('js.import')}
                  icon="cloud-upload"
                  disabled={!this.props.CurrentUserStore.userIsAccountOwner}
                  loading={this.state.isImportingRelationships}
                  onClick={this.toggleImportingRelationships.bind(this)}
                />
                <Button icon="cloud-download">
                  <CSVLink
                    data={this.props.OrganizationStore.csvFormattedRelationships}
                    filename={`${this.props.OrganizationStore.activeRelationship.name} relationships.csv`}
                  >
                    {I18n.t('js.download')}
                  </CSVLink>
                </Button>
                {this.renderEditRelationshipDialog()}
                {this.renderNewRelationshipDialog()}
              </div>
            </Flexbox>
          ) : null}
        </Flexbox>
      </Flexbox>
    );
  }
}
