import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent, Dialog, Tooltip, Card } from '@blueprintjs/core';
import Cookies from 'js-cookie';
import axios from 'axios';
import ToastStore from '../../stores/ToastStore';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('UserProfileStore')
@observer
export default class UserProfileEditPersonalAccessToken extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isGenerating: false,
      isDialogOpen: false,
      accessToken: '',
    };
  }

  toggleOpen() {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
        isGenerating: false,
        isDialogOpen: false,
        accessToken: '',
      });
    } else {
      this.setState({
        isOpen: false,
        isGenerating: false,
        isDialogOpen: false,
        accessToken: '',
      });
    }
  }

  toggleDialog() {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  }

  toggleGenerating() {
    this.setState({ isGenerating: !this.state.isGenerating });
  }

  generatePersonalAccessToken() {
    this.toggleGenerating();
    this.fetchPat()
      .then(response => {
        this.setState({ accessToken: response.data, isGenerating: false, isDialogOpen: true });
      })
      .catch(error => {
        this.toggleGenerating();
      });
  }

  fetchPat() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/users/personal-access-token`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  copyToClipboard() {
    Utilities.copyStringToClipboard(this.state.accessToken);
    ToastStore.showToast(I18n.t('js.pat_copied_to_clipboard'), 'success');
  }

  closeAndReset() {
    this.setState({
      isOpen: false,
      isGenerating: false,
      isDialogOpen: false,
      accessToken: '',
    });
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.state.isDialogOpen}
        onClose={() => this.toggleDialog()}
        canOutsideClickClose={false}
        title={I18n.t('js.personal_access_token_pat')}
        className=""
        inline={false}
      >
        <div className="bp3-dialog-body">
          <p>{I18n.t('js.your_personal_access_token')}:</p>
          <Tooltip portalContainer={document.body} content={I18n.t('js.copy_to_clipboard')} position="top">
            <Card interactive={true} onClick={this.copyToClipboard.bind(this)}>
              <p className="access-token">{this.state.accessToken}</p>
            </Card>
          </Tooltip>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button intent={Intent.WARNING} onClick={this.closeAndReset.bind(this)} icon="key" text={I18n.t('js.i_have_copied_my_pat')} />
          </div>
        </div>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.personal_access_token_pat')}
            </h4>
            <p className="noselect">{I18n.t('js.pat_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <div className="bp3-callout bp3-intent-warning bp3-icon-warning-sign">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>{I18n.t('js.caution')}</h4>
            <p>{I18n.t('js.pat_caution')}</p>
            <Button
              intent={Intent.WARNING}
              text={I18n.t('js.generate_personal_access_token')}
              icon="key"
              onClick={this.generatePersonalAccessToken.bind(this)}
              loading={this.state.isGenerating}
            ></Button>
            <p className="push-10-t">
              <a href="https://developers.peoplegoal.com" target="_blank">
                {I18n.t('js.for_more_api_information')}
              </a>
            </p>
          </div>
        </Collapse>
        {this.renderDialog()}
      </Flexbox>
    );
  }
}
