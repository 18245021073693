import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Tooltip } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('TemplateStore')
@observer
export default class TemplateStateAddState extends React.Component {
  toggleDialog() {
    this.props.TemplateStore.newStateDialogOpen = !this.props
      .TemplateStore.newStateDialogOpen;
  }

  toggleOptions() {
    this.props.TemplateStore.editStateOptions = !this.props
      .TemplateStore.editStateOptions;
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
        alignItems="flex-start"
        marginTop="5px"
        marginBottom="5px"
        marginLeft="10px"
      >
        <Flexbox>
          <Tooltip
            portalContainer={document.body}
            content={I18n.t('js.create_a_new_state')}
            position="right"
          >
            <Button
              icon="plus"
              className="bp3-intent-primary bp3-minimal bp3-large"
              onClick={this.toggleDialog.bind(this)}
            />
          </Tooltip>
        </Flexbox>
      </Flexbox>
    );
  }
}
