// @flow
import * as React from 'react';
import SkeletonUtilities from '../../utils/SkeletonUtilities';

const skeletonCardStyle = {
  marginRight: '10px',
  marginTop: '10px',
};

const pictureStyle = {
  width: '100%',
  height: '50%',
};

const textStyle = {
  color: 'rgba(255,255,255,0.01)',
};

type SkeletonCardProps = {
  center: boolean,
  width: number,
  height: number,
  titleLength: number,
  descriptionLength: number,
};

class SkeletonCard extends React.Component<SkeletonCardProps> {
  static defaultProps = {
    descriptionLength: 50,
    titleLength: 10,
    center: false,
    width: 100,
    height: 200,
  };

  render() {
    const { emptyString } = SkeletonUtilities;
    return (
      <div
        style={{
          ...skeletonCardStyle,
          height: `${this.props.height}px`,
          width: `${this.props.width}px`,
          textAlign: this.props.center ? 'center' : 'left',
        }}
        className="skeleton-card"
      >
        <div
          style={pictureStyle}
          className="skeleton-card__picture bp3-skeleton"
        />
        <h4
          style={textStyle}
          className="skeleton-card__title bp3-skeleton"
        >
          {emptyString(this.props.titleLength)}
        </h4>
        <p
          style={textStyle}
          className="skeleton-card__paragraph bp3-skeleton"
        >
          {emptyString(this.props.descriptionLength)}
        </p>
      </div>
    );
  }
}

export default SkeletonCard;
