const authorizedAccountRequestColumns = [
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.requested_on'),
    id: 'createdAt',
    sortDir: 'desc',
    active: true,
  },
  {
    name: I18n.t('js.status'),
    id: 'approved',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const teamColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
];

const userColumns = [
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.last_signed_in_on'),
    id: 'lastSignInAt',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.owner'),
    id: 'isAccountOwner',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const hookColumns = [
  {
    name: I18n.t('js.trigger'),
    id: 'hookType',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.url'),
    id: 'url',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.created_at'),
    id: 'created_at',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const importColumns = [
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.status'),
    id: 'status',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.progress'),
    id: 'progress',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.created_at'),
    id: 'createdAt',
    sortDir: 'desc',
    active: true,
  },
  {
    name: I18n.t('js.finished_at'),
    id: 'finishedAt',
    sortDir: 'asc',
    active: false,
  },
];

const appColumns = [
  {
    name: I18n.t('js.app_name'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
];

const newUserTeamColumns = [
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.team_owner'),
    id: 'isOwner',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const userTeamColumns = [
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.joined'),
    id: 'createdAt',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.team_owner'),
    id: 'isOwner',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const invitationColumns = [
  {
    name: I18n.t('js.email'),
    id: 'email',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.sent_at'),
    id: 'createdAt',
    sortDir: 'desc',
    active: true,
  },
  {
    name: I18n.t('js.accepted'),
    id: 'accepted',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const notificationColumns = [
  {
    name: I18n.t('js.notification'),
    id: 'title',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.received_at'),
    id: 'createdAt',
    sortDir: 'desc',
    active: true,
  },
];

const relationshipPermissionColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.participant'),
    id: 'participant',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const teamPermissionColumns = [
  {
    name: I18n.t('js.team'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.permission_type'),
    id: 'permissionType',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const userPermissionColumns = [
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.permission_type'),
    id: 'permissionType',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const participantColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.owner'),
    id: 'is_owner',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.visibility_level'),
    id: 'visibility',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.default_user'),
    id: 'default_user',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.default_relationship'),
    id: 'default_relationship',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const statusFlagColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.color'),
    id: 'color',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.represents_archived'),
    id: 'is_archived',
    sortDir: false,
    active: false,
  },
];

const itemColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.updated_at'),
    id: 'updatedAt',
    sortDir: 'desc',
    active: true,
  },
  {
    name: I18n.t('js.state'),
    id: 'state',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.status'),
    id: 'status',
    sortDir: 'asc',
    active: false,
  },
];

const dashboardItemColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
  {
    name: I18n.t('js.type'),
    id: 'itemType',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.status'),
    id: 'status',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.updated_at'),
    id: 'updatedAt',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const dataViewItemColumns = [
  {
    name: I18n.t('js.id'),
    id: 'id',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.current_owner'),
    id: 'current_owner',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.state'),
    id: 'state',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.status'),
    id: 'status',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.updated_at'),
    id: 'updated_at',
    sortDir: 'desc',
    active: true,
  },
];

const storeCategoryColumns = [
  {
    name: I18n.t('js.id'),
    id: 'id',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.title'),
    id: 'title',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const accountDomainColumns = [
  {
    name: I18n.t('js.domain'),
    id: 'domain',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.version'),
    id: 'version',
    sortDir: false,
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const invoiceColumns = [
  {
    name: I18n.t('js.date'),
    id: 'date',
    sortDir: 'desc',
    active: true,
  },
  {
    name: I18n.t('js.number'),
    id: 'number',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.amount'),
    id: 'amount',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.status'),
    id: 'status',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const reportColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.date'),
    id: 'createdAt',
    sortDir: 'desc',
    active: true,
  },
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.status'),
    id: 'status',
    sortDir: 'asc',
    active: false,
  },
  {
    name: I18n.t('js.action'),
    id: 'action',
    sortDir: false,
    active: false,
  },
];

const newItemUserColumns = [
  {
    name: I18n.t('js.user'),
    id: 'fullName',
    sortDir: 'asc',
    active: true,
  },
];

const newItemTeamColumns = [
  {
    name: I18n.t('js.name'),
    id: 'name',
    sortDir: 'asc',
    active: true,
  },
];

class ColumnDefinitions {
  static get authorizedAccountRequestColumns() {
    return authorizedAccountRequestColumns;
  }

  static get userColumns() {
    return userColumns;
  }

  static get teamColumns() {
    return teamColumns;
  }

  static get importColumns() {
    return importColumns;
  }

  static get hookColumns() {
    return hookColumns;
  }

  static get appColumns() {
    return appColumns;
  }

  static get userTeamColumns() {
    return userTeamColumns;
  }

  static get newUserTeamColumns() {
    return newUserTeamColumns;
  }

  static get invitationColumns() {
    return invitationColumns;
  }

  static get notificationColumns() {
    return notificationColumns;
  }

  static get relationshipPermissionColumns() {
    return relationshipPermissionColumns;
  }

  static get teamPermissionColumns() {
    return teamPermissionColumns;
  }

  static get userPermissionColumns() {
    return userPermissionColumns;
  }

  static get participantColumns() {
    return participantColumns;
  }

  static get statusFlagColumns() {
    return statusFlagColumns;
  }

  static get itemColumns() {
    return itemColumns;
  }

  static get dashboardItemColumns() {
    return dashboardItemColumns;
  }

  static get dataViewItemColumns() {
    return dataViewItemColumns;
  }

  static get storeCategoryColumns() {
    return storeCategoryColumns;
  }

  static get accountDomainColumns() {
    return accountDomainColumns;
  }

  static get invoiceColumns() {
    return invoiceColumns;
  }

  static get reportColumns() {
    return reportColumns;
  }

  static get newItemUserColumns() {
    return newItemUserColumns;
  }

  static get newItemTeamColumns() {
    return newItemTeamColumns;
  }
}

export default ColumnDefinitions;
