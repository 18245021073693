// @flow
import React from 'react';
import Flexbox from 'flexbox-react';
import { Classes } from '@blueprintjs/core';
import NewDemoRequest from '../components/shared/NewDemoRequest';
import ErrorBoundary from '../components/errors/ErrorBoundary';

type DemoRequestViewProps = {};

type DemoRequestViewState = {
  formLoaded: boolean,
};

export default class DemoRequestView extends React.Component<
  DemoRequestViewProps,
  DemoRequestViewState
> {
  constructor(props: DemoRequestViewProps) {
    super(props);

    this.state = { formLoaded: false };
  }

  componentDidMount() {
    this.setState({ formLoaded: false });
    this.loadForm();
  }

  componentWillUnmount() {
    // lazy fix
    // $FlowFixMe
    document.hbspt = null;
  }

  loadForm() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    // $FlowFixMe
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt && !this.state.formLoaded) {
        window.hbspt.forms.create({
          portalId: '3757299',
          formId: 'adbad32c-5416-4187-a81b-b3ae34dbad79',
          target: '#hubspotForm',
        });
        this.setState({ formLoaded: true });
      }
    });
  }

  render() {
    return (
      <ErrorBoundary>
        <Flexbox
          className="app-content-centered fixed-width-container"
          flexDirection="column"
        >
          <div className="demo-request__hero-text">
            <Flexbox justifyContent="center" marginBottom="20px">
              <a href="/">
                <img
                  alt={I18n.t(`js.peoplegoal`)}
                  src={I18n.t(`js.pg_logo_black`)}
                  className="img-logo pt-cursor-pointer"
                />
              </a>
            </Flexbox>
            <h1
              className={`her ${Classes.HEADING}`}
              style={{ textAlign: 'center' }}
              // $FlowFixMe
            >
              {I18n.t('js.request_a_demo')}
            </h1>
            <p style={{ textAlign: 'center', marginTop: '20px' }}>
              {I18n.t('js.peoplegoal_placeholder_text')}
            </p>
          </div>
          <Flexbox width="500px" className="bp3-card" padding="30px">
            <NewDemoRequest
              submitLabel={I18n.t('js.request_a_demo')}
              redirectUrl=""
            />
          </Flexbox>
        </Flexbox>
      </ErrorBoundary>
    );
  }
}
