import styled from 'styled-components';

export default styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: ${props => props.theme.darkGray3};
  min-height: 65px;

  .left,
  .right {
    display: flex;
    align-content: center;
  }

  .left > *,
  .right > * {
    margin-top: auto;
    margin-bottom: auto;
  }
  svg path {
    fill: ${props => props.theme.white};
  }
`;
