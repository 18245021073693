import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import TeamUsersNewUserSearch from './TeamUsersNewUserSearch';
import TeamUsersNewUserRow from './TeamUsersNewUserRow';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import _ from 'lodash';

@inject('TeamsStore', 'TeamUsersStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class TeamUsersNewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    let totalLength = this.props.TeamUsersStore.newUserTeams.length;

    this.determineCloseDialog(totalLength);

    _.map(this.props.TeamUsersStore.newUserTeams, newUserTeam => {
      this.props.TeamUsersStore.createUserTeam(newUserTeam.userId, newUserTeam.teamId, newUserTeam.isPrimary, newUserTeam.isOwner)
        .then(response => {
          totalLength--;
          this.determineCloseDialog(totalLength);
          this.props.TeamsStore.addTeam(response.data.team, 'single');
          this.props.ToastStore.showToast(
            I18n.t('js.user_added_to_team', {
              name: response.data.user.full_name,
            }),
            'success'
          );
        })
        .catch(() => {
          totalLength--;
          this.determineCloseDialog(totalLength);
        });
    });
  }

  determineCloseDialog(length) {
    if (length === 0) {
      this.toggleLoading();
      this.toggleDialog();
      this.props.TeamUsersStore.newUserTeams = [];
    }
  }

  handleColumnSort(column) {
    this.props.TeamUsersStore.setNewUserTeamSortParams(column.id, column.sortDir);
  }

  handleCancel() {
    this.toggleDialog();
    this.props.TeamUsersStore.newUserTeams = [];
  }

  renderNewTeamUsers() {
    if (this.props.TeamUsersStore.newUserTeams.length > 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <SimpleTableHeader
                columns={this.props.TeamUsersStore.newUserTeamColumns}
                handleColumnSort={this.handleColumnSort.bind(this)}
              />
            </thead>
            <tbody>
              {_.map(this.props.TeamUsersStore.newUserTeams, newUserTeam => (
                <TeamUsersNewUserRow userTeam={newUserTeam} key={newUserTeam.id} />
              ))}
            </tbody>
          </table>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="new-person"
        isOpen={this.state.isOpen}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.add_users')}
        style={{ width: '50%' }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.add_users_to_team_description')}</p>
            </Flexbox>
            <Flexbox flexGrow={1}>
              <TeamUsersNewUserSearch />
            </Flexbox>
            {this.renderNewTeamUsers()}
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={this.props.TeamUsersStore.newUserTeams.length === 0}
                text={I18n.t('js.add_users')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox>
        <Button className="bp3-intent-primary" icon="new-person" text={I18n.t('js.add_users')} onClick={this.toggleDialog.bind(this)} />{' '}
        {this.renderDialog()}
      </Flexbox>
    );
  }
}
