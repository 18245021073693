import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import { Button, Intent, FormGroup, InputGroup, Switch, Tag, Spinner } from '@blueprintjs/core';
import moment from 'moment';

const SyncTable = props => {
  const { hasSyncs, syncs, isSyncRunning, runSync, store, isSyncTriggered, isIntegrationActive } = props;

  const checkIntent = status => {
    switch (status) {
      case 'completed':
        return 'SUCCESS';
        break;
      case 'in_progress':
        return 'PRIMARY';
        break;
      case 'failed':
        return 'DANGER';
        break;
      default:
    }
  };

  const normalizeStatusText = status => {
    switch (status) {
      case 'completed':
        return 'Completed';
        break;
      case 'in_progress':
        return 'In progress';
        break;
      case 'failed':
        return 'Failed';
        break;
      default:
    }
  };

  const calcDuration = sync => {
    switch (sync.status) {
      case 'completed':
        return Math.round((sync.duration / 60) * 100) / 100;
        break;
      default:
        return (Date.now() - moment.utc(sync.startedAt)) / 1000;
    }
  };

  const renderSyncTable = hasSyncs ? (
    <table className="push-30-b bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-interactive full-table">
      <thead>
        <tr>
          <th>{I18n.t('js.status')}</th>
          <th>{I18n.t('js.started_at')}</th>
        </tr>
      </thead>
      <tbody>
        {syncs.map(sync => (
          <tr key={sync.id} onClick={() => store.toggleDrawer(sync.id)}>
            <td>
              <Tag className="push-0" intent={checkIntent(sync.status)}>
                {normalizeStatusText(sync.status)}
              </Tag>
            </td>
            <td>{moment.utc(sync.startedAt).format('MMMM Do YYYY, h:mm a')}</td>
            {/* <td>
              <Tag>
                {calcDuration(sync)}
                {I18n.t('js.minutes')}
              </Tag>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div>
      <Button
        className="bp3-icon-play push-20-t push-20-r"
        text={I18n.t('models.shared.run_first_sync')}
        loading={isSyncTriggered}
        intent="SUCCESS"
        onClick={runSync}
      />
    </div>
  );

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexDirection="column">
        <Flexbox justifyContent="space-between" alignItems="center">
          <h2>{I18n.t('js.sync_history')}</h2>
          {hasSyncs ? (
            <div>
              <Button
                disabled={!isIntegrationActive}
                className="bp3-icon-play"
                loading={isSyncTriggered}
                text="Run Now"
                onClick={runSync}
              />
            </div>
          ) : (
            ''
          )}
        </Flexbox>
        {renderSyncTable}
      </Flexbox>
    </Flexbox>
  );
};
export default observer(SyncTable);
