import React from 'react';
import Flexbox from 'flexbox-react';
import { action, observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Intent, Tag, ProgressBar } from '@blueprintjs/core';
import UserAvatar from '../shared/UserAvatar';
import ImportDrawer from './ImportDrawer';
import CurrentUserStore from '../../stores/CurrentUserStore';
import AccountUsersStore from '../../stores/AccountUsersStore';
import AppItemStore from '../../stores/AppItemStore';
import ImportStore from '../../stores/ImportStore';
import ImportActions from '../../actions/ImportActions';
import TeamsStore from '../../stores/TeamsStore';
import ToastStore from '../../stores/ToastStore';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'AccountUsersStore',
  'AppItemStore',
  'ImportStore',
  'ImportActions',
  'TeamsStore',
  'ToastStore'
)
@observer
export default class ImportRow extends React.Component {
  @observable isDrawerOpen = false;
  @observable isFetchingImportedItems = false;

  @action toggleFetchingImportedItems() {
    this.isFetchingImportedItems = !this.isFetchingImportedItems;
  }

  @action toggleDrawer() {
    if (!this.isDrawerOpen) {
      this.isDrawerOpen = !this.isDrawerOpen;
      this.toggleFetchingImportedItems();

      switch (ImportStore.importableType) {
        case 'App':
          // fetch imported items
          AppItemStore.resetStartingAttributes();
          ImportActions.fetchImportedItems(
            ImportStore.importableId,
            this.props.import.id,
            1,
            25,
            0
          )
            .then(response => {
              AppItemStore.createPaginator(response.headers);
              _.map(response.data, importedItem => {
                AppItemStore.addItem(importedItem);
              });
              this.toggleFetchingImportedItems();
            })
            .catch(() => {
              this.toggleFetchingImportedItems();
            });
          break;
        case 'ProfileTemplate':
          // fetch imported users
          AccountUsersStore.resetStartingAttributes();
          ImportActions.fetchImportedUsers(
            this.props.import.id,
            1,
            25,
            0
          )
            .then(response => {
              AccountUsersStore.createPaginator(response.headers);
              _.map(response.data, importedUser => {
                AccountUsersStore.addUser(importedUser);
              });
              this.toggleFetchingImportedItems();
            })
            .catch(() => {
              this.toggleFetchingImportedItems();
            });
          break;
        case 'Team':
          // fetch imported teams
          ImportActions.fetchImportedTeams(
            this.props.import.id,
            1,
            25,
            0
          )
            .then(response => {
              TeamsStore.createPaginator(response.headers);
              _.map(response.data, team => {
                TeamsStore.addTeam(team, 'all');
              });
              this.toggleFetchingImportedItems();
            })
            .catch(() => {
              this.toggleFetchingImportedItems();
            });
          break;
        default:
          this.toggleFetchingImportedItems();
      }
    } else {
      this.isDrawerOpen = !this.isDrawerOpen;
      // Clear imported items
      AppItemStore.resetStartingAttributes();
      AccountUsersStore.resetStartingAttributes();
    }
  }

  renderDrawer() {
    return (
      <ImportDrawer
        isDrawerOpen={this.isDrawerOpen}
        isFetchingImportedItems={this.isFetchingImportedItems}
        toggleFetchingImportedItems={this.toggleFetchingImportedItems.bind(
          this
        )}
        toggleDrawer={this.toggleDrawer.bind(this)}
        import={this.props.import}
      />
    );
  }

  render() {
    const {
      user,
      status,
      progress,
      createdAtToDate,
      finishedAtToDate,
    } = this.props.import;

    return (
      <>
        <tr
          className="bp3-cursor-pointer"
          onClick={this.toggleDrawer.bind(this)}
        >
          <td>
            <UserAvatar user={user} avatarSize={20} noLink />
          </td>
          <td>
            <Tag
              intent={
                status === 'Complete'
                  ? Intent.SUCCESS
                  : status === 'Complete, with errors'
                  ? Intent.WARNING
                  : status === 'In progress'
                  ? Intent.PRIMARY
                  : status === 'Failed'
                  ? Intent.DANGER
                  : Intent.DEFAULT
              }
            >
              {this.props.import.status}
            </Tag>
          </td>
          <td>
            <Flexbox
              flexDirection="row"
              flexGrow={1}
              justifyContent="center"
              alignContent="center"
              paddingTop="10px"
              minWidth="150px"
            >
              <ProgressBar
                animate={status === 'In progress'}
                stripes={status === 'In progress'}
                intent={
                  status === 'Complete'
                    ? Intent.SUCCESS
                    : status === 'Complete, with errors'
                    ? Intent.WARNING
                    : status === 'In progress'
                    ? Intent.PRIMARY
                    : status === 'Failed'
                    ? Intent.DANGER
                    : Intent.DEFAULT
                }
                value={progress}
              />
            </Flexbox>
          </td>
          <td>{createdAtToDate}</td>
          <td>{finishedAtToDate}</td>
        </tr>
        {this.renderDrawer()}
      </>
    );
  }
}
