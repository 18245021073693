/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import AdminStore from '../stores/AdminStore';
import AdminActions from '../actions/AdminActions';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import NewsList from '../components/news/NewsList';
import LayoutHeader from '../components/layout/LayoutHeader';
import SignUpBar from '../components/layout/SignUpBar';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class NewsView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    AdminActions.fetchNews()
      .then(response => {
        _.map(response.items, news => {
          AdminStore.addNews(news);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderSignUp() {
    if (
      !CurrentUserStore.userSignedIn &&
      !CurrentUserStore.isLoading
    ) {
      return <SignUpBar />;
    }
    return undefined;
  }

  renderContent() {
    if (this.isLoading) {
      return (
        <Flexbox
          flexdirection="row"
          flexGrow={1}
          paddingTop="50px"
          paddingBottom="50px"
        >
          <NonIdealState
            title={I18n.t('js.fetching_news')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (AdminStore.news.length > 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1}>
            <NewsList />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox
          flexdirection="row"
          className="bp3-card"
          flexGrow={1}
          paddingTop="50px"
          paddingBottom="50px"
        >
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t(
              'js.there_is_nothing_to_show_right_now'
            )}
            icon="book"
            className="bp3-text-muted"
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    const stores = {
      AdminActions,
      AdminStore,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox
              flexGrow={1}
              width="100hw"
              alignContent="stretch"
              flexDirection="column"
              paddingBottom="0px"
            >
              {this.renderSignUp()}
              <LayoutHeader
                title={I18n.t('js.product_news')}
                subtitle={I18n.t('js.product_news_description')}
                icon={IconNames.BOOK}
              />
              <Flexbox
                className="fixed-width-container"
                flexDirection="row"
                flexGrow={1}
                marginTop="20px"
              >
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
