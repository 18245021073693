import { action, computed, observable } from 'mobx';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Item from '../models/ItemModel';
import User from '../models/UserModel';
import Team from '../models/TeamModel';
import StatusFlag from '../models/StatusFlagModel';
import State from '../models/StateModel';
import _ from 'lodash';
import Paginator from '../models/PaginatorModel';
import Cookies from 'js-cookie';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import nanoid from 'nanoid';

class AppItemStore {
  @observable activeRequest = nanoid();
  @observable newItem = new Item();
  @observable newItemUser = new User();
  @observable newItemUsers = [];
  @observable newItemTeams = [];
  @observable items = [];
  @observable itemColumns = _.clone(ColumnDefinitions.itemColumns);
  @observable newItemUserColumns = _.clone(ColumnDefinitions.newItemUserColumns);
  @observable newItemTeamColumns = _.clone(ColumnDefinitions.newItemTeamColumns);
  @observable pagination = new Paginator();
  @observable itemData = [];
  @observable searchUsers = [];
  @observable searchTeams = [];

  @computed
  get itemDataColumns() {
    const results = [];
    _.mapKeys(this.itemData[0], (value, key) => {
      if (key.length > 0) {
        results.push(key);
      }
    });
    return results;
  }

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  updateNewItem(data, attribute) {
    this.newItem[attribute] = data;
  }

  @action
  addItem(data) {
    const newItem = new Item(
      data.id,
      data.app_id,
      data.state_id,
      data.user_id,
      data.status_flag_id,
      data.name,
      data.item_type,
      data.item_icon,
      data.item_color,
      data.item_layout,
      data.image_file_name,
      data.comment_count,
      data.is_done_for_current_user,
      data.is_webform,
      data.import_id,
      data.webform_user_email,
      data.webform_user_full_name,
      data.webform_user_image_file_name,
      data.dataview,
      data.simple_dataview,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    const itemUser = new User(
      data.user.id,
      data.user.email,
      data.user.first_name,
      data.user.last_name,
      data.user.full_name,
      data.user.account_id,
      data.user.human_friendly_id,
      data.user.is_current_user,
      data.user.is_account_owner,
      data.user.image_file_name,
      data.user.background_image_file_name,
      data.user.last_sign_in_at,
      data.user.locale,
      data.user.theme,
      data.user.created_at,
      data.user.updated_at,
      data.user.deleted_at,
      data.user.created_by,
      data.user.updated_by,
      data.user.deleted_by
    );
    newItem.user = itemUser;

    const statusFlag = new StatusFlag(
      data.status_flag.id,
      data.status_flag.app_id,
      data.status_flag.name,
      data.status_flag.color,
      data.status_flag.is_archived,
      data.status_flag.created_at,
      data.status_flag.created_by,
      data.status_flag.deleted_at,
      data.status_flag.deleted_by,
      data.status_flag.updated_at,
      data.status_flag.updated_by
    );
    newItem.statusFlag = statusFlag;

    const state = new State(
      data.state.id,
      data.state.app_id,
      data.state.participant_id,
      data.state.status_flag_id,
      data.state.name,
      data.state.all_visible,
      data.state.all_editable,
      data.state.row_order,
      data.state.created_at,
      data.state.created_by,
      data.state.deleted_at,
      data.state.deleted_by,
      data.state.updated_at,
      data.state.updated_by
    );
    newItem.state = state;

    this.items = _.unionBy([newItem], this.items, 'id');
    this.sortItems();
  }

  @action
  removeItem(item) {
    this.items = _.filter(this.items, o => o.id !== item.id);
  }

  @action
  setItemSortParams(param) {
    const newItemColumns = this.itemColumns;
    _.map(newItemColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.itemColumns = newItemColumns;
    this.sortItems();
  }

  @action
  sortItems() {
    let newItems = this.items;
    const activeColumn = _.find(this.itemColumns, o => o.active);
    switch (activeColumn.id) {
      case 'fullName':
        newItems = _.orderBy(newItems, e => e.user.fullName, [activeColumn.sortDir]);
        break;
      case 'state':
        newItems = _.orderBy(newItems, e => e.state.name, [activeColumn.sortDir]);
        break;
      case 'status':
        newItems = _.orderBy(newItems, e => e.statusFlag.name, [activeColumn.sortDir]);
        break;
      default:
        newItems = _.orderBy(newItems, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.items = newItems;
  }

  @action
  sortItemData(column, dir) {
    let newItemData = this.itemData;
    newItemData = _.orderBy(newItemData, [column], [dir]);
    this.itemData = newItemData;
  }

  /*=======================================================================
  Adding items for multiple users
  =======================================================================*/

  @action
  addNewItemUser(user) {
    this.newItemUsers = _.unionBy([user], this.newItemUsers, 'id');
  }

  @action
  removeNewItemUser(userId) {
    this.newItemUsers = _.filter(this.newItemUsers, o => o.id !== userId);
  }

  @action
  resetSearchUsers() {
    return new Promise(resolve => {
      this.searchUsers = [];
      resolve();
    });
  }

  @action
  addSearchUser(data) {
    const newSearchUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.searchUsers = _.unionBy([newSearchUser], this.searchUsers, 'id');
    this.sortSearchUsers();
  }

  @action
  sortSearchUsers() {
    let newSearchUsers = this.searchUsers;
    newSearchUsers = _.orderBy(newSearchUsers, ['firstName'], ['asc']);
    this.searchUsers = newSearchUsers;
  }

  @action
  setNewItemUserSortParams(param) {
    const newItemUserColumns = this.newItemUserColumns;
    _.map(newItemUserColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.newItemUserColumns = newItemUserColumns;
    this.sortNewItemUsers();
  }

  @action
  sortNewItemUsers() {
    let newItemUsers = this.newItemUsers;
    const activeColumn = _.find(this.newItemUserColumns, o => o.active);
    if (activeColumn.id === 'fullName') {
      newItemUsers = _.orderBy(newItemUsers, e => e.fullName, [activeColumn.sortDir]);
    } else {
      newItemUsers = _.orderBy(newItemUsers, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.newItemUsers = newItemUsers;
  }

  @action
  fetchUsersQuery(query, requestId) {
    return new Promise((resolve, reject) => {
      this.activeRequest = requestId;
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${query}`, {
          requestId: 'userSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            if (this.activeRequest === requestId) {
              this.addSearchUser(user);
            }
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          }
        });
    });
  }

  /*=======================================================================
  Adding items for multiple teams
  =======================================================================*/

  @action
  addNewItemTeam(team) {
    this.newItemTeams = _.unionBy([team], this.newItemTeams, 'id');
  }

  @action
  removeNewItemTeam(teamId) {
    this.newItemTeams = _.filter(this.newItemTeams, o => o.id !== teamId);
  }

  @action
  resetSearchTeams() {
    return new Promise(resolve => {
      this.searchTeams = [];
      resolve();
    });
  }

  @action
  addSearchTeam(data) {
    const newSearchTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.searchTeams = _.unionBy([newSearchTeam], this.searchTeams, 'id');
    this.sortSearchTeams();
  }

  @action
  sortSearchTeams() {
    let newSearchTeams = this.searchTeams;
    newSearchTeams = _.orderBy(newSearchTeams, ['firstName'], ['asc']);
    this.searchTeams = newSearchTeams;
  }

  @action
  setNewItemTeamSortParams(param) {
    const newItemTeamColumns = this.newItemTeamColumns;
    _.map(newItemTeamColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.newItemTeamColumns = newItemTeamColumns;
    this.sortNewItemTeams();
  }

  @action
  sortNewItemTeams() {
    let newItemTeams = this.newItemTeams;
    const activeColumn = _.find(this.newItemTeamColumns, o => o.active);
    newItemTeams = _.orderBy(newItemTeams, [activeColumn.id], [activeColumn.sortDir]);
    this.newItemTeams = newItemTeams;
  }

  @action
  fetchTeamsQuery(query, requestId) {
    return new Promise((resolve, reject) => {
      this.activeRequest = requestId;
      axios
        .get(`${Cookies.get('apiEnv')}/teams?query=${query}`, {
          requestId: 'teamSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, team => {
            if (this.activeRequest === requestId) {
              this.addSearchTeam(team);
            }
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          }
        });
    });
  }

  @action
  resetStartingAttributes() {
    this.newItem = new Item();
    this.newItemUser = new User();
    this.newItemUsers = [];
    this.newItemTeams = [];
    this.items = [];
    this.itemData = [];
    this.searchUsers = [];
    this.searchTeams = [];
  }
}

const store = new AppItemStore();
export default store;
