import { observable } from 'mobx';

export default class ElementImage {
  @observable
  id;
  @observable
  elementId;
  @observable
  imageFileName;
  @observable
  imageContentType;
  @observable
  imageUpdatedAt;
  @observable
  imageFileSize;
  @observable
  alignment;

  constructor(
    id,
    elementId,
    imageFileName,
    imageContentType,
    imageUpdatedAt,
    imageFileSize,
    alignment
  ) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.imageFileName = imageFileName || '';
    this.imageContentType = imageContentType || '';
    this.imageUpdatedAt = imageUpdatedAt || '';
    this.imageFileSize = imageFileSize || '';
    this.alignment = alignment || '';
  }
}
