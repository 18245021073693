/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { IconNames } from '@blueprintjs/icons';
import CommentStore from '../stores/CommentStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import ShowComment from '../components/comments/ShowComment';
import { Spinner } from '@blueprintjs/core';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class SingleCommentView extends React.Component {
  @observable
  isLoading = true;
  @observable
  comment = '';

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  doNothing() {
    // do nothing
  }

  componentDidMount() {
    CommentStore.fetchComment(this.props.commentId)
      .then(response => {
        CommentStore.addComment(response.data).then(comment => {
          this.comment = comment;
          this.toggleLoading();
        });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderComment() {
    if (this.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <ShowComment
        key={this.comment.id}
        comment={this.comment}
        canPin={false}
        afterCreate={this.doNothing.bind(this)}
        afterDelete={this.doNothing.bind(this)}
        afterChange={this.doNothing.bind(this)}
      />
    );
  }
  render() {
    const stores = {
      CommentStore,
      ToastStore,
      CurrentUserStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <LayoutHeader
                title={I18n.t('js.comments')}
                subtitle={I18n.t('js.comment_single_view_description')}
                icon={IconNames.COMMENT}
              />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderComment()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
