import React from 'react';
import Flexbox from 'flexbox-react';
import { Tooltip, Position } from '@blueprintjs/core';

export default class ElementTag extends React.Component {
  render() {
    const { tagType, tagIcon, tagTooltip } = this.props;
    let tagIntent = 'bp3-intent-danger';
    if (tagType === 'protected' || tagType == 'protected_section') {
      tagIntent = 'bp3-intent-warning';
    }
    if (
      tagType === 'account_owner_only' ||
      tagType === 'account_owner_only_section'
    ) {
      tagIntent = 'bp3-intent-primary';
    }
    return (
      <Flexbox flexDirection="row" flexGrow={0}>
        <Tooltip
          content={tagTooltip}
          position={Position.TOP}
          portalContainer={document.body}
        >
          <span
            className={`bp3-tag bp3-minimal bp3-cursor-pointer ${tagIntent} push-5-r`}
          >
            <span
              className={`bp3-icon bp3-icon-${tagIcon} push-5-r`}
            />
            {I18n.t(`js.${tagType}`)}
          </span>
        </Tooltip>
      </Flexbox>
    );
  }
}
