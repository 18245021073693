import React from 'react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Tag, Tooltip, Intent, Position, Dialog } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import Utilities from '../../utils/Utilities';
import Avatar from 'react-avatar';
import moment from 'moment';
import RichTextDisplay from '../shared/RichTextDisplay';
import CommentsView from '../../views/CommentsView';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore',
  'WidgetStore',
  'WorkspaceStore'
)
@observer
export default class DashboardNewsWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggleDialog() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  renderUserDisplay() {
    if (this.props.widget.user_display_enabled) {
      const { widget } = this.props;
      return (
        <Flexbox flexGrow={0} flexDirection="row" paddingTop="10px" marginBottom="10px">
          <Flexbox flexDirection="column" flexGrow={1}>
            <Flexbox className="bp3-small" alignItems="center">
              <Flexbox marginRight="10px">
                <Avatar src={widget.user.image_file_name} size={40} round={true} />
              </Flexbox>
              <Flexbox flexDirection="column">
                <small
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('js.widget_created_by', {
                      full_name: widget.user.full_name,
                    }),
                  }}
                />
                <Flexbox flexDirection="row" alignItems="center">
                  <span className="bp3-icon bp3-icon-calendar bp3-text-muted push-5-r"></span>
                  <small className="bp3-text-muted">{moment.utc(widget.created_at).format('MMMM Do YYYY')}</small>
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }

  renderComments() {
    if (this.props.widget.comments_enabled) {
      return (
        <Flexbox flexGrow={0}>
          <CommentsView
            commentable={this.props.widget}
            commentableType="Widget"
            stub={true}
            sortByNew={true}
            noTitle={false}
            showNone={false}
            canPin={false}
            buttonText={I18n.t('js.comment')}
          />
        </Flexbox>
      );
    }
    return null;
  }

  renderOriginalLink() {
    let path = '/';
    switch (this.props.widget.widgetable_type) {
      case 'Workspace':
        path = `/workspaces/${this.props.widget.widgetable_id}`;
        break;
      case 'WorkspaceFolder':
        const folder = _.find(this.props.WorkspaceStore.workspaceFolders, o => o.id === this.props.widget.widgetable_id);
        if (folder) {
          path = `/workspaces/${folder.workspaceId}?folder=${this.props.widget.widgetable_id}`;
        }
        break;
      case 'App':
        path = `/apps/${this.props.widget.widgetable_id}`;
        break;
      case 'Team':
        path = `/teams/${this.props.widget.widgetable_id}`;
        break;
    }
    return (
      <Flexbox flexDirection="row" marginTop="10px" marginBottom="10px">
        <AnchorButton href={path} text={I18n.t('js.view_original')} icon="add-to-artifact" />
      </Flexbox>
    );
  }

  render() {
    const { widget } = this.props;
    const imgUrl = _.replace(widget.image_file_name, '/upload/', '/upload/t_company_news/');
    let bgStyle = {
      background: `url('${imgUrl}')`,
      backgroundSize: 'cover',
      height: '200px',
      borderRadius: '3px',
    };
    const darkenAmount = 0.25; // %
    if (!this.props.widget.image_file_name) {
      bgStyle = {
        background: `#293742`,
        height: '200px',
        borderRadius: '3px',
      };
    }
    return (
      <Flexbox flexDirection="column" flexGrow={0} width="300px" height="200px" marginRight="10px" marginBottom="70px">
        <Flexbox
          className="bp3-card bp3-card-no-padding bp3-interactive animated fadeIn"
          onClick={this.toggleDialog.bind(this)}
          flexGrow={1}
          flexDirection="column"
        >
          <Flexbox flexGrow={1} flexDirection="column" className="bp3-dark">
            <Flexbox style={bgStyle} flexDirection="column" justifyContent="center" alignItems="center">
              <Flexbox flexDirection="column" justifyContent="center" alignItems="center" className="text-center" padding="15px">
                <h4 className={`${Classes.HEADING} text-white`}>
                  {widget.title ? _.truncate(this.props.widget.title, { length: 60 }) : I18n.t('js.untitled_post')}
                </h4>
                <p>{I18n.t('js.read_more')}</p>
              </Flexbox>
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="row" paddingLeft="20px">
            {this.renderUserDisplay()}
          </Flexbox>
        </Flexbox>
        <Dialog
          portalContainer={document.body}
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog.bind(this)}
          canOutsideClickClose={true}
          inline={true}
          title={widget.title ? widget.title : I18n.t('js.untitled_post')}
          style={{ width: '1300px' }}
        >
          <Flexbox flexDirection="column" padding="20px" paddingTop="10px">
            {widget.content ? <RichTextDisplay content={widget.content} /> : null}
            {widget.image_file_name ? (
              <Flexbox flexDirection="column" flexGrow={1} justifyContent="center" alignItems="center" marginTop="20px">
                <img src={widget.image_file_name} className="widget-image" />
              </Flexbox>
            ) : null}
            <Flexbox marginTop="20px" flexDirection="column">
              {this.renderUserDisplay()}
              {this.renderOriginalLink()}
              {this.renderComments()}
            </Flexbox>
          </Flexbox>
        </Dialog>
      </Flexbox>
    );
  }
}
