/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Spinner, Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('IntegrationStore', 'ToastStore')
@observer
export default class IntegrationsTabs extends React.Component {
  renderRightLinks() {
    return (
      <Tabs
        id="accountSettingsIntegrationsTabnav"
        selectedTabId={this.props.IntegrationStore.activeTab}
        vertical={true}
        onChange={tab => this.props.IntegrationStore.setActiveTab(tab)}
      >
        <Tab id="webhooks" title={I18n.t('js.webhooks')} />
        <Tab id="slack-integration" title={I18n.t('js.slack_integration')} />
        <Tab id="bamboohr-integration" title={I18n.t('js.bamboo_hr_integration')} />
        <Tab id="azure-integration" title={I18n.t('js.azure_integration')} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return this.renderRightLinks();
  }
}
