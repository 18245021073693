/*
 * AboutView.js
 *
 * This is the about app, found at peoplegoal.com/about
 *
 * @flow
 */

import * as React from 'react';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import CTAFooter from '../components/landing/CTAFooter';
import ErrorBoundary from '../components/errors/ErrorBoundary';

const AboutContainer = styled.div`
  max-width: 930px;
  margin: auto;
  padding: 32px 0 64px 0;
`;

const CenteredPara = styled.p`
  text-align: center;
  line-spacing: 27px;
`;

const CenteredHeader = styled.h2`
  text-align: center;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 64px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 32px 0;
`;

const IconBackground = styled.div`
  width: 72px;
  height: 72px;
  margin: auto;
  border-radius: 50%;
  background: #db2c6f;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 170px;
  padding: 32px 0;

  .${Classes.ICON} {
    margin: 20px;
  }

  .${Classes.ICON} > svg > path {
    fill: #f5f7f9;
  }
`;

const IconDescription = styled.p`
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
`;

const Tagline = styled.h2`
  text-align: center;
  margin: 64px auto 0 auto;
  font-weight: 400;
  font-size: 32px;
`;

const AboutView = () => (
  <ErrorBoundary>
    <Flexbox className="peoplegoal-group-bg" height="400px" />
    <AboutContainer className="about-view">
      <Flexbox
        justifyContent="center"
        marginTop="40px"
        marginBottom="40px"
      >
        <img
          alt="logo"
          src={I18n.t(`js.pg_logo_black`)}
          width="50px"
          height="50px"
        />
      </Flexbox>
      <CenteredHeader>
        {/* $FlowFixMe */}
        {I18n.t('js.about_view_headline')}
      </CenteredHeader>
      <CenteredPara className={Classes.RUNNING_TEXT}>
        {I18n.t('js.about_view_para_1')}
      </CenteredPara>
      <IconsContainer>
        <IconContainer>
          <IconBackground>
            <Icon icon={IconNames.PROPERTIES} iconSize="32px" />
          </IconBackground>
          <IconDescription>
            {I18n.t('js.list_requirements')}
          </IconDescription>
        </IconContainer>
        <IconContainer>
          <IconBackground>
            <Icon icon={IconNames.WRENCH} iconSize="32px" />
          </IconBackground>
          <IconDescription>
            {I18n.t('js.create_solution')}
          </IconDescription>
        </IconContainer>
        <IconContainer>
          <IconBackground>
            <Icon icon={IconNames.SAVED} iconSize="32px" />
          </IconBackground>
          <IconDescription>{I18n.t('js.review')}</IconDescription>
        </IconContainer>
        <IconContainer>
          <IconBackground>
            <Icon icon={IconNames.CLOUD_UPLOAD} iconSize="32px" />
          </IconBackground>
          <IconDescription>
            {I18n.t('js.rollout_to_employees')}
          </IconDescription>
        </IconContainer>
      </IconsContainer>
      <CenteredPara className={Classes.RUNNING_TEXT}>
        {I18n.t('js.about_view_para_2')}
      </CenteredPara>
      <Tagline>{I18n.t('js.peoplegoal_tagline')}</Tagline>
    </AboutContainer>
    <CTAFooter
      prompt={I18n.t('js.get_in_touch_if_you_want_to_make_hr_better')}
      CTAs={[
        {
          description: I18n.t('js.request_a_demo'),
          link: '/request-a-demo',
        },
        {
          description: I18n.t('js.join_the_team'),
          link: 'mailto:contact@peoplegoal.com',
        },
      ]}
    />
  </ErrorBoundary>
);

export default AboutView;
