import React from 'react';
import Flexbox from 'flexbox-react';
import TeamEditName from './TeamEditName';
import TeamEditRelationships from './TeamEditRelationships';
import TeamEditPrivacy from './TeamEditPrivacy';
import TeamEditImage from './TeamEditImage';
// import TeamEditBackgroundImage from './TeamEditBackgroundImage';
import TeamEditDeleteTeam from './TeamEditDeleteTeam';
import { inject, observer } from 'mobx-react';

@inject('CurrentUserStore', 'TeamsStore', 'ToastStore')
@observer
export default class TeamEdit extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <TeamEditName />
        <TeamEditRelationships />
        <TeamEditPrivacy />
        <TeamEditImage />
        {/* <TeamEditBackgroundImage/> */}
        <TeamEditDeleteTeam />
      </Flexbox>
    );
  }
}
