import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import HookTypes from '../static/HookTypes';
import moment from 'moment';
import { Intent } from '@blueprintjs/core';

export default class Comment {
  @observable
  id;
  @observable
  userId;
  @observable
  accountId;
  @observable
  hookType;
  @observable
  url;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(id, userId, accountId, hookType, url, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy) {
    this.id = id || '';
    this.userId = userId || '';
    this.accountId = accountId || '';
    this.hookType = hookType || 'item_created';
    this.url = url || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get hookIntent() {
    if (this.hookType.includes('create') || this.hookType.includes('join') || this.hookType.includes('added')) {
      return Intent.SUCCESS;
    }
    if (this.hookType.includes('deleted') || this.hookType.includes('left')) {
      return Intent.DANGER;
    }
    if (this.hookType.includes('updated') || this.hookType.includes('submitted')) {
      return Intent.PRIMARY;
    }
    return Intent.WARNING;
  }

  @computed
  get urlValid() {
    if (this.url.length < 1 || !Utilities.validateUrl(this.url)) {
      return false;
    }
    return true;
  }

  @computed
  get urlError() {
    if (this.url.length < 1) {
      return I18n.t('js.url_is_required');
    }
    if (!Utilities.validateUrl(this.url)) {
      return I18n.t('js.url_is_invalid');
    }
    return null;
  }

  @computed
  get hookTypeValid() {
    if (HookTypes.hookTypes.includes(this.hookType)) {
      return true;
    }
    return false;
  }

  @computed
  get hookIsValid() {
    return this.urlValid && this.hookTypeValid;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
