import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import ImportInstructions from './ImportInstructions';
import ImportList from './ImportList';

@inject(
  'CurrentUserStore',
  'AccountUsersStore',
  'ImportStore',
  'ToastStore'
)
@observer
export default class Imports extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.setAndLoadImports(this.props);
  }

  setAndLoadImports(props) {
    props.ImportStore.resetStore();
    props.ImportStore.setImportDetails(
      props.importableType,
      props.importableId
    );
  }

  render() {
    const { importableType } = this.props;
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <ImportInstructions
          importableType={importableType}
          heading={this.props.heading}
        />
        <ImportList />
      </Flexbox>
    );
  }
}

Imports.propTypes = {
  importableType: PropTypes.string.isRequired,
  importableId: PropTypes.string,
};
