import styled from 'styled-components';
import { Button } from '@blueprintjs/core';

export default styled(Button)`
  color: ${props => props.theme.lightgray1};
  span {
    font-size: ${props => props.theme.fontSizeMedium}px;
    font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Inter',
      'Helvetica Neue', 'Icons16', sans-serif;
    font-weight: 600;
  }
`;
