import React from 'react';
import { inject, observer } from 'mobx-react';
import { Alert, Classes, EditableText, Intent, Popover, AnchorButton, Switch } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ColorSelector from '../shared/ColorSelector';

@inject('TemplateStore', 'TemplateActions')
@observer
export default class TemplateManageStatusFlagRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDeleting: false, isLoading: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting,
    });
  }

  handleChange(value) {
    this.props.statusFlag.name = value;
  }

  handleIsArchivedChange() {
    const statusFlagObj = {
      id: this.props.statusFlag.id,
      is_archived: !this.props.statusFlag.isArchived,
    };
    this.props.TemplateActions.changeStatusFlag(statusFlagObj);
  }

  handleChangeColor(code) {
    this.props.statusFlag.color = code;
    const statusFlagObj = {
      id: this.props.statusFlag.id,
      color: this.props.statusFlag.color,
    };
    this.props.TemplateActions.changeStatusFlag(statusFlagObj);
  }

  deleteStatusFlag() {
    this.props.TemplateActions.deleteStatusFlag(this.props.statusFlag.id).catch(this.toggleDeleting);
  }

  handleConfirm() {
    const statusFlagObj = {
      id: this.props.statusFlag.id,
      name: this.props.statusFlag.name,
      color: this.props.statusFlag.color,
      is_archived: this.props.statusFlag.isArchived,
    };
    this.props.TemplateActions.changeStatusFlag(statusFlagObj);
  }

  renderRemoveAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_status_flag')}
        onConfirm={this.deleteStatusFlag.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_status_flag', { name: this.props.statusFlag.name })}
      </Alert>
    );
  }

  renderColorChooser() {
    return (
      <Flexbox flexDirection="column" width="930px" padding="20px">
        <h5 className={Classes.HEADING}>{I18n.t('js.select_color')}</h5>
        <ColorSelector activeColor={this.props.statusFlag.color} handleChangeColor={this.handleChangeColor.bind(this)} />
      </Flexbox>
    );
  }

  render() {
    return (
      <tr key={this.props.statusFlag.id}>
        <td className="td-width-30">
          <EditableText
            confirmOnEnterKey={true}
            defaultValue={this.props.statusFlag.name}
            intent={Intent.DEFAULT}
            onChange={value => this.handleChange(value)}
            value={this.props.statusFlag.name}
            onConfirm={this.handleConfirm.bind(this)}
            placeholder={I18n.t('js.click_to_edit')}
          />
        </td>
        <td className="td-width-30">
          <Popover portalContainer={document.body} content={this.renderColorChooser()} position="top" autoFocus={false}>
            <Flexbox>
              <span
                className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-5-t"
                style={{
                  color: this.props.statusFlag.color,
                }}
              />
              <span
                className="bp3-text-muted bp3-cursor-pointer"
                style={{
                  marginLeft: '5px',
                }}
              >
                {this.props.statusFlag.color.toUpperCase()}
              </span>
            </Flexbox>
          </Popover>
        </td>
        <td className="td-width-20 pad-10">
          <Switch
            className="bp3-control-no-margin"
            checked={this.props.statusFlag.isArchived}
            onChange={() => this.handleIsArchivedChange()}
          />
        </td>
        <td>
          <AnchorButton
            className="pt-small"
            icon="trash"
            text={I18n.t('js.delete')}
            onClick={this.toggleDeleting.bind(this)}
          ></AnchorButton>
          {this.renderRemoveAlert()}
        </td>
      </tr>
    );
  }
}
