// @flow
import * as React from 'react';
import Flexbox from 'flexbox-react';
import { Classes } from '@blueprintjs/core';

type CTA = {
  description: string,
  link: string,
};

type CTAFooterProps = {
  prompt: string,
  CTAs: Array<CTA>,
};

const CTAFooter = ({ prompt, CTAs }: CTAFooterProps) => (
  <React.Fragment>
    <Flexbox
      className="cta-footer"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Flexbox marginBottom="10px">
        {/* $FlowFixMe */}
        <h2 className="text-white">{prompt}</h2>
      </Flexbox>
      <Flexbox
        className={Classes.DARK}
        justifyContent="space-between"
      >
        {CTAs.map((cta, i) => (
          <a
            key={i}
            href={cta.link}
            style={{ margin: '10px' }}
            className={`
              ${Classes.BUTTON}
              ${Classes.LARGE} 
              bp3-intent-primary
            `}
          >
            {cta.description}
          </a>
        ))}
      </Flexbox>
    </Flexbox>
    <div className="cta-footer__buffer" />
  </React.Fragment>
);

CTAFooter.defaultProps = {
  // $FlowFixMe
  prompt: I18n.t('js.want_to_see_peoplegoal_in_action'),
  CTAs: [
    {
      // $FlowFixMe
      description: I18n.t('js.request_a_demo'),
      link: '/request-a-demo',
    },
  ],
};

export default CTAFooter;
