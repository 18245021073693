/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class StateElement {
  @observable
  id;
  @observable
  stateId;
  @observable
  elementId;
  @observable
  visible;
  @observable
  editable;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(id, stateId, elementId, visible, editable, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy) {
    this.id = id || '';
    this.stateId = stateId || '';
    this.elementId = elementId || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof visible === 'undefined' ? (this.visible = true) : (this.visible = visible);
    // eslint-disable-next-line no-unused-expressions
    typeof editable === 'undefined' ? (this.editable = true) : (this.editable = editable);
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
