import React from 'react';
import Flexbox from 'flexbox-react';
import { observer } from 'mobx-react';
import { FormGroup } from '@blueprintjs/core';
import _ from 'lodash';
import FormElementMultilineInput from './FormElementMultilineInput';

@observer
export default class FormElementMultiline extends React.Component {
  render() {
    if (this.props.element.id == '6cd71851-e07d-42ca-a021-b141039a4a78') {
      console.log(this.props.element.formValues);
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <FormGroup label={this.props.element.elementMultiline.name} labelFor={this.props.element.id} labelInfo={''} />
        {_.map(this.props.element.elementMultiline.elementMultilineInputs, input => (
          <FormElementMultilineInput
            key={input.id}
            input={input}
            element={this.props.element}
            errors={this.props.errors}
            showError={this.props.showError}
          />
        ))}
      </Flexbox>
    );
  }
}
