/* Legacy code - ignore this errors */ /* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import ToastStore from '../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Item from '../../models/ItemModel';
import { Button, Classes, MenuItem, PopoverInteractionKind, Spinner } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import nanoid from 'nanoid';
import _ from 'lodash';

const ItemSelect = Select.ofType(Item);

@inject('StateRuleStore', 'StateRuleActions', 'TemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StateConditionItemValueItemSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      selectedItem: '',
      searchItems: [],
      isLoading: false,
      requestId: nanoid(),
    };
  }

  componentDidMount() {
    if (this.props.stateCondition.stateConditionValue.referenceItem) {
      this.setState({
        selectedItem: this.props.stateCondition.stateConditionValue.referenceItem,
      });
    }
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addItem(data) {
    const newItem = new Item(
      data.id,
      data.app_id,
      data.state_id,
      data.user_id,
      data.status_flag_id,
      data.name,
      data.item_type,
      data.item_icon,
      data.item_color,
      data.item_layout,
      data.image_file_name,
      data.comment_count,
      data.is_done_for_current_user,
      data.is_webform,
      data.import_id,
      data.webform_user_email,
      data.webform_user_full_name,
      data.webform_user_image_file_name,
      data.dataview,
      data.simple_dataview,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.setState({
      searchItems: _.unionBy([newItem], this.state.searchItems, 'id'),
    });
    this.sortSearchItems();
  }

  sortSearchItems() {
    let newItems = this.state.searchItems;
    newItems = _.orderBy(newItems, ['fullName'], ['asc']);
    this.setState({ searchItems: newItems });
  }

  searchItems(query) {
    const requestId = nanoid();
    this.setState({
      searchItems: [],
      query,
      requestId,
    });
    if (query.length > 1) {
      this.toggleLoading();
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/items?query=${query}`)
          .then(response => {
            let total = response.data.length;
            _.map(response.data, item => {
              if (this.state.requestId === requestId) {
                this.addItem(item);
              }
              total--;
            });
            if (total === 0) {
              resolve(response);
              this.toggleLoading();
            }
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
            this.toggleLoading();
          });
      });
    }
    return undefined;
  }

  renderInputValue(item) {
    return item.name;
  }

  renderItemLabel(item) {
    const iconClass = classNames('push-5-r fa', item.itemIcon);
    return (
      <span>
        <span className={iconClass} />
        {_.capitalize(item.itemType)}
      </span>
    );
  }

  renderItem(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }
    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={_.truncate(item.name, { length: 50 })}
        label={this.renderItemLabel(item, details)}
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length > 1 && !this.state.isLoading) {
      return <MenuItem className="popover-larger" disabled text={I18n.t('js.no_items_found')} />;
    }
    return undefined;
  }

  handleItemSelect(item) {
    this.setState({ query: '', selectedItem: item });
    this.props.handleItemSelect(item);
    this.handleCancel();
  }

  handleCancel() {
    this.setState({ searchItems: [], query: '' });
  }

  renderCancel() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    if (this.state.query.length > 0) {
      return <button type="button" className="bp3-button bp3-icon-cross bp3-minimal" onClick={this.handleCancel.bind(this)} />;
    }
    return undefined;
  }

  renderCurrentState() {
    if (this.props.ItemStore.currentState.id === this.props.state.id) {
      return <Flexbox flexGrow={0} flexDirection="row" className="bp3-tag-notifier animated flash" />;
    }
    return undefined;
  }

  renderButton() {
    if (this.state.selectedItem === '') {
      return <Button>{I18n.t('js.select_item')}</Button>;
    }
    const iconClass = classNames('push-5-r fa bp3-text-muted', this.state.selectedItem.itemIcon);
    return (
      <Button>
        <span>
          <span className={iconClass} />
          {_.capitalize(this.state.selectedItem.name)}
        </span>
      </Button>
    );
  }

  render() {
    return (
      <ItemSelect
        resetOnSelect={false}
        items={this.state.searchItems}
        itemRenderer={this.renderItem.bind(this)}
        onItemSelect={this.handleItemSelect.bind(this)}
        inputValueRenderer={this.renderInputValue.bind(this)}
        inputProps={{
          placeholder: I18n.t('js.search_items'),
          className: 'popover-larger bp3-no-focus',
          rightElement: this.renderCancel(),
        }}
        query={this.state.query}
        onQueryChange={this.searchItems.bind(this)}
        popoverProps={{
          usePortal: false,
          position: 'bottom',
          className: '',
          interactionKind: PopoverInteractionKind.CLICK,
          onClose: this.handleCancel.bind(this),
          popoverClassName: 'bp3-no-padding',
        }}
        noResults={this.renderResultSearch()}
      >
        {this.renderButton()}
      </ItemSelect>
    );
  }
}
