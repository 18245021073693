import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Intent, NonIdealState } from '@blueprintjs/core';
import ElementIdSelector from '../../shared/ElementIdSelector';
import Dropzone from 'react-dropzone';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementFileDownload extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleDrop(attachments) {
    this.store.updateActiveElement(attachments[0], 'attachmentFileName');
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.props.element.id,
    };
    const finalFileDownload = this.store.activeElement.elementFileDownload.attachmentFileName;
    const formData = new FormData();
    formData.append('alignment', this.store.activeElement.elementFileDownload.alignment);
    if (typeof this.store.activeElement.elementFileDownload.attachmentFileName === 'object') {
      formData.append('attachment', finalFileDownload, finalFileDownload.name);
    }
    this.props.TemplateActions.changeElementFileDownload(this.store.activeElement.elementFileDownload.id, formData)
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderSelectFileDownloadText() {
    if (typeof this.store.activeElement.elementFileDownload.attachmentFileName !== 'object') {
      return <span>{I18n.t('js.select_new_attachment')}</span>;
    }
    return <span>{this.store.activeElement.elementFileDownload.attachmentFileName.name}</span>;
  }

  renderPreview() {
    if (typeof this.store.activeElement.elementFileDownload.attachmentFileName === 'object') {
      return (
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="10px" justifyContent="flex-start">
          <AnchorButton
            icon="cloud-download"
            text={I18n.t('js.download_file', {
              name: this.store.activeElement.elementFileDownload.attachmentFileName.name,
            })}
            onClick={() => {
              window.location = this.store.activeElement.elementFileDownload.attachmentFilePath;
            }}
          />
        </Flexbox>
      );
    }
    if (this.store.activeElement.elementFileDownload.attachmentFileName.length > 0) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="10px" justifyContent="flex-start">
          <AnchorButton
            icon="cloud-download"
            text={I18n.t('js.download_file', {
              name: this.store.activeElement.elementFileDownload.attachmentFileName,
            })}
            onClick={() => {
              window.location = this.store.activeElement.elementFileDownload.attachmentFilePath;
            }}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="10px">
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="10px" justifyContent="flex-start">
          <NonIdealState
            title={I18n.t('js.file_download')}
            description={I18n.t('js.configure_element_to_upload_an_attachment')}
            icon="cloud-download"
            className="bp3-text-muted"
          />
        </Flexbox>
      </Flexbox>
    );
  }

  renderStaticPreview() {
    if (this.props.element.elementFileDownload.attachmentFileName.length > 0) {
      return (
        <AnchorButton
          icon="cloud-download"
          text={I18n.t('js.download_file', {
            name: this.props.element.elementFileDownload.attachmentFileName,
          })}
          onClick={() => {
            window.location = this.props.element.elementFileDownload.attachmentFilePath;
          }}
        />
      );
    }
    return (
      <NonIdealState
        title={I18n.t('js.file_download')}
        description={I18n.t('js.configure_element_to_upload_an_attachment')}
        icon="cloud-download"
        className="bp3-text-muted"
      />
    );
  }

  render() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
            {this.renderPreview()}
            <hr />
            <form onSubmit={this.handleSubmit.bind(this)}>
              <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
                <Flexbox>
                  <span className="bp3-icon bp3-icon-cloud-download push-10-r" />
                  <h5 className={Classes.HEADING}>{I18n.t('js.file_download_options')}</h5>
                </Flexbox>
                <Flexbox flexDirection="column" flexGrow={1}>
                  <div className="bp3-form-group">
                    <label className="bp3-label">
                      {I18n.t('js.select_new_attachment')}
                      <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                    </label>
                    <div className="bp3-form-content">
                      <Dropzone onDrop={this.handleDrop.bind(this)} className="bp3-dropzone bp3-button bp3-icon-paperclip">
                        {this.renderSelectFileDownloadText()}
                      </Dropzone>
                    </div>
                  </div>
                </Flexbox>
                <Flexbox marginTop="10px">
                  <AnchorButton
                    intent={Intent.PRIMARY}
                    onClick={this.handleSubmit.bind(this)}
                    text={I18n.t('js.save_changes')}
                    icon="floppy-disk"
                    loading={this.state.isLoading}
                  />
                  <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
                </Flexbox>
                <ElementIdSelector id={this.props.element.id} />
              </Flexbox>
            </form>
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="10px">
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="10px" justifyContent="flex-start">
          {this.renderStaticPreview()}
        </Flexbox>
      </Flexbox>
    );
  }
}
