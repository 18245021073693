import React from 'react';
import { observer } from 'mobx-react';
import { AnchorButton, Intent } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';

@observer
export default class InviteView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  handleSetNewPassword() {
    this.setState({ isLoading: true });
    window.location = `/invite-change-password/${this.props.invitation_id}`;
  }

  render() {
    return (
      <Flexbox
        flexGrow={1}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        paddingBottom="0px"
      >
        <h1 className="bp3-heading">
          {I18n.t('js.welcome_to_peoplegoal')}
        </h1>
        <p className="bp3-running-text push-10-t">
          {I18n.t('js.set_a_new_password')}
        </p>
        <Flexbox className="push-10-t">
          <AnchorButton
            text={I18n.t('js.setup_a_password')}
            large={true}
            intent={Intent.SUCCESS}
            icon="lock"
            onClick={() => this.handleSetNewPassword()}
            loading={this.state.isLoading}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
