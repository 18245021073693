import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';
import RichTextEditor from '../../shared/RichTextEditor';
import ElementIdSelector from '../../shared/ElementIdSelector';
import RichTextDisplay from '../../shared/RichTextDisplay';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementRichTextareaDisplay extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleChange(content) {
    this.store.updateActiveElement(content, 'content');
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.props.element.id,
    };
    const elementRichTextareaDisplayObj = {
      id: this.store.activeElement.elementRichTextareaDisplay.id,
      content: this.store.activeElement.elementRichTextareaDisplay.content,
    };
    this.props.TemplateActions.changeSubElement(elementRichTextareaDisplayObj, 'element_rich_textarea_displays')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  render() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap" paddingTop="10px" paddingBottom="10px">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <RichTextEditor
                content={this.store.activeElement.elementRichTextareaDisplay.content}
                handleChange={content => this.handleChange(content)}
                placeholderDisabled={true}
                placeholder={I18n.t('js.your_content_goes_here')}
                inputValid={true}
              />
              <Flexbox marginTop="20px">
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.handleSubmit.bind(this)}
                  text={I18n.t('js.save_changes')}
                  icon="floppy-disk"
                  loading={this.state.isLoading}
                />
                <Button text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
              </Flexbox>
              <ElementIdSelector id={this.props.element.id} />
            </form>
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <RichTextDisplay content={this.props.element.elementRichTextareaDisplay.content} />
      </Flexbox>
    );
  }
}
