/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { NonIdealState, Spinner, Button } from '@blueprintjs/core';
import OrganizationStore from '../stores/OrganizationStore';
import AppItemStore from '../stores/AppItemStore';
import TeamsStore from '../stores/TeamsStore';
import ImportStore from '../stores/ImportStore';
import ImportActions from '../actions/ImportActions';
import AccountUsersStore from '../stores/AccountUsersStore';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import Imports from '../components/imports/Imports';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import Utilities from '../utils/Utilities';

@observer
export default class OrganizationImportDataView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    OrganizationStore.fetchRelationships().then(() => {
      this.toggleLoading();
    });
  }

  renderContent() {
    if (CurrentUserStore.isLoading || this.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px" marginTop="20px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (OrganizationStore.relationships.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" paddingTop="50px" className="bp3-card" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_relationship_types')}
            icon="send-to-graph"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (OrganizationStore.activeTab === 'individual') {
      return (
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="row" marginBottom="10px" flexGrow={0}>
            <Button text={I18n.t('js.back_to_organization')} icon="arrow-left" onClick={() => Utilities.navigate('/organization')} />
          </Flexbox>
          <Imports importableType="UserRelationship" importableId={null} />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      OrganizationStore,
      AppItemStore,
      TeamsStore,
      ImportStore,
      ImportActions,
      AccountUsersStore,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexDirection="column"></Flexbox>
              <Flexbox flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
