/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';
import ToastStore from '../stores/ToastStore';
import axiosCancel from 'axios-cancel';
import CurrentUserStore from '../stores/CurrentUserStore';

axiosCancel(axios, {
  debug: false,
});

class AccountBillingActions {
  @action
  fetchPlans() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/plans`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchSubscriptions() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/subscriptions`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchInvoices(subscriptionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/subscriptions/${subscriptionId}/invoices`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchUpcomingInvoice(subscriptionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/subscriptions/${subscriptionId}/invoices/upcoming`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createSubscription(subscriptionObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Cookies.get('apiEnv')}/subscriptions`,
          subscriptionObj
        )
        .then(response => {
          const resolvedItem = response;
          CurrentUserStore.fetchCurrentUser(
            CurrentUserStore.currentUser.id
          ).then(() => {
            resolve(resolvedItem);
          });
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeSubscription(subscriptionObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/subscriptions/${
            subscriptionObj.id
          }`,
          subscriptionObj
        )
        .then(response => {
          const resolvedItem = response;
          CurrentUserStore.fetchCurrentUser(
            CurrentUserStore.currentUser.id
          ).then(() => {
            resolve(resolvedItem);
          });
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteSubscription(subscriptionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Cookies.get('apiEnv')}/subscriptions/${subscriptionId}`
        )
        .then(response => {
          const resolvedItem = response;
          CurrentUserStore.fetchCurrentUser(
            CurrentUserStore.currentUser.id
          ).then(() => {
            resolve(resolvedItem);
          });
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}

const store = new AccountBillingActions();
export default store;
