import React from 'react';
import Flexbox from 'flexbox-react';
import ItemEditName from './ItemEditName';
import ItemEditImage from './ItemEditImage';
import ItemEditStatus from './ItemEditStatus';
import ItemEditState from './ItemEditState';
import { inject, observer } from 'mobx-react';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions'
)
@observer
export default class ItemEdit extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
        <ItemEditName />
        <ItemEditImage />
        <ItemEditStatus />
        <ItemEditState />
      </Flexbox>
    );
  }
}
