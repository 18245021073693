/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import {
  Alert,
  AnchorButton,
  Classes,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Spinner,
  Tab,
  Tabs,
  Tooltip,
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('CurrentUserStore', 'TeamsStore', 'TeamUsersStore', 'ToastStore')
@observer
export default class TeamTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isJoining: false, isLeaving: false };
  }

  toggleJoining() {
    this.setState({
      isJoining: !this.state.isJoining,
    });
  }

  toggleLeaving() {
    this.setState({
      isLeaving: !this.state.isLeaving,
    });
  }

  handleTabChange(tab) {
    if (tab !== 'actions') {
      this.props.TeamsStore.activeTab = tab;
    }
  }

  renderFeedTitle() {
    return <span>{I18n.t('js.feed')}</span>;
  }

  renderUsersTitle() {
    return (
      <span>
        {I18n.t('js.users')} ({this.props.TeamsStore.activeTeam.userCount})
      </span>
    );
  }

  renderRequestsTitle() {
    return <span>{I18n.t('js.requests')}</span>;
  }

  renderEditTitle() {
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t('js.only_team_owners_have_access_to_edit_this_team')}
        position="right"
        disabled={this.props.TeamsStore.activeTeam.isCurrentUserTeamOwner}
      >
        <span>{I18n.t('js.edit_team')}</span>
      </Tooltip>
    );
  }

  togglePrimary() {
    const userTeamObj = {
      id: this.props.TeamsStore.currentUserTeam.id,
      is_primary: !this.props.TeamsStore.currentUserTeam.isPrimary,
    };
    this.props.TeamUsersStore.changeUserTeam(userTeamObj).then(response => {
      this.props.TeamsStore.addCurrentUserTeam(response.data);
      this.props.TeamUsersStore.addUserTeam(response.data);
      this.props.ToastStore.showToast(I18n.t('js.user_team_has_been_updated'), 'success');
    });
  }

  joinTeam() {
    this.props.TeamUsersStore.createUserTeam(this.props.CurrentUserStore.currentUser.id, this.props.TeamsStore.activeTeam.id, false).then(
      () => {
        this.props.TeamsStore.activeTeam.isCurrentUserTeamMember = true;
        this.props.TeamsStore.activeTeam.userCount++;
        this.props.ToastStore.showToast(I18n.t('js.you_have_joined_the_team'), 'success');
        this.toggleJoining();
      }
    );
  }

  leaveTeam() {
    this.props.TeamUsersStore.deleteUserTeamCurrentUser(this.props.TeamsStore.activeTeam.id).then(() => {
      this.props.TeamsStore.activeTeam.isCurrentUserTeamMember = false;
      this.props.TeamsStore.activeTeam.userCount--;
      this.props.ToastStore.showToast(I18n.t('js.you_have_left_the_team'), 'success');
      this.toggleLeaving();
      location.reload();
    });
  }

  renderJoinTeam() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isJoining}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleJoining()}
        confirmButtonText={I18n.t('js.join_team')}
        onConfirm={() => this.joinTeam()}
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_join_this_team', {
          name: this.props.TeamsStore.activeTeam.name,
        })}
      </Alert>
    );
  }

  renderLeaveTeam() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isLeaving}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleLeaving()}
        confirmButtonText={I18n.t('js.leave_team')}
        onConfirm={() => this.leaveTeam()}
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_leave_this_team', {
          name: this.props.TeamsStore.activeTeam.name,
        })}
      </Alert>
    );
  }

  renderPrimaryToggle() {
    const { currentUserTeam } = this.props.TeamsStore;
    return (
      <MenuItem
        disabled={currentUserTeam.id.length === 0}
        text={currentUserTeam.isPrimary ? I18n.t('js.make_non_primary_team') : I18n.t('js.make_primary_team')}
        icon={currentUserTeam.isPrimary ? 'cross' : 'confirm'}
        onClick={() => this.togglePrimary()}
      />
    );
  }

  renderJoinLeaveTeam() {
    if (this.props.TeamsStore.activeTeam.isCurrentUserTeamMember) {
      return <MenuItem text={I18n.t('js.leave_team')} icon="blocked-person" onClick={() => this.toggleLeaving()} />;
    }
    return <MenuItem text={I18n.t('js.join_team')} icon="new-person" onClick={() => this.toggleJoining()} />;
  }

  renderPrivateRequests() {
    if (this.props.TeamsStore.activeTeam.privateTeam) {
      return <Tab id="requests" disabled={!this.props.TeamsStore.activeTeam.isCurrentUserTeamOwner} title={this.renderRequestsTitle()} />;
    }
    return undefined;
  }

  renderActionMenuItems() {
    return (
      <Menu>
        {/* {this.renderPrimaryToggle()} */}
        {this.renderJoinLeaveTeam()}
        <MenuDivider />
        <MenuItem
          icon="edit"
          disabled={!this.props.TeamsStore.activeTeam.isCurrentUserTeamOwner}
          text={this.renderEditTitle()}
          onClick={() => this.handleTabChange('edit')}
        />
      </Menu>
    );
  }

  renderActionMenu() {
    return (
      <Tab id="actions">
        <Popover
          portalContainer={document.body}
          content={this.renderActionMenuItems()}
          interactionKind={PopoverInteractionKind.HOVER}
          position="bottom-left"
        >
          <Flexbox flexDirection="row" alignItems="center">
            <span>{I18n.t('js.actions')}</span>
            <span className="bp3-icon-standard bp3-icon-chevron-down push-5-l bp3-align-right" />
          </Flexbox>
        </Popover>
      </Tab>
    );
  }

  renderBreadCrumbs() {
    return (
      <Flexbox>
        <ul className={`${Classes.LIST} bp3-breadcrumbs`}>
          <li>
            <a className="bp3-breadcrumb" href="/teams">
              {I18n.t('js.teams')}
            </a>
          </li>
          <li>
            <span className="bp3-breadcrumb bp3-breadcrumb-current">{this.props.TeamsStore.activeTeam.name}</span>
          </li>
        </ul>
      </Flexbox>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <nav className="bp3-navbar bp3-navbar-bordered">
          <Flexbox className="fixed-width-container" flexGrow={1}>
            <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
              <Spinner size={20} />
            </Flexbox>
          </Flexbox>
        </nav>
      );
    }
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container" flexGrow={1}>
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            <Tabs
              id="teamTabs"
              animate={true}
              defaultSelectedTabId={this.props.TeamsStore.activeTab}
              onChange={this.handleTabChange.bind(this)}
              className="noselect"
            >
              <Tab id="users" title={this.renderUsersTitle()} />
              <Tab id="feed" title={this.renderFeedTitle()} />
              {this.renderPrivateRequests()}
              {this.renderActionMenu()}
              <Tabs.Expander />
            </Tabs>
          </Flexbox>
          <Flexbox flexDirection="column" justifyContent="center">
            {this.renderLeaveTeam()}
            {this.renderJoinTeam()}
          </Flexbox>
          <Flexbox flexGrow={0} flexDirection="column" alignItems="center" justifyContent="center" marginLeft="10px"></Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
