/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observer, Provider } from 'mobx-react';
import Flexbox from 'flexbox-react';
import AppStore from '../stores/AppStore';
import ItemStore from '../stores/ItemStore';
import TemplateStore from '../stores/TemplateStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import UserProfileStore from '../stores/UserProfileStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import ToastStore from '../stores/ToastStore';
import ItemActions from '../actions/ItemActions';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import TemplateActions from '../actions/TemplateActions';
import UserProfileTemplateHeader from '../components/user_profile_template/UserProfileTemplateHeader';
import UserProfileTemplate from '../components/user_profile_template/UserProfileTemplate';
import UserProfileEditTemplate from '../components/user_profile_template/UserProfileEditTemplate';
import SkeletonTitle from '../components/skeletons/SkeletonTitle';
import SkeletonRows from '../components/skeletons/SkeletonRows';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class UserProfileTemplateView extends React.Component {
  componentDidMount() {
    UserProfileTemplateStore.resetStartingAttributes();
    UserProfileStore.fetchAuthorizedAccounts(UserProfileStore.user.id)
      .then(() => {
        TemplateActions.fetchProfileTemplates()
          .then(response => {
            _.map(response.data, profileTemplate => {
              UserProfileTemplateStore.addProfileTemplate(profileTemplate);
            });
            UserProfileTemplateStore.matchActiveProfileTemplate(UserProfileStore.authorizedAccount);
            UserProfileTemplateStore.toggleLoading();
          })
          .catch(() => {
            UserProfileTemplateStore.toggleLoading();
          });
      })
      .catch(() => {
        UserProfileTemplateStore.toggleLoading();
      });
  }

  renderProfileTemplate() {
    if (UserProfileTemplateStore.isEditingTemplate) {
      return <UserProfileEditTemplate />;
    }
    return <UserProfileTemplate />;
  }

  render() {
    const stores = {
      CurrentUserStore,
      AppStore,
      ItemStore,
      TemplateStore,
      ItemActions,
      UserProfileStore,
      UserProfileTemplateStore,
      StoreTemplateStore,
      StoreTemplateActions,
      TemplateActions,
      ToastStore,
    };
    if (CurrentUserStore.isLoading || UserProfileTemplateStore.isLoading || UserProfileStore.isLoading) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container">
          <SkeletonTitle />
          <Flexbox paddingTop="50px" paddingBottom="50px" flexGrow={1}>
            <SkeletonRows numRows={4} />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox flexGrow={1} flexDirection="column" className="profile-container">
            <UserProfileTemplateHeader />
            {this.renderProfileTemplate()}
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
