/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

export default class ImportError {
  @observable id;
  @observable importId;
  @observable message;
  @observable errorSeverity;
  @observable templateRowNumber;
  @observable templateColumnNumber;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    importId,
    message,
    errorSeverity,
    templateRowNumber,
    templateColumnNumber,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.importId = importId || '';
    this.message = message || '';
    this.errorSeverity = errorSeverity || '';
    this.templateRowNumber = templateRowNumber || '';
    this.templateColumnNumber = templateColumnNumber || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
