/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Popover, Position, PopoverInteractionKind, Tag, AnchorButton, Alert, Intent, Classes } from '@blueprintjs/core';
import CurrentUserStore from '../../stores/CurrentUserStore';
import DashboardStore from '../../stores/DashboardStore';
import DynamicViewStore from '../../stores/DynamicViewStore';
import AppStore from '../../stores/AppStore';
import AppItemStore from '../../stores/AppItemStore';
import UserProfileStore from '../../stores/UserProfileStore';
import UserProfileTemplateStore from '../../stores/UserProfileTemplateStore';
import ToastStore from '../../stores/ToastStore';
import ItemStore from '../../stores/ItemStore';
import ItemActions from '../../actions/ItemActions';
import TemplateActions from '../../actions/TemplateActions';
import TeamUsersStore from '../../stores/TeamUsersStore';
import NotificationStore from '../../stores/NotificationStore';
import WorkspaceStore from '../../stores/WorkspaceStore';
import ThemeWrapper from '../styles/ThemeWrapper/index.js';
import ErrorBoundary from '../errors/ErrorBoundary';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import AccountPopover from './AccountPopover';
import WorkspaceMenuList from './WorkspaceMenuList';
import AccountSwitchingMenu from '../accounts/AccountSwitchingMenu';
import QuickCreate from './QuickCreate';
import _ from 'lodash';

@observer
export default class Sidebar extends React.Component {
  @observable isLoading = true;
  @observable showAlert = false;

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      (function() {
        var pp = document.createElement('script'),
            ppr = document.getElementsByTagName('script')[0];
        stid = 'ZW4xN2tSeGhqSzJWMGNiR3VvR0tIUT09';
        pp.type = 'text/javascript';
        pp.async = true;
        pp.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 
                 's01.live2support.com/dashboardv2/chatwindow/';
        ppr.parentNode.insertBefore(pp, ppr);
      })();
    `;
    document.body.appendChild(script);
  }


  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  toggleAlert = () => {
    this.showAlert = !this.showAlert;
  };

  handleLeaveAccount() {
    CurrentUserStore.leaveCurrentAccount().then(response => {
      this.showAlert = false;
      Utilities.navigate('/setup');
    });
  }

  renderAccountIcon() {
    const accountImage = CurrentUserStore.activeAccount.accountImage;

    return <Avatar src={accountImage} className="bp3-cursor-pointer bp3-sidebar-logo" round={true} size={50} />;
  }

  render() {
    const stores = {
      CurrentUserStore,
      DashboardStore,
      DynamicViewStore,
      UserProfileStore,
      UserProfileTemplateStore,
      AppStore,
      AppItemStore,
      ItemStore,
      ItemActions,
      ToastStore,
      TemplateActions,
      TeamUsersStore,
      NotificationStore,
      WorkspaceStore,
    };

    const hasAccount = CurrentUserStore ? CurrentUserStore.currentUser.accountId.length > 0 : false;
    const { activeAccount,authorizedAccounts, userSignedIn, userIsAccountOwner, isLoading } = CurrentUserStore;
    const { action, activeObjectId } = this.props;

    if (isLoading) {
      return (
        <ErrorBoundary>
          <Provider {...stores}>
            <ThemeWrapper>
              <Flexbox flexGrow={1} flexDirection="column" justifyContent="space-between" height="100vh">
                <Flexbox flexDirection="column" paddingTop="40px" alignItems="center" flexGrow={1}></Flexbox>
                <Flexbox flexDirection="column" paddingBottom="20px" justifyContent="center" alignItems="center" flexGrow={0}></Flexbox>
              </Flexbox>
            </ThemeWrapper>
          </Provider>
        </ErrorBoundary>
      );
    }
    return (
      <ErrorBoundary>
        <Provider {...stores}>
          <ThemeWrapper>
            <Flexbox flexGrow={1} flexDirection="column" height="100vh">
              <Alert
                portalContainer={document.body}
                isOpen={this.showAlert}
                cancelButtonText={I18n.t('js.cancel')}
                onCancel={this.toggleAlert.bind(this)}
                confirmButtonText={I18n.t('js.leave_active_account', { accountName: activeAccount.accountName })}
                onConfirm={this.handleLeaveAccount.bind(this)}
                intent={Intent.DANGER}
              >
                <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
                {I18n.t('js.are_you_sure_you_wish_to_leave_this_account', {
                  accountName: activeAccount.accountName,
                })}
              </Alert>
              <Flexbox flexDirection="column" alignItems="flex-start" flexGrow={0} className="sidebar-list" marginTop="20px">
                <React.Fragment>
                  <Flexbox marginLeft="-5px">
                    <Popover
                      portalContainer={document.body}
                      content={
                        authorizedAccounts.length > 1 ? (
                          <AccountSwitchingMenu toggleAlert={() => this.toggleAlert()} />
                        ) : null
                      }
                     // content={<AccountSwitchingMenu toggleAlert={() => this.toggleAlert()} />}
                      interactionKind={PopoverInteractionKind.HOVER}
                      position={Position.RIGHT}
                      boundary="viewport"
                    >
                      <AnchorButton
                        className="bp3-minimal bp3-account-button"
                        text={activeAccount.accountName}
                        icon={this.renderAccountIcon()}
                        rightIcon="chevron-right"
                      />
                    </Popover>
                  </Flexbox>
                  <AnchorButton
                    className="bp3-minimal bp3-sidebar-button push-20-t"
                    active={action === 'dashboard'}
                    onClick={() => Utilities.navigate(`/dashboard`)}
                    href="/dashboard"
                    text={I18n.t('js.dashboard')}
                    icon="home"
                  />
                  <AnchorButton
                    className="bp3-minimal bp3-sidebar-button"
                    active={action === 'workspaces' && activeObjectId === null}
                    onClick={() => Utilities.navigate(`/workspaces`)}
                    href="/workspaces"
                    text={I18n.t('js.workspaces')}
                    icon="grid-view"
                  />
                  <WorkspaceMenuList action={action} activeObjectId={activeObjectId} />
                </React.Fragment>
              </Flexbox>
              <Flexbox flexDirection="column" alignItems="flex-start" flexGrow={0} className="sidebar-list">
                <React.Fragment>
                  {!hasAccount ? (
                    <div></div>
                  ) : (
                    <React.Fragment>
                      <AnchorButton
                        className="bp3-minimal bp3-sidebar-button"
                        active={action === 'teams'}
                        onClick={() => Utilities.navigate(`/teams`)}
                        href="/teams"
                        icon="layout-hierarchy"
                        text={I18n.t('js.teams')}
                      ></AnchorButton>
                      <AnchorButton
                        className="bp3-minimal bp3-sidebar-button"
                        active={action === 'organization'}
                        onClick={() => Utilities.navigate(`/organization`)}
                        href="/organization"
                        text={I18n.t('js.organization')}
                        icon="send-to-graph"
                      ></AnchorButton>
                      <AnchorButton
                        className="bp3-minimal bp3-sidebar-button"
                        active={action === 'reports'}
                        onClick={() => Utilities.navigate(`/reports`)}
                        href="/reports"
                        text={I18n.t('js.reports')}
                        icon="panel-table"
                      ></AnchorButton>
                      <AccountPopover action={action} userIsAccountOwner={userIsAccountOwner} account={CurrentUserStore.activeAccount} />
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} justifyContent="flex-end">
                <Flexbox
                  onClick={() => Utilities.navigate('https://www.peoplegoal.com')}
                  className="bp3-cursor-pointer"
                  flexDirection="row"
                  justifyContent="center"
                  paddingBottom="20px"
                >
                  {CurrentUserStore.currentUser.theme == 'dark' ? (
                    <img alt="logo" src={I18n.t(`js.pg_logo_white`)} className="copyright-img-logo" />
                  ) : (
                    <img alt="logo" src={I18n.t(`js.pg_logo_black`)} className="copyright-img-logo" />
                  )}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ThemeWrapper>
        </Provider>
      </ErrorBoundary>
    );
  }
}
