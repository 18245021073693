import { action, computed, extendObservable, observable } from 'mobx';
import CurrentUserStore from './CurrentUserStore';
import ItemActions from '../actions/ItemActions';
import ToastStore from './ToastStore';
import AppStore from './AppStore';
import Item from '../models/ItemModel';
import User from '../models/UserModel';
import Team from '../models/TeamModel';
import StatusFlag from '../models/StatusFlagModel';
import Participant from '../models/ParticipantModel';
import ItemParticipant from '../models/ItemParticipantModel';
import ItemValue from '../models/ItemValueModel';
import State from '../models/StateModel';
import Section from '../models/SectionModel';
import ElementMappingActions from '../actions/ElementMappingActions';
import NetworkGraph from '../models/NetworkGraphModel';
import Utilities from '../utils/Utilities';
import ActionCable from 'actioncable';
import Cookies from 'js-cookie';
import _ from 'lodash';

class ItemStore {
  @observable activeTab = 'details';
  @observable activeEditSection = null;
  @observable isShowingParticipants = true;
  @observable userCanEdit = null;
  @observable item = new Item();
  @observable newItem = new Item();
  @observable activeItem = new Item();
  @observable itemParticipantGraph = new NetworkGraph();
  @observable sections = [];
  @observable exposedSections = [];
  @observable readOnlySections = [];
  @observable states = [];
  @observable participants = [];
  @observable statusFlags = [];
  @observable itemParticipants = [];
  @observable itemPins = [];
  @observable formValuesToCreate = [];
  @observable formValuesToChange = [];
  @observable formValuesToDelete = [];
  @observable itemParticipantsToChange = [];
  @observable submissionAttempted = false;
  @observable itemErrors = [];
  @observable countDown = 10;
  @observable isSaving = false;
  @observable isCountingDown = false;
  @observable webformFullName = '';
  @observable webformEmail = '';
  @observable isWebformView = false;
  @observable collapsedSections = [];
  @observable itemSectionInvites = [];
  @observable invitableStateSections = [];
  @observable commentableStateSections = [];
  @observable activeSectionInvitationId = null;

  @computed
  get hasChanges() {
    return this.formValuesToCreate.length > 0 || this.formValuesToChange.length > 0 || this.formValuesToDelete.length > 0;
  }

  @computed
  get itemHasError() {
    return this.itemErrors.length > 0;
  }

  @computed
  get userIsOwner() {
    if (this.userCanEdit != null) {
      return this.userCanEdit;
    }
    if (CurrentUserStore.currentUser.id === this.item.userId || this.item.isWebform || this.isWebformView) {
      return true;
    }
    if (AppStore.activeApp.userCanEdit) {
      return true;
    }
    if (CurrentUserStore.currentUser.id === this.item.createdBy && this.currentStateIsFirst) {
      return true;
    }
    return false;
  }

  @computed
  get currentState() {
    return _.find(this.states, state => state.id === this.item.stateId);
  }

  @computed
  get currentFullState() {
    return _.find(this.fullStates, o => o.isCurrent);
  }

  @computed
  get nextState() {
    if (this.currentState.id === _.last(this.states).id) {
      return this.currentState;
    }
    return this.states[this.states.indexOf(this.currentState) + 1];
  }

  @computed
  get currentStateIsFirst() {
    return this.currentState.id === _.head(this.states).id;
  }

  @computed
  get currentStateIsLast() {
    return this.currentState.id === this.nextState.id;
  }

  @computed
  get nextFullState() {
    return _.find(this.fullStates, o => o.id === this.nextState.id);
  }

  @computed
  get nextStateRequiresItemParticipant() {
    return !this.nextFullState.itemParticipant.userId.length > 0;
  }

  @computed
  get activeStateNumber() {
    return this.states.indexOf(this.currentState) + 1;
  }

  @computed
  get totalStateNumber() {
    return this.states.length;
  }

  @computed
  get currentStateProgress() {
    return this.activeStateNumber / this.totalStateNumber;
  }

  @computed
  get itemHasCalculation() {
    const sectionsWithCalculations = _.filter(this.sections, {
      elements: [{ elementType: 'calculation' }],
    });
    return sectionsWithCalculations.length > 0;
  }

  @computed
  get hasUnsetParticipants() {
    let result = false;
    _.map(this.fullStates, state => {
      if (state.itemParticipant) {
        if (state.itemParticipant.userId === '') {
          result = true;
        }
      }
    });
    return result;
  }

  @computed
  get fullStates() {
    let fullStates = [];
    _.map(this.states, state => {
      const newState = _.clone(state);
      if (state.id === this.currentState.id) {
        newState.isCurrent = true;
      } else {
        newState.isCurrent = false;
      }
      const findParticipant = _.find(this.participants, o => o.id === state.participantId);
      if (findParticipant) {
        newState.participant = findParticipant;
        const findItemParticipant = _.find(this.itemParticipants, o => o.participantId === findParticipant.id);
        if (findItemParticipant) {
          newState.itemParticipant = findItemParticipant;
        } else {
          newState.itemParticipant = new ItemParticipant();
        }
      }
      fullStates = _.unionBy([newState], fullStates, 'id');
    });
    fullStates = _.orderBy(fullStates, ['rowOrder'], ['asc']);
    return fullStates;
  }

  @computed
  get visibleSections() {
    let visibleSections = [];
    _.map(this.sections, section => {
      let sectionHasData = false;
      section.elements.map(element => {
        if (element.formValues.length > 0 || element.isDisplayElement) {
          sectionHasData = true;
        }
      });
      if (sectionHasData) {
        visibleSections.push(section);
      } else {
        if (this.isSectionInvitableInState(section.id)) {
          visibleSections.push(section);
        } else {
          if (!_.find(this.readOnlySections, o => o.section_id == section.id)) {
            visibleSections.push(section);
          }
        }
      }
    });
    return visibleSections;
  }

  @computed
  get activeSectionInvitation() {
    if (this.activeSectionInvitationId) {
      return _.find(this.sections, o => o.id == this.activeSectionInvitationId);
    }
    return null;
  }

  isSectionInvitableInState(sectionId) {
    const findInvitableStateSection = _.find(
      this.invitableStateSections,
      o => o.section_id == sectionId && o.state_id == this.currentState.id
    );
    if (findInvitableStateSection) {
      return true;
    }
    return false;
  }

  isSectionCommentableInState(sectionId) {
    const findCommentableStateSection = _.find(
      this.commentableStateSections,
      o => o.section_id == sectionId && o.state_id == this.currentState.id
    );
    if (findCommentableStateSection) {
      return true;
    }
    return false;
  }

  @action setActiveSectionInvitationId(id) {
    this.activeSectionInvitationId = id;
  }

  @action
  exposeSection(id) {
    this.exposedSections = _.unionBy(this.exposedSections, [id]);
  }

  @action
  removeExposedSection(id) {
    this.exposedSections = _.filter(this.exposedSections, o => o != id);
    // Remove item_values for sections that are removed from exposure
    _.map(this.sections, section => {
      if (section.id == id) {
        _.map(section.elements, element => {
          _.map(element.formValues, formValue => {
            if (formValue.createdAt) {
              this.addFormValueToDelete({ id: formValue.id });
            }
          });
          element.formValues = [];
          this.formValuesToCreate = _.filter(this.formValuesToCreate, o => o.element_id != element.id);
          this.itemErrors = _.filter(this.itemErrors, o => o.elementId != element.id);
        });
      }
    });
  }

  @action
  updateItem(data, attribute) {
    this.newItem[attribute] = data;
  }

  @action
  resetItemAttribute(attribute) {
    this.newItem[attribute] = this.item[attribute];
  }

  @action
  toggleSaving() {
    this.isSaving = !this.isSaving;
  }

  @action
  setNextItemParticipant(userId) {
    const nextItemParticipantId = this.nextFullState.itemParticipant.id;
    const nextItemParticipant = _.clone(_.find(this.itemParticipants, o => o.id === nextItemParticipantId));
    nextItemParticipant.userId = userId;
    this.itemParticipantsToChange = _.unionBy([nextItemParticipant], this.itemParticipantsToChange, 'id');
  }

  @action
  updateActiveItem(data, attribute) {
    this.activeItem[attribute] = data;
  }

  @action
  addItemSectionInvite(data) {
    this.itemSectionInvites = _.unionBy([data], this.itemSectionInvites, 'id');
  }

  @action
  removeItemSectionInvite(id) {
    this.itemSectionInvites = _.filter(this.itemSectionInvites, o => o.id != id);
  }

  @action
  addItemPin(data) {
    this.itemPins = _.unionBy([data], this.itemPins, 'id');
  }

  @action
  removeItemPin(id) {
    this.itemPins = _.filter(this.itemPins, o => o.id != id);
  }

  @action
  addItem(data) {
    const newItem = new Item(
      data.id,
      data.app_id,
      data.state_id,
      data.user_id,
      data.status_flag_id,
      data.name,
      data.item_type,
      data.item_icon,
      data.item_color,
      data.item_layout,
      data.image_file_name,
      data.comment_count,
      data.is_done_for_current_user,
      data.is_webform,
      data.import_id,
      data.webform_user_email,
      data.webform_user_full_name,
      data.webform_user_image_file_name,
      data.dataview,
      data.simple_dataview,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    const itemUser = new User(
      data.user.id,
      data.user.email,
      data.user.first_name,
      data.user.last_name,
      data.user.full_name,
      data.user.account_id,
      data.user.human_friendly_id,
      data.user.is_current_user,
      data.user.is_account_owner,
      data.user.image_file_name,
      data.user.background_image_file_name,
      data.user.last_sign_in_at,
      data.user.locale,
      data.user.theme,
      data.user.created_at,
      data.user.updated_at,
      data.user.deleted_at,
      data.user.created_by,
      data.user.updated_by,
      data.user.deleted_by
    );
    newItem.user = itemUser;

    const statusFlag = new StatusFlag(
      data.status_flag.id,
      data.status_flag.app_id,
      data.status_flag.name,
      data.status_flag.color,
      data.is_archived,
      data.status_flag.created_at,
      data.status_flag.created_by,
      data.status_flag.deleted_at,
      data.status_flag.deleted_by,
      data.status_flag.updated_at,
      data.status_flag.updated_by
    );
    newItem.statusFlag = statusFlag;

    this.item = newItem;
    this.newItem = _.clone(newItem);
    this.activeItem = _.cloneDeep(newItem);

    if (data.app) {
      _.map(data.app.states, state => {
        this.addState(state);
      });
      _.map(data.app.participants, participant => {
        this.addParticipant(participant);
      });
    }
    _.map(data.item_participants, itemParticipant => {
      this.addItemParticipant(itemParticipant);
    });
    _.map(data.sections, section => {
      this.addSection(section);
    });
    _.map(data.elements, element => {
      this.addElement(element);
    });
    _.map(data.item_values, itemValue => {
      this.addItemValue(itemValue);
    });
    _.map(data.item_section_invites, itemSectionInvite => {
      this.addItemSectionInvite(itemSectionInvite);
    });
    _.map(data.item_pins, itemPin => {
      this.addItemPin(itemPin);
    });
    if (this.itemHasCalculation) {
      this.setUpActionCable();
    }
  }

  @action
  toggleSectionCollapse(sectionId) {
    if (_.find(this.collapsedSections, o => o === sectionId)) {
      this.collapsedSections = _.filter(this.collapsedSections, o => o != sectionId);
    } else {
      this.collapsedSections.push(sectionId);
    }
  }

  @action
  showAllSections() {
    this.collapsedSections = [];
  }

  @action
  addError(errorObj) {
    const newItemErrors = _.filter(this.itemErrors, error => error.elementId !== errorObj.elementId);
    newItemErrors.push(errorObj);
    this.itemErrors = newItemErrors;
  }

  @action
  clearErrorsForElement(elementId) {
    this.itemErrors = _.filter(this.itemErrors, o => o.elementId !== elementId);
  }

  @action
  addFormValueToChange(formValueObj) {
    this.formValuesToChange = _.unionBy([formValueObj], this.formValuesToChange, 'id');
  }

  @action
  addFormValueToCreate(formValueObj) {
    formValueObj.orderToPost = this.formValuesToCreate.length;
    if (formValueObj instanceof FormData) {
      // UnionBy doesn't work for FormData
      this.formValuesToCreate.push(formValueObj);
    } else {
      this.formValuesToCreate = _.unionBy([formValueObj], this.formValuesToCreate, 'id');
    }
  }

  @action
  addFormValueToDelete(formValueObj) {
    this.formValuesToDelete = _.unionBy([formValueObj], this.formValuesToDelete, 'id');
  }

  @action
  removeFormValueToChange(id) {
    this.formValuesToChange = _.filter(this.formValuesToChange, o => o.id !== id);
  }

  @action
  removeFormValueToCreate(id) {
    this.formValuesToCreate = _.filter(this.formValuesToCreate, o => o.id !== id);
  }

  @action
  removeFormValueToDelete(id) {
    this.formValuesToDelete = _.filter(this.formValuesToDelete, o => o.id !== id);
  }

  @action
  addParticipant(data) {
    const newParticipant = new Participant(
      data.id,
      data.app_id,
      data.is_owner,
      data.name,
      data.visibility_level,
      data.relationship_id,
      data.user_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.participants = _.unionBy([newParticipant], this.participants, 'id');
  }

  @action
  addItemParticipant(data) {
    const newItemParticipant = new ItemParticipant(
      data.id,
      data.item_id,
      data.participant_id,
      data.user_id,
      data.is_done,
      data.email,
      data.full_name,
      data.gravatar_url,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    if (data.user) {
      const user = new User(
        data.user.id,
        data.user.email,
        data.user.first_name,
        data.user.last_name,
        data.user.full_name,
        data.user.account_id,
        data.user.human_friendly_id,
        data.user.is_current_user,
        data.user.is_account_owner,
        data.user.image_file_name,
        data.user.background_image_file_name,
        data.user.last_sign_in_at,
        data.user.locale,
        data.user.theme,
        data.user.created_at,
        data.user.updated_at,
        data.user.deleted_at,
        data.user.created_by,
        data.user.updated_by,
        data.user.deleted_by
      );
      newItemParticipant.user = user;
    }
    this.itemParticipants = _.unionBy([newItemParticipant], this.itemParticipants, 'id');
    if (!this.hasUnsetParticipants) {
      this.itemErrors = _.filter(this.itemErrors, o => o.elementId !== 'item-participant-chart');
    }
  }

  @action
  addItemValue(data) {
    _.map(this.sections, section => {
      _.map(section.elements, element => {
        if (element.id === data.element_id) {
          if (section.optional) {
            this.exposeSection(section.id);
          }

          const newItemValue = new ItemValue(
            data.id,
            data.item_id,
            data.element_id,
            data.item_value_type,
            data.string_value,
            data.number_value,
            data.decimal_value,
            data.boolean_value,
            data.date_value,
            data.image_file_name,
            data.attachment_file_name,
            data.element_select_option_id,
            data.element_object_select_item_id,
            data.element_object_select_user_id,
            data.element_object_select_team_id,
            data.element_grid_column_id,
            data.element_grid_row_id,
            data.element_multiline_input_id,
            data.row_order,
            data.created_at,
            data.created_by,
            data.deleted_at,
            data.deleted_by,
            data.updated_at,
            data.updated_by
          );

          if (data.reference_user) {
            newItemValue.referenceUser = new User(
              data.reference_user.id,
              data.reference_user.email,
              data.reference_user.first_name,
              data.reference_user.last_name,
              data.reference_user.full_name,
              data.reference_user.account_id,
              data.reference_user.human_friendly_id,
              data.reference_user.is_current_user,
              data.reference_user.is_account_owner,
              data.reference_user.image_file_name,
              data.reference_user.background_image_file_name,
              data.reference_user.last_sign_in_at,
              data.reference_user.locale,
              data.reference_user.theme,
              data.reference_user.created_at,
              data.reference_user.updated_at,
              data.reference_user.deleted_at,
              data.reference_user.created_by,
              data.reference_user.updated_by,
              data.reference_user.deleted_by
            );
          }
          if (data.reference_team) {
            newItemValue.referenceTeam = new Team(
              data.reference_team.id,
              data.reference_team.account_id,
              data.reference_team.is_default_account,
              data.reference_team.name,
              data.reference_team.description,
              data.reference_team.image_file_name,
              data.reference_team.background_image_file_name,
              data.reference_team.private,
              data.reference_team.is_current_user_team_owner,
              data.reference_team.is_current_user_team_member,
              data.reference_team.row_order,
              data.reference_team.user_count,
              data.reference_team.comment_count,
              data.reference_team.import_id,
              data.reference_team.created_at,
              data.reference_team.created_by,
              data.reference_team.deleted_at,
              data.reference_team.deleted_by,
              data.reference_team.updated_at,
              data.reference_team.updated_by
            );
          }
          if (data.reference_item) {
            newItemValue.referenceItem = new Item(
              data.reference_item.id,
              data.reference_item.app_id,
              data.reference_item.state_id,
              data.reference_item.user_id,
              data.reference_item.status_flag_id,
              data.reference_item.name,
              data.reference_item.item_type,
              data.reference_item.item_icon,
              data.reference_item.item_color,
              data.reference_item.item_layout,
              data.reference_item.image_file_name,
              data.reference_item.comment_count,
              data.reference_item.is_done_for_current_user,
              data.reference_item.is_webform,
              data.reference_item.import_id,
              data.reference_item.webform_user_email,
              data.reference_item.webform_user_full_name,
              data.reference_item.webform_user_image_file_name,
              data.reference_item.dataview,
              data.reference_item.simple_dataview,
              data.reference_item.created_at,
              data.reference_item.created_by,
              data.reference_item.deleted_at,
              data.reference_item.deleted_by,
              data.reference_item.updated_at,
              data.reference_item.updated_by
            );
          }
          if (element.elementType === 'calculation') {
            element.formValues = [newItemValue];
          } else {
            let newFormValues = _.unionBy([newItemValue], element.formValues, 'id');
            if (element.repeatable) {
              newFormValues = _.orderBy(newFormValues, ['rowOrder'], ['asc']);
            } else {
              newFormValues = _.orderBy(newFormValues, ['updatedAt'], ['desc']);
            }
            element.formValues = newFormValues;
          }
        }
      });
    });
  }

  @action
  removeItemValue(formValueId) {
    const newSections = _.cloneDeep(this.sections);
    _.map(newSections, section => {
      _.map(section.elements, element => {
        element.formValues = _.filter(element.formValues, o => o.id !== formValueId);
      });
    });
    this.sections = newSections;
  }

  @action
  addStatusFlag(data) {
    const newStatusFlag = new StatusFlag(
      data.id,
      data.app_id,
      data.name,
      data.color,
      data.is_archived,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );
    this.statusFlags = _.unionBy([newStatusFlag], this.statusFlags, 'id');
  }

  @action
  addState(data) {
    const newState = new State(
      data.id,
      data.app_id,
      data.participant_id,
      data.status_flag_id,
      data.name,
      data.all_visible,
      data.all_editable,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.states = _.unionBy([newState], this.states, 'id');
    this.sortStates();
  }

  @action
  addSection(data) {
    const newSection = new Section(
      data.id,
      data.section_type,
      data.app_id,
      data.profile_template_id,
      data.title,
      data.private_view,
      data.protected_view,
      data.account_owner_only_view,
      data.default_collapsed,
      data.optional,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    if (newSection.defaultCollapsed) {
      this.collapsedSections.push(newSection.id);
    }
    extendObservable(newSection, { elements: [] });
    this.sections = _.unionBy([newSection], this.sections, 'id');
    this.addStateSections(data.state_sections);
    this.sortSections();
  }

  @action
  addStateSections(state_sections) {
    _.map(state_sections, state_section => {
      if (state_section.invitable) {
        this.invitableStateSections = _.unionBy([state_section], this.invitableStateSections, 'id');
      }
      if (state_section.commentable) {
        this.commentableStateSections = _.unionBy([state_section], this.commentableStateSections, 'id');
      }
      if (!state_section.editable && state_section.state_id == this.currentState.id) {
        this.readOnlySections = _.unionBy([state_section], this.readOnlySections, 'id');
      }
    });
  }

  @action
  addElement(data) {
    const findSection = _.find(this.sections, o => o.id === data.section_id);
    const newElements = ElementMappingActions.mapElements([data]);
    _.map(newElements, element => {
      if (!this.userIsOwner) {
        element.isEditable = false;
      }
    });
    _.map(newElements, element => {
      extendObservable(element, { formValues: [] });
    });
    if (findSection) {
      findSection.elements = _.unionBy(newElements, findSection.elements, 'id');
      findSection.elements = _.orderBy(findSection.elements, ['rowOrder'], ['asc']);
    }
  }

  @action
  sortSections() {
    this.sections = _.orderBy(this.sections, ['rowOrder'], ['asc']);
  }

  @action
  sortStates() {
    this.states = _.orderBy(this.states, ['rowOrder'], ['asc']);
  }

  @action
  resetCountDown() {
    this.countDown = 10;
  }

  @action
  processCountDown() {
    if (this.countDown > 0 && this.isCountingDown) {
      --this.countDown;
    } else {
      if (this.hasChanges) {
        ToastStore.showSavingToast(I18n.t('js.saving_your_changes'));
        ItemActions.handleSave()
          .then(() => {
            this.resetCountDown();
          })
          .catch(() => {
            this.resetCountDown();
          });
      } else {
        this.resetCountDown();
      }
    }
  }

  @action
  startCountDown() {
    if (this.isCountingDown === false) {
      this.resetCountDown();
      this.isCountingDown = true;
      const intervalDuration = 1000; //ms
      this.interval = setInterval(() => {
        this.processCountDown();
      }, intervalDuration);
    }
  }

  @action
  pauseCountDown() {
    clearInterval(this.interval);
    this.isCountingDown = false;
  }

  @action
  setItemUpdateTime(dateTime) {
    this.item.updatedAt = dateTime;
  }

  @computed
  get itemParticipantNodes() {
    let itemParticipantNodes = [];
    let darkMode = Cookies.get('theme') === 'dark';
    _.map(this.fullStates, fullState => {
      const avatarNumber =
        // lazy fix for legacy code
        // eslint-disable-next-line no-magic-numbers
        Math.floor(Math.random() * (4 - 1 + 1)) + 1;
      let image = I18n.t(`js.pg_avatar_placeholder_${avatarNumber}`);
      let label = `<i>${I18n.t('js.select_participant', {
        participant: fullState.participant.name,
      })}</i>`;
      let title;
      if (fullState.itemParticipant.userId !== '') {
        image = fullState.itemParticipant.user.imageFileName;
        label = `${fullState.name}\n(${fullState.itemParticipant.user.fullName} - ${fullState.participant.name})`;
      }
      if (this.item.isWebform) {
        if (fullState.itemParticipant.gravatarUrl) {
          image = fullState.itemParticipant.gravatarUrl;
        }
        if (fullState.itemParticipant.fullName) {
          label = `${fullState.name}\n(${fullState.itemParticipant.fullName})`;
        }
      }
      if (
        fullState.itemParticipant.participantId === this.currentState.participantId ||
        (AppStore.activeApp.lockParticipants && fullState.itemParticipant.userId && AppStore.activeApp.userPermissionLevel.name !== 'owner')
      ) {
        title = I18n.t('js.participant_is_not_editable', {
          participant_name: fullState.participant.name,
        });
      } else {
        title = I18n.t('js.set_participant_name', {
          participant: fullState.participant.name,
        });
      }
      const statusFlag = _.find(this.statusFlags, o => o.id === fullState.statusFlagId);
      const newNode = {
        id: fullState.id,
        shape: 'circularImage',
        title,
        image,
        font: {
          multi: true,
          face: 'Inter',
          color: darkMode == true ? '#ffffff' : '#1c2127',
          size: 14,
        },
        label,
        physics: true,
        borderWidth: 5,
        borderWidthSelected: 5,
        labelHighlightBold: false,
        color: {
          border: `#BFCCD6`,
          highlight: statusFlag && this.currentState.id === fullState.id ? statusFlag.color : '#30404d',
          hover: {
            border: `#30404d`,
          },
        },
      };
      itemParticipantNodes = _.unionBy([newNode], itemParticipantNodes, 'id');
    });
    return itemParticipantNodes;
  }

  @computed
  get itemParticipantLinks() {
    let itemParticipantLinks = [];
    _.map(this.fullStates, fullState => {
      if (_.last(this.fullStates).id !== fullState.id) {
        const nextState = this.fullStates[this.fullStates.indexOf(fullState) + 1];
        const newNode = {
          id: Utilities.makeId(),
          from: fullState.id,
          to: nextState.id,
          dashes: true,
          width: 3,
          hoverWidth: 0,
          color: {
            color: '#BFCCD6',
            highlight: '#BFCCD6',
            hover: '#BFCCD6',
          },
        };
        itemParticipantLinks = _.unionBy([newNode], itemParticipantLinks, 'id');
      }
    });
    return itemParticipantLinks;
  }

  @computed
  get webformEmailValid() {
    const exp = /\S+@\S+\.\S+/u;
    return exp.test(this.webformEmail);
  }

  @action
  setUpActionCable() {
    const that = this;
    const importCable = ActionCable.createConsumer(`${Cookies.get('cablePath')}`);
    importCable.subscriptions.create(
      {
        channel: 'ItemValueChannel',
        itemId: this.item.id,
      },
      {
        received(data) {
          that.addItemValue(JSON.parse(data));
          that.sections = _.cloneDeep(that.sections);
        },
      }
    );
  }

  @action
  resetStartingAttributes() {
    this.activeTab = 'details';
    this.activeEditSection = null;
    this.isShowingParticipants = true;
    this.item = new Item();
    this.userCanEdit = null;
    this.newItem = new Item();
    this.activeItem = new Item();
    this.itemParticipantGraph = new NetworkGraph(
      true,
      // lazy fix for legacy code
      // eslint-disable-next-line no-magic-numbers
      250,
      // eslint-disable-next-line no-magic-numbers
      300,
      'RL',
      'hubsize',
      true
    );
    this.sections = [];
    this.exposedSections = [];
    this.readOnlySections = [];
    this.states = [];
    this.participants = [];
    this.statusFlags = [];
    this.itemParticipants = [];
    this.itemPins = [];
    this.formValuesToCreate = [];
    this.formValuesToChange = [];
    this.formValuesToDelete = [];
    this.itemParticipantsToChange = [];
    this.submissionAttempted = false;
    this.itemErrors = [];
    this.countDown = 10;
    this.isSaving = false;
    this.isCountingDown = false;
    this.webformFullName = '';
    this.webformEmail = '';
    this.isWebformView = false;
    this.collapsedSections = [];
    this.itemSectionInvites = [];
    this.invitableStateSections = [];
    this.commentableStateSections = [];
    this.activeSectionInvitationId = null;
  }
}

const store = new ItemStore();
export default store;
