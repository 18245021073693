/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import { AnchorButton, Icon, NonIdealState, Spinner, Tag } from '@blueprintjs/core';
import CurrentUserStore from '../stores/CurrentUserStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import AppStore from '../stores/AppStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import ToastStore from '../stores/ToastStore';
import StoreTemplateTabs from '../components/store_templates/StoreTemplateTabs';
import StoreTemplate from '../components/store_templates/StoreTemplate';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import StoreTemplateEditStoreTemplate from '../components/store_templates/StoreTemplateEditStoreTemplate';

@observer
export default class StoreTemplateView extends React.Component {
  @observable
  isPublishing = false;

  togglePublishing() {
    this.isPublishing = !this.isPublishing;
  }

  componentDidMount() {
    StoreTemplateStore.resetStartingAttributes();
    if (this.props.tab) {
      StoreTemplateStore.activeTab = this.props.tab;
    }
    StoreTemplateActions.fetchStoreTemplate(this.props.storeTemplateId)
      .then(response => {
        StoreTemplateStore.addStoreTemplate(response.data);
        StoreTemplateStore.setActiveStoreTemplate(response.data.id);
        StoreTemplateStore.toggleLoading();
      })
      .catch(() => {
        StoreTemplateStore.toggleLoading();
      });
  }

  publishStoreTemplate() {
    if (StoreTemplateStore.activeStoreTemplate.imageFileName.length === 0) {
      ToastStore.showToast(I18n.t('js.please_add_an_image_to_the_template_before_publishing'), 'danger');
    } else {
      this.togglePublishing();
      let status = 'live';
      if (StoreTemplateStore.activeStoreTemplate.status === 'live') {
        status = 'draft';
      }
      const newObj = {
        id: StoreTemplateStore.activeStoreTemplate.id,
        status,
      };
      StoreTemplateActions.changeStoreTemplate(newObj)
        .then(response => {
          this.togglePublishing();
          StoreTemplateStore.addStoreTemplate(response.data);
          StoreTemplateStore.setActiveStoreTemplate(response.data.id);
          if (response.data.status === 'live') {
            ToastStore.showToast(I18n.t('js.template_has_been_set_to_live'), 'success');
          }
        })
        .catch(() => {
          this.togglePublishing();
          this.toggleLoading();
        });
    }
  }

  renderDraftStoreTemplate() {
    if (StoreTemplateStore.activeStoreTemplate.status === 'draft' && !StoreTemplateStore.isLoading) {
      return (
        <Flexbox className="sign-up-bar bp3-elevation-3">
          <Flexbox className="fixed-width-container" justifyContent="space-between" flexGrow={1}>
            <Flexbox flexGrow={1} alignContent="center" alignItems="center">
              <Icon icon="issue-new" className="text-white push-10-r" iconSize={25} />
              <Flexbox flexDirection="column" justifyContent="center" paddingTop="10px">
                <p>{I18n.t('js.draft_store_template_text')}</p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" justifyContent="center">
              <AnchorButton
                onClick={this.publishStoreTemplate.bind(this)}
                text={I18n.t('js.publish')}
                className="bp3-intent-primary bp3-rose-button"
                loading={this.isPublishing}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderTags() {
    if (StoreTemplateStore.activeStoreTemplate.status !== 'draft') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Tag className="bp3-cursor-pointer bp3-intent-success">{I18n.t('js.live')}</Tag>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderContent() {
    if (StoreTemplateStore.isLoading || CurrentUserStore.isLoading || StoreTemplateStore.activeStoreTemplate.id === '') {
      return (
        <Flexbox flexdirection="row" flexGrow={1} paddingTop="50px" paddingBottom="50px">
          <NonIdealState icon={<Spinner />} className="bp3-text-muted" />
        </Flexbox>
      );
    }
    if (StoreTemplateStore.activeTab === 'storeTemplate') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <StoreTemplate storeTemplate={StoreTemplateStore.activeStoreTemplate} storeCategoryView={false} />
        </Flexbox>
      );
    }
    if (StoreTemplateStore.activeTab === 'edit') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <StoreTemplateEditStoreTemplate />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      AppStore,
      WorkspaceStore,
      CurrentUserStore,
      StoreTemplateStore,
      StoreTemplateActions,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <StoreTemplateTabs />
              {this.renderDraftStoreTemplate()}
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <Flexbox flexGrow={1} flexDirection="column">
                  {this.renderContent()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
