/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import StoreCategoryStore from '../stores/StoreCategoryStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import StoreCategoryWidget from '../components/store_categories/StoreCategoryWidget';
import SignUpBar from '../components/layout/SignUpBar';
import ContentfulClient from '../static/ContentfulClient';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class StoreView extends React.Component {
  handleFilterChange(e) {
    e.preventDefault();
    StoreCategoryStore.filterQuery = e.target.value;
  }

  componentDidMount() {
    if (StoreCategoryStore.hasNoData) {
      StoreCategoryStore.resetStartingAttributes();
      ContentfulClient.getEntries({
        content_type: 'storeCategory',
        order: '-sys.createdAt',
      }).then(response => {
        let itemCount = response.items.length;
        _.map(response.items, storeCategory => {
          StoreCategoryStore.addStoreCategory(storeCategory);
          itemCount--;
        });
        if (itemCount === 0) {
          StoreCategoryStore.toggleLoading();
        }
      });
    }
  }

  renderSearch() {
    return (
      <Flexbox flexGrow={1} marginBottom="10px">
        <div className="bp3-input-group bp3-large max-width">
          <span className="bp3-icon bp3-icon-search" />
          <input
            className="bp3-input"
            type="search"
            placeholder={I18n.t('js.filter_store_categories')}
            dir="auto"
            value={StoreCategoryStore.filterQuery}
            onChange={this.handleFilterChange.bind(this)}
          />
        </div>
      </Flexbox>
    );
  }

  renderStoreCategories() {
    if (StoreCategoryStore.filteredStoreCategories.length === 0) {
      return (
        <Flexbox flexGrow={1} marginTop="20px" paddingTop="20px" justifyContent="center" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_store_categories_to_show')}
            icon="search-text"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
        {_.map(StoreCategoryStore.filteredStoreCategories, storeCategory => (
          <StoreCategoryWidget key={storeCategory.sys.id} storeCategory={storeCategory} />
        ))}
      </Flexbox>
    );
  }
  renderContent() {
    if (CurrentUserStore.isLoading || StoreCategoryStore.isLoading) {
      return (
        <Flexbox flexGrow={1} justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderSearch()}
        {this.renderStoreCategories()}
      </Flexbox>
    );
  }

  renderSignUp() {
    if (!CurrentUserStore.userSignedIn && !CurrentUserStore.isLoading) {
      return <SignUpBar />;
    }
    return undefined;
  }

  render() {
    const stores = {
      CurrentUserStore,
      StoreCategoryStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column">
              <LayoutHeader title={I18n.t('js.store')} subtitle={I18n.t('js.categories_description')} icon="git-repo" />
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderSignUp()}
                <Flexbox className="fixed-width-container" flexDirection="row" flexGrow={1} marginTop="20px">
                  {this.renderContent()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
