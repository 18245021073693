import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, FormGroup, Intent } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementFileInput extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  newAttachment = '';
  @observable
  displayedAttachment = '';
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      if (this.props.element.formValues[0].attachmentFileName.length > 0) {
        this.displayedAttachment = this.props.element.formValues[0].attachmentFileName;
        this.props.validateInput(this.displayedAttachment);
      } else {
        this.props.validateInput(this.newAttachment);
      }
    } else {
      this.props.validateInput(this.newAttachment);
    }
    this.mountDisplayValuesInFlight();
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue instanceof FormData) {
        if (formValue.get('element_id') === this.props.element.id) {
          this.newAttachment = formValue.get('attachment');
        }
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        this.newAttachment = findFormValueToChange.attachment;
      }
    });
  }

  setStoreAndActions() {
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.append('user_id', this.props.UserProfileStore.user.id);
        object.append('profile_template_id', this.props.UserProfileTemplateStore.activeProfileTemplate.id);
        object.append('profile_value_type', 'attachment');
        return object;
      default:
        object.append('item_id', this.store.item.id);
        object.append('item_value_type', 'attachment');
        return object;
    }
  }

  handleDrop(attachments) {
    this.newAttachment = attachments[0];
    if (this.actionType === 'updating') {
      const changedFormValue = {
        id: this.props.element.formValues[0].id,
        attachment: this.newAttachment,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      let newFormValue = new FormData();
      newFormValue.append('id', this.newId);
      newFormValue.append('element_id', this.props.element.id);
      newFormValue.append('attachment', this.newAttachment);
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
    }
    this.props.validateInput(this.newAttachment);
  }

  handleRemoveAttachment() {
    this.newAttachment = '';
    this.displayedAttachment = '';
    const changedFormValue = {
      id: this.props.element.formValues[0].id,
    };
    this.store.addFormValueToDelete(changedFormValue);
    this.props.validateInput(this.newAttachment);
  }

  renderSelectAttachmentText() {
    if (typeof this.newAttachment !== 'object') {
      return <span>{I18n.t('js.select_file')}</span>;
    }
    return <span>{this.newAttachment.name}</span>;
  }

  renderAttachmentDisplay() {
    if (this.displayedAttachment.length > 0) {
      return (
        <Flexbox flexDirection="row" marginBottom="10px">
          <a href={this.displayedAttachment}>
            <span className="bp3-icon bp3-icon-paperclip push-10-r" />
            {I18n.t('js.download_file', {
              name: Utilities.getFileName(this.displayedAttachment),
            })}
          </a>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderRemoveAttachment() {
    if (this.newAttachment.length > 0 || this.displayedAttachment.length > 0) {
      return (
        <Button className="push-10-l" icon="trash" onClick={this.handleRemoveAttachment.bind(this)}>
          {I18n.t('js.remove_attachment')}
        </Button>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          label={this.props.element.elementFileInput.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          {this.renderAttachmentDisplay()}
          <Flexbox>
            <Dropzone onDrop={this.handleDrop.bind(this)} className="bp3-button bp3-icon-cloud-upload">
              {this.renderSelectAttachmentText()}
            </Dropzone>
            {this.renderRemoveAttachment()}
          </Flexbox>
        </FormGroup>
      </Flexbox>
    );
  }
}
