import { action, computed, observable } from 'mobx';
import StoreTemplateMappings from '../actions/StoreTemplateMappings';
import _ from 'lodash';

class StoreCategoryStore {
  @observable
  isLoading = true;
  @observable
  filterQuery = '';
  @observable
  activeStoreCategory = '';
  @observable
  activeStoreTemplate = '';
  @observable
  storeCategories = [];
  @observable
  tabSelection = 'App'; // or Workspace (capital and singular to match the model)

  @computed
  get filteredStoreTemplates() {
    let storeTemplates = [];
    if (this.activeStoreCategory) {
      if (this.filterQuery === '') {
        storeTemplates = this.activeStoreCategory.fields.storeTemplates;
      } else {
        return _.filter(
          this.activeStoreCategory.fields.storeTemplates,
          o => o.title.toLowerCase().indexOf(this.filterQuery.toLowerCase()) !== -1
        );
      }
    } else {
      if (this.filterQuery === '') {
        storeTemplates = this.allStoreTemplates;
      } else {
        return _.filter(this.allStoreTemplates, o => o.title.toLowerCase().indexOf(this.filterQuery.toLowerCase()) !== -1);
      }
    }
    return storeTemplates;
  }

  @computed
  get allStoreTemplates() {
    let storeTemplates = [];
    _.map(this.storeCategories, category => {
      _.map(category.fields.storeTemplates, template => {
        storeTemplates.push(template);
      });
    });
    return storeTemplates;
  }

  // switches depending on the tab which is selected
  @computed
  get viewedStoreTemplates() {
    return this.filteredStoreTemplates.filter(storeTemplate => storeTemplate.templatableObjectType === this.tabSelection);
  }

  @computed
  get hasNoData() {
    return this.storeCategories.length === 0;
  }

  @computed
  get filteredStoreCategories() {
    if (this.filterQuery === '') {
      return this.storeCategories;
    }
    return _.filter(this.storeCategories, o => o.fields.title.toLowerCase().indexOf(this.filterQuery.toLowerCase()) !== -1);
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  setActiveStoreCategory(storeCategory) {
    this.activeStoreCategory = storeCategory;
  }

  @action
  setActiveStoreTemplate(storeTemplate) {
    this.activeStoreTemplate = storeTemplate;
  }

  @action
  addStoreCategory(storeCategory) {
    const storeTemplates = [];
    _.map(storeCategory.fields.storeTemplates, storeTemplate => {
      if (storeTemplate.fields) {
        const newStoreTemplate = StoreTemplateMappings.mapStoreTemplate(storeTemplate.fields, 'contentful');
        storeTemplates.push(newStoreTemplate);
      }
    });
    storeCategory.fields.storeTemplates = storeTemplates;
    this.storeCategories.push(storeCategory);
    this.sortStoreCategories();
  }

  @action
  sortStoreCategories() {
    let newStoreCategories = this.storeCategories;
    newStoreCategories = _.orderBy(newStoreCategories, ['fields.title'], ['asc']);
    this.storeCategories = newStoreCategories;
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.filterQuery = '';
    this.activeStoreCategory = '';
    this.activeStoreTemplate = '';
    this.storeCategories = [];
  }
}

const store = new StoreCategoryStore();
export default store;
