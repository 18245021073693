import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, NonIdealState } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import DocumentationPath from './DocumentationPath';
import StringToMarkdown from '../shared/StringToMarkdown';
import _ from 'lodash';

@inject('DocumentationStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class DocumentationWindow extends React.Component {
  renderDocumentationTable() {
    if (
      _.values(
        this.props.DocumentationStore.selectedDocumentation.properties
      ).length > 0
    ) {
      const properties = [];
      _.map(
        this.props.DocumentationStore.selectedDocumentation
          .properties,
        (value, key) => {
          properties.push({
            key,
            value,
          });
        }
      );
      return (
        <Flexbox
          className="bp3-card bp3-card-no-padding"
          marginTop="10px"
        >
          <table className="bp3-html-table bp3-html-table-striped full-table">
            <thead>
              <tr>
                <th>{I18n.t('js.attribute')}</th>
                <th>{I18n.t('js.type')}</th>
                <th>{I18n.t('js.description')}</th>
              </tr>
            </thead>
            <tbody>
              {_.map(properties, prop => (
                <tr key={prop.key}>
                  <td>
                    <code className="bp3-code">{prop.key}</code>
                  </td>
                  <td>
                    <code className="bp3-code">
                      {prop.value.type}
                    </code>
                  </td>
                  <td>
                    <small className="bp3-text-muted">
                      {prop.value.description}
                    </small>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderDocumentation() {
    if (
      this.props.DocumentationStore.selectedDocumentationType ===
      'model'
    ) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <h3 className={Classes.HEADING}>
            {Utilities.humanize(
              this.props.DocumentationStore.selectedDocumentation.name
            )}
          </h3>
          {/* <p className='bp3-running-text'>{this.props.DocumentationStore.selectedDocumentation.description}</p> */}
          {this.renderDocumentationTable()}
        </Flexbox>
      );
    }
    if (
      this.props.DocumentationStore.selectedDocumentationType ===
      'path'
    ) {
      return <DocumentationPath />;
    }
    return undefined;
  }

  render() {
    if (
      this.props.DocumentationStore.selectedDocumentationType === ''
    ) {
      return (
        <Flexbox
          flexDirection="column"
          flexGrow={0}
          marginLeft="20px"
          paddingTop="50px"
        >
          <NonIdealState
            title={I18n.t('js.select_a_guide_or_api')}
            description={I18n.t('js.select_a_guide_or_api_to_begin')}
            icon="git-repo"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (
      this.props.DocumentationStore.selectedDocumentationType ===
      'guide'
    ) {
      return (
        <Flexbox
          flexDirection="column"
          flexGrow={1}
          marginLeft="20px"
        >
          <StringToMarkdown
            string={
              this.props.DocumentationStore.selectedDocumentation
                .fields.body
            }
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginLeft="20px">
        {this.renderDocumentation()}
      </Flexbox>
    );
  }
}
