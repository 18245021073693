/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Classes, InputGroup, Intent, Tag } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('ToastStore', 'SetupStore', 'CurrentUserStore')
@observer
export default class SetupJoinAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleInputChange(e) {
    this.props.SetupStore.updateNewRequest(e.target.value, e.target.name);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.SetupStore.newRequest[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.SetupStore.newRequest[`${field}Error`]}</div>;
    }
    return undefined;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.SetupStore.newRequest.accountSubdomainValid) {
      this.toggleLoading();
      this.props.SetupStore.createAuthorizedAccountRequest()
        .then(() => {
          this.toggleLoading();
          this.setState({ isBlurred: [] });
          this.props.SetupStore.setActiveSetup('none');
        })
        .catch(() => {
          this.toggleLoading();
        });
    } else {
      this.setState({ isBlurred: ['accountSubdomain'] });
    }
  }

  renderTag() {
    return (
      <Flexbox>
        <Tag className="bp3-intent-success bp3-large">.peoplegoal.com</Tag>
      </Flexbox>
    );
  }

  renderJoinAccount() {
    if (this.props.SetupStore.activeSetup === 'newRequest') {
      return (
        <Flexbox flexDirection="column" flexGrow={1} maxWidth="930px" className="animated fadeIn">
          <form onSubmit={this.handleSubmit.bind(this)}>
            <h1 className={`push-20-t ${Classes.HEADING}`}>{I18n.t('js.join_an_account')}</h1>
            <p className="bp3-running-text push-20-t">{I18n.t('js.join_an_account_details')}</p>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'accountSubdomain') && !this.props.SetupStore.newRequest.accountSubdomainValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="accountSubdomain">
                  {I18n.t('js.subdomain')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'accountSubdomain') && !this.props.SetupStore.newRequest.accountSubdomainValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <InputGroup
                      autoComplete="off"
                      className="bp3-fill bp3-large"
                      type="text"
                      placeholder={I18n.t('js.example')}
                      name="accountSubdomain"
                      value={this.props.SetupStore.newRequest.accountSubdomain}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      leftIcon="globe"
                      rightElement={this.renderTag()}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('accountSubdomain')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row">
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                className="bp3-large bp3-icon-envelope bp3-align-right"
                text={I18n.t('js.send_request')}
                disabled={!this.props.SetupStore.newRequest.accountSubdomainValid}
                loading={this.state.isSubmitting}
              />
              <Button
                type="button"
                className="bp3-large bp3-minimal push-10-l"
                text={I18n.t('js.back')}
                onClick={() => this.props.SetupStore.setActiveSetup('none')}
              />
            </Flexbox>
          </form>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column" alignItems="stretch" alignContent="stretch" alignSelf="stretch">
        {this.renderJoinAccount()}
      </Flexbox>
    );
  }
}
