/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import _ from 'lodash';
import Cookies from 'js-cookie';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ItemStore from '../stores/ItemStore';
import ToastStore from '../stores/ToastStore';
import Utilities from '../utils/Utilities';

axiosCancel(axios, {
  debug: false,
});

class ItemActions {
  @computed
  get itemPath() {
    let itemPath = 'items';
    if ((ItemStore.item.id.length > 0 && ItemStore.item.isWebform) || ItemStore.isWebformView) {
      itemPath = 'webform_items';
    }
    return itemPath;
  }

  @computed
  get itemValuePath() {
    let itemPath = 'item_values';
    if ((ItemStore.item.id.length > 0 && ItemStore.item.isWebform) || ItemStore.isWebformView) {
      itemPath = 'webform_item_values';
    }
    return itemPath;
  }

  @action
  fetchAppItems(appId, xPage, xPerPage, xOffset, status) {
    return new Promise((resolve, reject) => {
      let url = `${Cookies.get('apiEnv')}/items?&app_id=${appId}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`;
      if (status) {
        url = `${url}&status=${status}`;
      }
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  searchAppItems(appId, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/items?&app_id=${appId}&query=${query}`, {
          requestId: 'itemSearch',
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            reject(error);
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
          }
        });
    });
  }

  @action
  fetchAppDataItems(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/items/dataview?&app_id=${appId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchUserItems(showDone, xPage, xPerPage, xOffset) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/items?page=${xPage}&per_page=${xPerPage}&offset=${xOffset}&show_done=${showDone}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchPinnedItemsForUser(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/item_pins?user_id=${userId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchItemSectionInvites() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/item_section_invites`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchSections(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/sections?app_id=${appId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchItem(itemId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/items/${itemId}?&type=full`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWebformItem(itemId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/webform_items/${itemId}?&type=full`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWebformItemWithSectionInvite(itemId, itemSectionInviteId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/webform_items/${itemId}?item_section_invite_id=${itemSectionInviteId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchStatusFlags(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/status_flags?app_id=${appId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  fetchItemValues(itemId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/${this.itemValuePath}?item_id=${itemId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  fetchItemParticipants(itemId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/item_participants?item_id=${itemId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createItem(itemObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/items`, itemObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createItemSectionInvite(itemSectionInviteObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/item_section_invites`, itemSectionInviteObj)
        .then(response => {
          resolve(response);
          ToastStore.showToast(I18n.t('js.invitation_sent'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  resendItemSectionInviteEmail(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/item_section_invites/${id}/resubmit`)
        .then(response => {
          resolve(response);
          ToastStore.showToast(I18n.t('js.invitation_sent'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  duplicateItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/items/${id}/copy`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  duplicateItemForUser(id, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/items/${id}/copy?user_id=${user_id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  pinItemForUser(id, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/item_pins`, {
          item_id: id,
          user_id: user_id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  pinItemForTeam(id, team_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/item_pins`, {
          item_id: id,
          team_id: team_id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeItem(itemObj) {
    let id = itemObj.id;
    if (itemObj instanceof FormData) {
      id = itemObj.get('id');
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/items/${id}`, itemObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  markItemAsComplete(itemId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/items/${itemId}/mark_as_done`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeItemParticipant(itemParticipantObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/item_participants/${itemParticipantObj.id}`, itemParticipantObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  submitWebformItem(itemObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/webform_items/${itemObj.id}/submit`, itemObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  submitItemSectionInvite(id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/item_section_invite_submissions/${id}/submit`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  submitItem(itemObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/items/${itemObj.id}/submit`, itemObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteItem(itemId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/items/${itemId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteItemSectionInvite(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/item_section_invites/${id}`)
        .then(response => {
          resolve(response);
          ToastStore.showToast(I18n.t('js.invitation_deleted'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteItemPin(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/item_pins/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  async createItemValue(formValueObj) {
    if (!formValueObj.row_order_position) {
      formValueObj.row_order_position = 0;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/${this.itemValuePath}`, formValueObj)
        .then(response => {
          resolve(response);
          ItemStore.setItemUpdateTime(response.data.updated_at);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  async changeItemValue(formValueObj) {
    if (typeof formValueObj.attachment === 'object' || typeof formValueObj.image === 'object') {
      const formData = new FormData();
      if (typeof formValueObj.attachment === 'object') {
        formData.append('attachment', formValueObj.attachment);
      }
      if (typeof formValueObj.image === 'object') {
        formData.append('image', formValueObj.image);
      }
      return new Promise((resolve, reject) => {
        axios
          .put(`${Cookies.get('apiEnv')}/${this.itemValuePath}/${formValueObj.id}`, formData)
          .then(response => {
            resolve(response);
            ItemStore.setItemUpdateTime(response.data.updated_at);
          })
          .catch(error => {
            reject(error);
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
          });
      });
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/${this.itemValuePath}/${formValueObj.id}`, formValueObj)
        .then(response => {
          resolve(response);
          ItemStore.setItemUpdateTime(response.data.updated_at);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteItemValue(formValueId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/${this.itemValuePath}/${formValueId}`)
        .then(response => {
          resolve(response);
          ItemStore.setItemUpdateTime(response.data.updated_at);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  handleSave(notify) {
    return new Promise((resolve, reject) => {
      ItemStore.toggleSaving();
      let operationCount = ItemStore.formValuesToChange.length + ItemStore.formValuesToCreate.length + ItemStore.formValuesToDelete.length;

      this.submitSave(operationCount, notify);
      if (operationCount === 0) {
        resolve();
      }
      _.map(ItemStore.formValuesToChange.slice(), formValueObj => {
        this.changeItemValue(formValueObj)
          .then(response => {
            --operationCount;
            if (operationCount === 0) {
              resolve();
            }
            this.submitSave(operationCount, notify);
            ItemStore.addItemValue(response.data);
            ItemStore.removeFormValueToChange(formValueObj.id);
          })
          .catch(error => {
            reject(error);
            ItemStore.toggleSaving();
          });
      });

      const orderedValuesToCreate = _.orderBy(ItemStore.formValuesToCreate, ['orderToPost'], ['ASC']);
      const createLoop = async () => {
        // This convoluted loop is to ensure that item_values are created in order for repeatable elements
        for (var formValueObj of orderedValuesToCreate) {
          if (!formValueObj.row_order_position) {
            formValueObj.row_order_position = 0;
          }
          await axios
            .post(`${Cookies.get('apiEnv')}/${this.itemValuePath}`, formValueObj)
            .then(response => {
              ItemStore.setItemUpdateTime(response.data.updated_at);
              --operationCount;
              if (operationCount === 0) {
                resolve();
              }
              this.submitSave(operationCount, notify);
              ItemStore.addItemValue(response.data);
              ItemStore.removeFormValueToCreate(formValueObj.id);
            })
            .catch(error => {
              const errors = error.response.data.error.join(', ');
              ToastStore.showToast(errors, 'danger');
            });
        }
      };
      createLoop();

      _.map(ItemStore.formValuesToDelete.slice(), formValueObj => {
        this.deleteItemValue(formValueObj.id)
          .then(() => {
            --operationCount;
            if (operationCount === 0) {
              resolve();
            }
            this.submitSave(operationCount, notify);
            ItemStore.removeItemValue(formValueObj.id);
            ItemStore.removeFormValueToDelete(formValueObj.id);
          })
          .catch(error => {
            reject(error);
            ItemStore.toggleSaving();
          });
      });
    });
  }

  @action
  refreshCalculations() {
    if (Cookies.get('user_id')) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${Cookies.get('apiEnv')}/items/${ItemStore.item.id}/refresh-calculation`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
            const errors = error.response.data.error.join(', ');
          });
      });
    } else {
      return true;
    }
  }

  @action
  submitSave(operationCount, notify) {
    if (operationCount === 0) {
      this.refreshCalculations();
      ItemStore.resetCountDown();
      ItemStore.toggleSaving();
      if (notify) {
        ToastStore.showToast(I18n.t('js.your_changes_have_been_saved'), 'success');
      }
    }
  }
}

const actions = new ItemActions();
export default actions;
