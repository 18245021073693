import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { Button, Intent, Switch, Alert, Classes, NonIdealState, Spinner, Divider, Checkbox, Label, Collapse } from '@blueprintjs/core';

import AzureIntegrationForm from './AzureIntegrationForm';
import AzureIntegrationFormLogic from './AzureIntegrationFormLogic';
import PauseIntegrationAlert from '../shared_components/PauseIntegrationAlert';
import DeleteIntegrationAlert from '../shared_components/DeleteIntegrationAlert';
import IntegrationActionButtons from '../shared_components/IntegrationActionButtons';
import SyncTable from '../shared_components/SyncTable';
import SyncDrawer from '../shared_components/SyncDrawer';

@inject('AzureIntegrationStore', 'ToastStore', 'AccountSettingsStore', 'IntegrationStore')
@observer
export default class AzureIntegration extends React.Component {
  @observable
  isOpen = true;
  @observable
  isPauseAlertOpen = false;
  @observable
  isDeleteAlertOpen = false;

  constructor(props) {
    super(props);
    this.toggleBoolean = this.toggleBoolean.bind(this);
    this.updateActiveStatus = this.updateActiveStatus.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.handleSaveChanges = this.handleSaveChanges.bind(this);
    this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
    this.startSync = this.startSync.bind(this);
  }

  componentWillMount() {
    const AzureIntegrationStore = this.props.AzureIntegrationStore;
    if (!this.props.AzureIntegrationStore.hasAzureIntegration) {
      AzureIntegrationStore.fetchAzureIntegration()
        .then(() => {
          AzureIntegrationStore.toggleLoading();
        })
        .catch(() => {
          AzureIntegrationStore.toggleLoading();
        });
    }
    AzureIntegrationStore.fetchSyncs();
  }

  startSync(azureStore) {
    azureStore.toggleBoolean('isSyncTriggered');
    azureStore.runSynchronization();
  }

  handleSaveChanges() {
    const AzureStore = this.props.AzureIntegrationStore;
    const azure_integration = AzureStore.azureIntegration;
    const azureID = azure_integration.id;
    const azureIntegrationObj = {
      client_id: azure_integration.clientId,
      client_secret: azure_integration.clientSecret,
      client_uri: azure_integration.clientUri,
      client_tenant: azure_integration.clientTenant,
      include_profile_photos: azure_integration.includeProfilePhotos,
      include_departments: azure_integration.includeDepartments,
      include_managers: azure_integration.includeManagers,
      active: azure_integration.active,
    };
    AzureStore.updateAzureIntegration(azureID, azureIntegrationObj)
      .then(response => {})
      .catch(error => {
        reject(error);
        const errors = error.response.data.error.join(', ');
        ToastStore.showToast(errors, 'danger');
      });
  }

  cancelChanges() {
    this.props.AzureIntegrationStore.cancelChanges();
  }

  deleteIntegration() {
    const azureID = this.props.AzureIntegrationStore.azureIntegration.id;
    this.props.AzureIntegrationStore.removeAzureIntegration();
    this.props.AzureIntegrationStore.deleteAzureIntegration(azureID);
  }

  toggleDeleteDialog() {
    this.isDeleteAlertOpen = !this.isDeleteAlertOpen;
  }

  toggleBoolean(name) {
    this[`${name}`] = !this[`${name}`];
  }

  updateActiveStatus() {
    this.props.AzureIntegrationStore.toggleActive();
    this.toggleBoolean('isPauseAlertOpen');
    this.handleSaveChanges();
    if (this.initiateSync) {
      this.props.AzureIntegrationStore.runSynchronization();
    }
  }

  renderForm() {
    const azureStore = this.props.AzureIntegrationStore;
    const azureIntegration = azureStore.azureIntegration;
    const active = azureIntegration.active;
    const canSave = azureStore.hasNewValidChanges;
    const hasNewChanges = azureStore.hasNewChanges;

    return (
      <div>
        <Flexbox flexDirection="column" className="push-10-b">
          <Flexbox
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            maxHeight="auto"
            width="100%"
            flexGrow="0"
            alignSelf="flex-start"
          >
            <h2 className="push-0 pointer" onClick={() => this.toggleBoolean('isOpen')}>
              {I18n.t('js.configure_azure_integration')}
            </h2>
            <Button
              text={this.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              className="push-0 push-10-l"
              icon={this.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleBoolean('isOpen')}
            />
          </Flexbox>
        </Flexbox>

        <Collapse isOpen={this.isOpen}>
          <Flexbox justifyContent="flex-start" className="push-10-t">
            <Switch
              checked={active}
              large={true}
              label={active ? I18n.t('js.active') : I18n.t('js.paused')}
              onChange={() => this.toggleBoolean('isPauseAlertOpen')}
            />
            <PauseIntegrationAlert
              integration={azureIntegration}
              isPauseAlertOpen={this.isPauseAlertOpen}
              toggleBoolean={() => this.toggleBoolean()}
              updateActiveStatus={() => this.updateActiveStatus()}
            />
          </Flexbox>
          <AzureIntegrationFormLogic>
            <AzureIntegrationForm />
          </AzureIntegrationFormLogic>

          <IntegrationActionButtons
            canSave={canSave}
            handleSaveChanges={() => this.handleSaveChanges()}
            hasNewChanges={hasNewChanges}
            cancelChanges={() => this.cancelChanges()}
            toggleBoolean={() => this.toggleBoolean()}
            toggleDeleteDialog={() => this.toggleDeleteDialog()}
          />
          <DeleteIntegrationAlert
            isDeleteAlertOpen={this.isDeleteAlertOpen}
            toggleBoolean={() => this.toggleDeleteDialog()}
            deleteIntegration={() => this.deleteIntegration()}
          />
          <Divider className="push-30-t" />
        </Collapse>
      </div>
    );
  }

  render() {
    const azureStore = this.props.AzureIntegrationStore;

    if (azureStore.syncsLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px" flexGrow={1} className="bp3-card">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column" className="max-width">
          {this.renderForm()}
          <SyncTable
            hasSyncs={azureStore.hasSyncs}
            syncs={azureStore.syncs}
            isSyncRunning={azureStore.isSyncRunning}
            runSync={() => this.startSync(azureStore)}
            store={azureStore}
            isSyncTriggered={azureStore.isSyncTriggered}
            isIntegrationActive={azureStore.azureIntegration.active}
          />
          <SyncDrawer store={azureStore} isDrawerOpen={azureStore.isDrawerOpen} onClose={() => azureStore.toggleDrawer()} />
        </Flexbox>
      );
    }
  }
}
