/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Collapse, Dialog, Intent, Classes } from '@blueprintjs/core';
import ItemManageParticipantsSelectUser from './ItemManageParticipantsSelectUser';
import Graph from 'react-vis-network-graph';
import classNames from 'classnames';
import _ from 'lodash';

@inject('ItemStore', 'ItemActions', 'ToastStore', 'AppStore')
@observer
export default class ItemsManageParticipants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDialogOpen: false,
      activeFullState: '',
    };
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  toggleDialog() {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  }

  setNetworkInstance(nw) {
    this.network = nw;
  }

  componentDidUpdate() {
    this.setInitialSelection();
  }

  componentDidMount() {
    this.setInitialSelection();
  }

  setInitialSelection() {
    if (this.network && this.props.ItemStore.currentFullState) {
      const id = this.props.ItemStore.currentFullState.id;
      this.network.setSelection({
        nodes: [id],
      });
    }
  }

  setCursorPointer(event) {
    const networkCanvas = document.getElementById('item-participant-chart').getElementsByTagName('canvas')[0];
    const findFullState = _.find(this.props.ItemStore.fullStates, o => o.id === event.node);
    if (
      findFullState.itemParticipant.participantId === this.props.ItemStore.currentState.participantId ||
      (this.props.AppStore.activeApp.lockParticipants &&
        findFullState.itemParticipant.userId &&
        this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner')
    ) {
      networkCanvas.style.cursor = 'not-allowed';
    } else {
      networkCanvas.style.cursor = 'pointer';
    }
  }

  setCursorAuto() {
    const networkCanvas = document.getElementById('item-participant-chart').getElementsByTagName('canvas')[0];
    networkCanvas.style.cursor = 'pointer';
  }

  renderChart() {
    const graph = {
      nodes: this.props.ItemStore.itemParticipantNodes.slice(),
      edges: this.props.ItemStore.itemParticipantLinks.slice(),
    };

    const options = {
      layout: {
        randomSeed: 1,
        hierarchical: {
          enabled: this.props.ItemStore.itemParticipantGraph.hierarchical,
          levelSeparation: this.props.ItemStore.itemParticipantGraph.levelSeparation,
          nodeSpacing: this.props.ItemStore.itemParticipantGraph.nodeSpacing,
          direction: this.props.ItemStore.itemParticipantGraph.direction,
          sortMethod: this.props.ItemStore.itemParticipantGraph.sortMethod,
        },
      },
      interaction: {
        dragNodes: false,
        dragView: true,
        zoomView: false,
        hover: true,
        hoverConnectedEdges: false,
        tooltipDelay: 100,
        selectable: true,
        selectConnectedEdges: false,
      },
      physics: {
        barnesHut: {
          avoidOverlap: 1,
        },
      },
    };

    const events = {
      select: event => {
        this.handleStateSelect(event);
      },
      hoverNode: event => {
        this.setCursorPointer(event);
      },
      blurNode: event => {
        this.setCursorAuto(event);
      },
    };

    return (
      <Flexbox flexDirection="column" className="bp3-cursor-pointer" flexGrow={1} id="item-participant-chart">
        <Graph
          graph={graph}
          getNetwork={this.setNetworkInstance.bind(this)}
          options={options}
          events={events}
          style={{
            width: '100%',
            height: '150px',
          }}
        />
      </Flexbox>
    );
  }

  renderChartArea() {
    return (
      <Flexbox flexDirection="column" className="bp3-cursor-pointer chart-area" flexGrow={1}>
        {this.renderChart()}
      </Flexbox>
    );
  }

  handleSelectParticipantUser(user) {
    const newFullState = this.state.activeFullState;
    newFullState.itemParticipant.user = user;
    this.setState({ activeFullState: newFullState });
  }

  handleSubmit() {
    this.toggleLoading();
    const itemParticipantObj = {
      id: this.state.activeFullState.itemParticipant.id,
      user_id: this.state.activeFullState.itemParticipant.user.id,
    };
    this.props.ItemActions.changeItemParticipant(itemParticipantObj)
      .then(response => {
        this.toggleLoading();
        this.props.ItemStore.addItemParticipant(response.data);
        this.props.ToastStore.showToast(
          I18n.t('js.participant_has_been_updated', {
            participant_name: this.state.activeFullState.participant.name,
          }),
          'success'
        );
        this.handleCancel();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleCancel() {
    this.setState({ activeFullState: '' });
    this.toggleDialog();
  }

  handleStateSelect(event) {
    if (event.nodes[0] !== this.props.ItemStore.currentFullState.id) {
      const findFullState = _.find(this.props.ItemStore.fullStates, o => o.id === event.nodes[0]);
      if (findFullState) {
        const timeoutDuration = 100; // ms
        setTimeout(() => {
          const stateId = findFullState.id;
          this.network.setSelection({
            nodes: [stateId],
          });
          const positions = this.network.getPositions([stateId]);
          const options = {
            position: {
              x: positions[stateId].x,
              y: positions[stateId].y,
            },
            scale: 1,
            animation: true,
          };
          this.network.moveTo(options);
        }, timeoutDuration);
        if (findFullState.itemParticipant.participantId !== this.props.ItemStore.currentFullState.itemParticipant.participantId) {
          if (
            this.props.AppStore.activeApp.lockParticipants &&
            findFullState.itemParticipant.userId &&
            this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner'
          ) {
            return;
          } else {
            this.setState({ activeFullState: findFullState });
            this.toggleDialog();
          }
        }
      }
    }
  }

  renderDialog() {
    if (this.state.activeFullState !== '' && this.state.activeFullState.participant) {
      return (
        <Dialog
          portalContainer={document.body}
          icon="new-person"
          isOpen={this.state.isDialogOpen}
          onClose={this.toggleDialog.bind(this)}
          enforceFocus={false}
          canOutsideClickClose={false}
          title={I18n.t('js.select_participant', {
            participant: this.state.activeFullState.participant.name,
          })}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="bp3-dialog-body">
              <p>
                {I18n.t('js.select_participant_text', {
                  participant: this.state.activeFullState.participant.name,
                })}
              </p>
              {this.props.AppStore.activeApp.lockParticipants && this.props.AppStore.activeApp.userPermissionLevel.name !== 'owner' && (
                <div className="bp3-callout bp3-intent-warning bp3-icon-warning-sign push-20-b">
                  <h4 className={`${Classes.HEADING} bp3-callout-title`}>{I18n.t('js.caution')}</h4>
                  {I18n.t('js.once_set_participants_cannot_be_changed')}
                </div>
              )}
              <ItemManageParticipantsSelectUser
                handleSelectParticipantUser={this.handleSelectParticipantUser.bind(this)}
                selectedUser={this.state.activeFullState.itemParticipant.user}
              />
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.handleSubmit.bind(this)}
                  text={I18n.t('js.set_participant_name', {
                    participant: this.state.activeFullState.participant.name,
                  })}
                  loading={this.state.isLoading}
                  disabled={typeof this.state.activeFullState.itemParticipant.user !== 'object'}
                />
              </div>
            </div>
          </form>
        </Dialog>
      );
    }
    return undefined;
  }

  renderError() {
    if (this.props.ItemStore.hasUnsetParticipants && this.props.ItemStore.submissionAttempted) {
      return (
        <Flexbox className="bp3-form-group bp3-intent-danger" paddingLeft="20px">
          <div className="bp3-form-helper-text">
            <span>{I18n.t('js.please_set_all_participants')}</span>
          </div>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    let cardClass = classNames('');
    if (this.props.ItemStore.hasUnsetParticipants && this.props.ItemStore.submissionAttempted) {
      cardClass = classNames('bp3-card-has-error');
    }
    if (this.props.ItemStore.isShowingParticipants) {
      cardClass = `${cardClass} square-top`;
    }
    if (this.props.shownInDialogView && !this.props.ItemStore.isShowingParticipants) {
      cardClass = '';
    }

    return (
      <Collapse isOpen={this.props.ItemStore.isShowingParticipants}>
        <Flexbox
          flexDirection="column"
          flexGrow={1}
          className={`${this.props.shownInDialogView ? null : 'bp3-item-header-row'} element_item-participant-chart`}
          paddingBottom="-20px"
          paddingTop="-20px"
        >
          <Flexbox flexDirection="column" className={cardClass} flexGrow={1}>
            {this.renderChartArea()}
            {this.renderError()}
          </Flexbox>
          {this.renderDialog()}
        </Flexbox>
      </Collapse>
    );
  }
}
