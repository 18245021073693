import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Callout, Intent, Dialog, Button, FormGroup, TextArea, InputGroup } from '@blueprintjs/core';
import ItemWidget from '../items/ItemWidget';
import Paginator from '../shared/Paginator';
import Emoji from '../shared/Emoji';
import axios from 'axios';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import ActivityView from '../../views/ActivityView';
import FormElementDisplay from '../form_elements/FormElementDisplay';
import TeamAvatar from '../shared/TeamAvatar';
import moment from 'moment';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore',
  'ActivityStore'
)
@observer
export default class DashboardDiscussYourNeeds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      isSubmitting: false,
      query: '',
      phone: '',
    };
  }

  toggleDialog() {
    this.setState({ showDialog: !this.state.showDialog });
  }

  handleQueryChange(e) {
    this.setState({ query: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    fetch('https://hooks.zapier.com/hooks/catch/202877/ozengg6/', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        name: this.props.CurrentUserStore.currentUser.fullName,
        email: this.props.CurrentUserStore.currentUser.email,
        account: this.props.CurrentUserStore.activeAccount.accountName,
        query: this.state.query,
        phone: this.state.phone,
      }),
    })
      .then(response => {
        this.setState({ isSubmitting: false, query: '', phone: '' });
        this.toggleDialog();
        this.props.ToastStore.showToast(I18n.t('js.discuss_your_needs_success_submission'), 'success');
      })
      .catch(error => {
        this.props.ToastStore.showToast('An error occurred. Please try again later.', 'danger');
        this.setState({ isSubmitting: false });
        this.toggleDialog();
      });
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="phone"
        isOpen={this.state.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={true}
        title={I18n.t('js.discuss_your_needs')}
      >
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="bp3-dialog-body">
            <p>{I18n.t('js.discuss_your_needs_action')}</p>
            <FormGroup label={I18n.t('js.your_query')} labelFor="query" labelInfo={`(${I18n.t('js.required')})`}>
              <TextArea
                name="query"
                className="bp3-fill"
                onChange={e => this.handleQueryChange(e)}
                placeholder={I18n.t('js.your_query_placeholder')}
                type="textarea"
                rows={6}
                value={this.state.query}
              />
            </FormGroup>
            <FormGroup label={I18n.t('js.phone')} labelFor="phone">
              <InputGroup
                name="phone"
                leftIcon="phone"
                className="bp3-fill"
                onChange={e => this.handlePhoneChange(e)}
                placeholder={I18n.t('js.phone_number')}
                value={this.state.phone}
              />
            </FormGroup>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
              <Button
                intent={Intent.SUCCESS}
                type="submit"
                disabled={this.state.query.length === 0}
                text={I18n.t('js.submit')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    if (this.isLoading || this.props.UserProfileTemplateStore.isLoading || this.props.DynamicViewStore.isLoading) {
      return (
        <Flexbox
          flexDirection="column"
          marginRight="40px"
          width="360px"
          height="180px"
          marginBottom="20px"
          className="bp3-skeleton"
        ></Flexbox>
      );
    }
    if (this.props.CurrentUserStore.currentUser.isAccountOwner) {
      return (
        <Flexbox flexDirection="column" className="bp3-cursor-pointer bp3-interactive" flexGrow={0} marginTop="20px">
          <Callout intent={Intent.SUCCESS} icon="phone" title={I18n.t('js.discuss_your_needs')} onClick={this.toggleDialog.bind(this)}>
            {I18n.t('js.discuss_your_needs_description')}
          </Callout>
          {this.renderDialog()}
        </Flexbox>
      );
    }
    return null;
  }
}
