import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { AnchorButton, Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('StoreTemplateStore', 'CurrentUserStore')
@observer
export default class StoreTemplateTabs extends React.Component {
  @observable
  isLoading = false;
  @observable
  isDeleting = false;

  toggleDeleting() {
    this.isDeleting = !this.isDeleting;
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleTabChange(tab) {
    this.props.StoreTemplateStore.activeTab = tab;
  }

  renderRightLinks() {
    return (
      <Flexbox>
        <Tabs
          id="storeTemplateTabs"
          animate={true}
          selectedTabId={this.props.StoreTemplateStore.activeTab}
          defaultSelectedTabId="storeTemplate"
          onChange={this.handleTabChange.bind(this)}
          className="noselect"
        >
          <Tab id="storeTemplate" title={I18n.t('js.preview')} />
          <Tab id="edit" title={I18n.t('js.edit_details')} />
          <Tabs.Expander />
        </Tabs>
      </Flexbox>
    );
  }

  renderEditAction() {
    let buttonText = I18n.t('js.edit_app_template');
    let icon = 'document';
    let link = `/apps/${this.props.StoreTemplateStore.activeStoreTemplate.templatableObjectId}`;
    if (this.props.StoreTemplateStore.activeStoreTemplate.templatableObjectType === 'Workspace') {
      buttonText = I18n.t('js.edit_workspace_template');
      icon = 'grid-view';
      link = `/workspaces/${this.props.StoreTemplateStore.activeStoreTemplate.templatableObjectId}`;
    }
    if (this.props.StoreTemplateStore.activeStoreTemplate.templatableObjectType === 'ProfileTemplate') {
      buttonText = I18n.t('js.edit_profile_template');
      icon = 'new-person';
      link = `/profile-templates/${this.props.StoreTemplateStore.activeStoreTemplate.templatableObjectId}`;
    }
    return <AnchorButton text={buttonText} icon={icon} className="push-10-l" href={link} />;
  }

  render() {
    if (this.props.loading) {
      return (
        <nav className="bp3-navbar bp3-navbar-bordered">
          <Flexbox className="fixed-width-container">
            <Flexbox className="bp3-navbar-group" justifyContent="center" flexGrow={1} />
          </Flexbox>
        </nav>
      );
    }
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
          <Flexbox flexGrow={1} flexDirection="row" alignItems="center" justifyContent="flex-end">
            <AnchorButton href={`/store-templates`} rightIcon="undo" className="bp3-minimal">
              {I18n.t('js.your_store_templates')}
            </AnchorButton>
            {this.renderEditAction()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
