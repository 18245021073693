/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import User from '../../models/UserModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

const UserMultiSelect = MultiSelect.ofType(User);

@inject('StateRuleStore', 'ToastStore')
@observer
export default class StateActionItemUserSelect extends React.Component {
  @computed
  get selectedUsers() {
    const recipients = _.filter(
      this.props.StateRuleStore.stateActionEmailRecipients,
      recipient => recipient.stateActionId === this.props.stateAction.id && recipient.changeType !== 'deleted'
    );
    let selectedUsers = [];
    _.map(recipients, recipient => {
      const findUser = _.find(this.props.StateRuleStore.stateActionEmailRecipientUsers, user => user.id === recipient.userId);
      if (findUser) {
        selectedUsers = selectedUsers.concat(findUser);
      }
    });
    return selectedUsers;
  }

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchUsers: [],
      isLoading: false,
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.setState({
      searchUsers: _.unionBy([newUser], this.state.searchUsers, 'id'),
    });
  }

  searchUsers(e) {
    const query = e.target.value;
    this.setState({ searchUsers: [], query });
    if (query.length > 1) {
      this.toggleLoading();
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/users?query=${query}`)
          .then(response => {
            let total = response.data.length;
            _.map(response.data, user => {
              this.addUser(user);
              total--;
            });
            if (total === 0) {
              resolve(response);
              this.toggleLoading();
            }
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            this.props.ToastStore.showToast(errors, 'danger');
            reject(error);
            this.toggleLoading();
          });
      });
    }
    return undefined;
  }

  renderUser(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.fullName}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-graph push-5-r" /> <span> {I18n.t('js.no_users_found')} </span>
          </span>
        }
      />
    );
  }

  renderTag(user) {
    return <span id={user.id}>{user.fullName}</span>;
  }

  renderInputValue(user) {
    return user.fullName;
  }

  handleUsersSelect(user) {
    this.props.handleAddRecipientUser(user);
  }

  handlePopoverClose() {
    this.setState({ searchUsers: [], query: '' });
  }

  handleUserRemove(user) {
    const userId = user.props.id;
    this.props.handleRemoveRecipientUser(userId);
  }

  filterUser() {
    let possibleUsers = this.state.searchUsers;
    // filter out selected users
    _.map(this.selectedUsers, user => {
      possibleUsers = _.filter(possibleUsers, o => o.id !== user.id);
    });
    possibleUsers = _.orderBy(possibleUsers, ['fullName'], ['asc']);
    return possibleUsers;
  }

  renderRightElement() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    return (
      <UserMultiSelect
        resetOnSelect={true}
        items={this.state.searchUsers}
        itemListPredicate={this.filterUser.bind(this)}
        selectedItems={this.selectedUsers}
        tagRenderer={this.renderTag.bind(this)}
        itemRenderer={this.renderUser.bind(this)}
        onItemSelect={this.handleUsersSelect.bind(this)}
        tagInputProps={{
          disabled: this.props.disabled,
          leftIcon: 'person',
          inputProps: {
            disabled: this.props.disabled,
            placeholder: I18n.t('js.search_for_recipients'),
            onChange: this.searchUsers.bind(this),
          },
          rightElement: this.renderRightElement(),
          className: 'bp3-tag-input',
          onRemove: this.handleUserRemove.bind(this),
        }}
        popoverProps={{
          usePortal: false,
          disabled: this.props.disabled,
          className: '',
          position: 'bottom-left',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
