import React, { Component } from 'react';
import { Button, AnchorButton, Drawer, Position, Intent } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import GuideContent from '../../static/GuideContent';
import renderHTML from 'react-render-html';
import Cookies from 'js-cookie';
import _ from 'lodash';

export default class GuideButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGuide: false,
      guide: null,
    };
  }

  componentDidMount() {
    const findGuide = _.find(GuideContent, o => o.action === this.props.action);
    if (findGuide) {
      this.setState({ guide: findGuide });
    }
  }

  toggleGuide() {
    this.setState({ showGuide: !this.state.showGuide });
  }

  render() {
    const { guide, showGuide } = this.state;
    const darkMode = Cookies.get('theme') === 'dark' ? true : false;

    if (guide) {
      return (
        <Flexbox className="guide-button">
          <Button
            text={I18n.t('js.how_does_this_work')}
            minimal={this.props.minimal}
            outlined={!darkMode}
            intent={darkMode ? Intent.WARNING : Intent.SUCCESS}
            icon="manual"
            onClick={() => this.toggleGuide()}
          ></Button>
          <Drawer
            portalContainer={document.body}
            position={Position.RIGHT}
            onClose={this.toggleGuide.bind(this)}
            isOpen={showGuide}
            size={800}
            title={guide.name}
          >
            <Flexbox flexDirection="column" padding="20px" style={{ overflowY: 'scroll' }}>
              <Flexbox flexGrow={1} flexDirection="row" marginBottom="20px">
                <AnchorButton
                  icon="document-open"
                  intent={Intent.SUCCESS}
                  text={I18n.t('js.learn_more_in_the_support_center')}
                  href={guide.supportUrl}
                  target="_blank"
                  className="full-width"
                ></AnchorButton>
              </Flexbox>
              <p>{renderHTML(guide.content)}</p>
            </Flexbox>
          </Drawer>
        </Flexbox>
      );
    }
    return null;
  }
}
7;
