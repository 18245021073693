import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import AppItemsNewItemNameInput from './AppItemsNewItemNameInput';
import TeamInfoDrawer from '../form_elements/TeamInfoDrawer';
import TeamSuggest from '../suggests/TeamSuggest';
import _ from 'lodash';

@inject('AppStore', 'AppItemStore', 'DynamicViewStore', 'ItemActions', 'ItemStore', 'CurrentUserStore', 'ToastStore', 'TeamUsersStore')
@observer
export default class AppItemsSelectTeams extends React.Component {
  @observable drawerData = [];
  @observable isTeamDrawerLoading = false;
  @observable isTeamDrawerOpen = false;

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      itemName: I18n.t('js.new_item_with_name', {
        item: this.props.AppStore.activeApp.item,
        name: I18n.t('js.users_name'),
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      itemName: I18n.t('js.new_item_with_name', {
        item: this.props.AppStore.activeApp.item,
        name: I18n.t('js.users_name'),
      }),
    });
  }

  toggleLoading = () => {
    this.setState({ isSubmitting: !this.state.isSubmitting });
  };

  toggleDialog() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleNameInputChange(name) {
    this.setState({ itemName: name });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    let teamLength = this.props.AppItemStore.newItemTeams.length;
    let uniqueUsers = [];
    this.processItems(teamLength, uniqueUsers);

    if (this.props.postType == 'pin') {
      _.map(this.props.AppItemStore.newItemTeams, team => {
        this.props.ItemActions.pinItemForTeam(this.props.ItemStore.item.id, team.id)
          .then(response => {
            this.props.ItemStore.addItemPin(response.data);
            teamLength--;
            this.determineCloseDialog(teamLength);
          })
          .catch(() => {
            teamLength--;
            this.determineCloseDialog(teamLength);
          });
      });
    } else {
      _.map(this.props.AppItemStore.newItemTeams, team => {
        // Fetch all the users in this team
        this.fetchUserTeams(team.id).then(users => {
          _.map(users, user => uniqueUsers.push(user));
          uniqueUsers = _.uniqBy(uniqueUsers, user => {
            return user.id;
          });
          teamLength--;
          this.processItems(teamLength, uniqueUsers);
        });
      });
    }
  }

  processItems(teamLength, users) {
    if (teamLength === 0) {
      // Create the items for the unique users
      const defaultName = I18n.t('js.new_item_with_name', {
        item: this.props.AppStore.activeApp.item,
        name: I18n.t('js.users_name'),
      });
      let userLength = users.length;
      _.map(users, user => {
        const itemObj = {};
        itemObj.app_id = this.props.AppStore.activeApp.id;
        itemObj.user_id = user.id;
        if (this.state.itemName !== defaultName) {
          itemObj.name = this.state.itemName;
        } else {
          itemObj.name = I18n.t('js.new_item_with_name', {
            item: this.props.AppStore.activeApp.item,
            name: user.full_name,
          });
        }
        switch (this.props.postType) {
          case 'create':
            this.props.ItemActions.createItem(itemObj)
              .then(response => {
                this.props.AppItemStore.addItem(response.data);
                this.props.DynamicViewStore.fetchItemsForUser(user.id);
                userLength--;
                this.determineCloseDialog(userLength);
              })
              .catch(() => {
                userLength--;
                this.determineCloseDialog(userLength);
              });
            break;
          case 'copy':
            this.props.ItemActions.duplicateItemForUser(this.props.ItemStore.item.id, user.id)
              .then(response => {
                this.props.AppItemStore.addItem(response.data);
                this.props.DynamicViewStore.fetchItemsForUser(user.id);
                userLength--;
                this.determineCloseDialog(userLength);
              })
              .catch(() => {
                userLength--;
                this.determineCloseDialog(userLength);
              });
            break;
        }
      });
    }
  }

  fetchUserTeams(teamId) {
    let users = [];
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_teams?team_id=${teamId}&page=1&per_page=1&offset=0`)
        .then(response => {
          const total = response.headers['x-total'];
          axios
            .get(`${Cookies.get('apiEnv')}/user_teams?team_id=${teamId}&type=show_user&page=1&per_page=${total}&offset=0`)
            .then(response => {
              _.map(response.data, userTeam => {
                users.push(userTeam.user);
              });
              resolve(users);
            })
            .catch(error => {
              reject(error);
              const errors = error.response.data.error.join(', ');
              ToastStore.showToast(errors, 'danger');
            });
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  determineCloseDialog(length) {
    if (length === 0) {
      this.toggleLoading();
      this.props.toggleDialog();
      this.props.AppItemStore.newItemTeams = [];
      this.setState({
        itemName: I18n.t('js.new_item_with_name', {
          item: this.props.AppStore.activeApp.item,
          name: I18n.t('js.users_name'),
        }),
      });
      this.props.ToastStore.showToast(
        I18n.t('js.items_have_been_created', {
          itemPlural: this.props.AppStore.activeApp.itemPlural,
        }),
        'success'
      );
    }
  }

  handleColumnSort(column) {
    this.props.AppItemStore.setNewItemUserSortParams(column.id, column.sortDir);
  }

  handleCancel() {
    this.props.AppItemStore.newItemTeams = [];
    this.setState({
      itemName: I18n.t('js.new_item_with_name', {
        item: this.props.AppStore.activeApp.item,
        name: I18n.t('js.users_name'),
      }),
    });
    this.props.toggleDialog();
  }

  openTeamDrawer(team) {
    const TeamUsersStore = this.props.TeamUsersStore;
    this.drawerData = team;
    this.toggleBoolean('isTeamDrawerLoading');
    TeamUsersStore.resetStartingAttributes();
    TeamUsersStore.fetchTeam(team.id)
      .then(() => {
        const appNum = 1;
        TeamUsersStore.fetchUserTeams(team.id, appNum, TeamUsersStore.pagination.xPerPage, TeamUsersStore.pagination.xOffset)
          .then(() => {
            this.toggleBoolean('isTeamDrawerLoading');
          })
          .catch(() => {
            this.toggleBoolean('isTeamDrawerLoading');
          });
      })
      .catch(() => {
        this.toggleBoolean('isTeamDrawerLoading');
      });

    this.toggleBoolean('isTeamDrawerOpen');
  }

  @action
  toggleIsTeamDrawerLoading() {
    this.isTeamDrawerLoading = !this.isTeamDrawerLoading;
  }

  @action
  toggleBoolean = value => {
    this[`${value}`] = !this[`${value}`];
  };

  renderDialogFooter() {
    return (
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
          <Button
            intent={Intent.PRIMARY}
            onClick={this.handleSubmit.bind(this)}
            disabled={this.props.AppItemStore.newItemTeams.length === 0}
            text={
              this.props.postType === 'create'
                ? I18n.t('js.create_items', {
                    items: this.props.AppStore.activeApp.itemPlural,
                  })
                : this.props.postType == 'copy'
                ? I18n.t('js.duplicate_items', {
                    items: this.props.AppStore.activeApp.itemPlural,
                  })
                : I18n.t('js.pin_item', {
                    item: this.props.AppStore.activeApp.item,
                  })
            }
            loading={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }

  handleTeamClick(team) {
    this.props.AppItemStore.addNewItemTeam(team);
  }

  handleTeamRemove(teamId) {
    this.props.AppItemStore.removeNewItemTeam(teamId);
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="layout-hierarchy"
        isOpen={this.props.dialogOpen}
        onClose={this.props.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.select_team_of_users')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            {this.props.postType === 'create' ? (
              <Flexbox flexGrow={1} flexDirection="column">
                <AppItemsNewItemNameInput handleNameInputChange={this.handleNameInputChange.bind(this)} name={this.state.itemName} />
              </Flexbox>
            ) : null}
            <Flexbox flexGrow={1} flexDirection="column">
              <label className="bp3-label" htmlFor="name">
                {I18n.t('js.select_team_of_users')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <Flexbox marginTop="-10px">
                <TeamSuggest
                  placeholder={I18n.t('js.select_team')}
                  element={null}
                  handleTeamClick={this.handleTeamClick.bind(this)}
                  handleTeamRemove={this.handleTeamRemove.bind(this)}
                  validateInput={() => null}
                  intent={null}
                  openDrawer={this.openTeamDrawer.bind(this)}
                  expandMenu={false}
                />
              </Flexbox>
            </Flexbox>
          </div>
          {this.renderDialogFooter()}
        </form>
        <TeamInfoDrawer
          isOpen={this.isTeamDrawerOpen}
          team={this.drawerData}
          toggleBool={this.toggleBoolean.bind(this)}
          isLoading={this.isTeamDrawerLoading}
          toggleIsTeamDrawerLoading={this.toggleIsTeamDrawerLoading.bind(this)}
        />
      </Dialog>
    );
  }

  render() {
    return <Flexbox>{this.renderDialog()}</Flexbox>;
  }
}
