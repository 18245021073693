// @flow
import * as React from 'react';
import Flexbox from 'flexbox-react';
import WorkspaceMenuItem from './WorkspaceMenuItem';
import { inject, observer } from 'mobx-react';
import { Collapse } from '@blueprintjs/core';
import _ from 'lodash';

@inject('CurrentUserStore', 'WorkspaceStore')
@observer
export default class WorkspaceMenuList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.WorkspaceStore.workspaces.length <= 1) {
      this.setState({ isLoading: true });
      this.props.WorkspaceStore.fetchWorkspaces()
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const { activeObjectId } = this.props;
    return (
      <React.Fragment>
        <Flexbox width="100%" flexDirection="column" paddingRight="15px" paddingTop="10px" paddingBottom="10px">
          <div className="bp3-sidebar-divider"></div>
        </Flexbox>
        {this.state.isLoading ? null : (
          <Collapse isOpen={this.props.WorkspaceStore.filteredWorkspaces.length > 0 && !this.state.isLoading}>
            <Flexbox flexDirection="column">
              {_.map(this.props.WorkspaceStore.filteredWorkspaces, workspace => {
                return <WorkspaceMenuItem key={workspace.id} workspace={workspace} activeObjectId={activeObjectId} displayAs="button" />;
              })}
            </Flexbox>
          </Collapse>
        )}
        {this.props.WorkspaceStore.filteredWorkspaces.length > 0 && !this.state.isLoading ? (
          <Flexbox width="100%" flexDirection="column" paddingRight="15px" paddingTop="10px" paddingBottom="10px">
            <div className="bp3-sidebar-divider"></div>
          </Flexbox>
        ) : null}
      </React.Fragment>
    );
  }
}
