/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Spinner, Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsTabs extends React.Component {
  renderRightLinks() {
    if (this.props.loading) {
      return <Spinner size={20} />;
    }
    return (
      <Tabs
        id="accountSettingsTabnav"
        selectedTabId={this.props.AccountSettingsStore.activeTab}
        vertical={false}
        onChange={tab => this.props.AccountSettingsStore.setActiveTab(tab)}
      >
        <Tab id="details" title={I18n.t('js.details')} />
        <Tab id="usage" title={I18n.t('js.usage')} />
        <Tab id="integrations" title={I18n.t('js.integrations')} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
