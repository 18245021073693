/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import StateRuleActions from '../../actions/StateRuleActions';
import {
  Button,
  Classes,
  Dialog,
  Intent,
  MenuItem,
  Switch,
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { Select } from '@blueprintjs/select';
import StatusFlag from '../../models/StatusFlagModel';
import classNames from 'classnames';
import TemplateNewParticipant from './TemplateNewParticipant';
import _ from 'lodash';

const EditStatusFlagSelect = Select.ofType(StatusFlag);

@inject('AppStore', 'TemplateStore', 'TemplateActions')
@observer
export default class TemplateNewState extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  renderStatusFlagText(item) {
    return (
      <Flexbox>
        <span
          className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-10-r"
          style={{
            marginTop: '2px',
            color: item.color,
          }}
        />
        {item.name}
      </Flexbox>
    );
  }

  renderStatusFlag(item, details) {
    let menuClass = classNames('popover-larger');
    if (item.name === this.props.TemplateStore.editStatusFlag.name) {
      menuClass = classNames(
        Classes.ACTIVE,
        Classes.INTENT_PRIMARY,
        'popover-larger'
      );
    }
    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={this.renderStatusFlagText(item, details)}
      />
    );
  }

  renderInputValue(statusFlag) {
    return statusFlag.name;
  }

  handleItemSelect(statusFlag) {
    this.props.TemplateStore.updateNewState(
      statusFlag.id,
      'statusFlagId'
    );
  }

  renderDropDown() {
    return (
      <span className="bp3-icon bp3-icon-caret-down push-10-l" />
    );
  }

  handleAllVisibleChange() {
    this.props.TemplateStore.updateNewState(
      !this.props.TemplateStore.newState.allVisible,
      'allVisible'
    );
  }

  handleAllEditableChange() {
    this.props.TemplateStore.updateNewState(
      !this.props.TemplateStore.newState.allEditable,
      'allEditable'
    );
  }

  handleCancel() {
    this.props.TemplateStore.resetNewState();
    this.props.toggleDialog();
  }

  handleSubmit(e) {
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    e.preventDefault();
    const findParticipant = _.find(
      this.props.TemplateStore.participants,
      o =>
        o.name.toLowerCase() ===
        this.props.TemplateStore.newParticipant.name.toLowerCase()
    );
    if (findParticipant) {
      this.processSubmit(findParticipant.id);
    } else {
      const participantObj = {
        app_id: this.props.AppStore.activeApp.id,
        name: this.props.TemplateStore.newParticipant.name,
      };
      this.props.TemplateActions.createParticipant(participantObj)
        .then(response => {
          this.processSubmit(response.data.id);
        })
        .catch(() => {
          this.toggleLoading();
        });
    }
  }

  processSubmit(participantId) {
    const stateObj = {
      app_id: this.props.AppStore.activeApp.id,
      participant_id: participantId,
      status_flag_id: this.props.TemplateStore.newState.statusFlagId,
      name: this.props.TemplateStore.newState.name,
      all_visible: this.props.TemplateStore.newState.allVisible,
      all_editable: this.props.TemplateStore.newState.allEditable,
    };
    this.props.TemplateActions.createState(stateObj)
      .then(response => {
        this.props.toggleDialog();
        this.toggleLoading();
        this.props.TemplateStore.resetNewState();
        StateRuleActions.loadInitialData(response.data.id);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.TemplateStore.updateNewState(
      e.target.value,
      e.target.name
    );
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.TemplateStore.newState[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.TemplateStore.newState[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  renderButtonText() {
    if (this.props.TemplateStore.newState.statusFlagId.length > 0) {
      const findStatusFlag = _.find(
        this.props.TemplateStore.statusFlags,
        o => o.id === this.props.TemplateStore.newState.statusFlagId
      );
      return (
        <Flexbox>
          <span
            className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-10-r"
            style={{
              marginTop: '2px',
              color: findStatusFlag.color,
            }}
          />
          {findStatusFlag.name}
          <Flexbox
            marginLeft="10px"
            marginTop="2px"
            marginRight="5px"
          >
            <span className="bp3-icon bp3-icon-caret-down push-5-l" />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1}>
        {I18n.t('js.choose_from_the_following_options')}
        <Flexbox marginLeft="10px" marginTop="2px" marginRight="5px">
          <span className="bp3-icon bp3-icon-caret-down push-5-l" />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.props.isOpen}
        onClose={this.props.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.create_a_new_state')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.new_state_description')}</p>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'name') &&
                  !this.props.TemplateStore.newState.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.state_name')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'name') &&
                      !this.props.TemplateStore.newState.nameValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t(
                        'js.enter_a_name_for_your_new_state'
                      )}
                      name="name"
                      value={this.props.TemplateStore.newState.name}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('name')}
                </div>
              </div>
            </Flexbox>
            <TemplateNewParticipant />
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'statusFlagId') &&
                  !this.props.TemplateStore.newState.statusFlagIdValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t(
                    'js.select_default_status_flag_for_this_state'
                  )}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <EditStatusFlagSelect
                    resetOnSelect={true}
                    filterable={false}
                    items={this.props.TemplateStore.statusFlags}
                    itemRenderer={this.renderStatusFlag.bind(this)}
                    onItemSelect={this.handleItemSelect.bind(this)}
                    inputValueRenderer={this.renderInputValue.bind(
                      this
                    )}
                    inputProps={{
                      rightElement: this.renderDropDown(),
                    }}
                    popoverProps={{
                      usePortal: false,
                      position: 'bottom-left',
                      className: '',
                      popoverClassName: 'bp3-minimal',
                    }}
                  >
                    <Button text={this.renderButtonText()} />
                  </EditStatusFlagSelect>
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                checked={this.props.TemplateStore.newState.allVisible}
                className="bp3-control-no-margin"
                label={I18n.t('js.all_visible_description')}
                onChange={this.handleAllVisibleChange.bind(this)}
              />
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <Switch
                checked={
                  this.props.TemplateStore.newState.allEditable
                }
                className="bp3-control-no-margin"
                label={I18n.t('js.all_editable_description')}
                onChange={this.handleAllEditableChange.bind(this)}
              />
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.handleCancel.bind(this)}
              />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.props.TemplateStore.newStateFormValid}
                text={I18n.t('js.add_state')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}
