import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Intent, Position, Toaster } from '@blueprintjs/core';

@inject('ToastStore')
@observer
export default class Toasts extends React.Component {
  @observable toasts = [];

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof nextProps.latestToast === 'object') {
      this.addToast(nextProps.latestToast);
    }
  }

  componentDidMount() {
    if (this.props.flash.length > 0) {
      if (this.props.flash[0][0] === 'success') {
        this.toaster.show({
          message: this.props.flash[0][1],
          intent: Intent.SUCCESS,
          icon: 'tick',
        });
      }
      if (this.props.flash[0][0] === 'notice') {
        this.toaster.show({
          message: this.props.flash[0][1],
          intent: Intent.SUCCESS,
          icon: 'info-sign',
        });
      }
      if (this.props.flash[0][0] === 'warning') {
        this.toaster.show({
          message: this.props.flash[0][1],
          intent: Intent.WARNING,
          icon: 'warning-sign',
        });
      }
      if (this.props.flash[0][0] === 'error') {
        this.toaster.show({
          message: this.props.flash[0][1],
          intent: Intent.DANGER,
          icon: 'error',
        });
      }
    }
  }

  addToast(toast) {
    if (toast.message.length > 0) {
      if (toast.message != 'Unauthorized. Access denied.') {
        this.toaster.show({
          message: toast.message,
          intent: toast.intent,
          icon: toast.icon,
        });
      }
    }
  }

  render() {
    return <Toaster position={Position.TOP} usePortal={false} portalContainer={Document.body} ref={c => (this.toaster = c)} />;
  }
}
