import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer, Provider } from 'mobx-react';
import { Spinner, NonIdealState, Divider } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import IntegrationsTabs from '../components/integrations/IntegrationsTabs';
import IntegrationStore from '../stores/IntegrationStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import BambooIntegrationStore from '../stores/BambooIntegrationStore';
import BambooIntegration from '../components/integrations/bamboo_integration/BambooIntegration';
import NewBambooIntegration from '../components/integrations/bamboo_integration/NewBambooIntegration';
import SlackIntegration from '../components/integrations/slack_integration/SlackIntegration';
import AzureIntegration from '../components/integrations/azure_integration/AzureIntegration';
import NewAzureIntegration from '../components/integrations/azure_integration/NewAzureIntegration';
import AzureIntegrationStore from '../stores/AzureIntegrationStore';
import HookStore from '../stores/HookStore';
import Hooks from '../components/hooks/Hooks';
@observer
export default class IntegrationsView extends React.Component {
  renderContent() {
    const activeTab = IntegrationStore.activeTab;
    const bamboo_integration = BambooIntegrationStore.bambooIntegration;
    const azure_integration = AzureIntegrationStore.azureIntegration;

    if (IntegrationStore.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    switch (activeTab) {
      case 'webhooks':
        return <Hooks />;
      case 'bamboohr-integration':
        if (bamboo_integration != null && bamboo_integration.id != '') {
          return <BambooIntegration />;
        } else {
          return <NewBambooIntegration />;
        }
        break;
      case 'slack-integration':
        return <SlackIntegration />;
        break;
      case 'azure-integration':
        if (azure_integration != null && azure_integration.id != '') {
          return <AzureIntegration />;
        } else {
          return <NewAzureIntegration />;
        }
        break;
      default:
        return undefined;
        break;
    }
  }

  render() {
    const stores = {
      AzureIntegrationStore,
      BambooIntegrationStore,
      ToastStore,
      CurrentUserStore,
      IntegrationStore,
      HookStore,
    };
    return (
      <Provider {...stores}>
        <Flexbox flexGrow={1} className="position-relative">
          <Flexbox className="push-20-r">
            <IntegrationsTabs />
          </Flexbox>
          {this.renderContent()}
        </Flexbox>
      </Provider>
    );
  }
}
