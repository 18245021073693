const actionTypes = [
  {
    id: 0,
    name: 'change_state',
    text: I18n.t('js.change_state_to'),
  },
  {
    id: 1,
    name: 'change_status',
    text: I18n.t('js.change_status_flag_to'),
  },
  {
    id: 2,
    name: 'send_email',
    text: I18n.t('js.send_email'),
  },
  {
    id: 3,
    name: 'create_timeline_event',
    text: I18n.t('js.create_timeline_event'),
  },
  {
    id: 4,
    name: 'post_webhook',
    text: I18n.t('js.post_webhook'),
  },
  {
    id: 5,
    name: 'invite_to_section',
    text: I18n.t('js.invite_to_section'),
  },
  {
    id: 6,
    name: 'override_participant',
    text: I18n.t('js.override_participant'),
  },
];

class StateActionDefinitions {
  static get actionTypes() {
    return actionTypes;
  }
}

export default StateActionDefinitions;
