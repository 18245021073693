import { action, computed, observable } from 'mobx';
import ToastStore from './ToastStore';
import SignUp from '../models/SignUpModel';
import SignIn from '../models/SignInModel';
import ForgotPassword from '../models/ForgotPasswordModel';
import auth0 from 'auth0-js';
import Cookies from 'js-cookie';
import _ from 'lodash';

class AuthStore {
  @observable
  newSignUp = new SignUp();
  @observable
  newSignIn = new SignIn();
  @observable
  newForgotPassword = new ForgotPassword();
  @observable
  activeTab = 'email';

  webAuth = new auth0.WebAuth({
    domain: 'peoplegoal.eu.auth0.com',
    clientID: Cookies.get('auth0clientId'),
    audience: 'https://peoplegoal.eu.auth0.com/userinfo',
    redirectUri: Cookies.get('auth0redirectUri'),
    responseType: 'code',
    scope: 'openid email',
  });

  @computed
  get signUpFormValid() {
    return (
      this.newSignUp.firstNameValid &&
      this.newSignUp.lastNameValid &&
      this.newSignUp.emailValid &&
      this.newSignUp.passwordValid &&
      this.newSignUp.passwordConfirmationValid
    );
  }

  @computed
  get signInFormValid() {
    return this.newSignIn.emailValid && this.newSignIn.passwordValid;
  }

  @computed
  get forgotPasswordFormValid() {
    return this.newForgotPassword.emailValid;
  }

  @action
  updateActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  updateNewSignUp(data, attribute) {
    this.newSignUp[attribute] = data;
  }

  @action
  updateNewSignIn(data, attribute) {
    this.newSignIn[attribute] = data;
  }

  @action
  updateNewForgotPassword(data, attribute) {
    this.newForgotPassword[attribute] = data;
  }

  @action
  createSignUp() {
    return new Promise((resolve, reject) => {
      this.webAuth.signup(
        {
          connection: Cookies.get('auth0connection'),
          email: this.newSignUp.email,
          password: this.newSignUp.password,
        },
        (err, authResult) => {
          if (err) {
            ToastStore.showToast(_.capitalize(err.description), 'danger');
            reject(err.message);
          } else {
            Cookies.set('newSignUp', {
              userId: authResult.userId,
              firstName: this.newSignUp.firstName,
              lastName: this.newSignUp.lastName,
            });
            this.newSignIn.email = this.newSignUp.email;
            this.newSignIn.password = this.newSignUp.password;
            this.createSignIn().then(resolve);
          }
        }
      );
    });
  }

  @action
  createSocialSignUp(connection) {
    return new Promise((resolve, reject) => {
      this.webAuth.authorize(
        {
          connection,
        },
        err => {
          if (err) {
            ToastStore.showToast(_.capitalize(err.description), 'danger');
            reject(err.message);
          } else {
            this.createSignIn().then(resolve);
          }
        }
      );
    });
  }

  @action
  createSignIn() {
    return new Promise((resolve, reject) => {
      this.webAuth.redirect.loginWithCredentials(
        {
          connection: Cookies.get('auth0connection'),
          username: this.newSignIn.email,
          password: this.newSignIn.password,
        },
        err => {
          if (err) {
            ToastStore.showToast(_.capitalize(err.description), 'danger');
            reject(err.message);
          } else {
            resolve();
          }
        }
      );
    });
  }

  @action
  createForgotPassword() {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword(
        {
          connection: Cookies.get('auth0connection'),
          email: this.newForgotPassword.email,
        },
        (err, response) => {
          if (err) {
            ToastStore.showToast(_.capitalize(err.description), 'danger');
            reject(err.message);
          } else {
            ToastStore.showToast(response, 'success');
            // Utilities.navigate('/sign-in');
            resolve();
          }
        }
      );
    });
  }
}

const store = new AuthStore();
export default store;
