import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, InputGroup, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import ElementMultilineInput from '../../../models/elements/ElementMultilineInputModel';
import ElementMultilineInputRow from './ElementMultilineInputRow';
import {
  arrayMove,
  sortableContainer,
  sortableElement,
} from 'react-sortable-hoc';

const SortableItem = sortableElement(
  ({ input, handleConfirmInputChange, handleDeleteInput }) => (
    <ElementMultilineInputRow
      key={input.id}
      input={input}
      handleConfirmInputChange={handleConfirmInputChange}
      handleDeleteInput={handleDeleteInput}
    />
  )
);

const SortableArea = sortableContainer(
  ({ inputs, handleConfirmInputChange, handleDeleteInput }) => (
    <tbody>
      {inputs.map(input => (
        <SortableItem
          key={input.id}
          handleConfirmInputChange={handleConfirmInputChange}
          handleDeleteInput={handleDeleteInput}
          index={inputs.indexOf(input)}
          input={input}
        />
      ))}
    </tbody>
  )
);

@inject('UserProfileTemplateStore', 'TemplateStore')
@observer
export default class ElementMultilineInputs extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { newInputName: '' };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({ newInputName: e.target.value });
  }

  handleAddNewInput() {
    const newInput = new ElementMultilineInput(
      '',
      this.store.activeElement.elementMultiline.id,
      this.state.newInputName,
      this.state.newInputName,
      true,
      false,
      'inline',
      'full'
    );
    newInput.added = true;

    const newActiveElement = _.clone(this.store.activeElement);
    newActiveElement.elementMultiline.elementMultilineInputs.push(
      newInput
    );
    this.store.activeElement = newActiveElement;

    this.setState({ newInputName: '' });
  }

  handleConfirmInputChange(attribute, value, input) {
    const newInput = _.find(
      this.store.activeElement.elementMultiline
        .elementMultilineInputs,
      o => o.id === input.id
    );
    newInput[attribute] = value;
    newInput.changed = true;
  }

  handleDeleteInput(input) {
    const newActiveElement = _.clone(this.store.activeElement);
    newActiveElement.elementMultiline.elementMultilineInputs = _.filter(
      newActiveElement.elementMultiline.elementMultilineInputs,
      o => o.id !== input.id
    );
    this.store.activeElement = newActiveElement;
  }

  handleSortEnd(input, newIndex) {
    const newInput = _.find(
      this.store.activeElement.elementMultiline
        .elementMultilineInputs,
      o => o.id === input.id
    );
    newInput.rowOrder = newIndex;
    newInput.changed = true;
    newInput.rowOrderChanged = true;
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the section
    const findInput = this.store.activeElement.elementMultiline
      .elementMultilineInputs[oldIndex];

    // Reorder the current UI
    const newInputs = arrayMove(
      this.store.activeElement.elementMultiline.elementMultilineInputs.slice(),
      oldIndex,
      newIndex
    );
    const newElement = _.clone(this.store.activeElement);
    newElement.elementMultiline.elementMultilineInputs = newInputs;
    this.store.activeElement = newElement;

    // Process reorder on server
    this.handleSortEnd(findInput, newIndex);
  }

  renderNewInput() {
    return (
      <Flexbox flexDirection="column" marginTop="10px">
        <Flexbox className="bp3-control-group">
          <InputGroup
            autoComplete="off"
            type="text"
            intent={Intent.DEFAULT}
            placeholder={I18n.t('js.add_a_new_input_here')}
            name="name"
            value={this.state.newInputName}
            onChange={this.handleInputChange.bind(this)}
            dir="auto"
            className="bp3-fill"
          />
          <AnchorButton
            onClick={this.handleAddNewInput.bind(this)}
            text={I18n.t('js.add_new_input')}
            className="bp3-intent-primary"
            disabled={this.state.newInputName.length === 0}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox
        className="bp3-form-group"
        flexDirection="column"
        flexGrow={1}
        flexBasis="1"
      >
        <label className="bp3-label" htmlFor="inputType">
          {I18n.t('js.add_remove_or_edit_inputs')}
          <span className="bp3-text-muted push-5-l">
            ({I18n.t('js.required')})
          </span>
        </label>
        <div className="bp3-form-content">
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
              <thead>
                <tr>
                  <th>{I18n.t('js.input_name')}</th>
                  <th>{I18n.t('js.input_placeholder_text')}</th>
                  <th>
                    <Flexbox flexGrow={1} justifyContent="center">
                      {I18n.t('js.actions')}
                    </Flexbox>
                  </th>
                </tr>
              </thead>
              <SortableArea
                inputs={
                  this.store.activeElement.elementMultiline
                    .elementMultilineInputs
                }
                onSortEnd={this.onSortEnd.bind(this)}
                lockToContainerEdges={true}
                lockAxis="y"
                handleConfirmInputChange={(attribute, value, input) =>
                  this.handleConfirmInputChange(
                    attribute,
                    value,
                    input
                  )
                }
                handleDeleteInput={input =>
                  this.handleDeleteInput(input)
                }
                useDragHandle={false}
                distance={5}
              />
            </table>
            {this.renderNewInput()}
          </Flexbox>
        </div>
      </Flexbox>
    );
  }
}
