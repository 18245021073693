import { computed, observable } from 'mobx';

export default class ElementProfileValue {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;
  @observable
  profileTemplateElementId;
  @observable
  participantId;
  @observable
  keepExistingItemsUpdated;

  constructor(
    id,
    elementId,
    name,
    profileTemplateElementId,
    participantId,
    keepExistingItemsUpdated
  ) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
    this.profileTemplateElementId = profileTemplateElementId || '';
    this.participantId = participantId || '';
    typeof keepExistingItemsUpdated === 'undefined' ||
    keepExistingItemsUpdated === null
      ? (this.keepExistingItemsUpdated = false)
      : (this.keepExistingItemsUpdated = keepExistingItemsUpdated);
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get participantValid() {
    if (this.participantId.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get participantError() {
    if (this.participantId.length < 1) {
      return I18n.t('js.participant_is_required');
    }
    return null;
  }
}
