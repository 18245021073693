import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import AppItemsNewItemNameInput from './AppItemsNewItemNameInput';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject(
  'AppStore',
  'AppItemStore',
  'DynamicViewStore',
  'ItemActions',
  'ItemStore',
  'CurrentUserStore',
  'ToastStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions'
)
@observer
export default class AppItemsNewItemName extends React.Component {
  @observable
  isUserDrawerOpen = false;
  @observable
  drawerData = [];

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      itemName: I18n.t('js.new_item_with_placeholder_name', {
        item: this.props.AppStore.activeApp.item,
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      itemName: I18n.t('js.new_item_with_placeholder_name', {
        item: nextProps.AppStore.activeApp.item,
      }),
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleNameInputChange(name) {
    this.setState({ itemName: name });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const itemObj = {};
    itemObj.app_id = this.props.AppStore.activeApp.id;
    itemObj.user_id = this.props.CurrentUserStore.currentUser.id;
    itemObj.name = this.state.itemName;
    this.props.ItemActions.createItem(itemObj)
      .then(response => {
        Utilities.navigate(`/items/${response.data.id}`);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleCancel() {
    this.props.toggleDialog();
  }

  renderDialogFooter() {
    return (
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
          <Button
            intent={Intent.PRIMARY}
            onClick={this.handleSubmit.bind(this)}
            disabled={this.state.itemName.length === 0}
            text={
              this.props.postType === 'create'
                ? I18n.t('js.create_item', {
                    item: this.props.AppStore.activeApp.item,
                  })
                : I18n.t('js.duplicate_items', {
                    items: this.props.AppStore.activeApp.itemPlural,
                  })
            }
            loading={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.props.dialogOpen}
        onClose={this.props.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.new_item', {
          item: this.props.AppStore.activeApp.item,
        })}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            {this.props.postType === 'create' ? (
              <Flexbox flexGrow={1} flexDirection="column">
                <AppItemsNewItemNameInput
                  handleNameInputChange={this.handleNameInputChange.bind(this)}
                  name={this.state.itemName}
                  label="default"
                />
              </Flexbox>
            ) : null}
          </div>
          {this.renderDialogFooter()}
        </form>
      </Dialog>
    );
  }

  render() {
    return <Flexbox>{this.renderDialog()}</Flexbox>;
  }
}
