/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class UserTeamRequest {
  @observable
  id;
  @observable
  teamId;
  @observable
  approved;
  @observable
  approvedBy;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;
  @observable
  userId;

  constructor(id, teamId, approved, approvedBy, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy, userId) {
    this.id = id || '';
    this.teamId = teamId || '';
    // FIXME this probably wasn't meant to be an expression
    // eslint-disable-next-line no-unused-expressions
    typeof approved === 'undefined' ? (this.approved = false) : (this.approved = approved);
    this.approvedBy = approvedBy || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.userId = userId || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
