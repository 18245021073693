const chartTypes = [
  {
    name: I18n.t('js.line'),
    type: 'line',
    icon: 'timeline-line-chart',
  },
  {
    name: I18n.t('js.bar'),
    type: 'bar',
    icon: 'timeline-bar-chart',
  },
  {
    name: I18n.t('js.pie'),
    type: 'pie',
    icon: 'pie-chart',
  },
  {
    name: I18n.t('js.polar'),
    type: 'polar',
    icon: 'flow-review-branch',
  },
  {
    name: I18n.t('js.radar'),
    type: 'radar',
    icon: 'polygon-filter',
  },
  {
    name: I18n.t('js.metric'),
    type: 'metric',
    icon: 'numerical',
  },
];

class ChartDefinitions {
  static get chartTypes() {
    return chartTypes;
  }
}

export default ChartDefinitions;
