import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import Flexbox from 'flexbox-react';
import Utilities from '../../../utils/Utilities';
import _ from 'lodash';
import { Tag } from '@blueprintjs/core';

@inject('BambooIntegrationStore', 'ToastStore')
@observer
export default class BambooIntegrationFormLogic extends React.Component {
  @observable
  isLoading = false;
  @observable
  errors = {};

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (!this.props.BambooIntegrationStore.bambooIntegration) {
      this.props.BambooIntegrationStore.createEmptyIntegration();
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (!_.isEmpty(this.errors) && e.target.name in this.errors) {
      delete this.errors[e.target.name];
    }
    if (name.includes('include')) {
      this.props.BambooIntegrationStore.updateBooleanAttribute(
        name,
        value
      );
    } else {
      this.props.BambooIntegrationStore.updateAttribute(name, value);
      this.validateInput(name, value);
    }
  }

  handleBlurChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (_.isEmpty(this.errors) && value == '') {
      this.errors[`${name}`] = I18n.t(
        `js.please_enter_valid_${name}`
      );
      this.props.BambooIntegrationStore.updateHasErrors(
        !_.isEmpty(this.errors)
      );
    } else if (_.isEmpty(this.errors) || value != null) {
      this.validateInput(name, value);
    }
  }

  validateInput(name, value) {
    const capital_name = name.charAt(0).toUpperCase() + name.slice(1);
    const validate_method = `validate${capital_name}`;
    if (!Utilities[`${validate_method}`](value)) {
      this.errors[`${name}`] = I18n.t(
        `js.please_enter_valid_${name}`
      );
    } else {
      this.props.BambooIntegrationStore.updateAttribute(name, value);
      this.props.BambooIntegrationStore.updateHasErrors(
        !_.isEmpty(this.errors)
      );
    }
    this.props.BambooIntegrationStore.updateHasErrors(
      !_.isEmpty(this.errors)
    );
  }

  @computed
  get isDisabled() {
    const active = this.props.BambooIntegrationStore.bambooIntegration
      .active;
    const id = this.props.BambooIntegrationStore.bambooIntegration.id;
    if (!active && id == '') {
      return false;
    } else if (!active && id != '') {
      return true;
    } else if (active && id != '') {
      return false;
    }
  }

  renderErrors(field) {
    if (!_.isEmpty(this.errors) && field in this.errors) {
      return <span>{this.errors[field]}</span>;
    }
    return undefined;
  }

  renderTag() {
    return (
      <Flexbox>
        <Tag className="bp3-intent-success bp3-large">
          .bamboohr.com
        </Tag>
      </Flexbox>
    );
  }

  render() {
    const {
      apiKey,
      subdomain,
      includeProfilePhotos,
      includeDepartments,
      includeManagers,
    } = this.props.BambooIntegrationStore.bambooIntegration;
    const hasErrors = this.props.BambooIntegrationStore.hasErrors;

    return React.cloneElement(this.props.children, {
      handleInputChange: this.handleInputChange.bind(this),
      handleBlurChange: this.handleBlurChange.bind(this),
      renderErrors: this.renderErrors.bind(this),
      renderTag: this.renderTag.bind(this),
      apiKeyVal: apiKey,
      subdomainVal: subdomain,
      includeProfilePhotos: includeProfilePhotos,
      includeDepartments: includeDepartments,
      includeManagers: includeManagers,
      errors: this.errors,
      isDisabled: this.isDisabled,
      hasErrors: hasErrors,
    });
  }
}
