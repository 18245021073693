import React from 'react';
import Flexbox from 'flexbox-react';
import TemplateEditParticipant from './TemplateEditParticipant';
import TemplateEditStatusFlags from './TemplateEditStatusFlags';
import {
  AnchorButton,
  Classes,
  Intent,
  Switch,
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AppStore', 'TemplateStore', 'TemplateActions', 'ToastStore')
@observer
export default class TemplateEditState extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleAllVisibleChange() {
    this.props.TemplateStore.updateEditState(
      !this.props.TemplateStore.editState.allVisible,
      'allVisible'
    );
  }

  handleAllEditableChange() {
    this.props.TemplateStore.updateEditState(
      !this.props.TemplateStore.editState.allEditable,
      'allEditable'
    );
  }

  handleSubmit(e) {
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    e.preventDefault();
    const findParticipant = _.find(
      this.props.TemplateStore.participants,
      o => o.id === this.props.TemplateStore.editParticipant.id
    );
    const findStatusFlag = _.find(
      this.props.TemplateStore.statusFlags,
      o => o.id === this.props.TemplateStore.editStatusFlag.id
    );
    const stateObj = {
      app_id: this.props.AppStore.activeApp.id,
      participant_id: findParticipant.id,
      status_flag_id: findStatusFlag.id,
      id: this.props.TemplateStore.editState.id,
      name: this.props.TemplateStore.editState.name,
      all_visible: this.props.TemplateStore.editState.allVisible,
      all_editable: this.props.TemplateStore.editState.allEditable,
    };
    this.props.TemplateActions.changeState(stateObj, false)
      .then(() => {
        this.toggleLoading();
        this.props.ToastStore.showToast(
          I18n.t('js.changes_saved'),
          'success'
        );
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.TemplateStore.updateEditState(
      e.target.value,
      e.target.name
    );
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  handleCancel() {
    this.props.TemplateStore.editState = _.clone(
      this.props.TemplateStore.activeState
    );
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.TemplateStore.editState[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.TemplateStore.editState[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox
        marginTop="10px"
        marginBottom="20px"
        className="bp3-card"
        flexDirection="column"
        flexGrow={1}
      >
        <Flexbox flexDirection="column" flexGrow={1}>
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Flexbox flexDirection="column" flexGrow={1}>
              <Flexbox flexDirection="column">
                <h3 className={Classes.HEADING}>
                  {I18n.t('js.edit_state')}
                </h3>
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') &&
                    !this.props.TemplateStore.editState.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.state_name')}
                    <span className="bp3-text-muted push-5-l">
                      ({I18n.t('js.required')})
                    </span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') &&
                        !this.props.TemplateStore.editState.nameValid
                          ? classNames(
                              'bp3-input-group bp3-intent-danger'
                            )
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t(
                          'js.enter_a_name_for_your_new_state'
                        )}
                        name="name"
                        value={
                          this.props.TemplateStore.editState.name
                        }
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <TemplateEditParticipant />
              <TemplateEditStatusFlags />
              <Flexbox flexDirection="column">
                <Switch
                  className="bp3-control-no-margin"
                  checked={
                    this.props.TemplateStore.editState.allVisible
                  }
                  label={I18n.t('js.all_visible_description')}
                  onChange={this.handleAllVisibleChange.bind(this)}
                />
              </Flexbox>
              <Flexbox flexDirection="column" marginTop="20px">
                <Switch
                  className="bp3-control-no-margin"
                  checked={
                    this.props.TemplateStore.editState.allEditable
                  }
                  label={I18n.t('js.all_editable_description')}
                  onChange={this.handleAllEditableChange.bind(this)}
                />
              </Flexbox>
            </Flexbox>
            <Flexbox
              flexDirection="row"
              flexGrow={1}
              marginTop="20px"
            >
              <AnchorButton
                intent={Intent.PRIMARY}
                icon="floppy-disk"
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                loading={this.state.isSubmitting}
              />
              <AnchorButton
                text={I18n.t('js.delete_state')}
                disabled={this.props.TemplateStore.states.length <= 1}
                icon="trash"
                className="push-10-l"
                onClick={this.props.toggleDeleting.bind(this)}
              />
              <AnchorButton
                text={I18n.t('js.cancel_changes')}
                icon="cross"
                className="push-10-l"
                onClick={() => this.handleCancel()}
              />
            </Flexbox>
          </form>
        </Flexbox>
      </Flexbox>
    );
  }
}
