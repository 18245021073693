/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import Participant from '../../models/ParticipantModel';
import { Classes, MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

const ParticipantMultiSelect = MultiSelect.ofType(Participant);

@inject('StateRuleStore', 'TemplateStore')
@observer
export default class StateActionItemParticipantSelect extends React.Component {
  @computed
  get selectedParticipants() {
    const recipients = _.filter(
      this.props.StateRuleStore.stateActionEmailRecipients,
      recipient =>
        recipient.stateActionId === this.props.stateAction.id &&
        recipient.changeType !== 'deleted'
    );
    let selectedParticipants = [];
    _.map(recipients, recipient => {
      const findParticipant = _.find(
        this.props.TemplateStore.participants,
        participant => participant.id === recipient.participantId
      );
      if (findParticipant) {
        selectedParticipants = selectedParticipants.concat(
          findParticipant
        );
      }
    });
    return selectedParticipants;
  }

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchParticipants: [],
      isLoading: false,
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  renderParticipant(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(
        Classes.ACTIVE,
        Classes.INTENT_PRIMARY,
        ''
      );
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox />{' '}
            <Flexbox marginTop="2px"> {item.name} </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderTag(participant) {
    return <span id={participant.id}>{participant.name}</span>;
  }

  renderInputValue(participant) {
    return participant.name;
  }

  handleParticipantsSelect(participant) {
    this.props.handleAddRecipientParticipant(participant);
  }

  handlePopoverClose() {
    this.setState({ searchParticipants: [], query: '' });
  }

  handleParticipantRemove(participant) {
    const participantId = participant.props.id;
    this.props.handleRemoveRecipientParticipant(participantId);
  }

  filterParticipant() {
    let possibleParticipants = this.props.TemplateStore.participants;
    // filter out selected participants
    _.map(this.selectedParticipants, participant => {
      possibleParticipants = _.filter(
        possibleParticipants,
        o => o.id !== participant.id
      );
    });
    possibleParticipants = _.orderBy(
      possibleParticipants,
      ['name'],
      ['asc']
    );
    return possibleParticipants;
  }

  renderNoResults() {
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-person push-5-r" />{' '}
            <span> {I18n.t('js.no_participants_found')} </span>
          </span>
        }
      />
    );
  }

  render() {
    return (
      <ParticipantMultiSelect
        resetOnSelect={true}
        items={this.props.TemplateStore.participants}
        itemListPredicate={this.filterParticipant.bind(this)}
        selectedItems={this.selectedParticipants}
        tagRenderer={this.renderTag.bind(this)}
        itemRenderer={this.renderParticipant.bind(this)}
        onItemSelect={this.handleParticipantsSelect.bind(this)}
        tagInputProps={{
          disabled: this.props.disabled,
          leftIcon: 'mugshot',
          inputProps: {
            disabled: this.props.disabled,
            placeholder: I18n.t('js.select_participants'),
          },
          className: 'bp3-tag-input',
          onRemove: this.handleParticipantRemove.bind(this),
        }}
        popoverProps={{
          usePortal: false,
          disabled: this.props.disabled,
          className: '',
          position: 'bottom-left',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
        }}
        noResults={this.renderNoResults()}
      />
    );
  }
}
