import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState } from '@blueprintjs/core';
import DashboardNewsWidget from './DashboardNewsWidget';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore',
  'WidgetStore'
)
@observer
export default class DashboardNews extends React.Component {
  renderNews() {
    const { widgets } = this.props.WidgetStore;
    const orderedWidgets = _.orderBy(widgets, ['updated_at'], ['desc']);
    if (orderedWidgets.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_is_no_company_news_to_show')}
            icon={'add-to-artifact'}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
        {orderedWidgets.map(widget => {
          if (widget.title || widget.content) {
            return <DashboardNewsWidget key={widget.id} widget={widget} />;
          }
        })}
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={0}>
        <p>{I18n.t('js.company_news_description')}</p>
        {this.renderNews()}
      </Flexbox>
    );
  }
}
