/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('CurrentUserStore', 'UserProfileStore', 'ToastStore')
@observer
export default class UserProfileTabs extends React.Component {
  handleTabChange(tab) {
    this.props.UserProfileStore.activeTab = tab;
  }

  handleEditProfileClick() {
    // do something
  }

  renderActivityTitle() {
    return <span>{I18n.t('js.activity')}</span>;
  }

  renderAccountTitle() {
    return <span>{I18n.t('js.profile')}</span>;
  }

  renderItemsTitle() {
    return <span>{I18n.t('js.items')}</span>;
  }

  renderTimelineTitle() {
    return <span>{I18n.t('js.timeline')}</span>;
  }

  renderTeamsTitle() {
    return <span>{I18n.t('js.teams')}</span>;
  }

  renderRelationshipsTitle() {
    return <span>{I18n.t('js.relationships')}</span>;
  }

  renderSettingsTitle() {
    return <span>{I18n.t('js.settings')}</span>;
  }

  renderAccountTab() {
    if (this.props.CurrentUserStore.activeAccount.accountName.length > 0) {
      return <Tab id="account" title={this.renderAccountTitle()} />;
    }
    return undefined;
  }

  renderItemsTab() {
    if (this.props.CurrentUserStore.activeAccount.accountName.length > 0) {
      return <Tab id="items" title={this.renderItemsTitle()} />;
    }
    return undefined;
  }

  renderTimelineTab() {
    if (this.props.CurrentUserStore.activeAccount.accountName.length > 0) {
      return <Tab id="timeline" title={this.renderTimelineTitle()} />;
    }
    return undefined;
  }

  renderActivityTab() {
    if (this.props.CurrentUserStore.activeAccount.accountName.length > 0) {
      return <Tab id="activity" title={this.renderActivityTitle()} />;
    }
    return undefined;
  }

  renderTeamsTab() {
    if (this.props.CurrentUserStore.activeAccount.accountName.length > 0) {
      return <Tab id="teams" title={this.renderTeamsTitle()} />;
    }
    return undefined;
  }

  renderRelationshipsTab() {
    if (this.props.CurrentUserStore.activeAccount.accountName.length > 0) {
      return <Tab id="relationships" title={this.renderRelationshipsTitle()} />;
    }
    return undefined;
  }

  renderEditTab() {
    if (this.props.CurrentUserStore.userSignedIn && this.props.UserProfileStore.isCurrentUser) {
      return <Tab id="settings" title={this.renderSettingsTitle()} />;
    }
    return undefined;
  }

  renderRightLinks() {
    return (
      <Tabs
        id="tabnav"
        animate={true}
        defaultSelectedTabId={this.props.UserProfileStore.activeTab}
        onChange={this.handleTabChange.bind(this)}
        className="noselect"
      >
        {this.renderAccountTab()}
        {this.renderItemsTab()}
        {this.renderTimelineTab()}
        {this.renderTeamsTab()}
        {this.renderRelationshipsTab()}
        {this.renderEditTab()}
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
