/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class Comment {
  @observable id;
  @observable userId;
  @observable body;
  @observable commentableId;
  @observable commentableType;
  @observable commentableReferenceId;
  @observable commentableReferenceType;
  @observable commentCount;
  @observable voteCount;
  @observable pinned;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    userId,
    body,
    commentableId,
    commentableType,
    commentableReferenceId,
    commentableReferenceType,
    commentCount,
    voteCount,
    pinned,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.userId = userId || '';
    this.body = body || '';
    this.commentableId = commentableId || '';
    this.commentableType = commentableType || '';
    this.commentableReferenceId = commentableReferenceId || '';
    this.commentableReferenceType = commentableReferenceType || '';
    this.commentCount = commentCount || 0;
    this.voteCount = voteCount || 0;
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof pinned === 'undefined' || pinned === null ? (this.pinned = false) : (this.pinned = pinned);
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get bodyValid() {
    if (this.body === '') {
      return false;
    }
    return true;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
