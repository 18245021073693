/* Legacy code - ignore this and complexity errors */
/* eslint class-methods-use-this: 0 complexity: 0 */
import React from 'react';
import Utilities from '../utils/Utilities';
import { observer, Provider } from 'mobx-react';
import { Alert, Classes, Intent } from '@blueprintjs/core';
import CurrentUserStore from '../stores/CurrentUserStore';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import AccountUsersStore from '../stores/AccountUsersStore';

@observer
export default class CurrentUserView extends React.Component {
  componentDidMount() {
    if (
      this.props.action === 'account_users' ||
      this.props.action === 'account_relationships' ||
      this.props.action === 'account_settings'
    ) {
      CurrentUserStore.setActiveTab('accountSettings');
    } else if (this.props.action === 'private_team') {
      CurrentUserStore.setActiveTab('teams');
    } else if (
      this.props.action === 'workspace_access_denied' ||
      this.props.action === 'apps' ||
      this.props.action === 'app_access_denied' ||
      this.props.action === 'items' ||
      this.props.action === 'item_access_denied'
    ) {
      CurrentUserStore.setActiveTab('workspaces');
    } else {
      CurrentUserStore.setActiveTab(this.props.action);
    }

    if (!CurrentUserStore.hasUserData) {
      CurrentUserStore.setAxiosAuthorizationHeader();
      CurrentUserStore.setNProgressLoader();
      CurrentUserStore.fetchCurrentUser(this.props.userId);
    }
  }

  handleLeaveAccount() {
    AccountUsersStore.deleteUser(CurrentUserStore.currentUser.id)
      .then(() => {
        CurrentUserStore.toggleIsLeaving();
        Utilities.navigate('/dashboard');
      })
      .catch(() => {
        CurrentUserStore.toggleIsLeaving();
      });
  }

  renderLeaveAccount() {
    if (!CurrentUserStore.isLoading) {
      return (
        <Alert
          portalContainer={document.body}
          isOpen={CurrentUserStore.userIsLeavingAccount}
          cancelButtonText={I18n.t('js.cancel')}
          onCancel={() => CurrentUserStore.toggleIsLeaving()}
          confirmButtonText={I18n.t('js.leave_active_account', {
            accountName: CurrentUserStore.activeAccount.accountName,
          })}
          onConfirm={() => this.handleLeaveAccount()}
          intent={Intent.DANGER}
        >
          <h4 className={Classes.HEADING}>
            {I18n.t('js.are_you_sure')}
          </h4>
          {I18n.t('js.are_you_sure_you_wish_to_leave_this_account', {
            accountName: CurrentUserStore.activeAccount.accountName,
          })}
        </Alert>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      CurrentUserStore,
    };
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <span>{this.renderLeaveAccount()}</span>
        </ErrorBoundary>
      </Provider>
    );
  }
}
