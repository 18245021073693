import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import ToastStore from '../stores/ToastStore';
import { Classes } from '@blueprintjs/core';

@observer
export default class ErrorView extends React.Component {
  renderView() {
    if (this.props.code === '404') {
      return (
        <Flexbox
          flexGrow={1}
          padding="50px"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Flexbox flexDirection="column">
            <div>
              <img
                alt="peoplegoal robot"
                src={I18n.t(`js.pg_robot_24`)}
                className="hero-logo animated rubberBand"
              />
            </div>
            <h1 className={Classes.HEADING}>
              {I18n.t('js.page_not_found')}
            </h1>
            <p className="bp3-running-text push-10-t">
              {I18n.t('js.this_is_not_what_you_were_looking_for')}
            </p>
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexGrow={1}
        padding="50px"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <Flexbox flexDirection="column">
          <div>
            <img
              alt="peoplegoal robot"
              src={I18n.t(`js.pg_robot_24`)}
              className="hero-logo animated rubberBand"
            />
          </div>
          <h1 className={Classes.HEADING}>
            {I18n.t('js.something_went_wrong')}
          </h1>
          <p className="bp3-running-text push-10-t">
            {I18n.t('js.we_have_been_notified')}
          </p>
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    const stores = {
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <Flexbox
          flexGrow={1}
          width="100hw"
          alignContent="stretch"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop="50px"
          marginBottom="50px"
          paddingLeft="30px"
          paddingRight="30px"
        >
          <Flexbox
            className="fixed-width-container bp3-card"
            flexDirection="row"
            alignSelf="center"
          >
            {this.renderView()}
          </Flexbox>
        </Flexbox>
      </Provider>
    );
  }
}
