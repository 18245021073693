/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent, MenuItem, NonIdealState, Spinner } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import StatusFlag from '../../models/StatusFlagModel';
import classNames from 'classnames';
import _ from 'lodash';

const EditStatusFlagSelect = Select.ofType(StatusFlag);

@inject('ItemStore', 'ItemActions', 'AppStore')
@observer
export default class ItemEditStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.ItemStore.activeEditSection === 'status') {
      this.setState({ isOpen: true });
    }
  }

  toggleOpen() {
    if (!this.state.isOpen) {
      this.loadData();
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleSubmitting() {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  loadData() {
    this.toggleLoading();
    this.props.ItemActions.fetchStatusFlags(this.props.ItemStore.item.appId)
      .then(response => {
        _.map(response.data, statusFlag => {
          this.props.ItemStore.addStatusFlag(statusFlag);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    const newObj = {
      id: this.props.ItemStore.item.id,
      status_flag_id: this.props.ItemStore.newItem.statusFlagId,
    };
    this.props.ItemActions.changeItem(newObj)
      .then(response => {
        this.props.ItemStore.addItem(response.data);
        this.toggleSubmitting();
        this.toggleOpen();
        if (this.props.ItemStore.activeEditSection === 'status') {
          this.props.ItemStore.activeTab = 'details';
          this.props.ItemStore.activeEditSection = null;
        }
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  handleCancel() {
    this.props.ItemStore.resetItemAttribute('statusFlagId');
    this.toggleOpen();
    if (this.props.ItemStore.activeEditSection === 'status') {
      this.props.ItemStore.activeTab = 'details';
      this.props.ItemStore.activeEditSection = null;
    }
  }

  renderStatusFlagText(item) {
    return (
      <Flexbox>
        <span
          className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-10-r"
          style={{
            marginTop: '5px',
            color: item.color,
          }}
        />
        {item.name}
      </Flexbox>
    );
  }

  renderStatusFlag(item, details) {
    let menuClass = classNames('popover-larger');
    if (item.id === this.props.ItemStore.item.statusFlagId) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }
    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={this.renderStatusFlagText(item, details)} />;
  }

  renderInputValue(statusFlag) {
    return statusFlag.name;
  }

  handleItemSelect(statusFlag) {
    this.props.ItemStore.newItem.statusFlagId = statusFlag.id;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderButtonText() {
    const findActiveStatusFlag = _.find(this.props.ItemStore.statusFlags, o => o.id === this.props.ItemStore.newItem.statusFlagId);
    if (findActiveStatusFlag) {
      return (
        <Flexbox flexGrow={1}>
          <span
            className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-10-r"
            style={{
              marginTop: '2px',
              color: findActiveStatusFlag.color,
            }}
          />
          {findActiveStatusFlag.name}
          <Flexbox marginLeft="10px" marginTop="2px" marginRight="5px">
            <span className="bp3-icon bp3-icon-caret-down push-5-l" />
          </Flexbox>
        </Flexbox>
      );
    }
    return <Flexbox flexGrow={1}>{I18n.t('js.select_status_flag')}</Flexbox>;
  }

  renderContent() {
    if (this.state.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="20px" paddingBottom="20px">
          <NonIdealState
            title={I18n.t('js.fetching_data')}
            description={I18n.t('js.please_wait')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column" flexGrow={1}>
            <div className="bp3-form-group">
              <div className="bp3-form-content">
                <EditStatusFlagSelect
                  resetOnSelect={true}
                  filterable={false}
                  items={this.props.ItemStore.statusFlags}
                  itemRenderer={this.renderStatusFlag.bind(this)}
                  onItemSelect={this.handleItemSelect.bind(this)}
                  inputValueRenderer={this.renderInputValue.bind(this)}
                  inputProps={{
                    rightElement: this.renderDropDown(),
                  }}
                  popoverProps={{
                    usePortal: false,
                    position: 'bottom-left',
                    className: '',
                    popoverClassName: 'bp3-minimal',
                  }}
                >
                  <Button text={this.renderButtonText()} disabled={this.props.ItemStore.statusFlags.length === 0} />
                </EditStatusFlagSelect>
              </div>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox>
          <div>
            <Button
              intent={Intent.PRIMARY}
              type="submit"
              disabled={false}
              text={I18n.t('js.save_changes')}
              loading={this.state.isSubmitting}
              icon="tick"
            />
            <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
          </div>
        </Flexbox>
      </form>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* ignore jsx-a11y as toggle functionality included eslewhere for */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.item_status_flag', {
                item: this.props.AppStore.activeApp.item,
              })}
            </h4>
            <p className="noselect">
              {I18n.t('js.change_item_status_text', {
                item: this.props.AppStore.activeApp.item,
              })}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>{this.renderContent()}</Collapse>
      </Flexbox>
    );
  }
}
