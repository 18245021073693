import React from 'react';
import Flexbox from 'flexbox-react';
import Cookies from 'js-cookie';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Button,
  ButtonGroup,
  NonIdealState,
  Tooltip,
  Position,
  Spinner,
} from '@blueprintjs/core';
import AppItemsNewItem from './AppItemsNewItem';
import AppItemsFilter from './AppItemsFilter';
import AppItemsListRow from './AppItemsListRow';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import ItemWidget from '../items/ItemWidget';
import Paginator from '../shared/Paginator';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AppStore', 'AppItemStore', 'ItemActions')
@observer
export default class AppItemsListItems extends React.Component {
  @observable
  isSearching = false;
  @observable
  viewType = 'list';

  toggleSearching() {
    this.isSearching = !this.isSearching;
  }

  toggleViewType() {
    if (this.viewType === 'list') {
      Cookies.set('itemViewType', 'widget');
      this.viewType = 'widget';
    } else {
      Cookies.set('itemViewType', 'list');
      this.viewType = 'list';
    }
  }

  componentDidMount() {
    if (Cookies.get('itemViewType')) {
      this.viewType = Cookies.get('itemViewType');
    } else {
      const paginationMax = 10;
      if (this.props.AppItemStore.pagination.xTotal > paginationMax) {
        this.viewType = 'list';
      }
    }
  }

  handleColumnSort(column) {
    this.props.AppItemStore.setItemSortParams(
      column.id,
      column.sortDir
    );
  }

  handlePageChange(pageNumber) {
    this.toggleSearching();
    this.props.AppItemStore.resetStartingAttributes();
    this.props.ItemActions.fetchAppItems(
      this.props.AppStore.activeApp.id,
      pageNumber,
      this.props.AppItemStore.pagination.xPerPage,
      this.props.AppItemStore.pagination.xOffset
    )
      .then(response => {
        this.props.AppItemStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.AppItemStore.addItem(item);
        });
        this.toggleSearching();
      })
      .catch(() => {
        this.toggleSearching();
      });
  }

  handlePerPageChange(e) {
    this.toggleSearching();
    this.props.AppItemStore.resetStartingAttributes();
    const appNum = 1;
    this.props.ItemActions.fetchAppItems(
      this.props.AppStore.activeApp.id,
      appNum,
      e.target.value,
      this.props.AppItemStore.pagination.xOffset
    )
      .then(response => {
        this.props.AppItemStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.AppItemStore.addItem(item);
        });
        this.toggleSearching();
      })
      .catch(() => {
        this.toggleSearching();
      });
  }

  renderPagination() {
    if (
      this.props.AppItemStore.pagination.xTotal >
      this.props.AppItemStore.pagination.xPerPage
    ) {
      return (
        <Paginator
          paginationObject={this.props.AppItemStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderItemView() {
    if (this.viewType === 'list') {
      return (
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader
              columns={this.props.AppItemStore.itemColumns}
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          <tbody>
            {_.map(this.props.AppItemStore.items, item => (
              <AppItemsListRow item={item} key={item.id} />
            ))}
          </tbody>
        </table>
      );
    }
    return (
      <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
        {_.map(this.props.AppItemStore.items, item => (
          <ItemWidget item={item} key={item.id} />
        ))}
      </Flexbox>
    );
  }

  renderItems() {
    if (this.isSearching) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.AppItemStore.items.length > 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} marginTop="20px">
          <Flexbox>{this.renderItemView()}</Flexbox>
          <Flexbox flexGrow={1} justifyContent="center">
            {this.renderPagination()}
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderNoItems() {
    if (
      this.props.AppItemStore.items.length === 0 &&
      !this.isSearching
    ) {
      const iconClass = classNames(
        'bp3-text-muted',
        'fa fa-icon-large',
        this.props.AppStore.activeApp.icon
      );
      return (
        <Flexbox
          key={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          paddingTop="50px"
          paddingBottom="50px"
          marginTop="20px"
          className="bp3-card"
        >
          <Flexbox padding="20px">
            <span className={iconClass} />
          </Flexbox>
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_items_to_show', {
              items: this.props.AppStore.activeApp.itemPlural,
            })}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderTotal() {
    if (this.props.AppItemStore.pagination.xTotal > 0) {
      return (
        <span>({this.props.AppItemStore.pagination.xTotal})</span>
      );
    }
    if (this.props.AppItemStore.items.length > 0) {
      return <span>({this.props.AppItemStore.items.length})</span>;
    }
    return undefined;
  }

  renderViewToggle() {
    return (
      <Flexbox flexGrow={0} flexDirection="column">
        <ButtonGroup minimal={false} large={false}>
          <Tooltip
            content={I18n.t('js.list_view')}
            position={Position.TOP}
          >
            <Button
              icon="properties"
              active={this.viewType === 'list'}
              onClick={this.toggleViewType.bind(this)}
            />
          </Tooltip>
          <Tooltip
            content={I18n.t('js.widget_view')}
            position={Position.TOP}
          >
            <Button
              icon="widget-button"
              active={this.viewType === 'widget'}
              onClick={this.toggleViewType.bind(this)}
            />
          </Tooltip>
        </ButtonGroup>
      </Flexbox>
    );
  }

  renderHeader() {
    if (this.props.showHeader) {
      return (
        <Flexbox flexGrow={1}>
          <AppItemsNewItem />
          <Flexbox flexGrow={1} marginRight="10px">
            <AppItemsFilter
              toggleSearching={this.toggleSearching.bind(this)}
            />
          </Flexbox>
          {this.renderViewToggle()}
        </Flexbox>
      );
    }
    return null;
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexDirection="column" flexGrow={1}>
            {this.renderHeader()}
            {this.renderItems()}
          </Flexbox>
        </Flexbox>
        {this.renderNoItems()}
      </Flexbox>
    );
  }
}
