import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, FormGroup, Intent } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import Utilities from '../../utils/Utilities';
import nanoid from 'nanoid';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementImageUpload extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  newImage = '';
  @observable
  displayedImage = '';
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      if (this.props.element.formValues[0].imageFileName.length > 0) {
        this.displayedImage = this.props.element.formValues[0].imageFileName;
        this.props.validateInput(this.displayedImage);
      } else {
        this.props.validateInput(this.newImage);
      }
    } else {
      this.props.validateInput(this.newImage);
    }
    this.mountDisplayValuesInFlight();
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue instanceof FormData) {
        if (formValue.get('element_id') === this.props.element.id) {
          this.newImage = formValue.get('image');
          this.displayedImage = formValue.get('image').preview;
        }
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        this.newImage = findFormValueToChange.image;
        this.displayedImage = findFormValueToChange.image.preview;
      }
    });
  }

  setStoreAndActions() {
    this.newId = nanoid();
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.append('user_id', this.props.UserProfileStore.user.id);
        object.append('profile_template_id', this.props.UserProfileTemplateStore.activeProfileTemplate.id);
        object.append('profile_value_type', 'image');
        return object;
      default:
        object.append('item_id', this.store.item.id);
        object.append('item_value_type', 'image');
        return object;
    }
  }

  handleDrop(images) {
    this.newImage = images[0];
    this.displayedImage = images[0].preview;
    if (this.actionType === 'updating') {
      const changedFormValue = {
        id: this.props.element.formValues[0].id,
        image: this.newImage,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      let newFormValue = new FormData();
      newFormValue.append('id', this.newId);
      newFormValue.append('element_id', this.props.element.id);
      newFormValue.append('image', this.newImage);
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
    }
    this.props.validateInput(this.newImage);
  }

  handleRemoveImage() {
    this.newImage = '';
    this.displayedImage = '';
    const changedFormValue = {
      id: this.props.element.formValues[0].id,
      image: '',
    };
    this.store.addFormValueToChange(changedFormValue);
    this.props.validateInput(this.newImage);
  }

  renderSelectImageText() {
    if (typeof this.newImage !== 'object') {
      return <span>{I18n.t('js.select_image')}</span>;
    }
    return <span>{this.newImage.name}</span>;
  }

  renderImageDisplay() {
    if (this.displayedImage.length > 0) {
      return (
        <Flexbox flexGrow={1} justifyContent="flex-start" marginBottom="10px">
          <img alt="element" className="element-image" src={this.displayedImage} />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderRemoveImage() {
    if (this.newImage.length > 0 || this.displayedImage.length > 0) {
      return (
        <Button className="push-10-l" icon="trash" onClick={this.handleRemoveImage.bind(this)}>
          {I18n.t('js.remove_image')}
        </Button>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          label={this.props.element.elementImageUpload.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          {this.renderImageDisplay()}
          <Flexbox>
            <Dropzone onDrop={this.handleDrop.bind(this)} className="bp3-button bp3-icon-media">
              {this.renderSelectImageText()}
            </Dropzone>
            {this.renderRemoveImage()}
          </Flexbox>
        </FormGroup>
      </Flexbox>
    );
  }
}
