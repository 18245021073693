import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import ElementIdSelector from '../../shared/ElementIdSelector';
import { EditableText, Intent } from '@blueprintjs/core';

@inject('AppStore', 'UserProfileTemplateStore', 'TemplateStore', 'TemplateActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class ElementDescription extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = {};
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  handleChange(value) {
    this.props.element.elementDescription.name = value;
  }

  handleConfirm() {
    const elementObj = {
      id: this.props.element.id,
    };
    const elementDescriptionObj = {
      id: this.props.element.elementDescription.id,
      name: this.props.element.elementDescription.name,
    };
    this.props.TemplateActions.changeSubElement(elementDescriptionObj, 'element_descriptions').then(() => {
      this.props.TemplateActions.changeElement(elementObj);
      this.store.setActiveArea(null);
    });
  }

  render() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap" paddingTop="10px" paddingBottom="10px">
            <EditableText
              multiline="multiline"
              confirmOnEnterKey={true}
              isEditing={true}
              defaultValue={this.props.element.elementDescription.name}
              intent={Intent.DEFAULT}
              onChange={value => this.handleChange(value)}
              value={this.props.element.elementDescription.name}
              onConfirm={this.handleConfirm.bind(this)}
              placeholder={I18n.t('js.click_to_edit')}
            />
          </Flexbox>
          <ElementIdSelector id={this.props.element.id} />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap" paddingTop="10px">
          <p className="pre bp3-running-text">{this.props.element.elementDescription.name}</p>
        </Flexbox>
      </Flexbox>
    );
  }
}
