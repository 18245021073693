/* Legacy code - ignore this and complexity errors */
/* eslint complexity: 0 class-methods-use-this: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class User {
  @observable id;
  @observable email;
  @observable firstName;
  @observable lastName;
  @observable fullName;
  @observable accountId;
  @observable humanFriendlyId;

  constructor(
    id,
    email,
    firstName,
    lastName,
    fullName,
    accountId,
    humanFriendlyId,
    isCurrentUser,
    isAccountOwner,
    imageFileName,
    backgroundImageFileName,
    lastSignInAt,
    locale,
    theme,
    createdAt,
    updatedAt,
    deletedAt,
    createdBy,
    updatedBy,
    deletedBy
  ) {
    this.accountId = accountId || '';
    this.backgroundImageFileName = backgroundImageFileName || '';
    this.email = email || '';
    this.firstName = firstName || '';
    this.fullName = fullName || '';
    this.humanFriendlyId = humanFriendlyId || '';
    this.id = id || '';
    this.imageFileName = imageFileName || '';
    this.isCurrentUser = isCurrentUser || '';
    this.isAccountOwner = isAccountOwner || '';
    this.lastName = lastName || '';
    this.locale = locale || '';
    this.theme = theme || '';
    this.lastSignInAt = lastSignInAt || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get isTeamOwner() {
    return false;
  }

  @computed
  get firstNameValid() {
    if (this.firstName !== '') {
      return true;
    }
    return false;
  }

  @computed
  get firstNameError() {
    if (this.firstName !== '') {
      return null;
    }
    return I18n.t('js.first_name_is_required');
  }

  @computed
  get lastNameValid() {
    if (this.lastName !== '') {
      return true;
    }
    return false;
  }

  @computed
  get lastNameError() {
    if (this.lastName !== '') {
      return null;
    }
    return I18n.t('js.last_name_is_required');
  }

  @computed
  get nameValid() {
    if (this.firstNameValid && this.lastNameValid) {
      return true;
    }
    return false;
  }

  @computed
  get emailValid() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email !== '' && exp.test(this.email)) {
      return true;
    }
    return false;
  }

  @computed
  get emailError() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email === '') {
      return I18n.t('js.email_is_required');
    }
    if (!exp.test(this.email)) {
      return I18n.t('js.email_is_invalid');
    }
    return null;
  }

  @computed
  get lastSignInAtToDate() {
    if (this.lastSignInAt === '') {
      return 'N/A';
    }
    return moment.utc(this.lastSignInAt).format('MMMM Do YYYY, h:mm:ss a');
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
