import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import HookRow from './HookRow';
import { inject, observer } from 'mobx-react';

@inject('CurrentUserStore', 'HookStore', 'ToastStore')
@observer
export default class HookList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.props.HookStore.fetchHooks()
      .then(response => {
        this.setState({ isLoading: false });
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  handleColumnSort(column) {
    this.props.HookStore.setHookSortParams(column.id, column.sortDir);
  }

  renderHooks() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.HookStore.hooks.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_webhooks_to_show')}
            icon="flows"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader columns={this.props.HookStore.hookColumns} handleColumnSort={this.handleColumnSort.bind(this)} />
          </thead>
          <tbody>
            {_.map(this.props.HookStore.hooks, hook => (
              <HookRow hook={hook} key={hook.id} />
            ))}
          </tbody>
        </table>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" className="bp3-card" marginTop="20px" flexGrow={1}>
        {this.renderHooks()}
      </Flexbox>
    );
  }
}
