import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Button, Classes } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import Utilities from '../utils/Utilities';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class SetupVerifyEmailView extends React.Component {
  @observable
  isLoading = false;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleSignIn() {
    this.toggleLoading();
    Utilities.navigate('/sign-in');
  }

  render() {
    const stores = {
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox
            flexGrow={1}
            width="100hw"
            alignContent="stretch"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop="50px"
            marginBottom="50px"
          >
            <Flexbox
              className="fixed-width-container bp3-card"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding="0px"
            >
              <Flexbox
                className="fixed-width-container bp3-callout bp3-intent-primary bp3-icon-info-sign"
                marginBottom="20px"
                flexDirection="column"
              >
                <h4
                  className={`${Classes.HEADING} bp3-callout-title`}
                >
                  {I18n.t('js.email_verification_required')}
                </h4>
                {I18n.t(
                  'js.an_email_verification_has_been_sent_to_you'
                )}
              </Flexbox>
              <Flexbox
                padding="40px"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <h3 className={Classes.HEADING}>
                  {I18n.t(
                    'js.please_verify_your_email_or_close_window'
                  )}
                </h3>
                <Button
                  type="button"
                  className="bp3-icon-log-in bp3-large bp3-intent-primary push-20-t"
                  text={I18n.t('js.sign_in')}
                  onClick={this.handleSignIn.bind(this)}
                  loading={this.isLoading}
                />
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
