import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Spinner } from '@blueprintjs/core';
import StoreTemplateEditStatus from './StoreTemplateEditStatus';
import StoreTemplateEditDetails from './StoreTemplateEditDetails';
import StoreTemplateEditImage from './StoreTemplateEditImage';
import StoreTemplateEditStyle from './StoreTemplateEditStyle';
import StoreTemplateEditStoreCategories from './StoreTemplateEditStoreCategories';
import StoreTemplateDeleteStoreTemplate from './StoreTemplateDeleteStoreTemplate';
import _ from 'lodash';

@inject('AppStore', 'WorkspaceStore', 'StoreTemplateActions', 'StoreTemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreTemplateEditStoreTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  componentDidMount() {
    this.props.StoreTemplateStore.newStoreTemplate = _.clone(this.props.StoreTemplateStore.activeStoreTemplate);
    if (this.props.StoreTemplateStore.storeCategories.length === 0) {
      this.props.StoreTemplateActions.fetchStoreCategories().then(response => {
        _.map(response.items, item => {
          this.props.StoreTemplateStore.addStoreCategory(item);
        });
      });
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" marginTop="10px">
        <StoreTemplateEditDetails />
        <StoreTemplateEditStoreCategories />
        <StoreTemplateEditImage />
        <StoreTemplateEditStyle />
        <StoreTemplateEditStatus />
        <StoreTemplateDeleteStoreTemplate />
      </Flexbox>
    );
  }
}
