/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Utilities from '../../utils/Utilities';
import CurrentUserStore from '../../stores/CurrentUserStore';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Button, Popover, PopoverInteractionKind, Spinner } from '@blueprintjs/core';
import LocaleSelector from './LocaleSelector';
import Flexbox from 'flexbox-react';

@observer
export default class AppCopyrightBar extends React.Component {
  renderLocaleSelector() {
    if (typeof CurrentUserStore.activeLanguage === 'object') {
      return (
        <Flexbox flexDirection="row" marginRight="20px">
          <Button className="bp3-button bp3-minimal">
            <Popover
              portalContainer={document.body}
              content={<LocaleSelector />}
              interactionKind={PopoverInteractionKind.CLICK}
              inheritDarkTheme={false}
              position="top"
            >
              <Flexbox flexDirection="row" alignItems="center">
                <img alt="flag icon" className="flag-icon" src={`${I18n.t('js.flag_path')}${CurrentUserStore.activeLanguage.code}.png`} />
                <span className="">{CurrentUserStore.activeLanguage.name}</span>
                <span className="bp3-icon-standard bp3-icon-caret-down bp3-align-right" />
              </Flexbox>
            </Popover>
          </Button>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row">
        <Spinner size={20} className="push-10-t" />
      </Flexbox>
    );
  }

  render() {
    let footerClass = classNames('copyright-bar');
    let linkClass = classNames('');
    return (
      <Flexbox flexDirection="row" flexGrow={1} className={footerClass} alignContent="center" paddingRight="10px">
        <Flexbox
          flexGrow={1}
          flexDirection="row"
          justifyContent="space-between"
          className="fixed-width-container"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <Flexbox flexGrow={1} className="bp3-text-muted" alignContent="center" alignItems="center">
            <Flexbox>
              <small>{I18n.t('js.or_copyright')}</small>
            </Flexbox>
          </Flexbox>
          <Flexbox flexGrow={0} alignItems="center" alignContent="center">
            <a href="https://www.peoplegoal.com/support" className={linkClass}>
              <small>{I18n.t('js.support')}</small>
            </a>
            <span className="bp3-text-muted push-5-l push-5-r" />
            <a href="https://developers.peoplegoal.com" target="_blank" className={linkClass}>
              <small>{I18n.t('js.developers')}</small>
            </a>
            <span className="bp3-text-muted push-5-l push-5-r" />
            <a href="https://www.peoplegoal.com/blog" className={linkClass}>
              <small>{I18n.t('js.blog')}</small>
            </a>
            <span className="bp3-text-muted push-5-l push-5-r" />
            <a href="https://www.peoplegoal.com/app-store" className={linkClass}>
              <small>{I18n.t('js.store')}</small>
            </a>
            <span className="bp3-text-muted push-5-l push-5-r" />
            <a href="https://www.peoplegoal.com/terms" className={linkClass}>
              <small>{I18n.t('js.terms_and_conditions')}</small>
            </a>
            <span className="bp3-text-muted push-5-l push-5-r" />
            {this.renderLocaleSelector()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
