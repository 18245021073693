// @flow
import * as React from 'react';
import Avatar from 'react-avatar';

type AccountIconProps = {
  imgSrc: string,
};

const AccountIcon = ({ imgSrc }: AccountIconProps) => (
  <Avatar src={imgSrc} size={22} round={true} className="push-10-r" />
);

export default AccountIcon;
