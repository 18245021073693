// @flow
import * as React from 'react';
import Flexbox from 'flexbox-react';
import { AnchorButton, Classes, Tag } from '@blueprintjs/core';

type HeroProps = {
  ctaDescription: string,
  ctaLink: string,
  description: string,
  icon: string,
  tagline: string,
};

const Hero = ({
  ctaDescription,
  ctaLink,
  description,
  icon,
  tagline,
}: HeroProps) => (
  <Flexbox flexDirection="column" className="animated zoomIn">
    <h1
      className={`her ${Classes.HEADING}`}
      style={{
        fontSize: '60px',
        marginBottom: '20px',
        lineHeight: '60px',
      }}
    >
      {/* $FlowFixMe */}
      {tagline}
    </h1>
    <p style={{ fontSize: '30px' }}>{description}</p>
    <Flexbox marginTop="10px" className="bp3-dark">
      <AnchorButton
        className="bp3-intent-success bp3-large"
        icon={icon}
        href={ctaLink}
      >
        {ctaDescription}
      </AnchorButton>
    </Flexbox>
    <Flexbox marginTop="20px">
      <Tag className="push-10-r bp3-intent-success text-white">
        Alpha Version{' '}
      </Tag>
      {/* $FlowFixMe */}
      <small>{I18n.t('js.peoplegoal_placeholder_text')}</small>
    </Flexbox>
  </Flexbox>
);

Hero.defaultProps = {
  icon: 'endorsed',
};

export default Hero;
