/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { NonIdealState, Button } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Graph from 'react-vis-network-graph';
import { Tabs, Tab, Classes, Tree } from '@blueprintjs/core';
import TeamsNewTeam from './TeamsNewTeam';
import TeamsGraphToolbar from './TeamsGraphToolbar';
import TeamsTeamSelect from './TeamsTeamSelect';
import Utilities from '../../utils/Utilities';

@inject('TeamsStore', 'ToastStore', 'CurrentUserStore')
@observer
export default class TeamsShowTeams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isImportingTeams: false,
    };
  }

  toggleImportingTeams() {
    if (this.props.CurrentUserStore.userIsAccountOwner) {
      this.setState({
        isImportingTeams: !this.state.isImportingTeams,
      });
      Utilities.navigate('/teams/import-data');
    }
  }

  handleNodeClick(nodeData) {
    if (nodeData.nodeType === 'team') {
      const teamId = nodeData.id;
      Utilities.navigate(`/teams/${teamId}`);
    }
    if (nodeData.nodeType === 'category') {
      const category = nodeData.id;
      this.props.TeamsStore.toggleTeam(category);
    }
  }

  handleNodeCollapse(nodeData) {
    const teamId = nodeData.id;
    this.props.TeamsStore.toggleTeam(teamId);
  }

  handleNodeExpand(nodeData) {
    const teamId = nodeData.id;
    this.props.TeamsStore.toggleTeam(teamId);
  }

  handleTabChange(tab) {
    this.props.TeamsStore.activeSidebarTab = tab;
  }

  componentDidUpdate() {
    this.setInitialSelection();
  }

  componentDidMount() {
    this.setInitialSelection();
  }

  setInitialSelection() {
    if (this.network) {
      if (this.props.TeamsStore.selectedTeamId != '') {
        const id = this.props.TeamsStore.selectedTeamId;
        this.network.setSelection({
          nodes: [id],
        });
        const timeoutDuration = 100; // ms
        setTimeout(() => {
          const positions = this.network.getPositions([id]);
          const options = {
            position: {
              x: positions[id].x,
              y: positions[id].y,
            },
            scale: 1,
            animation: true,
          };
          this.network.moveTo(options);
        }, timeoutDuration);
      } else {
        this.network.unselectAll();
      }
    }
  }

  handleTeamSelect(event) {
    this.props.TeamsStore.selectedTeamId = event.nodes[0];
    const timeoutDuration = 100; // ms
    setTimeout(() => {
      this.network.setSelection({
        nodes: [event.nodes[0]],
      });
      const positions = this.network.getPositions([event.nodes[0]]);
      const options = {
        position: {
          x: positions[event.nodes[0]].x,
          y: positions[event.nodes[0]].y,
        },
        scale: 1,
        animation: true,
      };
      this.network.moveTo(options);
    }, timeoutDuration);
  }

  handleTeamNavigate(event) {
    Utilities.navigate(`/teams/${event.nodes[0]}`);
  }

  setNetworkInstance(nw) {
    this.network = nw;
  }

  renderChart() {
    const graph = {
      nodes: this.props.TeamsStore.teamNodes.slice(),
      edges: this.props.TeamsStore.teamRelationshipNodes.slice(),
    };

    const options = {
      layout: {
        randomSeed: 1,
        hierarchical: {
          enabled: this.props.TeamsStore.teamGraph.hierarchical,
          levelSeparation: this.props.TeamsStore.teamGraph.levelSeparation,
          nodeSpacing: this.props.TeamsStore.teamGraph.nodeSpacing,
          direction: this.props.TeamsStore.teamGraph.direction,
          sortMethod: this.props.TeamsStore.teamGraph.sortMethod,
        },
      },
      interaction: {
        hover: true,
      },
      physics: this.props.TeamsStore.teamGraph.physics,
    };

    const events = {
      select: event => {
        if (event.nodes.length > 0) {
          this.handleTeamSelect(event);
        }
      },
      doubleClick: event => {
        if (event.nodes.length > 0) {
          this.handleTeamNavigate(event);
        }
      },
    };

    return (
      <Flexbox flexDirection="column" className="bp3-cursor-pointer" flexGrow={1} className="bp3-card">
        <Graph
          graph={graph}
          getNetwork={this.setNetworkInstance.bind(this)}
          options={options}
          events={events}
          style={{
            width: '100%',
            height: '800px',
          }}
        />
      </Flexbox>
    );
  }

  renderOption(team) {
    return (
      <Flexbox flexDirection="row" alignContent="center">
        <Flexbox>
          <Avatar src={team.imageFileName} size={22} round={true} className="" />
        </Flexbox>
        <Flexbox marginLeft="5px">{team.name}</Flexbox>
      </Flexbox>
    );
  }

  handleTeamSelectChange(team) {
    this.props.TeamsStore.selectedTeamId = team.id;
    if (this.network) {
      this.network.selectNodes([team.id]);
      const timeoutDuration = 500; // ms
      setTimeout(() => {
        const positions = this.network.getPositions([team.id]);
        const options = {
          position: {
            x: positions[team.id].x,
            y: positions[team.id].y,
          },
          scale: 1.2,
          animation: true,
        };
        this.network.moveTo(options);
      }, timeoutDuration);
    }
  }

  renderSearch() {
    return <TeamsTeamSelect handleTeamSelectChange={this.handleTeamSelectChange.bind(this)} />;
  }

  renderImportButton() {
    return (
      <Flexbox>
        <Button
          text={I18n.t('js.import_teams')}
          icon="cloud-upload"
          className="push-10-l"
          disabled={!this.props.CurrentUserStore.userIsAccountOwner}
          loading={this.state.isImportingTeams}
          onClick={this.toggleImportingTeams.bind(this)}
        />
      </Flexbox>
    );
  }

  renderTeams() {
    if (this.props.TeamsStore.activeSidebarTab === 'list') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexDirection="column" className="bp3-card bp3-card-no-padding">
            <Tree
              contents={this.props.TeamsStore.teamTree}
              onNodeClick={this.handleNodeClick.bind(this)}
              onNodeCollapse={this.handleNodeCollapse.bind(this)}
              onNodeExpand={this.handleNodeExpand.bind(this)}
              className={Classes.ELEVATION_0}
            />
          </Flexbox>
        </Flexbox>
      );
    }
    if (this.props.TeamsStore.activeSidebarTab === 'network') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexDirection="column" className="bp3-cursor-pointer chart-area" flexGrow={1}>
            {this.renderChart()}
          </Flexbox>
          <Flexbox marginTop="10px">
            <TeamsGraphToolbar handleTeamSelectChange={team => this.handleTeamSelectChange(team)} />
          </Flexbox>
        </Flexbox>
      );
    }
  }

  render() {
    if (this.props.TeamsStore.teams.length === 0) {
      return (
        <Flexbox flexDirection="column">
          <TeamsNewTeam handleTeamSelectChange={team => this.handleTeamSelectChange(team)} />
          <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px" className="bp3-card">
            <NonIdealState
              title={I18n.t('js.nothing_to_show')}
              description={I18n.t('js.there_are_no_teams_to_show')}
              icon="graph"
              className="bp3-text-muted"
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="column">
          <Flexbox marginBottom="20px" flexDirection="row" flexGrow={1}>
            <TeamsNewTeam handleTeamSelectChange={team => this.handleTeamSelectChange(team)} />
            <Flexbox flexGrow={1}>{this.renderSearch()}</Flexbox>
            {this.renderImportButton()}
          </Flexbox>
          <Flexbox flexDirection="row" flexGrow={1}>
            <Flexbox flexDirection="column" flexGrow={0} marginRight="20px">
              <Tabs
                id="teamSidebar"
                animate={true}
                vertical={true}
                defaultSelectedTabId={this.props.TeamsStore.activeSidebarTab}
                onChange={this.handleTabChange.bind(this)}
                className="noselect"
              >
                <Tab id="list" title={I18n.t('js.list')} />
                <Tab id="network" title={I18n.t('js.network')} />
                <Tabs.Expander />
              </Tabs>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={1}>
              {this.renderTeams()}
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
