import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, NonIdealState, Spinner } from '@blueprintjs/core';
import AdminAccountDomainRow from './AdminAccountDomainRow';
import AdminNewAccountDomain from './AdminNewAccountDomain';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import _ from 'lodash';

@inject('AdminActions', 'AdminStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AdminAccountDomains extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleColumnSort(column) {
    this.props.AdminStore.setAccountDomainSortParams(column.id, column.sortDir);
  }

  componentDidMount() {
    this.props.AdminActions.fetchAccountDomains()
      .then(response => {
        _.map(response.data, accountDomain => {
          this.props.AdminStore.addAccountDomain(accountDomain);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderAccountDomain() {
    if (this.props.AdminStore.accountDomains.length > 0) {
      return (
        <Flexbox flexGrow={1} marginTop="20px">
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <SimpleTableHeader columns={this.props.AdminStore.accountDomainColumns} handleColumnSort={this.handleColumnSort.bind(this)} />
            </thead>
            <tbody>
              {_.map(this.props.AdminStore.accountDomains, accountDomain => (
                <AdminAccountDomainRow accountDomain={accountDomain} key={accountDomain.id} />
              ))}
            </tbody>
          </table>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexdirection="row" flexGrow={1} paddingTop="50px" paddingBottom="50px">
        <NonIdealState
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.there_is_nothing_to_show_right_now')}
          icon="git-repo"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }

  render() {
    if (this.isLoading) {
      return (
        <Flexbox flexdirection="row" className="bp3-card" flexGrow={1} paddingTop="50px" paddingBottom="50px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" className="bp3-card" flexGrow={1}>
        <h3 className={Classes.HEADING}>Account domains</h3>
        <p>Add account domains here. Note, the default is v2. You only need to add account domains that need v1 or all access here.</p>
        <AdminNewAccountDomain /> {this.renderAccountDomain()}
        <Flexbox flexGrow={1} justifyContent="center">
          {/* {this.renderPagination()} */}
        </Flexbox>
      </Flexbox>
    );
  }
}
