import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Classes,
  Collapse,
  Intent,
  Switch,
} from '@blueprintjs/core';

@inject('CurrentUserStore', 'WorkspaceStore', 'ToastStore')
@observer
export default class WorkspaceEditPrivacy extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isOpen: false };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    const newObj = {
      status: this.props.WorkspaceStore.newWorkspace.status,
    };
    this.props.WorkspaceStore.changeWorkspace(newObj)
      .then(response => {
        this.toggleLoading();
        this.toggleOpen();
        if (response.data.status === 'published') {
          this.props.ToastStore.showToast(
            I18n.t('js.workspace_published'),
            'success'
          );
        }
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handlePrivateWorkspaceChange() {
    if (this.props.WorkspaceStore.newWorkspace.status === 'draft') {
      this.props.WorkspaceStore.updateNewWorkspace(
        'published',
        'status'
      );
    } else {
      this.props.WorkspaceStore.updateNewWorkspace('draft', 'status');
    }
  }

  handleCancel() {
    this.props.WorkspaceStore.resetWorkspaceAttribute('status');
    this.toggleOpen();
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.status')}
            </h4>
            <p className="noselect">
              {I18n.t('js.workspace_status_text')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column" marginBottom="10px">
              <Switch
                className="bp3-control-no-margin"
                checked={
                  this.props.WorkspaceStore.newWorkspace.status ===
                  'published'
                }
                label={
                  this.props.WorkspaceStore.newWorkspace.status ===
                  'published'
                    ? I18n.t('js.published')
                    : I18n.t('js.draft')
                }
                onChange={this.handlePrivateWorkspaceChange.bind(
                  this
                )}
              />
            </Flexbox>
            <Flexbox>
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  type="submit"
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button
                  className="push-10-l"
                  icon="cross"
                  text={I18n.t('js.cancel_changes')}
                  onClick={this.handleCancel.bind(this)}
                />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
