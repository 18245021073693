import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import Utilities from '../../utils/Utilities';

@inject(
  'CurrentUserStore',
  'ImportStore',
  'ImportActions',
  'ToastStore'
)
@observer
export default class ImportUploadFileButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBlurred: [],
      file: '',
    };
  }

  handleDrop(files) {
    this.setState({ file: files[0] });
  }

  renderSelectFileText() {
    if (this.state.file === '') {
      return <span>{I18n.t('js.select_import_data')}</span>;
    }
    return <span>{this.state.file.name}</span>;
  }

  renderFile() {
    return (
      <Dropzone
        onDrop={this.handleDrop.bind(this)}
        className="bp3-dropzone push-10-r"
      >
        <Button icon="th" text={this.renderSelectFileText()} />
      </Dropzone>
    );
  }

  handleSubmit() {
    this.props.handleSubmit(this.state.file);
  }

  render() {
    return (
      <form>
        <Flexbox flexDirection="row">
          {this.renderFile()}
          <Button
            intent={Intent.PRIMARY}
            onClick={this.handleSubmit.bind(this)}
            disabled={
              this.state.file === '' ||
              !this.props.ImportStore.hasImportTemplate
            }
            text={I18n.t('js.upload_data')}
            loading={this.props.isLoading}
            icon="cloud-upload"
          />
        </Flexbox>
      </form>
    );
  }
}
