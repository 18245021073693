import { computed, observable } from 'mobx';

export default class ElementImageUpload {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;

  constructor(id, elementId, name) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }
}
