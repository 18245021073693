import React from 'react';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import classNames from 'classnames';
import { Tooltip } from '@blueprintjs/core';

export default class WebformAvatar extends React.Component {
  render() {
    let paragraphClass = classNames('');
    if (this.props.textSize === 'larger') {
      paragraphClass = classNames('bp3-running-text');
    }
    return (
      <Flexbox flexDirection="row" flexGrow={0}>
        <Flexbox flexDirection="row" alignItems="center">
          <Avatar
            src={this.props.item.webformUserImageFileName}
            size={this.props.avatarSize}
            round={true}
            className="push-5-r"
          />
        </Flexbox>
        <Flexbox flexDirection="row" alignItems="center">
          <Tooltip
            portalContainer={document.body}
            content={this.props.item.webformUserEmail}
            disabled={
              this.props.item.webformUserEmail ===
              'anonymous@peoplegoal.com'
            }
          >
            <span className={paragraphClass}>
              {this.props.item.webformUserFullName}
            </span>
          </Tooltip>
        </Flexbox>
      </Flexbox>
    );
  }
}
