const permissionTypes = [
  {
    id: 0,
    name: 'access_denied',
    text: I18n.t('js.access_denied'),
    icon: 'disable',
  },
  {
    id: 1,
    name: 'create_only',
    text: I18n.t('js.create_only'),
    icon: 'edit',
  },
  {
    id: 2,
    name: 'create_and_view',
    text: I18n.t('js.create_and_view'),
    icon: 'annotation',
  },
  {
    id: 3,
    name: 'create_view_and_edit',
    text: I18n.t('js.create_view_and_edit'),
    icon: 'new-text-box',
  },
  {
    id: 4,
    name: 'owner',
    text: I18n.t('js.owner'),
    icon: 'lock',
  },
  {
    id: 5,
    name: 'view_only',
    text: I18n.t('js.view_only'),
    icon: 'eye-open',
  },
];

const noPermission = [
  {
    id: 0,
    name: 'access_denied',
    text: I18n.t('js.access_denied'),
    icon: 'bp3-icon-disable',
  },
];

class PermissionDefinitions {
  static get permissionTypes() {
    return permissionTypes;
  }

  static get noPermissions() {
    return noPermission[0];
  }
}

export default PermissionDefinitions;
