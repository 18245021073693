/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observer, Provider } from 'mobx-react';
import Flexbox from 'flexbox-react';
import LayoutHeader from '../components/layout/LayoutHeader';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import { IconNames } from '@blueprintjs/icons';
import CurrentUserStore from '../stores/CurrentUserStore';
import NotificationStore from '../stores/NotificationStore';
import ToastStore from '../stores/ToastStore';
import NotificationList from '../components/notifications/NotificationList';
import Paginator from '../components/shared/Paginator';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class NotificationView extends React.Component {
  handlePageChange(pageNumber) {
    NotificationStore.fetchNotifications(pageNumber, NotificationStore.pagination.xPerPage, NotificationStore.pagination.xOffset);
  }

  handlePerPageChange(e) {
    NotificationStore.fetchNotifications(1, e.target.value, NotificationStore.pagination.xOffset);
  }

  renderPagination() {
    if (!NotificationStore.isLoading && NotificationStore.pagination.xTotal > NotificationStore.pagination.xPerPage) {
      return (
        <Paginator
          paginationObject={NotificationStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      CurrentUserStore,
      NotificationStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <Flexbox flexGrow={1} flexDirection="column">
                  <Flexbox marginBottom="10px">
                    <h2 className="bp3-heading">{I18n.t('js.notifications')}</h2>
                  </Flexbox>
                  <NotificationList />
                  <Flexbox flexGrow={1} justifyContent="center">
                    {this.renderPagination()}
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
