/* Lecacy code: ignore magic numbers */
/* eslint no-magic-numbers: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tree } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

@inject('DocumentationStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class DocumentationTabs extends React.Component {
  handleNodeClick(nodeData, nodePath) {
    this.props.DocumentationStore.navTreeUnselectAll();
    const navTree = _.clone(
      this.props.DocumentationStore.navTree.slice()
    );
    if (nodePath.length === 1) {
      navTree[nodePath].isSelected = true;
      if (nodeData.type === 'api') {
        this.props.DocumentationStore.setActiveApi(nodeData.id);
      }
      // FIXME
      // This line looks like it was trying to do something. It doesn't.
      // No bugs noticed, however.
      // eslint-disable-next-line
      nodeData.isExpanded
        ? this.handleNodeCollapse(nodeData, nodePath)
        : this.handleNodeExpand(nodeData, nodePath);
    }
    if (nodePath.length === 2) {
      navTree[nodePath[0]].childNodes[nodePath[1]].isSelected = true;
      this.props.DocumentationStore.setSelectedDocumentation(
        nodeData.id,
        nodeData.type
      );
      // FIXME
      // This line looks like it was trying to do something. It doesn't.
      // No bugs noticed, however.
      // eslint-disable-next-line
      nodeData.isExpanded
        ? this.handleNodeCollapse(nodeData, nodePath)
        : this.handleNodeExpand(nodeData, nodePath);
    }
    if (nodePath.length === 3) {
      navTree[nodePath[0]].childNodes[nodePath[1]].childNodes[
        nodePath[2]
      ].isSelected = true;
      this.props.DocumentationStore.setSelectedDocumentation(
        nodeData.id,
        nodeData.type
      );
    }
    this.props.DocumentationStore.navTree = navTree;
  }

  handleNodeCollapse(nodeData, nodePath) {
    const navTree = _.clone(
      this.props.DocumentationStore.navTree.slice()
    );
    if (nodePath.length === 1) {
      navTree[nodePath].isExpanded = false;
    }
    if (nodePath.length === 2) {
      navTree[nodePath[0]].childNodes[nodePath[1]].isExpanded = false;
    }
    if (nodePath.length === 3) {
      navTree[nodePath[0]].childNodes[nodePath[1]].childNodes[
        nodePath[2]
      ].isExpanded = false;
    }
    this.props.DocumentationStore.navTree = navTree;
  }

  handleNodeExpand(nodeData, nodePath) {
    const navTree = _.clone(
      this.props.DocumentationStore.navTree.slice()
    );
    if (nodePath.length === 1) {
      navTree[nodePath].isExpanded = true;
    }
    if (nodePath.length === 2) {
      navTree[nodePath[0]].childNodes[nodePath[1]].isExpanded = true;
    }
    if (nodePath.length === 3) {
      navTree[nodePath[0]].childNodes[nodePath[1]].childNodes[
        nodePath[2]
      ].isExpanded = true;
    }
    this.props.DocumentationStore.navTree = navTree;
  }

  render() {
    return (
      <Flexbox flexDirection="row" minWidth="250px">
        <Scrollbars
          autoHide={true}
          style={{
            height: '70vh',
          }}
        >
          <Tree
            contents={this.props.DocumentationStore.navTree}
            onNodeClick={this.handleNodeClick.bind(this)}
            onNodeCollapse={this.handleNodeCollapse.bind(this)}
            onNodeExpand={this.handleNodeExpand.bind(this)}
          />
        </Scrollbars>
      </Flexbox>
    );
  }
}
