import { computed, observable } from 'mobx';

export default class ElementRichTextareaDisplay {
  @observable
  id;
  @observable
  elementId;
  @observable
  content;

  constructor(id, elementId, content) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.content = content || '';
  }

  @computed
  get contentValid() {
    if (this.content.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get contentError() {
    if (this.content.length < 1) {
      return I18n.t('js.content_is_required');
    }
    return null;
  }
}
