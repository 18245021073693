const displayTypes = [
  {
    id: 0,
    name: 'radio',
    text: I18n.t('js.radio_display_option'),
    icon: 'circle',
  },
  {
    id: 1,
    name: 'checkbox',
    text: I18n.t('js.checkbox_display_option'),
    icon: 'square',
  },
];

class GridDefinitions {
  static get displayTypes() {
    return displayTypes;
  }
}

export default GridDefinitions;
