import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, HTMLSelect, Intent, NonIdealState, Spinner, Switch } from '@blueprintjs/core';
import _ from 'lodash';

@inject('CurrentUserStore', 'WorkspaceStore', 'AppStore', 'ToastStore')
@observer
export default class AppEditDefaults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleSubmitting() {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    this.setState({ isBlurred: [] });
    const newObj = {
      default_process_visible: this.props.AppStore.newApp.defaultProcessVisible,
      include_in_quick_create_menu: this.props.AppStore.newApp.includeInQuickCreateMenu,
      lock_participants: this.props.AppStore.newApp.lockParticipants,
    };
    if (this.props.AppStore.newApp.defaultViewFolder.length > 0) {
      newObj.default_view_folder = this.props.AppStore.newApp.defaultViewFolder;
    }
    this.props.AppStore.changeApp(newObj)
      .then(() => {
        this.toggleSubmitting();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  handleQuickLockParticipantsChange() {
    if (this.props.AppStore.newApp.lockParticipants === true) {
      this.props.AppStore.newApp.lockParticipants = false;
    } else {
      this.props.AppStore.newApp.lockParticipants = true;
    }
  }

  handleQuickCreateMenuChange() {
    if (this.props.AppStore.newApp.includeInQuickCreateMenu === true) {
      this.props.AppStore.newApp.includeInQuickCreateMenu = false;
    } else {
      this.props.AppStore.newApp.includeInQuickCreateMenu = true;
    }
  }

  handleDefaultProcessVisibleChange() {
    if (this.props.AppStore.newApp.defaultProcessVisible === true) {
      this.props.AppStore.newApp.defaultProcessVisible = false;
    } else {
      this.props.AppStore.newApp.defaultProcessVisible = true;
    }
  }

  handleDefaultViewChange(e) {
    this.props.AppStore.updateNewApp(e.target.value, 'defaultViewFolder');
  }

  handleCancel() {
    this.props.AppStore.resetAppAttribute('defaultViewFolder');
    this.props.AppStore.newApp.defaultProcessVisible = this.props.AppStore.activeApp.defaultProcessVisible;
    this.toggleOpen();
  }

  renderContent() {
    if (this.state.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="20px" paddingBottom="20px">
          <NonIdealState
            title={I18n.t('js.fetching_data')}
            description={I18n.t('js.please_wait')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column" flexGrow={1}>
            <Flexbox className="bp3-form-group bp3-card">
              <label className="bp3-label">{I18n.t('js.select_default_view_folder')}</label>
              <div className="bp3-form-content">
                <HTMLSelect
                  name="defaultViewFolder"
                  onChange={this.handleDefaultViewChange.bind(this)}
                  value={this.props.AppStore.newApp.defaultViewFolder}
                >
                  <option value="home">{I18n.t('js.home')}</option>
                  <option value="your-items">{I18n.t('js.your_items')}</option>
                  <option value="your-colleagues">{I18n.t('js.your_colleagues')}</option>
                  <option value="all-items">{I18n.t('js.all_items_plural', { itemPlural: this.props.AppStore.newApp.itemPlural })}</option>
                </HTMLSelect>
              </div>
            </Flexbox>
            <Flexbox className="bp3-form-group bp3-card">
              <label className="bp3-label">{I18n.t('js.default_process_visible_on_items')}</label>
              <div className="bp3-form-content">
                <Switch
                  className="bp3-control-no-margin"
                  checked={this.props.AppStore.newApp.defaultProcessVisible}
                  label={this.props.AppStore.newApp.defaultProcessVisible ? I18n.t('js.visible') : I18n.t('js.hidden')}
                  onChange={this.handleDefaultProcessVisibleChange.bind(this)}
                />
              </div>
            </Flexbox>
            <Flexbox className="bp3-form-group bp3-card">
              <label className="bp3-label">{I18n.t('js.include_in_quick_create_menu')}</label>
              <div className="bp3-form-content">
                <Switch
                  className="bp3-control-no-margin"
                  checked={this.props.AppStore.newApp.includeInQuickCreateMenu}
                  label={
                    this.props.AppStore.newApp.includeInQuickCreateMenu
                      ? I18n.t('js.included_in_quick_create_menu')
                      : I18n.t('js.not_shown_in_quick_create_menu')
                  }
                  onChange={this.handleQuickCreateMenuChange.bind(this)}
                />
              </div>
            </Flexbox>
            <Flexbox className="bp3-form-group bp3-card">
              <label className="bp3-label">{I18n.t('js.lock_participants_once_set')}</label>
              <div className="bp3-form-content">
                <Switch
                  className="bp3-control-no-margin"
                  checked={this.props.AppStore.newApp.lockParticipants}
                  label={I18n.t('js.participants_locked')}
                  onChange={this.handleQuickLockParticipantsChange.bind(this)}
                />
              </div>
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Flexbox>
          <div>
            <Button
              intent={Intent.PRIMARY}
              type="submit"
              disabled={false}
              text={I18n.t('js.save_changes')}
              loading={this.state.isSubmitting}
              icon="tick"
            />
            <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
          </div>
        </Flexbox>
      </form>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* ignore jsx-a11y as toggle functionality included eslewhere for */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.defaults')}
            </h4>
            <p className="noselect">{I18n.t('js.app_defaults_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>{this.renderContent()}</Collapse>
      </Flexbox>
    );
  }
}
