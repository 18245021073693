import { action, observable, computed } from 'mobx';
import { Intent } from '@blueprintjs/core';
import Toast from '../models/ToastModel';
import moment from 'moment';
import { create, all } from 'mathjs';

const math = create(all, {});

math.import({
  SUM: args => {
    return math.sum(args);
  },
  AVERAGE: args => {
    if (args._data.length === 0) {
      return 0;
    }
    return math.mean(args);
  },
  COUNT: args => {
    return args._data.length;
  },
});

class FormulaStore {
  @observable formula = '';
  @observable result = '';
  @observable error = '';
  @observable formulaState = 'valid';
  @observable availableElements = [];

  @computed
  get displayFormula() {
    let interpolatedFormula = '';
    const split = this.formula.split(/"/);
    split.map(fragment => {
      let newFrag = fragment;
      if (fragment.includes('_attributes#') || fragment.includes('element#')) {
        const elementId = fragment.split('#')[1];
        const findElement = _.find(this.availableElements, o => o.id === elementId);
        if (findElement) {
          newFrag = `"${findElement.name}"`;
        } else {
          const findDataElement = _.find(this.availableElements, o => {
            const id = o.id.split('#')[1];
            return id === elementId;
          });
          if (findDataElement) {
            newFrag = `"${findDataElement.name}"`;
          }
        }
      }
      interpolatedFormula = `${interpolatedFormula}${newFrag}`;
    });
    return interpolatedFormula;
  }

  @computed
  get shouldDisplayHelper() {
    return this.formula.includes('_attributes#') || this.formula.includes(`element#`);
  }

  @action resetFormula() {
    this.formula = '';
    this.result = '';
    this.error = '';
    this.formulaState = 'valid';
  }

  @action setAvailableElements(elements) {
    this.availableElements = elements;
  }

  @action setFormula(newFormula) {
    this.formula = newFormula;

    const resultObj = this.analyzeFormula(newFormula, []);
    if (resultObj) {
      this.result = resultObj.result;
      this.error = resultObj.error;
      this.formulaState = resultObj.state;
    }
  }

  @action analyzeFormula(formula, data) {
    if (!formula || formula.length === 0) {
      this.resetFormula();
      return {
        result: '',
        error: '',
        state: 'valid',
      };
    }
    try {
      const result = this.evaluateFormula(formula, data);
      if (typeof result != 'object' && !isNaN(result)) {
        return {
          result: result,
          error: '',
          state: 'valid',
        };
      }
    } catch (e) {
      return {
        result: '',
        error: e.message,
        state: 'invalid',
      };
    }
    return {
      result: '',
      error: '',
      state: 'valid',
    };
  }

  evaluateFormula(formula, data) {
    // This evaluates functions. But first we interpolate the data.
    let interpolatedFormula = this.interpolateFormula(formula, data);
    const evaluatedFormula = math.evaluate(`(${interpolatedFormula})`);
    return evaluatedFormula;
  }

  interpolateFormula(formula, data) {
    // This replaces placeholder elements so functions can be performed.
    let interpolatedFormula = '';
    const split = formula.split(/"/);
    split.map(fragment => {
      let newFrag = fragment;
      if (newFrag.slice(-1) == "(") { newFrag = `${newFrag}[` }
      if (newFrag.charAt(0) == ")") { newFrag = `]${newFrag}` }
      if (fragment.includes('_attributes#') || fragment.includes('element#')) {
        newFrag = [];
        data.map(item => {
          item.map(datum => {
            if (datum.column_id === fragment) {
              if (datum.column_number_value) {
                newFrag.push(datum.column_number_value);
              } else {
                if (datum.column_type === 'datetime') {
                  const date = moment.utc(datum.column_value);
                  if (date.unix()) {
                    newFrag.push(date.unix());
                  } else {
                    newFrag.push(0);
                  }
                } else {
                  newFrag.push(0);
                }
              }
            }
          });
        });
        if (newFrag.length === 0) {
          newFrag = 0;
        }
        newFrag = `${newFrag}`;
      }
      interpolatedFormula = `${interpolatedFormula}${newFrag}`;
    });
    return interpolatedFormula;
  }
}

const store = new FormulaStore();
export default store;
