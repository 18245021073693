import React from 'react';
// import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import TeamAvatar from '../shared/TeamAvatar';
import { Popover, Button, Menu, MenuItem } from '@blueprintjs/core';
import _ from 'lodash';
import classNames from 'classnames';
import PermissionDefinitions from '../../static/PermissionDefinitions';

@inject('PermissionStore', 'ToastStore')
@observer
export default class TeamPermissionRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeType: '',
      permissionTypes: PermissionDefinitions.permissionTypes,
    };
  }

  componentDidMount() {
    const findType = _.find(
      this.state.permissionTypes,
      type => type.name === this.props.teamPermission.permissionType
    );
    if (findType) {
      this.setState({ activeType: findType });
    }
  }

  handleTypeClick(type) {
    this.setState({ activeType: type });
    this.props.teamPermission.permissionType = type.name;
  }

  handleRemovePermission() {
    this.props.PermissionStore.removeTeamPermission(
      this.props.teamPermission.id
    );
  }

  renderMenu() {
    return (
      <Menu>
        {_.map(this.state.permissionTypes, type => {
          return (
            <MenuItem
              key={type.id}
              icon={type.icon}
              text={type.text}
              onClick={() => this.handleTypeClick(type)}
            />
          );
        })}
      </Menu>
    );
  }

  renderPermissionType() {
    const icon = this.state.activeType.icon;
    return (
      <Popover
        portalContainer={document.body}
        content={this.renderMenu()}
        position="bottom-left"
      >
        <Button
          icon={icon}
          rightIcon="bp3-icon bp3-icon-caret-down"
          className="bp3-minimal bp3-small"
          text={this.state.activeType.text}
        />
      </Popover>
    );
  }

  render() {
    return (
      <tr>
        <td className="td-width-40">
          <TeamAvatar
            team={this.props.teamPermission.team}
            avatarSize={20}
            noLink={true}
          />
        </td>
        <td className="td-width-40">{this.renderPermissionType()}</td>
        <td className="td-width-20">
          <Button
            text={I18n.t('js.remove_team')}
            className="bp3-small"
            onClick={this.handleRemovePermission.bind(this)}
          />
        </td>
      </tr>
    );
  }
}
