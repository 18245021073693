/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer } from 'mobx-react';
import CurrentUserStore from '../../stores/CurrentUserStore';
import { Menu, MenuItem } from '@blueprintjs/core';

@observer
export default class LocaleSelector extends React.Component {
  handleSetActiveLanguage(language) {
    CurrentUserStore.setActiveLanguage(language.locale);
    window.location = '/';
  }

  renderLocale(language) {
    return (
      <Flexbox flexDirection="row">
        <img alt="flag icon" className="flag-icon" src={`${I18n.t('js.flag_path')}${language.code}.png`} />
        <Flexbox>{language.name}</Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Menu>
        {CurrentUserStore.filteredLanguages.map(language => (
          <MenuItem onClick={() => this.handleSetActiveLanguage(language)} key={language.code} text={this.renderLocale(language)} />
        ))}
      </Menu>
    );
  }
}
