/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import AppStore from '../stores/AppStore';
import _ from 'lodash';

export default class WorkspaceFolder {
  @observable
  id;
  @observable
  workspaceId;
  @observable
  name;
  @observable
  rowOrder;
  @observable
  commentCount;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(id, workspaceId, name, rowOrder, commentCount, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy) {
    this.id = id || '';
    this.workspaceId = workspaceId || '';
    this.name = name || '';
    this.commentCount = commentCount || '';
    this.rowOrder = rowOrder || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get appSize() {
    // legacy quick-fix
    // eslint-disable-next-line no-magic-numbers
    if (this.id === 9999) {
      const apps = _.filter(AppStore.apps, o => o.workspaceFolderId === '' && o.status != 'draft' && o.workspaceId === this.workspaceId);
      return apps.length;
    }
    const apps = _.filter(AppStore.apps, o => o.workspaceFolderId === this.id && o.status != 'draft' && o.workspaceId === this.workspaceId);
    return apps.length;
  }

  @computed
  get title() {
    // legacy quick-fix
    // eslint-disable-next-line no-magic-numbers
    if (this.id === 9999) {
      return `${this.name} (${this.appSize})`;
    }
    if (this.appSize === 0) {
      return this.name;
    }
    return `${this.name} (${this.appSize})`;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
