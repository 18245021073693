import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import Flexbox from 'flexbox-react';
import { Button, Intent, Position, Tooltip, TextArea, Menu, MenuItem, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import AccountUsersStore from '../../stores/AccountUsersStore';
import TemplateStore from '../../stores/TemplateStore';
import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  ContentState,
  SelectionState,
  Modifier,
  Entity,
  KeyBindingUtil,
} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import classNames from 'classnames';
import CodeUtils from 'draft-js-code';

// COLOR PICKER
import { CirclePicker } from 'react-color';
import createStyles from 'draft-js-custom-styles';
import ColorDefinitions from '../../static/ColorDefinitions';

// --Emoji Plugin--
import createEmojiPlugin from 'draft-js-emoji-plugin';
import 'draft-js-emoji-plugin/lib/plugin.css';

// -- Mentions Plugin--
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import nanoid from 'nanoid';

// --Temp Import--
import placeholders from './placeholders';

// -- Link plugins --
import createLinkPlugin from 'draft-js-anchor-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { ItalicButton, BoldButton, UnderlineButton } from 'draft-js-buttons';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';

const { styles, customStyleFn, exporter } = createStyles(['color'], 'CUSTOM_');

@observer
export default class RichTextEditor extends React.Component {
  @observable
  mentions = [];
  @observable
  suggestions = [];
  @observable
  placeholderSuggestions = this.getInitialPlaceHolders();

  @observable
  isFocused = false;
  @observable
  showDialog = false;
  @observable
  urlValue = '';
  @observable
  editorState = EditorState.createEmpty();
  @observable
  hasNoContent = true;
  @observable
  showPlaceholder = true;
  @observable
  isInitial = true;
  @observable
  query = '';
  @observable
  isQuerying = false;
  @observable
  editorClass = classNames('bp3-editor');
  @observable
  editorStyle = { minHeight: 92 };
  @observable
  isColorPickerOpen = false;
  @observable
  chosenColor = '';
  @observable
  colorsForPicker = [];

  // ------------shared functions------------
  constructor(props) {
    super(props);

    this._linkPlugin = createLinkPlugin({
      placeholder: 'http://…',
    });
    this._inlineToolbarPlugin = createInlineToolbarPlugin({
      structure: [BoldButton, ItalicButton, UnderlineButton, this._linkPlugin.LinkButton],
    });
    this._emojiPlugin = createEmojiPlugin();
    this._mentionPlugin = createMentionPlugin({
      supportWhitespace: true,
      mentionComponent: mentionProps => <span className="mention-text">{mentionProps.children}</span>,
    });
    this._customPlaceholderPlugin = createMentionPlugin({
      mentionTrigger: '/',
      supportWhitespace: true,
      customPlaceHolderComponent: mentionProps => <span>{mentionProps.children}</span>,
    });
  }

  componentDidMount() {
    this.handleInitialProps(this.props);
    ColorDefinitions.ptColors.map(color => {
      this.colorsForPicker.push(color.code);
    });
  }

  componentWillReceiveProps(nextProps) {
    this.handleInitialProps(nextProps);
  }

  handleInitialProps(props) {
    if (props.content && this.isInitial) {
      if (this.isFocused) {
        const newContentState = convertFromRaw(JSON.parse(props.content));
        const editorState = EditorState.moveFocusToEnd(EditorState.push(this.editorState, newContentState));
        this.editorState = editorState;
        this.isInitial = false;
      } else {
        const newContentState = convertFromRaw(JSON.parse(props.content));
        const editorState = EditorState.push(this.editorState, newContentState);
        this.editorState = editorState;
        this.isInitial = false;
      }
    }
  }

  toggleQuerying() {
    this.isQuerying = !this.isQuerying;
  }

  testContent(editorState) {
    const result = editorState.getCurrentContent().getPlainText();
    return result.length > 0;
  }

  @action
  handleLinkChange(value) {
    this.urlValue = value;
  }

  @action
  onBlur() {
    this.editor.blur();
    this.isFocused = false;
  }

  @action
  focusEditor() {
    this.editor.focus();
    this.isFocused = true;
    if (this.isColorPickerOpen == true) this.toggleIsColorPickerOpen();
  }

  @action
  getBlockType() {
    const selection = this.editorState.getSelection();
    const blockType = this.editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return blockType;
  }

  @action
  getInlineStyle(style) {
    const currentStyle = this.editorState.getCurrentInlineStyle();
    return currentStyle.has(style);
  }

  @action
  onStyleClick(e, style) {
    e.preventDefault();
    this.onChange(RichUtils.toggleInlineStyle(this.editorState, style));
    this.isFocused = true;
  }

  @action
  onBlockTypeClick(e, blockType) {
    e.preventDefault();
    this.onChange(RichUtils.toggleBlockType(this.editorState, blockType));
    this.isFocused = true;
  }

  @action
  handleKeyCommand(command) {
    const editorState = this.editorState;
    let newState;
    if (CodeUtils.hasSelectionInBlock(editorState)) {
      newState = CodeUtils.handleKeyCommand(editorState, command);
    }
    if (!newState) {
      newState = RichUtils.handleKeyCommand(editorState, command);
    }
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  @action
  keyBindingFn(e) {
    const editorState = this.editorState;
    if (!CodeUtils.hasSelectionInBlock(editorState)) {
      return getDefaultKeyBinding(e);
    }
    const command = CodeUtils.getKeyBinding(e);
    return command || getDefaultKeyBinding(e);
  }

  initiateMention(e) {
    e.preventDefault();
    const editorState = this.editorState;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const modifiedEditorState = Modifier.insertText(currentContent, selection, '@');
    const newEditorState = EditorState.push(editorState, modifiedEditorState);
    this.editorState = newEditorState;
  }

  initiateAddPlaceholder(e) {
    e.preventDefault();
    const editorState = this.editorState;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const modifiedEditorState = Modifier.insertText(currentContent, selection, '/');
    const newEditorState = EditorState.push(editorState, modifiedEditorState);
    this.editorState = newEditorState;
  }

  shouldHidePlaceholder(editorState) {
    const contentState = editorState.getCurrentContent();
    return (
      contentState.hasText() ||
      contentState
        .getBlockMap()
        .first()
        .getType() !== 'unstyled'
    );
  }

  onChange = editorState => {
    this.showPlaceholder = !this.shouldHidePlaceholder(editorState);
    const currentContentState = this.editorState.getCurrentContent();
    const newContentState = editorState.getCurrentContent();
    if (currentContentState !== newContentState) {
      this.props.handleChange(JSON.stringify(convertToRaw(newContentState)));
      this.hasNoContent = this.testContent(editorState) ? false : true;
    }
    this.editorState = editorState;
  };

  handleSubmit() {
    this.props.handleSubmit();
    this.handleClearInput();
  }

  handleClearInput() {
    this.props.handleCancel();
  }

  // ------------mentions------------
  @action
  updateSuggestions(query, data) {
    this.mentions = [];
    data.map(user => {
      this.mentions.push({
        name: user.full_name,
        link: `/users/${user.id}`,
        mentioned_user_id: user.id,
        avatar: user.image_file_name,
      });
    });
    this.suggestions = defaultSuggestionsFilter(query, this.mentions);
  }

  onSearchChange = ({ value }) => {
    this.handleSearchChange(value);
  };

  handleSearchChange(query) {
    const requestId = nanoid();
    this.query = query;
    if (query.length <= 1) {
      AccountUsersStore.resetSearchUsers();
    } else {
      AccountUsersStore.resetSearchUsers().then(() => {
        this.toggleQuerying();
        AccountUsersStore.fetchUsersQuery(query, requestId)
          .then(response => {
            this.updateSuggestions(query, response.data);
            this.toggleQuerying();
          })
          .catch(() => {
            this.toggleQuerying();
          });
      });
    }
  }

  // placeholders
  getInitialPlaceHolders() {
    if (!this.props.placeholderDisabled) {
      const template_elements = TemplateStore.allElements;
      const object_of_elements = {};
      const strings = {};
      const numbers = {};
      _.map(template_elements, element => {
        let element_type = 'element' + element.elementType.charAt(0).toUpperCase() + element.elementType.slice(1);
        if (element_type.includes('_')) {
          const index = element_type.indexOf('_');
          element_type = element_type.slice(0, index) + element_type.charAt(index + 1).toUpperCase() + element_type.slice(index + 2);
        }
        let sub_element = '';
        switch (true) {
          case element['elementType'] == 'currency':
            sub_element = element['elementNumber'];
            break;
          case element['elementType'] == 'time' || element['elementType'] == 'date_range':
            sub_element = element['elementDate'];
            break;
          default:
            sub_element = element[element_type];
        }
        if (sub_element) {
          if (sub_element.name != 'Calculation') {
            const isNumber = ['number', 'currency', 'date', 'date_range', 'grid', 'time', 'select'];
            const isString = ['text_input'];
            switch (true) {
              case isNumber.includes(element.elementType):
                numbers[element.id] = {
                  name: sub_element.name,
                  object_type: element.elementType,
                  static: 'false',
                  element_id: element.id,
                };
                break;
              case isString.includes(element.elementType):
                strings[element.id] = {
                  name: sub_element.name,
                  object_type: element.elementType,
                  static: 'false',
                  element_id: element.id,
                };
                break;
              default:
            }
          }
        }
      });
      object_of_elements['strings'] = strings;
      object_of_elements['numbers'] = numbers;
      const data = placeholders;
      _.map(object_of_elements['strings'], str => {
        data.push(str);
      });
      _.map(object_of_elements['numbers'], num => {
        data.push(num);
      });
      return data;
    }
  }

  @action
  updatePlaceholderSuggestions(query, data) {
    this.placeholderSuggestions = defaultSuggestionsFilter(query, data);
  }

  handlePlaceholderSearchChange = query => {
    this.updatePlaceholderSuggestions(query, placeholders);
  };

  onPlaceholderSearchChange = ({ value }) => {
    this.handlePlaceholderSearchChange(value);
  };

  // COLOR PICKER

  @action
  toggleIsColorPickerOpen() {
    this.isColorPickerOpen = !this.isColorPickerOpen;
  }

  @action
  handleChangeComplete = color => {
    this.chosenColor = color;
    this.toggleColor(color);
  };

  @action
  toggleColor = color => {
    const modifiedEditorState = styles.color.toggle(this.editorState, color.hex);
    this.onChange(modifiedEditorState);
    this.editorState = modifiedEditorState;
  };

  // ------------renderers------------

  renderHeadingMenu() {
    return (
      <Menu>
        <MenuItem text="Heading 1" onClick={e => this.onBlockTypeClick(e, 'header-one')} />
        <MenuItem text="Heading 2" onClick={e => this.onBlockTypeClick(e, 'header-two')} />
        <MenuItem text="Heading 3" onClick={e => this.onBlockTypeClick(e, 'header-three')} />
        <MenuItem text="Heading 4" onClick={e => this.onBlockTypeClick(e, 'header-four')} />
      </Menu>
    );
  }

  renderButtons() {
    if (this.props.showSubmissionButtons) {
      return (
        <Flexbox flexDirection="row" marginTop="10px">
          <Button
            className="bp3-intent-primary"
            icon={this.props.submitIcon}
            text={this.props.submitText}
            loading={this.props.submitLoading}
            onClick={this.handleSubmit.bind(this)}
            disabled={this.hasNoContent}
          />
          <Button
            className="push-10-l"
            icon={this.props.cancelIcon}
            text={this.props.cancelText}
            onClick={this.handleClearInput.bind(this)}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderEditor() {
    const plugins = [this._emojiPlugin, this._mentionPlugin, this._customPlaceholderPlugin, this._linkPlugin, this._inlineToolbarPlugin];
    const { LinkButton } = this._linkPlugin;
    const { EmojiSuggestions } = this._emojiPlugin;
    const { MentionSuggestions } = this._mentionPlugin;
    const { MentionSuggestions: ElementPlaceholders } = this._customPlaceholderPlugin;
    const { InlineToolbar } = this._inlineToolbarPlugin;

    let editorClass = this.isFocused ? 'bp3-editor bp3-editor-active' : 'bp3-editor';
    if (this.props.disabled) {
      editorClass = `${editorClass} bp3-editor-disabled`;
    }
    return (
      <Flexbox className={editorClass} flexGrow={1} onClick={() => this.focusEditor()} flexDirection="column">
        <Editor
          customStyleFn={customStyleFn}
          editorState={this.editorState}
          onChange={this.onChange}
          onBlur={this.onBlur.bind(this)}
          plugins={plugins}
          placeholder={this.showPlaceholder ? this.props.placeholder : ''}
          keyBindingFn={e => this.keyBindingFn(e)}
          handleKeyCommand={this.handleKeyCommand}
          ref={element => {
            this.editor = element;
          }}
        />
        <InlineToolbar>
          {externalProps => (
            <div>
              <LinkButton {...externalProps} />
            </div>
          )}
        </InlineToolbar>
        <EmojiSuggestions />
        <MentionSuggestions onSearchChange={this.onSearchChange} suggestions={this.suggestions} />
        {this.props.placeholderDisabled ? (
          ''
        ) : (
          <ElementPlaceholders onSearchChange={this.onPlaceholderSearchChange} suggestions={this.placeholderSuggestions} />
        )}
      </Flexbox>
    );
  }

  renderButtonGroup() {
    const activeBlockStyle = this.getBlockType();
    const { EmojiSelect } = this._emojiPlugin;
    let style = {};
    if (this.isFocused) {
      style = { opacity: 1 };
    }
    return (
      <Flexbox flexDirection="row" className="rich-text-editor-btn-group" style={style}>
        <Flexbox>
          <Popover
            portalContainer={document.body}
            usePortal={false}
            content={this.renderHeadingMenu()}
            position="bottom"
            interactionKind={PopoverInteractionKind.HOVER}
            disabled={this.props.disabled}
            inline={true}
            autoFocus={false}
          >
            <Button disabled={this.props.disabled} minimal={true} icon="header" />
          </Popover>
        </Flexbox>
        <Flexbox className="bp3-button-group bp3-minimal" marginBottom="10px">
          <Tooltip
            content={I18n.t('js.bold')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onStyleClick(e, 'BOLD')}
              icon="bold"
              active={this.getInlineStyle('BOLD')}
            />
          </Tooltip>
          <Tooltip
            content={I18n.t('js.italic')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onStyleClick(e, 'ITALIC')}
              icon="italic"
              active={this.getInlineStyle('ITALIC')}
            />
          </Tooltip>

          <Tooltip
            content={I18n.t('js.underline')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onStyleClick(e, 'UNDERLINE')}
              icon="underline"
              active={this.getInlineStyle('UNDERLINE')}
            />
          </Tooltip>
          <Tooltip
            content={I18n.t('js.code')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onStyleClick(e, 'CODE')}
              icon="code"
              active={this.getInlineStyle('CODE')}
            />
          </Tooltip>

          <Tooltip
            content={I18n.t('js.strikethrough')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onStyleClick(e, 'STRIKETHROUGH')}
              icon="minus"
              active={this.getInlineStyle('STRIKETHROUGH')}
            />
          </Tooltip>
          <Tooltip
            content={I18n.t('js.paragraph')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onBlockTypeClick(e, 'paragraph')}
              icon="paragraph"
              active={activeBlockStyle === 'paragraph'}
            />
          </Tooltip>
          <Tooltip
            content={I18n.t('js.blockquote')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onBlockTypeClick(e, 'blockquote')}
              icon="citation"
              active={activeBlockStyle === 'blockquote'}
            />
          </Tooltip>

          <Tooltip
            content={I18n.t('js.unordered_list')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onBlockTypeClick(e, 'unordered-list-item')}
              icon="properties"
              active={activeBlockStyle === 'unordered-list-item'}
            />
          </Tooltip>

          <Tooltip
            content={I18n.t('js.ordered_list')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onBlockTypeClick(e, 'ordered-list-item')}
              icon="numbered-list"
              active={activeBlockStyle === 'ordered-list-item'}
            />
          </Tooltip>

          <Tooltip
            content={I18n.t('js.code_block')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onClick={e => this.onBlockTypeClick(e, 'code-block')}
              icon="code-block"
              active={activeBlockStyle === 'code-block'}
            />
          </Tooltip>
          {this.props.placeholderDisabled ? (
            ''
          ) : (
            <Tooltip
              content={I18n.t('js.element_placeholder')}
              position={Position.TOP}
              hoverOpenDelay={300}
              className="tooltip-rich-text-editor"
              portalContainer={document.body}
              disabled={this.props.disabled}
            >
              <Button
                disabled={this.props.disabled}
                onMouseDown={e => this.initiateAddPlaceholder(e)}
                icon="add"
                // active={this.showDialog}
              />
            </Tooltip>
          )}
          <Tooltip
            content={I18n.t('js.mention')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <Button
              disabled={this.props.disabled}
              onMouseDown={e => this.initiateMention(e)}
              icon="person"
              // active={this.showDialog}
            />
          </Tooltip>

          <div className="position-relative">
            <Tooltip
              content={I18n.t('js.color_picker')}
              position={Position.TOP}
              hoverOpenDelay={300}
              className="tooltip-rich-text-editor"
              portalContainer={document.body}
              disabled={this.props.disabled}
            >
              <Button onClick={() => this.toggleIsColorPickerOpen()} icon="tint" active={this.isColorPickerOpen} />
            </Tooltip>
            {this.isColorPickerOpen ? (
              <div>
                <CirclePicker
                  className="color-picker-wrapper scroll flex justify-center color-picker"
                  color={this.chosenColor}
                  onChangeComplete={this.handleChangeComplete}
                  colors={this.colorsForPicker}
                  circleSize={20}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <Tooltip
            content={I18n.t('js.emoji')}
            position={Position.TOP}
            hoverOpenDelay={300}
            className="tooltip-rich-text-editor"
            portalContainer={document.body}
            disabled={this.props.disabled}
          >
            <EmojiSelect className="brd-none w-0" />
          </Tooltip>
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderButtonGroup()}
        {this.renderEditor()}
        {this.renderButtons()}
      </Flexbox>
    );
  }
}
