import { action, computed, observable } from 'mobx';
import AccountBillingActions from '../actions/AccountBillingActions';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import Subscription from '../models/SubscriptionModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Plan from '../models/PlanModel';
import _ from 'lodash';

axiosCancel(axios, {
  debug: false, // default
});

class AccountBillingStore {
  @observable isLoading = true;
  @observable isLoadingInvoices = false;
  @observable newSubscriptionNumberValid = false;
  @observable activeTab = 'plans';
  @observable newSubscription = new Subscription();
  @observable activeSubscription = new Subscription();
  @observable selectedPlan = new Plan();
  @observable subscribedPlan = new Plan();
  @observable plans = [];
  @observable subscriptions = [];
  @observable invoices = [];
  @observable invoiceColumns = ColumnDefinitions.invoiceColumns;
  @observable upcomingInvoice = '';

  @computed
  get newSubscriptionValid() {
    let valid = false;
    if (
      this.newSubscription.cardHoldersNameValid &&
      this.newSubscription.companyNameValid &&
      this.newSubscription.addressLine1Valid &&
      this.newSubscription.addressLine2Valid &&
      this.newSubscription.cityValid &&
      this.newSubscription.zipValid &&
      this.newSubscription.countryValid &&
      this.newSubscriptionNumberValid
    ) {
      valid = true;
    }
    return valid;
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  updateNewSubscription(data, attribute) {
    this.newSubscription[attribute] = data;
  }

  @action
  addPlan(data) {
    return new Promise(resolve => {
      const newPlan = new Plan(
        data.id,
        data.active,
        data.aggregate_usage,
        data.billing_scheme,
        data.nickname,
        data.product,
        data.trial_period_days,
        data.amount,
        data.currency,
        data.metadata,
        data.interval,
        data.interval_count
      );
      this.plans = _.unionBy([newPlan], this.plans, 'id');
      this.sortPlans();
      resolve(newPlan);
    });
  }

  @action
  addSubscription(data) {
    this.subscribedPlan = _.find(this.plans, o => o.id === data.plan_id);

    const newSubscription = new Subscription(
      data.id,
      data.plan_id,
      data.user_id,
      data.account_id,
      data.application_fee_percent,
      data.billing,
      data.billing_cycle_anchor,
      data.coupon,
      data.cancel_at_period_end,
      data.canceled_at,
      data.current_period_end,
      data.current_period_start,
      data.customer,
      data.days_until_due,
      data.discount,
      data.ended_at,
      data.quantity,
      data.start,
      data.status,
      data.tax_percent,
      data.card_holders_name,
      data.address_line_1,
      data.address_line_2,
      data.city,
      data.state_county,
      data.zip,
      data.tax_number,
      data.country,
      data.company_name,
      data.trial_end,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.subscriptions = _.unionBy([newSubscription], this.subscriptions, 'id');
    this.activeSubscription = newSubscription;
    this.sortSubscriptions();
    this.loadInvoices(newSubscription.id);
  }

  @action
  loadInvoices(id) {
    this.isLoadingInvoices = true;
    AccountBillingActions.fetchUpcomingInvoice(id).then(response => {
      this.upcomingInvoice = response.data;
      AccountBillingActions.fetchInvoices(id).then(res => {
        let totalLength = res.data.length;
        _.map(res.data, invoice => {
          this.addInvoice(invoice);
          totalLength--;
        });
        if (totalLength === 0) {
          this.isLoadingInvoices = false;
        }
      });
    });
  }

  @action
  addInvoice(invoice) {
    this.invoices = _.unionBy([invoice], this.invoices, 'id');
    this.sortInvoices();
  }

  @action
  setInvoiceSortParams(param) {
    const newInvoiceColumns = this.invoiceColumns;
    _.map(newInvoiceColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.invoiceColumns = newInvoiceColumns;
    this.sortInvoices();
  }

  @action
  sortInvoices() {
    let newInvoices = this.invoices;
    const activeColumn = _.find(this.invoiceColumns, o => o.active);
    newInvoices = _.orderBy(newInvoices, [activeColumn.id], [activeColumn.sortDir]);
    this.invoices = newInvoices;
  }

  @action
  sortPlans() {
    let newPlans = this.plans;
    newPlans = _.orderBy(newPlans, ['amount'], ['asc']);
    this.plans = newPlans;
  }

  @action
  sortSubscriptions() {
    let newSubscriptions = this.subscriptions;
    newSubscriptions = _.orderBy(newSubscriptions, ['createdAt'], ['asc']);
    this.subscriptions = newSubscriptions;
  }

  @action
  resetNewSubscription() {
    this.newSubscription = new Subscription();
    this.newSubscription = new Subscription();
    this.selectedPlan = new Plan();
  }

  @action
  resetSubscriptions() {
    this.subscriptions = [];
    this.activeSubscription = new Subscription();
    this.newSubscription = new Subscription();
    this.subscribedPlan = new Plan();
    this.selectedPlan = new Plan();
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.isLoadingInvoices = false;
    this.newSubscriptionNumberValid = false;
    this.activeTab = 'plans';
    this.activeSubscription = new Subscription();
    this.newSubscription = new Subscription();
    this.selectedPlan = new Plan();
    this.subscribedPlan = new Plan();
    this.plans = [];
    this.subscriptions = [];
    this.invoices = [];
    this.upcomingInvoice = '';
  }
}

const store = new AccountBillingStore();
export default store;
