import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent, Switch, Alert, Classes } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('OrganizationStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class OrganizationEditRelationship extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isDeletingRelationship: false,
      isBlurred: [],
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    this.setState({ isBlurred: [] });
    this.props.OrganizationStore.changeRelationship(this.props.OrganizationStore.newRelationship)
      .then(response => {
        this.toggleSubmitting();
        this.props.toggleDialog();
        this.props.OrganizationStore.addRelationship(response.data, true);
        this.props.OrganizationStore.setActiveTab('individual');
        this.props.ToastStore.showToast(I18n.t('js.relationship_updated'), 'success');
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  handleDeleteRelationship() {
    this.props.OrganizationStore.deleteRelationship(this.props.OrganizationStore.activeRelationship).then(response => {
      this.props.ToastStore.showToast(I18n.t('js.relationship_deleted'), 'success');
      this.toggleIsDeletingRelationship();
      this.props.toggleDialog();
    });
  }

  toggleIsDeletingRelationship() {
    this.setState({ isDeletingRelationship: !this.state.isDeletingRelationship });
  }

  toggleSubmitting() {
    this.setState({ isSubmitting: !this.state.isSubmitting });
  }

  handleInputChange(e) {
    this.props.OrganizationStore.updateNewRelationship(e.target.value, e.target.name);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  handleDefaultChange() {
    this.props.OrganizationStore.newRelationship.defaultRelationship = !this.props.OrganizationStore.newRelationship.defaultRelationship;
  }

  handleLockUserChange() {
    this.props.OrganizationStore.newRelationship.lockUserChanges = !this.props.OrganizationStore.newRelationship.lockUserChanges;
  }

  handleLockCounterpartUserChange() {
    this.props.OrganizationStore.newRelationship.lockCounterpartUserChanges = !this.props.OrganizationStore.newRelationship
      .lockCounterpartUserChanges;
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.OrganizationStore.newRelationship[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.OrganizationStore.newRelationship[`${field}Error`]}</div>;
    }
    return undefined;
  }

  renderAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeletingRelationship}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleIsDeletingRelationship()}
        confirmButtonText={I18n.t('js.delete_relationship')}
        onConfirm={() => this.handleDeleteRelationship()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_want_to_delete_this_relationship')}
      </Alert>
    );
  }

  render() {
    return (
      <Dialog
        icon="edit"
        isOpen={this.props.isOpen}
        onClose={() => this.props.toggleDialog()}
        canOutsideClickClose={false}
        portalContainer={document.body}
        inline={true}
        title={I18n.t('js.edit_relationship')}
      >
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'name') && !this.props.OrganizationStore.newRelationship.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.relationship_name')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.props.OrganizationStore.newRelationship.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.manager')}
                        name="name"
                        value={this.props.OrganizationStore.newRelationship.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </label>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'counterpartName') && !this.props.OrganizationStore.newRelationship.counterpartNameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="counterpartName">
                  {I18n.t('js.counterpart_name')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'counterpartName') &&
                        !this.props.OrganizationStore.newRelationship.counterpartNameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.direct_reports')}
                        name="counterpartName"
                        value={this.props.OrganizationStore.newRelationship.counterpartName}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('counterpartName')}
                  </div>
                </label>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'description') && !this.props.OrganizationStore.newRelationship.descriptionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="description">
                  {I18n.t('js.relationship_description')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'description') && !this.props.OrganizationStore.newRelationship.descriptionValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <textarea
                        className="bp3-input bp3-textarea bp3-fill"
                        type="textarea"
                        placeholder={I18n.t('js.manager_description')}
                        name="description"
                        value={this.props.OrganizationStore.newRelationship.description}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('description')}
                  </div>
                </label>
              </div>
            </Flexbox>
            <Flexbox flexGrow={0}>
              <Switch
                disabled={this.props.OrganizationStore.relationships.length <= 1}
                className="bp3-control-no-margin"
                checked={this.props.OrganizationStore.newRelationship.defaultRelationship}
                label={I18n.t('js.default_relationship')}
                onChange={this.handleDefaultChange.bind(this)}
              />
            </Flexbox>
            <Flexbox flexGrow={0} flexDirection="column">
              <h4>{I18n.t('js.lock_relationship_changes')}</h4>
              <label className="bp3-label" htmlFor="description">
                {I18n.t('js.relationship_locking_description')}
                <div className="bp3-form-content">
                  <Switch
                    className="bp3-control-no-margin push-20-t"
                    checked={this.props.OrganizationStore.newRelationship.lockUserChanges}
                    label={I18n.t('js.lock_name_changes', { name: this.props.OrganizationStore.newRelationship.counterpartName })}
                    onChange={this.handleLockUserChange.bind(this)}
                  />
                </div>
                <div className="bp3-form-content">
                  <Switch
                    className="bp3-control-no-margin push-10-t"
                    checked={this.props.OrganizationStore.newRelationship.lockCounterpartUserChanges}
                    label={I18n.t('js.lock_name_changes', { name: this.props.OrganizationStore.newRelationship.name })}
                    onChange={this.handleLockCounterpartUserChange.bind(this)}
                  />
                </div>
              </label>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={() => this.props.toggleDialog()} />
              <Button text={I18n.t('js.delete_relationship')} intent={Intent.DANGER} onClick={() => this.toggleIsDeletingRelationship()} />
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                disabled={!this.props.OrganizationStore.newRelationshipFormValid}
                text={I18n.t('js.save_changes')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
        {this.renderAlert()}
      </Dialog>
    );
  }
}
