import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import TimelineStore from '../../stores/TimelineStore';
import {
  Classes,
  Switch,
  NonIdealState,
  Spinner,
  AnchorButton,
  Intent,
  Dialog,
  InputGroup,
  FormGroup,
  Button,
  Alert,
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';

@observer
export default class EditTimelineEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      showDeletion: false,
      isDeleting: false,
    };
  }

  @computed
  get canSubmit() {
    const { activeTimeline } = TimelineStore;
    return activeTimeline.event_description.length > 0;
  }

  minDate() {
    var minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    return minDate;
  }

  maxDate() {
    var maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 100);
    return maxDate;
  }

  toggleDelete() {
    this.setState({ showDeletion: !this.state.showDeletion });
  }

  handleStartDateChange(date) {
    if (date != null) {
      TimelineStore.updateActiveTimelineAttribute(date, 'event_start_date');
    }
  }

  handleEndDateChange(date) {
    if (date != null) {
      TimelineStore.updateActiveTimelineAttribute(date, 'event_end_date');
    }
  }

  handleInputChange(e) {
    TimelineStore.updateActiveTimelineAttribute(e.target.value, e.target.name);
  }

  handleCancel() {
    this.props.toggleDialog();
    TimelineStore.resetActiveTimeline();
  }

  handleDelete() {
    this.setState({ isDeleting: true, showDeletion: false });
    TimelineStore.deleteTimeline(TimelineStore.activeTimeline.id)
      .then(response => {
        this.setState({ isDeleting: false });
        this.props.toggleDialog();
        TimelineStore.resetActiveTimeline();
      })
      .catch(error => {
        this.setState({ isDeleting: false });
        this.props.toggleDialog();
        TimelineStore.resetActiveTimeline();
      });
  }

  handleSubmit() {
    this.setState({ isSubmitting: true });
    const timelineObj = {
      id: TimelineStore.activeTimeline.id,
      event_start_date: moment.utc(TimelineStore.activeTimeline.event_start_date),
      event_end_date: moment.utc(TimelineStore.activeTimeline.event_end_date),
      event_description: TimelineStore.activeTimeline.event_description,
    };
    TimelineStore.changeTimeline(timelineObj)
      .then(response => {
        this.setState({ isSubmitting: false });
        this.props.toggleDialog();
        TimelineStore.resetActiveTimeline();
      })
      .catch(error => {
        this.setState({ isSubmitting: false });
        this.props.toggleDialog();
        TimelineStore.resetActiveTimeline();
      });
  }

  renderDialog() {
    const { activeTimeline } = TimelineStore;
    return (
      <Dialog
        portalContainer={document.body}
        icon="timeline-events"
        isOpen={this.props.showDialog}
        onClose={this.handleCancel.bind(this)}
        canOutsideClickClose={false}
        inline={true}
        style={{ width: '530px' }}
        title={I18n.t('js.edit_timeline_event')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <FormGroup label={I18n.t('js.event_description')} labelFor="event_description" labelInfo={`(${I18n.t('js.required')})`}>
                <InputGroup
                  name="event_description"
                  className="bp3-fill"
                  onChange={e => this.handleInputChange(e)}
                  placeholder={I18n.t('js.event_description')}
                  value={activeTimeline.event_description}
                />
              </FormGroup>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={0}>
              <FormGroup label={I18n.t('js.event_start_date')} labelFor="event_start_date" labelInfo={`(${I18n.t('js.required')})`}>
                <DatePicker
                  className="bp3-card"
                  value={new Date(activeTimeline.event_start_date)}
                  minDate={this.minDate()}
                  maxDate={this.maxDate()}
                  onChange={date => this.handleStartDateChange(date)}
                  highlightCurrentDay={true}
                  includeTime={false}
                  showActionsBar={false}
                  popoverProps={{
                    usePortal: false,
                    inline: false,
                  }}
                />
              </FormGroup>
              <FormGroup
                label={I18n.t('js.event_end_date')}
                labelFor="event_end_date"
                className="push-20-l"
                labelInfo={`(${I18n.t('js.required')})`}
              >
                <DatePicker
                  className="bp3-card"
                  value={new Date(activeTimeline.event_end_date)}
                  minDate={this.minDate()}
                  maxDate={this.maxDate()}
                  onChange={date => this.handleEndDateChange(date)}
                  highlightCurrentDay={true}
                  includeTime={false}
                  showActionsBar={false}
                  popoverProps={{
                    usePortal: false,
                    inline: false,
                  }}
                />
              </FormGroup>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <Flexbox flexDirection="row" justifyContent="space-between">
              <Flexbox>
                <Button
                  text={I18n.t('js.delete')}
                  icon="trash"
                  intent={Intent.DANGER}
                  onClick={this.toggleDelete.bind(this)}
                  loading={this.state.isDeleting}
                />
              </Flexbox>
              <Flexbox>
                <Button text={I18n.t('js.cancel')} icon="cross" onClick={this.handleCancel.bind(this)} className="push-10-r" />
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.handleSubmit.bind(this)}
                  disabled={!this.canSubmit}
                  icon="floppy-disk"
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                />
              </Flexbox>
            </Flexbox>
          </div>
        </form>
      </Dialog>
    );
  }

  renderAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.showDeletion}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDelete()}
        confirmButtonText={I18n.t('js.delete_timeline_event')}
        onConfirm={this.handleDelete.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_timeline_event')}
      </Alert>
    );
  }

  render() {
    return (
      <Flexbox>
        {this.renderDialog()}
        {this.renderAlert()}
      </Flexbox>
    );
  }
}
