// @flow
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme';

type ThemeWrapperProps = { children: React.Node };

/**
 * Sits at the top of each view and make a consistent theme
 * available to all styled components below.
 */
const ThemeWrapper = ({ children }: ThemeWrapperProps) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>{children}</React.Fragment>
  </ThemeProvider>
);

export default ThemeWrapper;
