/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import {
  Button,
  Classes,
  InputGroup,
  Intent,
  MenuItem,
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { Select } from '@blueprintjs/select';
import Participant from '../../models/ParticipantModel';
import classNames from 'classnames';
import _ from 'lodash';

const ParticipantSelect = Select.ofType(Participant);

@inject('TemplateStore')
@observer
export default class TemplateNewParticipant extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  renderParticipant(item, details) {
    let menuClass = classNames('popover-larger');
    if (details.modifiers.active) {
      menuClass = classNames(
        Classes.ACTIVE,
        Classes.INTENT_PRIMARY,
        'popover-larger'
      );
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={item.name}
      />
    );
  }

  renderInputValue(participant) {
    return participant.name;
  }

  handleItemSelect(participant) {
    this.setState({ isBlurred: [] });
    this.props.TemplateStore.newParticipant.name = participant.name;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  handleInputChange(e) {
    this.props.TemplateStore.updateNewParticipant(
      e.target.value,
      e.target.name
    );
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.TemplateStore.newParticipant[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.TemplateStore.newParticipant[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  renderParticipantSelect() {
    return (
      <ParticipantSelect
        resetOnSelect={true}
        filterable={false}
        items={this.props.TemplateStore.participants}
        itemRenderer={this.renderParticipant.bind(this)}
        onItemSelect={this.handleItemSelect.bind(this)}
        inputValueRenderer={this.renderInputValue.bind(this)}
        inputProps={{
          rightElement: this.renderDropDown(),
        }}
        popoverProps={{
          usePortal: false,
          position: 'bottom-right',
          className: '',
          popoverClassName: 'bp3-minimal',
        }}
      >
        <Button
          text={I18n.t('js.choose_existing_participant')}
          rightIcon="caret-down"
          className="bp3-minimal"
        />
      </ParticipantSelect>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="column">
          <div
            className={
              _.includes(this.state.isBlurred, 'name') &&
              !this.props.TemplateStore.newParticipant.nameValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <label className="bp3-label" htmlFor="name">
              {I18n.t('js.create_or_select_a_participant_name')}
              <span className="bp3-text-muted push-5-l">
                ({I18n.t('js.required')})
              </span>
            </label>
            <div className="bp3-form-content">
              <InputGroup
                autoComplete="off"
                type="text"
                intent={
                  _.includes(this.state.isBlurred, 'name') &&
                  !this.props.TemplateStore.newParticipant.nameValid
                    ? Intent.DANGER
                    : Intent.DEFAULT
                }
                placeholder={I18n.t('js.new_participant_name')}
                name="name"
                value={this.props.TemplateStore.newParticipant.name}
                onChange={this.handleInputChange.bind(this)}
                onBlur={this.handleBlurChange.bind(this)}
                dir="auto"
                rightElement={this.renderParticipantSelect()}
              />
              {this.renderErrors('name')}
              <div className="bp3-form-helper-text">
                {I18n.t('js.participant_name_description')}
              </div>
            </div>
          </div>
        </Flexbox>
      </Flexbox>
    );
  }
}
