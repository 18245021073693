// @flow
import * as React from 'react';
import SkeletonUtilities from '../../utils/SkeletonUtilities';

const skeletonTitleStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const textStyle = {
  color: 'rgba(255,255,255,0.01)',
  marginRight: 'auto',
  marginTop: '0',
};

type SkeletonTitleProps = {
  titleLength: number,
  subtitleLength: number,
};

class SkeletonTitle extends React.Component<SkeletonTitleProps> {
  static defaultProps = {
    titleLength: 40,
    subtitleLength: 40,
  };

  render() {
    const { emptyString } = SkeletonUtilities;
    return (
      <div style={skeletonTitleStyle}>
        <h4 style={textStyle} className="bp3-skeleton">
          {emptyString(this.props.titleLength)}
        </h4>
        <p
          style={textStyle}
          className="bp3-running-text bp3-skeleton"
        >
          {emptyString(this.props.subtitleLength)}
        </p>
      </div>
    );
  }
}

export default SkeletonTitle;
