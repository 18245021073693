import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  renderImage() {
    if (this.props.element.elementImage.imageFileName.length > 0) {
      return (
        <img
          className="element-image"
          alt="element"
          src={this.props.element.elementImage.imageFileName}
        />
      );
    }
    return undefined;
  }

  render() {
    let alignment = 'center';
    if (this.props.element.elementImage.alignment === 'left') {
      alignment = 'flex-start';
    }
    if (this.props.element.elementImage.alignment === 'right') {
      alignment = 'flex-end';
    }
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="10px"
      >
        <Flexbox
          flexGrow={1}
          flexDirection="row"
          flexWrap="wrap"
          paddingTop="10px"
          justifyContent={alignment}
        >
          {this.renderImage()}
        </Flexbox>
      </Flexbox>
    );
  }
}
