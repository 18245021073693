import { computed, observable } from 'mobx';
import Utilities from '../../utils/Utilities';

export default class ElementSelectOption {
  @observable
  id;
  @observable
  elementSelectId;
  @observable
  name;
  @observable
  color;
  @observable
  numberValue;
  @observable
  rowOrder;

  constructor(
    id,
    elementSelectId,
    name,
    color,
    numberValue,
    rowOrder
  ) {
    this.id = id || Utilities.makeId();
    this.elementSelectId = elementSelectId || '';
    this.name = name || '';
    this.color = color || '';
    this.numberValue = Utilities.decimalToInt(numberValue) || '0';
    this.rowOrder = rowOrder || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }
}
