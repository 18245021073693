import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import TeamRequestsListRequestsRow from './TeamRequestsListRequestsRow';
import Paginator from '../shared/Paginator';
import _ from 'lodash';

@inject('TeamUsersRequestsStore')
@observer
export default class TeamRequestsListRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {
    this.toggleLoading();
    const appNum = 1;
    this.props.TeamUsersRequestsStore.fetchUserTeamRequests(
      this.props.teamId,
      appNum,
      this.props.TeamUsersRequestsStore.pagination.xPerPage,
      this.props.TeamUsersRequestsStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleColumnSort(column) {
    this.props.TeamUsersRequestsStore.setUserTeamRequestSortParams(
      column.id,
      column.sortDir
    );
  }

  handlePageChange(pageNumber) {
    this.toggleLoading();
    this.props.TeamUsersRequestsStore.fetchUserTeamRequests(
      this.props.teamId,
      pageNumber,
      this.props.TeamUsersRequestsStore.pagination.xPerPage,
      this.props.TeamUsersRequestsStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handlePerPageChange(e) {
    this.toggleLoading();
    const appNum = 1;
    this.props.TeamUsersRequestsStore.fetchUserTeamRequests(
      this.props.teamId,
      appNum,
      e.target.value,
      this.props.TeamUsersRequestsStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderPagination() {
    if (
      !this.state.isLoading &&
      this.props.TeamUsersRequestsStore.pagination.xTotal >
        this.props.TeamUsersRequestsStore.pagination.xPerPage
    ) {
      return (
        <Paginator
          paginationObject={
            this.props.TeamUsersRequestsStore.pagination
          }
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderRequests() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader
              columns={
                this.props.TeamUsersRequestsStore
                  .userTeamRequestColumns
              }
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          <tbody>
            {_.map(
              this.props.TeamUsersRequestsStore.userTeamRequests,
              request => (
                <TeamRequestsListRequestsRow
                  request={request}
                  key={request.id}
                />
              )
            )}
          </tbody>
        </table>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexDirection="column" alignItems="stretch">
          {this.renderRequests()}
          <Flexbox flexGrow={1} justifyContent="center">
            {this.renderPagination()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
