/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import axios from 'axios';
import Account from '../models/AccountModel';
import IntegrationToken from '../models/IntegrationTokenModel';
import _ from 'lodash';

class AccountSettingsStore {
  @observable
  isLoading = true;
  @observable
  activeTab = 'details';
  @observable
  originalAccount = new Account();
  @observable
  newAccount = new Account();
  @observable
  integrationTokens = [];

  @computed
  get slackIntegration() {
    return _.find(this.integrationTokens, o => o.name === 'Slack');
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  addAccount(data) {
    let newImage = ``;
    if (typeof data.image_file_name === `string`) {
      newImage = data.image_file_name;
    } else {
      newImage = I18n.t(`js.pg_icon_blue`);
    }
    const newAccount = new Account(
      data.id,
      data.name,
      data.active,
      data.is_enterprise,
      data.subdomain,
      data.account_purpose,
      data.account_purpose_other,
      data.owner_role,
      data.owner_phone,
      data.account_size,
      data.implementation_time,
      data.referral_source,
      data.referral_source_other,
      newImage,
      data.dummy_data
    );
    this.newAccount = newAccount;
  }

  @action
  addOriginalAccount(data) {
    let newImage = ``;
    if (typeof data.image_file_name === `string`) {
      newImage = data.image_file_name;
    } else {
      newImage = I18n.t(`js.pg_icon_blue`);
    }
    const newAccount = new Account(
      data.id,
      data.name,
      data.active,
      data.is_enterprise,
      data.subdomain,
      data.account_purpose,
      data.account_purpose_other,
      data.owner_role,
      data.owner_phone,
      data.account_size,
      data.implementation_time,
      data.referral_source,
      data.referral_source_other,
      newImage,
      data.dummy_data
    );
    this.originalAccount = newAccount;
  }

  @action
  addIntegrationToken(data) {
    const newIntegrationToken = new IntegrationToken(
      data.id,
      data.user_id,
      data.account_id,
      data.name,
      data.token,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.integrationTokens = _.unionBy([newIntegrationToken], this.integrationTokens, 'id');
  }

  @action
  updateAccount(data, attribute) {
    this.newAccount[attribute] = data;
  }

  @action
  resetAccountAttribute(attribute) {
    this.newAccount[attribute] = this.originalAccount[attribute];
  }

  @action
  removeIntegrationToken(id) {
    this.integrationTokens = _.filter(this.integrationTokens, o => o.id !== id);
  }

  @action
  fetchAccount(accountId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/accounts/${accountId}`)
        .then(response => {
          this.addAccount(response.data);
          this.addOriginalAccount(response.data);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchIntegrationTokens() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/integration_tokens`)
        .then(response => {
          _.map(response.data, token => {
            this.addIntegrationToken(token);
          });
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  changeAccount(attributes) {
    const that = this;
    const newObj = {};
    _.map(attributes, attribute => {
      newObj[_.snakeCase(attribute)] = this.newAccount[attribute];
    });
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/accounts/${that.newAccount.id}`, newObj)
        .then(response => {
          that.addAccount(response.data);
          that.addOriginalAccount(response.data);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  changeAccountEnterprise(account) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/accounts/${account.id}/enterprise`, { is_enterprise: account.is_enterprise })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  changeAccountImage(formData) {
    const that = this;
    const config = {
      processData: false,
      contentType: false,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/accounts/${that.newAccount.id}`, formData, config)
        .then(response => {
          that.addAccount(response.data);
          that.addOriginalAccount(response.data);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  deleteIntegrationToken(integrationTokenId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/integration_tokens/${integrationTokenId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  deleteAccount() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/accounts/${this.newAccount.id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.activeTab = 'details';
    this.originalAccount = new Account();
    this.newAccount = new Account();
    this.integrationTokens = [];
  }
}

const store = new AccountSettingsStore();
export default store;
