import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tabs, Tab } from '@blueprintjs/core';
import UsageDefinitions from '../../static/UsageDefinitions';
import UsageMetric from '../shared/UsageMetric';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsUsage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabId: 'users',
    };
  }

  handleTabChange(tab) {
    this.setState({ selectedTabId: tab });
  }

  render() {
    return (
      <Flexbox flexDirection="row">
        <Flexbox flexDirection="column" flexGrow={0} marginRight="20px">
          <Tabs id="accountUsageTabs" vertical={true} onChange={this.handleTabChange.bind(this)} selectedTabId={this.state.selectedTabId}>
            <Tab id="users" title={I18n.t('js.users_and_relationships')} />
            <Tab id="apps" title={I18n.t('js.apps_and_items')} />
            <Tab id="teams" title={I18n.t('js.teams')} />
          </Tabs>
        </Flexbox>
        <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
          {UsageDefinitions.accountUsageTypes.map(usage => {
            if (usage.tab === this.state.selectedTabId) {
              return <UsageMetric usage={usage} key={usage.title} />;
            }
          })}
        </Flexbox>
      </Flexbox>
    );
  }
}
