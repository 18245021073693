/**
 * The colors in comments below are copied and pasted from
 * Blueprint. They have not been tweaked from the defaults.
 *
 * Colors in the exported object have been modified to peoplegoal specifics.
 *
 * When you need a colour, you should use the consistent colours from here.
 */

// TODO $green1: #0a6640 !default;
// TODO $green2: #0d8050 !default;
// TODO $green3: #0f9960 !default;
// TODO $green4: #15b371 !default;
// TODO $green5: #3dcc91 !default;
// TODO $orange1: #a66321 !default;
// TODO $orange2: #bf7326 !default;
// TODO $orange3: #d9822b !default;
// TODO $orange4: #f29d49 !default;
// TODO $orange5: #ffb366 !default;
// TODO $red1: #a82a2a !default;
// TODO $red2: #c23030 !default;
// TODO $red3: #db3737 !default;
// TODO $red4: #f55656 !default;
// TODO $red5: #ff7373 !default;
// TODO // Extended colors
// TODO $vermilion1: #9e2b0e !default;
// TODO $vermilion2: #b83211 !default;
// TODO $vermilion3: #d13913 !default;
// TODO $vermilion4: #eb532d !default;
// TODO $vermilion5: #ff6e4a !default;
// TODO $rose1: #a82255 !default;
// TODO $rose2: #c22762 !default;
// TODO $rose3: #db2c6f !default;
// TODO $rose4: #f5498b !default;
// TODO $rose5: #ff66a1 !default;
// TODO $violet1: #5c255c !default;
// TODO $violet2: #752f75 !default;
// TODO $violet3: #8f398f !default;
// TODO $violet4: #a854a8 !default;
// TODO $violet5: #c274c2 !default;
// TODO $indigo1: #5642a6 !default;
// TODO $indigo2: #634dbf !default;
// TODO $indigo3: #7157d9 !default;
// TODO $indigo4: #9179f2 !default;
// TODO $indigo5: #ad99ff !default;
// TODO $cobalt1: #1f4b99 !default;
// TODO $cobalt2: #2458b3 !default;
// TODO $cobalt3: #2965cc !default;
// TODO $cobalt4: #4580e6 !default;
// TODO $cobalt5: #669eff !default;
// TODO $turquoise1: #008075 !default;
// TODO $turquoise2: #00998c !default;
// TODO $turquoise3: #00b3a4 !default;
// TODO $turquoise4: #14ccbd !default;
// TODO $turquoise5: #2ee6d6 !default;
// TODO $forest1: #1d7324 !default;
// TODO $forest2: #238c2c !default;
// TODO $forest3: #29a634 !default;
// TODO $forest4: #43bf4d !default;
// TODO $forest5: #62d96b !default;
// TODO $lime1: #728c23 !default;
// TODO $lime2: #87a629 !default;
// TODO $lime3: #9bbf30 !default;
// TODO $lime4: #b6d94c !default;
// TODO $lime5: #d1f26d !default;
// TODO $gold1: #a67908 !default;
// TODO $gold2: #bf8c0a !default;
// TODO $gold3: #d99e0b !default;
// TODO $gold4: #f2b824 !default;
// TODO $gold5: #ffc940 !default;
// TODO $sepia1: #63411e !default;
// TODO $sepia2: #7d5125 !default;
// TODO $sepia3: #96622d !default;
// TODO $sepia4: #b07b46 !default;
// TODO $sepia5: #c99765 !default;

export default {
  /* PRIMARIES */
  // These are referred to as 'blue's in app.scss
  purple1: `#0000fa`,
  purple2: `#0000fa`,
  purple3: `#0000fa`,
  purple4: `#0f0fff`,
  purple5: `#3636ff`,

  peoplegoalBlue: '#576076',

  /* NEUTRALS */
  white: '#fff',
  black: '#10161a',
  darkGray1: '#182026',
  darkGray2: '#202b33',
  darkGray3: '#293742',
  darkGray4: '#30404d',
  darkGray5: '#394b59',
  gray1: '#5c7080',
  gray2: '#738694',
  gray3: '#8a9ba8',
  gray4: '#a7b6c2',
  gray5: '#bfccd6',
  lightGray1: '#ced9e0',
  lightGray2: '#d8e1e8',
  lightGray3: '#e1e8ed',
  lightGray4: '#ebf1f5',
  // used as just lighter than white background
  lightGray5: '#f5f8fa',

  /* SECONDARIES */
};
