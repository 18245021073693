import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Spinner } from '@blueprintjs/core';
import AppEditStatus from './AppEditStatus';
import AppImportData from './AppImportData';
import AppEditDetails from './AppEditDetails';
import AppEditImage from './AppEditImage';
import AppEditStyle from './AppEditStyle';
import AppEditDefaults from './AppEditDefaults';
import AppEditPermissions from './AppEditPermissions';
import AppEditFolder from './AppEditFolder';
import AppEditWorkspace from './AppEditWorkspace';
import AppPublishApp from './AppPublishApp';
import AppDeleteApp from './AppDeleteApp';
import AppEditDummyData from './AppEditDummyData';
import AppBulkPdfExport from './AppBulkPdfExport';
import AppEditWebformEnabled from './AppEditWebformEnabled';
import GuideButton from '../guides/GuideButton';
import _ from 'lodash';

@inject('WorkspaceStore', 'AppStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppEditApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  componentDidMount() {
    this.props.AppStore.newApp = _.clone(this.props.AppStore.activeApp);
  }

  renderDeletApp() {
    if (this.props.AppStore.activeApp.workspaceId.length > 0) {
      return <AppDeleteApp />;
    }
    return undefined;
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    if (this.props.AppStore.activeApp.isStoreTemplate) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} width="100%">
          <AppEditDetails />
          <AppEditImage />
          <AppEditStyle />
          {this.renderDeletApp()}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1} width="100%">
        <Flexbox flexDirection="row" marginBottom="20px">
          <GuideButton action="app_settings" />
        </Flexbox>
        <AppEditDetails />
        <AppEditImage />
        <AppEditStyle />
        <AppEditStatus />
        <AppEditDefaults />
        <AppEditDummyData />
        <AppImportData />
        <AppBulkPdfExport />
        <AppEditPermissions />
        <AppEditFolder />
        <AppEditWorkspace />
        <AppEditWebformEnabled />
        {this.props.CurrentUserStore.isSuperAdmin ? <AppPublishApp /> : null}
        <AppDeleteApp />
      </Flexbox>
    );
  }
}
