import React from 'react';
import Flexbox from 'flexbox-react';
import { Classes } from '@blueprintjs/core';
import HookList from './HookList';
import NewHook from './NewHook';
import { inject, observer } from 'mobx-react';

@inject('CurrentUserStore', 'HookStore', 'ToastStore')
@observer
export default class Hooks extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  renderDialog() {}

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`}>{I18n.t('js.webhooks')}</h4>
        <NewHook />
        <HookList />
      </Flexbox>
    );
  }
}
