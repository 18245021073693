import React, { Component } from 'react';
import { observable, computed } from 'mobx';
import {
  Drawer,
  Position,
  Classes,
  Button,
  AnchorButton,
  Alert,
  Intent,
  Slider,
  FormGroup,
  Tooltip,
  Switch,
  InputGroup,
} from '@blueprintjs/core';
import WidgetStore from '../../stores/WidgetStore';
import CurrentUserStore from '../../stores/CurrentUserStore';
import Flexbox from 'flexbox-react';
import CommentsView from '../../views/CommentsView';
import WidgetPost from './WidgetPost';
import WidgetSchedule from './WidgetSchedule';
import ScheduledEvents from './ScheduledEvents';
import ScheduledEvent from './ScheduledEvent';
import { convertFromRaw, EditorState } from 'draft-js';
import RichTextEditor from '../shared/RichTextEditor';
import Dropzone from 'react-dropzone';
import Utilities from '../../utils/Utilities';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Avatar from 'react-avatar';
import GuideButton from '../guides/GuideButton';

@observer
export default class Widget extends Component {
  @observable
  newImage = '';
  @observable
  displayedImage = '';
  @observable
  hasChangedImage = false;

  @computed
  get widgetHasContent() {
    switch (this.props.widget.widget_type) {
      case 'post':
        return this.props.widget.content || this.props.widget.image_file_name;
      case 'schedule':
        return this.props.widget.content;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      isSaving: false,
      isHovering: false,
      showDeletionAlert: false,
      showNotificationAlert: false,
    };
  }

  componentDidMount() {
    if (this.props.widget.image_file_name) {
      this.displayedImage = this.props.widget.image_file_name;
    }
  }

  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  toggleDeleteAlert() {
    this.setState({ showDeletionAlert: !this.state.showDeletionAlert });
  }

  toggleNotifyAlert() {
    this.setState({ showNotificationAlert: !this.state.showNotificationAlert });
  }

  toggleSaving() {
    this.setState({ isSaving: !this.state.isSaving });
  }

  handleWidgetClick() {
    if (this.props.editable) {
      WidgetStore.setActiveWidget(this.props.widget);
      this.toggleDrawer();
    }
  }

  handleSave(e) {
    e.preventDefault();
    this.toggleSaving();
    let widgetObj = new FormData();
    widgetObj.append('id', WidgetStore.activeWidget.id);
    widgetObj.append('width', WidgetStore.activeWidget.width);
    widgetObj.append('comments_enabled', WidgetStore.activeWidget.comments_enabled);
    widgetObj.append('user_display_enabled', WidgetStore.activeWidget.user_display_enabled);
    widgetObj.append('show_on_dashboard', WidgetStore.activeWidget.show_on_dashboard);
    if (WidgetStore.activeWidget.title) {
      widgetObj.append('title', WidgetStore.activeWidget.title);
    }
    if (WidgetStore.activeWidget.content) {
      widgetObj.append('content', WidgetStore.activeWidget.content);
    }
    if (this.hasChangedImage) {
      widgetObj.append('image', this.newImage);
    }
    WidgetStore.changeWidget(widgetObj)
      .then(response => {
        this.toggleSaving();
        this.toggleDrawer();
      })
      .catch(err => {
        this.toggleSaving();
      });
  }

  handleCancel() {
    WidgetStore.setActiveWidget(null);
    this.toggleDrawer();
  }

  handleDelete() {
    this.toggleDeleteAlert();
    WidgetStore.deleteWidget(WidgetStore.activeWidget.id);
    this.handleCancel();
  }

  handleNotifyAllUsers() {
    this.toggleNotifyAlert();
    WidgetStore.notifyAllUsers(WidgetStore.activeWidget.id);
    this.handleCancel();
  }

  handleWidthChange(width) {
    WidgetStore.updateActiveWidgetAttribute(width, 'width');
  }

  handleToggleBool(attr) {
    WidgetStore.updateActiveWidgetAttribute(!WidgetStore.activeWidget[attr], attr);
  }

  handleRichTextAreaChange(content) {
    WidgetStore.activeWidget.content = content;
  }

  handleTitleChange(e) {
    WidgetStore.activeWidget.title = e.target.value;
  }

  handleDrop(images) {
    this.newImage = images[0];
    this.displayedImage = images[0].preview;
    this.hasChangedImage = true;
  }

  handleRemoveImage() {
    this.newImage = '';
    this.displayedImage = '';
    this.hasChangedImage = true;
  }

  renderSelectImageText() {
    if (typeof this.newImage !== 'object') {
      return <span>{I18n.t('js.select_image')}</span>;
    }
    return <span>{this.newImage.name}</span>;
  }

  renderImageDisplay() {
    if (this.displayedImage.length > 0) {
      return (
        <Flexbox flexGrow={1} justifyContent="flex-start" marginBottom="10px">
          <img alt="element" className="element-image" src={this.displayedImage} />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderRemoveImage() {
    if (this.newImage.length > 0 || this.displayedImage.length > 0) {
      return (
        <Button className="push-10-l" icon="trash" onClick={this.handleRemoveImage.bind(this)}>
          {I18n.t('js.remove_image')}
        </Button>
      );
    }
    return undefined;
  }

  renderUserDisplay() {
    if (this.props.widget.user_display_enabled) {
      const { widget } = this.props;
      return (
        <Flexbox flexGrow={0} flexDirection="row" paddingLeft="20px" marginBottom="10px">
          <Flexbox flexDirection="column" flexGrow={1}>
            <Flexbox className="bp3-text-muted bp3-small" alignItems="center">
              <Flexbox marginRight="10px">
                <Avatar src={widget.user.image_file_name} size={20} round={true} />
              </Flexbox>
              <small
                dangerouslySetInnerHTML={{
                  __html: I18n.t('js.widget_created_by_on_date', {
                    full_name: widget.user.full_name,
                    link: `/users/${widget.user.id}`,
                    date: moment.utc(widget.created_at).format('MMMM Do YYYY, h:mm a'),
                  }),
                }}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }

  renderComments() {
    if (this.props.widget.comments_enabled) {
      return (
        <Flexbox flexGrow={0} paddingLeft="20px" paddingRight="20px">
          <CommentsView
            commentable={this.props.widget}
            commentableType="Widget"
            stub={true}
            sortByNew={true}
            noTitle={false}
            showNone={false}
            canPin={this.props.editable}
            buttonText={I18n.t('js.comment')}
          />
        </Flexbox>
      );
    }
    return null;
  }

  renderContent() {
    switch (this.props.widget.widget_type) {
      case 'post':
        return <WidgetPost {...this.props} />;
      case 'schedule':
        return <WidgetSchedule {...this.props} />;
    }
  }

  renderEditableContent() {
    const { activeWidget } = WidgetStore;
    switch (activeWidget.widget_type) {
      case 'post':
        return (
          <Flexbox flexDirection="column">
            <FormGroup label={I18n.t('js.title')} labelFor="title" labelInfo={`(${I18n.t('js.optional')})`}>
              <InputGroup
                value={activeWidget.title ? activeWidget.title : ''}
                name="title"
                onChange={title => this.handleTitleChange(title)}
                placeholder={I18n.t('js.title')}
              />
            </FormGroup>
            <FormGroup label={I18n.t('js.post_content')} labelFor="content" labelInfo={`(${I18n.t('js.optional')})`}>
              <RichTextEditor
                content={activeWidget.content}
                handleChange={content => this.handleRichTextAreaChange(content)}
                placeholder={I18n.t('js.click_here_to_edit_content')}
                placeholderDisabled={true}
              />
            </FormGroup>
            <FormGroup label={I18n.t('js.select_image')} labelFor="image" labelInfo={`(${I18n.t('js.optional')})`}>
              {this.renderImageDisplay()}
              <Flexbox>
                <Dropzone onDrop={this.handleDrop.bind(this)} className="bp3-button bp3-icon-media">
                  {this.renderSelectImageText()}
                </Dropzone>
                {this.renderRemoveImage()}
              </Flexbox>
            </FormGroup>
          </Flexbox>
        );
      case 'schedule':
        return (
          <Flexbox flexDirection="column">
            <Flexbox marginBottom="10px">
              <GuideButton action="schedules" />
            </Flexbox>
            <FormGroup label={I18n.t('js.schedule_description')} labelFor="content" labelInfo={`(${I18n.t('js.optional')})`}>
              <RichTextEditor
                content={activeWidget.content}
                handleChange={content => this.handleRichTextAreaChange(content)}
                placeholder={I18n.t('js.click_here_to_edit_content')}
                placeholderDisabled={true}
              />
              <ScheduledEvents {...this.props} />
            </FormGroup>
          </Flexbox>
        );
    }
  }

  renderEditButton() {
    if (this.props.editable && this.state.isHovering) {
      return (
        <div className="absolute" style={{ right: '5px', top: '5px' }}>
          <AnchorButton small={true} minimal={true} icon="edit" onClick={this.handleWidgetClick.bind(this)}></AnchorButton>
        </div>
      );
    }
  }

  renderWidgetContainer() {
    return (
      <Flexbox
        className="bp3-card bp3-card-no-padding relative"
        flexDirection="column"
        flexGrow={1}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        {this.renderEditButton()}
        <Flexbox flexDirection="column" padding="20px" flexGrow={1}>
          {this.renderContent()}
        </Flexbox>
        {this.renderUserDisplay()}
        {this.renderComments()}
      </Flexbox>
    );
  }

  render() {
    if (this.props.editable) {
      return (
        <>
          {this.renderWidgetContainer()}
          {WidgetStore.activeWidget ? (
            <form onSubmit={this.handleSave.bind(this)}>
              <Drawer
                portalContainer={document.body}
                position={Position.RIGHT}
                onClose={this.toggleDrawer.bind(this)}
                isOpen={this.state.drawerOpen}
                canOutsideClickClose={false}
                icon={this.props.widget.widget_type === 'post' ? 'add-to-artifact' : 'calendar'}
                title={I18n.t('js.edit_widget', { type: _.capitalize(this.props.widget.widget_type) })}
              >
                {WidgetStore.activeEvent ? (
                  <Flexbox className={Classes.DRAWER_BODY} flexDirection="column" padding="20px" flexGrow={1}>
                    <ScheduledEvent />
                  </Flexbox>
                ) : (
                  <>
                    <Flexbox className={Classes.DRAWER_BODY} flexDirection="column" padding="20px" flexGrow={1}>
                      <Flexbox flexDirection="column" flexGrow={1}>
                        {WidgetStore.activeWidget.widget_type === 'post' ? (
                          <Flexbox flexDirection="row" marginBottom="20px">
                            <GuideButton action="posts" />
                            <Button
                              icon="notifications"
                              intent={Intent.SUCCESS}
                              className="push-10-l"
                              onClick={this.toggleNotifyAlert.bind(this)}
                              text={I18n.t('js.notify_all_users')}
                            />
                          </Flexbox>
                        ) : null}
                        {this.renderEditableContent()}
                        <Flexbox flexDirection="column" marginTop="20px">
                          <Switch
                            className="bp3-control-no-margin"
                            checked={WidgetStore.activeWidget.user_display_enabled}
                            label={I18n.t('js.user_display_enabled')}
                            onChange={() => this.handleToggleBool('user_display_enabled')}
                          />
                        </Flexbox>
                        <Flexbox flexDirection="column" marginTop="20px">
                          <Switch
                            className="bp3-control-no-margin"
                            checked={WidgetStore.activeWidget.comments_enabled}
                            label={I18n.t('js.comments_enabled')}
                            onChange={() => this.handleToggleBool('comments_enabled')}
                          />
                        </Flexbox>
                        {WidgetStore.activeWidget.widget_type == 'post' ? (
                          <Flexbox flexDirection="column" marginTop="20px">
                            <Switch
                              className="bp3-control-no-margin"
                              disabled={!CurrentUserStore.userIsAccountOwner}
                              checked={WidgetStore.activeWidget.show_on_dashboard}
                              label={I18n.t('js.show_on_dashboard_company_news')}
                              onChange={() => this.handleToggleBool('show_on_dashboard')}
                            />
                          </Flexbox>
                        ) : null}
                        <Flexbox flexDirection="column" marginTop="20px">
                          <FormGroup label={I18n.t('js.widget_width')} labelFor="width">
                            <Flexbox width="100px">
                              <Slider
                                name="width"
                                value={WidgetStore.activeWidget.width}
                                labelStepSize={1}
                                min={1}
                                max={4}
                                onChange={this.handleWidthChange.bind(this)}
                              />
                            </Flexbox>
                          </FormGroup>
                        </Flexbox>
                      </Flexbox>
                    </Flexbox>
                    <Flexbox className={Classes.DRAWER_FOOTER} flexDirection="column" padding="20px" flexGrow={0}>
                      <Flexbox flexDirection="row">
                        <Flexbox flexDirection="row">
                          <Button
                            type="submit"
                            intent={Intent.PRIMARY}
                            loading={this.state.isSaving}
                            icon="floppy-disk"
                            onClick={this.handleSave.bind(this)}
                            text={I18n.t('js.save_changes')}
                            className="push-10-r"
                          />
                          <Button icon="cross" className="push-10-r" onClick={this.handleCancel.bind(this)} text={I18n.t('js.cancel')} />
                          <Button icon="trash" onClick={this.toggleDeleteAlert.bind(this)} text={I18n.t('js.delete')} />
                        </Flexbox>
                        <Alert
                          portalContainer={document.body}
                          isOpen={this.state.showDeletionAlert}
                          cancelButtonText={I18n.t('js.cancel')}
                          onCancel={() => this.toggleDeleteAlert()}
                          confirmButtonText={I18n.t('js.delete')}
                          onConfirm={() => this.handleDelete()}
                          intent={Intent.DANGER}
                        >
                          <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
                          {I18n.t('js.are_you_sure_you_wish_to_delete_this_widget')}
                        </Alert>
                        <Alert
                          portalContainer={document.body}
                          isOpen={this.state.showNotificationAlert}
                          cancelButtonText={I18n.t('js.cancel')}
                          onCancel={() => this.toggleNotifyAlert()}
                          confirmButtonText={I18n.t('js.notify_all_users')}
                          onConfirm={() => this.handleNotifyAllUsers()}
                          intent={Intent.SUCCESS}
                        >
                          <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
                          {I18n.t('js.are_you_sure_you_wish_to_notify_all_users')}
                        </Alert>
                      </Flexbox>
                    </Flexbox>
                  </>
                )}
              </Drawer>
            </form>
          ) : null}
        </>
      );
    } else {
      if (this.widgetHasContent) {
        return <Flexbox flexDirection="column">{this.renderWidgetContainer()}</Flexbox>;
      }
    }
    return null;
  }
}
