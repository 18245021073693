/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import UserAvatar from '../shared/UserAvatar';
import StatusTag from '../shared/StatusTag';
import renderHTML from 'react-render-html';
import classNames from 'classnames';
import ActivityGridDisplay from './ActivityGridDisplay';
import ActivityMultilineDisplay from './ActivityMultilineDisplay';
import FormElementDisplay from '../form_elements/FormElementDisplay';
import FormElementSlider from '../form_elements/FormElementSlider';
import FormElementRangeSlider from '../form_elements/FormElementRangeSlider';
import FormElementLocationSelect from '../form_elements/FormElementLocationSelect';
import CommentsView from '../../views/CommentsView';
import NewComment from '../comments/NewComment';
import Utilities from '../../utils/Utilities';

@inject('ActivityStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class Activity extends React.Component {
  @observable
  hideActivity = false;

  constructor(props) {
    super(props);

    this.state = { commentEnabled: false, showCommentButton: false };
  }

  addHover() {
    this.setState({ showCommentButton: true });
  }

  removeHover() {
    this.setState({ showCommentButton: false });
  }

  setCommentEnabled() {
    this.setState({ commentEnabled: true, showCommentButton: false });
  }

  componentDidMount() {
    const { activity } = this.props;
    if (!this.props.alwaysShow) {
      if (activity.duplicate) {
        this.hideActivity = true;
      }
      if (activity.eventName === 'deleted_item_value') {
        if (activity.element.elementType === 'grid') {
          this.hideActivity = true;
        }
      }
      if (activity.eventName === 'updated_item_value' || activity.eventName === 'created_item_value') {
        if (activity.element.elementType === 'select' && !activity.element.formValues[0].booleanValue) {
          this.hideActivity = true;
        }
      }
    }
  }
  handleAfterCommentCreated() {
    this.props.activity.commentCount++;
    this.handleCancelComment();
  }

  handleCancelComment() {
    this.setState({
      commentEnabled: false,
      showCommentButton: false,
    });
  }

  validateInput() {
    // do nothing
  }

  renderEventTitle() {
    const { activity } = this.props;
    switch (activity.eventName) {
      case 'created_workspace':
      case 'published_workspace':
      case 'deleted_workspace':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/workspaces/${activity.workspace.id}`,
              })
            )}
          </p>
        );
      case 'created_workspace_folder':
      case 'deleted_workspace_folder':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/workspaces/${activity.workspaceFolder.workspaceId}`,
              })
            )}
          </p>
        );
      case 'created_app':
      case 'published_app':
      case 'deleted_app':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/apps/${activity.app.id}`,
              })
            )}
          </p>
        );
      case 'created_item':
      case 'deleted_item':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/items/${activity.item.id}`,
                item: activity.item.itemType,
              })
            )}
          </p>
        );
      case 'submitted_item':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/items/${activity.item.id}`,
                item: activity.item.itemType,
                stateName: activity.additionalInformation.toLowerCase(),
              })
            )}
          </p>
        );
      case 'created_team':
      case 'deleted_team':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/teams/${activity.team.id}`,
              })
            )}
          </p>
        );
      case 'created_user_team':
      case 'deleted_user_team':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/teams/${activity.userTeam.teamId}`,
                objectName: activity.userTeam.team.name ? activity.userTeam.team.name : I18n.t('js.team'),
              })
            )}
          </p>
        );
      case 'created_user_team_request':
      case 'approved_user_team_request':
      case 'deleted_user_team_request':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/teams/${activity.userTeamRequest.teamId}`,
                objectName: activity.userTeamRequest.team.name ? activity.userTeamRequest.team.name : I18n.t('js.team'),
              })
            )}
          </p>
        );
      case 'created_item_value':
      case 'updated_item_value':
      case 'deleted_item_value':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.email ? activity.email : activity.user.fullName,
                userLink: activity.email ? activity.email : `/users/${activity.user.id}`,
                objectLink: `/items/${activity.formValues[0].itemId}`,
                itemValueName: activity.element[activity.element.camelCaseTitle].name,
              })
            )}
          </p>
        );
      case 'created_profile_value':
      case 'updated_profile_value':
      case 'deleted_profile_value':
        return (
          <p>
            {renderHTML(
              I18n.t(`js.activity_${activity.eventName}`, {
                userName: activity.user.fullName,
                userLink: `/users/${activity.user.id}`,
                objectLink: `/users/${activity.formValues[0].userId}?tab=account`,
                profileValueName: activity.element[activity.element.camelCaseTitle].name,
              })
            )}
          </p>
        );
      default:
        return null;
    }
  }

  renderDetail() {
    let iconClass = classNames('fa fa-lg fa-fw');
    const { activity } = this.props;
    const { eventName } = activity;
    if (activity.email) {
      console.log(activity);
    }
    switch (eventName) {
      case 'created_workspace':
      case 'published_workspace':
      case 'deleted_workspace':
        iconClass = classNames(iconClass, activity.workspace.icon);
        return (
          <Flexbox
            flexDirection="row"
            alignItems="center"
            className="bp3-cursor-pointer"
            onClick={() => Utilities.navigate(`/workspaces/${activity.workspace.id}`)}
          >
            <Flexbox className={iconClass} style={{ color: activity.workspace.color }} marginRight="5px" />
            <div>{activity.workspace.name}</div>
            {this.renderTag()}
          </Flexbox>
        );
      case 'created_workspace_folder':
      case 'deleted_workspace_folder':
        return (
          <Flexbox
            flexDirection="row"
            alignItems="center"
            className="bp3-cursor-pointer"
            onClick={() => Utilities.navigate(`/workspaces/${activity.workspaceFolder.workspaceId}`)}
          >
            <Flexbox className="bp3-icon bp3-icon-folder-open bp3-text-muted" marginRight="5px" />
            <Flexbox>{activity.workspaceFolder.name}</Flexbox>
            {this.renderTag()}
          </Flexbox>
        );
      case 'created_app':
      case 'published_app':
      case 'deleted_app':
        iconClass = classNames(iconClass, activity.app.icon);
        return (
          <Flexbox
            flexDirection="row"
            alignItems="center"
            className="bp3-cursor-pointer"
            onClick={() => Utilities.navigate(`/apps/${activity.app.id}`)}
          >
            <Flexbox className={iconClass} style={{ color: activity.app.color }} marginRight="5px" />
            <Flexbox>{activity.app.name}</Flexbox>
            {this.renderTag()}
          </Flexbox>
        );
      case 'created_item':
      case 'submitted_item':
      case 'deleted_item':
        iconClass = classNames(iconClass, activity.item.itemIcon);
        return (
          <Flexbox
            flexDirection="row"
            alignItems="center"
            className="bp3-cursor-pointer"
            onClick={() => Utilities.navigate(`/items/${activity.item.id}`)}
          >
            <Flexbox className={iconClass} style={{ color: activity.item.itemColor }} marginRight="5px" />
            <Flexbox>{activity.item.name}</Flexbox>
            {this.renderTag()}
          </Flexbox>
        );
      case 'created_team':
      case 'deleted_team':
        return (
          <Flexbox
            flexDirection="row"
            alignItems="center"
            className="bp3-cursor-pointer"
            onClick={() => Utilities.navigate(`/teams/${activity.team.id}`)}
          >
            <Flexbox marginRight="5px">
              <Avatar src={activity.team.imageFileName} size={20} round={true} />
            </Flexbox>
            <Flexbox>{activity.team.name}</Flexbox>
            {this.renderTag()}
          </Flexbox>
        );
      case 'approved_user_team_request':
      case 'deleted_user_team_request':
        return (
          <Flexbox
            flexDirection="row"
            alignItems="center"
            className="bp3-cursor-pointer"
            onClick={() => Utilities.navigate(`/teams/${activity.userTeamRequest.teamId}?tab=requests`)}
          >
            <UserAvatar user={activity.userTeamRequest.user} avatarSize={20} />
          </Flexbox>
        );
      case 'created_item_value':
      case 'updated_item_value':
      case 'deleted_item_value':
      case 'created_profile_value':
      case 'updated_profile_value':
      case 'deleted_profile_value':
        switch (activity.element.elementType) {
          case 'multiline':
            return <ActivityMultilineDisplay element={activity.element} />;
          case 'grid':
            return <ActivityGridDisplay element={activity.element} />;
          case 'slider':
            return (
              <FormElementSlider
                element={activity.element}
                validateInput={this.validateInput.bind(this)}
                hasError={false}
                showError={false}
                noTitle={true}
              />
            );
          case 'range_slider':
            return (
              <FormElementRangeSlider
                element={activity.element}
                validateInput={this.validateInput.bind(this)}
                hasError={false}
                showError={false}
                noTitle={true}
              />
            );
          case 'location_select':
            return (
              <FormElementLocationSelect
                element={activity.element}
                validateInput={this.validateInput.bind(this)}
                hasError={false}
                showError={false}
                noTitle={true}
              />
            );
          default:
            return (
              <Flexbox flexDirection="row" alignItems="center" marginTop="10px">
                <FormElementDisplay element={activity.element} noTitle={true} showLabels={true} />
              </Flexbox>
            );
        }
      default:
        return null;
    }
  }

  renderTag() {
    const { activity } = this.props;
    const { eventName } = activity;
    if (eventName.includes('delete')) {
      return (
        <Flexbox marginLeft="10px">
          <StatusTag name={I18n.t('js.deleted')} color="#DB3737" />
        </Flexbox>
      );
    }
    if (activity.statusFlag) {
      return (
        <Flexbox marginLeft="10px" paddingTop="5px">
          <StatusTag name={activity.statusFlag.name} color={activity.statusFlag.color} />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderNewComment() {
    if (this.state.showCommentButton && !this.state.commentEnabled) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" justifyContent="center" className="position-relative">
          <Button onClick={this.setCommentEnabled.bind(this)} className="bp3-minimal activity-comment-button">
            <span className="fa far fa-comments bp3-text-muted" />
            <span className="push-10-l">{I18n.t('js.comment')}</span>
          </Button>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    if (this.hideActivity) {
      return null;
    }
    const { activity } = this.props;
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={1}
        className="activity"
        onMouseEnter={this.addHover.bind(this)}
        onMouseLeave={this.removeHover.bind(this)}
      >
        <Flexbox flexGrow={0} marginRight="20px">
          <a href={`/users/${activity.user.id}`}>
            <Avatar src={activity.user.imageFileName} size={40} round={true} />
          </a>
        </Flexbox>
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox flexDirection="row" flexGrow={1}>
            <Flexbox flexDirection="row" flexGrow={1}>
              {this.renderEventTitle()}
            </Flexbox>
            <Flexbox marginLeft="20px" className="no-wrap" alignItems="center">
              <span className="bp3-icon bp3-icon-calendar bp3-text-muted push-10-r" />
              <small className="bp3-text-muted">{activity.createdAtToDate}</small>
            </Flexbox>
          </Flexbox>
          <Flexbox>{this.renderDetail()}</Flexbox>
          <CommentsView commentable={activity} commentableType="Activity" canPin={false} />
          {this.renderNewComment()}
          <NewComment
            commentableId={activity.id}
            commentableType="Activity"
            commentEnabled={this.state.commentEnabled}
            afterCreate={this.handleAfterCommentCreated.bind(this)}
            handleCancel={this.handleCancelComment.bind(this)}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
