import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tree, Classes, HTMLSelect, Button } from '@blueprintjs/core';
import AppItemsNewItem from '../app_items/AppItemsNewItem';
import DynamicUserSuggest from './DynamicUserSuggest';
import DynamicMultiSelectActions from './DynamicMultiSelectActions';
import _ from 'lodash';

@inject('DynamicViewStore', 'AppStore', 'WorkspaceStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynamicTeamView extends React.Component {
  handleNodeInteract(nodeData) {
    switch (nodeData.nodeType) {
      case 'team':
        const teamId = nodeData.id;
        this.props.DynamicViewStore.toggleTeam(teamId);
        break;
      case 'user':
        const userId = nodeData.id;
        this.props.DynamicViewStore.toggleUser(userId);
        break;
      case 'item':
        // const itemId = nodeData.id;
        // window.location = `/items/${itemId}`;
        break;
    }
  }

  handleNodeClick(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeCollapse(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeExpand(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  renderAppSelect() {
    const { getSelectedAppId } = this.props.DynamicViewStore;
    const { apps } = this.props.AppStore;
    const { filteredWorkspaces } = this.props.WorkspaceStore;

    if (this.props.allowAppSelect) {
      return (
        <Flexbox className="bp3-select" marginRight="10px">
          <HTMLSelect disabled={apps.length <= 1} value={getSelectedAppId} onChange={this.props.handleAppChange.bind(this)}>
            <option key={0} value={'all'}>
              {I18n.t('js.all_apps')}
            </option>
            {_.map(filteredWorkspaces, workspace => {
              return (
                <React.Fragment key={workspace.id}>
                  <option key={workspace.id} disabled={true} value={workspace.id}>
                    {workspace.name}
                  </option>
                  {_.map(apps, app => {
                    if (app.workspaceId === workspace.id && app.userPermissionLevel.name !== 'access_denied') {
                      return (
                        <option key={app.id} value={app.id}>
                          {app.name}
                        </option>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </HTMLSelect>
        </Flexbox>
      );
    }
  }

  render() {
    const {
      teamTree,
      participants,
      statusFlags,
      isLoadingParticipants,
      isLoadingStatusFlags,
      activeApp,
      selectedTeamId,
    } = this.props.DynamicViewStore;

    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row" marginBottom="10px">
          <Flexbox flexDirection="row" flexGrow={1}>
            {this.renderAppSelect()}
            <Flexbox className="bp3-select" marginRight="10px">
              <HTMLSelect disabled={activeApp === null || isLoadingParticipants} onChange={this.props.handleParticipantChange.bind(this)}>
                <option defaultValue value={null}>
                  {I18n.t('js.all_participants')}
                </option>
                {_.map(participants, participant => (
                  <option key={participant.id} value={participant.id}>
                    {participant.name}
                  </option>
                ))}
              </HTMLSelect>
            </Flexbox>
            <Flexbox className="bp3-select" marginRight="10px">
              <HTMLSelect
                disabled={activeApp === null || isLoadingStatusFlags}
                value={this.props.DynamicViewStore.activeStatusFlag?.id}
                onChange={this.props.handleStatusFlagChange.bind(this)}
              >
                <option defaultValue value="reset">
                  {I18n.t('js.any_current_status')}
                </option>
                {_.map(statusFlags, statusFlag => (
                  <option key={statusFlag.id} value={statusFlag.id}>
                    {statusFlag.name}
                  </option>
                ))}
              </HTMLSelect>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <DynamicUserSuggest disabled={selectedTeamId === null} />
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <DynamicMultiSelectActions allowMultiSelect={true} />
        <Flexbox flexDirection="column" className="bp3-card bp3-card-no-padding bp3-cursor-pointer">
          <Tree
            contents={teamTree}
            onNodeClick={this.handleNodeClick.bind(this)}
            onNodeCollapse={this.handleNodeCollapse.bind(this)}
            onNodeExpand={this.handleNodeExpand.bind(this)}
            className={Classes.ELEVATION_0}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
