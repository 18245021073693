/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import ToastStore from './ToastStore';

class UserProfileRelationshipsStore {
  @observable isLoading = true;
  @observable relationships = [];
  @observable userRelationships = [];
  @observable currentRelationship = [];
  @observable activeTab = '';
  @observable userId = '';
  @observable isDialogOpen = false;
  @observable isDialogPatents = false;
  @observable isSubmitting = false;
  @observable subOrdinateUserDelta = [];
  @observable subUserRelationshipsToCreate = [];
  @observable superOrdinateUserDelta = [];
  @observable superUserRelationshipsToCreate = [];
  @observable isAlertOpen = false;
  @observable deleteType = '';
  @observable counterpartIdToDelete = '';

  @computed
  get superordinates() {
    const superordinates = [];
    _.map(this.userRelationships, rel => {
      if (rel.user_id == this.userId) {
        superordinates.push(rel);
      }
    });
    return superordinates;
  }

  @computed
  get subordinates() {
    const subordinates = [];
    _.map(this.userRelationships, rel => {
      if (rel.counterpart_user.id == this.userId) {
        subordinates.push(rel);
      }
    });
    return subordinates;
  }

  @action
  setIsParents(isParents) {
    this.isDialogPatents = isParents;
  }

  @action
  setUserId(id) {
    this.userId = id;
  }

  @action
  toggleIsAlertOpen() {
    this.isAlertOpen = !this.isAlertOpen;
  }

  @action
  toggleDialog() {
    this.isDialogOpen = !this.isDialogOpen;
  }

  @action
  toggleIsSubmitting() {
    this.isSubmitting = !this.isSubmitting;
  }

  @action
  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action
  resetStartingAttributes() {
    this.viewedUser = null;
    this.isLoading = true;
    this.relationships = [];
    this.userRelationships = [];
  }

  @action
  updateCurrentRelationship(userId, relationship) {
    if (this.isLoading == false) {
      this.toggleLoading();
    }
    this.activeTab = relationship.id;
    this.currentRelationship = relationship;
    this.fetchUserRelationships(userId, relationship.id);
  }

  @action
  fetchRelationships() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/relationships`)
        .then(response => {
          const relationships = _.orderBy(response.data, ['default'], ['desc']);
          this.relationships = relationships;
          resolve(response);
        })
        .catch(error => this.errorHandler(reject, error));
    });
  }

  @action
  fetchUserRelationships(userId, relationshipId) {
    if (this.activeTab != relationshipId) {
      this.activeTab = relationshipId;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_relationships?user_id=${userId}&relationship_id=${relationshipId}&`)
        .then(response => {
          if (this.isLoading == true) {
            this.toggleLoading();
          }
          this.userRelationships = response.data;
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  openAlert(e, counterpartId, type) {
    e.stopPropagation();
    this.deleteType = type;
    this.counterpartIdToDelete = counterpartId;
    this.toggleIsAlertOpen();
  }

  @action
  deleteEmployeeRelationship() {
    this[`${this.deleteType}`].map(em => {
      switch (this.deleteType) {
        case 'superordinates':
          if (em.counterpart_user.id == this.counterpartIdToDelete && this.userId == em.user_id) {
            this.sendDeleteRequest(em);
          }
          break;
        case 'subordinates':
          if (em.counterpart_user.id == this.userId && em.user_id == this.counterpartIdToDelete) {
            this.sendDeleteRequest(em);
          }
          break;
      }
    });
  }

  @action
  sendDeleteRequest(em) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/user_relationships/${em.id}`)
        .then(response => {
          resolve(response);
          this.fetchUserRelationships(this.userId, this.currentRelationship.id);
          this.toggleIsAlertOpen();
          this.deleteType = '';
          this.counterpartIdToDelete = '';
        })
        .catch(error => {
          reject(error);
          this.toggleIsAlertOpen();
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createUserRelationship(userId, counterpartUserId, relationshipId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/user_relationships`, {
          user_id: userId,
          counterpart_user_id: counterpartUserId,
          relationship_id: relationshipId,
        })
        .then(response => {
          this.fetchUserRelationships(this.userId, this.currentRelationship.id);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  resetDialog() {
    this.subOrdinateUserDelta = [];
    this.subUserRelationshipsToCreate = [];
    this.subUserRelationshipsToDelete = [];
    this.superOrdinateUserDelta = [];
  }
}

const store = new UserProfileRelationshipsStore();
export default store;
