import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import ToastStore from '../stores/ToastStore';
import { Classes } from '@blueprintjs/core';

@observer
export default class AccountInactiveView extends React.Component {
  render() {
    const stores = {
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <Flexbox flexDirection="column" padding="20px">
          <h1 className={Classes.HEADING}>{I18n.t('js.account_is_inactive')}</h1>
          <p className="bp3-running-text push-10-t">{I18n.t('js.this_account_has_not_been_activated')}</p>
        </Flexbox>
      </Provider>
    );
  }
}
