/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent, MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import StoreCategory from '../../models/StoreCategoryModel';
import classNames from 'classnames';
import _ from 'lodash';

const StoreCategorySelect = MultiSelect.ofType(StoreCategory);

@inject('StoreTemplateActions', 'StoreTemplateStore', 'ToastStore')
@observer
export default class StoreTemplateEditStoreCategories extends React.Component {
  @observable
  commsToAdd = [];
  @observable
  commsToDelete = [];

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      selectedStoreCategories: [],
      query: '',
    };
  }

  componentDidMount() {
    this.commsToAdd = [];
    this.commsToDelete = [];
    this.mapStoreCategories();
  }

  mapStoreCategories() {
    const newStoreCategories = [];
    _.map(this.props.StoreTemplateStore.activeStoreTemplate.storeCategories, storeCategory => {
      const findStoreCategory = _.find(this.props.StoreTemplateStore.storeCategories, comm => comm.fields.id === storeCategory.id);
      if (findStoreCategory) {
        findStoreCategory.changeType = 'original';
        newStoreCategories.push(findStoreCategory);
      }
    });
    this.setState({ selectedStoreCategories: newStoreCategories });
  }

  toggleOpen() {
    this.mapStoreCategories();
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();

    let totalCount = this.commsToAdd.length + this.commsToDelete.length;
    this.processCompletion(totalCount);

    _.map(this.commsToAdd, comm => {
      const storeCategoryStoreTemplateObj = {
        store_category_id: comm.fields.id,
        store_template_id: this.props.StoreTemplateStore.activeStoreTemplate.id,
      };
      this.props.StoreTemplateActions.createStoreCategoryStoreTemplate(storeCategoryStoreTemplateObj).then(() => {
        totalCount--;
        this.processCompletion(totalCount);
      });
    });
    _.map(this.commsToDelete, comm => {
      const findId = _.find(
        this.props.StoreTemplateStore.activeStoreTemplate.storeCategoryStoreTemplates,
        o => o.storeCategoryId === comm.fields.id
      );
      if (findId) {
        this.props.StoreTemplateActions.deleteStoreCategoryStoreTemplate(findId.id).then(() => {
          totalCount--;
          this.processCompletion(totalCount);
        });
      } else {
        totalCount--;
        this.processCompletion(totalCount);
      }
    });
  }

  processCompletion(count) {
    if (count === 0) {
      this.props.StoreTemplateActions.fetchStoreTemplate(this.props.StoreTemplateStore.activeStoreTemplate.id).then(response => {
        this.props.StoreTemplateStore.addStoreTemplate(response.data);
        this.props.StoreTemplateStore.setActiveStoreTemplate(response.data.id);
        this.toggleLoading();
        this.toggleOpen();
        this.mapStoreCategories();
        this.props.ToastStore.showToast(I18n.t('js.template_store_categories_updated'), 'success');
      });
    }
  }

  handleCancel() {
    this.props.StoreTemplateStore.resetEditedStoreTemplate();
    this.toggleOpen();
  }

  searchStoreCategory(e) {
    const query = e.target.value;
    this.setState({ query });
  }

  renderNoResults() {
    return <MenuItem disabled text={I18n.t('js.no_results_found')} />;
  }

  handleStoreCategorySelect(storeCategory) {
    const findStoreCategory = _.find(
      this.props.StoreTemplateStore.activeStoreTemplate.storeCategories,
      o => o.id === storeCategory.fields.id
    );
    if (!findStoreCategory) {
      storeCategory.changeType = 'added';
      this.commsToAdd = _.unionBy([storeCategory], this.commsToAdd, 'sys.id');
    } else {
      this.commsToDelete = _.filter(this.commsToDelete, o => o.fields.id !== storeCategory.fields.id);
    }
    this.setState({
      selectedStoreCategories: _.concat(this.state.selectedStoreCategories.slice(), [storeCategory]),
    });
  }

  handleStoreCategoryRemove(storeCategory) {
    const storeCategoryId = storeCategory.props.id;
    const findStoreCategory = _.find(this.state.selectedStoreCategories.slice(), o => o.fields.id === storeCategoryId);
    if (findStoreCategory.changeType === 'original') {
      this.commsToDelete = _.unionBy([findStoreCategory], this.commsToDelete, 'sys.id');
    } else {
      this.commsToAdd = _.filter(this.commsToAdd, o => o.fields.id !== storeCategoryId);
    }
    const mewStoreCategories = _.filter(this.state.selectedStoreCategories.slice(), o => o.fields.id !== storeCategoryId);
    this.setState({ selectedStoreCategories: mewStoreCategories });
  }

  filterStoreCategories(query) {
    let possibleStoreCategories = _.filter(this.props.StoreTemplateStore.storeCategories, o =>
      o.fields.title.toLowerCase().includes(query)
    );

    // filter out selected storeCategories
    _.map(this.state.selectedStoreCategories, storeCategory => {
      possibleStoreCategories = _.filter(possibleStoreCategories, o => o.fields.id !== storeCategory.fields.id);
    });

    return possibleStoreCategories;
  }

  renderTag(storeCategory) {
    return <span id={storeCategory.fields.id}>{storeCategory.fields.title}</span>;
  }

  renderStoreCategory(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return <MenuItem className={menuClass} key={item.fields.id} onClick={details.handleClick} text={item.fields.title} />;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.store_category')}
            </h4>
            <p className="noselect">{I18n.t('js.template_store_categories_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column" marginBottom="10px">
              <StoreCategorySelect
                className="max-width"
                resetOnSelect={true}
                items={this.props.StoreTemplateStore.storeCategories}
                itemListPredicate={this.filterStoreCategories.bind(this)}
                selectedItems={this.state.selectedStoreCategories}
                tagRenderer={this.renderTag.bind(this)}
                itemRenderer={this.renderStoreCategory.bind(this)}
                onItemSelect={this.handleStoreCategorySelect.bind(this)}
                tagInputProps={{
                  inputProps: {
                    placeholder: this.state.selectedStoreCategories.length > 0 ? '' : I18n.t('js.select_store_categories'),
                    value: this.state.query,
                    onChange: this.searchStoreCategory.bind(this),
                  },
                  className: 'bp3-tag-input',
                  onRemove: this.handleStoreCategoryRemove.bind(this),
                }}
                popoverProps={{
                  usePortal: false,
                  position: 'bottom-left',
                  className: '',
                  popoverClassName: 'bp3-minimal',
                }}
                noResults={this.renderNoResults()}
              />
            </Flexbox>
            <Flexbox>
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  type="submit"
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
