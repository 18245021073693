/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Spinner, Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class OrganizationTabs extends React.Component {
  renderManageTitle() {
    return <span>{I18n.t('js.manage_relationships')}</span>;
  }
  renderRightLinks() {
    if (this.props.loading) {
      return <Spinner size={20} />;
    }
    return (
      <Tabs id="OrganizationTabnav" selectedTabId="manage">
        <Tab id="manage" title={this.renderManageTitle()} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
