import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Intent, Dialog } from '@blueprintjs/core';
import { observable } from 'mobx';
import classNames from 'classnames';
import ToastStore from '../../../stores/ToastStore';
import BambooIntegration from '../../../models/BambooIntegrationModel';
import Utilities from '../../../utils/Utilities';
import BambooIntegrationForm from './BambooIntegrationForm';
import BambooIntegrationFormLogic from './BambooIntegrationFormLogic';

@inject('BambooIntegrationStore', 'IntegrationStore', 'ToastStore')
@observer
export default class NewBambooIntegration extends React.Component {
  @observable
  showDialog = false;
  @observable
  errors = {};

  constructor(props) {
    super(props);
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }

  handleSubmit() {
    const BambooStore = this.props.BambooIntegrationStore;
    const {
      apiKey,
      subdomain,
      includeProfilePhotos,
      includeDepartments,
      includeManagers,
    } = BambooStore.bambooIntegration;

    if (apiKey != '' && subdomain != '') {
      const bambooIntegrationObj = {
        api_key: apiKey,
        subdomain: subdomain,
        include_profile_photos: includeProfilePhotos,
        include_departments: includeDepartments,
        include_managers: includeManagers,
      };
      BambooStore.createBambooIntegration(bambooIntegrationObj)
        .then(response => {
          BambooStore.updateIntegrationModel(response.data);
          this.props.IntegrationStore.setActiveTab(
            'bamboohr-integration'
          );
        })
        .catch(error => {});
    }
  }

  handleCancel() {
    this.toggleDialog();
  }

  renderBambooIntegrationDialog() {
    const canSave = this.props.BambooIntegrationStore.canSave;
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.connect_your_bamboohr_account')}
      >
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="bp3-dialog-body">
            <BambooIntegrationFormLogic>
              <BambooIntegrationForm />
            </BambooIntegrationFormLogic>
          </div>

          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.handleCancel.bind(this)}
              />
              <Button
                disabled={!canSave}
                intent={'PRIMARY'}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.connect')}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  renderBambooIntegration() {
    const { bambooIntegration } = this.props.BambooIntegrationStore;

    const buttonText =
      bambooIntegration != null && bambooIntegration.id != ''
        ? I18n.t('js.configure_bamboohr_integration')
        : I18n.t('js.connect_your_bamboohr_account');
    const onClick =
      bambooIntegration != null && bambooIntegration.id != ''
        ? () =>
            this.props.IntegrationStore.setActiveTab(
              'bamboohr-integration'
            )
        : this.toggleDialog.bind(this);

    return (
      <div>
        <Flexbox>
          <Button
            text={buttonText}
            icon="offline"
            onClick={onClick}
          />
        </Flexbox>
        {this.renderBambooIntegrationDialog()}
      </div>
    );
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.bamboo_hr')}
            </h4>
            <p className="noselect">
              {I18n.t('js.bamboo_hr_integration_text')}
            </p>
          </Flexbox>
        </Flexbox>
        {this.renderBambooIntegration()}
      </Flexbox>
    );
  }
}
