import React from 'react';
import Flexbox from 'flexbox-react';
import { AnchorButton, Slider, Tooltip } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

@inject('TeamsStore')
@observer
export default class TeamsGraphToolbar extends React.Component {
  toggleHierarchial() {
    this.props.TeamsStore.updateTeamGraph(
      !this.props.TeamsStore.teamGraph.hierarchical,
      'hierarchical'
    );
  }

  toggleSortMethod() {
    if (this.props.TeamsStore.teamGraph.sortMethod === 'hubsize') {
      this.props.TeamsStore.updateTeamGraph('directed', 'sortMethod');
    } else {
      this.props.TeamsStore.updateTeamGraph('hubsize', 'sortMethod');
    }
  }

  handleSetLevelSeparation(number) {
    this.props.TeamsStore.updateTeamGraph(number, 'levelSeparation');
  }

  handleSetNodeSpacing(number) {
    this.props.TeamsStore.updateTeamGraph(number, 'nodeSpacing');
  }

  renderHierarchicalAnchorButton() {
    if (this.props.TeamsStore.teamGraph.hierarchical) {
      return (
        <Tooltip
          portalContainer={document.body}
          content={I18n.t('js.hierarchical_layout')}
          inheritDarkTheme={false}
          position="top"
        >
          <AnchorButton
            icon="layout-hierarchy"
            tabIndex="0"
            onClick={this.toggleHierarchial.bind(this)}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t('js.auto_layout')}
        inheritDarkTheme={false}
        position="top"
      >
        <AnchorButton
          icon="layout-auto"
          tabIndex="0"
          onClick={this.toggleHierarchial.bind(this)}
        />
      </Tooltip>
    );
  }

  renderPhysics() {
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t('js.physics_enabled')}
        inheritDarkTheme={false}
        position="top"
      >
        <AnchorButton
          icon="exchange"
          tabIndex="0"
          onClick={() =>
            this.props.TeamsStore.updateTeamGraph(
              !this.props.TeamsStore.teamGraph.physics,
              'physics'
            )
          }
          active={this.props.TeamsStore.teamGraph.physics}
          disabled={!this.props.TeamsStore.teamGraph.hierarchical}
        />
      </Tooltip>
    );
  }

  renderSortMethod() {
    if (this.props.TeamsStore.teamGraph.sortMethod === 'hubsize') {
      return (
        <Tooltip
          portalContainer={document.body}
          content={I18n.t('js.sort_by_hubsize')}
          inheritDarkTheme={false}
          position="top"
        >
          <AnchorButton
            icon="layout-circle"
            tabIndex="0"
            onClick={this.toggleSortMethod.bind(this)}
            disabled={!this.props.TeamsStore.teamGraph.hierarchical}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t('js.sort_by_direction')}
        inheritDarkTheme={false}
        position="top"
      >
        <AnchorButton
          icon="layout-sorted-clusters"
          tabIndex="0"
          onClick={this.toggleSortMethod.bind(this)}
          disabled={!this.props.TeamsStore.teamGraph.hierarchical}
        />
      </Tooltip>
    );
  }

  renderDirectionAnchorButton(direction) {
    const iconName = dir => {
      switch (dir) {
        case 'UD':
          return 'arrow-up';
        case 'DU':
          return 'arrow-down';
        case 'LR':
          return 'arrow-left';
        case 'RL':
          return 'arrow-right';
        default:
          throw new Error('direction not accounted for');
      }
    };
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t(`js.${direction.toLowerCase()}`)}
        inheritDarkTheme={false}
        position="top"
      >
        <AnchorButton
          tabIndex="0"
          icon={iconName(direction)}
          onClick={() =>
            this.props.TeamsStore.updateTeamGraph(
              direction,
              'direction'
            )
          }
          active={
            this.props.TeamsStore.teamGraph.direction === direction
          }
          disabled={!this.props.TeamsStore.teamGraph.hierarchical}
        />
      </Tooltip>
    );
  }

  render() {
    return (
      <Flexbox
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        paddingTop="10px"
        flexDirection="column"
        marginBottom="20px"
      >
        <div className="bp3-button-group">
          {this.renderHierarchicalAnchorButton()}
          {this.renderPhysics()}
          {this.renderSortMethod()}
          {this.renderDirectionAnchorButton('UD')}
          {this.renderDirectionAnchorButton('DU')}
          {this.renderDirectionAnchorButton('LR')}
          {this.renderDirectionAnchorButton('RL')}
        </div>
        <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
          <Flexbox
            marginTop="10px"
            flexGrow={1}
            className="max-width"
            flexDirection="column"
            alignItems="center"
          >
            <Slider
              disabled={!this.props.TeamsStore.teamGraph.hierarchical}
              initialValue={0}
              value={this.props.TeamsStore.teamGraph.levelSeparation}
              labelStepSize={50}
              min={50}
              max={500}
              labelRenderer={false}
              onChange={this.handleSetLevelSeparation.bind(this)}
            />
            <small className="bp3-text-muted push-10-t">
              {I18n.t('js.level_separation')}
            </small>
          </Flexbox>
          <Flexbox
            marginTop="10px"
            marginLeft="20px"
            flexGrow={1}
            className="max-width"
            flexDirection="column"
            alignItems="center"
          >
            <Slider
              disabled={!this.props.TeamsStore.teamGraph.hierarchical}
              initialValue={0}
              value={this.props.TeamsStore.teamGraph.nodeSpacing}
              labelStepSize={50}
              min={50}
              max={500}
              labelRenderer={false}
              onChange={this.handleSetNodeSpacing.bind(this)}
            />
            <small className="bp3-text-muted push-10-t">
              {I18n.t('js.node_spacing')}
            </small>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
