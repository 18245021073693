import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import UserPermissionRow from './UserPermissionRow';
import PermissionAddUserPermission from './PermissionAddUserPermission';
import UserPermission from '../../models/UserPermissionModel';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('PermissionStore', 'WorkspaceStore', 'AppStore', 'ToastStore')
@observer
export default class UserPermissions extends React.Component {
  handleColumnSort(column) {
    this.props.PermissionStore.setUserPermissionSortParams(
      column.id,
      column.sortDir
    );
  }

  handleUserSelectChange(user) {
    const findUser = _.find(
      this.props.PermissionStore.userPermissions,
      o => o.userId === user.id
    );
    if (!findUser) {
      const newUserPermission = new UserPermission(
        Utilities.makeId(),
        user.id,
        this.props.type,
        'create_and_view',
        this.props.WorkspaceStore.activeWorkspace.id,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (this.props.type === 'app') {
        newUserPermission.workspaceId = null;
        newUserPermission.appId = this.props.AppStore.activeApp.id;
      }
      newUserPermission.user = user;
      this.props.PermissionStore.userPermissions = _.unionBy(
        [newUserPermission],
        this.props.PermissionStore.userPermissions,
        'id'
      );
      this.props.PermissionStore.sortUserPermissions();
    }
  }

  renderPermissions() {
    if (this.props.PermissionStore.userPermissions.length === 0) {
      return (
        <tbody>
          <tr>
            <td className="td-width-40">
              <span className="bp3-text-muted">
                {I18n.t('js.there_are_no_users_to_show')}
              </span>
            </td>
            <td className="td-width-40" />
            <td className="td-width-20" />
          </tr>
        </tbody>
      );
    }
    return (
      <tbody>
        {_.map(
          this.props.PermissionStore.userPermissions,
          userPermission => (
            <UserPermissionRow
              userPermission={userPermission}
              key={userPermission.id}
            />
          )
        )}
      </tbody>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginTop="20px">
        <h5 className={Classes.HEADING}>
          {I18n.t('js.user_permissions')}
        </h5>
        <p>{I18n.t('js.only_following_users')}</p>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader
              columns={
                this.props.PermissionStore.userPermissionColumns
              }
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          {this.renderPermissions()}
        </table>
        <Flexbox flexDirection="row" flexGrow={1} marginBottom="10px">
          <PermissionAddUserPermission
            handleUserSelectChange={this.handleUserSelectChange.bind(
              this
            )}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
