import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse } from '@blueprintjs/core';
import Imports from '../imports/Imports';

@inject('CurrentUserStore', 'AppStore', 'ToastStore')
@observer
export default class AppImportData extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.import_data')}
            </h4>
            <p className="noselect">
              {I18n.t('js.import_app_data_text')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <Imports
            importableType="App"
            importableId={this.props.AppStore.activeApp.id}
          />
        </Collapse>
      </Flexbox>
    );
  }
}
