import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, Switch, NonIdealState, Spinner, Icon } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import ItemWidget from '../items/ItemWidget';
import InviteWidget from '../items/InviteWidget';
import Paginator from '../shared/Paginator';
import renderHTML from 'react-render-html';
import _ from 'lodash';

@inject('AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DashboardTaskList extends React.Component {
  handleColumnSort(column) {
    this.props.DashboardStore.setItemSortParams(column.id, column.sortDir);
  }

  handleToggleShowDone() {
    if (this.props.DashboardStore.showDone === true) {
      this.props.DashboardStore.showDone = false;
    } else {
      this.props.DashboardStore.showDone = true;
    }
    this.handlePageChange(1);
  }

  handlePageChange(pageNumber) {
    this.props.DashboardStore.isLoading = true;
    this.props.DashboardStore.resetStartingAttributes();
    this.props.ItemActions.fetchUserItems(
      this.props.DashboardStore.showDone,
      pageNumber,
      this.props.DashboardStore.pagination.xPerPage,
      this.props.DashboardStore.pagination.xOffset
    )
      .then(response => {
        this.props.DashboardStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.DashboardStore.addItem(item);
        });
        this.props.ItemActions.fetchPinnedItemsForUser(this.props.CurrentUserStore.currentUser.id).then(response => {
          _.map(response.data, itemPin => {
            this.props.DashboardStore.addItemPin(itemPin);
          });
        });
        this.props.DashboardStore.isLoading = false;
      })
      .catch(() => {
        this.props.DashboardStore.isLoading = false;
      });
  }

  handlePerPageChange(e) {
    this.props.DashboardStore.isLoading = true;
    this.props.DashboardStore.resetStartingAttributes();
    const pageNumber = 1;
    this.props.ItemActions.fetchUserItems(
      this.props.DashboardStore.showDone,
      pageNumber,
      e.target.value,
      this.props.DashboardStore.pagination.xOffset
    )
      .then(response => {
        this.props.DashboardStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.DashboardStore.addItem(item);
        });
        this.props.ItemActions.fetchPinnedItemsForUser(this.props.CurrentUserStore.currentUser.id).then(response => {
          _.map(response.data, itemPin => {
            this.props.DashboardStore.addItemPin(itemPin);
          });
        });
        this.props.DashboardStore.isLoading = false;
      })
      .catch(() => {
        this.props.DashboardStore.isLoading = false;
      });
  }

  renderPagination() {
    if (this.props.DashboardStore.pagination.xTotal > this.props.DashboardStore.pagination.xPerPage) {
      return (
        <Paginator
          paginationObject={this.props.DashboardStore.pagination}
          pageRangeDisplayed={25}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderTaskCount() {
    if (this.props.DashboardStore.pagination.xTotal > 0) {
      return <span>({this.props.DashboardStore.pagination.xTotal})</span>;
    }
    return undefined;
  }

  renderSwitch() {
    return (
      <Flexbox flexGrow={0} marginBottom="20px">
        <Switch
          className="bp3-control-no-margin"
          checked={this.props.DashboardStore.showDone}
          label={I18n.t('js.include_items_marked_as_done')}
          onChange={this.handleToggleShowDone.bind(this)}
        />
      </Flexbox>
    );
  }

  renderItems() {
    if (this.props.DashboardStore.items.length > 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <table className="bp3-html-table bp3-html-table-condensed bp3-interactive">
            <thead>
              <SimpleTableHeader columns={this.props.DashboardStore.itemColumns} handleColumnSort={this.handleColumnSort.bind(this)} />
            </thead>
            <tbody>
              {_.map(this.props.DashboardStore.items, item => (
                <ItemWidget item={item} key={item.id} showActions={true} />
              ))}
            </tbody>
          </table>
          <Flexbox flexGrow={1} justifyContent="center">
            {this.renderPagination()}
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderItemSectionInvites() {
    if (this.props.DashboardStore.itemSectionInvites.length > 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} marginTop="40px">
          <h3 className="bp3-heading">{I18n.t('js.invitations')}</h3>
          <p>These are pending invitations for you to contribute to an item.</p>
          <table className="bp3-html-table bp3-html-table-condensed bp3-interactive">
            <tbody>
              {_.map(this.props.DashboardStore.itemSectionInvites, invite => (
                <InviteWidget invite={invite} key={invite.id} showActions={true} />
              ))}
            </tbody>
          </table>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderNoItems() {
    if (this.props.DashboardStore.items.length === 0 && this.props.DashboardStore.itemSectionInvites.length === 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} justifyContent="center" alignItems="center" flexDirection="column" className="animated zoomIn">
            <Flexbox marginBottom="20px" marginTop="20px">
              <img src={I18n.t('js.pg_calm_image')} alt="calm" className="calm-img" />
            </Flexbox>
            <h3 className={Classes.HEADING}>{I18n.t('js.youre_all_caught_up_relax_and_enjoy_the_moment')}</h3>
            <p className="bp3-text-muted">{I18n.t('js.you_have_no_outstanding_tasks')}</p>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderContent() {
    if (this.props.DashboardStore.isLoading) {
      return (
        <Flexbox flexDirection="row" flexGrow={1}>
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted push-20-t pad-20"
          />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderItems()}
          {this.renderItemSectionInvites()}
          {this.renderNoItems()}
        </Flexbox>
      );
    }
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={0} marginBottom="20px">
        <h3 className="bp3-heading">{I18n.t('js.action_list')}</h3>
        <p>{renderHTML(I18n.t('js.your_tasks_description'))}</p>
        {this.renderSwitch()}
        {this.renderContent()}
      </Flexbox>
    );
  }
}
