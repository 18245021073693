/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, extendObservable, observable } from 'mobx';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Participant from '../models/ParticipantModel';
import Relationship from '../models/RelationshipModel';
import User from '../models/UserModel';
import State from '../models/StateModel';
import StateSection from '../models/StateSectionModel';
import StateElement from '../models/StateElementModel';
import StatusFlag from '../models/StatusFlagModel';
import Section from '../models/SectionModel';
import Element from '../models/ElementModel';
import ElementMappingActions from '../actions/ElementMappingActions';
import Utilities from '../utils/Utilities';
import _ from 'lodash';

class TemplateStore {
  @observable newStateDialogOpen = false;
  @observable activeSubsection = '';
  @observable activeAnimation = '';
  @observable activeAreaId = null;
  @observable sections = [];
  @observable relationships = [];
  @observable participants = [];
  @observable states = [];
  @observable stateSections = [];
  @observable stateElements = [];
  @observable statusFlags = [];
  @observable activeSection = new Section();
  @observable activeElement = new Element();
  @observable oldActiveElement = new Element();
  @observable newSection = new Section();
  @observable activeState = new State();
  @observable newParticipant = new Participant();
  @observable newStatusFlag = new StatusFlag();
  @observable editParticipant = new Participant();
  @observable editStatusFlag = new StatusFlag();
  @observable newState = new State();
  @observable editState = new State();
  @observable participantColumns = _.clone(ColumnDefinitions.participantColumns);
  @observable statusFlagColumns = _.clone(ColumnDefinitions.statusFlagColumns);
  @observable key = Math.random();

  @computed
  get participantNames() {
    return this.participants.map(participant => participant.name);
  }

  @computed
  get allElements() {
    let elements = [];
    _.map(this.sections, section => {
      _.map(section.elements, element => {
        elements.push(element);
      });
    });
    return elements;
  }

  @computed
  get availableElementsForFormulaBuilder() {
    const elements = _.filter(this.allElements, o => o.isAvailableForFormulaBuilder);
    const availableElements = [];
    _.map(elements, element => {
      let icon = 'new-text-box';
      let type = 'string';
      switch (element.elementType) {
        case 'number':
        case 'currency':
        case 'percentage':
        case 'slider':
        case 'calculation':
          icon = 'numerical';
          type = 'number';
          break;
        case 'date':
        case 'date_time':
        case 'time':
          icon = 'timeline-events';
          type = 'datetime';
          break;
        case 'grid':
          icon = 'grid';
          type = 'number';
          break;
        case 'select':
          icon = 'selection';
          type = 'number';
          break;
      }
      if (element.id != this.activeElement.id) {
        availableElements.push({
          name: element[element.camelCaseTitle].name,
          id: element.id,
          icon: icon,
          type: type,
          implementation: `"element#${element.id}"`,
        });
      }
    });
    return availableElements;
  }

  @computed
  get availableDateElements() {
    const elements = _.filter(this.allElements, o => o.isDateRelated);
    return elements;
  }

  @computed
  get availableUserSelectElements() {
    const elements = _.filter(this.allElements, o => o.elementType == 'user_select');
    return elements;
  }

  @computed
  get invitableSections() {
    const sections = [];
    _.map(this.stateSections, state_section => {
      if (state_section.invitable) {
        const findSection = _.find(this.sections, o => o.id == state_section.sectionId);
        if (findSection) {
          sections.push(findSection);
        }
      }
    });
    return _.reverse(sections);
  }

  @computed
  get newStateFormValid() {
    return this.newState.nameValid && this.newState.statusFlagIdValid && this.newParticipant.nameValid;
  }

  @computed
  get newStatusFlagValid() {
    return this.newStatusFlag.nameValid && this.newStatusFlag.colorValid;
  }

  @computed
  get previousState() {
    const newIndex = this.states.indexOf(this.activeState) - 1;
    if (newIndex >= 0 && newIndex <= this.states.length) {
      return this.states[newIndex];
    }
    return null;
  }

  @computed
  get nextState() {
    const newIndex = this.states.indexOf(this.activeState) + 1;
    if (newIndex >= 0 && newIndex <= this.states.length) {
      return this.states[newIndex];
    }
    return null;
  }

  @action
  setActiveSubsection(tab) {
    this.activeSubsection = tab;
  }

  @action
  setActiveArea(id) {
    if (id === null) {
      this.activeAreaId = null;
    } else {
      if (id !== this.activeAreaId) {
        this.activeAreaId = id;
        _.map(this.sections, section => {
          const findElement = _.find(section.elements, element => element.id === id);
          if (findElement) {
            this.oldActiveElement = _.cloneDeep(findElement);
            this.activeElement = _.cloneDeep(findElement);
          }
        });
      }
    }
  }

  @action
  updateNewState(data, attribute) {
    this.newState[attribute] = data;
  }

  @action
  updateNewParticipant(data, attribute) {
    this.newParticipant[attribute] = data;
  }

  @action
  updateNewStatusFlag(data, attribute) {
    this.newStatusFlag[attribute] = data;
  }

  @action
  updateEditState(data, attribute) {
    this.editState[attribute] = data;
  }

  @action
  updateEditParticipant(data, attribute) {
    this.editParticipant[attribute] = data;
  }

  @action
  updateActiveElement(data, attribute) {
    if (attribute === 'minLength' || attribute === 'maxLength' || attribute === 'isRequired' || attribute === 'repeatable') {
      this.activeElement[attribute] = data;
    } else {
      this.activeElement[this.activeElement.camelCaseTitle][attribute] = data;
    }
  }

  @action
  setActiveState(state, doAnimation) {
    // FIXME this probably wasn't meant to be an expression
    // eslint-disable-next-line
    this.states.length === 1 ? (doAnimation = false) : null;
    if (doAnimation) {
      let firstAnim = 'fadeOutLeftBig';
      let lastAnim = 'fadeInRightBig';
      if (state.rowOrder < this.activeState.rowOrder) {
        firstAnim = 'fadeOutRightBig';
        lastAnim = 'fadeInLeftBig';
      }
      this.activeAnimation = firstAnim;
      setTimeout(() => {
        this.activeAnimation = lastAnim;
        this.activeState = state;
        this.editState = _.clone(state);
        this.editParticipant = _.find(this.participants, o => o.id === state.participantId);
        this.setCurrentStatusFlag(state);
        this.appendStatesToSectionBody();
      }, 1);
    } else {
      this.activeState = state;
      this.editState = _.clone(state);
      this.editParticipant = _.find(this.participants, o => o.id === state.participantId);
      this.setCurrentStatusFlag(state);
      this.appendStatesToSectionBody();
    }
  }

  @action
  setCurrentStatusFlag(state) {
    const statusFlag = _.find(this.statusFlags, o => o.id === state.statusFlagId);
    if (statusFlag) {
      this.editStatusFlag = statusFlag;
    }
  }

  @action
  addParticipant(data) {
    const newParticipant = new Participant(
      data.id,
      data.app_id,
      data.is_owner,
      data.name,
      data.visibility_level,
      data.relationship_id,
      data.user_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    if (data.user) {
      newParticipant.user = new User(
        data.user.id,
        data.user.email,
        data.user.first_name,
        data.user.last_name,
        data.user.full_name,
        data.user.account_id,
        data.user.human_friendly_id,
        data.user.is_current_user,
        data.user.is_account_owner,
        data.user.image_file_name,
        data.user.background_image_file_name,
        data.user.last_sign_in_at,
        data.user.locale,
        data.user.theme,
        data.user.created_at,
        data.user.updated_at,
        data.user.deleted_at,
        data.user.created_by,
        data.user.updated_by,
        data.user.deleted_by
      );
    }
    const findRelationship = _.find(this.relationships, o => o.id === newParticipant.relationshipId);
    if (findRelationship) {
      extendObservable(newParticipant, {
        relationship: findRelationship,
      });
    }

    this.participants = _.unionBy([newParticipant], this.participants, 'id');
    this.sortParticipants();
  }

  @action
  addRelationship(data) {
    const newRelationship = new Relationship(
      data.id,
      data.account_id,
      data.name,
      data.counterpart_name,
      data.description,
      data.default,
      data.lock_user_changes,
      data.lock_counterpart_user_changes,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );

    this.relationships = _.unionBy([newRelationship], this.relationships, 'id');
    this.sortRelationships();
  }

  @action
  addNullRelationship() {
    const newRelationship = new Relationship(
      Utilities.makeId(),
      null,
      I18n.t('js.reset'),
      null,
      null,
      false,
      false,
      false,
      null,
      null,
      null,
      null,
      null
    );
    this.relationships.push(newRelationship);
  }

  @action
  addState(data, type, doAnimate) {
    const newState = new State(
      data.id,
      data.app_id,
      data.participant_id,
      data.status_flag_id,
      data.name,
      data.all_visible,
      data.all_editable,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.stateSections = _.unionBy(this.mapStateSections(data.state_sections), this.stateSections, 'id');
    this.stateElements = _.unionBy(this.mapStateElements(data.state_elements), this.stateElements, 'id');

    this.states = _.unionBy([newState], this.states, 'id');
    this.appendStatesToSectionBody();
    this.sortStates();

    if (type === 'single') {
      const findState = _.find(this.states, o => o.id === newState.id);
      this.setActiveState(findState, doAnimate);
    } else {
      this.setActiveState(_.head(this.states), doAnimate);
    }

    this.appendStatesToSectionBody();
  }

  @action
  addStatusFlag(data) {
    const newStatusFlag = new StatusFlag(
      data.id,
      data.app_id,
      data.name,
      data.color,
      data.is_archived,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );

    this.statusFlags = _.unionBy([newStatusFlag], this.statusFlags, 'id');
    this.sortStatusFlags();
  }

  @action
  addSection(data, type) {
    const newSection = new Section(
      data.id,
      data.section_type,
      data.app_id,
      data.profile_template_id,
      data.title,
      data.private_view,
      data.protected_view,
      data.account_owner_only_view,
      data.default_collapsed,
      data.optional,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    extendObservable(newSection, {
      elements: ElementMappingActions.mapElements(data.elements),
    });
    this.stateSections = _.unionBy(this.mapStateSections(data.state_sections), this.stateSections, 'id');
    // Do the below for cloned sections that need their state elements included
    _.map(data.elements, element => {
      this.stateElements = _.unionBy(this.mapStateElements(element.state_elements), this.stateElements, 'id');
    });

    if (type === 'single') {
      this.activeSection = _.clone(newSection);
      this.newSection = _.clone(newSection);
    } else {
      this.sections = _.unionBy([newSection], this.sections, 'id');
      this.sortSections();
    }
    this.appendStatesToSectionBody();
  }

  @action
  addElement(data) {
    const findSection = _.find(this.sections, o => o.id === data.section_id);
    if (findSection) {
      const newElements = ElementMappingActions.mapElements([data]);
      findSection.elements = _.unionBy(newElements, findSection.elements, 'id');
      findSection.elements = _.orderBy(findSection.elements, ['rowOrder'], ['asc']);
      this.stateElements = _.unionBy(this.mapStateElements(data.state_elements), this.stateElements, 'id');
      this.appendStatesToSectionBody();
    }
    this.sortSections();
  }

  @action
  addStateElement(data) {
    this.stateElements = _.unionBy(this.mapStateElements([data]), this.stateElements, 'id');
    this.appendStatesToSectionBody();
  }

  @action
  addStateSection(data) {
    this.stateSections = _.unionBy(this.mapStateSections([data]), this.stateSections, 'id');
    this.appendStatesToSectionBody();
  }

  @action
  removeState(data) {
    this.states = _.filter(this.states, o => o.id !== data.id);
    this.setActiveState(_.last(this.states), true);
  }

  @action
  removeStatusFlag(data) {
    this.statusFlags = _.filter(this.statusFlags, o => o.id !== data.id);
  }

  @action
  removeParticipant(data) {
    this.participants = _.filter(this.participants, o => o.id !== data.id);
  }

  @action
  removeSection(data) {
    this.sections = _.filter(this.sections, o => o.id !== data.id);
  }

  @action
  removeElement(data) {
    const findSection = _.find(this.sections, o => o.id === data.section_id);
    if (findSection) {
      findSection.elements = _.filter(findSection.elements, o => o.id !== data.id);
      this.sections = _.unionBy([findSection], this.sections, 'id');
    }
    this.sortSections();
  }

  @action
  mapStateSections(stateSections) {
    const newStateSections = [];
    _.map(stateSections, stateSection => {
      const newStateSection = new StateSection(
        stateSection.id,
        stateSection.state_id,
        stateSection.section_id,
        stateSection.visible,
        stateSection.editable,
        stateSection.invitable,
        stateSection.commentable,
        stateSection.default_collapsed,
        stateSection.created_at,
        stateSection.created_by,
        stateSection.deleted_at,
        stateSection.deleted_by,
        stateSection.updated_at,
        stateSection.updated_by
      );

      newStateSections.push(newStateSection);
    });
    return newStateSections;
  }

  @action
  mapStateElements(stateElements) {
    const newStateElements = [];
    _.map(stateElements, stateElement => {
      const newStateElement = new StateElement(
        stateElement.id,
        stateElement.state_id,
        stateElement.element_id,
        stateElement.visible,
        stateElement.editable,
        stateElement.created_at,
        stateElement.created_by,
        stateElement.deleted_at,
        stateElement.deleted_by,
        stateElement.updated_at,
        stateElement.updated_by
      );

      newStateElements.push(newStateElement);
    });
    return newStateElements;
  }

  @action
  appendStatesToSectionBody() {
    if (this.activeState.id.length > 0) {
      _.map(this.sections, section => {
        const findStateSection = _.find(this.stateSections, o => o.sectionId === section.id && o.stateId === this.activeState.id);
        if (findStateSection) {
          if (section.stateSection) {
            section.stateSection = findStateSection;
          } else {
            extendObservable(section, {
              stateSection: findStateSection,
            });
          }
        }
        _.map(section.elements, element => {
          const findStateElement = _.find(this.stateElements, o => o.elementId === element.id && o.stateId === this.activeState.id);
          if (findStateElement) {
            if (element.stateElement) {
              element.stateElement = findStateElement;
            } else {
              extendObservable(element, {
                stateElement: findStateElement,
              });
            }
          }
        });
      });
    }
    this.key = Math.random();
  }

  @action
  sortSections() {
    this.sections = _.orderBy(this.sections, ['rowOrder'], ['asc']);
  }

  @action
  sortStates() {
    this.states = _.orderBy(this.states, ['rowOrder'], ['asc']);
  }

  @action
  sortParticipants() {
    let newParticipants = this.participants;
    const activeColumn = _.find(this.participantColumns, o => o.active);
    newParticipants = _.orderBy(newParticipants, [activeColumn.id], [activeColumn.sortDir]);
    this.participants = newParticipants;
  }

  @action
  sortStatusFlags() {
    let newStatusFlags = this.statusFlags;
    const activeColumn = _.find(this.statusFlagColumns, o => o.active);
    newStatusFlags = _.orderBy(newStatusFlags, [activeColumn.id], [activeColumn.sortDir]);
    this.statusFlags = newStatusFlags;
  }

  @action
  sortRelationships() {
    let newRelationships = this.relationships;
    newRelationships = _.orderBy(newRelationships, ['name'], ['asc']);
    this.relationships = newRelationships;
  }

  @action
  setParticipantSortParams(param) {
    const newParticipantColumns = this.participantColumns;
    _.map(newParticipantColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.participantColumns = newParticipantColumns;
    this.sortParticipants();
  }

  @action
  setStatusFlagSortParams(param) {
    const newStatusFlagColumns = this.statusFlagColumns;
    _.map(newStatusFlagColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.statusFlagColumns = newStatusFlagColumns;
    this.sortStatusFlags();
  }

  @action
  resetStartingAttributes() {
    this.newStateDialogOpen = false;
    this.activeAnimation = '';
    this.participants = [];
    this.relationships = [];
    this.sections = [];
    this.states = [];
    this.statusFlags = [];
    this.stateSections = [];
    this.stateElements = [];
    this.activeSection = new Section();
    this.activeElement = new Element();
    this.oldActiveElement = new Element();
    this.newSection = new Section();
    this.activeState = new State();
    this.newParticipant = new Participant();
    this.newStatusFlag = new StatusFlag();
    this.editParticipant = new Participant();
    this.editStatusFlag = new StatusFlag();
    this.newState = new State();
    this.editState = new State();
  }

  @action
  resetNewState() {
    this.newState = new State();
    this.newParticipant = new Participant();
    this.newStatusFlag = new StatusFlag();
  }
}

const store = new TemplateStore();
export default store;
