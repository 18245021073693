/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { AnchorButton, Button, NonIdealState } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import StateAction from '../../models/StateActionModel';
import StateActionItem from './StateActionItem';
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => <AnchorButton className="bp3-cursor-move bp3-minimal" icon="drag-handle-vertical" />);

const SortableAction = sortableElement(({ action }) => <StateActionItem stateAction={action} dragHandle={<DragHandle />} />);

const SortableActions = sortableContainer(({ actions }) => (
  <Flexbox flexDirection="column" flexGrow={1}>
    {actions.map(action => (
      <SortableAction key={action.id} index={actions.indexOf(action)} action={action} />
    ))}
  </Flexbox>
));

@inject('StateRuleStore', 'StateRuleActions', 'TemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StateActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  handleSortEnd(action, newIndex) {
    action.changeType = 'row_order_changed';
    action.rowOrder = newIndex;
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the action
    const findAction = this.props.StateRuleStore.activeStateActions[oldIndex];

    // Reorder the current UI
    const newActions = arrayMove(this.props.StateRuleStore.stateActions.slice(), oldIndex, newIndex);
    this.props.StateRuleStore.stateActions = newActions;

    // Process reorder on server
    this.handleSortEnd(findAction, newIndex);
  }

  addStateAction() {
    const newAction = new StateAction(
      null,
      this.props.StateRuleStore.activeStateRule.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      'added'
    );
    this.props.StateRuleStore.stateActions = this.props.StateRuleStore.stateActions.concat(newAction);
  }

  renderActions() {
    if (this.props.StateRuleStore.activeStateActions.length === 0) {
      return (
        <Flexbox flexGrow={1} className="bp3-card bp3-card-gray" flexDirection="column">
          <NonIdealState
            title={I18n.t('js.no_actions')}
            description={I18n.t('js.there_are_no_actions_for_this_rule')}
            icon="offline"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} className="bp3-card bp3-card-gray" flexDirection="column">
        <SortableActions
          actions={this.props.StateRuleStore.activeStateActions}
          onSortEnd={this.onSortEnd.bind(this)}
          useDragHandle={true}
          lockToContainerEdges={true}
          lockAxis="y"
        />
      </Flexbox>
    );
  }

  renderAddButton() {
    return (
      <Flexbox marginTop="20px" flexGrow={1}>
        <Button icon="offline" text={I18n.t('js.add_action')} onClick={this.addStateAction.bind(this)} />
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderActions()}
        {this.renderAddButton()}
      </Flexbox>
    );
  }
}
