import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton } from '@blueprintjs/core';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementFileDownload extends React.Component {
  renderDownload() {
    if (
      this.props.element.elementFileDownload.attachmentFileName
        .length > 0
    ) {
      return (
        <AnchorButton
          icon="cloud-download"
          text={I18n.t('js.download_file', {
            name: this.props.element.elementFileDownload
              .attachmentFileName,
          })}
          onClick={() => {
            window.location = this.props.element.elementFileDownload.attachmentFilePath;
          }}
        />
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="10px"
      >
        <Flexbox
          flexGrow={1}
          flexDirection="row"
          flexWrap="wrap"
          paddingTop="10px"
          justifyContent="flex-start"
        >
          {this.renderDownload()}
        </Flexbox>
      </Flexbox>
    );
  }
}
