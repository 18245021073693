/* Legacy code - ignore magic-number errors */
/* eslint no-magic-numbers: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import Pagination from 'react-js-pagination';
import Utilities from '../../utils/Utilities';
import { HTMLSelect } from '@blueprintjs/core';

export default class Paginator extends React.Component {
  handleClick() {
    Utilities.navigate(this.props.path);
  }

  renderPagination() {
    if (
      this.props.paginationObject.xPerPage <=
      this.props.paginationObject.xTotal
    ) {
      return (
        <Pagination
          activePage={this.props.paginationObject.xPage}
          itemsCountPerPage={this.props.paginationObject.xPerPage}
          totalItemsCount={this.props.paginationObject.xTotal}
          pageRangeDisplayed={this.props.pageRangeDisplayed}
          onChange={this.props.handlePageChange.bind(this)}
          disabledClass="bp3-disabled"
          innerClass="bp3-button-group bp3-minimal bp3-pagination"
          itemClass="bp3-button"
          activeClass="bp3-active bp3-pagination-active"
          linkClass="no-underline"
          prevPageText={
            <span className="bp3-icon-chevron-left"> </span>
          }
          nextPageText={
            <span className="bp3-icon-chevron-right"> </span>
          }
          firstPageText={
            <span className="bp3-icon-double-chevron-left"> </span>
          }
          lastPageText={
            <span className="bp3-icon-double-chevron-right"> </span>
          }
        />
      );
    }
    return undefined;
  }

  renderOption(number) {
    if (this.props.paginationObject.xTotal > number) {
      return (
        <option value={number}>
          {I18n.t('js.show_x_per_page', { amount: number })}
        </option>
      );
    } else if (this.props.paginationObject.xTotal === number) {
      return (
        <option value={number}>
          {I18n.t('js.show_all_total', { total: number })}
        </option>
      );
    }
    return undefined;
  }

  renderSelect() {
    if (this.props.paginationObject.xTotal > 9) {
      return (
        <Flexbox flexDirection="row" flexGrow={0}>
          <div className="bp3-select bp3-minimal">
            <HTMLSelect
              onChange={this.props.handlePerPageChange.bind(this)}
              value={this.props.paginationObject.xPerPage}
            >
              {this.renderOption(10)}
              {this.renderOption(25)}
              {this.renderOption(50)}
              {this.renderOption(100)}
              {this.renderOption(250)}
              {this.renderOption(500)}
              {this.renderOption(this.props.paginationObject.xTotal)}
            </HTMLSelect>
          </div>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        marginTop="10px"
      >
        <Flexbox flexDirection="row" flexGrow={1}>
          {this.renderPagination()}
        </Flexbox>
        {this.renderSelect()}
      </Flexbox>
    );
  }
}
