import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import DynamicUserView from '../dynamic_view/DynamicUserView';
import DashboardProfileSnippet from './DashboardProfileSnippet';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore'
)
@observer
export default class DashboardYou extends React.Component {
  componentDidMount() {
    this.props.DynamicViewStore.items = [];
    this.props.DynamicViewStore.isLoadingItemsAsOwner = true;
    this.props.DynamicViewStore.setFilteredUser(this.props.CurrentUserStore.currentUser);
  }

  handleAppChange(e) {
    if (this.props.DynamicViewStore.activeApp === null) {
      const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
      this.props.DynamicViewStore.setActiveApp(app);
    } else {
      if (this.props.DynamicViewStore.activeApp.id !== e.target.value) {
        const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
        this.props.DynamicViewStore.setActiveApp(app);
      }
    }
  }

  handleParticipantChange(e) {
    const participant = _.find(this.props.DynamicViewStore.participants, o => o.id === e.target.value);
    this.props.DynamicViewStore.setActiveParticipant(participant);
  }

  handleStatusFlagChange(e) {
    const statusFlag = _.find(this.props.DynamicViewStore.statusFlags, o => o.id === e.target.value);
    this.props.DynamicViewStore.setActiveStatusFlag(statusFlag);
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <DashboardProfileSnippet />
        <DynamicUserView
          user={this.props.CurrentUserStore.currentUser}
          allowAppSelect={true}
          allowMultiSelect={false}
          allowParticipantSelect={false}
          allowStatusFlagSelect={false}
          handleAppChange={this.handleAppChange.bind(this)}
          handleParticipantChange={this.handleParticipantChange.bind(this)}
          handleStatusFlagChange={this.handleStatusFlagChange.bind(this)}
        />
      </Flexbox>
    );
  }
}
