import { action, computed, observable } from 'mobx';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Item from '../models/ItemModel';
import User from '../models/UserModel';
import StatusFlag from '../models/StatusFlagModel';
import State from '../models/StateModel';
import _ from 'lodash';
import Paginator from '../models/PaginatorModel';
import axios from 'axios';
import Cookies from 'js-cookie';

class DashboardStore {
  @observable activeTab = 'inbox';
  @observable newItem = new Item();
  @observable newItemUser = new User();
  @observable isLoading = false;
  @observable showDone = false;
  @observable items = [];
  @observable pinnedItems = [];
  @observable itemSectionInvites = [];
  @observable itemColumns = _.clone(ColumnDefinitions.dashboardItemColumns);
  @observable userColumns = ColumnDefinitions.userColumns;
  @observable pagination = new Paginator();
  @observable searchResults = [];

  @computed
  get itemsNotDone() {
    return _.filter(this.items, o => !o.isDoneForCurrentUser);
  }

  @action toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  updateNewItem(data, attribute) {
    this.newItem[attribute] = data;
  }

  @action
  addItemSectionInvite(data) {
    this.itemSectionInvites = _.unionBy([data], this.itemSectionInvites, 'id');
  }

  @action
  addItem(data) {
    const newItem = new Item(
      data.id,
      data.app_id,
      data.state_id,
      data.user_id,
      data.status_flag_id,
      data.name,
      data.item_type,
      data.item_icon,
      data.item_color,
      data.item_layout,
      data.image_file_name,
      data.comment_count,
      data.is_done_for_current_user,
      data.is_webform,
      data.import_id,
      data.webform_user_email,
      data.webform_user_full_name,
      data.webform_user_image_file_name,
      data.dataview,
      data.simple_dataview,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    const itemUser = new User(
      data.user.id,
      data.user.email,
      data.user.first_name,
      data.user.last_name,
      data.user.full_name,
      data.user.account_id,
      data.user.human_friendly_id,
      data.user.is_current_user,
      data.user.is_account_owner,
      data.user.image_file_name,
      data.user.background_image_file_name,
      data.user.last_sign_in_at,
      data.user.locale,
      data.user.theme,
      data.user.created_at,
      data.user.updated_at,
      data.user.deleted_at,
      data.user.created_by,
      data.user.updated_by,
      data.user.deleted_by
    );
    newItem.user = itemUser;

    const statusFlag = new StatusFlag(
      data.status_flag.id,
      data.status_flag.app_id,
      data.status_flag.name,
      data.status_flag.color,
      data.status_flag.is_archived,
      data.status_flag.created_at,
      data.status_flag.created_by,
      data.status_flag.deleted_at,
      data.status_flag.deleted_by,
      data.status_flag.updated_at,
      data.status_flag.updated_by
    );
    newItem.statusFlag = statusFlag;

    const state = new State(
      data.state.id,
      data.state.app_id,
      data.state.participant_id,
      data.state.status_flag_id,
      data.state.name,
      data.state.all_visible,
      data.state.all_editable,
      data.state.row_order,
      data.state.created_at,
      data.state.created_by,
      data.state.deleted_at,
      data.state.deleted_by,
      data.state.updated_at,
      data.state.updated_by
    );
    newItem.state = state;

    this.items = _.unionBy([newItem], this.items, 'id');
    this.sortItems();
  }

  @action
  removeItem(item) {
    this.items = _.filter(this.items, o => o.id !== item.id);
    --this.pagination.xTotal;
  }

  @action
  addItemPin(itemPin) {
    this.pinnedItems = _.unionBy([itemPin], this.pinnedItems, 'id');
  }

  @action
  setItemSortParams(param) {
    const newItemColumns = this.itemColumns;
    _.map(newItemColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.itemColumns = newItemColumns;
    this.sortItems();
  }

  @action
  sortItems() {
    let newItems = this.items;
    const activeColumn = _.find(this.itemColumns, o => o.active);
    switch (activeColumn.id) {
      case 'name':
        newItems = _.orderBy(newItems, e => e.name, [activeColumn.sortDir]);
        break;
      case 'status':
        newItems = _.orderBy(newItems, e => e.statusFlag.name, [activeColumn.sortDir]);
        break;
      default:
        newItems = _.orderBy(newItems, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.items = newItems;
  }

  @action
  performSearch(query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/search?query=${query}`)
        .then(response => {
          this.searchResults = response.data;
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  resetStartingAttributes() {
    this.newItem = new Item();
    this.newItemUser = new User();
    this.items = [];
    this.itemSectionInvites = [];
    this.pinnedItems = [];
    this.searchResults = [];
  }
}

const store = new DashboardStore();
export default store;
