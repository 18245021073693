/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import Utilities from '../../utils/Utilities';

export default class ElementSlider {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;
  @observable
  sliderType;
  @observable
  labelType;
  @observable
  labelCurrencyType;
  @observable
  minValue;
  @observable
  maxValue;
  @observable
  stepSize;
  @observable
  vertical;

  constructor(
    id,
    elementId,
    name,
    sliderType,
    labelType,
    labelCurrencyType,
    minValue,
    maxValue,
    stepSize,
    vertical
  ) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
    this.sliderType = sliderType || '';
    this.labelType = labelType || '';
    this.labelCurrencyType = labelCurrencyType || '';
    if (minValue === '0.0') {
      this.minValue = 0;
    } else {
      this.minValue = Utilities.decimalToInt(minValue) || null;
    }
    if (maxValue === '0.0') {
      this.maxValue = 0;
    } else {
      this.maxValue = Utilities.decimalToInt(maxValue) || null;
    }
    if (stepSize === '0.0') {
      this.stepSize = 0;
    } else {
      this.stepSize = Utilities.decimalToInt(stepSize) || null;
    }
    // FIXME this wasn't meant to be an expression
    // eslint-disable-next-line
    typeof vertical === 'undefined'
      ? (this.vertical = false)
      : (this.vertical = vertical);
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }
}
