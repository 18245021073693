/* Legacy code - ignore this and complexity errors */
/* eslint class-methods-use-this: 0 complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Dialog, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';
import StoreCategory from '../../models/StoreCategoryModel';
import RichTextEditor from '../shared/RichTextEditor';

const StoreCategorySelect = MultiSelect.ofType(StoreCategory);

@inject('StoreTemplateActions', 'StoreTemplateStore', 'ToastStore')
@observer
export default class NewStoreTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isBlurred: [],
      selectedStoreCategories: [],
      query: '',
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.StoreTemplateStore.storeCategories.length === 0 && nextProps.dialogOpen) {
      nextProps.StoreTemplateActions.fetchStoreCategories().then(response => {
        _.map(response.items, item => {
          nextProps.StoreTemplateStore.addStoreCategory(item);
        });
      });
    }
  }

  handleCreateStoreTemplate() {
    this.toggleLoading();
    const newStoreTemplateObj = {
      templatable_object_id: this.props.templatableObject.id,
      templatable_object_type: this.props.templatableObjectType,
      title: this.props.StoreTemplateStore.newStoreTemplate.title,
      subtitle: this.props.StoreTemplateStore.newStoreTemplate.subtitle,
      description: this.props.StoreTemplateStore.newStoreTemplate.description,
      benefits: this.props.StoreTemplateStore.newStoreTemplate.benefits,
      icon: this.props.templatableObject.icon,
    };
    this.props.StoreTemplateActions.createStoreTemplate(newStoreTemplateObj)
      .then(response => {
        const storeTemplateId = response.data.id;
        let operationCount = this.state.selectedStoreCategories.length;
        _.map(this.state.selectedStoreCategories, storeCategory => {
          const storeCategoryStoreTemplateObj = {
            store_template_id: storeTemplateId,
            store_category_id: storeCategory.fields.id,
          };
          this.props.StoreTemplateActions.createStoreCategoryStoreTemplate(storeCategoryStoreTemplateObj).then(() => {
            operationCount--;
            this.processCompletion(operationCount, storeTemplateId);
          });
        });
        this.processCompletion(operationCount, storeTemplateId);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  processCompletion(operationCount) {
    if (operationCount === 0) {
      this.toggleLoading();
      this.props.toggleOpen();
      this.props.StoreTemplateStore.resetNewStoreTemplate();
      this.props.ToastStore.showToast(I18n.t('js.template_has_been_queued_for_creation'), 'success');
    }
  }

  handleInputChange(e) {
    this.props.StoreTemplateStore.updateNewStoreTemplate(e.target.value, e.target.name);
  }

  handleDescriptionChange(content) {
    this.props.StoreTemplateStore.updateNewStoreTemplate(content, 'description');
  }

  searchStoreCategory(e) {
    const query = e.target.value;
    this.setState({ query });
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.StoreTemplateStore.newStoreTemplate[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.StoreTemplateStore.newStoreTemplate[`${field}Error`]}</div>;
    }
    return undefined;
  }

  renderNoResults() {
    return <MenuItem disabled text={I18n.t('js.no_results_found')} />;
  }

  handleCancel() {
    this.props.toggleOpen();
  }

  handleStoreCategorySelect(storeCategory) {
    this.setState({
      selectedStoreCategories: _.concat(this.state.selectedStoreCategories.slice(), [storeCategory]),
    });
  }

  handleStoreCategoryRemove(storeCategory) {
    const storeCategoryId = storeCategory.props.id;
    const newStoreCategorys = _.filter(this.state.selectedStoreCategories.slice(), o => o.fields.id !== storeCategoryId);
    this.setState({ selectedStoreCategories: newStoreCategorys });
  }

  filterStoreCategories(query) {
    let possibleStoreCategories = _.filter(this.props.StoreTemplateStore.storeCategories, o =>
      o.fields.title.toLowerCase().includes(query)
    );

    // filter out selected storeCategories
    _.map(this.state.selectedStoreCategories, storeCategory => {
      possibleStoreCategories = _.filter(possibleStoreCategories, o => o.fields.id !== storeCategory.fields.id);
    });

    return possibleStoreCategories;
  }

  renderTag(storeCategory) {
    return <span id={storeCategory.fields.id}>{storeCategory.fields.title}</span>;
  }

  renderStoreCategory(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return <MenuItem className={menuClass} key={item.fields.id} onClick={details.handleClick} text={item.fields.title} />;
  }

  render() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="social-media"
        isOpen={this.props.dialogOpen}
        canOutsideClickClose={false}
        onClose={this.props.toggleOpen.bind(this)}
        title={I18n.t('js.publish_a_new_template')}
      >
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox className="bp3-dialog-body" flexDirection="column" flexGrow={1}>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'title') && !this.props.StoreTemplateStore.newStoreTemplate.titleValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="title">
                  {I18n.t('js.store_template_title')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'title') && !this.props.StoreTemplateStore.newStoreTemplate.titleValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      autoFocus
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.enter_a_title_for_your_store_template')}
                      name="title"
                      value={this.props.StoreTemplateStore.newStoreTemplate.title}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('title')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'subtitle') && !this.props.StoreTemplateStore.newStoreTemplate.subtitleValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="subtitle">
                  {I18n.t('js.subtitle')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'subtitle') && !this.props.StoreTemplateStore.newStoreTemplate.subtitleValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.enter_a_subtitle_for_your_store_template')}
                      name="subtitle"
                      value={this.props.StoreTemplateStore.newStoreTemplate.subtitle}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('subtitle')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="10px">
              <div
                className={
                  _.includes(this.state.isBlurred, 'description') && !this.props.StoreTemplateStore.newStoreTemplate.descriptionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="description">
                  {I18n.t('js.store_template_description')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'description') && !this.props.StoreTemplateStore.newStoreTemplate.descriptionValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <RichTextEditor
                      content={this.props.StoreTemplateStore.newStoreTemplate.description}
                      rows={8}
                      handleChange={content => this.handleDescriptionChange(content)}
                      placeholder={I18n.t('js.enter_a_description_for_your_store_template')}
                      disabled={false}
                      inputValid={true}
                      showSubmissionButtons={false}
                    />
                  </div>
                  {this.renderErrors('description')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="10px">
              <FormGroup label={I18n.t('js.select_store_categories')} labelInfo="(required)">
                <StoreCategorySelect
                  className="max-width"
                  resetOnSelect={true}
                  items={this.props.StoreTemplateStore.storeCategories}
                  itemListPredicate={this.filterStoreCategories.bind(this)}
                  selectedItems={this.state.selectedStoreCategories}
                  tagRenderer={this.renderTag.bind(this)}
                  itemRenderer={this.renderStoreCategory.bind(this)}
                  onItemSelect={this.handleStoreCategorySelect.bind(this)}
                  tagInputProps={{
                    inputProps: {
                      placeholder: this.state.selectedStoreCategories.length > 0 ? '' : I18n.t('js.select_store_categories'),
                      value: this.state.query,
                      onChange: this.searchStoreCategory.bind(this),
                    },
                    className: 'bp3-tag-input',
                    onRemove: this.handleStoreCategoryRemove.bind(this),
                  }}
                  popoverProps={{
                    usePortal: false,
                    position: 'bottom-left',
                    className: '',
                    popoverClassName: 'bp3-minimal',
                  }}
                  noResults={this.renderNoResults()}
                />
              </FormGroup>
            </Flexbox>
          </Flexbox>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={() => this.handleCancel()} />
              <Button
                intent={Intent.PRIMARY}
                loading={this.state.isLoading}
                onClick={this.handleCreateStoreTemplate.bind(this)}
                disabled={!this.props.StoreTemplateStore.newStoreTemplateValid}
                text={I18n.t('js.create_store_template')}
              />
            </div>
          </div>
        </Flexbox>
      </Dialog>
    );
  }
}
