import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, Switch, NonIdealState, Spinner, AnchorButton, Intent, Alignment, Icon } from '@blueprintjs/core';
import ItemWidget from '../items/ItemWidget';
import Paginator from '../shared/Paginator';
import Emoji from '../shared/Emoji';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import ActivityView from '../../views/ActivityView';
import FormElementDisplay from '../form_elements/FormElementDisplay';
import TeamAvatar from '../shared/TeamAvatar';
import moment from 'moment';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore',
  'ActivityStore'
)
@observer
export default class DashboardActivity extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={0}>
        <p>{I18n.t('js.your_network_description')}</p>
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
          <ActivityView activityObjectType="Network" activityObjectId={this.props.CurrentUserStore.currentUser.id} />
        </Flexbox>
      </Flexbox>
    );
  }
}
