/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import ItemElement from './ItemElement';
import { inject, observer } from 'mobx-react';
import { Classes, NonIdealState } from '@blueprintjs/core';
import ItemSection from './ItemSection';
import _ from 'lodash';

@inject('ItemStore')
@observer
export default class ItemSections extends React.Component {
  render() {
    if (this.props.ItemStore.sections.length > 0) {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          {_.map(this.props.ItemStore.sections, section => (
            <ItemSection section={section} key={section.id} shownInDialogView={this.props.shownInDialogView} />
          ))}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column" className="bp3-card" marginBottom="20px">
        <NonIdealState
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.there_are_no_sections_to_show')}
          icon="duplicate"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }
}
