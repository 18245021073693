import React, { Component } from 'react';
import Flexbox from 'flexbox-react';
import ToastStore from '../../stores/ToastStore';
import CurrentUserStore from '../../stores/CurrentUserStore';
import { Icon, NonIdealState, Intent, HTMLSelect, Button, ButtonGroup } from '@blueprintjs/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';

export default class UsageMetric extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      displayType: 'line',
      hasError: false,
      query_type: '',
      timeframe: '',
      interval: '',
      event_collection: '',
      data: [],
      formattedData: {},
      options: {
        title: {
          display: true,
          position: 'top',
          text: this.props.usage.description,
        },
        legend: {
          position: 'bottom',
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
              },
              display: true,
              scaleLabel: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
              display: true,
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
              scaleLabel: {
                display: false,
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    const { title, description, query_type, timeframe, interval, event_collection, icon } = this.props.usage;
    this.setState({
      query_type: query_type,
      timeframe: timeframe,
      interval: interval,
      event_collection: event_collection,
    });
    this.refreshData(query_type, timeframe, interval, event_collection);
  }

  refreshData(query_type, timeframe, interval, event_collection) {
    return new Promise((resolve, reject) => {
      this.setState({ isLoading: true });
      let accountId = CurrentUserStore.activeAccount.accountId;
      if (this.props.accountId) {
        accountId = this.props.accountId;
      }
      let url = `${Cookies.get(
        'apiEnv'
      )}/usage?account_id=${accountId}&query_type=${query_type}&timeframe=${timeframe}&interval=${interval}&event_collection=${event_collection}`;
      if (this.props.workspace) {
        url = url + `&workspace_id=${this.props.workspace.id}`;
      }
      if (this.props.app) {
        url = url + `&app_id=${this.props.app.id}`;
      }

      axios
        .get(url)
        .then(response => {
          resolve(response);
          this.processData(response.data);
          this.setState({ isLoading: false });
        })
        .catch(error => {
          throw error;
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
          this.setState({ isLoading: false });
        });
    });
  }

  processData(data) {
    if (data.error_code) {
      this.setState({ hasError: true });
    }
    let newData = {
      labels: [],
      datasets: [
        {
          label: this.props.usage.title,
          borderColor: '#DB2C6F',
          backgroundColor: '#FF66A1',
          data: [],
          fill: true,
        },
        {
          label: `${this.props.usage.title} (${I18n.t('js.average')})`,
          borderColor: '#FFC940',
          // backgroundColor: '#2965CC',
          data: [],
          fill: false,
        },
      ],
    };
    const average = _.round(
      _.meanBy(data.result, o => o.value),
      2
    );
    _.map(data.result, result => {
      switch (this.state.interval) {
        case 'daily':
        case 'weekly':
          newData.labels.push(moment(result.timeframe.start).format('ll'));
          break;
        case 'monthly':
          newData.labels.push(moment(result.timeframe.start).format('MMMM'));
          break;
        case 'yearly':
          newData.labels.push(moment(result.timeframe.start).format('YYYY'));
          break;
      }
      newData.datasets[0].data.push(result.value);
      newData.datasets[1].data.push(average);
    });
    this.setState({ data: data, formattedData: newData });
  }

  setDisplayType(displayType) {
    this.setState({ displayType: displayType });
  }

  handleIntervalChange(e) {
    this.setState({ interval: e.target.value });
    this.refreshData(this.state.query_type, this.state.timeframe, e.target.value, this.state.event_collection);
  }

  handleTimeframeChange(e) {
    this.setState({ timeframe: e.target.value });
    this.refreshData(this.state.query_type, e.target.value, this.state.interval, this.state.event_collection);
  }

  renderControls() {
    return (
      <Flexbox flexDirection="row">
        <Flexbox flexDirection="column">
          <ButtonGroup className="push-10-r">
            <Button
              icon="timeline-line-chart"
              active={this.state.displayType === 'line'}
              onClick={() => this.setDisplayType('line')}
            ></Button>
            <Button icon="th" active={this.state.displayType === 'table'} onClick={() => this.setDisplayType('table')}></Button>
          </ButtonGroup>
        </Flexbox>
        <HTMLSelect className="push-10-r" name="interval" onChange={e => this.handleIntervalChange(e)} value={this.state.interval}>
          <option id="daily" value="daily">
            {I18n.t('js.daily')}
          </option>
          <option id="weekly" value="weekly">
            {I18n.t('js.weekly')}
          </option>
          <option id="monthly" value="monthly">
            {I18n.t('js.monthly')}
          </option>
          <option id="yearly" value="yearly">
            {I18n.t('js.yearly')}
          </option>
        </HTMLSelect>
        <HTMLSelect className="push-10-r" name="timeframe" onChange={e => this.handleTimeframeChange(e)} value={this.state.timeframe}>
          <option id="30" value="this_7_days">
            {I18n.t('js.last_x_days', { number: 7 })}
          </option>
          <option id="30" value="this_30_days">
            {I18n.t('js.last_x_days', { number: 30 })}
          </option>
          <option id="90" value="this_90_days">
            {I18n.t('js.last_x_days', { number: 90 })}
          </option>
          <option id="180" value="this_180_days">
            {I18n.t('js.last_x_days', { number: 180 })}
          </option>
          <option id="365" value="this_365_days">
            {I18n.t('js.last_x_days', { number: 365 })}
          </option>
        </HTMLSelect>
      </Flexbox>
    );
  }

  renderChart() {
    if (this.state.isLoading) {
      return (
        <Flexbox
          flexDirection="column"
          marginRight="40px"
          width="100%"
          height="320px"
          marginBottom="20px"
          marginTop="20px"
          className="bp3-skeleton"
        ></Flexbox>
      );
    }
    if (this.state.hasError) {
      return (
        <Flexbox>
          <NonIdealState
            title={I18n.t('js.error')}
            description={I18n.t('js.an_error_occured_with_this_chart')}
            icon={<Icon icon="warning-sign" intent={Intent.DANGER} iconSize={50} />}
            className="bp3-intent-danger"
          />
        </Flexbox>
      );
    }
    if (this.state.displayType === 'line') {
      return (
        <Flexbox flexDirection="column">
          <Flexbox marginTop="20px">
            <Line data={this.state.formattedData} options={this.state.options} />
          </Flexbox>
        </Flexbox>
      );
    }
    if (this.state.displayType === 'table') {
      console.log(this.state.data);
      return (
        <Flexbox flexDirection="column">
          <Flexbox marginTop="20px" maxHeight="300px" style={{ overflowY: 'scroll' }}>
            <table className="bp3-html-table bp3-small bp3-html-table-bordered bp3-html-table-striped bp3-html-table-condensed max-width animated slideInUp">
              <thead>
                <tr>
                  <th>{I18n.t('js.start')}</th>
                  <th>{I18n.t('js.end')}</th>
                  <th>{I18n.t('js.result')}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.result.map((result, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <small>
                          <code>{moment(result.timeframe.start).format('DD/MM/YYYY')}</code>
                        </small>
                      </td>
                      <td>
                        <small>
                          <code>{moment(result.timeframe.end).format('DD/MM/YYYY')}</code>
                        </small>
                      </td>
                      <td>
                        <small>
                          <code>{result.value}</code>
                        </small>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }

  render() {
    const { title, description, query_type, timeframe, interval, event_collection, icon } = this.props.usage;
    Chart.defaults.global.defaultFontFamily = 'Inter';
    if (Cookies.get('theme') == 'dark') {
      Chart.defaults.global.defaultFontColor = '#fff';
    }

    return (
      <Flexbox className="bp3-card bp3-metric-card" width="48%" marginRight="20px" marginBottom="20px" flexDirection="column">
        <Flexbox justifyContent="space-between" flexDirection="row" marginBottom="10px">
          <Flexbox flexDirection="row" alignItems="center" flexWrap="nowrap">
            <Icon icon={icon} className="bp3-text-muted push-10-r" iconSize={20} />
            <h4 className="bp3-label-no-margin">{title}</h4>
          </Flexbox>
          <Flexbox>{this.renderControls()}</Flexbox>
        </Flexbox>
        {this.renderChart()}
      </Flexbox>
    );
  }
}
