import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  AnchorButton,
  Button,
  ButtonGroup,
  Classes,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import DataViewTable from '../data_view/DataViewTable';
import UserAvatar from '../shared/UserAvatar';
import Utilities from '../../utils/Utilities';
import DataViewVisualization from '../data_view/DataViewVisualization';

@inject('ReportStore', 'ReportActions')
@observer
export default class Report extends Component {
  constructor(props) {
    super(props);
    this.state = { isDeleting: false };
  }

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting,
    });
  }

  handleDeleteReport() {
    this.props.ReportActions.deleteReport(this.props.ReportStore.activeReport.id).then(response => {
      this.props.ReportStore.removeReport(response.data.id);
      window.location = `/reports`;
    });
  }

  renderDeleteAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_report')}
        onConfirm={() => this.handleDeleteReport()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_report')}
      </Alert>
    );
  }

  renderDownloadMenu() {
    return (
      <Menu>
        <MenuItem
          text={I18n.t('js.csv')}
          href={this.props.ReportStore.activeReport.csvUrl}
          icon={<span className="far fa-file-alt fa-menu-icon" />}
          onClick={() => Utilities.navigate(this.props.ReportStore.activeReport.csvUrl)}
        />
        <MenuItem
          text={I18n.t('js.excel')}
          href={this.props.ReportStore.activeReport.xlsUrl}
          icon={<span className="far fa-file-excel fa-menu-icon" />}
          onClick={() => Utilities.navigate(this.props.ReportStore.activeReport.xlsUrl)}
        />
      </Menu>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox justifyContent="space-between" alignItems="center">
          <Flexbox marginTop="20px" marginBottom="20px">
            <ButtonGroup>
              <Popover
                portalContainer={document.body}
                content={this.renderDownloadMenu()}
                inheritDarkTheme={false}
                interactionKind={PopoverInteractionKind.CLICK}
                position="bottom"
                autoFocus={false}
              >
                <Button icon="cloud-download" rightIcon="caret-down" text={I18n.t('js.export')} style={{ minHeight: '32px' }} />
              </Popover>
              <Button icon="trash" text={I18n.t('js.delete_report')} onClick={this.toggleDeleting.bind(this)} />
              <AnchorButton
                icon="undo"
                text={I18n.t('js.reports')}
                href={`/reports?report_template_id=${this.props.ReportStore.activeReport.reportTemplateId}`}
              />
            </ButtonGroup>
            {this.renderDeleteAlert()}
          </Flexbox>
        </Flexbox>
        <DataViewVisualization store={this.props.ReportStore} isEditable={false} />
        <Flexbox className="table-container-box">
          <DataViewTable tableData={JSON.parse(this.props.ReportStore.activeReport.data)} isEditingReportTemplate={false} />
        </Flexbox>
        <Flexbox justifyContent="space-between" alignItems="center" marginTop="10px">
          <Flexbox marginRight="20px">
            <UserAvatar
              user={this.props.ReportStore.activeReport.user}
              avatarSize={20}
              path={`/users/${this.props.ReportStore.activeReport.user.humanFriendlyId}`}
            />
          </Flexbox>
          <Flexbox alignItems="center" alignContent="center">
            <span className="bp3-icon bp3-icon-time push-5-r" />
            {this.props.ReportStore.activeReport.createdAtToDate}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
