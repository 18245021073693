import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { AnchorButton, Classes, Intent } from '@blueprintjs/core';
import CurrentUserStore from '../stores/CurrentUserStore';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class WebformSubmittedView extends React.Component {
  renderHero() {
    return (
      <Flexbox flexDirection="column" className="animated zoomInDown">
        <h2 className={`her ${Classes.HEADING}`}>
          {I18n.t('js.your_item_has_been_submitted_thanks', {
            item: this.props.itemName,
            account_name: this.props.accountName,
          })}
        </h2>
        <Flexbox
          flexDirection="row"
          marginTop="20px"
          justifyContent="center"
        >
          <AnchorButton
            href="/"
            text={I18n.t('js.home')}
            icon="home"
            intent={Intent.PRIMARY}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    const stores = {
      CurrentUserStore,
    };
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox flexGrow={1} flexDirection="column" flexBasis="1">
            <Flexbox
              className="fixed-width-container"
              flexGrow={1}
              flexDirection="column"
            >
              <Flexbox
                flexGrow={1}
                flexDirection="row"
                justifyContent="flex-start"
              >
                {this.renderHero()}
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
