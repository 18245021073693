/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Utilities from '../../utils/Utilities';
import CurrentUserStore from '../../stores/CurrentUserStore';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { AnchorButton, Classes, Popover, PopoverInteractionKind, Spinner } from '@blueprintjs/core';
import LocaleSelector from './LocaleSelector';
import Flexbox from 'flexbox-react';

@observer
export default class Footer extends React.Component {
  renderLocaleSelector() {
    if (typeof CurrentUserStore.activeLanguage === 'object') {
      return (
        <Flexbox flexDirection="row">
          <button className="bp3-button bp3-minimal">
            <Popover
              portalContainer={document.body}
              content={<LocaleSelector />}
              interactionKind={PopoverInteractionKind.CLICK}
              inheritDarkTheme={false}
              position="top"
            >
              <Flexbox flexDirection="row" alignItems="center">
                <img alt="flag icon" className="flag-icon" src={`${I18n.t('js.flag_path')}${CurrentUserStore.activeLanguage.code}.png`} />
                <span className="">{CurrentUserStore.activeLanguage.name}</span>
                <span className="bp3-icon-standard bp3-icon-caret-down bp3-align-right" />
              </Flexbox>
            </Popover>
          </button>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row">
        <Spinner size={20} className="push-10-t" />
      </Flexbox>
    );
  }

  render() {
    const footerClass = classNames('footer');
    const marginTop = '100px';
    const imgSrc = I18n.t('js.pg_icon_black');
    const linkClass = classNames('');
    return (
      <Flexbox element="footer" height="200px" marginTop={marginTop} flexDirection="column" className={footerClass}>
        <Flexbox className="fixed-width-container-original" flexDirection="row" flexGrow={1}>
          <Flexbox flexBasis="0" flexGrow={1} justifyContent="flex-start" flexDirection="column">
            <h5 className={Classes.HEADING}>{I18n.t('js.peoplegoal')}</h5>
            <ul className={`${Classes.LIST} bp3-list-unstyled footer-list`}>
              <li>
                <a className={linkClass} href="https://www.peoplegoal.com/about">
                  {I18n.t('js.about')}
                </a>
              </li>
              <li>
                <a className={linkClass} href="https://www.peoplegoal.com/blog">
                  {I18n.t('js.blog')}
                </a>
              </li>
            </ul>
          </Flexbox>
          <Flexbox flexBasis="0" flexGrow={1} justifyContent="flex-start" flexDirection="column">
            <h5 className={Classes.HEADING}>{I18n.t('js.resources')}</h5>
            <ul className={`${Classes.LIST} bp3-list-unstyled footer-list`}>
              <li>
                <a className={linkClass} href="https://www.peoplegoal.com/app-store">
                  {I18n.t('js.store')}
                </a>
              </li>
              <li>
                <a className={linkClass} href="/news">
                  {I18n.t('js.product_news')}
                </a>
              </li>
            </ul>
          </Flexbox>
          <Flexbox flexBasis="0" flexGrow={1} justifyContent="flex-start" flexDirection="column">
            <h5 className={Classes.HEADING}>{I18n.t('js.platform')}</h5>
            <ul className={`${Classes.LIST} bp3-list-unstyled footer-list`}>
              <li>
                <a className={linkClass} href="https://www.peoplegoal.com/support">
                  {I18n.t('js.support')}
                </a>
              </li>
              <li>
                <a className={linkClass} href="https://developers.peoplegoal.com" target="_blank">
                  {I18n.t('js.developers')}
                </a>
              </li>
            </ul>
          </Flexbox>
          <Flexbox flexBasis="1" flexGrow={0} justifyContent="flex-start" alignItems="flex-end" flexDirection="column">
            <AnchorButton
              onClick={() => {
                Utilities.navigate('/');
              }}
              minimal
            >
              <img alt="logo" src={imgSrc} className="img-logo" />
            </AnchorButton>
            <Flexbox maxWidth="250px" flexWrap="wrap">
              <p>{I18n.t('js.peoplegoal_tagline')}</p>
            </Flexbox>
            {this.renderLocaleSelector()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
