/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Classes, Spinner, NonIdealState } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ActivityView from './ActivityView';
import CommentsView from './CommentsView';
import ToastStore from '../stores/ToastStore';
import TeamsStore from '../stores/TeamsStore';
import TeamUsersStore from '../stores/TeamUsersStore';
import TeamUsersRequestsStore from '../stores/TeamUsersRequestsStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import TeamTabs from '../components/teams/TeamTabs';
import TeamEdit from '../components/teams/TeamEdit';
import TeamUsers from '../components/teams/TeamUsers';
import TeamRequests from '../components/teams/TeamRequests';
import CurrentUserStore from '../stores/CurrentUserStore';
import CommentStore from '../stores/CommentStore';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import NewWidget from '../components/widgets/NewWidget';
import Widgets from '../components/widgets/Widgets';

@observer
export default class TeamView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    if (this.props.tab) {
      TeamsStore.activeTab = this.props.tab;
    } else {
      TeamsStore.activeTab = 'users';
    }
    TeamsStore.fetchTeam(this.props.teamId)
      .then(() => {
        TeamsStore.fetchCurrentUserTeam(this.props.teamId, CurrentUserStore.currentUser.id)
          .then(() => {
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderContent() {
    if (this.isLoading || TeamsStore.activeTeam === '') {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (TeamsStore.activeTab === 'users') {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox flexDirection="row" marginBottom="10px">
            <h3 className="bp3-heading push-10-r">{TeamsStore.activeTeam.name}</h3>
          </Flexbox>
          <TeamUsers teamId={this.props.teamId} />
        </Flexbox>
      );
    }
    if (TeamsStore.activeTab === 'edit' && TeamsStore.activeTeam.isCurrentUserTeamOwner) {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox flexDirection="row" marginBottom="10px">
            <h3 className="bp3-heading push-10-r">{TeamsStore.activeTeam.name}</h3>
          </Flexbox>
          <TeamEdit />
        </Flexbox>
      );
    }
    if (TeamsStore.activeTab === 'requests' && TeamsStore.activeTeam.isCurrentUserTeamOwner && TeamsStore.activeTeam.privateTeam) {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox flexDirection="row" marginBottom="10px">
            <h3 className="bp3-heading push-10-r">{TeamsStore.activeTeam.name}</h3>
          </Flexbox>
          <TeamRequests teamId={this.props.teamId} />
        </Flexbox>
      );
    }
    if (TeamsStore.activeTab === 'feed') {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox flexDirection="row" marginBottom="10px">
            <h3 className="bp3-heading push-10-r">{TeamsStore.activeTeam.name}</h3>
            {this.renderTags()}
          </Flexbox>
          <Flexbox flexGrow={1} flexDirection="column" marginBottom="40px">
            <Widgets
              editable={TeamsStore.activeTeam.isCurrentUserTeamOwner}
              widgetableType="Team"
              widgetableId={TeamsStore.activeTeam.id}
            />
            <NewWidget
              editable={TeamsStore.activeTeam.isCurrentUserTeamOwner}
              widgetableType="Team"
              widgetableId={TeamsStore.activeTeam.id}
            />
            <Flexbox marginTop="10px">
              <CommentsView
                commentable={TeamsStore.activeTeam}
                commentableType="Team"
                stub={true}
                sortByNew={true}
                noTitle={false}
                showNone={true}
                canPin={TeamsStore.activeTeam.isCurrentUserTeamOwner}
                buttonText={I18n.t('js.comment_on_this_team')}
              />
            </Flexbox>
          </Flexbox>
          <h6 className={Classes.HEADING}>{I18n.t('js.activity')}</h6>
          <Flexbox flexGrow={1} flexDirection="column" style={{ borderTop: '1px solid #e6e6e6' }} paddingTop="10px">
            <ActivityView activityObjectType="Team" activityObjectId={TeamsStore.activeTeam.id} />
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderTags() {
    return (
      <Flexbox flexDirection="column">
        {TeamsStore.activeTeam.privateTeam ? (
          <span className="bp3-tag bp3-intent-danger bp3-group-tag bp3-minimal noselect">{I18n.t('js.private_team')}</span>
        ) : null}
        {TeamsStore.currentUserTeam.isPrimary ? (
          <span className="bp3-tag bp3-intent-primary bp3-group-tag bp3-minimal noselect">{I18n.t('js.primary_team')}</span>
        ) : null}
        {TeamsStore.activeTeam.isDefaultAccount ? (
          <span className="bp3-tag bp3-intent-success bp3-group-tag bp3-minimal noselect">{I18n.t('js.default_account_team')}</span>
        ) : null}
      </Flexbox>
    );
  }

  render() {
    const stores = {
      TeamsStore,
      TeamUsersStore,
      TeamUsersRequestsStore,
      CurrentUserStore,
      CommentStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <TeamTabs />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
