import { observable } from 'mobx';

export default class ElementObjectSelectItemApp {
  @observable
  id;
  @observable
  elementObjectSelectId;
  @observable
  appId;

  constructor(id, elementObjectSelectId, appId) {
    this.id = id || '';
    this.elementObjectSelectId = elementObjectSelectId || '';
    this.appId = appId || '';
  }
}
