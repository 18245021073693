import { action, computed, observable } from 'mobx';
import StoreTemplate from '../models/StoreTemplateModel';
import StoreTemplateMappings from '../actions/StoreTemplateMappings';
import _ from 'lodash';
import Paginator from '../models/PaginatorModel';
import Workspace from '../models/WorkspaceModel';

class StoreTemplateStore {
  @observable
  isLoading = true;
  @observable
  activeTab = 'storeTemplate';
  @observable
  activeHeaderTab = 'apps';
  @observable
  activeStoreTemplate = new StoreTemplate();
  @observable
  storeTemplates = [];
  @observable
  storeCategories = [];
  @observable
  workspaces = [];
  @observable
  workspaceFolders = [];
  @observable
  storeTemplateTriggers = [];
  @observable
  newStoreTemplate = new StoreTemplate();
  @observable
  pagination = new Paginator();

  @computed
  get newStoreTemplateValid() {
    return this.newStoreTemplate.titleValid && this.newStoreTemplate.subtitleValid && this.newStoreTemplate.descriptionValid;
  }

  @computed
  get filteredStoreTemplates() {
    switch (this.activeHeaderTab) {
      case 'apps':
        return _.filter(this.storeTemplates, storeTemplate => storeTemplate.templatableObjectType === 'App');
      case 'workspaces':
        return _.filter(this.storeTemplates, storeTemplate => storeTemplate.templatableObjectType === 'Workspace');
      case 'profile_templates':
        return _.filter(this.storeTemplates, storeTemplate => storeTemplate.templatableObjectType === 'ProfileTemplate');
      default:
        return this.storeTemplates;
    }
  }

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  updateNewStoreTemplate(data, attribute) {
    this.newStoreTemplate[attribute] = data;
  }

  @action
  resetNewStoreTemplate() {
    this.newStoreTemplate = new StoreTemplate();
  }

  @action
  resetEditedStoreTemplate() {
    this.newStoreTemplate = _.clone(this.activeStoreTemplate);
  }

  @action
  addStoreCategory(storeCategory) {
    this.storeCategories = _.unionBy([storeCategory], this.storeCategories, 'sys.id');
    this.sortStoreCategories();
  }

  @action
  addTriggerNotification(notification) {
    this.storeTemplateTriggers = _.unionBy([notification], this.storeTemplateTriggers, 'id');
  }

  @action
  addWorkspace(data) {
    const newWorkspace = new Workspace(
      data.id,
      data.account_id,
      data.name,
      data.description,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.user_access_level,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    if (newWorkspace.userAccessLevel === 'owner') {
      this.workspaces = _.unionBy([newWorkspace], this.workspaces, 'id');
    }
    this.sortWorkspaces();
  }

  @action
  addWorkspaceFolder(data) {
    const newWorkspaceFolder = new Workspace(
      data.id,
      data.account_id,
      data.name,
      data.description,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.user_access_level,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.workspaceFolders = _.unionBy([newWorkspaceFolder], this.workspaceFolders, 'id');
    this.sortWorkspaceFolders();
  }

  @action
  setActiveStoreTemplate(id) {
    const findStoreTemplate = _.find(this.storeTemplates, o => o.id === id);
    if (findStoreTemplate) {
      this.activeStoreTemplate = findStoreTemplate;
    }
  }

  @action
  addStoreTemplate(data) {
    const newStoreTemplate = StoreTemplateMappings.mapStoreTemplate(data);
    this.storeTemplates = _.unionBy([newStoreTemplate], this.storeTemplates, 'id');
    this.sortStoreTemplates();
  }

  @action
  sortStoreCategories() {
    let newStoreCategories = this.storeCategories;
    newStoreCategories = _.orderBy(newStoreCategories, ['fields.title'], ['asc']);
    this.storeCategories = newStoreCategories;
  }

  @action
  sortStoreTemplates() {
    let newStoreTemplates = this.storeTemplates;
    newStoreTemplates = _.orderBy(newStoreTemplates, ['createdAt'], ['desc']);
    this.storeTemplates = newStoreTemplates;
  }

  @action
  sortWorkspaces() {
    let newWorkspaces = this.workspaces;
    newWorkspaces = _.orderBy(newWorkspaces, ['name'], ['asc']);
    this.workspaces = newWorkspaces;
  }

  @action
  sortWorkspaceFolders() {
    let newWorkspaceFolders = this.workspaceFolders;
    newWorkspaceFolders = _.orderBy(newWorkspaceFolders, ['rowOrder'], ['asc']);
    this.workspaceFolders = newWorkspaceFolders;
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.activeTab = 'storeTemplate';
    this.activeHeaderTab = 'apps';
    this.activeStoreTemplate = new StoreTemplate();
    this.storeTemplates = [];
    this.storeCategories = [];
    this.newStoreTemplate = new StoreTemplate();
    this.pagination = new Paginator();
  }
}

const store = new StoreTemplateStore();
export default store;
