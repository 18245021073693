import React from 'react';
import Utilities from '../../utils/Utilities';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';

const CopyrightBar = () => {
  const footerClass = classNames(`copyright-bar`);
  const linkClass = classNames(``);

  return (
    <Flexbox flexDirection="row" className={footerClass}>
      <Flexbox flexDirection="row" className="full-width">
        <Flexbox flexGrow={1} className="full-width" padding="20px" flexDirection="row" justifyContent="space-between">
          <Flexbox flexGrow={1} className={linkClass} alignContent="center" alignItems="center">
            <Flexbox marginRight="10px" onClick={() => Utilities.navigate('/')} className="bp3-cursor-pointer">
              {Cookies.get('theme') == 'dark' ? (
                <img alt="logo" src={I18n.t('js.pg_logo_white')} className="copyright-img-logo" />
              ) : (
                <img src={I18n.t(`js.pg_logo_black`)} alt="logo" className="copyright-img-logo" />
              )}
            </Flexbox>
            <Flexbox>
              <small>{I18n.t('js.or_copyright')}</small>
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="column" justifyContent="center">
            <a href="/terms" className={linkClass} onClick={() => Utilities.navigate('/terms')}>
              <small>Terms & Conditions</small>
            </a>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export default CopyrightBar;
