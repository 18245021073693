// @flow
import * as React from 'react';
import SkeletonRow from './SkeletonRow';

const defaultSkeletonRowsStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const SkeletonRows = props => {
  return (
    <div style={defaultSkeletonRowsStyle} className="skeleton-rows">
      {[...Array(props.numRows)].map((element, index) => (
        <SkeletonRow key={index} />
      ))}
    </div>
  );
};

export default SkeletonRows;
