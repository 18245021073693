import colors from './colors';
import shadows from './shadows';
import spacing from './spacing';
import typography from './typography';

/**
 * An object that contains global styles for the app
 *
 * You should try and keep this aligned with Blueprint's
 * packages/core/src/common/_variables.scss
 */
export default {
  ...colors,
  ...shadows,
  ...spacing,
  ...typography,
};
