import React from 'react';
import myLogo from '../../assets/logo.svg';
import '../../css/styles.css';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';

const ThankYou = inject('ToastStore')(observer((props) => {
  const { ToastStore } = props;

  const firstName = localStorage.getItem('firstName') || 'Guest';
  const lastName = localStorage.getItem('lastName') || '';
  const email = localStorage.getItem('email') || 'guest@example.com';
  const phone = localStorage.getItem('phone') || '+0000000000';

  const src = `https://calendly.com/proprofs-team/proprofs-peoplegoal-demo?embed_domain=peoplegoal.com&hide_event_type_details=1&embed_type=Inline&name=${encodeURIComponent(
    firstName
  )}&email=${encodeURIComponent(email)}&a1=${encodeURIComponent(
    phone
  )}&phone_number=${encodeURIComponent(phone)}`;

  return (
    <Flexbox flexGrow={1} flexDirection="column" alignItems="stretch" alignContent="stretch" alignSelf="stretch" >
     <div className='container' >
      <div className='nav' >
        <div className='main-logo' >
         <a className="header__logo" href="https://www.peoplegoal.com/" aria-label="Peoplegoal" tabindex="0">            
          <img src={myLogo} />
                </a> 

        </div>
      </div>
      <div className="fullWrapper">
  <div className="register-section" style={{ padding: '40px 6%' }}>
    <div className="tab-pane" id="step4">
      <div className="text-head">
        <h3 className="sh3">Hi {firstName} {lastName},</h3>
        <p className="sp1" style={{ lineHeight: '1.3em', fontSize: '19px', paddingTop: '5px' }}>
          We're about to save you hours in set up time.
        </p>
      </div>
      <div className="field-control">
        <p className="sp1" style={{ lineHeight: '27px' }}>
          <b>Book a time</b>{' '}
          <span style={{ fontWeight: 'normal' }}>
            with Julie and her team now for:
          </span>
        </p>
        <ul className="tick-list">
          <li>
            <span>
              <img
                src="https://www.proprofs.com/api/signup/user_v2/register-new/tick.svg"
                alt="tick"
              />
            </span>
            Save hours in set up &amp; customization
          </li>
          <li>
            <span>
              <img
                src="https://www.proprofs.com/api/signup/user_v2/register-new/tick.svg"
                alt="tick"
              />
            </span>
            Get your questions answered
          </li>
        </ul>
      </div>
      <div className="field-control"></div>
      <div className="field-control join_now">
        <h4
          className="sh4"
          style={{
            marginTop: '25px',
            marginBottom: '5px',
            display: 'none',
          }}
        >
          Demo + FREE Premium Support Upgrade
        </h4>
        <p
          className="sp1"
          style={{
            fontWeight: 'normal',
            display: 'none',
          }}
        >
          Don't worry - your trial begins instantly regardless of you booking a
          time or not. We strongly recommend booking a time for business users.
        </p>
      </div>
      <div className="field-control join_now" style={{ height: '600px' }}>
        <iframe
          id="myiFrame"
         // src="https://calendly.com/proprofs-team/proprofs-picreeldemo?embed_domain=picreel.com&hide_event_type_details=1&embed_type=Inline&name=Ashraf Ashraf&email=ashraf@proprofs.com&a1=+91-9875678575&phone_number=+91-9875678575"
          src={src}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Calendly"
        ></iframe>
      </div>
    </div>
  </div>
  <div className="client-section">
    <div className="client-wrap1">
      <div className="customer-div">
        <div className="customer-inn-cont">
          <div className="test-div1">
            <span className="txt2">
              <span className="blockqoute1"></span>
              Would you like my team to save you hours &amp; answer all
              questions? Join me for a demo &amp; unlock premium support.
              <span className="blockqoute2"></span>
            </span>
          </div>
        </div>
        <div
          className="customer-img customer-img2"
          style={{ paddingTop: '25px' }}
        >
          <span>
            <img src="https://www.picreel.com/signup/img/julie2.png" alt="Julie" />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

      
      </div> 

    </Flexbox>
  );
}));

export default ThankYou;
