/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import moment from 'moment';

export default class StateCondition {
  @observable
  id;
  @observable
  stateRuleId;
  @observable
  elementId;
  @observable
  operand;
  @observable
  rowOrder;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;
  @observable
  changeType;

  constructor(id, stateRuleId, elementId, operand, rowOrder, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy, changeType) {
    this.id = id || Utilities.makeId();
    this.stateRuleId = stateRuleId || '';
    this.elementId = elementId || '';
    this.operand = operand || '';
    this.rowOrder = rowOrder || 0;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.changeType = changeType || 'original';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
