import React from 'react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';

// TODO this whole component seems unused

@inject('TeamsStore')
@observer
export default class UserProfileTeamsCard extends React.Component {
  render() {
    let background;
    const darkenAmount = 0.25; // %
    if (this.props.team.imageFileName === '') {
      background = `linear-gradient(#d72d69, ${Utilities.darkenHex(
        'd72d69',
        darkenAmount
      )})`;
    } else {
      background = `url(${this.props.team.imageFileName}`;
    }

    const imgStyle = {
      // set image for card
      background,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    };
    return (
      <Flexbox flexDirection="column" marginBottom="16px">
        <Flexbox
          className="bp3-card bp3-interactive bp3-elevation-1 bp3-card-no-padding bp3-cursor"
          flexGrow={1}
          flexDirection="column"
          marginRight="20px"
          width="290px"
          onClick={() =>
            Utilities.navigate(`/teams/${this.props.team.id}`)
          }
        >
          <Flexbox
            className="workspace-widget-img"
            style={imgStyle}
          />
          <Flexbox
            flexDirection="column"
            padding="20px"
            flexGrow={1}
            justifyContent="space-between"
          >
            <Flexbox flexDirection="column">
              <h5 className={Classes.HEADING}>
                {this.props.team.name}
              </h5>
              <p>{this.props.team.description}</p>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
