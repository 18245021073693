import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import CommentStore from '../../stores/CommentStore';
import CurrentUserStore from '../../stores/CurrentUserStore';
import ToastStore from '../../stores/ToastStore';
import Comment from '../../models/CommentModel';
import RichTextEditor from '../shared/RichTextEditor';

export default class NewComment extends React.Component {
  @observable
  comment = new Comment();
  @observable
  isLoading = false;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleCancel() {
    this.props.handleCancel();
  }

  handleInputChange(content) {
    this.comment.body = content;
  }

  handleSubmit() {
    this.toggleLoading();
    const commentObj = {
      user_id: CurrentUserStore.currentUser.id,
      body: this.comment.body,
      commentable_id: this.props.commentableId,
      commentable_type: this.props.commentableType,
    };
    if (this.props.sectionId) {
      commentObj['commentable_reference_id'] = this.props.sectionId;
      commentObj['commentable_reference_type'] = 'Section';
    }
    CommentStore.createComment(commentObj)
      .then(response => {
        CommentStore.addComment(response.data).then(comment => {
          ToastStore.showToast(I18n.t('js.your_comment_has_been_posted'), 'success');
          this.comment.body = '';
          this.props.afterCreate(comment);
          this.toggleLoading();
        });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderInput() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <RichTextEditor
          content={this.comment.body}
          rows={4}
          handleChange={content => this.handleInputChange(content)}
          handleSubmit={this.handleSubmit.bind(this)}
          handleCancel={this.handleCancel.bind(this)}
          placeholder={I18n.t('js.start_typing')}
          disabled={false}
          inputValid={true}
          showSubmissionButtons={true}
          submitIcon="comment"
          submitText={I18n.t('js.add_comment')}
          submitLoading={this.isLoading}
          cancelIcon="trash"
          cancelText={I18n.t('js.cancel')}
          placeholderDisabled={true}
        />
      </Flexbox>
    );
  }

  render() {
    if (this.props.commentEnabled) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" marginTop="10px">
          {this.renderInput()}
        </Flexbox>
      );
    }
    return <span />;
  }
}
