import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import IconSelector from '../shared/IconSelector';
import classNames from 'classnames';

@inject('AppStore', 'WorkspaceStore', 'StoreTemplateActions', 'StoreTemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreTemplateEditStyle extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isOpen: false };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit() {
    this.toggleLoading();
    const formData = new FormData();
    formData.append('id', this.props.StoreTemplateStore.newStoreTemplate.id);
    if (this.props.StoreTemplateStore.newStoreTemplate.icon !== this.props.StoreTemplateStore.activeStoreTemplate.icon) {
      formData.append('icon', this.props.StoreTemplateStore.newStoreTemplate.icon);
    }
    this.props.StoreTemplateActions.changeStoreTemplate(formData)
      .then(response => {
        this.props.StoreTemplateStore.addStoreTemplate(response.data);
        this.props.StoreTemplateStore.setActiveStoreTemplate(response.data.id);
        this.toggleLoading();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleCancel() {
    this.setState({ isOpen: false });
    this.props.StoreTemplateStore.resetEditedStoreTemplate();
  }

  handleChangeIcon(icon) {
    this.props.StoreTemplateStore.updateNewStoreTemplate(icon, 'icon');
  }

  renderIconSelector() {
    if (this.state.isOpen) {
      return (
        <IconSelector
          activeIcon={this.props.StoreTemplateStore.newStoreTemplate.icon}
          handleChangeIcon={this.handleChangeIcon.bind(this)}
        />
      );
    }
    return undefined;
  }

  render() {
    const newIconClass = classNames('push-5-r fa', this.props.StoreTemplateStore.newStoreTemplate.icon);
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.style')}
            </h4>
            <p className="noselect">{I18n.t('js.template_style_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form>
            <Flexbox flexDirection="column">
              <Flexbox flexDirection="column">
                <Flexbox flexDirection="column" marginTop="20px">
                  <h5 className={Classes.HEADING}>
                    <span className={newIconClass} />
                    {I18n.t('js.template_icon')}
                  </h5>
                  {this.renderIconSelector()}
                </Flexbox>
                <div className="push-10-t">
                  <Button
                    intent={Intent.PRIMARY}
                    onClick={this.handleSubmit.bind(this)}
                    text={I18n.t('js.save_changes')}
                    loading={this.state.isSubmitting}
                    icon="tick"
                  />
                  <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
                </div>
              </Flexbox>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
