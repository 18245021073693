import React from 'react';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { Classes, Button } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';

const UserProfileRelationshipsTabData = inject(
  'UserProfileRelationshipsStore',
  'UserProfileStore',
  'CurrentUserStore'
)(
  observer(props => {
    const openDialog = isParents => {
      props.UserProfileRelationshipsStore.setIsParents(isParents);
      props.UserProfileRelationshipsStore.toggleDialog();
    };

    const store = props.UserProfileRelationshipsStore;

    const canEditRelationships =
      props.CurrentUserStore.userIsAccountOwner || props.UserProfileStore.user.id === props.CurrentUserStore.currentUser.id;

    const canEditUserRelationships = !store.currentRelationship.lock_user_changes || props.CurrentUserStore.userIsAccountOwner;

    const canEditCounterpartUserRelationships =
      !store.currentRelationship.lock_counterpart_user_changes || props.CurrentUserStore.userIsAccountOwner;

    const AdditionButton = isParents => {
      if (canEditRelationships) {
        return (
          <Flexbox flexDirection="row-reverse">
            <Button text={I18n.t('js.add')} onClick={() => openDialog(isParents.isParents)} icon="new-person" />
          </Flexbox>
        );
      }
      return null;
    };
    7;

    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="column" className="bp3-card">
          <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1} marginBottom="10px">
            <h4 className={Classes.HEADING}>{store.currentRelationship.name}</h4>
            {canEditCounterpartUserRelationships && <AdditionButton isParents={true} />}
          </Flexbox>
          {_.isEmpty(store.superordinates)
            ? I18n.t('js.no_relationships_set')
            : _.map(store.superordinates, superordinate => {
                return (
                  <Flexbox
                    className="react-selected-item pointer"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px"
                    key={superordinate.counterpart_user.id}
                    onClick={() => Utilities.navigate(`/users/${superordinate.counterpart_user.id}`)}
                  >
                    <Flexbox alignItems="center">
                      <Avatar src={superordinate.counterpart_user.image_file_name} size={25} round={true} className="push-10-r" />
                      <span className="font-s-16">{superordinate.counterpart_user.full_name}</span>
                    </Flexbox>
                    {canEditRelationships && canEditCounterpartUserRelationships ? (
                      <span
                        className="bp3-icon bp3-icon-cross push-10-r delete-react-selected-item"
                        onClick={e => store.openAlert(e, superordinate.counterpart_user.id, 'superordinates')}
                      />
                    ) : null}
                  </Flexbox>
                );
              })}
        </Flexbox>
        <Flexbox flexDirection="column" className="bp3-card" marginTop="20px">
          <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1} marginBottom="10px">
            <h4 className={Classes.HEADING}>{store.currentRelationship.counterpart_name}</h4>
            {canEditUserRelationships && <AdditionButton isParents={false} />}
          </Flexbox>
          {_.isEmpty(store.subordinates)
            ? I18n.t('js.no_relationships_set')
            : _.map(store.subordinates, subordinate => {
                return (
                  <Flexbox
                    className="react-selected-item pointer"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="10px"
                    key={subordinate.user.id}
                    onClick={() => Utilities.navigate(`/users/${subordinate.user.id}`)}
                  >
                    <Flexbox alignItems="center">
                      <Avatar src={subordinate.user.image_file_name} size={25} round={true} className="push-10-r" />
                      <span className="font-s-16">{subordinate.user.full_name}</span>
                    </Flexbox>
                    {canEditRelationships && canEditUserRelationships ? (
                      <span
                        className="bp3-icon bp3-icon-cross push-10-r delete-react-selected-item"
                        onClick={e => store.openAlert(e, subordinate.user.id, 'subordinates')}
                      />
                    ) : null}
                  </Flexbox>
                );
              })}
        </Flexbox>
      </Flexbox>
    );
  })
);

export default UserProfileRelationshipsTabData;
