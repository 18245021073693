import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import ShowComment from './ShowComment';
import _ from 'lodash';

@inject('CommentStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class CommentList extends React.Component {
  render() {
    let comments = this.props.CommentStore.comments.slice();
    if (this.props.sortByNew) {
      comments = comments.reverse();
    }
    if (this.props.sectionId) {
      comments = _.filter(comments, o => o.commentableReferenceId == this.props.sectionId);
    } else {
      comments = _.filter(comments, o => o.commentableReferenceId == '');
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        {_.map(comments, comment => {
          if (comment.commentableId === this.props.commentable.id && comment.pinned) {
            return (
              <ShowComment
                key={comment.id}
                comment={comment}
                canPin={this.props.canPin}
                afterCreate={this.props.afterCreate}
                afterDelete={this.props.afterDelete}
                afterChange={this.props.afterChange}
              />
            );
          }
          return undefined;
        })}
        {_.map(comments, comment => {
          if (comment.commentableId === this.props.commentable.id && !comment.pinned) {
            return (
              <ShowComment
                key={comment.id}
                comment={comment}
                canPin={this.props.canPin}
                afterCreate={this.props.afterCreate}
                afterDelete={this.props.afterDelete}
                afterChange={this.props.afterChange}
              />
            );
          }
          return undefined;
        })}
      </Flexbox>
    );
  }
}
