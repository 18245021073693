/* Legacy code - ignore complexity and magic number errors */
/* eslint complexity: 0 no-magic-numbers: 0 */
import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { Button, HTMLSelect } from '@blueprintjs/core';
import OperandDefinitions from '../../static/OperandDefinitions';
import StateConditionItemValue from './StateConditionItemValue';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

@inject('StateRuleStore', 'TemplateStore')
@observer
export default class StateRuleConditionItem extends React.Component {
  @observable
  selectedElement = null;
  @observable
  operands = OperandDefinitions.operands;

  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  componentDidMount() {
    this.findElement(this.props);
  }

  componentDidUpdate(nextProps) {
    this.findElement(nextProps);
  }

  findElement(props) {
    const findElement = _.find(props.TemplateStore.allElements, element => {
      if (!element.isDisplayElement || element.elementType === 'calculation') {
        return element.id === props.stateCondition.elementId;
      }
      return false;
    });
    if (findElement) {
      this.selectedElement = findElement;
      this.adjustOperands();
    }
  }

  adjustOperands() {
    switch (this.selectedElement.elementType) {
      case 'text_input':
      case 'textarea':
      case 'rich_textarea':
      case 'email':
      case 'hyperlink_input':
      case 'select':
        _.map(this.operands, operand => {
          if (operand.id > 6) {
            operand.disabled = true;
          }
        });
        break;
      case 'profile_value':
        _.map(this.operands, operand => {
          operand.disabled = false;
        });
        break;
      case 'multiline':
        _.map(this.operands, operand => {
          if (operand.id !== 1) {
            operand.disabled = true;
          }
        });
        break;
      case 'number':
      case 'percentage':
      case 'currency':
      case 'slider':
      case 'calculation':
        _.map(this.operands, operand => {
          if (operand.id === 3 || operand.id === 4 || operand.id === 5 || operand.id === 6 || operand.id === 9 || operand.id === 10) {
            operand.disabled = true;
          }
        });
        break;
      case 'date':
      case 'date_range':
      case 'date_time':
      case 'time':
        _.map(this.operands, operand => {
          if (operand.id === 3 || operand.id === 4 || operand.id === 5 || operand.id === 6) {
            operand.disabled = true;
          }
        });
        break;
      case 'user_select':
      case 'team_select':
      case 'item_select':
        _.map(this.operands, operand => {
          if (operand.id > 2) {
            operand.disabled = true;
          }
        });
        break;
      default:
        return null;
    }
    return undefined;
  }

  handleConditionDelete() {
    if (this.props.stateCondition.changeType === 'added') {
      this.props.StateRuleStore.removeStateCondition(this.props.stateCondition.id);
    } else {
      this.props.stateCondition.changeType = 'deleted';
    }
  }

  handleOperandChange(e) {
    this.props.stateCondition.operand = e.target.value;
    this.markChange();
  }

  handleElementChange(e) {
    this.props.stateCondition.elementId = e.target.value;
    this.markChange();
  }

  markChange() {
    if (this.props.stateCondition.changeType !== 'added') {
      this.props.stateCondition.changeType = 'changed';
    }
  }

  renderValue() {
    if (this.props.stateCondition.operand !== 'is_blank' && this.props.stateCondition.operand !== 'is_not_blank') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <StateConditionItemValue stateCondition={this.props.stateCondition} selectedElement={this.selectedElement} />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexGrow={1} marginBottom="10px">
        <Flexbox flexDirection="column" paddingRight="10px">
          {this.props.dragHandle}
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox marginRight="10px">
            <HTMLSelect name="element" onChange={this.handleElementChange.bind(this)} defaultValue={this.props.stateCondition.elementId}>
              <option value={null} disabled={this.props.stateCondition.elementId}>
                {I18n.t('js.select_element_condition')}
              </option>
              {this.props.TemplateStore.allElements
                .filter(e => e.isAvailableForRuleConditions)
                .map(e => (
                  <option key={e.id} value={e.id}>
                    {_.truncate(e[e.camelCaseTitle].name, {
                      length: 40,
                    })}
                  </option>
                ))}
            </HTMLSelect>
          </Flexbox>
          <Flexbox marginRight="10px">
            <HTMLSelect name="operand" onChange={this.handleOperandChange.bind(this)} defaultValue={this.props.stateCondition.operand}>
              <option value={null} disabled={this.props.stateCondition.operand}>
                {I18n.t('js.select_operator')}
              </option>
              {_.map(this.operands, operand => (
                <option key={operand.id} value={operand.name} disabled={operand.disabled}>
                  {operand.text.toLowerCase()}
                </option>
              ))}
            </HTMLSelect>
          </Flexbox>
          {this.renderValue()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={0}>
          <Button icon="trash" text={_.truncate(I18n.t('js.delete'), { length: 12 })} onClick={this.handleConditionDelete.bind(this)} />
        </Flexbox>
      </Flexbox>
    );
  }
}
