import React from 'react';
import Flexbox from 'flexbox-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import TeamRequestsListRequests from './TeamRequestsListRequests';

@inject('CurrentUserStore', 'TeamUsersRequestsStore', 'ToastStore')
@observer
export default class TeamRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  componentDidMount() {
    this.toggleLoading();
    this.props.TeamUsersRequestsStore.resetStartingAttributes();
    this.props.TeamUsersRequestsStore.fetchTeam(this.props.teamId)
      .then(() => {
        const appNum = 1;
        this.props.TeamUsersRequestsStore.fetchUserTeamRequests(
          this.props.teamId,
          appNum,
          this.props.TeamUsersRequestsStore.pagination.xPerPage,
          this.props.TeamUsersRequestsStore.pagination.xOffset
        )
          .then(() => {
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderUserTeamRequests() {
    if (this.props.TeamUsersRequestsStore.userTeamRequests.length === 0) {
      return (
        <Flexbox flexGrow={1}>
          <Flexbox paddingTop="50px" paddingBottom="50px" justifyContent="center" alignItems="center" flexGrow={1} className="bp3-card">
            <NonIdealState
              title={I18n.t('js.nothing_to_show')}
              description={I18n.t('js.there_are_no_requests_to_join_this_team')}
              icon="envelope"
              className="bp3-text-muted"
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <TeamRequestsListRequests teamId={this.props.teamId} loading={this.state.loading} toggleLoading={() => this.toggleLoading()} />
      </Flexbox>
    );
  }

  renderUserTeamRequestCount() {
    if (this.props.TeamUsersRequestsStore.pagination.xTotal > 0) {
      return <span>({this.props.TeamUsersRequestsStore.pagination.xTotal})</span>;
    }
    return undefined;
  }
  render() {
    if (this.state.loading) {
      return (
        <Flexbox flexGrow={1} justifyContent="center" alignItems="center" paddingTop="50px" paddingBottom="50px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderUserTeamRequests()}
        </Flexbox>
      </Flexbox>
    );
  }
}
