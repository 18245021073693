// @flow
import * as React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { HTMLSelect } from '@blueprintjs/core';

type ReportTemplateSelectorProps = {
  DataViewStore: any,
};

@inject('DataViewStore')
@observer
export default class ReportTemplateSelector extends React.Component<ReportTemplateSelectorProps> {
  // $FlowFixMe
  handleSelectReportTemplate = ({ target: selectedOption }) => {
    const { value: selectedId } = selectedOption;
    this.props.DataViewStore.setActiveReportTemplate(selectedId);
  };

  render() {
    const { filteredReportTemplates, activeReportTemplate } = this.props.DataViewStore;
    return (
      <HTMLSelect
        className="report-template-selector push-10-r push-10-l"
        name="reportTemplateSelect"
        onChange={this.handleSelectReportTemplate}
        value={activeReportTemplate.id}
      >
        {filteredReportTemplates.map(reportTemplate => (
          <option value={reportTemplate.id} key={reportTemplate.id}>
            {_.truncate(reportTemplate.name, { length: 25 })}
          </option>
        ))}
      </HTMLSelect>
    );
  }
}
