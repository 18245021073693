import React from 'react';
import { Tag } from '@blueprintjs/core';

export default class StatusTag extends React.Component {
  render() {
    return (
      <Tag
        style={{
          backgroundColor: this.props.color,
        }}
      >
        {this.props.name}
      </Tag>
    );
  }
}
