import React from 'react';
import Flexbox from 'flexbox-react';
import { observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';

@observer
export default class FormElementTitle extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          <h2
            className={Classes.HEADING}
            style={{
              margin: '0px',
              paddingTop: '10px',
            }}
          >
            {this.props.element.elementTitle.name}
          </h2>
        </Flexbox>
      </Flexbox>
    );
  }
}
