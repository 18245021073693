// @flow
import * as React from 'react';

const skeletonBoxStyle = {
  minWidth: '120px',
  minHeight: '120px',
  borderRadius: '100%',
};

type SkeletonRoundProps = {
  height: number,
};

class SkeletonRound extends React.Component<SkeletonRoundProps> {
  static defaultProps = {
    height: 120,
  };

  render() {
    const { height } = this.props;
    return (
      <div
        className="skeleton-box bp3-skeleton"
        style={{ ...skeletonBoxStyle, height: `${height}px` }}
      />
    );
  }
}

export default SkeletonRound;
