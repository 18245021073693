import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { CardElement } from 'react-stripe-elements';
import { FormGroup } from '@blueprintjs/core';
import classNames from 'classnames';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class AccountBillingCardSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      hasError: false,
      errorText: null,
    };
  }

  toggleFocus() {
    this.setState({ isFocused: !this.state.isFocused });
  }

  handleChange(change) {
    if (change.error) {
      this.props.AccountBillingStore.newSubscriptionNumberValid = false;
      this.setState({
        hasError: true,
        errorText: change.error.message,
      });
    } else {
      this.props.AccountBillingStore.newSubscriptionNumberValid = true;
      this.setState({ hasError: false, errorText: null });
    }
  }

  render() {
    let inputClass = classNames('bp3-input');
    if (this.state.isFocused) {
      inputClass = classNames('bp3-input bp3-input-is-focused');
    } else {
      if (this.state.hasError) {
        inputClass = classNames('bp3-input bp3-input-has-error');
      }
    }
    return (
      <FormGroup
        helperText={this.state.errorText}
        label={I18n.t('js.card_details')}
        labeInfo="(required)"
        className={this.state.hasError ? 'bp3-intent-danger' : ''}
      >
        <Flexbox className={inputClass} flexGrow={1} flexDirection="column" paddingTop="7px">
          <CardElement
            style={{
              base: {
                fontFamily: 'Inter',
                color: '#182026',
                fontSize: '14px',
                fontWeight: '400',
                '::placeholder': {
                  color: 'rgba(92, 112, 128, .5)',
                },
              },
            }}
            hidePostalCode={true}
            onBlur={this.toggleFocus.bind(this)}
            onFocus={this.toggleFocus.bind(this)}
            onChange={this.handleChange.bind(this)}
          />
        </Flexbox>
      </FormGroup>
    );
  }
}
