/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Tab, Tabs, Button, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import SocialAuthButton from './SocialAuthButton';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AuthStore', 'ToastStore')
@observer
export default class SignInNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.AuthStore.signUpFormValid) {
      this.toggleLoading();
      this.setState({ isBlurred: [] });
      this.props.AuthStore.createSignUp()
        .then(() => {
          // FIXME
          // this.toggleLoading();
        })
        .catch(() => {
          this.toggleLoading();
        });
    } else {
      this.setState({
        isBlurred: ['firstName', 'lastName', 'email', 'password', 'passwordConfirmation'],
      });
    }
  }

  handleInputChange(e) {
    this.props.AuthStore.updateNewSignUp(e.target.value, e.target.name);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.AuthStore.newSignUp[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.AuthStore.newSignUp[`${field}Error`]}</div>;
    }
    return undefined;
  }

  renderEmailForm() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Flexbox flexDirection="row" flexGrow={1} className="push-20-t">
          <Flexbox
            flexGrow={1}
            className={
              _.includes(this.state.isBlurred, 'firstName') && !this.props.AuthStore.newSignUp.firstNameValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <label className="bp3-label" htmlFor="firstName">
              {I18n.t('js.first_name')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'firstName') && !this.props.AuthStore.newSignUp.firstNameValid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  className="bp3-input bp3-fill"
                  type="text"
                  placeholder={I18n.t('js.first_name')}
                  name="firstName"
                  value={this.props.AuthStore.newSignUp.firstName}
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </Flexbox>
              {this.renderErrors('firstName')}
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox
            flexGrow={1}
            className={
              _.includes(this.state.isBlurred, 'lastName') && !this.props.AuthStore.newSignUp.lastNameValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <label className="bp3-label" htmlFor="lastName">
              {I18n.t('js.last_name')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'lastName') && !this.props.AuthStore.newSignUp.lastNameValid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  className="bp3-input bp3-fill"
                  type="text"
                  placeholder={I18n.t('js.last_name')}
                  name="lastName"
                  value={this.props.AuthStore.newSignUp.lastName}
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </Flexbox>
              {this.renderErrors('lastName')}
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox
            flexGrow={1}
            className={
              _.includes(this.state.isBlurred, 'email') && !this.props.AuthStore.newSignUp.emailValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <label className="bp3-label" htmlFor="email">
              {I18n.t('js.email')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'email') && !this.props.AuthStore.newSignUp.emailValid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  className="bp3-input bp3-fill"
                  type="text"
                  placeholder={I18n.t('js.someone_example_com')}
                  name="email"
                  value={this.props.AuthStore.newSignUp.email}
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </Flexbox>
              {this.renderErrors('email')}
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox
            flexGrow={1}
            className={
              _.includes(this.state.isBlurred, 'password') && !this.props.AuthStore.newSignUp.passwordValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <label className="bp3-label" htmlFor="password">
              {I18n.t('js.password')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'password') && !this.props.AuthStore.newSignUp.passwordValid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="password"
                  placeholder={I18n.t('js.password')}
                  name="password"
                  value={this.props.AuthStore.newSignUp.password}
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </Flexbox>
              {this.renderErrors('password')}
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox
            flexGrow={1}
            className={
              _.includes(this.state.isBlurred, 'passwordConfirmation') && !this.props.AuthStore.newSignUp.passwordConfirmationValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <label className="bp3-label" htmlFor="passwordConfirmation">
              {I18n.t('js.confirm_password')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'passwordConfirmation') && !this.props.AuthStore.newSignUp.passwordConfirmationValid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="password"
                  placeholder={I18n.t('js.confirm_password')}
                  name="passwordConfirmation"
                  value={this.props.AuthStore.newSignUp.passwordConfirmation}
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </Flexbox>
              {this.renderErrors('passwordConfirmation')}
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Button
            intent={Intent.PRIMARY}
            type="submit"
            className="bp3-fill bp3-large"
            text={I18n.t('js.sign_up')}
            loading={this.state.isSubmitting}
          />
        </Flexbox>
      </form>
    );
  }

  renderConnectedAccounts() {
    return (
      <Flexbox flexDirection="column">
        <SocialAuthButton
          text={I18n.t('js.sign_up_with_google')}
          connection="google-oauth2"
          image="https://res.cloudinary.com/dkctgehrc/image/upload/v1497899678/google_logo_transparent_whvdyb.png"
        />
        <Button
          type="button"
          intent={Intent.PRIMARY}
          className="bp3-fill bp3-large bp3-icon-envelope"
          text={I18n.t('js.sign_up_with_email')}
          loading={this.state.isSubmitting}
          onClick={() => this.props.AuthStore.updateActiveTab('email')}
        />
      </Flexbox>
    );
  }

  render() {
    return (
      <div>
        <Tabs
          selectedTabId={this.props.AuthStore.activeTab}
          defaultSelectedTabId="social"
          onChange={tab => this.props.AuthStore.updateActiveTab(tab)}
        >
          <Tab id="email" title={I18n.t('js.email')} panel={this.renderEmailForm()} />
          <Tab id="social" title={I18n.t('js.social')} panel={this.renderConnectedAccounts()} />
        </Tabs>
      </div>
    );
  }
}
