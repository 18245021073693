import { computed, observable } from 'mobx';

export default class SignIn {
  @observable
  email;

  constructor(email) {
    this.email = email || '';
  }

  @computed
  get emailValid() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email !== '' && exp.test(this.email)) {
      return true;
    }
    return false;
  }

  @computed
  get emailError() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email === '') {
      return I18n.t('js.email_is_required');
    }
    if (!exp.test(this.email)) {
      return I18n.t('js.email_is_invalid');
    }
    return null;
  }
}
