import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

@inject(
  'AdminActions',
  'AdminStore',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class AdminEditBlogPost extends React.Component {
  @observable
  isSubmitting = false;

  constructor(props) {
    super(props);

    this.state = { isBlurred: [] };
  }

  toggleSubmitting() {
    this.isSubmitting = !this.isSubmitting;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    const blogPostObj = {
      id: this.props.AdminStore.activeItem.id,
      title: this.props.AdminStore.activeItem.title,
      body: this.props.AdminStore.activeItem.body,
      url: this.props.AdminStore.activeItem.url,
    };
    this.props.AdminActions.changeBlogPost(blogPostObj).then(
      response => {
        this.props.AdminStore.addBlogPost(response.data);
        this.props.AdminStore.resetNewBlogPost();
        this.props.AdminStore.setActiveItem(null, null);
        this.props.ToastStore.showToast(
          'Blog post updated',
          'success'
        );
      }
    );
  }

  handleInputChange(e) {
    this.props.AdminStore.updateActiveItem(
      e.target.value,
      e.target.name
    );
  }

  handleCancel() {
    this.props.AdminStore.resetNewBlogPost();
    this.props.AdminStore.setActiveItem(null, null);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.AdminStore.activeItem[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.AdminStore.activeItem[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        className="bp3-card"
        flexGrow={1}
      >
        <Flexbox>
          <Flexbox flexGrow={1}>
            <h3 className={Classes.HEADING}>
              {this.props.AdminStore.activeItem.title}
            </h3>
          </Flexbox>
          <Flexbox
            flexGrow={0}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              text="Back"
              onClick={() =>
                this.props.AdminStore.setActiveItem(null, null)
              }
              rightIcon="undo"
              className="bp3-minimal"
            />
          </Flexbox>
        </Flexbox>
        <Flexbox flexGrow={1} flexDirection="column">
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'title') &&
                  !this.props.AdminStore.activeItem.titleValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  Title
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'title') &&
                      !this.props.AdminStore.activeItem.titleValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder="Enter title..."
                      name="title"
                      value={this.props.AdminStore.activeItem.title}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('title')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text="Update blog post"
                loading={this.isSubmitting}
                className="push-10-r"
              />
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.handleCancel.bind(this)}
              />
            </Flexbox>
          </form>
        </Flexbox>
      </Flexbox>
    );
  }
}
