/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import CurrentUserStore from './CurrentUserStore';
import ToastStore from './ToastStore';
import axios from 'axios';
import moment from 'moment';
import nanoid from 'nanoid';
import _ from 'lodash';

class TimelineStore {
  @observable
  isLoading = true;
  @observable
  timelines = [];
  @observable
  activeTimeline = { event_start_date: new Date(), event_end_date: new Date(), event_description: '' };

  // Client

  @action
  resetActiveTimeline() {
    this.activeTimeline = { event_start_date: new Date(), event_end_date: new Date(), event_description: '' };
  }

  @action
  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action
  setActiveTimeline(timeline) {
    this.activeTimeline = _.cloneDeep(timeline);
  }

  @action
  addTimeline(data) {
    this.timelines = _.unionBy([data], this.timelines, 'id');
    this.sortTimelines();
  }

  @action
  removeTimeline(id) {
    this.timelines = _.filter(this.timelines, o => o.id !== id);
    this.sortTimelines();
  }

  @action
  updateActiveTimelineAttribute(value, attribute) {
    this.activeTimeline[attribute] = value;
  }

  // Graph

  @computed
  get timelineItems() {
    const timelineItems = [];
    _.map(this.timelines, (timeline, index) => {
      const newItem = {
        id: timeline.id,
        start: new Date(timeline.event_start_date),
        content: `<span>${moment(timeline.event_start_date).format('MMMM Do YYYY')}</span><br /><strong>${
          timeline.event_description
        }</strong>`,
      };
      if (timeline.event_end_date != timeline.event_start_date) {
        newItem.end = timeline.event_end_date;
        newItem.content = `<span>${moment(timeline.event_start_date).format('MMMM Do YYYY')} - ${moment(timeline.event_end_date).format(
          'MMMM Do YYYY'
        )}</span><br /><strong>${timeline.event_description}</strong>`;
      }
      if (timeline.user_id === CurrentUserStore.currentUser.id) {
        newItem.title = I18n.t('js.double_click_to_edit');
      }
      timelineItems.push(newItem);
    });
    return timelineItems.slice();
  }

  // Server

  @action
  createTimeline(timelineObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/timeline_events`, timelineObj)
        .then(response => {
          this.addTimeline(response.data);
          resolve(response);
          ToastStore.showToast(I18n.t('js.timeline_event_has_been_created'), 'success');
          this.resetActiveTimeline();
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeTimeline(timelineObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/timeline_events/${timelineObj.id}`, timelineObj)
        .then(response => {
          this.addTimeline(response.data);
          resolve(response);
          ToastStore.showToast(I18n.t('js.timeline_event_has_been_updated'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteTimeline(timelineId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/timeline_events/${timelineId}`)
        .then(response => {
          ToastStore.showToast(I18n.t('js.timeline_event_has_been_deleted'), 'success');
          this.removeTimeline(response.data.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchUserTimelines(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/timeline_events?user_id=${userId}`)
        .then(response => {
          _.map(response.data, timeline => {
            this.addTimeline(timeline);
          });
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  // Utility

  @action
  sortTimelines() {
    this.timelines = _.orderBy(this.timelines, ['event_start_date'], ['asc']);
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.timelines = [];
    this.activeTimeline = { event_start_date: new Date(), event_end_date: new Date(), event_description: '' };
  }
}

const store = new TimelineStore();
export default store;
