import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { Button, Intent, Drawer, Classes, Checkbox, Label, Spinner, Collapse, Tag } from '@blueprintjs/core';
import Utilities from '../../../utils/Utilities';
import Avatar from 'react-avatar';
import moment from 'moment';

@inject('ToastStore', 'AccountSettingsStore')
@observer
export default class SyncDrawer extends React.Component {
  @observable
  isLoading = false;
  @observable
  isUsersOpen = true;
  @observable
  isTeamsOpen = true;
  @observable
  isErrorsOpen = true;

  constructor(props) {
    super(props);
    this.toggleBool = this.toggleBool.bind(this);
  }

  whatIntent(sync_type) {
    switch (sync_type) {
      case 'inserted':
        return 'SUCCESS';
        break;
      case 'updated':
        return 'PRIMARY';
        break;
      case 'deleted':
        return 'DANGER';
        break;
      default:
    }
  }

  toggleBool(name) {
    this[`${name}`] = !this[`${name}`];
  }

  renderUsers() {
    const user_syncs = this.props.store.userSyncs;

    const numberOfSyncedUsers = user_syncs.length;
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Flexbox flexDirection="column">
            <h2>{numberOfSyncedUsers} synced users</h2>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.isUsersOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.isUsersOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleBool('isUsersOpen')}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.isUsersOpen}>
          <table className="bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-small full-table">
            <tbody>
              {user_syncs.map(user_sync => (
                <tr key={user_sync.id}>
                  <td onClick={() => Utilities.navigate(`/users/${user_sync.user.id}`, true)} className="pointer">
                    <Flexbox flexDirection="row">
                      <Flexbox flexDirection="row" flexGrow={1}>
                        <Tag className="push-10-r" intent={this.whatIntent(user_sync.sync_type)}>
                          {user_sync.sync_type.charAt(0).toUpperCase() + user_sync.sync_type.slice(1)}
                        </Tag>
                        <Avatar src={user_sync.user.image_file_name} size="20" round={true} className="push-10-r" />
                        <span>{user_sync.user.full_name}</span>
                      </Flexbox>
                      <Flexbox>
                        <small className="push-0">{moment.utc(user_sync.updated_at).format('h:mm:ss a, DD/MM/YY')}</small>
                      </Flexbox>
                    </Flexbox>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Collapse>
      </Flexbox>
    );
  }

  renderTeamSyncs() {
    const team_array = this.props.store.teamArray;
    const numberOfSyncedTeams = team_array.length;
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Flexbox flexDirection="column">
            <h2>{numberOfSyncedTeams} synced teams</h2>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.isTeamsOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.isTeamsOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleBool('isTeamsOpen')}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.isTeamsOpen}>
          <table className="bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-small full-table">
            <tbody>
              {team_array.map(team => (
                <tr key={team.id}>
                  <td onClick={() => Utilities.navigate(`/teams/${team.id}`, true)} className="pointer">
                    <Flexbox flexDirection="row">
                      <Flexbox flexDirection="row" flexGrow={1}>
                        <Avatar src={team.image} size="20" round={true} className="push-10-r" />
                        <span>{team.name}</span>
                      </Flexbox>
                      <Flexbox>
                        <small className="push-0">{moment.utc(team.updated_at).format('h:mm:ss a, DD/MM/YY')}</small>
                      </Flexbox>
                    </Flexbox>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Collapse>
      </Flexbox>
    );
  }

  renderErrors() {
    const response = this.props.store.response;
    const employees = response != null ? _.values(response.employees) : '';
    const sync_errors = this.props.store.syncErrors;
    const numberOfSyncedErrors = sync_errors.length;

    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Flexbox flexDirection="column">
            <h2>{numberOfSyncedErrors} sync errors</h2>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.isErrorsOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.isErrorsOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleBool('isErrorsOpen')}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.isErrorsOpen}>
          <table className="bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-small full-table">
            <tbody>
              {sync_errors.map(sync_error => (
                <tr key={sync_error.id}>
                  <td onClick={() => Utilities.navigate(`/users/${sync_error.user.id}`, true)} className="pointer">
                    <Flexbox flexDirection="row">
                      <Flexbox flexDirection="row" flexGrow={1}>
                        {sync_error.user == null ? null : (
                          <>
                            <Avatar src={sync_error.user.image_file_name} size="20" round={true} className="push-10-r" />
                            <span>{sync_error.user.full_name}</span>
                          </>
                        )}
                      </Flexbox>
                      <Flexbox>
                        <Tag className="push-10-l" intent={Intent.DANGER}>
                          {sync_error.error_message}
                        </Tag>
                      </Flexbox>
                    </Flexbox>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Collapse>
      </Flexbox>
    );
  }

  render() {
    const syncsDetailsLoading = this.props.store.syncsDetailsLoading;
    return (
      <Drawer
        portalContainer={document.body}
        icon="info-sign"
        onClose={this.props.onClose}
        title="Synchronization Details"
        isOpen={this.props.isDrawerOpen}
        position="RIGHT"
        size={Drawer.SIZE_STANDARD}
      >
        <div className={Classes.DRAWER_BODY}>
          {syncsDetailsLoading ? (
            <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
              <Spinner />
            </Flexbox>
          ) : (
            <div className={Classes.DIALOG_BODY}>
              {this.renderErrors()}
              {this.renderUsers()}
              {this.renderTeamSyncs()}
            </div>
          )}
        </div>
      </Drawer>
    );
  }
}
