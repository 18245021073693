import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes } from '@blueprintjs/core';
import StringToMarkdown from '../shared/StringToMarkdown';
import moment from 'moment';
import _ from 'lodash';

@inject('AdminActions', 'AdminStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class NewsList extends React.Component {
  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        {_.map(this.props.AdminStore.news, news => (
          <Flexbox key={news.sys.id} flexGrow={1} flexDirection="column" marginBottom="30px" marginTop="30px">
            <h3 className={`${Classes.HEADING} bp3-primary`}>{news.fields.title}</h3>
            <hr />
            <small className="bp3-text-muted">
              <span className="bp3-icon-calendar push-5-r" />
              {I18n.t('js.published_on', {
                date: moment.utc(news.fields.publishedOn).format('MMMM Do YYYY, h:mm a'),
              })}
            </small>
            <Flexbox flexDirection="row" marginTop="20px">
              <Flexbox padding="15px" marginRight="20px">
                <img
                  className="news-img"
                  src={news.fields.thumbnail.fields.file.url}
                  title={news.fields.thumbnail.fields.description}
                  alt={news.fields.thumbnail.fields.description}
                />
              </Flexbox>
              <Flexbox flexDirection="column" className="bp3-running-text" flexGrow={0}>
                <StringToMarkdown string={news.fields.body} />
                <Flexbox flexDirection="row">
                  <a href={news.fields.url}>
                    <Button type="button" className="bp3-icon-book bp3-intent-primary" text={I18n.t('js.read_more')} />
                  </a>
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        ))}
      </Flexbox>
    );
  }
}
