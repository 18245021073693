import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tree, Classes, HTMLSelect, NonIdealState, Spinner } from '@blueprintjs/core';
import Paginator from '../shared/Paginator';
import _ from 'lodash';
import DynamicUserSuggest from './DynamicUserSuggest';
import DynamicMultiSelectActions from './DynamicMultiSelectActions';

@inject('DynamicViewStore', 'AppStore', 'WorkspaceStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynmaicAllItemsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleLoading() {
    this.setState({ isLoading: !this.state.isLoading });
  }

  handleNodeInteract(nodeData) {
    switch (nodeData.nodeType) {
      case 'relationship':
        const relationshipType = nodeData.id;
        this.props.DynamicViewStore.toggleUser(relationshipType);
        break;
      case 'user':
        const userId = nodeData.id;
        this.props.DynamicViewStore.toggleUser(userId);
        break;
      case 'item':
        // const itemId = nodeData.id;
        // window.location = `/items/${itemId}`;
        break;
    }
  }

  handleNodeClick(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeCollapse(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeExpand(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handlePageChange(pageNumber) {
    this.toggleLoading();
    this.props.ItemActions.fetchAppItems(
      this.props.AppStore.activeApp.id,
      pageNumber,
      this.props.DynamicViewStore.itemPagination.xPerPage,
      this.props.DynamicViewStore.itemPagination.xOffset,
      this.props.DynamicViewStore.activeStatusFlag?.name
    )
      .then(response => {
        this.props.DynamicViewStore.items = [];
        this.props.DynamicViewStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.DynamicViewStore.addItem(item);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handlePerPageChange(e) {
    this.toggleLoading();
    const appNum = 1;
    this.props.ItemActions.fetchAppItems(
      this.props.AppStore.activeApp.id,
      appNum,
      e.target.value,
      this.props.DynamicViewStore.itemPagination.xOffset,
      this.props.DynamicViewStore.activeStatusFlag?.name
    )
      .then(response => {
        this.props.DynamicViewStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.DynamicViewStore.addItem(item);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderPagination() {
    if (this.props.DynamicViewStore.itemPagination.xTotal > this.props.DynamicViewStore.itemPagination.xPerPage) {
      return (
        <Paginator
          paginationObject={this.props.DynamicViewStore.itemPagination}
          pageRangeDisplayed={25}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderTree() {
    const { isLoading, itemTree } = this.props.DynamicViewStore;

    if (isLoading || this.state.isLoading) {
      return (
        <NonIdealState
          title={I18n.t('js.loading')}
          description={I18n.t('js.loading_data')}
          icon={<Spinner />}
          className="bp3-text-muted push-20-t pad-20"
        />
      );
    } else {
      return (
        <Tree
          contents={itemTree}
          onNodeClick={this.handleNodeClick.bind(this)}
          onNodeCollapse={this.handleNodeCollapse.bind(this)}
          onNodeExpand={this.handleNodeExpand.bind(this)}
          className={Classes.ELEVATION_0}
        />
      );
    }
  }

  render() {
    const { statusFlags, isLoadingStatusFlags, activeApp, isLoadingAllItems } = this.props.DynamicViewStore;
    if (isLoadingAllItems || this.state.isLoading) {
      return (
        <Flexbox flexDirection="column" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted push-20-t"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row" marginBottom="10px">
          <Flexbox flexDirection="row" flexGrow={1}>
            <Flexbox className="bp3-select" marginRight="10px">
              <HTMLSelect
                disabled={activeApp === null || isLoadingStatusFlags}
                value={this.props.DynamicViewStore.activeStatusFlag?.id}
                onChange={this.props.handleStatusFlagChange.bind(this)}
              >
                <option defaultValue value="reset">
                  {I18n.t('js.any_current_status')}
                </option>
                {_.map(statusFlags, statusFlag => (
                  <option key={statusFlag.id} value={statusFlag.id}>
                    {statusFlag.name}
                  </option>
                ))}
              </HTMLSelect>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <DynamicUserSuggest />
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <DynamicMultiSelectActions allowMultiSelect={true} />
        <Flexbox flexDirection="column" className="bp3-card bp3-card-no-padding bp3-cursor-pointer">
          {this.renderTree()}
        </Flexbox>
        {this.renderPagination()}
      </Flexbox>
    );
  }
}
