/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { computed } from 'mobx';
import Flexbox from 'flexbox-react';
import ItemElement from './ItemElement';
import { inject, observer } from 'mobx-react';
import { Classes, NonIdealState, Collapse, Button, Alert, Intent, Tooltip, Position, Dialog, FormGroup, TagInput } from '@blueprintjs/core';
import classNames from 'classnames';
import ItemSectionInvite from './ItemSectionInvite';
import Avatar from 'react-avatar';
import CommentsView from '../../views/CommentsView';
import _ from 'lodash';

@inject('ItemStore', 'CurrentUserStore')
@observer
export default class ItemSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRemoveExposedAlert: false,
      showInvitationDialog: false,
    };
  }

  @computed
  get isCollapsed() {
    if (_.find(this.props.ItemStore.collapsedSections, o => o === this.props.section.id)) {
      return true;
    }
    return false;
  }

  @computed
  get isSectionExposed() {
    if (this.props.section.optional) {
      return this.props.ItemStore.exposedSections.includes(this.props.section.id);
    }
    return true;
  }

  @computed
  get isSectionReadOnly() {
    let readOnly = false;
    _.map(this.props.ItemStore.readOnlySections, state_section => {
      if (state_section.section_id == this.props.section.id) {
        readOnly = true;
      }
    });
    return readOnly;
  }

  toggleCollapse() {
    this.props.ItemStore.toggleSectionCollapse(this.props.section.id);
  }

  toggleInviteSectionDialog(id) {
    this.props.ItemStore.setActiveSectionInvitationId(id);
    this.setState({ showInvitationDialog: !this.state.showInvitationDialog });
  }

  toggleRemovalDialog() {
    this.setState({ showRemoveExposedAlert: !this.state.showRemoveExposedAlert });
  }

  handleRemoveExposedOption() {
    this.props.ItemStore.removeExposedSection(this.props.section.id);
    this.toggleRemovalDialog();
  }

  renderSection() {
    const findItemSectionInvite = _.find(this.props.ItemStore.itemSectionInvites, o => o.section_id == this.props.section.id);
    if (this.props.section.elements.length === 0) {
      return (
        <Flexbox flexDirection="column" className="template-area" marginTop="20px" marginBottom="20px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_elements_in_this_this_section')}
            icon="multi-select"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox marginBottom="10px" flexDirection="column" flexGrow={1} padding="30px">
        {_.map(this.props.section.elements, element => (
          <ItemElement
            key={element.id}
            element={element}
            sectionExposed={this.isSectionExposed}
            findItemSectionInvite={findItemSectionInvite}
          />
        ))}
      </Flexbox>
    );
  }

  renderInvitableButton() {
    if (!this.props.ItemStore.isWebformView) {
      const findItemSectionInvite = _.find(this.props.ItemStore.itemSectionInvites, o => o.section_id == this.props.section.id);
      if (findItemSectionInvite) {
        if (findItemSectionInvite.user) {
          return (
            <Button
              className="push-10-r"
              intent={
                findItemSectionInvite.status == 'closed'
                  ? Intent.SUCCESS
                  : findItemSectionInvite.status == 'expired'
                  ? Intent.WARNING
                  : Intent.DEFAULT
              }
              onClick={() => this.toggleInviteSectionDialog(this.props.section.id)}
            >
              <Flexbox flexDirection="row" alignItems="center" alignContent="center">
                <Avatar src={findItemSectionInvite.user.image_file_name} size={20} round={true} className="push-5-r" />
                {findItemSectionInvite.user.full_name}
              </Flexbox>
            </Button>
          );
        } else {
          if (findItemSectionInvite.external_email) {
            return (
              <Button
                icon="envelope"
                intent={
                  findItemSectionInvite.status == 'closed'
                    ? Intent.SUCCESS
                    : findItemSectionInvite.status == 'expired'
                    ? Intent.WARNING
                    : Intent.DEFAULT
                }
                className="push-10-r"
                text={findItemSectionInvite.external_email}
                onClick={() => this.toggleInviteSectionDialog(this.props.section.id)}
              />
            );
          }
        }
      } else {
        if (this.props.ItemStore.isSectionInvitableInState(this.props.section.id)) {
          return (
            <Button
              icon="envelope"
              className="push-10-r"
              text={I18n.t('js.invite_another_user_to_edit')}
              onClick={() => this.toggleInviteSectionDialog(this.props.section.id)}
            />
          );
        }
      }
    }
  }

  render() {
    if (this.props.section.optional && this.props.ItemStore.userIsOwner) {
      if (!this.isSectionExposed) {
        if (this.isSectionReadOnly) {
          return null;
        }
        return (
          <Flexbox marginBottom="10px">
            <Button
              icon="add"
              minimal={!this.props.shownInDialogView}
              text={I18n.t('js.add_section_with_title', { title: this.props.section.title })}
              onClick={() => this.props.ItemStore.exposeSection(this.props.section.id)}
            ></Button>
          </Flexbox>
        );
      }
    }
    let sectionClass = classNames('bp3-card bp3-card-no-padding');
    if (!this.props.shownInDialogView) {
      sectionClass = classNames(sectionClass, '');
      if (this.props.section.optional) {
        sectionClass = classNames(sectionClass, 'optional-section');
      }
    }

    if (!this.props.ItemStore.userIsOwner && !this.isSectionExposed) {
      return null;
    }
    return (
      <>
        <Flexbox className={sectionClass} flexDirection="column" marginBottom="20px">
          <Flexbox flexGrow={1} className="item-section-header" maxHeight="50px">
            <Flexbox flexGrow={1} paddingLeft="30px" paddingTop="15px">
              <h4 className={Classes.HEADING}>{this.props.section.title}</h4>
            </Flexbox>
            <Flexbox flexGrow={0} flexDirection="row" alignItems="center" justifyContent="center">
              <Flexbox flexDirection="row" justifyContent="center" paddingRight="10px">
                {this.renderInvitableButton()}
                <Tooltip
                  content={this.isCollapsed ? I18n.t('js.expand') : I18n.t('js.collapse')}
                  portalContainer={document.body}
                  position={Position.TOP}
                >
                  <Button icon={this.isCollapsed ? 'expand-all' : 'collapse-all'} minimal={true} onClick={this.toggleCollapse.bind(this)} />
                </Tooltip>
              </Flexbox>
              {this.props.section.optional && !this.isSectionReadOnly && this.props.ItemStore.userIsOwner ? (
                <Flexbox flexDirection="row" justifyContent="center" paddingRight="10px">
                  <Tooltip
                    content={I18n.t('js.remove_section_with_title', { title: this.props.section.title })}
                    portalContainer={document.body}
                    position={Position.TOP}
                  >
                    <Button icon="trash" minimal={true} onClick={this.toggleRemovalDialog.bind(this)}></Button>
                  </Tooltip>
                  <Alert
                    portalContainer={document.body}
                    isOpen={this.state.showRemoveExposedAlert}
                    cancelButtonText={I18n.t('js.cancel')}
                    onCancel={() => this.toggleRemovalDialog()}
                    confirmButtonText={I18n.t('js.remove')}
                    onConfirm={() => this.handleRemoveExposedOption()}
                    intent={Intent.DANGER}
                  >
                    <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
                    {I18n.t('js.are_you_sure_you_want_to_remove_section_with_title', {
                      title: this.props.section.title,
                    })}
                  </Alert>
                </Flexbox>
              ) : null}
            </Flexbox>
          </Flexbox>
          <Collapse isOpen={!this.isCollapsed} keepChildrenMounted={true}>
            {this.renderSection()}
          </Collapse>
          <ItemSectionInvite
            showDialog={this.state.showInvitationDialog}
            toggleDialog={this.toggleInviteSectionDialog.bind(this)}
            section={this.props.section}
          />
        </Flexbox>
        {this.props.ItemStore.isSectionCommentableInState(this.props.section.id) && !this.props.ItemStore.isWebformView && (
          <Flexbox
            flexDirection="column"
            flexGrow={1}
            marginBottom="30px"
            marginTop="-22px"
            className="bp3-card bp3-card-square comment-card bg-gray"
          >
            <CommentsView
              commentable={this.props.ItemStore.item}
              commentableType="Item"
              sectionId={this.props.section.id}
              canPin={false}
              stub={true}
              sortByNew={true}
              showNone={true}
              buttonText={I18n.t('js.comment_on_this_section')}
            />
          </Flexbox>
        )}
      </>
    );
  }
}
