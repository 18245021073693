import React, { Component } from 'react';
import Flexbox from 'flexbox-react';
import StoreCategoryStore from '../../stores/StoreCategoryStore';
import StoreTemplateStore from '../../stores/StoreTemplateStore';
import StoreTemplateActions from '../../actions/StoreTemplateActions';
import {
  Button,
  Dialog,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import StoreTemplateWidget from '../store_templates/StoreTemplateWidget';
import StoreTemplate from '../store_templates/StoreTemplate';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import Utilities from '../../utils/Utilities';
import { Scrollbars } from 'react-custom-scrollbars';
import ContentfulClient from '../../static/ContentfulClient';
import _ from 'lodash';

@inject('WorkspaceStore', 'CurrentUserStore')
@observer
export default class StoreBrowser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      action: 'browse',
    };
  }

  componentDidMount() {
    StoreCategoryStore.tabSelection = this.props.type;
    if (StoreCategoryStore.hasNoData) {
      StoreCategoryStore.resetStartingAttributes();
      StoreCategoryStore.tabSelection = this.props.type;
      ContentfulClient.getEntries({
        content_type: 'storeCategory',
        order: '-sys.createdAt',
      }).then(response => {
        let itemCount = response.items.length;
        _.map(response.items, storeCategory => {
          StoreCategoryStore.addStoreCategory(storeCategory);
          itemCount--;
        });
        if (itemCount === 0) {
          StoreCategoryStore.toggleLoading();
          this.setState({ isLoading: false });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleClose() {
    this.setState({ action: 'browse' });
    this.props.toggleDialog();
  }

  handleFilterChange(e) {
    e.preventDefault();
    StoreCategoryStore.filterQuery = e.target.value;
  }

  handleTemplateClick(storeTemplate) {
    StoreCategoryStore.setActiveStoreTemplate(storeTemplate);
    this.setState({ action: 'template' });
  }

  renderStoreTemplates() {
    if (this.state.action === 'browse') {
      if (StoreCategoryStore.viewedStoreTemplates.length === 0) {
        return (
          <Flexbox flexGrow={1} marginTop="20px">
            <NonIdealState
              title={I18n.t('js.nothing_to_show')}
              description={I18n.t('js.there_are_no_templates_to_show')}
              icon="search-text"
              className="bp3-text-muted"
            />
          </Flexbox>
        );
      }
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          <Scrollbars
            autoHide={true}
            style={{
              height: '70vh',
            }}
          >
            <Flexbox marginTop="20px" flexDirection="row" flexWrap="wrap">
              {StoreCategoryStore.viewedStoreTemplates.map(storeTemplate => (
                <StoreTemplateWidget
                  storeTemplate={storeTemplate}
                  key={storeTemplate.id}
                  storeCategoryView={false}
                  clickHandler={() => this.handleTemplateClick(storeTemplate)}
                />
              ))}
            </Flexbox>
          </Scrollbars>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <Scrollbars
          autoHide={true}
          style={{
            height: '70vh',
          }}
        >
          <StoreTemplate storeTemplate={StoreCategoryStore.activeStoreTemplate} storeCategoryView={true} />
        </Scrollbars>
      </Flexbox>
    );
  }

  render() {
    return (
      <Dialog
        portalContainer={document.body}
        className="image-view-dialog"
        icon="cloud-download"
        isOpen={this.props.isOpen}
        onClose={this.handleClose.bind(this)}
        canOutsideClickClose={true}
        inline={true}
        title={I18n.t('js.browse_templates_from_the_peoplegoal_store')}
      >
        {this.state.isLoading ? (
          <div className="bp3-dialog-body">
            <Flexbox flexGrow={1} flexDirection="column" marginTop="20px">
              <NonIdealState title={I18n.t('js.fetching_templates')} description={I18n.t('js.please_wait')} icon={<Spinner />} />
            </Flexbox>
          </div>
        ) : this.state.action === 'browse' ? (
          <Flexbox className="bp3-dialog-body">
            <Flexbox flexGrow={1} flexDirection="column">
              <Flexbox flexDirection="row" flexGrow={0}>
                <div className="bp3-input-group max-width">
                  <span className="bp3-icon bp3-icon-search" />
                  <input
                    className="bp3-input"
                    type="search"
                    placeholder={I18n.t('js.filter_templates')}
                    dir="auto"
                    value={StoreCategoryStore.filterQuery}
                    onChange={this.handleFilterChange.bind(this)}
                  />
                </div>
              </Flexbox>
              <Flexbox flexDirection="column">{this.renderStoreTemplates()}</Flexbox>
            </Flexbox>
          </Flexbox>
        ) : (
          <Flexbox className="bp3-dialog-body">
            <Flexbox flexGrow={1} flexDirection="column">
              <Flexbox flexDirection="row" flexGrow={0} marginBottom="20px">
                <Button text={I18n.t('js.back')} icon="arrow-left" onClick={() => this.setState({ action: 'browse' })}></Button>
              </Flexbox>
              <Flexbox flexDirection="column">{this.renderStoreTemplates()}</Flexbox>
            </Flexbox>
          </Flexbox>
        )}
      </Dialog>
    );
  }
}
