import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Collapse, FormGroup, Intent } from '@blueprintjs/core';
import ElementMultilineInputs from './ElementMultilineInputs';
import ElementMultilineDisplay from './ElementMultilineDisplay';
import ElementTag from '../../shared/ElementTag';
import ElementIdSelector from '../../shared/ElementIdSelector';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AppStore', 'UserProfileTemplateStore', 'TemplateStore', 'TemplateActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class ElementMultiline extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      is_required: this.store.activeElement.isRequired,
    };
    const elementMultilineObj = {
      id: this.store.activeElement.elementMultiline.id,
      name: this.store.activeElement.elementMultiline.name,
    };
    this.handleInputSubmission()
      .then(() => {
        this.props.TemplateActions.changeSubElement(elementMultilineObj, 'element_multilines')
          .then(() => {
            this.props.TemplateActions.changeElement(elementObj)
              .then(() => {
                this.store.setActiveArea(null);
                this.toggleLoading();
              })
              .catch(() => {
                this.toggleLoading();
              });
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputSubmission() {
    return new Promise((resolve, reject) => {
      const inputsToAdd = [];
      const inputsToDelete = [];
      const inputsToChange = [];

      _.map(this.store.activeElement.elementMultiline.elementMultilineInputs, input => {
        if (input.added) {
          inputsToAdd.push(input);
        } else {
          if (input.changed) {
            inputsToChange.push(input);
          }
        }
      });
      _.map(this.props.element.elementMultiline.elementMultilineInputs, input => {
        const findInput = _.find(this.store.activeElement.elementMultiline.elementMultilineInputs, o => o.id === input.id);
        if (!findInput) {
          inputsToDelete.push(input);
        }
      });

      let totalLength = inputsToAdd.length + inputsToChange.length + inputsToDelete.length;
      if (totalLength === 0) {
        resolve();
      }

      _.map(inputsToAdd, input => {
        const elementMultilineInputObj = {
          element_multiline_id: input.elementMultilineId,
          name: input.name,
          placeholder_text: input.placeholderText,
          is_required: input.isRequired,
          display_label: input.displayLabel,
          display_label_type: input.displayLabelType,
          display_width: input.displayWidth,
          row_order_position: input.rowOrder,
        };
        this.props.TemplateActions.createElementMultilineInput(elementMultilineInputObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(inputsToChange, input => {
        const elementMultilineInputObj = {
          id: input.id,
          name: input.name,
          placeholder_text: input.placeholderText,
          is_required: input.isRequired,
          display_label: input.displayLabel,
          display_label_type: input.displayLabelType,
          display_width: input.displayWidth,
        };
        if (input.rowOrderChanged) {
          elementMultilineInputObj.row_order_position = input.rowOrder;
        }
        this.props.TemplateActions.changeElementMultilineInput(elementMultilineInputObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(inputsToDelete, input => {
        this.props.TemplateActions.deleteElementMultilineInput(input.id)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });
    });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementMultiline[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementMultiline[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-add-to-artifact push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.multiline_element_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementMultiline.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.multiline_name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementMultiline.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={this.store.activeElement.elementMultiline.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <ElementMultilineInputs element={this.props.element} sectionType={this.props.sectionType} />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup label={this.renderLabel(this.store.activeElement.elementMultiline.name)} labelFor={this.store.activeElement.id}>
          <ElementMultilineDisplay element={this.store.activeElement} disabled={!this.props.element.stateElement.editable} />
        </FormGroup>
      );
    }
    return (
      <FormGroup label={this.renderLabel(this.props.element.elementMultiline.name)} labelFor={this.props.element.id}>
        <ElementMultilineDisplay element={this.props.element} disabled={!this.props.element.stateElement.editable} />
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
