import React from 'react';
import { inject, observer } from 'mobx-react';
import UserSuggest from '../suggests/UserSuggest';
import _ from 'lodash';

@inject('DynamicViewStore', 'AppStore', 'WorkspaceStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynamicUserSuggest extends React.Component {
  constructor(props) {
    super(props);

    this.state = { query: '' };
  }

  handleUserClick(user) {
    this.handleUserRemove();
    this.props.DynamicViewStore.setFilteredUser(user);
    this.setState({ query: user.full_name });
  }

  handleUserRemove() {
    this.props.DynamicViewStore.setFilteredUser('');
    this.setState({ query: '' });
  }

  render() {
    const { filteredUser } = this.props.DynamicViewStore;
    return (
      <UserSuggest
        placeholder={I18n.t('js.filter_users')}
        element={null}
        disabled={filteredUser != ''}
        handleUserClick={this.handleUserClick.bind(this)}
        handleUserRemove={this.handleUserRemove.bind(this)}
        validateInput={() => null}
        intent={null}
        whitelistUsers={this.props.whitelistUsers}
        openDrawer={() => null}
        preSelectedUsers={filteredUser != '' ? [filteredUser] : null}
        expandMenu={true}
      />
    );
  }
}
