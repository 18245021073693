import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Alert, Button, Classes, Intent, Tag } from '@blueprintjs/core';
import UserAvatar from '../shared/UserAvatar';

@inject('CurrentUserStore', 'HookStore', 'ToastStore')
@observer
export default class HookRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isRemovingHook: false,
    };
  }

  toggleRemovingHook() {
    this.setState({
      isRemovingHook: !this.state.isRemovingHook,
    });
  }

  handleRemoveHook() {
    this.props.HookStore.deleteHook(this.props.hook.id)
      .then(() => {
        this.props.ToastStore.showToast(I18n.t('js.webhook_has_been_deleted'), 'success');
      })
      .catch(() => {
        this.toggleRemovingHook();
      });
  }

  renderRemoveUser() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isRemovingHook}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleRemovingHook()}
        confirmButtonText={I18n.t('js.delete_webhook')}
        onConfirm={() => this.handleRemoveHook()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_remove_this_hook')}
      </Alert>
    );
  }

  render() {
    return (
      <tr>
        <td>
          <Tag intent={this.props.hook.hookIntent} minimal={true}>
            {this.props.hook.hookType}
          </Tag>
        </td>
        <td>{this.props.hook.url}</td>
        <td>{this.props.hook.createdAtToDate}</td>
        <td>
          <Button className="bp3-small" text={I18n.t('js.delete_webhook')} onClick={() => this.toggleRemovingHook()} />
        </td>
        {this.renderRemoveUser()}
      </tr>
    );
  }
}
