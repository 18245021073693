import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Tabs, Tab, Spinner, NonIdealState, Button } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import TeamsStore from '../stores/TeamsStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import CurrentUserStore from '../stores/CurrentUserStore';
import AccountUsersStore from '../stores/AccountUsersStore';
import AppItemStore from '../stores/AppItemStore';
import ImportStore from '../stores/ImportStore';
import ImportActions from '../actions/ImportActions';
import TeamsShowTeams from '../components/teams/TeamsShowTeams';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import Imports from '../components/imports/Imports';
import Utilities from '../utils/Utilities';

@observer
export default class TeamsImportView extends React.Component {
  @observable
  isLoading = false;
  @observable currentTab = 'Team';

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentWillMount() {
    ImportStore.resetStore();
    TeamsStore.resetStartingAttributes();
  }

  handleTabChange(tab) {
    if (tab !== this.currentTab) {
      ImportStore.resetStore();
      ImportStore.setImportDetails(tab, null);
      this.currentTab = tab;
    }
  }

  renderImports() {
    return (
      <Imports
        importableType={this.currentTab}
        importableId={null}
        heading={
          this.currentTab === 'Team'
            ? I18n.t('js.import_teams')
            : this.currentTab === 'TeamRelationship'
            ? I18n.t('js.import_team_relationships')
            : I18n.t('js.import_users_to_teams')
        }
      />
    );
  }

  renderContent() {
    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row" flexGrow={1}>
        <Flexbox flexGrow={0} flexDirection="column" marginRight="20px">
          <Flexbox marginBottom="10px">
            <Button text={I18n.t('js.back_to_teams')} icon="arrow-left" fill={true} onClick={() => Utilities.navigate('/teams')} />
          </Flexbox>
          <Tabs vertical={true} id="teamsImport" onChange={this.handleTabChange.bind(this)} selectedTabId={this.currentTab}>
            <Tab id="Team" title={I18n.t('js.import_teams')} disabled={ImportStore.isLoadingImports} />
            <Tab id="TeamRelationship" title={I18n.t('js.import_team_relationships')} disabled={ImportStore.isLoadingImports} />
            <Tab id="UserTeam" title={I18n.t('js.import_users_to_teams')} disabled={ImportStore.isLoadingImports} />
          </Tabs>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          {this.renderImports()}
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    const stores = {
      TeamsStore,
      AccountUsersStore,
      CurrentUserStore,
      ImportStore,
      ImportActions,
      AppItemStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
