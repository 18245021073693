import { action, computed, extendObservable, observable } from 'mobx';
import Item from '../models/ItemModel';
import User from '../models/UserModel';
import Team from '../models/TeamModel';
import Participant from '../models/ParticipantModel';
import StateRule from '../models/StateRuleModel';
import StateCondition from '../models/StateConditionModel';
import StateConditionValue from '../models/StateConditionValueModel';
import StateAction from '../models/StateActionModel';
import StateActionEmailRecipient from '../models/StateActionEmailRecipientModel';
import _ from 'lodash';

class StateRuleStore {
  @observable isLoading = true;
  @observable stateRules = [];
  @observable newStateRule = new StateRule();
  @observable activeStateRule = new StateRule();
  @observable activeStateRuleEdit = new StateRule();
  @observable stateConditions = [];
  @observable stateConditionsOriginal = [];
  @observable stateActions = [];
  @observable stateActionsOriginal = [];
  @observable stateActionEmailRecipients = [];
  @observable stateActionEmailRecipientsOriginal = [];
  @observable stateActionEmailRecipientUsers = [];
  @observable stateActionEmailRecipientUsersOriginal = [];
  @observable stateActionEmailRecipientParticipants = [];
  @observable stateActionEmailRecipientParticipantsOriginal = [];

  @computed
  get newStateRuleFormValid() {
    return this.newStateRule.nameValid;
  }

  @computed
  get stateConditionsToAdd() {
    return _.filter(this.stateConditions, o => o.changeType === 'added');
  }

  @computed
  get stateConditionsToChange() {
    return _.filter(this.stateConditions, o => o.changeType === 'changed' || o.changeType === 'row_order_changed');
  }

  @computed
  get stateConditionValuesToChange() {
    let values = [];
    _.filter(this.stateConditions, o => {
      if (o.stateConditionValue.changeType === 'changed') {
        values = values.concat(o.stateConditionValue);
      }
    });
    return values;
  }

  @computed
  get stateConditionsToDelete() {
    return _.filter(this.stateConditions, o => o.changeType === 'deleted');
  }

  @computed
  get activeStateConditions() {
    return _.filter(this.stateConditions, o => o.stateRuleId === this.activeStateRule.id && o.changeType !== 'deleted');
  }

  @computed
  get stateActionsToAdd() {
    return _.filter(this.stateActions, o => o.changeType === 'added');
  }

  @computed
  get stateActionsToChange() {
    return _.filter(this.stateActions, o => o.changeType === 'changed' || o.changeType === 'row_order_changed');
  }

  @computed
  get stateActionsToDelete() {
    return _.filter(this.stateActions, o => o.changeType === 'deleted');
  }

  @computed
  get activeStateActions() {
    return _.filter(this.stateActions, o => o.stateRuleId === this.activeStateRule.id && o.changeType !== 'deleted');
  }

  @computed
  get stateActionEmailRecipientsToAdd() {
    const results = [];
    _.map(this.stateActionEmailRecipients, o => {
      const findAction = _.find(this.stateActions, act => act.id === o.stateActionId);
      if (findAction) {
        if (o.changeType === 'added') {
          results.push(o);
        }
      }
    });
    return results;
  }

  @computed
  get stateActionEmailRecipientsToChange() {
    return _.filter(this.stateActionEmailRecipients, o => o.changeType === 'changed' || o.changeType === 'row_order_changed');
  }

  @computed
  get stateActionEmailRecipientsToDelete() {
    return _.filter(this.stateActionEmailRecipients, o => o.changeType === 'deleted');
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  addStateRule(data) {
    const newRule = new StateRule(
      data.id,
      data.state_id,
      data.name,
      data.is_active,
      data.all_conditions,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    _.map(data.state_conditions, condition => {
      this.addStateCondition(condition);
    });
    _.map(data.state_actions, act => {
      this.addStateAction(act);
    });
    this.stateRules = _.unionBy([newRule], this.stateRules, 'id');
    this.sortStateRules();
  }

  @action
  addStateCondition(data) {
    const newCondition = new StateCondition(
      data.id,
      data.state_rule_id,
      data.element_id,
      data.operand,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by,
      data.change_type
    );
    if (data.state_condition_value !== null) {
      const newConditionValue = new StateConditionValue(
        data.state_condition_value.id,
        data.state_condition_value.state_condition_id,
        data.state_condition_value.value_type,
        data.state_condition_value.string_value,
        data.state_condition_value.number_value,
        data.state_condition_value.decimal_value,
        data.state_condition_value.boolean_value,
        data.state_condition_value.date_value,
        data.state_condition_value.user_id,
        data.state_condition_value.team_id,
        data.state_condition_value.item_id,
        data.state_condition_value.created_at,
        data.state_condition_value.created_by,
        data.state_condition_value.deleted_at,
        data.state_condition_value.deleted_by,
        data.state_condition_value.updated_at,
        data.state_condition_value.updated_by,
        data.state_condition_value.change_type
      );

      if (data.state_condition_value.reference_user) {
        newConditionValue.referenceUser = new User(
          data.state_condition_value.reference_user.id,
          data.state_condition_value.reference_user.email,
          data.state_condition_value.reference_user.first_name,
          data.state_condition_value.reference_user.last_name,
          data.state_condition_value.reference_user.full_name,
          data.state_condition_value.reference_user.account_id,
          data.state_condition_value.reference_user.human_friendly_id,
          data.state_condition_value.reference_user.is_current_user,
          data.state_condition_value.reference_user.is_account_owner,
          data.state_condition_value.reference_user.image_file_name,
          data.state_condition_value.reference_user.background_image_file_name,
          data.state_condition_value.reference_user.last_sign_in_at,
          data.state_condition_value.reference_user.locale,
          data.state_condition_value.reference_user.theme,
          data.state_condition_value.reference_user.created_at,
          data.state_condition_value.reference_user.updated_at,
          data.state_condition_value.reference_user.deleted_at,
          data.state_condition_value.reference_user.created_by,
          data.state_condition_value.reference_user.updated_by,
          data.state_condition_value.reference_user.deleted_by
        );
      }
      if (data.state_condition_value.reference_team) {
        newConditionValue.referenceTeam = new Team(
          data.state_condition_value.reference_team.id,
          data.state_condition_value.reference_team.account_id,
          data.state_condition_value.reference_team.is_default_account,
          data.state_condition_value.reference_team.name,
          data.state_condition_value.reference_team.description,
          data.state_condition_value.reference_team.image_file_name,
          data.state_condition_value.reference_team.background_image_file_name,
          data.state_condition_value.reference_team.private,
          data.state_condition_value.reference_team.is_current_user_team_owner,
          data.state_condition_value.reference_team.is_current_user_team_member,
          data.state_condition_value.reference_team.row_order,
          data.state_condition_value.reference_team.user_count,
          data.state_condition_value.reference_team.comment_count,
          data.state_condition_value.reference_team.import_id,
          data.state_condition_value.reference_team.created_at,
          data.state_condition_value.reference_team.created_by,
          data.state_condition_value.reference_team.deleted_at,
          data.state_condition_value.reference_team.deleted_by,
          data.state_condition_value.reference_team.updated_at,
          data.state_condition_value.reference_team.updated_by
        );
      }
      if (data.state_condition_value.reference_item) {
        newConditionValue.referenceItem = new Item(
          data.state_condition_value.reference_item.id,
          data.state_condition_value.reference_item.app_id,
          data.state_condition_value.reference_item.state_id,
          data.state_condition_value.reference_item.user_id,
          data.state_condition_value.reference_item.status_flag_id,
          data.state_condition_value.reference_item.name,
          data.state_condition_value.reference_item.item_type,
          data.state_condition_value.reference_item.item_icon,
          data.state_condition_value.reference_item.item_color,
          data.state_condition_value.reference_item.item_layout,
          data.state_condition_value.reference_item.image_file_name,
          data.state_condition_value.reference_item.comment_count,
          data.state_condition_value.reference_item.is_done_for_current_user,
          data.state_condition_value.reference_item.is_webform,
          data.state_condition_value.reference_item.import_id,
          data.state_condition_value.reference_item.webform_user_email,
          data.state_condition_value.reference_item.webform_user_full_name,
          data.state_condition_value.reference_item.webform_user_image_file_name,
          data.state_condition_value.reference_item.dataview,
          data.state_condition_value.reference_item.simple_dataview,
          data.state_condition_value.reference_item.created_at,
          data.state_condition_value.reference_item.created_by,
          data.state_condition_value.reference_item.deleted_at,
          data.state_condition_value.reference_item.deleted_by,
          data.state_condition_value.reference_item.updated_at,
          data.state_condition_value.reference_item.updated_by
        );
      }

      extendObservable(newCondition, {
        stateConditionValue: newConditionValue,
      });
    } else {
      const newConditionValue = new StateConditionValue(
        '',
        newCondition.id,
        'string',
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        'original'
      );
      extendObservable(newCondition, {
        stateConditionValue: newConditionValue,
      });
    }
    this.stateConditions = _.unionBy([newCondition], this.stateConditions, 'id');
    this.stateConditionsOriginal = _.unionBy(_.cloneDeep([newCondition]), this.stateConditionsOriginal, 'id');
    this.sortStateConditions();
  }

  @action
  addStateAction(data) {
    const newAction = new StateAction(
      data.id,
      data.state_rule_id,
      data.action_type,
      data.target_state_id,
      data.target_status_flag_id,
      data.target_email_content,
      data.target_email_subject,
      data.timeline_event_participant_id,
      data.timeline_event_description,
      data.webhook_url,
      data.include_calendar_invitation,
      data.calendar_element_id,
      data.include_pdf_attachment,
      data.invite_to_section_user_select_element_id,
      data.invite_to_section_id,
      data.invite_to_section_due_date_increment,
      data.invite_to_section_message,
      data.invite_to_section_due_date,
      data.invite_to_section_has_due_date,
      data.invite_to_section_anonymous,
      data.override_participant_id,
      data.override_participant_user_select_element_id,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by,
      data.change_type
    );
    _.map(data.state_action_email_recipients, recipient => {
      this.addStateActionEmailRecipient(recipient);
    });
    this.stateActions = _.unionBy([newAction], this.stateActions, 'id');
    this.stateActionsOriginal = _.unionBy(_.cloneDeep([newAction]), this.stateActionsOriginal, 'id');
    this.sortStateActions();
  }

  @action
  addStateActionEmailRecipient(data) {
    const newRecipient = new StateActionEmailRecipient(
      data.id,
      data.state_action_id,
      data.user_id,
      data.participant_id,
      data.email,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by,
      data.change_type
    );
    if (data.user) {
      const newUser = new User(
        data.user.id,
        data.user.email,
        data.user.first_name,
        data.user.last_name,
        data.user.full_name,
        data.user.account_id,
        data.user.human_friendly_id,
        data.user.is_current_user,
        data.user.is_account_owner,
        data.user.image_file_name,
        data.user.background_image_file_name,
        data.user.last_sign_in_at,
        data.user.locale,
        data.user.theme,
        data.user.created_at,
        data.user.updated_at,
        data.user.deleted_at,
        data.user.created_by,
        data.user.updated_by,
        data.user.deleted_by
      );
      this.addStateActionEmailRecipientUser(newUser);
    }
    if (data.participant) {
      const newParticipant = new Participant(
        data.id,
        data.app_id,
        data.is_owner,
        data.name,
        data.visibility_level,
        data.relationship_id,
        data.user_id,
        data.created_at,
        data.created_by,
        data.deleted_at,
        data.deleted_by,
        data.updated_at,
        data.updated_by
      );
      this.addStateActionEmailRecipientParticipant(newParticipant);
    }
    this.stateActionEmailRecipients = _.unionBy([newRecipient], this.stateActionEmailRecipients, 'id');
    this.stateActionEmailRecipientsOriginal = _.unionBy(_.cloneDeep([newRecipient]), this.stateActionEmailRecipientsOriginal, 'id');
  }

  @action
  addStateActionEmailRecipientUser(user) {
    this.stateActionEmailRecipientUsers = _.unionBy([user], this.stateActionEmailRecipientUsers, 'id');
    this.stateActionEmailRecipientUsersOriginal = _.unionBy([user], this.stateActionEmailRecipientUsersOriginal, 'id');
  }

  @action
  addStateActionEmailRecipientParticipant(participant) {
    this.stateActionEmailRecipientParticipants = _.unionBy([participant], this.stateActionEmailRecipientParticipants, 'id');
    this.stateActionEmailRecipientParticipantsOriginal = _.unionBy([participant], this.stateActionEmailRecipientParticipantsOriginal, 'id');
  }

  @action
  removeStateRule(id) {
    const newRules = _.filter(this.stateRules, o => o.id !== id);
    this.stateRules = newRules;
    this.activeStateRule = new StateRule();
    this.activeStateRuleEdit = new StateRule();
  }

  @action
  removeStateCondition(id, fromOriginal) {
    const newConditions = _.filter(this.stateConditions, o => o.id !== id);
    if (fromOriginal) {
      this.stateConditionsOriginal = _.filter(this.stateConditionsOriginal, o => o.id !== id);
    }
    this.stateConditions = newConditions;
  }

  @action
  removeStateAction(id, fromOriginal) {
    const newActions = _.filter(this.stateActions, o => o.id !== id);
    if (fromOriginal) {
      this.stateActionsOriginal = _.filter(this.stateActionsOriginal, o => o.id !== id);
    }
    this.stateActions = newActions;
  }

  @action
  removeStateActionEmailRecipient(id, fromOriginal) {
    const newRecipients = _.filter(this.stateActionEmailRecipients, o => o.id !== id);
    if (fromOriginal) {
      this.stateActionEmailRecipientsOriginal = _.filter(this.stateActionEmailRecipientsOriginal, o => o.id !== id);
    }
    this.stateActionEmailRecipients = newRecipients;
  }

  @action
  removeStateActionEmailRecipientUser(id, fromOriginal) {
    const newUsers = _.filter(this.stateActionEmailRecipientUsers, o => o.id !== id);
    if (fromOriginal) {
      this.stateActionEmailRecipientUsersOriginal = _.filter(this.stateActionEmailRecipientUsersOriginal, o => o.id !== id);
    }
    this.stateActionEmailRecipientUsers = newUsers;
  }

  @action
  removeStateActionEmailRecipientParticipant(id, fromOriginal) {
    const newParticipants = _.filter(this.stateActionEmailRecipientParticipants, o => o.id !== id);
    if (fromOriginal) {
      this.stateActionEmailRecipientParticipantsOriginal = _.filter(this.stateActionEmailRecipientParticipantsOriginal, o => o.id !== id);
    }
    this.stateActionEmailRecipientParticipants = newParticipants;
  }

  @action
  cascadeActionIdsToRecipients(oldActionId, newActionId) {
    // This is to cascade the newly creacted ActionID to the ready-to-create email recipients
    _.map(this.stateActionEmailRecipients, recipient => {
      if (recipient.stateActionId === oldActionId) {
        recipient.stateActionId = newActionId;
      }
    });
  }

  @action
  resetStateConditions() {
    this.stateConditions = _.cloneDeep(this.stateConditionsOriginal.slice());
    this.sortStateConditions();
  }

  @action
  resetStateActions() {
    this.stateActions = _.cloneDeep(this.stateActionsOriginal.slice());
    this.sortStateActions();
  }

  @action
  resetStateActionEmailRecipients() {
    this.stateActionEmailRecipients = _.cloneDeep(this.stateActionEmailRecipientsOriginal.slice());
  }

  @action
  resetStateActionEmailRecipientUsers() {
    this.stateActionEmailRecipientUsers = _.cloneDeep(this.stateActionEmailRecipientUsersOriginal.slice());
  }

  @action
  resetStateActionEmailRecipientParticipants() {
    this.stateActionEmailRecipientParticipants = _.cloneDeep(this.stateActionEmailRecipientParticipantsOriginal.slice());
  }

  @action
  updateNewStateRule(data, attribute) {
    this.newStateRule[attribute] = data;
  }

  @action
  updateActiveStateRule(data, attribute) {
    this.activeStateRuleEdit[attribute] = data;
  }

  @action
  setActiveStateRule(id) {
    if (id !== this.activeStateRule.id) {
      const findRule = _.find(this.stateRules, o => o.id === id);
      if (findRule) {
        this.activeStateRule = findRule;
        this.activeStateRuleEdit = _.cloneDeep(findRule);
      } else {
        this.activeStateRule = new StateRule();
        this.activeEditRule = new StateRule();
      }
    } else {
      this.activeStateRule = new StateRule();
      this.activeEditRule = new StateRule();
    }
  }

  @action
  sortStateRules() {
    let rewRules = this.stateRules;
    rewRules = _.orderBy(rewRules, ['rowOrder'], ['asc']);
    this.stateRules = rewRules;
  }

  @action
  sortStateConditions() {
    let rewConditions = this.stateConditions;
    rewConditions = _.orderBy(rewConditions, ['rowOrder'], ['asc']);
    this.stateConditions = rewConditions;
  }

  @action
  sortStateActions() {
    let rewActions = this.stateActions;
    rewActions = _.orderBy(rewActions, ['rowOrder'], ['asc']);
    this.stateActions = rewActions;
  }

  @action
  resetNewStateRule() {
    this.newStateRule = new StateRule();
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.stateRules = [];
    this.newStateRule = new StateRule();
    this.activeStateRule = new StateRule();
    this.activeEditRule = new StateRule();
    this.stateConditions = [];
    this.stateConditionsOriginal = [];
    this.stateActions = [];
    this.stateActionsOriginal = [];
    this.stateActionEmailRecipients = [];
    this.stateActionEmailRecipientsOriginal = [];
    this.stateActionEmailRecipientUsers = [];
    this.stateActionEmailRecipientUsersOriginal = [];
    this.stateActionEmailRecipientParticipants = [];
    this.stateActionEmailRecipientParticipantsOriginal = [];
  }
}

const store = new StateRuleStore();
export default store;
