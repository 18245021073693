/* Legacy code - ignore this errors */
/* eslint complexity: 0 class-methods-use-this: 0*/
import { action, extendObservable } from 'mobx';
import App from '../models/AppModel';
import Element from '../models/ElementModel';
import ElementTitle from '../models/elements/ElementTitleModel';
import ElementProfileValue from '../models/elements/ElementProfileValueModel';
import ElementCalculation from '../models/elements/ElementCalculationModel';
import ElementDescription from '../models/elements/ElementDescriptionModel';
import ElementRichTextareaDisplay from '../models/elements/ElementRichTextareaDisplayModel';
import ElementImage from '../models/elements/ElementImageModel';
import ElementHyperlink from '../models/elements/ElementHyperlinkModel';
import ElementFileDownload from '../models/elements/ElementFileDownloadModel';
import ElementTextInput from '../models/elements/ElementTextInputModel';
import ElementTextarea from '../models/elements/ElementTextareaModel';
import ElementRichTextarea from '../models/elements/ElementRichTextareaModel';
import ElementEmail from '../models/elements/ElementEmailModel';
import ElementSelect from '../models/elements/ElementSelectModel';
import ElementSelectOption from '../models/elements/ElementSelectOptionModel';
import ElementGrid from '../models/elements/ElementGridModel';
import ElementGridRow from '../models/elements/ElementGridRowModel';
import ElementGridColumn from '../models/elements/ElementGridColumnModel';
import ElementObjectSelect from '../models/elements/ElementObjectSelectModel';
import ElementObjectSelectItemApp from '../models/elements/ElementObjectSelectItemAppModel';
import ElementNumber from '../models/elements/ElementNumberModel';
import ElementSlider from '../models/elements/ElementSliderModel';
import ElementDate from '../models/elements/ElementDateModel';
import ElementMultiline from '../models/elements/ElementMultilineModel';
import ElementMultilineInput from '../models/elements/ElementMultilineInputModel';
import ElementFileInput from '../models/elements/ElementFileInputModel';
import ElementImageUpload from '../models/elements/ElementImageUploadModel';
import ElementHyperlinkInput from '../models/elements/ElementHyperlinkInputModel';
import ElementLocationSelect from '../models/elements/ElementLocationSelectModel';
import _ from 'lodash';

class ElementMappingActions {
  @action
  mapElements(elements) {
    const newElements = [];
    _.map(elements, element => {
      const newElement = new Element(
        element.id,
        element.section_id,
        element.element_type,
        element.is_editable,
        element.is_required,
        element.min_length,
        element.max_length,
        element.private_view,
        element.protected_view,
        element.account_owner_only_view,
        element.repeatable,
        element.display_on_dashboard,
        element.row_order,
        element.created_at,
        element.created_by,
        element.deleted_at,
        element.deleted_by,
        element.updated_at,
        element.updated_by
      );
      switch (element.element_type) {
        case 'title':
          extendObservable(newElement, {
            elementTitle: this.mapElementTitle(element.element_title),
          });
          break;
        case 'calculation':
          extendObservable(newElement, {
            elementCalculation: this.mapElementCalculation(element.element_calculation),
          });
          break;
        case 'description':
          extendObservable(newElement, {
            elementDescription: this.mapElementDescription(element.element_description),
          });
          break;
        case 'profile_value':
          extendObservable(newElement, {
            elementProfileValue: this.mapElementProfileValue(element.element_profile_value),
          });
          break;
        case 'rich_textarea_display':
          extendObservable(newElement, {
            elementRichTextareaDisplay: this.mapElementRichTextareaDisplay(element.element_rich_textarea_display),
          });
          break;
        case 'image':
          extendObservable(newElement, {
            elementImage: this.mapElementImage(element.element_image),
          });
          break;
        case 'hyperlink':
          extendObservable(newElement, {
            elementHyperlink: this.mapElementHyperlink(element.element_hyperlink),
          });
          break;
        case 'file_download':
          extendObservable(newElement, {
            elementFileDownload: this.mapElementFileDownload(element.element_file_download),
          });
          break;
        case 'text_input':
          extendObservable(newElement, {
            elementTextInput: this.mapElementTextInput(element.element_text_input),
          });
          break;
        case 'textarea':
          extendObservable(newElement, {
            elementTextarea: this.mapElementTextarea(element.element_textarea),
          });
          break;
        case 'rich_textarea':
          extendObservable(newElement, {
            elementRichTextarea: this.mapElementRichTextarea(element.element_rich_textarea),
          });
          break;
        case 'email':
          extendObservable(newElement, {
            elementEmail: this.mapElementEmail(element.element_email),
          });
          break;
        case 'select':
          extendObservable(newElement, {
            elementSelect: this.mapElementSelect(element.element_select),
          });
          break;
        case 'grid':
          extendObservable(newElement, {
            elementGrid: this.mapElementGrid(element.element_grid),
          });
          break;
        case 'user_select':
          extendObservable(newElement, {
            elementObjectSelect: this.mapElementObjectSelect(element.element_object_select),
          });
          break;
        case 'team_select':
          extendObservable(newElement, {
            elementObjectSelect: this.mapElementObjectSelect(element.element_object_select),
          });
          break;
        case 'item_select':
          extendObservable(newElement, {
            elementObjectSelect: this.mapElementObjectSelect(element.element_object_select),
          });
          break;
        case 'number':
          extendObservable(newElement, {
            elementNumber: this.mapElementNumber(element.element_number),
          });
          break;
        case 'currency':
          extendObservable(newElement, {
            elementNumber: this.mapElementNumber(element.element_number),
          });
          break;
        case 'percentage':
          extendObservable(newElement, {
            elementNumber: this.mapElementNumber(element.element_number),
          });
          break;
        case 'slider':
          extendObservable(newElement, {
            elementSlider: this.mapElementSlider(element.element_slider),
          });
          break;
        case 'range_slider':
          extendObservable(newElement, {
            elementSlider: this.mapElementSlider(element.element_slider),
          });
          break;
        case 'date':
          extendObservable(newElement, {
            elementDate: this.mapElementDate(element.element_date),
          });
          break;
        case 'date_range':
          extendObservable(newElement, {
            elementDate: this.mapElementDate(element.element_date),
          });
          break;
        case 'date_time':
          extendObservable(newElement, {
            elementDate: this.mapElementDate(element.element_date),
          });
          break;
        case 'time':
          extendObservable(newElement, {
            elementDate: this.mapElementDate(element.element_date),
          });
          break;
        case 'multiline':
          extendObservable(newElement, {
            elementMultiline: this.mapElementMultiline(element.element_multiline),
          });
          break;
        case 'file_upload':
          extendObservable(newElement, {
            elementFileInput: this.mapElementFileInput(element.element_file_upload),
          });
          break;
        case 'image_upload':
          extendObservable(newElement, {
            elementImageUpload: this.mapElementImageUpload(element.element_image_upload),
          });
          break;
        case 'hyperlink_input':
          extendObservable(newElement, {
            elementHyperlinkInput: this.mapElementHyperlinkInput(element.element_hyperlink_input),
          });
          break;
        case 'location_select':
          extendObservable(newElement, {
            elementLocationSelect: this.mapElementLocationSelect(element.element_location_select),
          });
          break;
        default:
          throw new Error(`Element type: ${element.element_type} not accounted for`);
      }
      extendObservable(newElement, {
        profileValues: [],
        formValues: [],
      });
      newElements.push(newElement);
    });
    return newElements;
  }

  @action
  mapElementTitle(elementTitle) {
    const newElementTitle = new ElementTitle(elementTitle.id, elementTitle.element_id, elementTitle.name);
    return newElementTitle;
  }

  @action
  mapElementCalculation(elementCalculation) {
    const newElementCalculation = new ElementCalculation(
      elementCalculation.id,
      elementCalculation.element_id,
      elementCalculation.name,
      elementCalculation.formula,
      elementCalculation.human_friendly_formula,
      elementCalculation.formula_object_string,
      elementCalculation.decimal_places,
      elementCalculation.prefix,
      elementCalculation.suffix
    );
    return newElementCalculation;
  }

  @action
  mapElementDescription(elementDescription) {
    const newElementDescription = new ElementDescription(elementDescription.id, elementDescription.element_id, elementDescription.name);
    return newElementDescription;
  }

  @action
  mapElementProfileValue(elementProfileValue) {
    const newElementProfileValue = new ElementProfileValue(
      elementProfileValue.id,
      elementProfileValue.element_id,
      elementProfileValue.name,
      elementProfileValue.profile_template_element_id,
      elementProfileValue.participant_id,
      elementProfileValue.keep_existing_items_updated
    );
    return newElementProfileValue;
  }

  @action
  mapElementRichTextareaDisplay(elementRichTextareaDisplay) {
    const newElementRichTextareaDisplay = new ElementRichTextareaDisplay(
      elementRichTextareaDisplay.id,
      elementRichTextareaDisplay.element_id,
      elementRichTextareaDisplay.content
    );
    return newElementRichTextareaDisplay;
  }

  @action
  mapElementImage(elementImage) {
    const newElementImage = new ElementImage(
      elementImage.id,
      elementImage.element_id,
      elementImage.image_file_name,
      elementImage.image_content_type,
      elementImage.image_updated_at,
      elementImage.image_file_size,
      elementImage.alignment
    );
    return newElementImage;
  }

  @action
  mapElementHyperlink(elementHyperlink) {
    const newElementHyperlink = new ElementHyperlink(
      elementHyperlink.id,
      elementHyperlink.element_id,
      elementHyperlink.hyperlink,
      elementHyperlink.title,
      elementHyperlink.display_as
    );
    return newElementHyperlink;
  }

  @action
  mapElementFileDownload(elementFileDownload) {
    const newElementFileDownload = new ElementFileDownload(
      elementFileDownload.id,
      elementFileDownload.element_id,
      elementFileDownload.attachment_file_name,
      elementFileDownload.attachment_content_type,
      elementFileDownload.attachment_updated_at,
      elementFileDownload.attachment_file_size,
      elementFileDownload.attachment_file_path
    );
    return newElementFileDownload;
  }

  @action
  mapElementTextInput(elementTextInput) {
    const newElementTextInput = new ElementTextInput(
      elementTextInput.id,
      elementTextInput.element_id,
      elementTextInput.name,
      elementTextInput.placeholder_text
    );
    return newElementTextInput;
  }

  @action
  mapElementTextarea(elementTextarea) {
    const newElementTextarea = new ElementTextarea(
      elementTextarea.id,
      elementTextarea.element_id,
      elementTextarea.name,
      elementTextarea.placeholder_text,
      elementTextarea.rows
    );
    return newElementTextarea;
  }

  @action
  mapElementRichTextarea(elementRichTextarea) {
    const newElementRichTextarea = new ElementRichTextarea(
      elementRichTextarea.id,
      elementRichTextarea.element_id,
      elementRichTextarea.name,
      elementRichTextarea.placeholder_text,
      elementRichTextarea.rows
    );
    return newElementRichTextarea;
  }

  @action
  mapElementEmail(elementEmail) {
    const newElementEmail = new ElementEmail(elementEmail.id, elementEmail.element_id, elementEmail.name, elementEmail.placeholder_text);
    return newElementEmail;
  }

  @action
  mapElementSelect(elementSelect) {
    const newElementSelect = new ElementSelect(
      elementSelect.id,
      elementSelect.element_id,
      elementSelect.name,
      elementSelect.placeholder_text,
      elementSelect.option_type,
      elementSelect.display_type
    );
    const newElementSelectOptions = [];
    _.map(elementSelect.element_select_options, option => {
      const newOption = this.mapElementSelectOption(option);
      newElementSelectOptions.push(newOption);
    });
    newElementSelect.elementSelectOptions = newElementSelectOptions;
    return newElementSelect;
  }

  @action
  mapElementSelectOption(elementSelectOption) {
    const newElementSelectOption = new ElementSelectOption(
      elementSelectOption.id,
      elementSelectOption.element_select_id,
      elementSelectOption.name,
      elementSelectOption.color,
      elementSelectOption.number_value,
      elementSelectOption.row_order
    );
    return newElementSelectOption;
  }

  @action
  mapElementGrid(elementGrid) {
    const newElementGrid = new ElementGrid(elementGrid.id, elementGrid.element_id, elementGrid.name, elementGrid.display_type);
    const newElementGridRows = [];
    const newElementGridColumns = [];
    _.map(elementGrid.element_grid_rows, row => {
      const newRow = this.mapElementGridRow(row);
      newElementGridRows.push(newRow);
    });
    _.map(elementGrid.element_grid_columns, column => {
      const newColumn = this.mapElementGridColumn(column);
      newElementGridColumns.push(newColumn);
    });
    newElementGrid.elementGridRows = newElementGridRows;
    newElementGrid.elementGridColumns = newElementGridColumns;
    return newElementGrid;
  }

  @action
  mapElementGridRow(elementGridRow) {
    const newElementGridRow = new ElementGridRow(
      elementGridRow.id,
      elementGridRow.element_grid_id,
      elementGridRow.name,
      elementGridRow.row_order
    );
    return newElementGridRow;
  }

  @action
  mapElementGridColumn(elementGridColumn) {
    const newElementGridColumn = new ElementGridColumn(
      elementGridColumn.id,
      elementGridColumn.element_grid_id,
      elementGridColumn.name,
      elementGridColumn.number_value,
      elementGridColumn.row_order
    );
    return newElementGridColumn;
  }

  @action
  mapElementObjectSelect(elementObjectSelect) {
    const newElementObjectSelect = new ElementObjectSelect(
      elementObjectSelect.id,
      elementObjectSelect.element_id,
      elementObjectSelect.name,
      elementObjectSelect.placeholder_text,
      elementObjectSelect.option_type,
      elementObjectSelect.object_type
    );
    if (elementObjectSelect.object_type === 'item') {
      const newElementObjectItemApps = [];
      _.map(elementObjectSelect.element_object_select_item_apps, itemApp => {
        const newItemApp = new ElementObjectSelectItemApp(itemApp.id, itemApp.element_object_select_id, itemApp.app_id);
        const newApp = new App(
          itemApp.app.id,
          itemApp.app.workspace_id,
          itemApp.app.workspace_folder_id,
          itemApp.app.name,
          itemApp.app.description,
          itemApp.app.item,
          itemApp.app.item_plural,
          itemApp.app.item_layout,
          itemApp.app.icon,
          itemApp.app.color,
          itemApp.app.image_file_name,
          itemApp.app.permission_type,
          itemApp.app.status,
          itemApp.app.row_order,
          itemApp.app.row_order_folder,
          itemApp.app.user_access_level,
          itemApp.app.webform_enabled,
          itemApp.app.webform_email_required,
          itemApp.app.webform_anonymous,
          itemApp.app.webform_path,
          itemApp.app.default_view_folder,
          itemApp.app.default_process_visible,
          itemApp.app.include_in_quick_create_menu,
          itemApp.app.dummy_data,
          itemApp.app.lock_participants,
          itemApp.app.comment_count,
          itemApp.app.is_store_template,
          itemApp.app.storeTemplate_id,
          itemApp.app.created_at,
          itemApp.app.created_by,
          itemApp.app.deleted_at,
          itemApp.app.deleted_by,
          itemApp.app.updated_at,
          itemApp.app.updated_by
        );
        newItemApp.app = newApp;
        newElementObjectItemApps.push(newItemApp);
      });
      newElementObjectSelect.elementObjectSelectItemApps = newElementObjectItemApps;
    }
    return newElementObjectSelect;
  }

  @action
  mapElementNumber(elementNumber) {
    const newElementNumber = new ElementNumber(
      elementNumber.id,
      elementNumber.element_id,
      elementNumber.name,
      elementNumber.placeholder_text,
      elementNumber.numeric_type,
      elementNumber.prefix,
      elementNumber.suffix,
      elementNumber.min_value,
      elementNumber.max_value
    );
    return newElementNumber;
  }

  @action
  mapElementSlider(elementSlider) {
    const newElementSlider = new ElementSlider(
      elementSlider.id,
      elementSlider.element_id,
      elementSlider.name,
      elementSlider.slider_type,
      elementSlider.label_type,
      elementSlider.label_currency_type,
      elementSlider.min_value,
      elementSlider.max_value,
      elementSlider.step_size,
      elementSlider.vertical
    );
    return newElementSlider;
  }

  @action
  mapElementDate(elementDate) {
    const newElementDate = new ElementDate(
      elementDate.id,
      elementDate.element_id,
      elementDate.name,
      elementDate.min_date,
      elementDate.max_date,
      elementDate.display_type,
      elementDate.input_type,
      elementDate.triggers_timeline_event,
      elementDate.timeline_event_description
    );
    return newElementDate;
  }

  @action
  mapElementMultiline(elementMultiline) {
    const newElementMultiline = new ElementMultiline(elementMultiline.id, elementMultiline.element_id, elementMultiline.name);
    const newElementMultilineInputs = [];
    _.map(elementMultiline.element_multiline_inputs, input => {
      const newInput = this.mapElementMultilineInput(input);
      newElementMultilineInputs.push(newInput);
    });
    newElementMultiline.elementMultilineInputs = newElementMultilineInputs;
    return newElementMultiline;
  }

  @action
  mapElementMultilineInput(elementMultilineInput) {
    const newElementMultilineInput = new ElementMultilineInput(
      elementMultilineInput.id,
      elementMultilineInput.element_multiline_id,
      elementMultilineInput.name,
      elementMultilineInput.placeholder_text,
      elementMultilineInput.is_required,
      elementMultilineInput.display_label,
      elementMultilineInput.display_label_type,
      elementMultilineInput.display_width,
      elementMultilineInput.row_order
    );
    return newElementMultilineInput;
  }

  @action
  mapElementFileInput(elementFileInput) {
    const newElementFileInput = new ElementFileInput(elementFileInput.id, elementFileInput.element_id, elementFileInput.name);
    return newElementFileInput;
  }

  @action
  mapElementImageUpload(elementImageUpload) {
    const newElementImageUpload = new ElementImageUpload(elementImageUpload.id, elementImageUpload.element_id, elementImageUpload.name);
    return newElementImageUpload;
  }

  @action
  mapElementHyperlinkInput(elementHyperlinkInput) {
    const newElementHyperlinkInput = new ElementHyperlinkInput(
      elementHyperlinkInput.id,
      elementHyperlinkInput.element_id,
      elementHyperlinkInput.name,
      elementHyperlinkInput.placeholder_text
    );
    return newElementHyperlinkInput;
  }

  @action
  mapElementLocationSelect(elementLocationSelect) {
    const newElementLocationSelect = new ElementLocationSelect(
      elementLocationSelect.id,
      elementLocationSelect.element_id,
      elementLocationSelect.name
    );
    return newElementLocationSelect;
  }
}

const actions = new ElementMappingActions();
export default actions;
