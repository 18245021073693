/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  Classes,
  Collapse,
  FormGroup,
  HTMLSelect,
  Intent,
  NumericInput,
  RangeSlider,
  Slider,
  Switch,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ElementReadOnly from '../../shared/ElementReadOnly';
import CurrencyDefinitions from '../../../static/CurrencyDefinitions';
import ElementTag from '../../shared/ElementTag';
import ElementIdSelector from '../../shared/ElementIdSelector';
import classNames from 'classnames';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementSlider extends React.Component {
  @observable
  displayedInput = 0;
  @observable
  displayedRangeInput = [0, 0];
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      is_required: this.store.activeElement.isRequired,
    };
    const elementSliderObj = {
      id: this.store.activeElement.elementSlider.id,
      name: this.store.activeElement.elementSlider.name,
      label_type: this.store.activeElement.elementSlider.labelType,
      label_currency_type: this.store.activeElement.elementSlider.labelCurrencyType,
      min_value: this.store.activeElement.elementSlider.minValue,
      max_value: this.store.activeElement.elementSlider.maxValue,
      step_size: this.store.activeElement.elementSlider.stepSize,
      vertical: this.store.activeElement.elementSlider.vertical,
    };
    this.props.TemplateActions.changeSubElement(elementSliderObj, 'element_sliders')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  componentDidMount() {
    if (this.props.element.elementSlider.minValue > 0) {
      this.displayedInput = this.props.element.elementSlider.minValue;
      this.displayedRangeInput = [this.props.element.elementSlider.minValue, this.props.element.elementSlider.minValue];
    }
  }

  handleDisplayedValueChange(n) {
    this.displayedInput = n;
  }

  handleDisplayedValueRangeChange(arr) {
    this.displayedRangeInput = arr;
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleCurrencyChange(e) {
    const findCurrency = _.find(CurrencyDefinitions.currencies, o => o.code === e.target.value);
    this.store.updateActiveElement(findCurrency.code, 'labelCurrencyType');
  }

  handleLabelChange(e) {
    this.store.updateActiveElement(e.target.value, 'labelType');
  }

  handleMinValueChange(n) {
    if (n > parseInt(this.store.activeElement.elementSlider.maxValue)) {
      // lazy fix for legacy code
      // eslint-disable-next-line
      n = parseInt(this.store.activeElement.elementSlider.maxValue);
    }
    if (this.displayedInput < n) {
      this.displayedInput = n;
    }
    if (this.displayedRangeInput[0] < n) {
      this.displayedRangeInput = [n, this.displayedRangeInput[1]];
    }
    this.autoAdjustStepSize(n, this.store.activeElement.elementSlider.maxValue);
    this.store.updateActiveElement(n, 'minValue');
  }

  handleMaxValueChange(n) {
    if (n < parseInt(this.store.activeElement.elementSlider.minValue)) {
      // lazy fix for legacy code
      // eslint-disable-next-line
      n = parseInt(this.store.activeElement.elementSlider.minValue);
    }
    if (this.displayedInput > n) {
      this.displayedInput = n;
    }
    if (this.displayedRangeInput[1] > n) {
      this.displayedRangeInput = [this.displayedRangeInput[0], n];
    }
    this.autoAdjustStepSize(this.store.activeElement.elementSlider.minValue, n);
    this.store.updateActiveElement(n, 'maxValue');
  }

  autoAdjustStepSize(min, max) {
    // lazy fix for legacy code
    // eslint-disable-next-line
    let step = Math.floor((max - min) / 10);
    if (step < 1) {
      step = 1;
    }
    this.store.updateActiveElement(step, 'stepSize');
  }

  handleStepSizeChange(n) {
    this.store.updateActiveElement(n, 'stepSize');
  }

  handleVerticalChange() {
    this.store.updateActiveElement(!this.store.activeElement.elementSlider.vertical, 'vertical');
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementSlider[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementSlider[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-text-highlight push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.slider_element_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementSlider.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementSlider.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        name="name"
                        value={this.store.activeElement.elementSlider.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginRight="20px">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="selectCurrency">
                    {I18n.t('js.select_label_type')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div className="bp3-select bp3-fill">
                      <HTMLSelect
                        name="labelType"
                        onChange={this.handleLabelChange.bind(this)}
                        defaultValue={this.store.activeElement.elementSlider.labelType}
                      >
                        <option value="none">{I18n.t('js.none')}</option>
                        <option value="currency">{I18n.t('js.currency')}</option>
                        <option value="percentage">{I18n.t('js.percentage')}</option>
                      </HTMLSelect>
                    </div>
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="selectCurrency">
                    {I18n.t('js.select_currency')}
                  </label>
                  <div className="bp3-form-content">
                    <div className="bp3-select bp3-fill">
                      <HTMLSelect
                        name="currency"
                        onChange={this.handleCurrencyChange.bind(this)}
                        defaultValue={this.store.activeElement.elementSlider.labelCurrencyType}
                        disabled={this.store.activeElement.elementSlider.labelType !== 'currency'}
                      >
                        {_.map(CurrencyDefinitions.currencies, currency => (
                          <option key={currency.name} value={currency.code}>
                            {currency.name} ({currency.symbol_native})
                          </option>
                        ))}
                      </HTMLSelect>
                    </div>
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="minValue">
                    {I18n.t('js.minimum_value')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <NumericInput
                      className="bp3-fill"
                      placeholder={I18n.t('js.enter_value')}
                      value={this.store.activeElement.elementSlider.minValue}
                      name="minValue"
                      onValueChange={(n, s) => {
                        this.handleMinValueChange(n, s);
                      }}
                    />
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="maxValue">
                    {I18n.t('js.maximum_value')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <NumericInput
                      className="bp3-fill"
                      placeholder={I18n.t('js.enter_value')}
                      value={this.store.activeElement.elementSlider.maxValue}
                      name="maxValue"
                      onValueChange={(n, s) => {
                        this.handleMaxValueChange(n, s);
                      }}
                    />
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.elementSlider.vertical}
                label={I18n.t('js.display_slider_as_vertical')}
                onChange={this.handleVerticalChange.bind(this)}
              />
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.response_required')}
                onChange={this.handleResponseRequiredChange.bind(this)}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  labelRenderer(n, element) {
    if (element.elementSlider.labelType === 'currency') {
      const findCurrency = _.find(CurrencyDefinitions.currencies, o => o.code === element.elementSlider.labelCurrencyType);
      const t = n.toString().replace(/\B(?=(\d{3})+(?!\d))/gu, ',');
      if (findCurrency) {
        return `${findCurrency.symbol_native}${t}`;
      }
      return `${t}`;
    }
    if (element.elementSlider.labelType === 'percentage') {
      return `${n}%`;
    }
    return n;
  }

  renderDisplayObject(element) {
    if (element.elementSlider.sliderType === 'range_slider') {
      return (
        <Flexbox flexGrow={1} paddingRight="10px" marginTop="10px">
          <RangeSlider
            disabled={!this.props.element.stateElement.editable}
            labelStepSize={parseInt(element.elementSlider.stepSize)}
            min={parseInt(element.elementSlider.minValue)}
            max={parseInt(element.elementSlider.maxValue)}
            onChange={arr => {
              this.handleDisplayedValueRangeChange(arr);
            }}
            value={this.displayedRangeInput}
            vertical={element.elementSlider.vertical}
            labelRenderer={n => this.labelRenderer(n, element)}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} paddingRight="10px" marginTop="10px">
        <Slider
          disabled={!this.props.element.stateElement.editable}
          labelStepSize={parseInt(element.elementSlider.stepSize)}
          min={parseInt(element.elementSlider.minValue)}
          max={parseInt(element.elementSlider.maxValue)}
          onChange={n => {
            this.handleDisplayedValueChange(n);
          }}
          value={this.displayedInput}
          vertical={element.elementSlider.vertical}
          labelRenderer={n => this.labelRenderer(n, element)}
        />
      </Flexbox>
    );
  }

  renderDisabledText(element) {
    if (!this.props.element.stateElement.editable) {
      return (
        <Flexbox marginTop="10px">
          <ElementReadOnly element={element} />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementSlider.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
        >
          {this.renderDisplayObject(this.store.activeElement)}
          {this.renderDisabledText(this.store.activeElement)}
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementSlider.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
      >
        {this.renderDisplayObject(this.props.element)}
        {this.renderDisabledText(this.props.element)}
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
