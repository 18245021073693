import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import ElementIdSelector from '../../shared/ElementIdSelector';
import { Classes, EditableText, Intent } from '@blueprintjs/core';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementTitle extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = {};
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  handleChange(value) {
    this.props.element.elementTitle.name = value;
  }

  handleConfirm() {
    const elementObj = {
      id: this.props.element.id,
    };
    const elementTitleObj = {
      id: this.props.element.elementTitle.id,
      name: this.props.element.elementTitle.name,
    };
    this.props.TemplateActions.changeSubElement(elementTitleObj, 'element_titles')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj);
        this.store.setActiveArea(null);
      })
      .catch(() => {
        // do nothing for now
      });
  }

  render() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
            <h2
              className={Classes.HEADING}
              style={{
                margin: '0px',
                paddingTop: '10px',
              }}
            >
              <EditableText
                multiline="multiline"
                confirmOnEnterKey={true}
                isEditing={true}
                defaultValue={this.props.element.elementTitle.name}
                intent={Intent.DEFAULT}
                onChange={value => this.handleChange(value)}
                value={this.props.element.elementTitle.name}
                onConfirm={this.handleConfirm.bind(this)}
                placeholder={I18n.t('js.click_to_edit')}
              />
            </h2>
          </Flexbox>
          <ElementIdSelector id={this.props.element.id} />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          <h2
            className={Classes.HEADING}
            style={{
              margin: '0px',
              paddingTop: '10px',
            }}
          >
            {this.props.element.elementTitle.name}
          </h2>
        </Flexbox>
      </Flexbox>
    );
  }
}
