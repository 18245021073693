import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Collapse, FormGroup, InputGroup, HTMLSelect, Intent, Switch } from '@blueprintjs/core';
import { DateInput, DatePicker, DateRangeInput, DateRangePicker, DateTimePicker, TimePicker } from '@blueprintjs/datetime';
import ElementReadOnly from '../../shared/ElementReadOnly';
import ElementTag from '../../shared/ElementTag';
import ElementIdSelector from '../../shared/ElementIdSelector';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementDate extends React.Component {
  @observable
  dateInput = null;
  @observable
  dateRangeInput = [null, null];
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      is_required: this.store.activeElement.isRequired,
    };
    const elementDateObj = {
      id: this.store.activeElement.elementDate.id,
      name: this.store.activeElement.elementDate.name,
      min_date: this.store.activeElement.elementDate.minDate,
      max_date: this.store.activeElement.elementDate.maxDate,
      input_type: this.store.activeElement.elementDate.inputType,
      triggers_timeline_event: this.store.activeElement.elementDate.triggersTimelineEvent,
      timeline_event_description: this.store.activeElement.elementDate.timelineEventDescription,
    };
    this.props.TemplateActions.changeSubElement(elementDateObj, 'element_dates')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleDisplayedDateChange(date) {
    this.dateInput = new Date(date);
  }

  handleDisplayedDateRangeChange(date) {
    this.dateRangeInput = date;
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleInputTypeChange(e) {
    this.store.updateActiveElement(e.target.value, 'inputType');
  }

  handleMinDateChange(date) {
    this.store.updateActiveElement(date, 'minDate');
  }

  handleMaxDateChange(date) {
    this.store.updateActiveElement(date, 'maxDate');
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleTimelineEventDescriptionChange(e) {
    this.store.updateActiveElement(e.target.value, 'timelineEventDescription');
  }

  handleTriggersTimelineEventChange() {
    this.store.updateActiveElement(!this.store.activeElement.elementDate.triggersTimelineEvent, 'triggersTimelineEvent');
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementDate[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementDate[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderTitles() {
    const { displayType } = this.store.activeElement.elementDate;
    switch (displayType) {
      case 'date':
        return (
          <Flexbox>
            <span className="bp3-icon bp3-icon-calendar push-10-r" />
            <h5 className={Classes.HEADING}>{I18n.t('js.date_element_options')}</h5>
          </Flexbox>
        );
      case 'date_range':
        return (
          <Flexbox>
            <span className="bp3-icon bp3-icon-timeline-events push-10-r" />
            <h5 className={Classes.HEADING}>{I18n.t('js.date_range_element_options')}</h5>
          </Flexbox>
        );
      case 'date_time':
        return (
          <Flexbox>
            <span className="bp3-icon bp3-icon-notifications push-10-r" />
            <h5 className={Classes.HEADING}>{I18n.t('js.date_time_element_options')}</h5>
          </Flexbox>
        );
      case 'time':
        return (
          <Flexbox>
            <span className="bp3-icon bp3-icon-time push-10-r" />
            <h5 className={Classes.HEADING}>{I18n.t('js.time_element_options')}</h5>
          </Flexbox>
        );
      default:
        throw new Error(`display type not accunted for: ${displayType}`);
    }
  }

  renderInputTypeSelect() {
    if (this.store.activeElement.elementDate.displayType === 'date' || this.store.activeElement.elementDate.displayType === 'date_range') {
      return (
        <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
          <div className="bp3-form-group">
            <label className="bp3-label" htmlFor="selectCurrency">
              {I18n.t('js.select_input_type')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <div className="bp3-form-content">
              <div className="bp3-fill">
                <HTMLSelect
                  name="labelType"
                  disabled={this.store.activeElement.elementDate.displayType === 'date_time'}
                  onChange={this.handleInputTypeChange.bind(this)}
                  defaultValue={this.store.activeElement.elementDate.inputType}
                >
                  <option value="input">{I18n.t('js.input')}</option>
                  <option value="calendar">{I18n.t('js.calendar')}</option>
                </HTMLSelect>
              </div>
            </div>
          </div>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderMinMaxSelection() {
    if (this.store.activeElement.elementDate.displayType !== 'time') {
      return (
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="minDate">
                {I18n.t('js.minimum_date')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <div className="bp3-form-content">
                <DateInput
                  value={new Date(this.store.activeElement.elementDate.minDate)}
                  onChange={this.handleMinDateChange.bind(this)}
                  formatDate={date => moment(date).format('DD/MM/YYYY')}
                  parseDate={str => new Date(str)}
                  minDate={new Date('Tue Jan 01 1901 00:00:00 GMT+0000 (GMT)')}
                  maxDate={new Date('Fri Jan 01 2117 00:00:00 GMT+0000 (GMT)')}
                  popoverProps={{
                    usePortal: false,
                    inline: false,
                  }}
                />
              </div>
            </div>
          </Flexbox>
          <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="maxDate">
                {I18n.t('js.maximum_date')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <div className="bp3-form-content">
                <DateInput
                  value={new Date(this.store.activeElement.elementDate.maxDate)}
                  onChange={this.handleMaxDateChange.bind(this)}
                  formatDate={date => moment(date).format('DD/MM/YYYY')}
                  parseDate={str => new Date(str)}
                  minDate={new Date('Tue Jan 01 1901 00:00:00 GMT+0000 (GMT)')}
                  maxDate={new Date('Fri Jan 01 2117 00:00:00 GMT+0000 (GMT)')}
                  popoverProps={{
                    usePortal: false,
                    inline: false,
                  }}
                />
              </div>
            </div>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderTimelineEventDescriptionInput() {
    if (this.store.activeElement.elementDate.triggersTimelineEvent) {
      return (
        <FormGroup
          label={I18n.t('js.timeline_event_description')}
          labelFor="timeline_event_description"
          labelInfo={`(${I18n.t('js.optional')})`}
          className="push-20-t"
        >
          <InputGroup
            value={this.store.activeElement.elementDate.timelineEventDescription}
            name="timeline_event_description"
            onChange={this.handleTimelineEventDescriptionChange.bind(this)}
            placeholder={I18n.t('js.timeline_event_description')}
          />
        </FormGroup>
      );
    }
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              {this.renderTitles()}
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementDate.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementDate.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        name="name"
                        value={this.store.activeElement.elementDate.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              {this.renderInputTypeSelect()}
            </Flexbox>
            {this.renderMinMaxSelection()}
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.response_required')}
                onChange={this.handleResponseRequiredChange.bind(this)}
              />
            </Flexbox>
            {this.props.sectionType !== 'app' ? (
              <Flexbox flexDirection="column" marginTop="10px">
                <Switch
                  className="bp3-control-no-margin"
                  checked={this.store.activeElement.elementDate.triggersTimelineEvent}
                  label={I18n.t('js.triggers_timeline_event')}
                  onChange={this.handleTriggersTimelineEventChange.bind(this)}
                />
                {this.renderTimelineEventDescriptionInput()}
              </Flexbox>
            ) : null}
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderDateDisplay(element) {
    if (element.elementDate.inputType === 'input' || !this.props.element.stateElement.editable) {
      return (
        <DateInput
          disabled={!this.props.element.stateElement.editable}
          placeholder={I18n.t('js.choose_a_date')}
          formatDate={date => moment(date).format('DD/MM/YYYY')}
          parseDate={str => new Date(str)}
          value={this.dateInput}
          onChange={this.handleDisplayedDateChange.bind(this)}
          minDate={new Date(element.elementDate.minDate)}
          maxDate={new Date(element.elementDate.maxDate)}
          popoverProps={{
            usePortal: false,
            inline: false,
          }}
        />
      );
    }
    return (
      <DatePicker
        className="bp3-elevation-1"
        value={this.dateInput}
        onChange={this.handleDisplayedDateChange.bind(this)}
        minDate={new Date(element.elementDate.minDate)}
        maxDate={new Date(element.elementDate.maxDate)}
        showActionsBar={false}
      />
    );
  }

  renderDateRangeDisplay(element) {
    if (element.elementDate.inputType === 'input' || !this.props.element.stateElement.editable) {
      return (
        <DateRangeInput
          disabled={!this.props.element.stateElement.editable}
          formatDate={date => moment(date).format('DD/MM/YYYY')}
          parseDate={str => new Date(str)}
          value={this.dateRangeInput.slice()}
          onChange={this.handleDisplayedDateRangeChange.bind(this)}
          minDate={new Date(element.elementDate.minDate)}
          maxDate={new Date(element.elementDate.maxDate)}
          popoverProps={{
            usePortal: false,
            portalContainer: document.body,
          }}
        />
      );
    }
    return (
      <DateRangePicker
        className="bp3-elevation-1"
        value={this.dateRangeInput.slice()}
        onChange={this.handleDisplayedDateRangeChange.bind(this)}
        minDate={new Date(element.elementDate.minDate)}
        maxDate={new Date(element.elementDate.maxDate)}
        showActionsBar={false}
      />
    );
  }

  renderDateTimeDisplay(element) {
    if (!this.props.element.stateElement.editable) {
      return (
        <DateInput
          disabled={!this.props.element.stateElement.editable}
          formatDate={date => moment(date).format('MMMM Do YYYY, h:mm a')}
          parseDate={str => new Date(str)}
          value={this.dateInput}
          onChange={this.handleDisplayedDateChange.bind(this)}
          minDate={new Date(element.elementDate.minDate)}
          maxDate={new Date(element.elementDate.maxDate)}
        />
      );
    }
    return (
      <DateTimePicker
        className="bp3-elevation-1"
        value={this.dateInput}
        onChange={this.handleDisplayedDateChange.bind(this)}
        minDate={new Date(element.elementDate.minDate)}
        maxDate={new Date(element.elementDate.maxDate)}
        timePickerProps={{
          showArrowButtons: false,
        }}
      />
    );
  }

  renderDisplayObject(element) {
    const {
      elementDate: { displayType },
    } = element;
    switch (displayType) {
      case 'date':
        return <Flexbox>{this.renderDateDisplay(element)}</Flexbox>;
      case 'date_range':
        return <Flexbox>{this.renderDateRangeDisplay(element)}</Flexbox>;
      case 'date_time':
        return <Flexbox>{this.renderDateTimeDisplay(element)}</Flexbox>;
      case 'time':
        return (
          <Flexbox>
            <TimePicker
              showArrowButtons={false}
              disabled={!this.props.element.stateElement.editable}
              value={this.dateInput}
              onChange={this.handleDisplayedDateChange.bind(this)}
            />
          </Flexbox>
        );
      default:
        throw new Error(`Display type not accounted for: ${displayType}`);
    }
  }

  renderDisabledText(element) {
    if (!this.props.element.stateElement.editable) {
      return <ElementReadOnly element={element} />;
    }
    return undefined;
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementDate.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
        >
          {this.renderDisplayObject(this.store.activeElement)}
          {this.renderDisabledText(this.store.activeElement)}
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementDate.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
      >
        {this.renderDisplayObject(this.props.element)}
        {this.renderDisabledText(this.props.element)}
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
