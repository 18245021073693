// @flow
import * as React from 'react';
import Flexbox from 'flexbox-react';
import { Cell, Column, ColumnHeaderCell, CopyCellsMenuItem, SelectionModes, RenderMode, Table, TruncatedFormat } from '@blueprintjs/table';
import { Menu, MenuDivider, Card, MenuItem, NonIdealState, Intent } from '@blueprintjs/core';
import type { TableData } from '../../types/DataViewTypes';

type DataViewTableProps = {
  tableData: TableData,
  isEditingReportTemplate: boolean,
  handleColumnReorder: Function,
  handleColumnHide: Function,
  handleColumnSort: Function,
};

class DataViewTable extends React.Component<DataViewTableProps> {
  constructor(props) {
    super(props);

    this.state = {
      width: 1200,
    };
  }

  componentDidMount() {
    if (document.querySelector('.table-container-box')) {
      let box = document.querySelector('.table-container-box');
      let width = box.offsetWidth - 10;
      this.setState({ width: width });
    }
  }
  getCellData(rowIndex: number, columnIndex: number) {
    const cellContents = this.props.tableData[rowIndex][columnIndex].column_value;
    return cellContents;
  }

  cellRenderer(rowIndex: number, columnIndex: number) {
    if (this.props.tableData[rowIndex]) {
      const cellContents = this.props.tableData[rowIndex][columnIndex];
      if (cellContents) {
        if (cellContents.column_id === 'item_attributes#id') {
          return (
            <Cell>
              <a href={`/items/${cellContents.column_value}`} rel="noopener noreferrer" target="_blank">
                {cellContents.column_value}
              </a>
            </Cell>
          );
        }
        if (cellContents.column_id === 'user_attributes#id') {
          return (
            <Cell>
              <a href={`/users/${cellContents.column_value}`} rel="noopener noreferrer" target="_blank">
                {cellContents.column_value}
              </a>
            </Cell>
          );
        }
        return (
          <Cell>
            <TruncatedFormat detectTruncation={true}>{cellContents.column_value === null ? '' : cellContents.column_value}</TruncatedFormat>
          </Cell>
        );
      }
    }
    return <Cell>''</Cell>;
  }

  columnHeaderCellRenderer = (columnIndex: number, columnName: string) => (
    <ColumnHeaderCell
      name={columnName}
      menuRenderer={this.props.isEditingReportTemplate ? () => this.renderColumnHeaderMenu(columnIndex) : null}
    />
  );

  renderColumnHeaderMenu(columnIndex: number) {
    const findColumnId = this.props.tableData[0][columnIndex].column_id;
    return (
      <Menu>
        <MenuItem
          // $FlowFixMe
          text={I18n.t('js.sort_ascending')}
          icon="sort-asc"
          onClick={() => this.props.handleColumnSort(findColumnId, 'asc')}
        />
        <MenuItem text={I18n.t('js.sort_descending')} icon="sort-desc" onClick={() => this.props.handleColumnSort(findColumnId, 'desc')} />
        <MenuDivider />
        <MenuItem text={I18n.t('js.hide_column')} icon="remove-column" onClick={() => this.props.handleColumnHide(columnIndex)} />
      </Menu>
    );
  }

  renderBodyContextMenu(context: string) {
    return (
      <Menu>
        <CopyCellsMenuItem
          context={context}
          getCellData={this.getCellData.bind(this)}
          // $FlowFixMe
          text={I18n.t('js.copy_data')}
          icon="clipboard"
        />
      </Menu>
    );
  }

  render() {
    const { tableData, isEditingReportTemplate } = this.props;

    if (tableData.length > 0) {
      return (
        <Flexbox maxWidth={`${this.state.width}px`} flexGrow={1}>
          <Table
            numRows={tableData.length}
            renderMode={RenderMode.BATCH}
            bodyContextMenuRenderer={this.renderBodyContextMenu.bind(this)}
            getCellClipboardData={this.getCellData.bind(this)}
            enableColumnReordering={isEditingReportTemplate}
            onColumnsReordered={this.props.handleColumnReorder}
            selectionModes={SelectionModes.COLUMNS_AND_CELLS}
          >
            {tableData[0].map(itemRowDatum => (
              <Column
                key={itemRowDatum.column_id}
                name={itemRowDatum.column_name}
                cellRenderer={this.cellRenderer.bind(this)}
                columnHeaderCellRenderer={columnIndex => {
                  const columnName = itemRowDatum.column_name;
                  return this.columnHeaderCellRenderer(columnIndex, columnName);
                }}
              />
            ))}
          </Table>
        </Flexbox>
      );
    }
    return (
      <Card className="push-20-t">
        <NonIdealState icon="search" title={I18n.t('js.nothing_to_show')} description={I18n.t('js.there_is_no_data_to_show')} />
      </Card>
    );
  }
}

export default DataViewTable;
