import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Alert, AnchorButton, Classes, Intent, Menu, MenuItem, Popover, PopoverInteractionKind, Tag, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import Utilities from '../../utils/Utilities';

@inject('WorkspaceStore', 'ToastStore')
@observer
export default class WorkspaceWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, isDuplicating: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleDuplicating() {
    this.setState({
      isDuplicating: !this.state.isDuplicating,
    });
  }

  handleDuplicateWorkspace() {
    this.toggleLoading();
    this.toggleDuplicating();
    this.props.WorkspaceStore.copyWorkspace(this.props.workspace.id)
      .then(() => {
        this.toggleLoading();
        this.props.ToastStore.showToast(I18n.t('js.workspace_has_been_queued_for_duplication'), 'success');
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderDraftTag() {
    if (this.props.workspace.status === 'draft') {
      return (
        <Tag className="push-10-l bp3-minimal" intent={Intent.WARNING}>
          {I18n.t('js.draft_workspace')}
        </Tag>
      );
    }
    return undefined;
  }

  renderTags() {
    return (
      <Flexbox justifyContent="flex-end" flexGrow={1}>
        <Tooltip portalContainer={document.body} content={I18n.t('js.your_permission_type_for_this_workspace')} position="bottom">
          <Tag className="bp3-minimal">{this.props.workspace.userPermissionLevel.text}</Tag>
        </Tooltip>
        {this.renderDraftTag()}
      </Flexbox>
    );
  }

  renderMenu() {
    return (
      <Menu>
        <MenuItem
          icon="edit"
          onClick={() => Utilities.navigate(`/workspaces/${this.props.workspace.id}?tab=edit`)}
          text={I18n.t('js.edit_workspace')}
        />
        <MenuItem icon="duplicate" onClick={() => this.toggleDuplicating()} text={I18n.t('js.duplicate_workspace')} />
      </Menu>
    );
  }

  renderEdit() {
    if (this.props.workspace.userPermissionLevel.name === 'owner') {
      return (
        <Flexbox className="widget-edit" width="50px" marginBottom="-30px">
          <Popover
            portalContainer={document.body}
            content={this.renderMenu()}
            interactionKind={PopoverInteractionKind.CLICK}
            inline={true}
            position="bottom"
            autoFocus={false}
          >
            <Tooltip portalContainer={document.body} content={I18n.t('js.options')} position="bottom">
              <AnchorButton icon="cog" loading={this.state.isLoading} className="bp3-minimal bp3-text-muted" />
            </Tooltip>
          </Popover>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderConfirmDuplicate() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDuplicating}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDuplicating()}
        confirmButtonText={I18n.t('js.duplicate_workspace')}
        onConfirm={this.handleDuplicateWorkspace.bind(this)}
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.duplicate_workspace_confirmation_text')}
      </Alert>
    );
  }

  render() {
    let bgStyle = {
      background: `url('${this.props.workspace.imageFileNameWidget}')`,
      backgroundSize: 'cover',
      borderBottom: `5px solid ${this.props.workspace.color}`,
    };
    if (this.props.workspace.status === 'draft') {
      bgStyle = {
        background: `linear-gradient( rgba(255, 2555, 255, 0.75), rgba(255, 2555, 255, 0.75) ), url('${this.props.workspace.imageFileNameWidget}')`,
        backgroundSize: 'cover',
        borderBottom: `5px solid ${this.props.workspace.color}`,
      };
    }
    if (this.props.workspace.imageFileName === '') {
      const darkenAmount = 0.25; // %
      bgStyle = {
        background: `linear-gradient(${this.props.workspace.color}, ${Utilities.darkenHex(this.props.workspace.color, darkenAmount)})`,
        borderBottom: `5px solid ${this.props.workspace.color}`,
      };
    }
    const iconClass = classNames('fa bp3-icon-workspace-widget text-white', this.props.workspace.icon);
    const iconStyle = {
      backgroundColor: this.props.workspace.color,
    };
    return (
      <Flexbox flexDirection="column" marginBottom="20px">
        <Flexbox
          className="bp3-card bp3-interactive bp3-elevation-1 bp3-card-no-padding bp3-cursor animated fadeIn"
          flexGrow={1}
          flexDirection="column"
          marginRight="20px"
          width="290px"
          onClick={() => Utilities.navigate(`/workspaces/${this.props.workspace.id}`)}
        >
          <Flexbox className="workspace-widget-img" style={bgStyle} padding="20px" flexGrow={0} flexDirection="row" alignItems="flex-end">
            <Flexbox flexGrow={1} justifyContent="flex-end">
              <Flexbox
                className="bp3-small-circle-icon"
                style={iconStyle}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <i className={iconClass} />
              </Flexbox>
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="column" padding="20px" flexGrow={1} justifyContent="space-between">
            <Flexbox flexDirection="column">
              <h5 className={Classes.HEADING}>{this.props.workspace.name}</h5>
              <p>{this.props.workspace.description}</p>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={0} justifyContent="flex-end" marginTop="10px">
              {this.renderTags()}
            </Flexbox>
          </Flexbox>
        </Flexbox>
        {this.renderEdit()}
        {this.renderConfirmDuplicate()}
      </Flexbox>
    );
  }
}
