import { computed, observable } from 'mobx';
import moment from 'moment';

export default class ReportTemplateScheduleModel {
  @observable id;
  @observable reportTemplateId;
  @observable frequency;
  @observable interval;
  @observable startDate;
  @observable endDate;
  @observable recipients;
  @observable message;

  constructor({
    id = '',
    report_template_id = '',
    frequency = '',
    interval = '',
    start_date = '',
    end_date = '',
    recipients = '',
    message = '',
    created_at = '',
    updated_at = '',
    deleted_at = '',
    created_by = '',
    updated_by = '',
    deleted_by = '',
  }) {
    let today = new Date().setMinutes(0);
    let nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    nextYear.setMinutes(0);

    this.id = id;
    this.reportTemplateId = report_template_id;
    this.frequency = frequency;
    this.interval = interval;
    start_date === '' ? (this.startDate = today) : (this.startDate = start_date);
    end_date === '' ? (this.endDate = nextYear) : (this.endDate = end_date);
    this.recipients = recipients;
    this.message = message;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.deletedAt = deleted_at;
    this.createdBy = created_by;
    this.updatedBy = updated_by;
    this.deletedBy = deleted_by;
  }

  @computed
  get isPersisted() {
    return this.createdAt.length > 0;
  }

  @computed
  get availableFrequencies() {
    return ['daily', 'weekly', 'monthly', 'yearly'];
  }

  @computed
  get frequencyPlural() {
    switch (this.frequency) {
      case 'daily':
        return 'day(s)';
      case 'weekly':
        return 'week(s)';
      case 'monthly':
        return 'month(s)';
      case 'yearly':
        return 'year(s)';
    }
  }

  @computed
  get minDate() {
    if (self.start_date) {
      return self.start_date;
    } else {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }
  }

  @computed
  get maxDate() {
    return new Date(new Date().setFullYear(new Date().getFullYear() + 10));
  }
}
