import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent, Menu, MenuDivider, MenuItem, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import StoreBrowser from '../shared/StoreBrowser';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('WorkspaceStore', 'CurrentUserStore')
@observer
export default class WorkspacesNewWorkspace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isStoreDialogOpen: false,
      isBlurred: [],
    };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleStoreDialog() {
    this.setState({
      isStoreDialogOpen: !this.state.isStoreDialogOpen,
    });
  }

  handleFilterChange(filter) {
    this.props.WorkspaceStore.activeFilter = filter;
  }

  handleSubmit(e) {
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    e.preventDefault();
    const workspaceObj = {};
    workspaceObj.account_id = this.props.CurrentUserStore.currentUser.accountId;
    workspaceObj.name = this.props.WorkspaceStore.newWorkspace.name;
    workspaceObj.description = this.props.WorkspaceStore.newWorkspace.description;
    workspaceObj.icon = 'far fa-chalkboard';
    workspaceObj.color = '#DB2C6F';
    workspaceObj.status = 'draft';

    this.props.WorkspaceStore.createWorkspace(workspaceObj)
      .then(response => {
        Utilities.navigate(`/workspaces/${response.data.id}?tab=edit`);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.WorkspaceStore.updateNewWorkspace(e.target.value, e.target.name);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.WorkspaceStore.newWorkspace[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.WorkspaceStore.newWorkspace[`${field}Error`]}</div>;
    }
    return undefined;
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="add"
        isOpen={this.state.isOpen}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={true}
        title={I18n.t('js.create_a_new_workspace')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.new_workspaces_description')}</p>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'name') && !this.props.WorkspaceStore.newWorkspace.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.workspace_name')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'name') && !this.props.WorkspaceStore.newWorkspace.nameValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.enter_a_name_for_your_workspace')}
                      name="name"
                      value={this.props.WorkspaceStore.newWorkspace.name}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('name')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'description') && !this.props.WorkspaceStore.newWorkspace.descriptionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="description">
                  {I18n.t('js.workspace_description')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'description') && !this.props.WorkspaceStore.newWorkspace.descriptionValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <textarea
                      className="bp3-input bp3-textarea bp3-fill"
                      type="textarea"
                      placeholder={I18n.t('js.enter_a_description_for_your_workspace')}
                      name="description"
                      value={this.props.WorkspaceStore.newWorkspace.description}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('description')}
                </div>
              </div>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.props.WorkspaceStore.newWorkspaceFormValid}
                text={I18n.t('js.create_workspace')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  renderFilterMenu() {
    return (
      <Menu>
        <MenuItem icon="share" text={I18n.t('js.published_workspaces')} onClick={() => this.handleFilterChange('published')} />
        <MenuItem icon="annotation" text={I18n.t('js.draft_workspaces')} onClick={() => this.handleFilterChange('draft')} />
        <MenuItem icon="disable" text={I18n.t('js.restricted_workspaces')} onClick={() => this.handleFilterChange('restricted')} />
        <MenuDivider />
        <MenuItem icon="grid-view" text={I18n.t('js.all_workspaces')} onClick={() => this.handleFilterChange('all')} />
      </Menu>
    );
  }

  renderNewWorkspaceMenu() {
    return (
      <Menu>
        <MenuItem icon="new-text-box" text={`${I18n.t('js.blank_template')}...`} onClick={() => this.toggleDialog()} />
        <MenuItem icon="cloud-download" text={`${I18n.t('js.store_template')}...`} onClick={() => this.toggleStoreDialog()} />
      </Menu>
    );
  }

  renderFilter() {
    let filterText = I18n.t('js.all_workspaces');
    if (this.props.WorkspaceStore.activeFilter === 'published') {
      filterText = I18n.t('js.published_workspaces');
    }
    if (this.props.WorkspaceStore.activeFilter === 'draft') {
      filterText = I18n.t('js.draft_workspaces');
    }
    if (this.props.WorkspaceStore.activeFilter === 'restricted') {
      filterText = I18n.t('js.restricted_workspaces');
    }

    return (
      <Popover
        portalContainer={document.body}
        content={this.renderFilterMenu()}
        position="right"
        interactionKind={PopoverInteractionKind.CLICK}
        autoFocus={false}
      >
        <Button icon="filter-list" outlined={false} text={filterText} />
      </Popover>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="row" marginRight="10px">
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox flexDirection="row" flexGrow={1}>
            <Popover content={this.renderNewWorkspaceMenu()} position={Position.BOTTOM} portalContainer={document.body}>
              <Button
                outlined={false}
                text={I18n.t('js.create_a_new_workspace')}
                icon="grid-view"
                rightIcon="caret-down"
                className="push-10-r"
                intent={Intent.PRIMARY}
              />
            </Popover>
            <StoreBrowser isOpen={this.state.isStoreDialogOpen} toggleDialog={this.toggleStoreDialog.bind(this)} type="Workspace" />
            {this.renderFilter()}
          </Flexbox>
          {this.renderDialog()}
        </Flexbox>
      </Flexbox>
    );
  }
}
