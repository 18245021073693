/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import Team from '../models/TeamModel';
import axios from 'axios';
import _ from 'lodash';
import UserTeamRequest from '../models/UserTeamRequestModel';
import User from '../models/UserModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Paginator from '../models/PaginatorModel';

class TeamUsersRequestsStore {
  @observable
  activeTeam = new Team();
  @observable
  pagination = new Paginator();
  @observable
  userTeamRequests = [];
  @observable
  userTeamRequestColumns = _.clone(ColumnDefinitions.authorizedAccountRequestColumns);

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  addTeam(data) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.activeTeam = _.clone(newTeam);
  }

  @action
  addUserTeamRequest(data) {
    this.removeUserTeamRequest(data);
    const newUserTeamRequest = new UserTeamRequest(
      data.id,
      data.team_id,
      data.approved,
      data.approved_by,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by,
      data.user_id
    );

    const newUser = new User(
      data.user.id,
      data.user.email,
      data.user.first_name,
      data.user.last_name,
      data.user.full_name,
      data.user.account_id,
      data.user.human_friendly_id,
      data.user.is_current_user,
      data.user.is_account_owner,
      data.user.image_file_name,
      data.user.background_image_file_name,
      data.user.last_sign_in_at,
      data.user.locale,
      data.user.theme,
      data.user.created_at,
      data.user.updated_at,
      data.user.deleted_at,
      data.user.created_by,
      data.user.updated_by,
      data.user.deleted_by
    );

    newUserTeamRequest.user = newUser;

    this.userTeamRequests = _.unionBy(this.userTeamRequests, [newUserTeamRequest], 'id');
    this.sortUserTeamRequests();
  }

  @action
  removeUserTeamRequest(data) {
    this.userTeamRequests = _.filter(this.userTeamRequests, o => o.id !== data.id);
  }

  @action
  fetchTeam(teamId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/teams/${teamId}`)
        .then(response => {
          this.addTeam(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchUserTeamRequests(teamId, xPage, xPerPage, xOffset) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_team_requests?team_id=${teamId}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`)
        .then(response => {
          this.createPaginator(response.headers);
          _.map(response.data, userTeamRequest => {
            this.addUserTeamRequest(userTeamRequest);
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createUserTeamRequest(teamId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/user_team_requests`, {
          team_id: teamId,
        })
        .then(response => {
          ToastStore.showToast(I18n.t('js.request_has_been_sent'), 'success');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  approveRequest(request) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/user_team_requests/${request.id}`, { approved: true })
        .then(response => {
          resolve(response);
          this.addUserTeamRequest(response.data);
          ToastStore.showToast(I18n.t('js.request_approved'), 'success');
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  rejectRequest(request) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/user_team_requests/${request.id}`)
        .then(response => {
          resolve(response);
          this.pagination.xTotal--;
          this.removeUserTeamRequest(response.data);
          ToastStore.showToast(I18n.t('js.request_removed'), 'success');
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  setUserTeamRequestSortParams(param) {
    const newUserTeamRequestColumns = this.userTeamRequestColumns;
    _.map(newUserTeamRequestColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.userTeamRequestColumns = newUserTeamRequestColumns;
    this.sortUserTeamRequests();
  }

  @action
  sortUserTeamRequests() {
    let newUserTeamRequests = this.userTeamRequests;
    const activeColumn = _.find(this.userTeamRequestColumns, o => o.active);
    newUserTeamRequests = _.orderBy(newUserTeamRequests, [activeColumn.id], [activeColumn.sortDir]);
    this.userTeamRequests = newUserTeamRequests;
  }

  @action
  resetStartingAttributes() {
    this.activeTeam = new Team();
    this.pagination = new Paginator();
    this.userTeamRequests = [];
    this.userTeamRequestColumns = _.clone(ColumnDefinitions.authorizedAccountRequestColumns);
  }
}

const store = new TeamUsersRequestsStore();
export default store;
