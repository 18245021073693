import React, { Component } from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import CalendarStore from '../stores/CalendarStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import ItemCalendar from '../components/calendar/ItemCalendar';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class CalendarView extends Component {
  render() {
    const stores = {
      CurrentUserStore,
      ToastStore,
      CalendarStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <LayoutHeader title={I18n.t('js.calendar')} subtitle={I18n.t('js.calendar_text')} icon="calendar" />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="50px" height="800px">
                <ItemCalendar />
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
