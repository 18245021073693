/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';

export default class Plan {
  @observable
  id;
  @observable
  active;
  @observable
  aggregateUsage;
  @observable
  billingScheme;
  @observable
  nickname;
  @observable
  product;
  @observable
  trialPeriodDays;
  @observable
  amount;
  @observable
  currency;
  @observable
  metadata;
  @observable
  interval;
  @observable
  intervalCount;

  constructor(
    id,
    active,
    aggregateUsage,
    billingScheme,
    nickname,
    product,
    trialPeriodDays,
    amount,
    currency,
    metadata,
    interval,
    intervalCount
  ) {
    this.id = id || '';
    this.active = active || '';
    this.aggregateUsage = aggregateUsage || '';
    this.billingScheme = billingScheme || '';
    this.nickname = nickname || '';
    this.product = product || '';
    this.trialPeriodDays = trialPeriodDays || '';
    this.amount = amount || '';
    this.currency = currency || '';
    this.metadata = metadata || '';
    this.interval = interval || '';
    this.intervalCount = intervalCount || '';
  }

  @computed
  get formattedAmount() {
    const penceInPound = 100;
    return this.amount / penceInPound;
  }

  @computed
  get features() {
    return JSON.parse(this.metadata);
  }
}
