import React from 'react';
import Flexbox from 'flexbox-react';
import { NonIdealState } from '@blueprintjs/core';
import IconDefinitions from '../../static/IconDefinitions';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

export default class IconSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      icons: _.sortBy(IconDefinitions.faIcons),
      query: '',
      activeIcon: '',
    };
  }

  handleFilterChange(e) {
    const query = e.target.value.toLowerCase();
    let newIcons = [];
    if (query === '') {
      newIcons = _.sortBy(IconDefinitions.faIcons);
    } else {
      newIcons = _.filter(_.sortBy(IconDefinitions.faIcons), icon =>
        _.includes(icon, query)
      );
    }
    this.setState({ query: e.target.value, icons: newIcons });
  }

  resetFilter(e) {
    e.preventDefault();
    this.setState({
      query: '',
      icons: _.sortBy(IconDefinitions.faIcons),
    });
  }

  setActiveIcon(icon) {
    this.setState({ activeIcon: icon });
    this.props.handleChangeIcon(icon);
  }

  renderIcon(icon) {
    const iconClass = classNames(
      'bp3-text-muted fa fa-fw fa-2x',
      icon
    );
    let containerClass = classNames('bp3-icon-container');
    if (
      icon === this.state.activeIcon ||
      icon === this.props.activeIcon
    ) {
      containerClass = classNames(
        'bp3-icon-container bp3-icon-container-active'
      );
    }
    return (
      <Flexbox
        padding="20px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={containerClass}
        onClick={() => this.setActiveIcon(icon)}
      >
        <Flexbox>
          <i className={iconClass} />
        </Flexbox>
        <Flexbox
          marginTop="10px"
          justifyContent="center"
          alignItems="center"
        >
          <small className="bp3-text-muted">
            {_.truncate(_.replace(icon, 'far fa-', ''), {
              length: 20,
            })}
          </small>
        </Flexbox>
      </Flexbox>
    );
  }

  renderFilterIcons() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <div className="bp3-input-group">
          <span className="bp3-icon bp3-icon-search" />
          <input
            autoComplete="off"
            type="text"
            className="bp3-input"
            value={this.state.query}
            onChange={this.handleFilterChange.bind(this)}
            placeholder={I18n.t('js.filter_icons')}
          />
          <button
            className="bp3-button bp3-minimal bp3-intent-primary bp3-icon-cross"
            onClick={this.resetFilter.bind(this)}
          />
        </div>
      </Flexbox>
    );
  }

  renderIconList() {
    if (this.state.icons.length === 0) {
      return (
        <Flexbox
          className="bp3-card"
          flexDirection="column"
          marginTop="10px"
          height="400px"
          flexGrow={1}
        >
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t(
              'js.there_is_nothing_to_show_right_now'
            )}
            icon="disable"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox
        className="bp3-card"
        flexDirection="column"
        marginTop="10px"
        flexGrow={1}
      >
        <Scrollbars
          autoHide={true}
          style={{
            height: 400,
          }}
        >
          <Flexbox
            flexDirection="row"
            flexWrap="wrap"
            marginTop="10px"
          >
            {_.map(this.state.icons, icon => (
              <Flexbox key={icon}>{this.renderIcon(icon)}</Flexbox>
            ))}
          </Flexbox>
        </Scrollbars>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" width="930px">
        {this.renderFilterIcons()}
        {this.renderIconList()}
      </Flexbox>
    );
  }
}
