import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import AppItemsNewItemNameInput from './AppItemsNewItemNameInput';
import UserSuggest from '../suggests/UserSuggest';
import UserInfoDrawer from '../form_elements/UserInfoDrawer';
import _ from 'lodash';

@inject(
  'AppStore',
  'AppItemStore',
  'DynamicViewStore',
  'ItemActions',
  'ItemStore',
  'CurrentUserStore',
  'ToastStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions'
)
@observer
export default class AppItemsSelectUsers extends React.Component {
  @observable isUserDrawerOpen = false;
  @observable drawerData = [];

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      itemName: I18n.t('js.new_item_with_name', {
        item: this.props.AppStore.activeApp.item,
        name: I18n.t('js.users_name'),
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      itemName: I18n.t('js.new_item_with_name', {
        item: this.props.AppStore.activeApp.item,
        name: I18n.t('js.users_name'),
      }),
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleNameInputChange(name) {
    this.setState({ itemName: name });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const defaultName = I18n.t('js.new_item_with_name', {
      item: this.props.AppStore.activeApp.item,
      name: I18n.t('js.users_name'),
    });
    let totalLength = this.props.AppItemStore.newItemUsers.length;

    this.determineCloseDialog(totalLength);

    _.map(this.props.AppItemStore.newItemUsers, itemUser => {
      const itemObj = {};
      itemObj.app_id = this.props.AppStore.activeApp.id;
      itemObj.user_id = itemUser.id;
      if (this.state.itemName !== defaultName) {
        itemObj.name = this.state.itemName;
      } else {
        itemObj.name = I18n.t('js.new_item_with_name', {
          item: this.props.AppStore.activeApp.item,
          name: itemUser.full_name,
        });
      }
      switch (this.props.postType) {
        case 'create':
          this.props.ItemActions.createItem(itemObj)
            .then(response => {
              this.props.AppItemStore.removeNewItemUser(itemUser.id);
              this.props.AppItemStore.addItem(response.data);
              this.props.DynamicViewStore.fetchItemsForUser(itemUser.id);
              totalLength--;
              this.determineCloseDialog(totalLength);
            })
            .catch(() => {
              this.props.AppItemStore.removeNewItemUser(itemUser.id);
              totalLength--;
              this.determineCloseDialog(totalLength);
            });
          break;
        case 'copy':
          this.props.ItemActions.duplicateItemForUser(this.props.ItemStore.item.id, itemUser.id)
            .then(response => {
              this.props.AppItemStore.removeNewItemUser(itemUser.id);
              this.props.AppItemStore.addItem(response.data);
              this.props.DynamicViewStore.fetchItemsForUser(itemUser.id);
              totalLength--;
              this.determineCloseDialog(totalLength);
            })
            .catch(() => {
              this.props.AppItemStore.removeNewItemUser(itemUser.id);
              totalLength--;
              this.determineCloseDialog(totalLength);
            });
          break;
        case 'pin':
          this.props.ItemActions.pinItemForUser(this.props.ItemStore.item.id, itemUser.id)
            .then(response => {
              this.props.AppItemStore.removeNewItemUser(itemUser.id);
              this.props.ItemStore.addItemPin(response.data);
              totalLength--;
              this.determineCloseDialog(totalLength);
            })
            .catch(() => {
              this.props.AppItemStore.removeNewItemUser(itemUser.id);
              totalLength--;
              this.determineCloseDialog(totalLength);
            });
          break;
      }
    });
  }

  determineCloseDialog(length) {
    if (length === 0) {
      this.toggleLoading();
      this.props.toggleDialog();
      this.props.AppItemStore.newItemUsers = [];
      this.setState({
        itemName: I18n.t('js.new_item_with_name', {
          item: this.props.AppStore.activeApp.item,
          name: I18n.t('js.users_name'),
        }),
      });
      switch (this.props.postType) {
        case 'create':
        case 'copy':
          this.props.ToastStore.showToast(
            I18n.t('js.items_have_been_created', {
              itemPlural: this.props.AppStore.activeApp.itemPlural,
            }),
            'success'
          );
          break;
        case 'pin':
          this.props.ToastStore.showToast(
            I18n.t('js.items_have_been_pinned', {
              itemPlural: this.props.AppStore.activeApp.itemPlural,
            }),
            'success'
          );
          break;
      }
    }
  }

  handleColumnSort(column) {
    this.props.AppItemStore.setNewItemUserSortParams(column.id, column.sortDir);
  }

  handleCancel() {
    this.props.AppItemStore.newItemUsers = [];
    this.setState({
      itemName: I18n.t('js.new_item_with_name', {
        item: this.props.AppStore.activeApp.item,
        name: I18n.t('js.users_name'),
      }),
    });
    this.props.toggleDialog();
  }

  @action
  toggleBoolean = value => {
    this[`${value}`] = !this[`${value}`];
  };

  @action
  openUserDrawer(user) {
    this.drawerData = user;
    const UserProfileTemplateStore = this.props.UserProfileTemplateStore;
    const UserProfileStore = this.props.UserProfileStore;

    UserProfileStore.setUser(user);

    UserProfileTemplateStore.resetStartingAttributes();
    UserProfileStore.fetchAuthorizedAccounts(user.id)
      .then(() => {
        this.props.TemplateActions.fetchProfileTemplates()
          .then(response => {
            _.map(response.data, profileTemplate => {
              UserProfileTemplateStore.addProfileTemplate(profileTemplate);
            });
            UserProfileTemplateStore.matchActiveProfileTemplate(UserProfileStore.authorizedAccount);
            UserProfileTemplateStore.toggleLoading();
          })
          .catch(() => {
            UserProfileTemplateStore.toggleLoading();
          });
      })
      .catch(() => {
        UserProfileTemplateStore.toggleLoading();
      });

    this.toggleBoolean('isUserDrawerOpen');
  }

  renderDialogFooter() {
    return (
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
          <Button
            intent={Intent.PRIMARY}
            onClick={this.handleSubmit.bind(this)}
            disabled={this.props.AppItemStore.newItemUsers.length === 0}
            text={
              this.props.postType === 'create'
                ? I18n.t('js.create_items', {
                    items: this.props.AppStore.activeApp.itemPlural,
                  })
                : this.props.postType == 'copy'
                ? I18n.t('js.duplicate_items', {
                    items: this.props.AppStore.activeApp.itemPlural,
                  })
                : I18n.t('js.pin_item', {
                    item: this.props.AppStore.activeApp.item,
                  })
            }
            loading={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }

  handleUserClick(user) {
    this.props.AppItemStore.addNewItemUser(user);
  }

  handleUserRemove(userId) {
    this.props.AppItemStore.removeNewItemUser(userId);
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="people"
        isOpen={this.props.dialogOpen}
        onClose={this.props.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.select_users')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            {this.props.postType === 'create' ? (
              <Flexbox flexGrow={1} flexDirection="column">
                <AppItemsNewItemNameInput handleNameInputChange={this.handleNameInputChange.bind(this)} name={this.state.itemName} />
              </Flexbox>
            ) : null}
            <Flexbox flexGrow={1} flexDirection="column">
              <label className="bp3-label" htmlFor="name">
                {this.props.AppStore.firstParticipant.length > 0
                  ? I18n.t('js.select_participant', { participant: this.props.AppStore.firstParticipant })
                  : I18n.t('js.select_users')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <Flexbox marginTop="-10px">
                <UserSuggest
                  placeholder={I18n.t('js.select_users')}
                  element={null}
                  handleUserClick={this.handleUserClick.bind(this)}
                  handleUserRemove={this.handleUserRemove.bind(this)}
                  validateInput={() => null}
                  intent={null}
                  openDrawer={this.openUserDrawer.bind(this)}
                  expandMenu={false}
                />
              </Flexbox>
            </Flexbox>
          </div>
          {this.renderDialogFooter()}
        </form>
        <UserInfoDrawer
          isOpen={this.isUserDrawerOpen}
          toggleBool={this.toggleBoolean.bind(this)}
          drawerData={this.drawerData}
          isLoading={this.props.UserProfileTemplateStore.isLoading}
        />
      </Dialog>
    );
  }

  render() {
    return <Flexbox>{this.renderDialog()}</Flexbox>;
  }
}
