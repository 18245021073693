/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import StoreCategoryStore from '../stores/StoreCategoryStore';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import CommentStore from '../stores/CommentStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import { AnchorButton, Spinner } from '@blueprintjs/core';
import CommentsView from './CommentsView';
import StoreCategory from '../models/StoreCategoryModel';
import StoreCategoryLeftNav from '../components/store_categories/StoreCategoryLeftNav';
import StoreCategoryTabs from '../components/store_categories/StoreCategoryTabs';
import Cookies from 'js-cookie';
import SignUpBar from '../components/layout/SignUpBar';
import StoreTemplate from '../components/store_templates/StoreTemplate';
import ContentfulClient from '../static/ContentfulClient';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class StoreCategoryStoreTemplateView extends React.Component {
  @observable
  storeCategory = new StoreCategory();
  @observable
  isLoading = true;

  componentDidMount() {
    if (StoreCategoryStore.hasNoData) {
      StoreCategoryStore.resetStartingAttributes();
      ContentfulClient.getEntries({
        content_type: 'storeCategory',
        order: '-sys.createdAt',
      }).then(response => {
        let itemCount = response.items.length;
        _.map(response.items, storeCategory => {
          StoreCategoryStore.addStoreCategory(storeCategory);
          itemCount--;
        });
        if (itemCount === 0) {
          this.setSlug();
          StoreCategoryStore.toggleLoading();
          this.isLoading = false;
        }
      });
    } else {
      this.setSlug();
      this.isLoading = false;
    }
  }

  setSlug() {
    const findStoreCategory = _.find(StoreCategoryStore.storeCategories, storeCategory => storeCategory.fields.slug === this.props.id);
    if (findStoreCategory) {
      StoreCategoryStore.setActiveStoreCategory(findStoreCategory);
      this.storeCategory = findStoreCategory;
      const findStoreTemplate = _.find(findStoreCategory.fields.storeTemplates, storeTemplate => storeTemplate.slug === this.props.slug);
      if (findStoreTemplate) {
        StoreCategoryStore.setActiveStoreTemplate(findStoreTemplate);
      }
    }
  }

  renderComments() {
    if (CurrentUserStore.userSignedIn) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" marginTop="20px">
          <CommentsView
            commentable={this.storeCategory}
            canPin={Cookies.get('super_admin') === 'true'}
            commentableType="StoreCategory"
            stub={true}
            sortByNew={true}
            showNone={true}
            buttonText={I18n.t('js.comment_on_store_category', {
              title: StoreCategoryStore.activeStoreCategory.fields.title.toLowerCase(),
            })}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} marginTop="20px" justifyContent="center" flexDirection="row">
        <AnchorButton text={I18n.t('js.sign_in_to_view_comments')} href="/sign-in" target="_blank" icon="log-in" />
      </Flexbox>
    );
  }

  renderContent() {
    if (CurrentUserStore.isLoading || StoreCategoryStore.isLoading || this.isLoading) {
      return (
        <Flexbox flexGrow={1} justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <LayoutHeader
          title={StoreCategoryStore.activeStoreTemplate.title}
          subtitle={StoreCategoryStore.activeStoreTemplate.subtitle}
          icon={StoreCategoryStore.activeStoreTemplate.icon}
          tabs={<StoreCategoryTabs />}
        />
        <Flexbox flexDirection="column">{this.renderSignUp()}</Flexbox>
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox className="fixed-width-container position-relative" flexDirection="row" flexGrow={1} marginTop="20px">
            <Flexbox flexGrow={0} flexDirection="column" marginRight="20px" className="left-navigation">
              <StoreCategoryLeftNav />
            </Flexbox>
            <Flexbox flexGrow={1} flexDirection="column">
              <StoreTemplate storeTemplate={StoreCategoryStore.activeStoreTemplate} storeCategoryView={true} />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }

  renderSignUp() {
    if (!CurrentUserStore.userSignedIn && !CurrentUserStore.isLoading) {
      return <SignUpBar />;
    }
    return undefined;
  }

  render() {
    const stores = {
      CurrentUserStore,
      CommentStore,
      StoreCategoryStore,
      StoreTemplateStore,
      StoreTemplateActions,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column">
              {this.renderContent()}
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
