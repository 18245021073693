import React from 'react';
import { observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { Button } from '@blueprintjs/core';

const IntegrationActionButtons = props => {
  const {
    canSave,
    handleSaveChanges,
    hasNewChanges,
    cancelChanges,
    toggleDeleteDialog,
  } = props;
  return (
    <Flexbox flexDirection="row">
      <Button
        disabled={!canSave}
        className="bp3-icon-floppy-disk push-10-t push-10-r"
        text={I18n.t('js.save_changes')}
        onClick={handleSaveChanges}
      />
      <Button
        disabled={!hasNewChanges}
        className="bp3-icon-cross push-10-t push-10-r"
        text={I18n.t('js.cancel_changes')}
        onClick={cancelChanges}
      />
      <Button
        className="bp3-icon-trash push-10-t"
        text={I18n.t('js.delete_integration')}
        onClick={toggleDeleteDialog}
      />
    </Flexbox>
  );
};
export default observer(IntegrationActionButtons);
