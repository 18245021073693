import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Classes, NonIdealState, Spinner, Tabs, Tab, Alert, Intent } from '@blueprintjs/core';
import UserProfileRelationshipsNewUserRelationship from './UserProfileRelationshipsNewUserRelationship';
import UserProfileRelationshipsTabData from './UserProfileRelationshipsTabData';
import Flexbox from 'flexbox-react';
import _ from 'lodash';

@inject('UserProfileStore', 'UserProfileRelationshipsStore')
@observer
export default class UserProfileRelationships extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '',
      isTabLoading: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const store = this.props.UserProfileRelationshipsStore;
    if (store.userId != nextProps.UserProfileStore.user.id) {
      store.setUserId(nextProps.UserProfileStore.user.id);
      this.fetchData();
    }
  }

  fetchData() {
    const store = this.props.UserProfileRelationshipsStore;
    store.toggleLoading();
    const userId = this.props.UserProfileStore.user.id;
    store.setUserId(userId);

    store.resetStartingAttributes();
    store
      .fetchRelationships()
      .then(res => {
        if (_.find(res.data, o => o.default)) {
          const defaultRelationship = _.find(res.data, o => o.default);
          store.updateCurrentRelationship(userId, defaultRelationship);
        } else {
          store.updateCurrentRelationship(userId, res.data[0]);
        }
        store.toggleLoading();
      })
      .catch(() => {
        store.toggleLoading();
      });
  }

  renderDialog() {
    return <UserProfileRelationshipsNewUserRelationship {...this.props.UserProfileRelationshipsStore.dialogProps} />;
  }

  deleteRelationship() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.props.UserProfileRelationshipsStore.isAlertOpen}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.props.UserProfileRelationshipsStore.toggleIsAlertOpen()}
        confirmButtonText={I18n.t('js.delete_relationship')}
        onConfirm={() => this.props.UserProfileRelationshipsStore.deleteEmployeeRelationship()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_want_to_delete_this_relationship')}
      </Alert>
    );
  }

  handleTabChange(relationshipId) {
    const userId = this.props.UserProfileRelationshipsStore.userId;
    _.map(this.props.UserProfileRelationshipsStore.relationships, relationship => {
      if (relationship.id == relationshipId) {
        this.props.UserProfileRelationshipsStore.updateCurrentRelationship(userId, relationship);
        return;
      }
    });
  }

  render() {
    if (this.props.UserProfileRelationshipsStore.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.UserProfileRelationshipsStore.relationships.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.no_relationships_found')}
            description={I18n.t('js.there_are_no_relationships_configured_for_this_account')}
            icon="people"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexDirection="column">
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox marginRight="20px">
                <Tabs
                  selectedTabId={this.props.UserProfileRelationshipsStore.activeTab}
                  vertical="true"
                  onChange={this.handleTabChange.bind(this)}
                  animate={false}
                >
                  {this.props.UserProfileRelationshipsStore.relationships.map(relationship => (
                    <Tab id={relationship.id} key={relationship.id} title={relationship.name} />
                  ))}
                </Tabs>
              </Flexbox>
              <Flexbox flexGrow={1} flexDirection="column">
                <UserProfileRelationshipsTabData activeTab={this.state.activeTab} userId={this.props.UserProfileStore.user.id} />
              </Flexbox>
            </Flexbox>
          </Flexbox>
          {this.renderDialog()}
          {this.deleteRelationship()}
        </Flexbox>
      );
    }
  }
}
