/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import ActivityMappings from '../actions/ActivityMappings';
import _ from 'lodash';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import Paginator from '../models/PaginatorModel';

axiosCancel(axios, {
  debug: false, // default
});

class ActivityStore {
  @observable
  activities = [];
  @observable
  pagination = new Paginator();

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  addActivity(data) {
    return new Promise(resolve => {
      const newActivity = ActivityMappings.mapActivity(data);
      const findDuplicate = _.find(
        this.activities,
        o => o.additionalInformation === data.additional_information
      );
      if (findDuplicate) {
        newActivity.duplicate = true;
      }
      this.activities = _.unionBy(
        [newActivity],
        this.activities,
        'id'
      );
      this.sortActivities();
      resolve(newActivity);
    });
  }

  @action
  fetchActivities(
    activityObjectId,
    activityObjectType,
    xPage,
    xPerPage,
    xOffset
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/activities?activity_object_id=${activityObjectId}&activity_object_type=${activityObjectType}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchActivity(activityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/activities/${activityId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  sortActivities() {
    let newActivities = this.activities;
    newActivities = _.orderBy(newActivities, ['createdAt'], ['desc']);
    this.activities = newActivities;
  }

  @action
  resetActivities() {
    this.activities = [];
    this.pagination = new Paginator();
  }
}

const store = new ActivityStore();
export default store;
