const placeholders = [
  {
    name: 'First Name',
    static: 'true',
    attr: 'first_name',
  },
  {
    name: 'Last Name',
    static: 'true',
    attr: 'last_name',
  },
  {
    name: 'Full Name',
    static: 'true',
    attr: 'full_name',
  },
  {
    name: 'Email',
    static: 'true',
    attr: 'email',
  },
];

export default placeholders;
