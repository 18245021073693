// @flow
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from '../stores/ToastStore';

const apiEnv = Cookies.get('apiEnv');
const apiEndpoint = `${apiEnv}/profile_templates`;

export default class ProfileTemplateActions {
  static fetchAllProfileTemplates() {
    // fetches all profile templates for current account
    return axios
      .get(apiEndpoint)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static errorHandler(error: any) {
    const errors = error.response.data.error.join(', ');
    ToastStore.showToast(errors, 'danger');
  }
}
