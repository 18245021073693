import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { AnchorButton, NonIdealState } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import TemplateAddSection from '../templates/TemplateAddSection';
import TemplateSection from '../templates/TemplateSection';
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import classNames from 'classnames';

const DragHandle = sortableHandle(() => (
  <AnchorButton
    className="bp3-cursor-move bp3-minimal"
    icon="drag-handle-vertical"
  />
));

const SortableSection = sortableElement(({ section }) => (
  <TemplateSection section={section} dragHandle={<DragHandle />} />
));

const SortableSections = sortableContainer(({ sections }) => (
  <Flexbox flexDirection="column" marginTop="-20px">
    {sections.map(section => (
      <SortableSection
        key={section.id}
        index={sections.indexOf(section)}
        section={section}
      />
    ))}
  </Flexbox>
));

@inject('UserProfileTemplateStore', 'TemplateActions')
@observer
export default class UserProfileEditTemplate extends React.Component {
  @observable
  isLoading = false;

  handleSortEnd(section, newIndex) {
    const sectionObj = {
      id: section.id,
      row_order_position: newIndex,
    };
    this.props.TemplateActions.changeSection(sectionObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the section
    const findSection = this.props.UserProfileTemplateStore
      .activeSections[oldIndex];

    // Reorder the current UI
    const newSections = arrayMove(
      this.props.UserProfileTemplateStore.sections.slice(),
      oldIndex,
      newIndex
    );
    this.props.UserProfileTemplateStore.sections = newSections;

    // Process reorder on server
    this.handleSortEnd(findSection, newIndex);
  }

  renderSections() {
    if (
      this.props.UserProfileTemplateStore.activeSections.length > 0
    ) {
      const animatedClass = classNames(
        'animated',
        this.props.UserProfileTemplateStore.activeAnimation
      );
      return (
        <Flexbox
          flexDirection="column"
          flexGrow={1}
          marginTop="20px"
          className={animatedClass}
        >
          <SortableSections
            sections={
              this.props.UserProfileTemplateStore.activeSections
            }
            onSortEnd={this.onSortEnd.bind(this)}
            useDragHandle={true}
            lockToContainerEdges={true}
            lockAxis="y"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox
          flexDirection="column"
          marginTop="20px"
          marginBottom="20px"
          className="bp3-card"
        >
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_sections_to_show')}
            icon="duplicate"
            className="bp3-text-muted"
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    if (
      this.props.UserProfileTemplateStore.activeProfileTemplate === ''
    ) {
      return (
        <Flexbox
          flexGrow={1}
          width="100hw"
          alignContent="stretch"
          flexDirection="column"
          paddingBottom="0px"
        >
          <Flexbox
            flexDirection="column"
            flexGrow={1}
            className="fixed-width-container bp3-card"
            marginTop="20px"
          >
            <NonIdealState
              title={I18n.t('js.nothing_to_show')}
              description={I18n.t(
                'js.there_is_no_profile_template_to_show'
              )}
              icon="id-number"
              className="bp3-text-muted"
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexGrow={1}
        width="100hw"
        alignContent="stretch"
        flexDirection="column"
        paddingBottom="0px"
      >
        <Flexbox
          flexDirection="column"
          flexGrow={1}
          className="fixed-width-container"
          marginTop="20px"
        >
          {this.renderSections()}
        </Flexbox>
        <TemplateAddSection
          type="profile_template"
          profileTemplateId={
            this.props.UserProfileTemplateStore.activeProfileTemplate
              .id
          }
        />
      </Flexbox>
    );
  }
}
