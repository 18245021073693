import React from 'react';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { Alert, Button, Classes, Intent } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class AccountUsersInvitationsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDeleting: false };
  }

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting,
    });
  }

  handleDeleteInvitation(invitation) {
    this.props.AccountUsersStore.deleteInvitation(invitation.id)
      .then(() => {
        this.toggleDeleting();
        this.props.ToastStore.showToast(
          I18n.t('js.invitation_has_been_deleted'),
          'success'
        );
      })
      .catch(() => {
        this.toggleDeleting();
      });
  }

  renderDeleteAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_invitation')}
        onConfirm={() =>
          this.handleDeleteInvitation(this.props.invitation)
        }
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t(
          'js.are_you_sure_you_wish_to_delete_this_invitation',
          { email: this.props.invitation.email }
        )}
      </Alert>
    );
  }

  renderButtons() {
    if (this.props.invitation.accepted) {
      return (
        <Button
          className="bp3-small"
          text={I18n.t('js.delete_invitation')}
          disabled={true}
        />
      );
    }
    return (
      <Button
        className="bp3-small"
        text={I18n.t('js.delete_invitation')}
        onClick={() => this.toggleDeleting()}
      />
    );
  }

  render() {
    const { email } = this.props.invitation;
    const displayInitials = Utilities.getDisplayInitials(email);
    return (
      <tr key={this.props.invitation.id}>
        <td>
          <Flexbox flexDirection="row" alignItems="center">
            <Avatar
              email={this.props.invitation.email}
              size={20}
              round={true}
              className="push-10-r"
              src={`https://i2.wp.com/cdn.auth0.com/avatars/${displayInitials}.png`}
            />{' '}
            {this.props.invitation.email}
          </Flexbox>
        </td>
        <td>{this.props.invitation.createdAtToDate}</td>
        <td>
          {this.props.invitation.accepted ? (
            <span className="bp3-tag bp3-intent-success bp3-minimal">
              {I18n.t('js.accepted')}
            </span>
          ) : (
            <span className="bp3-tag bp3-intent-warning bp3-minimal">
              {I18n.t('js.pending')}
            </span>
          )}
        </td>
        <td>{this.renderButtons()}</td>
        {this.renderDeleteAlert()}
      </tr>
    );
  }
}
