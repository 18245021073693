/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Button, Callout, Classes, Dialog, Icon, Intent, NonIdealState, Spinner } from '@blueprintjs/core';
import ItemActions from '../actions/ItemActions';
import TemplateActions from '../actions/TemplateActions';
import ItemStore from '../stores/ItemStore';
import UserProfileStore from '../stores/UserProfileStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import AppStore from '../stores/AppStore';
import DynamicViewStore from '../stores/DynamicViewStore';
import AppItemStore from '../stores/AppItemStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import TeamUsersStore from '../stores/TeamUsersStore';
import CommentStore from '../stores/CommentStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import ItemTabs from '../components/items/ItemTabs';
import ItemEdit from '../components/items/ItemEdit';
import ItemSections from '../components/items/ItemSections';
import ItemSummary from '../components/items/ItemSummary';
import ActivityView from './ActivityView';
import CommentsView from './CommentsView';
import scrollToElement from 'scroll-to-element';
import Utilities from '../utils/Utilities';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

@observer
export default class ItemView extends React.Component {
  @observable isLoading = true;
  @observable isSubmitting = false;
  @observable isSaving = false;
  @observable showDialog = false;
  @observable showErrors = false;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  toggleSubmitting() {
    this.isSubmitting = !this.isSubmitting;
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }

  toggleShowErrors() {
    this.showErrors = !this.showErrors;
  }

  componentDidMount() {
    ItemStore.resetStartingAttributes();
    ItemStore.userCanEdit = this.props.userCanEdit;
    if (this.props.tab) {
      ItemStore.activeTab = this.props.tab;
    }
    ItemActions.fetchItem(this.props.itemId)
      .then(response => {
        if (AppStore.activeWorkspace.id === '') {
          WorkspaceStore.fetchWorkspace(response.data.app.workspace_id);
        }
        AppStore.addApp(response.data.app, 'single');
        ItemStore.isShowingParticipants = response.data.app.default_process_visible;
        ItemStore.addItem(response.data);
        ItemActions.fetchStatusFlags(response.data.app.id)
          .then(response => {
            _.map(response.data, statusFlag => {
              ItemStore.addStatusFlag(statusFlag);
            });
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleScrollToElement(elementId) {
    if (elementId === 'item-participant-chart') {
      ItemStore.isShowingParticipants = true;
    }
    scrollToElement(`.element_${elementId}`, {
      offset: 0,
      ease: 'out-circ',
      duration: 500,
    });
  }

  hideErrors() {
    ItemStore.submissionAttempted = false;
    this.showErrors = false;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (ItemStore.hasUnsetParticipants) {
      const errorObj = {
        elementId: 'item-participant-chart',
        errorMessage: I18n.t('js.please_set_all_participants'),
        errorMessageFull: I18n.t('js.please_set_all_participants'),
      };
      ItemStore.addError(errorObj);
      ItemStore.isShowingParticipants = true;
    }
    if (ItemStore.itemHasError) {
      ItemStore.showAllSections();
      ItemStore.submissionAttempted = true;
      this.toggleShowErrors();
    } else {
      this.toggleDialog();
    }
  }

  handleItemSubmission() {
    this.toggleSubmitting();
    ItemActions.handleSave(false)
      .then(() => {
        if (ItemStore.itemParticipantsToChange.length > 0) {
          this.submitItemParticipant();
        } else {
          this.submitItem();
        }
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  submitItemParticipant() {
    const itemParticipantObj = {
      id: _.last(ItemStore.itemParticipantsToChange).id,
      user_id: _.last(ItemStore.itemParticipantsToChange).userId,
    };
    ItemActions.changeItemParticipant(itemParticipantObj)
      .then(response => {
        ItemStore.itemParticipantsToChange = [];
        ItemStore.addItemParticipant(response.data);
        this.submitItem();
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  submitItem() {
    const itemObj = {
      id: ItemStore.item.id,
    };
    ItemActions.submitItem(itemObj)
      .then(() => {
        ToastStore.showToast(
          I18n.t('js.item_has_been_submitted', {
            name: _.capitalize(AppStore.activeApp.item),
          }),
          'success'
        );
        Utilities.navigate(`/items/${ItemStore.item.id}`);
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  handleSaveButNotSubmit() {
    const itemObj = {
      id: ItemStore.item.id,
      is_draft: false,
    };
    ItemActions.handleSave(false).then(response => {
      ItemActions.changeItem(itemObj).then(response => {
        Utilities.navigate(`/items/${ItemStore.item.id}`);
      });
    });
  }

  renderContent() {
    if (this.isLoading) {
      return (
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="row" flexWrap="wrap" marginTop="40px">
            <NonIdealState
              title={I18n.t('js.loading')}
              description={I18n.t('js.fetching_data')}
              icon={<Spinner />}
              className="bp3-text-muted"
            />
          </Flexbox>
        </Flexbox>
      );
    }
    if (ItemStore.activeTab === 'activity') {
      return (
        <Flexbox flexDirection="column" padding="20px">
          <ActivityView activityObjectId={ItemStore.item.id} activityObjectType="Item" />
        </Flexbox>
      );
    }
    if (ItemStore.activeTab === 'edit' && ItemStore.userIsOwner) {
      return (
        <Flexbox flexDirection="column" padding="20px">
          <ItemEdit />
        </Flexbox>
      );
    }
    if (this.props.shownInDialogView) {
      const height = document.documentElement.clientHeight * 0.5;
      return (
        <Flexbox flexGrow={1} flexDirection="column" paddingLeft="30px" paddingRight="30px">
          <ItemSummary shownInDialogView={this.props.shownInDialogView} />
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Scrollbars
              autoHide={true}
              style={{
                height: height,
              }}
            >
              <Flexbox padding="5px" flexDirection="column" marginTop="20px">
                <ItemSections shownInDialogView={this.props.shownInDialogView} />
                {this.renderErrors()}
              </Flexbox>
            </Scrollbars>
            <Flexbox flexDirection="column" marginTop="20px">
              {this.renderSubmission()}
              {this.renderSubmissionDialog()}
            </Flexbox>
          </form>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <ItemSummary shownInDialogView={this.props.shownInDialogView} />
        <Flexbox className="template-container" flexDirection="column" marginTop="20px">
          <form onSubmit={this.handleSubmit.bind(this)}>
            <ItemSections shownInDialogView={this.props.shownInDialogView} />
            {this.renderErrors()}
            <Flexbox flexDirection="column" marginTop="20px">
              {this.renderSubmission()}
              {this.renderSubmissionDialog()}
            </Flexbox>
          </form>
          <Flexbox flexDirection="column" flexGrow={1} marginTop="40px">
            <CommentsView
              commentable={ItemStore.item}
              commentableType="Item"
              canPin={true}
              stub={true}
              sortByNew={true}
              showNone={true}
              buttonText={I18n.t('js.comment_on_this_item', {
                item: AppStore.activeApp.item,
              })}
            />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }

  renderSubmissionDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        enforceFocus={false}
        title={I18n.t('js.are_you_sure')}
      >
        <div className="bp3-dialog-body">
          {ItemStore.nextFullState && (
            <p
              dangerouslySetInnerHTML={{
                __html: I18n.t('js.are_you_sure_you_wish_to_submit_this_item', {
                  state: ItemStore.nextFullState.name,
                }),
              }}
            />
          )}
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
            <Button
              icon="arrow-right"
              intent={Intent.PRIMARY}
              onClick={this.handleItemSubmission.bind(this)}
              text={I18n.t('js.submit_item', {
                name: AppStore.activeApp.item,
              })}
              loading={this.isSubmitting}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  renderSubmission() {
    if (this.props.shownInDialogView) {
      return (
        <Flexbox flexDirection="row" justifyContent="flex-end">
          <Button
            outlined={false}
            large={true}
            text={I18n.t('js.save_draft')}
            icon="floppy-disk"
            onClick={() => this.handleSaveButNotSubmit()}
            className="push-10-r"
            disabled={!ItemStore.hasChanges}
            loading={ItemStore.isSaving}
          />
          <Button
            large={true}
            outlined={false}
            text={I18n.t('js.create_item', { item: AppStore.activeApp.item })}
            icon="arrow-right"
            intent={Intent.PRIMARY}
            onClick={this.handleSubmit.bind(this)}
            disabled={ItemStore.itemHasError && ItemStore.submissionAttempted}
          />
        </Flexbox>
      );
    }
    if (ItemStore.userIsOwner) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" marginTop="0px">
          {!ItemStore.currentStateIsLast ? (
            <Button
              outlined={false}
              text={I18n.t('js.submit_to_next_state')}
              icon="arrow-right"
              intent={Intent.PRIMARY}
              className="bp3-large push-10-r"
              onClick={this.handleSubmit.bind(this)}
              disabled={ItemStore.itemHasError && ItemStore.submissionAttempted}
            />
          ) : null}
          <Button
            outlined={false}
            text={!ItemStore.hasChanges ? I18n.t('js.your_changes_have_been_saved') : I18n.t('js.save_changes')}
            icon="floppy-disk"
            className="bp3-large"
            onClick={() => ItemActions.handleSave(true)}
            disabled={!ItemStore.hasChanges}
            loading={ItemStore.isSaving}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderErrors() {
    if (this.showErrors && ItemStore.itemHasError) {
      return (
        <Flexbox flexGrow={1} marginBottom="40px">
          <Callout intent={Intent.DANGER} icon="error" className="max-width">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>
              <Flexbox flexGrow={1}>
                <Flexbox flexGrow={1}>
                  {I18n.t('js.please_correct_the_following_errors_before_submitting_this_item', { name: AppStore.activeApp.item })}
                </Flexbox>
                <Flexbox flexGrow={0} justifyContent="flex-end">
                  <Button intent={Intent.DANGER} className="bp3-small bp3-minimal" onClick={this.hideErrors.bind(this)}>
                    <span className="bp3-icon bp3-icon-cross error-clear-button" />
                  </Button>
                </Flexbox>
              </Flexbox>
            </h4>
            <ul className={`${Classes.LIST} bp3-list bp3-intent-danger`}>
              {_.map(ItemStore.itemErrors.reverse(), (error, index) => (
                <li key={index}>
                  {/* FIXME make accessible */}
                  {/* eslint-disable-next-line */}
                  <a onClick={() => this.handleScrollToElement(error.elementId)}>{error.errorMessageFull}</a>
                </li>
              ))}
            </ul>
          </Callout>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderUnsavedChangesBar() {
    if (ItemStore.hasChanges && !this.props.shownInDialogView) {
      return (
        <Flexbox width="100hw" className="sticky-footer sticky-footer-primary bp3-elevation-3 animated zoomInDown">
          <Flexbox className="fixed-width-container" flexGrow={1} justifyContent="space-between">
            <Flexbox flexGrow={1} alignContent="center" alignItems="center">
              <Icon icon="issue-new" className="text-white push-10-r" iconSize={25} />
              <Flexbox flexDirection="column" justifyContent="center" paddingTop="10px">
                <p>{I18n.t('js.you_have_unsaved_changes')}</p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" justifyContent="center">
              <Button
                text={I18n.t('js.save_changes_now')}
                icon="tick"
                className="bp3-intent-primary push-60-r"
                onClick={() => ItemActions.handleSave(true)}
                disabled={!ItemStore.hasChanges}
                loading={ItemStore.isSaving}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      ItemStore,
      ItemActions,
      UserProfileStore,
      UserProfileTemplateStore,
      DynamicViewStore,
      ItemActions,
      TemplateActions,
      AppStore,
      AppItemStore,
      CurrentUserStore,
      CommentStore,
      ToastStore,
      TeamUsersStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} alignContent="stretch" flexDirection="column" paddingBottom="0px">
              {this.props.shownInDialogView ? null : (
                <React.Fragment>
                  <ItemTabs />
                </React.Fragment>
              )}
              <Flexbox flexGrow={1} flexDirection="column" marginTop={this.props.shownInDialogView ? '20px' : '40px'}>
                {this.renderContent()}
              </Flexbox>
              {this.renderUnsavedChangesBar()}
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
