/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';

export default class AuthorizationView extends React.Component {
  getParameterByName(name) {
    // lazy fix for legacy code
    // eslint-disable-next-line require-unicode-regexp
    const match = RegExp(`[#&]${name}=([^&]*)`).exec(
      window.location.hash
    );
    return match && decodeURIComponent(match[1].replace(/\+/gu, ' '));
  }

  getAccessToken() {
    return this.getParameterByName('access_token');
  }

  getIdToken() {
    return this.getParameterByName('id_token');
  }

  render() {
    return <div>{this.getAccessToken()}</div>;
  }
}
