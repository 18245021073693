import React from 'react';
import { inject, observer } from 'mobx-react';
import { Collapse } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import classNames from 'classnames';
import renderHTML from 'react-render-html';

@inject('NotificationStore')
@observer
export default class NotificationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isExpanded: false };
  }

  toggleExpanded() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
    this.handleNotificationClick();
  }

  handleNotificationClick() {
    if (!this.props.notification.read) {
      this.props.NotificationStore.markAsRead(
        this.props.notification.id
      );
    }
  }

  renderDate() {
    let textClass = classNames('noselect bp3-primary');
    if (this.props.notification.read) {
      textClass = classNames('bp3-text-muted noselect');
    }

    if (this.props.notification.read) {
      return (
        <Flexbox
          className={textClass}
          flexDirection="row"
          alignItems="flex-start"
        >
          {this.props.notification.createdAtToDate}
        </Flexbox>
      );
    }
    return (
      <Flexbox
        className={textClass}
        flexDirection="row"
        alignItems="flex-start"
      >
        {this.props.notification.createdAtToDate}
      </Flexbox>
    );
  }

  renderContent() {
    let textClass = classNames('');
    if (this.props.notification.read) {
      textClass = classNames('bp3-text-muted');
    }
    return (
      <Collapse isOpen={this.state.isExpanded}>
        <Flexbox
          flexDirection="column"
          flexGrow={0}
          flexBasis="0"
          className={textClass}
          marginTop="10px"
        >
          <span className="bp3-running-text">
            {renderHTML(this.props.notification.message)}
          </span>
        </Flexbox>
      </Collapse>
    );
  }

  renderAvatar() {
    let imgClass = classNames('push-10-r');
    if (this.props.notification.read) {
      imgClass = classNames('push-10-r grayscale');
    }
    if (this.props.notification.read) {
      return (
        <Avatar
          src={this.props.notification.imageFileName}
          size={30}
          round={true}
          className={imgClass}
        />
      );
    }
    return (
      <Flexbox className="position-relative">
        <span className="bp3-icon-full-circle bp3-primary bp3-notification-unread" />
        <Avatar
          src={this.props.notification.imageFileName}
          size={30}
          round={true}
          className={imgClass}
        />
      </Flexbox>
    );
  }

  render() {
    let notificationClass = classNames('bp3-tr-unread');
    let textClass = classNames('noselect bp3-primary');
    if (this.props.notification.read) {
      notificationClass = classNames('');
      textClass = classNames('bp3-text-muted noselect');
    }
    return (
      <tr
        className={notificationClass}
        onClick={this.toggleExpanded.bind(this)}
      >
        <td className="bp3-tr-fixed">
          <Flexbox
            key={this.props.notification.id}
            flexDirection="row"
          >
            <Flexbox flexDirection="column" alignItems="flex-start">
              {this.renderAvatar()}
            </Flexbox>
            <Flexbox
              flexDirection="column"
              alignContent="center"
              flexGrow={1}
            >
              <Flexbox
                flexDirection="column"
                flexBasis="1"
                flexGrow={1}
                justifyContent="center"
                className="notifications-row"
              >
                <span className={textClass}>
                  {this.props.notification.title}
                </span>
              </Flexbox>
              {this.renderContent()}
            </Flexbox>
          </Flexbox>
        </td>
        <td>
          <Flexbox
            flexDirection="column"
            flexGrow={1}
            paddingTop="5px"
          >
            {this.renderDate()}
          </Flexbox>
        </td>
      </tr>
    );
  }
}
