/* for legacy code, remove class method this restriction: */
/* eslint class-methods-use-this: 0 */
import ActivityView from './ActivityView';
import Flexbox from 'flexbox-react';
import React from 'react';
import AppsView from './AppsView';
import ToastStore from '../stores/ToastStore';
import WorkspaceUsage from '../components/workspaces/WorkspaceUsage';
import WorkspaceEditWorkspace from '../components/workspaces/WorkspaceEditWorkspace';
import WorkspaceStore from '../stores/WorkspaceStore';
import WorkspaceTabs from '../components/workspaces/WorkspaceTabs';
import CurrentUserStore from '../stores/CurrentUserStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import CommentStore from '../stores/CommentStore';
import classNames from 'classnames';
import { AnchorButton, Classes, Icon, Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class WorkspaceView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    WorkspaceStore.resetStartingAttributes();
    if (this.props.tab) {
      WorkspaceStore.activeTab = this.props.tab;
    }
    if (this.props.folder) {
      WorkspaceStore.activeFolderId = this.props.folder;
    }
    WorkspaceStore.fetchWorkspace(this.props.workspaceId)
      .then(() => {
        if (this.props.tab === 'edit' && WorkspaceStore.activeWorkspace.userPermissionLevel.name !== 'owner') {
          WorkspaceStore.activeTab = 'apps';
        }
        WorkspaceStore.fetchWorkspaceFolders(this.props.workspaceId)
          .then(() => {
            this.toggleLoading();
          })
          .catch(error => {
            this.toggleLoading();
            // eslint-disable-next-line no-console
            console.log('error with fetching workspace folders: ', error);
          });
      })
      .catch(error => {
        this.toggleLoading();
        // eslint-disable-next-line no-console
        console.log('error with fetching workspace: ', error);
      });
  }

  publishWorkspace() {
    const newObj = {
      status: 'published',
    };
    WorkspaceStore.changeWorkspace(newObj).then(response => {
      if (response.data.status === 'published') {
        ToastStore.showToast(I18n.t('js.workspace_published'), 'success');
      }
    });
  }

  renderPublishWorkspace() {
    const { activeWorkspace } = WorkspaceStore;
    if (activeWorkspace.userPermissionLevel.name === 'owner') {
      return (
        <Flexbox flexDirection="column" justifyContent="center">
          <AnchorButton onClick={this.publishWorkspace.bind(this)} text={I18n.t('js.publish_workspace')} className="bp3-intent-warning" />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderDraftWorkspace() {
    const { activeWorkspace } = WorkspaceStore;
    if (activeWorkspace.status === 'draft' && !this.isLoading) {
      return (
        <Flexbox className="sign-up-bar bp3-callout bp3-intent-warning">
          <Flexbox className="fixed-width-container" justifyContent="space-between" flexGrow={1}>
            <Flexbox flexGrow={1} alignContent="center" alignItems="center">
              <Icon icon="issue-new" className="push-10-r" iconSize={20} intent={Intent.WARNING} />
              <Flexbox flexDirection="column" justifyContent="center" paddingTop="10px">
                <p>{I18n.t('js.draft_workspace_text')}</p>
              </Flexbox>
            </Flexbox>
            {this.renderPublishWorkspace()}
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderWorkspaceIcon() {
    const iconClass = classNames('fa bp3-profile-icon-large', WorkspaceStore.activeWorkspace.icon);
    const bgStyle = {
      backgroundColor: WorkspaceStore.activeWorkspace.color,
    };
    return (
      <Flexbox className="bp3-large-circle-icon" style={bgStyle} justifyContent="center" alignItems="center">
        <i className={iconClass} />
      </Flexbox>
    );
  }

  renderBreadCrumbs() {
    return (
      <Flexbox>
        <ul className={`${Classes.LIST} bp3-breadcrumbs`}>
          <li>
            <a className="bp3-breadcrumb" href="/workspaces">
              {I18n.t('js.workspaces')}
            </a>
          </li>
          <li>
            <span className="bp3-breadcrumb">{WorkspaceStore.activeWorkspace.name}</span>
          </li>
        </ul>
      </Flexbox>
    );
  }

  renderContent() {
    if (this.isLoading || WorkspaceStore.activeWorkspace === '') {
      return <Flexbox flexDirection="column" width="100%" className="fixed-width-container" marginTop="20px"></Flexbox>;
    }
    if (WorkspaceStore.activeTab === 'edit' && WorkspaceStore.activeWorkspace.userPermissionLevel.name === 'owner') {
      return (
        <Flexbox flexDirection="column" className="fixed-width-container" marginTop="20px">
          <WorkspaceEditWorkspace />
        </Flexbox>
      );
    }
    if (WorkspaceStore.activeTab === 'apps') {
      return <AppsView activeWorkspace={WorkspaceStore.activeWorkspace} />;
    }
    if (WorkspaceStore.activeTab === 'usage') {
      return (
        <Flexbox flexDirection="column" className="fixed-width-container" marginTop="20px">
          <WorkspaceUsage />
        </Flexbox>
      );
    }
    if (WorkspaceStore.activeTab === 'activity') {
      return (
        <Flexbox flexDirection="column" className="fixed-width-container" marginTop="20px">
          <ActivityView activityObjectId={WorkspaceStore.activeWorkspace.id} activityObjectType="Workspace" />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const stores = {
      WorkspaceStore,
      CurrentUserStore,
      StoreTemplateStore,
      StoreTemplateActions,
      CommentStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <WorkspaceTabs />
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderDraftWorkspace()}
              </Flexbox>
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
