import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { injectStripe } from 'react-stripe-elements';
import AccountBillingCardSection from './AccountBillingCardSection';
import AccountBillingAddressSection from './AccountBillingAddressSection';
import Utilities from '../../utils/Utilities';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
class AccountBillingCheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleDialog() {
    this.props.toggleDialog();
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleCancel() {
    // reset form inputs
    this.toggleDialog();
  }

  handleSubmit(e) {
    this.toggleLoading();
    e.preventDefault();
    this.props.stripe
      .createToken({
        type: 'card',
        name: this.props.AccountBillingStore.newSubscription.cardHoldersName,
        address_line1: this.props.AccountBillingStore.newSubscription.addressLine1,
        address_line2: this.props.AccountBillingStore.newSubscription.addressLine2,
        address_city: this.props.AccountBillingStore.newSubscription.city,
        address_state: this.props.AccountBillingStore.newSubscription.stateCounty,
        address_zip: this.props.AccountBillingStore.newSubscription.zip,
        address_country: this.props.AccountBillingStore.newSubscription.country,
      })
      .then(({ token }) => {
        const subscriptionObj = {
          plan_id: this.props.AccountBillingStore.selectedPlan.id,
          token: token.id,
          address_line_1: this.props.AccountBillingStore.newSubscription.addressLine1,
          address_line_2: this.props.AccountBillingStore.newSubscription.addressLine2,
          city: this.props.AccountBillingStore.newSubscription.city,
          state_county: this.props.AccountBillingStore.newSubscription.stateCounty,
          zip: this.props.AccountBillingStore.newSubscription.zip,
          country: this.props.AccountBillingStore.newSubscription.country,
          card_holders_name: this.props.AccountBillingStore.newSubscription.cardHoldersName,
          company_name: this.props.AccountBillingStore.newSubscription.companyName,
          tax_percent: this.props.AccountBillingStore.newSubscription.calculatedTaxPercent,
          coupon: this.props.AccountBillingStore.newSubscription.coupon,
        };
        if (this.props.AccountBillingStore.newSubscription.taxNumber.length > 0) {
          subscriptionObj.tax_number = this.props.AccountBillingStore.newSubscription.taxNumber;
        }
        if (this.props.AccountBillingStore.newSubscription.stateCounty.length > 0) {
          subscriptionObj.state_county = this.props.AccountBillingStore.newSubscription.stateCounty;
        }
        if (this.props.action === 'changeCard') {
          subscriptionObj.id = this.props.AccountBillingStore.activeSubscription.id;
          subscriptionObj.plan_id = this.props.AccountBillingStore.activeSubscription.planId;
          this.props.AccountBillingActions.changeSubscription(subscriptionObj)
            .then(response => {
              this.props.AccountBillingStore.addSubscription(response.data);
              this.toggleLoading();
              this.toggleDialog();
              this.props.AccountBillingStore.setActiveTab('subscription');
              this.props.ToastStore.showToast(I18n.t('js.your_subscription_has_been_updated'), 'success');
              this.props.AccountBillingStore.resetNewSubscription();
            })
            .catch(() => {
              this.toggleLoading();
            });
        } else {
          this.props.AccountBillingActions.createSubscription(subscriptionObj)
            .then(response => {
              this.props.AccountBillingStore.addSubscription(response.data);
              this.toggleLoading();
              this.toggleDialog();
              this.props.ToastStore.showToast(
                I18n.t('js.you_are_now_subscribed_to_the_plan', {
                  nickname: this.props.AccountBillingStore.selectedPlan.nickname.toLowerCase(),
                }),
                'success'
              );
              this.props.AccountBillingStore.resetNewSubscription();
              window.location = '/dashboard?show_welcome=true';
            })
            .catch(() => {
              this.toggleLoading();
            });
        }
      })
      .catch(() => {
        this.props.ToastStore.showToast(I18n.t('js.something_went_wrong'), 'danger');
        this.toggleLoading();
      });
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="credit-card"
        isOpen={this.props.dialogOpen}
        canOutsideClickClose={false}
        onClose={this.toggleDialog.bind(this)}
        title={
          this.props.action === 'changeCard'
            ? I18n.t('js.add_new_card')
            : I18n.t('js.subscribe_to_plan', {
                plan: this.props.AccountBillingStore.selectedPlan.nickname,
              })
        }
      >
        <Flexbox flexDirection="column" flexGrow={1}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox className="bp3-dialog-body" flexDirection="column" flexGrow={1}>
              <AccountBillingCardSection />
              <AccountBillingAddressSection />
            </Flexbox>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <Button text={I18n.t('js.cancel')} onClick={() => this.handleCancel()} />
                <Button
                  intent={Intent.SUCCESS}
                  type="submit"
                  loading={this.state.isLoading}
                  disabled={!this.props.AccountBillingStore.newSubscriptionValid}
                  text={
                    this.props.action === 'changeCard'
                      ? I18n.t('js.add_new_card')
                      : I18n.t('js.subscribe_to_plan', {
                          plan: this.props.AccountBillingStore.selectedPlan.nickname,
                        })
                  }
                />
              </div>
            </div>
          </form>
        </Flexbox>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        {this.renderDialog()}
      </Flexbox>
    );
  }
}

export default injectStripe(AccountBillingCheckoutForm);
