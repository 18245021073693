import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import TimelineStore from '../../stores/TimelineStore';
import {
  Classes,
  Switch,
  NonIdealState,
  Spinner,
  AnchorButton,
  Intent,
  Dialog,
  InputGroup,
  FormGroup,
  Button,
  Callout,
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';

@observer
export default class NewTimelineEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
    };
  }

  minDate() {
    var minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    return minDate;
  }

  maxDate() {
    var maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 100);
    return maxDate;
  }

  @computed
  get canSubmit() {
    const { activeTimeline } = TimelineStore;
    return activeTimeline.event_description.length > 0;
  }

  toggleDialog() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleStartDateChange(date) {
    if (date != null) {
      TimelineStore.updateActiveTimelineAttribute(date, 'event_start_date');
    }
  }

  handleEndDateChange(date) {
    if (date != null) {
      TimelineStore.updateActiveTimelineAttribute(date, 'event_end_date');
    }
  }

  handleInputChange(e) {
    TimelineStore.updateActiveTimelineAttribute(e.target.value, e.target.name);
  }

  handleCancel() {
    this.setState({ isOpen: false });
    TimelineStore.resetActiveTimeline();
  }

  handleSubmit() {
    this.setState({ isSubmitting: true });
    const timelineObj = {
      user_id: this.props.userId,
      event_start_date: moment.utc(TimelineStore.activeTimeline.event_start_date),
      event_end_date: moment.utc(TimelineStore.activeTimeline.event_end_date),
      event_description: TimelineStore.activeTimeline.event_description,
    };
    TimelineStore.createTimeline(timelineObj)
      .then(response => {
        this.setState({ isSubmitting: false, isOpen: false });
      })
      .catch(error => {
        this.setState({ isSubmitting: false, isOpen: false });
      });
  }

  renderDialog() {
    const { activeTimeline } = TimelineStore;
    return (
      <Dialog
        portalContainer={document.body}
        icon="timeline-events"
        isOpen={this.state.isOpen}
        onClose={this.handleCancel.bind(this)}
        canOutsideClickClose={false}
        inline={true}
        style={{ width: '530px' }}
        title={I18n.t('js.add_timeline_event')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.create_a_new_timeline_event_description')}</p>
            </Flexbox>
            <Flexbox flexDirection="column">
              <FormGroup label={I18n.t('js.event_description')} labelFor="event_description" labelInfo={`(${I18n.t('js.required')})`}>
                <InputGroup
                  name="event_description"
                  className="bp3-fill"
                  onChange={e => this.handleInputChange(e)}
                  placeholder={I18n.t('js.event_description')}
                  value={activeTimeline.event_description}
                />
              </FormGroup>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={0}>
              <FormGroup label={I18n.t('js.event_start_date')} labelFor="event_start_date" labelInfo={`(${I18n.t('js.required')})`}>
                <DatePicker
                  className="bp3-card"
                  value={new Date(activeTimeline.event_start_date)}
                  onChange={date => this.handleStartDateChange(date)}
                  minDate={this.minDate()}
                  maxDate={this.maxDate()}
                  highlightCurrentDay={true}
                  includeTime={false}
                  showActionsBar={false}
                  popoverProps={{
                    usePortal: false,
                    inline: false,
                  }}
                />
              </FormGroup>
              <FormGroup
                label={I18n.t('js.event_end_date')}
                labelFor="event_end_date"
                className="push-20-l"
                labelInfo={`(${I18n.t('js.required')})`}
              >
                <DatePicker
                  className="bp3-card"
                  value={new Date(activeTimeline.event_end_date)}
                  onChange={date => this.handleEndDateChange(date)}
                  minDate={this.minDate()}
                  maxDate={this.maxDate()}
                  highlightCurrentDay={true}
                  includeTime={false}
                  showActionsBar={false}
                  popoverProps={{
                    usePortal: false,
                    inline: false,
                  }}
                />
              </FormGroup>
            </Flexbox>
            <Callout title={`${I18n.t('js.point_in_time_event')}?`} icon="help">
              {I18n.t('js.point_in_time_event_description')}
            </Callout>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.canSubmit}
                text={I18n.t('js.add_timeline_event')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} marginTop="20px">
        <AnchorButton icon="timeline-events" text={I18n.t('js.add_timeline_event')} onClick={this.toggleDialog.bind(this)} />
        {this.renderDialog()}
      </Flexbox>
    );
  }
}
