/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import classNames from 'classnames';
import RichTextEditor from '../shared/RichTextEditor';

@inject('AppStore', 'WorkspaceStore', 'StoreTemplateActions', 'StoreTemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreTemplateEditDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    const newObj = {
      id: this.props.StoreTemplateStore.newStoreTemplate.id,
      title: this.props.StoreTemplateStore.newStoreTemplate.title,
      subtitle: this.props.StoreTemplateStore.newStoreTemplate.subtitle,
      description: this.props.StoreTemplateStore.newStoreTemplate.description,
      benefits: this.props.StoreTemplateStore.newStoreTemplate.benefits,
      item: this.props.StoreTemplateStore.newStoreTemplate.item,
      item_plural: this.props.StoreTemplateStore.newStoreTemplate.itemPlural,
    };
    this.props.StoreTemplateActions.changeStoreTemplate(newObj)
      .then(response => {
        this.props.StoreTemplateStore.addStoreTemplate(response.data);
        this.props.StoreTemplateStore.setActiveStoreTemplate(response.data.id);
        this.toggleLoading();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.StoreTemplateStore.updateNewStoreTemplate(e.target.value, e.target.name);
  }

  handleDescriptionChange(content) {
    this.props.StoreTemplateStore.updateNewStoreTemplate(content, 'description');
  }

  handleBenefitsChange(content) {
    this.props.StoreTemplateStore.updateNewStoreTemplate(content, 'benefits');
  }

  handleCancel() {
    this.props.StoreTemplateStore.resetEditedStoreTemplate();
    this.toggleOpen();
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.StoreTemplateStore.newStoreTemplate[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.StoreTemplateStore.newStoreTemplate[`${field}Error`]}</div>;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.details')}
            </h4>
            <p className="noselect">{I18n.t('js.change_store_template_title_and_description')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'title') && !this.props.StoreTemplateStore.newStoreTemplate.titleValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="title">
                  {I18n.t('js.store_template_title')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'title') && !this.props.StoreTemplateStore.newStoreTemplate.titleValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.enter_a_title_for_your_store_template')}
                      name="title"
                      value={this.props.StoreTemplateStore.newStoreTemplate.title}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('title')}
                </div>
              </Flexbox>
              <Flexbox flexDirection="column">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'subtitle') && !this.props.StoreTemplateStore.newStoreTemplate.subtitleValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="subtitle">
                    {I18n.t('js.subtitle')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'subtitle') && !this.props.StoreTemplateStore.newStoreTemplate.subtitleValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_a_subtitle_for_your_store_template')}
                        name="subtitle"
                        value={this.props.StoreTemplateStore.newStoreTemplate.subtitle}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('subtitle')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'description') && !this.props.StoreTemplateStore.newStoreTemplate.descriptionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="description">
                  {I18n.t('js.store_template_description')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'description') && !this.props.StoreTemplateStore.newStoreTemplate.descriptionValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <RichTextEditor
                      content={this.props.StoreTemplateStore.newStoreTemplate.description}
                      rows={8}
                      handleChange={content => this.handleDescriptionChange(content)}
                      placeholder={I18n.t('js.enter_a_description_for_your_store_template')}
                      disabled={false}
                      inputValid={true}
                      showSubmissionButtons={false}
                    />
                  </div>
                  {this.renderErrors('description')}
                </div>
              </Flexbox>
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'benefits') && !this.props.StoreTemplateStore.newStoreTemplate.descriptionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="benefits">
                  {I18n.t('js.template_benefits')}
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'benefits') && !this.props.StoreTemplateStore.newStoreTemplate.descriptionValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <RichTextEditor
                      content={this.props.StoreTemplateStore.newStoreTemplate.benefits}
                      rows={8}
                      handleChange={content => this.handleBenefitsChange(content)}
                      placeholder={I18n.t('js.describe_the_benefits_of_using_your_store_template')}
                      disabled={false}
                      inputValid={true}
                      showSubmissionButtons={false}
                    />
                  </div>
                  {this.renderErrors('benefits')}
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox>
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  type="submit"
                  disabled={false}
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
