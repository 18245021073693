/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import _ from 'lodash';
import { FormGroup, Intent } from '@blueprintjs/core';
import TeamUsersStore from '../../stores/TeamUsersStore';
import TeamSuggest from '../suggests/TeamSuggest';
import TeamInfoDrawer from './TeamInfoDrawer';
import Utilities from '../../utils/Utilities';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementObjectSelectTeam extends React.Component {
  @observable drawerData = [];
  @observable isTeamDrawerLoading = false;
  @observable isTeamDrawerOpen = false;
  @observable store = '';
  @observable actions = '';

  componentDidMount() {
    this.setStoreAndActions();
  }

  setStoreAndActions() {
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  @action
  toggleBoolean = value => {
    this[`${value}`] = !this[`${value}`];
  };

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  openTeamDrawer(team) {
    this.drawerData = team;
    this.toggleBoolean('isTeamDrawerLoading');
    TeamUsersStore.resetStartingAttributes();
    TeamUsersStore.fetchTeam(team.id)
      .then(() => {
        const appNum = 1;
        TeamUsersStore.fetchUserTeams(team.id, appNum, TeamUsersStore.pagination.xPerPage, TeamUsersStore.pagination.xOffset)
          .then(() => {
            this.toggleBoolean('isTeamDrawerLoading');
          })
          .catch(() => {
            this.toggleBoolean('isTeamDrawerLoading');
          });
      })
      .catch(() => {
        this.toggleBoolean('isTeamDrawerLoading');
      });

    this.toggleBoolean('isTeamDrawerOpen');
  }

  @action
  toggleIsTeamDrawerLoading() {
    this.isTeamDrawerLoading = !this.isTeamDrawerLoading;
  }

  handleTeamClick(result) {
    const { element } = this.props;
    const formValue = {
      id: Utilities.makeId(),
      element_id: element.id,
      element_object_select_team_id: result.id,
    };
    if (this.props.storeType == 'profile') {
      formValue['profile_template_id'] = this.store.activeProfileTemplate.id;
      formValue['user_id'] = this.props.UserProfileStore.user.id;
      formValue['profile_value_type'] = 'number';
    } else {
      formValue['item_id'] = this.store.item.id;
      formValue['item_value_type'] = 'number';
    }
    const findFormValueToDelete = _.find(element.formValues, o => o.elementObjectSelectTeamId === result.id);
    if (findFormValueToDelete) {
      // Form value is included in existing form values then it was removed and added back, then remove from array to delete
      this.store.formValuesToDelete = _.filter(this.store.formValuesToDelete, o => o.id != findFormValueToDelete.id);
    } else {
      this.store.addFormValueToCreate(formValue);
    }
  }

  handleTeamRemove(id) {
    const { element } = this.props;
    const findValueToCreate = _.find(
      this.store.formValuesToCreate,
      o => o.element_object_select_team_id === id && o.element_id === element.id
    );
    if (findValueToCreate) {
      // Form value has not been created, so just remove from the list to create
      this.store.formValuesToCreate = _.filter(
        this.store.formValuesToCreate,
        o => o.element_object_select_team_id != id && o.element_id != element.id
      );
    } else {
      const formValue = _.find(element.formValues, o => o.elementObjectSelectTeamId == id);
      this.store.addFormValueToDelete({ id: formValue.id });
    }
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          className={this.props.element.isEditable ? '' : 'bp3-display-label'}
          label={this.props.element.elementObjectSelect.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isEditable && this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          <TeamSuggest
            placeholder={this.props.element.elementObjectSelect.placeholderText}
            element={this.props.element}
            handleTeamClick={this.handleTeamClick.bind(this)}
            handleTeamRemove={this.handleTeamRemove.bind(this)}
            validateInput={this.props.validateInput}
            intent={this.props.showError ? Intent.DANGER : null}
            openDrawer={this.openTeamDrawer.bind(this)}
            expandMenu={true}
          />
        </FormGroup>
        <TeamInfoDrawer
          isOpen={this.isTeamDrawerOpen}
          team={this.drawerData}
          toggleBool={this.toggleBoolean.bind(this)}
          isLoading={this.isTeamDrawerLoading}
          toggleIsTeamDrawerLoading={this.toggleIsTeamDrawerLoading.bind(this)}
        />
      </Flexbox>
    );
  }
}
