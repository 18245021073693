/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
// @flow
import { observable } from 'mobx';
import ReportTemplateFilter from './ReportTemplateFilterModel';
import type { FilterData, SortDirection, UUID } from '../types/DataViewTypes';

export default class ReportTemplateColumnModel {
  @observable
  id: UUID;
  @observable
  reportTemplateId: UUID;
  @observable
  columnName: string;
  @observable
  columnId: UUID;
  @observable
  columnType: FilterData;
  @observable
  columnOrder: number;
  @observable
  sortDirection: SortDirection;
  @observable
  sortPriority: number;
  @observable
  hidden: boolean;
  @observable
  createdBy: ?string;
  @observable
  updatedBy: ?string;
  @observable
  deletedBy: ?string;
  @observable
  deletedAt: ?string;
  @observable
  createdAt: ?string;
  @observable
  updatedAt: ?string;
  @observable
  reportTemplateFilters: Array<ReportTemplateFilter>;

  // $FlowFixMe
  constructor({
    id,
    report_template_id,
    column_name,
    column_id,
    column_type,
    column_order,
    sort_direction,
    sort_priority,
    hidden,
    created_by = '',
    updated_by = '',
    deleted_by = '',
    deleted_at = '',
    created_at = '',
    updated_at = '',
    report_template_filters = [],
  }) {
    this.id = id;
    this.reportTemplateId = report_template_id;
    this.columnName = column_name;
    this.columnId = column_id;
    this.columnType = column_type;
    this.columnOrder = column_order;
    this.sortDirection = sort_direction;
    this.sortPriority = sort_priority;
    this.hidden = hidden;
    this.createdBy = created_by;
    this.updatedBy = updated_by;
    this.deletedBy = deleted_by;
    this.deletedAt = deleted_at;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.reportTemplateFilters = report_template_filters.map(filterData => new ReportTemplateFilter(filterData));
  }

  // really the operand should be its own type here but the switch is ok for now.
  shouldOperandBeDisabled(operand: string): boolean {
    switch (operand) {
      // case 'equals':
      //   return false;
      // case 'contains':
      // case 'does_not_contain':
      //   return this.columnType !== 'string';
      // case 'starts_with':
      //   return this.columnType !== 'string';
      // case 'ends_with':
      //   return this.columnType !== 'string';
      // case 'is_blank':
      //   return false;
      // case 'is_not_blank':
      //   return false;
      // case 'is_greater_than':
      //   return this.columnType === 'string';
      // case 'is_less_than':
      //   return this.columnType === 'string';
      // case 'is_within_last_days':
      //   return this.columnType !== 'datetime';
      // case 'is_within_next_days':
      //   return this.columnType !== 'datetime';
      default:
        return false;
    }
  }
}
