import React from 'react';
import Flexbox from 'flexbox-react';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Dialog, FormGroup, HTMLSelect, Icon, Intent } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('StoreTemplateActions', 'StoreTemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class InstallStoreTemplate extends React.Component {
  @observable
  installProgress = 0;
  @observable
  isFinishedInstalling = false;

  @computed
  get installReady() {
    let ready = true;
    if (this.props.storeTemplate.templatableObjectType === 'App') {
      if (this.state.selectedWorkspaceId === '') {
        ready = false;
      }
      if (this.props.StoreTemplateStore.workspaceFolders.length > 0 && this.state.selectedWorkspaceFolderId === '') {
        ready = false;
      }
    }
    if (this.props.storeTemplate.templatableObjectType === 'ProfileTemplate' && !this.props.CurrentUserStore.userIsAccountOwner) {
      ready = false;
    }
    return ready;
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingWorkspaces: false,
      isLoadingWorkspaceFolders: false,
      selectedWorkspaceId: '',
      selectedWorkspaceFolderId: '',
    };

    this.installProgress = 0;
    this.isFinishedInstalling = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.triggerStoreTemplate) {
      this.finishInstallProgress();
      const timeoutDuration = 3000; // ms
      setTimeout(() => {
        this.isFinishedInstalling = true;
        const path = _.last(nextProps.StoreTemplateStore.storeTemplateTriggers).path;
        Utilities.navigate(path, true);
        nextProps.StoreTemplateStore.storeTemplateTriggers = [];
      }, timeoutDuration);
    }
    if (nextProps.StoreTemplateStore.workspaces.length === 0 && nextProps.dialogOpen) {
      this.toggleLoadingWorkspaces();
      nextProps.StoreTemplateActions.fetchWorkspaces().then(response => {
        _.map(response.data, workspace => {
          nextProps.StoreTemplateStore.addWorkspace(workspace);
        });
        this.toggleLoadingWorkspaces();
      });
    }
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleLoadingWorkspaces() {
    this.setState({
      isLoadingWorkspaces: !this.state.isLoadingWorkspaces,
    });
  }

  toggleLoadingWorkspaceFolders() {
    this.setState({
      isLoadingWorkspaceFolders: !this.state.isLoadingWorkspaceFolders,
    });
  }

  handleWorkspaceChange(e) {
    this.setState({ selectedWorkspaceId: e.target.value });
    this.props.StoreTemplateStore.workspaceFolders = [];
    if (e.target.value.length > 0) {
      const findWorkspace = _.find(this.props.StoreTemplateStore.workspaces, o => o.id === e.target.value);
      this.toggleLoadingWorkspaceFolders();
      this.props.StoreTemplateActions.fetchWorkspaceFolders(findWorkspace.id).then(response => {
        _.map(response.data, workspaceFolder => {
          this.props.StoreTemplateStore.addWorkspaceFolder(workspaceFolder);
        });
        this.toggleLoadingWorkspaceFolders();
      });
    }
  }

  handleWorkspaceFolderChange(e) {
    this.setState({ selectedWorkspaceFolderId: e.target.value });
  }

  handleInstallStoreTemplate() {
    this.toggleLoading();
    this.startInstallProgress();
    const installObj = {
      id: this.props.storeTemplate.id,
    };
    if (this.props.storeTemplate.templatableObjectType === 'App') {
      installObj.workspace_id = this.state.selectedWorkspaceId;
      installObj.workspace_folder_id = this.state.selectedWorkspaceFolderId;
    }
    this.props.StoreTemplateActions.installStoreTemplate(installObj).catch(() => {
      this.finishInstallProgress();
      this.props.toggleOpen();
    });
  }

  processInstallProgress() {
    const oneHundredPercent = 100;
    const ninetyNinePercent = 99;
    if (this.installProgress < oneHundredPercent) {
      const newProgress = Math.floor(Math.random() * (ninetyNinePercent - this.installProgress + 1)) + this.installProgress;
      this.installProgress = newProgress;
    }
  }

  startInstallProgress() {
    this.resetProgress();
    const timeoutDuration = 1000; // ms
    this.interval = setInterval(() => {
      this.processInstallProgress();
    }, timeoutDuration);
  }

  resetProgress() {
    this.installProgress = 0;
  }

  finishInstallProgress() {
    clearInterval(this.interval);
    this.installProgress = 100;
  }

  handleCancel() {
    this.props.toggleOpen();
  }

  renderWorkspaceFolderSelect() {
    if (this.state.isLoadingWorkspaceFolders) {
      return null;
    }
    if (this.props.StoreTemplateStore.workspaceFolders.length > 0) {
      return (
        <FormGroup labelInfo="(required)" label={I18n.t('js.select_workspace_folder')}>
          <Flexbox className="bp3-fill">
            <HTMLSelect name="workspaceFolderSelect" autoFocus onChange={this.handleWorkspaceFolderChange.bind(this)}>
              <option value="" defaultValue>
                {I18n.t('js.select_workspace_folder')}
              </option>
              {_.map(this.props.StoreTemplateStore.workspaceFolders, workspaceFolder => (
                <option key={workspaceFolder.id} value={workspaceFolder.id}>
                  {workspaceFolder.name}
                </option>
              ))}
            </HTMLSelect>
          </Flexbox>
        </FormGroup>
      );
    }
    return undefined;
  }

  renderInstallBody() {
    if (this.props.storeTemplate.templatableObjectType === 'App') {
      if (this.state.isLoadingWorkspaces) {
        return null;
      }
      if (this.props.StoreTemplateStore.workspaces.length === 0) {
        return (
          <Flexbox flexDirection="column" row={1} marginTop="20px">
            <div className="bp3-callout bp3-intent-danger bp3-icon-warning-sign">
              <h4 className={`${Classes.HEADING} bp3-callout-title`}>{I18n.t('js.no_workspaces_available')}</h4>
              {I18n.t('js.no_workspaces_available_text')}
            </div>
          </Flexbox>
        );
      }
      return (
        <Flexbox flexDirection="column" row={1} marginTop="20px">
          <FormGroup labelInfo="required)" label={I18n.t('js.select_workspace')}>
            <Flexbox className="bp3-fill">
              <HTMLSelect name="workspaceSelect" onChange={this.handleWorkspaceChange.bind(this)}>
                <option value="" defaultValue>
                  {I18n.t('js.select_workspace')}
                </option>
                {_.map(this.props.StoreTemplateStore.workspaces, workspace => (
                  <option key={workspace.id} value={workspace.id}>
                    {workspace.name}
                  </option>
                ))}
              </HTMLSelect>
            </Flexbox>
          </FormGroup>
          {this.renderWorkspaceFolderSelect()}
        </Flexbox>
      );
    }
    if (this.props.storeTemplate.templatableObjectType === 'ProfileTemplate' && !this.props.CurrentUserStore.userIsAccountOwner) {
      return (
        <Flexbox flexDirection="column" row={1} marginTop="20px">
          <div className="bp3-callout bp3-intent-danger bp3-icon-warning-sign">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>{I18n.t('js.account_ownership_required')}</h4>
            {I18n.t('js.profile_templates_can_only_be_installed_by_account_owners')}
          </div>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    if (this.state.isLoading) {
      if (!this.isFinishedInstalling) {
        return (
          <Dialog
            portalContainer={document.body}
            icon="cloud-download"
            isOpen={this.props.dialogOpen}
            canOutsideClickClose={false}
            onClose={this.props.toggleOpen.bind(this)}
            title={I18n.t('js.installing_template')}
          >
            <Flexbox className="bp3-dialog-body" flexDirection="column" flexGrow={1}>
              <Flexbox className="bp3-progress-bar bp3-intent-success">
                <Flexbox className="bp3-progress-meter" style={{ width: `${this.installProgress}%` }} />
              </Flexbox>
            </Flexbox>
          </Dialog>
        );
      }
      return (
        <Dialog
          portalContainer={document.body}
          icon="cloud-download"
          isOpen={this.props.dialogOpen}
          canOutsideClickClose={false}
          onClose={this.props.toggleOpen.bind(this)}
          title={I18n.t('js.installing_template')}
        >
          <Flexbox className="bp3-dialog-body" flexDirection="column" flexGrow={1} justifyContent="center" alignItems="center">
            <Icon icon="endorsed" iconSize={50} className="bp3-intent-success animated bounceIn" />
          </Flexbox>
        </Dialog>
      );
    }
    return (
      <Dialog
        portalContainer={document.body}
        icon="cloud-download"
        isOpen={this.props.dialogOpen}
        canOutsideClickClose={false}
        onClose={this.props.toggleOpen.bind(this)}
        title={I18n.t('js.install_template')}
      >
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox className="bp3-dialog-body" flexDirection="column" flexGrow={1}>
            <Flexbox>{I18n.t('js.install_template_description')}</Flexbox>
            {this.renderInstallBody()}
          </Flexbox>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={() => this.handleCancel()} />
              <Button
                intent={Intent.SUCCESS}
                loading={this.state.isLoading}
                onClick={this.handleInstallStoreTemplate.bind(this)}
                disabled={!this.installReady}
                text={I18n.t('js.install_template')}
              />
            </div>
          </div>
        </Flexbox>
      </Dialog>
    );
  }
}
