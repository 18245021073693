import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Icon } from '@blueprintjs/core';

@inject('CurrentUserStore', 'ToastStore')
@observer
export default class TemplateWarning extends React.Component {
  render() {
    return (
      <Flexbox
        className="template-warning-bg"
        justifyContent="center"
        alignItems="center"
      ></Flexbox>
    );
  }
}
