/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { AnchorButton, Classes, Collapse, FormGroup, Intent, MenuItem, Switch } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import ElementGridColumnOptions from './ElementGridColumnOptions';
import ElementGridRowOptions from './ElementGridRowOptions';
import ElementGridDisplay from './ElementGridDisplay';
import ElementTag from '../../shared/ElementTag';
import ElementIdSelector from '../../shared/ElementIdSelector';
import GridDefinitions from '../../../static/GridDefinitions';
import classNames from 'classnames';
import _ from 'lodash';

const DisplayGrid = Select.ofType(GridDefinitions.displayTypes);

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementGrid extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      is_required: this.store.activeElement.isRequired,
    };
    const elementGridObj = {
      id: this.store.activeElement.elementGrid.id,
      name: this.store.activeElement.elementGrid.name,
      display_type: this.store.activeElement.elementGrid.displayType,
    };
    this.handleColumnSubmission()
      .then(() => {
        this.handleRowSubmission()
          .then(() => {
            this.props.TemplateActions.changeSubElement(elementGridObj, 'element_grids')
              .then(() => {
                this.props.TemplateActions.changeElement(elementObj)
                  .then(() => {
                    this.store.setActiveArea(null);
                    this.toggleLoading();
                  })
                  .catch(() => {
                    this.toggleLoading();
                  });
              })
              .catch(() => {
                this.toggleLoading();
              });
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleColumnSubmission() {
    return new Promise((resolve, reject) => {
      const columnsToAdd = [];
      const columnsToDelete = [];
      const columnsToChange = [];

      _.map(this.store.activeElement.elementGrid.elementGridColumns, column => {
        if (column.added) {
          columnsToAdd.push(column);
        } else {
          if (column.changed) {
            columnsToChange.push(column);
          }
        }
      });
      _.map(this.props.element.elementGrid.elementGridColumns, column => {
        const findOption = _.find(this.store.activeElement.elementGrid.elementGridColumns, o => o.id === column.id);
        if (!findOption) {
          columnsToDelete.push(column);
        }
      });

      let totalLength = columnsToAdd.length + columnsToChange.length + columnsToDelete.length;
      if (totalLength === 0) {
        resolve();
      }

      _.map(columnsToAdd, column => {
        const elementGridColumnObj = {
          element_grid_id: column.elementGridId,
          name: column.name,
          number_value: column.numberValue,
          row_order_position: column.rowOrder,
        };
        this.props.TemplateActions.createElementGridColumn(elementGridColumnObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(columnsToChange, column => {
        const elementGridColumnObj = {
          id: column.id,
          name: column.name,
          number_value: column.numberValue,
        };
        if (column.rowOrderChanged) {
          elementGridColumnObj.row_order_position = column.rowOrder;
        }
        this.props.TemplateActions.changeElementGridColumn(elementGridColumnObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(columnsToDelete, column => {
        this.props.TemplateActions.deleteElementGridColumn(column.id)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(() => {
            // lazy fix for legacy code
            // eslint-disable-next-line
            reject();
          });
      });
    });
  }

  handleRowSubmission() {
    return new Promise((resolve, reject) => {
      const rowsToAdd = [];
      const rowsToDelete = [];
      const rowsToChange = [];

      _.map(this.store.activeElement.elementGrid.elementGridRows, row => {
        if (row.added) {
          rowsToAdd.push(row);
        } else {
          if (row.changed) {
            rowsToChange.push(row);
          }
        }
      });
      _.map(this.props.element.elementGrid.elementGridRows, row => {
        const findOption = _.find(this.store.activeElement.elementGrid.elementGridRows, o => o.id === row.id);
        if (!findOption) {
          rowsToDelete.push(row);
        }
      });

      let totalLength = rowsToAdd.length + rowsToChange.length + rowsToDelete.length;
      if (totalLength === 0) {
        resolve();
      }

      _.map(rowsToAdd, row => {
        const elementGridRowObj = {
          element_grid_id: row.elementGridId,
          name: row.name,
          row_order_position: row.rowOrder,
        };
        this.props.TemplateActions.createElementGridRow(elementGridRowObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(rowsToChange, row => {
        const elementGridRowObj = {
          id: row.id,
          name: row.name,
        };
        if (row.rowOrderChanged) {
          elementGridRowObj.row_order_position = row.rowOrder;
        }
        this.props.TemplateActions.changeElementGridRow(elementGridRowObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(rowsToDelete, row => {
        this.props.TemplateActions.deleteElementGridRow(row.id)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });
    });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleDisplayTypeSelect(type) {
    this.store.updateActiveElement(type.name, 'displayType');
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderOption(item, details) {
    const activeDisplayType = _.find(GridDefinitions.displayTypes, o => o.name === this.store.activeElement.elementGrid.displayType);
    let menuClass = classNames('popover-larger');
    if (item.text === activeDisplayType.text) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        icon={item.icon}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox />{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.text}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementGrid[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementGrid[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderInputValue(option) {
    return option.text;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      const activeDisplayType = _.find(GridDefinitions.displayTypes, o => o.name === this.store.activeElement.elementGrid.displayType);
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-grid push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.grid_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementGrid.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementGrid.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={this.store.activeElement.elementGrid.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox className="bp3-form-group" flexDirection="column" flexGrow={1} flexBasis="1" paddingLeft="20px">
                <label className="bp3-label" htmlFor="optionType">
                  {I18n.t('js.grid_element_display_type')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <Flexbox>
                    <DisplayGrid
                      resetOnGrid={true}
                      filterable={false}
                      items={GridDefinitions.displayTypes}
                      itemRenderer={this.renderOption.bind(this)}
                      onItemSelect={this.handleDisplayTypeSelect.bind(this)}
                      inputValueRenderer={this.renderInputValue.bind(this)}
                      inputProps={{
                        rightElement: this.renderDropDown(),
                      }}
                      popoverProps={{
                        usePortal: false,
                        position: 'bottom-left',
                        className: '',
                        popoverClassName: 'bp3-minimal',
                      }}
                    >
                      <AnchorButton text={activeDisplayType.text} icon={activeDisplayType.icon} rightIcon="caret-down" />
                    </DisplayGrid>
                  </Flexbox>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <ElementGridColumnOptions sectionType={this.props.sectionType} />
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <ElementGridRowOptions sectionType={this.props.sectionType} />
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.responses_are_required')}
                onChange={this.handleResponseRequiredChange.bind(this)}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementGrid.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
        >
          <ElementGridDisplay element={this.store.activeElement} disabled={!this.props.element.stateElement.editable} />
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementGrid.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
      >
        <ElementGridDisplay element={this.props.element} disabled={!this.props.element.stateElement.editable} />
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
