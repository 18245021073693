import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormGroup, Intent } from '@blueprintjs/core';
import LocationSelect from '../shared/LocationSelect';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('ItemStore', 'ItemActions', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions')
@observer
export default class FormElementLocationSelect extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  inputValue = '';
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      if (this.props.element.formValues[0].stringValue.length > 0) {
        this.inputValue = JSON.parse(this.props.element.formValues[0].stringValue);
      }
    }
    this.props.validateInput(this.inputValue);
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_id === this.props.element.id) {
        this.inputValue = JSON.parse(formValue.string_value);
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        this.inputValue = JSON.parse(findFormValueToChange.string_value);
      }
    });
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'string';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'string';
        return object;
    }
  }

  handleDragEnd(coordinates) {
    this.inputValue = coordinates;
    if (this.actionType === 'updating') {
      const changedFormValue = {
        id: this.props.element.formValues[0].id,
        string_value: JSON.stringify(this.inputValue),
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      let newFormValue = {
        id: Utilities.makeId(),
        element_id: this.props.element.id,
        string_value: JSON.stringify(this.inputValue),
      };
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
    }
    this.props.validateInput(this.inputValue);
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    if (this.props.noTitle) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <LocationSelect
            handleDragEnd={this.handleDragEnd.bind(this)}
            selectedLocation={this.inputValue}
            isDraggable={this.props.element.isEditable}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          className={this.props.element.isEditable ? '' : 'bp3-display-label'}
          label={this.props.element.elementLocationSelect.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          <LocationSelect
            handleDragEnd={this.handleDragEnd.bind(this)}
            selectedLocation={this.inputValue}
            isDraggable={this.props.element.isEditable}
          />
        </FormGroup>
      </Flexbox>
    );
  }
}
