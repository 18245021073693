/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import CurrencyDefinitions from '../../static/CurrencyDefinitions';
import { FormGroup, Intent, Slider } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementSlider extends React.Component {
  @observable store = '';
  @observable actions = '';
  @observable inputValue = 0;
  @observable actionType = 'creating';
  @observable newId = Utilities.makeId();

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      let value = parseInt(this.props.element.formValues[0].numberValue);
      if (!value > 0 || value < this.props.element.elementSlider.minValue) {
        value = this.props.element.elementSlider.minValue;
      }
      if (value > this.props.element.elementSlider.maxValue) {
        value = this.props.element.elementSlider.maxValue;
      }
      this.inputValue = _.clone(value);
      this.props.validateInput(this.inputValue);
    } else {
      this.inputValue = this.props.element.elementSlider.minValue;
      this.props.validateInput('');
    }
    this.mountDisplayValuesInFlight();
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_id === this.props.element.id) {
        this.inputValue = formValue.number_value;
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        this.inputValue = findFormValueToChange.number_value;
      }
    });
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'number';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'number';
        return object;
    }
  }

  handleValueChange(n) {
    this.inputValue = n;
    if (this.actionType === 'updating') {
      const changedFormValue = {
        id: this.props.element.formValues[0].id,
        number_value: this.inputValue,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      let newFormValue = {
        id: this.newId,
        element_id: this.props.element.id,
        number_value: this.inputValue,
      };
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
    }
    this.props.validateInput(this.inputValue);
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  labelRenderer(n, element) {
    if (element.elementSlider.labelType === 'currency') {
      const findCurrency = _.find(CurrencyDefinitions.currencies, o => o.code === element.elementSlider.labelCurrencyType);
      const t = n.toString().replace(/\B(?=(\d{3})+(?!\d))/gu, ',');
      if (findCurrency) {
        return `${findCurrency.symbol_native}${t}`;
      }
      return `${t}`;
    }
    if (element.elementSlider.labelType === 'percentage') {
      return `${n}%`;
    }
    return n;
  }

  render() {
    if (this.props.noTitle) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox
            flexGrow={1}
            paddingLeft={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            paddingRight={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            marginTop="10px"
          >
            <Slider
              disabled={!this.props.element.isEditable}
              labelStepSize={parseInt(this.props.element.elementSlider.stepSize)}
              min={parseInt(this.props.element.elementSlider.minValue)}
              max={parseInt(this.props.element.elementSlider.maxValue)}
              onChange={n => {
                this.handleValueChange(n);
              }}
              value={this.inputValue}
              vertical={this.props.element.elementSlider.vertical}
              labelRenderer={n => this.labelRenderer(n, this.props.element)}
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          className={this.props.element.isEditable ? '' : 'bp3-display-label'}
          label={this.props.element.elementSlider.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          <Flexbox
            flexGrow={1}
            paddingLeft={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            paddingRight={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            marginTop="10px"
          >
            <Slider
              disabled={!this.props.element.isEditable}
              labelStepSize={parseInt(this.props.element.elementSlider.stepSize)}
              min={parseInt(this.props.element.elementSlider.minValue)}
              max={parseInt(this.props.element.elementSlider.maxValue)}
              onChange={n => {
                this.handleValueChange(n);
              }}
              value={this.inputValue}
              vertical={this.props.element.elementSlider.vertical}
              labelRenderer={n => this.labelRenderer(n, this.props.element)}
            />
          </Flexbox>
        </FormGroup>
      </Flexbox>
    );
  }
}
