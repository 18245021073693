import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Dialog, Intent } from '@blueprintjs/core';
import NewStoreTemplate from '../store_templates/NewStoreTemplate';

@inject('WorkspaceStore')
@observer
export default class WorkspacePublishWorkspace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isPublishing: false,
      confirmedInput: '',
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleStoreTemplate() {
    this.setState({
      isPublishing: !this.state.isPublishing,
    });
  }

  handleCreateStoreTemplate() {
    this.toggleLoading();
  }

  handleInputChange(e) {
    this.setState({ confirmedInput: e.target.value });
  }

  handleCancel() {
    this.toggleOpen();
  }

  renderStoreTemplateDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="social-media"
        isOpen={this.state.isPublishing}
        onClose={this.toggleStoreTemplate.bind(this)}
        title={I18n.t('js.publish_a_copy_of_this_workspace')}
      >
        <Flexbox className="bp3-dialog-body" />
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button text={I18n.t('js.cancel')} onClick={() => this.toggleStoreTemplate()} />
            <Button
              intent={Intent.DANGER}
              loading={this.state.isSubmitting}
              onClick={this.handleCreateStoreTemplate.bind(this)}
              text={I18n.t('js.create_store_template')}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.publish_to_store_categories')}
            </h4>
            <p className="noselect">{I18n.t('js.publish_workspace_to_store_categories_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <div className="bp3-callout bp3-intent-success bp3-icon-info-sign">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>{I18n.t('js.what_is_a_store_template')}</h4>
            {I18n.t('js.what_is_a_store_template_workspace_details')}
          </div>
          <Flexbox marginTop="20px">
            <Button
              text={I18n.t('js.publish_a_copy_of_this_workspace')}
              className="bp3-intent-success"
              icon="social-media"
              onClick={this.toggleStoreTemplate.bind(this)}
            />
            <NewStoreTemplate
              dialogOpen={this.state.isPublishing}
              toggleOpen={this.toggleStoreTemplate.bind(this)}
              templatableObjectType="Workspace"
              templatableObject={this.props.WorkspaceStore.activeWorkspace}
            />
          </Flexbox>
        </Collapse>
      </Flexbox>
    );
  }
}
