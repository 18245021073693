import { createClient } from 'contentful';
import Cookies from 'js-cookie';

const ContentfulClient = createClient({
  space: Cookies.get('contentfulSpaceId'),
  accessToken: Cookies.get('contentfulCdaKey'),
  environment: Cookies.get('contentFulEnvironment'),
});

export default ContentfulClient;
