/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  AnchorButton,
  Button,
  Classes,
  EditableText,
  HTMLSelect,
  Intent,
  MenuDivider,
  MenuItem,
  Tooltip,
  Switch,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import Relationship from '../../models/RelationshipModel';
import TemplateManageParticipantRowUserSelect from './TemplateManageParticipantsRowUserSelect';
import classNames from 'classnames';
import _ from 'lodash';

const RelationshipSelect = Select.ofType(Relationship);

@inject('TemplateStore', 'TemplateActions')
@observer
export default class TemplateManageParticipantRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDeleting: false, isLoading: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting,
    });
  }

  handleChange(value) {
    this.props.participant.name = value;
  }

  handleVisibilityChange(e) {
    e.preventDefault();
    this.props.participant.visibilityLevel = e.target.value;
    this.handleConfirm();
  }

  deleteParticipant() {
    this.props.TemplateActions.deleteParticipant(this.props.participant.id)
      .then(this.toggledeleting)
      .catch(this.toggledeleting);
  }

  handleConfirm() {
    const participantObj = {
      id: this.props.participant.id,
      name: this.props.participant.name,
      visibility_level: this.props.participant.visibilityLevel,
    };
    this.props.TemplateActions.changeParticipant(participantObj);
  }

  renderInputValue(relationship) {
    return relationship.name;
  }

  handleUserSelect(user) {
    this.toggleLoading();
    const participantObj = {
      id: this.props.participant.id,
      user_id: user.id,
    };
    this.props.TemplateActions.changeParticipant(participantObj)
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleIsOwnerChange() {
    const participantObj = {
      id: this.props.participant.id,
      is_owner: !this.props.participant.isOwner,
    };
    this.props.TemplateActions.changeParticipant(participantObj);
  }

  handleItemSelect(relationship) {
    this.toggleLoading();
    const participantObj = {
      id: this.props.participant.id,
      relationship_id: relationship.id,
    };
    if (relationship.name === I18n.t('js.reset')) {
      participantObj.relationship_id = null;
    }
    this.props.TemplateActions.changeParticipant(participantObj)
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  removeDefaultUser() {
    const participantObj = {
      id: this.props.participant.id,
      user_id: null,
    };
    this.props.TemplateActions.changeParticipant(participantObj);
  }

  removeDefaultRelationship() {
    const participantObj = {
      id: this.props.participant.id,
      relationship_id: null,
    };
    this.props.TemplateActions.changeParticipant(participantObj);
  }

  renderRemoveAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_participant')}
        onConfirm={this.deleteParticipant.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_participant', { name: this.props.participant.name })}
      </Alert>
    );
  }

  renderRelationship(item, details) {
    if (item.name === I18n.t('js.reset')) {
      return (
        <span key={item.id}>
          <MenuDivider />
          <MenuItem onClick={details.handleClick} text={I18n.t('js.reset')} icon="trash" />
        </span>
      );
    }
    let menuClass = classNames('popover-larger');
    if (item.name === this.props.TemplateStore.editParticipant.name) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }
    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={item.name} />;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderRelationshipText() {
    if (typeof this.props.participant.relationship === 'object') {
      return _.truncate(this.props.participant.relationship.name, { length: 18 });
    }
    return I18n.t('js.none');
  }

  renderDefaultRelationshipSelect() {
    if (this.props.TemplateStore.relationships.length <= 1) {
      return <span className="bp3-text-muted disabled">{I18n.t('js.na_no_relationships_available')}</span>;
    }
    return (
      <RelationshipSelect
        resetOnSelect={true}
        filterable={false}
        items={this.props.TemplateStore.relationships}
        itemRenderer={this.renderRelationship.bind(this)}
        onItemSelect={this.handleItemSelect.bind(this)}
        inputValueRenderer={this.renderInputValue.bind(this)}
        inputProps={{
          rightElement: this.renderDropDown(),
        }}
        popoverProps={{
          usePortal: false,
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
        }}
      >
        <AnchorButton
          text={this.renderRelationshipText()}
          className="bp3-minimal bp3-small"
          rightIcon="caret-down"
          loading={this.state.isLoading}
        />
      </RelationshipSelect>
    );
  }

  renderVisibilitySelect() {
    return (
      <div className="bp3-select">
        <HTMLSelect
          name="visibilityLevel"
          onChange={this.handleVisibilityChange.bind(this)}
          defaultValue={this.props.participant.visibilityLevel}
        >
          <option value={null} disabled={true}>
            {I18n.t('js.select_visibility_level')}
          </option>
          <option value="full">{I18n.t('js.full_visibility')}</option>
          <option value="limited">{I18n.t('js.limited_visibility')}</option>
        </HTMLSelect>
      </div>
    );
  }

  renderUserCancel() {
    if (this.props.participant.userId) {
      return <Button className="bp3-minimal" icon="small-cross" onClick={this.removeDefaultUser.bind(this)} />;
    }
    return undefined;
  }

  render() {
    let disabled = false;
    const findStates = _.find(this.props.TemplateStore.states, o => o.participantId === this.props.participant.id);
    if (findStates) {
      disabled = true;
    }
    return (
      <tr key={this.props.participant.id}>
        <td className="td-width-20">
          <EditableText
            confirmOnEnterKey={true}
            defaultValue={this.props.participant.name}
            intent={Intent.DEFAULT}
            onChange={value => this.handleChange(value)}
            value={this.props.participant.name}
            onConfirm={this.handleConfirm.bind(this)}
            placeholder={I18n.t('js.click_to_edit')}
          />
        </td>
        <td className="td-width-20 pad-10">
          <Switch className="bp3-control-no-margin" checked={this.props.participant.isOwner} onChange={() => this.handleIsOwnerChange()} />
        </td>
        <td className="td-width-20">{this.renderVisibilitySelect()}</td>
        <td className="td-width-20">
          <TemplateManageParticipantRowUserSelect
            handleSelectParticipantUser={this.handleUserSelect.bind(this)}
            selectedUser={this.props.participant.user}
          />
          {this.renderUserCancel()}
        </td>
        <td className="td-width-20">{this.renderDefaultRelationshipSelect()}</td>
        <td className="td-width-10">
          <Tooltip
            portalContainer={document.body}
            content={I18n.t('models.participant.there_are_states_that_depened_on_this_participant')}
            disabled={!disabled}
          >
            <AnchorButton
              className="bp3-small"
              icon="trash"
              text={I18n.t('js.delete')}
              onClick={this.toggleDeleting.bind(this)}
              disabled={disabled}
            />
          </Tooltip>
          {this.renderRemoveAlert()}
        </td>
      </tr>
    );
  }
}
