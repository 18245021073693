import React from 'react';
import Flexbox from 'flexbox-react';
import { Dialog, Button, Intent, HTMLSelect } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import HookTypes from '../../static/HookTypes';
import classNames from 'classnames';

@inject('CurrentUserStore', 'HookStore', 'ToastStore')
@observer
export default class NewHook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isAdding: false,
      isBlurred: [],
    };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleSubmit() {
    this.toggleLoading();
    const hookObj = {
      account_id: this.props.CurrentUserStore.activeAccount.accountId,
      hook_type: this.props.HookStore.newHook.hookType,
      url: this.props.HookStore.newHook.url,
    };
    this.props.HookStore.createHook(hookObj)
      .then(response => {
        this.toggleLoading();
        this.toggleDialog();
        this.props.ToastStore.showToast(I18n.t('js.hook_has_been_added'), 'success');
      })
      .catch(err => {
        this.toggleLoading();
        this.toggleDialog();
      });
  }

  handleInputChange(e) {
    this.props.HookStore.updateNewHook(e.target.value, e.target.name);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.HookStore.newHook[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.HookStore.newHook[`${field}Error`]}</div>;
    }
    return undefined;
  }

  renderDialog() {
    const { newHook } = this.props.HookStore;
    return (
      <Dialog
        portalContainer={document.body}
        icon="add"
        isOpen={this.state.isOpen}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.add_hook')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.new_hook_description')}</p>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'hookType') && !newHook.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="hookType">
                  {I18n.t('js.select_trigger')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'hookType') && !newHook.nameValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <HTMLSelect name="hookType" onChange={this.handleInputChange.bind(this)} defaultValue={newHook.hookType}>
                      {_.map(HookTypes.hookTypes, hookType => (
                        <option key={hookType} value={hookType}>
                          {hookType}
                        </option>
                      ))}
                    </HTMLSelect>
                  </div>
                  {this.renderErrors('hookType')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'url') && !newHook.urlValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="url">
                  {I18n.t('js.url')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'url') && !newHook.urlValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.url_placeholder')}
                      name="url"
                      value={newHook.url}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('url')}
                </div>
              </div>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.props.HookStore.newHook.hookIsValid}
                text={I18n.t('js.create_hook')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row">
          <Button icon="flows" onClick={this.toggleDialog.bind(this)} text={I18n.t('js.add_hook')} />
        </Flexbox>
        {this.renderDialog()}
      </Flexbox>
    );
  }
}
