// @flow
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from '../stores/ToastStore';

const apiEnv = Cookies.get('apiEnv');
const apiEndpoint = `${apiEnv}/report_templates`;

export default class ReportTemplateActions {
  static fetchReportTemplate(reportTemplateId: string) {
    // makes an axios request for a reportTemplate
    // returns a report template object
    return (
      axios
        .get(`${apiEndpoint}/${reportTemplateId}`)
        // ? return response.data?
        .then(response => response.data)
        .catch(this.errorHandler)
    );
  }

  static fetchReportTemplates(appId: string) {
    // fetches all report templates for a given app
    return axios
      .get(`${apiEndpoint}?app_id=${appId}`)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static fetchProfileReportTemplates(profileTemplateId: string) {
    // fetches all report templates for a given profile tempalte
    return axios
      .get(`${apiEndpoint}?profile_template_id=${profileTemplateId}`)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static fetchAllReportTemplates() {
    // fetches all report templates for current account
    return axios
      .get(apiEndpoint)
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  static createReportTemplate(reportData: any) {
    const {
      appId,
      profileTemplateId,
      name,
      description,
    } = reportData;
    axios
      .post(`${apiEndpoint}`, {
        params: {
          app_id: appId,
          profile_template_id: profileTemplateId,
          name,
          description,
        },
      })
      .catch(this.errorHandler);
  }

  /**
   * Makes an axios call to create a report template
   */
  static changeReportTemplate(reportData: any) {
    const {
      appId,
      profileTemplateId,
      name,
      description,
    } = reportData;
    axios
      .put(`${apiEndpoint}`, {
        params: {
          app_id: appId,
          profile_template_id: profileTemplateId,
          name,
          description,
        },
      })
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  /**
   * Makes an axios call to delete a give report template
   */
  static deleteReportTemplate(reportTemplateId: string) {
    axios
      .delete(apiEndpoint, {
        params: { id: reportTemplateId },
      })
      .catch(this.errorHandler);
  }

  static errorHandler(error: any) {
    const errors = error.response.data.error.join(', ');
    ToastStore.showToast(errors, 'danger');
  }
}
