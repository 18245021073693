/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from '../stores/ToastStore';

const apiEnv = Cookies.get('apiEnv');
const apiEndpoint = `${apiEnv}/reports`;

class ReportActions {
  fetchReports(reportTemplateId, xPage, xPerPage, xOffset) {
    // fetches all reports for a given report template id
    return axios
      .get(apiEndpoint, {
        params: {
          report_template_id: reportTemplateId,
          page: xPage,
          per_page: xPerPage,
          offset: xOffset,
        },
      })
      .then(response => response)
      .catch(this.errorHandler);
  }

  fetchReport(reportId) {
    // fetches all reports for a given report template id
    return axios
      .get(`${apiEndpoint}/${reportId}`)
      .then(response => response)
      .catch(this.errorHandler);
  }

  createReport(reportTemplateId) {
    // creates an instance of a report template
    return axios
      .post(apiEndpoint, {
        report_template_id: reportTemplateId,
      })
      .then(response => response.data)
      .catch(this.errorHandler);
  }

  deleteReport(reportId) {
    // creates an instance of a report template
    return axios
      .delete(`${apiEndpoint}/${reportId}`)
      .then(response => response)
      .catch(this.errorHandler);
  }

  errorHandler(error) {
    const errors = error.response.data.error.join(', ');
    ToastStore.showToast(errors, 'danger');
  }
}

const actions = new ReportActions();
export default actions;
