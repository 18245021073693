// @flow
import * as React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import { Intent, Icon } from '@blueprintjs/core';

type ErrorBoundaryProps = {
  children: React.Node,
};

type ErrorBoundaryState = {
  error: any,
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <Flexbox padding="20px" flexGrow={1} flexDirection="column">
          <Flexbox flexGrow={1} className="animated zoomIn">
            <NonIdealState
              icon={<Icon icon="error" intent={Intent.DANGER} iconSize={50} />}
              // $FlowFixMe
              title={I18n.t('js.something_went_wrong')}
              description={I18n.t('js.we_are_on_our_way_to_fix_this')}
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return this.props.children;
  }
}
