/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import AppStore from '../stores/AppStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import CommentStore from '../stores/CommentStore';
import ToastStore from '../stores/ToastStore';
import { NonIdealState, Tooltip, Tag } from '@blueprintjs/core';
import AppsNewApp from '../components/apps/AppsNewApp';
import AppWidget from '../components/apps/AppWidget';
import WorkspaceFolders from '../components/workspaces/WorkspaceFolders';
import CommentsView from './CommentsView';
import Widgets from '../components/widgets/Widgets';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import { arrayMove, sortableContainer, sortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';
import Utilities from '../utils/Utilities';
import _ from 'lodash';

const SortableApp = sortableElement(({ app }) => <AppWidget key={app.id} app={app} />);

const SortableApps = sortableContainer(({ apps }) => (
  <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
    {apps.map(app => (
      <SortableApp key={app.id} index={apps.indexOf(app)} app={app} />
    ))}
  </Flexbox>
));

@observer
export default class AppsView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    AppStore.resetStartingAttributes();
    AppStore.activeWorkspace = this.props.activeWorkspace;
    AppStore.fetchApps(AppStore.activeWorkspace.id)
      .then(this.toggleLoading)
      .catch(this.toggleLoading);
  }

  handleSortEnd(app, newIndex, hasFolders) {
    let appObj = {
      id: app.id,
      row_order_position: newIndex,
    };
    if (hasFolders) {
      appObj = {
        id: app.id,
        row_order_folder_position: newIndex,
      };
    }
    AppStore.reorderApp(appObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    if (WorkspaceStore.hasFolders) {
      // Find the app
      const findApp = AppStore.appsForCurrentWorkspaceInFolder[oldIndex];

      // Reorder the current UI
      const newApps = arrayMove(AppStore.apps.slice(), oldIndex, newIndex);
      let unshownApps = [];
      _.map(AppStore.apps, app => {
        const findInNewApps = _.find(newApps, o => o.id === app.id);
        if (!findInNewApps) {
          unshownApps = unshownApps.concat(app);
        }
      });
      const mergedApps = newApps.concat(unshownApps);
      AppStore.apps = mergedApps;

      // Process reorder on server
      this.handleSortEnd(findApp, newIndex, true);
    } else {
      // Find the app
      const findApp = AppStore.appsForCurrentWorkspace[oldIndex];

      // Reorder the current UI
      const newApps = arrayMove(AppStore.apps.slice(), oldIndex, newIndex);
      AppStore.apps = newApps;

      // Process reorder on server
      this.handleSortEnd(findApp, newIndex, false);
    }
  }

  renderApps() {
    if (AppStore.filteredApps.length > 0) {
      if (WorkspaceStore.activeWorkspace.userPermissionLevel.name === 'owner') {
        return (
          <SortableApps
            apps={AppStore.filteredApps}
            onSortEnd={this.onSortEnd.bind(this)}
            lockToContainerEdges={true}
            useDragHandle={false}
            axis="xy"
            distance={5}
          />
        );
      }
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          {_.map(AppStore.filteredApps, app => (
            <AppWidget key={app.id} app={app} />
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderNoApps() {
    if (AppStore.filteredApps.length === 0) {
      return (
        <Flexbox flexGrow={1} justifyContent="center" alignItems="center" padding="50px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_apps_to_show')}
            icon="applications"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderWorkspaceFolders() {
    if (WorkspaceStore.hasFolders) {
      if (WorkspaceStore.activeFolderId === '') {
        WorkspaceStore.setActiveFolderId(WorkspaceStore.workspaceFolders[0].id);
      }
      return (
        <Flexbox flexDirection="column">
          <WorkspaceFolders />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderWorkspaceComments() {
    if (!WorkspaceStore.activeWorkspace.isStoreTemplate) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" marginTop="20px">
          <CommentsView
            commentable={WorkspaceStore.activeWorkspace}
            canPin={WorkspaceStore.activeWorkspace.userAccessLevel === 'owner'}
            commentableType="Workspace"
            stub={true}
            sortByNew={true}
            showNone={true}
            buttonText={I18n.t('js.comment_on_this_workspace')}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderWorkspaceFolderComments() {
    if (!WorkspaceStore.activeWorkspace.isStoreTemplate) {
      // lazy fix for legacy
      // eslint-disable-next-line no-magic-numbers
      if (WorkspaceStore.activeFolder.id !== 9999) {
        return (
          <Flexbox flexGrow={1} flexDirection="column" marginTop="20px">
            <CommentsView
              commentable={WorkspaceStore.activeFolder}
              canPin={WorkspaceStore.activeWorkspace.userAccessLevel === 'owner'}
              commentableType="WorkspaceFolder"
              stub={true}
              sortByNew={true}
              showNone={true}
              buttonText={I18n.t('js.comment_on_this_workspace_folder')}
            />
          </Flexbox>
        );
      }
    }
    return undefined;
  }

  generateTags() {
    const { activeWorkspace } = AppStore;
    const { isStoreTemplate, status } = activeWorkspace;
    return [
      // permission tag
      <Tooltip portalContainer={document.body} key={0} content={I18n.t('js.your_permission_type_for_this_workspace')} position="right">
        <Tag className="bp3-minimal">{activeWorkspace.userPermissionLevel.text}</Tag>
      </Tooltip>,
      //  draft tag
      status == 'draft' && (
        <Tooltip portalContainer={document.body} key={1} content={I18n.t('js.draft_workspace_text')} position="right">
          <Tag className="bp3-cursor-pointer bp3-minimal push-10-l">{I18n.t('js.draft_workspace')}</Tag>
        </Tooltip>
      ),
      // storeTemplate tag
      isStoreTemplate && (
        <Tooltip
          portalContainer={document.body}
          key={2}
          content={I18n.t('js.store_template_tooltip_text', {
            object: I18n.t('js.workspace').toLowerCase(),
          })}
          position="right"
        >
          <Tag className="bp3-cursor-pointer push-10-l bp3-intent-success">{I18n.t('js.store_template')}</Tag>
        </Tooltip>
      ),
    ];
  }

  renderHeader() {
    const { activeWorkspace } = AppStore;
    let bgStyle = {
      background: `url('${activeWorkspace.imageFileName}')`,
      backgroundSize: 'cover',
      height: '100px',
      width: '100px',
      borderRadius: '100px',
      border: `5px solid ${activeWorkspace.color}`,
    };
    let iconClass = classNames('display-none');
    const darkenAmount = 0.25; // %
    if (activeWorkspace.imageFileName === '') {
      bgStyle = {
        background: `linear-gradient(${activeWorkspace.color}, ${Utilities.darkenHex(activeWorkspace.color, darkenAmount)})`,
        height: '100px',
        width: '100px',
        borderRadius: '100px',
        border: `5px solid ${activeWorkspace.color}`,
      };
      iconClass = classNames('fa fa-2x text-white', activeWorkspace.icon);
    }
    return (
      <Flexbox flexDirection="column" className="bp3-profile-header-row" flexGrow={1} justifyContent="space-between">
        <Flexbox flexDirection="row">
          <Flexbox flexDirection="column">
            <Flexbox
              style={bgStyle}
              className="bp3-cursor-pointer"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              marginRight="20px"
              flexShrink={0}
            >
              <Flexbox className={iconClass} />
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="column" paddingTop="20px">
            <Flexbox flexDirection="row">
              <h2 className="bp3-heading push-10-r">{activeWorkspace.name}</h2>
              <React.Fragment>{this.generateTags()}</React.Fragment>
            </Flexbox>
            <p>{activeWorkspace.description}</p>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }

  renderLayout() {
    const { activeWorkspace } = AppStore;
    if (WorkspaceStore.hasFolders) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1}>
            <Flexbox flexDirection="column" flexGrow={1}>
              {this.renderHeader()}
              <Flexbox className="fixed-width-container" flexDirection="row" flexGrow={1} paddingTop="20px">
                {this.renderWorkspaceFolders()}
                <Flexbox flexGrow={1} flexDirection="column">
                  <Flexbox flexGrow={0}>
                    <AppsNewApp />
                  </Flexbox>
                  <Flexbox marginTop="20px" flexGrow={1} flexDirection="column">
                    {WorkspaceStore.activeFolder.id != 9999 ? (
                      <Widgets
                        editable={activeWorkspace.userPermissionLevel.name === 'owner'}
                        widgetableType="WorkspaceFolder"
                        widgetableId={WorkspaceStore.activeFolder.id}
                      />
                    ) : null}
                    {this.renderNoApps()}
                  </Flexbox>
                  <Flexbox flexDirection="row">{this.renderApps()}</Flexbox>
                  {this.renderWorkspaceFolderComments()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderHeader()}
        <Flexbox className="fixed-width-container" flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} marginTop="20px">
            <AppsNewApp />
          </Flexbox>
          <Flexbox marginTop="20px" flexGrow={1}>
            {this.renderWorkspaceFolders()}
            <Flexbox flexDirection="column" flexGrow={1}>
              <Widgets
                editable={AppStore.activeWorkspace.userPermissionLevel.name === 'owner'}
                widgetableType="Workspace"
                widgetableId={AppStore.activeWorkspace.id}
              />
              {this.renderNoApps()}
              <Flexbox flexDirection="row">{this.renderApps()}</Flexbox>
            </Flexbox>
          </Flexbox>
          {this.renderWorkspaceComments()}
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    const stores = {
      AppStore,
      WorkspaceStore,
      CurrentUserStore,
      CommentStore,
      ToastStore,
    };
    if (CurrentUserStore.isLoading || this.isLoading) {
      return <Flexbox flexDirection="column"></Flexbox>;
    }
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox flexGrow={1} flexDirection="column">
            {this.renderLayout()}
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
