import React from 'react';
import _ from 'lodash';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ReportStore from '../stores/ReportStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ReportActions from '../actions/ReportActions';
import ReportTemplateActions from '../actions/ReportTemplateActions';
import ReportSelector from '../components/reports/ReportSelector';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class ReportsView extends React.Component {
  @observable
  isLoading = true;

  componentDidMount() {
    ReportStore.resetStartingAttributes();
    ReportTemplateActions.fetchAllReportTemplates()
      .then(response => {
        _.map(response, reportTemplate => {
          ReportStore.addReportTemplate(reportTemplate);
        });
        if (this.props.reportTemplateId) {
          ReportStore.setActiveReportTemplate(this.props.reportTemplateId);
          ReportStore.reports = [];
          const appNum = 1,
            perPage = 25,
            offset = 0;
          const { reportTemplateId } = this.props;
          ReportActions.fetchReports(reportTemplateId, appNum, perPage, offset).then(newResponse => {
            ReportStore.createPaginator(newResponse.headers);
            _.map(newResponse.data, report => {
              ReportStore.addReport(report);
            });
          });
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  renderContent() {
    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (ReportStore.reportTemplates.length === 0) {
      return (
        <Flexbox flexGrow={1} paddingTop="50px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_report_templates_to_show')}
            icon="chart"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return <ReportSelector runReport={this.props.runReport} />;
  }

  render() {
    const stores = {
      ReportStore,
      ReportActions,
      ReportTemplateActions,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <Flexbox marginBottom="10px">
                  <h2 className="bp3-heading">{I18n.t('js.reports')}</h2>
                </Flexbox>
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
