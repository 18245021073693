import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, HTMLSelect, Button, Popover, Classes, Intent, Slider } from '@blueprintjs/core';
import ColorSelector from '../../shared/ColorSelector';
import Utilities from '../../../utils/Utilities';
import Flexbox from 'flexbox-react';
import { NonIdealState } from '@blueprintjs/core';
import nanoid from 'nanoid';

@inject('AppStore', 'DataViewStore', 'ToastStore')
@observer
export default class ChartPieConfiguration extends React.Component {
  handleAttrChange(e) {
    const value = e.target.value;
    const attribute = e.target.name;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attribute] = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleToggleBool(attr) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attr] = !newDataConfig[attr];
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleChangeColor(value, colorOption) {
    colorOption.color = value;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.availableColors = _.merge(newDataConfig.availableColors, colorOption);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleCutoutChange(value) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.cutoutPercentage = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  render() {
    const { activeChart, availableElements } = this.props.DataViewStore;
    const { availableColors } = activeChart.dataConfig;
    const filteredColors = _.filter(
      availableColors,
      o => o.elementId === activeChart.dataConfig.elementId && o.label != null && o.color != null
    );
    return (
      <Flexbox flexDirection="column" marginTop="20px">
        <Flexbox flexDirection="column" marginRight="20px">
          <label className="bp3-label" htmlFor="elementId">
            {I18n.t('js.select_element_condition')}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <div className="bp3-form-content">
            <div className="bp3-input-group">
              <HTMLSelect
                className="push-10-r"
                name="elementId"
                onChange={this.handleAttrChange.bind(this)}
                value={activeChart.dataConfig.elementId}
              >
                {_.map(availableElements, element => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.name}
                    </option>
                  );
                })}
              </HTMLSelect>
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="column" marginTop="20px">
          <Switch
            className="bp3-control-no-margin"
            checked={activeChart.dataConfig.includeNullValues}
            label={I18n.t('js.include_null_values')}
            onChange={() => this.handleToggleBool('includeNullValues')}
          />
        </Flexbox>
        {activeChart.type === 'pie' ? (
          <Flexbox flexDirection="column" marginTop="20px">
            <label className="bp3-label" htmlFor="elementId">
              {I18n.t('js.cutout_percentage')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <Flexbox className="bp3-form-content" paddingLeft="10px" paddingRight="20px" marginTop="20px">
              <Slider
                value={activeChart.dataConfig.cutoutPercentage}
                labelStepSize={10}
                min={0}
                max={100}
                onChange={this.handleCutoutChange.bind(this)}
              />
            </Flexbox>
          </Flexbox>
        ) : null}
        {filteredColors.length > 0 ? (
          <Flexbox flexDirection="column">
            <h3>{I18n.t('js.colors')}</h3>
            {_.map(filteredColors, colorOption => {
              return (
                <Flexbox flexDirection="row" flexGrow={0} key={Math.random()} marginTop="10px">
                  <Popover
                    portalContainer={document.body}
                    content={
                      <Flexbox flexDirection="column" width="760px" padding="20px">
                        <h5 className={Classes.HEADING}>{I18n.t('js.select_color')}</h5>
                        <ColorSelector
                          activeColor={colorOption.color}
                          handleChangeColor={value => this.handleChangeColor(value, colorOption)}
                        />
                      </Flexbox>
                    }
                    position="top"
                  >
                    <Button>
                      <span
                        className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg"
                        style={{
                          color: colorOption.color,
                        }}
                      />
                      <span
                        className="bp3-cursor-pointer"
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        {colorOption.label}
                      </span>
                    </Button>
                  </Popover>
                </Flexbox>
              );
            })}
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }
}
