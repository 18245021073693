/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';
import _ from 'lodash';
import ToastStore from './ToastStore';
import WorkspaceStore from './WorkspaceStore';
import App from '../models/AppModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Workspace from '../models/WorkspaceModel';
import RelationshipPermission from '../models/RelationshipPermissionModel';
import Relationship from '../models/RelationshipModel';

class AppStore {
  @observable newApp = new App();
  @observable activeApp = new App();
  @observable activeWorkspace = new Workspace();
  @observable firstParticipant = '';
  @observable activeTab = 'items';
  @observable activeFilter = 'published';
  @observable appColumns = ColumnDefinitions.appColumns;
  @observable apps = [];
  @observable appsForCurrentWorkspace = [];
  @observable relationshipPermissions = [];
  @observable userRelationships = [];

  @computed
  get draftApps() {
    let draftApps = _.filter(this.apps, app => {
      if (WorkspaceStore.activeFolderId !== '') {
        // lazy fix for legacy code
        // eslint-disable-next-line no-magic-numbers
        if (WorkspaceStore.activeFolderId === 9999) {
          return app.status === 'draft' && app.userPermissionLevel.name !== 'access_denied' && app.workspaceFolderId === '';
        }
        return (
          app.status === 'draft' &&
          app.userPermissionLevel.name !== 'access_denied' &&
          app.workspaceFolderId === WorkspaceStore.activeFolderId
        );
      }
      return app.status === 'draft' && app.userPermissionLevel.name !== 'access_denied';
    });
    draftApps = _.filter(draftApps, o => o.workspaceId === WorkspaceStore.activeWorkspace.id);
    return draftApps;
  }

  @computed
  get publishedApps() {
    let publishedApps = _.filter(this.apps, app => {
      if (WorkspaceStore.activeFolderId !== '') {
        // lazy fix for legacy code
        // eslint-disable-next-line no-magic-numbers
        if (WorkspaceStore.activeFolderId === 9999) {
          return app.status === 'published' && app.userPermissionLevel.name !== 'access_denied' && app.workspaceFolderId === '';
        }
        return (
          app.status === 'published' &&
          app.userPermissionLevel.name !== 'access_denied' &&
          app.workspaceFolderId === WorkspaceStore.activeFolderId
        );
      }
      return app.status === 'published' && app.userPermissionLevel.name !== 'access_denied';
    });
    publishedApps = _.filter(publishedApps, o => o.workspaceId === WorkspaceStore.activeWorkspace.id);
    return publishedApps;
  }

  @computed
  get restrictedApps() {
    let restrictedApps = _.filter(this.apps, app => {
      if (WorkspaceStore.activeFolderId !== '') {
        // lazy fix for legacy code
        // eslint-disable-next-line no-magic-numbers
        if (WorkspaceStore.activeFolderId === 9999) {
          return app.userPermissionLevel.name === 'access_denied' && app.workspaceFolderId === '';
        }
        return app.userPermissionLevel.name === 'access_denied' && app.workspaceFolderId === WorkspaceStore.activeFolderId;
      }
      return app.userPermissionLevel.name === 'access_denied';
    });
    restrictedApps = _.filter(restrictedApps, o => o.workspaceId === WorkspaceStore.activeWorkspace.id);
    return restrictedApps;
  }

  @computed
  get allPublishedApps() {
    let publishedApps = _.filter(this.apps, app => {
      return app.status === 'published' && app.userPermissionLevel.name !== 'access_denied';
    });
    return publishedApps;
  }

  @computed
  get filteredApps() {
    if (this.activeFilter === 'published') {
      return this.publishedApps;
    } else if (this.activeFilter === 'draft') {
      return this.draftApps;
    } else if (this.activeFilter === 'restricted') {
      return this.restrictedApps;
    }
    if (WorkspaceStore.activeFolderId !== '') {
      // lazy fix for legacy code
      // eslint-disable-next-line no-magic-numbers
      if (WorkspaceStore.activeFolderId === 9999) {
        let apps = _.filter(this.apps, o => o.workspaceFolderId === '');
        apps = _.orderBy(apps, ['rowOrder'], ['asc']);
        apps = _.filter(apps, o => o.workspaceId === WorkspaceStore.activeWorkspace.id);
        return apps;
      }
      let apps = _.filter(this.apps, o => o.workspaceFolderId === WorkspaceStore.activeFolderId);
      apps = _.orderBy(apps, ['rowOrder'], ['asc']);
      apps = _.filter(apps, o => o.workspaceId === WorkspaceStore.activeWorkspace.id);
      return apps;
    }
    return _.filter(this.apps, o => o.workspaceId === WorkspaceStore.activeWorkspace.id);
  }

  @computed
  get quickCreateApps() {
    let apps = [];
    _.map(this.apps, app => {
      if (app.includeInQuickCreateMenu) {
        if (
          app.status === 'published' &&
          app.userPermissionLevel.name !== 'access_denied' &&
          app.userPermissionLevel.name !== 'view_only'
        ) {
          apps.push(app);
        }
      }
    });
    apps = _.orderBy(apps, ['name'], ['asc']);
    return apps;
  }

  @computed
  get appsForCurrentWorkspaceInFolder() {
    return _.filter(this.appsForCurrentWorkspace, o => o.workspaceFolderId === WorkspaceStore.activeFolder.id);
  }

  @computed
  get hasUncategorizedApps() {
    const uncategorizedApps = _.find(this.apps, o => o.workspaceFolderId === '' && o.workspaceId === WorkspaceStore.activeWorkspace.id);
    if (!uncategorizedApps) {
      return false;
    }
    if (typeof uncategorizedApps === 'object' || uncategorizedApps.length > 0) {
      return true;
    }
    return false;
  }

  @computed
  get newAppFormValid() {
    return this.newApp.nameValid && this.newApp.descriptionValid && this.newApp.itemValid && this.newApp.itemPluralValid;
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  setAppsToUncategorized(folderId) {
    _.map(this.apps, app => {
      if (app.workspaceFolderId === folderId) {
        app.workspaceFolderId = '';
      }
    });
  }

  @action
  addRelationshipPermission(data) {
    const newRelationshipPermission = new RelationshipPermission(
      data.id,
      data.relationship_id,
      data.permission_model,
      data.permission_type,
      data.workspace_id,
      data.app_id,
      data.participant_id,
      data.item_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    newRelationshipPermission.relationship = new Relationship(
      data.relationship.id,
      data.relationship.account_id,
      data.relationship.name,
      data.relationship.counterpart_name,
      data.relationship.description,
      data.relationship.default,
      data.relationship.lock_user_changes,
      data.relationship.lock_counterpart_user_changes,
      data.relationship.created_at,
      data.relationship.created_by,
      data.relationship.updated_at,
      data.relationship.updated_by,
      data.relationship.deleted_at,
      data.relationship.deleted_by
    );

    this.relationshipPermissions = _.unionBy([_.clone(newRelationshipPermission)], this.relationshipPermissions, 'id');
  }

  @action
  folderSize(folderId) {
    // lazy fix for legacy code
    // eslint-disable-next-line no-magic-numbers
    if (folderId === 9999) {
      const uncategorizedApps = _.find(this.apps, o => o.workspaceFolderId === '');
      return [uncategorizedApps].length;
    }
    const folder = _.find(WorkspaceStore.workspaceFolders, o => o.id === folderId);
    if (folder) {
      const apps = _.find(this.apps, o => o.workspaceFolderId === folder.id);
      return [apps].length - 1;
    }
    return undefined;
  }

  @action
  updateNewApp(data, attribute) {
    this.newApp[attribute] = data;
  }

  @action
  resetAppAttribute(attribute) {
    this.newApp[attribute] = this.activeApp[attribute];
  }

  @action
  addApp(data, type) {
    const newApp = new App(
      data.id,
      data.workspace_id,
      data.workspace_folder_id,
      data.name,
      data.description,
      data.item,
      data.item_plural,
      data.item_layout,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.row_order_folder,
      data.user_access_level,
      data.webform_enabled,
      data.webform_email_required,
      data.webform_anonymous,
      data.webform_path,
      data.default_view_folder,
      data.default_process_visible,
      data.include_in_quick_create_menu,
      data.dummy_data,
      data.lock_participants,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    if (type === 'single') {
      this.activeApp = _.clone(newApp);
      this.newApp = _.clone(newApp);
      this.sortApps();
    } else {
      this.apps = _.unionBy([newApp], this.apps, 'id');
      if (newApp.workspaceId == this.activeWorkspace.id) {
        this.appsForCurrentWorkspace = _.unionBy([newApp], this.appsForCurrentWorkspace, 'id');
      }
      this.sortApps();
    }
  }

  @action
  removeApp(data) {
    this.apps = _.filter(this.apps, o => o.id !== data.id);
  }

  @action
  createApp(appObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/apps`, appObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  copyApp(appId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/apps/${appId}/copy`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchApps(workspaceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/apps?workspace_id=${workspaceId}`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, app => {
            this.addApp(app, 'all');
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchApp(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/apps/${appId}`)
        .then(response => {
          this.addApp(response.data, 'single');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchParticipants(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/participants?app_id=${appId}`)
        .then(response => {
          this.firstParticipant = response.data[0].name;
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchRelationshipPermissions(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/relationship_permissions?app_id=${appId}`)
        .then(response => {
          _.map(response.data, permission => {
            this.addRelationshipPermission(permission);
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchUserRelationships(relationshipId, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_relationships?relationship_id=${relationshipId}&user_id=${userId}`)
        .then(response => {
          this.userRelationships = response.data;
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeApp(appObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/apps/${this.activeApp.id}`, appObj)
        .then(response => {
          this.addApp(response.data, 'single');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  reorderApp(appObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/apps/${appObj.id}`, appObj)
        .then(response => {
          this.addApp(response.data, 'all');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteApp() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/apps/${this.activeApp.id}`)
        .then(response => {
          resolve(response);
          // this.removeApp(response.data);
          // this.resetStartingAttributes();
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  exportAllItemsToPdf() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/apps/${this.activeApp.id}/bulk_pdf`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  sortApps() {
    if (WorkspaceStore.hasFolders) {
      this.apps = _.orderBy(this.apps, ['rowOrderFolder'], ['asc']);
      this.appsForCurrentWorkspace = _.orderBy(this.appsForCurrentWorkspace, ['rowOrderFolder'], ['asc']);
    } else {
      this.apps = _.orderBy(this.apps, ['rowOrder'], ['asc']);
      this.appsForCurrentWorkspace = _.orderBy(this.appsForCurrentWorkspace, ['rowOrder'], ['asc']);
    }
  }

  @action
  resetStartingAttributes() {
    this.newApp = new App();
    this.activeApp = new App();
    this.firstParticipant = '';
    this.activeWorkspace = new Workspace();
    this.apps = [];
    this.appsForCurrentWorkspace = [];
    this.activeFilter = 'published';
    this.activeTab = 'items';
    this.relationshipPermissions = [];
    this.userRelationships = [];
  }
}

const store = new AppStore();
export default store;
