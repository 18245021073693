import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  Classes,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  ProgressBar,
  Tag,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import UserAvatar from '../shared/UserAvatar';
import Utilities from '../../utils/Utilities';

@inject('ReportStore', 'ReportActions', 'ToastStore')
@observer
export default class ReportRow extends Component {
  @observable
  generateProgress = 0;
  @observable
  isFinishedGenerating = false;

  constructor(props) {
    super(props);

    this.state = { isDeleting: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      if (nextProps.report.status === 'generating_data') {
        this.startGenerateProgress();
      }
    }
  }

  processGenerateProgress() {
    const oneHundredPercent = 100;
    if (this.generateProgress < oneHundredPercent) {
      const newProgress =
        Math.floor(
          Math.random() * (oneHundredPercent - this.generateProgress)
        ) + this.generateProgress;
      this.generateProgress = newProgress;
    } else {
      this.finishGenerateProgress();
    }
  }

  startGenerateProgress() {
    this.resetProgress();
    const intervalDuration = 1000; //ms
    this.interval = setInterval(() => {
      this.processGenerateProgress();
    }, intervalDuration);
  }

  resetProgress() {
    this.generateProgress = 0;
  }

  finishGenerateProgress() {
    clearInterval(this.interval);
    this.generateProgress = 100;
  }

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting,
    });
  }

  handleDeleteReport() {
    this.props.ReportActions.deleteReport(this.props.report.id).then(
      response => {
        this.props.ReportStore.removeReport(response.data.id);
        this.props.ReportStore.pagination.xTotal--;
        this.props.ToastStore.showToast(
          I18n.t('js.report_has_been_deleted'),
          'success'
        );
      }
    );
  }

  renderDeleteAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_report')}
        onConfirm={() => this.handleDeleteReport()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_report')}
      </Alert>
    );
  }

  renderDownloadMenu() {
    return (
      <Menu>
        <MenuItem
          text={I18n.t('js.csv')}
          href={this.props.report.csvUrl}
          icon={<span className="far fa-file-alt fa-menu-icon" />}
          onClick={() => Utilities.navigate(this.props.report.csvUrl)}
        />
        <MenuItem
          text={I18n.t('js.excel')}
          href={this.props.report.xlsUrl}
          icon={<span className="far fa-file-excel fa-menu-icon" />}
          onClick={() => Utilities.navigate(this.props.report.xlsUrl)}
        />
      </Menu>
    );
  }

  renderStatusTag() {
    if (this.props.report.status === 'complete') {
      return (
        <Tag intent={Intent.SUCCESS}>
          {this.props.report.translatedStatus}
        </Tag>
      );
    }
    if (this.props.report.status === 'failed') {
      return (
        <Tag intent={Intent.DANGER}>
          {this.props.report.translatedStatus}
        </Tag>
      );
    }
    return (
      <Tag intent={Intent.PRIMARY}>
        {this.props.report.translatedStatus}
      </Tag>
    );
  }

  renderAction() {
    if (this.props.report.status === 'complete') {
      return (
        <Flexbox>
          <Button
            className="bp3-small push-5-r"
            icon="document-open"
            text={I18n.t('js.open')}
            onClick={() =>
              Utilities.navigate(`/reports/${this.props.report.id}`)
            }
          />
          <Popover
            portalContainer={document.body}
            content={this.renderDownloadMenu()}
            inheritDarkTheme={false}
            interactionKind={PopoverInteractionKind.CLICK}
            position="bottom"
          >
            <Button
              className="bp3-small push-5-r"
              icon="cloud-download"
              rightIcon="caret-down"
              text={I18n.t('js.export')}
            />
          </Popover>
          <Button
            className="bp3-small"
            icon="trash"
            text={I18n.t('js.delete')}
            onClick={this.toggleDeleting.bind(this)}
          />
          {this.renderDeleteAlert()}
        </Flexbox>
      );
    }
    if (this.props.report.status === 'failed') {
      return (
        <Flexbox>
          <Button
            className="bp3-small"
            icon="trash"
            text={I18n.t('js.delete')}
            onClick={this.toggleDeleting.bind(this)}
          />
          {this.renderDeleteAlert()}
        </Flexbox>
      );
    }
    const oneHundredPercent = 100;
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={1}
        justifyContent="center"
        alignContent="center"
        paddingTop="10px"
        minWidth="150px"
      >
        <ProgressBar
          animate
          stripes
          intent={Intent.PRIMARY}
          value={this.generateProgress / oneHundredPercent}
        />
      </Flexbox>
    );
  }

  render() {
    const { report } = this.props;
    const { name, status, user, id } = report;
    return (
      <tr>
        <td>
          {status === 'complete' ? (
            <a href={`/reports/${id}`}>
              <span>{name}</span>
            </a>
          ) : (
            <span>{name}</span>
          )}
        </td>
        <td>{report.createdAtToDateShort}</td>
        <td>
          <UserAvatar
            user={user}
            avatarSize={20}
            path={`/users/${user.humanFriendlyId}`}
          />
        </td>
        <td>{this.renderStatusTag()}</td>
        <td>{this.renderAction()}</td>
      </tr>
    );
  }
}
