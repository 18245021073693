import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { NonIdealState } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import AppStore from '../stores/AppStore';
import CurrentUserStore from '../stores/CurrentUserStore';

@observer
export default class AppAccessDeniedView extends React.Component {
  render() {
    const stores = {
      AppStore,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <Flexbox
          flexGrow={1}
          width="100hw"
          alignContent="stretch"
          flexDirection="column"
          paddingBottom="0px"
          marginTop="20px"
        >
          <Flexbox
            className="fixed-width-container"
            flexDirection="column"
            flexGrow={1}
            justifyContent="flex-start"
            marginTop="20px"
          >
            <Flexbox flexDirection="column">
              <Flexbox flexGrow={1} flexDirection="column">
                <Flexbox flexDirection="row" flexGrow={1}>
                  <NonIdealState
                    title={I18n.t('js.access_denied')}
                    description={I18n.t(
                      'js.access_to_this_app_is_denied'
                    )}
                    icon="lock"
                    className="animated rubberBand"
                  />
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Provider>
    );
  }
}
