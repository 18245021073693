import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import { Transform } from 'react-darkroom';
import browserImageSize from 'browser-image-size';
import Dropzone from 'react-dropzone';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import Utilities from '../../utils/Utilities';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsDetailsAccountLogo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      image: '',
      imageTransformed: '',
      hasImage: false,
      isCropping: false,
      imageAngle: 0,
      canvasWidth: 300,
      canvasHeight: 300,
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleCropping() {
    this.setState({
      isCropping: !this.state.isCropping,
    });
  }

  handleDrop(images) {
    browserImageSize(images[0].preview).then(size => {
      let maxDimension = size.width;
      if (size.height > size.width) {
        maxDimension = size.height;
      }
      this.setState({
        image: images[0],
        imageTransformed: '',
        hasImage: true,
        canvasWidth: maxDimension,
        canvasHeight: maxDimension,
      });
    });
  }

  handleRotate(degrees) {
    let newImage = this.state.image.preview;
    // FIXME this probably wasn't meant to be an expression
    // eslint-disable-next-line no-unused-expressions
    this.state.imageTransformed !== '' ? (newImage = this.state.imageTransformed) : null;
    Transform.cropImage(
      newImage,
      {
        x: 0,
        y: 0,
        width: this.state.canvasWidth,
        height: this.state.canvasHeight,
      },
      {
        width: this.state.canvasWidth,
        height: this.state.canvasHeight,
      },
      degrees
    ).then(image => {
      this.setState({ imageTransformed: image, isCropping: false });
    });
  }

  handleCropConfirm() {
    const { x, y, width, height } = this.canvasWrapper.cropBox;
    let newImage = this.state.image.preview;
    // FIXME this wasn't meant to be an expression
    // eslint-disable-next-line no-unused-expressions
    this.state.imageTransformed !== '' ? (newImage = this.state.imageTransformed) : null;
    this.setState({ canvasWidth: width, canvasHeight: height });
    Transform.cropImage(
      newImage,
      {
        x,
        y,
        width,
        height,
      },
      {
        width: x + width,
        height: y + height,
      }
    ).then(image => {
      this.setState({ imageTransformed: image, isCropping: false });
    });
  }

  handleCropCancel() {
    this.setState({ isCropping: false });
  }

  handleSubmit() {
    this.toggleLoading();
    let finalImage = this.state.image;
    if (this.state.imageTransformed !== '') {
      const b64Data = this.state.imageTransformed;
      finalImage = dataURLtoBlob(b64Data);
    }
    const formData = new FormData();
    formData.append('image', finalImage, Utilities.generateRandomImgName());
    this.props.AccountSettingsStore.changeAccountImage(formData)
      .then(() => {
        this.toggleLoading();
        this.toggleOpen();
        location.reload();
      })
      .catch(this.toggleLoading);
  }

  handleCancel() {
    this.setState({
      isOpen: false,
      hasImage: false,
      image: '',
      imageTransformed: '',
      isCropping: false,
      imageAngle: 0,
    });
  }

  renderDarkroom() {
    if (this.state.image !== '') {
      return (
        <Flexbox marginTop="20px" padding="20px" className="bp3-navbar-gray">
          <img alt="logo" src={this.state.image.preview} className="img-logo" />
        </Flexbox>
      );
    }
    return (
      <Flexbox marginTop="20px" padding="20px" className="bp3-navbar-gray">
        <img alt="logo" src={this.props.AccountSettingsStore.newAccount.imageFileName} className="img-logo" />
      </Flexbox>
    );
  }

  renderCropCancel() {
    if (this.state.isCropping) {
      return <Button icon="cross" intent={Intent.DANGER} onClick={this.handleCropCancel.bind(this)} />;
    }
    return undefined;
  }

  renderCropConfirm() {
    if (this.state.isCropping) {
      return <Button icon="tick" intent={Intent.SUCCESS} onClick={this.handleCropConfirm.bind(this)} />;
    }
    return undefined;
  }

  renderSelectImageText() {
    if (this.state.image === '') {
      return <span>{I18n.t('js.select_new_image')}</span>;
    }
    return <span>{this.state.image.name}</span>;
  }

  renderImageMenu() {
    return (
      <div className="bp3-button-group">
        <Dropzone onDrop={this.handleDrop.bind(this)} className="bp3-dropzone bp3-button bp3-icon-media">
          {this.renderSelectImageText()}
        </Dropzone>
      </div>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* FIXME Make accessible */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.logo')}
            </h4>
            <p className="noselect">{I18n.t('js.account_logo_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form>
            <Flexbox flexDirection="column">
              {this.renderImageMenu()}
              {this.renderDarkroom()}
              <div className="push-10-t">
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.handleSubmit.bind(this)}
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
