import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Classes,
  Collapse,
  HTMLSelect,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'WorkspaceStore',
  'AppStore',
  'ToastStore'
)
@observer
export default class AppEditWorkspace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
      isLoading: false,
    };
  }

  toggleOpen() {
    if (!this.state.isOpen) {
      this.loadData();
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleSubmitting() {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  loadData() {
    this.toggleLoading();
    this.props.WorkspaceStore.fetchWorkspaces()
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    this.setState({ isBlurred: [] });
    const newObj = {
      workspace_id: this.props.AppStore.newApp.workspaceId,
      workspace_folder_id: null,
    };
    this.props.AppStore.changeApp(newObj)
      .then(() => {
        const findWorkspace = _.find(
          this.props.WorkspaceStore.workspaces,
          o => o.id === this.props.AppStore.newApp.workspaceId
        );
        if (findWorkspace) {
          this.props.AppStore.activeWorkspace = findWorkspace;
        }
        this.toggleSubmitting();
        this.toggleOpen();
        this.props.WorkspaceStore.workspaceFolders = [];
        this.props.WorkspaceStore.fetchWorkspaceFolders(
          this.props.AppStore.activeApp.workspaceId
        );
      })
      .catch(() => {
        this.toggleSubmitting();
      });
  }

  handleWorkspaceChange(e) {
    this.props.AppStore.updateNewApp(e.target.value, 'workspaceId');
  }

  handleCancel() {
    this.props.AppStore.resetAppAttribute('workspaceId');
    this.toggleOpen();
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.AppStore.newApp[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.AppStore.newApp[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  renderContent() {
    if (this.state.isLoading) {
      return (
        <Flexbox
          justifyContent="center"
          alignItems="center"
          paddingTop="20px"
          paddingBottom="20px"
        >
          <NonIdealState
            title={I18n.t('js.fetching_data')}
            description={I18n.t('js.please_wait')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column" flexGrow={1}>
            <div className="bp3-form-group">
              <label className="bp3-label">
                {I18n.t('js.select_workspace')}
              </label>
              <div className="bp3-form-content">
                <HTMLSelect
                  name="workspaceId"
                  onChange={this.handleWorkspaceChange.bind(this)}
                  defaultValue={
                    this.props.AppStore.newApp.workspaceId
                  }
                >
                  {_.map(
                    this.props.WorkspaceStore.workspaces,
                    workspace => (
                      <option key={workspace.id} value={workspace.id}>
                        {workspace.name}
                      </option>
                    )
                  )}
                </HTMLSelect>
              </div>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox>
          <div>
            <Button
              intent={Intent.PRIMARY}
              type="submit"
              disabled={false}
              text={I18n.t('js.save_changes')}
              loading={this.state.isSubmitting}
              icon="tick"
            />
            <Button
              className="push-10-l"
              icon="cross"
              text={I18n.t('js.cancel_changes')}
              onClick={this.handleCancel.bind(this)}
            />
          </div>
        </Flexbox>
      </form>
    );
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* ignore jsx-a11y as toggle functionality included eslewhere for */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.workspace')}
            </h4>
            <p className="noselect">
              {I18n.t('js.change_workspace')}
            </p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          {this.renderContent()}
        </Collapse>
      </Flexbox>
    );
  }
}
