import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import StateRuleActions from '../../actions/StateRuleActions';
import { Tag, Tooltip } from '@blueprintjs/core';
import _ from 'lodash';

@inject('TemplateStore')
@observer
export default class TemplateStateCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isHovering: false };
  }

  handleCardClick() {
    this.props.TemplateStore.setActiveArea(null);
    if (this.props.state.id !== this.props.TemplateStore.activeState.id) {
      this.props.TemplateStore.setActiveState(this.props.state, true);
      if (this.props.TemplateStore.activeSubsection === 'rules') {
        StateRuleActions.loadInitialData(this.props.state.id);
      }
    }
  }

  renderIcon() {
    if (this.props.state.id !== _.last(this.props.TemplateStore.states).id) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} justifyContent="center" alignItems="center" marginLeft="5px" marginRight="5px">
          <span className="bp3-icon bp3-icon-more bp3-text-muted" />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderTag() {
    const findStatusFlag = _.find(this.props.TemplateStore.statusFlags, o => o.id === this.props.state.statusFlagId);
    return (
      <Flexbox className="state-card-status-flag">
        <Tag style={{ backgroundColor: findStatusFlag.color }}>{findStatusFlag.name}</Tag>
      </Flexbox>
    );
  }

  render() {
    let cardClass = classNames('bp3-card bp3-interactive state-card noselect');
    if (this.props.state.id === this.props.TemplateStore.activeState.id) {
      cardClass = classNames('bp3-card bp3-interactive bp3-elevation-3 state-card noselect');
    }
    const findStatusFlag = _.find(this.props.TemplateStore.statusFlags, o => o.id === this.props.state.statusFlagId);
    return (
      <Flexbox
        flexDirection="column"
        marginTop="5px"
        marginBottom="15px"
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        <Flexbox flexDirection="row">
          <Flexbox flexDirection="column">
            <Tooltip portalContainer={document.body} position="bottom" content={I18n.t('js.set_as_active_state')}>
              <Flexbox
                className={cardClass}
                style={
                  this.props.state.id === this.props.TemplateStore.activeState.id
                    ? { borderLeft: `5px solid ${findStatusFlag.color}` }
                    : null
                }
                flexDirection="column"
                flexGrow={1}
                alignSelf="stretch"
                alignItems="center"
                justifyContent="center"
                onClick={this.handleCardClick.bind(this)}
              >
                {this.renderTag()}
                <Flexbox flexDirection="column" justifyContent="center" alignItems="center">
                  <div>
                    {_.truncate(this.props.state.name, {
                      length: 22,
                    })}
                  </div>
                  <small className="bp3-text-muted">
                    {_.truncate(this.props.participant.name, {
                      length: 30,
                    })}
                  </small>
                </Flexbox>
              </Flexbox>
            </Tooltip>
          </Flexbox>
          {this.renderIcon()}
        </Flexbox>
      </Flexbox>
    );
  }
}
