import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { IconNames } from '@blueprintjs/icons';
import ActivityStore from '../stores/ActivityStore';
import CommentStore from '../stores/CommentStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import Activity from '../components/activities/Activity';
import { Spinner } from '@blueprintjs/core';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class SingleCommentView extends React.Component {
  @observable
  isLoading = true;
  @observable
  activity = '';

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    this.isLoading = true;
    ActivityStore.fetchActivity(this.props.activityId)
      .then(response => {
        ActivityStore.addActivity(response.data).then(activity => {
          this.activity = activity;
          this.isLoading = false;
        });
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  renderComment() {
    if (this.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    return <Activity activity={this.activity} alwaysShow={true} />;
  }

  render() {
    const stores = {
      ActivityStore,
      CommentStore,
      ToastStore,
      CurrentUserStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <LayoutHeader title={I18n.t('js.activity')} subtitle={I18n.t('js.activity_single_view_description')} icon={IconNames.WALK} />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderComment()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
