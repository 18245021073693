/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  AnchorButton,
  Checkbox,
  Classes,
  MenuItem,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import { MultiSelect, Select } from '@blueprintjs/select';
import classNames from 'classnames';
import ElementSelectOption from '../../../models/elements/ElementSelectOptionModel';
import ElementReadOnly from '../../shared/ElementReadOnly';
import _ from 'lodash';

const SingleSelectOption = Select.ofType(ElementSelectOption);
const MultipleSelectOption = MultiSelect.ofType(ElementSelectOption);

@observer
export default class ElementSelectDisplay extends React.Component {
  @observable
  displayedInput = '';

  constructor(props) {
    super(props);

    this.state = {
      singleSelectOption: '',
      multipleSelectOptions: [],
    };
  }

  handleRadioChange(e) {
    const findOption = _.find(
      this.props.element.elementSelect.elementSelectOptions,
      o => o.id === e.target.value
    );
    this.setState({ singleSelectOption: findOption });
  }

  handleSingleSelect(option) {
    this.setState({ singleSelectOption: option });
  }

  handleMultiSelect(option) {
    const findOption = _.find(
      this.state.multipleSelectOptions,
      o => o.id === option.id
    );
    if (findOption) {
      this.setState({
        multipleSelectOptions: _.filter(
          this.state.multipleSelectOptions,
          o => o.id !== findOption.id
        ),
      });
    } else {
      this.setState({
        multipleSelectOptions: this.state.multipleSelectOptions.concat(
          option
        ),
      });
    }
  }

  handleMultiSelectRemove(option) {
    const optionId = option.props.id;
    const newOptions = _.filter(
      this.state.multipleSelectOptions,
      o => o.id !== optionId
    );
    this.setState({ multipleSelectOptions: newOptions });
  }

  filterOptions(query) {
    let possibleOptions = _.filter(
      this.props.element.elementSelect.elementSelectOptions,
      o => _.includes(o.name.toLowerCase(), query.toLowerCase())
    );
    // filter out selected options
    _.map(this.state.multipleSelectOptions, option => {
      possibleOptions = _.filter(
        possibleOptions,
        o => o.id !== option.id
      );
    });

    return possibleOptions;
  }

  renderSingleOption(item, details) {
    if (
      item.name !== this.props.element.elementSelect.placeholderText
    ) {
      let menuClass = classNames('popover-larger');
      if (item.name === this.state.singleSelectOption.name) {
        menuClass = classNames(
          Classes.ACTIVE,
          Classes.INTENT_PRIMARY,
          'popover-larger'
        );
      }

      return (
        <MenuItem
          className={menuClass}
          key={item.id}
          onClick={details.handleClick}
          icon={item.icon}
          text={item.name}
        />
      );
    }
    return undefined;
  }

  renderMultipleOption(item, details) {
    let menuClass = classNames('popover-larger');
    if (details.modifiers.active) {
      menuClass = classNames(
        Classes.ACTIVE,
        Classes.INTENT_PRIMARY,
        'popover-larger'
      );
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        icon={item.icon}
        text={item.name}
      />
    );
  }

  renderInputValue(option) {
    return option.name;
  }

  renderTag(option) {
    return (
      <span id={option.id}>
        {_.truncate(option.name, { length: 40 })}
      </span>
    );
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderNoResults() {
    return <MenuItem disabled text={I18n.t('js.no_results_found')} />;
  }

  renderRightElement() {
    return (
      <AnchorButton
        className="bp3-minimal bp3-small"
        icon="caret-down"
        disabled={this.props.disabled}
      />
    );
  }

  renderDropDownDisplay() {
    if (this.props.element.elementSelect.optionType === 'single') {
      return (
        <Flexbox>
          <SingleSelectOption
            resetOnSelect={true}
            filterable={false}
            disabled={this.props.disabled}
            items={
              this.props.element.elementSelect.elementSelectOptions
            }
            itemRenderer={this.renderSingleOption.bind(this)}
            onItemSelect={this.handleSingleSelect.bind(this)}
            inputValueRenderer={this.renderInputValue.bind(this)}
            inputProps={{
              rightElement: this.renderDropDown(),
            }}
            popoverProps={{
              usePortal: false,
              position: 'bottom-left',
              className: '',
              popoverClassName: 'bp3-minimal',
            }}
          >
            <AnchorButton
              text={
                typeof this.state.singleSelectOption !== 'object'
                  ? this.props.element.elementSelect.placeholderText
                  : this.state.singleSelectOption.name
              }
              rightIcon="caret-down"
              disabled={this.props.disabled}
            />
          </SingleSelectOption>
        </Flexbox>
      );
    }
    const options = this.props.element.elementSelect.elementSelectOptions.slice();
    return (
      <Flexbox>
        <MultipleSelectOption
          className="max-width"
          resetOnSelect={true}
          items={options}
          itemListPredicate={this.filterOptions.bind(this)}
          selectedItems={this.state.multipleSelectOptions}
          tagRenderer={this.renderTag.bind(this)}
          itemRenderer={this.renderMultipleOption.bind(this)}
          onItemSelect={this.handleMultiSelect.bind(this)}
          tagInputProps={{
            disabled: this.props.disabled,
            inputProps: {
              placeholder: this.props.element.elementSelect
                .placeholderText,
            },
            rightElement: this.renderRightElement(),
            className: 'bp3-tag-input',
            onRemove: this.handleMultiSelectRemove.bind(this),
          }}
          popoverProps={{
            usePortal: false,
            position: 'bottom-left',
            className: '',
            popoverClassName: 'bp3-minimal',
          }}
          noResults={this.renderNoResults()}
        />
      </Flexbox>
    );
  }

  renderListDisplay() {
    const { elementSelect } = this.props.element;
    if (elementSelect.optionType === 'single') {
      return (
        <Flexbox>
          <RadioGroup
            onChange={this.handleRadioChange.bind(this)}
            selectedValue={this.state.singleSelectOption.id}
          >
            {elementSelect.elementSelectOptions
              .filter(o => o.name !== elementSelect.placeholderText)
              .map(o => (
                <Radio
                  key={o.id}
                  label={o.name}
                  value={o.id}
                  disabled={this.props.disabled}
                />
              ))}
          </RadioGroup>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column">
        {_.map(
          this.props.element.elementSelect.elementSelectOptions,
          option => {
            let checked = false;
            const findOption = _.find(
              this.state.multipleSelectOptions,
              o => o.id === option.id
            );
            if (findOption) {
              checked = true;
            }
            return (
              <Checkbox
                id={option.id}
                key={option.id}
                checked={checked}
                onChange={() => this.handleMultiSelect(option)}
                label={option.name}
                disabled={this.props.disabled}
              />
            );
          }
        )}
      </Flexbox>
    );
  }

  renderDisabledText() {
    if (this.props.disabled) {
      return <ElementReadOnly element={this.props.element} />;
    }
    return undefined;
  }

  render() {
    if (this.props.element.elementSelect.displayType === 'list') {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderListDisplay()}
          {this.renderDisabledText()}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderDropDownDisplay()}
        {this.renderDisabledText()}
      </Flexbox>
    );
  }
}
