import { action, observable } from 'mobx';
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';

const apiEnv = Cookies.get('apiEnv');

class CalendarStore {
  @observable
  calendarItems = [];
  @observable
  isLoading = true;
  @observable
  dialogIsOpen = false;
  @observable
  dialogCalendarItem = '';

  @action
  closeDialog() {
    this.dialogIsOpen = false;
  }

  @action
  openDialog() {
    this.dialogIsOpen = true;
  }

  @action
  fetchAndAddItems(startDate = null, endDate = null) {
    axios
      .get(`${apiEnv}/calendar_items/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then(response => {
        this.calendarItems = response.data;
      })
      .catch(this.catchError);
    this.toggleLoading();
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  setLoading(truth) {
    this.isLoading = truth;
  }

  static catchError = error => {
    const errors = error.response.data.error.join(', ');
    ToastStore.showToast(errors, 'danger');
  };
}

const store = new CalendarStore();
export default store;
