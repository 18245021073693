import React from 'react';
import Flexbox from 'flexbox-react';
import { Alert, Button, Classes, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import OrganizationChooseUsers from './OrganizationChooseUsers';
import renderHTML from 'react-render-html';

@inject('OrganizationStore', 'CurrentUserStore')
@observer
export default class OrganizationUserOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoadingAll: false };
  }

  toggleLoadingAll() {
    this.setState({
      isLoadingAll: !this.state.isLoadingAll,
    });
  }

  renderAddYourselfIcon() {
    return <Avatar src={this.props.CurrentUserStore.currentUser.imageFileName} round={true} size={20} />;
  }

  renderAddYourself() {
    return (
      <Button
        icon={this.renderAddYourselfIcon()}
        text={I18n.t('js.add_yourself', { name: this.props.CurrentUserStore.currentUser.fullName })}
        onClick={() => this.handleAddYourSelf()}
      />
    );
  }

  handleAddYourSelf() {
    this.props.handleUserSelectChange(this.props.CurrentUserStore.currentUser);
  }

  renderActiveUser() {
    return <OrganizationChooseUsers handleUserSelectChange={this.props.handleUserSelectChange.bind(this)} />;
  }

  renderLoadAllUserRelationships() {
    const { totalActiveUserRelationships, loadingAllUserRelationships } = this.props.OrganizationStore;
    // if (totalActiveUserRelationships > 49) {
    //   if (this.props.CurrentUserStore.currentUser.isAccountOwner) {
    //     return (
    //       <Button
    //         className="bp3-button bp3-icon-import"
    //         text={I18n.t('js.load_all_users')}
    //         onClick={() => this.toggleLoadingAll()}
    //         loading={loadingAllUserRelationships}
    //       />
    //     );
    //   }
    // }
    return (
      <Button
        className="bp3-button bp3-icon-import"
        text={I18n.t('js.load_all_users')}
        onClick={() => this.props.loadAllRelationships()}
        loading={loadingAllUserRelationships}
      />
    );
  }

  confirmLoadAllUsers() {
    this.props.loadAllRelationships();
    this.toggleLoadingAll();
  }

  renderLoadAllAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isLoadingAll}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleLoadingAll()}
        confirmButtonText={I18n.t('js.load_all_relationships')}
        onConfirm={() => this.confirmLoadAllUsers()}
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.load_all_user_relationships_confirm_text', {
          total: this.props.OrganizationStore.totalActiveUserRelationships,
        })}
      </Alert>
    );
  }

  renderClearUsers() {
    return (
      <Button
        className="bp3-button bp3-icon-cross"
        text={I18n.t('js.clear_users')}
        onClick={() => this.props.OrganizationStore.resetUsersAndRelationships()}
      />
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <h4 className="bp3-heading">{I18n.t('js.chart_options')}</h4>
        <Flexbox marginTop="10px">
          <p>
            {renderHTML(
              I18n.t('js.organization_chart_options_description', {
                url: `/users/${this.props.CurrentUserStore.currentUser.humanFriendlyId}?tab=relationships`,
              })
            )}
          </p>
        </Flexbox>
        <Flexbox flexDirection="row" marginBottom="10px">
          <div className="bp3-button-group">
            {this.renderAddYourself()}
            {this.renderActiveUser()}
            {this.renderLoadAllUserRelationships()}
            {this.renderLoadAllAlert()}
            {this.renderClearUsers()}
          </div>
        </Flexbox>
      </Flexbox>
    );
  }
}
