import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Classes,
  Collapse,
  Intent,
  Switch,
} from '@blueprintjs/core';
import AppItemWebformCard from '../app_items/AppItemWebformCard';

@inject('CurrentUserStore', 'AppStore', 'ToastStore')
@observer
export default class AppEditWebformEnabled extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isOpen: false };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    const newObj = {
      webform_enabled: this.props.AppStore.newApp.webformEnabled,
      webform_email_required: this.props.AppStore.newApp
        .webformEmailRequired,
      webform_anonymous: this.props.AppStore.newApp.webformAnonymous,
    };
    this.props.AppStore.changeApp(newObj)
      .then(response => {
        this.toggleLoading();
        this.toggleOpen();
        if (response.data.webform_enabled) {
          this.props.ToastStore.showToast(
            I18n.t('js.settings_have_been_updated'),
            'success'
          );
        }
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleWebformEnabledChange() {
    if (this.props.AppStore.newApp.webformEnabled) {
      this.props.AppStore.updateNewApp(false, 'webformEnabled');
    } else {
      this.props.AppStore.updateNewApp(true, 'webformEnabled');
    }
  }

  handleWebformEmailRequiredChange() {
    if (this.props.AppStore.newApp.webformEmailRequired) {
      this.props.AppStore.updateNewApp(false, 'webformEmailRequired');
    } else {
      this.props.AppStore.updateNewApp(true, 'webformEmailRequired');
    }
  }

  handleWebformAnonymousChange() {
    if (this.props.AppStore.newApp.webformAnonymous) {
      this.props.AppStore.updateNewApp(false, 'webformAnonymous');
    } else {
      this.props.AppStore.updateNewApp(true, 'webformAnonymous');
    }
  }

  handleCancel() {
    this.props.AppStore.resetAppAttribute('status');
    this.toggleOpen();
  }

  renderWebformLink() {
    if (this.props.AppStore.newApp.webformEnabled) {
      return (
        <Flexbox className="bp3-card">
          <AppItemWebformCard justification="flex-start" />
        </Flexbox>
      );
    }
    return null;
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* ignore jsx-a11y as toggle functionality included eslewhere for */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.webform')}
            </h4>
            <p className="noselect">{I18n.t('js.webform_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            {this.renderWebformLink()}
            <Flexbox
              flexDirection="column"
              marginBottom="10px"
              marginTop="10px"
            >
              <Switch
                className="bp3-control-no-margin"
                checked={this.props.AppStore.newApp.webformEnabled}
                label={I18n.t('js.webform_enabled')}
                onChange={this.handleWebformEnabledChange.bind(this)}
              />
            </Flexbox>
            <Flexbox flexDirection="column" marginBottom="10px">
              <Switch
                className="bp3-control-no-margin"
                checked={
                  this.props.AppStore.newApp.webformEmailRequired
                }
                disabled={!this.props.AppStore.newApp.webformEnabled}
                label={I18n.t('js.require_emails_for_submission')}
                onChange={this.handleWebformEmailRequiredChange.bind(
                  this
                )}
              />
            </Flexbox>
            <Flexbox flexDirection="column" marginBottom="10px">
              <Switch
                className="bp3-control-no-margin"
                checked={this.props.AppStore.newApp.webformAnonymous}
                disabled={
                  !this.props.AppStore.newApp.webformEnabled ||
                  this.props.AppStore.newApp.webformEmailRequired
                }
                label={I18n.t('js.anonymous_responses_only')}
                onChange={this.handleWebformAnonymousChange.bind(
                  this
                )}
              />
            </Flexbox>
            <Flexbox>
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  type="submit"
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button
                  className="push-10-l"
                  icon="cross"
                  text={I18n.t('js.cancel_changes')}
                  onClick={this.handleCancel.bind(this)}
                />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
