import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Button, Classes, NonIdealState, Spinner } from '@blueprintjs/core';
import StringToMarkdown from '../shared/StringToMarkdown';
import moment from 'moment';
import _ from 'lodash';

@inject('AdminActions', 'AdminStore', 'AuthStore', 'ToastStore')
@observer
export default class AuthNews extends React.Component {
  @observable
  isLoading = true;
  @observable
  activeNews = '';

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    this.props.AdminActions.fetchNews()
      .then(response => {
        _.map(response.items, news => {
          this.props.AdminStore.addNews(news);
        });
        this.activeNews = this.props.AdminStore.news[0];
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  goToNext() {
    if (_.last(this.props.AdminStore.news).sys.id !== this.activeNews.sys.id) {
      this.activeNews = this.props.AdminStore.news[this.props.AdminStore.news.indexOf(this.activeNews) + 1];
    }
  }

  goToPrevious() {
    if (_.head(this.props.AdminStore.news).sys.id !== this.activeNews.sys.id) {
      this.activeNews = this.props.AdminStore.news[this.props.AdminStore.news.indexOf(this.activeNews) - 1];
    }
  }

  renderNext() {
    if (_.last(this.props.AdminStore.news).sys.id !== this.activeNews.sys.id) {
      return <Button type="button" className="push-10-l" text={I18n.t('js.next')} onClick={this.goToNext.bind(this)} />;
    }
    return undefined;
  }

  renderPrevious() {
    if (_.head(this.props.AdminStore.news).sys.id !== this.activeNews.sys.id) {
      return <Button type="button" className="push-10-l" text={I18n.t('js.previous')} onClick={this.goToPrevious.bind(this)} />;
    }
    return undefined;
  }

  render() {
    if (this.isLoading) {
      return (
        <Flexbox
          flexBasis="0"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          className="border-l"
          flexDirection="column"
          padding="20px"
        >
          <Spinner />
        </Flexbox>
      );
    }
    if (this.props.AdminStore.news.length > 0) {
      return (
        <Flexbox
          flexBasis="0"
          flexGrow={1}
          justifyContent="flex-start"
          alignItems="flex-start"
          className="border-l"
          flexDirection="column"
          padding="20px"
        >
          <p className="bp3-text-muted">{I18n.t('js.whats_new')}</p>
          <h2 className={Classes.HEADING}>{this.activeNews.fields.title}</h2>
          <small className="bp3-text-muted">
            <span className="bp3-icon-calendar push-5-r" />
            {I18n.t('js.published_on', {
              date: moment.utc(this.activeNews.fields.publishedOn).format('MMMM Do YYYY, h:mm a'),
            })}
          </small>
          <Flexbox flexDirection="row" marginTop="20px" className="bp3-running-text">
            <StringToMarkdown string={this.activeNews.fields.body} />
          </Flexbox>
          <Flexbox flexDirection="row" marginTop="20px">
            <AnchorButton href={this.activeNews.fields.url} className="bp3-icon-book bp3-intent-primary" text={I18n.t('js.learn_more')} />
            {this.renderNext()}
            {this.renderPrevious()}
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexBasis="0"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        className="border-l"
        flexDirection="column"
        padding="20px"
      >
        <NonIdealState
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.there_is_no_news_to_show')}
          icon="book"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }
}
