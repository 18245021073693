import React from 'react';
import Flexbox from 'flexbox-react';
import { Button } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('AuthStore')
@observer
export default class SocialAuthButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleClick() {
    this.toggleLoading();
    this.props.AuthStore.createSocialSignUp(this.props.connection);
  }

  render() {
    return (
      <Button
        type="button"
        className="bp3-fill bp3-large push-10-b"
        loading={this.state.isSubmitting}
        onClick={this.handleClick.bind(this)}
      >
        <Flexbox flexDirection="row" justifyContent="center">
          <Flexbox marginRight="10px">
            <img
              src={this.props.image}
              alt="social button"
              className="social-button-img"
            />
          </Flexbox>
          {this.props.text}
        </Flexbox>
      </Button>
    );
  }
}
