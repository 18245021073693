import { computed, observable } from 'mobx';

export default class Account {
  @observable id;
  @observable name;
  @observable active;
  @observable isEnterprise;
  @observable subdomain;
  @observable accountPurpose;
  @observable accountPurposeOther;
  @observable ownerRole;
  @observable ownerPhone;
  @observable accountSize;
  @observable implementationTime;
  @observable referralSource;
  @observable referralSourceOther;
  @observable imageFileName;
  @observable dummyData;

  constructor(
    id,
    name,
    active,
    isEnterprise,
    subdomain,
    accountPurpose,
    accountPurposeOther,
    ownerRole,
    ownerPhone,
    accountSize,
    implementationTime,
    referralSource,
    referralSourceOther,
    imageFileName,
    dummyData
  ) {
    this.id = id || ``;
    this.name = name || ``;
    typeof active === 'undefined' || active === null ? (this.active = false) : (this.active = active);
    typeof isEnterprise === 'undefined' || isEnterprise === null ? (this.isEnterprise = false) : (this.isEnterprise = isEnterprise);
    this.subdomain = subdomain || ``;
    this.accountPurpose = accountPurpose || ``;
    this.accountPurposeOther = accountPurposeOther || ``;
    this.ownerRole = ownerRole || ``;
    this.ownerPhone = ownerPhone || ``;
    this.accountSize = accountSize || ``;
    this.implementationTime = implementationTime || ``;
    this.referralSource = referralSource || ``;
    this.referralSourceOther = referralSourceOther || ``;
    this.imageFileName = imageFileName || I18n.t(`js.pg_logo_black`);
    typeof dummyData === 'undefined' || dummyData === null ? (this.dummyData = false) : (this.dummyData = dummyData);
  }

  @computed
  get allValid() {
    return this.nameValid && this.subdomainValid && this.accountPurposeValid && this.ownerRoleValid && this.accountSizeValid;
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.account_name_is_required');
    }
    return null;
  }

  @computed
  get subdomainValid() {
    if (this.subdomain.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get subdomainError() {
    if (this.subdomain.length < 1) {
      return I18n.t('js.account_subdomain_is_required');
    }
    return null;
  }

  @computed
  get accountPurposeValid() {
    if (this.accountPurpose.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get accountPurposeError() {
    if (this.accountPurpose.length < 1) {
      return I18n.t('js.account_purpose_is_required');
    }
    return null;
  }

  @computed
  get ownerRoleValid() {
    if (this.ownerRole.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get ownerRoleError() {
    if (this.ownerRole.length < 1) {
      return I18n.t('js.owner_role_is_required');
    }
    return null;
  }

  @computed
  get ownerPhoneValid() {
    if (this.ownerPhone.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get ownerPhoneError() {
    if (this.ownerPhone.length < 1) {
      return I18n.t('js.owner_phone_is_required');
    }
    return null;
  }

  @computed
  get accountSizeValid() {
    if (this.accountSize.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get accountSizeError() {
    if (this.accountSize.length < 1) {
      return I18n.t('js.account_size_is_required');
    }
    return null;
  }

  @computed
  get implementationTimeValid() {
    if (this.implementationTime.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get implementationTimeError() {
    if (this.implementationTime.length < 1) {
      return I18n.t('js.implementation_time_is_required');
    }
    return null;
  }

  @computed
  get referralSourceValid() {
    if (this.referralSource.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get referralSourceError() {
    if (this.implementationTime.length < 1) {
      return I18n.t('js.referral_source_is_required');
    }
    return null;
  }
}
