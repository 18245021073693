/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 max-classes-per-file: 0*/
// @flow
import * as React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Alert, Button, ButtonGroup, EditableText, Intent, NonIdealState, Spinner, Switch } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import DataViewActions from '../actions/DataViewActions';
import DataViewTable from '../components/data_view/DataViewTable';
import ReportTemplateSelector from '../components/data_view/ReportTemplateSelector';
import ProfileTemplateSelector from '../components/data_view/ProfileTemplateSelector';
import SortPicker from '../components/data_view/SortPicker';
import FilterPicker from '../components/data_view/FilterPicker';
import NewVisualization from '../components/data_view/NewVisualization';
import SkeletonTable from '../components/skeletons/SkeletonTable';
import HiddenColumns from '../components/data_view/HiddenColumns';
import DataViewVisualization from '../components/data_view/DataViewVisualization';
import NewReportTemplate from '../components/data_view/NewReportTemplate';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import ReportSchedules from '../components/report_schedules/ReportSchedules';
import Utilities from '../utils/Utilities';
import type { DataViewLocation, UUID } from '../types/DataViewTypes';

// ================================================================================================
// SAVE & EXPORT BUTTONS
// ================================================================================================

type SaveButtonState = {
  dialogOpen: boolean,
  isSaving: boolean,
  disabled: Boolean,
};

@inject('DataViewStore', 'ToastStore')
@observer
class SaveButton extends React.Component<any, SaveButtonState> {
  constructor(props) {
    super(props);
    this.state = { dialogOpen: false, isSaving: false };
  }

  toggleDialog = () => {
    this.props.DataViewStore.resetNewReportTemplateAttributes();
    this.setState({ dialogOpen: !this.state.dialogOpen });
  };

  toggleSaving = () => {
    this.setState({ isSaving: !this.state.isSaving });
  };

  handleSave = () => {
    if (this.props.DataViewStore.activeReportTemplate.isPersisted) {
      this.handleSaveChanges();
    } else {
      this.toggleDialog();
    }
  };

  async applyInitialReportVariables(newlyCreatedReportTemplate, initialReportTemplate) {
    await _.map(initialReportTemplate.reportTemplateColumns, (reportTemplateColumn, index: number) => {
      const findRealColumn = _.find(newlyCreatedReportTemplate.report_template_columns, o => o.column_id === reportTemplateColumn.columnId);
      if (!findRealColumn) {
        throw new Error('could not find real column');
      }
      DataViewActions.changeReportTemplateColumn({
        id: findRealColumn.id,
        column_order_position: index,
        sort_direction: reportTemplateColumn.sortDirection,
        sort_priority: reportTemplateColumn.sortPriority,
        hidden: reportTemplateColumn.hidden,
      }).then(() => {
        _.map(reportTemplateColumn.reportTemplateFilters, reportTemplateFilter => {
          DataViewActions.createReportTemplateFilter({
            report_template_column_id: findRealColumn.id,
            operand: reportTemplateFilter.operand,
            test_value: reportTemplateFilter.testValue,
          });
        });
      });
    });
  }

  async handleSaveNewReportTemplate() {
    // Logic here is not optimal. We create the report template on server, then apply existing report template column variables to the newly created report template columns.
    this.toggleSaving();
    const { DataViewStore } = this.props;
    const initialReportTemplate = _.cloneDeep(DataViewStore.activeReportTemplate);
    const reportTemplateObj = {
      name: DataViewStore.newReportTemplate.name,
      description: DataViewStore.newReportTemplate.description,
      profile_template_id: '',
      app_id: '',
      chart_configuration: DataViewStore.activeChartConfigurationToString,
      default_report_template: DataViewStore.newReportTemplate.defaultReportTemplate,
      public_report_template: DataViewStore.newReportTemplate.publicReportTemplate,
    };
    if (this.props.location === 'profile') {
      reportTemplateObj.profile_template_id = DataViewStore.activeReportTemplate.profileTemplateId;
    } else {
      reportTemplateObj.app_id = DataViewStore.newReportTemplate.appId;
    }
    await DataViewActions.createReportTemplate(reportTemplateObj).then(response => {
      this.applyInitialReportVariables(response, initialReportTemplate).then(() => {
        const timeoutDuration = 3000; // ms
        setTimeout(() => {
          DataViewActions.fetchReportTemplate(response.id).then(newResponse => {
            DataViewStore.createAndAddReportTemplate(newResponse);
            DataViewStore.setActiveReportTemplate(newResponse.id);
            this.toggleSaving();
            this.toggleDialog();
            this.props.ToastStore.showToast(
              // $FlowFixMe
              I18n.t('js.report_template_has_been_created'),
              'success'
            );
          });
        }, timeoutDuration);
      });
    });
  }

  async handleSaveChanges() {
    this.toggleSaving();
    const { DataViewStore } = this.props;
    // change the individual report template columns first
    await _.map(DataViewStore.reportTemplateFiltersToDelete, filterId => {
      DataViewActions.deleteReportTemplateFilter(filterId);
    });
    await _.map(DataViewStore.activeReportTemplate.reportTemplateColumns, (reportTemplateColumn, index: number) => {
      _.map(reportTemplateColumn.reportTemplateFilters, reportTemplateFilter => {
        if (reportTemplateFilter.isPersisted) {
          DataViewActions.changeReportTemplateFilter({
            id: reportTemplateFilter.id,
            report_template_column_id: reportTemplateFilter.reportTemplateColumnId,
            operand: reportTemplateFilter.operand,
            test_value: reportTemplateFilter.testValue,
          });
        } else {
          DataViewActions.createReportTemplateFilter({
            report_template_column_id: reportTemplateFilter.reportTemplateColumnId,
            operand: reportTemplateFilter.operand,
            test_value: reportTemplateFilter.testValue,
          });
        }
      });
      DataViewActions.changeReportTemplateColumn({
        id: reportTemplateColumn.id,
        column_order_position: index,
        sort_direction: reportTemplateColumn.sortDirection,
        sort_priority: reportTemplateColumn.sortPriority,
        hidden: reportTemplateColumn.hidden,
      });
    });
    await setTimeout(() => {
      DataViewActions.changeReportTemplate({
        id: DataViewStore.activeReportTemplate.id,
        name: DataViewStore.activeReportTemplate.name,
        description: DataViewStore.activeReportTemplate.description,
        chart_configuration: DataViewStore.activeChartConfigurationToString,
        default_report_template: DataViewStore.activeReportTemplate.defaultReportTemplate,
        public_report_template: DataViewStore.activeReportTemplate.publicReportTemplate,
      }).then(response => {
        DataViewStore.reportTemplateFiltersToDelete = [];
        DataViewStore.createAndAddReportTemplate(response);
        DataViewStore.setActiveReportTemplate(response.id);
        this.props.ToastStore.showToast(
          // $FlowFixMe
          I18n.t('js.your_changes_have_been_saved'),
          'success'
        );
        this.toggleSaving();
      });
      // eslint-disable-next-line no-magic-numbers
    }, 3000);
  }

  render() {
    return (
      <ErrorBoundary>
        <Button
          icon="floppy-disk"
          text={
            this.props.DataViewStore.activeReportTemplate.isPersisted
              ? // $FlowFixMe
                I18n.t('js.save_changes')
              : // $FlowFixMe
                I18n.t('js.save_as_template')
          }
          disabled={this.props.DataViewStore.activeReportTemplate.isPersisted && this.props.DataViewStore.hasUnsavedChanges}
          className="push-10-r"
          onClick={this.handleSave.bind(this)}
          loading={this.state.isSaving}
          disabled={this.props.disabled}
        />
        {/* $FlowFixMe */}
        <NewReportTemplate
          dialogOpen={this.state.dialogOpen}
          isSaving={this.state.isSaving}
          toggleDialog={this.toggleDialog.bind(this)}
          handleSaveNewReportTemplate={this.handleSaveNewReportTemplate.bind(this)}
        />
      </ErrorBoundary>
    );
  }
}

type DeleteButtonState = {
  alertOpen: boolean,
  isDeleting: boolean,
  disabled: boolean,
};

@inject('DataViewStore', 'AppStore', 'ToastStore')
@observer
class DeleteButton extends React.Component<any, DeleteButtonState> {
  constructor(props) {
    super(props);
    this.state = { alertOpen: false, isDeleting: false };
  }

  toggleAlert = () => {
    this.setState({ alertOpen: !this.state.alertOpen });
  };

  toggleDeleting = () => {
    this.setState({ isDeleting: !this.state.isDeleting });
  };

  handleDelete = () => {
    this.toggleDeleting();
    const { DataViewStore } = this.props;
    DataViewActions.deleteReportTemplate(DataViewStore.activeReportTemplate.id)
      .then(response => {
        DataViewStore.removeReportTemplate(response.id);
        this.toggleDeleting();
        this.toggleAlert();
        // $FlowFixMe
        this.props.ToastStore.showToast(I18n.t('js.report_template_has_been_deleted'), 'success');
      })
      .then(() => {
        const url = location => {
          switch (location) {
            case 'app':
              return `/apps/${this.props.AppStore.activeApp.id}?tab=dataview`;
            case 'profile':
              return `/account/users`;
            default:
              throw new Error(`location: ${location} is not accounted for`);
          }
        };
        Utilities.navigate(url(DataViewStore.dataViewLocation));
      });
  };

  render() {
    return (
      <>
        <Button
          icon="trash"
          // $FlowFixMe
          text={I18n.t('js.delete')}
          disabled={!this.props.DataViewStore.activeReportTemplate.isPersisted || this.props.disabled}
          onClick={this.toggleAlert.bind(this)}
        />
        <Alert
          portalContainer={document.body}
          isOpen={this.state.alertOpen}
          intent={Intent.DANGER}
          cancelButtonText={I18n.t('js.cancel')}
          onCancel={this.toggleAlert.bind(this)}
          onConfirm={this.handleDelete.bind(this)}
          confirmButtonText={I18n.t('js.delete_report_template')}
        >
          {I18n.t('js.are_you_sure_you_want_to_delete_this_report_template')}
        </Alert>
      </>
    );
  }
}

@inject('DataViewStore', 'ToastStore')
@observer
class RunReportButton extends React.Component<any> {
  clickHandler = () => {
    // const { id } = this.props.DataViewStore.activeReportTemplate;
    Utilities.navigate(`/reports?tab=reports&report_template_id=${this.props.DataViewStore.activeReportTemplate.id}&run_report=true`);
  };

  render() {
    return (
      <Button
        icon="play"
        // $FlowFixMe
        text={I18n.t('js.run_report')}
        onClick={this.clickHandler}
        intent={Intent.SUCCESS}
        disabled={!this.props.DataViewStore.activeReportTemplate.isPersisted}
        className="run-button push-10-r"
      />
    );
  }
}

// ================================================================================================
// MAIN COMPONENT
// ================================================================================================

type DataViewProps = {
  DataViewStore: any,
  reportTemplateId: UUID,
  location: DataViewLocation,
};

@inject('DataViewStore')
@observer
export default class DataView extends React.Component<DataViewProps> {
  componentDidMount() {
    this.props.DataViewStore.isLoadingAllTableData = true;
    this.props.DataViewStore.isLoadingReportTemplatesData = true;

    this.props.DataViewStore.dataViewLocation = this.props.location;
    if (this.props.location === 'app') {
      this.props.DataViewStore.loadTableData(this.props.reportTemplateId);
    } else {
      this.props.DataViewStore.loadProfileTemplates().then(() => {
        this.props.DataViewStore.loadTableData(this.props.reportTemplateId);
      });
    }
  }

  handleColumnReorder = (oldIndex: number, newIndex: number, length: number) => {
    this.props.DataViewStore.reorderReportTemplateColumns(oldIndex, newIndex, length);
  };

  handleColumnHide = (columnIndex: number) => {
    this.props.DataViewStore.hideColumn(columnIndex);
  };

  handleColumnSort = (columnId: string, sortDirection: string) => {
    const columnToSort = _.find(this.props.DataViewStore.activeColumns, col => col.columnId === columnId);
    if (!columnToSort) {
      throw new Error('could not find column to sort');
    }
    const hasSort = _.find(this.props.DataViewStore.sortColumns, sortColumn => sortColumn.id === columnToSort.id);
    if (hasSort) {
      this.props.DataViewStore.setToHighestSortPriority(columnToSort.id);
      if (sortDirection !== columnToSort.sortDirection) {
        this.props.DataViewStore.toggleSortDirection(columnToSort.id);
      }
    } else {
      this.props.DataViewStore.addSort(sortDirection, columnToSort.id);
    }
  };

  handleTitleChange = (value: string) => {
    this.props.DataViewStore.activeReportTemplate.name = value;
  };

  handleDescriptionChange = (value: string) => {
    this.props.DataViewStore.activeReportTemplate.description = value;
  };

  handleToggleDefaultReportTemplate = () => {
    this.props.DataViewStore.activeReportTemplate.defaultReportTemplate = !this.props.DataViewStore.activeReportTemplate
      .defaultReportTemplate;
    _.map(this.props.DataViewStore.reportTemplates, reportTemplate => {
      if (reportTemplate.id != this.props.DataViewStore.activeReportTemplate.id) {
        reportTemplate.defaultReportTemplate = false;
      }
    });
  };

  handleTogglePublicReportTemplate = () => {
    this.props.DataViewStore.activeReportTemplate.publicReportTemplate = !this.props.DataViewStore.activeReportTemplate
      .publicReportTemplate;
  };

  renderProfileTemplateSelector = () => {
    if (this.props.location === 'profile') {
      // $FlowFixMe
      return (
        <Flexbox flexDirection="row" marginBottom="10px">
          <ProfileTemplateSelector />
        </Flexbox>
      );
    }
    return null;
  };

  renderTitleArea = () => {
    const { activeReportTemplate } = this.props.DataViewStore;

    if (activeReportTemplate.isPersisted) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="bp3-card" marginBottom="20px">
          <h2 className="bp3-label-no-margin">
            <EditableText
              value={activeReportTemplate.name}
              onChange={this.handleTitleChange.bind(this)}
              disabled={this.props.userPermissionLevel != 'owner'}
            />
          </h2>
          <EditableText
            multiline
            minLines={1}
            maxLines={3}
            value={activeReportTemplate.description}
            className="push-10-t"
            onChange={this.handleDescriptionChange.bind(this)}
            disabled={this.props.userPermissionLevel != 'owner'}
          />
          {this.props.userPermissionLevel === 'owner' ? (
            <Flexbox flexDirection="row" marginTop="20px">
              <Flexbox flexDirection="column" marginRight="20px">
                <Switch
                  className="bp3-control-no-margin"
                  checked={activeReportTemplate.publicReportTemplate}
                  label={I18n.t('js.public_report_template')}
                  onChange={() => this.handleTogglePublicReportTemplate()}
                  disabled={this.props.userPermissionLevel != 'owner'}
                />
              </Flexbox>
              <Flexbox flexDirection="column" marginRight="20px">
                <Switch
                  className="bp3-control-no-margin"
                  checked={activeReportTemplate.defaultReportTemplate}
                  label={I18n.t('js.default_report_template')}
                  onChange={() => this.handleToggleDefaultReportTemplate()}
                  disabled={this.props.userPermissionLevel != 'owner'}
                />
              </Flexbox>
            </Flexbox>
          ) : null}
        </Flexbox>
      );
    }
    return null;
  };

  renderToolbar = () => (
    <Flexbox width="100%" justifyContent="space-between" alignItems="flex-end" marginBottom="10px" className="toolbar">
      <Flexbox flexDirection="row">
        <ButtonGroup>
          <NewVisualization disabled={this.props.userPermissionLevel != 'owner'} />
          <FilterPicker />
          <SortPicker />
          <HiddenColumns />
        </ButtonGroup>
        <ReportTemplateSelector />
        <SaveButton location={this.props.location} disabled={this.props.userPermissionLevel != 'owner'} />
        <DeleteButton disabled={this.props.userPermissionLevel != 'owner'} />
      </Flexbox>
      <Flexbox flexDirection="row" alignItems="flex-end">
        <ReportSchedules
          reportTemplate={this.props.DataViewStore.activeReportTemplate}
          disabled={this.props.userPermissionLevel != 'owner' || !this.props.DataViewStore.activeReportTemplate.isPersisted}
        />
        <RunReportButton />
      </Flexbox>
    </Flexbox>
  );

  renderTableSection = () => {
    const { displayedTableData } = this.props.DataViewStore;
    if (displayedTableData.length !== 0) {
      return (
        <Flexbox maxHeight="75vh" className="table-container-box">
          <DataViewTable
            tableData={displayedTableData}
            isEditingReportTemplate
            handleColumnReorder={this.handleColumnReorder.bind(this)}
            handleColumnHide={this.handleColumnHide.bind(this)}
            handleColumnSort={this.handleColumnSort.bind(this)}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox marginTop="20px">
        <NonIdealState
          // $FlowFixMe
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.there_is_no_item_data')}
          icon="th"
        />
      </Flexbox>
    );
  };

  renderVisualization = () => {
    return <DataViewVisualization store={this.props.DataViewStore} isEditable={this.props.userPermissionLevel === 'owner'} />;
  };

  shouldRenderProfileDependentSection = () =>
    this.props.DataViewStore.dataViewLocation !== 'profile' || this.props.DataViewStore.profileTemplateIsSelected;

  render() {
    const { isLoadingReportTemplatesData, isLoadingAllTableData, hasTableData } = this.props.DataViewStore;

    if (isLoadingReportTemplatesData) {
      return (
        <Flexbox flexGrow={1} margin="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (!hasTableData && !isLoadingAllTableData) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} className="bp3-card push-20-t">
          <NonIdealState
            // $FlowFixMe
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_is_no_data_to_show')}
            icon="th"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1} width="100%">
        {this.renderProfileTemplateSelector()}
        {/* <ErrorBoundary> */}
        {this.shouldRenderProfileDependentSection && this.renderTitleArea()}
        {this.shouldRenderProfileDependentSection && this.renderToolbar()}
        {this.renderVisualization()}
        {this.shouldRenderProfileDependentSection && this.renderTableSection()}
        {/* </ErrorBoundary> */}
      </Flexbox>
    );
  }
}
