import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, NonIdealState, Spinner } from '@blueprintjs/core';
import AdminStoreCategoryRow from './AdminStoreCategoryRow';
import AdminNewStoreCategory from './AdminNewStoreCategory';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import _ from 'lodash';

@inject('AdminActions', 'AdminStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AdminStoreCategories extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleColumnSort(column) {
    this.props.AdminStore.setStoreCategorySortParams(column.id, column.sortDir);
  }

  componentDidMount() {
    this.props.AdminActions.fetchStoreCategories()
      .then(response => {
        _.map(response.data, storeCategory => {
          this.props.AdminStore.addStoreCategory(storeCategory);
        });
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderStoreCategory() {
    if (this.props.AdminStore.storeCategories.length > 0) {
      return (
        <Flexbox flexGrow={1} marginTop="20px">
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <SimpleTableHeader columns={this.props.AdminStore.storeCategoryColumns} handleColumnSort={this.handleColumnSort.bind(this)} />
            </thead>
            <tbody>
              {_.map(this.props.AdminStore.storeCategories, storeCategory => (
                <AdminStoreCategoryRow storeCategory={storeCategory} key={storeCategory.id} />
              ))}
            </tbody>
          </table>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexdirection="row" flexGrow={1} paddingTop="50px" paddingBottom="50px">
        <NonIdealState
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.there_is_nothing_to_show_right_now')}
          icon="git-repo"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }

  render() {
    if (this.isLoading) {
      return (
        <Flexbox flexdirection="row" className="bp3-card" flexGrow={1} paddingTop="50px" paddingBottom="50px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" className="bp3-card" flexGrow={1}>
        <h3 className={Classes.HEADING}>Store Categories</h3>
        <p>
          Create and edit store categories here. Once you have created a store category it will be automatically synced with Contentful. It
          will be in draft state but include Title and ID matched to PeopleGoal. You can then add content and publish when ready. If you
          delete a ccategory from this list it will be archived on Contentful.
        </p>
        <AdminNewStoreCategory /> {this.renderStoreCategory()}
        <Flexbox flexGrow={1} justifyContent="center">
          {/* {this.renderPagination()} */}
        </Flexbox>
      </Flexbox>
    );
  }
}
