/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import { observable } from 'mobx';
import ToastStore from '../../../stores/ToastStore';
import Cookies from 'js-cookie';
import { inject, observer } from 'mobx-react';
import App from '../../../models/AppModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import Utilities from '../../../utils/Utilities';
import classNames from 'classnames';
import _ from 'lodash';

const AppMultiSelect = MultiSelect.ofType(App);

@inject('ToastStore')
@observer
export default class ElementObjectSelectApps extends React.Component {
  @observable
  query = '';
  @observable
  searchApps = [];
  @observable
  selectedApps = [];
  @observable
  isLoading = false;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    _.map(this.props.element.elementObjectSelect.elementObjectSelectItemApps, itemApp => {
      this.selectedApps.push(itemApp.app);
    });
  }

  addApp(data) {
    const newApp = new App(
      data.id,
      data.workspace_id,
      data.workspace_folder_id,
      data.name,
      data.description,
      data.item,
      data.item_plural,
      data.item_layout,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.row_order_folder,
      data.user_access_level,
      data.webform_enabled,
      data.webform_email_required,
      data.webform_anonymous,
      data.webform_path,
      data.default_view_folder,
      data.default_process_visible,
      data.include_in_quick_create_menu,
      data.dummy_data,
      data.lock_participants,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.searchApps = _.unionBy([newApp], this.searchApps, 'id');
  }

  handleSearchApps(e) {
    const query = e.target.value;
    this.searchApps = [];
    this.query = query;
    if (query.length > 1) {
      this.toggleLoading();
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/apps?query=${query}`)
          .then(response => {
            let total = response.data.length;
            _.map(response.data, app => {
              this.addApp(app);
              total--;
            });
            if (total === 0) {
              resolve(response);
              this.toggleLoading();
            }
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
            this.toggleLoading();
          });
      });
    }
    return undefined;
  }

  renderAppLabel(item) {
    const iconClass = classNames('push-5-r fa', item.icon);
    return <span className={iconClass} />;
  }

  renderApp(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }
    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={_.truncate(item.name, { length: 50 })}
        label={this.renderAppLabel(item, details)}
      />
    );
  }

  renderResultSearch() {
    if (this.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.no_apps_found')} </span>
          </span>
        }
      />
    );
  }

  renderTag(app) {
    return <span id={app.id}>{app.name}</span>;
  }

  renderInputValue(app) {
    return app.name;
  }

  handleAppsSelect(app) {
    this.selectedApps = _.concat(this.selectedApps.slice(), [app]);
    const newItemApp = {
      id: Utilities.makeId(),
      element_object_select_id: this.props.element.elementObjectSelect.id,
      app_id: app.id,
    };
    this.props.addItemAppToCreate(newItemApp);
    this.handlePopoverClose();
  }

  handlePopoverClose() {
    this.searchApps = [];
    this.query = '';
  }

  handleAppRemove(app) {
    const appId = app.props.id;
    const newApps = _.filter(this.selectedApps.slice(), o => o.id !== appId);
    this.props.addItemAppToDelete(appId);
    this.selectedApps = newApps;
  }

  filterApp() {
    let possibleApps = this.searchApps;
    // filter out selected apps
    if (this.props.element.elementObjectSelect.optionType === 'multiple') {
      _.map(this.selectedApps, app => {
        possibleApps = _.filter(possibleApps, o => o.id !== app.id);
      });
    }
    possibleApps = _.orderBy(possibleApps, ['name'], ['asc']);
    return possibleApps;
  }

  renderRightElement() {
    if (this.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    return (
      <AppMultiSelect
        className="max-width"
        resetOnSelect={true}
        items={this.searchApps.slice()}
        itemListPredicate={this.filterApp.bind(this)}
        selectedItems={this.selectedApps.slice()}
        tagRenderer={this.renderTag.bind(this)}
        itemRenderer={this.renderApp.bind(this)}
        onItemSelect={this.handleAppsSelect.bind(this)}
        tagInputProps={{
          disabled: this.props.disabled,
          inputProps: {
            disabled: this.props.disabled,
            placeholder: I18n.t('js.search_for_a_app'),
            onChange: this.handleSearchApps.bind(this),
          },
          rightElement: this.renderRightElement(),
          className: 'bp3-tag-input',
          onRemove: this.handleAppRemove.bind(this),
        }}
        popoverProps={{
          usePortal: false,
          disabled: this.props.disabled,
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
