import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, HTMLSelect } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('DataViewStore', 'ToastStore')
@observer
export default class ChartCommonConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  handleLegendToggle() {
    const { activeChart, activeChartLegendEnabled } = this.props.DataViewStore;
    let newOptions = activeChart.options;
    if (activeChartLegendEnabled) {
      newOptions.legend = null;
    } else {
      newOptions.legend = {
        position: 'top',
        align: 'center',
      };
    }
    this.props.DataViewStore.updateActiveChart(newOptions, 'options');
  }

  handleLengendPositionChange(e) {
    const position = e.target.value;
    const { activeChart, activeChartLegendEnabled } = this.props.DataViewStore;
    let newOptions = activeChart.options;
    newOptions.legend.position = position;
    this.props.DataViewStore.updateActiveChart(newOptions, 'options');
  }

  handleLengendAlignmentChange(e) {
    const align = e.target.value;
    const { activeChart, activeChartLegendEnabled } = this.props.DataViewStore;
    let newOptions = activeChart.options;
    newOptions.legend.align = align;
    this.props.DataViewStore.updateActiveChart(newOptions, 'options');
  }

  handleAnnotationChange(e) {
    const annotation = e.target.value;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.annotation = annotation;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleInputChange(e) {
    this.props.DataViewStore.updateActiveChart(e.target.value, e.target.name);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.DataViewStore.activeChart[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.DataViewStore.activeChart[`${field}Error`]}</div>;
    }
    return undefined;
  }

  render() {
    const { activeChart, activeChartLegendEnabled } = this.props.DataViewStore;
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="column">
          <div
            className={
              _.includes(this.state.isBlurred, 'name') && !activeChart.nameValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <label className="bp3-label" htmlFor="name">
              {I18n.t('js.name')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <div className="bp3-form-content">
              <div
                className={
                  _.includes(this.state.isBlurred, 'name') && !activeChart.nameValid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="text"
                  name="name"
                  value={activeChart.name}
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </div>
              {this.renderErrors('name')}
            </div>
          </div>
          <Flexbox flexDirection="column" flexGrow={1}>
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="annotation">
                {I18n.t('js.annotation')}
              </label>
              <div className="bp3-form-content">
                <div className="bp3-input-group">
                  <textarea
                    autoComplete="off"
                    rows={2}
                    className="bp3-input bp3-fill"
                    placeholder={I18n.t('js.annotation_placeholder')}
                    type="text"
                    name="name"
                    value={activeChart.dataConfig.annotation}
                    onChange={this.handleAnnotationChange.bind(this)}
                    dir="auto"
                  />
                </div>
              </div>
            </div>
          </Flexbox>
          {activeChart.type != 'metric' ? (
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={activeChartLegendEnabled}
                label={I18n.t('js.legend_enabled')}
                onChange={this.handleLegendToggle.bind(this)}
              />
            </Flexbox>
          ) : null}
        </Flexbox>
        {activeChart.type != 'metric' ? (
          <Flexbox flexDirection="row" marginTop="20px">
            <Flexbox flexDirection="column" marginRight="20px">
              <label className="bp3-label" htmlFor="legend_position">
                {I18n.t('js.legend_position')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <div className="bp3-form-content">
                <div className="bp3-input-group">
                  <HTMLSelect
                    className="push-10-r"
                    disabled={!activeChartLegendEnabled}
                    onChange={this.handleLengendPositionChange.bind(this)}
                    value={activeChartLegendEnabled ? activeChart.options.legend.position : ''}
                  >
                    <option value="top" id="top">
                      {I18n.t('js.top')}
                    </option>
                    <option value="left" id="left">
                      {I18n.t('js.left')}
                    </option>
                    <option value="right" id="right">
                      {I18n.t('js.right')}
                    </option>
                    <option value="bottom" id="bottom">
                      {I18n.t('js.bottom')}
                    </option>
                  </HTMLSelect>
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column" marginRight="20px">
              <label className="bp3-label" htmlFor="legend_alignment">
                {I18n.t('js.legend_alignment')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <div className="bp3-form-content">
                <div className="bp3-input-group">
                  <HTMLSelect
                    className="push-10-r"
                    disabled={!activeChartLegendEnabled}
                    onChange={this.handleLengendAlignmentChange.bind(this)}
                    value={activeChartLegendEnabled ? activeChart.options.legend.align : ''}
                  >
                    <option value="start" id="start">
                      {I18n.t('js.start')}
                    </option>
                    <option value="center" id="center">
                      {I18n.t('js.center')}
                    </option>
                    <option value="end" id="end">
                      {I18n.t('js.end')}
                    </option>
                  </HTMLSelect>
                </div>
              </div>
            </Flexbox>
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }
}
