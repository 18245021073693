/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
// @flow
import _ from 'lodash';
import moment from 'moment';
import ReportTemplate from '../models/ReportTemplateModel';
import ReportTemplateColumn from '../models/ReportTemplateColumnModel';
import ReportTemplateFilter from '../models/ReportTemplateFilterModel';
import type { ItemRowData, ItemRowDatum, TableData, UUID } from '../types/DataViewTypes';
import CurrentUserStore from '../stores/CurrentUserStore';
import Utilities from './Utilities';

const safeToLowerCase = (value: ?string) => (value ? value.toLowerCase() : '');

class ReportUtilities {
  static emptyReportTemplate({ appId, profileTemplateId }: { appId: ?UUID, profileTemplateId: ?UUID }): ReportTemplate {
    return new ReportTemplate({
      id: Utilities.makeId(),
      app_id: appId,
      profile_template_id: profileTemplateId,
      // $FlowFixMe
      name: I18n.t('js.new_report_template'),
      description: I18n.t('js.new_report_template_description'),
      report_template_columns: [],
      chart_configuration: '',
      default_report_template: false,
      public_report_template: false,
    });
  }

  static generateReportTemplateColumnsFromTabledata(itemRowData: ItemRowData, reportTemplateId: UUID) {
    // BEWARE: This was an object literal, not an array of  ReportTemplateColumns and some bugs may occur.
    return itemRowData.map(
      (itemRowDatum, index) =>
        new ReportTemplateColumn({
          id: Utilities.makeId(),
          report_template_id: reportTemplateId,
          column_name: itemRowDatum.column_name,
          column_id: itemRowDatum.column_id,
          column_type: itemRowDatum.column_type,
          column_order: index,
          hidden: false,
          sort_priority: 1000,
          sort_direction: 'asc',
        })
    );
  }

  static applyColumnOrder = _.curryRight((tableData: TableData, reportTemplate: ReportTemplate): TableData => {
    const newTableData = _.cloneDeep(tableData);
    newTableData.forEach((itemRowData, index) => {
      const row = newTableData[index];
      const newRow: ItemRowData = [];
      reportTemplate.reportTemplateColumns.forEach(column => {
        const foundColumn: ?ItemRowDatum = row.find(col => col.column_id === column.columnId);
        if (foundColumn) {
          newRow.push(foundColumn);
        } else {
          // console.log(`Could not find column with id: ${column.columnId}`);
        }
        newTableData[index] = newRow;
      });
    });
    return newTableData;
  });

  static applyHiddenColumns = _.curryRight((tableData: TableData, reportTemplate: ReportTemplate): TableData =>
    reportTemplate.reportTemplateColumns.length === 0
      ? tableData
      : tableData.map(itemRowData =>
          itemRowData.filter(itemRowDatum => {
            const foundReportTemplateColumn = reportTemplate.reportTemplateColumns.find(
              reportTemplateColumn => reportTemplateColumn.columnId === itemRowDatum.column_id
            );
            if (!foundReportTemplateColumn) {
              throw new Error('error finding report template column');
            }
            return !foundReportTemplateColumn.hidden;
          })
        )
  );

  static applyFilters = _.curryRight((tableData: TableData, reportTemplate: ReportTemplate): TableData => {
    let excludedRows = [];
    let filteredData = [];

    _.map(reportTemplate.reportTemplateColumns, reportTemplateColumn => {
      _.map(reportTemplateColumn.reportTemplateFilters, reportTemplateFilter => {
        _.map(tableData, rowData => {
          const itemRowDatum = _.find(rowData, o => o.column_id === reportTemplateColumn.columnId);
          const { operand, testValue } = reportTemplateFilter;
          let interpolatedTestValue = this.interpolateTestValue(testValue);
          const shouldExcludeRow = !this.testRow(operand, interpolatedTestValue, itemRowDatum);
          if (shouldExcludeRow) {
            excludedRows.push(rowData[0].column_value);
          }
        });
      });
    });
    excludedRows = _.uniq(excludedRows);
    _.map(tableData, rowData => {
      const excluded = _.find(excludedRows, o => o === rowData[0].column_value);
      if (!excluded) {
        filteredData.push(rowData);
      }
    });
    return filteredData;
  });

  static testRow = (operand, testValue, itemRowDatum) => {
    switch (operand) {
      case 'equals':
        return itemRowDatum.column_type === 'datetime'
          ? moment.utc(new Date(itemRowDatum.column_value)).isSame(moment.utc(testValue, 'DD/MM/YYYY'), 'day')
          : itemRowDatum.column_number_value !== null
          ? parseFloat(itemRowDatum.column_number_value) === parseFloat(testValue)
          : parseFloat(itemRowDatum.column_value) === parseFloat(testValue);
      case 'contains':
        return safeToLowerCase(itemRowDatum.column_value).includes(testValue.toLowerCase());
      case 'does_not_contain':
        return !safeToLowerCase(itemRowDatum.column_value).includes(testValue.toLowerCase());
      case 'starts_with':
        return safeToLowerCase(itemRowDatum.column_value).startsWith(testValue.toLowerCase());
      case 'ends_with':
        return safeToLowerCase(itemRowDatum.column_value).endsWith(testValue.toLowerCase());
      case 'is_blank':
        return !itemRowDatum.column_value;
      case 'is_not_blank':
        return !!itemRowDatum.column_value; // !! converts to a boolean
      case 'is_greater_than':
        return itemRowDatum.column_type === 'datetime'
          ? moment.utc(new Date(itemRowDatum.column_value)).isAfter(moment.utc(testValue), 'days')
          : itemRowDatum.column_number_value !== null
          ? parseFloat(itemRowDatum.column_number_value) > parseFloat(testValue)
          : parseFloat(itemRowDatum.column_value) > parseFloat(testValue);
      case 'is_less_than':
        return itemRowDatum.column_type === 'datetime'
          ? moment.utc(new Date(itemRowDatum.column_value)).isBefore(moment.utc(testValue), 'days')
          : itemRowDatum.column_number_value !== null
          ? parseFloat(itemRowDatum.column_number_value) < parseFloat(testValue)
          : parseFloat(itemRowDatum.column_value) < parseFloat(testValue);
      case 'is_within_last_days':
        return (
          moment.utc(new Date(itemRowDatum.column_value)).isAfter(moment.utc().subtract(testValue, 'days')) &&
          moment.utc(new Date(itemRowDatum.column_value)).isBefore(moment.utc())
        );
      case 'is_within_next_days':
        return (
          moment.utc(new Date(itemRowDatum.column_value)).isBefore(moment.utc().add(parseInt(testValue, 10), 'days')) &&
          moment.utc(new Date(itemRowDatum.column_value)).isAfter(moment.utc())
        );
      default:
        throw new Error(`There is no case for the given operand: ${operand}`);
    }
  };

  static interpolateTestValue = testValue => {
    const { currentUser } = CurrentUserStore;
    let result = testValue;
    if (result.includes('user')) {
      const attribute = testValue.split('.')[1];
      if (currentUser[attribute]) {
        result = currentUser[attribute];
      }
    }
    return result;
  };

  static applySorts = _.curryRight((tableData: TableData, sortColumns: Array<ReportTemplateColumn>): TableData => {
    return _.orderBy(
      tableData,
      sortColumns.map(sortColumn => row => {
        const foundCell = row.find(cell => cell.column_id === sortColumn.columnId);
        if (!foundCell) {
          throw new Error(`Could not find cell with id: ${sortColumn.columnId}`);
        }
        return foundCell.column_value;
      }),
      sortColumns.map(sortColumn => sortColumn.sortDirection)
    );
  });

  static tableTimeFormat = 'DD-MM-YYYY, HH:mm a';
}

export default ReportUtilities;
