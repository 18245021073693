import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import ReportScheduleStore from '../../stores/ReportScheduleStore';
import { FormGroup, HTMLSelect, NumericInput, Classes, TextArea, Intent, Button, Alert } from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import moment from 'moment';
import _ from 'lodash';

@observer
export default class ReportSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isDeleting: false,
      showDeletionAlert: false,
    };
  }

  handleCancel() {
    ReportScheduleStore.setAction('index');
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    const { activeReportTemplateSchedule } = ReportScheduleStore;
    const reportTemplateScheduleObj = {
      report_template_id: activeReportTemplateSchedule.reportTemplateId,
      frequency: activeReportTemplateSchedule.frequency,
      interval: activeReportTemplateSchedule.interval,
      start_date: moment.utc(activeReportTemplateSchedule.startDate),
      end_date: moment.utc(activeReportTemplateSchedule.endDate),
      recipients: activeReportTemplateSchedule.recipients,
      message: activeReportTemplateSchedule.message,
    };
    if (activeReportTemplateSchedule.isPersisted) {
      // Save changes
      ReportScheduleStore.updateReportTemplateSchedule(reportTemplateScheduleObj)
        .then(response => {
          this.setState({ isSaving: false });
          ReportScheduleStore.setAction('index');
        })
        .catch(error => {
          this.setState({ isSaving: false });
        });
    } else {
      // Save new instance
      ReportScheduleStore.createReportTemplateSchedule(reportTemplateScheduleObj)
        .then(response => {
          this.setState({ isSaving: false });
          ReportScheduleStore.setAction('index');
        })
        .catch(error => {
          this.setState({ isSaving: false });
        });
    }
  }

  toggleDeleteAlert() {
    this.setState({ showDeletionAlert: !this.state.showDeletionAlert });
  }

  handleDelete() {
    ReportScheduleStore.deleteReportTemplateSchedule();
  }

  handleAttrChange(e) {
    const { value, name } = e.target;
    ReportScheduleStore.updateActiveReportTemplateSchedule(value, name);
  }

  handleDateChange(date, attr) {
    if (date === null) {
      ReportScheduleStore.updateActiveReportTemplateSchedule(date, attr);
    } else {
      ReportScheduleStore.updateActiveReportTemplateSchedule(date.setMinutes(0), attr);
    }
  }

  render() {
    const { activeReportTemplateSchedule } = ReportScheduleStore;
    if (activeReportTemplateSchedule) {
      return (
        <Flexbox flexDirection="column" className="bp3-card">
          <form onSubmit={this.handleSave.bind(this)}>
            <Flexbox flexDirection="row">
              <Flexbox flexDirection="column">
                <FormGroup helperText={null} label={I18n.t('js.frequency')} labelFor="frequency" labelInfo={`(${I18n.t('js.required')})`}>
                  <HTMLSelect
                    className="push-10-r"
                    name="frequency"
                    onChange={e => this.handleAttrChange(e)}
                    value={activeReportTemplateSchedule.frequency}
                  >
                    {_.map(activeReportTemplateSchedule.availableFrequencies, frequency => {
                      return (
                        <option key={frequency} value={frequency}>
                          {_.capitalize(frequency)}
                        </option>
                      );
                    })}
                  </HTMLSelect>
                </FormGroup>
              </Flexbox>
              <Flexbox flexDirection="column" marginLeft="20px">
                <FormGroup
                  helperText={I18n.t('js.interval_helper_text', {
                    interval: activeReportTemplateSchedule.interval,
                    frequency: activeReportTemplateSchedule.frequencyPlural,
                  })}
                  label={I18n.t('js.interval')}
                  labelFor="interval"
                  labelInfo={`(${I18n.t('js.required')})`}
                >
                  <NumericInput
                    allowNumericCharactersOnly={true}
                    name="interval"
                    min={1}
                    value={activeReportTemplateSchedule.interval}
                    onValueChange={val => ReportScheduleStore.updateActiveReportTemplateSchedule(val, 'interval')}
                  />
                </FormGroup>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" marginTop="20px">
              <Flexbox flexDirection="column">
                <FormGroup
                  helperText={I18n.t('js.select_start_date_and_hour')}
                  label={I18n.t('js.start_date')}
                  labelFor="startDate"
                  labelInfo={`(${I18n.t('js.required')})`}
                >
                  <DatePicker
                    className={Classes.ELEVATION_1}
                    value={new Date(activeReportTemplateSchedule.startDate)}
                    onChange={date => this.handleDateChange(date, 'startDate')}
                    highlightCurrentDay={true}
                    timePickerProps={{
                      showArrowButtons: true,
                    }}
                    showActionsBar={true}
                    popoverProps={{
                      usePortal: false,
                      inline: false,
                    }}
                  />
                </FormGroup>
              </Flexbox>
              <Flexbox flexDirection="column" marginLeft="40px">
                <FormGroup helperText={I18n.t('js.optional_end_date')} label={I18n.t('js.end_date')} labelFor="startDate">
                  <DatePicker
                    className={Classes.ELEVATION_1}
                    value={new Date(activeReportTemplateSchedule.endDate)}
                    onChange={date => this.handleDateChange(date, 'endDate')}
                    highlightCurrentDay={true}
                    timePickerProps={{
                      showArrowButtons: true,
                    }}
                    showActionsBar={true}
                    popoverProps={{
                      usePortal: false,
                      inline: false,
                    }}
                  />
                </FormGroup>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <FormGroup
                helperText={I18n.t('js.comma_separated_list_of_email_addresses')}
                label={I18n.t('js.recipients')}
                labelFor="recipients"
                labelInfo={`(${I18n.t('js.required')})`}
              >
                <TextArea
                  name="recipients"
                  className="bp3-fill"
                  onChange={e => this.handleAttrChange(e)}
                  value={activeReportTemplateSchedule.recipients}
                />
              </FormGroup>
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <FormGroup
                helperText={I18n.t('js.optional_message_to_include_in_the_report_notification')}
                label={I18n.t('js.message')}
                labelFor="message"
              >
                <TextArea
                  name="message"
                  className="bp3-fill"
                  onChange={e => this.handleAttrChange(e)}
                  value={activeReportTemplateSchedule.message}
                />
              </FormGroup>
            </Flexbox>
            <Flexbox flexDirection="row" justifyContent="space-between">
              <Flexbox flexDirection="row">
                <Button
                  type="submit"
                  intent={Intent.PRIMARY}
                  loading={this.state.isSaving}
                  icon="floppy-disk"
                  onClick={this.handleSave.bind(this)}
                  text={I18n.t('js.save_report_schedule')}
                  className="push-10-r"
                />
                <Button icon="cross" onClick={this.handleCancel.bind(this)} text={I18n.t('js.cancel')} />
              </Flexbox>
              <Button
                icon="trash"
                onClick={this.toggleDeleteAlert.bind(this)}
                text={I18n.t('js.delete')}
                intent={Intent.DANGER}
                disabled={!activeReportTemplateSchedule.isPersisted}
              />
              <Alert
                portalContainer={document.body}
                isOpen={this.state.showDeletionAlert}
                cancelButtonText={I18n.t('js.cancel')}
                onCancel={() => this.toggleDeleteAlert()}
                confirmButtonText={I18n.t('js.delete')}
                onConfirm={() => this.handleDelete()}
                intent={Intent.DANGER}
              >
                <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
                {I18n.t('js.are_you_sure_you_wish_to_delete_this_report_template_schedule')}
              </Alert>
            </Flexbox>
          </form>
        </Flexbox>
      );
    }
    return null;
  }
}
