import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes, Switch, NonIdealState, Spinner, AnchorButton, Intent } from '@blueprintjs/core';
import ItemWidget from '../items/ItemWidget';
import Paginator from '../shared/Paginator';
import Emoji from '../shared/Emoji';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import FormElementDisplay from '../form_elements/FormElementDisplay';
import moment from 'moment';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class DashboardProfileSnippet extends React.Component {
  componentDidMount() {
    // Fetch profile values for current user
    const { currentUser, activeAccount } = this.props.CurrentUserStore;
    this.props.UserProfileStore.user = currentUser;
    this.props.UserProfileTemplateStore.resetStartingAttributes();
    this.props.TemplateActions.fetchProfileTemplate(activeAccount.profileTemplateId)
      .then(response => {
        this.props.UserProfileTemplateStore.addProfileTemplate(response.data);
        this.props.UserProfileTemplateStore.setActiveProfileTemplate(this.props.UserProfileTemplateStore.profileTemplates[0]);
        this.props.UserProfileTemplateStore.toggleLoading();
      })
      .catch(err => {
        this.props.UserProfileTemplateStore.toggleLoading();
      });
  }

  render() {
    if (this.props.UserProfileTemplateStore.isLoading || this.props.UserProfileTemplateStore.dashboardProfileElements.length === 0) {
      return null;
    }
    const { currentUser, activeAccount } = this.props.CurrentUserStore;
    return (
      <Flexbox flexDirection="column" flexGrow={1} paddingBottom="0px">
        <Flexbox flexDirection="column">
          {_.map(this.props.UserProfileTemplateStore.dashboardProfileElements, element => {
            return (
              <Flexbox flexDirection="column" className="animated fadeIn" key={element.id}>
                <FormElementDisplay element={element} showLabels={false} />
              </Flexbox>
            );
          })}
        </Flexbox>
      </Flexbox>
    );
  }
}
