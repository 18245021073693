import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Classes, Collapse, Dialog, EditableText, Intent, NonIdealState } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Cookies from 'js-cookie';
import InjectedCheckoutForm from './AccountBillingCheckoutForm';
import AccountBillingInvoices from './AccountBillingInvoices';
import _ from 'lodash';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class AccountBillingSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      changeDialogOpen: false,
      isCancelling: false,
      isLoading: false,
      addingDiscount: false,
      confirmedInput: '',
    };
  }

  toggleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  toggleCancellation() {
    this.setState({ isCancelling: !this.state.isCancelling });
  }

  toggleDiscountCode() {
    this.setState({ addingDiscount: !this.state.addingDiscount });
  }

  handleInputChange(input, attribute) {
    this.props.AccountBillingStore.activeSubscription[attribute] = input;
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleInputConfirm(input, attribute) {
    const subscriptionObj = {
      id: this.props.AccountBillingStore.activeSubscription.id,
    };
    subscriptionObj[_.snakeCase(attribute)] = input;
    this.props.AccountBillingActions.changeSubscription(subscriptionObj);
  }

  handleCancelInputChange(e) {
    this.setState({ confirmedInput: e.target.value });
  }

  handleCancelSubscription() {
    this.toggleLoading();
    this.props.AccountBillingActions.deleteSubscription(this.props.AccountBillingStore.activeSubscription.id)
      .then(() => {
        this.props.AccountBillingStore.resetSubscriptions();
        this.toggleDialog();
        this.toggleLoading();
        this.props.ToastStore.showToast(I18n.t('js.your_subscription_has_been_cancelled'), 'success');
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderCancellation() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="trash"
        isOpen={this.state.isCancelling}
        canOutsideClickClose={false}
        onClose={this.toggleCancellation.bind(this)}
        title={I18n.t('js.cancel_subscription')}
      >
        <Flexbox flexDirection="column" flexGrow={1}>
          <div className="bp3-dialog-body">
            {I18n.t('js.cancel_subscription_confirm', {
              name: this.props.AccountBillingStore.activeSubscription.companyName,
            })}
            <Flexbox flexGrow={1} marginTop="20px">
              <input
                autoComplete="off"
                type="text"
                className="bp3-input bp3-intent-danger bp3-fill"
                placeholder={this.props.AccountBillingStore.activeSubscription.companyName}
                onChange={this.handleCancelInputChange.bind(this)}
              />
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={() => this.toggleCancellation().bind(this)} />
              <Button
                intent={Intent.DANGER}
                loading={this.state.isLoading}
                onClick={this.handleCancelSubscription.bind(this)}
                text={I18n.t('js.cancel_subscription')}
                disabled={
                  this.props.AccountBillingStore.activeSubscription.companyName.toLowerCase() !== this.state.confirmedInput.toLowerCase()
                }
              />
            </div>
          </div>
        </Flexbox>
      </Dialog>
    );
  }

  render() {
    if (this.props.AccountBillingStore.subscriptions.length === 0) {
      return (
        <Flexbox flexGrow={1} paddingTop="20px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_active_subscriptions_for_this_account')}
            icon="credit-card"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    const {
      subscribedPlan = {
        nickname: '',
        interval: 'month',
        formattedAmount: '0.00',
      },
    } = this.props.AccountBillingStore;
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox className="bp3-card" flexDirection="column" flexGrow={1}>
          <p className="bp3-text-muted">{I18n.t('js.your_subscription')}</p>
          <h2 className={Classes.HEADING}>
            {this.props.AccountBillingStore.activeSubscription.quantity} x {subscribedPlan.nickname} {I18n.t('js.licence').toLowerCase()}
          </h2>
          <hr />
          <Flexbox flexDirection="column" flexGrow={1} marginTop="20px">
            <Flexbox flexDirection="row" marginTop="5px">
              <Flexbox className="subscription-attribute">
                <h6 className={Classes.HEADING}>{I18n.t('js.status')}</h6>
              </Flexbox>
              <Flexbox>
                <p>
                  <span
                    className={
                      this.props.AccountBillingStore.activeSubscription.status === 'trialing'
                        ? 'bp3-tag bp3-intent-warning'
                        : 'bp3-tag bp3-intent-success'
                    }
                  >
                    {_.capitalize(this.props.AccountBillingStore.activeSubscription.status)}
                  </span>
                </p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" marginTop="5px">
              <Flexbox className="subscription-attribute">
                <h6 className={Classes.HEADING}>{I18n.t('js.trial_end_date')}</h6>
              </Flexbox>
              <Flexbox>
                <p>{this.props.AccountBillingStore.activeSubscription.trialEndToDate}</p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" marginTop="5px">
              <Flexbox className="subscription-attribute">
                <h6 className={Classes.HEADING}>{I18n.t('js.current_period')}</h6>
              </Flexbox>
              <Flexbox>
                <p>
                  {this.props.AccountBillingStore.activeSubscription.currentPeriodStartToDate} -{' '}
                  {this.props.AccountBillingStore.activeSubscription.currentPeriodEndToDate}
                </p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row">
              <Flexbox className="subscription-attribute">
                <h6 className={`${Classes.HEADING} pad-5-t`}>{I18n.t('js.company_name')}</h6>
              </Flexbox>
              <Flexbox flexGrow={0} flexDirection="column">
                <EditableText
                  value={this.props.AccountBillingStore.activeSubscription.companyName}
                  placeholder={I18n.t('js.company_name')}
                  onChange={input => this.handleInputChange(input, 'companyName')}
                  onConfirm={input => this.handleInputConfirm(input, 'companyName')}
                />
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" marginTop="10px">
              <Flexbox className="subscription-attribute">
                <h6 className={`${Classes.HEADING} pad-5-t`}>{I18n.t('js.address')}</h6>
              </Flexbox>
              <Flexbox flexDirection="column">
                <Flexbox>
                  <EditableText
                    value={this.props.AccountBillingStore.activeSubscription.addressLine1}
                    placeholder={I18n.t('js.address_line_1')}
                    onChange={input => this.handleInputChange(input, 'addressLine1')}
                    onConfirm={input => this.handleInputConfirm(input, 'addressLine1')}
                  />
                </Flexbox>
                <Flexbox marginTop="0px">
                  <EditableText
                    value={this.props.AccountBillingStore.activeSubscription.addressLine2}
                    placeholder={I18n.t('js.address_line_2')}
                    onChange={input => this.handleInputChange(input, 'addressLine2')}
                    onConfirm={input => this.handleInputConfirm(input, 'addressLine2')}
                  />
                </Flexbox>
                <Flexbox marginTop="0px">
                  <EditableText
                    value={this.props.AccountBillingStore.activeSubscription.city}
                    placeholder={I18n.t('js.city')}
                    onChange={input => this.handleInputChange(input, 'city')}
                    onConfirm={input => this.handleInputConfirm(input, 'city')}
                  />
                </Flexbox>
                <Flexbox marginTop="0px">
                  <EditableText
                    value={this.props.AccountBillingStore.activeSubscription.stateCounty}
                    placeholder={I18n.t('js.state_county')}
                    onChange={input => this.handleInputChange(input, 'stateCounty')}
                    onConfirm={input => this.handleInputConfirm(input, 'stateCounty')}
                  />
                </Flexbox>
                <Flexbox marginTop="0px">
                  <EditableText
                    value={this.props.AccountBillingStore.activeSubscription.country}
                    placeholder={I18n.t('js.country')}
                    onChange={input => this.handleInputChange(input, 'country')}
                    onConfirm={input => this.handleInputConfirm(input, 'country')}
                  />
                </Flexbox>
                <Flexbox marginTop="0px">
                  <EditableText
                    value={this.props.AccountBillingStore.activeSubscription.zip}
                    placeholder={I18n.t('js.zip')}
                    onChange={input => this.handleInputChange(input, 'zip')}
                    onConfirm={input => this.handleInputConfirm(input, 'zip')}
                  />
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" marginTop="10px">
              <Flexbox className="subscription-attribute">
                <h6 className={`${Classes.HEADING} pad-5-t`}>{I18n.t('js.tax_number')}</h6>
              </Flexbox>
              <Flexbox flexGrow={0} flexDirection="column">
                <EditableText
                  value={this.props.AccountBillingStore.activeSubscription.taxNumber}
                  placeholder={I18n.t('js.tax_number')}
                  onChange={input => this.handleInputChange(input, 'taxNumber')}
                  onConfirm={input => this.handleInputConfirm(input, 'taxNumber')}
                />
              </Flexbox>
            </Flexbox>
            <Collapse isOpen={this.state.addingDiscount}>
              <Flexbox flexDirection="row" marginTop="10px">
                <Flexbox className="subscription-attribute">
                  <h6 className={`${Classes.HEADING} pad-5-t`}>{I18n.t('js.discount_code')}</h6>
                </Flexbox>
                <Flexbox flexGrow={0} flexDirection="column">
                  <EditableText
                    value={this.props.AccountBillingStore.activeSubscription.coupon}
                    placeholder={I18n.t('js.discount_code')}
                    onChange={input => this.handleInputChange(input, 'coupon')}
                    onConfirm={input => this.handleInputConfirm(input, 'coupon')}
                  />
                </Flexbox>
              </Flexbox>
            </Collapse>
          </Flexbox>
          <Flexbox marginTop="20px">
            <Button
              text={I18n.t('js.add_new_card')}
              icon="credit-card"
              className="bp3-intent-primary push-10-r"
              onClick={() => this.toggleDialog()}
            />
            <Button
              text={I18n.t('js.add_a_discount_code')}
              icon="percentage"
              className="push-10-r"
              onClick={this.toggleDiscountCode.bind(this)}
            />
            <Button text={I18n.t('js.cancel_subscription')} icon="trash" onClick={() => this.toggleCancellation()} />
          </Flexbox>
          {this.renderCancellation()}
          <StripeProvider apiKey={Cookies.get('stripePublishableKey')}>
            <Elements
              fonts={[
                {
                  cssSrc: 'https://fonts.googleapis.com/css?family=Inter:300,400,600,700',
                },
              ]}
            >
              <InjectedCheckoutForm toggleDialog={this.toggleDialog.bind(this)} dialogOpen={this.state.dialogOpen} action="changeCard" />
            </Elements>
          </StripeProvider>
        </Flexbox>
        <Flexbox marginTop="20px">
          <AccountBillingInvoices />
        </Flexbox>
      </Flexbox>
    );
  }
}
