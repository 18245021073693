// @flow
import _ from 'lodash';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ReportTemplateSchedule from '../models/ReportTemplateScheduleModel';
import CurrentUserStore from './CurrentUserStore';
import { action, computed, observable } from 'mobx';

axiosCancel(axios, {
  debug: false, // default
});

class ReportScheduleStore {
  @observable
  action = 'index';
  @observable
  reportTemplate = null;
  @observable
  reportTemplateSchedules = [];
  @observable
  activeReportTemplateSchedule = null;

  // Actions

  @action
  setAction(action) {
    if (action === 'new') {
      this.activeReportTemplateSchedule = new ReportTemplateSchedule({
        report_template_id: this.reportTemplate.id,
        frequency: 'monthly',
        interval: 1,
        recipients: `${CurrentUserStore.currentUser.email}`,
      });
    }
    this.action = action;
  }

  @action
  setActiveReportTemplateSchedule(reportTemplateSchedule) {
    this.activeReportTemplateSchedule = _.cloneDeep(reportTemplateSchedule);
  }

  @action
  updateActiveReportTemplateSchedule(value, attribute) {
    this.activeReportTemplateSchedule[attribute] = value;
  }

  @action
  resetStartingAttributes() {
    this.action = 'index';
    this.reportTemplate = null;
    this.reportTemplateSchedules = [];
  }

  @action
  addReportTemplateSchedule(data) {
    const newReportTemplateSchedule = new ReportTemplateSchedule(data);
    this.reportTemplateSchedules = _.unionBy([newReportTemplateSchedule], this.reportTemplateSchedules, 'id');
    this.reportTemplateSchedules = _.orderBy(this.reportTemplateSchedules, ['createdAt'], ['asc']);
  }

  @action
  removeReportTemplateSchedule(id) {
    this.reportTemplateSchedules = _.filter(this.reportTemplateSchedules, o => o.id !== id);
  }

  // Server-side functions

  @action
  fetchReportTemplateSchedules() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/report_template_schedules?report_template_id=${this.reportTemplate.id}`)
        .then(response => {
          resolve(response);
          _.map(response.data, schedule => {
            this.addReportTemplateSchedule(schedule);
          });
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createReportTemplateSchedule(reportTemplateScheduleObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/report_template_schedules`, reportTemplateScheduleObj)
        .then(response => {
          resolve(response);
          this.addReportTemplateSchedule(response.data);
          ToastStore.showToast(I18n.t('js.report_template_schedule_has_been_created'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  updateReportTemplateSchedule(reportTemplateScheduleObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/report_template_schedules/${this.activeReportTemplateSchedule.id}`, reportTemplateScheduleObj)
        .then(response => {
          resolve(response);
          this.addReportTemplateSchedule(response.data);
          ToastStore.showToast(I18n.t('js.report_template_schedule_has_been_updated'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteReportTemplateSchedule() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/report_template_schedules/${this.activeReportTemplateSchedule.id}`)
        .then(response => {
          resolve(response);
          this.removeReportTemplateSchedule(response.data.id);
          this.setAction('index');
          ToastStore.showToast(I18n.t('js.report_template_schedule_has_been_deleted'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}

const store = new ReportScheduleStore();
export default store;
