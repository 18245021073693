/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class Section {
  @observable id;
  @observable sectionType;
  @observable appId;
  @observable profileTemplateId;
  @observable title;
  @observable privateView;
  @observable protectedView;
  @observable accountOwnerOnlyView;
  @observable defaultCollapsed;
  @observable optional;
  @observable rowOrder;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    sectionType,
    appId,
    profileTemplateId,
    title,
    privateView,
    protectedView,
    accountOwnerOnlyView,
    defaultCollapsed,
    optional,
    rowOrder,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.sectionType = sectionType || '';
    this.appId = appId || '';
    this.profileTemplateId = profileTemplateId || '';
    this.title = title || '';
    typeof privateView === 'undefined' ? (this.privateView = false) : (this.privateView = privateView);
    typeof protectedView === 'undefined' ? (this.protectedView = false) : (this.protectedView = protectedView);
    typeof accountOwnerOnlyView === 'undefined' ? (this.accountOwnerOnlyView = false) : (this.accountOwnerOnlyView = accountOwnerOnlyView);
    typeof defaultCollapsed === 'undefined' ? (this.defaultCollapsed = false) : (this.defaultCollapsed = defaultCollapsed);
    typeof optional === 'undefined' ? (this.optional = false) : (this.optional = optional);
    this.rowOrder = rowOrder || 0;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get titleValid() {
    if (this.title.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get titleError() {
    if (this.title.length < 1) {
      return I18n.t('js.title_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
