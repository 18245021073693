/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observer, Provider } from 'mobx-react';
import Flexbox from 'flexbox-react';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import CurrentUserStore from '../stores/CurrentUserStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import AppStore from '../stores/AppStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import UserStoreTemplateList from '../components/store_templates/UserStoreTemplateList';
import ToastStore from '../stores/ToastStore';
import StoreTemplatesTabs from '../components/store_templates/StoreTemplatesTabs';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class StoreTemplatesView extends React.Component {
  componentDidMount() {
    StoreTemplateStore.resetStartingAttributes();
    const appNum = 1,
      perPage = 25,
      offset = 1;
    StoreTemplateActions.fetchYourStoreTemplates(appNum, perPage, offset)
      .then(response => {
        StoreTemplateStore.createPaginator(response.headers);
        let totalLength = response.data.length;
        _.map(response.data, storeTemplate => {
          StoreTemplateStore.addStoreTemplate(storeTemplate);
          totalLength--;
        });
        if (totalLength === 0) {
          StoreTemplateStore.toggleLoading();
        }
      })
      .catch(StoreTemplateStore.toggleLoading);
  }

  renderContent() {
    if (StoreTemplateStore.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox flexdirection="row" flexGrow={1} paddingTop="50px" paddingBottom="50px">
          <NonIdealState icon={<Spinner />} className="bp3-text-muted" />
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        <UserStoreTemplateList />
      </Flexbox>
    );
  }

  render() {
    const stores = {
      AppStore,
      WorkspaceStore,
      CurrentUserStore,
      StoreTemplateStore,
      StoreTemplateActions,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <StoreTemplatesTabs />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <Flexbox flexGrow={1} flexDirection="column">
                  {this.renderContent()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
