// @flow
import * as React from 'react';
// TODO remove avatar
import { Classes, Icon, Tag, Tooltip } from '@blueprintjs/core';
import SkeletonRound from '../../skeletons/SkeletonRound';
import SkeletonTitle from '../../skeletons/SkeletonTitle';
import LayoutHeaderWrapper from './styles/LayoutHeaderWrapper';
import LayoutHeaderStyles from './styles/LayoutHeaderStyles';
import TagsContainer from './styles/TagsContainer';
import StyledFAIcon from './styles/StyledFAIcon';

// eslint-disable-next-line no-magic-numbers
const isFontAwesomeIcon = s => s.slice(0, 2) === 'fa';

import styled from 'styled-components';
const SquashContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.navBarHeight * 2}px;

  * {
    background: none;
  }
`;

type LayoutHeaderProps = {
  icon: ?string,
  image: ?string,
  square: ?boolean,
  title: string,
  subtitle: string,
  editableTitleComponent: ?React.Element<any>,
  renderTags: Array<React.Element<Tag> | React.Element<Tooltip>>,
  // TODO make more specific if tab component is made
  tabs: ?React.Element<any>,
  squash: ?boolean,
  loading: ?boolean,
};

export default ({ icon, image, square, title, subtitle, renderTags, tabs, squash, editableTitleComponent, loading }: LayoutHeaderProps) => (
  <React.Fragment>
    <LayoutHeaderWrapper>
      <LayoutHeaderStyles paddingTop={!!tabs && !squash}>
        {loading ? (
          <SkeletonRound height={120} />
        ) : image ? (
          <img
            src={image}
            square={square}
            style={{
              minWidth: '120px',
              height: '120px',
              borderRadius: '100%',
            }}
          />
        ) : icon && isFontAwesomeIcon(icon) ? (
          <StyledFAIcon className={icon} />
        ) : (
          <Icon icon={icon} iconSize={64} className="bp3-dark-icon" />
        )}
        <div className="txt-area">
          <div className="txt-area__top-row">
            {loading ? (
              <SkeletonTitle titleLength={80} subtitleLength={80} />
            ) : editableTitleComponent ? (
              <>
                {editableTitleComponent}
                <TagsContainer>{renderTags}</TagsContainer>
              </>
            ) : (
              <>
                <h2 className={Classes.HEADING}>{title}</h2>
                <TagsContainer>{renderTags}</TagsContainer>
              </>
            )}
          </div>
          {loading ? null : <p className={`${Classes.RUNNING_TEXT}`}>{subtitle}</p>}
        </div>
      </LayoutHeaderStyles>
      {loading ? <div style={{ minHeight: '50px', backgroundColor: '#394B59' }}></div> : <div>{tabs}</div>}
    </LayoutHeaderWrapper>
  </React.Fragment>
);
