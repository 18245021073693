/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
// require("channels")

// Deal with react-rails
var reactRequireContext = require.context('react', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(reactRequireContext);

import 'normalize.css';
import '../react/css/app.scss';
import 'draft-js/dist/Draft.css';
import 'react-image-lightbox/style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'react-mosaic-component/react-mosaic-component.css';
import 'animate.css/animate.min.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Turbolinks from 'turbolinks';
import { FocusStyleManager } from '@blueprintjs/core';
import { I18n as i18n } from 'i18n-js';
import translations from 'locales.json';

// Deal with Blueprint
FocusStyleManager.onlyShowFocusOnTabs();

// Deal with I18n
export const I18n = new i18n(translations);
window.I18n = I18n;
I18n.locale = document.documentElement.lang || 'en';

// // Deal with Turbolinks
Turbolinks.BrowserAdapter.prototype.showProgressBarAfterDelay = function() {
  return (this.progressBarTimeout = setTimeout(this.showProgressBar, 0));
};

document.addEventListener('turbolinks:request-start', function(event) {
  var xhr = event.data.xhr;
  xhr.withCredentials = true;
});

document.addEventListener('turbolinks:request-end', function(event) {
  analytics.page();
});

// Catch all <a> tag events and over-ride for Turbolinks navigation if it's internal
// document.onclick = function(e) {
//   e = e || window.event;
//   var element = e.target || e.srcElement;

//   if (element.tagName == 'A') {
//     var href = element.href;
//     if (typeof href == 'string' && href.length > 0) {
//       if (typeof href == 'string') {
//         if (href != '#') {
//           Utilities.navigate(href);
//         }
//       }
//     }
//   }
// };
