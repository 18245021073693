import { action, computed, observable } from 'mobx';

export default class ElementHyperlink {
  @observable
  id;
  @observable
  elementId;
  @observable
  hyperlink;
  @observable
  title;
  @observable
  displayAs;

  constructor(id, elementId, hyperlink, title, displayAs) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.hyperlink = hyperlink || '';
    this.title = title || '';
    this.displayAs = displayAs || '';
  }

  @action
  elementValid() {
    if (this.hyperlinkValid && this.titleValid) {
      return true;
    }
    return false;
  }

  @computed
  get hyperlinkValid() {
    if (this.hyperlink.length < 1) {
      return false;
    }
    // eslint-disable-next-line
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(this.hyperlink);
  }

  @computed
  get hyperlinkError() {
    if (this.hyperlink.length < 1) {
      return I18n.t('js.hyperlink_is_required');
    }
    return I18n.t('js.hyperlink_is_not_valid');
  }

  @computed
  get titleValid() {
    if (this.title.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get titleError() {
    if (this.title.length < 1) {
      return I18n.t('js.title_is_required');
    }
    return null;
  }
}
