const countries = [
  { name: 'Afghanistan', code: 'AF', taxPercent: 0.0 },
  { name: 'Åland Islands', code: 'AX', taxPercent: 0.0 },
  { name: 'Albania', code: 'AL', taxPercent: 0.0 },
  { name: 'Algeria', code: 'DZ', taxPercent: 0.0 },
  { name: 'American Samoa', code: 'AS', taxPercent: 0.0 },
  { name: 'AndorrA', code: 'AD', taxPercent: 0.0 },
  { name: 'Angola', code: 'AO', taxPercent: 0.0 },
  { name: 'Anguilla', code: 'AI', taxPercent: 0.0 },
  { name: 'Antarctica', code: 'AQ', taxPercent: 0.0 },
  { name: 'Antigua and Barbuda', code: 'AG', taxPercent: 0.0 },
  { name: 'Argentina', code: 'AR', taxPercent: 0.0 },
  { name: 'Armenia', code: 'AM', taxPercent: 0.0 },
  { name: 'Aruba', code: 'AW', taxPercent: 0.0 },
  { name: 'Australia', code: 'AU', taxPercent: 0.0 },
  { name: 'Austria', code: 'AT', taxPercent: 20.0 },
  { name: 'Azerbaijan', code: 'AZ', taxPercent: 0.0 },
  { name: 'Bahamas', code: 'BS', taxPercent: 0.0 },
  { name: 'Bahrain', code: 'BH', taxPercent: 0.0 },
  { name: 'Bangladesh', code: 'BD', taxPercent: 0.0 },
  { name: 'Barbados', code: 'BB', taxPercent: 0.0 },
  { name: 'Belarus', code: 'BY', taxPercent: 0.0 },
  { name: 'Belgium', code: 'BE', taxPercent: 20.0 },
  { name: 'Belize', code: 'BZ', taxPercent: 0.0 },
  { name: 'Benin', code: 'BJ', taxPercent: 0.0 },
  { name: 'Bermuda', code: 'BM', taxPercent: 0.0 },
  { name: 'Bhutan', code: 'BT', taxPercent: 0.0 },
  { name: 'Bolivia', code: 'BO', taxPercent: 0.0 },
  { name: 'Bosnia and Herzegovina', code: 'BA', taxPercent: 0.0 },
  { name: 'Botswana', code: 'BW', taxPercent: 0.0 },
  { name: 'Bouvet Island', code: 'BV', taxPercent: 0.0 },
  { name: 'Brazil', code: 'BR', taxPercent: 0.0 },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    taxPercent: 0.0,
  },
  { name: 'Brunei Darussalam', code: 'BN', taxPercent: 0.0 },
  { name: 'Bulgaria', code: 'BG', taxPercent: 20.0 },
  { name: 'Burkina Faso', code: 'BF', taxPercent: 0.0 },
  { name: 'Burundi', code: 'BI', taxPercent: 0.0 },
  { name: 'Cambodia', code: 'KH', taxPercent: 0.0 },
  { name: 'Cameroon', code: 'CM', taxPercent: 0.0 },
  { name: 'Canada', code: 'CA', taxPercent: 0.0 },
  { name: 'Cape Verde', code: 'CV', taxPercent: 0.0 },
  { name: 'Cayman Islands', code: 'KY', taxPercent: 0.0 },
  { name: 'Central African Republic', code: 'CF', taxPercent: 0.0 },
  { name: 'Chad', code: 'TD', taxPercent: 0.0 },
  { name: 'Chile', code: 'CL', taxPercent: 0.0 },
  { name: 'China', code: 'CN', taxPercent: 0.0 },
  { name: 'Christmas Island', code: 'CX', taxPercent: 0.0 },
  { name: 'Cocos (Keeling) Islands', code: 'CC', taxPercent: 0.0 },
  { name: 'Colombia', code: 'CO', taxPercent: 0.0 },
  { name: 'Comoros', code: 'KM', taxPercent: 0.0 },
  { name: 'Congo', code: 'CG', taxPercent: 0.0 },
  {
    name: 'Congo, The Democratic Republic of the',
    code: 'CD',
    taxPercent: 0.0,
  },
  { name: 'Cook Islands', code: 'CK', taxPercent: 0.0 },
  { name: 'Costa Rica', code: 'CR', taxPercent: 0.0 },
  { name: "Cote D'Ivoire", code: 'CI', taxPercent: 0.0 },
  { name: 'Croatia', code: 'HR', taxPercent: 20.0 },
  { name: 'Cuba', code: 'CU', taxPercent: 0.0 },
  { name: 'Cyprus', code: 'CY', taxPercent: 20.0 },
  { name: 'Czech Republic', code: 'CZ', taxPercent: 20.0 },
  { name: 'Denmark', code: 'DK', taxPercent: 20.0 },
  { name: 'Djibouti', code: 'DJ', taxPercent: 0.0 },
  { name: 'Dominica', code: 'DM', taxPercent: 0.0 },
  { name: 'Dominican Republic', code: 'DO', taxPercent: 0.0 },
  { name: 'Ecuador', code: 'EC', taxPercent: 0.0 },
  { name: 'Egypt', code: 'EG', taxPercent: 0.0 },
  { name: 'El Salvador', code: 'SV', taxPercent: 0.0 },
  { name: 'Equatorial Guinea', code: 'GQ', taxPercent: 0.0 },
  { name: 'Eritrea', code: 'ER', taxPercent: 0.0 },
  { name: 'Estonia', code: 'EE', taxPercent: 20.0 },
  { name: 'Ethiopia', code: 'ET', taxPercent: 0.0 },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    taxPercent: 0.0,
  },
  { name: 'Faroe Islands', code: 'FO', taxPercent: 0.0 },
  { name: 'Fiji', code: 'FJ', taxPercent: 0.0 },
  { name: 'Finland', code: 'FI', taxPercent: 20.0 },
  { name: 'France', code: 'FR', taxPercent: 20.0 },
  { name: 'French Guiana', code: 'GF', taxPercent: 0.0 },
  { name: 'French Polynesia', code: 'PF', taxPercent: 0.0 },
  {
    name: 'French Southern Territories',
    code: 'TF',
    taxPercent: 0.0,
  },
  { name: 'Gabon', code: 'GA', taxPercent: 0.0 },
  { name: 'Gambia', code: 'GM', taxPercent: 0.0 },
  { name: 'Georgia', code: 'GE', taxPercent: 0.0 },
  { name: 'Germany', code: 'DE', taxPercent: 20.0 },
  { name: 'Ghana', code: 'GH', taxPercent: 0.0 },
  { name: 'Gibraltar', code: 'GI', taxPercent: 0.0 },
  { name: 'Greece', code: 'GR', taxPercent: 20.0 },
  { name: 'Greenland', code: 'GL', taxPercent: 0.0 },
  { name: 'Grenada', code: 'GD', taxPercent: 0.0 },
  { name: 'Guadeloupe', code: 'GP', taxPercent: 0.0 },
  { name: 'Guam', code: 'GU', taxPercent: 0.0 },
  { name: 'Guatemala', code: 'GT', taxPercent: 0.0 },
  { name: 'Guernsey', code: 'GG', taxPercent: 0.0 },
  { name: 'Guinea', code: 'GN', taxPercent: 0.0 },
  { name: 'Guinea-Bissau', code: 'GW', taxPercent: 0.0 },
  { name: 'Guyana', code: 'GY', taxPercent: 0.0 },
  { name: 'Haiti', code: 'HT', taxPercent: 0.0 },
  {
    name: 'Heard Island and Mcdonald Islands',
    code: 'HM',
    taxPercent: 0.0,
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VA',
    taxPercent: 0.0,
  },
  { name: 'Honduras', code: 'HN', taxPercent: 0.0 },
  { name: 'Hong Kong', code: 'HK', taxPercent: 0.0 },
  { name: 'Hungary', code: 'HU', taxPercent: 20.0 },
  { name: 'Iceland', code: 'IS', taxPercent: 0.0 },
  { name: 'India', code: 'IN', taxPercent: 0.0 },
  { name: 'Indonesia', code: 'ID', taxPercent: 0.0 },
  { name: 'Iran, Islamic Republic Of', code: 'IR', taxPercent: 0.0 },
  { name: 'Iraq', code: 'IQ', taxPercent: 0.0 },
  { name: 'Ireland', code: 'IE', taxPercent: 20.0 },
  { name: 'Isle of Man', code: 'IM', taxPercent: 0.0 },
  { name: 'Israel', code: 'IL', taxPercent: 0.0 },
  { name: 'Italy', code: 'IT', taxPercent: 20.0 },
  { name: 'Jamaica', code: 'JM', taxPercent: 0.0 },
  { name: 'Japan', code: 'JP', taxPercent: 0.0 },
  { name: 'Jersey', code: 'JE', taxPercent: 0.0 },
  { name: 'Jordan', code: 'JO', taxPercent: 0.0 },
  { name: 'Kazakhstan', code: 'KZ', taxPercent: 0.0 },
  { name: 'Kenya', code: 'KE', taxPercent: 0.0 },
  { name: 'Kiribati', code: 'KI', taxPercent: 0.0 },
  {
    name: "Korea, Democratic People'S Republic of",
    code: 'KP',
    taxPercent: 0.0,
  },
  { name: 'Korea, Republic of', code: 'KR', taxPercent: 0.0 },
  { name: 'Kuwait', code: 'KW', taxPercent: 0.0 },
  { name: 'Kyrgyzstan', code: 'KG', taxPercent: 0.0 },
  {
    name: "Lao People'S Democratic Republic",
    code: 'LA',
    taxPercent: 0.0,
  },
  { name: 'Latvia', code: 'LV', taxPercent: 20.0 },
  { name: 'Lebanon', code: 'LB', taxPercent: 0.0 },
  { name: 'Lesotho', code: 'LS', taxPercent: 0.0 },
  { name: 'Liberia', code: 'LR', taxPercent: 0.0 },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', taxPercent: 0.0 },
  { name: 'Liechtenstein', code: 'LI', taxPercent: 0.0 },
  { name: 'Lithuania', code: 'LT', taxPercent: 20.0 },
  { name: 'Luxembourg', code: 'LU', taxPercent: 20.0 },
  { name: 'Macao', code: 'MO', taxPercent: 0.0 },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    taxPercent: 0.0,
  },
  { name: 'Madagascar', code: 'MG', taxPercent: 0.0 },
  { name: 'Malawi', code: 'MW', taxPercent: 0.0 },
  { name: 'Malaysia', code: 'MY', taxPercent: 0.0 },
  { name: 'Maldives', code: 'MV', taxPercent: 0.0 },
  { name: 'Mali', code: 'ML', taxPercent: 0.0 },
  { name: 'Malta', code: 'MT', taxPercent: 20.0 },
  { name: 'Marshall Islands', code: 'MH', taxPercent: 0.0 },
  { name: 'Martinique', code: 'MQ', taxPercent: 0.0 },
  { name: 'Mauritania', code: 'MR', taxPercent: 0.0 },
  { name: 'Mauritius', code: 'MU', taxPercent: 0.0 },
  { name: 'Mayotte', code: 'YT', taxPercent: 0.0 },
  { name: 'Mexico', code: 'MX', taxPercent: 0.0 },
  {
    name: 'Micronesia, Federated States of',
    code: 'FM',
    taxPercent: 0.0,
  },
  { name: 'Moldova, Republic of', code: 'MD', taxPercent: 0.0 },
  { name: 'Monaco', code: 'MC', taxPercent: 0.0 },
  { name: 'Mongolia', code: 'MN', taxPercent: 0.0 },
  { name: 'Montserrat', code: 'MS', taxPercent: 0.0 },
  { name: 'Morocco', code: 'MA', taxPercent: 0.0 },
  { name: 'Mozambique', code: 'MZ', taxPercent: 0.0 },
  { name: 'Myanmar', code: 'MM', taxPercent: 0.0 },
  { name: 'Namibia', code: 'NA', taxPercent: 0.0 },
  { name: 'Nauru', code: 'NR', taxPercent: 0.0 },
  { name: 'Nepal', code: 'NP', taxPercent: 0.0 },
  { name: 'Netherlands', code: 'NL', taxPercent: 20.0 },
  { name: 'Netherlands Antilles', code: 'AN', taxPercent: 0.0 },
  { name: 'New Caledonia', code: 'NC', taxPercent: 0.0 },
  { name: 'New Zealand', code: 'NZ', taxPercent: 0.0 },
  { name: 'Nicaragua', code: 'NI', taxPercent: 0.0 },
  { name: 'Niger', code: 'NE', taxPercent: 0.0 },
  { name: 'Nigeria', code: 'NG', taxPercent: 0.0 },
  { name: 'Niue', code: 'NU', taxPercent: 0.0 },
  { name: 'Norfolk Island', code: 'NF', taxPercent: 0.0 },
  { name: 'Northern Mariana Islands', code: 'MP', taxPercent: 0.0 },
  { name: 'Norway', code: 'NO', taxPercent: 0.0 },
  { name: 'Oman', code: 'OM', taxPercent: 0.0 },
  { name: 'Pakistan', code: 'PK', taxPercent: 0.0 },
  { name: 'Palau', code: 'PW', taxPercent: 0.0 },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
    taxPercent: 0.0,
  },
  { name: 'Panama', code: 'PA', taxPercent: 0.0 },
  { name: 'Papua New Guinea', code: 'PG', taxPercent: 0.0 },
  { name: 'Paraguay', code: 'PY', taxPercent: 0.0 },
  { name: 'Peru', code: 'PE', taxPercent: 0.0 },
  { name: 'Philippines', code: 'PH', taxPercent: 0.0 },
  { name: 'Pitcairn', code: 'PN', taxPercent: 0.0 },
  { name: 'Poland', code: 'PL', taxPercent: 20.0 },
  { name: 'Portugal', code: 'PT', taxPercent: 20.0 },
  { name: 'Puerto Rico', code: 'PR', taxPercent: 0.0 },
  { name: 'Qatar', code: 'QA', taxPercent: 0.0 },
  { name: 'Reunion', code: 'RE', taxPercent: 0.0 },
  { name: 'Romania', code: 'RO', taxPercent: 20.0 },
  { name: 'Russian Federation', code: 'RU', taxPercent: 0.0 },
  { name: 'RWANDA', code: 'RW', taxPercent: 0.0 },
  { name: 'Saint Helena', code: 'SH', taxPercent: 0.0 },
  { name: 'Saint Kitts and Nevis', code: 'KN', taxPercent: 0.0 },
  { name: 'Saint Lucia', code: 'LC', taxPercent: 0.0 },
  { name: 'Saint Pierre and Miquelon', code: 'PM', taxPercent: 0.0 },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    taxPercent: 0.0,
  },
  { name: 'Samoa', code: 'WS', taxPercent: 0.0 },
  { name: 'San Marino', code: 'SM', taxPercent: 0.0 },
  { name: 'Sao Tome and Principe', code: 'ST', taxPercent: 0.0 },
  { name: 'Saudi Arabia', code: 'SA', taxPercent: 0.0 },
  { name: 'Senegal', code: 'SN', taxPercent: 0.0 },
  { name: 'Serbia and Montenegro', code: 'CS', taxPercent: 0.0 },
  { name: 'Seychelles', code: 'SC', taxPercent: 0.0 },
  { name: 'Sierra Leone', code: 'SL', taxPercent: 0.0 },
  { name: 'Singapore', code: 'SG', taxPercent: 0.0 },
  { name: 'Slovakia', code: 'SK', taxPercent: 20.0 },
  { name: 'Slovenia', code: 'SI', taxPercent: 20.0 },
  { name: 'Solomon Islands', code: 'SB', taxPercent: 0.0 },
  { name: 'Somalia', code: 'SO', taxPercent: 0.0 },
  { name: 'South Africa', code: 'ZA', taxPercent: 0.0 },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    taxPercent: 0.0,
  },
  { name: 'Spain', code: 'ES', taxPercent: 20.0 },
  { name: 'Sri Lanka', code: 'LK', taxPercent: 0.0 },
  { name: 'Sudan', code: 'SD', taxPercent: 0.0 },
  { name: 'Suriname', code: 'SR', taxPercent: 0.0 },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', taxPercent: 0.0 },
  { name: 'Swaziland', code: 'SZ', taxPercent: 0.0 },
  { name: 'Sweden', code: 'SE', taxPercent: 20.0 },
  { name: 'Switzerland', code: 'CH', taxPercent: 0.0 },
  { name: 'Syrian Arab Republic', code: 'SY', taxPercent: 0.0 },
  { name: 'Taiwan, Province of China', code: 'TW', taxPercent: 0.0 },
  { name: 'Tajikistan', code: 'TJ', taxPercent: 0.0 },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
    taxPercent: 0.0,
  },
  { name: 'Thailand', code: 'TH', taxPercent: 0.0 },
  { name: 'Timor-Leste', code: 'TL', taxPercent: 0.0 },
  { name: 'Togo', code: 'TG', taxPercent: 0.0 },
  { name: 'Tokelau', code: 'TK', taxPercent: 0.0 },
  { name: 'Tonga', code: 'TO', taxPercent: 0.0 },
  { name: 'Trinidad and Tobago', code: 'TT', taxPercent: 0.0 },
  { name: 'Tunisia', code: 'TN', taxPercent: 0.0 },
  { name: 'Turkey', code: 'TR', taxPercent: 0.0 },
  { name: 'Turkmenistan', code: 'TM', taxPercent: 0.0 },
  { name: 'Turks and Caicos Islands', code: 'TC', taxPercent: 0.0 },
  { name: 'Tuvalu', code: 'TV', taxPercent: 0.0 },
  { name: 'Uganda', code: 'UG', taxPercent: 0.0 },
  { name: 'Ukraine', code: 'UA', taxPercent: 0.0 },
  { name: 'United Arab Emirates', code: 'AE', taxPercent: 0.0 },
  { name: 'United Kingdom', code: 'GB', taxPercent: 20.0 },
  { name: 'United States', code: 'US', taxPercent: 0.0 },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    taxPercent: 0.0,
  },
  { name: 'Uruguay', code: 'UY', taxPercent: 0.0 },
  { name: 'Uzbekistan', code: 'UZ', taxPercent: 0.0 },
  { name: 'Vanuatu', code: 'VU', taxPercent: 0.0 },
  { name: 'Venezuela', code: 'VE', taxPercent: 0.0 },
  { name: 'Viet Nam', code: 'VN', taxPercent: 0.0 },
  { name: 'Virgin Islands, British', code: 'VG', taxPercent: 0.0 },
  { name: 'Virgin Islands, U.S.', code: 'VI', taxPercent: 0.0 },
  { name: 'Wallis and Futuna', code: 'WF', taxPercent: 0.0 },
  { name: 'Western Sahara', code: 'EH', taxPercent: 0.0 },
  { name: 'Yemen', code: 'YE', taxPercent: 0.0 },
  { name: 'Zambia', code: 'ZM', taxPercent: 0.0 },
  { name: 'Zimbabwe', code: 'ZW' },
];

class CountryDefinitions {
  static get countries() {
    return countries;
  }
}

export default CountryDefinitions;
