import React from 'react';
import Flexbox from 'flexbox-react';
import { Collapse } from '@blueprintjs/core';
import PermissionDefinitions from '../../static/PermissionDefinitions';
import classNames from 'classnames';
import _ from 'lodash';

export default class PermissionExplanations extends React.Component {
  render() {
    return (
      <Collapse isOpen={this.props.isOpen}>
        <Flexbox
          flexDirection="column"
          flexGrow={1}
          marginBottom="10px"
        >
          <p>
            {I18n.t('js.permissions_comment', {
              module: this.props.module.toLowerCase(),
            })}
          </p>
          <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
            <thead>
              <tr>
                <th>
                  <strong>{I18n.t('js.permission_type')}</strong>
                </th>
                <th>
                  <strong>{I18n.t('js.explanation')}</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(PermissionDefinitions.permissionTypes, type => {
                const iconClass = classNames(
                  'bp3-icon-standard bp3-text-muted push-10-r',
                  type.icon
                );
                return (
                  <tr key={type.id}>
                    <td>
                      <span className={iconClass} />
                      {type.text}
                    </td>
                    <td>
                      {I18n.t(`js.${type.name}_description`, {
                        type: this.props.type.toLowerCase(),
                        module: this.props.module.toLowerCase(),
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Flexbox>
      </Collapse>
    );
  }
}
