/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import ToastStore from '../../stores/ToastStore';
import Cookies from 'js-cookie';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Team from '../../models/TeamModel';
import { Button, Classes, MenuItem, PopoverInteractionKind, Spinner } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import nanoid from 'nanoid';
import _ from 'lodash';

const TeamSelect = Select.ofType(Team);

@inject('PermissionStore', 'ToastStore')
@observer
export default class PermissionAddTeamPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchTeams: [],
      isLoading: false,
      requestId: nanoid(),
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addTeam(data) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.setState({
      searchTeams: _.unionBy([newTeam], this.state.searchTeams, 'id'),
    });
    this.sortSearchTeams();
  }

  sortSearchTeams() {
    let newTeams = this.state.searchTeams;
    newTeams = _.orderBy(newTeams, ['fullName'], ['asc']);
    this.setState({ searchTeams: newTeams });
  }

  searchTeams(query) {
    const requestId = nanoid();
    this.setState({
      searchTeams: [],
      query,
      requestId,
    });
    if (query.length > 1) {
      this.toggleLoading();
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/teams?query=${query}`)
          .then(response => {
            let total = response.data.length;
            _.map(response.data, team => {
              if (this.state.requestId === requestId) {
                this.addTeam(team);
              }
              total--;
            });
            if (total === 0) {
              resolve(response);
              this.toggleLoading();
            }
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
            this.toggleLoading();
          });
      });
    }
    return undefined;
  }

  renderInputValue(team) {
    return team.name;
  }

  renderTeam(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox>
              <Avatar src={item.imageFileName} size={22} round={true} className="" />
            </Flexbox>{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.name}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length > 1 && !this.state.isLoading) {
      return <MenuItem className="popover-larger" disabled text={I18n.t('js.no_teams_found')} />;
    }
    return undefined;
  }

  handleTeamSelect(team) {
    this.setState({ query: '' });
    this.props.handleTeamSelectChange(team);
    this.handleCancel();
  }

  handleCancel() {
    this.setState({ searchTeams: [], query: '' });
  }

  renderCancel() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    if (this.state.query.length > 0) {
      return <button type="button" className="bp3-button bp3-icon-cross bp3-minimal" onClick={this.handleCancel.bind(this)} />;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox marginTop="10px">
          <TeamSelect
            resetOnSelect={false}
            items={this.state.searchTeams}
            itemRenderer={this.renderTeam.bind(this)}
            onItemSelect={this.handleTeamSelect.bind(this)}
            inputValueRenderer={this.renderInputValue.bind(this)}
            inputProps={{
              placeholder: I18n.t('js.start_typing'),
              onChange: this.searchTeams.bind(this),
              value: this.state.query,
              className: 'popover-larger bp3-no-focus',
              rightElement: this.renderCancel(),
            }}
            query={this.state.query}
            onQueryChange={this.searchTeams.bind(this)}
            popoverProps={{
              usePortal: false,
              position: 'top-left',
              className: '',
              interactionKind: PopoverInteractionKind.CLICK,
              onClose: this.handleCancel.bind(this),
              popoverClassName: 'bp3-no-padding',
            }}
            noResults={this.renderResultSearch()}
          >
            <Button icon="graph" text={I18n.t('js.add_team')} />
          </TeamSelect>
        </Flexbox>
      </Flexbox>
    );
  }
}
