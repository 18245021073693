// @flow
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Menu, MenuItem, MenuDivider, Button, Popover, PopoverInteractionKind, Position, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import ChartDefinitions from '../../static/ChartDefinitions';
import ReportChart from '../../models/ReportChartModel';
import nanoid from 'nanoid';

@inject('DataViewStore', 'DataViewActions')
@observer
export default class NewVisualization extends React.Component {
  handleAddChart(chart, id) {
    const newChart = new ReportChart({ id: id, name: chart.name, type: chart.type, icon: chart.icon, options: '', dataConfig: '' });
    this.props.DataViewStore.addChart(newChart);
  }

  handleReset() {
    if (this.props.DataViewStore.isActiveReportTemplatePersisted) {
      this.props.DataViewStore.resetPeristedChartConfiguration();
    } else {
      this.props.DataViewStore.resetChartLayout();
    }
  }

  renderMenu() {
    return (
      <Menu>
        {_.map(ChartDefinitions.chartTypes, chart => {
          return (
            <MenuItem
              text={I18n.t('js.add_new_chart_name', { name: chart.name.toLowerCase() })}
              icon={chart.icon}
              key={chart.name}
              onClick={() => this.handleAddChart(chart, nanoid())}
            />
          );
        })}
        <MenuDivider />
        <MenuItem text={I18n.t('js.reset')} icon="reset" onClick={this.handleReset.bind(this)} intent={Intent.DANGER} />
      </Menu>
    );
  }

  render() {
    return (
      <Popover
        content={this.renderMenu()}
        position={Position.BOTTOM_LEFT}
        portalContainer={document.body}
        interactionKind={PopoverInteractionKind.CLICK}
        autoFocus={false}
        disabled={this.props.disabled}
      >
        <Button text={I18n.t('js.chart')} icon="series-add" disabled={this.props.disabled} />
      </Popover>
    );
  }
}
