import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Classes,
  Intent,
  HTMLSelect,
} from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

@inject(
  'AdminActions',
  'AdminStore',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class AdminEditAccountDomain extends React.Component {
  @observable
  isSubmitting = false;

  constructor(props) {
    super(props);

    this.state = { isBlurred: [] };
  }

  toggleSubmitting() {
    this.isSubmitting = !this.isSubmitting;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSubmitting();
    const accountDomainObj = {
      id: this.props.AdminStore.activeItem.id,
      domain: this.props.AdminStore.activeItem.domain,
      version: this.props.AdminStore.activeItem.version,
    };
    this.props.AdminActions.changeAccountDomain(
      accountDomainObj
    ).then(response => {
      this.props.AdminStore.addAccountDomain(response.data);
      this.props.AdminStore.resetNewAccountDomain();
      this.props.AdminStore.setActiveItem(null, null);
      this.props.ToastStore.showToast(
        'Account domain updated',
        'success'
      );
    });
  }

  handleInputChange(e) {
    this.props.AdminStore.updateActiveItem(
      e.target.value,
      e.target.name
    );
  }

  handleCancel() {
    this.props.AdminStore.resetNewAccountDomain();
    this.props.AdminStore.setActiveItem(null, null);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.AdminStore.activeItem[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.AdminStore.activeItem[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        className="bp3-card"
        flexGrow={1}
      >
        <Flexbox>
          <Flexbox flexGrow={1}>
            <h3 className={Classes.HEADING}>
              {this.props.AdminStore.activeItem.domain}
            </h3>
          </Flexbox>
          <Flexbox
            flexGrow={0}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              text="Back"
              onClick={() =>
                this.props.AdminStore.setActiveItem(null, null)
              }
              rightIcon="undo"
              className="bp3-minimal"
            />
          </Flexbox>
        </Flexbox>
        <Flexbox flexGrow={1} flexDirection="column">
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'domain') &&
                  !this.props.AdminStore.activeItem.domainValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  Domain name
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'domain') &&
                      !this.props.AdminStore.activeItem.domainValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder="Enter domain..."
                      name="domain"
                      value={this.props.AdminStore.activeItem.domain}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('domain')}
                </div>
              </div>
              <div
                className={
                  _.includes(this.state.isBlurred, 'version') &&
                  !this.props.AdminStore.activeItem.versionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  Select version
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <HTMLSelect
                    fill={true}
                    name="version"
                    value={this.props.AdminStore.activeItem.version}
                    onChange={this.handleInputChange.bind(this)}
                    dir="auto"
                  >
                    <option value="v1">v1</option>
                    <option value="v2">v2</option>
                    <option value="All">All</option>
                  </HTMLSelect>
                  {this.renderErrors('version')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text="Update account domain"
                loading={this.isSubmitting}
                className="push-10-r"
              />
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.handleCancel.bind(this)}
              />
            </Flexbox>
          </form>
        </Flexbox>
      </Flexbox>
    );
  }
}
