import React from 'react';
import Flexbox from 'flexbox-react';

export default class NumberValueValidator extends React.Component {
  render() {
    if (!this.props.disabled) {
      if (
        this.props.minValue !== null &&
        this.props.maxValue !== null
      ) {
        if (
          this.props.value < this.props.minValue ||
          this.props.value > this.props.maxValue
        ) {
          return (
            <Flexbox
              flexDirection="row"
              flexGrow={1}
              className="bp3-form-helper-text"
            >
              <span>
                {I18n.t(
                  'js.please_enter_a_value_between_min_and_max',
                  {
                    min: this.props.minValue,
                    max: this.props.maxValue,
                    prefix: this.props.prefix,
                    suffix: this.props.suffix,
                  }
                )}
              </span>
            </Flexbox>
          );
        }
        return null;
      }
      if (
        this.props.value > this.props.maxValue &&
        this.props.maxValue !== null
      ) {
        return (
          <Flexbox
            flexDirection="row"
            flexGrow={1}
            className="bp3-form-helper-text"
          >
            <span>
              {I18n.t('js.please_enter_a_value_less_than_x', {
                value: this.props.maxValue,
                prefix: this.props.prefix,
                suffix: this.props.suffix,
              })}
            </span>
          </Flexbox>
        );
      }
      if (
        this.props.minValue > this.props.value &&
        this.props.minValue !== null
      ) {
        return (
          <Flexbox
            flexDirection="row"
            flexGrow={1}
            className="bp3-form-helper-text"
          >
            <span>
              {I18n.t('js.please_enter_a_value_greater_than_x', {
                value: this.props.minValue,
                prefix: this.props.prefix,
                suffix: this.props.suffix,
              })}
            </span>
          </Flexbox>
        );
      }
      return null;
    }
    return null;
  }
}
