import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import ItemPin from '../items/ItemPin';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import renderHTML from 'react-render-html';
import _ from 'lodash';

@inject('AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DashboardPins extends React.Component {
  renderItems() {
    if (this.props.DashboardStore.pinnedItems.length > 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <p>{renderHTML(I18n.t('js.dashboard_pinned_items_description'))}</p>
          <table className="bp3-html-table bp3-html-table-condensed bp3-interactive">
            <tbody>
              {_.map(this.props.DashboardStore.pinnedItems, itemPin => (
                <ItemPin itemPin={itemPin} key={itemPin.id} showActions={true} />
              ))}
            </tbody>
          </table>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderNoItems() {
    if (this.props.DashboardStore.pinnedItems.length === 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <p className="bp3-text-muted">{I18n.t('js.you_have_no_pinned_items')}</p>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderContent() {
    if (this.props.DashboardStore.isLoading) {
      return (
        <Flexbox flexDirection="row" flexGrow={1}>
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted push-20-t pad-20"
          />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderItems()}
          {this.renderNoItems()}
        </Flexbox>
      );
    }
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={0} marginTop="20px">
        <h3 className="bp3-heading">{I18n.t('js.pinned_items')}</h3>
        {this.renderContent()}
      </Flexbox>
    );
  }
}
