/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class IntegrationToken {
  @observable
  id;
  @observable
  userId;
  @observable
  accountId;
  @observable
  name;
  @observable
  token;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(id, userId, accountId, name, token, createdAt, createdBy, deletedAt, deletedBy, updatedAt, updatedBy) {
    this.id = id || '';
    this.userId = userId || '';
    this.accountId = accountId || '';
    this.name = name || '';
    this.token = token || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToShortDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY');
  }
}
