import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import PermissionStore from '../stores/PermissionStore';
import AppStore from '../stores/AppStore';
import WorkspaceStore from '../stores/WorkspaceStore';
import RelationshipPermissions from '../components/permissions/RelationshipPermissions';
import TeamPermissions from '../components/permissions/TeamPermissions';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import UserPermissions from '../components/permissions/UserPermissions';

@observer
export default class PermissionView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    PermissionStore.resetStartingAttributes();
    if (this.props.type === 'workspace') {
      PermissionStore.fetchPermissions(WorkspaceStore.activeWorkspace.id, 'workspace')
        .then(this.toggleLoading)
        .catch(this.toggleLoading);
    }
    if (this.props.type === 'app') {
      PermissionStore.fetchPermissions(AppStore.activeApp.id, 'app')
        .then(
          AppStore.fetchParticipants(AppStore.activeApp.id).then(response => {
            PermissionStore.participants = response.data;
            this.toggleLoading();
          })
        )
        .catch(this.toggleLoading);
    }
  }

  render() {
    const stores = {
      PermissionStore,
      AppStore,
      WorkspaceStore,
      CurrentUserStore,
      ToastStore,
    };
    if (this.isLoading || WorkspaceStore.activeWorkspace === '') {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="20px" paddingBottom="20px">
          <NonIdealState
            title={I18n.t('js.fetching_data')}
            description={I18n.t('js.please_wait')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox flexDirection="column" flexGrow={1}>
            <RelationshipPermissions type={this.props.type} />
            <TeamPermissions type={this.props.type} />
            <UserPermissions type={this.props.type} />
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
