import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import CurrentUserStore from '../stores/CurrentUserStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class TasksView extends React.Component {
  renderContent() {
    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} className="bp3-card">
        <NonIdealState
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.lorem_ipsum_short')}
          icon="properties"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }

  render() {
    const stores = {
      CurrentUserStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <LayoutHeader
                title={I18n.t('js.tasks')}
                subtitle={I18n.t('js.tasks_text')}
                icon="bp3-icon-properties"
                loading={this.isLoading || CurrentUserStore.isLoading}
              />
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="50px">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
