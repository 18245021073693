import React from 'react';
import Flexbox from 'flexbox-react';
import UserAvatar from '../shared/UserAvatar';
import WebformAvatar from '../shared/WebformAvatar';
import { inject, observer } from 'mobx-react';
import { Intent, Tag } from '@blueprintjs/core';
import StatusTag from '../shared/StatusTag';
import _ from 'lodash';
import Utilities from '../../utils/Utilities';

@inject(
  'AppStore',
  'AppItemStore',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class AppItemsListRow extends React.Component {
  renderUserAvatar() {
    if (
      this.props.item.isWebform &&
      this.props.item.hasWebformUserData
    ) {
      return <WebformAvatar item={this.props.item} avatarSize={20} />;
    }
    return (
      <UserAvatar
        user={this.props.item.user}
        avatarSize={20}
        path={`/users/${this.props.item.user.humanFriendlyId}`}
      />
    );
  }

  renderWebformTag() {
    if (this.props.item.isWebform) {
      return (
        <Tag minimal intent={Intent.SUCCESS} className="push-10-l">
          {I18n.t('js.webform_submission')}
        </Tag>
      );
    }
    return undefined;
  }

  render() {
    return (
      <tr>
        <td>
          <a
            href={`/items/${this.props.item.id}`}
            onClick={() =>
              Utilities.navigate(`/items/${this.props.item.id}`)
            }
          >
            {_.truncate(this.props.item.name, { length: 60 })}
          </a>
        </td>
        <td>
          <Flexbox flexDirection="row">
            {this.renderUserAvatar()} {this.renderWebformTag()}
          </Flexbox>
        </td>
        <td>{this.props.item.updatedAtToDate}</td>
        <td>{this.props.item.state.name}</td>
        <td>
          <StatusTag
            name={this.props.item.statusFlag.name}
            color={this.props.item.statusFlag.color}
          />
        </td>
      </tr>
    );
  }
}
