/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class ItemParticipant {
  @observable
  id;
  @observable
  itemId;
  @observable
  participantId;
  @observable
  userId;
  @observable
  isDone;
  @observable
  email;
  @observable
  fullName;
  @observable
  gravatarUrl;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    itemId,
    participantId,
    userId,
    isDone,
    email,
    fullName,
    gravatarUrl,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.itemId = itemId || '';
    this.participantId = participantId || '';
    this.userId = userId || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof isDone === 'undefined' || isDone === null ? (this.isDone = false) : (this.isDone = isDone);
    this.email = email || '';
    this.fullName = fullName || '';
    this.gravatarUrl = gravatarUrl || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
