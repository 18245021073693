/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class Invitation {
  @observable
  id;
  @observable
  userId;
  @observable
  accountId;
  @observable
  email;
  @observable
  accepted;

  constructor(id, accepted, accountId, createdAt, createdBy, deletedAt, deletedBy, email, updatedAt, updatedBy, userId) {
    this.id = id || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof accepted === 'undefined' ? (this.accepted = false) : (this.accepted = accepted);
    this.accountId = accountId || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.email = email || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.userId = userId || '';
  }

  @computed
  get emailValid() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email !== '' && exp.test(this.email)) {
      return true;
    }
    return false;
  }

  @computed
  get emailError() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email === '') {
      return I18n.t('js.email_is_required');
    }
    if (!exp.test(this.email)) {
      return I18n.t('js.email_is_invalid');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
