import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import TemplateTabs from './TemplateTabs';
import TemplateHeader from './TemplateHeader';
import TemplateSection from './TemplateSection';
import TemplateAddSection from './TemplateAddSection';
import GuideButton from '../guides/GuideButton';
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { AnchorButton, NonIdealState } from '@blueprintjs/core';
import classNames from 'classnames';

const DragHandle = sortableHandle(() => <AnchorButton className="bp3-cursor-move bp3-minimal" icon="drag-handle-vertical" />);

const SortableSection = sortableElement(({ section }) => <TemplateSection section={section} dragHandle={<DragHandle />} />);

const SortableSections = sortableContainer(({ sections }) => (
  <Flexbox flexDirection="column" marginTop="-20px">
    {sections.map(section => (
      <SortableSection key={section.id} index={sections.indexOf(section)} section={section} />
    ))}
  </Flexbox>
));

@inject('AppStore', 'TemplateStore', 'TemplateActions')
@observer
export default class TemplateEdit extends React.Component {
  handleSortEnd(section, newIndex) {
    const sectionObj = {
      id: section.id,
      row_order_position: newIndex,
    };
    this.props.TemplateActions.changeSection(sectionObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the section
    const findSection = this.props.TemplateStore.sections[oldIndex];

    // Reorder the current UI
    const newSections = arrayMove(this.props.TemplateStore.sections.slice(), oldIndex, newIndex);
    this.props.TemplateStore.sections = newSections;

    // Process reorder on server
    this.handleSortEnd(findSection, newIndex);
  }

  renderSections() {
    if (this.props.TemplateStore.activeSubsection === 'template') {
      if (this.props.TemplateStore.sections.length > 0) {
        const animatedClass = classNames('animated', this.props.TemplateStore.activeAnimation);
        return (
          <Flexbox flexDirection="column" flexGrow={1} marginTop="20px" className={animatedClass}>
            <Flexbox flexGrow={1} justifyContent="center" marginBottom="40px">
              <GuideButton action="sections_and_elements" />
            </Flexbox>
            <SortableSections
              sections={this.props.TemplateStore.sections}
              onSortEnd={this.onSortEnd.bind(this)}
              useDragHandle={true}
              lockToContainerEdges={true}
              lockAxis="y"
            />
            <TemplateAddSection type="app" appId={this.props.AppStore.activeApp.id} />
          </Flexbox>
        );
      }
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} justifyContent="center">
            <GuideButton action="sections_and_elements" />
          </Flexbox>
          <Flexbox flexDirection="column" marginTop="20px" marginBottom="20px" className="bp3-card">
            <NonIdealState
              title={I18n.t('js.nothing_to_show')}
              description={I18n.t('js.there_are_no_sections_to_show')}
              icon="duplicate"
              className="bp3-text-muted"
            />
          </Flexbox>
          <TemplateAddSection type="app" appId={this.props.AppStore.activeApp.id} />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <TemplateTabs />
        <Flexbox flexGrow={1} flexDirection="column">
          <TemplateHeader />
          <Flexbox flexDirection="column" flexGrow={1} className="template-container">
            {this.renderSections()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
