import React from 'react';
import Flexbox from 'flexbox-react';
import { AnchorButton, Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('CurrentUserStore', 'UserProfileTemplateStore')
@observer
export default class StoreTemplateProfileTemplateTabs extends React.Component {
  handleTabChange(tab) {
    this.props.UserProfileTemplateStore.activeProfileTemplateTab = tab;
  }

  renderRightLinks() {
    return (
      <Tabs
        id="tabnav"
        animate={true}
        defaultSelectedTabId={this.props.UserProfileTemplateStore.activeProfileTemplateTab}
        onChange={this.handleTabChange.bind(this)}
        className="noselect"
      >
        <Tab id="profileTemplate" title={I18n.t('js.profile_template')} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
          <Flexbox flexGrow={0} flexDirection="column" alignItems="center" justifyContent="center">
            <AnchorButton
              href={`/store-templates/${this.props.UserProfileTemplateStore.activeProfileTemplate.storeTemplateId}`}
              className="bp3-minimal"
              rightIcon="undo"
            >
              {I18n.t('js.store_template')}
            </AnchorButton>
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
