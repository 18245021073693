import React from 'react';
import Flexbox from 'flexbox-react';
import marked from 'marked';

export default class StringToMarkDown extends React.Component {
  render() {
    const rawMarkup = marked(this.props.string, { sanitize: true });
    return (
      <Flexbox
        flexDirection="column"
        dangerouslySetInnerHTML={{ __html: rawMarkup }}
      />
    );
  }
}
