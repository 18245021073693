/* for legacy code, remove class method this restriction: */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import WorkspaceStore from '../stores/WorkspaceStore';
import StoreTemplateStore from '../stores/StoreTemplateStore';
import StoreTemplateActions from '../actions/StoreTemplateActions';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import { NonIdealState } from '@blueprintjs/core';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import WorkspacesNewWorkspace from '../components/workspaces/WorkspacesNewWorkspace';
import WorkspaceWidget from '../components/workspaces/WorkspaceWidget';
import SkeletonTitle from '../components/skeletons/SkeletonTitle';
import SkeletonCard from '../components/skeletons/SkeletonCard';
import { arrayMove, sortableContainer, sortableElement } from 'react-sortable-hoc';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

const SortableWorkspace = sortableElement(({ workspace }) => <WorkspaceWidget workspace={workspace} />);

const SortableWorkspaces = sortableContainer(({ workspaces }) => (
  <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
    {workspaces.map(workspace => (
      <SortableWorkspace key={workspace.id} index={workspaces.indexOf(workspace)} workspace={workspace} />
    ))}
  </Flexbox>
));

@observer
export default class WorkspacesView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    WorkspaceStore.resetStartingAttributes();
    WorkspaceStore.fetchWorkspaces()
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleSortEnd(workspace, newIndex) {
    const workspaceObj = {
      id: workspace.id,
      row_order_position: newIndex,
    };
    WorkspaceStore.reorderWorkspace(workspaceObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the workspace
    const findWorkspace = WorkspaceStore.workspaces[oldIndex];

    // Reorder the current UI
    const newWorkspaces = arrayMove(WorkspaceStore.workspaces.slice(), oldIndex, newIndex);
    WorkspaceStore.workspaces = newWorkspaces;

    // Process reorder on server
    this.handleSortEnd(findWorkspace, newIndex);
  }

  renderWorkspaces() {
    if (WorkspaceStore.filteredWorkspaces.length === 0) {
      return (
        <Flexbox flexGrow={1} paddingTop="50px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_workspaces_to_show')}
            icon="grid-view"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (CurrentUserStore.userIsAccountOwner) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
          <SortableWorkspaces
            workspaces={WorkspaceStore.filteredWorkspaces}
            onSortEnd={this.onSortEnd.bind(this)}
            lockToContainerEdges={true}
            useDragHandle={false}
            axis="xy"
            distance={5}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
        {_.map(WorkspaceStore.filteredWorkspaces, workspace => (
          <WorkspaceWidget key={workspace.id} workspace={workspace} />
        ))}
      </Flexbox>
    );
  }

  renderContent() {
    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox flexDirection="column" width="100%">
          <SkeletonTitle />
          <Flexbox>
            <SkeletonCard width={170} />
            <SkeletonCard width={170} />
            <SkeletonCard width={170} />
            <SkeletonCard width={170} />
            <SkeletonCard width={170} />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <h2 className="bp3-heading push-20-b">{I18n.t('js.workspaces')}</h2>
        <Flexbox marginBottom="20px">
          <WorkspacesNewWorkspace />
        </Flexbox>
        <Flexbox flexDirection="row">{this.renderWorkspaces()}</Flexbox>
      </Flexbox>
    );
  }

  render() {
    const stores = {
      WorkspaceStore,
      CurrentUserStore,
      StoreTemplateStore,
      StoreTemplateActions,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
