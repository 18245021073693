import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent, Switch } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateActions', 'ToastStore')
@observer
export default class UserProfileNewTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleCancel() {
    this.props.UserProfileTemplateStore.resetNewProfileTemplate();
    this.props.toggleDialog();
  }

  handleSubmit(e) {
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    e.preventDefault();
    const profileTemplateObj = {
      name: this.props.UserProfileTemplateStore.newProfileTemplate
        .name,
      description: this.props.UserProfileTemplateStore
        .newProfileTemplate.description,
      default: this.props.UserProfileTemplateStore.newProfileTemplate
        .defaultTemplate,
      icon: 'far fa-address-card',
    };
    this.props.TemplateActions.createProfileTemplate(
      profileTemplateObj
    )
      .then(response => {
        this.props.UserProfileTemplateStore.addProfileTemplate(
          response.data
        );
        this.props.UserProfileTemplateStore.isEditingTemplate = false;
        this.props.ToastStore.showToast(
          I18n.t('js.profile_template_has_been_created'),
          'success'
        );
        this.props.toggleDialog();
        this.toggleLoading();
        this.props.UserProfileTemplateStore.resetNewProfileTemplate();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleDefaultTemplateChange() {
    this.props.UserProfileTemplateStore.updateNewProfileTemplate(
      !this.props.UserProfileTemplateStore.newProfileTemplate
        .defaultTemplate,
      'defaultTemplate'
    );
  }

  handleInputChange(e) {
    this.props.UserProfileTemplateStore.updateNewProfileTemplate(
      e.target.value,
      e.target.name
    );
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.UserProfileTemplateStore.newProfileTemplate[
        `${field}Valid`
      ]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {
            this.props.UserProfileTemplateStore.newProfileTemplate[
              `${field}Error`
            ]
          }
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.props.isOpen}
        onClose={this.props.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.create_a_new_profile_template')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.new_profile_template_description')}</p>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'name') &&
                  !this.props.UserProfileTemplateStore
                    .newProfileTemplate.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.profile_template_name')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'name') &&
                      !this.props.UserProfileTemplateStore
                        .newProfileTemplate.nameValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t(
                        'js.profile_template_name_placeholder'
                      )}
                      name="name"
                      value={
                        this.props.UserProfileTemplateStore
                          .newProfileTemplate.name
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('name')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'description') &&
                  !this.props.UserProfileTemplateStore
                    .newProfileTemplate.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="description">
                  {I18n.t('js.profile_template_description')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(
                        this.state.isBlurred,
                        'description'
                      ) &&
                      !this.props.UserProfileTemplateStore
                        .newProfileTemplate.nameValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <textarea
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t(
                        'js.profile_template_description_placeholder'
                      )}
                      name="description"
                      value={
                        this.props.UserProfileTemplateStore
                          .newProfileTemplate.description
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('description')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={
                  this.props.UserProfileTemplateStore
                    .newProfileTemplate.defaultTemplate
                }
                label={I18n.t(
                  'js.make_this_the_default_profile_template'
                )}
                onChange={this.handleDefaultTemplateChange.bind(this)}
              />
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.handleCancel.bind(this)}
              />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  !this.props.UserProfileTemplateStore
                    .newProfileTemplateFormValid
                }
                text={I18n.t('js.create_profile_template')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}
