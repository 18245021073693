import React from 'react';
import { inject, observer } from 'mobx-react';
import { Popover, Button, Menu, MenuItem } from '@blueprintjs/core';
import _ from 'lodash';

@inject('PermissionStore', 'AppStore', 'ToastStore')
@observer
export default class RelationshipPermissionRow extends React.Component {
  constructor(props) {
    super(props);
  }

  handleRemovePermission() {
    this.props.PermissionStore.removeRelationshipPermission(this.props.relationshipPermission.id);
  }

  handleParticipantClick(participant) {
    const relationshipPermission = _.clone(this.props.relationshipPermission);
    relationshipPermission.participantId = participant.id;
    this.props.PermissionStore.changeRelationshipPermission(relationshipPermission);
  }

  handleParticipantReset() {
    const relationshipPermission = _.clone(this.props.relationshipPermission);
    relationshipPermission.participantId = null;
    this.props.PermissionStore.changeRelationshipPermission(relationshipPermission);
  }

  renderMenu() {
    return (
      <Menu>
        <MenuItem key="all" text={I18n.t('js.all')} onClick={() => this.handleParticipantReset()} />
        {_.map(this.props.PermissionStore.participants, participant => {
          return <MenuItem key={participant.id} text={participant.name} onClick={() => this.handleParticipantClick(participant)} />;
        })}
      </Menu>
    );
  }

  renderParticipantSelection() {
    let btnText = I18n.t('js.all');
    if (this.props.relationshipPermission.participantId) {
      const findParticipant = _.find(this.props.PermissionStore.participants, o => o.id == this.props.relationshipPermission.participantId);
      if (findParticipant) {
        btnText = findParticipant.name;
      }
    }

    return (
      <Popover portalContainer={document.body} content={this.renderMenu()} position="bottom-left">
        <Button rightIcon="caret-down" className="bp3-small" text={btnText} />
      </Popover>
    );
  }

  render() {
    return (
      <tr>
        <td className="td-width-40">{this.props.relationshipPermission.relationship.name}</td>
        {this.props.type == 'app' ? (
          <td className="td-width-40">{this.renderParticipantSelection()}</td>
        ) : (
          <td className="td-width-40">N/A</td>
        )}
        <td className="td-width-20">
          <Button text={I18n.t('js.remove_relationship')} className="bp3-small" onClick={this.handleRemovePermission.bind(this)} />
        </td>
      </tr>
    );
  }
}
