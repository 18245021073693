/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class StateSection {
  @observable id;
  @observable stateId;
  @observable sectionId;
  @observable visible;
  @observable editable;
  @observable invitable;
  @observable commentable;
  @observable defaultCollapsed;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    stateId,
    sectionId,
    visible,
    editable,
    invitable,
    commentable,
    defaultCollapsed,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.stateId = stateId || '';
    this.sectionId = sectionId || '';
    typeof visible === 'undefined' ? (this.visible = true) : (this.visible = visible);
    typeof editable === 'undefined' ? (this.editable = true) : (this.editable = editable);
    typeof invitable === 'undefined' ? (this.invitable = true) : (this.invitable = invitable);
    typeof commentable === 'undefined' ? (this.commentable = true) : (this.commentable = commentable);
    typeof defaultCollapsed === 'undefined' ? (this.defaultCollapsed = false) : (this.defaultCollapsed = defaultCollapsed);
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
