import React from 'react';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import moment from 'moment';

export default class ContentfulAuthor extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="row" flexGrow={1} marginTop="20px">
        <Flexbox marginRight="10px">
          <a href={`/users/${this.props.author.fields.peoplegoalUserId}`}>
            <Avatar src={this.props.author.fields.image.fields.file.url} size={40} round={true} />
          </a>
        </Flexbox>
        <Flexbox flexDirection="column">
          <Flexbox className="bp3-text-muted">
            {I18n.t('js.posted_by')}
            <a href={`/users/${this.props.author.fields.peoplegoalUserId}`} className="push-5-l">
              <strong>{this.props.author.fields.displayName}</strong>
            </a>
          </Flexbox>
          <Flexbox alignItems="center">
            <small className="bp3-icon bp3-icon-calendar bp3-text-muted push-5-r" />
            <small className="bp3-text-muted">{moment.utc(this.props.publishedAt).format('MMMM Do YYYY, h:mm a')}</small>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
