import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import TeamsStore from '../stores/TeamsStore';
import TeamUsersStore from '../stores/TeamUsersStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import CurrentUserStore from '../stores/CurrentUserStore';
import AccountUsersStore from '../stores/AccountUsersStore';
import AppItemStore from '../stores/AppItemStore';
import ImportStore from '../stores/ImportStore';
import ImportActions from '../actions/ImportActions';
import TeamsShowTeams from '../components/teams/TeamsShowTeams';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class TeamsView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    TeamsStore.resetStartingAttributes();
    TeamUsersStore.resetStartingAttributes();
    TeamsStore.fetchTeams()
      .then(() => {
        TeamsStore.fetchTeamRelationships()
          .then(() => {
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderContent() {
    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px" marginTop="10px">
          <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
            <NonIdealState
              title={I18n.t('js.loading')}
              description={I18n.t('js.fetching_data')}
              icon={<Spinner />}
              className="bp3-text-muted"
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return <TeamsShowTeams />;
  }

  render() {
    const stores = {
      TeamsStore,
      AccountUsersStore,
      CurrentUserStore,
      ImportStore,
      ImportActions,
      AppItemStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <h2 className="bp3-heading push-10-b">{I18n.t('js.teams')}</h2>
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
