import React from 'react';
import Flexbox from 'flexbox-react';
import { AnchorButton, Slider, Tooltip } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

@inject('OrganizationStore')
@observer
export default class OrganizationGraphToolbar extends React.Component {
  toggleHierarchial() {
    this.props.OrganizationStore.updateRelationshipGraph(!this.props.OrganizationStore.relationshipGraph.hierarchical, 'hierarchical');
  }

  toggleSortMethod() {
    if (this.props.OrganizationStore.relationshipGraph.sortMethod === 'hubsize') {
      this.props.OrganizationStore.updateRelationshipGraph('directed', 'sortMethod');
    } else {
      this.props.OrganizationStore.updateRelationshipGraph('hubsize', 'sortMethod');
    }
  }

  handleSetLevelSeparation(number) {
    this.props.OrganizationStore.updateRelationshipGraph(number, 'levelSeparation');
  }

  handleSetNodeSpacing(number) {
    this.props.OrganizationStore.updateRelationshipGraph(number, 'nodeSpacing');
  }

  renderHierarchicalAnchorButton() {
    if (this.props.OrganizationStore.relationshipGraph.hierarchical) {
      return (
        <Tooltip usePortal={false} content={I18n.t('js.hierarchical_layout')} inheritDarkTheme={false} position="top">
          <AnchorButton className="bp3-button bp3-icon-layout-hierarchy" tabIndex="0" onClick={this.toggleHierarchial.bind(this)} />
        </Tooltip>
      );
    }
    return (
      <Tooltip usePortal={false} content={I18n.t('js.auto_layout')} inheritDarkTheme={false} position="top">
        <AnchorButton className="bp3-button bp3-icon-layout-auto" tabIndex="0" onClick={this.toggleHierarchial.bind(this)} />
      </Tooltip>
    );
  }

  renderPhysics() {
    return (
      <Tooltip usePortal={false} content={I18n.t('js.physics_enabled')} inheritDarkTheme={false} position="top">
        <AnchorButton
          className="bp3-button bp3-icon-exchange"
          tabIndex="0"
          onClick={() =>
            this.props.OrganizationStore.updateRelationshipGraph(!this.props.OrganizationStore.relationshipGraph.physics, 'physics')
          }
          active={this.props.OrganizationStore.relationshipGraph.physics}
          disabled={!this.props.OrganizationStore.relationshipGraph.hierarchical}
        />
      </Tooltip>
    );
  }

  renderSortMethod() {
    if (this.props.OrganizationStore.relationshipGraph.sortMethod === 'hubsize') {
      return (
        <Tooltip usePortal={false} content={I18n.t('js.sort_by_hubsize')} inheritDarkTheme={false} position="top">
          <AnchorButton
            className="bp3-button bp3-icon-layout-circle"
            tabIndex="0"
            onClick={this.toggleSortMethod.bind(this)}
            disabled={!this.props.OrganizationStore.relationshipGraph.hierarchical}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip usePortal={false} content={I18n.t('js.sort_by_direction')} inheritDarkTheme={false} position="top">
        <AnchorButton
          className="bp3-button bp3-icon-layout-sorted-clusters"
          tabIndex="0"
          onClick={this.toggleSortMethod.bind(this)}
          disabled={!this.props.OrganizationStore.relationshipGraph.hierarchical}
        />
      </Tooltip>
    );
  }

  renderDirectionAnchorButton(direction) {
    let directionClass = classNames('bp3-button');
    if (direction === 'UD') {
      directionClass = classNames('bp3-button bp3-icon-arrow-up');
    }
    if (direction === 'DU') {
      directionClass = classNames('bp3-button bp3-icon-arrow-down');
    }
    if (direction === 'LR') {
      directionClass = classNames('bp3-button bp3-icon-arrow-left');
    }
    if (direction === 'RL') {
      directionClass = classNames('bp3-button bp3-icon-arrow-right');
    }
    return (
      <Tooltip usePortal={false} content={I18n.t(`js.${direction.toLowerCase()}`)} inheritDarkTheme={false} position="top">
        <AnchorButton
          className={directionClass}
          tabIndex="0"
          onClick={() => this.props.OrganizationStore.updateRelationshipGraph(direction, 'direction')}
          active={this.props.OrganizationStore.relationshipGraph.direction === direction}
          disabled={!this.props.OrganizationStore.relationshipGraph.hierarchical}
        />
      </Tooltip>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="row" flexGrow={1} marginTop="20px" justifyContent="center" alignItems="center">
        <div className="bp3-button-group bp3-minimal">
          {this.renderHierarchicalAnchorButton()}
          {this.renderPhysics()}
          {this.renderSortMethod()}
          {this.renderDirectionAnchorButton('UD')}
          {this.renderDirectionAnchorButton('DU')}
          {this.renderDirectionAnchorButton('LR')}
          {this.renderDirectionAnchorButton('RL')}
        </div>
        {/* <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexGrow={1} flexDirection="column" alignItems="center">
            <Slider
              disabled={!this.props.OrganizationStore.relationshipGraph.hierarchical}
              initialValue={0}
              value={this.props.OrganizationStore.relationshipGraph.levelSeparation}
              labelStepSize={50}
              min={50}
              max={500}
              labelRenderer={false}
              onChange={this.handleSetLevelSeparation.bind(this)}
            />
            <small className="bp3-text-muted push-10-t">{I18n.t('js.level_separation')}</small>
          </Flexbox>
          <Flexbox marginLeft="20px" flexGrow={1} flexDirection="column" alignItems="center">
            <Slider
              disabled={!this.props.OrganizationStore.relationshipGraph.hierarchical}
              initialValue={0}
              value={this.props.OrganizationStore.relationshipGraph.nodeSpacing}
              labelStepSize={50}
              min={50}
              max={500}
              labelRenderer={false}
              onChange={this.handleSetNodeSpacing.bind(this)}
            />
            <small className="bp3-text-muted push-10-t">{I18n.t('js.node_spacing')}</small>
          </Flexbox>
        </Flexbox> */}
      </Flexbox>
    );
  }
}
