/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import {
  AnchorButton,
  Button,
  NonIdealState,
} from '@blueprintjs/core';
import { extendObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import StateCondition from '../../models/StateConditionModel';
import StateConditionValue from '../../models/StateConditionValueModel';
import StateConditionItem from './StateConditionItem';
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => (
  <AnchorButton
    className="bp3-cursor-move bp3-minimal"
    icon="drag-handle-vertical"
  />
));

const SortableCondition = sortableElement(({ condition }) => (
  <StateConditionItem
    stateCondition={condition}
    dragHandle={<DragHandle />}
  />
));

const SortableConditions = sortableContainer(({ conditions }) => (
  <Flexbox flexDirection="column" flexGrow={1}>
    {conditions.map(condition => (
      <SortableCondition
        key={condition.id}
        index={conditions.indexOf(condition)}
        condition={condition}
      />
    ))}
  </Flexbox>
));

@inject(
  'StateRuleStore',
  'StateRuleActions',
  'TemplateStore',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class StateConditions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isBlurred: [] };
  }

  handleSortEnd(condition, newIndex) {
    condition.changeType = 'row_order_changed';
    condition.rowOrder = newIndex;
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the condition
    const findCondition = this.props.StateRuleStore
      .activeStateConditions[oldIndex];

    // Reorder the current UI
    const newConditions = arrayMove(
      this.props.StateRuleStore.stateConditions.slice(),
      oldIndex,
      newIndex
    );
    this.props.StateRuleStore.stateConditions = newConditions;

    // Process reorder on server
    this.handleSortEnd(findCondition, newIndex);
  }

  addStateCondition() {
    const newCondition = new StateCondition(
      '',
      this.props.StateRuleStore.activeStateRule.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      'added'
    );
    const newConditionValue = new StateConditionValue(
      '',
      newCondition.id,
      'string',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      'added'
    );
    extendObservable(newCondition, {
      stateConditionValue: newConditionValue,
    });
    this.props.StateRuleStore.stateConditions = this.props.StateRuleStore.stateConditions.concat(
      newCondition
    );
  }

  renderConditions() {
    if (
      this.props.StateRuleStore.activeStateConditions.length === 0
    ) {
      return (
        <Flexbox
          flexGrow={1}
          className="bp3-card bp3-card-gray"
          flexDirection="column"
        >
          <NonIdealState
            title={I18n.t('js.no_conditions')}
            description={I18n.t(
              'js.there_are_no_conditions_for_this_rule'
            )}
            icon="filter"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexGrow={1}
        className="bp3-card bp3-card-gray"
        flexDirection="column"
      >
        <SortableConditions
          conditions={this.props.StateRuleStore.activeStateConditions}
          onSortEnd={this.onSortEnd.bind(this)}
          useDragHandle={true}
          lockToContainerEdges={true}
          lockAxis="y"
        />
      </Flexbox>
    );
  }

  renderAddButton() {
    return (
      <Flexbox marginTop="20px" flexGrow={1}>
        <Button
          icon="filter"
          text={I18n.t('js.add_condition')}
          onClick={this.addStateCondition.bind(this)}
        />
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.renderConditions()}
        {this.renderAddButton()}
      </Flexbox>
    );
  }
}
