import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Button, NonIdealState } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import CommentStore from '../stores/CommentStore';
import ActivityStore from '../stores/ActivityStore';
import Activity from '../components/activities/Activity';
import SkeletonRows from '../components/skeletons/SkeletonRows';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class ActivityView extends React.Component {
  @observable isLoading = true;
  @observable loadingMore = false;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  toggleLoadingMore() {
    this.loadingMore = !this.loadingMore;
  }

  componentDidMount() {
    ActivityStore.resetActivities();
    ActivityStore.newItemUser = CurrentUserStore.currentUser;
    const appNum = 1;
    const perPage = 10;
    const offset = 0;
    ActivityStore.fetchActivities(this.props.activityObjectId, this.props.activityObjectType, appNum, perPage, offset)
      .then(res => {
        ActivityStore.createPaginator(res.headers);
        _.map(res.data, activity => {
          ActivityStore.addActivity(activity);
        });
        this.toggleLoading();
      })
      .catch(this.toggleLoading);
  }

  loadMore() {
    this.toggleLoadingMore();
    const perPage = 10;
    const offset = 0;
    ActivityStore.fetchActivities(
      this.props.activityObjectId,
      this.props.activityObjectType,
      ActivityStore.pagination.xPage + 1,
      perPage,
      offset
    )
      .then(response => {
        ActivityStore.createPaginator(response.headers);
        _.map(response.data, activity => {
          ActivityStore.addActivity(activity);
        });
        this.toggleLoadingMore();
      })
      .catch(() => {
        this.toggleLoadingMore();
      });
  }

  renderLoadMore() {
    if (ActivityStore.activities.length < ActivityStore.pagination.xTotal) {
      return (
        <Flexbox flexGrow={1} marginTop="20px" justifyContent="center">
          <Button
            className="bp3-minimal bp3-large bp3-text-muted"
            icon="more"
            onClick={this.loadMore.bind(this)}
            loading={this.loadingMore}
          />
        </Flexbox>
      );
    }
    if (ActivityStore.activities.length === ActivityStore.pagination.xTotal && !this.isLoading) {
      if (ActivityStore.activities.length > 0) {
        return (
          <Flexbox flexGrow={1} marginTop="20px" justifyContent="center">
            <NonIdealState description={I18n.t('js.there_is_no_more_activity_to_show')} icon="feed-subscribed" className="bp3-text-muted" />
          </Flexbox>
        );
      }
      return (
        <Flexbox flexGrow={1} marginTop="50px" justifyContent="center">
          <NonIdealState description={I18n.t('js.there_are_no_activities_to_show')} icon="feed" className="bp3-text-muted" />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderActivities() {
    if (this.isLoading) {
      return (
        <Flexbox>
          <SkeletonRows numRows={4} />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        {_.map(ActivityStore.activities, (activity, i) => (
          <Flexbox flexGrow={1} key={i}>
            <Activity activity={activity} />
          </Flexbox>
        ))}
      </Flexbox>
    );
  }

  render() {
    const stores = {
      ActivityStore,
      CommentStore,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox flexGrow={1} flexDirection="column" marginTop="10px">
            {this.renderActivities()}
            {this.renderLoadMore()}
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
