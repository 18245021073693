import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Spinner } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import _ from 'lodash';
import moment from 'moment';
import renderHTML from 'react-render-html';

const penceInPound = 100;
const moneyDecimalPlaces = 2;

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class AccountBillingInvoices extends React.Component {
  handleColumnSort(column) {
    this.props.AccountBillingStore.setInvoiceSortParams(
      column.id,
      column.sortDir
    );
  }

  renderUpcomingInvoice() {
    const { upcomingInvoice } = this.props.AccountBillingStore;
    if (upcomingInvoice !== '') {
      return (
        <Flexbox flexGrow={1} marginBottom="20px">
          <div className="bp3-callout bp3-intent-primary bp3-icon-info-sign">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>
              {I18n.t('js.your_next_invoice')}
            </h4>
            {renderHTML(
              I18n.t(
                'js.your_next_invoice_is_due_on_and_for_amount',
                {
                  due_date: moment
                    .unix(upcomingInvoice.date)
                    .format('MMMM Do YYYY, h:mm a'),
                  amount: `${upcomingInvoice.currency.toUpperCase()} ${(
                    upcomingInvoice.amount_due / penceInPound
                  ).toFixed(moneyDecimalPlaces)}`,
                }
              )
            )}
          </div>
        </Flexbox>
      );
    }
    return undefined;
  }
  render() {
    if (this.props.AccountBillingStore.isLoadingInvoices) {
      return (
        <Flexbox
          justifyContent="center"
          alignItems="center"
          paddingTop="20px"
          flexGrow={1}
          flexDirection="column"
        >
          <Spinner />
        </Flexbox>
      );
    }
    if (this.props.AccountBillingStore.invoices.length > 0) {
      return (
        <Flexbox
          className="bp3-card bp3-card-no-padding"
          flexGrow={1}
          flexDirection="column"
        >
          {this.renderUpcomingInvoice()}
          <Flexbox padding="20px" flexGrow={1} flexDirection="column">
            <h2 className={Classes.HEADING}>
              {I18n.t('js.invoices')}
            </h2>
            <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
              <thead>
                <SimpleTableHeader
                  columns={
                    this.props.AccountBillingStore.invoiceColumns
                  }
                  handleColumnSort={this.handleColumnSort.bind(this)}
                />
              </thead>
              <tbody>
                {_.map(
                  this.props.AccountBillingStore.invoices,
                  invoice => (
                    <tr key={invoice.id}>
                      <td>
                        {moment
                          .unix(invoice.date)
                          .format('MMMM Do YYYY, h:mm a')}
                      </td>
                      <td>
                        <a
                          href={invoice.hosted_invoice_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {invoice.number}
                        </a>
                      </td>
                      <td>{`${invoice.currency.toUpperCase()} ${(
                        invoice.amount_due / penceInPound
                      ).toFixed(moneyDecimalPlaces)}`}</td>
                      <td>
                        <span
                          className={
                            invoice.paid
                              ? 'bp3-tag bp3-intent-success'
                              : 'bp3-tag'
                          }
                        >
                          {invoice.paid
                            ? I18n.t('js.paid')
                            : I18n.t('js.pending')}
                        </span>
                      </td>
                      <td>
                        <AnchorButton
                          text={I18n.t('js.view_invoice')}
                          icon="document-open"
                          target="_blank"
                          href={invoice.hosted_invoice_url}
                          className="push-10-r"
                        />
                        <AnchorButton
                          text={I18n.t('js.download_pdf')}
                          icon="cloud-download"
                          target="_blank"
                          href={invoice.invoice_pdf}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }
}
