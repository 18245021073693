import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('AppStore', 'AppItemStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppItemsNewItemNameInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isAdding: false,
      isBlurred: [],
    };
  }

  handleInputChange(e) {
    this.props.handleNameInputChange(e.target.value);
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && this.props.name.length === 0) {
      return <div className="bp3-form-helper-text">{I18n.t('js.name_is_required')}</div>;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexGrow={1}>
        <div
          className={
            _.includes(this.state.isBlurred, 'name') && this.props.name.length === 0
              ? classNames('bp3-form-group max-width bp3-intent-danger')
              : classNames('bp3-form-group max-width')
          }
        >
          <label className="bp3-label" htmlFor="name">
            {this.props.label === 'default'
              ? I18n.t('js.name')
              : I18n.t('js.item_name_type', {
                  item: this.props.AppStore.activeApp.item,
                })}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <div className="bp3-form-content">
            <div
              className={
                _.includes(this.state.isBlurred, 'name') && this.props.name.length === 0
                  ? classNames('bp3-input-group bp3-intent-danger')
                  : classNames('bp3-input-group')
              }
            >
              <input
                autoComplete="off"
                className="bp3-input bp3-fill"
                type="text"
                placeholder={I18n.t('js.enter_item_name_placeholder')}
                name="name"
                value={this.props.name}
                onChange={this.handleInputChange.bind(this)}
                onBlur={this.handleBlurChange.bind(this)}
                dir="auto"
              />
            </div>
            {this.renderErrors('name')}
          </div>
        </div>
      </Flexbox>
    );
  }
}
