import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import Flexbox from 'flexbox-react';
import Utilities from '../../../utils/Utilities';
import _ from 'lodash';
import { Tag } from '@blueprintjs/core';

@inject(
  'BambooIntegrationStore',
  'AzureIntegrationStore',
  'ToastStore'
)
@observer
export default class AzureIntegrationFormLogic extends React.Component {
  @observable
  isLoading = false;
  @observable
  errors = {};

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (!this.props.AzureIntegrationStore.azureIntegration) {
      this.props.AzureIntegrationStore.createEmptyIntegration();
    }
  }

  handleInputChange(e) {
    this.props.AzureIntegrationStore.validateInput(
      e.target.name,
      e.target.value,
      (blur = 'false')
    );
  }

  handleBlurChange(e) {
    this.props.AzureIntegrationStore.validateInput(
      e.target.name,
      e.target.value,
      (blur = 'true')
    );
  }

  @computed
  get isDisabled() {
    const active = this.props.AzureIntegrationStore.azureIntegration
      .active;
    const id = this.props.AzureIntegrationStore.azureIntegration.id;
    if (!active && id == '') {
      return false;
    } else if (!active && id != '') {
      return true;
    } else if (active && id != '') {
      return false;
    }
  }

  renderErrors(field) {
    const errorsList = this.props.AzureIntegrationStore.errorsList;
    if (!_.isEmpty(errorsList) && field in errorsList) {
      return <span>{errorsList[field]}</span>;
    }
    return undefined;
  }

  renderTag() {
    return (
      <Flexbox>
        <Tag className="bp3-intent-success bp3-large">
          .bamboohr.com
        </Tag>
      </Flexbox>
    );
  }

  render() {
    const {
      clientId,
      clientSecret,
      clientUri,
      clientTenant,
      includeProfilePhotos,
      includeDepartments,
      includeManagers,
    } = this.props.AzureIntegrationStore.azureIntegration;
    const hasErrors = this.props.BambooIntegrationStore.hasErrors;
    const errorsList = this.props.AzureIntegrationStore.errorsList;

    return React.cloneElement(this.props.children, {
      handleInputChange: this.handleInputChange.bind(this),
      handleBlurChange: this.handleBlurChange.bind(this),
      renderErrors: this.renderErrors.bind(this),
      renderTag: this.renderTag.bind(this),
      clientIdVal: clientId,
      clientSecretVal: clientSecret,
      clientUriVal: clientUri,
      clientTenantVal: clientTenant,
      includeProfilePhotos: includeProfilePhotos,
      includeDepartments: includeDepartments,
      includeManagers: includeManagers,
      errors: errorsList,
      isDisabled: this.isDisabled,
      hasErrors: hasErrors,
    });
  }
}
