// @flow
/* eslint class-methods-use-this: 0 */
import * as React from 'react';
import { computed } from 'mobx';
import { FormGroup, InputGroup, HTMLSelect, TextArea, Button, AnchorButton } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from '../../stores/ToastStore';
import axiosCancel from 'axios-cancel';

axiosCancel(axios, {
  debug: false,
});

type NewDemoRequestProps = {
  submitLabel: string,
  redirectUrl: string,
};

type NewDemoRequestState = {
  firstName: string,
  lastName: string,
  email: string,
  companyName: string,
  companySize: string,
  requirements: string,
  formSubmitted: boolean,
  isSubmitting: boolean,
};

const companySizeOptions = [
  { label: 'Select number of employees', value: '' },
  { label: '0-10 Employees', value: '0-10' },
  { label: '10-50 Employees', value: '10-50' },
  { label: '50-250 Employees', value: '50-250' },
  { label: '250-500 Employees', value: '250-500' },
  { label: '500+ Employees', value: '500+' },
];

class NewDemoRequest extends React.Component<NewDemoRequestProps, NewDemoRequestState> {
  constructor(props: NewDemoRequestProps) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      companySize: '',
      requirements: '',
      isSubmitting: false,
      formSubmitted: false,
    };
  }

  @computed
  get formIsValid() {
    return (
      this.state.firstName.length !== 0 &&
      this.state.lastName.length !== 0 &&
      this.emailValid &&
      this.state.companyName.length !== 0 &&
      this.state.companySize.length !== 0 &&
      this.state.requirements.length !== 0
    );
  }

  @computed
  get emailValid() {
    return Utilities.validateEmail(this.state.email);
  }

  handleFirstNameChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ firstName: e.target.value });
  }

  handleLastNameChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ lastName: e.target.value });
  }

  handleEmailChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ email: e.target.value });
  }

  handleCompanyNameChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ companyName: e.target.value });
  }

  handleCompanySizeChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ companySize: e.target.value });
  }

  handleRequirementsChange(e: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ requirements: e.target.value });
  }

  handleResetState() {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      companySize: '',
      requirements: '',
      isSubmitting: false,
    });
  }

  handleSubmit(e: SyntheticEvent<>) {
    e.preventDefault();
    if (this.formIsValid) {
      this.setState({ isSubmitting: true });
      return new Promise((resolve, reject) => {
        axios
          .post(`${Cookies.get('apiEnv')}/demo_requests`, {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            company_name: this.state.companyName,
            company_size: this.state.companySize,
            requirements: this.state.requirements,
          })
          .then(response => {
            resolve(response);
            this.setState({ isSubmitting: true });
            if (this.props.redirectUrl) {
              Utilities.navigate(this.props.redirectUrl);
            } else {
              this.setState({ formSubmitted: true });
            }
          })
          .catch(error => {
            reject(error);
            this.setState({ isSubmitting: true });
          });
      });
    }
    ToastStore.showToast('Please complete all required fields', 'danger');
    return null;
  }

  render() {
    if (this.state.formSubmitted) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox justifyContent="center">
            <h2>{I18n.t('js.thank_you')}</h2>
          </Flexbox>
          <Flexbox justifyContent="center">
            <p>{I18n.t('js.one_of_our_team_will_be_in_touch')}</p>
          </Flexbox>
          <Flexbox marginTop="20px" justifyContent="center">
            <AnchorButton text={I18n.t('js.home')} className="bp3-intent-primary" icon="home" href="/" />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="row" flexGrow={1}>
            <Flexbox flexDirection="column" flexGrow={1} paddingRight="10px">
              <FormGroup label={I18n.t('js.first_name')} labelFor="first_name" labelInfo={`(${I18n.t('js.required')})`}>
                <InputGroup
                  id="first_name"
                  placeholder="John"
                  className="bp3-large"
                  onChange={this.handleFirstNameChange.bind(this)}
                  value={this.state.firstName}
                />
              </FormGroup>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={1} paddingLeft="10px">
              <FormGroup label={I18n.t('js.last_name')} labelFor="last_name" labelInfo={`(${I18n.t('js.required')})`}>
                <InputGroup
                  id="last_name"
                  placeholder="Smith"
                  className="bp3-large"
                  onChange={this.handleLastNameChange.bind(this)}
                  value={this.state.lastName}
                />
              </FormGroup>
            </Flexbox>
          </Flexbox>
          <FormGroup label={I18n.t('js.email')} labelFor="email" labelInfo={`(${I18n.t('js.required')})`}>
            <InputGroup
              id="email"
              placeholder="someone@example.com"
              className="bp3-large"
              onChange={this.handleEmailChange.bind(this)}
              value={this.state.email}
            />
          </FormGroup>
          <FormGroup label={I18n.t('js.company_name')} labelFor="company_name" labelInfo={`(${I18n.t('js.required')})`}>
            <InputGroup
              id="company_name"
              placeholder="Example Inc."
              className="bp3-large"
              onChange={this.handleCompanyNameChange.bind(this)}
              value={this.state.companyName}
            />
          </FormGroup>
          <FormGroup label={I18n.t('js.company_size')} labelFor="company_size" labelInfo={`(${I18n.t('js.required')})`}>
            <HTMLSelect
              className="max-width bp3-large"
              options={companySizeOptions}
              onChange={this.handleCompanySizeChange.bind(this)}
              value={this.state.companySize}
            />
          </FormGroup>
          <FormGroup
            label={I18n.t('js.demo_request_requirements')}
            labelFor="demo_request_requirements"
            labelInfo={`(${I18n.t('js.required')})`}
          >
            <TextArea
              id="demo_request_requirements"
              placeholder={I18n.t('js.demo_request_requirements_description')}
              fill={true}
              large={true}
              style={{ height: '200px' }}
              onChange={this.handleRequirementsChange.bind(this)}
              value={this.state.requirements}
            />
          </FormGroup>
          <Button
            type="submit"
            className="bp3-intent-primary bp3-large bp3-fill"
            text={I18n.t('js.request_a_demo')}
            loading={this.state.isSubmitting}
          />
        </form>
      </Flexbox>
    );
  }
}

export default NewDemoRequest;
