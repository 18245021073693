import React, { Component } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import WidgetStore from '../../stores/WidgetStore';
import Flexbox from 'flexbox-react';
import CommentsView from '../../views/CommentsView';
import RichTextDisplay from '../shared/RichTextDisplay';
import { inject, observer } from 'mobx-react';

@observer
export default class WidgetPost extends Component {
  render() {
    const { widget, editable } = this.props;

    if (widget.content || widget.image_file_name) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} marginBottom="10px">
          {widget.title ? <h1 className="bp3-label-no-margin">{widget.title}</h1> : null}
          {widget.content ? <RichTextDisplay content={widget.content} /> : null}
          {widget.image_file_name ? (
            <Flexbox flexDirection="column" flexGrow={1} marginTop="20px" justifyContent="center" alignItems="center">
              <img src={widget.image_file_name} className="widget-image" />
            </Flexbox>
          ) : null}
        </Flexbox>
      );
    }
    if (editable) {
      return (
        <Flexbox
          flexDirection="row"
          flexGrow={1}
          justifyContent="center"
          paddingBottom="20px"
          style={{ borderBottom: '1px solid #EBF1F5' }}
        >
          <NonIdealState
            title={I18n.t('js.no_content')}
            description={I18n.t('js.click_the_edit_button_to_add_content')}
            icon="add-to-artifact"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return null;
  }
}
