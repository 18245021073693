/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import CurrencyDefinitions from '../../static/CurrencyDefinitions';
import { FormGroup, Intent, RangeSlider } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('ItemStore', 'ItemActions', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions')
@observer
export default class ProfileElementRangeSlider extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  inputValueRange = [0, 0];
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      let newRange = [];
      _.map(this.props.element.formValues, (formValue, index) => {
        if (index <= 1) {
          newRange.push(parseInt(formValue.numberValue));
        }
      });
      newRange = _.sortBy(newRange);
      this.inputValueRange = newRange;
    } else {
      this.inputValueRange = [this.props.element.elementSlider.minValue, this.props.element.elementSlider.minValue];
    }
    this.mountDisplayValuesInFlight();
  }

  mountDisplayValuesInFlight() {
    let values = [];
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_id === this.props.element.id) {
        values.push(formValue.numberValue);
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        values.push(findFormValueToChange.numberValue);
      }
    });
    // eslint-disable-next-line
    if (values.length === 2) {
      values = _.sortBy(values);
      this.inputValue = [values[0], values[1]];
    }
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'number';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'number';
        return object;
    }
  }

  handleValueChange(arr) {
    this.inputValueRange = arr;
    if (this.actionType === 'updating') {
      _.map(arr, (numberValue, index) => {
        const changedFormValue = {
          id: this.props.element.formValues[index].id,
          number_value: numberValue,
        };
        this.store.addFormValueToChange(changedFormValue);
      });
    } else {
      this.store.formValuesToCreate = _.filter(this.store.formValuesToCreate, o => {
        if (o.element_id === this.props.element.id) {
          return false;
        }
        return true;
      });
      if (arr[0] !== null && arr[1] !== null) {
        _.map(arr, numberValue => {
          let newFormValue = {
            id: Utilities.makeId(),
            element_id: this.props.element.id,
            number_value: numberValue,
          };
          newFormValue = this.appendAttributes(newFormValue);
          this.store.addFormValueToCreate(newFormValue);
        });
      }
    }
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  labelRenderer(n, element) {
    if (element.elementSlider.labelType === 'currency') {
      const findCurrency = _.find(CurrencyDefinitions.currencies, o => o.code === element.elementSlider.labelCurrencyType);
      const t = n.toString().replace(/\B(?=(\d{3})+(?!\d))/gu, ',');
      if (findCurrency) {
        return `${findCurrency.symbol_native}${t}`;
      }
      return `${t}`;
    }
    if (element.elementSlider.labelType === 'percentage') {
      return `${n}%`;
    }
    return n;
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderLabel(name) {
    return (
      <label>
        <Flexbox flexDirection="row" alignItems="center" alignContent="center" flexGrow={0} className="bp3-display-label push-10-b">
          <span className="push-10-r">{name}</span>
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
        </Flexbox>
      </label>
    );
  }

  render() {
    if (this.props.noTitle) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox
            flexGrow={1}
            paddingLeft={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            paddingRight={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            marginTop="10px"
          >
            <RangeSlider
              disabled={!this.props.element.isEditable}
              labelStepSize={parseInt(this.props.element.elementSlider.stepSize)}
              min={parseInt(this.props.element.elementSlider.minValue)}
              max={parseInt(this.props.element.elementSlider.maxValue)}
              onChange={arr => {
                this.handleValueChange(arr);
              }}
              value={this.inputValueRange}
              vertical={this.props.element.elementSlider.vertical}
              labelRenderer={n => this.labelRenderer(n, this.props.element)}
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          className={this.props.element.isEditable ? '' : 'bp3-display-label'}
          label={this.props.element.elementSlider.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          <Flexbox
            flexGrow={1}
            paddingLeft={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            paddingRight={!this.props.element.elementSlider.vertical ? '10px' : '0px'}
            marginTop="10px"
          >
            <RangeSlider
              disabled={!this.props.element.isEditable}
              labelStepSize={parseInt(this.props.element.elementSlider.stepSize)}
              min={parseInt(this.props.element.elementSlider.minValue)}
              max={parseInt(this.props.element.elementSlider.maxValue)}
              onChange={arr => {
                this.handleValueChange(arr);
              }}
              value={this.inputValueRange}
              vertical={this.props.element.elementSlider.vertical}
              labelRenderer={n => this.labelRenderer(n, this.props.element)}
            />
          </Flexbox>
        </FormGroup>
      </Flexbox>
    );
  }
}
