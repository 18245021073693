/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import AppStore from '../stores/AppStore';
import UserProfileStore from '../stores/UserProfileStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import TemplateActions from '../actions/TemplateActions';
import OrganizationStore from '../stores/OrganizationStore';
import AccountUsersStore from '../stores/AccountUsersStore';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import LayoutHeader from '../components/layout/LayoutHeader';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import OrganizationOptions from '../components/organization/OrganizationOptions';
import OrganizationActiveRelationship from '../components/organization/OrganizationActiveRelationship';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class OrganizationView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    OrganizationStore.fetchRelationships()
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleRelationshipClick(relationship) {
    OrganizationStore.setActiveRelationship(relationship);
    OrganizationStore.setActiveTab('individual');
  }

  renderRelationships() {
    if (CurrentUserStore.isLoading || this.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (OrganizationStore.relationships.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" paddingTop="50px" className="bp3-card" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_relationship_types')}
            icon="send-to-graph"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (OrganizationStore.activeTab === 'individual') {
      return <OrganizationActiveRelationship activeRelationship={OrganizationStore.activeRelationship} />;
    }
    return undefined;
  }

  render() {
    const stores = {
      AppStore,
      OrganizationStore,
      AccountUsersStore,
      CurrentUserStore,
      UserProfileStore,
      UserProfileTemplateStore,
      ToastStore,
      TemplateActions,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <Flexbox marginBottom="10px">
                  <h2 className="bp3-heading">{I18n.t('js.organization')}</h2>
                </Flexbox>
                <OrganizationOptions />
                {this.renderRelationships()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
