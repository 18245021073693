import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import TimelineStore from '../../stores/TimelineStore';
import { Classes, Switch, NonIdealState, Spinner, AnchorButton, Intent, Alignment, Icon } from '@blueprintjs/core';
import NewTimelineEvent from './NewTimelineEvent';
import EditTimelineEvent from './EditTimelineEvent';
import Timeline from 'react-visjs-timeline';
import _ from 'lodash';

@observer
export default class UserTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditDialog: false,
    };
  }

  componentDidMount() {
    TimelineStore.resetStartingAttributes();
    TimelineStore.fetchUserTimelines(this.props.userId)
      .then(response => {
        TimelineStore.isLoading = false;
      })
      .catch(error => {
        TimelineStore.isLoading = false;
      });
  }

  toggleEditDialog() {
    this.setState({ showEditDialog: !this.state.showEditDialog });
  }

  doubleClickHandler(event) {
    if (event.item) {
      const timeline = _.find(TimelineStore.timelines, o => o.id === event.item);
      TimelineStore.setActiveTimeline(timeline);
      this.toggleEditDialog();
    }
  }

  renderTimeline() {
    const options = {
      minHeight: '300px',
    };

    return <Timeline options={options} items={TimelineStore.timelineItems} doubleClickHandler={this.doubleClickHandler.bind(this)} />;
  }

  render() {
    if (TimelineStore.isLoading) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} marginTop={this.props.showTitles ? '20px' : '0px'}>
          {this.props.showTitles ? (
            <Flexbox flexDirection="column" width="200px" height="30px" marginBottom="20px" className="bp3-skeleton"></Flexbox>
          ) : null}
          <Flexbox flexDirection="row" flexGrow={0}>
            <Flexbox flexDirection="column" width="100%" height="300px" marginRight="20px" className="bp3-skeleton"></Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} marginTop={this.props.showTitles ? '20px' : '0px'} flexDirection="column">
        {this.props.showTitles ? (
          <>
            <h3 className={Classes.HEADING}>
              <Icon icon="timeline-line-chart" iconSize={25} className="push-10-r" />
              {`${I18n.t('js.your_timeline')} (${TimelineStore.timelines.length})`}
            </h3>
            <p>{I18n.t('js.your_timeline_description')}</p>
          </>
        ) : null}
        <Flexbox
          flexDirection="column"
          className="bp3-cursor-pointer bp3-card"
          flexGrow={1}
          marginTop={this.props.showTitles ? '20px' : '0px'}
        >
          {this.renderTimeline()}
        </Flexbox>
        {this.props.isEditable ? (
          <>
            <NewTimelineEvent userId={this.props.userId} />
            {TimelineStore.activeTimeline ? (
              <EditTimelineEvent
                userId={this.props.userId}
                showDialog={this.state.showEditDialog}
                toggleDialog={this.toggleEditDialog.bind(this)}
              />
            ) : null}
          </>
        ) : null}
      </Flexbox>
    );
  }
}
