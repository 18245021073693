import React from 'react';
import Flexbox from 'flexbox-react';
import { observer } from 'mobx-react';
import { Checkbox, Radio } from '@blueprintjs/core';
import ElementReadOnly from '../../shared/ElementReadOnly';
import classNames from 'classnames';
import _ from 'lodash';

@observer
export default class ElementGridDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = { rows: [] };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      rows: this.props.element.elementGrid.elementGridRows,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      rows: nextProps.element.elementGrid.elementGridRows,
    });
  }

  handleRadioChange(rowId, columnId) {
    const findRow = _.find(this.state.rows, o => o.id === rowId);
    if (findRow) {
      findRow.selectedColumnId = columnId;
      this.setState({
        rows: _.unionBy([findRow], this.state.rows, 'id'),
      });
    }
  }

  renderDisplay() {
    if (this.props.element.elementGrid.displayType === 'radio') {
      return (
        <tbody>
          {_.map(
            this.props.element.elementGrid.elementGridRows,
            row => (
              <tr key={row.id}>
                <td>{row.name}</td>
                {_.map(
                  this.props.element.elementGrid.elementGridColumns,
                  column => {
                    let checked = false;
                    const findRow = _.find(
                      this.state.rows,
                      o => o.id === row.id
                    );
                    if (findRow.selectedColumnId === column.id) {
                      checked = true;
                    }
                    return (
                      <td key={column.id} className="grid-td">
                        <Flexbox justifyContent="center">
                          <Radio
                            checked={checked}
                            onChange={() =>
                              this.handleRadioChange(
                                row.id,
                                column.id
                              )
                            }
                            disabled={this.props.disabled}
                          />
                        </Flexbox>
                      </td>
                    );
                  }
                )}
              </tr>
            )
          )}
        </tbody>
      );
    }
    return (
      <tbody>
        {_.map(
          this.props.element.elementGrid.elementGridRows,
          row => (
            <tr key={row.id}>
              <td>{row.name}</td>
              {_.map(
                this.props.element.elementGrid.elementGridColumns,
                column => (
                  <td key={column.id} className="grid-td">
                    <Flexbox justifyContent="center">
                      <Checkbox disabled={this.props.disabled} />
                    </Flexbox>
                  </td>
                )
              )}
            </tr>
          )
        )}
      </tbody>
    );
  }

  renderDisabledText() {
    if (this.props.disabled) {
      return <ElementReadOnly element={this.props.element} />;
    }
    return undefined;
  }

  render() {
    let tableClass = classNames(
      'bp3-html-table bp3-html-table-striped bp3-small full-table'
    );
    if (this.props.disabled) {
      tableClass = classNames(
        'bp3-html-table bp3-html-table-striped bp3-small full-table'
      );
    }
    return (
      <div className="bp3-form-content">
        <Flexbox flexDirection="column" flexGrow={1}>
          <table className={tableClass}>
            <thead>
              <tr>
                <th className="td-width-30" />
                {_.map(
                  this.props.element.elementGrid.elementGridColumns,
                  column => (
                    <th
                      key={column.id}
                      className="td-width-10 grid-th"
                    >
                      <small>{column.name}</small>
                    </th>
                  )
                )}
              </tr>
            </thead>
            {this.renderDisplay()}
          </table>
          {this.renderDisabledText()}
        </Flexbox>
      </div>
    );
  }
}
