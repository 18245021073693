import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner, Button, Alert, Intent, Classes } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import AccountUsersRow from './AccountUsersRow';
import AccountUsersFilter from './AccountUsersFilter';
import Paginator from '../shared/Paginator';
import _ from 'lodash';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class AccountUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isInvitingAll: false,
      showInviteAllAlert: false,
    };
  }

  componentDidMount() {
    if (this.props.loadUsers) {
      this.toggleLoading();
      const pageNumber = 1;
      this.props.AccountUsersStore.fetchUsers(
        pageNumber,
        this.props.AccountUsersStore.pagination.xPerPage,
        this.props.AccountUsersStore.pagination.xOffset,
        '',
        ''
      )
        .then(() => {
          this.toggleLoading();
        })
        .catch(() => {
          this.toggleLoading();
        });
    }
  }

  toggleLoading() {
    if (this.props.importId) {
      this.props.toggleLoading();
    } else {
      this.setState({
        isLoading: !this.state.isLoading,
      });
    }
  }

  toggleInvitingAll() {
    this.setState({ isInvitingAll: !this.state.isInvitingAll });
  }

  toggleInviteAllAlert() {
    this.setState({
      showInviteAllAlert: !this.state.showInviteAllAlert,
    });
  }

  handleColumnSort(column) {
    this.props.AccountUsersStore.setUserSortParams(column.id, column.sortDir);
  }

  handlePageChange(pageNumber) {
    this.toggleLoading();
    this.props.AccountUsersStore.fetchUsers(
      pageNumber,
      this.props.AccountUsersStore.pagination.xPerPage,
      this.props.AccountUsersStore.pagination.xOffset,
      '',
      this.props.importId
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handlePerPageChange(e) {
    this.toggleLoading();
    const pageNumber = 1;
    this.props.AccountUsersStore.fetchUsers(
      pageNumber,
      e.target.value,
      this.props.AccountUsersStore.pagination.xOffset,
      '',
      this.props.importId
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInviteAll() {
    this.toggleInviteAllAlert();
    this.setState({ isInvitingAll: true });
    let totalLength = this.props.AccountUsersStore.usersAbleToBeInvited.length;
    _.map(this.props.AccountUsersStore.usersAbleToBeInvited, user => {
      this.props.AccountUsersStore.updateNewInvitation(user.email, 'email');
      this.props.AccountUsersStore.createInvitation();
      totalLength = totalLength - 1;
    });
    if (totalLength === 0) {
      this.props.ToastStore.showToast(I18n.t('js.invitations_have_been_sent'), 'success');
      this.setState({ isInvitingAll: false });
    }
  }

  renderPagination() {
    if (!this.state.isLoading && this.props.AccountUsersStore.pagination.xTotal > this.props.AccountUsersStore.pagination.xPerPage) {
      return (
        <Paginator
          paginationObject={this.props.AccountUsersStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderUsers() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.AccountUsersStore.users.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" className="bp3-card" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_users_to_show')}
            icon="people"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader columns={this.props.AccountUsersStore.userColumns} handleColumnSort={this.handleColumnSort.bind(this)} />
          </thead>
          <tbody>
            {_.map(this.props.AccountUsersStore.users, user => (
              <AccountUsersRow user={user} key={user.id} />
            ))}
          </tbody>
        </table>
      </Flexbox>
    );
  }

  renderFilter() {
    if (this.props.showFilter) {
      return (
        <Flexbox flexGrow={1} justifyContent="flex-start">
          <AccountUsersFilter toggleUserLoading={() => this.toggleLoading()} />
        </Flexbox>
      );
    }
  }

  renderInviteAll() {
    if (this.props.AccountUsersStore.usersAbleToBeInvited.length > 0) {
      return (
        <Flexbox flexGrow={1} marginTop="10px" justifyContent="flex-start">
          <Button
            text={I18n.t('js.invite_users', {
              userCount: this.props.AccountUsersStore.usersAbleToBeInvited.length,
            })}
            intent={Intent.PRIMARY}
            icon="envelope"
            loading={this.state.isInvitingAll || this.state.isLoading}
            disabled={this.props.AccountUsersStore.usersAbleToBeInvited.length === 0}
            onClick={this.toggleInviteAllAlert.bind(this)}
          />
          <Alert
            portalContainer={document.body}
            isOpen={this.state.showInviteAllAlert}
            cancelButtonText={I18n.t('js.cancel')}
            onCancel={() => this.toggleInviteAllAlert()}
            confirmButtonText={I18n.t('js.invite_users', {
              userCount: this.props.AccountUsersStore.usersAbleToBeInvited.length,
            })}
            onConfirm={() => this.handleInviteAll()}
            intent={Intent.SUCCESS}
          >
            <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
            {I18n.t('js.are_you_sure_you_wish_to_invite_all_users', {
              userCount: this.props.AccountUsersStore.usersAbleToBeInvited.length,
            })}
          </Alert>
        </Flexbox>
      );
    }
  }

  renderUserCount() {
    if (this.props.AccountUsersStore.pagination.xTotal > 0) {
      return <span>({this.props.AccountUsersStore.pagination.xTotal})</span>;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        {this.props.showHeader ? <p>{I18n.t('js.account_users_text')}:</p> : null}
        <Flexbox flexDirection="column" alignItems="stretch">
          {this.renderFilter()}
          {this.renderInviteAll()}
          {this.renderUsers()}
          <Flexbox flexGrow={1} justifyContent="center">
            {this.renderPagination()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
