import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, HTMLSelect, Intent, NonIdealState } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ElementIdSelector from '../../shared/ElementIdSelector';
import Dropzone from 'react-dropzone';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementImage extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleDrop(images) {
    this.store.updateActiveElement(images[0], 'imageFileName');
  }

  handleAlignmentChange(e) {
    this.store.updateActiveElement(e.target.value, 'alignment');
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.props.element.id,
    };
    const finalImage = this.store.activeElement.elementImage.imageFileName;
    const formData = new FormData();
    formData.append('alignment', this.store.activeElement.elementImage.alignment);
    if (typeof this.store.activeElement.elementImage.imageFileName === 'object') {
      formData.append('image', finalImage, finalImage.name);
    }
    this.props.TemplateActions.changeElementImage(this.store.activeElement.elementImage.id, formData)
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderSelectImageText() {
    if (typeof this.store.activeElement.elementImage.imageFileName !== 'object') {
      return <span>{I18n.t('js.select_new_image')}</span>;
    }
    return <span>{this.store.activeElement.elementImage.imageFileName.name}</span>;
  }

  renderPreview() {
    let alignment = 'center';
    if (this.store.activeElement.elementImage.alignment === 'left') {
      alignment = 'flex-start';
    }
    if (this.store.activeElement.elementImage.alignment === 'right') {
      alignment = 'flex-end';
    }
    if (typeof this.store.activeElement.elementImage.imageFileName === 'object') {
      return (
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="10px" justifyContent={alignment}>
          <div>
            <img className="element-image" alt="form element display" src={this.store.activeElement.elementImage.imageFileName.preview} />
          </div>
        </Flexbox>
      );
    }
    if (this.store.activeElement.elementImage.imageFileName.length > 0) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="10px" justifyContent={alignment}>
          <img alt="form element display" className="element-image" src={this.store.activeElement.elementImage.imageFileName} />
        </Flexbox>
      );
    }
    return (
      <NonIdealState
        title={I18n.t('js.image')}
        description={I18n.t('js.configure_element_to_upload_an_image')}
        icon="media"
        className="bp3-text-muted"
      />
    );
  }

  renderStaticPreview() {
    if (this.props.element.elementImage.imageFileName.length > 0) {
      return <img alt="form element display" className="element-image" src={this.props.element.elementImage.imageFileName} />;
    }
    return (
      <NonIdealState
        title={I18n.t('js.image')}
        description={I18n.t('js.configure_element_to_upload_an_image')}
        icon="media"
        className="bp3-text-muted"
      />
    );
  }

  render() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexGrow={1} flexDirection="column">
            {this.renderPreview()}
            <hr />
            <form onSubmit={this.handleSubmit.bind(this)}>
              <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
                <Flexbox>
                  <span className="bp3-icon bp3-icon-media push-10-r" />
                  <h5 className={Classes.HEADING}>{I18n.t('js.image_options')}</h5>
                </Flexbox>
                <Flexbox flexDirection="column" flexGrow={1}>
                  <div className="bp3-form-group">
                    <label className="bp3-label">
                      {I18n.t('js.select_new_image')}
                      <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                    </label>
                    <div className="bp3-form-content">
                      <Dropzone onDrop={this.handleDrop.bind(this)} className="bp3-dropzone bp3-button bp3-icon-media">
                        {this.renderSelectImageText()}
                      </Dropzone>
                    </div>
                  </div>
                </Flexbox>
                <Flexbox flexDirection="column" flexGrow={1}>
                  <div className="bp3-form-group">
                    <label className="bp3-label">
                      {I18n.t('js.select_image_alignment')}
                      <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                    </label>
                    <div className="bp3-form-content">
                      <div className="bp3-select">
                        <HTMLSelect
                          name="alignment"
                          onChange={this.handleAlignmentChange.bind(this)}
                          defaultValue={this.store.activeElement.elementImage.alignment}
                        >
                          <option value="left">{I18n.t('js.left_aligned')}</option>
                          <option value="center">{I18n.t('js.center_aligned')}</option>
                          <option value="right">{I18n.t('js.right_aligned')}</option>
                        </HTMLSelect>
                      </div>
                    </div>
                  </div>
                </Flexbox>
                <Flexbox marginTop="10px">
                  <AnchorButton
                    intent={Intent.PRIMARY}
                    onClick={this.handleSubmit.bind(this)}
                    text={I18n.t('js.save_changes')}
                    icon="floppy-disk"
                    loading={this.state.isLoading}
                  />
                  <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
                </Flexbox>
                <ElementIdSelector id={this.props.element.id} />
              </Flexbox>
            </form>
          </Flexbox>
        </Flexbox>
      );
    }
    let alignment = 'center';
    if (this.props.element.elementImage.alignment === 'left') {
      alignment = 'flex-start';
    }
    if (this.props.element.elementImage.alignment === 'right') {
      alignment = 'flex-end';
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="10px">
        <Flexbox flexGrow={1} flexDirection="row" flexWrap="wrap" paddingTop="10px" justifyContent={alignment}>
          {this.renderStaticPreview()}
        </Flexbox>
      </Flexbox>
    );
  }
}
