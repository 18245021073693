const appUsageTypes = [
  {
    title: I18n.t('js.app_views'),
    description: I18n.t('js.app_views_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'app_view',
    icon: 'application',
    tab: '',
  },
  {
    title: I18n.t('js.items_created'),
    description: I18n.t('js.items_created_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_created',
    icon: 'new-object',
    tab: '',
  },
  {
    title: I18n.t('js.items_updated'),
    description: I18n.t('js.items_updated_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_updated',
    icon: 'automatic-updates',
    tab: '',
  },
  {
    title: I18n.t('js.items_deleted'),
    description: I18n.t('js.items_deleted_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_deleted',
    icon: 'graph-remove',
    tab: '',
  },
];

const workspaceUsageTypes = [
  {
    title: I18n.t('js.workspace_views'),
    description: I18n.t('js.workspace_views_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'workspace_view',
    icon: 'grid-view',
    tab: '',
  },
  {
    title: I18n.t('js.items_created'),
    description: I18n.t('js.items_created_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_created',
    icon: 'new-object',
    tab: '',
  },
  {
    title: I18n.t('js.items_updated'),
    description: I18n.t('js.items_updated_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_updated',
    icon: 'automatic-updates',
    tab: '',
  },
  {
    title: I18n.t('js.items_deleted'),
    description: I18n.t('js.items_deleted_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_deleted',
    icon: 'graph-remove',
    tab: '',
  },
];

const accountUsageTypes = [
  {
    title: I18n.t('js.users'),
    description: I18n.t('js.user_count_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'user_count',
    icon: 'people',
    tab: 'users',
  },
  {
    title: I18n.t('js.dashboard_views'),
    description: I18n.t('js.dashboard_views_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'dashboard_view',
    icon: 'home',
    tab: 'users',
  },
  {
    title: I18n.t('js.items'),
    description: I18n.t('js.item_count_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_count',
    icon: 'new-object',
    tab: 'apps',
  },
  {
    title: I18n.t('js.items_updated'),
    description: I18n.t('js.items_updated_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'item_updated',
    icon: 'automatic-updates',
    tab: 'apps',
  },
  {
    title: I18n.t('js.apps'),
    description: I18n.t('js.app_count_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'app_count',
    icon: 'application',
    tab: 'apps',
  },
  {
    title: I18n.t('js.workspaces'),
    description: I18n.t('js.workspace_count_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'workspace_count',
    icon: 'grid-view',
    tab: 'apps',
  },
  {
    title: I18n.t('js.teams'),
    description: I18n.t('js.team_count_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'team_count',
    icon: 'layout-hierarchy',
    tab: 'teams',
  },
  {
    title: I18n.t('js.users_belonging_to_teams'),
    description: I18n.t('js.user_teams_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'user_team_count',
    icon: 'new-person',
    tab: 'teams',
  },
  {
    title: I18n.t('js.users_joining_account'),
    description: I18n.t('js.users_joining_account_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'user_joined_account',
    icon: 'following',
    tab: 'users',
  },
  {
    title: I18n.t('js.users_leaving_account'),
    description: I18n.t('js.users_leaving_account_description'),
    query_type: 'count',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'user_left_account',
    icon: 'follower',
    tab: 'users',
  },
  {
    title: I18n.t('js.relationships'),
    description: I18n.t('js.relationships_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'relationship_count',
    icon: 'follower',
    tab: 'users',
  },
  {
    title: I18n.t('js.user_relationships'),
    description: I18n.t('js.user_relationships_description'),
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'user_relationship_count',
    icon: 'new-link',
    tab: 'users',
  },
];

const systemUsageTypes = [
  {
    title: 'Accounts',
    description: 'Total number of accounts',
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'system_account_count',
    icon: 'bank-account',
    tab: '',
  },
  {
    title: 'Apps',
    description: 'Total number of apps',
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'system_app_count',
    icon: 'application',
    tab: '',
  },
  {
    title: 'Items',
    description: 'Total number of items',
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'system_item_count',
    icon: 'new-object',
    tab: '',
  },
  {
    title: 'Teams',
    description: 'Total number of teams',
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'system_team_count',
    icon: 'layout-hierarchy',
    tab: '',
  },
  {
    title: 'Users',
    description: 'Total number of users',
    query_type: 'sum',
    timeframe: 'this_90_days',
    interval: 'daily',
    event_collection: 'system_user_count',
    icon: 'people',
    tab: '',
  },
];

class UsageDefinitions {
  static get appUsageTypes() {
    return appUsageTypes;
  }

  static get workspaceUsageTypes() {
    return workspaceUsageTypes;
  }

  static get accountUsageTypes() {
    return accountUsageTypes;
  }

  static get systemUsageTypes() {
    return systemUsageTypes;
  }
}

export default UsageDefinitions;
