import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Classes, Spinner, NonIdealState } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import UserProfileTeamsCard from './UserProfileTeamsCard';
import Utilities from '../../utils/Utilities';

@inject('CurrentUserStore')
@observer
export default class UserProfileTeams extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    this.props.CurrentUserStore.fetchTeams()
      .then(() => {
        this.toggleLoading();
      })
      .catch(error => {
        this.toggleLoading();
      });
  }

  renderTeams() {
    if (this.props.CurrentUserStore.teams.length === 0) {
      return (
        <Flexbox
          flexDirection="row"
          flexWrap="wrap"
          flexGrow={1}
          className="bp3-card"
        >
          <NonIdealState
            title={I18n.t('js.user_does_not_belong_to_any_teams')}
            description={I18n.t('js.view_all_teams_below')}
            icon="layout-hierarchy"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
          {this.props.CurrentUserStore.teams.map(team => (
            <UserProfileTeamsCard key={team.id} team={team} />
          ))}
        </Flexbox>
      );
    }
  }

  render() {
    if (this.isLoading) {
      return (
        <Flexbox
          justifyContent="center"
          alignItems="center"
          paddingTop="150px"
          paddingBottom="150px"
        >
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <React.Fragment>
        {this.renderTeams()}
        <Flexbox flexDirection="row" marginTop="10px">
          <button
            className="bp3-button"
            onClick={() => Utilities.navigate('/teams')}
          >
            {I18n.t('js.view_all_teams')}
            <span className="bp3-icon-standard bp3-icon-arrow-right bp3-alight-right push-5-l" />
          </button>
        </Flexbox>
      </React.Fragment>
    );
  }
}
