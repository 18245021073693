// @flow
import React from 'react';
import Cookies from 'js-cookie';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import type { Notification } from '../../types/Notification';
import { Card, Classes, Icon, Intent } from '@blueprintjs/core';
import styled, { type ReactComponentStyled } from 'styled-components';

// DATE ===================================================

type DateProps = {
  read: boolean,
  date: string,
};

const DateArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  align-items: center;

  .${Classes.ICON} {
    margin-right: 8px;
  }

  p {
    margin: 0;
    font-size: 12px;
  }
`;

const Date = ({ read, date }: DateProps) => (
  <DateArea>
    {!read ? <span className="bp3-icon bp3-icon-full-circle animated pulse notification-pulse" /> : <Icon icon="calendar" iconSize={12} />}
    <p className={read ? Classes.MUTED : ''}>
      <em>{date}</em>
    </p>
  </DateArea>
);

// NOTIFICATION CONTENT ===================================

type NotificationCardProps = {
  read: boolean,
  onClick: (props: any) => any,
};

const NotificationCard: ReactComponentStyled<NotificationCardProps> = styled(Card)`
  && {
    box-shadow: none;
    cursor: pointer;
    padding: '10px 20px';
    border-bottom: 1px solid ${({ theme }) => (Cookies.get('theme') == 'dark' ? theme.darkGray3 : theme.lightGray1)};
    background-color: ${({ read, theme }) =>
      read
        ? Cookies.get('theme') == 'dark'
          ? theme.darkGray4
          : theme.white
        : Cookies.get('theme') == 'dark'
        ? theme.darkGray5
        : theme.lightGray5};
  }
`;

const NotificationContent = styled.div`
  display: flex;
  justifycontent: space-around;
  aligncontent: center;

  p {
    margin-left: 10px;
  }
`;

const AvatarContainer = styled.div`
  min-width: '30px;';
`;

type NotificationHeaderListItemProps = {
  notification: Notification,
};

@inject('NotificationStore')
@observer
class NotificationHeaderListItem extends React.Component<NotificationHeaderListItemProps> {
  handleClick = () => {
    const { id, path, read } = this.props.notification;
    if (!read) {
      // $FlowFixMe
      this.props.NotificationStore.markAsRead(id);
    }
    if (path.length > 0) {
      Utilities.navigate(path, true);
    } else {
      Utilities.navigate('/notifications', true);
    }
  };
  render() {
    const {
      notification: { title, imageFileName, read, createdAtToDate },
    } = this.props;
    return (
      <NotificationCard onClick={this.handleClick} read={read}>
        <NotificationContent>
          <AvatarContainer>
            <Avatar src={imageFileName} size={30} round />
          </AvatarContainer>
          <p>{title}</p>
        </NotificationContent>
        <Date read={read} date={createdAtToDate} />
      </NotificationCard>
    );
  }
}

export default NotificationHeaderListItem;
