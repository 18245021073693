import { action } from 'mobx';
import _ from 'lodash';
import Cookies from 'js-cookie';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from '../stores/ToastStore';

axiosCancel(axios, {
  debug: false,
});

class ImportActions {
  @action
  fetchImports(importTemplateId, type, xPage, xPerPage, xOffset) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/imports?&import_template_id=${importTemplateId}&type=${type}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchImportTemplate(importableType, importableId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/import_templates?&importable_type=${importableType}&importable_id=${importableId}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action createImport(importObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/imports`, importObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action rollBackImport(importId) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get(
            'apiEnv'
          )}/imports/${importId}?rolled_back=true`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action deleteImport(importId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/imports/${importId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchImportedTeams(importId, xPage, xPerPage, xOffset) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/teams?import_id=${importId}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchImportedItems(appId, importId, xPage, xPerPage, xOffset) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/items?&app_id=${appId}&import_id=${importId}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchImportedUsers(importId, xPage, xPerPage, xOffset) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/users?import_id=${importId}&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}

const actions = new ImportActions();
export default actions;
