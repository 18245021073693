/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action } from 'mobx';
import StoreTemplate from '../models/StoreTemplateModel';
import StoreCategory from '../models/StoreCategoryModel';
import StoreCategoryStoreTemplate from '../models/StoreCategoryStoreTemplateModel';
import User from '../models/UserModel';
import Workspace from '../models/WorkspaceModel';
import App from '../models/AppModel';
import ProfileTemplate from '../models/ProfileTemplateModel';
import _ from 'lodash';

class StoreTemplateMappings {
  @action
  mapStoreTemplate(data, type) {
    let newStoreTemplate = new StoreTemplate();
    if (type === 'contentful') {
      newStoreTemplate = new StoreTemplate(
        data.id,
        data.userId,
        data.templatableObjectId,
        data.templatableObjectType,
        data.title,
        data.subtitle,
        data.description,
        data.benefits,
        data.icon,
        data.imageFileName,
        data.status,
        data.rating,
        data.ratingCount,
        data.commentCount,
        data.slug,
        data.createdAt,
        data.createdBy,
        data.deletedAt,
        data.deletedBy,
        data.updatedAt,
        data.updatedBy
      );
    } else {
      newStoreTemplate = new StoreTemplate(
        data.id,
        data.user_id,
        data.templatable_object_id,
        data.templatable_object_type,
        data.title,
        data.subtitle,
        data.description,
        data.benefits,
        data.icon,
        data.image_file_name,
        data.status,
        data.rating,
        data.rating_count,
        data.comment_count,
        data.slug,
        data.created_at,
        data.created_by,
        data.deleted_at,
        data.deleted_by,
        data.updated_at,
        data.updated_by
      );
      newStoreTemplate.user = this.mapUser(data.user);
      newStoreTemplate.storeCategories = [];
      _.map(data.store_categories, storeCategory => {
        newStoreTemplate.storeCategories.push(this.mapStoreCategory(storeCategory));
      });
      newStoreTemplate.storeCategoryStoreTemplates = [];
      _.map(data.store_category_store_templates, storeCategoryStoreTemplate => {
        newStoreTemplate.storeCategoryStoreTemplates.push(this.mapStoreCategoryStoreTemplate(storeCategoryStoreTemplate));
      });
      switch (data.templatable_object_type) {
        case 'Workspace':
          newStoreTemplate.workspace = this.mapWorkspace(data.workspace);
          break;
        case 'App':
          newStoreTemplate.app = this.mapApp(data.app);
          break;
        case 'ProfileTemplate':
          newStoreTemplate.profileTemplate = this.mapProfileTemplate(data.profile_template);
          break;

        default:
          throw new Error(`Object type not accounted for: ${data.templatable_object_type}`);
      }
    }
    return newStoreTemplate;
  }

  @action
  mapUser(data) {
    return new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
  }

  @action
  mapStoreCategory(data) {
    return new StoreCategory(
      data.id,
      data.title,
      data.comment_count,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapStoreCategoryStoreTemplate(data) {
    return new StoreCategoryStoreTemplate(
      data.id,
      data.store_template_id,
      data.store_category_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapWorkspace(data) {
    return new Workspace(
      data.id,
      data.account_id,
      data.name,
      data.description,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.user_access_level,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapApp(data) {
    return new App(
      data.id,
      data.workspace_id,
      data.workspace_folder_id,
      data.name,
      data.description,
      data.item,
      data.item_plural,
      data.item_layout,
      data.icon,
      data.color,
      data.image_file_name,
      data.permission_type,
      data.status,
      data.row_order,
      data.row_order_folder,
      data.user_access_level,
      data.webform_enabled,
      data.webform_email_required,
      data.webform_anonymous,
      data.webform_path,
      data.default_view_folder,
      data.default_process_visible,
      data.include_in_quick_create_menu,
      data.dummy_data,
      data.lock_participants,
      data.comment_count,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }

  @action
  mapProfileTemplate(data) {
    return new ProfileTemplate(
      data.id,
      data.account_id,
      data.name,
      data.description,
      data.default,
      data.icon,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
  }
}

const activityMappings = new StoreTemplateMappings();
export default activityMappings;
