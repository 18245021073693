import { computed, observable } from 'mobx';

export default class ElementCalculation {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;
  @observable
  formula;
  @observable
  human_friendly_formula;
  @observable
  formula_object_string;
  @observable
  decimalPlaces;
  @observable
  prefix;
  @observable
  suffix;

  constructor(id, elementId, name, formula, humanFriendlyFormula, formulaObjectString, decimalPlaces, prefix, suffix) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
    this.formula = formula || '';
    this.human_friendly_formula = humanFriendlyFormula || '';
    this.formula_object_string = formulaObjectString || '';
    this.decimalPlaces = decimalPlaces;
    this.prefix = prefix || '';
    this.suffix = suffix || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get formulaValid() {
    if (this.formula.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get formulaError() {
    if (this.formula.length < 1) {
      return I18n.t('js.placeholder_text_is_required');
    }
    return null;
  }
}
