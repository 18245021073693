const operators = [
  {
    id: 'add',
    name: I18n.t('js.add'),
    icon: 'plus',
    type: 'operator',
    implementation: `+`,
  },
  {
    id: 'subtract',
    name: I18n.t('js.subtract'),
    icon: 'minus',
    type: 'operator',
    implementation: `-`,
  },
  {
    id: 'multiply',
    name: I18n.t('js.multiply'),
    icon: 'asterisk',
    type: 'operator',
    implementation: `*`,
  },
  {
    id: 'divide',
    name: I18n.t('js.divide'),
    icon: 'slash',
    type: 'operator',
    implementation: `/`,
  },
];

const functions = [
  {
    id: 'sum',
    name: I18n.t('js.sum'),
    icon: 'function',
    type: 'function',
    implementation: `SUM()`,
  },
  {
    id: 'average',
    name: I18n.t('js.average'),
    icon: 'function',
    type: 'function',
    implementation: `AVERAGE()`,
  },
  {
    id: 'count',
    name: I18n.t('js.count'),
    icon: 'function',
    type: 'function',
    implementation: `COUNT()`,
  },
];

class FormulaDefinitions {
  static get operators() {
    return operators;
  }

  static get functions() {
    return functions;
  }
}

export default FormulaDefinitions;
