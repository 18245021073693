/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

@inject('CurrentUserStore', 'UserProfileStore')
@observer
export default class UserProfileEditName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    const newObj = {
      first_name: this.props.UserProfileStore.newUser.firstName,
      last_name: this.props.UserProfileStore.newUser.lastName,
    };
    this.props.UserProfileStore.changeUser(newObj)
      .then(response => {
        this.toggleLoading();
        this.toggleOpen();
        this.props.CurrentUserStore.addUser(response.data);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.props.UserProfileStore.updateUser(
      e.target.value,
      e.target.name
    );
  }

  handleCancel() {
    this.props.UserProfileStore.resetUserAttribute('firstName');
    this.props.UserProfileStore.resetUserAttribute('lastName');
    this.toggleOpen();
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.UserProfileStore.newUser[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.UserProfileStore.newUser[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox
        flexDirection="column"
        flexGrow={1}
        marginBottom="20px"
      >
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Flexbox flexDirection="column">
            {/* alternative funcitonality provided for accesibility users so fine to disable */}
            {/* eslint-disable-next-line */}
            <h4
              className={`${Classes.HEADING} bp3-cursor-pointer noselect`}
              onClick={() => this.toggleOpen()}
            >
              {I18n.t('js.your_name')}
            </h4>
            <p className="noselect">{I18n.t('js.your_name_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={
                this.state.isOpen
                  ? I18n.t('js.close')
                  : I18n.t('js.expand')
              }
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="row">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'firstName') &&
                  !this.props.UserProfileStore.newUser.firstNameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="firstName">
                  {I18n.t('js.first_name')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'firstName') &&
                      !this.props.UserProfileStore.newUser
                        .firstNameValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.joe')}
                      name="firstName"
                      value={
                        this.props.UserProfileStore.newUser.firstName
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('firstName')}
                </div>
              </Flexbox>
              <Flexbox
                flexGrow={1}
                marginLeft="10px"
                className={
                  _.includes(this.state.isBlurred, 'lastName') &&
                  !this.props.UserProfileStore.newUser.lastNameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="lastName">
                  {I18n.t('js.last_name')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'lastName') &&
                      !this.props.UserProfileStore.newUser
                        .lastNameValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.bloggs')}
                      name="lastName"
                      value={
                        this.props.UserProfileStore.newUser.lastName
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('lastName')}
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox>
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  type="submit"
                  disabled={
                    !this.props.UserProfileStore.newUser.nameValid
                  }
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button
                  className="push-10-l"
                  icon="cross"
                  text={I18n.t('js.cancel_changes')}
                  onClick={this.handleCancel.bind(this)}
                />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
