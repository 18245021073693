import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import AccountUsersInvitationsRow from './AccountUsersInvitationsRow';
import AccountUsersNewInvitation from './AccountUsersNewInvitation';
import Paginator from '../shared/Paginator';
import _ from 'lodash';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class AccountUsersInvitations extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {
    const pageNo = 1;
    this.toggleLoading();
    this.props.AccountUsersStore.fetchInvitations(
      pageNo,
      this.props.AccountUsersStore.pagination.xPerPage,
      this.props.AccountUsersStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleColumnSort(column) {
    this.props.AccountUsersStore.setInvitationSortParams(column.id, column.sortDir);
  }

  handlePageChange(pageNumber) {
    this.toggleLoading();
    this.props.AccountUsersStore.fetchInvitations(
      pageNumber,
      this.props.AccountUsersStore.pagination.xPerPage,
      this.props.AccountUsersStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handlePerPageChange(e) {
    const pageNumber = 1;
    this.toggleLoading();
    this.props.AccountUsersStore.fetchInvitations(pageNumber, e.target.value, this.props.AccountUsersStore.pagination.xOffset)
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderPagination() {
    if (!this.state.isLoading && this.props.AccountUsersStore.pagination.xTotal > this.props.AccountUsersStore.pagination.xPerPage) {
      return (
        <Paginator
          paginationObject={this.props.AccountUsersStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderInvitations() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.AccountUsersStore.invitations.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_invitations_for_this_account')}
            icon="label"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader
              columns={this.props.AccountUsersStore.invitationColumns}
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          <tbody>
            {_.map(this.props.AccountUsersStore.invitations, invitation => (
              <AccountUsersInvitationsRow invitation={invitation} key={invitation.id} />
            ))}
          </tbody>
        </table>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <p>{I18n.t('js.account_invitations_text')}</p>
        <Flexbox flexDirection="column" alignItems="stretch">
          <AccountUsersNewInvitation />
          <Flexbox flexDirection="column" alignItems="stretch" marginTop="20px">
            {this.renderInvitations()}
            <Flexbox flexGrow={1} justifyContent="center">
              {this.renderPagination()}
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
