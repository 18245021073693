import React from 'react';
import Flexbox from 'flexbox-react';
import UserProfileEditName from './UserProfileEditName';
import UserProfileEditEmail from './UserProfileEditEmail';
import UserProfileEditProfileImage from './UserProfileEditProfileImage';
import UserProfileEditTheme from './UserProfileEditTheme';
import UserProfileEditPersonalAccessToken from './UserProfileEditPersonalAccessToken';
import { observer } from 'mobx-react';

@observer
export default class UserProfileEdit extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <UserProfileEditName />
        <UserProfileEditEmail />
        <UserProfileEditProfileImage />
        <UserProfileEditTheme />
        <UserProfileEditPersonalAccessToken />
      </Flexbox>
    );
  }
}
