import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  EditableText,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';
import ElementGridRow from '../../../models/elements/ElementGridRowModel';
import {
  arrayMove,
  sortableContainer,
  sortableElement,
} from 'react-sortable-hoc';

const SortableItem = sortableElement(
  ({ row, handleConfirmRowName, handleDeleteRow }) => (
    <tr key={row.id}>
      <td className="td-width-70">
        <EditableText
          multiline={true}
          confirmOnEnterKey={true}
          defaultValue={row.name}
          intent={Intent.DEFAULT}
          onConfirm={value => handleConfirmRowName(value, row)}
          placeholder={I18n.t('js.click_to_edit')}
        />
      </td>
      <td className="td-width-20">
        <AnchorButton
          className="bp3-small push-5-l"
          icon="trash"
          text={I18n.t('js.remove_row')}
          onClick={() => handleDeleteRow(row)}
        />
      </td>
    </tr>
  )
);

const SortableArea = sortableContainer(
  ({ rows, handleConfirmRowName, handleDeleteRow }) => (
    <tbody>
      {rows.map(row => (
        <SortableItem
          key={row.id}
          handleConfirmRowName={handleConfirmRowName}
          handleDeleteRow={handleDeleteRow}
          index={rows.indexOf(row)}
          row={row}
        />
      ))}
    </tbody>
  )
);

@inject(
  'AppStore',
  'UserProfileTemplateStore',
  'TemplateStore',
  'TemplateActions',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class ElementGridRowOptions extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { newRowName: '' };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({ newRowName: e.target.value });
  }

  handleConfirmRowName(value, row) {
    const newRow = _.find(
      this.store.activeElement.elementGrid.elementGridRows,
      o => o.id === row.id
    );
    newRow.name = value;
    newRow.changed = true;
  }

  handleAddNewRow() {
    const newRow = new ElementGridRow(
      '',
      this.store.activeElement.elementGrid.id,
      this.state.newRowName
    );
    newRow.added = true;
    const newElement = _.clone(this.store.activeElement);
    newElement.elementGrid.elementGridRows.push(newRow);
    this.store.activeElement = newElement;
    this.setState({ newRowName: '' });
  }

  handleDeleteRow(row) {
    const newActiveElement = _.clone(this.store.activeElement);
    newActiveElement.elementGrid.elementGridRows = _.filter(
      newActiveElement.elementGrid.elementGridRows,
      o => o.id !== row.id
    );
    this.store.activeElement = newActiveElement;
  }

  handleSortEnd(row, newIndex) {
    const newRow = _.find(
      this.store.activeElement.elementGrid.elementGridRows,
      o => o.id === row.id
    );
    newRow.rowOrder = newIndex;
    newRow.changed = true;
    newRow.rowOrderChanged = true;
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the section
    const findRow = this.store.activeElement.elementGrid
      .elementGridRows[oldIndex];

    // Reorder the current UI
    const newRows = arrayMove(
      this.store.activeElement.elementGrid.elementGridRows.slice(),
      oldIndex,
      newIndex
    );
    const newElement = _.clone(this.store.activeElement);
    newElement.elementGrid.elementGridRows = newRows;
    this.store.activeElement = newElement;

    // Process reorder on server
    this.handleSortEnd(findRow, newIndex);
  }

  renderNewRow() {
    return (
      <Flexbox flexDirection="column" marginTop="10px">
        <Flexbox className="bp3-control-group">
          <InputGroup
            autoComplete="off"
            type="text"
            intent={Intent.DEFAULT}
            placeholder={I18n.t('js.add_a_new_row_here')}
            name="name"
            value={this.state.newRowName}
            onChange={this.handleInputChange.bind(this)}
            dir="auto"
            className="bp3-fill"
          />
          <AnchorButton
            onClick={this.handleAddNewRow.bind(this)}
            text={I18n.t('js.add_new_row')}
            className="bp3-intent-primary"
            disabled={this.state.newRowName.length === 0}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox
        className="bp3-form-group"
        flexDirection="column"
        flexGrow={1}
        flexBasis="1"
      >
        <label className="bp3-label" htmlFor="rowType">
          {I18n.t('js.add_remove_or_edit_rows')}
          <span className="bp3-text-muted push-5-l">
            ({I18n.t('js.required')})
          </span>
        </label>
        <div className="bp3-form-content">
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-html-table-striped bp3-interactive bp3-small full-table">
              <thead>
                <tr>
                  <th>{I18n.t('js.row_name')}</th>
                  <th>{I18n.t('js.action')}</th>
                </tr>
              </thead>
              <SortableArea
                rows={
                  this.store.activeElement.elementGrid.elementGridRows
                }
                onSortEnd={this.onSortEnd.bind(this)}
                lockToContainerEdges={true}
                lockAxis="y"
                handleConfirmRowName={(value, row) =>
                  this.handleConfirmRowName(value, row)
                }
                handleDeleteRow={row => this.handleDeleteRow(row)}
                useDragHandle={false}
                distance={5}
              />
            </table>
            {this.renderNewRow()}
          </Flexbox>
        </div>
      </Flexbox>
    );
  }
}
