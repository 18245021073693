import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import Flexbox from 'flexbox-react';
import { Button, Intent, Switch, Alert, Classes, Spinner, Divider, Checkbox, Label, Tag } from '@blueprintjs/core';
import Utilities from '../../../utils/Utilities';
import BambooIntegrationForm from './BambooIntegrationForm';
import BambooIntegrationFormLogic from './BambooIntegrationFormLogic';
import SyncDrawer from '../shared_components/SyncDrawer';
import moment from 'moment';

@inject('BambooIntegrationStore', 'ToastStore', 'AccountSettingsStore', 'IntegrationStore')
@observer
export default class SyncTable extends React.Component {
  @observable
  isLoading = false;
  @observable
  isDrawerOpen = false;

  constructor(props) {
    super(props);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.runSync = this.runSync.bind(this);
  }

  toggleDrawer(syncID) {
    this.props.BambooIntegrationStore.clearSyncDetails();
    if (this.isDrawerOpen == false) {
      this.props.BambooIntegrationStore.fetchSyncDetails(syncID);
    }
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  runSync() {
    this.props.BambooIntegrationStore.toggleBoolean('syncRunning');
    this.props.BambooIntegrationStore.runSynchronization();
  }

  calcDuration(sync) {
    switch (sync.status) {
      case 'completed':
        return Math.round((sync.duration / 60) * 100) / 100;
        break;
      default:
        return (Date.now() - moment.utc(sync.startedAt)) / 1000;
    }
  }

  checkIntent(status) {
    switch (status) {
      case 'completed':
        return 'SUCCESS';
        break;
      case 'in_progress':
        return 'PRIMARY';
        break;
      case 'failed':
        return 'DANGER';
        break;
      default:
    }
  }

  normalizeStatusText(status) {
    switch (status) {
      case 'completed':
        return 'Completed';
        break;
      case 'in_progress':
        return 'In progress';
        break;
      case 'failed':
        return 'Failed';
        break;
      default:
    }
  }

  renderSyncTable() {
    const syncs = this.props.BambooIntegrationStore.syncs;
    const has_syncs = this.props.BambooIntegrationStore.hasSyncs;
    if (this.props.BambooIntegrationStore.syncsLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    } else {
      return has_syncs ? (
        <table className="push-30-b bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-interactive full-table">
          <thead>
            <tr>
              <th>{I18n.t('js.status')}</th>
              <th>{I18n.t('js.started_at')}</th>
            </tr>
          </thead>
          <tbody>
            {syncs.map(sync => (
              <tr key={sync.id}>
                <td onClick={() => this.toggleDrawer(sync.id)}>
                  <Tag className="push-0" intent={this.checkIntent(sync.status)}>
                    {this.normalizeStatusText(sync.status)}
                  </Tag>
                </td>
                <td onClick={() => this.toggleDrawer(sync.id)}>{moment.utc(sync.startedAt).format('MMMM Do YYYY, h:mm a')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          <Button
            className="bp3-icon-play push-20-t push-20-r"
            text={I18n.t('models.shared.run_first_sync')}
            intent="SUCCESS"
            onClick={() => this.runSync()}
          />
        </div>
      );
    }
  }

  render() {
    if (this.props.BambooIntegrationStore.isLoading || this.props.AccountSettingsStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center">
          <Spinner />
        </Flexbox>
      );
    } else {
      return (
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column">
            <Flexbox justifyContent="space-between" alignItems="center">
              <h2>{I18n.t('js.sync_history')}</h2>
              {this.props.BambooIntegrationStore.hasSyncs ? (
                <div>
                  <Button
                    disabled={this.props.BambooIntegrationStore.syncRunning}
                    className="bp3-icon-play"
                    text="Run Now"
                    onClick={() => this.runSync()}
                  />
                </div>
              ) : (
                ''
              )}
            </Flexbox>
            {this.renderSyncTable()}
            <SyncDrawer isDrawerOpen={this.isDrawerOpen} onClose={() => this.toggleDrawer()} store={this.props.BambooIntegrationStore} />
          </Flexbox>
        </Flexbox>
      );
    }
  }
}
