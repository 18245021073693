import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import QuickCreate from '../layout/QuickCreate';
import { Tooltip, Position } from '@blueprintjs/core';
import renderHTML from 'react-render-html';
import moment from 'moment';
import _ from 'lodash';

@inject('AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DashboardHeader extends React.Component {
  render() {
    const { currentUser } = this.props.CurrentUserStore;
    const timeOfDay = Utilities.getGreetingTime(moment());
    let greeting = I18n.t('js.interpolated_greeting', { time_of_day: timeOfDay, name: currentUser.firstName });
    let timeSymbol = '👋';

    return (
      <Flexbox flexDirection="column" className="bp3-profile-header-row" alignItems="flex-start">
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexDirection="column">
            <Flexbox flexDirection="row" alignItems="center">
              <Flexbox marginRight="20px">
                <Tooltip usePortal={false} content={I18n.t('js.your_profile')} position={Position.LEFT}>
                  <a href="/users/me">
                    <Avatar
                      src={this.props.CurrentUserStore.isLoading ? I18n.t('js.pg_avatar_standard') : currentUser.imageFileName}
                      size={100}
                      round={true}
                      className="dashboard-profile-image bp3-cursor-pointer"
                    />
                  </a>
                </Tooltip>
              </Flexbox>
              <Flexbox flexDirection="column" height="130px">
                <h1>{greeting}</h1>
                <Flexbox marginTop="-10px">
                  <p>{renderHTML(I18n.t('js.you_have_x_items_in_your_inbox', { count: this.props.DashboardStore.itemsNotDone.length }))}</p>
                </Flexbox>
                <Flexbox flexDirection="row" justifyContent="center" flexGrow={1}>
                  <QuickCreate />
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
