import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Classes,
  Collapse,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Tooltip,
} from '@blueprintjs/core';
import UnsplashImageSearch from '../shared/UnsplashImageSearch';
import Utilities from '../../utils/Utilities';

@inject('AppStore', 'WorkspaceStore', 'StoreTemplateActions', 'StoreTemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreTemplateEditImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      searchDialogOpen: false,
      image: '',
      imageResult: '',
      imagePreview: this.props.StoreTemplateStore.activeStoreTemplate.imageFileName,
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleSearchDialogOpen() {
    this.setState({
      searchDialogOpen: !this.state.searchDialogOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleRemoveImage() {
    this.setState({ image: '', imageResult: '', imagePreview: '' });
  }

  handleDrop(images) {
    this.setState({ image: images[0], imageResult: '' });
    this.setState({ imagePreview: URL.createObjectURL(images[0]) });
  }

  handleImageSelect(result) {
    this.setState({
      image: '',
      imageResult: result,
      imagePreview: result.urls.regular,
    });
    this.toggleSearchDialogOpen();
  }

  handleSubmit() {
    this.toggleLoading();
    const finalImage = this.state.image;
    const formData = new FormData();
    formData.append('id', this.props.StoreTemplateStore.newStoreTemplate.id);
    if (typeof this.state.image === 'object') {
      formData.append('image', finalImage, Utilities.generateRandomImgName());
    }
    if (typeof this.state.imageResult === 'object') {
      formData.append('image_url', this.state.imageResult.urls.regular);
    }
    if (
      this.state.image === '' &&
      this.state.imageResult === '' &&
      this.state.imagePreview === '' &&
      this.props.StoreTemplateStore.activeStoreTemplate.imageFileName.length > 0
    ) {
      formData.append('image', '');
    }
    this.props.StoreTemplateActions.changeStoreTemplate(formData)
      .then(response => {
        this.props.StoreTemplateStore.addStoreTemplate(response.data);
        this.props.StoreTemplateStore.setActiveStoreTemplate(response.data.id);
        this.setState({
          image: '',
          imageResult: '',
          imagePreview: this.props.StoreTemplateStore.activeStoreTemplate.imageFileName,
        });
        this.toggleLoading();
        this.toggleOpen();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleCancel() {
    this.setState({
      isOpen: false,
      searchDialogOpen: false,
      hasImage: false,
      image: '',
      imageResult: '',
      imagePreview: this.props.StoreTemplateStore.activeStoreTemplate.imageFileName,
    });
  }

  renderMenu() {
    return (
      <Menu>
        <MenuItem
          text={I18n.t('js.upload_an_image')}
          icon="cloud-upload"
          onClick={() => {
            this.fileUploader.click();
          }}
        />
        <MenuItem key={1} text={I18n.t('js.search_for_an_image')} icon="search" onClick={this.toggleSearchDialogOpen.bind(this)} />
        <MenuDivider />
        <MenuItem
          text={I18n.t('js.remove_image')}
          icon="trash"
          className="bp3-intent-danger"
          disabled={this.state.imagePreview === ''}
          onClick={this.handleRemoveImage.bind(this)}
        />
      </Menu>
    );
  }

  renderImageSelect() {
    if (this.state.image === '' && this.state.imageResult === '' && this.state.imagePreview === '') {
      return <Button text={I18n.t('js.select_image')} icon="media" />;
    }
    return (
      <Flexbox className="bp3-cursor-pointer">
        <Tooltip portalContainer={document.body} content={I18n.t('js.click_to_change')} position="top">
          <img src={this.state.imagePreview} alt="preview" className="image-preview" />
        </Tooltip>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* Disable jsx-a11y lints as the close functionality is duplicated by the adjacent button */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.image')}
            </h4>
            <p className="noselect">{I18n.t('js.template_image_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form>
            <Flexbox flexDirection="column">
              <Flexbox flexDirection="column">
                <Flexbox flexDirection="row" flexGrow={0}>
                  <Popover
                    portalContainer={document.body}
                    content={this.renderMenu()}
                    interactionKind={PopoverInteractionKind.CLICK}
                    inline={true}
                  >
                    {this.renderImageSelect()}
                  </Popover>
                </Flexbox>
                <UnsplashImageSearch
                  initialTerm={this.props.StoreTemplateStore.activeStoreTemplate.title}
                  handleImageSelect={image => this.handleImageSelect(image)}
                  dialogOpen={this.state.searchDialogOpen}
                  toggleDialog={this.toggleSearchDialogOpen.bind(this)}
                />
                <input
                  type="file"
                  ref={c => {
                    this.fileUploader = c;
                  }}
                  onChange={e => this.handleDrop(e.target.files)}
                  className="display-none"
                />
                <Flexbox className="push-10-t">
                  <Button
                    intent={Intent.PRIMARY}
                    onClick={this.handleSubmit.bind(this)}
                    text={I18n.t('js.save_changes')}
                    loading={this.state.isSubmitting}
                    icon="tick"
                  />
                  <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
