/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import PermissionDefinitions from '../static/PermissionDefinitions';
import _ from 'lodash';

export default class Workspace {
  @observable
  id;
  @observable
  accountId;
  @observable
  name;
  @observable
  description;
  @observable
  icon;
  @observable
  color;
  @observable
  imageFileName;
  @observable
  permissionType;
  @observable
  status;
  @observable
  rowOrder;
  @observable
  userAccessLevel;
  @observable
  commentCount;
  @observable
  isStoreTemplate;
  @observable
  storeTemplateId;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    accountId,
    name,
    description,
    icon,
    color,
    imageFileName,
    permissionType,
    status,
    rowOrder,
    userAccessLevel,
    commentCount,
    isStoreTemplate,
    storeTemplateId,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.accountId = accountId || '';
    this.name = name || '';
    this.description = description || '';
    this.icon = icon || '';
    this.color = color || '';
    this.imageFileName = imageFileName || '';
    this.permissionType = permissionType;
    this.status = status || 'draft';
    this.rowOrder = rowOrder || '';
    this.userAccessLevel = userAccessLevel || '';
    this.commentCount = commentCount || 0;
    // FIXME this probably wasn't meant to be an expression
    // eslint-disable-next-line no-unused-expressions
    typeof isStoreTemplate === 'undefined' || isStoreTemplate === null
      ? (this.isStoreTemplate = false)
      : (this.isStoreTemplate = isStoreTemplate);
    this.storeTemplateId = storeTemplateId || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get userPermissionLevel() {
    const findPerimssion = _.find(PermissionDefinitions.permissionTypes, o => o.name === this.userAccessLevel);
    if (findPerimssion) {
      return findPerimssion;
    }
    return PermissionDefinitions.noPermissions;
  }

  @computed
  get imageFileNameWidget() {
    let replacement = _.replace(this.imageFileName, 't_profile_image', 't_workspace_widget');
    if (this.userAccessLevel === 'access_denied') {
      replacement = _.replace(this.imageFileName, 't_workspace_widget', 't_workspace_widget/t_locked_overlay');
    }
    return replacement;
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get descriptionValid() {
    if (this.description.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get descriptionError() {
    if (this.description.length < 1) {
      return I18n.t('js.description_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
