const optionTypes = [
  {
    id: 0,
    name: 'single',
    text: I18n.t('js.single_choice_option'),
    icon: 'select',
  },
  {
    id: 1,
    name: 'multiple',
    text: I18n.t('js.multiple_choice_option'),
    icon: 'multi-select',
  },
];

const displayTypes = [
  {
    id: 0,
    name: 'dropdown',
    text: I18n.t('js.dropdown_display_option'),
    icon: 'widget-button',
  },
  {
    id: 1,
    name: 'list',
    text: I18n.t('js.list_display_option'),
    icon: 'properties',
  },
];

class SelectDefinitions {
  static get optionTypes() {
    return optionTypes;
  }

  static get displayTypes() {
    return displayTypes;
  }
}

export default SelectDefinitions;
