/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class ProfileTemplate {
  @observable
  id;
  @observable
  accountId;
  @observable
  name;
  @observable
  description;
  @observable
  defaultTemplate;
  @observable
  icon;
  @observable
  isStoreTemplate;
  @observable
  storeTemplateId;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    accountId,
    name,
    description,
    defaultTemplate,
    icon,
    isStoreTemplate,
    storeTemplateId,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.accountId = accountId || '';
    this.name = name || '';
    this.description = description || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof defaultTemplate === 'undefined' ? (this.defaultTemplate = false) : (this.defaultTemplate = defaultTemplate);
    this.icon = icon || '';
    // eslint-disable-next-line no-unused-expressions
    typeof isStoreTemplate === 'undefined' || isStoreTemplate === null
      ? (this.isStoreTemplate = false)
      : (this.isStoreTemplate = isStoreTemplate);
    this.storeTemplateId = storeTemplateId || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get descriptionValid() {
    if (this.description.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get descriptionError() {
    if (this.description.length < 1) {
      return I18n.t('js.description_is_required');
    }
    return null;
  }

  @computed
  get iconValid() {
    if (this.icon.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get iconError() {
    if (this.icon.length < 1) {
      return I18n.t('js.icon_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
