import React from 'react';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';
import classNames from 'classnames';

export default class ItemAvatar extends React.Component {
  handleClick() {
    Utilities.navigate(this.props.path);
  }

  render() {
    let paragraphClass = classNames('');
    const iconClass = classNames(
      'push-10-r bp3-text-muted fa',
      this.props.item.itemIcon
    );
    if (this.props.textSize === 'larger') {
      paragraphClass = classNames('bp3-running-text');
    }
    if (this.props.noLink) {
      return (
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexDirection="row" alignItems="center">
            <span className={iconClass} />
          </Flexbox>
          <Flexbox
            flexDirection="row"
            alignItems="center"
            paddingTop="3px"
          >
            <span className={paragraphClass}>
              {this.props.item.name}
            </span>
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={1}
        className="bp3-cursor-pointer"
        onClick={this.handleClick.bind(this)}
      >
        <Flexbox flexDirection="row" alignItems="center">
          <span className={iconClass} />
        </Flexbox>
        <Flexbox
          flexDirection="row"
          alignItems="center"
          paddingTop="3px"
        >
          <span className={paragraphClass}>
            {this.props.item.name}
          </span>
        </Flexbox>
      </Flexbox>
    );
  }
}
