// @flow
import * as React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Alignment, AnchorButton, Button, ButtonGroup, Classes, HTMLSelect, Intent, Popover, Position } from '@blueprintjs/core';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Flexbox from 'flexbox-react';
import ReportTemplateColumn from '../../models/ReportTemplateColumnModel';
import type { SortDirection } from '../../types/DataViewTypes';
import type { DataViewStore } from '../../stores/DataViewStore';

type AddNewSortButtonProps = {
  addNewSort: () => void,
};

const AddNewSortButton = (props: AddNewSortButtonProps) => (
  <Button
    // $FlowFixMe
    text={`${I18n.t('js.add_a_new_sort')}`}
    alignText={Alignment.LEFT}
    minimal
    icon="add"
    onClick={props.addNewSort}
  />
);

type ColumnPickerProps = {
  selectedColumnId: string,
  columns: Array<ReportTemplateColumn>,
  changeSortColumn: (previousColumnId: string, newColumnId: string) => void,
};

type ColumnPickerState = {
  selectedColumnId: string,
};

class ColumnPicker extends React.Component<ColumnPickerProps, ColumnPickerState> {
  constructor(props: ColumnPickerProps) {
    super(props);
    this.state = { selectedColumnId: props.selectedColumnId };
  }

  changeHandler = event => {
    const previousColumnId = this.state.selectedColumnId;
    const newColumnId = event.currentTarget.value;
    const { changeSortColumn } = this.props;
    changeSortColumn(previousColumnId, newColumnId);
  };

  render() {
    const { columns } = this.props;
    return (
      <HTMLSelect onChange={this.changeHandler} value={this.props.selectedColumnId}>
        {columns.map(column => (
          <option value={column.id} key={column.id}>
            {_.truncate(column.columnName, { length: 25 })}
          </option>
        ))}
      </HTMLSelect>
    );
  }
}

type DirectionPickerProps = {
  sortDirection: SortDirection,
  toggleDirection: () => void,
};

const DirectionPicker = (props: DirectionPickerProps) => {
  const { sortDirection, toggleDirection } = props;
  return (
    <ButtonGroup>
      <Button text="ASC" active={sortDirection === 'asc'} icon="sort-asc" onClick={toggleDirection} />
      <Button text="DESC" active={sortDirection === 'desc'} rightIcon="sort-desc" onClick={toggleDirection} />
    </ButtonGroup>
  );
};

type DeleteButtonProps = {
  deleteHandler: () => void,
};

const DeleteButton = (props: DeleteButtonProps) => <Button icon="small-cross" minimal onClick={props.deleteHandler} />;

type SortableSortProps = {
  sortDirection: SortDirection,
  columns: Array<ReportTemplateColumn>,
  selectedColumnId: string,
  changeSortColumn: (previousColumnId: string, newColumnId: string) => void,
  toggleDirection: () => void,
  deleteHandler: () => void,
  first: boolean,
};

const DragHandle = SortableHandle(() => <AnchorButton className="bp3-cursor-move bp3-minimal" icon="drag-handle-vertical" />);

const SortableSort = SortableElement((props: SortableSortProps) => {
  const { sortDirection, columns, selectedColumnId, changeSortColumn, toggleDirection, deleteHandler, first } = props;
  return (
    <Flexbox className="sort-filter-popover-sort" justifyContent="space-between" alignItems="center">
      <DragHandle />
      <p>{first ? 'Sort by' : 'then by'}</p>
      <ColumnPicker selectedColumnId={selectedColumnId} columns={columns} changeSortColumn={changeSortColumn} />
      <DirectionPicker sortDirection={sortDirection} toggleDirection={toggleDirection} />
      <DeleteButton deleteHandler={deleteHandler} />
    </Flexbox>
  );
});

type SortPickerProps = {
  DataViewStore: DataViewStore,
};

@inject('DataViewStore')
@observer
export default class SortPicker extends React.Component<SortPickerProps> {
  // $FlowFixMe - destructuring
  handleSortEnd({ oldIndex, newIndex }) {
    this.props.DataViewStore.changeSortPriority(oldIndex, newIndex);
  }

  render() {
    const {
      sortColumns,
      activeColumns,
      changeSortColumn,
      toggleSortDirection,
      deleteSort,
      addSort,
      highestSortPriority,
    } = this.props.DataViewStore;
    const targetButton = (
      <Button
        text={
          sortColumns.length > 0
            ? // $FlowFixMe
              `${I18n.t('js.sorts')} (${sortColumns.length})`
            : // $FlowFixMe
              I18n.t('js.sort')
        }
        icon="sort"
      />
    );
    const sortComponents = sortColumns.map(sortColumn => (
      <SortableSort
        index={sortColumn.sortPriority}
        className="sort"
        key={sortColumn.id}
        sortDirection={sortColumn.sortDirection}
        columns={activeColumns}
        changeSortColumn={changeSortColumn}
        selectedColumnId={sortColumn.id}
        toggleDirection={() => toggleSortDirection(sortColumn.id)}
        deleteHandler={() => deleteSort(sortColumn.id)}
        first={sortColumn.sortPriority === highestSortPriority}
      />
    ));
    const SortableSorts = SortableContainer(({ sorts }) => <div>{sorts}</div>);
    const popoverContent = (
      <Flexbox minWidth="300px" flexDirection="column" className="sort-filter-popover">
        {sortColumns === [] && (
          <p className={Classes.TEXT_DISABLED}>
            {/* $FlowFixMe */}
            {`${I18n.t('js.no_sorts_active')}`}
          </p>
        )}
        <SortableSorts sorts={sortComponents} onSortEnd={this.handleSortEnd.bind(this)} useDragHandle lockAxis="y" />
        <AddNewSortButton addNewSort={addSort} />
      </Flexbox>
    );
    return <Popover portalContainer={document.body} target={targetButton} content={popoverContent} position={Position.BOTTOM_LEFT} />;
  }
}
