import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Alert, AnchorButton, Classes, Collapse, EditableText, HTMLSelect, Intent, Switch, Tooltip } from '@blueprintjs/core';
import StateConditions from './StateConditions';
import StateActions from './StateActions';
import classNames from 'classnames';
import _ from 'lodash';

@inject('StateRuleStore', 'StateRuleActions', 'ToastStore')
@observer
export default class StateRule extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, isDeleting: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting,
    });
  }

  toggleOpen() {
    this.props.StateRuleStore.setActiveStateRule(this.props.stateRule.id);
    this.handleReset();
  }

  handleCancel() {
    this.props.StateRuleStore.setActiveStateRule(null);
    this.handleReset();
  }

  handleReset() {
    this.props.StateRuleStore.resetStateConditions();
    this.props.StateRuleStore.resetStateActions();
    this.props.StateRuleStore.resetStateActionEmailRecipients();
    this.props.StateRuleStore.resetStateActionEmailRecipientUsers();
    this.props.StateRuleStore.resetStateActionEmailRecipientParticipants();
  }

  handleDelete() {
    this.props.StateRuleStore.removeStateRule(this.props.stateRule.id);
    this.props.StateRuleActions.deleteStateRule(this.props.stateRule.id);
  }

  handleConditionsChange(e) {
    let value = true;
    if (e.target.value === 'any_conditions') {
      value = false;
    }
    this.props.StateRuleStore.updateActiveStateRule(value, 'allConditions');
  }

  handleNameChange(value) {
    this.props.StateRuleStore.updateActiveStateRule(value, 'name');
  }

  handleActiveChange() {
    const ruleObj = {
      id: this.props.stateRule.id,
      is_active: !this.props.stateRule.isActive,
    };
    this.props.stateRule.isActive = !this.props.stateRule.isActive;
    this.props.StateRuleActions.changeStateRule(ruleObj);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const ruleObj = {
      id: this.props.stateRule.id,
      name: this.props.StateRuleStore.activeStateRuleEdit.name,
      all_conditions: this.props.StateRuleStore.activeStateRuleEdit.allConditions,
    };
    this.processConditions()
      .then(() => {
        this.processActions()
          .then(() => {
            this.processActionEmailRecipients()
              .then(() => {
                this.props.StateRuleActions.changeStateRule(ruleObj)
                  .then(() => {
                    this.toggleLoading();
                    this.props.ToastStore.showToast(I18n.t('js.changes_saved'), 'success');
                  })
                  .catch(() => {
                    this.toggleLoading();
                  });
              })
              .catch(() => {
                this.toggleLoading();
              });
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  processConditions() {
    return new Promise((resolve, reject) => {
      let totalLength =
        this.props.StateRuleStore.stateConditionsToAdd.length +
        this.props.StateRuleStore.stateConditionsToChange.length +
        this.props.StateRuleStore.stateConditionValuesToChange.length +
        this.props.StateRuleStore.stateConditionsToDelete.length;
      if (totalLength === 0) {
        resolve();
      }

      _.map(this.props.StateRuleStore.stateConditionsToAdd, condition => {
        const conditionObj = {
          state_rule_id: condition.stateRuleId,
          operand: condition.operand,
        };
        if (condition.elementId !== '') {
          conditionObj.element_id = condition.elementId;
        }
        this.props.StateRuleActions.createStateCondition(conditionObj)
          .then(response => {
            condition.id = response.data.id;
            this.processConditionValuesToCreate(condition)
              .then(() => {
                totalLength--;
                this.props.StateRuleStore.removeStateCondition(condition.id);
                if (totalLength === 0) {
                  resolve();
                }
              })
              .catch(() => {
                // lazy quick fix for legacy code:
                // eslint-disable-next-line
                reject();
              });
          })
          .catch(() => {
            // lazy quick fix for legacy code:
            // eslint-disable-next-line
            reject();
          });
      });

      _.map(this.props.StateRuleStore.stateConditionsToChange, condition => {
        const conditionObj = {
          id: condition.id,
          state_rule_id: condition.stateRuleId,
          element_id: condition.elementId,
          operand: condition.operand,
        };
        if (condition.changeType === 'row_order_changed') {
          conditionObj.row_order_position = condition.rowOrder;
        }
        this.props.StateRuleActions.changeStateCondition(conditionObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(() => {
            // lazy quick fix for legacy code:
            // eslint-disable-next-line
            reject();
          });
      });

      _.map(this.props.StateRuleStore.stateConditionValuesToChange, conditionValue => {
        const conditionValueObj = {
          id: conditionValue.id,
          value_type: conditionValue.valueType,
          string_value: conditionValue.stringValue,
          number_value: conditionValue.numberValue,
          decimal_value: conditionValue.decimalValue,
          boolean_value: conditionValue.booleanValue,
          date_value: conditionValue.dateValue,
          user_id: conditionValue.userId,
          team_id: conditionValue.teamId,
          item_id: conditionValue.itemId,
        };
        this.props.StateRuleActions.changeStateConditionValue(conditionValueObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      });

      _.map(this.props.StateRuleStore.stateConditionsToDelete, condition => {
        this.props.StateRuleActions.deleteStateCondition(condition.id)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(() => {
            // lazy quick fix for legacy code:
            // eslint-disable-next-line
            reject();
          });
      });
    });
  }

  processConditionValuesToCreate(condition) {
    return new Promise((resolve, reject) => {
      if (typeof condition.stateConditionValue === 'object') {
        if (condition.stateConditionValue.changeType === 'added') {
          const conditionValueObj = {
            state_condition_id: condition.id,
            value_type: condition.stateConditionValue.valueType,
            string_value: condition.stateConditionValue.stringValue,
            number_value: condition.stateConditionValue.numberValue,
            decimal_value: condition.stateConditionValue.decimalValue,
            boolean_value: condition.stateConditionValue.booleanValue,
            date_value: condition.stateConditionValue.dateValue,
            user_id: condition.stateConditionValue.userId,
            team_id: condition.stateConditionValue.teamId,
            item_id: condition.stateConditionValue.itemId,
          };
          this.props.StateRuleActions.createStateConditionValue(conditionValueObj)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }
      } else {
        resolve();
      }
    });
  }

  processActions() {
    return new Promise((resolve, reject) => {
      let totalLength =
        this.props.StateRuleStore.stateActionsToAdd.length +
        this.props.StateRuleStore.stateActionsToChange.length +
        this.props.StateRuleStore.stateActionsToDelete.length;
      if (totalLength === 0) {
        resolve();
      }

      _.map(this.props.StateRuleStore.stateActionsToAdd, action => {
        const actionObj = {
          state_rule_id: action.stateRuleId,
          action_type: action.actionType,
        };
        switch (action.actionType) {
          case 'change_state':
            actionObj.target_state_id = action.targetStateId;
            break;
          case 'change_status':
            actionObj.target_status_flag_id = action.targetStatusFlagId;
            break;
          case 'send_email':
            actionObj.target_email_content = action.targetEmailContent;
            actionObj.target_email_subject = action.targetEmailSubject;
            actionObj.include_calendar_invitation = action.includeCalendarInvitation;
            actionObj.calendar_element_id = action.calendarElementId;
            actionObj.include_pdf_attachment = action.includePdfAttachment;
            break;
          case 'create_timeline_event':
            actionObj.timeline_event_participant_id = action.timelineEventParticipantId;
            actionObj.timeline_event_description = action.timelineEventDescription;
            break;
          case 'post_webhook':
            actionObj.webhook_url = action.webhookUrl;
            break;
          case 'invite_to_section':
            actionObj.invite_to_section_user_select_element_id = action.inviteToSectionUserSelectElementId;
            actionObj.invite_to_section_id = action.inviteToSectionId;
            actionObj.invite_to_section_has_due_date = action.inviteToSectionHasDueDate;
            actionObj.invite_to_section_anonymous = action.inviteToSectionAnonymous;
            if (action.inviteToSectionDueDateIncrement > 0) {
              actionObj.invite_to_section_due_date_increment = action.inviteToSectionDueDateIncrement;
              actionObj.invite_to_section_due_date = null;
            } else {
              actionObj.invite_to_section_due_date = action.inviteToSectionDueDate;
              actionObj.invite_to_section_due_date_increment = 0;
            }
            if (action.inviteToSectionMessage.length > 0) {
              actionObj.invite_to_section_message = action.inviteToSectionMessage;
            }
            break;
          case 'override_participant':
            actionObj.override_participant_id = action.overrideParticipantId;
            actionObj.override_participant_user_select_element_id = action.overrideParticipantUserSelectElementId;
            break;
          default:
            throw new Error(`action type not accounted for: ${action.actionType}`);
        }
        this.props.StateRuleActions.createStateAction(actionObj)
          .then(response => {
            this.props.StateRuleStore.cascadeActionIdsToRecipients(action.id, response.data.id);
            totalLength--;
            this.props.StateRuleStore.removeStateAction(action.id);
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(this.props.StateRuleStore.stateActionsToChange, action => {
        const actionObj = {
          id: action.id,
          state_rule_id: action.stateRuleId,
          action_type: action.actionType,
        };
        switch (action.actionType) {
          case 'change_state':
            actionObj.target_state_id = action.targetStateId;
            break;
          case 'change_status':
            actionObj.target_status_flag_id = action.targetStatusFlagId;
            break;
          case 'send_email':
            actionObj.target_email_content = action.targetEmailContent;
            actionObj.target_email_subject = action.targetEmailSubject;
            actionObj.include_calendar_invitation = action.includeCalendarInvitation;
            actionObj.calendar_element_id = action.calendarElementId;
            actionObj.include_pdf_attachment = action.includePdfAttachment;
            break;
          case 'create_timeline_event':
            actionObj.timeline_event_participant_id = action.timelineEventParticipantId;
            actionObj.timeline_event_description = action.timelineEventDescription;
            break;
          case 'post_webhook':
            actionObj.webhook_url = action.webhookUrl;
            break;
          case 'invite_to_section':
            actionObj.invite_to_section_user_select_element_id = action.inviteToSectionUserSelectElementId;
            actionObj.invite_to_section_id = action.inviteToSectionId;
            actionObj.invite_to_section_has_due_date = action.inviteToSectionHasDueDate;
            actionObj.invite_to_section_anonymous = action.inviteToSectionAnonymous;
            if (action.inviteToSectionDueDateIncrement > 0) {
              actionObj.invite_to_section_due_date_increment = action.inviteToSectionDueDateIncrement;
              actionObj.invite_to_section_due_date = null;
            } else {
              actionObj.invite_to_section_due_date = action.inviteToSectionDueDate;
              actionObj.invite_to_section_due_date_increment = 0;
            }
            if (action.inviteToSectionMessage.length > 0) {
              actionObj.invite_to_section_message = action.inviteToSectionMessage;
            }
            break;
          case 'override_participant':
            actionObj.override_participant_id = action.overrideParticipantId;
            actionObj.override_participant_user_select_element_id = action.overrideParticipantUserSelectElementId;
            break;
          default:
            throw new Error(`${action.actionType} not accounted for`);
        }
        if (action.changeType === 'row_order_changed') {
          actionObj.row_order_position = action.rowOrder;
        }
        this.props.StateRuleActions.changeStateAction(actionObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(this.props.StateRuleStore.stateActionsToDelete, action => {
        this.props.StateRuleActions.deleteStateAction(action.id)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });
    });
  }

  processActionEmailRecipients() {
    return new Promise((resolve, reject) => {
      let totalLength =
        this.props.StateRuleStore.stateActionEmailRecipientsToAdd.length +
        this.props.StateRuleStore.stateActionEmailRecipientsToChange.length +
        this.props.StateRuleStore.stateActionEmailRecipientsToDelete.length;
      if (totalLength === 0) {
        resolve();
      }

      _.map(this.props.StateRuleStore.stateActionEmailRecipientsToAdd, recipient => {
        const recipientObj = {
          state_action_id: recipient.stateActionId,
        };
        if (recipient.userId.length > 0) {
          recipientObj.user_id = recipient.userId;
        }
        if (recipient.participantId.length > 0) {
          recipientObj.participant_id = recipient.participantId;
        }
        if (recipient.email.length > 0) {
          recipientObj.email = recipient.email;
        }
        this.props.StateRuleActions.createStateActionEmailRecipient(recipientObj)
          .then(() => {
            totalLength--;
            this.props.StateRuleStore.removeStateActionEmailRecipient(recipient.id);
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(this.props.StateRuleStore.stateActionEmailRecipientsToChange, recipient => {
        const recipientObj = {
          id: recipient.id,
        };
        if (recipient.userId.length > 0) {
          recipientObj.user_id = recipient.userId;
        }
        if (recipient.participantId.length > 0) {
          recipientObj.participant_id = recipient.participantId;
        }
        if (recipient.email.length > 0) {
          recipientObj.email = recipient.email;
        }
        this.props.StateRuleActions.changeStateActionEmailRecipient(recipientObj)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });

      _.map(this.props.StateRuleStore.stateActionEmailRecipientsToDelete, recipient => {
        this.props.StateRuleActions.deleteStateActionEmailRecipient(recipient.id)
          .then(() => {
            totalLength--;
            if (totalLength === 0) {
              resolve();
            }
          })
          .catch(reject);
      });
    });
  }

  renderDeleteRule() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={this.toggleDeleting.bind(this)}
        confirmButtonText={I18n.t('js.delete_rule')}
        onConfirm={this.handleDelete.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_rule')}
      </Alert>
    );
  }

  renderConfiguration() {
    if (this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id) {
      return (
        <div>
          <Flexbox flexGrow={1} flexDirection="column" paddingLeft="40px" paddingRight="40px">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <Flexbox marginTop="10px" marginBottom="10px" flexGrow={1}>
                <div className="bp3-running-text">
                  {I18n.t('js.if')}
                  <span className="bp3-select push-10-l push-10-r">
                    <HTMLSelect
                      name="all-conditions"
                      onChange={this.handleConditionsChange.bind(this)}
                      defaultValue={
                        this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id
                          ? this.props.StateRuleStore.activeStateRuleEdit.allConditionsText
                          : this.props.stateRule.allConditionsText
                      }
                    >
                      <option value="all_conditions">{I18n.t('js.all')}</option>
                      <option value="any_conditions">{I18n.t('js.any')}</option>
                    </HTMLSelect>
                  </span>
                  {I18n.t('js.of_the_following_conditions')}
                </div>
              </Flexbox>
              <Flexbox flexGrow={1}>
                <StateConditions />
              </Flexbox>
              <Flexbox marginTop="20px">
                <p>{I18n.t('js.then_run_the_following_actions')}</p>
              </Flexbox>
              <Flexbox flexGrow={1}>
                <StateActions />
              </Flexbox>
              <Flexbox marginTop="20px" marginBottom="10px">
                <AnchorButton
                  intent={Intent.PRIMARY}
                  onClick={this.handleSubmit.bind(this)}
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isLoading}
                  icon="floppy-disk"
                />
                <AnchorButton text={I18n.t('js.delete_rule')} onClick={this.toggleDeleting.bind(this)} className="push-10-l" icon="trash" />
                <AnchorButton
                  text={I18n.t('js.cancel_changes')}
                  onClick={this.handleCancel.bind(this)}
                  className="push-10-l"
                  icon="cross"
                />
              </Flexbox>
              {this.renderDeleteRule()}
            </form>
          </Flexbox>
        </div>
      );
    }
  }

  render() {
    let cardClass = classNames('bp3-card');
    let textClass = classNames('bp3-cursor-pointer');
    if (!this.props.stateRule.isActive) {
      cardClass = classNames(cardClass, 'state-rule-inactive');
    }
    if (this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id) {
      cardClass = classNames(cardClass, 'bp3-elevation-3');
      textClass = classNames('');
    }
    return (
      <Flexbox flexDirection="column" className={cardClass} marginBottom="20px">
        <Flexbox flexGrow={1}>
          <Flexbox flexDirection="column">{this.props.dragHandle}</Flexbox>
          <Flexbox flexGrow={1} paddingTop="5px" paddingLeft="10px" flexDirection="column">
            {/* FIXME make accessible */}
            {/* eslint-disable-next-line */}
            <h4
              className={Classes.HEADING}
              style={{
                margin: '0px',
                marginTop: '0px',
                marginRight: '20px',
              }}
              onClick={this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id ? null : this.toggleOpen.bind(this)}
            >
              <EditableText
                confirmOnEnterKey={true}
                multiline={true}
                disabled={this.props.StateRuleStore.activeStateRule.id !== this.props.stateRule.id}
                defaultValue={this.props.stateRule.name}
                intent={Intent.DEFAULT}
                onChange={value => this.handleNameChange(value)}
                className={textClass}
                value={
                  this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id
                    ? this.props.StateRuleStore.activeStateRuleEdit.name
                    : this.props.stateRule.name
                }
                placeholder={I18n.t('js.click_to_edit')}
              />
            </h4>
          </Flexbox>
          <Flexbox flexDirection="column" marginRight="5px" paddingTop="5px">
            <Tooltip
              portalContainer={document.body}
              content={this.props.stateRule.isActive ? I18n.t('js.turn_rule_off') : I18n.t('js.turn_rule_on')}
              position="top"
            >
              <Switch
                checked={this.props.stateRule.isActive}
                className="bp3-control-no-margin"
                onChange={this.handleActiveChange.bind(this)}
              />
            </Tooltip>
          </Flexbox>
          <Flexbox flexDirection="column">
            <AnchorButton
              text={
                this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id
                  ? I18n.t('js.cancel_changes')
                  : I18n.t('js.edit_rule')
              }
              icon={this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id ? 'cross' : 'edit'}
              active={this.props.StateRuleStore.activeStateRule.id === this.props.stateRule.id}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderConfiguration()}
        </Flexbox>
      </Flexbox>
    );
  }
}
