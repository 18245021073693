import styled from 'styled-components';

export default styled.li`
  display: flex;
  flex-align: baseline;
  list-style-type: none;
  margin-right: ${props => props.theme.gridSize * 2}px;
  border-bottom: 3px solid
    ${({ active, theme }) => (active ? theme.purple3 : theme.white)};
  a,
  h4 {
    color: ${({ active, theme, disabled }) =>
      disabled ? '#5c7080' : active ? theme.black : theme.darkGray1};
    text-decoration: none;
  }
  a:hover h4 {
    color: ${props => props.theme.purple3};
  }
  span {
    margin: auto 0 auto 3px;
  }
`;
