import React from 'react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { IconNames } from '@blueprintjs/icons';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ReportStore from '../stores/ReportStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ReportActions from '../actions/ReportActions';
import ReportTemplateActions from '../actions/ReportTemplateActions';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import Report from '../components/reports/Report';
import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class ReportView extends React.Component {
  @observable
  isLoading = true;

  componentDidMount() {
    ReportActions.fetchReport(this.props.reportId)
      .then(response => {
        ReportStore.addReport(response.data);
        ReportStore.setActiveReport(response.data.id);
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  renderContent() {
    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" marginTop="20px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.loading_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" className="fixed-width-container" flexGrow={1}>
        <h3 className="bp3-heading push-10-b push-20-t">{ReportStore.activeReport.name}</h3>
        <Report />
      </Flexbox>
    );
  }

  render() {
    const stores = {
      ReportStore,
      ReportActions,
      ReportTemplateActions,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
