/* Legacy code - ignore magic numbers */
/* eslint no-magic-numbers: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormGroup, Intent } from '@blueprintjs/core';
import { DateRangeInput, DateRangePicker } from '@blueprintjs/datetime';
import moment from 'moment';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class ProfileElementDateRange extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  inputValue = [null, null];
  @observable
  actionType = 'creating';
  @observable
  newId = Utilities.makeId();

  componentDidMount() {
    this.setStoreAndActions();
    if (this.props.element.formValues.length > 0) {
      this.actionType = 'updating';
      let date0 = new Date(this.props.element.formValues[0].dateValue);
      let date1 = new Date(this.props.element.formValues[1].dateValue);
      if (date0 > date1) {
        date0 = new Date(this.props.element.formValues[1].dateValue);
        date1 = new Date(this.props.element.formValues[0].dateValue);
      }
      this.inputValue = [date0, date1];
    }
    this.props.validateInput(this.inputValue);
    this.mountDisplayValuesInFlight();
  }

  mountDisplayValuesInFlight() {
    let values = [];
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_id === this.props.element.id) {
        values.push(formValue.date_value);
      }
    });
    _.map(this.props.element.formValues, formValue => {
      const findFormValueToChange = _.find(this.store.formValuesToChange, o => o.id === formValue.id);
      if (findFormValueToChange) {
        values.push(findFormValueToChange.date_value);
      }
    });
    if (values.length === 2) {
      values = _.sortBy(values);
      this.inputValue = [values[0], values[1]];
    }
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'datetime';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'datetime';
        return object;
    }
  }

  handleInputChange(dateRange) {
    this.inputValue = dateRange;
    if (this.actionType === 'updating') {
      if (dateRange[0] !== null && dateRange[1] !== null) {
        _.map(dateRange, (date, index) => {
          const changedFormValue = {
            id: this.props.element.formValues[index].id,
            date_value: new Date(date),
          };
          this.store.addFormValueToChange(changedFormValue);
          this.props.validateInput(this.inputValue);
        });
      }
    } else {
      this.store.formValuesToCreate = _.filter(this.store.formValuesToCreate, o => o.element_id !== this.props.element.id);
      if (dateRange[0] !== null && dateRange[1] !== null) {
        _.map(dateRange, date => {
          let newFormValue = {
            id: Utilities.makeId(),
            element_id: this.props.element.id,
            date_value: new Date(date),
          };
          newFormValue = this.appendAttributes(newFormValue);
          this.store.addFormValueToCreate(newFormValue);
          this.props.validateInput(this.inputValue);
        });
      }
    }
    this.props.validateInput(this.inputValue);
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderInput() {
    if (this.props.element.elementDate.inputType === 'input') {
      return (
        <DateRangeInput
          value={this.inputValue.slice()}
          onChange={this.handleInputChange.bind(this)}
          formatDate={date => moment(date).format('DD/MM/YYYY')}
          parseDate={str => new Date(str)}
          minDate={new Date(this.props.element.elementDate.minDate)}
          maxDate={new Date(this.props.element.elementDate.maxDate)}
          intent={this.props.showError ? Intent.DANGER : null}
          disabled={!this.props.element.isEditable}
          popoverProps={{
            usePortal: false,
            portalContainer: document.body,
          }}
        />
      );
    }
    return (
      <DateRangePicker
        className="bp3-elevation-1"
        value={this.inputValue.slice()}
        onChange={this.handleInputChange.bind(this)}
        minDate={new Date(this.props.element.elementDate.minDate)}
        maxDate={new Date(this.props.element.elementDate.maxDate)}
        intent={this.props.showError ? Intent.DANGER : null}
        showActionsBar={false}
        disabled={!this.props.element.isEditable}
      />
    );
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    const { element, sectionProtectedView } = this.props;
    if (element.protectedView && !sectionProtectedView) {
      return (
        <span className="bp3-tag bp3-intent-primary bp3-minimal push-10-r">
          <span className="bp3-icon bp3-icon-lock push-10-r" />
          {I18n.t('js.protected')}
        </span>
      );
    }
    return undefined;
  }

  renderLabel(name) {
    return (
      <label>
        <Flexbox flexDirection="row" alignItems="center" alignContent="center" flexGrow={0} className="bp3-display-label push-10-b">
          <span className="push-10-r">{name}</span>
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
        </Flexbox>
      </label>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
        <FormGroup
          label={this.props.element.elementDate.name}
          labelFor={this.props.element.id}
          labelInfo={this.props.element.isRequired ? `(${I18n.t('js.required')})` : ''}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          <Flexbox>{this.renderInput()}</Flexbox>
        </FormGroup>
      </Flexbox>
    );
  }
}
