/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Tabs } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import AppItemWebformCard from './AppItemWebformCard';
import AppItemsNewItem from '../app_items/AppItemsNewItem';
import _ from 'lodash';

@inject('CurrentUserStore', 'WorkspaceStore', 'DynamicViewStore', 'AppStore', 'AppItemStore', 'ToastStore')
@observer
export default class AppItemTabs extends React.Component {
  componentDidMount() {
    this.props.DynamicViewStore.setActiveApp(this.props.AppStore.activeApp);
    if (this.props.AppStore.activeApp.defaultViewFolder.length > 0) {
      let tab = this.props.AppStore.activeApp.defaultViewFolder;
      this.handleTabChange(tab);
    }
  }

  handleTabChange(tab) {
    this.props.DynamicViewStore.setActiveTab(tab);
    if (tab == 'your-colleagues') {
      this.props.DynamicViewStore.toggleUser('subordinates');
    }
  }

  renderTabs() {
    return (
      <Tabs
        id="app-item-tabs"
        selectedTabId={this.props.DynamicViewStore.activeTab}
        defaultSelectedTabId="home"
        onChange={this.handleTabChange.bind(this)}
        className="noselect"
      >
        <Tab id="home" title={I18n.t('js.home')} />
        <Tab id="your-items" title={I18n.t('js.your_items_name', { items: this.props.AppStore.activeApp.itemPlural })} />
        <Tab id="your-colleagues" title={_.startCase(_.toLower(I18n.t('js.your_colleagues')))} />
        <Tab id="all-items" title={I18n.t('js.all_items_plural', { itemPlural: this.props.AppStore.activeApp.itemPlural })} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            <Flexbox marginRight="20px">
              <AppItemsNewItem />
            </Flexbox>
            {this.renderTabs()}
          </Flexbox>
          <Flexbox flexGrow={0} flexDirection="row" alignItems="center" justifyContent="center">
            <AppItemWebformCard justification="flex-end" />
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
