import React, { Component } from 'react';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import UserRelationshipsNewRelationshipSelectChildren from './UserRelationshipsNewRelationshipSelectChildren';
import UserRelationshipsNewRelationshipSelectParents from './UserRelationshipsNewRelationshipSelectParents';

@inject('UserProfileRelationshipsStore', 'UserProfileStore')
@observer
export default class NewUserRelationshipDialog extends Component {
  constructor(props) {
    super(props);
  }

  handleCancel() {
    this.props.UserProfileRelationshipsStore.toggleDialog();
    this.props.UserProfileRelationshipsStore.resetDialog();
  }

  handleSubmitChildrenSubmit(e) {
    const store = this.props.UserProfileRelationshipsStore;
    e.preventDefault();
    store.toggleIsSubmitting();
    _.map(store.subUserRelationshipsToCreate, user => {
      store.createUserRelationship(user.id, this.props.UserProfileStore.user.id, store.currentRelationship.id);
    });
    store.subUserRelationshipsToCreate = [];
    store.toggleIsSubmitting();
    this.handleCancel();
  }

  handleSubmitParentsSubmit(e) {
    const store = this.props.UserProfileRelationshipsStore;
    e.preventDefault();
    store.toggleIsSubmitting();
    _.map(store.superUserRelationshipsToCreate, user => {
      store.createUserRelationship(this.props.UserProfileStore.user.id, user.id, store.currentRelationship.id);
    });
    store.superUserRelationshipsToCreate = [];
    store.toggleIsSubmitting();
    this.handleCancel();
  }

  render() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="add"
        isOpen={this.props.UserProfileRelationshipsStore.isDialogOpen}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.create_a_new_user_relationship')}
        onClose={() => this.handleCancel()}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column" flexGrow={1}>
              <Flexbox flexDirection="row" justifyContent="center" flexGrow={1}>
                <Avatar src={this.props.UserProfileStore.user.imageFileName} size={100} round={true} />
              </Flexbox>
              <Flexbox flexDirection="row" justifyContent="center" flexGrow={1} marginTop="10px">
                <p className="bp3-text-muted">{this.props.UserProfileStore.user.fullName}</p>
              </Flexbox>

              {this.props.UserProfileRelationshipsStore.isDialogPatents ? (
                <Flexbox marginTop="20px" flexDirection="column" flexGrow={1}>
                  <label className="bp3-label max-width">
                    {I18n.t('js.select_parent_label', {
                      user: this.props.UserProfileStore.user.firstName,
                      name: this.props.UserProfileRelationshipsStore.currentRelationship.name.toLowerCase(),
                    })}
                  </label>
                  <Flexbox flexGrow={1}>
                    <UserRelationshipsNewRelationshipSelectParents />
                  </Flexbox>
                </Flexbox>
              ) : (
                <Flexbox marginTop="20px" flexDirection="column" flexGrow={1}>
                  <label className="bp3-label max-width">
                    {I18n.t('js.select_child_label', {
                      user: this.props.UserProfileStore.user.firstName,
                      name: this.props.UserProfileRelationshipsStore.currentRelationship.name.toLowerCase(),
                    })}
                  </label>
                  <Flexbox flexGrow={1}>
                    <UserRelationshipsNewRelationshipSelectChildren />
                  </Flexbox>
                </Flexbox>
              )}
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={() => this.handleCancel()} />
              <Button
                intent={Intent.PRIMARY}
                onClick={
                  this.props.UserProfileRelationshipsStore.isDialogPatents
                    ? this.handleSubmitParentsSubmit.bind(this)
                    : this.handleSubmitChildrenSubmit.bind(this)
                }
                text={I18n.t('js.set_relationships')}
                loading={this.props.UserProfileRelationshipsStore.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}
