import React from 'react';
import Flexbox from 'flexbox-react';

export default class LengthValidator extends React.Component {
  renderMessage() {
    if (!this.props.element.stateElement.editable) {
      return (
        <span>
          {I18n.t('js.element_is_read_only_in_this_state', {
            element: this.props.element.fullTitle,
          })}
        </span>
      );
    }
    return (
      <span>
        {this.props.element.validateLengthMessage(this.props.input)}
      </span>
    );
  }

  render() {
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={1}
        justifyContent="flex-start"
        className="bp3-form-helper-text bp3-intent-danger"
      >
        {this.renderMessage()}
      </Flexbox>
    );
  }
}
