import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import AccountUsersRequestsRow from './AccountUsersRequestsRow';
import Paginator from '../shared/Paginator';
import _ from 'lodash';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class AccountUsersRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {
    this.toggleLoading();
    const pageNumber = 1;
    this.props.AccountUsersStore.fetchRequests(
      this.props.accountId,
      pageNumber,
      this.props.AccountUsersStore.pagination.xPerPage,
      this.props.AccountUsersStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleColumnSort(column) {
    this.props.AccountUsersStore.setRequestSortParams(column.id, column.sortDir);
  }

  handlePageChange(pageNumber) {
    this.toggleLoading();
    this.props.AccountUsersStore.fetchRequests(
      this.props.accountId,
      pageNumber,
      this.props.AccountUsersStore.pagination.xPerPage,
      this.props.AccountUsersStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handlePerPageChange(e) {
    this.toggleLoading();
    const pageNumber = 1;
    this.props.AccountUsersStore.fetchRequests(
      this.props.accountId,
      pageNumber,
      e.target.value,
      this.props.AccountUsersStore.pagination.xOffset
    )
      .then(() => {
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderPagination() {
    if (!this.state.isLoading && this.props.AccountUsersStore.pagination.xTotal > this.props.AccountUsersStore.pagination.xPerPage) {
      return (
        <Paginator
          paginationObject={this.props.AccountUsersStore.pagination}
          pageRangeDisplayed={10}
          handlePageChange={this.handlePageChange.bind(this)}
          handlePerPageChange={this.handlePerPageChange.bind(this)}
        />
      );
    }
    return undefined;
  }

  renderRequests() {
    if (this.state.isLoading) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    if (this.props.AccountUsersStore.authorizedAccountRequests.length === 0) {
      return (
        <Flexbox flexDirection="row" flexWrap="wrap" paddingTop="50px" marginTop="20px" className="bp3-card">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_requests_to_join_this_account')}
            icon="envelope"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader columns={this.props.AccountUsersStore.requestColumns} handleColumnSort={this.handleColumnSort.bind(this)} />
          </thead>
          <tbody>
            {_.map(this.props.AccountUsersStore.authorizedAccountRequests, request => (
              <AccountUsersRequestsRow request={request} key={request.id} />
            ))}
          </tbody>
        </table>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <p>{I18n.t('js.account_requests_text')}</p>
        <Flexbox flexDirection="column" alignItems="stretch">
          {this.renderRequests()}
          <Flexbox flexGrow={1} justifyContent="center">
            {this.renderPagination()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
