import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, InputGroup, Intent, Switch, Tag } from '@blueprintjs/core';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsDetailsAccountActivate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    this.props.AccountSettingsStore.changeAccount(['active'])
      .then(() => {
        this.toggleLoading();
        this.toggleOpen();
        this.props.ToastStore.showToast(I18n.t('js.account_updated'), 'success');
        location.reload();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleActiveChange() {
    this.props.AccountSettingsStore.updateAccount(!this.props.AccountSettingsStore.newAccount.active, 'active');
  }

  handleCancel() {
    this.props.AccountSettingsStore.resetAccountAttribute('active');
    this.toggleOpen();
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.activate_deactive_account')}
            </h4>
            <p className="noselect">{I18n.t('js.activate_deactive_account_text')}</p>
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column">
              <div className="bp3-form-group">
                <Flexbox flexDirection="column" marginBottom="10px">
                  <Switch
                    className="bp3-control-no-margin"
                    checked={this.props.AccountSettingsStore.newAccount.active}
                    label={I18n.t('js.active')}
                    onChange={this.handleActiveChange.bind(this)}
                  />
                </Flexbox>
                <div className="push-10-t">
                  <Button
                    intent={Intent.PRIMARY}
                    type="submit"
                    disabled={!this.props.AccountSettingsStore.newAccount.subdomainValid}
                    text={I18n.t('js.save_changes')}
                    loading={this.state.isSubmitting}
                    icon="tick"
                  />
                  <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
                </div>
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
