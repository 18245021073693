/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Callout, Classes, Icon, Intent, NonIdealState } from '@blueprintjs/core';
import scrollToElement from 'scroll-to-element';
import UserProfileSections from './UserProfileSections';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateActions', 'ToastStore')
@observer
export default class UserProfileTemplate extends React.Component {
  @observable
  isLoading = false;
  @observable
  showErrors = false;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  toggleShowErrors() {
    this.props.UserProfileTemplateStore.showAllSections();
    this.showErrors = !this.showErrors;
  }

  hideErrors() {
    this.showErrors = false;
  }

  handleSave(notify) {
    if (this.props.UserProfileTemplateStore.profileHasError && !this.props.UserProfileTemplateStore.submissionAttempted) {
      this.props.UserProfileTemplateStore.submissionAttempted = true;
      this.toggleShowErrors();
    }
    return new Promise((resolve, reject) => {
      this.toggleLoading();
      let operationCount =
        this.props.UserProfileTemplateStore.formValuesToChange.length +
        this.props.UserProfileTemplateStore.formValuesToCreate.length +
        this.props.UserProfileTemplateStore.formValuesToDelete.length;
      this.submitSave(operationCount, notify);
      if (operationCount === 0) {
        resolve();
      }
      _.map(this.props.UserProfileTemplateStore.formValuesToChange.slice(), profileValueObj => {
        this.props.TemplateActions.changeProfileValue(profileValueObj)
          .then(response => {
            --operationCount;
            if (operationCount === 0) {
              resolve();
            }
            this.submitSave(operationCount, notify);
            this.props.UserProfileTemplateStore.addProfileValue(response.data);
            this.props.UserProfileTemplateStore.removeFormValueToChange(profileValueObj.id);
          })
          .catch(err => {
            reject(err);
            this.toggleLoading();
          });
      });

      _.map(this.props.UserProfileTemplateStore.formValuesToCreate.slice(), profileValueObj => {
        this.props.TemplateActions.createProfileValue(profileValueObj)
          .then(response => {
            --operationCount;
            if (operationCount === 0) {
              resolve();
            }
            this.submitSave(operationCount, notify);
            this.props.UserProfileTemplateStore.addProfileValue(response.data);
            this.props.UserProfileTemplateStore.removeFormValueToCreate(profileValueObj.id);
          })
          .catch(err => {
            reject(err);
            this.toggleLoading();
          });
      });

      _.map(this.props.UserProfileTemplateStore.formValuesToDelete.slice(), profileValueObj => {
        this.props.TemplateActions.deleteProfileValue(profileValueObj.id)
          .then(() => {
            --operationCount;
            if (operationCount === 0) {
              resolve();
            }
            this.submitSave(operationCount, notify);
            this.props.UserProfileTemplateStore.removeProfileValue(profileValueObj.id);
            this.props.UserProfileTemplateStore.removeFormValueToDelete(profileValueObj.id);
          })
          .catch(err => {
            reject(err);
            this.toggleLoading();
          });
      });
    });
  }

  submitSave(operationCount, notify) {
    if (operationCount === 0) {
      this.props.UserProfileTemplateStore.resetCountDown();
      this.toggleLoading();
      if (!this.props.UserProfileTemplateStore.profileHasError) {
        if (notify) {
          this.props.ToastStore.showToast(I18n.t('js.your_changes_have_been_saved'), 'success');
          setTimeout(() => {
            this.props.UserProfileTemplateStore.toggleEditProfile();
          }, 1);
        }
      }
    }
  }

  handleScrollToElement(elementId) {
    scrollToElement(`.element_${elementId}`, {
      offset: 0,
      ease: 'out-circ',
      duration: 500,
    });
  }

  renderSubmission() {
    if (this.props.UserProfileTemplateStore.isEditingProfile && this.props.UserProfileTemplateStore.activeSections.length > 0) {
      return (
        <Flexbox flexGrow={1} flexDirection="row" marginTop="20px">
          <Button
            text={I18n.t('js.save_changes')}
            intent={Intent.PRIMARY}
            icon="floppy-disk"
            className="bp3-large push-10-r"
            onClick={() => this.handleSave(true)}
            loading={this.isLoading}
            disabled={!this.props.UserProfileTemplateStore.hasChanges}
          />
          <Button
            text={I18n.t('js.cancel_changes')}
            icon="cross"
            className="bp3-large"
            onClick={() => this.props.UserProfileTemplateStore.cancelProfileChanges()}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderErrors() {
    if (this.showErrors && this.props.UserProfileTemplateStore.profileHasError && this.props.UserProfileTemplateStore.isEditingProfile) {
      return (
        <Flexbox flexGrow={1} marginTop="20px">
          <Callout intent={Intent.DANGER} icon="error" className="max-width">
            <h4 className={`${Classes.HEADING} bp3-callout-title`}>
              <Flexbox flexGrow={1}>
                <Flexbox flexGrow={1}>{I18n.t('js.please_correct_the_following_errors_before_saving')}</Flexbox>
                <Flexbox flexGrow={0} justifyContent="flex-end">
                  <Button intent={Intent.DANGER} className="bp3-small bp3-minimal" onClick={this.hideErrors.bind(this)}>
                    <Icon icon="cross" className="error-clear-button" />
                  </Button>
                </Flexbox>
              </Flexbox>
            </h4>
            <ul className={`${Classes.LIST} bp3-list bp3-intent-danger`}>
              {_.map(this.props.UserProfileTemplateStore.profileErrors.reverse(), (error, index) => (
                <li key={index}>
                  {/* FIXME make accessible */}
                  {/* eslint-disable-next-line */}
                  <a onClick={() => this.handleScrollToElement(error.elementId)}>{error.errorMessageFull}</a>
                </li>
              ))}
            </ul>
          </Callout>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderUnsavedChangesBar() {
    if (this.props.UserProfileTemplateStore.hasChanges) {
      return (
        <Flexbox width="100hw" className="sticky-footer sticky-footer-primary bp3-elevation-3 animated zoomInDown">
          <Flexbox className="fixed-width-container" flexGrow={1} justifyContent="space-between">
            <Flexbox flexGrow={1} alignContent="center" alignItems="center">
              <Icon icon="issue-new" className="text-white push-10-r" iconSize={25} />
              <Flexbox flexDirection="column" justifyContent="center" paddingTop="10px">
                <p>{I18n.t('js.you_have_unsaved_changes')}</p>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" justifyContent="center">
              <Button
                text={I18n.t('js.save_changes_now')}
                icon="tick"
                className="bp3-large bp3-intent-primary"
                onClick={() => this.handleSave(true)}
                disabled={!this.props.UserProfileTemplateStore.hasChanges}
                loading={this.isLoading}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    if (this.props.UserProfileTemplateStore.activeProfileTemplate === '') {
      return (
        <Flexbox flexGrow={1} flexDirection="column" className={this.props.isDisplayDrawer ? '' : 'fixed-width-container'}>
          <Flexbox className="bp3-card" flexGrow={1} marginTop="20px">
            <NonIdealState
              title={I18n.t('js.nothing_to_show')}
              description={I18n.t('js.there_is_no_profile_template_to_show')}
              icon="id-number"
              className="bp3-text-muted"
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexGrow={1}
        width="100hw"
        alignContent="stretch"
        flexDirection={this.props.isDisplayDrawer ? 'row' : 'column'}
        paddingBottom="0px"
      >
        <Flexbox flexDirection="column" flexGrow={1} className={this.props.isDisplayDrawer ? '' : 'fixed-width-container'}>
          <UserProfileSections />
          {this.renderErrors()}
          {this.renderSubmission()}
        </Flexbox>
        {this.renderUnsavedChangesBar()}
      </Flexbox>
    );
  }
}
