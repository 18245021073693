/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import moment from 'moment';

export default class ProfileValue {
  @observable
  id;
  @observable
  userId;
  @observable
  profileTemplateId;
  @observable
  elementId;
  @observable
  itemValueType;
  @observable
  stringValue;
  @observable
  numberValue;
  @observable
  decimalValue;
  @observable
  booleanValue;
  @observable
  dateValue;
  @observable
  imageFileName;
  @observable
  attachmentFileName;
  @observable
  elementSelectOptionId;
  @observable
  elementObjectSelectItemId;
  @observable
  elementObjectSelectUserId;
  @observable
  elementObjectSelectTeamId;
  @observable
  elementGridColumnId;
  @observable
  elementGridRowId;
  @observable
  elementMultilineInputId;
  @observable
  rowOrder;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    userId,
    profileTemplateId,
    elementId,
    itemValueType,
    stringValue,
    numberValue,
    decimalValue,
    booleanValue,
    dateValue,
    imageFileName,
    attachmentFileName,
    elementSelectOptionId,
    elementObjectSelectItemId,
    elementObjectSelectUserId,
    elementObjectSelectTeamId,
    elementGridColumnId,
    elementGridRowId,
    elementMultilineInputId,
    rowOrder,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || Utilities.makeId();
    this.userId = userId || '';
    this.profileTemplateId = profileTemplateId || '';
    this.elementId = elementId || '';
    this.itemValueType = itemValueType || '';
    this.stringValue = stringValue || '';
    this.numberValue = numberValue;
    this.decimalValue = decimalValue;
    this.booleanValue = booleanValue || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof booleanValue === 'undefined' ? (this.booleanValue = false) : (this.booleanValue = booleanValue);
    this.dateValue = dateValue || '';
    this.imageFileName = imageFileName || '';
    this.attachmentFileName = attachmentFileName || '';
    this.elementSelectOptionId = elementSelectOptionId || '';
    this.elementObjectSelectItemId = elementObjectSelectItemId || '';
    this.elementObjectSelectUserId = elementObjectSelectUserId || '';
    this.elementObjectSelectTeamId = elementObjectSelectTeamId || '';
    this.elementGridColumnId = elementGridColumnId || '';
    this.elementGridRowId = elementGridRowId || '';
    this.elementMultilineInputId = elementMultilineInputId || '';
    this.rowOrder = rowOrder || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
