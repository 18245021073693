// @flow
import * as React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { HTMLSelect } from '@blueprintjs/core';

type ProfileTemplateSelectorProps = {
  DataViewStore: any,
  AccountUsersStore: any,
};

@inject('DataViewStore', 'AccountUsersStore')
@observer
class ProfileTemplateSelector extends React.Component<ProfileTemplateSelectorProps> {
  // $FlowFixMe
  handleSelectProfileTemplate = ({ target: selectedOption }) => {
    const { value: selectedId } = selectedOption;
    this.props.DataViewStore.toggleIsLoadingReportTemplatesData();
    this.props.DataViewStore.toggleIsLoadingAllTableData();
    this.props.DataViewStore.setActiveProfileTemplate(selectedId);
    const profileTemplate = _.find(
      this.props.AccountUsersStore.profileTemplates,
      o => o.id === selectedId
    );
    this.props.AccountUsersStore.setActiveProfileTemplate(
      profileTemplate
    );
    this.props.DataViewStore.loadTableData();
  };

  render() {
    const {
      profileTemplates,
      activeProfileTemplate,
    } = this.props.AccountUsersStore;
    return (
      <HTMLSelect
        className="profile-template-selector"
        name="profileTemplateSelect"
        onChange={this.handleSelectProfileTemplate}
        value={activeProfileTemplate.id}
      >
        {profileTemplates.map(profileTemplate => (
          <option value={profileTemplate.id} key={profileTemplate.id}>
            {_.truncate(profileTemplate.name, { length: 25 })}
          </option>
        ))}
      </HTMLSelect>
    );
  }
}

export default ProfileTemplateSelector;
