import React from 'react';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';
import Avatar from 'react-avatar';
import classNames from 'classnames';

export default class TeamAvatar extends React.Component {
  handleClick() {
    Utilities.navigate(this.props.path);
  }

  render() {
    let paragraphClass = classNames('');
    if (this.props.textSize === 'larger') {
      paragraphClass = classNames('bp3-running-text');
    }
    if (this.props.noLink) {
      return (
        <Flexbox flexDirection="row" flexGrow={0}>
          <Flexbox flexDirection="row" alignItems="center">
            <Avatar
              src={this.props.team.imageFileName}
              size={this.props.avatarSize}
              round={true}
              className="push-5-r"
            />
          </Flexbox>
          <Flexbox flexDirection="row" alignItems="center">
            <span className={paragraphClass}>
              {this.props.team.name}
            </span>
            {this.props.showUserCount ? (
              <span className="push-5-l bp3-text-muted">
                (
                {I18n.t('js.user_count_teams', {
                  count: this.props.team.userCount,
                })}
                )
              </span>
            ) : null}
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={0}
        className="bp3-cursor-pointer"
        onClick={this.handleClick.bind(this)}
      >
        <Flexbox flexDirection="row" alignItems="center">
          <Avatar
            src={this.props.team.imageFileName}
            size={this.props.avatarSize}
            round={true}
            className="push-5-r"
          />
        </Flexbox>
        <Flexbox flexDirection="row" alignItems="center">
          <span className={paragraphClass}>
            {this.props.team.name}
          </span>
        </Flexbox>
      </Flexbox>
    );
  }
}
