/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class Notification {
  @observable
  id;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  icon;
  @observable
  imageFileName;
  @observable
  message;
  @observable
  read;
  @observable
  title;
  @observable
  updatedAt;
  @observable
  updatedBy;
  @observable
  userId;
  @observable
  path;
  @observable
  trigger;

  constructor(
    id,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    icon,
    imageFileName,
    message,
    read,
    title,
    updatedAt,
    updatedBy,
    userId,
    path,
    trigger
  ) {
    this.id = id || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.icon = icon || '';
    this.imageFileName = imageFileName || '';
    this.message = message || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof read === 'undefined' ? (this.read = false) : (this.read = read);
    this.title = title || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.userId = userId || '';
    this.path = path || '';
    this.trigger = trigger || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
