// @flow
import * as React from 'react';
import Flexbox from 'flexbox-react';
import {
  Column,
  Table,
  RenderMode,
  TableLoadingOption,
} from '@blueprintjs/table';

const skeletonTableStyle = {
  width: '100%',
  height: '500px',
};

const toolbarStyle = {
  width: '100%',
  height: '30px',
  display: 'flex',
  marginBottom: '10px',
  justifyContent: 'space-between',
};

const baseButtonStyle = {
  height: '100%',
  marginRight: '10px',
  border: 'none',
};

const buttonOneStyle = { ...baseButtonStyle, width: '307px' };
const buttonTwoStyle = { ...baseButtonStyle, width: '158px' };
const buttonThreeStyle = { ...baseButtonStyle, width: '85px' };
const buttonFourStyle = { ...baseButtonStyle, width: '125px' };
const buttonFiveStyle = {
  ...baseButtonStyle,
  width: '60px',
  marginRight: '0',
};

const tableBodyStyle = {
  width: '100%',
  height: '400px',
};

const width = window.innerWidth - 200;

const SkeletonTable = ({ tableStyle }) => (
  <div style={skeletonTableStyle} className="skeleton-table">
    <div style={toolbarStyle} className="skeleton-table__toolbar">
      <div
        className="skeleton-table__btn bp3-skeleton"
        style={buttonOneStyle}
      />
      <div
        className="skeleton-table__btn bp3-skeleton"
        style={buttonTwoStyle}
      />
      <div
        className="skeleton-table__btn bp3-skeleton"
        style={buttonThreeStyle}
      />
      <div
        className="skeleton-table__btn bp3-skeleton"
        style={buttonFourStyle}
      />
      <div
        className="skeleton-table__btn bp3-skeleton"
        style={buttonFiveStyle}
      />
    </div>
    <div className="flex flex-col">
      {tableStyle === 'advanced' ? (
        <Flexbox
          flexDirection="column"
          style={{ height: '800px' }}
          width={`${width}px`}
        >
          <Table
            numRows={50}
            renderMode={RenderMode.BATCH}
            loadingOptions={[
              TableLoadingOption.CELLS,
              TableLoadingOption.COLUMN_HEADERS,
              TableLoadingOption.ROW_HEADERS,
            ]}
          >
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
            <Column />
          </Table>
        </Flexbox>
      ) : (
        <div
          style={tableBodyStyle}
          className="skeleton-table__table-body bp3-skeleton"
        />
      )}
    </div>
  </div>
);

export default SkeletonTable;
