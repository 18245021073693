import * as React from 'react';
import { Position, Tooltip } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';

class ImageGalleryImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isHovering: false };
  }

  addHover() {
    this.setState({ isHovering: true });
  }

  removeHover() {
    this.setState({ isHovering: false });
  }

  render() {
    const bgStyle = {
      background: `url('${this.props.result.urls.small}')`,
      backgroundSize: 'cover',
      maxWidth: '360px',
      maxHeight: '270px',
    };
    return (
      <Flexbox
        flexDirection="column"
        marginRight="30px"
        marginBottom="30px"
        onMouseEnter={this.addHover.bind(this)}
        onMouseLeave={this.removeHover.bind(this)}
        className="animated fadeIn"
        flexGrow={1}
      >
        <Tooltip portalContainer={document.body} content={I18n.t('js.select_image')} position={Position.TOP}>
          <Flexbox
            className="position-relative bp3-cursor-pointer"
            flexGrow={1}
            flexDirection="column"
            style={bgStyle}
            width="360px"
            height="270px"
            onClick={() => this.props.handleImageClick(this.props.result)}
          />
        </Tooltip>
        <Flexbox className="image-gallery-caption" flexGrow={1} paddingTop="5px" marginTop="-5px" paddingLeft="10px" width="360px">
          <p className="text-white">
            <strong>
              <span className="push-5-r">{I18n.t('js.photo_by')}</span>
              <span className="push-5-r">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                  href={`${this.props.result.user.links.html}?utm_source=peoplegoal&utm_medium=referral`}
                >
                  {this.props.result.user.name}
                </a>
              </span>
              <span className="push-5-r">on</span>
              <span className="push-5-r">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-white"
                  href={`https://unsplash.com?utm_source=peoplegoal&utm_medium=referral`}
                >
                  Unsplash
                </a>
              </span>
            </strong>
          </p>
        </Flexbox>
      </Flexbox>
    );
  }
}

export default ImageGalleryImage;
