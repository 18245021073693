import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Alert, Button, Classes, Intent } from '@blueprintjs/core';
import _ from 'lodash';

@inject('AdminActions', 'AdminStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AdminStoreCategoryRow extends React.Component {
  @observable
  isDeleting = false;

  toggleDeleting() {
    this.isDeleting = !this.isDeleting;
  }

  removeStoreCategory() {
    this.props.AdminActions.deleteStoreCategory(this.props.storeCategory.id)
      .then(response => {
        this.props.AdminStore.removeStoreCategory(response.data.id);
        this.toggleDeleting();
        this.props.ToastStore.showToast('Category deleted', 'success');
      })
      .catch(() => {
        this.toggleDeleting();
      });
  }

  renderAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText="Delete category"
        onConfirm={this.removeStoreCategory.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        Are you sure you wish to delete this category?
      </Alert>
    );
  }

  render() {
    return (
      <tr>
        <td>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://app.contentful.com/spaces/xd145f6kosfj/entries/${this.props.storeCategory.id}`}
          >
            {this.props.storeCategory.id}
          </a>
        </td>
        <td>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://app.contentful.com/spaces/xd145f6kosfj/entries/${this.props.storeCategory.id}`}
          >
            {_.truncate(this.props.storeCategory.title, { length: 30 })}
          </a>
        </td>
        <td>
          <Button
            className="bp3-small"
            text="Edit"
            onClick={() => this.props.AdminStore.setActiveItem(this.props.storeCategory, 'storeCategory')}
          />
          <Button className="bp3-small push-5-l" text="Delete" onClick={this.toggleDeleting.bind(this)} />
          {this.renderAlert()}
        </td>
      </tr>
    );
  }
}
