import React from 'react';
import { Drawer, Position } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import Flexbox from 'flexbox-react';
import UserProfileSections from '../user_profile_template/UserProfileSections';
import UserProfileTemplate from '../user_profile_template/UserProfileTemplate';
import SkeletonRows from '../skeletons/SkeletonRows';
import Utilities from '../../../react/utils/Utilities';

// takes  drawerData props which is a user object
const UserInfoDrawer = props => {
  const navigateToUrl = () => {
    props.toggleBool('isUserDrawerOpen');
    Utilities.navigate(`/users/${props.drawerData.id}`);
  };

  return (
    <Drawer
      portalContainer={document.body}
      position={Position.RIGHT}
      size={Drawer.SIZE_STANDARD}
      onClose={() => props.toggleBool('isUserDrawerOpen')}
      isOpen={props.isOpen}
    >
      <Flexbox className="pad-20 scroll" flexDirection="column">
        <Flexbox flexDirection="row" alignItems="center" className="push-40-b pointer" onClick={() => navigateToUrl()}>
          <Avatar src={props.drawerData.imageFileName} size={100} round={true} className="push-5-r" />
          <Flexbox flexDirection="column" className="push-10-l">
            <span className="font-s-28 font-w-600">{props.drawerData.fullName}</span>
            <span>{props.drawerData.email}</span>
          </Flexbox>
        </Flexbox>
        {props.isLoading ? (
          <Flexbox justifyContent="center" alignItems="center" paddingTop="50px" paddingBottom="50px" flexGrow={1}>
            <SkeletonRows numRows={4} />
          </Flexbox>
        ) : (
          <UserProfileTemplate isDisplayDrawer={true} />
        )}
      </Flexbox>
    </Drawer>
  );
};

export default UserInfoDrawer;
