import React from 'react';
import UserAvatar from '../shared/UserAvatar';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Switch } from '@blueprintjs/core';

@inject('TeamUsersStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class TeamUsersNewUserRow extends React.Component {
  handlePrimaryChange() {
    this.props.userTeam.isPrimary = !this.props.userTeam.isPrimary;
  }

  handleOwnerChange() {
    this.props.userTeam.isOwner = !this.props.userTeam.isOwner;
  }

  removeUser() {
    this.props.TeamUsersStore.removeNewUserTeam(this.props.userTeam);
  }

  render() {
    return (
      <tr>
        <td>
          <UserAvatar user={this.props.userTeam.user} avatarSize={20} path={`/users/${this.props.userTeam.user.humanFriendlyId}`} />
        </td>
        {/* <td>
          <Flexbox flexGrow={1} flexDirection="row" justifyContent="flex-start">
            <Switch
              checked={this.props.userTeam.isPrimary}
              className="bp3-control-no-margin"
              onChange={this.handlePrimaryChange.bind(this)}
            />
          </Flexbox>
        </td> */}
        <td>
          <Flexbox flexGrow={1} flexDirection="row" justifyContent="flex-start">
            <Switch checked={this.props.userTeam.isOwner} className="bp3-control-no-margin" onChange={this.handleOwnerChange.bind(this)} />
          </Flexbox>
        </td>
        <td>
          <Button className="bp3-small bp3-minimal" icon="trash" text={I18n.t('js.remove_user')} onClick={this.removeUser.bind(this)} />
        </td>
      </tr>
    );
  }
}
