import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Intent, Dialog, FormGroup, Callout, Switch, Tooltip, Tabs, Tab } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import UserSuggest from '../suggests/UserSuggest';
import moment from 'moment';
import _ from 'lodash';

@inject('ItemStore', 'ItemActions')
@observer
export default class ItemSectionInvite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: null,
      selectedEmail: '',
      selectedDate: null,
      selectedTab: 'user',
      message: '',
      anonymous: false,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.setState({ selectedEmail: '', message: '', isSubmitting: false });
  }

  handleSubmit() {
    this.setState({ isSubmitting: true });
    const itemSectionInviteObj = {
      item_id: this.props.ItemStore.item.id,
      section_id: this.props.section.id,
      user_id: this.state.selectedUser ? this.state.selectedUser.id : null,
      external_email: this.state.selectedEmail,
      message: this.state.message,
      due_date: this.state.selectedDate,
      anonymous: this.state.anonymous,
    };
    this.props.ItemActions.createItemSectionInvite(itemSectionInviteObj)
      .then(response => {
        this.setState({ isSubmitting: false });
        this.toggleDialog();
        this.props.ItemStore.addItemSectionInvite(response.data);
      })
      .catch(error => {
        this.setState({ isSubmitting: false });
        this.toggleDialog();
      });
  }

  handleRemoveInvitation() {
    this.setState({ isSubmitting: true });
    const findItemSectionInvite = _.find(this.props.ItemStore.itemSectionInvites, o => o.section_id == this.props.section.id);

    this.props.ItemActions.deleteItemSectionInvite(findItemSectionInvite.id)
      .then(() => {
        this.props.ItemStore.removeItemSectionInvite(findItemSectionInvite.id);
        this.setState({ isSubmitting: false });
        this.toggleDialog();
      })
      .catch(error => {
        this.setState({ isSubmitting: false });
        this.toggleDialog();
      });
  }

  removeItemValues() {
    // Remove existing item values in this section
    const findSection = _.find(this.props.ItemStore.sections, o => o.id == this.props.section.id);
    _.map(findSection.elements, element => {
      _.map(element.formValues, formValue => {
        this.props.ItemActions.deleteItemValue(formValue.id).then(() => {
          this.props.ItemStore.removeItemValue(formValue.id);
        });
      });
    });
  }

  resendEmail() {
    const findItemSectionInvite = _.find(this.props.ItemStore.itemSectionInvites, o => o.section_id == this.props.section.id);
    if (findItemSectionInvite) {
      this.props.ItemActions.resendItemSectionInviteEmail(findItemSectionInvite.id);
      this.toggleDialog();
    }
  }

  handleTabChange(tab) {
    this.setState({ selectedTab: tab, selectedUser: null, selectedEmail: '' });
  }

  handleUserSelect(user) {
    this.setState({ selectedUser: user, selectedEmail: '' });
  }

  handleUserRemove(user) {
    this.setState({ selectedUser: null });
  }

  handleDueDateChange(date) {
    Date.prototype.addHours = function (h) {
      this.setTime(this.getTime() + (h * 60 * 60 * 1000));
      return this;
    }
    
    let newDate = date.addHours(12);
    this.setState({ selectedDate: newDate });
  }

  setEmail(e) {
    this.setState({ selectedEmail: e.target.value });
  }

  toggleDialog() {
    this.setState({ selectedEmail: '', selectedUser: null, message: '', selectedDate: null, isSubmitting: false, anonymous: false });
    this.props.toggleDialog(null);
  }

  toggleAnonymous() {
    this.setState({ anonymous: !this.state.anonymous });
  }

  render() {
    const findItemSectionInvite = _.find(this.props.ItemStore.itemSectionInvites, o => o.section_id == this.props.section.id);

    return (
      <Dialog
        portalContainer={document.body}
        icon="envelope"
        isOpen={this.props.showDialog}
        onClose={this.props.toggleDialog.bind(this)}
        title={`${I18n.t('js.invite_another_user_to_edit')} ${this.props.ItemStore.activeSectionInvitation?.title}`}
      >
        <div className="bp3-dialog-body">
          {findItemSectionInvite ? (
            <Flexbox flexDirection="column">
              {findItemSectionInvite.status != 'closed' && <p>{I18n.t('js.item_section_invite_already_exists')}</p>}
              <p>{I18n.t('js.invitation_for')}</p>
              <p className="bp3-running-text">
                <strong>{findItemSectionInvite.user ? findItemSectionInvite.user.full_name : findItemSectionInvite.external_email}</strong>
              </p>
              {findItemSectionInvite.due_date && (
                <p>
                  {I18n.t('js.due_date')}: {moment(findItemSectionInvite.due_date).format('MMMM Do YYYY')}
                </p>
              )}
              {findItemSectionInvite.message && (
                <p>
                  <i>{findItemSectionInvite.message}</i>
                </p>
              )}
              {findItemSectionInvite.status == 'closed' && (
                <Callout intent={Intent.SUCCESS} title={I18n.t('js.submission_completed')}>
                  {I18n.t('js.submission_completed_by_user_on_date', {
                    user: findItemSectionInvite.user ? findItemSectionInvite.user.full_name : findItemSectionInvite.external_email,
                    date: moment(findItemSectionInvite.upated_at).format('MMMM Do YYYY'),
                  })}
                </Callout>
              )}
              {findItemSectionInvite.status == 'expired' && (
                <Callout intent={Intent.WARNING} title={I18n.t('js.invitation_expired')}>
                  {I18n.t('js.invitation_expired_description')}
                </Callout>
              )}
            </Flexbox>
          ) : (
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.item_section_invite_description')}</p>
              <Tabs
                id="emailSelectTab"
                selectedTabId={this.state.selectedTab}
                vertical={false}
                onChange={this.handleTabChange.bind(this)}
                className="push-10-b"
              >
                <Tab id="user" title={I18n.t('js.select_user')} />
                <Tab id="external_email" title={I18n.t('js.email')} />
                <Tabs.Expander />
              </Tabs>
              {this.state.selectedTab == 'user' && (
                <FormGroup className="max-width">
                  <UserSuggest
                    optionType="single"
                    placeholder={I18n.t('js.start_typing')}
                    handleUserClick={this.handleUserSelect.bind(this)}
                    handleUserRemove={this.handleUserRemove.bind(this)}
                    validateInput={() => {
                      null;
                    }}
                    intent={null}
                    disabled={this.state.selectedEmail.length > 0}
                    expandMenu={false}
                  />
                </FormGroup>
              )}
              {this.state.selectedTab == 'external_email' && (
                <FormGroup className="max-width">
                  <p className="bp3-text-muted">{I18n.t('js.external_email_address')}</p>
                  <input
                    autoComplete="off"
                    className="bp3-input bp3-fill"
                    type="email"
                    placeholder={I18n.t('js.someone_example_com')}
                    name="email"
                    disabled={this.state.selectedUser != null}
                    value={this.state.selectedUser != null ? this.state.selectedUser.email : this.state.selectedEmail}
                    onChange={e => this.setEmail(e)}
                    dir="auto"
                  />
                </FormGroup>
              )}
              <FormGroup className="max-width" marginTop="-10px">
                <Tooltip portalContainer={document.body} content={I18n.t('js.anonymous_invitation_description')} position="top">
                  <Switch
                    checked={this.state.anonymous}
                    label={I18n.t('js.anonymous_invitation')}
                    onChange={() => this.toggleAnonymous()}
                  />
                </Tooltip>
              </FormGroup>
              <FormGroup label={I18n.t('js.due_date')} className="max-width">
                <p className="bp3-text-muted">{I18n.t('js.item_section_invite_due_date')}</p>
                <div className="flex flex-row">
                  <DateInput
                    popoverProps={{
                      usePortal: false,
                      inline: false,
                    }}
                    formatDate={date => moment(date).format('MMMM Do YYYY')}
                    parseDate={str => new Date(str)}
                    value={this.state.selectedDate}
                    onChange={this.handleDueDateChange.bind(this)}
                    placeholder={I18n.t('js.due_date')}
                    minDate={new Date()}
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                  />
                  {this.state.selectedDate != null && (
                    <Button className="bp3-minimal push-5-l" icon="cross" onClick={() => this.setState({ selectedDate: null })} />
                  )}
                </div>
              </FormGroup>
              <FormGroup label={I18n.t('js.message')} className="max-width">
                <textarea
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="text"
                  rows={4}
                  placeholder={I18n.t('js.optional_message_for_your_invitation')}
                  name="email"
                  value={this.state.message}
                  onChange={e => this.setState({ message: e.target.value })}
                  dir="auto"
                />
              </FormGroup>
            </Flexbox>
          )}
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
            {findItemSectionInvite ? (
              <>
                {findItemSectionInvite.status != 'closed' && (
                  <>
                    {findItemSectionInvite.status != 'expired' && (
                      <Button onClick={this.resendEmail.bind(this)} text={I18n.t('js.resend_email')} />
                    )}
                    <Button
                      intent={Intent.DANGER}
                      loading={this.state.isSubmitting}
                      onClick={this.handleRemoveInvitation.bind(this)}
                      text={I18n.t('js.remove_invitation')}
                    />
                  </>
                )}
              </>
            ) : (
              <Button
                intent={Intent.PRIMARY}
                loading={this.state.isSubmitting}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.send_invitation')}
                disabled={this.state.selectedEmail == '' && this.state.selectedUser == null}
              />
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}
