import React from 'react';
import TeamAvatar from '../shared/TeamAvatar';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Switch } from '@blueprintjs/core';

@inject('AppItemStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppItemsTeamsNewTeamRow extends React.Component {
  removeTeam() {
    this.props.AppItemStore.removeNewItemTeam(this.props.team.id);
  }

  render() {
    return (
      <tr>
        <td>
          <TeamAvatar
            team={this.props.team}
            avatarSize={20}
            path={`/teams/${this.props.team.id}`}
            noLink={true}
            showUserCount={true}
          />
        </td>
        <td>
          <Flexbox
            flexGrow={1}
            flexDirection="row"
            justifyContent="flex-start"
          ></Flexbox>
        </td>
        <td>
          <Button
            className="bp3-small bp3-minimal pull-right"
            icon="trash"
            onClick={this.removeTeam.bind(this)}
          />
        </td>
      </tr>
    );
  }
}
