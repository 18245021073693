// @flow
import * as React from 'react';
import { AnchorButton, Classes } from '@blueprintjs/core';
import AccountIcon from './AccountIcon';

type HeaderButtonProps = {
  icon: string | AccountIcon,
  navigateTo: () => void,
  text: string,
};

const HeaderButton = ({
  icon,
  navigateTo,
  text,
}: HeaderButtonProps) => (
  <AnchorButton
    className={`${Classes.MINIMAL} header-button`}
    text={text}
    icon={icon}
    onClick={navigateTo}
  />
);

export default HeaderButton;
