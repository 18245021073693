import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Collapse, Intent } from '@blueprintjs/core';
import PermissionView from '../../views/PermissionView';
import PermissionStore from '../../stores/PermissionStore';
import GuideButton from '../guides/GuideButton';
import AppEditPermissionsSelectPermission from './AppEditPermissionsSelectPermission';
import PermissionExplanations from '../permissions/PermissionExplanations';
import _ from 'lodash';

@inject('CurrentUserStore', 'AppStore', 'ToastStore')
@observer
export default class AppEditPermissions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      showDetailedPermissions: false,
      showExplanations: false,
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDetailedPermissions() {
    this.setState({
      showDetailedPermissions: !this.state.showDetailedPermissions,
    });
  }

  toggleExplanation() {
    this.setState({
      showExplanations: !this.state.showExplanations,
    });
  }

  componentDidMount() {
    this.setState({ isSubmitting: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    const newObj = {
      permission_type: this.props.AppStore.newApp.permissionType,
    };
    this.props.AppStore.changeApp(newObj)
      .then(() => {
        let totalLength = PermissionStore.totalPermissionsToPersist;

        this.determineCompleteness(totalLength);

        _.map(PermissionStore.relationshipPermissionsToDelete, permission => {
          PermissionStore.deletePermission(permission.id, 'relationship')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.teamPermissionsToDelete, permission => {
          PermissionStore.deletePermission(permission.id, 'team')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.userPermissionsToDelete, permission => {
          PermissionStore.deletePermission(permission.id, 'user')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.relationshipPermissionsToAdd, permission => {
          const permissionObj = {
            relationship_id: permission.relationshipId,
            permission_model: permission.permissionModel,
            permission_type: permission.permissionType,
            app_id: permission.appId,
          };
          PermissionStore.createPermission(permissionObj, 'relationship')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.teamPermissionsToAdd, permission => {
          const permissionObj = {
            team_id: permission.teamId,
            permission_model: permission.permissionModel,
            permission_type: permission.permissionType,
            app_id: permission.appId,
          };
          PermissionStore.createPermission(permissionObj, 'team')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.userPermissionsToAdd, permission => {
          const permissionObj = {
            user_id: permission.userId,
            permission_model: permission.permissionModel,
            permission_type: permission.permissionType,
            app_id: permission.appId,
          };
          PermissionStore.createPermission(permissionObj, 'user')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.relationshipPermissionsToChange, permission => {
          const permissionObj = {
            id: permission.id,
            permission_type: permission.permissionType,
            participant_id: permission.participantId,
          };
          PermissionStore.changePermission(permissionObj, 'relationship')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.teamPermissionsToChange, permission => {
          const permissionObj = {
            id: permission.id,
            permission_type: permission.permissionType,
          };
          PermissionStore.changePermission(permissionObj, 'team')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });

        _.map(PermissionStore.userPermissionsToChange, permission => {
          const permissionObj = {
            id: permission.id,
            permission_type: permission.permissionType,
          };
          PermissionStore.changePermission(permissionObj, 'user')
            .then(() => {
              totalLength--;
              this.determineCompleteness(totalLength);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  determineCompleteness(length) {
    if (length === 0) {
      this.toggleLoading();
      this.toggleOpen();
      PermissionStore.resetStartingAttributes();
      this.props.ToastStore.showToast(I18n.t('js.permissions_updated'), 'success');
    }
  }

  handleInputChange(permission) {
    this.props.AppStore.updateNewApp(permission.name, 'permissionType');
  }

  handleCancel() {
    this.props.AppStore.resetAppAttribute('permissionType');
    this.toggleOpen();
  }

  renderPermissions() {
    if (this.state.showDetailedPermissions) {
      return <PermissionView type="app" />;
    }
    return undefined;
  }

  renderAddMoreButton() {
    return (
      <Flexbox>
        <Button
          text={I18n.t('js.add_more_specific_permissions')}
          icon="edit"
          className="push-10-l"
          active={this.state.showDetailedPermissions}
          onClick={this.toggleDetailedPermissions.bind(this)}
        />
      </Flexbox>
    );
  }

  renderExplanationButton() {
    return (
      <Flexbox>
        <Button
          onClick={this.toggleExplanation.bind(this)}
          icon="help"
          className="push-10-l"
          active={this.state.showExplanations}
          text={I18n.t('js.show_permission_type_explanations')}
        />
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginBottom="20px">
        <Flexbox flexDirection="row" justifyContent="space-between" flexGrow={1}>
          <Flexbox flexDirection="column">
            {/* ignore jsx-a11y as toggle functionality included eslewhere for */}
            {/* eslint-disable-next-line */}
            <h4 className={`${Classes.HEADING} bp3-cursor-pointer noselect`} onClick={() => this.toggleOpen()}>
              {I18n.t('js.permissions')}
            </h4>
            <p className="noselect">{I18n.t('js.app_permissions_text')}</p>
            <GuideButton action="permissions" minimal={false} />
          </Flexbox>
          <Flexbox flexDirection="column">
            <Button
              text={this.state.isOpen ? I18n.t('js.close') : I18n.t('js.expand')}
              icon={this.state.isOpen ? 'chevron-up' : 'expand-all'}
              onClick={() => this.toggleOpen()}
            />
          </Flexbox>
        </Flexbox>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <Flexbox flexDirection="column" marginTop="20px">
              <Flexbox flexGrow={1} className="bp3-form-group">
                <label className="bp3-label" htmlFor="permissionType">
                  {I18n.t('js.default_permission_type')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <Flexbox>
                    <AppEditPermissionsSelectPermission handleInputChange={this.handleInputChange.bind(this)} />{' '}
                    {this.renderExplanationButton()}
                    {this.renderAddMoreButton()}
                  </Flexbox>
                </div>
              </Flexbox>
              <Flexbox flexGrow={1} flexDirection="column">
                <PermissionExplanations type={I18n.t('js.users')} module={I18n.t('js.app')} isOpen={this.state.showExplanations} />
              </Flexbox>
              {this.renderPermissions()}
            </Flexbox>
            <Flexbox>
              <div>
                <Button
                  intent={Intent.PRIMARY}
                  type="submit"
                  text={I18n.t('js.save_changes')}
                  loading={this.state.isSubmitting}
                  icon="tick"
                />
                <Button className="push-10-l" icon="cross" text={I18n.t('js.cancel_changes')} onClick={this.handleCancel.bind(this)} />
              </div>
            </Flexbox>
          </form>
        </Collapse>
      </Flexbox>
    );
  }
}
