import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Dialog, Classes, MenuItem, Intent } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import Element from '../../models/ElementModel';
import App from '../../models/AppModel';
import classNames from 'classnames';
import _ from 'lodash';

const LeftSelect = MultiSelect.ofType(Element);
const RightSelect = MultiSelect.ofType(Element);

@inject('DynamicViewStore', 'AppStore', 'WorkspaceStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynamicViewCustomiseItemDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      isSubmitting: false,
    };
  }

  toggleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  handleSubmit() {
    this.setState({ isSubmitting: true });

    const appObj = {
      id: this.props.DynamicViewStore.activeApp.id,
      item_layout: JSON.stringify(this.props.DynamicViewStore.itemLayout),
    };

    this.props.AppStore.changeApp(appObj)
      .then(response => {
        const { data } = response;
        const newApp = new App(
          data.id,
          data.workspace_id,
          data.workspace_folder_id,
          data.name,
          data.description,
          data.item,
          data.item_plural,
          data.item_layout,
          data.icon,
          data.color,
          data.image_file_name,
          data.permission_type,
          data.status,
          data.row_order,
          data.row_order_folder,
          data.user_access_level,
          data.webform_enabled,
          data.webform_email_required,
          data.webform_anonymous,
          data.webform_path,
          data.default_view_folder,
          data.default_process_visible,
          data.include_in_quick_create_menu,
          data.dummy_data,
          data.lock_participants,
          data.comment_count,
          data.is_store_template,
          data.store_template_id,
          data.created_at,
          data.created_by,
          data.deleted_at,
          data.deleted_by,
          data.updated_at,
          data.updated_by
        );
        this.props.DynamicViewStore.setActiveApp(newApp);
        this.toggleDialog();
        this.setState({ isSubmitting: false });
        this.props.ToastStore.showToast(I18n.t('js.item_layout_has_been_updated'), 'success');
        if (this.props.DynamicViewStore.activeTab === 'all-items') {
          this.props.DynamicViewStore.loadAllItems();
        }
      })
      .catch(error => {
        reject(error);
        const errors = error.response.data.error.join(', ');
        this.props.ToastStore.showToast(errors, 'danger');
        this.toggleDialog();
        this.setState({ isSubmitting: false });
      });
  }

  renderElement(item, details) {
    let menuClass = classNames('popover-larger');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.column_id}
        onClick={details.handleClick}
        icon={item.column_type === 'string' ? 'new-text-box' : item.column_type === 'number' ? 'numerical' : 'calendar'}
        text={item.column_name}
      />
    );
  }

  renderNoResults() {
    if (this.props.DynamicViewStore.availableElements.length === 0) {
      return <MenuItem disabled text={I18n.t('js.nothing_to_show')} />;
    }
  }

  renderTag(element) {
    return <span id={element.column_id}>{element.column_name}</span>;
  }

  renderInputValue(element) {
    return element.column_name;
  }

  handleLeftElementSelect(element) {
    this.props.DynamicViewStore.addLeftElement(element);
  }

  handleLeftElementRemove(element) {
    this.props.DynamicViewStore.removeLeftElement(element.props.id);
  }

  handleRightElementSelect(element) {
    this.props.DynamicViewStore.addRightElement(element);
  }

  handleRightElementRemove(element) {
    this.props.DynamicViewStore.removeRightElement(element.props.id);
  }

  handleCancel() {
    this.props.DynamicViewStore.setFilteredUser('');
    this.setState({ query: '' });
  }

  handleQueryChange(query) {
    this.setState({ query });
  }

  handlePopoverClose() {
    // this.setState({ searchUsers: [], query: '' });
  }

  filterLeftElements() {
    const store = this.props.DynamicViewStore;
    let possibleElements = [];
    _.map(store.availableElements, element => {
      let findElement = _.find(store.itemLayout.leftElements, o => o === element.column_id);
      if (!findElement) {
        possibleElements.push(element);
      }
    });
    return possibleElements;
  }

  filterRightElements() {
    const store = this.props.DynamicViewStore;
    let possibleElements = [];
    _.map(store.availableElements, element => {
      let findElement = _.find(store.itemLayout.rightElements, o => o === element.column_id);
      if (!findElement) {
        possibleElements.push(element);
      }
    });
    return possibleElements;
  }

  render() {
    const store = this.props.DynamicViewStore;
    const { availableElements, selectedLeftElements, selectedRightElements } = store;
    if (store.activeApp) {
      return (
        <Flexbox marginTop="10px" justifyContent="flex-end">
          <Button
            icon="edit"
            text={I18n.t('js.edit_item_layout')}
            onClick={this.toggleDialog.bind(this)}
            disabled={store.items.length === 0 || store.activeApp === null}
          ></Button>
          <Dialog
            portalContainer={document.body}
            isOpen={this.state.dialogOpen}
            onClose={() => this.toggleDialog()}
            canOutsideClickClose={false}
            icon="edit"
            title={I18n.t('js.edit_item_layout')}
            inline={false}
          >
            <form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Flexbox className="bp3-dialog-body" flexDirection="column">
                <p>{I18n.t('js.edit_item_layout_description')}</p>
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="leftElements">
                    <span>{I18n.t('js.left_elements')}</span>
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div className="bp3-input-group">
                      <LeftSelect
                        className="max-width"
                        resetOnSelect={true}
                        items={availableElements}
                        itemListPredicate={this.filterLeftElements.bind(this)}
                        selectedItems={selectedLeftElements}
                        tagRenderer={this.renderTag.bind(this)}
                        itemRenderer={this.renderElement.bind(this)}
                        onItemSelect={this.handleLeftElementSelect.bind(this)}
                        fill={true}
                        tagInputProps={{
                          onRemove: this.handleLeftElementRemove.bind(this),
                        }}
                        popoverProps={{
                          usePortal: false,
                          position: 'bottom-left',
                          className: '',
                          popoverClassName: 'bp3-minimal',
                          onClose: this.handlePopoverClose.bind(this),
                        }}
                        noResults={this.renderNoResults()}
                      />
                    </div>
                  </div>
                </div>
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="rightElements">
                    <span>{I18n.t('js.right_elements')}</span>
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div className="bp3-input-group">
                      <RightSelect
                        className="max-width"
                        resetOnSelect={true}
                        items={availableElements}
                        itemListPredicate={this.filterRightElements.bind(this)}
                        selectedItems={selectedRightElements}
                        tagRenderer={this.renderTag.bind(this)}
                        itemRenderer={this.renderElement.bind(this)}
                        onItemSelect={this.handleRightElementSelect.bind(this)}
                        fill={true}
                        tagInputProps={{
                          onRemove: this.handleRightElementRemove.bind(this),
                        }}
                        popoverProps={{
                          usePortal: false,
                          position: 'bottom-left',
                          className: '',
                          popoverClassName: 'bp3-minimal',
                          onClose: this.handlePopoverClose.bind(this),
                        }}
                        noResults={this.renderNoResults()}
                      />
                    </div>
                  </div>
                </div>
              </Flexbox>
              <Flexbox className="bp3-dialog-footer" justifyContent="flex-end">
                <Flexbox className="bp3-dialog-footer-actions">
                  <Button text={I18n.t('js.cancel')} onClick={() => this.toggleDialog()} />
                  <Button
                    type="submit"
                    intent={Intent.PRIMARY}
                    onClick={this.handleSubmit.bind(this)}
                    text={I18n.t('js.set_item_layout')}
                    loading={this.state.isSubmitting}
                  />
                </Flexbox>
              </Flexbox>
            </form>
          </Dialog>
        </Flexbox>
      );
    }
    return null;
  }
}
