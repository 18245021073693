import { observable } from 'mobx';

export default class ElementTitle {
  @observable
  id;
  @observable
  elementId;
  @observable
  name;

  constructor(id, elementId, name) {
    this.id = id || '';
    this.elementId = elementId || '';
    this.name = name || '';
  }
}
