/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Avatar from 'react-avatar';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  AnchorButton,
  Classes,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Spinner,
  Tab,
  Tabs,
} from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import AppItemsSelectUsers from '../app_items/AppItemsSelectUsers';
import AppItemsSelectTeams from '../app_items/AppItemsSelectTeams';
import _ from 'lodash';

@inject('CurrentUserStore', 'ToastStore', 'AppStore', 'ItemStore', 'ItemActions', 'DynamicViewStore')
@observer
export default class ItemTabs extends React.Component {
  @observable isLoading = false;
  @observable isDeleting = false;
  @observable isDuplicating = false;
  @observable isAddingNewUsers = false;
  @observable isAddingNewPinUsers = false;
  @observable isAddingNewTeams = false;
  @observable isAddingNewPinTeams = false;

  toggleDeleting() {
    this.isDeleting = !this.isDeleting;
  }

  toggleDuplicating() {
    this.isDuplicating = !this.isDuplicating;
  }

  toggleAddNewUsers() {
    this.isAddingNewUsers = !this.isAddingNewUsers;
  }

  toggleAddNewPinUsers() {
    this.isAddingNewPinUsers = !this.isAddingNewPinUsers;
  }

  toggleAddNewTeams() {
    this.isAddingNewTeams = !this.isAddingNewTeams;
  }

  toggleAddNewPinTeams() {
    this.isAddingNewPinTeams = !this.isAddingNewPinTeams;
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleDeleteItem() {
    this.props.ItemActions.deleteItem(this.props.ItemStore.item.id)
      .then(() => {
        this.toggleDeleting();
        Utilities.navigate(`/apps/${this.props.AppStore.activeApp.id}`);
        this.props.ToastStore.showToast(
          I18n.t('js.item_has_been_deleted', {
            name: _.capitalize(this.props.AppStore.activeApp.item),
          }),
          'success'
        );
      })
      .catch(() => {
        this.toggleDeleting();
      });
  }

  handleDuplicateAsIs() {
    this.toggleDuplicating();
    this.props.ItemActions.duplicateItem(this.props.ItemStore.item.id)
      .then(() => {
        this.props.ToastStore.showToast(
          I18n.t('js.item_has_been_queued_for_duplication', {
            name: this.props.AppStore.activeApp.item,
          }),
          'success'
        );
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handlePinItem() {
    this.props.ItemActions.pinItemForUser(this.props.ItemStore.item.id, this.props.CurrentUserStore.currentUser.id).then(response => {
      this.props.ItemStore.addItemPin(response.data);
      this.props.ToastStore.showToast(
        I18n.t('js.item_pinned', {
          name: this.props.AppStore.activeApp.item,
        }),
        'success'
      );
    });
  }

  handleUnpinItem(itemPinId) {
    this.props.ItemActions.deleteItemPin(itemPinId).then(() => {
      this.props.ItemStore.removeItemPin(itemPinId);
      this.props.ToastStore.showToast(
        I18n.t('js.item_unpinned', {
          name: this.props.AppStore.activeApp.item,
        }),
        'success'
      );
    });
  }

  handleMarkAsComplete() {
    this.props.ItemActions.markItemAsComplete(this.props.ItemStore.item.id).then(response => {
      this.props.ItemStore.addItem(response.data);
      if (response.data.is_done_for_current_user) {
        this.props.ToastStore.showToast(
          I18n.t('js.item_marked_as_done', {
            item: this.props.AppStore.activeApp.item,
          }),
          'success'
        );
      } else {
        this.props.ToastStore.showToast(
          I18n.t('js.item_has_been_updated', {
            item: this.props.AppStore.activeApp.item,
          }),
          'success'
        );
      }
    });
  }

  renderDeleteItem() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_item', {
          name: this.props.AppStore.activeApp.item,
        })}
        onConfirm={this.handleDeleteItem.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_item', {
          name: this.props.AppStore.activeApp.item,
        })}
      </Alert>
    );
  }

  handleTabChange(tab) {
    if (tab !== 'actions') {
      this.props.ItemStore.activeEditSection = null;
      this.props.ItemStore.activeTab = tab;
    }
  }

  renderUserIcon() {
    return <Avatar src={this.props.CurrentUserStore.currentUser.imageFileName} size={20} round={true} className="push-5-r" />;
  }

  renderPins() {
    if (this.props.ItemStore.itemPins.length > 0) {
      return (
        <>
          {_.map(this.props.ItemStore.itemPins, itemPin => {
            return (
              <MenuItem
                key={itemPin.id}
                icon={
                  <Avatar
                    src={itemPin.user ? itemPin.user.image_file_name : itemPin.team.image_file_name}
                    size={20}
                    round={true}
                    className="push-5-r"
                  />
                }
                text={itemPin.user ? itemPin.user.full_name : itemPin.team.name}
              >
                <MenuItem
                  icon="unpin"
                  text={I18n.t('js.unpin_item', { name: this.props.AppStore.activeApp.item })}
                  intent={Intent.DANGER}
                  onClick={() => this.handleUnpinItem(itemPin.id)}
                />
              </MenuItem>
            );
          })}
          <MenuDivider />
        </>
      );
    }
    return null;
  }

  renderPinItemMenu() {
    return (
      <>
        <MenuItem icon="pin" text={I18n.t('js.pin_to_your_dashboard')} onClick={this.handlePinItem.bind(this)} />
        <MenuDivider />
        {this.renderPins()}
        <MenuItem icon="people" text={I18n.t('js.select_users')} onClick={this.toggleAddNewPinUsers.bind(this)} />
        <MenuItem icon="layout-hierarchy" text={I18n.t('js.select_team_of_users')} onClick={this.toggleAddNewPinTeams.bind(this)} />
      </>
    );
  }

  renderDuplicateItemMenu() {
    return (
      <>
        <MenuItem icon="duplicate" text={I18n.t('js.duplicate_as_is')} onClick={this.handleDuplicateAsIs.bind(this)} />
        <MenuDivider />
        <MenuItem icon="people" text={I18n.t('js.select_users')} onClick={this.toggleAddNewUsers.bind(this)} />
        <MenuItem icon="layout-hierarchy" text={I18n.t('js.select_team_of_users')} onClick={this.toggleAddNewTeams.bind(this)} />
      </>
    );
  }

  renderActionMenuItems() {
    return (
      <Menu>
        <MenuItem
          icon={this.props.ItemStore.item.isDoneForCurrentUser ? 'cross' : 'tick'}
          text={this.props.ItemStore.item.isDoneForCurrentUser ? I18n.t('js.mark_as_not_done') : I18n.t('js.mark_as_done')}
          disabled={!this.props.ItemStore.userIsOwner}
          onClick={this.handleMarkAsComplete.bind(this)}
        />
        <MenuItem
          icon="document-open"
          text={I18n.t('js.export_to_pdf')}
          onClick={() => {
            window.open(`/items/${this.props.ItemStore.activeItem.id}/pdf-item.pdf`);
          }}
        />
        <MenuItem
          icon="pin"
          text={I18n.t('js.pin_item', {
            item: this.props.AppStore.activeApp.item,
          })}
        >
          {this.renderPinItemMenu()}
        </MenuItem>
        <MenuItem
          icon="duplicate"
          text={I18n.t('js.duplicate_item', {
            item: this.props.AppStore.activeApp.item,
          })}
          disabled={!this.props.ItemStore.userIsOwner}
        >
          {this.renderDuplicateItemMenu()}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon="trash"
          disabled={!this.props.ItemStore.userIsOwner}
          text={I18n.t('js.delete_item', {
            name: this.props.AppStore.activeApp.item,
          })}
          onClick={this.toggleDeleting.bind(this)}
          className="bp3-intent-danger"
        />
      </Menu>
    );
  }

  renderPopover() {
    return (
      <Popover
        portalContainer={document.body}
        position="bottom"
        content={this.renderActionMenuItems()}
        autoFocus={false}
        interactionKind={PopoverInteractionKind.CLICK}
      >
        <Flexbox flexDirection="row" alignItems="center">
          <span>{I18n.t('js.actions')}</span>
          <span className="bp3-icon-standard bp3-icon-chevron-down push-5-l bp3-align-right" />
        </Flexbox>
      </Popover>
    );
  }

  renderActionMenu() {
    return <Tab id="actions">{this.renderPopover()}</Tab>;
  }

  renderDetailsTitle() {
    return <span>{I18n.t('js.form')}</span>;
  }

  renderActivityTitle() {
    return <span>{I18n.t('js.activity')}</span>;
  }

  renderEditTitle() {
    return <span>{I18n.t('js.edit')}</span>;
  }

  renderBreadCrumbs() {
    return (
      <Flexbox>
        <ul className={`{Classes.LIST} bp3-breadcrumbs`}>
          <li>
            <a className="bp3-breadcrumb" href={`/workspaces/${this.props.AppStore.activeWorkspace.id}`}>
              {this.props.AppStore.activeWorkspace.name}
            </a>
          </li>
          <li>
            <a className="bp3-breadcrumb" href={`/apps/${this.props.AppStore.activeApp.id}`}>
              {_.truncate(this.props.AppStore.activeApp.name, {
                length: 20,
              })}
            </a>
          </li>
          <li>
            <span className="bp3-breadcrumb">
              {_.truncate(this.props.ItemStore.item.name, {
                length: 20,
              })}
            </span>
          </li>
        </ul>
      </Flexbox>
    );
  }

  renderLeftNav() {
    return (
      <Flexbox>
        <Tabs
          id="itemTabs"
          animate={true}
          selectedTabId={this.props.ItemStore.activeTab}
          defaultSelectedTabId="actions"
          onChange={this.handleTabChange.bind(this)}
          className="noselect"
        >
          <Tab id="details" title={this.renderDetailsTitle()} />
          <Tab id="activity" title={this.renderActivityTitle()} />
          <Tab id="edit" title={this.renderEditTitle()} disabled={!this.props.ItemStore.userIsOwner} />
          {this.renderActionMenu()}
          <Tabs.Expander />
        </Tabs>
        {this.renderDeleteItem()}
      </Flexbox>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered item-navbar" style={{ zIndex: 2 }}>
        <Flexbox className="fixed-width-container animated fadeIn">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderLeftNav()}
          </Flexbox>
          <Flexbox flexGrow={0} flexDirection="row" alignItems="center" justifyContent="center">
            <AnchorButton href={`/apps/${this.props.AppStore.activeApp.id}`} className="push-10-l bp3-minimal" rightIcon="undo">
              {this.props.AppStore.activeApp.itemPlural}
            </AnchorButton>
            <AppItemsSelectUsers toggleDialog={this.toggleAddNewUsers.bind(this)} postType="copy" dialogOpen={this.isAddingNewUsers} />
            <AppItemsSelectUsers toggleDialog={this.toggleAddNewPinUsers.bind(this)} postType="pin" dialogOpen={this.isAddingNewPinUsers} />
            <AppItemsSelectTeams toggleDialog={this.toggleAddNewTeams.bind(this)} postType="copy" dialogOpen={this.isAddingNewTeams} />
            <AppItemsSelectTeams toggleDialog={this.toggleAddNewPinTeams.bind(this)} postType="pin" dialogOpen={this.isAddingNewPinTeams} />
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
