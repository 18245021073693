const ptIcons = [
  'bp3-icon-header-two',
  'bp3-icon-header-one',
  'bp3-icon-widget-footer',
  'bp3-icon-widget-header',
  'bp3-icon-widget-button',
  'bp3-icon-feed-subscribed',
  'bp3-icon-key',
  'bp3-icon-filter-remove',
  'bp3-icon-filter-keep',
  'bp3-icon-new-prescription',
  'bp3-icon-prescription',
  'bp3-icon-tick-circle',
  'bp3-icon-panel-table',
  'bp3-icon-panel-stats',
  'bp3-icon-issue-closed',
  'bp3-icon-issue-new',
  'bp3-icon-issue',
  'bp3-icon-eraser',
  'bp3-icon-ip-address',
  'bp3-icon-id-number',
  'bp3-icon-cell-tower',
  'bp3-icon-bank-account',
  'bp3-icon-paragraph',
  'bp3-icon-satellite',
  'bp3-icon-percentage',
  'bp3-icon-slash',
  'bp3-icon-blocked-person',
  'bp3-icon-intersection',
  'bp3-icon-expand-all',
  'bp3-icon-collapse-all',
  'bp3-icon-menu',
  'bp3-icon-following',
  'bp3-icon-follower',
  'bp3-icon-endorsed',
  'bp3-icon-full-stacked-chart',
  'bp3-icon-grouped-bar-chart',
  'bp3-icon-horizontal-bar-chart-asc',
  'bp3-icon-vertical-bar-chart-asc',
  'bp3-icon-eye-on',
  'bp3-icon-translate',
  'bp3-icon-regression-chart',
  'bp3-icon-key-tab',
  'bp3-icon-numerical',
  'bp3-icon-remove-column',
  'bp3-icon-moon',
  'bp3-icon-path',
  'bp3-icon-publish-function',
  'bp3-icon-dashboard',
  'bp3-icon-split-columns',
  'bp3-icon-merge-columns',
  'bp3-icon-airplane',
  'bp3-icon-manually-entered-data',
  'bp3-icon-git-new-branch',
  'bp3-icon-git-repo',
  'bp3-icon-new-grid-item',
  'bp3-icon-numbered-list',
  'bp3-icon-swap-horizontal',
  'bp3-icon-swap-vertical',
  'bp3-icon-list-detail-view',
  'bp3-icon-key-option',
  'bp3-icon-map-create',
  'bp3-icon-rig',
  'bp3-icon-oil-field',
  'bp3-icon-scatter-plot',
  'bp3-icon-unknown-vehicle',
  'bp3-icon-known-vehicle',
  'bp3-icon-image-rotate-right',
  'bp3-icon-image-rotate-left',
  'bp3-icon-derive-column',
  'bp3-icon-join-table',
  'bp3-icon-send-to-map',
  'bp3-icon-send-to-graph',
  'bp3-icon-application',
  'bp3-icon-wrench',
  'bp3-icon-symbol-triangle-down',
  'bp3-icon-symbol-triangle-up',
  'bp3-icon-symbol-cross',
  'bp3-icon-symbol-diamond',
  'bp3-icon-symbol-square',
  'bp3-icon-symbol-circle',
  'bp3-icon-build',
  'bp3-icon-git-push',
  'bp3-icon-git-commit',
  'bp3-icon-git-branch',
  'bp3-icon-git-merge',
  'bp3-icon-git-pull',
  'bp3-icon-alignment-bottom',
  'bp3-icon-alignment-horizontal-center',
  'bp3-icon-alignment-top',
  'bp3-icon-alignment-right',
  'bp3-icon-alignment-vertical-center',
  'bp3-icon-alignment-left',
  'bp3-icon-vertical-distribution',
  'bp3-icon-horizontal-distribution',
  'bp3-icon-document-share',
  'bp3-icon-document-open',
  'bp3-icon-horizontal-bar-chart-desc',
  'bp3-icon-vertical-bar-chart-desc',
  'bp3-icon-curved-range-chart',
  'bp3-icon-trending-down',
  'bp3-icon-trending-up',
  'bp3-icon-sim-card',
  'bp3-icon-mobile-phone',
  'bp3-icon-drag-handle-horizontal',
  'bp3-icon-drag-handle-vertical',
  'bp3-icon-euro',
  'bp3-icon-step-chart',
  'bp3-icon-small-minus',
  'bp3-icon-small-plus',
  'bp3-icon-horizontal-bar-chart',
  'bp3-icon-calculator',
  'bp3-icon-key-enter',
  'bp3-icon-key-escape',
  'bp3-icon-key-delete',
  'bp3-icon-key-backspace',
  'bp3-icon-key-shift',
  'bp3-icon-key-command',
  'bp3-icon-key-control',
  'bp3-icon-double-chevron-down',
  'bp3-icon-double-chevron-up',
  'bp3-icon-double-chevron-right',
  'bp3-icon-double-chevron-left',
  'bp3-icon-remove-column-right',
  'bp3-icon-remove-column-left',
  'bp3-icon-remove-row-bottom',
  'bp3-icon-remove-row-top',
  'bp3-icon-add-column-right',
  'bp3-icon-add-column-left',
  'bp3-icon-add-row-bottom',
  'bp3-icon-add-row-top',
  'bp3-icon-manual',
  'bp3-icon-variable',
  'bp3-icon-gantt-chart',
  'bp3-icon-heat-grid',
  'bp3-icon-ring',
  'bp3-icon-pivot',
  'bp3-icon-annotation',
  'bp3-icon-cut',
  'bp3-icon-filter-list',
  'bp3-icon-highlight',
  'bp3-icon-segmented-control',
  'bp3-icon-pivot-table',
  'bp3-icon-exclude-row',
  'bp3-icon-new-person',
  'bp3-icon-pulse',
  'bp3-icon-stacked-chart',
  'bp3-icon-waterfall-chart',
  'bp3-icon-function',
  'bp3-icon-grid-view',
  'bp3-icon-badge',
  'bp3-icon-rotate-app',
  'bp3-icon-rotate-document',
  'bp3-icon-chevron-forward',
  'bp3-icon-chevron-backward',
  'bp3-icon-hand',
  'bp3-icon-text-highlight',
  'bp3-icon-headset',
  'bp3-icon-mugshot',
  'bp3-icon-arrow-bottom-left',
  'bp3-icon-arrow-bottom-right',
  'bp3-icon-arrow-top-right',
  'bp3-icon-arrow-top-left',
  'bp3-icon-column-layout',
  'bp3-icon-power',
  'bp3-icon-small-tick',
  'bp3-icon-small-cross',
  'bp3-icon-sort-desc',
  'bp3-icon-sort-asc',
  'bp3-icon-layout-sorted-clusters',
  'bp3-icon-layout-balloon',
  'bp3-icon-add-to-folder',
  'bp3-icon-polygon-filter',
  'bp3-icon-grid',
  'bp3-icon-layer',
  'bp3-icon-doughnut-chart',
  'bp3-icon-timeline-area-chart',
  'bp3-icon-eye-off',
  'bp3-icon-contrast',
  'bp3-icon-take-action',
  'bp3-icon-sort-numerical-desc',
  'bp3-icon-sort-alphabetical-desc',
  'bp3-icon-double-caret-horizontal',
  'bp3-icon-double-caret-vertical',
  'bp3-icon-code-block',
  'bp3-icon-redo',
  'bp3-icon-undo',
  'bp3-icon-layout-linear',
  'bp3-icon-shop',
  'bp3-icon-shopping-cart',
  'bp3-icon-compressed',
  'bp3-icon-box',
  'bp3-icon-thumbs-down',
  'bp3-icon-thumbs-up',
  'bp3-icon-hand-left',
  'bp3-icon-hand-down',
  'bp3-icon-hand-up',
  'bp3-icon-hand-right',
  'bp3-icon-book',
  'bp3-icon-floppy-disk',
  'bp3-icon-saved',
  'bp3-icon-header',
  'bp3-icon-font',
  'bp3-icon-flash',
  'bp3-icon-tint',
  'bp3-icon-glass',
  'bp3-icon-lightbulb',
  'bp3-icon-phone',
  'bp3-icon-desktop',
  'bp3-icon-record',
  'bp3-icon-eject',
  'bp3-icon-step-forward',
  'bp3-icon-fast-forward',
  'bp3-icon-play',
  'bp3-icon-stop',
  'bp3-icon-pause',
  'bp3-icon-fast-backward',
  'bp3-icon-step-backward',
  'bp3-icon-music',
  'bp3-icon-volume-up',
  'bp3-icon-volume-down',
  'bp3-icon-volume-off',
  'bp3-icon-settings',
  'bp3-icon-film',
  'bp3-icon-video',
  'bp3-icon-mobile-video',
  'bp3-icon-camera',
  'bp3-icon-ban-circle',
  'bp3-icon-duplicate',
  'bp3-icon-office',
  'bp3-icon-heart',
  'bp3-icon-log-in',
  'bp3-icon-fullscreen',
  'bp3-icon-random',
  'bp3-icon-chevron-down',
  'bp3-icon-chevron-up',
  'bp3-icon-chevron-right',
  'bp3-icon-chevron-left',
  'bp3-icon-move',
  'bp3-icon-repeat',
  'bp3-icon-cloud-upload',
  'bp3-icon-cloud-download',
  'bp3-icon-cloud',
  'bp3-icon-asterisk',
  'bp3-icon-upload',
  'bp3-icon-circle-arrow-down',
  'bp3-icon-circle-arrow-up',
  'bp3-icon-circle-arrow-left',
  'bp3-icon-circle-arrow-right',
  'bp3-icon-comment',
  'bp3-icon-chat',
  'bp3-icon-unteam-objects',
  'bp3-icon-presentation',
  'bp3-icon-print',
  'bp3-icon-square',
  'bp3-icon-full-circle',
  'bp3-icon-pie-chart',
  'bp3-icon-database',
  'bp3-icon-direction-right',
  'bp3-icon-direction-left',
  'bp3-icon-multi-select',
  'bp3-icon-control',
  'bp3-icon-chart',
  'bp3-icon-map-marker',
  'bp3-icon-add-to-artifact',
  'bp3-icon-zoom-to-fit',
  'bp3-icon-offline',
  'bp3-icon-unresolve',
  'bp3-icon-widget',
  'bp3-icon-torch',
  'bp3-icon-barcode',
  'bp3-icon-delta',
  'bp3-icon-ninja',
  'bp3-icon-dollar',
  'bp3-icon-briefcase',
  'bp3-icon-graph',
  'bp3-icon-resolve',
  'bp3-icon-arrows-vertical',
  'bp3-icon-arrows-horizontal',
  'bp3-icon-arrow-down',
  'bp3-icon-arrow-up',
  'bp3-icon-social-media',
  'bp3-icon-folder-shared-open',
  'bp3-icon-eye-open',
  'bp3-icon-send-to',
  'bp3-icon-helper-management',
  'bp3-icon-insert',
  'bp3-icon-draw',
  'bp3-icon-circle',
  'bp3-icon-th-derived',
  'bp3-icon-th-list',
  'bp3-icon-th',
  'bp3-icon-home',
  'bp3-icon-globe',
  'bp3-icon-label',
  'bp3-icon-paperclip',
  'bp3-icon-envelope',
  'bp3-icon-search-text',
  'bp3-icon-map',
  'bp3-icon-code',
  'bp3-icon-app-layout',
  'bp3-icon-automatic-updates',
  'bp3-icon-path-search',
  'bp3-icon-new-object',
  'bp3-icon-new-link',
  'bp3-icon-new-text-box',
  'bp3-icon-flows',
  'bp3-icon-unpin',
  'bp3-icon-disable',
  'bp3-icon-time',
  'bp3-icon-property',
  'bp3-icon-dot',
  'bp3-icon-one-column',
  'bp3-icon-two-columns',
  'bp3-icon-feed',
  'bp3-icon-menu-closed',
  'bp3-icon-menu-open',
  'bp3-icon-caret-left',
  'bp3-icon-caret-down',
  'bp3-icon-caret-right',
  'bp3-icon-caret-up',
  'bp3-icon-folder-shared',
  'bp3-icon-folder-close',
  'bp3-icon-folder-open',
  'bp3-icon-sort',
  'bp3-icon-sort-numerical',
  'bp3-icon-sort-alphabetical',
  'bp3-icon-star-empty',
  'bp3-icon-star',
  'bp3-icon-log-out',
  'bp3-icon-search',
  'bp3-icon-history',
  'bp3-icon-edit',
  'bp3-icon-credit-card',
  'bp3-icon-info-sign',
  'bp3-icon-error',
  'bp3-icon-warning-sign',
  'bp3-icon-pin',
  'bp3-icon-flag',
  'bp3-icon-cog',
  'bp3-icon-delete',
  'bp3-icon-refresh',
  'bp3-icon-zoom-out',
  'bp3-icon-zoom-in',
  'bp3-icon-geolocation',
  'bp3-icon-remove',
  'bp3-icon-add',
  'bp3-icon-people',
  'bp3-icon-person',
  'bp3-icon-trash',
  'bp3-icon-fork',
  'bp3-icon-confirm',
  'bp3-icon-filter',
  'bp3-icon-list',
  'bp3-icon-comparison',
  'bp3-icon-exchange',
  'bp3-icon-arrow-right',
  'bp3-icon-arrow-left',
  'bp3-icon-minus',
  'bp3-icon-plus',
  'bp3-icon-cross',
  'bp3-icon-tick',
  'bp3-icon-maximize',
  'bp3-icon-minimize',
  'bp3-icon-export',
  'bp3-icon-import',
  'bp3-icon-properties',
  'bp3-icon-document',
  'bp3-icon-download',
  'bp3-icon-share',
  'bp3-icon-link',
  'bp3-icon-media',
  'bp3-icon-calendar',
  'bp3-icon-help',
  'bp3-icon-more',
  'bp3-icon-inbox',
  'bp3-icon-search-template',
  'bp3-icon-user',
  'bp3-icon-unlock',
  'bp3-icon-lock',
  'bp3-icon-notifications',
  'bp3-icon-changes',
  'bp3-icon-projects',
  'bp3-icon-applications',
  'bp3-icon-timeline-bar-chart',
  'bp3-icon-timeline-line-chart',
  'bp3-icon-timeline-events',
  'bp3-icon-selection',
  'bp3-icon-clipboard',
  'bp3-icon-tag',
  'bp3-icon-citation',
  'bp3-icon-bookmark',
  'bp3-icon-locate',
  'bp3-icon-layers',
  'bp3-icon-predictive-analysis',
  'bp3-icon-select',
  'bp3-icon-drive-time',
  'bp3-icon-heatmap',
  'bp3-icon-geosearch',
  'bp3-icon-layout-skew-grid',
  'bp3-icon-layout-group-by',
  'bp3-icon-layout-grid',
  'bp3-icon-layout-hierarchy',
  'bp3-icon-layout-circle',
  'bp3-icon-layout-auto',
  'bp3-icon-layout',
  'bp3-icon-merge-links',
  'bp3-icon-group-objects',
  'bp3-icon-graph-remove',
  'bp3-icon-search-around',
  'bp3-icon-underline',
  'bp3-icon-italic',
  'bp3-icon-bold',
  'bp3-icon-align-justify',
  'bp3-icon-align-right',
  'bp3-icon-align-center',
  'bp3-icon-align-left',
  'bp3-icon-style',
  'bp3-icon-blank',
];

const faIcons = [
  'far fa-address-book',
  'far fa-address-card',
  'far fa-adjust',
  'far fa-alarm-clock',
  'far fa-align-center',
  'far fa-align-justify',
  'far fa-align-left',
  'far fa-align-right',
  'far fa-allergies',
  'far fa-ambulance',
  'far fa-american-sign-language-interpreting',
  'far fa-anchor',
  'far fa-angle-double-down',
  'far fa-angle-double-left',
  'far fa-angle-double-right',
  'far fa-angle-double-up',
  'far fa-angle-down',
  'far fa-angle-left',
  'far fa-angle-right',
  'far fa-angle-up',
  'far fa-archive',
  'far fa-arrow-alt-circle-down',
  'far fa-arrow-alt-circle-left',
  'far fa-arrow-alt-circle-right',
  'far fa-arrow-alt-circle-up',
  'far fa-arrow-alt-down',
  'far fa-arrow-alt-from-bottom',
  'far fa-arrow-alt-from-left',
  'far fa-arrow-alt-from-right',
  'far fa-arrow-alt-from-top',
  'far fa-arrow-alt-left',
  'far fa-arrow-alt-right',
  'far fa-arrow-alt-square-down',
  'far fa-arrow-alt-square-left',
  'far fa-arrow-alt-square-right',
  'far fa-arrow-alt-square-up',
  'far fa-arrow-alt-to-bottom',
  'far fa-arrow-alt-to-left',
  'far fa-arrow-alt-to-right',
  'far fa-arrow-alt-to-top',
  'far fa-arrow-alt-up',
  'far fa-arrow-circle-down',
  'far fa-arrow-circle-left',
  'far fa-arrow-circle-right',
  'far fa-arrow-circle-up',
  'far fa-arrow-down',
  'far fa-arrow-from-bottom',
  'far fa-arrow-from-left',
  'far fa-arrow-from-right',
  'far fa-arrow-from-top',
  'far fa-arrow-left',
  'far fa-arrow-right',
  'far fa-arrow-square-down',
  'far fa-arrow-square-left',
  'far fa-arrow-square-right',
  'far fa-arrow-square-up',
  'far fa-arrow-to-bottom',
  'far fa-arrow-to-left',
  'far fa-arrow-to-right',
  'far fa-arrow-to-top',
  'far fa-arrow-up',
  'far fa-arrows',
  'far fa-arrows-alt',
  'far fa-arrows-alt-h',
  'far fa-arrows-alt-v',
  'far fa-arrows-h',
  'far fa-arrows-v',
  'far fa-assistive-listening-systems',
  'far fa-asterisk',
  'far fa-at',
  'far fa-audio-description',
  'far fa-backward',
  'far fa-badge',
  'far fa-badge-check',
  'far fa-balance-scale',
  'far fa-balance-scale-left',
  'far fa-balance-scale-right',
  'far fa-ban',
  'far fa-band-aid',
  'far fa-barcode',
  'far fa-barcode-alt',
  'far fa-barcode-read',
  'far fa-barcode-scan',
  'far fa-bars',
  'far fa-baseball',
  'far fa-baseball-ball',
  'far fa-basketball-ball',
  'far fa-basketball-hoop',
  'far fa-bath',
  'far fa-battery-bolt',
  'far fa-battery-empty',
  'far fa-battery-full',
  'far fa-battery-half',
  'far fa-battery-quarter',
  'far fa-battery-slash',
  'far fa-battery-three-quarters',
  'far fa-bed',
  'far fa-beer',
  'far fa-bell',
  'far fa-bell-slash',
  'far fa-bicycle',
  'far fa-binoculars',
  'far fa-birthday-cake',
  'far fa-blanket',
  'far fa-blender',
  'far fa-blind',
  'far fa-bold',
  'far fa-bolt',
  'far fa-bomb',
  'far fa-book',
  'far fa-book-heart',
  'far fa-book-open',
  'far fa-bookmark',
  'far fa-bowling-ball',
  'far fa-bowling-pins',
  'far fa-box',
  'far fa-box-alt',
  'far fa-box-check',
  'far fa-box-fragile',
  'far fa-box-full',
  'far fa-box-heart',
  'far fa-box-open',
  'far fa-box-up',
  'far fa-box-usd',
  'far fa-boxes',
  'far fa-boxes-alt',
  'far fa-boxing-glove',
  'far fa-braille',
  'far fa-briefcase',
  'far fa-briefcase-medical',
  'far fa-broadcast-tower',
  'far fa-broom',
  'far fa-browser',
  'far fa-bug',
  'far fa-building',
  'far fa-bullhorn',
  'far fa-bullseye',
  'far fa-burn',
  'far fa-bus',
  'far fa-calculator',
  'far fa-calendar',
  'far fa-calendar-alt',
  'far fa-calendar-check',
  'far fa-calendar-edit',
  'far fa-calendar-exclamation',
  'far fa-calendar-minus',
  'far fa-calendar-plus',
  'far fa-calendar-times',
  'far fa-camera',
  'far fa-camera-alt',
  'far fa-camera-retro',
  'far fa-capsules',
  'far fa-car',
  'far fa-caret-circle-down',
  'far fa-caret-circle-left',
  'far fa-caret-circle-right',
  'far fa-caret-circle-up',
  'far fa-caret-down',
  'far fa-caret-left',
  'far fa-caret-right',
  'far fa-caret-square-down',
  'far fa-caret-square-left',
  'far fa-caret-square-right',
  'far fa-caret-square-up',
  'far fa-caret-up',
  'far fa-cart-arrow-down',
  'far fa-cart-plus',
  'far fa-certificate',
  'far fa-chalkboard',
  'far fa-chalkboard-teacher',
  'far fa-chart-area',
  'far fa-chart-bar',
  'far fa-chart-line',
  'far fa-chart-pie',
  'far fa-check',
  'far fa-check-circle',
  'far fa-check-square',
  'far fa-chess',
  'far fa-chess-bishop',
  'far fa-chess-bishop-alt',
  'far fa-chess-board',
  'far fa-chess-clock',
  'far fa-chess-clock-alt',
  'far fa-chess-king',
  'far fa-chess-king-alt',
  'far fa-chess-knight',
  'far fa-chess-knight-alt',
  'far fa-chess-pawn',
  'far fa-chess-pawn-alt',
  'far fa-chess-queen',
  'far fa-chess-queen-alt',
  'far fa-chess-rook',
  'far fa-chess-rook-alt',
  'far fa-chevron-circle-down',
  'far fa-chevron-circle-left',
  'far fa-chevron-circle-right',
  'far fa-chevron-circle-up',
  'far fa-chevron-double-down',
  'far fa-chevron-double-left',
  'far fa-chevron-double-right',
  'far fa-chevron-double-up',
  'far fa-chevron-down',
  'far fa-chevron-left',
  'far fa-chevron-right',
  'far fa-chevron-square-down',
  'far fa-chevron-square-left',
  'far fa-chevron-square-right',
  'far fa-chevron-square-up',
  'far fa-chevron-up',
  'far fa-child',
  'far fa-church',
  'far fa-circle',
  'far fa-circle-notch',
  'far fa-clipboard',
  'far fa-clipboard-check',
  'far fa-clipboard-list',
  'far fa-clock',
  'far fa-clone',
  'far fa-closed-captioning',
  'far fa-cloud',
  'far fa-cloud-download',
  'far fa-cloud-download-alt',
  'far fa-cloud-upload',
  'far fa-cloud-upload-alt',
  'far fa-club',
  'far fa-code',
  'far fa-code-branch',
  'far fa-code-commit',
  'far fa-code-merge',
  'far fa-coffee',
  'far fa-cog',
  'far fa-cogs',
  'far fa-coins',
  'far fa-columns',
  'far fa-comment',
  'far fa-comment-alt',
  'far fa-comment-alt-check',
  'far fa-comment-alt-dots',
  'far fa-comment-alt-edit',
  'far fa-comment-alt-exclamation',
  'far fa-comment-alt-lines',
  'far fa-comment-alt-minus',
  'far fa-comment-alt-plus',
  'far fa-comment-alt-slash',
  'far fa-comment-alt-smile',
  'far fa-comment-alt-times',
  'far fa-comment-check',
  'far fa-comment-dots',
  'far fa-comment-edit',
  'far fa-comment-exclamation',
  'far fa-comment-lines',
  'far fa-comment-minus',
  'far fa-comment-plus',
  'far fa-comment-slash',
  'far fa-comment-smile',
  'far fa-comment-times',
  'far fa-comments',
  'far fa-comments-alt',
  'far fa-compact-disc',
  'far fa-compass',
  'far fa-compress',
  'far fa-compress-alt',
  'far fa-compress-wide',
  'far fa-container-storage',
  'far fa-conveyor-belt',
  'far fa-conveyor-belt-alt',
  'far fa-copy',
  'far fa-copyright',
  'far fa-couch',
  'far fa-credit-card',
  'far fa-credit-card-blank',
  'far fa-credit-card-front',
  'far fa-cricket',
  'far fa-crop',
  'far fa-crosshairs',
  'far fa-crow',
  'far fa-crown',
  'far fa-cube',
  'far fa-cubes',
  'far fa-curling',
  'far fa-cut',
  'far fa-database',
  'far fa-deaf',
  'far fa-desktop',
  'far fa-desktop-alt',
  'far fa-diagnoses',
  'far fa-diamond',
  'far fa-dice',
  'far fa-dice-five',
  'far fa-dice-four',
  'far fa-dice-one',
  'far fa-dice-six',
  'far fa-dice-three',
  'far fa-dice-two',
  'far fa-divide',
  'far fa-dna',
  'far fa-dollar-sign',
  'far fa-dolly',
  'far fa-dolly-empty',
  'far fa-dolly-flatbed',
  'far fa-dolly-flatbed-alt',
  'far fa-dolly-flatbed-empty',
  'far fa-donate',
  'far fa-door-closed',
  'far fa-door-open',
  'far fa-dot-circle',
  'far fa-dove',
  'far fa-download',
  'far fa-dumbbell',
  'far fa-edit',
  'far fa-eject',
  'far fa-ellipsis-h',
  'far fa-ellipsis-h-alt',
  'far fa-ellipsis-v',
  'far fa-ellipsis-v-alt',
  'far fa-envelope',
  'far fa-envelope-open',
  'far fa-envelope-square',
  'far fa-equals',
  'far fa-eraser',
  'far fa-euro-sign',
  'far fa-exchange',
  'far fa-exchange-alt',
  'far fa-exclamation',
  'far fa-exclamation-circle',
  'far fa-exclamation-square',
  'far fa-exclamation-triangle',
  'far fa-expand',
  'far fa-expand-alt',
  'far fa-expand-arrows',
  'far fa-expand-arrows-alt',
  'far fa-expand-wide',
  'far fa-external-link',
  'far fa-external-link-alt',
  'far fa-external-link-square',
  'far fa-external-link-square-alt',
  'far fa-eye',
  'far fa-eye-dropper',
  'far fa-eye-slash',
  'far fa-fast-backward',
  'far fa-fast-forward',
  'far fa-fax',
  'far fa-feather',
  'far fa-female',
  'far fa-field-hockey',
  'far fa-fighter-jet',
  'far fa-file',
  'far fa-file-alt',
  'far fa-file-archive',
  'far fa-file-audio',
  'far fa-file-check',
  'far fa-file-code',
  'far fa-file-edit',
  'far fa-file-excel',
  'far fa-file-exclamation',
  'far fa-file-image',
  'far fa-file-medical',
  'far fa-file-medical-alt',
  'far fa-file-minus',
  'far fa-file-pdf',
  'far fa-file-plus',
  'far fa-file-powerpoint',
  'far fa-file-times',
  'far fa-file-video',
  'far fa-file-word',
  'far fa-film',
  'far fa-film-alt',
  'far fa-filter',
  'far fa-fire',
  'far fa-fire-extinguisher',
  'far fa-first-aid',
  'far fa-flag',
  'far fa-flag-checkered',
  'far fa-flask',
  'far fa-folder',
  'far fa-folder-open',
  'far fa-font',
  'far fa-font-awesome-logo-full',
  'far fa-football-ball',
  'far fa-football-helmet',
  'far fa-forklift',
  'far fa-forward',
  'far fa-fragile',
  'far fa-frog',
  'far fa-frown',
  'far fa-futbol',
  'far fa-gamepad',
  'far fa-gas-pump',
  'far fa-gavel',
  'far fa-gem',
  'far fa-genderless',
  'far fa-gift',
  'far fa-glass-martini',
  'far fa-glasses',
  'far fa-globe',
  'far fa-golf-ball',
  'far fa-golf-club',
  'far fa-graduation-cap',
  'far fa-greater-than',
  'far fa-greater-than-equal',
  'far fa-h-square',
  'far fa-h1',
  'far fa-h2',
  'far fa-h3',
  'far fa-hand-heart',
  'far fa-hand-holding',
  'far fa-hand-holding-box',
  'far fa-hand-holding-heart',
  'far fa-hand-holding-seedling',
  'far fa-hand-holding-usd',
  'far fa-hand-holding-water',
  'far fa-hand-lizard',
  'far fa-hand-paper',
  'far fa-hand-peace',
  'far fa-hand-point-down',
  'far fa-hand-point-left',
  'far fa-hand-point-right',
  'far fa-hand-point-up',
  'far fa-hand-pointer',
  'far fa-hand-receiving',
  'far fa-hand-rock',
  'far fa-hand-scissors',
  'far fa-hand-spock',
  'far fa-hands',
  'far fa-hands-heart',
  'far fa-hands-helping',
  'far fa-hands-usd',
  'far fa-handshake',
  'far fa-handshake-alt',
  'far fa-hashtag',
  'far fa-hdd',
  'far fa-heading',
  'far fa-headphones',
  'far fa-heart',
  'far fa-heart-circle',
  'far fa-heart-square',
  'far fa-heartbeat',
  'far fa-helicopter',
  'far fa-hexagon',
  'far fa-history',
  'far fa-hockey-puck',
  'far fa-hockey-sticks',
  'far fa-home',
  'far fa-home-heart',
  'far fa-hospital',
  'far fa-hospital-alt',
  'far fa-hospital-symbol',
  'far fa-hourglass',
  'far fa-hourglass-end',
  'far fa-hourglass-half',
  'far fa-hourglass-start',
  'far fa-i-cursor',
  'far fa-id-badge',
  'far fa-id-card',
  'far fa-id-card-alt',
  'far fa-image',
  'far fa-images',
  'far fa-inbox',
  'far fa-inbox-in',
  'far fa-inbox-out',
  'far fa-indent',
  'far fa-industry',
  'far fa-industry-alt',
  'far fa-infinity',
  'far fa-info',
  'far fa-info-circle',
  'far fa-info-square',
  'far fa-inventory',
  'far fa-italic',
  'far fa-jack-o-lantern',
  'far fa-key',
  'far fa-keyboard',
  'far fa-kiwi-bird',
  'far fa-lamp',
  'far fa-language',
  'far fa-laptop',
  'far fa-leaf',
  'far fa-leaf-heart',
  'far fa-lemon',
  'far fa-less-than',
  'far fa-less-than-equal',
  'far fa-level-down',
  'far fa-level-down-alt',
  'far fa-level-up',
  'far fa-level-up-alt',
  'far fa-life-ring',
  'far fa-lightbulb',
  'far fa-link',
  'far fa-lira-sign',
  'far fa-list',
  'far fa-list-alt',
  'far fa-list-ol',
  'far fa-list-ul',
  'far fa-location-arrow',
  'far fa-lock',
  'far fa-lock-alt',
  'far fa-lock-open',
  'far fa-lock-open-alt',
  'far fa-long-arrow-alt-down',
  'far fa-long-arrow-alt-left',
  'far fa-long-arrow-alt-right',
  'far fa-long-arrow-alt-up',
  'far fa-long-arrow-down',
  'far fa-long-arrow-left',
  'far fa-long-arrow-right',
  'far fa-long-arrow-up',
  'far fa-loveseat',
  'far fa-low-vision',
  'far fa-luchador',
  'far fa-magic',
  'far fa-magnet',
  'far fa-male',
  'far fa-map',
  'far fa-map-marker',
  'far fa-map-marker-alt',
  'far fa-map-pin',
  'far fa-map-signs',
  'far fa-mars',
  'far fa-mars-double',
  'far fa-mars-stroke',
  'far fa-mars-stroke-h',
  'far fa-mars-stroke-v',
  'far fa-medkit',
  'far fa-meh',
  'far fa-memory',
  'far fa-mercury',
  'far fa-microchip',
  'far fa-microphone',
  'far fa-microphone-alt',
  'far fa-microphone-alt-slash',
  'far fa-microphone-slash',
  'far fa-minus',
  'far fa-minus-circle',
  'far fa-minus-hexagon',
  'far fa-minus-octagon',
  'far fa-minus-square',
  'far fa-mobile',
  'far fa-mobile-alt',
  'far fa-mobile-android',
  'far fa-mobile-android-alt',
  'far fa-money-bill',
  'far fa-money-bill-alt',
  'far fa-money-bill-wave',
  'far fa-money-bill-wave-alt',
  'far fa-money-check',
  'far fa-money-check-alt',
  'far fa-moon',
  'far fa-motorcycle',
  'far fa-mouse-pointer',
  'far fa-music',
  'far fa-neuter',
  'far fa-newspaper',
  'far fa-not-equal',
  'far fa-notes-medical',
  'far fa-object-group',
  'far fa-object-unteam',
  'far fa-octagon',
  'far fa-outdent',
  'far fa-paint-brush',
  'far fa-palette',
  'far fa-pallet',
  'far fa-pallet-alt',
  'far fa-paper-plane',
  'far fa-paperclip',
  'far fa-parachute-box',
  'far fa-paragraph',
  'far fa-parking',
  'far fa-paste',
  'far fa-pause',
  'far fa-pause-circle',
  'far fa-paw',
  'far fa-pen',
  'far fa-pen-alt',
  'far fa-pen-square',
  'far fa-pencil',
  'far fa-pencil-alt',
  'far fa-pennant',
  'far fa-people-carry',
  'far fa-percent',
  'far fa-percentage',
  'far fa-person-carry',
  'far fa-person-dolly',
  'far fa-person-dolly-empty',
  'far fa-phone',
  'far fa-phone-plus',
  'far fa-phone-slash',
  'far fa-phone-square',
  'far fa-phone-volume',
  'far fa-piggy-bank',
  'far fa-pills',
  'far fa-plane',
  'far fa-plane-alt',
  'far fa-play',
  'far fa-play-circle',
  'far fa-plug',
  'far fa-plus',
  'far fa-plus-circle',
  'far fa-plus-hexagon',
  'far fa-plus-octagon',
  'far fa-plus-square',
  'far fa-podcast',
  'far fa-poo',
  'far fa-portrait',
  'far fa-pound-sign',
  'far fa-power-off',
  'far fa-prescription-bottle',
  'far fa-prescription-bottle-alt',
  'far fa-print',
  'far fa-procedures',
  'far fa-project-diagram',
  'far fa-puzzle-piece',
  'far fa-qrcode',
  'far fa-question',
  'far fa-question-circle',
  'far fa-question-square',
  'far fa-quidditch',
  'far fa-quote-left',
  'far fa-quote-right',
  'far fa-racquet',
  'far fa-ramp-loading',
  'far fa-random',
  'far fa-receipt',
  'far fa-rectangle-landscape',
  'far fa-rectangle-portrait',
  'far fa-rectangle-wide',
  'far fa-recycle',
  'far fa-redo',
  'far fa-redo-alt',
  'far fa-registered',
  'far fa-repeat',
  'far fa-repeat-1',
  'far fa-repeat-1-alt',
  'far fa-repeat-alt',
  'far fa-reply',
  'far fa-reply-all',
  'far fa-retweet',
  'far fa-retweet-alt',
  'far fa-ribbon',
  'far fa-road',
  'far fa-robot',
  'far fa-rocket',
  'far fa-route',
  'far fa-rss',
  'far fa-rss-square',
  'far fa-ruble-sign',
  'far fa-ruler',
  'far fa-ruler-combined',
  'far fa-ruler-horizontal',
  'far fa-ruler-vertical',
  'far fa-rupee-sign',
  'far fa-save',
  'far fa-scanner',
  'far fa-scanner-keyboard',
  'far fa-scanner-touchscreen',
  'far fa-school',
  'far fa-screwdriver',
  'far fa-scrubber',
  'far fa-search',
  'far fa-search-minus',
  'far fa-search-plus',
  'far fa-seedling',
  'far fa-server',
  'far fa-share',
  'far fa-share-all',
  'far fa-share-alt',
  'far fa-share-alt-square',
  'far fa-share-square',
  'far fa-shekel-sign',
  'far fa-shield',
  'far fa-shield-alt',
  'far fa-shield-check',
  'far fa-ship',
  'far fa-shipping-fast',
  'far fa-shipping-timed',
  'far fa-shoe-prints',
  'far fa-shopping-bag',
  'far fa-shopping-basket',
  'far fa-shopping-cart',
  'far fa-shower',
  'far fa-shuttlecock',
  'far fa-sign',
  'far fa-sign-in',
  'far fa-sign-in-alt',
  'far fa-sign-language',
  'far fa-sign-out',
  'far fa-sign-out-alt',
  'far fa-signal',
  'far fa-sitemap',
  'far fa-skull',
  'far fa-sliders-h',
  'far fa-sliders-h-square',
  'far fa-sliders-v',
  'far fa-sliders-v-square',
  'far fa-smile',
  'far fa-smile-plus',
  'far fa-smoking',
  'far fa-smoking-ban',
  'far fa-snowflake',
  'far fa-sort',
  'far fa-sort-alpha-down',
  'far fa-sort-alpha-up',
  'far fa-sort-amount-down',
  'far fa-sort-amount-up',
  'far fa-sort-down',
  'far fa-sort-numeric-down',
  'far fa-sort-numeric-up',
  'far fa-sort-up',
  'far fa-space-shuttle',
  'far fa-spade',
  'far fa-spinner',
  'far fa-spinner-third',
  'far fa-square',
  'far fa-square-full',
  'far fa-star',
  'far fa-star-exclamation',
  'far fa-star-half',
  'far fa-step-backward',
  'far fa-step-forward',
  'far fa-stethoscope',
  'far fa-sticky-note',
  'far fa-stop',
  'far fa-stop-circle',
  'far fa-stopwatch',
  'far fa-store',
  'far fa-store-alt',
  'far fa-stream',
  'far fa-street-view',
  'far fa-strikethrough',
  'far fa-stroopwafel',
  'far fa-subscript',
  'far fa-subway',
  'far fa-suitcase',
  'far fa-sun',
  'far fa-superscript',
  'far fa-sync',
  'far fa-sync-alt',
  'far fa-syringe',
  'far fa-table',
  'far fa-table-tennis',
  'far fa-tablet',
  'far fa-tablet-alt',
  'far fa-tablet-android',
  'far fa-tablet-android-alt',
  'far fa-tablet-rugged',
  'far fa-tablets',
  'far fa-tachometer',
  'far fa-tachometer-alt',
  'far fa-tag',
  'far fa-tags',
  'far fa-tape',
  'far fa-tasks',
  'far fa-taxi',
  'far fa-tennis-ball',
  'far fa-terminal',
  'far fa-text-height',
  'far fa-text-width',
  'far fa-th',
  'far fa-th-large',
  'far fa-th-list',
  'far fa-thermometer',
  'far fa-thermometer-empty',
  'far fa-thermometer-full',
  'far fa-thermometer-half',
  'far fa-thermometer-quarter',
  'far fa-thermometer-three-quarters',
  'far fa-thumbs-down',
  'far fa-thumbs-up',
  'far fa-thumbtack',
  'far fa-ticket',
  'far fa-ticket-alt',
  'far fa-times',
  'far fa-times-circle',
  'far fa-times-hexagon',
  'far fa-times-octagon',
  'far fa-times-square',
  'far fa-tint',
  'far fa-toggle-off',
  'far fa-toggle-on',
  'far fa-toolbox',
  'far fa-trademark',
  'far fa-train',
  'far fa-transgender',
  'far fa-transgender-alt',
  'far fa-trash',
  'far fa-trash-alt',
  'far fa-tree',
  'far fa-tree-alt',
  'far fa-triangle',
  'far fa-trophy',
  'far fa-trophy-alt',
  'far fa-truck',
  'far fa-truck-container',
  'far fa-truck-couch',
  'far fa-truck-loading',
  'far fa-truck-moving',
  'far fa-truck-ramp',
  'far fa-tshirt',
  'far fa-tty',
  'far fa-tv',
  'far fa-tv-retro',
  'far fa-umbrella',
  'far fa-underline',
  'far fa-undo',
  'far fa-undo-alt',
  'far fa-universal-access',
  'far fa-university',
  'far fa-unlink',
  'far fa-unlock',
  'far fa-unlock-alt',
  'far fa-upload',
  'far fa-usd-circle',
  'far fa-usd-square',
  'far fa-user',
  'far fa-user-alt',
  'far fa-user-alt-slash',
  'far fa-user-astronaut',
  'far fa-user-check',
  'far fa-user-circle',
  'far fa-user-clock',
  'far fa-user-cog',
  'far fa-user-edit',
  'far fa-user-friends',
  'far fa-user-graduate',
  'far fa-user-lock',
  'far fa-user-md',
  'far fa-user-minus',
  'far fa-user-ninja',
  'far fa-user-plus',
  'far fa-user-secret',
  'far fa-user-shield',
  'far fa-user-slash',
  'far fa-user-tag',
  'far fa-user-tie',
  'far fa-user-times',
  'far fa-users',
  'far fa-users-cog',
  'far fa-utensil-fork',
  'far fa-utensil-knife',
  'far fa-utensil-spoon',
  'far fa-utensils',
  'far fa-utensils-alt',
  'far fa-venus',
  'far fa-venus-double',
  'far fa-venus-mars',
  'far fa-vial',
  'far fa-vials',
  'far fa-video',
  'far fa-video-plus',
  'far fa-video-slash',
  'far fa-volleyball-ball',
  'far fa-volume-down',
  'far fa-volume-mute',
  'far fa-volume-off',
  'far fa-volume-up',
  'far fa-walking',
  'far fa-wallet',
  'far fa-warehouse',
  'far fa-warehouse-alt',
  'far fa-watch',
  'far fa-weight',
  'far fa-wheelchair',
  'far fa-whistle',
  'far fa-wifi',
  'far fa-window',
  'far fa-window-alt',
  'far fa-window-close',
  'far fa-window-maximize',
  'far fa-window-minimize',
  'far fa-window-restore',
  'far fa-wine-glass',
  'far fa-won-sign',
  'far fa-wrench',
  'far fa-x-ray',
  'far fa-yen-sign',
];

class IconDefinitions {
  static get ptIcons() {
    return ptIcons;
  }
  static get faIcons() {
    return faIcons;
  }
}

export default IconDefinitions;
