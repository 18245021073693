import React, { Component } from 'react';
import { Position, Tooltip, Card, Tag, Intent } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import Flexbox from 'flexbox-react';

export default class SuggestSelectedResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };
  }

  render() {
    const { image, icon, color, type, text, onClick, onRemove } = this.props;

    return (
      <Card
        interactive={true}
        className="suggest-card"
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox alignItems="center" flexGrow={1} onClick={onClick.bind(this)}>
            <Flexbox flexGrow={1}>
              {image ? (
                <Avatar src={image} size={20} round={true} className="push-10-r" />
              ) : (
                <Flexbox paddingTop="3px" className={`${icon} push-5-r`} style={{ color: color }}></Flexbox>
              )}
              {type ? (
                <Flexbox marginRight="5px">
                  <Tag intent={Intent.DEFAULT} round={true} minimal={true}>
                    {type}
                  </Tag>
                </Flexbox>
              ) : null}
              <Tooltip content={I18n.t('js.show_details')} position={Position.TOP} portalContainer={document.body}>
                <span className="font-s-16">{text}</span>
              </Tooltip>
            </Flexbox>
          </Flexbox>
          {this.state.isHovering ? (
            <Flexbox flexGrow={0}>
              <Tooltip content={I18n.t('js.remove')} position={Position.RIGHT} portalContainer={document.body}>
                <span className="bp3-icon bp3-icon-cross bp3-danger push-10-r" onClick={onRemove.bind(this)} />
              </Tooltip>
            </Flexbox>
          ) : null}
        </Flexbox>
      </Card>
    );
  }
}
