import React from 'react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Tag, Tooltip, Intent, Position, Button, Icon } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class ItemWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isHovering: false, shouldBeRemoved: false };
  }

  handleMarkAsComplete() {
    this.props.ItemActions.markItemAsComplete(this.props.item.id).then(response => {
      this.props.DashboardStore.addItem(response.data);
      this.props.ToastStore.showToast(I18n.t('js.item_updated'), 'success');
      if (this.props.DashboardStore.showDone === false) {
        this.setState({ shouldBeRemoved: true });
        const timeoutDelay = 500; // ms
        setTimeout(() => {
          this.props.DashboardStore.removeItem(response.data);
        }, timeoutDelay);
      }
    });
  }

  renderMarkAsDoneIcon() {
    if (this.props.item.isDoneForCurrentUser) {
      return <Icon icon="cross" intent={Intent.DANGER} />;
    } else {
      return <Icon icon="tick" intent={Intent.SUCCESS} />;
    }
  }

  render() {
    const { item } = this.props;
    let bgStyle = {
      background: `url('${this.props.item.imageFileName}')`,
      backgroundSize: 'cover',
      height: '30px',
      width: '30px',
      borderRadius: '30px',
    };
    let iconClass = classNames('display-none');
    let parentClass = classNames('position-relative');
    const darkenAmount = 0.25; // %
    if (this.props.item.imageFileName === '') {
      bgStyle = {
        background: `linear-gradient(${this.props.item.itemColor}, ${Utilities.darkenHex(item.itemColor, darkenAmount)})`,
        height: '30px',
        width: '30px',
        borderRadius: '30px',
      };
      iconClass = classNames('fa text-white', this.props.item.itemIcon);
    }
    if (this.state.shouldBeRemoved) {
      parentClass = classNames('position-relative animated bounceOutLeft');
    }

    return (
      <tr className={`${parentClass} item-table-row`} style={{ fontSize: '13px' }}>
        <td onClick={() => Utilities.navigate(`/items/${this.props.item.id}`)}>
          <Flexbox flexDirection="row" alignItems="center">
            <Flexbox>
              <Flexbox flexDirection="column">
                <h4 className="push-10-l bp3-label-no-margin">{_.truncate(item.name, { length: 60 })}</h4>
                <small className="bp3-text-muted">{item.state.name}</small>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </td>
        <td onClick={() => Utilities.navigate(`/items/${this.props.item.id}`)}>
          <Flexbox flexDirection="row" alignItems="center" paddingTop="5px">
            <Flexbox style={bgStyle} flexDirection="column" justifyContent="center" alignItems="center">
              <Flexbox className={iconClass} />
            </Flexbox>
            <Flexbox marginLeft="10px">{item.itemType}</Flexbox>
          </Flexbox>
        </td>
        <td onClick={() => Utilities.navigate(`/items/${this.props.item.id}`)}>
          <Flexbox flexDirection="row" alignItems="center">
            <Flexbox paddingTop="10px">
              <Tag
                className="bp3-cursor-pointer item-widget-tag"
                style={{
                  backgroundColor: this.props.item.statusFlag.color,
                  color: '#fff',
                }}
              >
                {this.props.item.statusFlag.name}
              </Tag>
            </Flexbox>
          </Flexbox>
        </td>
        <td onClick={() => Utilities.navigate(`/items/${this.props.item.id}`)}>
          <Flexbox flexDirection="row" alignItems="center" paddingTop="5px">
            <Flexbox paddingTop="6px">{item.updatedAtToDate}</Flexbox>
          </Flexbox>
        </td>
        <td>
          <Flexbox flexDirection="row" paddingTop="5px">
            <Button
              outlined={false}
              intent={Intent.PRIMARY}
              text={I18n.t('js.open')}
              className="push-10-r"
              onClick={() => Utilities.navigate(`/items/${this.props.item.id}`)}
            />
            <Button
              outlined={false}
              text={this.props.item.isDoneForCurrentUser ? I18n.t('js.mark_as_not_done') : I18n.t('js.mark_as_done')}
              className="push-10-r"
              icon={this.renderMarkAsDoneIcon()}
              onClick={this.handleMarkAsComplete.bind(this)}
            />
          </Flexbox>
        </td>
      </tr>
    );
  }
}
