/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import moment from 'moment';

export default class StateConditionValue {
  @observable
  id;
  @observable
  stateConditionId;
  @observable
  valueType;
  @observable
  stringValue;
  @observable
  numberValue;
  @observable
  decimalValue;
  @observable
  booleanValue;
  @observable
  dateValue;
  @observable
  userId;
  @observable
  teamId;
  @observable
  itemId;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;
  @observable
  changeType;

  constructor(
    id,
    stateConditionId,
    valueType,
    stringValue,
    numberValue,
    decimalValue,
    booleanValue,
    dateValue,
    userId,
    teamId,
    itemId,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy,
    changeType
  ) {
    this.id = id || Utilities.makeId();
    this.stateConditionId = stateConditionId || '';
    this.valueType = valueType || '';
    this.stringValue = stringValue || '';
    this.numberValue = numberValue || 0;
    this.decimalValue = decimalValue || 0;
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof booleanValue === 'undefined' ? (this.booleanValue = false) : (this.booleanValue = booleanValue);
    this.dateValue = dateValue || '';
    this.userId = userId || '';
    this.teamId = teamId || '';
    this.itemId = itemId || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.changeType = changeType || 'original';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
