/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import ToastStore from '../../../stores/ToastStore';
import Cookies from 'js-cookie';
import { inject, observer } from 'mobx-react';
import Item from '../../../models/ItemModel';
import { Classes, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect, Suggest } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

axiosCancel(axios);

const ItemMultiSelect = MultiSelect.ofType(Item);
const ItemSuggest = Suggest.ofType(Item);

@inject('ToastStore')
@observer
export default class ElementObjectSelectDisplayItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      searchItems: [],
      selectedItems: [],
      selectedItem: '',
      isLoading: false,
    };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addItem(data) {
    const newItem = new Item(
      data.id,
      data.app_id,
      data.state_id,
      data.user_id,
      data.status_flag_id,
      data.name,
      data.item_type,
      data.item_icon,
      data.item_color,
      data.item_layout,
      data.image_file_name,
      data.comment_count,
      data.is_done_for_current_user,
      data.is_webform,
      data.import_id,
      data.webform_user_email,
      data.webform_user_full_name,
      data.webform_user_image_file_name,
      data.dataview,
      data.simple_dataview,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.setState({
      searchItems: _.unionBy([newItem], this.state.searchItems, 'id'),
    });
  }

  searchItems(query) {
    this.setState({ searchItems: [], query });
    if (query.length > 1) {
      this.setState({ isLoading: true });
      axios
        .get(`${Cookies.get('apiEnv')}/items?query=${query}`, {
          requestId: 'itemSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, item => {
            this.addItem(item);
            total--;
          });
          if (total === 0) {
            this.setState({ isLoading: false });
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.setState({ isLoading: false });
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            this.setState({ isLoading: false });
          }
        });
    }
  }

  renderItemLabel(item) {
    const iconClass = classNames('push-5-r fa', item.itemIcon);
    return (
      <span>
        <span className={iconClass} />
        {_.capitalize(item.itemType)}
      </span>
    );
  }

  renderItem(item, details) {
    let menuClass = classNames('');
    if (details.modifiers.active) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, '');
    }
    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={_.truncate(item.name, { length: 50 })}
        label={this.renderItemLabel(item, details)}
      />
    );
  }

  renderResultSearch() {
    if (this.state.query.length === 0) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.start_typing')} </span>
            </span>
          }
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <MenuItem
          disabled
          text={
            <span>
              {' '}
              <span className="bp3-icon-search push-5-r" /> <span> {I18n.t('js.fetching_data')} </span>
            </span>
          }
        />
      );
    }
    return (
      <MenuItem
        disabled
        text={
          <span>
            {' '}
            <span className="bp3-icon-new-object push-5-r" /> <span> {I18n.t('js.no_items_found')} </span>
          </span>
        }
      />
    );
  }

  renderTag(item) {
    return <span id={item.id}>{_.truncate(item.name, { length: 50 })}</span>;
  }

  renderInputValue(item) {
    return item.name;
  }

  handleItemsSelect(item) {
    this.setState({
      selectedItems: _.concat(this.state.selectedItems.slice(), [item]),
    });
    this.handlePopoverClose();
  }

  handleItemSelect(item) {
    this.setState({ selectedItem: item });
    this.handlePopoverClose();
  }

  handlePopoverClose() {
    this.setState({ searchItems: [], query: '' });
  }

  handleItemRemove(item) {
    const itemId = item.props.id;
    const newItems = _.filter(this.state.selectedItems.slice(), o => o.id !== itemId);
    this.setState({ selectedItems: newItems });
  }

  filterItem() {
    let possibleItems = this.state.searchItems;
    // filter out items that do not belong to the approved list of apps
    _.map(possibleItems, item => {
      const findItemApp = _.find(
        this.props.element.elementObjectSelect.elementObjectSelectItemApps,
        itemApp => itemApp.appId === item.appId
      );
      if (typeof findItemApp !== 'object') {
        possibleItems = _.filter(possibleItems, o => o.id !== item.id);
      }
    });
    // filter out selected items
    if (this.props.element.elementObjectSelect.optionType === 'multiple') {
      _.map(this.state.selectedItems, item => {
        possibleItems = _.filter(possibleItems, o => o.id !== item.id);
      });
    }
    possibleItems = _.orderBy(possibleItems, ['name'], ['asc']);
    return possibleItems;
  }

  renderRightElement() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    return undefined;
  }

  render() {
    if (this.props.element.elementObjectSelect.optionType === 'multiple') {
      return (
        <ItemMultiSelect
          className="max-width"
          resetOnSelect={true}
          items={this.state.searchItems}
          itemListPredicate={this.filterItem.bind(this)}
          selectedItems={this.state.selectedItems}
          tagRenderer={this.renderTag.bind(this)}
          itemRenderer={this.renderItem.bind(this)}
          onItemSelect={this.handleItemsSelect.bind(this)}
          tagInputProps={{
            disabled: this.props.disabled,
            inputProps: {
              disabled: this.props.disabled,
              placeholder: this.props.element.elementObjectSelect.placeholderText,
            },
            rightElement: this.renderRightElement(),
            leftIcon: 'search',
            className: 'bp3-tag-input',
            onRemove: this.handleItemRemove.bind(this),
          }}
          query={this.state.query}
          onQueryChange={this.searchItems.bind(this)}
          popoverProps={{
            usePortal: false,
            disabled: this.props.disabled,
            position: 'bottom-left',
            className: '',
            popoverClassName: 'bp3-minimal',
            onClose: this.handlePopoverClose.bind(this),
          }}
          noResults={this.renderResultSearch()}
        />
      );
    }
    return (
      <ItemSuggest
        className="max-width"
        resetOnSelect={true}
        items={this.state.searchItems}
        itemListPredicate={this.filterItem.bind(this)}
        inputValueRenderer={this.renderInputValue.bind(this)}
        itemRenderer={this.renderItem.bind(this)}
        onItemSelect={this.handleItemSelect.bind(this)}
        inputProps={{
          disabled: this.props.disabled,
          leftIcon: 'search',
          placeholder: this.props.element.elementObjectSelect.placeholderText,
        }}
        query={this.state.query}
        onQueryChange={this.searchItems.bind(this)}
        popoverProps={{
          position: 'bottom-left',
          className: '',
          popoverClassName: 'bp3-minimal',
          onClose: this.handlePopoverClose.bind(this),
          disabled: this.props.disabled,
        }}
        validateInput={() => {
          null;
        }}
        noResults={this.renderResultSearch()}
      />
    );
  }
}
