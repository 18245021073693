import React from 'react';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';
import Avatar from 'react-avatar';
import classNames from 'classnames';

export default class UserAvatar extends React.Component {
  handleClick() {
    if (!this.props.noLink) {
      Utilities.navigate(this.props.path, true);
    }
  }

  render() {
    let paragraphClass = classNames('');
    if (this.props.textSize === 'larger') {
      paragraphClass = classNames('bp3-running-text');
    }
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={0}
        className={this.props.noLink ? '' : 'bp3-cursor-pointer'}
        onClick={this.handleClick.bind(this)}
      >
        <Flexbox flexDirection="row" alignItems="center">
          <Avatar
            src={this.props.user.imageFileName}
            size={this.props.avatarSize}
            round={true}
            className="push-5-r"
          />
        </Flexbox>
        <Flexbox flexDirection="row" alignItems="center">
          <span className={paragraphClass}>
            {this.props.user.fullName}
          </span>
        </Flexbox>
      </Flexbox>
    );
  }
}
