import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  Classes,
  Tag,
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';

@inject(
  'AdminActions',
  'AdminStore',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class AdminAccountDomainRow extends React.Component {
  @observable
  isDeleting = false;

  toggleDeleting() {
    this.isDeleting = !this.isDeleting;
  }

  removeAccountDomain() {
    this.props.AdminActions.deleteAccountDomain(
      this.props.accountDomain.id
    )
      .then(response => {
        this.props.AdminStore.removeAccountDomain(response.data.id);
        this.toggleDeleting();
        this.props.ToastStore.showToast(
          'Account domain deleted',
          'success'
        );
      })
      .catch(() => {
        this.toggleDeleting();
      });
  }

  renderAlert() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText="Delete account domain"
        onConfirm={this.removeAccountDomain.bind(this)}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        Are you sure you wish to delete this account domain?
      </Alert>
    );
  }

  render() {
    return (
      <tr>
        <td>{this.props.accountDomain.domain}</td>
        <td>
          <Tag
            intent={
              this.props.accountDomain.version === 'All'
                ? Intent.PRIMARY
                : Intent.DEFAULT
            }
          >
            {this.props.accountDomain.version}
          </Tag>
        </td>
        <td>
          <Button
            className="bp3-small"
            text="Edit"
            onClick={() =>
              this.props.AdminStore.setActiveItem(
                this.props.accountDomain,
                'accountDomain'
              )
            }
          />
          <Button
            className="bp3-small push-5-l"
            text="Delete"
            onClick={this.toggleDeleting.bind(this)}
          />
          {this.renderAlert()}
        </td>
      </tr>
    );
  }
}
