import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { InputGroup, FormGroup } from '@blueprintjs/core';
import _ from 'lodash';

@inject('CurrentUserStore', 'ToastStore', 'AppStore', 'ItemStore', 'ItemActions')
@observer
export default class ItemTitleInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  handleSubmit() {}

  handleInputChange(e) {
    this.props.ItemStore.updateItem(e.target.value, 'name');
  }

  handleCancel() {
    this.props.ItemStore.resetItemAttribute('name');
    this.toggleOpen();
  }

  handleBlurChange(e) {
    this.setState({ isBlurred: [] });
    const newObj = {
      id: this.props.ItemStore.newItem.id,
      name: this.props.ItemStore.newItem.name,
    };
    this.props.ItemActions.changeItem(newObj)
      .then(response => {
        this.props.ItemStore.addItem(response.data);
      })
      .catch(() => {});
  }

  renderErrors(field) {
    if (_.includes(this.state.isBlurred, field) && !this.props.ItemStore.newItem[`${field}Valid`]) {
      return <div className="bp3-form-helper-text">{this.props.ItemStore.newItem[`${field}Error`]}</div>;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup label={I18n.t('js.item_name_type', { item: this.props.AppStore.activeApp.item })}>
          <InputGroup
            onChange={this.handleInputChange.bind(this)}
            onBlur={this.handleBlurChange.bind(this)}
            autoFocus={true}
            value={this.props.ItemStore.newItem.name}
            placeholder={I18n.t('js.name')}
          />
        </FormGroup>
      </Flexbox>
    );
  }
}
