import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import RelationshipPermission from '../models/RelationshipPermissionModel';
import TeamPermission from '../models/TeamPermissionModel';
import UserPermission from '../models/UserPermissionModel';
import User from '../models/UserModel';
import Team from '../models/TeamModel';
import Relationship from '../models/RelationshipModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import axios from 'axios';
import _ from 'lodash';

class PermissionStore {
  @observable
  relationshipPermissions = [];
  @observable
  teamPermissions = [];
  @observable
  userPermissions = [];
  @observable
  baselineRelationshipPermissions = [];
  @observable
  baselineTeamPermissions = [];
  @observable
  baselineUserPermissions = [];
  @observable
  activeRelationshipPermission = new RelationshipPermission();
  @observable
  newRelationshipPermission = new RelationshipPermission();
  @observable
  activeTeamPermission = new TeamPermission();
  @observable
  newTeamPermission = new TeamPermission();
  @observable
  activeUserPermission = new UserPermission();
  @observable
  newUserPermission = new UserPermission();
  @observable
  relationshipPermissionColumns = ColumnDefinitions.relationshipPermissionColumns;
  @observable
  userPermissionColumns = ColumnDefinitions.userPermissionColumns;
  @observable
  teamPermissionColumns = ColumnDefinitions.teamPermissionColumns;
  @observable
  participants = [];

  @computed
  get onlyOneUserOwnerLeft() {
    let result = false;
    const filteredUserPermissions = _.filter(this.userPermissions, userPermission => userPermission.permissionType === 'owner');
    if (filteredUserPermissions.length <= 1) {
      result = true;
    }
    return result;
  }

  @computed
  get totalPermissionsToPersist() {
    const totalLength =
      this.userPermissionsToAdd.length +
      this.userPermissionsToChange.length +
      this.userPermissionsToDelete.length +
      this.teamPermissionsToAdd.length +
      this.teamPermissionsToChange.length +
      this.teamPermissionsToDelete.length +
      this.relationshipPermissionsToAdd.length +
      this.relationshipPermissionsToChange.length +
      this.relationshipPermissionsToDelete.length;
    return totalLength;
  }

  @computed
  get userPermissionsToAdd() {
    let userPermissionsToAdd = [];
    _.map(this.userPermissions, userPermission => {
      const findPermission = _.find(this.baselineUserPermissions, o => o.id === userPermission.id);
      if (!findPermission) {
        userPermissionsToAdd = _.unionBy([userPermission], userPermissionsToAdd, 'id');
      }
    });
    return userPermissionsToAdd;
  }

  @computed
  get userPermissionsToChange() {
    let userPermissionsToChange = [];
    _.map(this.userPermissions, userPermission => {
      const findPermission = _.find(this.baselineUserPermissions, o => o.id === userPermission.id);
      if (findPermission) {
        if (findPermission.permissionType.toLowerCase() !== userPermission.permissionType.toLowerCase()) {
          userPermissionsToChange = _.unionBy([userPermission], userPermissionsToChange, 'id');
        }
      }
    });
    return userPermissionsToChange;
  }

  @computed
  get userPermissionsToDelete() {
    let userPermissionsToDelete = [];
    _.map(this.baselineUserPermissions, userPermission => {
      const findPermission = _.find(this.userPermissions, o => o.id === userPermission.id);
      if (!findPermission) {
        userPermissionsToDelete = _.unionBy([userPermission], userPermissionsToDelete, 'id');
      }
    });
    return userPermissionsToDelete;
  }

  @computed
  get teamPermissionsToAdd() {
    let teamPermissionsToAdd = [];
    _.map(this.teamPermissions, teamPermission => {
      const findPermission = _.find(this.baselineTeamPermissions, o => o.id === teamPermission.id);
      if (!findPermission) {
        teamPermissionsToAdd = _.unionBy([teamPermission], teamPermissionsToAdd, 'id');
      }
    });
    return teamPermissionsToAdd;
  }

  @computed
  get teamPermissionsToChange() {
    let teamPermissionsToChange = [];
    _.map(this.teamPermissions, teamPermission => {
      const findPermission = _.find(this.baselineTeamPermissions, o => o.id === teamPermission.id);
      if (findPermission) {
        if (findPermission.permissionType.toLowerCase() !== teamPermission.permissionType.toLowerCase()) {
          teamPermissionsToChange = _.unionBy([teamPermission], teamPermissionsToChange, 'id');
        }
      }
    });
    return teamPermissionsToChange;
  }

  @computed
  get teamPermissionsToDelete() {
    let teamPermissionsToDelete = [];
    _.map(this.baselineTeamPermissions, teamPermission => {
      const findPermission = _.find(this.teamPermissions, o => o.id === teamPermission.id);
      if (!findPermission) {
        teamPermissionsToDelete = _.unionBy([teamPermission], teamPermissionsToDelete, 'id');
      }
    });
    return teamPermissionsToDelete;
  }

  @computed
  get relationshipPermissionsToAdd() {
    let relationshipPermissionsToAdd = [];
    _.map(this.relationshipPermissions, relationshipPermission => {
      const findPermission = _.find(this.baselineRelationshipPermissions, o => o.id === relationshipPermission.id);
      if (!findPermission) {
        relationshipPermissionsToAdd = _.unionBy([relationshipPermission], relationshipPermissionsToAdd, 'id');
      }
    });
    return relationshipPermissionsToAdd;
  }

  @computed
  get relationshipPermissionsToChange() {
    let relationshipPermissionsToChange = [];
    _.map(this.relationshipPermissions, relationshipPermission => {
      const findPermission = _.find(this.baselineRelationshipPermissions, o => o.id === relationshipPermission.id);
      if (findPermission) {
        if (findPermission.participantId !== relationshipPermission.participantId) {
          relationshipPermissionsToChange = _.unionBy([relationshipPermission], relationshipPermissionsToChange, 'id');
        }
      }
    });
    return relationshipPermissionsToChange;
  }

  @computed
  get relationshipPermissionsToDelete() {
    let relationshipPermissionsToDelete = [];
    _.map(this.baselineRelationshipPermissions, relationshipPermission => {
      const findPermission = _.find(this.relationshipPermissions, o => o.id === relationshipPermission.id);
      if (!findPermission) {
        relationshipPermissionsToDelete = _.unionBy([relationshipPermission], relationshipPermissionsToDelete, 'id');
      }
    });
    return relationshipPermissionsToDelete;
  }

  @action
  changeRelationshipPermission(relationshipPermission) {
    this.relationshipPermissions = _.unionBy([relationshipPermission], this.relationshipPermissions, 'id');
    this.sortRelationshipPermissions();
  }

  @action
  addRelationshipPermission(data, type) {
    const newRelationshipPermission = new RelationshipPermission(
      data.id,
      data.relationship_id,
      data.permission_model,
      data.permission_type,
      data.workspace_id,
      data.app_id,
      data.participant_id,
      data.item_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    newRelationshipPermission.relationship = new Relationship(
      data.relationship.id,
      data.relationship.account_id,
      data.relationship.name,
      data.relationship.counterpart_name,
      data.relationship.description,
      data.relationship.default,
      data.relationship.lock_user_changes,
      data.relationship.lock_counterpart_user_changes,
      data.relationship.created_at,
      data.relationship.created_by,
      data.relationship.updated_at,
      data.relationship.updated_by,
      data.relationship.deleted_at,
      data.relationship.deleted_by
    );

    if (type === 'single') {
      this.activeRelationshipPermission = _.clone(newRelationshipPermission);
      this.newRelationshipPermission = _.clone(newRelationshipPermission);
    } else {
      this.baselineRelationshipPermissions = _.unionBy([_.clone(newRelationshipPermission)], this.baselineRelationshipPermissions, 'id');
      this.relationshipPermissions = _.unionBy([_.clone(newRelationshipPermission)], this.relationshipPermissions, 'id');
      this.sortRelationshipPermissions();
    }
  }

  @action
  removeRelationshipPermission(id) {
    this.relationshipPermissions = _.filter(this.relationshipPermissions, o => o.id !== id);
  }

  @action
  addTeamPermission(data, type) {
    const newTeamPermission = new TeamPermission(
      data.id,
      data.team_id,
      data.permission_model,
      data.permission_type,
      data.workspace_id,
      data.app_id,
      data.item_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    newTeamPermission.team = new Team(
      data.team.id,
      data.team.account_id,
      data.team.is_default_account,
      data.team.name,
      data.team.description,
      data.team.image_file_name,
      data.team.background_image_file_name,
      data.team.private,
      data.team.is_current_user_team_owner,
      data.team.is_current_user_team_member,
      data.team.row_order,
      data.team.user_count,
      data.team.comment_count,
      data.team.created_at,
      data.team.created_by,
      data.team.deleted_at,
      data.team.deleted_by,
      data.team.updated_at,
      data.team.updated_by
    );

    if (type === 'single') {
      this.activeTeamPermission = _.clone(newTeamPermission);
      this.newTeamPermission = _.clone(newTeamPermission);
    } else {
      this.baselineTeamPermissions = _.unionBy([_.clone(newTeamPermission)], this.baselineTeamPermissions, 'id');
      this.teamPermissions = _.unionBy([_.clone(newTeamPermission)], this.teamPermissions, 'id');
      this.sortTeamPermissions();
    }
  }

  @action
  removeTeamPermission(id) {
    this.teamPermissions = _.filter(this.teamPermissions, o => o.id !== id);
  }

  @action
  addUserPermission(data, type) {
    const newUserPermission = new UserPermission(
      data.id,
      data.user_id,
      data.permission_model,
      data.permission_type,
      data.workspace_id,
      data.app_id,
      data.item_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    newUserPermission.user = new User(
      data.user.id,
      data.user.email,
      data.user.first_name,
      data.user.last_name,
      data.user.full_name,
      data.user.account_id,
      data.user.human_friendly_id,
      data.user.is_current_user,
      data.user.is_account_owner,
      data.user.image_file_name,
      data.user.background_image_file_name,
      data.user.last_sign_in_at,
      data.user.locale,
      data.user.theme,
      data.user.created_at,
      data.user.updated_at,
      data.user.deleted_at,
      data.user.created_by,
      data.user.updated_by,
      data.user.deleted_by
    );

    if (type === 'single') {
      this.activeUserPermission = _.clone(newUserPermission);
      this.newUserPermission = _.clone(newUserPermission);
    } else {
      this.baselineUserPermissions = _.unionBy([_.clone(newUserPermission)], this.baselineUserPermissions, 'id');
      this.userPermissions = _.unionBy([_.clone(newUserPermission)], this.userPermissions, 'id');
      this.sortUserPermissions();
    }
  }

  @action
  removeUserPermission(id) {
    this.userPermissions = _.filter(this.userPermissions, o => o.id !== id);
  }

  @action
  fetchPermissions(id, type) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/relationship_permissions?${type}_id=${id}`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, relationshipPermission => {
            this.addRelationshipPermission(relationshipPermission, 'all');
            total--;
          });
          if (total === 0) {
            axios
              .get(`${Cookies.get('apiEnv')}/team_permissions?${type}_id=${id}`)
              .then(response => {
                let total = response.data.length;
                _.map(response.data, teamPermission => {
                  this.addTeamPermission(teamPermission, 'all');
                  total--;
                });
                if (total === 0) {
                  axios
                    .get(`${Cookies.get('apiEnv')}/user_permissions?${type}_id=${id}`)
                    .then(res => {
                      // lazy fix
                      // eslint-disable-next-line no-shadow
                      let total = res.data.length;
                      _.map(res.data, userPermission => {
                        this.addUserPermission(userPermission, 'all');
                        total--;
                      });
                      if (total === 0) {
                        resolve(response);
                      }
                    })
                    .catch(error => {
                      reject(error);
                      const errors = error.response.data.error.join(', ');
                      ToastStore.showToast(errors, 'danger');
                    });
                }
              })
              .catch(error => {
                reject(error);
                const errors = error.response.data.error.join(', ');
                ToastStore.showToast(errors, 'danger');
              });
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createPermission(permissionObj, type) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/${type}_permissions`, permissionObj)
        .then(response => {
          if (type === 'user') {
            this.addUserPermission(response.data, 'single');
            resolve();
          }
          if (type === 'team') {
            this.addTeamPermission(response.data, 'single');
            resolve();
          }
          if (type === 'relationship') {
            this.addRelationshipPermission(response.data, 'single');
            resolve();
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changePermission(permissionObj, type) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/${type}_permissions/${permissionObj.id}`, permissionObj)
        .then(response => {
          if (type === 'user') {
            this.addUserPermission(response.data, 'single');
            resolve();
          }
          if (type === 'team') {
            this.addTeamPermission(response.data, 'single');
            resolve();
          }
          if (type === 'relationship') {
            this.addRelationshipPermission(response.data, 'single');
            resolve();
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deletePermission(permissionId, type) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/${type}_permissions/${permissionId}`)
        .then(response => {
          if (type === 'user') {
            this.removeUserPermission(response.data.id);
            resolve();
          }
          if (type === 'team') {
            this.removeTeamPermission(response.data.id);
            resolve();
          }
          if (type === 'relationship') {
            this.removeRelationshipPermission(response.data.id);
            resolve();
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  setRelationshipPermissionSortParams(param) {
    const newRelationshipPermissionColumns = this.relationshipPermissionColumns;
    _.map(newRelationshipPermissionColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.relationshipPermissionColumns = newRelationshipPermissionColumns;
    this.sortRelationshipPermissions();
  }

  @action
  sortRelationshipPermissions() {
    let newRelationshipPermissions = this.relationshipPermissions;
    const activeColumn = _.find(this.relationshipPermissionColumns, o => o.active);
    if (activeColumn.id === 'name') {
      newRelationshipPermissions = _.orderBy(newRelationshipPermissions, o => o.relationship.name, [activeColumn.sortDir]);
    } else {
      newRelationshipPermissions = _.orderBy(newRelationshipPermissions, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.relationshipPermissions = newRelationshipPermissions;
  }

  @action
  setTeamPermissionSortParams(param) {
    const newTeamPermissionColumns = this.teamPermissionColumns;
    _.map(newTeamPermissionColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.teamPermissionColumns = newTeamPermissionColumns;
    this.sortTeamPermissions();
  }

  @action
  sortTeamPermissions() {
    let newTeamPermissions = this.teamPermissions;
    const activeColumn = _.find(this.teamPermissionColumns, o => o.active);
    if (activeColumn.id === 'name') {
      newTeamPermissions = _.orderBy(newTeamPermissions, o => o.team.name, [activeColumn.sortDir]);
    } else {
      newTeamPermissions = _.orderBy(newTeamPermissions, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.teamPermissions = newTeamPermissions;
  }

  @action
  setUserPermissionSortParams(param) {
    const newUserPermissionColumns = this.userPermissionColumns;
    _.map(newUserPermissionColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.userPermissionColumns = newUserPermissionColumns;
    this.sortUserPermissions();
  }

  @action
  sortUserPermissions() {
    let newUserPermissions = this.userPermissions;
    const activeColumn = _.find(this.userPermissionColumns, o => o.active);
    if (activeColumn.id === 'fullName') {
      newUserPermissions = _.orderBy(newUserPermissions, o => o.user.fullName, [activeColumn.sortDir]);
    } else {
      newUserPermissions = _.orderBy(newUserPermissions, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.userPermissions = newUserPermissions;
  }

  @action
  resetStartingAttributes() {
    this.relationshipPermissions = [];
    this.teamPermissions = [];
    this.userPermissions = [];
    this.baselineRelationshipPermissions = [];
    this.baselineTeamPermissions = [];
    this.baselineUserPermissions = [];
    this.activeRelationshipPermission = new RelationshipPermission();
    this.activeTeamPermission = new TeamPermission();
    this.activeUserPermission = new UserPermission();
    this.newRelationshipPermission = new RelationshipPermission();
    this.newTeamPermission = new TeamPermission();
    this.newUserPermission = new UserPermission();
    this.relationshipPermissionColumns = ColumnDefinitions.relationshipPermissionColumns;
    this.userPermissionColumns = ColumnDefinitions.userPermissionColumns;
    this.teamPermissionColumns = ColumnDefinitions.teamPermissionColumns;
    this.participants = [];
  }
}

const store = new PermissionStore();
export default store;
