/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

class WidgetStore {
  @observable widgets = [];
  @observable activeWidget = null;
  @observable activeEvent = null;

  // Client

  @action
  setActiveWidget(widget) {
    this.activeWidget = _.cloneDeep(widget);
  }

  @action
  setActiveEvent(event) {
    this.activeEvent = _.cloneDeep(event);
  }

  @action
  addWidget(data) {
    this.widgets = _.unionBy([data], this.widgets, 'id');
    this.sortWidgets();
  }

  @action
  removeWidget(id) {
    this.widgets = _.filter(this.widgets, o => o.id !== id);
    this.sortWidgets();
  }

  @action
  updateActiveWidgetAttribute(value, attribute) {
    this.activeWidget[attribute] = value;
  }

  @action
  updateActiveEvent(value, attribute) {
    this.activeEvent[attribute] = value;
  }

  @action addEvent(data) {
    let events = _.clone(this.activeWidget.scheduled_events);
    let widget = _.clone(this.activeWidget);
    events = _.unionBy([data], events, 'id');
    events = _.orderBy(events, ['created_at'], ['asc']);
    widget.scheduled_events = events;
    this.activeWidget = widget;
  }

  @action removeEvent(id) {
    let events = _.clone(this.activeWidget.scheduled_events);
    let widget = _.clone(this.activeWidget);
    events = _.filter(events, o => o.id !== id);
    events = _.orderBy(events, ['created_at'], ['asc']);
    widget.scheduled_events = events;
    this.activeWidget = widget;
  }

  // Server

  @action
  createWidget(widgetObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/widgets`, widgetObj)
        .then(response => {
          this.addWidget(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  notifyAllUsers(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/widgets/${id}/notify`)
        .then(response => {
          resolve(response);
          ToastStore.showToast(I18n.t('js.all_users_have_been_notified'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeWidget(widgetObj) {
    let id = '';
    if (widgetObj instanceof FormData) {
      id = widgetObj.get('id');
    } else {
      id = widgetObj.id;
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/widgets/${id}`, widgetObj)
        .then(response => {
          this.addWidget(response.data);
          resolve(response);
          if (widgetObj instanceof FormData) {
            ToastStore.showToast(I18n.t('js.widget_has_been_updated'), 'success');
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteWidget(widgetId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/widgets/${widgetId}`)
        .then(response => {
          this.removeWidget(response.data.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchWidgets(widgetableId, widgetableType) {
    if (widgetableId.length > 0 && widgetableType.length > 0) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/widgets?widgetable_id=${widgetableId}&widgetable_type=${widgetableType}`)
          .then(response => {
            _.map(response.data, widget => {
              this.addWidget(widget);
            });
            resolve(response);
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          });
      });
    }
  }

  @action
  createScheduledEvent(eventObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/scheduled_events`, eventObj)
        .then(response => {
          this.addEvent(response.data);
          resolve(response);
          ToastStore.showToast(I18n.t('js.event_has_been_created'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeScheduledEvent(eventObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/scheduled_events/${eventObj.id}`, eventObj)
        .then(response => {
          this.addEvent(response.data);
          resolve(response);
          ToastStore.showToast(I18n.t('js.event_has_been_updated'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteScheduledEvent(eventId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/scheduled_events/${eventId}`)
        .then(response => {
          this.removeEvent(response.data.id);
          resolve(response);
          ToastStore.showToast(I18n.t('js.event_has_been_deleted'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  // Utility

  @action
  sortWidgets() {
    this.widgets = _.orderBy(this.widgets, ['row_order'], ['asc']);
  }

  @action
  resetStartingAttributes() {
    this.widgets = [];
    this.activeWidget = null;
    this.activeEvent = null;
  }
}

const store = new WidgetStore();
export default store;
