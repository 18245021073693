import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Intent, Menu, MenuItem, MenuDivider, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import AppItemsNewItemDialog from '../app_items/AppItemsNewItemDialog';
import AppItemsSelectUsers from '../app_items/AppItemsSelectUsers';
import AppItemsSelectTeams from '../app_items/AppItemsSelectTeams';
import moment from 'moment';
import _ from 'lodash';

@inject('AppStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class QuickCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddingNewItem: false,
      isAddingNewUsers: false,
      isAddingNewTeams: false,
      draftItemId: null,
    };
  }

  toggleAddNewItem() {
    const itemObj = {};
    itemObj.app_id = this.props.AppStore.activeApp.id;
    itemObj.user_id = this.props.CurrentUserStore.currentUser.id;
    itemObj.name = I18n.t('js.new_item_with_name', {
      item: this.props.AppStore.activeApp.item,
      name: this.props.CurrentUserStore.currentUser.fullName,
    });
    itemObj.is_draft = true;
    this.props.ItemActions.createItem(itemObj).then(response => {
      const item = response.data;
      this.setState({
        draftItemId: item.id,
        isAddingNewItem: !this.state.isAddingNewItem,
      });
    });
  }

  handleDraftCancel() {
    this.setState({ isAddingNewItem: false });
    this.props.ItemActions.deleteItem(this.state.draftItemId);
  }

  toggleAddNewUsers = () => {
    this.setState({
      isAddingNewUsers: !this.state.isAddingNewUsers,
    });
  };

  toggleAddNewTeams = () => {
    this.setState({
      isAddingNewTeams: !this.state.isAddingNewTeams,
    });
  };

  copyWebformUrl(app) {
    Utilities.copyStringToClipboard(app.webformPath);
    this.props.ToastStore.showToast(I18n.t('js.copied_webform_path_to_your_clipboard'), 'success');
  }

  handleAppItemClick(app) {
    this.props.AppStore.activeApp = app;
    this.toggleAddNewItem();
  }

  handleAppUserClick(app) {
    this.props.AppStore.activeApp = app;
    this.toggleAddNewUsers();
  }

  handleAppTeamClick(app) {
    this.props.AppStore.activeApp = app;
    this.toggleAddNewTeams();
  }

  renderAppIcon(app) {
    return (
      <Flexbox flexDirection="row" width="15px" alignContent="center" justifyContent="center">
        <span className={`fa fas fa-fw ${app.icon}`} style={{ paddingTop: '3px', color: app.color }}></span>
      </Flexbox>
    );
  }

  renderUserIcon() {
    return <Avatar src={this.props.CurrentUserStore.currentUser.imageFileName} size={20} round={true} className="push-5-r" />;
  }

  renderAppNewItemMenu() {
    return (
      <Menu>
        {_.map(this.props.AppStore.quickCreateApps, app => {
          return (
            <MenuItem key={app.id} icon={this.renderAppIcon(app)} text={app.name}>
              <MenuItem
                icon={this.renderUserIcon()}
                text={I18n.t('js.new_draft_item', { item: app.item })}
                onClick={() => this.handleAppItemClick(app)}
              />
              <MenuDivider />
              <MenuItem
                icon="people"
                text={I18n.t('js.select_users')}
                onClick={() => this.handleAppUserClick(app)}
                disabled={!app.userCanEdit}
              />
              <MenuItem
                icon="layout-hierarchy"
                text={I18n.t('js.select_team_of_users')}
                onClick={() => this.handleAppTeamClick(app)}
                disabled={!app.userCanEdit}
              />
              {app.webformEnabled ? (
                <>
                  <MenuDivider />
                  <MenuItem
                    text={I18n.t('js.copy_webform_url_to_clipboard')}
                    intent={Intent.SUCCESS}
                    icon="clipboard"
                    onClick={() => this.copyWebformUrl(app)}
                  />
                </>
              ) : null}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="row" flexGrow={1}>
        {this.props.AppStore.quickCreateApps.length > 0 ? (
          <Flexbox flexDirection="row" flexGrow={1}>
            <Popover
              content={this.renderAppNewItemMenu()}
              position={Position.BOTTOM}
              usePortal={false}
              disabled={this.props.AppStore.quickCreateApps.length === 0}
              portalContainer={document.body}
              interactionKind={PopoverInteractionKind.CLICK}
              inline={true}
              autoFocus={false}
            >
              <AnchorButton
                rightIcon="chevron-down"
                text={I18n.t('js.quick_create')}
                intent={Intent.PRIMARY}
                disabled={this.props.AppStore.quickCreateApps.length === 0}
              />
            </Popover>
            <AppItemsNewItemDialog
              toggleDialog={this.toggleAddNewItem.bind(this)}
              handleCancel={this.handleDraftCancel.bind(this)}
              postType="create"
              dialogOpen={this.state.isAddingNewItem}
              itemId={this.state.draftItemId}
            />
            <AppItemsSelectUsers
              toggleDialog={this.toggleAddNewUsers.bind(this)}
              postType="create"
              dialogOpen={this.state.isAddingNewUsers}
            />
            <AppItemsSelectTeams
              toggleDialog={this.toggleAddNewTeams.bind(this)}
              postType="create"
              dialogOpen={this.state.isAddingNewTeams}
            />
          </Flexbox>
        ) : null}
      </Flexbox>
    );
  }
}
