import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Collapse, FormGroup, Intent, NumericInput, Switch, Button } from '@blueprintjs/core';
import LengthValidator from '../../shared/LengthValidator';
import ElementTag from '../../shared/ElementTag';
import ElementIdSelector from '../../shared/ElementIdSelector';
import classNames from 'classnames';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementTextarea extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      min_length: this.store.activeElement.minLength,
      max_length: this.store.activeElement.maxLength,
      is_required: this.store.activeElement.isRequired,
      repeatable: this.store.activeElement.repeatable,
    };
    const elementTextareaObj = {
      id: this.store.activeElement.elementTextarea.id,
      name: this.store.activeElement.elementTextarea.name,
      placeholder_text: this.store.activeElement.elementTextarea.placeholderText,
      rows: this.store.activeElement.elementTextarea.rows,
    };
    this.props.TemplateActions.changeSubElement(elementTextareaObj, 'element_textareas')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleRepeatableChange() {
    this.store.updateActiveElement(!this.store.activeElement.repeatable, 'repeatable');
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementTextarea[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementTextarea[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-align-justify push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.textarea_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementTextarea.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementTextarea.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={this.store.activeElement.elementTextarea.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'placeholderText') && !this.store.activeElement.elementTextarea.placeholderTextValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="placeholderText">
                    {I18n.t('js.placeholder_text')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'placeholderText') &&
                        !this.store.activeElement.elementTextarea.placeholderTextValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_placeholder_text')}
                        name="placeholderText"
                        value={this.store.activeElement.elementTextarea.placeholderText}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('placeholderText')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="minLength">
                    {I18n.t('js.minimum_length')}
                  </label>
                  <div className="bp3-form-content">
                    <NumericInput
                      min="0"
                      value={this.store.activeElement.minLength}
                      onValueChange={(n, s) => {
                        this.store.updateActiveElement(s, 'minLength');
                      }}
                      name="minLength"
                    />
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="maxLength">
                    {I18n.t('js.maximum_length')}
                  </label>
                  <div className="bp3-form-content">
                    <NumericInput
                      min="0"
                      value={this.store.activeElement.maxLength}
                      onValueChange={(n, s) => {
                        this.store.updateActiveElement(s, 'maxLength');
                      }}
                      name="maxLength"
                    />
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="rows">
                    {I18n.t('js.number_of_rows_in_textarea')}
                  </label>
                  <div className="bp3-form-content">
                    <NumericInput
                      min="0"
                      value={this.store.activeElement.elementTextarea.rows}
                      onValueChange={n => {
                        this.store.updateActiveElement(n, 'rows');
                      }}
                      name="rows"
                    />
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" marginBottom="20px">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.repeatable}
                label={I18n.t('js.repeatable')}
                onChange={this.handleRepeatableChange.bind(this)}
              />
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.response_required')}
                onChange={this.handleResponseRequiredChange.bind(this)}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      let inputClass = classNames('bp3-input bp3-fill resize-none');
      if (!this.store.activeElement.validateLength(this.displayedInput)) {
        inputClass = classNames('bp3-input bp3-fill resize-none bp3-intent-danger');
      }
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementTextarea.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
          intent={this.store.activeElement.validateLength(this.displayedInput) ? null : Intent.DANGER}
        >
          <textarea
            className={inputClass}
            dir="auto"
            rows={this.store.activeElement.elementTextarea.rows}
            placeholder={this.store.activeElement.elementTextarea.placeholderText}
            disabled={!this.props.element.stateElement.editable}
            value={this.displayedInput}
            onChange={e => {
              this.displayedInput = e.target.value;
            }}
          />
          <LengthValidator input={this.displayedInput} element={this.store.activeElement} />
          {this.store.activeElement.repeatable && this.props.element.stateElement.editable ? (
            <Button
              minimal={true}
              text={I18n.t('js.add_additional_response')}
              icon="add"
              className="push-10-t"
              disabled={!this.props.element.stateElement.editable}
            ></Button>
          ) : null}
        </FormGroup>
      );
    }
    let inputClass = classNames('bp3-input bp3-fill resize-none');
    if (!this.props.element.validateLength(this.displayedInput)) {
      inputClass = classNames('bp3-input bp3-fill resize-none bp3-intent-danger');
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementTextarea.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
        intent={this.props.element.validateLength(this.displayedInput) ? null : Intent.DANGER}
      >
        <textarea
          className={inputClass}
          dir="auto"
          rows={this.props.element.elementTextarea.rows}
          placeholder={this.props.element.elementTextarea.placeholderText}
          disabled={!this.props.element.stateElement.editable}
          value={this.displayedInput}
          onChange={e => {
            this.displayedInput = e.target.value;
          }}
        />
        <LengthValidator input={this.displayedInput} element={this.props.element} />
        {this.props.element.repeatable && this.props.element.stateElement.editable ? (
          <Button
            minimal={true}
            text={I18n.t('js.add_additional_response')}
            icon="add"
            className="push-10-t"
            disabled={!this.props.element.stateElement.editable}
          ></Button>
        ) : null}
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
