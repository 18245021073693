import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import TemplateManageParticipantRow from './TemplateManageParticipantRow';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import _ from 'lodash';

@inject('TemplateStore')
@observer
export default class TemplateManageParticipants extends React.Component {
  handleColumnSort(column) {
    this.props.TemplateStore.setParticipantSortParams(
      column.id,
      column.sortDir
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <p>{I18n.t('js.manage_participants_description')}</p>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader
              columns={this.props.TemplateStore.participantColumns}
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          <tbody>
            {_.map(
              this.props.TemplateStore.participants,
              participant => (
                <TemplateManageParticipantRow
                  key={participant.id}
                  participant={participant}
                />
              )
            )}
          </tbody>
        </table>
      </Flexbox>
    );
  }
}
