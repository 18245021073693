/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { InputGroup, NumericInput } from '@blueprintjs/core';
import { DateInput, DateTimePicker, TimePicker } from '@blueprintjs/datetime';
import { observer } from 'mobx-react';
import StateConditionItemValueUserSelect from './StateConditionItemValueUserSelect';
import StateConditionItemValueTeamSelect from './StateConditionItemValueTeamSelect';
import StateConditionItemValueItemSelect from './StateConditionItemValueItemSelect';
import moment from 'moment';

@observer
export default class StateRuleConditionItemValue extends React.Component {
  manageChange() {
    if (this.props.stateCondition.stateConditionValue.changeType !== 'added') {
      this.props.stateCondition.stateConditionValue.changeType = 'changed';
    }
  }

  handleStringValueChange(e) {
    e.preventDefault();
    this.props.stateCondition.stateConditionValue.stringValue = e.target.value;
    this.props.stateCondition.stateConditionValue.valueType = 'string';
    this.manageChange();
  }

  handleDecimalChange(n, s) {
    this.props.stateCondition.stateConditionValue.decimalValue = s;
    this.props.stateCondition.stateConditionValue.valueType = 'decimal';
    this.manageChange();
  }

  handleDateChange(date) {
    this.props.stateCondition.stateConditionValue.dateValue = date;
    this.props.stateCondition.stateConditionValue.valueType = 'datetime';
    this.manageChange();
  }

  handleUserSelect(user) {
    this.props.stateCondition.stateConditionValue.userId = user.id;
    this.props.stateCondition.stateConditionValue.valueType = 'user';
    this.manageChange();
  }

  handleTeamSelect(team) {
    this.props.stateCondition.stateConditionValue.teamId = team.id;
    this.props.stateCondition.stateConditionValue.valueType = 'team';
    this.manageChange();
  }

  handleItemSelect(item) {
    this.props.stateCondition.stateConditionValue.itemId = item.id;
    this.props.stateCondition.stateConditionValue.valueType = 'item';
    this.manageChange();
  }

  renderElementSwitch() {
    switch (this.props.selectedElement.elementType) {
      case 'text_input':
      case 'textarea':
      case 'rich_textarea':
      case 'email':
      case 'hyperlink_input':
      case 'multiline':
      case 'select':
      case 'profile_value':
        return (
          <InputGroup
            className="max-width"
            value={this.props.stateCondition.stateConditionValue.stringValue}
            onChange={this.handleStringValueChange.bind(this)}
            placeholder={I18n.t('js.enter_test_value')}
          />
        );
      case 'number':
      case 'percentage':
      case 'currency':
      case 'slider':
      case 'calculation':
        return (
          <NumericInput
            className="max-width"
            allowNumericCharactersOnly={true}
            value={this.props.stateCondition.stateConditionValue.decimalValue}
            onValueChange={this.handleDecimalChange.bind(this)}
          />
        );
      case 'date':
      case 'date_range':
        if (this.props.stateCondition.operand == 'is_within_last_days' || this.props.stateCondition.operand == 'is_within_next_days') {
          return (
            <InputGroup
              className="max-width"
              value={this.props.stateCondition.stateConditionValue.stringValue}
              onChange={this.handleStringValueChange.bind(this)}
              placeholder={I18n.t('js.enter_test_value')}
            />
          );
        }
        return (
          <DateInput
            value={
              this.props.stateCondition.stateConditionValue.dateValue
                ? new Date(this.props.stateCondition.stateConditionValue.dateValue)
                : null
            }
            onChange={this.handleDateChange.bind(this)}
            formatDate={date => moment.utc(date).format('DD/MM/YYYY')}
            parseDate={str => new Date(str)}
            placeholder={I18n.t('js.choose_a_date')}
          />
        );
      case 'date_time':
        if (this.props.stateCondition.operand == 'is_within_last_days' || this.props.stateCondition.operand == 'is_within_next_days') {
          return (
            <InputGroup
              className="max-width"
              value={this.props.stateCondition.stateConditionValue.stringValue}
              onChange={this.handleStringValueChange.bind(this)}
              placeholder={I18n.t('js.enter_test_value')}
            />
          );
        }
        return (
          <DateTimePicker
            className=""
            value={
              this.props.stateCondition.stateConditionValue.dateValue
                ? new Date(this.props.stateCondition.stateConditionValue.dateValue)
                : new Date()
            }
            onChange={this.handleDateChange.bind(this)}
          />
        );
      case 'time':
        return (
          <TimePicker
            value={
              this.props.stateCondition.stateConditionValue.dateValue
                ? new Date(this.props.stateCondition.stateConditionValue.dateValue)
                : new Date()
            }
            onChange={this.handleDateChange.bind(this)}
          />
        );
      case 'user_select':
        return (
          <StateConditionItemValueUserSelect
            handleUserSelect={this.handleUserSelect.bind(this)}
            stateCondition={this.props.stateCondition}
          />
        );
      case 'team_select':
        return (
          <StateConditionItemValueTeamSelect
            handleTeamSelect={this.handleTeamSelect.bind(this)}
            stateCondition={this.props.stateCondition}
          />
        );
      case 'item_select':
        return (
          <StateConditionItemValueItemSelect
            handleItemSelect={this.handleItemSelect.bind(this)}
            stateCondition={this.props.stateCondition}
          />
        );
      default:
        return null;
    }
  }

  render() {
    if (
      this.props.selectedElement === null ||
      (this.props.selectedElement.isDisplayElement && this.props.selectedElement.elementType != 'calculation')
    ) {
      return <Flexbox flexGrow={1} />;
    }
    return (
      <Flexbox flexGrow={1} marginRight="10px">
        {this.renderElementSwitch()}
      </Flexbox>
    );
  }
}
