import React from 'react';
import Flexbox from 'flexbox-react';
import ItemElement from '../items/ItemElement';
import { observer } from 'mobx-react';
import { Classes, NonIdealState, Spinner } from '@blueprintjs/core';
import _ from 'lodash';
import ItemDrawerStore from '../../stores/ItemDrawerStore';
import { Drawer, Position } from '@blueprintjs/core';
import Utilities from '../../../react/utils/Utilities';

@observer
export default class ItemInfoDrawer extends React.Component {
  renderSection(section) {
    if (section.elements.length === 0) {
      return (
        <Flexbox flexDirection="column" className="template-area" marginTop="20px" marginBottom="20px">
          <NonIdealState
            title={I18n.t('js.nothing_to_show')}
            description={I18n.t('js.there_are_no_elements_in_this_section')}
            icon="multi-select"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }

    return (
      <Flexbox
        marginBottom="10px"
        flexDirection="column"
        flexGrow={1}
        paddingLeft="30px"
        paddingRight="30px"
        paddingTop="10px"
        paddingBottom="10px"
      >
        {_.map(section.elements, element => (
          <ItemElement key={element.id} element={element} stopOpeningMoreDrawers={true} />
        ))}
      </Flexbox>
    );
  }

  navigateToUrl() {
    ItemDrawerStore.toggleBool('isDrawerOpen');
    Utilities.navigate(`/items/${ItemDrawerStore.item.id}`);
  }

  render() {
    return (
      <Drawer
        portalContainer={document.body}
        position={Position.RIGHT}
        size={Drawer.SIZE_STANDARD}
        onClose={this.props.toggleDrawer}
        isOpen={this.props.isItemDrawerOpen}
      >
        <Flexbox className="pad-20 scroll" flexDirection="column">
          {ItemDrawerStore.isLoading ? (
            <Flexbox justifyContent="center" alignItems="center" paddingTop="20px" paddingBottom="20px">
              <NonIdealState
                title={I18n.t('js.fetching_data')}
                description={I18n.t('js.please_wait')}
                icon={<Spinner />}
                className="bp3-text-muted"
              />
            </Flexbox>
          ) : (
            <>
              <Flexbox flexDirection="row" alignItems="center" className="pointer" marginBottom="20px" onClick={() => this.navigateToUrl()}>
                <Flexbox flexDirection="row" alignItems="center">
                  <span className={`push-10-r ${ItemDrawerStore.item.itemIcon}`} style={{ color: ItemDrawerStore.item.itemColor }} />
                  <h2 className="bp3-label-no-margin">{ItemDrawerStore.item.name}</h2>
                </Flexbox>
              </Flexbox>
              <Flexbox flexGrow={1} flexDirection="column">
                {_.map(this.props.sections, section => (
                  <Flexbox key={section.id} className="bp3-card bp3-card-no-padding" flexDirection="column" marginBottom="20px">
                    <Flexbox
                      flexGrow={1}
                      className="template-section-header"
                      paddingLeft="30px"
                      paddingRight="30px"
                      paddingTop="15px"
                      maxHeight="50px"
                    >
                      <Flexbox flexGrow={1}>
                        <h4 className={Classes.HEADING}>{section.title}</h4>
                      </Flexbox>
                      <Flexbox flexGrow={0}>
                        <small className="bp3-text-muted">
                          {I18n.t('js.item_section_x_of_y', {
                            x: this.props.sections.indexOf(section) + 1,
                            y: this.props.sections.length,
                          })}
                        </small>
                      </Flexbox>
                    </Flexbox>
                    {this.renderSection(section)}
                  </Flexbox>
                ))}
              </Flexbox>
            </>
          )}
        </Flexbox>
      </Drawer>
    );
  }
}
