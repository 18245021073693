/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { AnchorButton, Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import StateRuleActions from '../actions/StateRuleActions';
import ToastStore from '../stores/ToastStore';
import StateRuleStore from '../stores/StateRuleStore';
import TemplateStore from '../stores/TemplateStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import StateRule from '../components/state_rules/StateRule';

const DragHandle = sortableHandle(() => <AnchorButton className="bp3-cursor-move bp3-minimal" icon="drag-handle-vertical" />);

const SortableRule = sortableElement(({ rule }) => <StateRule stateRule={rule} dragHandle={<DragHandle />} />);

const SortableRules = sortableContainer(({ rules }) => (
  <Flexbox flexDirection="column" padding="0px">
    {rules.map(rule => (
      <SortableRule key={rule.id} index={rules.indexOf(rule)} rule={rule} />
    ))}
  </Flexbox>
));

@observer
export default class StateRulesView extends React.Component {
  @observable
  isLoading = false;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentWillMount() {
    StateRuleActions.loadInitialData(TemplateStore.activeState.id);
  }

  handleCreateRule() {
    this.toggleLoading();
    const ruleObj = {
      state_id: TemplateStore.activeState.id,
      name: I18n.t('js.new_state_rule_name', {
        state_name: TemplateStore.activeState.name.toLowerCase(),
      }),
    };
    StateRuleActions.createStateRule(ruleObj)
      .then(response => {
        this.toggleLoading();
        StateRuleStore.resetNewStateRule();
        StateRuleStore.setActiveStateRule(response.data.id);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleSortEnd(rule, newIndex) {
    const ruleObj = {
      id: rule.id,
      row_order_position: newIndex,
    };
    StateRuleActions.changeStateRule(ruleObj);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the rule
    const findRule = StateRuleStore.stateRules[oldIndex];

    // Reorder the current UI
    const newRules = arrayMove(StateRuleStore.stateRules.slice(), oldIndex, newIndex);
    StateRuleStore.stateRules = newRules;

    // Process reorder on server
    this.handleSortEnd(findRule, newIndex);
  }

  renderAddRule() {
    return (
      <Flexbox flexGrow={1} justifyContent="center">
        <Button
          className="bp3-intent-primary bp3-large"
          icon="add"
          text={I18n.t('js.create_a_new_state_rule')}
          onClick={this.handleCreateRule.bind(this)}
          loading={this.isLoading}
        />
      </Flexbox>
    );
  }

  renderRules() {
    if (StateRuleStore.stateRules.length === 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          <Flexbox flexDirection="column" marginBottom="20px">
            <Flexbox padding="20px">
              <NonIdealState
                title={I18n.t('js.warning_no_rules_set')}
                description={I18n.t('js.no_rules_set_description')}
                icon="flag"
                className="bp3-text-muted"
              />
            </Flexbox>
          </Flexbox>
          {this.renderAddRule()}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="row" justifyContent="center" marginBottom="10px">
          <p>
            {I18n.t('js.when_state_is_submitted', {
              name: TemplateStore.activeState.name,
            })}
          </p>
        </Flexbox>
        <SortableRules
          rules={StateRuleStore.stateRules}
          onSortEnd={this.onSortEnd.bind(this)}
          useDragHandle={true}
          lockToContainerEdges={true}
          lockAxis="y"
        />
        {this.renderAddRule()}
      </Flexbox>
    );
  }

  render() {
    const stores = {
      StateRuleStore,
      StateRuleActions,
      TemplateStore,
      CurrentUserStore,
      ToastStore,
    };
    if (StateRuleStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="50px" paddingBottom="50px">
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <Flexbox flexGrow={1} flexDirection="column">
            {this.renderRules()}
          </Flexbox>
        </ErrorBoundary>
      </Provider>
    );
  }
}
