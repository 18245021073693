/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import User from './UserModel';
import ImportError from './ImportErrorModel';
import Utilities from '../utils/Utilities';

export default class Import {
  @observable id;
  @observable importTemplateId;
  @observable templateUrl;
  @observable status;
  @observable progress;
  @observable rolledBack;
  @observable newObjectCount;
  @observable errorCount;
  @observable totalObjectCount;
  @observable finishedAt;
  @observable importErrors;
  @observable user;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;

  constructor(
    id,
    importTemplateId,
    templateUrl,
    status,
    progress,
    rolledBack,
    newObjectCount,
    errorCount,
    totalObjectCount,
    finishedAt,
    importErrors,
    user,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.importTemplateId = importTemplateId || '';
    this.templateUrl = templateUrl || '';
    this.status = status || '';
    this.progress = progress || 0;
    typeof rolledBack === 'undefined' || rolledBack === null ? (this.rolledBack = false) : (this.rolledBack = rolledBack);
    this.newObjectCount = newObjectCount || 0;
    this.errorCount = errorCount || 0;
    this.totalObjectCount = totalObjectCount || 0;
    this.finishedAt = finishedAt || '';
    this.importErrors = this.constructImportErrors(importErrors);
    this.user = new User(...Utilities.getObjectValues(user)) || null;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  constructImportErrors(importErrors) {
    const res = [];
    importErrors.map(importError => {
      const newError = new ImportError(...Utilities.getObjectValues(importError));
      res.push(newError);
    });
    return res;
  }
  @computed get progressPercentage() {
    return `${this.progress}%`;
  }

  @computed
  get finishedAtToDate() {
    if (this.finishedAt !== '') {
      return moment.utc(this.finishedAt).format('MMMM Do YYYY, h:mm a');
    } else {
      return null;
    }
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
