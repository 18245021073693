/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observer, Provider } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { Callout, Intent, Button, Tabs, Tab, Tag } from '@blueprintjs/core';
import Utilities from '../utils/Utilities';
import UsageDefinitions from '../static/UsageDefinitions';
import UsageMetric from '../components/shared/UsageMetric';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import AdminActions from '../actions/AdminActions';
import AdminStore from '../stores/AdminStore';
import AccountSettingsStore from '../stores/AccountSettingsStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import AccountSettingsDetailsAccountClose from '../components/accounts/AccountSettingsDetailsAccountClose';
import AccountSettingsDetailsAccountActivate from '../components/accounts/AccountSettingsDetailsAccountActivate';
import AccountSettingsDetailsAccountEnterprise from '../components/accounts/AccountSettingsDetailsAccountEnterprise';
import moment from 'moment';

@observer
export default class AdminAccountView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'details',
    };
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  componentDidMount() {
    AccountSettingsStore.resetStartingAttributes();
    AccountSettingsStore.fetchAccount(this.props.account.id)
      .then(() => {
        AccountSettingsStore.toggleLoading();
      })
      .catch(() => {
        AccountSettingsStore.toggleLoading();
      });
  }

  handleImpersonate(e, user) {
    AdminActions.getImpersonationToken(user.id).then(response => {
      Utilities.copyStringToClipboard(response.data.url);
      ToastStore.showToast(I18n.t('js.impersonation_url_has_been_copied_to_clipboard'), 'success');
    });
  }

  renderContent() {
    const { account, logo, created_by, workspaces, apps, teams, users } = this.props;
    switch (this.state.activeTab) {
      case 'details':
        return (
          <Flexbox flexDirection="column">
            <table className="bp3-html-table bp3-small bp3-html-table-striped bp3-html-table-condensed max-width">
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    <Tag minimal={true}>{account.name}</Tag>
                  </td>
                </tr>
                <tr>
                  <td>Active</td>
                  <td>
                    {account.active ? (
                      <Tag minimal intent={Intent.SUCCESS}>
                        Active
                      </Tag>
                    ) : (
                      <Tag minimal intent={Intent.WARNING}>
                        Inactive
                      </Tag>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Enterprise</td>
                  <td>
                    {account.is_enterprise ? (
                      <Tag minimal intent={Intent.SUCCESS}>
                        Enterprise
                      </Tag>
                    ) : (
                      <Tag minimal intent={Intent.WARNING}>
                        No
                      </Tag>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>ID</td>
                  <td>
                    <Tag minimal={true}>{account.id}</Tag>
                  </td>
                </tr>
                <tr>
                  <td>Subdomain</td>
                  <td>
                    <Tag intent={Intent.SUCCESS} minimal={true}>
                      {account.subdomain}
                    </Tag>
                  </td>
                </tr>
                <tr>
                  <td>Created at</td>
                  <td>{moment(account.created_at).format('LLLL')}</td>
                </tr>
                <tr>
                  <td>Created by</td>
                  <td>{`${created_by.first_name} ${created_by.last_name} (${created_by.email})`}</td>
                </tr>
                <tr>
                  <td>Account purpose</td>
                  <td>{account.account_purpose}</td>
                </tr>
                <tr>
                  <td>Account purpose (other)</td>
                  <td>{account.account_purpose_other}</td>
                </tr>
                <tr>
                  <td>Owner role</td>
                  <td>{account.owner_role}</td>
                </tr>
                <tr>
                  <td>Owner phone</td>
                  <td>{account.owner_phone}</td>
                </tr>
                <tr>
                  <td>Company size</td>
                  <td>{account.account_size}</td>
                </tr>
                <tr>
                  <td>Implementation time</td>
                  <td>{account.implementation_time}</td>
                </tr>
                <tr>
                  <td>Referral source</td>
                  <td>{account.referral_source}</td>
                </tr>
                <tr>
                  <td>Referral source (other)</td>
                  <td>{account.referral_source_other}</td>
                </tr>
              </tbody>
            </table>
          </Flexbox>
        );
      case 'usage':
        return (
          <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
            {UsageDefinitions.accountUsageTypes.map(usage => {
              return <UsageMetric usage={usage} key={usage.title} accountId={account.id} />;
            })}
          </Flexbox>
        );
      case 'workspaces':
        return (
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-small bp3-html-table-striped bp3-html-table-condensed max-width">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {workspaces.map(workspace => {
                  return (
                    <tr key={workspace.id}>
                      <td>{workspace.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Flexbox>
        );
      case 'apps':
        return (
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-small bp3-html-table-striped bp3-html-table-condensed max-width">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {apps.map(app => {
                  return (
                    <tr key={app.id}>
                      <td>{app.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Flexbox>
        );
      case 'teams':
        return (
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-small bp3-html-table-striped bp3-html-table-condensed max-width">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {teams.map(team => {
                  return (
                    <tr key={team.id}>
                      <td>{team.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Flexbox>
        );
      case 'users':
        return (
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-small bp3-html-table-striped bp3-html-table-condensed max-width">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => {
                  return (
                    <tr key={user.id}>
                      <td>{`${user.first_name} ${user.last_name} (${user.email})`}</td>
                      <td>{moment(user.created_at).format('DD/MM/YYYY')}</td>
                      <td>
                        <Button intent={Intent.DANGER} onClick={e => this.handleImpersonate(e, user)}>
                          Get impersonation URL
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Flexbox>
        );
      case 'actions':
        return (
          <Flexbox flexDirection="column" padding="20px">
            <AccountSettingsDetailsAccountEnterprise account={this.props.account} />
            <AccountSettingsDetailsAccountActivate />
            <AccountSettingsDetailsAccountClose />
          </Flexbox>
        );
    }
  }
  render() {
    const stores = {
      AccountSettingsStore,
      AdminActions,
      AdminStore,
      CurrentUserStore,
      ToastStore,
    };
    const { workspaces, apps, teams, users } = this.props;
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <h3 className="bp3-heading push-20-b">{this.props.account.name}</h3>
                <Callout intent={Intent.WARNING} className="push-20-b" title="Caution!">
                  You are in the admin only area. Act with caution and professionalism.
                </Callout>
                <Flexbox flexGrow={1} flexDirection="column">
                  <Flexbox>
                    <Button text="Back" icon="arrow-left" onClick={() => Utilities.navigate('/admin')} />
                  </Flexbox>
                  <Tabs
                    id="adminAccountTabnav"
                    selectedTabId={this.state.activeTab}
                    onChange={tab => this.setActiveTab(tab)}
                    className="push-10-t"
                  >
                    <Tab id="details">Details</Tab>
                    <Tab id="usage">Usage</Tab>
                    <Tab id="users">Users {`(${users.length})`}</Tab>
                    <Tab id="workspaces">Workspaces {`(${workspaces.length})`}</Tab>
                    <Tab id="apps">Apps {`(${apps.length})`}</Tab>
                    <Tab id="teams">Teams {`(${teams.length})`}</Tab>
                    <Tab id="actions">Actions</Tab>
                  </Tabs>
                </Flexbox>
                <Flexbox
                  flexDirection="column"
                  flexGrow={1}
                  marginTop="10px"
                  className={`${this.state.activeTab != 'usage' ? 'bp3-card bp3-card-no-padding' : null}`}
                >
                  {this.renderContent()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
