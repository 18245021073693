/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import moment from 'moment';

export default class StateAction {
  @observable id;
  @observable stateRuleId;
  @observable actionType;
  @observable targetStateId;
  @observable targetStatusFlagId;
  @observable targetEmailContent;
  @observable targetEmailSubject;
  @observable timelineEventParticipantId;
  @observable timelineEventDescription;
  @observable webhookUrl;
  @observable includeCalendarInvitation;
  @observable calendarElementId;
  @observable includePdfAttachment;
  @observable inviteToSectionUserSelectElementId;
  @observable inviteToSectionId;
  @observable inviteToSectionDueDateIncrement;
  @observable inviteToSectionMessage;
  @observable inviteToSectionDueDate;
  @observable inviteToSectionHasDueDate;
  @observable inviteToSectionAnonymous;
  @observable overrideParticipantId;
  @observable overrideParticipantUserSelectElementId;
  @observable rowOrder;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;
  @observable changeType;

  constructor(
    id,
    stateRuleId,
    actionType,
    targetStateId,
    targetStatusFlagId,
    targetEmailContent,
    targetEmailSubject,
    timelineEventParticipantId,
    timelineEventDescription,
    webhookUrl,
    includeCalendarInvitation,
    calendarElementId,
    includePdfAttachment,
    inviteToSectionUserSelectElementId,
    inviteToSectionId,
    inviteToSectionDueDateIncrement,
    inviteToSectionMessage,
    inviteToSectionDueDate,
    inviteToSectionHasDueDate,
    inviteToSectionAnonymous,
    overrideParticipantId,
    overrideParticipantUserSelectElementId,
    rowOrder,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy,
    changeType
  ) {
    this.id = id || Utilities.makeId();
    this.stateRuleId = stateRuleId || '';
    this.actionType = actionType || '';
    this.targetStateId = targetStateId || '';
    this.targetStatusFlagId = targetStatusFlagId || '';
    this.targetEmailContent = targetEmailContent || '';
    this.targetEmailSubject = targetEmailSubject || '';
    this.timelineEventParticipantId = timelineEventParticipantId || '';
    this.timelineEventDescription = timelineEventDescription || '';
    this.webhookUrl = webhookUrl || '';
    typeof includeCalendarInvitation === 'undefined' || includeCalendarInvitation === null
      ? (this.includeCalendarInvitation = false)
      : (this.includeCalendarInvitation = includeCalendarInvitation);
    this.calendarElementId = calendarElementId || '';
    typeof includePdfAttachment === 'undefined' || includePdfAttachment === null
      ? (this.includePdfAttachment = false)
      : (this.includePdfAttachment = includePdfAttachment);
    this.inviteToSectionUserSelectElementId = inviteToSectionUserSelectElementId || '';
    this.inviteToSectionId = inviteToSectionId || '';
    this.inviteToSectionDueDateIncrement = inviteToSectionDueDateIncrement || 0;
    this.inviteToSectionMessage = inviteToSectionMessage || '';
    this.inviteToSectionDueDate = inviteToSectionDueDate || new Date();
    typeof inviteToSectionHasDueDate === 'undefined' || inviteToSectionHasDueDate === null
      ? (this.inviteToSectionHasDueDate = false)
      : (this.inviteToSectionHasDueDate = inviteToSectionHasDueDate);
    typeof inviteToSectionAnonymous === 'undefined' || inviteToSectionAnonymous === null
      ? (this.inviteToSectionAnonymous = false)
      : (this.inviteToSectionAnonymous = inviteToSectionAnonymous);
    this.overrideParticipantId = overrideParticipantId || '';
    this.overrideParticipantUserSelectElementId = overrideParticipantUserSelectElementId || '';
    this.rowOrder = rowOrder || 0;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.changeType = changeType || 'original';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
