/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('StoreCategoryStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreCategoryLeftNav extends React.Component {
  handleTabChange(tab) {
    Utilities.navigate(`/store_categories/${tab}`);
  }

  render() {
    return (
      <Flexbox flexGrow={0} flexDirection="column">
        <Flexbox marginBottom="5px">
          <span className="bp3-icon bp3-icon-git-repo bp3-text-muted push-10-r" />
          <h6 className={Classes.HEADING}>{I18n.t('js.store')}</h6>
        </Flexbox>
        {_.map(this.props.StoreCategoryStore.storeCategories, storeCategory => (
          <Flexbox className="no-wrap" key={storeCategory.sys.id}>
            <a href={`/store_categories/${storeCategory.fields.slug}`}>
              <small>{storeCategory.fields.title}</small>
            </a>
          </Flexbox>
        ))}
        <Flexbox className="no-wrap" marginTop="10px">
          <a href={`/store_categories`}>
            <small>
              <span className="bp3-icon bp3-icon-arrow-left push-5-r" />
              {I18n.t('js.show_all')}
            </small>
          </a>
        </Flexbox>
      </Flexbox>
    );
  }
}
