// @flow
import React from 'react';
import Cookies from 'js-cookie';
import Utilities from '../../utils/Utilities';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import NotificationHeaderListItem from './NotificationHeaderListItem';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Card, Classes } from '@blueprintjs/core';
import type { Notification } from '../../types/Notification';
import styled from 'styled-components';

type NotificationHeaderListProps = {
  notifications: Array<Notification>,
  unreadCount: number,
};

const StyledCard = styled(Card)`
  && {
    background-color: ${({ theme }) => (Cookies.get('theme') == 'dark' ? theme.darkGray5 : theme.white)};
    border-top: 1px solid ${({ theme }) => (Cookies.get('theme') == 'dark' ? theme.darkGray3 : theme.lightGray1)};
  }
`;

@inject('NotificationStore')
@observer
class NotificationHeaderList extends React.Component<NotificationHeaderListProps> {
  render() {
    const { unreadCount, notifications } = this.props;
    const { length } = notifications;

    if (length === 0) {
      // $FlowFixMe
      return <Card>{I18n.t('js.there_are_no_notifications')}</Card>;
    }

    const maxNotificationsInPopover = 10;
    const maxNotificationsInWindow = 5;
    const oneNotificationHeight = 90; // px
    const scrollbarHeight =
      length >= maxNotificationsInWindow ? oneNotificationHeight * maxNotificationsInWindow : oneNotificationHeight * length;

    return (
      <div>
        <Scrollbars
          autoHide={true}
          style={{
            width: 400,
            height: scrollbarHeight,
          }}
        >
          {notifications.slice(0, maxNotificationsInPopover).map(notification => (
            <NotificationHeaderListItem key={notification.id} notification={notification} />
          ))}
        </Scrollbars>
        <StyledCard>
          <Flexbox justifyContent="center">
            <Button
              icon="notifications"
              minimal
              small
              onClick={() => {
                Utilities.navigate('/notifications', true);
              }}
            >
              {/* $FlowFixMe */}
              {I18n.t('js.see_all_notifications')}
            </Button>
            <Button
              minimal
              icon="tick"
              small
              disabled={unreadCount === 0}
              // $FlowFixMe to ignore Store issues
              onClick={this.props.NotificationStore.markAllAsRead}
            >
              {/* $FlowFixMe */}
              {I18n.t('js.mark_all_as_read')}
            </Button>
          </Flexbox>
        </StyledCard>
      </div>
    );
  }
}

export default NotificationHeaderList;
