/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, Collapse, FormGroup, Intent, MenuItem, Switch } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import Flexbox from 'flexbox-react';
import SelectDefinitions from '../../../static/SelectDefinitions';
import ElementObjectSelectApps from './ElementObjectSelectApps';
import ElementReadOnly from '../../shared/ElementReadOnly';
import ElementTag from '../../shared/ElementTag';
import ElementIdSelector from '../../shared/ElementIdSelector';
import UserSuggest from '../../suggests/UserSuggest';
import TeamSuggest from '../../suggests/TeamSuggest';
import ItemSuggest from '../../suggests/ItemSuggest';
import classNames from 'classnames';
import _ from 'lodash';

const OptionSelect = Select.ofType(SelectDefinitions.optionTypes);

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions', 'ItemStore')
@observer
export default class ElementObjectSelect extends React.Component {
  @observable
  displayedInput = '';
  @observable
  itemAppsToCreate = [];
  @observable
  itemAppsToDelete = [];
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  addItemAppToCreate(itemApp) {
    this.itemAppsToCreate = _.unionBy([itemApp], this.itemAppsToCreate, 'id');
  }

  addItemAppToDelete(appId) {
    const findItemApp = _.find(this.itemAppsToCreate, o => o.app_id === appId);
    if (typeof findItemApp === 'object') {
      this.itemAppsToCreate = _.filter(this.itemAppsToCreate, o => o.app_id !== appId);
    } else {
      const itemAppToDelete = _.find(this.props.element.elementObjectSelect.elementObjectSelectItemApps, o => o.appId === appId);
      this.itemAppsToDelete = _.unionBy([itemAppToDelete], this.itemAppsToDelete, 'id');
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      is_required: this.store.activeElement.isRequired,
    };
    const elementObjectSelectObj = {
      id: this.store.activeElement.elementObjectSelect.id,
      name: this.store.activeElement.elementObjectSelect.name,
      placeholder_text: this.store.activeElement.elementObjectSelect.placeholderText,
      option_type: this.store.activeElement.elementObjectSelect.optionType,
    };
    this.processItemApps()
      .then(() => {
        this.itemAppsToCreate = [];
        this.itemAppsToDelete = [];
        this.props.TemplateActions.changeSubElement(elementObjectSelectObj, 'element_object_selects')
          .then(() => {
            this.props.TemplateActions.changeElement(elementObj)
              .then(() => {
                this.store.setActiveArea(null);
                this.toggleLoading();
              })
              .catch(() => {
                this.toggleLoading();
              });
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  processItemApps() {
    return new Promise((resolve, reject) => {
      let operationCount = this.itemAppsToCreate.length + this.itemAppsToDelete.length;
      if (operationCount === 0) {
        resolve();
      }
      _.map(this.itemAppsToCreate, itemApp => {
        this.props.TemplateActions.createElementObjectSelectItemApp(itemApp)
          .then(() => {
            // add the item app to the store!
            operationCount--;
            if (operationCount === 0) {
              resolve();
            }
          })
          .catch(reject);
      });
      _.map(this.itemAppsToDelete, itemApp => {
        this.props.TemplateActions.deleteElementObjectSelectItemApp(itemApp.id)
          .then(() => {
            // remove the item app from the store!
            operationCount--;
            if (operationCount === 0) {
              resolve();
            }
          })
          .catch(reject);
      });
    });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleOptionTypeSelect(type) {
    this.store.updateActiveElement(type.name, 'optionType');
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderOption(item, details) {
    const activeOptionType = _.find(SelectDefinitions.optionTypes, o => o.name === this.store.activeElement.elementObjectSelect.optionType);
    let menuClass = classNames('popover-larger');
    if (item.text === activeOptionType.text) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        icon={item.icon}
        text={
          <Flexbox flexDirection="row" alignContent="center">
            {' '}
            <Flexbox />{' '}
            <Flexbox marginLeft="10px" marginTop="2px">
              {' '}
              {item.text}{' '}
            </Flexbox>{' '}
          </Flexbox>
        }
      />
    );
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementObjectSelect[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementObjectSelect[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderInputValue(option) {
    return option.text;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderTitle() {
    const { elementType } = this.props.element;
    switch (elementType) {
      case 'user_select':
        return (
          <Flexbox>
            <span className="bp3-icon bp3-icon-person push-10-r" />
            <h5 className={Classes.HEADING}>{I18n.t('js.user_select_options')}</h5>
          </Flexbox>
        );
      case 'team_select':
        return (
          <Flexbox>
            <span className="bp3-icon bp3-icon-graph push-10-r" />
            <h5 className={Classes.HEADING}>{I18n.t('js.team_select_options')}</h5>
          </Flexbox>
        );
      case 'item_select':
        return (
          <Flexbox>
            <span className="bp3-icon bp3-icon-new-object push-10-r" />
            <h5 className={Classes.HEADING}>{I18n.t('js.item_select_options')}</h5>
          </Flexbox>
        );
      default:
        throw new Error(`Element type: ${elementType} not accounted for`);
    }
  }

  renderItemAppSelect() {
    if (this.props.element.elementType === 'item_select') {
      return (
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox className="bp3-form-group" flexDirection="column" flexGrow={1} flexBasis="1">
            <label className="bp3-label" htmlFor="optionType">
              {I18n.t('js.please_choose_the_apps_that_should_be_available_for_selection_below')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <div className="bp3-form-content">
              <Flexbox>
                <ElementObjectSelectApps
                  element={this.props.element}
                  addItemAppToCreate={this.addItemAppToCreate.bind(this)}
                  addItemAppToDelete={this.addItemAppToDelete.bind(this)}
                />
              </Flexbox>
            </div>
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      const activeOptionType = _.find(
        SelectDefinitions.optionTypes,
        o => o.name === this.store.activeElement.elementObjectSelect.optionType
      );
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />

            <Flexbox justifyContent="space-between" alignItems="center">
              {this.renderTitle()}
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementObjectSelect.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementObjectSelect.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={this.store.activeElement.elementObjectSelect.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'placeholderText') &&
                    !this.store.activeElement.elementObjectSelect.placeholderTextValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="placeholderText">
                    {I18n.t('js.placeholder_text')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'placeholderText') &&
                        !this.store.activeElement.elementObjectSelect.placeholderTextValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_placeholder_text')}
                        name="placeholderText"
                        value={this.store.activeElement.elementObjectSelect.placeholderText}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('placeholderText')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            {this.renderItemAppSelect()}
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox className="bp3-form-group" flexDirection="column" flexGrow={1} flexBasis="1">
                <label className="bp3-label" htmlFor="optionType">
                  {I18n.t('js.select_element_option_type')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <Flexbox>
                    <OptionSelect
                      resetOnSelect={true}
                      filterable={false}
                      items={SelectDefinitions.optionTypes}
                      itemRenderer={this.renderOption.bind(this)}
                      onItemSelect={this.handleOptionTypeSelect.bind(this)}
                      inputValueRenderer={this.renderInputValue.bind(this)}
                      inputProps={{
                        rightElement: this.renderDropDown(),
                      }}
                      popoverProps={{
                        usePortal: false,
                        position: 'bottom-left',
                        className: '',
                        popoverClassName: 'bp3-minimal',
                      }}
                    >
                      <AnchorButton text={activeOptionType.text} icon={activeOptionType.icon} rightIcon="caret-down" />
                    </OptionSelect>
                  </Flexbox>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.response_required')}
                onChange={this.handleResponseRequiredChange.bind(this)}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderDisplayObject(element) {
    const { elementType } = this.props.element;
    switch (elementType) {
      case 'user_select':
        return (
          <UserSuggest
            optionType={this.props.element.elementObjectSelect.optionType}
            element={this.props.element}
            placeholder={this.props.element.elementObjectSelect.placeholderText}
            handleUserClick={() => {
              null;
            }}
            handleUserRemove={() => {
              null;
            }}
            validateInput={() => {
              null;
            }}
            intent={null}
            disabled={!this.props.element.stateElement.editable}
            expandMenu={true}
          />
        );
      case 'team_select':
        return (
          <TeamSuggest
            optionType={this.props.element.elementObjectSelect.optionType}
            element={this.props.element}
            placeholder={this.props.element.elementObjectSelect.placeholderText}
            handleTeamClick={() => {
              null;
            }}
            handleTeamRemove={() => {
              null;
            }}
            validateInput={() => {
              null;
            }}
            intent={null}
            disabled={!this.props.element.stateElement.editable}
            expandMenu={true}
          />
        );
      case 'item_select':
        return (
          <ItemSuggest
            optionType={this.props.element.elementObjectSelect.optionType}
            element={this.props.element}
            placeholder={this.props.element.elementObjectSelect.placeholderText}
            handleItemClick={() => {
              null;
            }}
            handleItemRemove={() => {
              null;
            }}
            validateInput={() => {
              null;
            }}
            intent={null}
            disabled={!this.props.element.stateElement.editable}
            expandMenu={true}
          />
        );
      default:
        throw new Error(`Element type: ${elementType} not accounted for`);
    }
  }

  renderDisabledText(element) {
    if (!this.props.element.stateElement.editable) {
      return <ElementReadOnly element={element} />;
    }
    return undefined;
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementObjectSelect.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
        >
          {this.renderDisplayObject(this.store.activeElement)}
          {this.renderDisabledText(this.store.activeElement)}
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementObjectSelect.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
      >
        {this.renderDisplayObject(this.props.element)}
        {this.renderDisabledText(this.props.element)}
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
