/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import moment from 'moment';

export default class StateActionEmailRecipient {
  @observable id;
  @observable stateActionId;
  @observable userId;
  @observable participantId;
  @observable email;
  @observable rowOrder;
  @observable createdAt;
  @observable createdBy;
  @observable deletedAt;
  @observable deletedBy;
  @observable updatedAt;
  @observable updatedBy;
  @observable changeType;
  @observable isPersisted;

  constructor(
    id,
    stateActionId,
    userId,
    participantId,
    email,
    rowOrder,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy,
    changeType
  ) {
    this.id = id || Utilities.makeId();
    this.stateActionId = stateActionId || '';
    this.userId = userId || '';
    this.participantId = participantId || '';
    this.email = email || '';
    this.rowOrder = rowOrder || 0;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
    this.changeType = changeType || 'original';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
