import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import WorkspaceEditStatus from './WorkspaceEditStatus';
import WorkspaceEditDetails from './WorkspaceEditDetails';
import WorkspaceEditImage from './WorkspaceEditImage';
import WorkspaceEditStyle from './WorkspaceEditStyle';
import WorkspaceEditPermissions from './WorkspaceEditPermissions';
import WorkspacePublishWorkspace from './WorkspacePublishWorkspace';
import WorkspaceDeleteWorkspace from './WorkspaceDeleteWorkspace';
import _ from 'lodash';

@inject('WorkspaceStore', 'CurrentUserStore')
@observer
export default class WorkspaceEditWorkspace extends React.Component {
  componentDidMount() {
    this.props.WorkspaceStore.newWorkspace = _.clone(this.props.WorkspaceStore.activeWorkspace);
  }

  render() {
    if (this.props.WorkspaceStore.activeWorkspace.isStoreTemplate) {
      return (
        <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
          <WorkspaceEditDetails />
          <WorkspaceEditImage />
          <WorkspaceEditStyle />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" flexGrow={1} marginTop="10px">
        <WorkspaceEditDetails />
        <WorkspaceEditImage />
        <WorkspaceEditStyle />
        <WorkspaceEditStatus />
        <WorkspaceEditPermissions />
        {this.props.CurrentUserStore.isSuperAdmin ? <WorkspacePublishWorkspace /> : null}
        <WorkspaceDeleteWorkspace />
      </Flexbox>
    );
  }
}
