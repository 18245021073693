import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('StoreCategoryStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreCategoryWidget extends React.Component {
  render() {
    const bgStyle = {
      background: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url('${this.props.storeCategory.fields.headerImage.fields.file.url}')`,
      backgroundSize: 'cover',
      height: '200px',
    };
    const iconClass = classNames('fa fa-3x text-white', this.props.storeCategory.fields.icon);
    return (
      <Flexbox
        className="bp3-card bp3-card-no-padding bp3-interactive post-widget"
        onClick={() => Utilities.navigate(`/store/categories/${this.props.storeCategory.fields.slug}`)}
        flexDirection="column"
        flexGrow={1}
      >
        <Flexbox flexGrow={1} flexDirection="column">
          <Flexbox style={bgStyle} flexDirection="column" justifyContent="center" alignItems="center">
            <Flexbox className={iconClass} />
          </Flexbox>
          <Flexbox flexDirection="column" flexGrow={1} padding="20px" marginTop="10px" minHeight="100px">
            <Flexbox flexDirection="column">
              <h5 className={Classes.HEADING}>
                <a href={`/store/categories/${this.props.storeCategory.fields.slug}`}>{this.props.storeCategory.fields.title}</a>
              </h5>
            </Flexbox>
            <p className="bp3-text-muted">
              {_.truncate(this.props.storeCategory.fields.subtitle, {
                length: 100,
              })}
            </p>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
