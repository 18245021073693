import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Classes, EditableText, InputGroup, Intent, Popover } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import ColorSelector from '../../shared/ColorSelector';
import ElementSelectOption from '../../../models/elements/ElementSelectOptionModel';
import { arrayMove, sortableContainer, sortableElement } from 'react-sortable-hoc';
import _ from 'lodash';

const SortableItem = sortableElement(
  ({ column, handleConfirmOptionName, handleConfirmNumberValue, handleConfirmColor, handleDeleteOption }) => (
    <tr key={column.id}>
      <td className="td-width-30">
        <EditableText
          multiline={true}
          confirmOnEnterKey={true}
          defaultValue={column.name}
          intent={Intent.DEFAULT}
          onConfirm={value => handleConfirmOptionName(value, column)}
          placeholder={I18n.t('js.click_to_edit')}
        />
      </td>
      <td className="td-width-20">
        <Popover
          portalContainer={document.body}
          content={
            <Flexbox flexDirection="column" width="930px" padding="20px">
              <h5 className={Classes.HEADING}>{I18n.t('js.select_color')}</h5>
              <ColorSelector activeColor={column.color} handleChangeColor={value => handleConfirmColor(value, column)} />
            </Flexbox>
          }
          position="top"
        >
          <Flexbox>
            <span
              className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-5-t"
              style={{
                color: column.color,
              }}
            />
            <span
              className="bp3-text-muted bp3-cursor-pointer"
              style={{
                marginLeft: '5px',
              }}
            >
              {column.color.toUpperCase()}
            </span>
          </Flexbox>
        </Popover>
      </td>
      <td className="td-width-30">
        <EditableText
          confirmOnEnterKey={true}
          defaultValue={column.numberValue}
          intent={Intent.DEFAULT}
          onConfirm={value => handleConfirmNumberValue(value, column)}
          placeholder={I18n.t('js.click_to_edit')}
        />
      </td>
      <td className="td-width-20">
        <AnchorButton className="bp3-small push-5-l" icon="trash" text={I18n.t('js.delete')} onClick={() => handleDeleteOption(column)} />
      </td>
    </tr>
  )
);

const SortableArea = sortableContainer(
  ({ columns, handleConfirmOptionName, handleConfirmNumberValue, handleConfirmColor, handleDeleteOption }) => (
    <tbody>
      {columns.map(column => (
        <SortableItem
          key={column.id}
          handleConfirmOptionName={handleConfirmOptionName}
          handleConfirmNumberValue={handleConfirmNumberValue}
          handleConfirmColor={handleConfirmColor}
          handleDeleteOption={handleDeleteOption}
          index={columns.indexOf(column)}
          column={column}
        />
      ))}
    </tbody>
  )
);

@inject('AppStore', 'UserProfileTemplateStore', 'TemplateStore', 'TemplateActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class ElementSelectOptions extends React.Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { newOptionName: '' };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({ newOptionName: e.target.value });
  }

  handleConfirmOptionName(value, option) {
    const newOption = _.find(this.store.activeElement.elementSelect.elementSelectOptions, o => o.id === option.id);
    newOption.name = value;
    newOption.changed = true;
  }

  handleConfirmNumberValue(value, option) {
    const newOption = _.find(this.store.activeElement.elementSelect.elementSelectOptions, o => o.id === option.id);
    newOption.numberValue = value;
    newOption.changed = true;
  }

  handleConfirmColor(value, option) {
    const newActiveElement = _.clone(this.store.activeElement);
    const newOption = _.find(newActiveElement.elementSelect.elementSelectOptions, o => o.id === option.id);
    newOption.color = value;
    newOption.changed = true;
    this.store.activeElement = newActiveElement;
  }

  handleAddNewOption() {
    const newOption = new ElementSelectOption('', this.store.activeElement.elementSelect.id, this.state.newOptionName, '#db2c6f', '0.0');
    newOption.added = true;
    newOption.rowOrder = this.store.activeElement.elementSelect.elementSelectOptions.length;
    this.store.activeElement.elementSelect.elementSelectOptions.push(newOption);
    this.setState({ newOptionName: '' });
  }

  handleDeleteOption(option) {
    const newActiveElement = _.clone(this.store.activeElement);
    newActiveElement.elementSelect.elementSelectOptions = _.filter(
      newActiveElement.elementSelect.elementSelectOptions,
      o => o.id !== option.id
    );
    this.store.activeElement = newActiveElement;
  }

  handleSortEnd(option, newIndex) {
    const newOption = _.find(this.store.activeElement.elementSelect.elementSelectOptions, o => o.id === option.id);
    newOption.rowOrder = newIndex;
    newOption.changed = true;
    newOption.rowOrderChanged = true;
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the section
    const findOption = this.store.activeElement.elementSelect.elementSelectOptions[oldIndex];

    // Reorder the current UI
    const newOptions = arrayMove(this.store.activeElement.elementSelect.elementSelectOptions.slice(), oldIndex, newIndex);
    const newElement = _.clone(this.store.activeElement);
    newElement.elementSelect.elementSelectOptions = newOptions;
    this.store.activeElement = newElement;

    // Process reorder on server
    this.handleSortEnd(findOption, newIndex);
  }

  renderNewOption() {
    return (
      <Flexbox flexDirection="column" marginTop="10px">
        <Flexbox className="bp3-control-group">
          <InputGroup
            autoComplete="off"
            type="text"
            intent={Intent.DEFAULT}
            placeholder={I18n.t('js.add_a_new_option_here')}
            name="name"
            value={this.state.newOptionName}
            onChange={this.handleInputChange.bind(this)}
            dir="auto"
            className="bp3-fill"
          />
          <AnchorButton
            onClick={this.handleAddNewOption.bind(this)}
            text={I18n.t('js.add_new_option')}
            className="bp3-intent-primary"
            disabled={this.state.newOptionName.length === 0}
          />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox className="bp3-form-group" flexDirection="column" flexGrow={1} flexBasis="1">
        <label className="bp3-label" htmlFor="optionType">
          {I18n.t('js.add_remove_or_edit_options')}
          <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
        </label>
        <div className="bp3-form-content">
          <Flexbox flexDirection="column" flexGrow={1}>
            <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
              <thead>
                <tr>
                  <th>{I18n.t('js.option_name')}</th>
                  <th>{I18n.t('js.color')}</th>
                  <th>{I18n.t('js.number_value_optional')}</th>
                  <th>{I18n.t('js.action')}</th>
                </tr>
              </thead>
              <SortableArea
                columns={this.store.activeElement.elementSelect.elementSelectOptions}
                onSortEnd={this.onSortEnd.bind(this)}
                lockToContainerEdges={true}
                lockAxis="y"
                handleConfirmOptionName={(value, column) => this.handleConfirmOptionName(value, column)}
                handleConfirmNumberValue={(value, column) => this.handleConfirmNumberValue(value, column)}
                handleConfirmColor={(value, column) => this.handleConfirmColor(value, column)}
                handleDeleteOption={column => this.handleDeleteOption(column)}
                useDragHandle={false}
                distance={5}
              />
            </table>
            {this.renderNewOption()}
          </Flexbox>
        </div>
      </Flexbox>
    );
  }
}
