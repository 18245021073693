// @flow
import * as React from 'react';
import { Button, Classes } from '@blueprintjs/core';
import styled from 'styled-components';

const StyledHeader = styled.th`
  .${Classes.BUTTON} {
    padding: 0;
  }
`;

type SimpleTableHeaderProps = {
  columns: Array<any>,
  handleColumnSort: Function,
};

export default ({ columns, handleColumnSort }: SimpleTableHeaderProps) => (
  <tr>
    {columns.map(col => (
      <StyledHeader key={col.name}>
        <Button
          minimal
          text={col.name}
          className="bp3-cursor-pointer noselect table-sorter"
          onClick={() => handleColumnSort(col)}
          rightIcon={col.sortDir !== 'desc' ? 'chevron-down' : 'chevron-up'}
        />
      </StyledHeader>
    ))}
  </tr>
);
