import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import CurrentUserStore from './CurrentUserStore';
import Relationship from '../models/RelationshipModel';
import UserRelationship from '../models/UserRelationshipModel';
import User from '../models/UserModel';
import NetworkGraph from '../models/NetworkGraphModel';
import axios from 'axios';
import _ from 'lodash';

class OrganizationStore {
  @observable activeRelationship = new Relationship();
  @observable newRelationship = new Relationship();
  @observable activeTab = 'relationships';
  @observable activeUser = new User();
  @observable loadingUserRelationships = false;
  @observable loadingAllUserRelationships = false;
  @observable relationshipGraph = new NetworkGraph();
  @observable relationships = [];
  @observable subOrdinateUserDelta = [];
  @observable subUserRelationshipsToCreate = [];
  @observable subUserRelationshipsToDelete = [];
  @observable superOrdinateUserDelta = [];
  @observable superUserRelationshipsToCreate = [];
  @observable superUserRelationshipsToDelete = [];
  @observable totalActiveUserRelationships = '';
  @observable userNodes = [];
  @observable userRelationshipNodes = [];
  @observable userRelationships = [];
  @observable users = [];

  @computed
  get newRelationshipFormValid() {
    return this.newRelationship.nameValid && this.newRelationship.descriptionValid;
  }

  @computed
  get csvFormattedRelationships() {
    let relationships = [];
    relationships.push([
      'relationship_name',
      'user_id',
      'user_full_name',
      'user_email',
      'counterpart_user_id',
      'counterpart_user_full_name',
      'counterpart_user_email',
    ]);
    _.map(this.userRelationships, ur => {
      relationships.push([
        this.activeRelationship.name,
        ur.user.id,
        ur.user.full_name,
        ur.user.email,
        ur.counterpartUser.id,
        ur.counterpartUser.full_name,
        ur.counterpartUser.email,
      ]);
    });
    return relationships;
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  setActiveRelationship(relationship) {
    this.loadingUserRelationships = true;
    this.resetUsersAndRelationships();
    return new Promise(resolve => {
      this.activeRelationship = relationship;
      this.fetchUserRelationshipsTotal(relationship.id).then(() => {
        this.loadingUserRelationships = false;
        resolve();
      });
    });
  }

  @action
  setActiveUser(userId) {
    this.activeUser = _.find(this.users, o => o.id === userId);
  }

  @action
  setActiveUserRelationships() {
    const newSuperOrdinateUserDelta = [];
    const newSubOrdinateUserDelta = [];
    _.map(this.userRelationships, o => {
      if (o.userId === this.activeUser.id && o.relationshipId === this.activeRelationship.id) {
        const counterpart = _.find(this.users, user => user.id === o.counterpartUserId);
        if (counterpart) {
          newSuperOrdinateUserDelta.push(counterpart);
        }
      }
      if (o.counterpartUserId === this.activeUser.id && o.relationshipId === this.activeRelationship.id) {
        const newUser = _.find(this.users, user => user.id === o.userId);
        if (newUser) {
          newSubOrdinateUserDelta.push(newUser);
        }
      }
    });
    this.superOrdinateUserDelta = newSuperOrdinateUserDelta;
    this.subOrdinateUserDelta = newSubOrdinateUserDelta;
  }

  @action
  updateNewRelationship(data, attribute) {
    this.newRelationship[attribute] = data;
  }

  @action
  updateActiveRelationship(data, attribute) {
    this.activeRelationship[attribute] = data;
  }

  @action
  updateRelationshipGraph(data, attribute) {
    this.relationshipGraph[attribute] = data;
  }

  @action
  addRelationship(data, setActive) {
    const newRelationship = new Relationship(
      data.id,
      data.account_id,
      data.name,
      data.counterpart_name,
      data.description,
      data.default,
      data.lock_user_changes,
      data.lock_counterpart_user_changes,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );

    this.relationships = _.unionBy([newRelationship], this.relationships, 'id');
    this.sortRelationships();

    if (setActive) {
      this.setActiveRelationship(newRelationship);
    }
  }

  @action
  addUser(data) {
    const newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.users = _.unionBy([newUser], this.users, 'id');
    this.addUserNode(newUser);
    this.sortUsers();
  }

  @action
  appendToUsers(user) {
    this.users = _.unionBy([user], this.users, 'id');
    this.addUserNode(user);
    this.sortUsers();
  }

  @action
  removeUser(userId) {
    this.users = _.filter(this.users, o => o.id !== userId);
    this.userNodes = _.filter(this.userNodes, o => o.id !== userId);
  }

  @action
  addUserRelationship(data) {
    const newUserRelationship = new UserRelationship(
      data.id,
      data.user_id,
      data.user,
      data.counterpart_user_id,
      data.counterpart_user,
      data.relationship_id,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by,
      false,
      false
    );

    this.addUserRelationshipNode(newUserRelationship);
    this.userRelationships = _.unionBy([newUserRelationship], this.userRelationships, 'id');
  }

  @action
  removeRelationship(data) {
    this.relationships = _.filter(this.relationships, o => o.id !== data.id);
    if (this.relationships.length >= 1) {
      this.setActiveRelationship(_.head(this.relationships));
      this.setActiveTab('individual');
    } else {
      this.setActiveRelationship('');
      this.setActiveTab('relationships');
    }
    this.sortRelationships();
  }

  @action
  removeUserRelationship(data) {
    this.userRelationships = _.filter(this.userRelationships, o => o.id !== data.id);
    this.userRelationshipNodes = _.filter(this.userRelationshipNodes, o => o.id !== data.id);
  }

  @action
  fetchUser(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/users/${userId}`)
        .then(response => {
          this.addUser(response.data);
          resolve();
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchNewlyAddedUsers() {
    return new Promise(resolve => {
      _.map(this.userRelationships, userRelationship => {
        if (userRelationship.userId === this.activeUser.id) {
          this.fetchUser(userRelationship.counterpartUserId);
        } else {
          this.fetchUser(userRelationship.userId);
        }
      });
      resolve();
    });
  }

  @action
  fetchRelationships() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/relationships`)
        .then(response => {
          _.map(response.data, relationship => {
            if (relationship.default) {
              this.addRelationship(relationship, true);
              this.activeTab = 'individual';
            } else {
              this.addRelationship(relationship, false);
            }
          });
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  createRelationship() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/relationships`, {
          name: this.newRelationship.name,
          counterpart_name: this.newRelationship.counterpartName,
          description: this.newRelationship.description,
          default: this.newRelationship.defaultRelationship,
          lock_user_changes: this.newRelationship.lockUserChanges,
          lock_counterpart_user_changes: this.newRelationship.lockCounterpartUserChanges,
        })
        .then(response => {
          resolve(response);
          this.addRelationship(response.data, true);
          this.newRelationship = new Relationship();
          this.setActiveTab('relationships');
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  changeRelationship(relationship) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/relationships/${relationship.id}`, {
          name: relationship.name,
          counterpart_name: relationship.counterpartName,
          description: relationship.description,
          default: relationship.defaultRelationship,
          lock_user_changes: relationship.lockUserChanges,
          lock_counterpart_user_changes: relationship.lockCounterpartUserChanges,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  deleteRelationship(relationship) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/relationships/${relationship.id}`)
        .then(response => {
          resolve(response);
          this.removeRelationship(response.data);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  createUserRelationship(userId, counterpartUserId, relationshipId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/user_relationships`, {
          user_id: userId,
          counterpart_user_id: counterpartUserId,
          relationship_id: relationshipId,
        })
        .then(response => {
          this.addUserRelationship(response.data);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchUserRelationships(userId) {
    if (typeof this.activeRelationship === 'object') {
      return new Promise((resolve, reject) => {
        axios
          .get(`${Cookies.get('apiEnv')}/user_relationships?user_id=${userId}&relationship_id=${this.activeRelationship.id}&`)
          .then(response => {
            _.map(response.data, relationship => {
              this.addUserRelationship(relationship);
            });
            resolve(response);
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          });
      });
    }
    return undefined;
  }

  @action
  fetchUserRelationshipsAll() {
    if (typeof this.activeRelationship === 'object') {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${Cookies.get('apiEnv')}/user_relationships?relationship_id=${this.activeRelationship.id}&page=1&per_page=${
              this.totalActiveUserRelationships
            }`
          )
          .then(response => {
            _.map(response.data, relationship => {
              this.addUserRelationship(relationship);
            });
            resolve(response);
          })
          .catch(error => {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          });
      });
    }
    return undefined;
  }

  @action
  fetchUserRelationshipsTotal(relationshipId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_relationships?relationship_id=${relationshipId}&page=1&per_page=1&offset=0`)
        .then(response => {
          this.totalActiveUserRelationships = response.headers['x-total'];
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  deleteUserRelationship(userRelationship) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/user_relationships/${userRelationship.id}`)
        .then(response => {
          resolve(response);
          this.removeUserRelationship(response.data);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  loadAllUserRelationships() {
    this.loadingAllUserRelationships = true;
    return new Promise(resolve => {
      this.fetchUserRelationshipsAll().then(response => {
        let totalLength = response.data.length;
        if (totalLength === 0) {
          resolve();
          this.loadingAllUserRelationships = false;
        } else {
          _.map(response.data, userRelationship => {
            this.fetchUser(userRelationship.user_id).then(() => {
              totalLength--;
              if (totalLength === 0) {
                resolve();
                this.loadingAllUserRelationships = false;
              }
            });
            this.fetchUser(userRelationship.counterpart_user_id).then(() => {
              totalLength--;
              if (totalLength === 0) {
                resolve();
                this.loadingAllUserRelationships = false;
              }
            });
          });
        }
      });
    });
  }

  @action
  selectAndFetchNewUser(user) {
    return new Promise(resolve => {
      this.appendToUsers(user);
      this.fetchUserRelationships(user.id).then(response => {
        let totalLength = response.data.length;
        if (totalLength === 0) {
          resolve();
        } else {
          _.map(response.data, userRelationship => {
            if (userRelationship.user_id === user.id) {
              this.fetchUser(userRelationship.counterpart_user_id).then(() => {
                totalLength--;
                if (totalLength === 0) {
                  resolve();
                }
              });
            } else {
              this.fetchUser(userRelationship.user_id).then(() => {
                totalLength--;
                if (totalLength === 0) {
                  resolve();
                }
              });
            }
          });
        }
      });
    });
  }

  @action
  removeHangingUsers() {
    return new Promise(resolve => {
      let totalLength = this.users.length;
      if (totalLength === 0) {
        resolve();
      } else {
        _.map(this.users, user => {
          let userIsHanging = true;
          _.map(this.userRelationships, userRelationship => {
            if (userRelationship.userId === user.id || userRelationship.counterpartUserId === user.id) {
              userIsHanging = false;
            }
          });
          if (user.id === this.activeUser.id) {
            userIsHanging = false;
          }
          if (userIsHanging) {
            this.removeUser(user.id);
          }
          totalLength--;
          if (totalLength === 0) {
            resolve();
          }
        });
      }
    });
  }

  @action
  addUserNode(user) {
    let title = user.fullName;
    let darkMode = Cookies.get('theme') === 'dark';
    if (CurrentUserStore.currentUser.isAccountOwner) {
      title = `Double click to set relationships for ${user.firstName}`;
    }

    const newUserNode = {
      id: user.id,
      label: user.fullName,
      title: title,
      shape: 'circularImage',
      image: user.imageFileName,
      font: {
        face: 'Inter',
        color: darkMode == true ? '#ffffff' : '#1c2127',
      },
      labelHighlightBold: false,
      physics: true,
      borderWidth: 3,
      borderWidthSelected: 5,
      color: {
        border: `#E1E8ED`,
        highlight: darkMode == true ? '#FBD065' : '#0000FA',
        hover: {
          border: darkMode == true ? '#FBD065' : '#0000FA',
        },
      },
    };
    this.userNodes = _.unionBy([newUserNode], this.userNodes, 'id');
  }

  @action
  addUserRelationshipNode(userRelationship) {
    let darkMode = Cookies.get('theme') === 'dark';
    const newUserRelationshipNode = {
      id: userRelationship.id,
      from: userRelationship.counterpartUserId,
      to: userRelationship.userId,
      dashes: true,
      width: 2,
      color: {
        color: `#BFCCD6`,
        highlight: darkMode == true ? '#FBD065' : '#0000FA',
        hover: darkMode == true ? '#FBD065' : '#0000FA',
      },
    };
    this.userRelationshipNodes = _.unionBy([newUserRelationshipNode], this.userRelationshipNodes, 'id');
  }

  @action
  sortRelationships() {
    let newRelationships = this.relationships;
    newRelationships = _.orderBy(newRelationships, ['name'], ['asc']);
    this.relationships = newRelationships;
  }

  @action
  sortUsers() {
    let newUsers = this.users;
    newUsers = _.orderBy(newUsers, ['firstName'], ['asc']);
    this.users = newUsers;
  }

  @action
  resetTempArrays() {
    this.subOrdinateUserDelta = [];
    this.superOrdinateUserDelta = [];
    this.superUserRelationshipsToCreate = [];
    this.superUserRelationshipsToDelete = [];
    this.subUserRelationshipsToCreate = [];
    this.subUserRelationshipsToDelete = [];
  }

  @action
  resetUsersAndRelationships() {
    this.activeUser = new User();
    this.totalActiveUserRelationships = '';
    this.users = [];
    this.userNodes = [];
    this.userRelationships = [];
    this.userRelationshipNodes = [];
  }
}

const store = new OrganizationStore();
export default store;
