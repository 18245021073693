import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import PlanWidget from '../plans/PlanWidget';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Cookies from 'js-cookie';
import InjectedCheckoutForm from './AccountBillingCheckoutForm';
import AccountBillingChangePlan from './AccountBillingChangePlan';
import _ from 'lodash';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class AccountBillingPlans extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dialogOpen: false, changeDialogOpen: false };
  }

  toggleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  toggleChangeDialog() {
    this.setState({ changeDialogOpen: !this.state.changeDialogOpen });
  }

  handleSelectPlan(plan) {
    if (this.props.AccountBillingStore.subscriptions.length > 0) {
      if (plan.id === this.props.AccountBillingStore.subscribedPlan.id) {
        this.props.AccountBillingStore.setActiveTab('subscription');
      } else {
        this.props.AccountBillingStore.selectedPlan = plan;
        this.toggleChangeDialog();
      }
    } else {
      this.props.AccountBillingStore.selectedPlan = plan;
      this.toggleDialog();
    }
  }

  renderChangeDialog() {
    if (this.props.AccountBillingStore.subscriptions.length > 0) {
      return <AccountBillingChangePlan toggleDialog={this.toggleChangeDialog.bind(this)} dialogOpen={this.state.changeDialogOpen} />;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <Flexbox flexDirection="row" flexWrap="wrap" flexGrow={1}>
          {_.map(this.props.AccountBillingStore.plans, plan => (
            <PlanWidget key={plan.id} plan={plan} handleSelectPlan={this.handleSelectPlan.bind(this)} />
          ))}
          <StripeProvider apiKey={Cookies.get('stripePublishableKey')}>
            <Elements
              fonts={[
                {
                  cssSrc: 'https://fonts.googleapis.com/css?family=Inter:300,400,600,700',
                },
              ]}
            >
              <InjectedCheckoutForm toggleDialog={this.toggleDialog.bind(this)} dialogOpen={this.state.dialogOpen} />
            </Elements>
          </StripeProvider>
          {this.renderChangeDialog()}
        </Flexbox>
      </Flexbox>
    );
  }
}
