const ptColors = [
  {
    id: 0,
    name: '@vermillion1',
    code: '#9E2B0E',
    color: 'white',
  },
  {
    id: 1,
    name: '@vermillion2',
    code: '#B83211',
    color: 'white',
  },
  {
    id: 2,
    name: '@vermillion3',
    code: '#D13913',
    color: 'white',
  },
  {
    id: 3,
    name: '@vermillion4',
    code: '#EB532D',
    color: 'black',
  },
  {
    id: 4,
    name: '@vermillion5',
    code: '#FF6E4A',
    color: 'black',
  },
  {
    id: 5,
    name: '@rose1',
    code: '#A82255',
    color: 'white',
  },
  {
    id: 6,
    name: '@rose2',
    code: '#C22762',
    color: 'white',
  },
  {
    id: 7,
    name: '@rose3',
    code: '#DB2C6F',
    color: 'white',
  },
  {
    id: 8,
    name: '@rose4',
    code: '#F5498B',
    color: 'black',
  },
  {
    id: 9,
    name: '@rose5',
    code: '#FF66A1',
    color: 'black',
  },
  {
    id: 10,
    name: '@violet1',
    code: '#5C255C',
    color: 'white',
  },
  {
    id: 11,
    name: '@violet2',
    code: '#752F75',
    color: 'white',
  },
  {
    id: 12,
    name: '@violet3',
    code: '#8F398F',
    color: 'white',
  },
  {
    id: 13,
    name: '@violet4',
    code: '#A854A8',
    color: 'white',
  },
  {
    id: 14,
    name: '@violet5',
    code: '#C274C2',
    color: 'black',
  },
  {
    id: 15,
    name: '@indigo1',
    code: '#5642A6',
    color: 'white',
  },
  {
    id: 16,
    name: '@indigo2',
    code: '#634DBF',
    color: 'white',
  },
  {
    id: 17,
    name: '@indigo3',
    code: '#7157D9',
    color: 'white',
  },
  {
    id: 18,
    name: '@indigo4',
    code: '#9179F2',
    color: 'black',
  },
  {
    id: 19,
    name: '@indigo5',
    code: '#AD99FF',
    color: 'black',
  },
  {
    id: 20,
    name: '@cobalt1',
    code: '#1F4B99',
    color: 'white',
  },
  {
    id: 21,
    name: '@cobalt2',
    code: '#2458B3',
    color: 'white',
  },
  {
    id: 22,
    name: '@cobalt3',
    code: '#2965CC',
    color: 'white',
  },
  {
    id: 23,
    name: '@cobalt4',
    code: '#4580E6',
    color: 'black',
  },
  {
    id: 24,
    name: '@cobalt5',
    code: '#669EFF',
    color: 'black',
  },
  {
    id: 25,
    name: '@turquoise1',
    code: '#008075',
    color: 'white',
  },
  {
    id: 26,
    name: '@turquoise2',
    code: '#00998C',
    color: 'black',
  },
  {
    id: 27,
    name: '@turquoise3',
    code: '#00B3A4',
    color: 'black',
  },
  {
    id: 28,
    name: '@turquoise4',
    code: '#14CCBD',
    color: 'black',
  },
  {
    id: 29,
    name: '@turquoise5',
    code: '#2EE6D6',
    color: 'black',
  },
  {
    id: 30,
    name: '@forest1',
    code: '#1D7324',
    color: 'white',
  },
  {
    id: 31,
    name: '@forest2',
    code: '#238C2C',
    color: 'white',
  },
  {
    id: 32,
    name: '@forest3',
    code: '#29A634',
    color: 'black',
  },
  {
    id: 33,
    name: '@forest4',
    code: '#43BF4D',
    color: 'black',
  },
  {
    id: 34,
    name: '@forest5',
    code: '#62D96B',
    color: 'black',
  },
  {
    id: 35,
    name: '@lime1',
    code: '#728C23',
    color: 'black',
  },
  {
    id: 36,
    name: '@lime2',
    code: '#87A629',
    color: 'black',
  },
  {
    id: 37,
    name: '@lime3',
    code: '#9BBF30',
    color: 'black',
  },
  {
    id: 38,
    name: '@lime4',
    code: '#B6D94C',
    color: 'black',
  },
  {
    id: 39,
    name: '@lime5',
    code: '#D1F26D',
    color: 'black',
  },
  {
    id: 40,
    name: '@gold1',
    code: '#A67908',
    color: 'black',
  },
  {
    id: 41,
    name: '@gold2',
    code: '#BF8C0A',
    color: 'black',
  },
  {
    id: 42,
    name: '@gold3',
    code: '#D99E0B',
    color: 'black',
  },
  {
    id: 43,
    name: '@gold4',
    code: '#F2B824',
    color: 'black',
  },
  {
    id: 44,
    name: '@gold5',
    code: '#FFC940',
    color: 'black',
  },
];

class ColorDefinitions {
  static get ptColors() {
    return ptColors;
  }
}

export default ColorDefinitions;
