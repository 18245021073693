import React from 'react';
import Flexbox from 'flexbox-react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import ToastStore from '../stores/ToastStore';
import AppStore from '../stores/AppStore';
import TemplateStore from '../stores/TemplateStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';
import ItemStore from '../stores/ItemStore';
import UserProfileStore from '../stores/UserProfileStore';
import TemplateActions from '../actions/TemplateActions';
import CurrentUserStore from '../stores/CurrentUserStore';
import TemplateEdit from '../components/templates/TemplateEdit';

import ErrorBoundary from '../components/errors/ErrorBoundary';

@observer
export default class TemplateView extends React.Component {
  @observable
  isLoading = true;

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  componentDidMount() {
    TemplateStore.resetStartingAttributes();
    TemplateActions.fetchRelationships()
      .then(() => {
        TemplateStore.addNullRelationship();
        TemplateActions.fetchParticipants(AppStore.activeApp.id)
          .then(() => {
            TemplateActions.fetchSections(AppStore.activeApp.id)
              .then(() => {
                TemplateActions.fetchStatusFlags(AppStore.activeApp.id)
                  .then(() => {
                    TemplateActions.fetchStates(AppStore.activeApp.id)
                      .then(this.toggleLoading)
                      .catch(this.toggleLoading);
                  })
                  .catch(this.toggleLoading);
              })
              .catch(this.toggleLoading);
          })
          .catch(this.toggleLoading);
      })
      .catch(this.toggleLoading);
  }

  render() {
    const stores = {
      UserProfileTemplateStore,
      TemplateStore,
      TemplateActions,
      CurrentUserStore,
      ToastStore,
      ItemStore,
      UserProfileStore,
    };
    if (this.isLoading || CurrentUserStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="50px" paddingBottom="50px">
          <NonIdealState
            title={I18n.t('js.loading')}
            description={I18n.t('js.fetching_data')}
            icon={<Spinner />}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Provider {...stores}>
        <ErrorBoundary>
          <TemplateEdit />
        </ErrorBoundary>
      </Provider>
    );
  }
}
