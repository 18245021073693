/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';
import _ from 'lodash';
import ToastStore from '../stores/ToastStore';
import StateRuleStore from '../stores/StateRuleStore';

class StateRuleActions {
  @action
  loadInitialData(stateId) {
    StateRuleStore.resetStartingAttributes();
    this.fetchStateRules(stateId)
      .then(() => {
        StateRuleStore.isLoading = false;
      })
      .catch(() => {
        StateRuleStore.isLoading = false;
      });
  }

  @action
  fetchStateRules(stateId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get('apiEnv')}/state_rules?state_id=${stateId}`
        )
        .then(response => {
          let total = response.data.length;
          _.map(response.data, rule => {
            StateRuleStore.addStateRule(rule);
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStateRule(ruleObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/state_rules`, ruleObj)
        .then(response => {
          StateRuleStore.addStateRule(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStateRule(ruleObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/state_rules/${ruleObj.id}`,
          ruleObj
        )
        .then(response => {
          StateRuleStore.addStateRule(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteStateRule(stateRuleId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/state_rules/${stateRuleId}`)
        .then(response => {
          StateRuleStore.removeStateRule(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStateCondition(conditionObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Cookies.get('apiEnv')}/state_conditions`,
          conditionObj
        )
        .then(response => {
          StateRuleStore.addStateCondition(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStateCondition(conditionObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/state_conditions/${
            conditionObj.id
          }`,
          conditionObj
        )
        .then(response => {
          StateRuleStore.addStateCondition(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteStateCondition(stateConditionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Cookies.get(
            'apiEnv'
          )}/state_conditions/${stateConditionId}`
        )
        .then(response => {
          StateRuleStore.removeStateCondition(response.data.id, true);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStateConditionValue(conditionValueObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Cookies.get('apiEnv')}/state_condition_values`,
          conditionValueObj
        )
        .then(response => {
          // StateRuleStore.addStateConditionValue(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStateConditionValue(conditionValueObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/state_condition_values/${
            conditionValueObj.id
          }`,
          conditionValueObj
        )
        .then(response => {
          // StateRuleStore.addStateConditionValue(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStateAction(actionObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/state_actions`, actionObj)
        .then(response => {
          StateRuleStore.addStateAction(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStateAction(actionObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/state_actions/${actionObj.id}`,
          actionObj
        )
        .then(response => {
          StateRuleStore.addStateAction(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteStateAction(stateActionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Cookies.get('apiEnv')}/state_actions/${stateActionId}`
        )
        .then(response => {
          StateRuleStore.removeStateAction(response.data.id, true);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStateActionEmailRecipient(recipientObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Cookies.get('apiEnv')}/state_action_email_recipients`,
          recipientObj
        )
        .then(response => {
          StateRuleStore.addStateActionEmailRecipient(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStateActionEmailRecipient(recipientObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/state_action_email_recipients/${
            recipientObj.id
          }`,
          recipientObj
        )
        .then(response => {
          StateRuleStore.addStateActionEmailRecipient(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteStateActionEmailRecipient(recipientId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Cookies.get(
            'apiEnv'
          )}/state_action_email_recipients/${recipientId}`
        )
        .then(response => {
          StateRuleStore.removeStateActionEmailRecipient(
            response.data.id,
            true
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}

const store = new StateRuleActions();
export default store;
