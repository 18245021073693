// @flow
import * as React from 'react';
import SkeletonUtilities from '../../utils/SkeletonUtilities';

const skeletonRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '60px',
};

const avatarStyle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  marginRight: '10px',
};

const descriptionSectionStyle = {
  marginRight: 'auto',
};

const dateStyle = {
  margin: '0',
  height: '1em',
};

type SkeletonRowProps = {
  descriptionOneLength: number,
  descriptionTwoLength: number,
};

class SkeletonRow extends React.Component<SkeletonRowProps> {
  static defaultProps = {
    descriptionOneLength: 35,
    descriptionTwoLength: 30,
  };

  render() {
    const { descriptionOneLength, descriptionTwoLength } = this.props;
    const { emptyString } = SkeletonUtilities;
    const placeholderLength = 15;
    return (
      <div className="skeleton-row" style={skeletonRowStyle}>
        <div
          className="skeleton-row__avatar bp3-skeleton"
          style={avatarStyle}
        />
        <div
          className="skeleton-row__description-company"
          style={descriptionSectionStyle}
        >
          <p className="skeleton-row__description-one bp3-skeleton">
            {emptyString(descriptionOneLength)}
          </p>
          <p className="skeleton-row_description-two bp3-skeleton">
            {emptyString(descriptionTwoLength)}
          </p>
        </div>
        <p
          className="skeleton-row_date bp3-skeleton"
          style={dateStyle}
        >
          {emptyString(placeholderLength)}
        </p>
      </div>
    );
  }
}

export default SkeletonRow;
