// @flow
import * as React from 'react';
import styled from 'styled-components';

// dimensions of iframe are video res / 2
// shadow is blueprint card elevation 3
const VideoContainer = styled.div`
  margin: 128px auto;
  width: 100%;
  height: 540px
  max-width: 960px;
  box-shadow: 0 0 0 1px rgba(16,22,26,.1),
              0 2px 4px rgba(16,22,26,.2),
              0 8px 24px rgba(16,22,26,.2);

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default () => (
  <VideoContainer>
    <iframe
      src="https://fast.wistia.net/embed/iframe/ohgbsukpi3?videoFoam=true"
      title="Wistia video player"
      frameBorder="0"
      scrolling="no"
      className="wistia_embed"
      name="wistia_embed"
      width="100%"
      height="100%"
    />
    <script
      src="https://fast.wistia.net/assets/external/E-v1.js"
      async
    />
  </VideoContainer>
);
// ?videoFoam=true
