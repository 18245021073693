import React from 'react';
import Flexbox from 'flexbox-react';
import { Spinner, Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Utilities from '../../utils/Utilities';

@inject('AccountUsersStore', 'ToastStore')
@observer
export default class AccountUsersTabs extends React.Component {
  handleTabChange(tab) {
    if (tab == 'dataview') {
      window.location = `/account/users?tab=dataview`;
      return;
    }
    this.props.AccountUsersStore.setActiveTab(tab);
  }

  renderUsersTitle = () => <span>{I18n.t('js.users')}</span>;

  renderRequestsTitle = () => <span>{I18n.t('js.requests')}</span>;

  renderInvitationsTitle = () => <span>{I18n.t('js.invitations')}</span>;

  renderImportTitle = () => <span>{I18n.t('js.import_users')}</span>;

  renderDataViewTab = () => <span>{I18n.t('js.data')}</span>;

  renderRightLinks() {
    if (this.props.loading) {
      return <Spinner size={20} />;
    }
    return (
      <Tabs id="accountUsersTabnav" selectedTabId={this.props.AccountUsersStore.activeTab} onChange={this.handleTabChange.bind(this)}>
        <Tab id="users" title={this.renderUsersTitle()} />
        <Tab id="requests" title={this.renderRequestsTitle()} />
        <Tab id="invitations" title={this.renderInvitationsTitle()} />
        <Tab id="import" title={this.renderImportTitle()} />
        <Tab id="dataview" title={this.renderDataViewTab()} />
        <Tabs.Expander />
      </Tabs>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
