import { action, computed, extendObservable, observable } from 'mobx';
import axios from 'axios';
import Cookies from 'js-cookie';
import UserProfileStore from './UserProfileStore';
import CurrentUserStore from './CurrentUserStore';
import ToastStore from './ToastStore';
import TemplateActions from '../actions/TemplateActions';
import ProfileTemplate from '../models/ProfileTemplateModel';
import Section from '../models/SectionModel';
import Element from '../models/ElementModel';
import StateSection from '../models/StateSectionModel';
import StateElement from '../models/StateElementModel';
import Item from '../models/ItemModel';
import User from '../models/UserModel';
import Team from '../models/TeamModel';
import ProfileValue from '../models/ProfileValueModel';
import ElementMappingActions from '../actions/ElementMappingActions';
import _ from 'lodash';

class UserProfileTemplateStore {
  @observable activeProfileTemplateTab = 'profileTemplate';
  @observable isLoading = true;
  @observable isEditingTemplate = false;
  @observable isEditingProfile = false;
  @observable activeAreaId = null;
  @observable profileTemplates = [];
  @observable sections = [];
  @observable exposedSections = [];
  @observable activeProfileTemplate = '';
  @observable activeSection = new Section();
  @observable activeElement = new Element();
  @observable oldActiveElement = new Element();
  @observable newSection = new Section();
  @observable newProfileTemplate = new ProfileTemplate();
  @observable formValuesToCreate = [];
  @observable formValuesToChange = [];
  @observable formValuesToDelete = [];
  @observable profileErrors = [];
  @observable countDown = 10;
  @observable isSaving = false;
  @observable isCountingDown = false;
  @observable submissionAttempted = false;
  @observable collapsedSections = [];

  @computed
  get availableElementsForFormulaBuilder() {
    const elements = _.filter(this.allElements, o => o.isAvailableForFormulaBuilder);
    const availableElements = [];
    _.map(elements, element => {
      let icon = 'new-text-box';
      let type = 'string';
      switch (element.elementType) {
        case 'number':
        case 'currency':
        case 'percentage':
        case 'slider':
        case 'calculation':
          icon = 'numerical';
          type = 'number';
          break;
        case 'date':
        case 'date_time':
        case 'time':
          icon = 'timeline-events';
          type = 'datetime';
          break;
        case 'grid':
          icon = 'grid';
          type = 'number';
          break;
        case 'select':
          icon = 'selection';
          type = 'number';
          break;
      }
      if (element.id != this.activeElement.id) {
        availableElements.push({
          name: element[element.camelCaseTitle].name,
          id: element.id,
          icon: icon,
          type: type,
          implementation: `"element#${element.id}"`,
        });
      }
    });
    return availableElements;
  }

  @computed
  get newProfileTemplateFormValid() {
    return this.newProfileTemplate.nameValid && this.newProfileTemplate.descriptionValid;
  }

  @computed
  get hasChanges() {
    return this.formValuesToCreate.length > 0 || this.formValuesToChange.length > 0 || this.formValuesToDelete.length > 0;
  }

  @computed
  get profileHasError() {
    return this.profileErrors.length > 0;
  }

  @computed
  get activeSections() {
    if (CurrentUserStore.userIsAccountOwner || UserProfileStore.isCurrentUser) {
      return _.filter(this.sections.slice(), section => section.profileTemplateId === this.activeProfileTemplate.id);
    }
    return _.filter(
      this.sections.slice(),
      section => section.profileTemplateId === this.activeProfileTemplate.id && !section.privateView && !section.accountOwnerOnlyView
    );
  }

  @computed
  get visibleSections() {
    let visibleSections = [];
    _.map(this.activeSections, section => {
      if (!this.exposedSections.includes(section.id) && section.optional) {
        // do nothing
      } else {
        visibleSections.push(section);
      }
    });
    return visibleSections;
  }

  @computed
  get allElements() {
    let elements = [];
    _.map(this.sections, section => {
      _.map(section.elements, element => {
        if (!element.isDisplayElement) {
          elements = elements.concat(element);
        }
      });
    });
    return elements;
  }

  @computed
  get dashboardProfileElements() {
    return _.filter(this.allElements, o => o.displayOnDashboard === true);
  }

  @action
  exposeSection(id) {
    this.exposedSections = _.unionBy(this.exposedSections, [id]);
  }

  @action
  removeExposedSection(id) {
    this.exposedSections = _.filter(this.exposedSections, o => o != id);
    // Remove item_values for sections that are removed from exposure
    _.map(this.sections, section => {
      if (section.id == id) {
        _.map(section.elements, element => {
          _.map(element.formValues, formValue => {
            if (formValue.createdAt) {
              this.addFormValueToDelete({ id: formValue.id });
            }
          });
          element.formValues = [];
          this.formValuesToCreate = _.filter(this.formValuesToCreate, o => o.element_id != element.id);
          this.itemErrors = _.filter(this.itemErrors, o => o.elementId != element.id);
        });
      }
    });
  }

  @action
  toggleEditProfile() {
    this.isEditingProfile = !this.isEditingProfile;
    this.isEditingTemplate = false;
    this.setEditableAttributes();
  }

  @action
  toggleEditTemplate() {
    this.isEditingTemplate = !this.isEditingTemplate;
    this.isEditingProfile = false;
    this.setEditableAttributes();
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  setActiveProfileTemplate(profileTemplate) {
    this.toggleLoading();
    this.formValuesToCreate = [];
    this.formValuesToChange = [];
    this.formValuesToDelete = [];
    this.profileErrors = [];
    this.submissionAttempted = false;
    TemplateActions.fetchProfileValues(UserProfileStore.user.id, profileTemplate.id)
      .then(response => {
        _.map(response.data, formValue => {
          this.addProfileValue(formValue);
        });
        this.activeProfileTemplate = profileTemplate;
        this.toggleLoading();
      })
      .catch(() => {
        this.activeProfileTemplate = profileTemplate;
        this.toggleLoading();
      });
  }

  @action
  setActiveArea(id) {
    if (id === null) {
      this.activeAreaId = null;
    } else {
      if (id !== this.activeAreaId) {
        this.activeAreaId = id;
        _.map(this.sections, section => {
          const findElement = _.find(section.elements, element => element.id === id);
          if (findElement) {
            this.oldActiveElement = _.cloneDeep(findElement);
            this.activeElement = _.cloneDeep(findElement);
          }
        });
      }
    }
  }

  @action
  matchActiveProfileTemplate(authorizedAccount) {
    if (authorizedAccount.profileTemplateId.length > 0) {
      const findProfileTemplate = _.find(this.profileTemplates, o => o.id === authorizedAccount.profileTemplateId);
      if (findProfileTemplate) {
        this.setActiveProfileTemplate(findProfileTemplate);
      }
    }
  }

  @action
  updateNewProfileTemplate(data, attribute) {
    this.newProfileTemplate[attribute] = data;
  }

  @action
  updateActiveElement(data, attribute) {
    if (attribute === 'minLength' || attribute === 'maxLength' || attribute === 'isRequired' || attribute === 'repeatable') {
      this.activeElement[attribute] = data;
    } else {
      this.activeElement[this.activeElement.camelCaseTitle][attribute] = data;
    }
  }

  @action
  toggleSectionCollapse(sectionId) {
    if (_.find(this.collapsedSections, o => o === sectionId)) {
      this.collapsedSections = _.filter(this.collapsedSections, o => o != sectionId);
    } else {
      this.collapsedSections.push(sectionId);
    }
  }

  @action
  showAllSections() {
    this.collapsedSections = [];
  }

  @action
  addProfileTemplate(data) {
    const newProfileTemplate = new ProfileTemplate(
      data.id,
      data.account_id,
      data.name,
      data.description,
      data.default,
      data.icon,
      data.is_store_template,
      data.store_template_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.profileTemplates = _.unionBy([newProfileTemplate], this.profileTemplates, 'id');
    if (data.sections.length > 0) {
      _.map(data.sections, section => {
        this.addSection(section, 'all');
      });
    }
  }

  @action
  addSection(data, type) {
    const newSection = new Section(
      data.id,
      data.section_type,
      data.app_id,
      data.profile_template_id,
      data.title,
      data.private_view,
      data.protected_view,
      data.account_owner_only_view,
      data.default_collapsed,
      data.optional,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    if (newSection.defaultCollapsed) {
      this.collapsedSections.push(newSection.id);
    }
    extendObservable(newSection, {
      elements: ElementMappingActions.mapElements(data.elements),
    });
    _.map(newSection.elements, element => {
      extendObservable(element, { formValues: [] });
    });

    if (type === 'single') {
      this.activeSection = _.clone(newSection);
      this.newSection = _.clone(newSection);
    } else {
      this.sections = _.unionBy([newSection], this.sections, 'id');
      this.originalSections = _.unionBy([newSection], this.sections, 'id');
    }
    this.appendBlankStateSectionsAndElements();
    this.setEditableAttributes();
    this.sortSections();
  }

  @action
  addElement(data) {
    const findSection = _.find(this.sections, o => o.id === data.section_id);
    if (findSection) {
      const newElements = ElementMappingActions.mapElements([data]);
      findSection.elements = _.unionBy(newElements, findSection.elements, 'id');
      findSection.elements = _.orderBy(findSection.elements, ['rowOrder'], ['asc']);
      this.appendBlankStateSectionsAndElements();
    }
    this.setEditableAttributes();
    this.sortSections();
  }

  @action
  setEditableAttributes() {
    const newSections = this.sections;
    _.map(newSections, section => {
      _.map(section.elements, element => {
        if (this.isEditingProfile) {
          if (CurrentUserStore.userIsAccountOwner) {
            element.isEditable = true;
          } else {
            if (UserProfileStore.isCurrentUser) {
              if (element.protectedView || section.protectedView) {
                element.isEditable = false;
              } else {
                element.isEditable = true;
              }
            }
          }
        } else {
          element.isEditable = false;
        }
      });
    });
    this.sections = newSections;
  }

  @action
  addProfileValue(data) {
    _.map(this.sections, section => {
      _.map(section.elements, element => {
        if (element.id === data.element_id) {
          if (section.optional) {
            this.exposeSection(section.id);
          }
          const newProfileValue = new ProfileValue(
            data.id,
            data.user_id,
            data.profile_template_id,
            data.element_id,
            data.profile_value_type,
            data.string_value,
            data.number_value,
            data.decimal_value,
            data.boolean_value,
            data.date_value,
            data.image_file_name,
            data.attachment_file_name,
            data.element_select_option_id,
            data.element_object_select_item_id,
            data.element_object_select_user_id,
            data.element_object_select_team_id,
            data.element_grid_column_id,
            data.element_grid_row_id,
            data.element_multiline_input_id,
            data.row_order,
            data.created_at,
            data.created_by,
            data.deleted_at,
            data.deleted_by,
            data.updated_at,
            data.updated_by
          );

          if (data.reference_user) {
            newProfileValue.referenceUser = new User(
              data.reference_user.id,
              data.reference_user.email,
              data.reference_user.first_name,
              data.reference_user.last_name,
              data.reference_user.full_name,
              data.reference_user.account_id,
              data.reference_user.human_friendly_id,
              data.reference_user.is_current_user,
              data.reference_user.is_account_owner,
              data.reference_user.image_file_name,
              data.reference_user.background_image_file_name,
              data.reference_user.last_sign_in_at,
              data.reference_user.locale,
              data.reference_user.theme,
              data.reference_user.created_at,
              data.reference_user.updated_at,
              data.reference_user.deleted_at,
              data.reference_user.created_by,
              data.reference_user.updated_by,
              data.reference_user.deleted_by
            );
          }
          if (data.reference_team) {
            newProfileValue.referenceTeam = new Team(
              data.reference_team.id,
              data.reference_team.account_id,
              data.reference_team.is_default_account,
              data.reference_team.name,
              data.reference_team.description,
              data.reference_team.image_file_name,
              data.reference_team.background_image_file_name,
              data.reference_team.private,
              data.reference_team.is_current_user_team_owner,
              data.reference_team.is_current_user_team_member,
              data.reference_team.row_order,
              data.reference_team.user_count,
              data.reference_team.comment_count,
              data.reference_team.import_id,
              data.reference_team.created_at,
              data.reference_team.created_by,
              data.reference_team.deleted_at,
              data.reference_team.deleted_by,
              data.reference_team.updated_at,
              data.reference_team.updated_by
            );
          }
          if (data.reference_item) {
            newProfileValue.referenceItem = new Item(
              data.reference_item.id,
              data.reference_item.app_id,
              data.reference_item.state_id,
              data.reference_item.user_id,
              data.reference_item.status_flag_id,
              data.reference_item.name,
              data.reference_item.item_type,
              data.reference_item.item_icon,
              data.reference_item.item_color,
              data.reference_item.item_layout,
              data.reference_item.image_file_name,
              data.reference_item.comment_count,
              data.reference_item.is_done_for_current_user,
              data.reference_item.is_webform,
              data.reference_item.import_id,
              data.reference_item.webform_user_email,
              data.reference_item.webform_user_full_name,
              data.reference_item.webform_user_image_file_name,
              data.reference_item.dataview,
              data.reference_item.simple_dataview,
              data.reference_item.created_at,
              data.reference_item.created_by,
              data.reference_item.deleted_at,
              data.reference_item.deleted_by,
              data.reference_item.updated_at,
              data.reference_item.updated_by
            );
          }
          let newFormValues = _.unionBy([newProfileValue], element.formValues, 'id');
          if (element.repeatable) {
            newFormValues = _.orderBy(newFormValues, ['rowOrder'], ['asc']);
          } else {
            newFormValues = _.orderBy(newFormValues, ['updatedAt'], ['desc']);
          }
          element.formValues = newFormValues;
        }
      });
    });
  }

  @action
  removeProfileValue(formValueId) {
    _.map(this.sections, section => {
      _.map(section.elements, element => {
        element.formValues = _.filter(element.formValues, o => o.id !== formValueId);
      });
    });
  }

  @action
  appendBlankStateSectionsAndElements() {
    _.map(this.sections, section => {
      section.stateSection = new StateSection();
      _.map(section.elements, element => {
        element.stateElement = new StateElement();
      });
    });
  }

  @action
  removeProfileTemplate(id) {
    if (this.activeProfileTemplate.id === id) {
      this.activeProfileTemplate = '';
    }
    this.profileTemplates = _.filter(this.profileTemplates, o => o.id !== id);
  }

  @action
  removeSection(data) {
    this.sections = _.filter(this.sections, o => o.id !== data.id);
    this.sortSections();
  }

  @action
  removeElement(data) {
    const findSection = _.find(this.sections, o => o.id === data.section_id);
    if (findSection) {
      findSection.elements = _.filter(findSection.elements, o => o.id !== data.id);
      this.sections = _.unionBy([findSection], this.sections, 'id');
    }
    this.sortSections();
  }

  @action
  sortSections() {
    this.sections = _.orderBy(this.sections, ['rowOrder'], ['asc']);
  }

  @action
  addError(errorObj) {
    const newItemErrors = _.filter(this.profileErrors, error => error.elementId !== errorObj.elementId);
    newItemErrors.push(errorObj);
    this.profileErrors = newItemErrors;
  }

  @action
  clearErrorsForElement(elementId) {
    this.profileErrors = _.filter(this.profileErrors, o => o.elementId !== elementId);
  }

  @action
  addFormValueToChange(formValueObj) {
    this.formValuesToChange = _.unionBy([formValueObj], this.formValuesToChange, 'id');
  }

  @action
  addFormValueToCreate(formValueObj) {
    formValueObj.orderToPost = this.formValuesToCreate.length;
    this.formValuesToCreate = _.unionBy([formValueObj], this.formValuesToCreate, 'id');
  }

  @action
  addFormValueToDelete(formValueObj) {
    this.formValuesToDelete = _.unionBy([formValueObj], this.formValuesToDelete, 'id');
  }

  @action
  removeFormValueToChange(id) {
    this.formValuesToChange = _.filter(this.formValuesToChange, o => o.id !== id);
  }

  @action
  removeFormValueToCreate(id) {
    this.formValuesToCreate = _.filter(this.formValuesToCreate, o => o.id !== id);
  }

  @action
  removeFormValueToDelete(id) {
    this.formValuesToDelete = _.filter(this.formValuesToDelete, o => o.id !== id);
  }

  @action
  cancelProfileChanges() {
    this.setActiveProfileTemplate(this.activeProfileTemplate);
    this.isEditingProfile = false;
  }

  @action
  resetCountDown() {
    this.countDown = 10;
  }

  @action
  processCountDown() {
    if (this.countDown > 0 && this.isCountingDown) {
      --this.countDown;
    } else {
      if (this.hasChanges) {
        ToastStore.showSavingToast(I18n.t('js.saving_your_changes'));
        // TemplateActions.handleSave().then((response) => {
        // 	this.resetCountDown();
        // }).catch((error) => {
        // 	this.resetCountDown();
        // });
      } else {
        this.resetCountDown();
      }
    }
  }

  @action
  startCountDown() {
    if (this.isCountingDown === false) {
      this.resetCountDown();
      this.isCountingDown = true;
      const intervalDuration = 1000;
      this.interval = setInterval(() => {
        this.processCountDown();
      }, intervalDuration);
    }
  }

  @action
  pauseCountDown() {
    clearInterval(this.interval);
    this.isCountingDown = false;
  }

  @action
  resetTemplate() {
    this.activeAreaId = null;
    this.sections = [];
    this.activeProfileTemplate = '';
    this.activeSection = new Section();
    this.activeElement = new Element();
    this.oldActiveElement = new Element();
    this.newSection = new Section();
    this.newProfileTemplate = new ProfileTemplate();
  }

  @action
  resetStartingAttributes() {
    this.activeProfileTemplateTab = 'profileTemplate';
    this.isLoading = true;
    this.isEditingTemplate = false;
    this.isEditingProfile = false;
    this.activeAreaId = null;
    this.profileTemplates = [];
    this.sections = [];
    this.exposedSections = [];
    this.activeProfileTemplate = '';
    this.activeSection = new Section();
    this.activeElement = new Element();
    this.oldActiveElement = new Element();
    this.newSection = new Section();
    this.newProfileTemplate = new ProfileTemplate();
    this.formValuesToCreate = [];
    this.formValuesToChange = [];
    this.formValuesToDelete = [];
    this.profileErrors = [];
    this.countDown = 10;
    this.isSaving = false;
    this.isCountingDown = false;
    this.submissionAttempted = false;
    this.collapsedSections = [];
  }

  @action
  resetNewProfileTemplate() {
    this.newProfileTemplate = new ProfileTemplate();
  }
}

const store = new UserProfileTemplateStore();
export default store;
