/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';
import CountryDefinitions from '../static/CountryDefinitions';
import _ from 'lodash';

export default class Subscription {
  @observable
  id;
  @observable
  planId;
  @observable
  userId;
  @observable
  accountId;
  @observable
  applicationFeePercent;
  @observable
  billing;
  @observable
  billingCycleAnchor;
  @observable
  coupon;
  @observable
  cancelAtPeriodEnd;
  @observable
  canceledAt;
  @observable
  currentPeriodEnd;
  @observable
  currentPeriodStart;
  @observable
  customer;
  @observable
  daysUntilDue;
  @observable
  discount;
  @observable
  endedAt;
  @observable
  quantity;
  @observable
  start;
  @observable
  status;
  @observable
  taxPercent;
  @observable
  cardHoldersName;
  @observable
  addressLine1;
  @observable
  addressLine2;
  @observable
  city;
  @observable
  stateCounty;
  @observable
  zip;
  @observable
  taxNumber;
  @observable
  country;
  @observable
  companyName;
  @observable
  trialEnd;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    planId,
    userId,
    accountId,
    applicationFeePercent,
    billing,
    billingCycleAnchor,
    coupon,
    cancelAtPeriodEnd,
    canceledAt,
    currentPeriodEnd,
    currentPeriodStart,
    customer,
    daysUntilDue,
    discount,
    endedAt,
    quantity,
    start,
    status,
    taxPercent,
    cardHoldersName,
    addressLine1,
    addressLine2,
    city,
    stateCounty,
    zip,
    taxNumber,
    country,
    companyName,
    trialEnd,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.planId = planId || '';
    this.userId = userId || '';
    this.accountId = accountId || '';
    this.applicationFeePercent = applicationFeePercent || '';
    this.billing = billing || '';
    this.billingCycleAnchor = billingCycleAnchor || '';
    this.coupon = coupon || '';
    this.cancelAtPeriodEnd = cancelAtPeriodEnd || '';
    this.canceledAt = canceledAt || '';
    this.currentPeriodEnd = currentPeriodEnd || '';
    this.currentPeriodStart = currentPeriodStart || '';
    this.customer = customer || '';
    this.daysUntilDue = daysUntilDue || '';
    this.discount = discount || '';
    this.endedAt = endedAt || '';
    this.quantity = quantity || '';
    this.start = start || '';
    this.status = status || '';
    this.taxPercent = taxPercent || '';
    this.cardHoldersName = cardHoldersName || '';
    this.addressLine1 = addressLine1 || '';
    this.addressLine2 = addressLine2 || '';
    this.city = city || '';
    this.stateCounty = stateCounty || '';
    this.zip = zip || '';
    this.taxNumber = taxNumber || '';
    this.country = country || '';
    this.companyName = companyName || '';
    this.trialEnd = trialEnd || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get cardHoldersNameValid() {
    if (this.cardHoldersName.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get cardHoldersNameError() {
    if (this.cardHoldersName.length < 1) {
      return I18n.t('js.card_holders_name_is_required');
    }
    return null;
  }

  @computed
  get companyNameValid() {
    if (this.companyName.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get companyNameError() {
    if (this.companyName.length < 1) {
      return I18n.t('js.company_name_is_required');
    }
    return null;
  }

  @computed
  get addressLine1Valid() {
    if (this.addressLine1.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get addressLine1Error() {
    if (this.addressLine1.length < 1) {
      return I18n.t('js.address_line_1_is_required');
    }
    return null;
  }

  @computed
  get addressLine2Valid() {
    if (this.addressLine2.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get addressLine2Error() {
    if (this.addressLine2.length < 1) {
      return I18n.t('js.address_line_2_is_required');
    }
    return null;
  }

  @computed
  get cityValid() {
    if (this.city.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get cityError() {
    if (this.city.length < 1) {
      return I18n.t('js.city_is_required');
    }
    return null;
  }

  @computed
  get zipValid() {
    if (this.zip.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get zipError() {
    if (this.zip.length < 1) {
      return I18n.t('js.zip_is_required');
    }
    return null;
  }

  @computed
  get countryValid() {
    if (this.country.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get countryError() {
    if (this.country.length < 1) {
      return I18n.t('js.country_is_required');
    }
    return null;
  }

  @computed
  get calculatedTaxPercent() {
    if (this.country.length > 0) {
      const findCountry = _.find(CountryDefinitions.countries, o => o.code === this.country);
      if (findCountry) {
        if (findCountry.code === 'GB') {
          return findCountry.taxPercent;
        }
        if (
          // lazy fix for legacy code
          // eslint-disable-next-line no-magic-numbers
          findCountry.taxPercent === 20.0 &&
          this.taxNumber.length > 0
        ) {
          return 0.0;
        }
        return findCountry.taxPercent;
      }
      return 0.0;
    }
    return 0.0;
  }

  @computed
  get currentPeriodStartToDate() {
    return moment.utc(this.currentPeriodStart).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get currentPeriodEndToDate() {
    return moment.utc(this.currentPeriodEnd).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get trialEndToDate() {
    return moment.utc(this.trialEnd).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
