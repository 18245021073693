/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import TemplateManageParticipants from './TemplateManageParticipants';
import {
  Button,
  Classes,
  Collapse,
  InputGroup,
  Intent,
  MenuItem,
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { Select } from '@blueprintjs/select';
import Participant from '../../models/ParticipantModel';
import classNames from 'classnames';

const EditParticipantSelect = Select.ofType(Participant);

@inject('AppStore', 'TemplateStore', 'TemplateActions')
@observer
export default class TemplateEditParticipant extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, isManaging: false };
  }

  toggleManaging() {
    this.setState({
      isManaging: !this.state.isManaging,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  renderParticipant(item, details) {
    let menuClass = classNames('popover-larger');
    if (item.name === this.props.TemplateStore.editParticipant.name) {
      menuClass = classNames(
        Classes.ACTIVE,
        Classes.INTENT_PRIMARY,
        'popover-larger'
      );
    }
    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        text={item.name}
      />
    );
  }

  renderInputValue(participant) {
    return participant.name;
  }

  handleItemSelect(participant) {
    this.setState({ isBlurred: [] });
    this.props.TemplateStore.editParticipant = participant;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  handleInputChange(e) {
    this.props.TemplateStore.updateNewParticipant(
      e.target.value,
      e.target.name
    );
  }

  renderManageParticipants() {
    return (
      <Collapse isOpen={this.state.isManaging}>
        <TemplateManageParticipants /> {this.renderNewParticipant()}
      </Collapse>
    );
  }

  handleSubmit(e) {
    this.toggleLoading();
    e.preventDefault();
    const participantObj = {
      app_id: this.props.AppStore.activeApp.id,
      name: this.props.TemplateStore.newParticipant.name,
    };
    this.props.TemplateActions.createParticipant(participantObj)
      .then(() => {
        this.toggleLoading();
        this.props.TemplateStore.resetNewState();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderNewParticipant() {
    if (this.state.isManaging) {
      return (
        <Flexbox
          flexDirection="column"
          marginBottom="20px"
          marginTop="10px"
        >
          <Flexbox className="bp3-control-group">
            <InputGroup
              autoComplete="off"
              type="text"
              intent={Intent.DEFAULT}
              placeholder={I18n.t('js.create_a_new_participant')}
              name="name"
              value={this.props.TemplateStore.newParticipant.name}
              onChange={this.handleInputChange.bind(this)}
              dir="auto"
              className="bp3-fill"
            />
            <Button
              onClick={this.handleSubmit.bind(this)}
              text={I18n.t('js.add_participant')}
              className="bp3-intent-primary"
              disabled={
                !this.props.TemplateStore.newParticipant.nameValid
              }
              loading={this.state.isSubmitting}
            />
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row">
          <Flexbox className="bp3-form-group">
            <label className="bp3-label" htmlFor="name">
              {I18n.t('js.participant_name')}
              <span className="bp3-text-muted push-5-l">
                ({I18n.t('js.required')})
              </span>
            </label>
            <div className="bp3-form-content">
              <Flexbox>
                <EditParticipantSelect
                  resetOnSelect={true}
                  filterable={false}
                  items={this.props.TemplateStore.participants}
                  itemRenderer={this.renderParticipant.bind(this)}
                  onItemSelect={this.handleItemSelect.bind(this)}
                  inputValueRenderer={this.renderInputValue.bind(
                    this
                  )}
                  inputProps={{
                    rightElement: this.renderDropDown(),
                  }}
                  popoverProps={{
                    usePortal: false,
                    position: 'bottom-left',
                    className: '',
                    popoverClassName: 'bp3-minimal',
                  }}
                >
                  <Button
                    text={
                      this.props.TemplateStore.editParticipant.name
                    }
                    rightIcon="caret-down"
                  />
                </EditParticipantSelect>
                <Flexbox
                  flexGrow={0}
                  flexDirection="column"
                  marginLeft="10px"
                >
                  <Button
                    text={I18n.t('js.manage_participants')}
                    icon="people"
                    onClick={this.toggleManaging.bind(this)}
                    active={this.state.isManaging}
                  />
                </Flexbox>
              </Flexbox>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column">
          {this.renderManageParticipants()}
        </Flexbox>
      </Flexbox>
    );
  }
}
