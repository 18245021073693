/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import TermsStore from '../stores/TermsStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import { AnchorButton, Spinner } from '@blueprintjs/core';
import SignUpBar from '../components/layout/SignUpBar';
import TermsLeftNav from '../components/terms/TermsLeftNav';
import StringToMarkdown from '../components/shared/StringToMarkdown';
import { Scrollbars } from 'react-custom-scrollbars';
import ContentfulClient from '../static/ContentfulClient';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class TermsView extends React.Component {
  componentDidMount() {
    TermsStore.resetStartingAttributes();
    if (TermsStore.terms.length === 0) {
      ContentfulClient.getEntries({
        content_type: 'policy',
        order: '-sys.createdAt',
      }).then(response => {
        let totalLength = response.items.length;
        _.map(response.items, terms => {
          TermsStore.addTerms(terms);
          totalLength--;
        });
        if (totalLength === 0) {
          this.setSlug(this.props.slug);
          TermsStore.toggleLoading();
        }
      });
    } else {
      this.setSlug(this.props.slug);
    }
  }

  setSlug(slug) {
    const findTerms = _.find(
      TermsStore.terms,
      o => o.fields.slug === slug
    );
    if (findTerms) {
      TermsStore.setActiveTerms(findTerms);
    } else {
      TermsStore.setActiveTerms(_.head(TermsStore.terms));
    }
  }

  goToNext() {
    if (
      _.last(TermsStore.terms).sys.id !==
      TermsStore.selectedTerms.sys.id
    ) {
      TermsStore.selectedTerms =
        TermsStore.terms[
          TermsStore.terms.indexOf(TermsStore.selectedTerms) + 1
        ];
    }
  }

  goToPrevious() {
    if (
      _.head(TermsStore.terms).sys.id !==
      TermsStore.selectedTerms.sys.id
    ) {
      TermsStore.selectedTerms =
        TermsStore.terms[
          TermsStore.terms.indexOf(TermsStore.selectedTerms) - 1
        ];
    }
  }

  renderNext() {
    if (
      _.last(TermsStore.terms).sys.id !==
      TermsStore.selectedTerms.sys.id
    ) {
      return (
        <AnchorButton
          className="bp3-intent-primary bp3-large"
          rightIcon="arrow-right"
          text={I18n.t('js.next')}
          href={`/terms/${TermsStore.terms[TermsStore.terms.indexOf(TermsStore.selectedTerms) + 1].fields.slug}`}
        />
      );
    }
    return undefined;
  }

  renderPrevious() {
    if (
      _.head(TermsStore.terms).sys.id !==
      TermsStore.selectedTerms.sys.id
    ) {
      return (
        <AnchorButton
          className="bp3-large push-10-r"
          icon="arrow-left"
          text={I18n.t('js.previous')}
          href={`/terms/${TermsStore.terms[TermsStore.terms.indexOf(TermsStore.selectedTerms) - 1].fields.slug}`}
        />
      );
    }
    return undefined;
  }

  renderContent() {
    if (CurrentUserStore.isLoading || TermsStore.isLoading) {
      return (
        <Flexbox
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          paddingTop="150px"
          paddingBottom="150px"
        >
          <Spinner />
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexDirection="column"
        className="position-relative"
        flexGrow={1}
      >
        <Flexbox className="left-navigation">
          <TermsLeftNav />
        </Flexbox>
        <Flexbox>
          <Scrollbars autoHide={true} autoHeight autoHeightMax={800}>
            <StringToMarkdown
              string={TermsStore.selectedTerms.fields.content}
            />
          </Scrollbars>
        </Flexbox>
        <Flexbox flexDirection="row" marginTop="20px">
          {this.renderPrevious()}
          {this.renderNext()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderSignUp() {
    if (
      !CurrentUserStore.userSignedIn &&
      !CurrentUserStore.isLoading
    ) {
      return <SignUpBar />;
    }
    return undefined;
  }

  render() {
    const stores = {
      CurrentUserStore,
      TermsStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox
              flexGrow={1}
              width="100hw"
              alignContent="stretch"
              flexDirection="column"
            >
              <LayoutHeader
                subtitle={I18n.t('js.terms_and_conditions')}
                title={
                  CurrentUserStore.isLoading || TermsStore.isLoading
                    ? ''
                    : TermsStore.selectedTerms.fields.title
                }
                icon="far fa-clipboard-check"
                loading={
                  CurrentUserStore.isLoading || TermsStore.isLoading
                }
              />
              <Flexbox flexGrow={1} flexDirection="column">
                {this.renderSignUp()}
                <Flexbox
                  className="fixed-width-container"
                  flexDirection="row"
                  flexGrow={1}
                  marginTop="30px"
                >
                  {this.renderContent()}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
