import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

@inject('ActivityStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class ActivityGridDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = { rows: [] };
  }

  componentDidMount() {
    this.setState({
      rows: this.props.element.elementGrid.elementGridRows,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      rows: nextProps.element.elementGrid.elementGridRows,
    });
  }

  renderDisplay() {
    if (this.props.element.elementGrid.displayType === 'radio') {
      return (
        <tbody>
          {_.map(
            this.props.element.elementGrid.elementGridRows,
            row => {
              if (
                row.id ===
                this.props.element.formValues[0].elementGridRowId
              ) {
                return (
                  <tr key={row.id}>
                    <td>{row.name}</td>
                    {_.map(
                      this.props.element.elementGrid
                        .elementGridColumns,
                      column => {
                        let checked = false;
                        if (
                          this.props.element.formValues[0]
                            .elementGridRowId === row.id &&
                          this.props.element.formValues[0]
                            .elementGridColumnId === column.id
                        ) {
                          checked = true;
                        }
                        if (checked) {
                          return (
                            <td key={column.id} className="grid-td">
                              <Flexbox justifyContent="center">
                                <span className="bp3-icon bp3-icon-full-circle bp3-intent-primary" />
                              </Flexbox>
                            </td>
                          );
                        }
                        return (
                          <td key={column.id} className="grid-td">
                            <Flexbox justifyContent="center" />
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              }
              return undefined;
            }
          )}
        </tbody>
      );
    }
    return (
      <tbody>
        {_.map(
          this.props.element.elementGrid.elementGridRows,
          row => {
            if (
              row.id ===
              this.props.element.formValues[0].elementGridRowId
            ) {
              return (
                <tr key={row.id}>
                  <td>{row.name}</td>
                  {_.map(
                    this.props.element.elementGrid.elementGridColumns,
                    column => (
                      <td key={column.id} className="grid-td">
                        <Flexbox justifyContent="center">
                          <span className="bp3-icon bp3-icon-tick bp3-intent-primary" />
                        </Flexbox>
                      </td>
                    )
                  )}
                </tr>
              );
            }
            return undefined;
          }
        )}
      </tbody>
    );
  }

  render() {
    const tableClass = classNames(
      'bp3-html-table bp3-html-table-striped bp3-small full-table'
    );
    return (
      <div className="bp3-form-content">
        <Flexbox flexDirection="column" flexGrow={1}>
          <table className={tableClass}>
            <thead>
              <tr>
                <th className="td-width-30" />
                {_.map(
                  this.props.element.elementGrid.elementGridColumns,
                  column => (
                    <th
                      key={column.id}
                      className="td-width-10 grid-th"
                    >
                      <small>{column.name}</small>
                    </th>
                  )
                )}
              </tr>
            </thead>
            {this.renderDisplay()}
          </table>
        </Flexbox>
      </div>
    );
  }
}
