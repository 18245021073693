import { action, computed, observable } from 'mobx';
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import BambooIntegration from '../models/BambooIntegrationModel';
import Sync from '../models/SyncModel';

class BambooIntegrationStore {
  @observable
  isLoading = true;
  @observable
  syncsLoading = true;
  @observable
  syncsDetailsLoading = true;
  @observable
  bambooIntegration = null;
  @observable
  initialBambooIntegration = null;
  @observable
  hasErrors = true;
  @observable
  syncs = [];
  @observable
  userSyncs = [];
  @observable
  syncErrors = [];
  @observable
  teamSyncs = [];
  @observable
  isDrawerLoading = true;
  @observable
  response = [];
  @observable
  teamArray = [];
  @observable
  syncRunning = false;

  @computed
  get hasBambooIntegration() {
    if (!_.isEmpty(this.bambooIntegration)) {
      return true;
    }
    return false;
  }

  get hasSyncs() {
    if (_.isEmpty(this.syncs)) {
      return false;
    }
    return true;
  }

  @computed
  get hasNewChanges() {
    return !_.isEqual(
      _.omit(this.bambooIntegration, ['active']),
      _.omit(this.initialBambooIntegration, ['active'])
    );
  }

  @computed
  get canSave() {
    if (this.hasNewChanges) {
      if (this.hasErrors) {
        return false;
      } else {
        return true;
      }
    } else if (this.hasErrors) {
      return false;
    }
    return true;
  }

  @action
  isSyncRunning() {
    for (const sync of this.syncs) {
      if (sync.status == 'in_progress') {
        this.syncRunning = true;
        break;
      }
    }
  }

  @action
  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action
  toggleBoolean(name) {
    this[`${name}`] = !this[`${name}`];
  }

  @action
  createBambooIntegration(bhrIntegrationObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Cookies.get('apiEnv')}/bamboo_integrations`,
          bhrIntegrationObj
        )
        .then(response => {
          this.hasErrors = true;
          this.setInitialBambooIntegration();
          ToastStore.showToast(
            'BambooHR integration has been created.',
            'success'
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteBambooIntegration(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/bamboo_integrations/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  removeBambooIntegration() {
    this.bambooIntegration = this.createEmptyIntegration();
    this.setInitialBambooIntegration();
  }

  @action
  fetchBambooIntegration() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/bamboo_integrations`)
        .then(response => {
          response.data == null
            ? this.createEmptyIntegration()
            : this.updateIntegrationModel(response.data);
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  updateAttribute(name, value) {
    this.bambooIntegration[`${name}`] = value;
  }

  @action
  updateBooleanAttribute(name, value) {
    this.bambooIntegration[`${name}`] = !this.bambooIntegration[
      `${name}`
    ];
    this.updateHasErrors(false);
  }

  @action
  updateBambooIntegration(bambooID, bhrIntegrationObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/bamboo_integrations/${bambooID}`,
          bhrIntegrationObj
        )
        .then(response => {
          ToastStore.showToast(
            I18n.t('js.integration_updated'),
            'success'
          );
          this.updateIntegrationModel(response.data);
          this.setInitialBambooIntegration();
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  createEmptyIntegration() {
    this.bambooIntegration = new BambooIntegration();
  }

  @action
  updateIntegrationModel(data) {
    if (this.bambooIntegration == null) {
      this.createEmptyIntegration();
    }
    (this.bambooIntegration.id = data.id),
      (this.bambooIntegration.accountId = data.account_id),
      (this.bambooIntegration.apiKey = data.api_key),
      (this.bambooIntegration.subdomain = data.subdomain),
      (this.bambooIntegration.includeProfilePhotos =
        data.include_profile_photos),
      (this.bambooIntegration.includeDepartments =
        data.include_departments),
      (this.bambooIntegration.includeManagers =
        data.include_managers),
      (this.bambooIntegration.active = data.active),
      (this.bambooIntegration.createdAt = data.created_at),
      (this.bambooIntegration.createdBy = data.created_by),
      (this.bambooIntegration.deletedAt = data.deleted_at),
      (this.bambooIntegration.deletedBy = data.deleted_by),
      (this.bambooIntegration.updatedAt = data.updated_at),
      (this.bambooIntegration.updatedBy = data.updated_by);
    this.setInitialBambooIntegration();
  }

  @action
  setInitialBambooIntegration() {
    this.initialBambooIntegration = _.clone(this.bambooIntegration);
  }

  @action
  cancelChanges() {
    this.hasErrors = true;
    this.bambooIntegration = _.clone(this.initialBambooIntegration);
  }

  @action
  toggleActive() {
    this.bambooIntegration.active = !this.bambooIntegration.active;
  }

  @action
  updateHasErrors(value) {
    this.hasErrors = value;
  }

  // NEED TO WORK ON THIS METHOD
  @action
  editErrors(action, name) {
    switch (action) {
      case 'delete':
        delete this.errorList[name];
        break;
      case 'add':
        this.errorList[`${name}`] = I18n.t(
          `js.please_enter_valid_${name}`
        );
        break;
      default:
        break;
    }
  }

  @action
  fetchSyncs() {
    this.syncsLoading = true;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get('apiEnv')}/bamboo_integrations/${
            this.bambooIntegration.id
          }/syncs`
        )
        .then(response => {
          const syncs = response.data;
          syncs.map(sync => this.addSync(sync));
          this.isSyncRunning();
          this.syncsLoading = false;
          resolve(response);
        })
        .catch(error => {
          const errors = response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  addSync(sync) {
    const newSync = new Sync(
      sync.id,
      sync.duration,
      sync.status,
      sync.started_at,
      sync.syncs
    );
    this.syncs = _.unionBy([newSync], this.syncs, 'id');

    var sortedArray = _.sortBy(this.syncs, function(sync) {
      return sync.startedAt;
    }).reverse();
    this.syncs = sortedArray;
  }

  @action
  runSynchronization() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/bamboo_integrations/run-sync`)
        .then(response => {
          this.addSync(response.data);
          this.isSyncRunning();
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  clearSyncDetails() {
    this.userSyncs = [];
    this.syncErrors = [];
    this.teamSyncs = [];
    this.response = [];
  }

  @action
  fetchSyncDetails(syncID) {
    this.syncsDetailsLoading = true;
    this.teamArray = [];
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/bamboo_integrations/syncs/${syncID}`
        )
        .then(response => {
          this.userSyncs = response.data.user_syncs;
          this.syncErrors = response.data.sync_errors;
          this.teamSyncs = response.data.team_syncs;
          this.response = JSON.parse(response.data.response);

          let teamNames = [];
          this.teamSyncs.map(team_sync => {
            if (!teamNames.includes(team_sync.team.name)) {
              teamNames.push(team_sync.team.name);
              this.teamArray.push({
                id: team_sync.team.id,
                name: team_sync.team.name,
                image: team_sync.team.image_file_name,
                updated_at: team_sync.team.updated_at,
              });
            }
          });
          this.syncsDetailsLoading = false;
          resolve(response);
        })
        .catch(error => {
          this.syncsDetailsLoading = false;
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}
const store = new BambooIntegrationStore();
export default store;
