import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  Classes,
  EditableText,
  Intent,
} from '@blueprintjs/core';
import classNames from 'classnames';

@inject('WorkspaceStore')
@observer
export default class WorkspaceFolderItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false,
    };
  }

  toggleDeleting() {
    this.setState({ isDeleting: !this.state.isDeleting });
  }

  handleDeleteFolder() {
    this.props.WorkspaceStore.deleteWorkspaceFolder(
      this.props.folder.id
    ).catch(this.toggleDeleting);
  }

  handleChangeName(name) {
    this.props.folder.name = name;
  }

  handleConfirmName(name) {
    this.props.folder.name = name;
    const folderObj = {
      id: this.props.folder.id,
      name,
    };
    this.props.WorkspaceStore.changeWorkspaceFolder(folderObj);
  }

  renderDeleteFolder() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeleting}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_folder')}
        onConfirm={() => this.handleDeleteFolder()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>
          {I18n.t('js.are_you_sure')}
        </h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_folder', {
          name: this.props.folder.name.toLowerCase(),
        })}
      </Alert>
    );
  }

  render() {
    let tabClass = classNames('workspace-folder-edit-tab');
    if (
      this.props.WorkspaceStore.activeFolderId ===
      this.props.folder.id
    ) {
      tabClass = classNames(
        'workspace-folder-edit-tab workspace-folder-edit-tab-active'
      );
    }
    return (
      <Flexbox flexGrow={1} className={tabClass}>
        <Flexbox flexGrow={1} alignItems="center">
          <Flexbox flexDirection="column" paddingLeft="10px">
            <EditableText
              value={this.props.folder.name}
              onChange={this.handleChangeName.bind(this)}
              onConfirm={this.handleConfirmName.bind(this)}
            />
          </Flexbox>
        </Flexbox>
        <Flexbox
          marginLeft="10px"
          paddingTop="3px"
          paddingBottom="3px"
        >
          <Button
            icon="small-cross"
            className="bp3-small bp3-minimal bp3-intent-danger"
            onClick={this.toggleDeleting.bind(this)}
          />
          {this.props.dragHandle}
          {this.renderDeleteFolder()}
        </Flexbox>
      </Flexbox>
    );
  }
}
