import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Button, Intent, Dialog, MenuItem, Alert, Classes } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import StatusFlag from '../../models/StatusFlagModel';
import State from '../../models/StateModel';
import classNames from 'classnames';
import _ from 'lodash';

const StatusFlagAssignation = Select.ofType(StatusFlag);
const StateAssignation = Select.ofType(State);

@inject('DynamicViewStore', 'AppStore', 'WorkspaceStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynamicMultiSelectActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAssigningStatus: false,
      isAssigningState: false,
      isAssigningStatusTo: null,
      isAssigningStateTo: null,
      isDeletingAll: false,
      inputName: '',
    };
  }

  handleInputChange(e) {
    this.setState({ inputName: e.target.value });
  }

  /*=======================================================================
  Status Flag Assignation
  =======================================================================*/

  handleAssignStatus() {
    this.props.DynamicViewStore.assignStatusToSelected(this.state.isAssigningStatusTo);
    this.resetAssigningStatus();
  }

  renderStatusFlagText(item) {
    return (
      <Flexbox>
        <span
          className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-10-r"
          style={{
            marginTop: '5px',
            color: item.color,
          }}
        />
        {item.name}
      </Flexbox>
    );
  }

  renderStatusFlag(item, details) {
    let menuClass = classNames('popover-larger');
    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={this.renderStatusFlagText(item, details)} />;
  }

  renderStatusFlagInputValue(statusFlag) {
    return statusFlag.name;
  }

  handleStatusFlagSelect(statusFlag) {
    this.setState({ isAssigningStatus: true, isAssigningStatusTo: statusFlag });
  }

  resetAssigningStatus() {
    this.setState({ isAssigningStatus: false, isAssigningStatusTo: null });
  }

  renderStatusAssignAlert() {
    if (this.state.isAssigningStatusTo) {
      return (
        <Alert
          portalContainer={document.body}
          isOpen={this.state.isAssigningStatus && this.state.isAssigningStatusTo}
          cancelButtonText={I18n.t('js.cancel')}
          onCancel={() => this.resetAssigningStatus()}
          confirmButtonText={I18n.t('js.assign_status_to', {
            name: this.state.isAssigningStatusTo.name,
          })}
          onConfirm={() => this.handleAssignStatus()}
          intent={Intent.SUCCESS}
        >
          <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
          {I18n.t('js.are_you_sure_you_wish_to_assign_status_to', {
            name: this.state.isAssigningStatusTo.name,
          })}
        </Alert>
      );
    }
  }

  /*=======================================================================
  State Assignation
  =======================================================================*/

  handleAssignState() {
    this.props.DynamicViewStore.assignStateToSelected(this.state.isAssigningStateTo);
    this.resetAssigningState();
  }

  renderStateText(item) {
    return <Flexbox>{item.name}</Flexbox>;
  }

  renderState(item, details) {
    let menuClass = classNames('popover-larger');
    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={this.renderStateText(item, details)} />;
  }

  renderStateInputValue(state) {
    return state.name;
  }

  handleStateSelect(state) {
    this.setState({ isAssigningState: true, isAssigningStateTo: state });
  }

  resetAssigningState() {
    this.setState({ isAssigningState: false, isAssigningStateTo: null });
  }

  renderStateAssignAlert() {
    if (this.state.isAssigningStateTo) {
      return (
        <Alert
          portalContainer={document.body}
          isOpen={this.state.isAssigningState && this.state.isAssigningStateTo}
          cancelButtonText={I18n.t('js.cancel')}
          onCancel={() => this.resetAssigningState()}
          confirmButtonText={I18n.t('js.assign_state_to', {
            name: this.state.isAssigningStateTo.name,
          })}
          onConfirm={() => this.handleAssignState()}
          intent={Intent.SUCCESS}
        >
          <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
          {I18n.t('js.are_you_sure_you_wish_to_assign_state_to', {
            name: this.state.isAssigningStateTo.name,
          })}
        </Alert>
      );
    }
  }

  /*=======================================================================
  Renders
  =======================================================================*/

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  renderDeleteAll() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="warning-sign"
        isOpen={this.state.isDeletingAll}
        onClose={() => this.handleDeleteAllClick()}
        title={I18n.t('js.are_you_sure')}
      >
        <div className="bp3-dialog-body">
          {I18n.t('js.are_you_sure_you_wish_to_delete_all_selected_items')}
          <Flexbox flexGrow={1} marginTop="20px">
            <input
              autoComplete="off"
              autoFocus={true}
              type="text"
              className="bp3-input bp3-intent-danger bp3-fill"
              placeholder={this.props.CurrentUserStore.currentUser.fullName}
              onChange={this.handleInputChange.bind(this)}
            />
          </Flexbox>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button text={I18n.t('js.cancel')} onClick={() => this.handleDeleteAllClick()} />
            <Button
              intent={Intent.DANGER}
              onClick={this.handleDeleteAllItems.bind(this)}
              text={I18n.t('js.delete_selected', { count: this.props.DynamicViewStore.selectedItems.length })}
              disabled={this.state.inputName.toLowerCase() !== this.props.CurrentUserStore.currentUser.fullName.toLowerCase()}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  handleDeleteAllClick() {
    this.setState({ isDeletingAll: !this.state.isDeletingAll });
  }

  handleDeleteAllItems() {
    this.setState({ isDeletingAll: false });
    this.props.DynamicViewStore.deleteAllSelected();
  }

  render() {
    let disabled = true;
    if (this.props.DynamicViewStore.activeApp) {
      const { userAccessLevel } = this.props.DynamicViewStore.activeApp;
      disabled = userAccessLevel == 'access_denied' || userAccessLevel == 'create_only';
    }
    if (this.props.DynamicViewStore.selectedItems.length > 0 && this.props.allowMultiSelect) {
      return (
        <Flexbox flexDirection="row" marginBottom="10px" flexGrow={1} justifyContent="space-between">
          <Flexbox flexDirection="row">
            <Button
              icon="plus"
              minimal={true}
              className="push-10-r"
              text={I18n.t('js.select_all_visible')}
              onClick={() => this.props.DynamicViewStore.selectAllVisible()}
            />
            <StatusFlagAssignation
              resetOnSelect={true}
              filterable={false}
              items={this.props.DynamicViewStore.statusFlags}
              itemRenderer={this.renderStatusFlag.bind(this)}
              onItemSelect={this.handleStatusFlagSelect.bind(this)}
              inputValueRenderer={this.renderStatusFlagInputValue.bind(this)}
              inputProps={{
                rightElement: this.renderDropDown(),
              }}
              popoverProps={{
                usePortal: false,
                position: 'bottom-left',
                className: '',
                popoverClassName: 'bp3-minimal',
              }}
            >
              <Button icon="flag" rightIcon="chevron-down" minimal={true} className="push-10-r" text={I18n.t('js.assign_status')} />
            </StatusFlagAssignation>
            <StateAssignation
              resetOnSelect={true}
              filterable={false}
              items={this.props.DynamicViewStore.states}
              itemRenderer={this.renderState.bind(this)}
              onItemSelect={this.handleStateSelect.bind(this)}
              inputValueRenderer={this.renderStateInputValue.bind(this)}
              inputProps={{
                rightElement: this.renderDropDown(),
              }}
              popoverProps={{
                usePortal: false,
                position: 'bottom-left',
                className: '',
                popoverClassName: 'bp3-minimal',
                disabled: disabled,
              }}
            >
              <Button
                disabled={disabled}
                icon="application"
                rightIcon="chevron-down"
                minimal={true}
                className="push-10-r"
                text={I18n.t('js.assign_state')}
              />
            </StateAssignation>
          </Flexbox>
          <Flexbox flexDirection="row">
            <Button
              icon="cross"
              minimal={true}
              className="push-10-r"
              text={I18n.t('js.clear_selected')}
              onClick={() => this.props.DynamicViewStore.clearSelected()}
            />
            <Button
              icon="trash"
              minimal={true}
              intent={Intent.DANGER}
              text={I18n.t('js.delete_selected', { count: this.props.DynamicViewStore.selectedItems.length })}
              onClick={() => this.handleDeleteAllClick()}
            />
            {this.renderDeleteAll()}
            {this.renderStatusAssignAlert()}
            {this.renderStateAssignAlert()}
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }
}
