import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import SimpleTableHeader from '../shared/SimpleTableHeader';
import TeamPermissionRow from './TeamPermissionRow';
import PermissionAddTeamPermission from './PermissionAddTeamPermission';
import TeamPermission from '../../models/TeamPermissionModel';
import Utilities from '../../utils/Utilities';
import _ from 'lodash';

@inject('PermissionStore', 'WorkspaceStore', 'AppStore', 'ToastStore')
@observer
export default class TeamPermissions extends React.Component {
  handleColumnSort(column) {
    this.props.PermissionStore.setTeamPermissionSortParams(
      column.id,
      column.sortDir
    );
  }

  handleTeamSelectChange(team) {
    const findTeam = _.find(
      this.props.PermissionStore.teamPermissions,
      o => o.teamId === team.id
    );
    if (!findTeam) {
      const newTeamPermission = new TeamPermission(
        Utilities.makeId(),
        team.id,
        this.props.type,
        'create_and_view',
        this.props.WorkspaceStore.activeWorkspace.id,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (this.props.type === 'app') {
        newTeamPermission.workspaceId = null;
        newTeamPermission.appId = this.props.AppStore.activeApp.id;
      }
      newTeamPermission.team = team;
      this.props.PermissionStore.teamPermissions = _.unionBy(
        [newTeamPermission],
        this.props.PermissionStore.teamPermissions,
        'id'
      );
      this.props.PermissionStore.sortTeamPermissions();
    }
  }

  renderPermissions() {
    if (this.props.PermissionStore.teamPermissions.length === 0) {
      return (
        <tbody>
          <tr>
            <td className="td-width-40" valign="center">
              <span className="bp3-text-muted">
                {I18n.t('js.there_are_no_teams_to_show')}
              </span>
            </td>
            <td className="td-width-40" />
            <td className="td-width-20" />
          </tr>
        </tbody>
      );
    }
    return (
      <tbody>
        {_.map(
          this.props.PermissionStore.teamPermissions,
          teamPermission => (
            <TeamPermissionRow
              teamPermission={teamPermission}
              key={teamPermission.id}
            />
          )
        )}
      </tbody>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <h5 className={Classes.HEADING}>
          {I18n.t('js.team_permissions')}
        </h5>
        <p>{I18n.t('js.only_following_teams')}</p>
        <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
          <thead>
            <SimpleTableHeader
              columns={
                this.props.PermissionStore.teamPermissionColumns
              }
              handleColumnSort={this.handleColumnSort.bind(this)}
            />
          </thead>
          {this.renderPermissions()}
        </table>
        <Flexbox flexDirection="row" flexGrow={1}>
          <PermissionAddTeamPermission
            handleTeamSelectChange={this.handleTeamSelectChange.bind(
              this
            )}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
