import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Menu, MenuDivider, MenuItem, Intent, Alert, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';

@inject('CurrentUserStore')
@observer
export default class AccountSwitchingMenu extends React.Component {
  render() {
    const { authorizedAccounts, activeAccount, switchAccount } = this.props.CurrentUserStore;

    // Render the Menu only if there is more than one authorized account
    if (authorizedAccounts.length <= 1) {
      return <>
      </>;
    }

    return (
      <Menu>
        <React.Fragment>
          {authorizedAccounts
            .filter(account => !account.activeAccount)
            .map(account => (
              <MenuItem
                key={account.id}
                icon={<Avatar src={account.accountImage} size={20} round />}
                text={account.accountName}
                onClick={() => {
                  switchAccount(account.accountId);
                }}
              />
            ))}
          {/* <MenuDivider /> */}
        </React.Fragment>
        {/* <MenuItem
          text={I18n.t('js.add_account')}
          icon={IconNames.PLUS}
          onClick={() => {
            Utilities.navigate('/setup');
          }}
        />
        <MenuItem
          text={I18n.t('js.leave_active_account', { accountName: activeAccount.accountName })}
          icon={IconNames.FOLLOWING}
          intent={Intent.DANGER}
          onClick={() => this.props.toggleAlert()}
        /> */}
      </Menu>
    );
  }
}