import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Slider, NumericInput, Switch } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import FormulaBuilder from '../../shared/FormulaBuilder';

@inject('DataViewStore', 'ToastStore')
@observer
export default class ChartMetricConfiguration extends React.Component {
  handleAttrChange(e) {
    const value = e.target.value;
    const attribute = e.target.name;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attribute] = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleFormulaChange(formula) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.formula = formula;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleFontSizeChange(value) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.fontSize = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleNumericChange(value, name) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[name] = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleToggleBool(attr) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attr] = !newDataConfig[attr];
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  render() {
    const { activeChart, availableElementsForFormulaBuilder } = this.props.DataViewStore;
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row">
          <Flexbox flexDirection="column" flexGrow={1} marginRight="10px">
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="prefix">
                {I18n.t('js.prefix')}
              </label>
              <div className="bp3-form-content">
                <div className="bp3-input-group">
                  <input
                    autoComplete="off"
                    rows={2}
                    className="bp3-input bp3-fill"
                    type="text"
                    placeholder={I18n.t('js.optional_prefix')}
                    name="prefix"
                    value={activeChart.dataConfig.prefix}
                    onChange={this.handleAttrChange.bind(this)}
                    dir="auto"
                  />
                </div>
              </div>
            </div>
          </Flexbox>
          <Flexbox flexDirection="column" flexGrow={1} marginLeft="10px">
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="suffix">
                {I18n.t('js.suffix')}
              </label>
              <div className="bp3-form-content">
                <div className="bp3-input-group">
                  <input
                    autoComplete="off"
                    rows={2}
                    className="bp3-input bp3-fill"
                    type="text"
                    placeholder={I18n.t('js.optional_suffix')}
                    name="suffix"
                    value={activeChart.dataConfig.suffix}
                    onChange={this.handleAttrChange.bind(this)}
                    dir="auto"
                  />
                </div>
              </div>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column">
          <label className="bp3-label" htmlFor="elementId">
            {I18n.t('js.font_size')}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <Flexbox className="bp3-form-content" paddingLeft="10px" paddingRight="20px" marginTop="10px">
            <Slider
              value={activeChart.dataConfig.fontSize}
              labelStepSize={10}
              min={10}
              max={200}
              onChange={this.handleFontSizeChange.bind(this)}
            />
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row" marginTop="20px">
          <Flexbox flexDirection="column" marginRight="20px">
            <label className="bp3-label" htmlFor="decimal_places">
              {I18n.t('js.decimal_places')}
            </label>
            <div className="bp3-form-content">
              <NumericInput
                allowNumericCharactersOnly={true}
                name="decimal_places"
                min={0}
                max={10}
                value={activeChart.dataConfig.decimalPlaces}
                onValueChange={val => this.handleNumericChange(val, 'decimalPlaces')}
              />
            </div>
          </Flexbox>
          <Flexbox flexDirection="column" marginTop="34px">
            <Switch
              className="bp3-control-no-margin"
              checked={activeChart.dataConfig.useCommas}
              label={I18n.t('js.separate_thousands_with_comma')}
              onChange={() => this.handleToggleBool('useCommas')}
            />
          </Flexbox>
        </Flexbox>
        <Flexbox marginTop="20px" flexDirection="column" flexGrow={1}>
          <FormulaBuilder
            elements={availableElementsForFormulaBuilder}
            initialFormula={activeChart.dataConfig.formula}
            handleFormulaChange={this.handleFormulaChange.bind(this)}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
