/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Classes, Intent, NonIdealState, Tag, Tooltip } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import UserProfileSection from './UserProfileSection';
import _ from 'lodash';

@inject('UserProfileTemplateStore')
@observer
export default class UserProfileSections extends React.Component {
  render() {
    if (this.props.UserProfileTemplateStore.activeSections.length > 0) {
      return (
        <Flexbox flexGrow={1} flexDirection="column">
          {_.map(this.props.UserProfileTemplateStore.activeSections, section => {
            return <UserProfileSection section={section} key={section.id} />;
          })}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column" marginTop="20px" className="bp3-card">
        <NonIdealState
          title={I18n.t('js.nothing_to_show')}
          description={I18n.t('js.there_are_no_sections_to_show')}
          icon="duplicate"
          className="bp3-text-muted"
        />
      </Flexbox>
    );
  }
}
