import { observable } from 'mobx';
import Utilities from '../utils/Utilities';

export default class Toast {
  @observable
  id;
  @observable
  message;
  @observable
  intent;
  @observable
  icon;

  constructor(id, message, intent, icon) {
    this.id = id || Utilities.makeId();
    this.message = message || '';
    this.intent = intent || '';
    this.icon = icon || '';
  }
}
