import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import {
  Button,
  Intent,
  FormGroup,
  InputGroup,
  Switch,
  Tag,
} from '@blueprintjs/core';

const AzureIntegrationForm = props => {
  const {
    clientIdVal,
    clientSecretVal,
    clientUriVal,
    clientTenantVal,
    includeProfilePhotos,
    includeDepartments,
    includeManagers,
    handleBlurChange,
    handleInputChange,
    errors,
    renderErrors,
    isDisabled,
    hasErrors,
  } = props;

  return (
    <Flexbox flexDirection="column" flexGrow="1">
      <Flexbox flexDirection="row" justifyContent="space-between">
        <Flexbox flexGrow="4" flexDirection="column">
          <FormGroup
            helperText={hasErrors ? renderErrors('clientId') : ''}
            intent={'clientId' in errors ? 'DANGER' : 'NONE'}
            label={I18n.t('js.client_id')}
            labelFor="client-id-input"
            labelInfo={`(${I18n.t('js.required')})`}
          >
            <InputGroup
              disabled={isDisabled}
              id="client-id-input"
              placeholder={I18n.t('js.enter_client_id')}
              intent={'clientId' in errors ? 'DANGER' : 'NONE'}
              type="text"
              name="clientId"
              dir="auto"
              value={clientIdVal}
              onBlur={handleBlurChange}
              onChange={handleInputChange}
            />
          </FormGroup>

          <FormGroup
            helperText={hasErrors ? renderErrors('clientSecret') : ''}
            intent={'clientSecret' in errors ? 'DANGER' : 'NONE'}
            label={I18n.t('js.client_secret')}
            labelFor="client-secret-input"
            labelInfo={`(${I18n.t('js.required')})`}
          >
            <InputGroup
              disabled={isDisabled}
              id="client-secret-input"
              placeholder={I18n.t('js.enter_client_secret')}
              intent={'clientSecret' in errors ? 'DANGER' : 'NONE'}
              type="text"
              name="clientSecret"
              dir="auto"
              value={clientSecretVal}
              onBlur={handleBlurChange}
              onChange={handleInputChange}
            />
          </FormGroup>

          <FormGroup
            helperText={hasErrors ? renderErrors('clientUri') : ''}
            intent={'clientUri' in errors ? 'DANGER' : 'NONE'}
            label={I18n.t('js.client_uri')}
            labelFor="client-uri-input"
            labelInfo={`(${I18n.t('js.required')})`}
          >
            <InputGroup
              disabled={isDisabled}
              id="client-uri-input"
              placeholder={I18n.t('js.enter_client_uri')}
              intent={'clientUri' in errors ? 'DANGER' : 'NONE'}
              type="text"
              name="clientUri"
              dir="auto"
              value={clientUriVal}
              onBlur={handleBlurChange}
              onChange={handleInputChange}
            />
          </FormGroup>

          <FormGroup
            helperText={hasErrors ? renderErrors('clientTenant') : ''}
            intent={'clientTenant' in errors ? 'DANGER' : 'NONE'}
            label={I18n.t('js.client_tenant')}
            labelFor="client-tenant-input"
            labelInfo={`(${I18n.t('js.required')})`}
          >
            <InputGroup
              disabled={isDisabled}
              id="client-tenant-input"
              placeholder={I18n.t('js.enter_client_tenant')}
              intent={'clientTenant' in errors ? 'DANGER' : 'NONE'}
              type="text"
              name="clientTenant"
              dir="auto"
              value={clientTenantVal}
              onBlur={handleBlurChange}
              onChange={handleInputChange}
            />
          </FormGroup>
          <Flexbox flexDirection="column" alignSelf="flex-start">
            <Checkbox
              disabled={isDisabled}
              label={I18n.t('js.include_profile_photos')}
              name="includeProfilePhotos"
              checked={includeProfilePhotos}
              onChange={handleInputChange}
            />
            <Checkbox
              disabled={isDisabled}
              label={I18n.t('js.include_departments')}
              name="includeDepartments"
              checked={includeDepartments}
              onChange={handleInputChange}
            />
            <Checkbox
              disabled={isDisabled}
              label={I18n.t('js.include_managers')}
              name="includeManagers"
              checked={includeManagers}
              onChange={handleInputChange}
            />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};
export default observer(AzureIntegrationForm);
