/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import { createConsumer } from '@rails/actioncable';
import ToastStore from './ToastStore';
import CurrentUserStore from './CurrentUserStore';
import StoreTemplateStore from './StoreTemplateStore';
import ReportStore from './ReportStore';
import Notification from '../models/NotificationModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Paginator from '../models/PaginatorModel';
import axios from 'axios';
import _ from 'lodash';

class NotificationStore {
  @observable isLoading = false;
  @observable notifications = [];
  @observable soundPlayed = false;
  @observable notificationColumns = ColumnDefinitions.notificationColumns;
  @observable pagination = new Paginator();

  @computed
  get unreadCount() {
    let unreadCount = 0;
    _.map(this.notifications, notification => {
      if (!notification.read) {
        unreadCount++;
      }
    });
    return unreadCount;
  }

  @action
  processTrigger(notification) {
    if (notification.trigger === 'installed_store_template') {
      console.log(notification)
      StoreTemplateStore.addTriggerNotification(notification);
    }
    if (notification.trigger.split('#')[0] === 'generated_report') {
      ReportStore.addTriggerNotification(notification);
    }
  }

  @action
  addNotification(data) {
    const newNotification = new Notification(
      data.id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.icon,
      data.image_file_name,
      data.message,
      data.read,
      data.title,
      data.updated_at,
      data.updated_by,
      data.user_id,
      data.path,
      data.trigger
    );
    this.notifications = _.unionBy([newNotification], this.notifications, 'id');
    this.determinePlay();
    this.sortNotifications();
  }

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  fetchNotifications(xPage, xPerPage, xOffset) {
    this.isLoading = true;
    this.notifications = [];
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/notifications?page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`)
        .then(response => {
          this.createPaginator(response.headers);
          _.map(response.data, notification => {
            this.addNotification(notification);
          });
          this.isLoading = false;
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          this.isLoading = false;
        });
    });
  }

  markAllAsRead = () => {
    const unreadNotificationIds = this.notifications.filter(n => !n.read).map(n => n.id);
    this.markNotificationsAsRead(unreadNotificationIds);
  };

  @action
  markNotificationsAsRead(notificationIds) {
    notificationIds.forEach(this.markAsRead);
  }

  @action
  markAsRead = notificationId =>
    new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/notifications/${notificationId}`, { read: true })
        .then(response => {
          this.addNotification(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          this.isLoading = false;
        });
    });

  @action
  setNotificationSortParams(param) {
    const newNotificationColumns = this.notificationColumns;
    _.map(newNotificationColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.notificationColumns = newNotificationColumns;
    this.sortNotifications();
  }

  @action
  sortNotifications() {
    let newNotifications = this.notifications;
    const activeColumn = _.find(this.notificationColumns, o => o.active);
    newNotifications = _.orderBy(newNotifications, [activeColumn.id], [activeColumn.sortDir]);
    this.notifications = newNotifications;
  }

  @action
  determinePlay() {
    if (!this.soundPlayed && this.unreadCount > 0) {
      this.playSound();
      this.soundPlayed = true;
    }
  }

  @action
  playSound() {
    // const audio = new Audio(
    //   'https://res.cloudinary.com/dkctgehrc/video/upload/v1515423052/click_pop_xxname.wav'
    // );
    // audio.play();
  }

  @action
  setUpActionCable() {
    const that = this;
    const cable = createConsumer(`${Cookies.get('cablePath')}`);

    axios
      .get(`${Cookies.get('apiEnv')}/users/me`)
      .then(response => {
        let user_id = response.data.id
        cable.subscriptions.create(
          {
            channel: 'NotificationChannel',
            userId: user_id,
          },
          {
            connected() {
              // console.log('Connected!');
            },
            received(data) {
              // Called when there's incoming data on the websocket for this channel
              const notification = JSON.parse(data);
              
              if (notification.trigger) {
                if (notification.trigger === 'installed_store_template') {
                  that.markAsRead(notification.id).then(() => {
                    that.processTrigger(notification);
                  });
                } else {
                  that.processTrigger(notification);
                  that.addNotification(notification, true);
                  that.playSound();
                }
              } else {
                that.addNotification(notification, true);
                that.playSound();
              }
            },
          }
        );
        
      }).catch(error => {
        console.log(error)
      });    
  }
}

const store = new NotificationStore();
export default store;
