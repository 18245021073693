import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Utilities from '../../utils/Utilities';
import { Classes, Button, Dialog, Intent, HTMLSelect, InputGroup, Tag } from '@blueprintjs/core';
import classNames from 'classnames';

@inject('SetupStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class AdminAccountsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      isSubmitting: false,
      isBlurred: [],
      isFinishing: false,
    };
  }

  toggleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  toggleSubmitting() {
    this.setState({ isSubmitting: !this.state.isSubmitting });
  }

  handleAccountClick(account) {
    Utilities.navigate(`/admin/accounts/${account.id}`);
  }

  handleInputChange(e) {
    if (e.target.name === 'email') {
      this.props.SetupStore.updateNewInvitation(e.target.value, e.target.name);
    } else {
      this.props.SetupStore.updateNewAccount(e.target.value, e.target.name);
    }
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  handleCancel() {
    this.setState({ dialogOpen: false, isSubmitting: false, isBlurred: [] });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.SetupStore.newAccount.allValid) {
      this.toggleSubmitting();
      this.props.SetupStore.createAccount()
        .then(response => {
          this.toggleSubmitting();
          this.toggleDialog();
          this.props.SetupStore.resetNewAccount();
          this.handleAccountClick(response.data);
        })
        .catch(() => {
          this.toggleSubmitting();
        });
    }
  }

  renderErrors(field) {
    if (field === 'email') {
      if (_.includes(this.state.isBlurred, field) && !this.props.SetupStore.newInvitation[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.props.SetupStore.newInvitation[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.props.SetupStore.newAccount[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.props.SetupStore.newAccount[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderCreateNewAccount() {
    return (
      <Dialog
        portalContainer={document.body}
        isOpen={this.state.dialogOpen}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title="Create a new account"
      >
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'name') && !this.props.SetupStore.newAccount.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.account_name')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    className={
                      _.includes(this.state.isBlurred, 'name') && !this.props.SetupStore.newAccount.nameValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.example_inc')}
                      name="name"
                      value={this.props.SetupStore.newAccount.name}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('name')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'accountPurpose') && !this.props.SetupStore.newAccount.accountPurposeValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="accountPurpose">
                  {I18n.t('js.what_are_you_hoping_to_achieve')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="column"
                    alignItems="stretch"
                    alignContent="stretch"
                    alignSelf="stretch"
                    className={
                      _.includes(this.state.isBlurred, 'accountPurpose') && !this.props.SetupStore.newAccount.accountPurposeValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <textarea
                      className="bp3-input bp3-textarea bp3-fill"
                      type="textarea"
                      placeholder={I18n.t('js.automate_internal_processes')}
                      name="accountPurpose"
                      rows="10"
                      value={this.props.SetupStore.newAccount.accountPurpose}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('accountPurpose')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'ownerRole') && !this.props.SetupStore.newAccount.ownerRoleValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="ownerRole">
                  {I18n.t('js.select_your_role')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'ownerRole') && !this.props.SetupStore.newAccount.ownerRoleValid
                        ? classNames('bp3-large bp3-fill bp3-intent-danger')
                        : classNames('bp3-large bp3-fill')
                    }
                  >
                    <HTMLSelect name="ownerRole" onChange={this.handleInputChange.bind(this)} onBlur={this.handleBlurChange.bind(this)}>
                      <option value="" defaultValue>
                        {I18n.t('js.choose_an_option')}
                      </option>
                      <option value="CEO">CEO</option>
                      <option value="C-Suite Executive">C-Suite Executive</option>
                      <option value="Senior Manager">Senior Manager</option>
                      <option value="Manager">Manager</option>
                      <option value="Team leader">Team leader</option>
                      <option value="Team member">Team member</option>
                      <option value="Developer">Developer</option>
                      <option value="Consultant">Consultant</option>
                      <option value="Other">Other</option>
                    </HTMLSelect>
                  </Flexbox>
                  {this.renderErrors('ownerRole')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'accountSize') && !this.props.SetupStore.newAccount.accountSizeValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="accountSize">
                  {I18n.t('js.select_account_size')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'accountSize') && !this.props.SetupStore.newAccount.accountSizeValid
                        ? 'bp3-large bp3-fill bp3-intent-danger'
                        : 'bp3-large bp3-fill'
                    }
                  >
                    <HTMLSelect name="accountSize" onChange={this.handleInputChange.bind(this)} onBlur={this.handleBlurChange.bind(this)}>
                      <option value="" defaultValue>
                        {I18n.t('js.choose_an_option')}
                      </option>
                      <option value="0-10">0-10</option>
                      <option value="10-50">10-50</option>
                      <option value="50-100">50-100</option>
                      <option value="100-200">100-200</option>
                      <option value="200-500">200-500</option>
                      <option value="500-1000">500-1000</option>
                      <option value="1000+">1000+</option>
                    </HTMLSelect>
                  </Flexbox>
                  {this.renderErrors('accountSize')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} alignItems="stretch">
              <Flexbox
                flexGrow={1}
                className={
                  _.includes(this.state.isBlurred, 'subdomain') && !this.props.SetupStore.newAccount.subdomainValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="subdomain">
                  {I18n.t('js.subdomain')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <Flexbox flexGrow={1} className="bp3-form-content" flexDirection="column">
                  <Flexbox
                    flexGrow={1}
                    flexDirection="row"
                    className={
                      _.includes(this.state.isBlurred, 'subdomain') && !this.props.SetupStore.newAccount.subdomainValid
                        ? classNames('bp3-input-group bp3-intent-danger')
                        : classNames('bp3-input-group')
                    }
                  >
                    <InputGroup
                      autoComplete="off"
                      className="bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.example')}
                      name="subdomain"
                      value={this.props.SetupStore.newAccount.subdomain}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      leftIcon="globe"
                      rightElement={<Tag className="bp3-intent-success">.peoplegoal-v2.com </Tag>}
                      dir="auto"
                    />
                  </Flexbox>
                  {this.renderErrors('subdomain')}
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.handleCancel.bind(this)} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.props.SetupStore.newAccount.allValid}
                text="Create account"
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1} className="bp3-card">
        <h3 className={Classes.HEADING}>Accounts</h3>
        <Flexbox marginBottom="20px">
          <Button icon="add" onClick={this.toggleDialog.bind(this)}>
            Create new account
          </Button>
          {this.renderCreateNewAccount()}
        </Flexbox>
        <table className="bp3-html-table bp3-small bp3-interactive bp3-html-table-striped bp3-html-table-condensed max-width">
          <thead>
            <tr>
              <th>Name</th>
              <th>Enterprise</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.props.accounts.map(account => {
              return (
                <tr key={account.id} onClick={() => this.handleAccountClick(account)}>
                  <td>{account.name}</td>
                  <td>{account.is_enterprise ? <Tag intent={Intent.SUCCESS}>Enterprise</Tag> : <Tag intent={Intent.WARNING}>No</Tag>}</td>
                  <td>{account.active ? <Tag intent={Intent.SUCCESS}>Active</Tag> : <Tag intent={Intent.WARNING}>Inactive</Tag>}</td>
                  <td>
                    <Button onClick={() => this.handleAccountClick(account)}>Go to account</Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flexbox>
    );
  }
}
