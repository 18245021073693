import { observable } from 'mobx';

export default class NetworkGraph {
  @observable hierarchical;
  @observable levelSeparation;
  @observable nodeSpacing;
  @observable direction;
  @observable sortMethod;
  @observable physics;

  constructor(hierarchical, levelSeparation = 150, nodeSpacing = 200, direction, sortMethod, physics) {
    typeof hierarchical === 'undefined' ? (this.hierarchical = true) : (this.hierarchical = hierarchical);
    this.levelSeparation = levelSeparation;
    this.nodeSpacing = nodeSpacing;
    this.direction = direction || 'UD';
    this.sortMethod = sortMethod || 'directed';
    typeof physics === 'undefined' ? (this.physics = false) : (this.physics = physics);
  }
}
