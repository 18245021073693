import { action, computed, observable } from 'mobx';
import Utilities from '../../utils/Utilities';

export default class ElementMultilineInput {
  @observable
  id;
  @observable
  elementMultilineId;
  @observable
  name;
  @observable
  placeholderText;
  @observable
  isRequired;
  @observable
  displayLabel;
  @observable
  displayLabelType;
  @observable
  displayWidth;
  @observable
  rowOrder;

  constructor(
    id,
    elementMultilineId,
    name,
    placeholderText,
    isRequired,
    displayLabel,
    displayLabelType,
    displayWidth,
    rowOrder
  ) {
    this.id = id || Utilities.makeId();
    this.elementMultilineId = elementMultilineId || '';
    this.name = name || '';
    this.placeholderText = placeholderText || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof isRequired === 'undefined'
      ? (this.isRequired = false)
      : (this.isRequired = isRequired);
    // eslint-disable-next-line no-unused-expressions
    typeof displayLabel === 'undefined'
      ? (this.displayLabel = false)
      : (this.displayLabel = displayLabel);
    this.displayLabelType = displayLabelType || '';
    this.displayWidth = displayWidth || '';
    this.rowOrder = rowOrder || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get placeholderTextValid() {
    if (this.placeholderText.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get placeholderTextError() {
    if (this.placeholderText.length < 1) {
      return I18n.t('js.placeholder_text_is_required');
    }
    return null;
  }

  @action
  validateRequired(input) {
    if (input.length < 1 && this.isRequired) {
      return false;
    }
    return true;
  }

  @action
  validateRequiredMessage(input, full) {
    if (input.length < 1 && this.isRequired) {
      if (full) {
        return I18n.t('js.response_required_for_multiline_input', {
          inputName: this.name,
        });
      }
      return I18n.t('js.a_response_is_required');
    }
    return null;
  }
}
