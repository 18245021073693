import { action, computed, observable } from 'mobx';
import Flexbox from 'flexbox-react';
import React from 'react';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import Team from '../models/TeamModel';
import TeamRelationship from '../models/TeamRelationshipModel';
import ColumnDefinitions from '../static/ColumnDefinitions';
import NetworkGraph from '../models/NetworkGraphModel';
import { Tag, Icon, Tooltip, Intent } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import axios from 'axios';
import _ from 'lodash';
import Utilities from '../utils/Utilities';
import Paginator from '../models/PaginatorModel';
import UserTeam from '../models/UserTeamModel';

class TeamsStore {
  @observable activeSidebarTab = 'list';
  @observable activeTab = 'users';
  @observable teams = [];
  @observable searchTeams = [];
  @observable teamNodes = [];
  @observable teamRelationships = [];
  @observable teamRelationshipNodes = [];
  @observable expandedTeams = ['your-teams'];
  @observable selectedTeamId = '';
  @observable teamGraph = new NetworkGraph();
  @observable newTeam = new Team();
  @observable activeTeam = '';
  @observable currentUserTeam = new UserTeam();
  @observable activeTeamRelationshipsParents = [];
  @observable activeTeamRelationshipsChildren = [];
  @observable parentTeamsToCreate = [];
  @observable parentTeamsToDelete = [];
  @observable childTeamsToCreate = [];
  @observable childTeamsToDelete = [];
  @observable pagination = new Paginator();
  @observable teamColumns = ColumnDefinitions.teamColumns;

  @computed
  get newTeamFormValid() {
    return this.newTeam.nameValid && this.newTeam.descriptionValid;
  }

  @computed
  get filteredTeams() {
    return _.filter(this.teams, o => o.id !== this.activeTeam.id);
  }

  @computed
  get deloopedTeamRelationships() {
    let teamRelationships = [];
    _.map(this.teamRelationships, relationship => {
      // Check if the inverse relationship exists. If it does, don't include it.
      const inverseRelationship = _.find(
        this.teamRelationships,
        o => o.parentId === relationship.childId && o.childId === relationship.parentId
      );
      if (!inverseRelationship) {
        teamRelationships.push(relationship);
      }
    });
    return teamRelationships;
  }

  @computed
  get levelZeroTeams() {
    let levelZeroTeams = [];
    _.map(this.teams, team => {
      const parentTeams = _.find(this.teamRelationships, relationship => {
        return relationship.childId === team.id;
      });
      if (!parentTeams) {
        levelZeroTeams.push(team);
      }
    });
    levelZeroTeams = _.orderBy(_.uniqBy(levelZeroTeams, 'id'), ['name'], ['asc']);
    return levelZeroTeams;
  }

  @computed
  get selectedTeam() {
    return _.find(this.teams, o => o.id === this.selectedTeamId);
  }

  @computed
  get yourTeams() {
    return _.filter(this.teams, o => o.isCurrentUserTeamMember);
  }

  @computed
  get teamTree() {
    const tree = [
      {
        id: 'all-teams',
        hasCaret: true,
        isExpanded: this.expandedTeams.slice().includes('all-teams') || this.selectedTeamId != '',
        className: 'bp3-cursor-pointer',
        icon: <Icon icon="layout-hierarchy" className="push-10-r" />,
        label: <h4 className="bp3-label-no-margin">{I18n.t('js.all_teams')}</h4>,
        nodeType: 'category',
        childNodes: this.allTeamsTree,
      },
    ];
    if (this.selectedTeamId === '') {
      tree.unshift({
        id: 'your-teams',
        hasCaret: true,
        isExpanded: this.expandedTeams.slice().includes('your-teams'),
        className: 'bp3-cursor-pointer',
        icon: <Icon icon="person" className="push-10-r" />,
        label: <h4 className="bp3-label-no-margin">{I18n.t('js.your_teams')}</h4>,
        nodeType: 'category',
        childNodes: this.yourTeamsTree,
      });
    }
    return tree;
  }

  @computed
  get yourTeamsTree() {
    let tree = [];
    if (this.yourTeams.length === 0) {
      const node = this.emptyNode();
      tree.push(node);
    } else {
      if (this.selectedTeamId != '') {
        _.map(this.yourTeams, team => {
          if (team.id === this.selectedTeamId) {
            const node = this.teamNodeSingle(this.selectedTeam);
            tree.push(node);
          }
        });
      } else {
        _.map(this.yourTeams, team => {
          const node = this.teamNodeSingle(team);
          tree.push(node);
        });
      }
    }
    return tree;
  }

  @computed
  get allTeamsTree() {
    let tree = [];
    if (this.selectedTeamId != '') {
      const node = this.teamNode(this.selectedTeam);
      tree.push(node);
    } else {
      if (this.levelZeroTeams.length === 0 && this.teams.length > 0) {
        // Handle the case where we have circular relationships and can't display in a tree view
        _.map(this.teams, team => {
          const node = this.teamNodeSingle(team);
          tree.push(node);
        });
      } else {
        _.map(this.levelZeroTeams, team => {
          const node = this.teamNode(team);
          tree.push(node);
        });
      }
    }
    if (tree.length === 0) {
      const node = this.emptyNode();
      tree.push(node);
    }
    return tree;
  }

  teamNode(team) {
    const teamExpanded = this.expandedTeams.slice().includes(team.id);

    const childTeams = this.childTeamsForTeam(team.id);
    const childNodes = [];
    _.map(childTeams, childTeam => {
      childNodes.push(this.teamNode(childTeam));
    });

    const node = {
      id: team.id,
      hasCaret: childTeams.length > 0,
      isExpanded: teamExpanded,
      isSelected: team.id === this.selectedTeamId,
      className: 'bp3-cursor-pointer',
      icon: (
        <span style={{ marginRight: '10px' }}>
          <Avatar src={team.imageFileName} size={22} round={true} />
        </span>
      ),
      label: this.generateTeamLabel(team),
      nodeType: 'team',
      childNodes: childNodes,
    };
    return node;
  }

  teamNodeSingle(team) {
    const node = {
      id: team.id,
      hasCaret: false,
      isExpanded: false,
      isSelected: team.id === this.selectedTeamId,
      className: 'bp3-cursor-pointer',
      icon: (
        <span style={{ marginRight: '10px' }}>
          <Avatar src={team.imageFileName} size={22} round={true} />
        </span>
      ),
      label: this.generateTeamLabel(team),
      nodeType: 'team',
    };
    return node;
  }

  childTeamsForTeam(teamId) {
    let childTeams = [];
    _.map(this.deloopedTeamRelationships, relationship => {
      if (relationship.parentId === teamId) {
        const childTeam = _.find(this.teams, o => o.id === relationship.childId);
        childTeams.push(childTeam);
      }
    });
    childTeams = _.orderBy(_.uniqBy(childTeams, 'id'), ['name'], ['asc']);
    return childTeams;
  }

  generateTeamLabel(team) {
    return (
      <Flexbox flexDirection="row" flexGrow={0}>
        <Flexbox paddingTop="3px">
          <Tooltip content={team.description} portalContainer={document.body}>
            <a href={`/teams/${team.id}`} className="unstyled" onClick={() => Utilities.navigate(`/teams/${team.id}`)}>
              <h4 className="bp3-label-no-margin">{team.name}</h4>
            </a>
          </Tooltip>
        </Flexbox>
        {team.privateTeam ? (
          <Tooltip content={I18n.t('js.private_team_description')} portalContainer={document.body}>
            <Tag intent={Intent.DANGER} minimal={true} className={team.id === this.selectedTeamId ? 'text-white push-10-l' : 'push-10-l'}>
              {I18n.t('js.private_team')}
            </Tag>
          </Tooltip>
        ) : null}
        {team.isDefaultAccount ? (
          <Tooltip content={I18n.t('js.default_account_team_description')} portalContainer={document.body}>
            <Tag intent={Intent.SUCCESS} minimal={true} className={team.id === this.selectedTeamId ? 'text-white push-10-l' : 'push-10-l'}>
              {I18n.t('js.default_account_team')}
            </Tag>
          </Tooltip>
        ) : null}
        <Tag minimal={team.id != this.selectedTeamId} className={team.id === this.selectedTeamId ? 'text-white push-10-l' : 'push-10-l'}>
          {team.userCount} {I18n.t('js.users')}
        </Tag>
      </Flexbox>
    );
  }

  emptyNode() {
    const node = {
      id: Math.random(),
      icon: <Icon icon="warning-sign" className="bp3-text-muted push-10-r" />,
      label: <span className="bp3-text-muted">{I18n.t('js.nothing_to_show')}</span>,
      nodeType: 'empty',
    };
    return node;
  }

  @action toggleTeam(teamId) {
    if (this.expandedTeams.includes(teamId)) {
      this.expandedTeams = _.filter(this.expandedTeams, o => o != teamId);
    } else {
      if (teamId === 'your-teams') {
        this.expandedTeams.push(teamId);
        this.expandedTeams = _.filter(this.expandedTeams, o => o != 'all-teams');
      } else {
        if (teamId === 'all-teams') {
          this.expandedTeams.push(teamId);
          this.expandedTeams = _.filter(this.expandedTeams, o => o != 'your-teams');
        } else {
          this.expandedTeams.push(teamId);
        }
      }
    }
  }

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  updateNewTeam(data, attribute) {
    this.newTeam[attribute] = data;
  }

  @action
  updateTeamGraph(data, attribute) {
    this.teamGraph[attribute] = data;
  }

  @action
  resetTeamAttribute(attribute) {
    this.newTeam[attribute] = this.activeTeam[attribute];
  }

  @action
  setActiveTeam(teamId) {
    return new Promise(resolve => {
      const team = _.find(this.teams, o => o.id === teamId);
      this.activeTeam = team;
      this.newTeam = _.clone(team);
      resolve();
    });
  }

  @action
  addCurrentUserTeam(data) {
    const currentUserTeam = new UserTeam(
      data.id,
      data.team_id,
      data.user_id,
      data.is_primary,
      data.is_owner,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.currentUserTeam = currentUserTeam;
  }

  @action
  addTeam(data, type) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    if (type === 'all') {
      this.addTeamNode(newTeam);
      this.teams = _.unionBy([newTeam], this.teams, 'id');
    } else {
      this.activeTeam = _.clone(newTeam);
      this.newTeam = _.clone(newTeam);
    }
    if (newTeam.userCount > 0) {
      this.expandedTeams.push(newTeam.id);
    }
    this.sortTeams();
  }

  @action
  addTeamParent(data) {
    const newParent = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.activeTeamRelationshipsParents = _.unionBy([newParent], this.activeTeamRelationshipsParents, 'id');
  }

  @action
  addTeamChild(data) {
    const newChild = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.activeTeamRelationshipsChildren = _.unionBy([newChild], this.activeTeamRelationshipsChildren, 'id');
  }

  @action
  addTeamRelationship(data) {
    const newTeamRelationship = new TeamRelationship(
      data.id,
      data.parent_id,
      data.child_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.addTeamRelationshipNode(newTeamRelationship);
    this.teamRelationships = _.unionBy([newTeamRelationship], this.teamRelationships, 'id');
  }

  @action
  removeTeam(data) {
    this.teams = _.filter(this.teams, o => o.id !== data.id);
    this.teamNodes = _.filter(this.teamNodes, o => o.id !== data.id);
  }

  @action
  removeTeamRelationship(data) {
    this.teamRelationships = _.filter(this.teamRelationships, o => o.id !== data.id);
    this.teamRelationshipNodes = _.filter(this.teamRelationshipNodes, o => o.id !== data.id);
  }

  @action
  addSearchTeam(data) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.searchTeams = _.unionBy([newTeam], this.searchTeams, 'id');
    this.sortSearchTeams();
  }

  @action
  fetchTeam(teamId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/teams/${teamId}?type=full`)
        .then(response => {
          this.addTeam(response.data, 'single');
          _.map(response.data.parents, parent => {
            this.addTeamParent(parent);
          });
          _.map(response.data.children, child => {
            this.addTeamChild(child);
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchCurrentUserTeam(teamId, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_teams?user_id=${userId}&team_id=${teamId}`)
        .then(response => {
          _.map(response.data, userTeam => {
            this.addCurrentUserTeam(userTeam);
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchTeams() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/teams`)
        .then(response => {
          this.createPaginator(response.headers);
          if (this.pagination.xTotal > this.pagination.xPerPage) {
            axios.get(`${Cookies.get('apiEnv')}/teams?per_page=${this.pagination.xTotal}&page=1&offset=0`).then(res => {
              _.map(res.data, team => {
                this.addTeam(team, 'all');
              });
              resolve(res);
            });
          } else {
            _.map(response.data, team => {
              this.addTeam(team, 'all');
            });
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchTeamsQuery(query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/teams?query=${query}`)
        .then(response => {
          _.map(response.data, team => {
            this.addSearchTeam(team);
          });
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  resetSearchTeams() {
    return new Promise(resolve => {
      this.searchTeams = [];
      resolve();
    });
  }

  @action
  createTeam(teamObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/teams`, teamObj)
        .then(response => {
          this.addTeam(response.data, 'all');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteTeam() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/teams/${this.activeTeam.id}`)
        .then(response => {
          this.removeTeam(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeTeam(teamObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/teams/${this.activeTeam.id}`, teamObj)
        .then(response => {
          this.addTeam(response.data, 'single');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchTeamRelationships() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/team_relationships`)
        .then(response => {
          _.map(response.data, teamRelationship => {
            this.addTeamRelationship(teamRelationship, 'all');
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createTeamRelationship(parentTeamId, childTeamId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/team_relationships`, {
          parent_id: parentTeamId,
          child_id: childTeamId,
        })
        .then(response => {
          this.addTeamRelationship(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteTeamRelationship(parentTeamId, childTeamId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/team_relationships?parent_id=${parentTeamId}&child_id=${childTeamId}`)
        .then(response => {
          this.removeTeamRelationship(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  addTeamNode(team) {
    let UserDescription = I18n.t('js.users');
    let teamImage = team.imageFileName;
    let teamLabel = `${team.name} (${team.userCount} ${UserDescription.toLowerCase()})`;
    let darkMode = Cookies.get('theme') === 'dark';
    console.log(darkMode);
    if (team.userCount === 1) {
      UserDescription = I18n.t('js.user');
    }
    if (team.privateTeam) {
      // teamImage = I18n.t('js.or_locked');
      teamImage = team.imageFileName;
      teamLabel = `${team.name} (${I18n.t('js.private')}: ${team.userCount} ${UserDescription.toLowerCase()})`;
    }
    const newTeamNode = {
      id: team.id,
      label: teamLabel,
      shape: 'circularImage',
      title: I18n.t('js.click_to_show_team'),
      image: teamImage,
      font: {
        face: 'Inter',
        color: darkMode == true ? '#ffffff' : '#1c2127',
      },
      physics: true,
      borderWidth: 3,
      borderWidthSelected: 5,
      labelHighlightBold: false,
      color: {
        border: `#E1E8ED`,
        highlight: darkMode == true ? '#FBD065' : '#0000FA',
        hover: {
          border: darkMode == true ? '#FBD065' : '#0000FA',
        },
      },
    };
    this.teamNodes = _.unionBy([newTeamNode], this.teamNodes, 'id');
  }

  @action
  addTeamRelationshipNode(teamRelationship) {
    let darkMode = Cookies.get('theme') === 'dark';
    const newTeamRelationshipNode = {
      id: teamRelationship.id,
      from: teamRelationship.parentId,
      to: teamRelationship.childId,
      dashes: true,
      width: 2,
      color: {
        color: `#BFCCD6`,
        highlight: darkMode == true ? '#FBD065' : '#0000FA',
        hover: darkMode == true ? '#FBD065' : '#0000FA',
      },
    };
    this.teamRelationshipNodes = _.unionBy([newTeamRelationshipNode], this.teamRelationshipNodes, 'id');
  }

  @action
  sortTeams() {
    let newTeams = this.teams;
    newTeams = _.orderBy(newTeams, ['name'], ['asc']);
    this.teams = newTeams;
  }

  @action
  sortSearchTeams() {
    let newSearchTeams = this.searchTeams;
    newSearchTeams = _.orderBy(newSearchTeams, ['name'], ['asc']);
    this.searchTeams = newSearchTeams;
  }

  @action
  resetStartingAttributes() {
    this.newTeam = new Team();
    this.activeTab = 'users';
    this.activeTeam = '';
    this.currentUserTeam = new UserTeam();
    this.activeTeamRelationshipsParents = [];
    this.activeTeamRelationshipsChildren = [];
    this.parentTeamsToCreate = [];
    this.parentTeamsToDelete = [];
    this.childTeamsToCreate = [];
    this.childTeamsToDelete = [];
    this.expandedTeams = ['your-teams'];
    this.selectedTeamId = '';
  }
}

const store = new TeamsStore();
export default store;
