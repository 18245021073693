/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { Spinner } from '@blueprintjs/core';
import AccountBillingStore from '../stores/AccountBillingStore';
import AccountBillingActions from '../actions/AccountBillingActions';
import ToastStore from '../stores/ToastStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import AccountBillingTabs from '../components/billing/AccountBillingTabs';
import AccountBillingPlans from '../components/billing/AccountBillingPlans';
import AccountBillingSubscription from '../components/billing/AccountBillingSubscription';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import _ from 'lodash';

@observer
export default class AccountBillingView extends React.Component {
  componentDidMount() {
    AccountBillingStore.resetStartingAttributes();
    if (this.props.tab !== null) {
      AccountBillingStore.setActiveTab(this.props.tab);
    }
    AccountBillingActions.fetchPlans()
      .then(response => {
        let totalPlans = response.data.length;
        _.map(response.data, plan => {
          AccountBillingStore.addPlan(plan);
          totalPlans--;
        });
        if (totalPlans === 0) {
          AccountBillingActions.fetchSubscriptions()
            .then(res => {
              let totalSubscriptions = res.data.length;
              _.map(res.data, subscription => {
                AccountBillingStore.addSubscription(subscription);
                totalSubscriptions--;
              });
              if (totalSubscriptions === 0) {
                AccountBillingStore.toggleLoading();
              }
            })
            .catch(() => {
              AccountBillingStore.toggleLoading();
            });
        }
      })
      .catch(() => {
        AccountBillingStore.toggleLoading();
      });
  }

  renderContent() {
    if (CurrentUserStore.isLoading || AccountBillingStore.isLoading) {
      return (
        <Flexbox justifyContent="center" alignItems="center" paddingTop="150px" paddingBottom="150px">
          <Spinner />
        </Flexbox>
      );
    }
    if (AccountBillingStore.activeTab === 'plans') {
      return <AccountBillingPlans />;
    }
    if (AccountBillingStore.activeTab === 'subscription') {
      return <AccountBillingSubscription />;
    }
    return undefined;
  }

  render() {
    const stores = {
      AccountBillingStore,
      AccountBillingActions,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexDirection="column">
                <AccountBillingTabs />
              </Flexbox>
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <Flexbox marginBottom="10px" flexDirection="column" marginBottom="20px">
                  <h2 className="bp3-heading">{I18n.t('js.billing')}</h2>
                  <p className="bp3-running-text">{I18n.t('js.billing_subtitle')}</p>
                </Flexbox>
                {this.renderContent()}
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
