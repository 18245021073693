/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  Classes,
  Collapse,
  FormGroup,
  Intent,
  Switch,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import LocationSelect from '../../shared/LocationSelect';
import ElementReadOnly from '../../shared/ElementReadOnly';
import ElementTag from '../../shared/ElementTag';
import classNames from 'classnames';
import _ from 'lodash';

@inject(
  'UserProfileTemplateStore',
  'TemplateStore',
  'TemplateActions'
)
@observer
export default class ElementLocationSelect extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      min_length: this.store.activeElement.minLength,
      max_length: this.store.activeElement.maxLength,
      is_required: this.store.activeElement.isRequired,
    };
    const elementLocationSelectObj = {
      id: this.store.activeElement.elementLocationSelect.id,
      name: this.store.activeElement.elementLocationSelect.name,
    };
    this.props.TemplateActions.changeSubElement(
      elementLocationSelectObj,
      'element_location_selects'
    )
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(
      !this.store.activeElement.isRequired,
      'isRequired'
    );
  }

  renderErrors(field) {
    if (
      field === 'minLength' ||
      field === 'maxLength' ||
      field === 'isRequired'
    ) {
      if (
        _.includes(this.state.isBlurred, field) &&
        !this.store.activeElement[`${field}Valid`]
      ) {
        return (
          <div className="bp3-form-helper-text">
            {this.store.activeElement[`${field}Error`]}
          </div>
        );
      }
    } else {
      if (
        _.includes(this.state.isBlurred, field) &&
        !this.store.activeElement.elementLocationSelect[
          `${field}Valid`
        ]
      ) {
        return (
          <div className="bp3-form-helper-text">
            {
              this.store.activeElement.elementLocationSelect[
                `${field}Error`
              ]
            }
          </div>
        );
      }
    }
    return undefined;
  }

  handleDragEnd() {
    // do nothing
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox>
              <span className="bp3-icon bp3-icon-map-marker push-10-r" />
              <h5 className={Classes.HEADING}>
                {I18n.t('js.location_select_element_options')}
              </h5>
            </Flexbox>
            <Flexbox
              flexDirection="row"
              flexGrow={1}
              marginTop="10px"
            >
              <Flexbox
                flexDirection="column"
                flexGrow={1}
                flexBasis="1"
              >
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') &&
                    !this.store.activeElement.elementLocationSelect
                      .nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.location_select_name')}
                    <span className="bp3-text-muted push-5-l">
                      ({I18n.t('js.required')})
                    </span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') &&
                        !this.store.activeElement
                          .elementLocationSelect.nameValid
                          ? classNames(
                              'bp3-input-group bp3-intent-danger'
                            )
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        placeholder={I18n.t('js.enter_name')}
                        name="name"
                        value={
                          this.store.activeElement
                            .elementLocationSelect.name
                        }
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.response_required')}
                onChange={this.handleResponseRequiredChange.bind(
                  this
                )}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton
                text={I18n.t('js.cancel_changes')}
                onClick={this.props.handleCancel.bind(this)}
                className="push-10-l"
              />
            </Flexbox>
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse
        isOpen={this.store.activeAreaId === this.props.element.id}
      >
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderDisabledText() {
    if (!this.props.element.stateElement.editable) {
      return <ElementReadOnly element={this.props.element} />;
    }
    return undefined;
  }

  renderLocationSelect() {
    return (
      <LocationSelect
        handleDragEnd={this.handleDragEnd.bind(this)}
        selectedLocation=""
        isDraggable={this.props.element.stateElement.editable}
      />
    );
  }

  renderPrivacyTag() {
    if (
      this.props.element.privateView &&
      !this.props.sectionPrivateView
    ) {
      return (
        <ElementTag
          tagType="private"
          tagIcon="eye-on"
          tagTooltip={I18n.t('js.private_element_description')}
        />
      );
    }
  }

  renderProtectedTag() {
    if (
      this.props.element.protectedView &&
      !this.props.sectionProtectedView
    ) {
      return (
        <ElementTag
          tagType="protected"
          tagIcon="lock"
          tagTooltip={I18n.t('js.protected_element_description')}
        />
      );
    }
  }

  renderAccountOwnerOnlyTag() {
    if (
      this.props.element.accountOwnerOnlyView &&
      !this.props.sectionAccountOwnerOnlyView
    ) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t(
            'js.account_owner_only_element_description'
          )}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox
        flexDirection="row"
        flexGrow={0}
        alignContent="center"
        alignItems="center"
      >
        {name}
        {isRequired ? (
          <span className="bp3-text-muted push-5-l">
            {`(${I18n.t('js.required')})`}
          </span>
        ) : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(
            this.store.activeElement.elementLocationSelect.name,
            this.store.activeElement.isRequired
          )}
          labelFor={this.store.activeElement.id}
          intent={
            this.store.activeElement.validateLength(
              this.displayedInput
            )
              ? null
              : Intent.DANGER
          }
        >
          {this.renderLocationSelect(this.store.activeElement)}
          {this.renderDisabledText()}
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(
          this.props.element.elementLocationSelect.name,
          this.props.element.isRequired
        )}
        labelFor={this.props.element.id}
        intent={
          this.props.element.validateLength(this.displayedInput)
            ? null
            : Intent.DANGER
        }
      >
        {this.renderLocationSelect(this.props.element)}
        {this.renderDisabledText()}
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
