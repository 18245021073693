/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class State {
  @observable
  id;
  @observable
  appId;
  @observable
  participantId;
  @observable
  statusFlagId;
  @observable
  name;
  @observable
  allVisible;
  @observable
  allEditable;
  @observable
  rowOrder;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    appId,
    participantId,
    statusFlagId,
    name,
    allVisible,
    allEditable,
    rowOrder,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.appId = appId || '';
    this.participantId = participantId || '';
    this.statusFlagId = statusFlagId || '';
    this.name = name || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof allVisible === 'undefined' ? (this.allVisible = true) : (this.allVisible = allVisible);
    // eslint-disable-next-line no-unused-expressions
    typeof allEditable === 'undefined' ? (this.allEditable = true) : (this.allEditable = allEditable);
    this.rowOrder = rowOrder || 0;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get nameValid() {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError() {
    if (this.name.length < 1) {
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get statusFlagIdValid() {
    if (this.statusFlagId.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get statusFlagIdError() {
    if (this.statusFlagId.length < 1) {
      return I18n.t('js.status_flag_is_required');
    }
    return null;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
