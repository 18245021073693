// @flow
import * as React from 'react';
import ThemeWrapper from '../../styles/ThemeWrapper/index.js';
import CurrentUserStore from '../../../stores/CurrentUserStore';
import NotificationStore from '../../../stores/NotificationStore';
import { observer, inject, Provider } from 'mobx-react';
import Utilities from '../../../utils/Utilities';
import _ from 'lodash';
import { AnchorButton, Button, Classes, Intent, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Avatar from 'react-avatar';
// sub components
import HeaderLink from '../HeaderLink';
import WorkspaceMenuList from '../WorkspaceMenuList';
import AccountPopover from '../AccountPopover';
import UserNavMenu from '../UserNavMenu';
import AccountSwitchingMenu from '../../accounts/AccountSwitchingMenu';
import NotificationPopover from '../NotificationPopover';
// styles
import HeaderNavStyles from './styles/HeaderNavStyles';
import VerticalDivider from './styles/VerticalDivider';
import StyledButton from './styles/StyledButton';
import Logo from './styles/Logo';
import StyledLinks from './styles/StyledLinks';

type HeaderNavProps = {
  controller: 'landing' | 'admin' | 'secured',
  action: string,
  CurrentUserStore: CurrentUserStore,
};

const HeaderNav = inject('CurrentUserStore')(
  observer(({ controller, action, CurrentUserStore }: HeaderNavProps) => {
    const navigateHome = () => {
      Utilities.navigate('/', true);
    };

    const hasAccount = CurrentUserStore ? CurrentUserStore.currentUser.accountId.length > 0 : false;

    const accountImage = CurrentUserStore.activeAccount.accountImage;
    const { userSignedIn, userIsAccountOwner } = CurrentUserStore;

    const isLoading = CurrentUserStore.isLoading;
    // prettier-ignore

    if (isLoading) {
        return (
          <HeaderNavStyles />
        )
      }
    return (
      <HeaderNavStyles>
        <div className="left">
          {!hasAccount ? (
            <StyledButton minimal onClick={() => Utilities.navigate('/')} icon={<Logo alt="logo" src={I18n.t(`js.pg_icon_blue`)} />}>
              {<span className="push-10-l">{I18n.t('js.peoplegoal')}</span>}
            </StyledButton>
          ) : (
            <React.Fragment>
              <Popover portalContainer={document.body} content={<AccountSwitchingMenu />}>
                <StyledButton
                  rightIcon={IconNames.CHEVRON_DOWN}
                  minimal
                  icon={
                    <a onClick={navigateHome} href="/">
                      <Logo alt="logo" src={accountImage} />
                    </a>
                  }
                >
                  {_.truncate(CurrentUserStore.activeAccount.accountName, { length: 25 })}
                </StyledButton>
              </Popover>
              <StyledLinks>
                <HeaderLink active={action === 'dashboard'} name={I18n.t('js.dashboard')} link="/dashboard" />
                <WorkspaceMenuList action={action} />
                <HeaderLink active={action === 'reports'} name={I18n.t('js.reports')} link="/reports" />
                <HeaderLink active={action === 'teams' || action === 'teams_import_data'} name={I18n.t('js.teams')} link="/teams" />
                <div className="bp3-cursor-pointer">
                  <AccountPopover action={action} userIsAccountOwner={userIsAccountOwner} />
                </div>
              </StyledLinks>
            </React.Fragment>
          )}
        </div>
        <div className="right">
          {userSignedIn ? (
            <React.Fragment>
              {/* $FlowFixMe */}
              <NotificationPopover />
              <Popover
                portalContainer={document.body}
                position={Position.BOTTOM_RIGHT}
                content={<UserNavMenu currentUser={CurrentUserStore.currentUser} />}
              >
                <Button
                  icon={<Avatar src={CurrentUserStore.currentUser.imageFileName} size={20} round={true} />}
                  text={CurrentUserStore.currentUser.fullName}
                  rightIcon={IconNames.CHEVRON_DOWN}
                  minimal
                />
              </Popover>
            </React.Fragment>
          ) : (
            action !== 'webform_item' && (
              <Button intent={Intent.PRIMARY} onClick={() => Utilities.navigate('/sign-in')} text={I18n.t('js.sign_in')} />
            )
          )}
        </div>
      </HeaderNavStyles>
    );
  })
);

export default (props: HeaderNavProps) => (
  <Provider CurrentUserStore={CurrentUserStore} NotificationStore={NotificationStore}>
    <ThemeWrapper>
      <HeaderNav {...props} />
    </ThemeWrapper>
  </Provider>
);
