import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tree, Classes, HTMLSelect, Button, Tooltip, Label } from '@blueprintjs/core';
import AppItemsNewItem from '../app_items/AppItemsNewItem';
import DynamicMultiSelectActions from './DynamicMultiSelectActions';
import _ from 'lodash';
import { Position } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';

@inject('DynamicViewStore', 'AppStore', 'WorkspaceStore', 'DashboardStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class DynamicUserView extends React.Component {
  componentDidMount() {
    if (!this.props.allowMultiSelect) {
      this.props.DynamicViewStore.canMultiSelect = false;
    }
  }

  handleNodeInteract(nodeData) {
    switch (nodeData.nodeType) {
      case 'relationship':
        const relationshipType = nodeData.id;
        this.props.DynamicViewStore.toggleUser(relationshipType);
        break;
      case 'user':
        const userId = nodeData.id;
        this.props.DynamicViewStore.toggleUser(userId);
        break;
      case 'item':
        // const itemId = nodeData.id;
        // window.location = `/items/${itemId}`;
        break;
    }
  }

  handleNodeClick(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeCollapse(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleNodeExpand(nodeData) {
    this.handleNodeInteract(nodeData);
  }

  handleRelationshipChange(e) {
    if (this.props.DynamicViewStore.activeRelationship.id !== e.target.value) {
      const relationship = _.find(this.props.DynamicViewStore.relationships, o => o.id === e.target.value);
      this.props.DynamicViewStore.setActiveRelationship(relationship);
    }
  }

  expandAll() {
    this.props.DynamicViewStore.expandAll();
  }

  collapseAll() {
    this.props.DynamicViewStore.collapseAll();
  }

  renderAppSelect() {
    const { getSelectedAppId } = this.props.DynamicViewStore;
    const { allPublishedApps } = this.props.AppStore;
    const { filteredWorkspaces } = this.props.WorkspaceStore;
    if (this.props.allowAppSelect) {
      return (
        <Flexbox className="bp3-select" marginRight="10px">
          <HTMLSelect disabled={allPublishedApps.length <= 1} value={getSelectedAppId} onChange={this.props.handleAppChange.bind(this)}>
            <option key={0} value={'all'}>
              {I18n.t('js.all_apps')}
            </option>
            {_.map(filteredWorkspaces, workspace => {
              return (
                <React.Fragment key={workspace.id}>
                  <option key={workspace.id} disabled={true} value={workspace.id}>
                    {workspace.name}
                  </option>
                  {_.map(allPublishedApps, app => {
                    if (app.workspaceId === workspace.id && app.userPermissionLevel.name !== 'access_denied') {
                      return (
                        <option key={app.id} value={app.id}>
                          {app.name}
                        </option>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </HTMLSelect>
        </Flexbox>
      );
    } else {
      return <AppItemsNewItem />;
    }
  }

  render() {
    const {
      specificUserTree,
      participants,
      statusFlags,
      isLoadingParticipants,
      isLoadingStatusFlags,
      activeApp,
    } = this.props.DynamicViewStore;

    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexDirection="row" marginBottom="10px">
          <Flexbox flexDirection="row" flexGrow={1}>
            {this.renderAppSelect()}
            {this.props.allowParticipantSelect ? (
              <Label className="bp3-label-no-margin">
                {I18n.t('js.filter_by_participant')}
                <Flexbox className="bp3-select" marginRight="10px">
                  <HTMLSelect
                    disabled={activeApp === null || isLoadingParticipants}
                    onChange={this.props.handleParticipantChange.bind(this)}
                  >
                    <option defaultValue value={null}>
                      {I18n.t('js.all_participants')}
                    </option>
                    {_.map(participants, participant => (
                      <option key={participant.id} value={participant.id}>
                        {participant.name}
                      </option>
                    ))}
                  </HTMLSelect>
                </Flexbox>
              </Label>
            ) : null}
            {this.props.allowStatusFlagSelect ? (
              <Label className="bp3-label-no-margin">
                {I18n.t('js.filter_by_status')}
                <Flexbox className="bp3-select" marginRight="10px">
                  <HTMLSelect
                    disabled={activeApp === null || isLoadingStatusFlags}
                    value={this.props.DynamicViewStore.activeStatusFlag?.id}
                    onChange={this.props.handleStatusFlagChange.bind(this)}
                  >
                    <option defaultValue value="reset">
                      {I18n.t('js.any_current_status')}
                    </option>
                    {_.map(statusFlags, statusFlag => (
                      <option key={statusFlag.id} value={statusFlag.id}>
                        {statusFlag.name}
                      </option>
                    ))}
                  </HTMLSelect>
                </Flexbox>
              </Label>
            ) : null}
          </Flexbox>
          <Flexbox flexDirection="row">
            {this.props.disableUserButton ? null : (
              <Tooltip
                usePortal={false}
                disabled={!this.props.DynamicViewStore.canCollapseAll}
                content={I18n.t('js.view_profile')}
                position={Position.TOP}
              >
                <Button
                  icon="arrow-right"
                  onClick={() => Utilities.navigate(`/users/${this.props.user.id}`)}
                  text={this.props.user.fullName}
                />
              </Tooltip>
            )}
          </Flexbox>
        </Flexbox>
        <DynamicMultiSelectActions allowMultiSelect={this.props.allowMultiSelect} />
        <Flexbox flexDirection="column" className="bp3-card bp3-card-no-padding bp3-cursor-pointer">
          <Tree
            contents={specificUserTree}
            onNodeClick={this.handleNodeClick.bind(this)}
            onNodeCollapse={this.handleNodeCollapse.bind(this)}
            onNodeExpand={this.handleNodeExpand.bind(this)}
            className={Classes.ELEVATION_0}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
