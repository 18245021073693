/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import { observer, Provider } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { Classes, Callout, Intent } from '@blueprintjs/core';
import ThemeWrapper from '../components/styles/ThemeWrapper/index.js';
import LayoutHeader from '../components/layout/LayoutHeader';
import AdminActions from '../actions/AdminActions';
import AdminStore from '../stores/AdminStore';
import SetupStore from '../stores/SetupStore';
import CurrentUserStore from '../stores/CurrentUserStore';
import ToastStore from '../stores/ToastStore';
import AdminTabnav from '../components/admin/AdminTabnav';
import AdminStoreCategories from '../components/admin/AdminStoreCategories';
import AdminEditStoreCategory from '../components/admin/AdminEditStoreCategory';
import AdminAccountDomains from '../components/admin/AdminAccountDomains';
import AdminEditAccountDomain from '../components/admin/AdminEditAccountDomain';
import AdminSystemUsage from '../components/admin/AdminSystemUsage';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import AdminEditBlogPost from '../components/admin/AdminEditBlogPost';
import AdminAccountsList from '../components/admin/AdminAccountsList';

@observer
export default class AdminView extends React.Component {
  renderContent() {
    if (AdminStore.activeItem === null) {
      switch (AdminStore.activeTab) {
        case 'system':
          return <AdminSystemUsage />;
        case 'accounts':
          return <AdminAccountsList accounts={this.props.accounts} />;
        case 'storeCategories':
          return <AdminStoreCategories />;
        case 'accountDomains':
          return <AdminAccountDomains />;
        default:
          return (
            <Flexbox flexDirection="row" className="bp3-card" flexGrow={1}>
              TBC
            </Flexbox>
          );
      }
    } else {
      if (AdminStore.activeItemType === 'storeCategory') {
        return <AdminEditStoreCategory />;
      }
      if (AdminStore.activeItemType === 'blogPost') {
        return <AdminEditBlogPost />;
      }
      if (AdminStore.activeItemType === 'accountDomain') {
        return <AdminEditAccountDomain />;
      }
    }
    return undefined;
  }

  render() {
    const stores = {
      AdminActions,
      AdminStore,
      SetupStore,
      CurrentUserStore,
      ToastStore,
    };
    return (
      <Provider {...stores}>
        <ThemeWrapper>
          <ErrorBoundary>
            <Flexbox flexGrow={1} width="100hw" alignContent="stretch" flexDirection="column" paddingBottom="0px">
              <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container" marginTop="20px">
                <Callout intent={Intent.WARNING} className="push-20-b" title="Caution!">
                  You are in the admin only area. Act with caution and professionalism.
                </Callout>
                <Flexbox flexGrow={1}>
                  <Flexbox flexGrow={0}>
                    <AdminTabnav />
                  </Flexbox>
                  <Flexbox flexGrow={1} marginLeft="20px">
                    {this.renderContent()}
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </ErrorBoundary>
        </ThemeWrapper>
      </Provider>
    );
  }
}
