import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  Classes,
  Dialog,
  EditableText,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Switch,
} from '@blueprintjs/core';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import UserProfileNewTemplate from './UserProfileNewTemplate';
import IconSelector from '../shared/IconSelector';
import NewStoreTemplate from '../store_templates/NewStoreTemplate';
import _ from 'lodash';

@inject('CurrentUserStore', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions', 'ToastStore')
@observer
export default class UserProfileTemplateHeader extends React.Component {
  @observable
  isLoading = false;
  @observable
  isDeleting = false;
  @observable
  isDuplicating = false;
  @observable
  isPublishing = false;
  @observable
  newTemplateDialogOpen = false;
  @observable
  confirmedInput = '';

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  toggleDeletion() {
    this.isDeleting = !this.isDeleting;
  }

  toggleDuplicating() {
    this.isDuplicating = !this.isDuplicating;
  }

  togglePublishing() {
    this.isPublishing = !this.isPublishing;
  }

  toggleDialog() {
    this.newTemplateDialogOpen = !this.newTemplateDialogOpen;
  }

  handleFinishEditing() {
    this.props.UserProfileTemplateStore.setActiveProfileTemplate(this.props.UserProfileTemplateStore.activeProfileTemplate);
    this.props.UserProfileTemplateStore.toggleEditTemplate();
  }

  handleProfileTemplateSelection(profileTemplate) {
    this.toggleLoading();
    const authorizedAccountObj = {
      id: this.props.UserProfileStore.authorizedAccount.id,
      profile_template_id: profileTemplate.id,
    };
    this.props.UserProfileStore.changeAuthorizedAccount(authorizedAccountObj)
      .then(() => {
        this.props.UserProfileTemplateStore.matchActiveProfileTemplate(this.props.UserProfileStore.authorizedAccount);
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleDuplicateProfileTemplate() {
    this.props.TemplateActions.copyProfileTemplate(this.props.UserProfileTemplateStore.activeProfileTemplate.id)
      .then(() => {
        this.toggleDuplicating();
      })
      .catch(() => {
        this.toggleDuplicating();
      });
  }

  handleDeleteProfileTemplate() {
    this.toggleLoading();
    const profileTemplateId = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
    if (this.props.UserProfileStore.authorizedAccount.profileTemplateId === this.props.UserProfileTemplateStore.activeProfileTemplate.id) {
      const authorizedAccountObj = {
        id: this.props.UserProfileStore.authorizedAccount.id,
        profile_template_id: null,
      };
      this.props.UserProfileStore.changeAuthorizedAccount(authorizedAccountObj)
        .then(() => {
          this.props.UserProfileTemplateStore.matchActiveProfileTemplate(this.props.UserProfileStore.authorizedAccount);
        })
        .catch(() => {
          this.toggleLoading();
        });
    }
    this.props.TemplateActions.deleteProfileTemplate(profileTemplateId)
      .then(response => {
        this.props.UserProfileTemplateStore.removeProfileTemplate(response.data.id);
        this.props.UserProfileTemplateStore.isEditingTemplate = false;
        this.toggleDeletion();
        this.toggleLoading();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleNameChange(value) {
    this.props.UserProfileTemplateStore.activeProfileTemplate.name = value;
  }

  handleNameConfirm() {
    const profileTemplateObj = {
      id: this.props.UserProfileTemplateStore.activeProfileTemplate.id,
      name: this.props.UserProfileTemplateStore.activeProfileTemplate.name,
    };
    this.props.TemplateActions.changeProfileTemplate(profileTemplateObj).then(response => {
      this.props.UserProfileTemplateStore.addProfileTemplate(response.data);
    });
  }

  handleDescriptionChange(value) {
    this.props.UserProfileTemplateStore.activeProfileTemplate.description = value;
  }

  handleDescriptionConfirm() {
    const profileTemplateObj = {
      id: this.props.UserProfileTemplateStore.activeProfileTemplate.id,
      description: this.props.UserProfileTemplateStore.activeProfileTemplate.description,
    };
    this.props.TemplateActions.changeProfileTemplate(profileTemplateObj).then(response => {
      this.props.UserProfileTemplateStore.addProfileTemplate(response.data);
    });
  }

  handleChangeIcon(icon) {
    this.props.UserProfileTemplateStore.activeProfileTemplate.icon = icon;
    const profileTemplateObj = {
      id: this.props.UserProfileTemplateStore.activeProfileTemplate.id,
      icon,
    };
    this.props.TemplateActions.changeProfileTemplate(profileTemplateObj).then(response => {
      this.props.UserProfileTemplateStore.addProfileTemplate(response.data);
    });
  }

  handleDefaultChange() {
    const newValue = !this.props.UserProfileTemplateStore.activeProfileTemplate.defaultTemplate;

    const profileTemplateObj = {
      id: this.props.UserProfileTemplateStore.activeProfileTemplate.id,
      default: newValue,
    };
    this.props.TemplateActions.changeProfileTemplate(profileTemplateObj)
      .then(response => {
        this.props.UserProfileTemplateStore.addProfileTemplate(response.data);
        this.props.UserProfileTemplateStore.activeProfileTemplate.defaultTemplate = newValue;
        _.map(this.props.UserProfileTemplateStore.profileTemplates, profileTemplate => {
          if (profileTemplate.id === this.props.UserProfileTemplateStore.activeProfileTemplate.id) {
            profileTemplate.defaultValue = newValue;
          } else {
            profileTemplate.defaultTemplate = false;
          }
        });
      })
      .catch((err = {}));
  }

  handleInputChange(e) {
    this.confirmedInput = e.target.value;
  }

  renderMenuHeaders() {
    if (this.props.UserProfileTemplateStore.profileTemplates.length === 0) {
      return <li className="bp3-menu-header bp3-text-muted">{I18n.t('js.there_are_no_profile_templates_to_show')}</li>;
    }
    return undefined;
  }

  renderSwitchProfileHeaders() {
    if (this.props.UserProfileTemplateStore.profileTemplates.length > 1) {
      return (
        <li className="bp3-menu-header">
          <h6 className={Classes.HEADING}>{I18n.t('js.change_profile_template')}</h6>
        </li>
      );
    }
    return undefined;
  }

  renderEditProfile() {
    if (this.props.UserProfileTemplateStore.activeProfileTemplate !== '') {
      if (this.props.CurrentUserStore.userIsAccountOwner || this.props.UserProfileStore.isCurrentUser) {
        if (this.props.UserProfileTemplateStore.isEditingProfile) {
          return (
            <MenuItem
              text={I18n.t('js.stop_editing_profile')}
              icon="cross"
              onClick={() => this.props.UserProfileTemplateStore.toggleEditProfile()}
            />
          );
        }
        return (
          <MenuItem text={I18n.t('js.edit_profile')} icon="edit" onClick={() => this.props.UserProfileTemplateStore.toggleEditProfile()} />
        );
      }
    }
    return undefined;
  }

  renderExportToPDF() {
    // if (this.props.UserProfileTemplateStore.activeProfileTemplate !== '') {
    //   return <MenuItem icon="cloud-download" text={I18n.t('js.export_to_pdf')} />;
    // }
    return undefined;
  }

  renderMenu() {
    const { profileTemplates, activeProfileTemplate } = this.props.UserProfileTemplateStore;
    return (
      <Menu>
        {this.renderEditProfile()}
        {this.renderExportToPDF()}
        {this.renderMenuHeaders()}
        {this.renderSwitchProfileHeaders()}
        {_.map(profileTemplates, profileTemplate => {
          if (profileTemplates.length > 1 || activeProfileTemplate === '') {
            const iconClass = classNames('fa bp3-text-muted push-10-r', profileTemplate.icon);
            return (
              <MenuItem
                key={profileTemplate.id}
                text={profileTemplate.name}
                icon={<Flexbox className={iconClass} paddingTop="3px" />}
                onClick={() => this.handleProfileTemplateSelection(profileTemplate)}
              />
            );
          }
          return undefined;
        })}
        <MenuDivider />
        <MenuItem
          text={I18n.t('js.account_owner_actions')}
          icon="lock"
          intent={Intent.DANGER}
          disabled={!this.props.CurrentUserStore.userIsAccountOwner}
        >
          <MenuItem
            text={I18n.t('js.edit_profile_template')}
            icon="id-number"
            disabled={!this.props.CurrentUserStore.userIsAccountOwner || this.props.UserProfileTemplateStore.activeProfileTemplate === ''}
            onClick={() => this.props.UserProfileTemplateStore.toggleEditTemplate()}
          />
          <MenuItem
            text={I18n.t('js.duplicate_profile_template')}
            icon="duplicate"
            disabled={!this.props.CurrentUserStore.userIsAccountOwner || this.props.UserProfileTemplateStore.activeProfileTemplate === ''}
            onClick={() => this.toggleDuplicating()}
          />
          <MenuItem
            text={I18n.t('js.publish_profile_template')}
            icon="social-media"
            disabled={!this.props.CurrentUserStore.userIsAccountOwner || this.props.UserProfileTemplateStore.activeProfileTemplate === ''}
            onClick={() => this.togglePublishing()}
          />
          <MenuItem
            text={I18n.t('js.create_new_profile_template')}
            icon="add"
            disabled={!this.props.CurrentUserStore.userIsAccountOwner}
            onClick={this.toggleDialog.bind(this)}
          />
          <MenuDivider />
          <MenuItem
            text={I18n.t('js.delete_this_profile_template')}
            icon="trash"
            intent={Intent.DANGER}
            disabled={!this.props.CurrentUserStore.userIsAccountOwner || this.props.UserProfileTemplateStore.activeProfileTemplate === ''}
            onClick={this.toggleDeletion.bind(this)}
          />
        </MenuItem>
      </Menu>
    );
  }

  renderDeletionAlert() {
    if (this.props.UserProfileTemplateStore.activeProfileTemplate !== '') {
      return (
        <Dialog
          portalContainer={document.body}
          icon="warning-sign"
          isOpen={this.isDeleting}
          onClose={this.toggleDeletion.bind(this)}
          title={I18n.t('js.are_you_sure')}
        >
          <div className="bp3-dialog-body">
            {I18n.t('js.delete_profile_template_confirm', {
              name: this.props.UserProfileTemplateStore.newProfileTemplate.name,
            })}
            <Flexbox flexGrow={1} marginTop="20px">
              <input
                autoComplete="off"
                autoFocus
                type="text"
                className="bp3-input bp3-intent-danger bp3-fill"
                placeholder={this.props.UserProfileTemplateStore.activeProfileTemplate.name}
                onChange={this.handleInputChange.bind(this)}
              />
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={() => this.toggleDeletion()} />
              <Button
                intent={Intent.DANGER}
                loading={this.isLoading}
                onClick={this.handleDeleteProfileTemplate.bind(this)}
                text={I18n.t('js.delete_profile_template')}
                disabled={
                  this.props.UserProfileTemplateStore.activeProfileTemplate.name.toLowerCase() !== this.confirmedInput.toLowerCase()
                }
              />
            </div>
          </div>
        </Dialog>
      );
    }
    return undefined;
  }

  renderConfirmDuplicate() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.isDuplicating}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDuplicating()}
        confirmButtonText={I18n.t('js.duplicate_profile_template')}
        onConfirm={this.handleDuplicateProfileTemplate.bind(this)}
        intent={Intent.SUCCESS}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.duplicate_profile_template_confirmation_text')}
      </Alert>
    );
  }

  renderProfileTemplateSelection() {
    if (this.props.UserProfileStore.isCurrentUser || this.props.CurrentUserStore.userIsAccountOwner) {
      if (this.props.UserProfileTemplateStore.activeProfileTemplate === '') {
        return (
          <Popover
            portalContainer={document.body}
            content={this.renderMenu()}
            position="bottom"
            interactionKind={PopoverInteractionKind.CLICK}
            inline={true}
            autoFocus={false}
          >
            <Button text={I18n.t('js.select_a_profile_template')} rightIcon="caret-down" loading={this.isLoading} />
          </Popover>
        );
      }
      return (
        <Popover
          portalContainer={document.body}
          content={this.renderMenu()}
          position="bottom"
          interactionKind={PopoverInteractionKind.CLICK}
          inline={true}
          autoFocus={false}
        >
          <Button rightIcon="caret-down" loading={this.isLoading}>
            <span>{this.props.UserProfileTemplateStore.activeProfileTemplate.name}</span>
          </Button>
        </Popover>
      );
    }
    return undefined;
  }

  renderIconSelector() {
    return (
      <Flexbox className="fixed-width-container" padding="20px">
        <IconSelector
          activeIcon={this.props.UserProfileTemplateStore.activeProfileTemplate.icon}
          handleChangeIcon={this.handleChangeIcon.bind(this)}
        />
      </Flexbox>
    );
  }

  renderIcon() {
    if (this.props.UserProfileTemplateStore.activeProfileTemplate !== '') {
      if (this.props.UserProfileTemplateStore.isEditingTemplate) {
        const iconClass = classNames(
          'fa push-10-r bp3-text-muted fa-lg bp3-cursor-pointer',
          this.props.UserProfileTemplateStore.activeProfileTemplate.icon
        );
        return (
          <Popover
            portalContainer={document.body}
            content={this.renderIconSelector()}
            position="bottom-left"
            interactionKind={PopoverInteractionKind.CLICK}
            inline={true}
            autoFocus={false}
          >
            <Flexbox className={iconClass} marginTop="2px" />
          </Popover>
        );
      }
      const iconClass = classNames('fa push-10-r bp3-text-muted fa-lg', this.props.UserProfileTemplateStore.activeProfileTemplate.icon);
      return <span className={iconClass} />;
    }
    return undefined;
  }

  renderProfileTemplateName() {
    if (this.props.UserProfileTemplateStore.activeProfileTemplate === '') {
      return <h4 className={Classes.HEADING}>{I18n.t('js.no_profile_template_selected')}</h4>;
    }
    if (this.props.UserProfileTemplateStore.isEditingTemplate) {
      return (
        <h3 className={Classes.HEADING}>
          <Flexbox>
            <Flexbox>
              <EditableText
                confirmOnEnterKey={true}
                defaultValue={this.props.UserProfileTemplateStore.activeProfileTemplate.name}
                intent={Intent.DEFAULT}
                onChange={value => this.handleNameChange(value)}
                value={this.props.UserProfileTemplateStore.activeProfileTemplate.name}
                onConfirm={this.handleNameConfirm.bind(this)}
                placeholder={I18n.t('js.click_to_edit')}
              />
            </Flexbox>
          </Flexbox>
        </h3>
      );
    }
    return <h3 className={Classes.HEADING}>{this.props.UserProfileTemplateStore.activeProfileTemplate.name}</h3>;
  }

  renderDefaultSwitch() {
    if (!this.props.UserProfileTemplateStore.activeProfileTemplate.isStoreTemplate) {
      return (
        <Flexbox flexGrow={0} paddingTop="5px">
          <Switch
            className="bp3-control-no-margin"
            checked={this.props.UserProfileTemplateStore.activeProfileTemplate.defaultTemplate}
            label={I18n.t('js.default_profile_template')}
            onChange={this.handleDefaultChange.bind(this)}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderProfileTemplateDescription() {
    if (this.props.UserProfileTemplateStore.activeProfileTemplate !== '') {
      if (this.props.UserProfileTemplateStore.isEditingTemplate) {
        return (
          <Flexbox flexGrow={1}>
            <Flexbox className="bp3-running-text" flexGrow={1}>
              <EditableText
                confirmOnEnterKey={true}
                defaultValue={this.props.UserProfileTemplateStore.activeProfileTemplate.description}
                intent={Intent.DEFAULT}
                onChange={value => this.handleDescriptionChange(value)}
                value={this.props.UserProfileTemplateStore.activeProfileTemplate.description}
                onConfirm={this.handleDescriptionConfirm.bind(this)}
                placeholder={I18n.t('js.click_to_edit')}
              />
            </Flexbox>
            {this.renderDefaultSwitch()}
          </Flexbox>
        );
      }
      return (
        <Flexbox>
          <Flexbox marginTop="3px" className="bp3-running-text">
            {this.props.UserProfileTemplateStore.activeProfileTemplate.description}
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderProfileButton() {
    if (this.props.UserProfileTemplateStore.isEditingTemplate) {
      return (
        <Flexbox marginRight="10px">
          <Button
            text={I18n.t('js.finished_editing_profile_template')}
            icon="endorsed"
            className="bp3-intent-primary"
            onClick={this.handleFinishEditing.bind(this)}
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderNewStoreTemplate() {
    if (this.props.CurrentUserStore.userIsAccountOwner && this.props.UserProfileTemplateStore.activeProfileTemplate !== '') {
      return (
        <NewStoreTemplate
          dialogOpen={this.isPublishing}
          toggleOpen={this.togglePublishing.bind(this)}
          templatableObjectType="ProfileTemplate"
          templatableObject={this.props.UserProfileTemplateStore.activeProfileTemplate}
        />
      );
    }
    return undefined;
  }

  render() {
    if (this.props.UserProfileTemplateStore.activeProfileTemplate.isStoreTemplate) {
      return (
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderProfileTemplateName()}
          {this.renderProfileTemplateDescription()}
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column" className="fixed-width-container">
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexGrow={1} paddingTop="5px">
            {this.renderProfileTemplateName()}
          </Flexbox>
          <Flexbox flexGrow={0}>
            <Flexbox flexDirection="column">
              {this.renderProfileButton()}
              {this.renderConfirmDuplicate()}
            </Flexbox>
            {this.renderProfileTemplateSelection()}
          </Flexbox>
          <UserProfileNewTemplate isOpen={this.newTemplateDialogOpen} toggleDialog={this.toggleDialog.bind(this)} />
          {this.renderDeletionAlert()}
        </Flexbox>
        {this.renderProfileTemplateDescription()}
        {this.renderNewStoreTemplate()}
      </Flexbox>
    );
  }
}
