import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Button, Classes } from '@blueprintjs/core';
import RichTextDisplay from '../shared/RichTextDisplay';
import StoreTemplateAuthor from './StoreTemplateAuthor';
import InstallStoreTemplate from './InstallStoreTemplate';
import CommentsView from '../../views/CommentsView';
import Utilities from '../../utils/Utilities';
import Lightbox from 'react-image-lightbox';
import classNames from 'classnames';
import _ from 'lodash';

@inject('CurrentUserStore', 'ToastStore', 'StoreTemplateStore')
@observer
export default class StoreTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lightBoxOpen: false,
      installingStoreTemplate: false,
    };
  }

  toggleLightBox() {
    this.setState({ lightBoxOpen: !this.state.lightBoxOpen });
  }

  toggleInstallingStoreTemplate() {
    this.setState({
      installingStoreTemplate: !this.state.installingStoreTemplate,
    });
  }

  renderStoreCategories() {
    return (
      <Flexbox flexDirection="row" flexGrow={0} marginTop="10px" flexWrap="wrap" maxWidth="220px">
        {_.map(this.props.storeTemplate.storeCategories, storeCategory => {
          let tagClass = classNames('bp3-cursor-pointer bp3-tag bp3-minimal bp3-intent-success push-5-r');
          if (this.props.storeTemplate.status === 'draft') {
            tagClass = classNames('bp3-cursor-pointer bp3-tag bp3-minimal bp3-intent-warning push-5-r');
          }
          return (
            <Flexbox
              key={storeCategory.id}
              flexDirection="column"
              flexGrow={0}
              className={tagClass}
              marginTop="10px"
              onClick={() => Utilities.navigate(`/store_categories/${storeCategory.id}`)}
            >
              {storeCategory.title}
            </Flexbox>
          );
        })}
      </Flexbox>
    );
  }

  renderImage() {
    if (this.props.storeTemplate.imageFileName.length > 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={0} marginRight="20px" maxWidth="260px">
          <Flexbox
            flexGrow={0}
            flexDirection="column"
            className="bp3-card bp3-cursor-pointer"
            padding="10px"
            marginBottom="20px"
            onClick={this.toggleLightBox.bind(this)}
          >
            <img
              src={this.props.storeTemplate.imageFileName}
              className="store-template-img"
              alt={this.props.storeTemplate.title}
              description={this.props.storeTemplate.title}
            />
          </Flexbox>
          {this.renderAddToAccount()}
          {this.renderStoreCategories()}
          {this.renderBenefits()}
          {this.renderLightBox()}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderNoImage() {
    if (this.props.storeTemplate.imageFileName.length === 0) {
      return (
        <Flexbox flexDirection="column" flexGrow={0} marginTop="10px">
          {this.renderBenefits()}
          <Flexbox flexGrow={0} flexDirection="row" marginTop="20px">
            {this.renderAddToAccount()}
          </Flexbox>
          {this.renderStoreCategories()}
          {this.renderLightBox()}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderAddToAccount() {
    if (
      this.props.CurrentUserStore.userSignedIn &&
      this.props.CurrentUserStore.currentUser.accountId.length > 0 &&
      this.props.storeCategoryView
    ) {
      return (
        <Flexbox flexGrow={1}>
          <Button
            text={I18n.t('js.add_to_your_account')}
            className="bp3-intent-success bp3-large bp3-fill"
            icon="cloud-download"
            onClick={this.toggleInstallingStoreTemplate.bind(this)}
          />
          <InstallStoreTemplate
            storeTemplate={this.props.storeTemplate}
            toggleOpen={this.toggleInstallingStoreTemplate.bind(this)}
            dialogOpen={this.state.installingStoreTemplate}
            triggerStoreTemplate={this.props.StoreTemplateStore.storeTemplateTriggers.length > 0}
          />
        </Flexbox>
      );
    }
    if (
      this.props.CurrentUserStore.userSignedIn &&
      this.props.CurrentUserStore.currentUser.accountId === '' &&
      this.props.storeCategoryView
    ) {
      return (
        <Flexbox flexGrow={1}>
          <AnchorButton
            text={I18n.t('js.create_an_account_to_use_this')}
            target="_blank"
            className="bp3-intent-success bp3-large bp3-fill"
            icon="applications"
            href="/setup"
          />
        </Flexbox>
      );
    }
    if (this.props.storeCategoryView) {
      return (
        <Flexbox flexGrow={1}>
          <AnchorButton
            text={I18n.t('js.sign_up_to_use_this_template')}
            target="_blank"
            className="bp3-intent-success bp3-large bp3-fill"
            icon="endorsed"
            href="/sign-up"
          />
        </Flexbox>
      );
    }
    return undefined;
  }

  renderLightBox() {
    if (this.state.lightBoxOpen) {
      return <Lightbox mainSrc={this.props.storeTemplate.imageFileNameFull} onCloseRequest={this.toggleLightBox.bind(this)} />;
    }
    return undefined;
  }

  renderBenefits() {
    if (this.props.storeTemplate.benefits.length > 0) {
      return (
        <Flexbox flexDirection="column" marginTop="20px">
          <h6 className={Classes.HEADING}>{I18n.t('js.why_add_this_to_your_account')}</h6>
          <Flexbox flexGrow={1} paddingTop="10px" flexDirection="column" style={{ borderTop: '1px solid #eee', fontSize: '13px' }}>
            <RichTextDisplay content={this.props.storeTemplate.benefits} />
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  renderComments() {
    if (this.props.CurrentUserStore.userSignedIn) {
      return (
        <Flexbox marginTop="20px">
          <CommentsView
            commentable={this.props.storeTemplate}
            commentableType="StoreTemplate"
            canPin={true}
            stub={true}
            sortByNew={true}
            showNone={true}
            buttonText={I18n.t('js.comment_on_this_store_template')}
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox
        flexGrow={1}
        marginTop="20px"
        paddingTop="20px"
        justifyContent="center"
        flexDirection="row"
        style={{ borderTop: '1px solid #eee' }}
      >
        <AnchorButton text={I18n.t('js.sign_in_to_view_comments')} href="/sign-in" target="_blank" icon="log-in" />
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <Flexbox flexGrow={1} flexDirection="row">
          <Flexbox flexDirection="column" flexGrow={0}>
            {this.renderImage()}
          </Flexbox>
          <Flexbox flexGrow={1} flexDirection="column" marginTop="10px">
            <h3 className={Classes.HEADING}>{I18n.t('js.description')}</h3>
            <Flexbox flexGrow={0} paddingTop="10px" flexDirection="column" style={{ borderTop: '1px solid #eee' }}>
              <RichTextDisplay content={this.props.storeTemplate.description} />
              {this.renderNoImage()}
              <StoreTemplateAuthor storeTemplate={this.props.storeTemplate} />
            </Flexbox>
          </Flexbox>
        </Flexbox>
        {this.renderComments()}
      </Flexbox>
    );
  }
}
