import React from 'react';
// import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import UserAvatar from '../shared/UserAvatar';
import {
  AnchorButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
} from '@blueprintjs/core';
import _ from 'lodash';
import PermissionDefinitions from '../../static/PermissionDefinitions';

@inject('PermissionStore', 'ToastStore')
@observer
export default class UserPermissionRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeType: '',
      permissionTypes: PermissionDefinitions.permissionTypes,
    };
  }

  componentDidMount() {
    const findType = _.find(
      this.state.permissionTypes,
      type => type.name === this.props.userPermission.permissionType
    );
    if (findType) {
      this.setState({ activeType: findType });
    }
  }

  handleTypeClick(type) {
    this.setState({ activeType: type });
    this.props.userPermission.permissionType = type.name;
  }

  handleRemovePermission() {
    this.props.PermissionStore.removeUserPermission(
      this.props.userPermission.id
    );
  }

  renderMenu() {
    return (
      <Menu>
        {_.map(this.state.permissionTypes, type => (
          <MenuItem
            key={type.id}
            icon={type.icon}
            text={type.text}
            onClick={() => this.handleTypeClick(type)}
          />
        ))}
      </Menu>
    );
  }

  renderPermissionType() {
    return (
      <Popover
        portalContainer={document.body}
        disabled={this.determineDisabled()}
        content={this.renderMenu()}
        position="bottom-left"
      >
        <Tooltip
          portalContainer={document.body}
          disabled={!this.determineDisabled()}
          content={I18n.t('js.there_must_be_at_least_one_owner')}
        >
          <AnchorButton
            icon={this.state.activeType.icon}
            disabled={this.determineDisabled()}
            rightIcon="bp3-icon bp3-icon-caret-down"
            className="bp3-minimal bp3-small"
            text={this.state.activeType.text}
          />
        </Tooltip>
      </Popover>
    );
  }

  determineDisabled() {
    return (
      this.props.userPermission.permissionType === 'owner' &&
      this.props.PermissionStore.onlyOneUserOwnerLeft
    );
  }

  render() {
    return (
      <tr>
        <td className="td-width-40">
          <UserAvatar
            user={this.props.userPermission.user}
            avatarSize={20}
            noLink={true}
          />
        </td>
        <td className="td-width-40">{this.renderPermissionType()}</td>
        <td className="td-width-20">
          <Tooltip
            portalContainer={document.body}
            disabled={!this.determineDisabled()}
            content={I18n.t('js.there_must_be_at_least_one_owner')}
          >
            <AnchorButton
              text={I18n.t('js.remove_user')}
              disabled={this.determineDisabled()}
              className="bp3-small"
              onClick={this.handleRemovePermission.bind(this)}
            />
          </Tooltip>
        </td>
      </tr>
    );
  }
}
