import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Button, Classes, NonIdealState, Spinner } from '@blueprintjs/core';
import StringToMarkdown from '../shared/StringToMarkdown';
import DashboardDiscussYourNeeds from './DashboardDiscussYourNeeds';
import moment from 'moment';
import _ from 'lodash';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore',
  'AdminStore',
  'AdminActions'
)
@observer
export default class DashboardAccountOwnerUpdates extends React.Component {
  render() {
    if (this.props.UserProfileTemplateStore.isLoading || this.props.DynamicViewStore.isLoading) {
      return <Flexbox flexDirection="column" width="360px" height="180px" marginBottom="20px" className="bp3-skeleton"></Flexbox>;
    }
    if (this.props.AdminStore.news.length > 0) {
      return (
        <Flexbox flexDirection="column" marginBottom="20px">
          <DashboardDiscussYourNeeds />
        </Flexbox>
      );
    }
    return null;
  }
}
