import React from 'react';
import Flexbox from 'flexbox-react';
import { AnchorButton } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('StoreCategoryStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreCategoryTabs extends React.Component {
  renderBackButton() {
    if (this.props.backTo === 'store') {
      return (
        <Flexbox flexGrow={0} flexDirection="column" alignItems="center" justifyContent="center" marginTop="8px">
          <AnchorButton href={`/store`} className="bp3-minimal" rightIcon="undo">
            {I18n.t('js.store')}
          </AnchorButton>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={0} flexDirection="column" alignItems="center" justifyContent="center" marginTop="8px">
        <AnchorButton
          href={`/store/categories/${this.props.StoreCategoryStore.activeStoreCategory.fields.slug}`}
          className="bp3-minimal"
          rightIcon="undo"
        >
          {this.props.StoreCategoryStore.activeStoreCategory.fields.title}
        </AnchorButton>
      </Flexbox>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <nav className="bp3-navbar bp3-navbar-bordered">
          <Flexbox className="fixed-width-container">
            <Flexbox className="bp3-navbar-group" justifyContent="center" flexGrow={1} />
          </Flexbox>
        </nav>
      );
    }
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container" justifyContent="flex-end">
          {this.renderBackButton()}
        </Flexbox>
      </nav>
    );
  }
}
