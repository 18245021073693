// @flow
import * as React from 'react';

const skeletonBoxStyle = {
  width: '930px',
  margin: '20px auto',
};

type SkeletonBoxProps = {
  height: number,
};

class SkeletonBox extends React.Component<SkeletonBoxProps> {
  static defaultProps = {
    height: 200,
  };

  render() {
    const { height } = this.props;
    return <div className="skeleton-box bp3-skeleton" style={{ ...skeletonBoxStyle, height: `${height}px` }} />;
  }
}

export default SkeletonBox;
