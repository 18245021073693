import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import TemplateStateCard from './TemplateStateCard';
import TemplateStateAddState from './TemplateStateAddState';
import {
  arrayMove,
  sortableContainer,
  sortableElement,
} from 'react-sortable-hoc';
import _ from 'lodash';

const SortableState = sortableElement(({ state, participants }) => {
  const findParticipant = _.find(
    participants,
    o => o.id === state.participantId
  );
  return (
    <TemplateStateCard state={state} participant={findParticipant} />
  );
});

const SortableStates = sortableContainer(
  ({ states, participants }) => (
    <Flexbox
      flexDirection="row"
      flexGrow={1}
      flexWrap="wrap"
      justifyContent="flex-start"
      marginTop="20px"
    >
      {states.map(state => (
        <SortableState
          key={state.id}
          index={states.indexOf(state)}
          state={state}
          participants={participants}
        />
      ))}
      <TemplateStateAddState />
    </Flexbox>
  )
);

@inject('TemplateStore', 'TemplateActions')
@observer
export default class TemplateReorderStates extends React.Component {
  handleSortEnd(state, newIndex) {
    const stateObj = {
      id: state.id,
      row_order_position: newIndex,
    };
    this.props.TemplateActions.changeState(stateObj, true);
  }

  onSortEnd({ oldIndex, newIndex }) {
    // Find the state
    const findState = this.props.TemplateStore.states[oldIndex];

    // Reorder the current UI
    const newStates = arrayMove(
      this.props.TemplateStore.states.slice(),
      oldIndex,
      newIndex
    );
    this.props.TemplateStore.states = newStates;

    // Process reorder on server
    this.handleSortEnd(findState, newIndex);
  }

  render() {
    return (
      <SortableStates
        states={this.props.TemplateStore.states}
        participants={this.props.TemplateStore.participants}
        onSortEnd={this.onSortEnd.bind(this)}
        lockToContainerEdges={true}
        hideSortableGhost={true}
        axis="xy"
        distance={5}
      />
    );
  }
}
