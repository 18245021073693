import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import AccountSettingsDetailsAccountName from './AccountSettingsDetailsAccountName';
import AccountSettingsDetailsAccountSubdomain from './AccountSettingsDetailsAccountSubdomain';
import AccountSettingsDetailsAccountLogo from './AccountSettingsDetailsAccountLogo';
import AccountSettingsDetailsAccountDummyData from './AccountSettingsDetailsAccountDummyData';
import AccountSettingsDetailsAccountClose from './AccountSettingsDetailsAccountClose';

@inject('AccountSettingsStore', 'ToastStore')
@observer
export default class AccountSettingsDetails extends React.Component {
  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <AccountSettingsDetailsAccountName />
        <AccountSettingsDetailsAccountSubdomain />
        <AccountSettingsDetailsAccountLogo />
        <AccountSettingsDetailsAccountDummyData />
        <AccountSettingsDetailsAccountClose />
      </Flexbox>
    );
  }
}
