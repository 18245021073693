/* Legacy code - ignore this and complexity errors */
/* eslint complexity: 0 class-methods-use-this: 0 */
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import Utilities from '../utils/Utilities';
import { convertFromRaw, EditorState } from 'draft-js';
import _ from 'lodash';

export default class Element {
  @observable
  id;
  @observable
  sectionId;
  @observable
  elementType;
  @observable
  isEditable;
  @observable
  isRequired;
  @observable
  minLength;
  @observable
  maxLength;
  @observable
  privateView;
  @observable
  protectedView;
  @observable
  accountOwnerOnlyView;
  @observable
  repeatable;
  @observable
  displayOnDashboard;
  @observable
  rowOrder;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    sectionId,
    elementType,
    isEditable,
    isRequired,
    minLength,
    maxLength,
    privateView,
    protectedView,
    accountOwnerOnlyView,
    repeatable,
    displayOnDashboard,
    rowOrder,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.sectionId = sectionId || '';
    this.elementType = elementType || '';
    // FIXME these probably weren't meant to be expressions
    // eslint-disable-next-line no-unused-expressions
    typeof isEditable === 'undefined' || isEditable === null ? (this.isEditable = false) : (this.isEditable = isEditable);
    // eslint-disable-next-line no-unused-expressions
    typeof isRequired === 'undefined' || isRequired === null ? (this.isRequired = false) : (this.isRequired = isRequired);
    this.minLength = minLength || 0;
    this.maxLength = maxLength || 0;
    // eslint-disable-next-line no-unused-expressions
    typeof privateView === 'undefined' || privateView === null ? (this.privateView = false) : (this.privateView = privateView);
    // eslint-disable-next-line no-unused-expressions
    typeof protectedView === 'undefined' || protectedView === null ? (this.protectedView = false) : (this.protectedView = protectedView);
    // eslint-disable-next-line no-unused-expressions
    typeof accountOwnerOnlyView === 'undefined' || accountOwnerOnlyView === null
      ? (this.accountOwnerOnlyView = false)
      : (this.accountOwnerOnlyView = accountOwnerOnlyView);
    typeof repeatable === 'undefined' || repeatable === null ? (this.repeatable = false) : (this.repeatable = repeatable);
    typeof displayOnDashboard === 'undefined' || displayOnDashboard === null
      ? (this.displayOnDashboard = false)
      : (this.displayOnDashboard = displayOnDashboard);
    this.rowOrder = rowOrder || 0;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @action
  validateRequired(input) {
    if (input.length < 1 && this.isRequired) {
      return false;
    }
    return true;
  }

  @action
  validateRequiredMessage(input, full) {
    if (input.length < 1 && this.isRequired) {
      if (full) {
        return I18n.t('js.response_required_for_element', {
          name: this.fullTitle.toLowerCase(),
          elementName: this[this.camelCaseTitle].name,
        });
      }
      return I18n.t('js.a_response_is_required');
    }
    return null;
  }

  @action
  validateLength(input) {
    if (input === '' || input.length === 0) {
      return true;
    }
    if (this.minLength > 0 && input.length < this.minLength) {
      return false;
    }
    if (this.maxLength > 0 && input.length > this.maxLength) {
      return false;
    }
    return true;
  }

  @action
  validateLengthMessage(input, full) {
    if (this.minLength > 0 && input.length < this.minLength) {
      if (full) {
        return I18n.t('js.x_more_characters_required_for_element', {
          characters: this.minLength - input.length,
          name: this.fullTitle.toLowerCase(),
          elementName: this[this.camelCaseTitle].name,
        });
      }
      return I18n.t('js.x_more_characters_required', {
        characters: this.minLength - input.length,
      });
    }
    if (this.maxLength > 0) {
      if (full) {
        return I18n.t('js.x_characters_remaining_for_element', {
          characters: this.maxLength - input.length,
          name: this.fullTitle.toLowerCase(),
          elementName: this[this.camelCaseTitle].name,
        });
      }
      return I18n.t('js.x_characters_remaining', {
        characters: this.maxLength - input.length,
      });
    }
    return null;
  }

  @action
  validateEmail(email) {
    if (!Utilities.validateEmail(email)) {
      return false;
    }
    return true;
  }

  @action
  validateEmailMessage(email, full) {
    if (!Utilities.validateEmail(email)) {
      if (full) {
        return I18n.t('js.invalid_email_address_for_element', {
          name: this.fullTitle.toLowerCase(),
          elementName: this[this.camelCaseTitle].name,
        });
      }
      return I18n.t('js.invalid_email_address');
    }
    return null;
  }

  @action
  validateHyperlink(url) {
    if (!Utilities.validateUrl(url)) {
      return false;
    }
    return true;
  }

  @action
  validateHyperlinkMessage(url, full) {
    if (!Utilities.validateUrl(url)) {
      if (full) {
        return I18n.t('js.invalid_hyperlink_for_element', {
          name: this.fullTitle.toLowerCase(),
          elementName: this[this.camelCaseTitle].name,
        });
      }
      return I18n.t('js.invalid_hyperlink');
    }
    return null;
  }

  @computed
  get fullTitle() {
    switch (this.elementType) {
      case 'title':
        return I18n.t('js.title_element');
      case 'description':
        return I18n.t('js.description_element');
      case 'profile_value':
        return I18n.t('js.profile_value_element');
      case 'rich_textarea_display':
        return I18n.t('js.rich_textarea_element');
      case 'image':
        return I18n.t('js.image_element');
      case 'hyperlink':
        return I18n.t('js.hyperlink_element');
      case 'file_download':
        return I18n.t('js.file_download_element');
      case 'text_input':
        return I18n.t('js.text_input_element');
      case 'textarea':
        return I18n.t('js.textarea_element');
      case 'rich_textarea':
        return I18n.t('js.rich_textarea_element');
      case 'email':
        return I18n.t('js.email_element');
      case 'select':
        return I18n.t('js.select_element');
      case 'grid':
        return I18n.t('js.grid_element');
      case 'user_select':
        return I18n.t('js.user_select_element');
      case 'team_select':
        return I18n.t('js.team_select_element');
      case 'item_select':
        return I18n.t('js.item_select_element');
      case 'number':
        return I18n.t('js.number_element');
      case 'currency':
        return I18n.t('js.currency_element');
      case 'percentage':
        return I18n.t('js.percentage_element');
      case 'slider':
        return I18n.t('js.slider_element');
      case 'range_slider':
        return I18n.t('js.range_slider_element');
      case 'date':
        return I18n.t('js.date_element');
      case 'date_range':
        return I18n.t('js.date_range_element');
      case 'date_time':
        return I18n.t('js.date_time_element');
      case 'time':
        return I18n.t('js.time_element');
      case 'multiline':
        return I18n.t('js.multiline_element');
      case 'file_upload':
        return I18n.t('js.file_upload_element');
      case 'image_upload':
        return I18n.t('js.image_upload_element');
      case 'hyperlink_input':
        return I18n.t('js.hyperlink_input_element');
      case 'location_select':
        return I18n.t('js.location_select_element');
      case 'calculation':
        return I18n.t('js.calculation_element');
      default:
        return null;
    }
  }

  @computed
  get camelCaseTitle() {
    switch (this.elementType) {
      case 'title':
        return 'elementTitle';
      case 'description':
        return 'elementDescription';
      case 'profile_value':
        return 'elementProfileValue';
      case 'rich_textarea_display':
        return 'elementRichTextareaDisplay';
      case 'image':
        return 'elementImage';
      case 'hyperlink':
        return 'elementHyperlink';
      case 'file_download':
        return 'elementFileDownload';
      case 'text_input':
        return 'elementTextInput';
      case 'textarea':
        return 'elementTextarea';
      case 'rich_textarea':
        return 'elementRichTextarea';
      case 'email':
        return 'elementEmail';
      case 'select':
        return 'elementSelect';
      case 'grid':
        return 'elementGrid';
      case 'user_select':
        return 'elementObjectSelect';
      case 'team_select':
        return 'elementObjectSelect';
      case 'item_select':
        return 'elementObjectSelect';
      case 'number':
        return 'elementNumber';
      case 'currency':
        return 'elementNumber';
      case 'percentage':
        return 'elementNumber';
      case 'slider':
        return 'elementSlider';
      case 'range_slider':
        return 'elementSlider';
      case 'date':
        return 'elementDate';
      case 'date_range':
        return 'elementDate';
      case 'date_time':
        return 'elementDate';
      case 'time':
        return 'elementDate';
      case 'multiline':
        return 'elementMultiline';
      case 'file_upload':
        return 'elementFileInput';
      case 'image_upload':
        return 'elementImageUpload';
      case 'hyperlink_input':
        return 'elementHyperlinkInput';
      case 'location_select':
        return 'elementLocationSelect';
      case 'calculation':
        return 'elementCalculation';
      default:
        return null;
    }
  }

  @computed
  get isDisplayElement() {
    switch (this.elementType) {
      case 'title':
      case 'description':
      case 'rich_textarea_display':
      case 'image':
      case 'hyperlink':
      case 'file_download':
      case 'calculation':
        return true;
      default:
        return false;
    }
  }

  @computed
  get isAllowedToRepeat() {
    switch (this.elementType) {
      case 'text_input':
      case 'textarea':
      case 'rich_textarea':
      case 'email':
        return true;
      default:
        return false;
    }
  }

  @computed
  get isAllowedToDisplayOnDashboard() {
    if (this.isDisplayElement) {
      return false;
    }
    switch (this.elementType) {
      case 'grid':
      case 'multiline':
      case 'file_upload':
      case 'image_upload':
      case 'hyperlink_input':
      case 'location_select':
        return false;
      default:
        return true;
    }
  }

  @computed
  get isAvailableForFormulaBuilder() {
    switch (this.elementType) {
      case 'select':
      case 'grid':
      case 'number':
      case 'currency':
      case 'percentage':
      case 'slider':
      case 'range_slider':
      case 'date':
      case 'date_time':
      case 'date_range':
      case 'time':
      case 'profile_value':
      case 'calculation':
        return true;
      default:
        return false;
    }
  }

  @computed
  get isAvailableForRuleConditions() {
    switch (this.elementType) {
      case 'title':
      case 'description':
      case 'rich_textarea_display':
      case 'image':
      case 'hyperlink':
      case 'file_download':
      case 'grid':
      case 'image_upload':
      case 'file_upload':
      case 'location_select':
      case 'date_range':
      case 'range_slider':
        return false;
      default:
        return true;
    }
  }

  @computed
  get useItemElementToDisplay() {
    switch (this.elementType) {
      case 'grid':
      case 'slider':
      case 'range_slider':
      case 'location_select':
      case 'profile_value':
        return true;
      default:
        return false;
    }
  }

  @computed
  get isSelectObjectElement() {
    switch (this.elementType) {
      case 'user_select':
      case 'team_select':
      case 'item_select':
        return true;
      default:
        return false;
    }
  }

  @computed
  get isDateRelated() {
    switch (this.elementType) {
      case 'date':
      case 'date_range':
      case 'date_time':
        return true;
      default:
        return false;
    }
  }

  @action
  formattedItemValues(element) {
    if (element.itemValues) {
      if (element.itemValues.length > 0) {
        switch (element.elementType) {
          case 'text_input':
          case 'textarea':
          case 'email':
          case 'hyperlink_input':
          case 'location_select':
          case 'profile_value':
            if (element.itemValues[0]) {
              return element.itemValues[0].stringValue;
            }
            break;
          case 'rich_textarea':
            return EditorState.createWithContent(convertFromRaw(JSON.parse(element.itemValues[0].stringValue)))
              .getCurrentContent()
              .getPlainText();
          case 'multiline': {
            const displayValues = [];
            _.map(element.elementMultiline.elementMultilineInputs, input => {
              const findItemValue = _.find(element.itemValues, o => o.elementMultilineInputId === input.id);
              if (findItemValue) {
                displayValues.push(findItemValue.stringValue);
              }
            });
            return displayValues.join(', ');
          }
          case 'select': {
            const displayValues = [];
            _.map(element.elementSelect.elementSelectOptions, option => {
              const findItemValue = _.find(
                element.itemValues,
                itemValue => itemValue.elementSelectOptionId === option.id && itemValue.booleanValue
              );
              if (findItemValue) {
                displayValues.push(option.name);
              }
            });
            return displayValues.join(', ');
          }
          case 'grid': {
            const displayValues = [];
            _.map(element.elementGrid.elementGridRows, row => {
              _.map(element.elementGrid.elementGridColumns, column => {
                const findIntersection = _.find(
                  element.itemValues,
                  itemValue => itemValue.elementGridRowId === row.id && itemValue.elementGridColumnId === column.id
                );
                if (findIntersection) {
                  if (findIntersection.booleanValue) {
                    const value = `${row.name}: ${column.name}`;
                    displayValues.push(value);
                  }
                }
              });
            });
            return displayValues.join(', ');
          }
          case 'user_select': {
            let displayValues = [];
            _.map(element.itemValues, itemValue => {
              displayValues.push(itemValue.referenceUser.fullName);
            });
            displayValues = _.orderBy(displayValues, ['asc']);
            return displayValues.join(', ');
          }
          case 'team_select': {
            let displayValues = [];
            _.map(element.itemValues, itemValue => {
              displayValues.push(itemValue.referenceTeam.name);
            });
            displayValues = _.orderBy(displayValues, ['asc']);
            return displayValues.join(', ');
          }
          case 'item_select': {
            let displayValues = [];
            _.map(element.itemValues, itemValue => {
              displayValues.push(itemValue.referenceItem.name);
            });
            displayValues = _.orderBy(displayValues, ['asc']);
            return displayValues.join(', ');
          }
          case 'number': {
            if (element.itemValues[0]) {
              let value = element.itemValues[0].decimalValue;
              if (element.itemValues[0].decimalValue % 1 === 0 && element.itemValues[0].decimalValue !== '') {
                value = parseInt(element.itemValues[0].decimalValue);
              }
              if (isNaN(value)) {
                value = '';
              }
              return value;
            }
            break;
          }
          case 'percentage':
            if (element.itemValues[0]) {
              let value = element.itemValues[0].decimalValue;
              if (element.itemValues[0].decimalValue % 1 === 0 && element.itemValues[0].decimalValue !== '') {
                value = parseInt(element.itemValues[0].decimalValue);
              }
              if (isNaN(value)) {
                value = '';
              }
              return `${value}%`;
            }
            break;
          case 'currency':
            if (element.itemValues[0]) {
              let value = element.itemValues[0].decimalValue;
              if (element.itemValues[0].decimalValue % 1 === 0 && element.itemValues[0].decimalValue !== '') {
                value = parseInt(element.itemValues[0].decimalValue);
              }
              if (isNaN(value)) {
                value = '';
              }
              return `${element.elementNumber.prefix + value} ${element.elementNumber.suffix}`;
            }
            break;
          case 'slider': {
            const value = element.itemValues[0].numberValue;
            switch (element.elementSlider.labelType) {
              case 'currency':
                return `${value} ${this.props.element.elementSlider.labelCurrencyType}`;
              case 'percentage':
                return `${value}%`;
              default:
                return value;
            }
          }
          case 'range_slider': {
            const displayValues = [];
            const rangeValues = _.orderBy(element.itemValues, ['numberValue'], ['asc']);
            _.map(rangeValues, itemValue => {
              switch (element.elementSlider.labelType) {
                case 'currency':
                  displayValues.push(`${itemValue.numberValue} ${element.elementSlider.labelCurrencyType}`);
                  break;
                case 'percentage':
                  displayValues.push(`${itemValue.numberValue}%`);
                  break;
                default:
                  displayValues.push(itemValue.numberValue);
              }
            });
            return displayValues.join(' - ');
          }
          case 'date':
            if (element.itemValues[0]) {
              return moment.utc(element.itemValues[0].dateValue).format('DD/MM/YYYY');
            }
            break;
          case 'date_time':
            if (element.itemValues[0]) {
              return moment.utc(element.itemValues[0].dateValue).format('DD/MM/YYYY, h:mm a');
            }
            break;
          case 'time':
            if (element.itemValues[0]) {
              return moment.utc(element.itemValues[0].dateValue).format('h:mm a');
            }
            break;
          case 'date_range': {
            const displayValues = [];
            const dateValues = _.orderBy(element.itemValues, ['dateValue'], ['asc']);
            _.map(dateValues, itemValue => {
              displayValues.push(moment.utc(itemValue.dateValue).format('DD/MM/YYYY'));
            });
            return displayValues.join(' - ');
          }
          case 'image_upload':
            if (element.itemValues[0]) {
              if (element.itemValues[0].imageFileName.length > 0) {
                return element.itemValues[0].imageFileName;
              }
            }
            break;
          case 'file_upload':
            if (element.itemValues[0]) {
              if (element.itemValues[0].attachmentFileName.length > 0) {
                return element.itemValues[0].attachmentFileName;
              }
            }
            break;
          default:
            return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
    return undefined;
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
