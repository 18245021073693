import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { AnchorButton, Classes, Collapse, FormGroup, Intent, NumericInput, Switch } from '@blueprintjs/core';
import ElementReadOnly from '../../shared/ElementReadOnly';
import ElementIdSelector from '../../shared/ElementIdSelector';
import NumberValueValidator from '../../shared/NumberValueValidator';
import ElementTag from '../../shared/ElementTag';
import classNames from 'classnames';
import _ from 'lodash';

@inject('UserProfileTemplateStore', 'TemplateStore', 'TemplateActions')
@observer
export default class ElementNumber extends React.Component {
  @observable
  displayedInput = '';
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = { isBlurred: [], isLoading: false };
    if (this.props.sectionType === 'app') {
      this.store = this.props.TemplateStore;
    } else {
      this.store = this.props.UserProfileTemplateStore;
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const elementObj = {
      id: this.store.activeElement.id,
      is_required: this.store.activeElement.isRequired,
    };
    const elementNumberObj = {
      id: this.store.activeElement.elementNumber.id,
      name: this.store.activeElement.elementNumber.name,
      placeholder_text: this.store.activeElement.elementNumber.placeholderText,
      numeric_type: this.store.activeElement.elementNumber.numericType,
      prefix: this.store.activeElement.elementNumber.prefix,
      suffix: this.store.activeElement.elementNumber.suffix,
      min_value: this.store.activeElement.elementNumber.minValue,
      max_value: this.store.activeElement.elementNumber.maxValue,
    };
    this.props.TemplateActions.changeSubElement(elementNumberObj, 'element_numbers')
      .then(() => {
        this.props.TemplateActions.changeElement(elementObj)
          .then(() => {
            this.store.setActiveArea(null);
            this.toggleLoading();
          })
          .catch(() => {
            this.toggleLoading();
          });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  handleDisplayedValueChange(n, s) {
    this.displayedInput = s;
  }

  handleInputChange(e) {
    this.store.updateActiveElement(e.target.value, e.target.name);
  }

  handleMinValueChange(n, s) {
    this.store.updateActiveElement(s, 'minValue');
  }

  handleMaxValueChange(n, s) {
    this.store.updateActiveElement(s, 'maxValue');
  }

  handleResponseRequiredChange() {
    this.store.updateActiveElement(!this.store.activeElement.isRequired, 'isRequired');
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (field === 'minLength' || field === 'maxLength' || field === 'isRequired') {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement[`${field}Error`]}</div>;
      }
    } else {
      if (_.includes(this.state.isBlurred, field) && !this.store.activeElement.elementNumber[`${field}Valid`]) {
        return <div className="bp3-form-helper-text">{this.store.activeElement.elementNumber[`${field}Error`]}</div>;
      }
    }
    return undefined;
  }

  renderForm() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Flexbox flexDirection="column">
            <hr />
            <Flexbox justifyContent="space-between" alignItems="center">
              <Flexbox>
                <span className="bp3-icon bp3-icon-numerical push-10-r" />
                <h5 className={Classes.HEADING}>{I18n.t('js.number_element_options')}</h5>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1} marginTop="10px">
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementNumber.nameValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="name">
                    {I18n.t('js.name')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'name') && !this.store.activeElement.elementNumber.nameValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        name="name"
                        value={this.store.activeElement.elementNumber.name}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('name')}
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'placeholderText') && !this.store.activeElement.elementNumber.placeholderTextValid
                      ? classNames('bp3-form-group bp3-intent-danger')
                      : classNames('bp3-form-group')
                  }
                >
                  <label className="bp3-label" htmlFor="placeholderText">
                    {I18n.t('js.placeholder_text')}
                    <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                  </label>
                  <div className="bp3-form-content">
                    <div
                      className={
                        _.includes(this.state.isBlurred, 'placeholderText') && !this.store.activeElement.elementNumber.placeholderTextValid
                          ? classNames('bp3-input-group bp3-intent-danger')
                          : classNames('bp3-input-group')
                      }
                    >
                      <input
                        autoComplete="off"
                        className="bp3-input bp3-fill"
                        type="text"
                        name="placeholderText"
                        value={this.store.activeElement.elementNumber.placeholderText}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleBlurChange.bind(this)}
                        dir="auto"
                      />
                    </div>
                    {this.renderErrors('placeholderText')}
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="row" flexGrow={1}>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="minValue">
                    {I18n.t('js.minimum_value')} ({I18n.t('js.leave_blank_for_none')})
                  </label>
                  <div className="bp3-form-content">
                    <NumericInput
                      min={0}
                      value={this.store.activeElement.elementNumber.minValue === null ? 0 : this.store.activeElement.elementNumber.minValue}
                      name="minValue"
                      onValueChange={(n, s) => {
                        this.handleMinValueChange(n, s);
                      }}
                    />
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" flexGrow={1} flexBasis="1" marginLeft="20px">
                <div className="bp3-form-group">
                  <label className="bp3-label" htmlFor="maxValue">
                    {I18n.t('js.maximum_value')} ({I18n.t('js.leave_blank_for_none')})
                  </label>
                  <div className="bp3-form-content">
                    <NumericInput
                      min={0}
                      value={this.store.activeElement.elementNumber.maxValue === null ? 0 : this.store.activeElement.elementNumber.maxValue}
                      name="maxValue"
                      onValueChange={(n, s) => {
                        this.handleMaxValueChange(n, s);
                      }}
                    />
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column">
              <Switch
                className="bp3-control-no-margin"
                checked={this.store.activeElement.isRequired}
                label={I18n.t('js.response_required')}
                onChange={this.handleResponseRequiredChange.bind(this)}
              />
            </Flexbox>
            <Flexbox marginTop="20px">
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                text={I18n.t('js.save_changes')}
                icon="floppy-disk"
                loading={this.state.isLoading}
              />
              <AnchorButton text={I18n.t('js.cancel_changes')} onClick={this.props.handleCancel.bind(this)} className="push-10-l" />
            </Flexbox>
            <ElementIdSelector id={this.props.element.id} />
          </Flexbox>
        </form>
      );
    }
    return undefined;
  }

  renderOptions() {
    return (
      <Collapse isOpen={this.store.activeAreaId === this.props.element.id}>
        <Flexbox marginBottom="10px" flexDirection="column">
          {this.renderForm()}
        </Flexbox>
      </Collapse>
    );
  }

  renderDisplayObject(element) {
    return (
      <NumericInput
        placeholder={element.elementNumber.placeholderText}
        disabled={!this.props.element.stateElement.editable}
        value={this.displayedInput}
        onValueChange={(n, s) => {
          this.handleDisplayedValueChange(n, s, element);
        }}
        intent={element.elementNumber.inputValid(this.displayedInput) ? null : Intent.DANGER}
      />
    );
  }

  renderDisabledText(element) {
    if (!this.props.element.stateElement.editable) {
      return <ElementReadOnly element={element} />;
    }
    return undefined;
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderAccountOwnerOnlyTag() {
    if (this.props.element.accountOwnerOnlyView && !this.props.sectionAccountOwnerOnlyView) {
      return (
        <ElementTag
          tagType="account_owner_only"
          tagIcon="blocked-person"
          tagTooltip={I18n.t('js.account_owner_only_element_description')}
        />
      );
    }
  }

  renderLabel(name, isRequired) {
    return (
      <Flexbox flexDirection="row" flexGrow={0} alignContent="center" alignItems="center" marginBottom="-10px">
        {name}
        {isRequired ? <span className="bp3-text-muted push-5-l">{`(${I18n.t('js.required')})`}</span> : null}
        <Flexbox flexDirection="row" className="push-10-l">
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
          {this.renderAccountOwnerOnlyTag()}
        </Flexbox>
      </Flexbox>
    );
  }

  renderDisplayedElement() {
    if (this.store.activeAreaId === this.props.element.id) {
      return (
        <FormGroup
          label={this.renderLabel(this.store.activeElement.elementNumber.name, this.store.activeElement.isRequired)}
          labelFor={this.store.activeElement.id}
          intent={this.store.activeElement.elementNumber.inputValid(this.displayedInput) ? null : Intent.DANGER}
        >
          {this.renderDisplayObject(this.store.activeElement)}
          {this.renderDisabledText(this.store.activeElement)}
          <NumberValueValidator
            value={this.displayedInput}
            minValue={this.store.activeElement.elementNumber.minValue}
            maxValue={this.store.activeElement.elementNumber.maxValue}
            prefix={''}
            suffix={''}
            disabled={!this.props.element.stateElement.editable}
          />
        </FormGroup>
      );
    }
    return (
      <FormGroup
        label={this.renderLabel(this.props.element.elementNumber.name, this.props.element.isRequired)}
        labelFor={this.props.element.id}
        intent={this.props.element.elementNumber.inputValid(this.displayedInput) ? null : Intent.DANGER}
      >
        {this.renderDisplayObject(this.props.element)}
        {this.renderDisabledText(this.props.element)}
        <NumberValueValidator
          value={this.displayedInput}
          minValue={this.props.element.elementNumber.minValue}
          maxValue={this.props.element.elementNumber.maxValue}
          prefix={''}
          suffix={''}
          disabled={!this.props.element.stateElement.editable}
        />
      </FormGroup>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexGrow={1} flexDirection="column" flexWrap="wrap">
          {this.renderDisplayedElement()}
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          {this.renderOptions()}
        </Flexbox>
      </Flexbox>
    );
  }
}
