import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Tooltip } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';

@inject(
  'AppStore',
  'AppItemStore',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore'
)
@observer
export default class AppItemWebformCard extends React.Component {
  async handleCopyPath() {
    try {
      await Utilities.copyStringToClipboard(
        this.props.AppStore.activeApp.webformPath
      );
      this.props.ToastStore.showToast(
        I18n.t('js.copied_webform_path_to_your_clipboard'),
        'success'
      );
    } catch (err) {
      throw new Error('Failed to copy path: ', err);
    }
  }

  render() {
    if (
      this.props.AppStore.activeApp.webformEnabled ||
      this.props.AppStore.newApp.webformEnabled
    ) {
      return (
        <Flexbox
          className="bp3-small"
          flexGrow={1}
          justifyContent={this.props.justification}
        >
          <Flexbox
            onClick={() => this.handleCopyPath()}
            marginLeft="5px"
          >
            <Tooltip
              portalContainer={document.body}
              content={I18n.t('js.open_webform_in_new_window')}
            >
              <strong>
                <a
                  href={this.props.AppStore.activeApp.webformPath}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {this.props.AppStore.activeApp.strippedWebformPath}
                </a>
              </strong>
            </Tooltip>
          </Flexbox>
          <Flexbox
            onClick={() => this.handleCopyPath()}
            marginLeft="5px"
          >
            <Tooltip
              portalContainer={document.body}
              content={I18n.t('js.copy_webform_url_to_clipboard')}
            >
              <Flexbox className="bp3-text-muted bp3-icon bp3-icon-paperclip push-5-l bp3-cursor-pointer" />
            </Tooltip>
          </Flexbox>
        </Flexbox>
      );
    }
    return null;
  }
}
