/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import Avatar from 'react-avatar';
import ItemEditableTitle from './ItemEditableTitle';
import ItemTitleInput from './ItemTitleInput';
import { inject, observer } from 'mobx-react';
import { Tag, Tooltip, Position, Button } from '@blueprintjs/core';
import classNames from 'classnames';
import ItemManageParticipants from './ItemManageParticipants';
import Utilities from '../../utils/Utilities';
import ItemHistory from './ItemHistory';
import _ from 'lodash';

@inject('ItemStore', 'ItemActions', 'ToastStore', 'AppStore')
@observer
export default class ItemSummary extends React.Component {
  handleImageClick() {
    const { userIsOwner } = this.props.ItemStore;
    if (userIsOwner && !this.props.shownInDialogView) {
      this.props.ItemStore.activeTab = 'edit';
      this.props.ItemStore.activeEditSection = 'image';
    }
  }

  handleStatusClick() {
    const { userIsOwner } = this.props.ItemStore;
    if (userIsOwner && !this.props.shownInDialogView) {
      this.props.ItemStore.activeTab = 'edit';
      this.props.ItemStore.activeEditSection = 'status';
    }
  }

  renderImageIcon() {
    const { item, userIsOwner } = this.props.ItemStore;
    if (item.imageFileName.length > 0) {
      return (
        <Tooltip
          portalContainer={document.body}
          content={userIsOwner && !this.props.shownInDialogView ? I18n.t('js.select_image') : item.itemType}
          inheritDarkTheme={false}
          position={Position.RIGHT}
        >
          <Flexbox flexGrow={1} flexDirection="column" width="100px">
            <Avatar src={item.imageFileName} className="item-image-avatar" round={true} size="100px" />
          </Flexbox>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          portalContainer={document.body}
          content={userIsOwner && !this.props.shownInDialogView ? I18n.t('js.select_image') : item.itemType}
          inheritDarkTheme={false}
          position={Position.RIGHT}
        >
          <span className={`fa ${item.itemIcon}`} style={{ fontSize: '30px', margin: '10px' }}></span>
        </Tooltip>
      );
    }
  }

  toggleParticipants() {
    this.props.ItemStore.isShowingParticipants = !this.props.ItemStore.isShowingParticipants;
  }

  renderToggleExpandCollapse() {
    if (this.props.ItemStore.isShowingParticipants) {
      return (
        <Tooltip portalContainer={document.body} content={I18n.t('js.hide_process')} position={Position.RIGHT}>
          <Button minimal={true} rightIcon="collapse-all" onClick={this.toggleParticipants.bind(this)} text={I18n.t('js.hide_process')} />
        </Tooltip>
      );
    }
    return (
      <Tooltip portalContainer={document.body} content={I18n.t('js.show_process')} position={Position.RIGHT}>
        <Button minimal={true} rightIcon="expand-all" onClick={this.toggleParticipants.bind(this)} text={I18n.t('js.show_process')} />
      </Tooltip>
    );
  }

  render() {
    const { currentState, currentFullState, isShowingParticipants, item, userIsOwner } = this.props.ItemStore;
    let cardClass = classNames('bp3-card');
    if (isShowingParticipants) {
      cardClass = classNames('bp3-card square-bottom');
    }
    let bgStyle = {
      background: `url('${item.imageFileName}')`,
      backgroundSize: 'cover',
      height: '100px',
      width: '100px',
      borderRadius: '100px',
      border: `5px solid ${item.itemColor}`,
    };
    let iconClass = classNames('display-none');
    const darkenAmount = 0.25; // %
    if (item.imageFileName === '') {
      bgStyle = {
        background: `linear-gradient(${item.itemColor}, ${Utilities.darkenHex(item.itemColor, darkenAmount)})`,
        height: '100px',
        width: '100px',
        borderRadius: '100px',
        border: `5px solid ${item.itemColor}`,
      };
      iconClass = classNames('fa fa-2x text-white', item.itemIcon);
    }
    if (this.props.shownInDialogView) {
      return (
        <Flexbox flexDirection="column">
          <Flexbox marginBottom="10px">
            <ItemTitleInput />
          </Flexbox>
          <ItemManageParticipants shownInDialogView={this.props.shownInDialogView} />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" marginTop="0px">
        <Flexbox flexDirection="row" className="bp3-profile-header-row" flexGrow={1} justifyContent="space-between">
          <Flexbox flexDirection="column" flexGrow={1} paddingTop="20px" paddingLeft="20px" paddingBottom="10px">
            <Flexbox flexDirection="row" alignItems="center">
              <Flexbox
                style={bgStyle}
                className="bp3-cursor-pointer"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                marginRight="20px"
                onClick={this.handleImageClick.bind(this)}
                flexShrink={0}
              >
                <Flexbox className={iconClass} />
              </Flexbox>
              <Flexbox marginTop="10px" flexDirection="column">
                <Flexbox flexDirection="row">
                  <Flexbox flexDirection="row" onClick={this.handleStatusClick.bind(this)} paddingBottom="10px">
                    <Tooltip portalContainer={document.body} content={I18n.t('js.status')} inheritDarkTheme={false} position={Position.TOP}>
                      <Tag
                        style={{
                          backgroundColor: item.statusFlag.color,
                          color: '#fff',
                          cursor: userIsOwner && !this.props.shownInDialogView ? 'pointer' : 'default',
                        }}
                      >
                        {item.statusFlag.name}
                      </Tag>
                    </Tooltip>
                    <Tooltip portalContainer={document.body} content={I18n.t('js.state')} inheritDarkTheme={false} position={Position.TOP}>
                      <span className="push-10-l">{this.props.ItemStore.currentState.name}</span>
                    </Tooltip>
                  </Flexbox>
                </Flexbox>
                <Tooltip
                  portalContainer={document.body}
                  content={
                    userIsOwner
                      ? I18n.t('js.edit_item_name', { item: item.itemType })
                      : I18n.t('js.item_name_type', { item: item.itemType })
                  }
                  inheritDarkTheme={false}
                  position={Position.TOP}
                >
                  {userIsOwner ? <ItemEditableTitle /> : <h1 className="bp3-label-no-margin">{this.props.ItemStore.item.name}</h1>}
                </Tooltip>
              </Flexbox>
            </Flexbox>
          </Flexbox>
          <Flexbox flexDirection="row" flexGrow={0} flexShrink={0} justifyContent="flex-end">
            <ItemHistory />
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row" justifyContent="flex-end" paddingRight="20px" marginTop="20px" style={{ zIndex: 1 }}>
          {this.renderToggleExpandCollapse()}
        </Flexbox>
        <Flexbox flexDirection="column" marginTop={this.props.ItemStore.isShowingParticipants ? '-50px' : '0px'}>
          <ItemManageParticipants shownInDialogView={this.props.shownInDialogView} />
        </Flexbox>
      </Flexbox>
    );
  }
}
