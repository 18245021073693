import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Dialog, Intent, Switch } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';
import TeamsNewTeamSelectParents from './TeamsNewTeamSelectParents';
import TeamsNewTeamSelectChildren from './TeamsNewTeamSelectChildren';

@inject('TeamsStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class TeamsNewTeam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isOpen: false,
      isBlurred: [],
    };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleDialog() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleSubmit(e) {
    this.toggleLoading();
    this.setState({ isBlurred: [] });
    e.preventDefault();

    const formData = new FormData();
    formData.append(
      'account_id',
      this.props.CurrentUserStore.currentUser.accountId
    );
    formData.append('name', this.props.TeamsStore.newTeam.name);
    formData.append(
      'description',
      this.props.TeamsStore.newTeam.description
    );
    formData.append(
      'private',
      this.props.TeamsStore.newTeam.privateTeam
    );

    this.props.TeamsStore.createTeam(formData)
      .then(response => {
        const team = response.data;
        let totalLength =
          this.props.TeamsStore.parentTeamsToCreate.length +
          this.props.TeamsStore.childTeamsToCreate.length;
        this.determineCloseDialog(totalLength, team);
        _.map(
          this.props.TeamsStore.parentTeamsToCreate,
          parentTeam => {
            this.props.TeamsStore.createTeamRelationship(
              parentTeam.id,
              team.id
            )
              .then(() => {
                totalLength--;
                this.determineCloseDialog(totalLength, team);
              })
              .catch(() => {
                this.toggleLoading();
              });
          }
        );
        _.map(this.props.TeamsStore.childTeamsToCreate, childTeam => {
          this.props.TeamsStore.createTeamRelationship(
            team.id,
            childTeam.id
          )
            .then(() => {
              totalLength--;
              this.determineCloseDialog(totalLength, team);
            })
            .catch(() => {
              this.toggleLoading();
            });
        });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  determineCloseDialog(length, team) {
    if (length === 0) {
      this.toggleLoading();
      this.toggleDialog();
      // this.props.ToastStore.showToast(I18n.t('js.team_created'), 'success');
      this.props.handleTeamSelectChange(team);
      this.props.TeamsStore.resetStartingAttributes();
    }
  }

  handleInputChange(e) {
    this.props.TeamsStore.updateNewTeam(
      e.target.value,
      e.target.name
    );
  }

  handlePrivateTeamChange() {
    this.props.TeamsStore.updateNewTeam(
      !this.props.TeamsStore.newTeam.privateTeam,
      'privateTeam'
    );
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.TeamsStore.newTeam[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {this.props.TeamsStore.newTeam[`${field}Error`]}
        </div>
      );
    }
    return undefined;
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        icon="add"
        isOpen={this.state.isOpen}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={true}
        title={I18n.t('js.create_a_new_team')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <p>{I18n.t('js.create_a_new_team_description')}</p>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'name') &&
                  !this.props.TeamsStore.newTeam.nameValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.team_name')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(this.state.isBlurred, 'name') &&
                      !this.props.TeamsStore.newTeam.nameValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.sales_team')}
                      name="name"
                      value={this.props.TeamsStore.newTeam.name}
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('name')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column">
              <div
                className={
                  _.includes(this.state.isBlurred, 'description') &&
                  !this.props.TeamsStore.newTeam.descriptionValid
                    ? classNames('bp3-form-group bp3-intent-danger')
                    : classNames('bp3-form-group')
                }
              >
                <label className="bp3-label" htmlFor="description">
                  {I18n.t('js.team_description')}
                  <span className="bp3-text-muted push-5-l">
                    ({I18n.t('js.required')})
                  </span>
                </label>
                <div className="bp3-form-content">
                  <div
                    className={
                      _.includes(
                        this.state.isBlurred,
                        'description'
                      ) &&
                      !this.props.TeamsStore.newTeam.descriptionValid
                        ? classNames(
                            'bp3-input-group bp3-intent-danger'
                          )
                        : classNames('bp3-input-group')
                    }
                  >
                    <textarea
                      className="bp3-input bp3-textarea bp3-fill"
                      type="textarea"
                      placeholder={I18n.t(
                        'js.sales_team_description'
                      )}
                      name="description"
                      value={
                        this.props.TeamsStore.newTeam.description
                      }
                      onChange={this.handleInputChange.bind(this)}
                      onBlur={this.handleBlurChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                  {this.renderErrors('description')}
                </div>
              </div>
            </Flexbox>
            <Flexbox flexDirection="column" flexGrow={1}>
              <label className="bp3-label max-width">
                {I18n.t('js.select_parent_team_label')}
              </label>
              <Flexbox>
                <TeamsNewTeamSelectParents />
              </Flexbox>
            </Flexbox>
            <Flexbox
              marginTop="20px"
              flexDirection="column"
              flexGrow={1}
            >
              <label className="bp3-label max-width">
                {I18n.t('js.select_child_team_label')}
              </label>
              <Flexbox>
                <TeamsNewTeamSelectChildren />
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <Switch
                className="bp3-control-no-margin"
                checked={this.props.TeamsStore.newTeam.privateTeam}
                label={I18n.t('js.private_team_description')}
                onChange={this.handlePrivateTeamChange.bind(this)}
              />
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button
                text={I18n.t('js.cancel')}
                onClick={this.toggleDialog.bind(this)}
              />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.props.TeamsStore.newTeamFormValid}
                text={I18n.t('js.create_new_team')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="row" marginRight="10px">
        <Flexbox flexGrow={0} flexDirection="column">
          <Flexbox>
            <Button
              className="bp3-intent-primary"
              icon="add"
              text={I18n.t('js.create_a_new_team')}
              onClick={this.toggleDialog.bind(this)}
            />{' '}
            {this.renderDialog()}
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
