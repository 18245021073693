/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import { computed, observable } from 'mobx';
import moment from 'moment';

export default class BambooIntegration {
  @observable
  id;
  @observable
  accountId;
  @observable
  apiKey;
  @observable
  subdomain;
  @observable
  includeProfilePhotos;
  @observable
  includeDepartments;
  @observable
  includeManagers;
  @observable
  active;
  @observable
  createdAt;
  @observable
  createdBy;
  @observable
  deletedAt;
  @observable
  deletedBy;
  @observable
  updatedAt;
  @observable
  updatedBy;

  constructor(
    id,
    accountId,
    apiKey,
    subdomain,
    includeProfilePhotos,
    includeDepartments,
    includeManagers,
    active,
    createdAt,
    createdBy,
    deletedAt,
    deletedBy,
    updatedAt,
    updatedBy
  ) {
    this.id = id || '';
    this.accountId = accountId || '';
    this.apiKey = apiKey || '';
    this.subdomain = subdomain || '';
    this.includeProfilePhotos = includeProfilePhotos || true;
    this.includeDepartments = includeDepartments || true;
    this.includeManagers = includeManagers || true;
    this.active = active || '';
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || '';
    this.deletedAt = deletedAt || '';
    this.deletedBy = deletedBy || '';
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy || '';
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
