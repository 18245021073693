import { action, computed, observable } from 'mobx';
import axios from 'axios';
import Cookies from 'js-cookie';
import ToastStore from './ToastStore';
import AzureIntegration from '../models/AzureIntegrationModel';
import Sync from '../models/SyncModel';
import Utilities from '../utils/Utilities';

class AzureIntegrationStore {
  @observable
  isLoading = true;
  @observable
  initialAzureIntegration = null;
  @observable
  azureIntegration = null;
  @observable
  errorsList = {};
  @observable
  syncs = [];
  @observable
  isDrawerOpen = false;
  @observable
  syncsLoading = false;
  @observable
  userSyncs = [];
  @observable
  syncErrors = [];
  @observable
  teamSyncs = [];
  @observable
  teamArray = [];
  @observable
  syncsDetailsLoading = false;
  @observable
  response = [];
  @observable
  isSyncTriggered = false;

  @action
  clearSyncDetails() {
    this.userSyncs = [];
    this.syncErrors = [];
    this.teamSyncs = [];
    this.response = [];
  }

  get hasSyncs() {
    if (_.isEmpty(this.syncs)) {
      return false;
    }
    return true;
  }

  @computed
  get hasAzureIntegration() {
    if (!_.isEmpty(this.azureIntegration)) {
      return true;
    }
    return false;
  }

  @computed
  get hasErrors() {
    if (!_.isEmpty(this.errorsList)) {
      return true;
    }
    return false;
  }

  @computed
  get hasNewValidChanges() {
    if (_.isEmpty(this.errorsList)) {
      if (
        _.isEqual(
          _.omit(this.azureIntegration, ['active']),
          _.omit(this.initialAzureIntegration, ['active'])
        )
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  @computed
  get hasNewChanges() {
    return !_.isEqual(
      _.omit(this.azureIntegration, ['active']),
      _.omit(this.initialAzureIntegration, ['active'])
    );
  }

  @computed
  get isSyncRunning() {
    for (const sync of this.syncs) {
      if (sync.status == 'in_progress') {
        return true;
        break;
      }
    }
  }

  @action
  validateInput(name, value, blur) {
    if (blur == 'true') {
      if (_.isEmpty(this.errorsList) && value == '') {
        this.errorsList[`${name}`] = I18n.t(
          `js.please_enter_valid_${name}`
        );
      }
    } else {
      if (name.includes('include')) {
        this.azureIntegration[`${name}`] = !this.azureIntegration[
          `${name}`
        ];
      } else {
        if (!_.isEmpty(this.errorsList) && name in this.errorsList) {
          delete this.errorsList[name];
        }
        this.azureIntegration[`${name}`] = value;
        const capital_name =
          name.charAt(0).toUpperCase() + name.slice(1);
        const validate_method = `validateAzure${capital_name}`;
        if (!Utilities[`${validate_method}`](value)) {
          this.errorsList[`${name}`] = I18n.t(
            `js.please_enter_valid_${name}`
          );
        }
      }
    }
  }

  @action
  toggleActive() {
    this.azureIntegration.active = !this.azureIntegration.active;
  }

  @action
  toggleBoolean(name) {
    this[`${name}`] = !this[`${name}`];
  }

  @action
  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action
  fetchAzureIntegration() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/azure_integrations`)
        .then(response => {
          response.data == null
            ? this.createEmptyIntegration()
            : this.updateIntegrationModel(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  createAzureIntegration(azureIntegrationObj) {
    this.updateLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Cookies.get('apiEnv')}/azure_integrations`,
          azureIntegrationObj
        )
        .then(response => {
          this.updateIntegrationModel(response.data);
          ToastStore.showToast(
            'Azure integration has been created.',
            'success'
          );
          this.updateLoading(false);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteAzureIntegration(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/azure_integrations/${id}`)
        .then(response => {
          ToastStore.showToast(
            I18n.t('js.azure_integration_deleted'),
            'success'
          );
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  removeAzureIntegration() {
    this.azureIntegration = this.createEmptyIntegration();
    this.clearSyncDetails();
    this.syncs = [];
    this.setInitialAzureIntegration();
  }

  @action
  setInitialAzureIntegration() {
    this.initialAzureIntegration = _.clone(this.azureIntegration);
  }

  @action
  updateLoading(value) {
    this.isLoading = value;
  }

  @action
  updateAzureIntegration(azureID, azureIntegrationObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Cookies.get('apiEnv')}/azure_integrations/${azureID}`,
          azureIntegrationObj
        )
        .then(response => {
          ToastStore.showToast(
            I18n.t('js.integration_updated'),
            'success'
          );
          this.updateIntegrationModel(response.data);
          this.setInitialAzureIntegration();
          resolve(response);
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  createEmptyIntegration() {
    this.azureIntegration = new AzureIntegration();
    this.setInitialAzureIntegration();
  }

  @action
  updateIntegrationModel(data) {
    if (this.azureIntegration == null) {
      this.createEmptyIntegration();
    }
    (this.azureIntegration.id = data.id),
      (this.azureIntegration.accountId = data.account_id),
      (this.azureIntegration.clientId = data.client_id),
      (this.azureIntegration.clientSecret = data.client_secret),
      (this.azureIntegration.clientUri = data.client_uri),
      (this.azureIntegration.clientTenant = data.client_tenant),
      (this.azureIntegration.includeProfilePhotos =
        data.include_profile_photos),
      (this.azureIntegration.includeDepartments =
        data.include_departments),
      (this.azureIntegration.includeManagers = data.include_managers),
      (this.azureIntegration.active = data.active),
      (this.azureIntegration.createdAt = data.created_at),
      (this.azureIntegration.createdBy = data.created_by),
      (this.azureIntegration.deletedAt = data.deleted_at),
      (this.azureIntegration.deletedBy = data.deleted_by),
      (this.azureIntegration.updatedAt = data.updated_at),
      (this.azureIntegration.updatedBy = data.updated_by);
    this.setInitialAzureIntegration();
  }

  @action
  cancelChanges() {
    this.azureIntegration = _.clone(this.initialAzureIntegration);
  }

  // --------------------EVERYTHING TO DO WITH SYNCS--------------------

  @action
  fetchSyncs() {
    this.syncsLoading = true;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get('apiEnv')}/azure_integrations/${
            this.azureIntegration.id
          }/syncs`
        )
        .then(response => {
          const syncs = response.data;
          syncs.map(sync => this.addSync(sync));
          this.syncsLoading = false;
          resolve(response);
        })
        .catch(error => {
          const errors = response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  fetchSyncDetails(syncID) {
    this.syncsDetailsLoading = true;
    this.teamArray = [];
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Cookies.get(
            'apiEnv'
          )}/azure_integrations/syncs/${syncID}`
        )
        .then(response => {
          this.userSyncs = response.data.user_syncs;
          this.syncErrors = response.data.sync_errors;
          this.teamSyncs = response.data.team_syncs;
          let teamNames = [];
          this.teamSyncs.map(team_sync => {
            if (!teamNames.includes(team_sync.team.name)) {
              teamNames.push(team_sync.team.name);
              this.teamArray.push({
                id: team_sync.team.id,
                name: team_sync.team.name,
                image: team_sync.team.image_file_name,
                updated_at: team_sync.team.updated_at,
              });
            }
          });
          this.syncsDetailsLoading = false;
          resolve(response);
        })
        .catch(error => {
          this.syncsDetailsLoading = false;
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  runSynchronization() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/azure_integrations/run-sync`)
        .then(response => {
          this.addSync(response.data);
          this.toggleBoolean('isSyncTriggered');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  addSync(sync) {
    const newSync = new Sync(
      sync.id,
      sync.duration,
      sync.status,
      sync.started_at,
      sync.syncs
    );
    this.syncs = _.unionBy([newSync], this.syncs, 'id');

    var sortedArray = _.sortBy(this.syncs, function(sync) {
      return sync.startedAt;
    }).reverse();
    this.syncs = sortedArray;
  }

  @action
  toggleDrawer(syncID) {
    this.clearSyncDetails();
    if (this.isDrawerOpen == false) {
      this.fetchSyncDetails(syncID);
    }
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  @action
  clearSyncDetails() {
    this.userSyncs = [];
    this.syncErrors = [];
    this.teamSyncs = [];
    this.response = [];
    this.teamArray = [];
  }
}
const store = new AzureIntegrationStore();
export default store;
