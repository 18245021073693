import React from 'react';
import Flexbox from 'flexbox-react';
import { Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('AppStore', 'WorkspaceStore', 'StoreTemplateActions', 'StoreTemplateStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class StoreTemplatesTabs extends React.Component {
  toggleDeleting() {
    this.isDeleting = !this.isDeleting;
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleTabChange(tab) {
    this.props.StoreTemplateStore.activeHeaderTab = tab;
  }

  renderRightLinks() {
    return (
      <Flexbox>
        <Tabs
          id="storeTemplateHeaderTabs"
          animate={true}
          selectedTabId={this.props.StoreTemplateStore.activeHeaderTab}
          defaultSelectedTabId="actions"
          onChange={this.handleTabChange.bind(this)}
          className="noselect"
        >
          <Tab id="apps" title={I18n.t('js.apps')} />
          <Tab id="workspaces" title={I18n.t('js.workspaces')} />
          <Tab id="profile_templates" title={I18n.t('js.profile_templates')} />
          <Tabs.Expander />
        </Tabs>
      </Flexbox>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <nav className="bp3-navbar bp3-navbar-bordered">
          <Flexbox className="fixed-width-container">
            <Flexbox className="bp3-navbar-group" justifyContent="center" flexGrow={1} />
          </Flexbox>
        </nav>
      );
    }
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      </nav>
    );
  }
}
