/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
// @flow
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import arrayMove from 'array-move';
import DeepDiff from 'deep-diff';
import Utilities from '../utils/Utilities';
import ReportUtilities from '../utils/ReportUtilities';
import ProfileTemplate from '../models/ProfileTemplateModel';
import ReportTemplate from '../models/ReportTemplateModel';
import ReportTemplateColumn from '../models/ReportTemplateColumnModel';
import ReportTemplateFilter from '../models/ReportTemplateFilterModel';
import ReportChart from '../models/ReportChartModel';
import DataViewActions from '../actions/DataViewActions';
import ReportTemplateActions from '../actions/ReportTemplateActions';
import ProfileTemplateActions from '../actions/ProfileTemplateActions';
import AppStore from './AppStore';
import AccountUsersStore from './AccountUsersStore';
import type { DataViewLocation, ItemRowData, TableData, UUID } from '../types/DataViewTypes';
import nanoid from 'nanoid';
import { element } from 'prop-types';

// export here is for typing purposes only
export class DataViewStore {
  // Main Objects =================================================================================
  @observable allTableData: TableData;
  @observable isLoadingAllTableData: boolean = false;
  @observable isLoadingReportTemplatesData: boolean = false;
  @observable activeReportTemplate: ReportTemplate = ReportUtilities.emptyReportTemplate({
    appId: AppStore.activeApp.id,
    profileTemplateId: undefined,
  });
  @observable reportTemplates: Array<ReportTemplate>;
  @observable newReportTemplate: ReportTemplate = ReportUtilities.emptyReportTemplate({
    appId: AppStore.activeApp.id,
    profileTemplateId: undefined,
  });
  @observable reportTemplateFiltersToDelete: Array<string> = [];
  @observable dataViewLocation: DataViewLocation;
  @observable reportCharts = this.startingReportCharts();
  @observable defaultChartLayout = this.startingChartConfig();
  @observable currentChartLayout = this.startingChartConfig();
  @observable activeChart = null;

  @computed
  get profileTemplateIsSelected(): boolean {
    return AccountUsersStore.activeProfileTemplate.id !== '';
  }

  @computed
  get emptyReportTemplateParams(): {
    appId: ?UUID,
    profileTemplateId: ?UUID,
  } {
    switch (this.dataViewLocation) {
      case 'app':
        return {
          appId: AppStore.activeApp.id,
          profileTemplateId: undefined,
        };
      case 'profile':
        return {
          appId: undefined,
          profileTemplateId: AccountUsersStore.activeProfileTemplate.id,
        };
      default:
        throw new Error('location is not accounted for');
    }
  }
  @computed
  get filteredReportTemplates(): Array<ReportTemplate> {
    return this.dataViewLocation === 'profile' || !this.reportTemplates
      ? this.reportTemplates.filter(o => o.profileTemplateId === AccountUsersStore.activeProfileTemplate.id)
      : this.reportTemplates;
  }

  @computed
  get isEditingUnsavedTemplate(): boolean {
    return this.activeReportTemplate.id === 'undefined';
  }

  @computed
  get isActiveReportTemplatePersisted(): boolean {
    return this.activeReportTemplate.createdAt.length > 0;
  }

  @computed
  get displayedTableData(): TableData {
    if (this.isLoadingAllTableData || this.isLoadingReportTemplatesData) {
      return [];
    }
    return _.flowRight([
      ReportUtilities.applyHiddenColumns(this.activeReportTemplate),
      ReportUtilities.applySorts(this.sortColumns),
      ReportUtilities.applyFilters(this.activeReportTemplate),
      ReportUtilities.applyColumnOrder(this.activeReportTemplate),
    ])(this.allTableData);
  }
  @computed
  get hasTableData(): boolean {
    return Array.isArray(this.allTableData) && this.allTableData.length > 0;
  }

  @computed
  get hasUnsavedChanges(): boolean {
    const oldActiveReportTemplate = this.reportTemplates.find(o => o.id === this.activeReportTemplate.id);
    const hasReportChanges = DeepDiff(this.activeReportTemplate, oldActiveReportTemplate);
    const hasChartChanges = DeepDiff(this.reportCharts, oldActiveReportTemplate.chartConfiguration.charts);
    const hasChartLayoutChanges = DeepDiff(this.currentChartLayout, oldActiveReportTemplate.chartConfiguration.layout);

    if (hasReportChanges || hasChartChanges || hasChartLayoutChanges) {
      return false;
    }
    return true;
  }

  @computed
  get hiddenColumns(): Array<ReportTemplateColumn> {
    return this.activeReportTemplate.reportTemplateColumns.filter(reportTemplateColumn => reportTemplateColumn.hidden);
  }

  @computed
  get activeColumns(): Array<ReportTemplateColumn> {
    return this.activeReportTemplate.reportTemplateColumns;
  }

  @computed
  get sortColumns(): Array<ReportTemplateColumn> {
    // The columns which are sortable are those which do not have the (joint) lowest sort priority:
    // lowestSortPriority is the highest number
    if (!(this.activeColumns.length > 0)) {
      return [];
    }
    return this.activeColumns
      .filter(column => column.sortPriority < this.lowestSortPriority)
      .sort((colOne, colTwo) => colOne.sortPriority - colTwo.sortPriority); // sort by priority
  }

  // Charts =======================================================================================
  @computed
  get activeChartConfigurationToString() {
    const chartConfiguration = {
      charts: this.reportCharts,
      layout: this.currentChartLayout,
    };
    return JSON.stringify(chartConfiguration);
  }

  @computed
  get activeChartLegendEnabled() {
    return this.activeChart.options.legend != null;
  }

  @computed
  get availableElements() {
    const elements = [];
    _.map(this.displayedTableData, item => {
      _.map(item, datum => {
        elements.push({ name: datum.column_name, id: datum.column_id });
      });
    });
    return _.uniqBy(elements, 'id');
  }

  @computed
  get availableElementsForFormulaBuilder() {
    const elements = [];
    _.map(this.displayedTableData, item => {
      _.map(item, datum => {
        if (datum.column_type === 'number' || datum.column_number_value != null || datum.column_type === 'datetime') {
          let icon = 'new-text-box';
          switch (datum.column_type) {
            case 'number':
              icon = 'numerical';
              break;
            case 'datetime':
              icon = 'timeline-events';
              break;
          }
          elements.push({
            name: datum.column_name,
            id: datum.column_id,
            icon: icon,
            type: datum.column_type,
            implementation: `"${datum.column_id}"`,
          });
        }
      });
    });
    return _.uniqBy(elements, 'id');
  }

  @computed
  get availableNumberElements() {
    const elements = [];
    _.map(this.displayedTableData, item => {
      _.map(item, datum => {
        if (datum.column_type === 'number' || datum.column_number_value != null) {
          if (!_.find(elements, o => o.id === datum.column_id)) {
            elements.push({ name: datum.column_name, id: datum.column_id });
          }
        }
      });
    });
    return _.uniqBy(elements, 'id');
  }

  @computed
  get availableDateElements() {
    const elements = [];
    _.map(this.displayedTableData[0], datum => {
      if (datum.column_type === 'datetime') {
        elements.push({ name: datum.column_name, id: datum.column_id });
      }
    });
    return elements;
  }

  @action applyChartConfigurations() {
    // Apply configuration object from saved report template to active view
    const { chartConfiguration } = this.activeReportTemplate;
    if (chartConfiguration) {
      const { charts, layout } = chartConfiguration;
      const newReportCharts = [];
      _.map(charts, chart => {
        const newChart = new ReportChart({
          id: chart.id,
          name: chart.name,
          type: chart.type,
          icon: chart.icon,
          options: chart.options,
          dataConfig: chart.dataConfig,
        });
        newReportCharts.push(newChart);
      });
      this.reportCharts = newReportCharts;
      this.currentChartLayout = layout;
    } else {
      this.resetChartLayout();
    }
  }

  @action resetPeristedChartConfiguration() {
    this.reportCharts = [];
    this.setActiveReportTemplate(this.activeReportTemplate.id);
  }

  @action setActiveChart(chartId) {
    const chart = _.find(this.reportCharts, o => o.id === chartId);
    if (chart) {
      this.activeChart = _.cloneDeep(chart);
    } else {
      this.chart = null;
    }
  }

  @action updateActiveChart(data, attribute) {
    this.activeChart[attribute] = data;
  }

  @action resetChartLayout() {
    this.reportCharts = this.startingReportCharts();
    this.defaultChartLayout = this.startingChartConfig();
    this.currentChartLayout = this.startingChartConfig();
  }

  @action addChart(chartObj) {
    this.reportCharts = _.unionBy([chartObj], this.reportCharts, 'id');
    let newLayout = this.currentChartLayout === null ? _.cloneDeep(this.defaultChartLayout) : _.cloneDeep(this.currentChartLayout);

    if (!this.doesCurrentChartLayoutIncludeId(newLayout, chartObj.id)) {
      if (this.currentChartLayout === null) {
        newLayout = chartObj.id;
      } else {
        if (typeof this.currentChartLayout === 'string') {
          // We only have one node, one chart
          newLayout = {
            direction: 'row',
            first: this.currentChartLayout,
            second: chartObj.id,
          };
        } else {
          newLayout = this.constructNewNode(newLayout, chartObj);
        }
      }
      this.currentChartLayout = newLayout;
    }
  }

  constructNewNode = (object, chartObj) => {
    let newObj = _.cloneDeep(object);
    _.mapKeys(object, (value, key) => {
      const nodeType = typeof value;
      if (nodeType === 'string') {
        if (key === 'second') {
          // We know we are at end node for even pairs, so convert the string into object
          const newNode = {
            direction: this.reportCharts.length % 2 === 0 ? 'row' : 'column',
            first: value,
            second: chartObj.id,
          };
          newObj[key] = newNode;
        }
      } else {
        if (nodeType === 'object' && key === 'second') {
          newObj[key] = this.constructNewNode(value, chartObj);
        }
      }
    });
    return newObj;
  };

  @action removeChart(reportChartId) {
    this.reportCharts = _.filter(this.reportCharts, o => o.id != reportChartId);
    let newLayout = this.currentChartLayout === null ? _.cloneDeep(this.defaultChartLayout) : _.cloneDeep(this.currentChartLayout);

    if (this.reportCharts.length === 0) {
      newLayout = null;
    } else {
      if (this.reportCharts.length === 1) {
        newLayout = this.reportCharts[0].id;
      } else {
        newLayout = this.removeNode(newLayout, reportChartId);
      }
    }
    this.currentChartLayout = newLayout;
  }

  removeNode = (object, reportChartId) => {
    let newObj = _.cloneDeep(object);
    if (newObj['first'] === reportChartId) {
      if (typeof newObj['second'] === 'string') {
        newObj = newObj['second'];
      } else {
        newObj['first'] = newObj['second']['first'];
        newObj['second'] = newObj['second']['second'];
      }
    } else {
      _.mapKeys(object, (value, key) => {
        const nodeType = typeof value;
        if (nodeType === 'string') {
          if (key === 'first' && value === reportChartId) {
            newObj = newObj['second'];
          }
          if (key === 'second' && value === reportChartId) {
            newObj = newObj['first'];
          }
        } else {
          if (nodeType === 'object') {
            newObj[key] = this.removeNode(value, reportChartId);
          }
        }
      });
    }
    return newObj;
  };

  startingChartConfig = () => {
    return null;
    // return {
    //   direction: 'row',
    //   first: this.reportCharts[0].id,
    //   second: this.reportCharts[1].id,
    //   splitPercentage: 70,
    // };
  };

  startingReportCharts = () => {
    return [];
    // return [
    //   new ReportChart({
    //     id: nanoid(),
    //     name: `${AppStore.activeApp.itemPlural} ${I18n.t('js.by_month')}`,
    //     type: 'line',
    //     icon: 'timeline-line-chart',
    //     options: '',
    //     dataConfig: '',
    //   }),
    //   new ReportChart({
    //     id: nanoid(),
    //     name: `${AppStore.activeApp.itemPlural} ${I18n.t('js.by_status')}`,
    //     type: 'pie',
    //     icon: 'pie-chart',
    //     options: '',
    //     dataConfig: '',
    //   }),
    // ];
  };

  doesCurrentChartLayoutIncludeId = (data, chartId) => {
    return JSON.stringify(data).includes(chartId);
  };

  // Server =======================================================================================

  @action
  toggleIsLoadingAllTableData = () => {
    this.isLoadingAllTableData = !this.isLoadingAllTableData;
  };

  @action
  toggleIsLoadingReportTemplatesData = () => {
    this.isLoadingReportTemplatesData = !this.isLoadingReportTemplatesData;
  };

  @action
  resetReportTemplateData() {
    this.reportTemplates = [];
  }

  @action
  addTableData = (tableData: TableData) => {
    this.allTableData = tableData;
    this.isLoadingAllTableData = false;
  };

  @action
  insertNewReportTemplate = () => {
    if (this.hasTableData) {
      const newReportTemplate: ReportTemplate = ReportUtilities.emptyReportTemplate(this.emptyReportTemplateParams);
      const rowData: ItemRowData = this.allTableData[0];
      newReportTemplate.reportTemplateColumns = ReportUtilities.generateReportTemplateColumnsFromTabledata(
        rowData,
        this.activeReportTemplate.id
      );
      this.addReportTemplate(newReportTemplate);
      this.setActiveReportTemplate(newReportTemplate.id);
    }
  };

  @action async loadTableData(reportTemplateId: string = '') {
    const { dataViewLocation } = this;
    const id = location => {
      switch (location) {
        case 'app':
          return AppStore.activeApp.id;
        case 'profile':
          return AccountUsersStore.activeProfileTemplate.id;
        default:
          throw new Error(`location (${dataViewLocation}) is not accounted for`);
      }
    };
    this.isLoadingAllTableData = true;
    await DataViewActions.fetchDataViewData({
      dataViewLocation,
      appOrProfileId: id(dataViewLocation),
    }).then(tableData => {
      this.addTableData(tableData);
      this.loadReportTemplatesData(reportTemplateId);
    });
  }

  @action
  loadReportTemplatesData = (reportTemplateId: string = '') => {
    this.resetReportTemplateData();
    this.isLoadingReportTemplatesData = true;
    if (this.dataViewLocation === 'app') {
      this.insertNewReportTemplate();
      const appId = AppStore.activeApp.id;
      // Add a blank report template to the reportTemplates array
      ReportTemplateActions.fetchReportTemplates(appId)
        .then(data => {
          this.createAndAddReportTemplates(data);
        })
        .then(() => {
          const reportTemplateIsPresent = !!this.reportTemplates.find(reportTemplate => reportTemplate.id === reportTemplateId);
          if (reportTemplateId && reportTemplateIsPresent) {
            this.setActiveReportTemplate(reportTemplateId);
          } else {
            if (!reportTemplateId) {
              // Check for a default report template
              _.map(this.reportTemplates, reportTemplate => {
                if (reportTemplate.defaultReportTemplate) {
                  this.setActiveReportTemplate(reportTemplate.id);
                }
              });
            }
          }
        })
        .then(() => {
          this.isLoadingReportTemplatesData = false;
        });
    } else {
      this.insertNewReportTemplate();
      const profileTemplateId = AccountUsersStore.activeProfileTemplate.id;
      // Add a blank report template to the reportTemplates array
      ReportTemplateActions.fetchProfileReportTemplates(profileTemplateId)
        .then(data => this.createAndAddReportTemplates(data))
        .then(() => {
          const reportTemplateIsPresent = !!this.reportTemplates.find(reportTemplate => reportTemplate.id === reportTemplateId);
          if (reportTemplateId && reportTemplateIsPresent) {
            this.setActiveReportTemplate(reportTemplateId);
          } else {
            if (!reportTemplateId) {
              // Check for a default report template
              _.map(this.reportTemplates, reportTemplate => {
                if (reportTemplate.defaultReportTemplate) {
                  this.setActiveReportTemplate(reportTemplate.id);
                }
              });
            }
          }
        })
        .then(() => {
          this.isLoadingReportTemplatesData = false;
        });
    }
  };

  @action
  async loadProfileTemplates() {
    await ProfileTemplateActions.fetchAllProfileTemplates()
      .then(data => this.createAndAddProfileTemplates(data))
      .then(() => {
        const firstProfileTemplate = _.head(AccountUsersStore.profileTemplates);
        AccountUsersStore.setActiveProfileTemplate(firstProfileTemplate);
        this.setActiveProfileTemplate(firstProfileTemplate.id);
      });
  }

  @action
  createAndAddProfileTemplates(profileTemplatesData: any) {
    profileTemplatesData.forEach(profiletemplatedata => this.createAndAddProfileTemplate(profiletemplatedata));
  }

  @action
  createAndAddProfileTemplate(profileTemplateData: any) {
    const additionalProfileTemplate = new ProfileTemplate(
      profileTemplateData.id,
      profileTemplateData.account_id,
      profileTemplateData.name,
      profileTemplateData.description,
      profileTemplateData.default_template,
      profileTemplateData.icon,
      profileTemplateData.is_store_template,
      profileTemplateData.storeTemplate_id,
      profileTemplateData.created_at,
      profileTemplateData.created_by,
      profileTemplateData.deleted_at,
      profileTemplateData.delted_by,
      profileTemplateData.updated_at,
      profileTemplateData.updated_by
    );
    this.addProfileTemplate(additionalProfileTemplate);
  }

  @action
  addProfileTemplate(profileTemplate: ProfileTemplate) {
    AccountUsersStore.profileTemplates = _.filter(AccountUsersStore.profileTemplates, o => o.id !== profileTemplate.id);
    AccountUsersStore.profileTemplates = _.unionBy(AccountUsersStore.profileTemplates, [profileTemplate], 'id');
  }

  // Updating objects on the store ================================================================

  @action
  setLocation(location: DataViewLocation) {
    this.dataViewLocation = location;
  }

  @action
  setActiveProfileTemplate(profileTemplateId: UUID) {
    const foundProfileTemplate = AccountUsersStore.profileTemplates.find(profileTemplate => profileTemplate.id === profileTemplateId);
    AccountUsersStore.activeProfileTemplate = foundProfileTemplate;
    const { name, description } = this.activeReportTemplate;
    if (
      // $FlowFixMe
      name === I18n.t('js.new_report_template') &&
      description === I18n.t('js.new_report_template_description')
    ) {
      this.updateNewReportTemplate(foundProfileTemplate.id, profileTemplateId);
      this.activeReportTemplate.profileTemplateId = foundProfileTemplate.id;
      const activeId = this.activeReportTemplate.id;
      if (this.reportTemplates) {
        this.reportTemplates = this.reportTemplates.map(reportTemplate =>
          reportTemplate.id === activeId ? this.activeReportTemplate : reportTemplate
        );
      }
    }
  }

  @action
  setActiveReportTemplate(reportTemplateId?: string) {
    const foundActiveReportTemplate: ?ReportTemplate = this.reportTemplates.find(reportTemplate => reportTemplate.id === reportTemplateId);
    if (!foundActiveReportTemplate && reportTemplateId) {
      throw new Error(`Could not find ReportTemplate with id: ${reportTemplateId}`);
    }

    this.activeReportTemplate = foundActiveReportTemplate
      ? _.cloneDeep(foundActiveReportTemplate)
      : ReportUtilities.emptyReportTemplate(this.emptyReportTemplateParams);

    this.applyChartConfigurations();
  }

  @action
  updateNewReportTemplate = (value: any, attribute: string) => {
    // $FlowFixMe
    const currentValue = this.newReportTemplate[attribute];
    const newValue: typeof currentValue = value;
    // $FlowFixMe
    this.newReportTemplate[attribute] = newValue;
  };

  @action
  createAndAddReportTemplates(reportTemplatesData: Array<any>) {
    reportTemplatesData.forEach(reportTemplateData => this.createAndAddReportTemplate(reportTemplateData));
  }

  @action
  createAndAddReportTemplate(reportTemplateData: any) {
    const additionalReportTemplate = new ReportTemplate({
      ...reportTemplateData,
    });
    this.addReportTemplate(additionalReportTemplate);
  }

  @action
  addReportTemplate(reportTemplate: ReportTemplate) {
    this.reportTemplates = _.filter(this.reportTemplates, o => o.id !== reportTemplate.id);
    this.reportTemplates = _.unionBy(this.reportTemplates, [reportTemplate], 'id');
  }

  @action
  removeReportTemplate(reportTemplateId: string) {
    this.reportTemplates = _(this.reportTemplates).filter(o => o.id !== reportTemplateId);
    this.setActiveReportTemplate(_(this.reportTemplates).head().id);
  }

  @action
  resetNewReportTemplateAttributes() {
    this.newReportTemplate.name = '';
    this.newReportTemplate.description = '';
  }

  @action
  hideColumn(columnIndex: number) {
    const visibleColumns = _.filter(this.activeReportTemplate.reportTemplateColumns, o => !o.hidden);
    const realIndex = this.activeReportTemplate.reportTemplateColumns.indexOf(visibleColumns[columnIndex]);
    this.activeReportTemplate.reportTemplateColumns[realIndex].hidden = !this.activeReportTemplate.reportTemplateColumns[realIndex].hidden;
  }

  @action
  unhideColumn(columnIndex: number) {
    this.activeReportTemplate.reportTemplateColumns[columnIndex].hidden = !this.activeReportTemplate.reportTemplateColumns[columnIndex]
      .hidden;
  }

  @action
  reorderReportTemplateColumns = (oldIndex: number, newIndex: number, length: number) => {
    if (length === 1) {
      const visibleColumns: Array<ReportTemplateColumn> = [];
      const hiddenColumns: Array<ReportTemplateColumn> = [];
      // eslint-disable-next-line no-invalid-this
      this.activeReportTemplate.reportTemplateColumns.forEach(reportTemplateCol => {
        if (reportTemplateCol.hidden) {
          hiddenColumns.push(reportTemplateCol);
        } else {
          visibleColumns.push(reportTemplateCol);
        }
      });
      const sortedColumns = arrayMove(visibleColumns, oldIndex, newIndex);
      // eslint-disable-next-line no-invalid-this
      this.activeReportTemplate.reportTemplateColumns = _.concat(hiddenColumns, sortedColumns);
      // We then depend on the displayedData computed function to update the displayed data column order
    } else {
      throw new Error(`reorderReportTemplateColumns is not implemented for length > 1`);
    }
  };

  // Filters
  @computed
  get activeFilters(): Array<ReportTemplateFilter> {
    return this.activeReportTemplate.reportTemplateColumns.reduce(
      (filters, reportTemplateColumn) => filters.concat(reportTemplateColumn.reportTemplateFilters),
      []
    );
  }

  @action
  addFilter = () => {
    // eslint-disable-next-line no-invalid-this
    if (this.activeColumns.length === 0) {
      throw new Error('There are no active columns. Make sure some are added');
    }
    const reportTemplateColumnId = this.activeColumns[0].id;
    const newFilter = new ReportTemplateFilter({
      id: Utilities.makeId(),
      report_template_column_id: reportTemplateColumnId,
      operand: 'is_not_blank',
      test_value: '',
    });
    // append to appropriate column in canonical data
    this.activeReportTemplate.reportTemplateColumns.forEach(reportTemplateColumn => {
      if (reportTemplateColumn.id === reportTemplateColumnId) {
        reportTemplateColumn.reportTemplateFilters.push(newFilter);
      }
    });
  };

  @action
  removeFilter = (filterId: string) => {
    this.markFilterForDeletion(filterId);
    _.map(this.activeReportTemplate.reportTemplateColumns, reportTemplateColumn => {
      const newFilters = _.filter(reportTemplateColumn.reportTemplateFilters, o => o.id !== filterId);
      reportTemplateColumn.reportTemplateFilters = newFilters;
    });
    this.activeReportTemplate = _.clone(this.activeReportTemplate);
  };

  @action
  markFilterForDeletion = (filterId: string) => {
    this.activeReportTemplate.reportTemplateColumns.forEach(reportTemplateColumn => {
      const foundFilter = reportTemplateColumn.reportTemplateFilters.find(o => o.id === filterId);
      if (foundFilter && foundFilter.isPersisted) {
        this.reportTemplateFiltersToDelete.push(filterId);
      }
    });
  };

  @action
  changeFilterAttribute = (filterId: string, attribute: string, newValue: string) => {
    _.map(this.activeReportTemplate.reportTemplateColumns, reportTemplateColumn => {
      _.map(reportTemplateColumn.reportTemplateFilters, reportTemplateFilter => {
        if (reportTemplateFilter.id === filterId) {
          // $FlowFixMe
          reportTemplateFilter[attribute] = newValue;
        }
      });
    });
    if (attribute === 'reportTemplateColumnId') {
      this.reassignFiltersToCorrectColumns();
    }
    this.activeReportTemplate = _.clone(this.activeReportTemplate);
  };

  @action
  reassignFiltersToCorrectColumns = () => {
    // This is necessary because the attribute reportTemplateId might have changed, but the parent reportTemplateColumn that is sits beneath will not be updated.
    const filtersToReassign = [];
    _.map(this.activeReportTemplate.reportTemplateColumns, reportTemplateColumn => {
      _.map(reportTemplateColumn.reportTemplateFilters, reportTemplateFilter => {
        if (reportTemplateFilter.reportTemplateColumnId !== reportTemplateColumn.id) {
          filtersToReassign.push(reportTemplateFilter);
          reportTemplateColumn.reportTemplateFilters = _.filter(
            reportTemplateColumn.reportTemplateFilters,
            o => o.id !== reportTemplateFilter.id
          );
        }
      });
    });
    _.map(filtersToReassign, reportTemplateFilter => {
      _.map(this.activeReportTemplate.reportTemplateColumns, reportTemplateColumn => {
        if (reportTemplateColumn.id === reportTemplateFilter.reportTemplateColumnId) {
          reportTemplateColumn.reportTemplateFilters.push(reportTemplateFilter);
        }
      });
    });
  };

  // sorting

  /* 'Sorts' are not objects in and of themselves. Rather, sorts are inferred from columns.
  From the perspective of a component, therefore, sorts should be updated like any other object on
  the store. */

  @action
  updateReportTemplateColumnSortPriority = (columnId: string, newSortPriority: number) => {
    this.activeReportTemplate.reportTemplateColumns.forEach(column => {
      if (column.id === columnId) {
        column.sortPriority = newSortPriority;
      }
    });
  };

  @action
  setToHighestSortPriority(columnId: string) {
    this.updateReportTemplateColumnSortPriority(columnId, this.highestSortPriority);
  }

  @action
  addSort = (direction: string = 'asc', columnId: ?string = undefined) => {
    const { lowestSortPriority, activeReportTemplate } = this;
    if (!activeReportTemplate.reportTemplateColumns) {
      throw new Error('There are no active reportTemplate columns');
    }
    const sortColumn = activeReportTemplate.reportTemplateColumns
      // match only with selecte id if present
      .filter(reportTemplateCol => (columnId ? reportTemplateCol.id === columnId : true))
      .find(reportTemplateCol => reportTemplateCol.sortPriority === lowestSortPriority);
    if (!sortColumn) {
      throw new Error('Had trouble finding a column with the lowest sort priority/that id');
    }

    this.sortColumns.forEach(column => this.updateReportTemplateColumnSortPriority(column.id, column.sortPriority - 1));

    const newSortPriority = lowestSortPriority - 1;
    this.updateReportTemplateColumnSortPriority(sortColumn.id, newSortPriority);
    if (sortColumn.sortDirection !== direction) {
      this.toggleSortDirection(sortColumn.id);
    }
  };

  @action
  changeSortColumn = (previousColumnId: string, newColumnId: string) => {
    const previousColumn = this.sortColumns.find(column => column.id === previousColumnId);
    if (!previousColumn) {
      throw new Error('previousColumnId has no corresponding column');
    }
    const { sortPriority } = previousColumn;
    const lowestPriority = this.lowestSortPriority;
    this.updateReportTemplateColumnSortPriority(previousColumnId, lowestPriority);
    this.updateReportTemplateColumnSortPriority(newColumnId, sortPriority);
  };

  @action
  deleteSort = (columnId: string) => {
    this.updateReportTemplateColumnSortPriority(columnId, this.lowestSortPriority);
  };

  @action
  toggleSortDirection = (reportTemplateColId: string) => {
    const foundReportTemplateCol = this.activeReportTemplate.reportTemplateColumns.find(
      reportTemplateCol => reportTemplateCol.id === reportTemplateColId
    );
    if (!foundReportTemplateCol) {
      throw new Error(`Could not find reportTemplateColumn with id: ${reportTemplateColId}`);
    }
    switch (foundReportTemplateCol.sortDirection) {
      case 'asc':
        foundReportTemplateCol.sortDirection = 'desc';
        break;
      case 'desc':
        foundReportTemplateCol.sortDirection = 'asc';
        break;
      default:
        throw new Error('Invalid sort direction');
    }
  };

  @action
  changeSortPriority = (oldSortPriority: number, newSortPriority: number) => {
    if (oldSortPriority === newSortPriority) return;
    const findReportTemplateColumn = (priority: number) => _.find(this.sortColumns, sortColumn => sortColumn.sortPriority === priority);
    type ChangeInfo = { columnId: string, newPriority: number };
    // bumped columns (those whose priorities are shifted as a result of moving the intended priority):
    const bump = newSortPriority < oldSortPriority ? 1 : -1;
    const priorityRange = _.range(newSortPriority, oldSortPriority + bump);
    const changesToMake: Array<ChangeInfo> = priorityRange.map(priority => {
      const bumpedReportTemplateColumn = findReportTemplateColumn(priority);
      const bumpedReportTemplateColumnId = bumpedReportTemplateColumn ? bumpedReportTemplateColumn.id : '';
      const bumpedSortPriority = priority + bump;
      return {
        columnId: bumpedReportTemplateColumnId,
        newPriority: bumpedSortPriority,
      };
    });
    // intended column:
    const movingReportTemplateColumn = findReportTemplateColumn(oldSortPriority);
    const movingReportTemplateColumnId = movingReportTemplateColumn ? movingReportTemplateColumn.id : '';
    changesToMake.push({
      columnId: movingReportTemplateColumnId,
      newPriority: newSortPriority,
    });

    changesToMake.forEach(change => {
      const { columnId, newPriority } = change;
      this.updateReportTemplateColumnSortPriority(columnId, newPriority);
    });
  };

  // Helper Methods/Objects =======================================================================
  @computed
  get lowestSortPriority(): number {
    const { reportTemplateColumns: activeReportTemplateColumns } = this.activeReportTemplate;
    // A lower number is a higher sortPriority
    if (activeReportTemplateColumns.length !== 0) {
      // check to ensure that the columns aren't empty
      const arbitrarySortPriorityForEmptyColumns = 1000;
      return arbitrarySortPriorityForEmptyColumns;
    }
    const starterPriority = activeReportTemplateColumns[0].sortPriority;
    return activeReportTemplateColumns.reduce(
      (lowestPriority, column) => (column.sortPriority > lowestPriority ? column.sortPriority : lowestPriority),
      starterPriority
    );
  }

  @computed
  get highestSortPriority(): number {
    const { reportTemplateColumns: activeReportTemplateColumns } = this.activeReportTemplate;
    // A lower number is a higher sortPriority
    if (!(activeReportTemplateColumns.length > 0)) {
      // check to ensure that the columns aren't empty
      const arbitrarySortPriorityForEmptyColumns = 1000;
      return arbitrarySortPriorityForEmptyColumns;
    }
    const starterPriority = activeReportTemplateColumns[0].sortPriority;
    return activeReportTemplateColumns.reduce(
      (highestPriority, column) => (column.sortPriority < highestPriority ? column.sortPriority : highestPriority),
      starterPriority
    );
  }
}

const store = new DataViewStore();
export default store;
