import React, { Component } from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormGroup, InputGroup, TextArea, Intent, Button } from '@blueprintjs/core';
import { convertFromRaw, EditorState } from 'draft-js';
import RichTextEditor from '../shared/RichTextEditor';
import nanoid from 'nanoid';
import _ from 'lodash';

@inject('ItemStore', 'ItemActions', 'UserProfileStore', 'UserProfileTemplateStore', 'TemplateActions')
@observer
export default class FormElementRepeatableInput extends Component {
  @observable
  store = '';

  constructor(props) {
    super(props);

    this.state = {
      formValues: [],
    };
  }

  componentDidMount() {
    this.setStoreAndActions();
    this.mountDisplayValuesInFlight();
  }

  setStoreAndActions() {
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  mountDisplayValuesInFlight() {
    let persistedValues = _.clone(this.props.element.formValues);
    persistedValues = _.orderBy(persistedValues, ['rowOrder', 'updatedAt'], ['asc', 'desc']);
    _.map(persistedValues, (formValue, index) => {
      if (index > 0) {
        const newFormValue = { id: formValue.id, string_value: formValue.stringValue, row_order_position: index, is_persisted: true };
        let newFormValues = this.state.formValues;
        newFormValues.push(newFormValue);
        this.setState({ formValues: newFormValues });
      }
    });
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'string';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'string';
        return object;
    }
  }

  updateStoreValues(formValue) {
    if (formValue.is_persisted) {
      const changedFormValue = {
        id: formValue.id,
        string_value: formValue.string_value,
        row_order_position: formValue.row_order_position,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      let newFormValue = {
        id: formValue.id,
        element_id: this.props.element.id,
        string_value: formValue.string_value,
        row_order_position: formValue.row_order_position,
      };
      newFormValue = this.appendAttributes(newFormValue);
      this.store.addFormValueToCreate(newFormValue);
    }
  }

  handleAddNewFormValue() {
    const newFormValue = { id: nanoid(), string_value: '', row_order_position: this.state.formValues.length + 1, is_persisted: false };
    let newFormValues = this.state.formValues;
    newFormValues.push(newFormValue);
    this.setState({ formValues: newFormValues });
  }

  handleInputChange(e, formValue) {
    formValue.string_value = e.target.value;
    let newFormValues = this.state.formValues;
    newFormValues = _.unionBy([formValue], newFormValues, 'id');
    newFormValues = _.orderBy(newFormValues, ['row_order_position'], ['asc']);
    this.setState({ formValues: newFormValues });
    this.updateStoreValues(formValue);
  }

  handleRichTextAreaChange(content, formValue) {
    formValue.string_value = content;
    let newFormValues = this.state.formValues;
    newFormValues = _.unionBy([formValue], newFormValues, 'id');
    newFormValues = _.orderBy(newFormValues, ['row_order_position'], ['asc']);
    this.setState({ formValues: newFormValues });
    this.updateStoreValues(formValue);
  }

  handleInputRemove(formValue) {
    let newFormValues = this.state.formValues;
    newFormValues = _.filter(newFormValues, o => o.id !== formValue.id);
    this.setState({ formValues: newFormValues });
    if (formValue.is_persisted) {
      this.store.addFormValueToDelete(formValue);
    } else {
      this.store.removeFormValueToCreate(formValue.id);
    }
  }

  render() {
    return (
      <Flexbox flexDirection="column" marginTop="5px">
        <Flexbox flexDirection="column">
          {_.map(this.state.formValues, formValue => {
            switch (this.props.element.elementType) {
              case 'rich_textarea':
                return (
                  <Flexbox flexDirection="row" flexGrow={1} key={formValue.id} marginBottom="10px">
                    <Flexbox flexGrow={1}>
                      <RichTextEditor
                        content={formValue.string_value}
                        rows={this.props.element.elementRichTextarea.rows}
                        handleChange={content => this.handleRichTextAreaChange(content, formValue)}
                        placeholder={this.props.element.elementRichTextarea.placeholderText}
                        placeholderDisabled={true}
                      />
                    </Flexbox>
                    <Flexbox flexGrow={0} marginLeft="10px">
                      <Button minimal={true} icon="cross" intent={Intent.DANGER} onClick={() => this.handleInputRemove(formValue)}></Button>
                    </Flexbox>
                  </Flexbox>
                );
              case 'textarea':
                return (
                  <Flexbox flexDirection="row" flexGrow={1} key={formValue.id} marginBottom="10px">
                    <Flexbox flexGrow={1}>
                      <TextArea
                        className="bp3-fill"
                        value={formValue.string_value}
                        rows={this.props.element.elementTextarea.rows}
                        placeholder={this.props.element[this.props.element.camelCaseTitle].placeholderText}
                        onChange={e => this.handleInputChange(e, formValue)}
                      />
                    </Flexbox>
                    <Flexbox flexGrow={0} marginLeft="10px">
                      <Button minimal={true} icon="cross" intent={Intent.DANGER} onClick={() => this.handleInputRemove(formValue)}></Button>
                    </Flexbox>
                  </Flexbox>
                );
              default:
                return (
                  <Flexbox flexDirection="row" flexGrow={1} key={formValue.id} maxHeight="30px" marginBottom="10px">
                    <Flexbox flexGrow={1}>
                      <InputGroup
                        className="bp3-fill"
                        type={this.props.element.elementType === 'email' ? 'email' : null}
                        value={formValue.string_value}
                        placeholder={this.props.element[this.props.element.camelCaseTitle].placeholderText}
                        onChange={e => this.handleInputChange(e, formValue)}
                      />
                    </Flexbox>
                    <Flexbox flexGrow={0} marginLeft="10px">
                      <Button
                        minimal={true}
                        small={true}
                        icon="cross"
                        intent={Intent.DANGER}
                        onClick={() => this.handleInputRemove(formValue)}
                      ></Button>
                    </Flexbox>
                  </Flexbox>
                );
            }
          })}
        </Flexbox>
        <Flexbox flexDirection="row">
          <Button
            minimal={true}
            text={I18n.t('js.add_additional_response')}
            onClick={this.handleAddNewFormValue.bind(this)}
            icon="add"
          ></Button>
        </Flexbox>
      </Flexbox>
    );
  }
}
