/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { AnchorButton, Classes, Tab, Tabs, Tooltip } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

@inject('CurrentUserStore', 'WorkspaceStore')
@observer
export default class WorkspaceTabs extends React.Component {
  handleTabChange(tab) {
    this.props.WorkspaceStore.activeTab = tab;
  }

  renderActivityTitle() {
    return <span>{I18n.t('js.activity')}</span>;
  }

  renderUsageTitle() {
    return <span>{I18n.t('js.usage')}</span>;
  }

  renderAppsTitle() {
    return <span>{I18n.t('js.apps')}</span>;
  }

  renderEditTitle() {
    return (
      <Tooltip
        portalContainer={document.body}
        content={I18n.t('js.only_workspace_owners_can_edit_the_workspace')}
        disabled={this.props.WorkspaceStore.activeWorkspace.userPermissionLevel.name === 'owner'}
        position="right"
      >
        <span>{I18n.t('js.settings')}</span>
      </Tooltip>
    );
  }

  renderRightLinks() {
    if (this.props.WorkspaceStore.activeWorkspace.isStoreTemplate) {
      return (
        <Tabs
          id="tabnav"
          animate={true}
          selectedTabId={this.props.WorkspaceStore.activeTab}
          onChange={this.handleTabChange.bind(this)}
          className="noselect"
        >
          <Tab id="apps" title={this.renderAppsTitle()} />
          <Tab
            id="edit"
            disabled={this.props.WorkspaceStore.activeWorkspace.userPermissionLevel.name !== 'owner'}
            title={this.renderEditTitle()}
          />
          <Tabs.Expander />
        </Tabs>
      );
    }
    return (
      <Tabs
        id="tabnav"
        animate={true}
        selectedTabId={this.props.WorkspaceStore.activeTab}
        onChange={this.handleTabChange.bind(this)}
        className="noselect"
      >
        <Tab id="apps" title={this.renderAppsTitle()} />
        <Tab id="activity" title={this.renderActivityTitle()} />
        <Tab id="usage" title={this.renderUsageTitle()} />
        <Tab
          id="edit"
          disabled={this.props.WorkspaceStore.activeWorkspace.userPermissionLevel.name !== 'owner'}
          title={this.renderEditTitle()}
        />
        <Tabs.Expander />
      </Tabs>
    );
  }

  renderContent() {
    if (!this.props.loading) {
      return (
        <Flexbox className="fixed-width-container">
          <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1}>
            {this.renderRightLinks()}
          </Flexbox>
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    return <nav className="bp3-navbar bp3-navbar-bordered">{this.renderContent()}</nav>;
  }
}
