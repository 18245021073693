import { computed, observable } from 'mobx';

const minPasswordLength = 8;

export default class SignUp {
  @observable
  firstName;
  @observable
  lastName;
  @observable
  email;
  @observable
  password;
  @observable
  passwordConfirmation;

  constructor(
    firstName,
    lastName,
    email,
    password,
    passwordConfirmation
  ) {
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.email = email || '';
    this.password = password || '';
    this.passwordConfirmation = passwordConfirmation || '';
  }

  @computed
  get firstNameValid() {
    if (this.firstName !== '') {
      return true;
    }
    return false;
  }

  @computed
  get firstNameError() {
    if (this.firstName !== '') {
      return null;
    }
    return I18n.t('js.first_name_is_required');
  }

  @computed
  get lastNameValid() {
    if (this.lastName !== '') {
      return true;
    }
    return false;
  }

  @computed
  get lastNameError() {
    if (this.lastName !== '') {
      return null;
    }
    return I18n.t('js.last_name_is_required');
  }

  @computed
  get emailValid() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email !== '' && exp.test(this.email)) {
      return true;
    }
    return false;
  }

  @computed
  get emailError() {
    const exp = /\S+@\S+\.\S+/u;
    if (this.email === '') {
      return I18n.t('js.email_is_required');
    }
    if (!exp.test(this.email)) {
      return I18n.t('js.email_is_invalid');
    }
    return null;
  }

  @computed
  get passwordValid() {
    const hasUpperCase = /[A-Z]/u;
    const hasLowerCase = /[a-z]/u;
    const hasNumbers = /\d/u;
    const hasNonalphas = /\W/u;
    if (this.password.length < minPasswordLength) {
      return false;
    }
    if (!hasUpperCase.test(this.password)) {
      return false;
    }
    if (!hasLowerCase.test(this.password)) {
      return false;
    }
    if (!hasNumbers.test(this.password)) {
      return false;
    }
    if (!hasNonalphas.test(this.password)) {
      return false;
    }
    return true;
  }

  @computed
  get passwordError() {
    const hasUpperCase = /[A-Z]/u;
    const hasLowerCase = /[a-z]/u;
    const hasNumbers = /\d/u;
    const hasNonalphas = /\W/u;
    if (this.password.length < minPasswordLength) {
      return I18n.t('js.password_should_have_8_characters');
    }
    if (!hasUpperCase.test(this.password)) {
      return I18n.t('js.password_should_have_1_uppercase_character');
    }
    if (!hasLowerCase.test(this.password)) {
      return I18n.t('js.password_should_have_1_lowercase_character');
    }
    if (!hasNumbers.test(this.password)) {
      return I18n.t('js.password_should_have_1_number');
    }
    if (!hasNonalphas.test(this.password)) {
      return I18n.t(
        'js.password_should_have_1_non_alphanumeric_character'
      );
    }
    return null;
  }

  @computed
  get passwordConfirmationValid() {
    if (this.passwordConfirmation !== this.password) {
      return false;
    }
    if (this.passwordConfirmation.length === 0) {
      return false;
    }
    return true;
  }

  @computed
  get passwordConfirmationError() {
    if (this.passwordConfirmation !== this.password) {
      return I18n.t('js.passwords_do_not_match');
    }
    if (this.passwordConfirmation.length === 0) {
      return I18n.t('js.password_confirmation_is_required');
    }
    return null;
  }
}
