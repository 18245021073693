import React, { Component } from 'react';
import Flexbox from 'flexbox-react';
import { NonIdealState, Intent, Icon } from '@blueprintjs/core';

export default class MetricDisplay extends Component {
  render() {
    if (this.props.data.state === 'invalid') {
      return (
        <Flexbox>
          <NonIdealState
            title={I18n.t('js.error')}
            description={I18n.t('js.error_calculating_metric')}
            icon={<Icon intent={Intent.DANGER} icon="outdated" iconSize={50} />}
          />
        </Flexbox>
      );
    }
    if (this.props.data.result === '') {
      return (
        <Flexbox>
          <NonIdealState
            title={I18n.t('js.chart_not_configured')}
            description={I18n.t('js.click_edit_to_configure_this_chart')}
            icon={this.props.chartObj.icon}
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" justifyContent="center" alignItems="center">
        <h1 style={{ fontSize: `${this.props.chartObj.dataConfig.fontSize}px` }} className="bp3-label-no-margin">
          {this.props.chartObj.dataConfig.prefix}
          {this.props.data.result}
          {this.props.chartObj.dataConfig.suffix}
        </h1>
        <p>{this.props.chartObj.dataConfig.annotation}</p>
      </Flexbox>
    );
  }
}
