/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import AppStore from '../../stores/AppStore';
import PermissionDefinitions from '../../static/PermissionDefinitions';
import { Button, Classes, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

const PermissionSelect = Select.ofType(
  PermissionDefinitions.permissionTypes
);

export default class AppEditPermissionsSelectPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = { activePermission: '', query: '' };
  }

  componentDidMount() {
    const findPermission = _.find(
      PermissionDefinitions.permissionTypes,
      o => o.name === AppStore.newApp.permissionType
    );
    this.setState({ activePermission: findPermission });
  }

  toggleQuerying() {
    this.setState({
      isQuerying: !this.state.isQuerying,
    });
  }

  renderMenuText(item) {
    const iconClass = classNames('bp3-icon push-10-r', item.icon);
    return (
      <Flexbox flexDirection="row" alignContent="center">
        <Flexbox className={iconClass} />
        <Flexbox paddingTop="2px">{item.text}</Flexbox>
      </Flexbox>
    );
  }

  renderPermission(item, details) {
    let menuClass = classNames('popover-larger');
    if (item.id === this.state.activePermission.id) {
      menuClass = classNames(
        Classes.ACTIVE,
        Classes.INTENT_PRIMARY,
        'popover-larger'
      );
    }

    return (
      <MenuItem
        className={menuClass}
        key={item.id}
        onClick={details.handleClick}
        icon={item.icon}
        text={this.renderMenuText(item)}
      />
    );
  }

  renderInputValue(permission) {
    return permission.text;
  }

  handleItemSelect(permission) {
    this.setState({ activePermission: permission });
    this.props.handleInputChange(permission);
  }

  handleInputChange(e) {
    this.setState({ query: e.target.value });
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  render() {
    return (
      <PermissionSelect
        resetOnSelect={true}
        filterable={false}
        items={PermissionDefinitions.permissionTypes}
        itemRenderer={this.renderPermission.bind(this)}
        onItemSelect={this.handleItemSelect.bind(this)}
        inputValueRenderer={this.renderInputValue.bind(this)}
        inputProps={{
          rightElement: this.renderDropDown(),
        }}
        popoverProps={{
          usePortal: false,
          position: 'bottom-left',
          popoverClassName: 'bp3-minimal',
        }}
      >
        <Button
          text={this.state.activePermission.text}
          icon={_.replace(
            this.state.activePermission.icon,
            'bp3-icon-',
            ''
          )}
          rightIcon="caret-down"
        />
      </PermissionSelect>
    );
  }
}
