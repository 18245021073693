import { action, computed, observable } from 'mobx';
import CurrentUserStore from './CurrentUserStore';
import ToastStore from './ToastStore';
import User from '../models/UserModel';
import AuthorizedAccount from '../models/AuthorizedAccountModel';
import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';

class UserProfileStore {
  @observable isLoading = true;
  @observable user = new User();
  @observable newUser = new User();
  @observable activeTab = 'account';
  @observable authorizedAccount = new AuthorizedAccount();

  @computed
  get isCurrentUser() {
    return this.user.id === CurrentUserStore.currentUser.id;
  }

  @action
  setUser(user) {
    this.user = _.clone(user);
    this.newUser = _.clone(user);
  }

  @action
  addUser(data) {
    this.user = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.newUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );
  }

  @action
  addAuthorizedAccount(data) {
    let newImage = '';
    if (data.account_image !== null) {
      newImage = data.account_image;
    } else {
      newImage = I18n.t(`js.pg_logo_black`);
    }
    this.authorizedAccount = new AuthorizedAccount(
      data.id,
      data.account_id,
      data.account_name,
      newImage,
      data.account_owner,
      data.account_dummy_data,
      data.account_enterprise,
      data.account_subdomain,
      data.active_account,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.last_active,
      data.updated_at,
      data.updated_by,
      data.user_id,
      data.profile_template_id
    );
  }

  @action
  updateUser(data, attribute) {
    this.newUser[attribute] = data;
  }

  @action
  resetUserAttribute(attribute) {
    this.newUser[attribute] = this.user[attribute];
  }

  @action
  fetchUser(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/users/${userId}`)
        .then(response => {
          this.addUser(response.data);
          this.isLoading = false;
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          this.isLoading = false;
        });
    });
  }

  @action
  fetchAuthorizedAccounts(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/authorized_accounts?user_id=${userId}`)
        .then(response => {
          if (response.data.length > 0) {
            this.addAuthorizedAccount(response.data[0]);
            resolve(response);
          } else {
            // lazy fix
            // eslint-disable-next-line prefer-promise-reject-errors
            reject();
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeAuthorizedAccount(authorizedAccountObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/authorized_accounts/${authorizedAccountObj.id}`, authorizedAccountObj)
        .then(response => {
          this.addAuthorizedAccount(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeUser(userObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/users/${this.user.id}`, userObj)
        .then(response => {
          this.addUser(response.data);
          resolve(response);
          ToastStore.showToast(I18n.t('js.your_profile_has_been_updated'), 'success');
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}

const store = new UserProfileStore();
export default store;
