// @flow

import { computed, observable } from 'mobx';
import ReportTemplateColumn from './ReportTemplateColumnModel';
import type { UUID } from '../types/DataViewTypes';

export default class ReportTemplateModel {
  @observable
  id: UUID;
  @observable
  appId: UUID;
  @observable
  profileTemplateId: string;
  @observable
  userId: string;
  @observable
  name: string;
  @observable
  description: string;
  @observable
  chartConfiguration: any;
  @observable
  defaultReportTemplate: Boolean;
  @observable
  publicReportTemplate: Boolean;
  @observable
  createdAt: string;
  @observable
  updatedAt: string;
  @observable
  deletedAt: string;
  @observable
  createdBy: string;
  @observable
  updatedBy: string;
  @observable
  deletedBy: string;
  @observable
  reportTemplateColumns: Array<ReportTemplateColumn>;

  // $FlowFixMe
  constructor({
    id = '',
    app_id = '',
    profile_template_id = '',
    user_id = '',
    name = '',
    description = '',
    chart_configuration = '',
    default_report_template = false,
    public_report_template = false,
    deleted_at = '',
    created_by = '',
    updated_by = '',
    deleted_by = '',
    updated_at = '',
    created_at = '',
    report_template_columns = [],
  }) {
    this.id = id;
    this.appId = app_id;
    this.profileTemplateId = profile_template_id;
    this.userId = user_id;
    this.name = name;
    this.description = description;
    this.chartConfiguration = chart_configuration === '' ? '' : JSON.parse(chart_configuration);
    typeof default_report_template === 'undefined' || default_report_template === null
      ? (this.defaultReportTemplate = false)
      : (this.defaultReportTemplate = default_report_template);
    typeof public_report_template === 'undefined' || public_report_template === null
      ? (this.publicReportTemplate = false)
      : (this.publicReportTemplate = public_report_template);
    this.createdAt = deleted_at;
    this.updatedAt = created_by;
    this.deletedAt = updated_by;
    this.createdBy = deleted_by;
    this.updatedBy = updated_at;
    this.deletedBy = deleted_by;
    this.createdAt = created_at;
    this.reportTemplateColumns = report_template_columns.map(columnData => new ReportTemplateColumn(columnData));
  }

  @computed
  get reportTemplateType(): string {
    if (this.appId) {
      return 'app';
    }
    return 'profileTemplate';
  }

  @computed
  get isPersisted(): boolean {
    return this.createdAt.length > 0;
  }

  @computed
  get nameValid(): boolean {
    if (this.name.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get nameError(): ?string {
    if (this.name.length < 1) {
      // $FlowFixMe
      return I18n.t('js.name_is_required');
    }
    return null;
  }

  @computed
  get descriptionValid(): boolean {
    if (this.description.length < 1) {
      return false;
    }
    return true;
  }

  @computed
  get descriptionError(): ?string {
    if (this.description.length < 1) {
      // $FlowFixMe
      return I18n.t('js.description_is_required');
    }
    return null;
  }
}
