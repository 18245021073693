/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Alert, Button, Classes, EditableText, Intent, NonIdealState, Spinner, Tooltip } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import Graph from 'react-vis-network-graph';
import OrganizationNewUserRelationship from './OrganizationNewUserRelationship';
import OrganizationGraphToolbar from './OrganizationGraphToolbar';
import OrganizationUserOptions from './OrganizationUserOptions';
import _ from 'lodash';

@inject('OrganizationStore', 'CurrentUserStore', 'ToastStore')
@observer
export default class OrganizationActiveRelationship extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeletingRelationship: false,
      dialogOpen: false,
      isLoading: false,
    };
  }

  toggleDeleting() {
    this.setState({
      isDeletingRelationship: !this.state.isDeletingRelationship,
    });
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  toggleDialog() {
    if (this.props.CurrentUserStore.currentUser.isAccountOwner) {
      this.setState({
        dialogOpen: !this.state.dialogOpen,
      });
    }
  }

  handleDeleteRelationship() {
    this.props.OrganizationStore.deleteRelationship(this.props.OrganizationStore.activeRelationship)
      .then(() => {
        this.toggleDeleting();
        this.props.ToastStore.showToast(I18n.t('js.relationship_has_been_deleted'), 'success');
      })
      .catch(() => {
        this.toggleDeleting();
      });
  }

  renderDeleteRelationship() {
    return (
      <Alert
        portalContainer={document.body}
        isOpen={this.state.isDeletingRelationship}
        cancelButtonText={I18n.t('js.cancel')}
        onCancel={() => this.toggleDeleting()}
        confirmButtonText={I18n.t('js.delete_relationship')}
        onConfirm={() => this.handleDeleteRelationship()}
        intent={Intent.DANGER}
      >
        <h4 className={Classes.HEADING}>{I18n.t('js.are_you_sure')}</h4>
        {I18n.t('js.are_you_sure_you_wish_to_delete_this_relationship', {
          name: this.props.OrganizationStore.activeRelationship.name.toLowerCase(),
        })}
      </Alert>
    );
  }

  setNetworkInstance(nw) {
    this.network = nw;
  }

  renderChart() {
    const graph = {
      nodes: this.props.OrganizationStore.userNodes,
      edges: this.props.OrganizationStore.userRelationshipNodes,
    };

    const options = {
      layout: {
        hierarchical: {
          enabled: this.props.OrganizationStore.relationshipGraph.hierarchical,
          levelSeparation: this.props.OrganizationStore.relationshipGraph.levelSeparation,
          nodeSpacing: this.props.OrganizationStore.relationshipGraph.nodeSpacing,
          direction: this.props.OrganizationStore.relationshipGraph.direction,
          sortMethod: this.props.OrganizationStore.relationshipGraph.sortMethod,
        },
      },
      interaction: {
        hover: true,
      },
      physics: this.props.OrganizationStore.relationshipGraph.physics,
    };

    const events = {
      select: event => {
        if (event.nodes.length > 0) {
          this.handleUserSelect(event, false);
        }
      },
      doubleClick: event => {
        this.handleUserSelect(event, true);
      },
    };

    return (
      <Flexbox flexDirection="column" className="bp3-cursor-pointer" flexGrow={1}>
        <Graph
          graph={graph}
          getNetwork={this.setNetworkInstance.bind(this)}
          options={options}
          events={events}
          style={{
            width: '100%',
            height: '800px',
          }}
        />
      </Flexbox>
    );
  }

  renderChartArea() {
    if (this.props.OrganizationStore.loadingUserRelationships) {
      return (
        <Flexbox
          flexDirection="column"
          height="500px"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          className="chart-area bp3-card"
        ></Flexbox>
      );
    }
    if (this.props.OrganizationStore.userNodes.length === 0) {
      return (
        <Flexbox
          flexDirection="column"
          height="500px"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          className="chart-area bp3-card"
        >
          <NonIdealState
            title={I18n.t('js.add_user')}
            description={I18n.t('js.add_a_user_to_get_started')}
            icon="new-person"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexDirection="column" className="bp3-cursor-pointer chart-area bp3-card" flexGrow={1}>
        {this.renderChart()}
      </Flexbox>
    );
  }

  renderOption(user) {
    return (
      <Flexbox flexDirection="row" alignContent="center">
        <Flexbox>
          <Avatar src={user.imageFileName} size={22} round={true} className="" />
        </Flexbox>
        <Flexbox marginLeft="5px">
          {user.fullName}
          <small className="bp3-text-muted push-5-l">{user.email}</small>
        </Flexbox>
      </Flexbox>
    );
  }

  renderArrow({ isOpen }) {
    if (!isOpen) {
      return <span className="bp3-icon-standard bp3-icon-caret-down bp3-text-muted" />;
    }
    return undefined;
  }

  renderClear() {
    if (typeof this.props.OrganizationStore.activeUser === 'object') {
      return <span className="bp3-icon-standard bp3-icon-small-cross" />;
    }
    return undefined;
  }

  handleUserSelect(event, showDialog) {
    if (event.nodes[0]) {
      this.props.OrganizationStore.setActiveUser(event.nodes[0]);
      const findUser = _.find(this.props.OrganizationStore.users, o => o.id === event.nodes[0]);
      this.handleUserSelectChange(findUser, showDialog);
    }
  }

  handleUserSelectChange(user, showDialog) {
    const timeout = 100;
    if (user === null) {
      this.props.OrganizationStore.activeUser = '';
    } else {
      this.toggleLoading();
      this.props.OrganizationStore.resetTempArrays();
      this.props.OrganizationStore.selectAndFetchNewUser(user).then(() => {
        this.props.OrganizationStore.setActiveUser(user.id);
        this.props.OrganizationStore.setActiveUserRelationships();
        this.network.selectNodes([user.id]);
        setTimeout(() => {
          const positions = this.network.getPositions([user.id]);
          const options = {
            position: {
              x: positions[user.id].x,
              y: positions[user.id].y,
            },
            animation: true,
          };
          this.network.moveTo(options);
          this.toggleLoading();
          if (showDialog) {
            this.toggleDialog();
          }
        }, timeout);
      });
    }
  }

  loadAllRelationships() {
    this.toggleLoading();
    this.props.OrganizationStore.loadAllUserRelationships().then(() => {
      this.toggleLoading();
    });
  }

  renderUserOptions() {
    return (
      <OrganizationUserOptions
        handleUserSelectChange={user => this.handleUserSelectChange(user)}
        toggleDialog={() => this.toggleDialog()}
        loadAllRelationships={() => this.loadAllRelationships()}
        isLoading={this.state.isLoading}
      />
    );
  }

  renderToolbar() {
    return (
      <OrganizationGraphToolbar
        handleUserSelectChange={user => this.handleUserSelectChange(user)}
        toggleDialog={() => this.toggleDialog()}
      />
    );
  }

  renderLoading() {
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexDirection="row" flexGrow={0} paddingTop="20px">
          {this.renderUserOptions()}
        </Flexbox>
        <Flexbox flexDirection="column" marginTop="10px" flexGrow={1}>
          {this.renderChartArea()}
          {this.renderToolbar()}
          <OrganizationNewUserRelationship dialogOpen={this.state.dialogOpen} toggleDialog={() => this.toggleDialog()} />
        </Flexbox>
      </Flexbox>
    );
  }
}
