/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { observer, Provider } from 'mobx-react';
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import CurrentUserStore from '../../stores/CurrentUserStore';
import DashboardStore from '../../stores/DashboardStore';
import NotificationStore from '../../stores/NotificationStore';
import WorkspaceStore from '../../stores/WorkspaceStore';
import ThemeWrapper from '../styles/ThemeWrapper/index.js';
import ErrorBoundary from '../errors/ErrorBoundary';
import MainSearch from './MainSearch';
import ProductPopover from './ProductPopover';
import NotificationPopover from './NotificationPopover';
import UserNavMenu from './UserNavMenu';
import Avatar from 'react-avatar';
import GuideButton from '../guides/GuideButton';
import _ from 'lodash';

@observer
export default class MainNav extends React.Component {
  render() {
    const stores = {
      DashboardStore,
      CurrentUserStore,
      NotificationStore,
      WorkspaceStore,
    };

    return (
      <ErrorBoundary>
        <Provider {...stores}>
          <ThemeWrapper>
            <Flexbox flexGrow={1} className="bp3-custom-navbar">
              <Flexbox flexGrow={1}>
                <MainSearch />
              </Flexbox>
              <Flexbox flexGrow={0} alignItems="center" justifyContent="center" paddingRight="20px">
                <GuideButton action={this.props.action} minimal={true} />
                <NotificationPopover />
                <ProductPopover />
                <Popover
                  portalContainer={document.body}
                  position={Position.BOTTOM_RIGHT}
                  interactionKind={PopoverInteractionKind.HOVER}
                  minimal={true}
                  boundary="viewport"
                  content={<UserNavMenu currentUser={CurrentUserStore.currentUser} />}
                >
                  <a className="unstyled push-10-l" href={`/users/${CurrentUserStore.currentUser.id}`}>
                    <Avatar className="bp3-cursor-pointer" src={CurrentUserStore.currentUser.imageFileName} size={30} round={true} />
                  </a>
                </Popover>
              </Flexbox>
            </Flexbox>
          </ThemeWrapper>
        </Provider>
      </ErrorBoundary>
    );
  }
}
