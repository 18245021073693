/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import TemplateManageStatusFlags from './TemplateManageStatusFlags';
import { Button, Classes, Collapse, Dialog, Intent, MenuItem } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { Select } from '@blueprintjs/select';
import StatusFlag from '../../models/StatusFlagModel';
import classNames from 'classnames';
import ColorSelector from '../shared/ColorSelector';

const EditStatusFlagSelect = Select.ofType(StatusFlag);

@inject('AppStore', 'TemplateStore', 'TemplateActions')
@observer
export default class TemplateEditStatusFlags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isManaging: false,
      showDialog: false,
    };
  }

  toggleManaging() {
    this.setState({
      isManaging: !this.state.isManaging,
    });
  }

  toggleDialog() {
    this.setState({
      showDialog: !this.state.showDialog,
    });
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  renderStatusFlagText(item) {
    return (
      <Flexbox>
        <span
          className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-10-r"
          style={{
            marginTop: '5px',
            color: item.color,
          }}
        />
        {item.name}
      </Flexbox>
    );
  }

  renderStatusFlag(item, details) {
    let menuClass = classNames('popover-larger');
    if (item.name === this.props.TemplateStore.editStatusFlag.name) {
      menuClass = classNames(Classes.ACTIVE, Classes.INTENT_PRIMARY, 'popover-larger');
    }
    return <MenuItem className={menuClass} key={item.id} onClick={details.handleClick} text={this.renderStatusFlagText(item, details)} />;
  }

  renderInputValue(statusFlag) {
    return statusFlag.name;
  }

  handleItemSelect(statusFlag) {
    this.setState({ isBlurred: [] });
    this.props.TemplateStore.editStatusFlag = statusFlag;
  }

  renderDropDown() {
    return <span className="bp3-icon bp3-icon-caret-down" />;
  }

  handleInputChange(e) {
    this.props.TemplateStore.updateNewStatusFlag(e.target.value, e.target.name);
  }

  handleChangeColor(code) {
    this.props.TemplateStore.updateNewStatusFlag(code, 'color');
  }

  renderManageStatusFlags() {
    return (
      <Collapse isOpen={this.state.isManaging}>
        <TemplateManageStatusFlags /> {this.renderNewStatusFlag()}
      </Collapse>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleLoading();
    const statusFlagObj = {
      app_id: this.props.AppStore.activeApp.id,
      name: this.props.TemplateStore.newStatusFlag.name,
      color: this.props.TemplateStore.newStatusFlag.color,
    };
    this.props.TemplateActions.createStatusFlag(statusFlagObj)
      .then(() => {
        this.toggleDialog();
        this.toggleLoading();
        this.props.TemplateStore.resetNewState();
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderDialog() {
    return (
      <Dialog
        portalContainer={document.body}
        className="fixed-width-container"
        isOpen={this.state.showDialog}
        onClose={this.toggleDialog.bind(this)}
        canOutsideClickClose={false}
        inline={false}
        title={I18n.t('js.add_status_flag')}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="bp3-dialog-body">
            <Flexbox flexDirection="column">
              <div className="bp3-form-group">
                <label className="bp3-label" htmlFor="name">
                  {I18n.t('js.status_flag_name')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div className="bp3-input-group">
                    <input
                      autoComplete="off"
                      className="bp3-input bp3-fill"
                      type="text"
                      placeholder={I18n.t('js.for_example_in_progress')}
                      name="name"
                      value={this.props.TemplateStore.newStatusFlag.name}
                      onChange={this.handleInputChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                </div>
              </div>
              <div className="bp3-form-group">
                <label className="bp3-label" htmlFor="color">
                  {I18n.t('js.status_flag_color')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <Flexbox flexDirection="column">
                    <ColorSelector
                      activeColor={this.props.TemplateStore.newStatusFlag.color}
                      handleChangeColor={this.handleChangeColor.bind(this)}
                    />
                  </Flexbox>
                </div>
              </div>
            </Flexbox>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text={I18n.t('js.cancel')} onClick={this.toggleDialog.bind(this)} />
              <Button
                intent={Intent.PRIMARY}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.props.TemplateStore.newStatusFlagValid}
                text={I18n.t('js.add_status_flag')}
                loading={this.state.isSubmitting}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  renderNewStatusFlag() {
    if (this.state.isManaging) {
      return (
        <Flexbox flexDirection="row" marginBottom="20px" marginTop="10px" flexGrow={0}>
          <Button
            onClick={this.toggleDialog.bind(this)}
            text={I18n.t('js.create_a_new_status_flag')}
            icon="flag"
            className="bp3-intent-primary"
          />
          {this.renderDialog()}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderButtonText() {
    if (this.props.TemplateStore.editStatusFlag.name.length > 0) {
      return (
        <Flexbox flexGrow={1}>
          <span
            className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg push-10-r"
            style={{
              marginTop: '2px',
              color: this.props.TemplateStore.editStatusFlag.color,
            }}
          />
          {this.props.TemplateStore.editStatusFlag.name}
          <Flexbox marginLeft="10px" marginTop="2px" marginRight="5px">
            <span className="bp3-icon bp3-icon-caret-down push-5-l" />
          </Flexbox>
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1}>
        {I18n.t('js.select_default_status_flag')}
        <Flexbox marginLeft="10px" marginTop="2px" marginRight="5px">
          <span className="bp3-icon bp3-icon-caret-down push-5-l" />
        </Flexbox>
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row">
          <Flexbox className="bp3-form-group">
            <label className="bp3-label" htmlFor="name">
              {I18n.t('js.default_status_flag')}
              <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
            </label>
            <div className="bp3-form-content">
              <Flexbox>
                <EditStatusFlagSelect
                  resetOnSelect={true}
                  filterable={false}
                  items={this.props.TemplateStore.statusFlags}
                  itemRenderer={this.renderStatusFlag.bind(this)}
                  onItemSelect={this.handleItemSelect.bind(this)}
                  inputValueRenderer={this.renderInputValue.bind(this)}
                  inputProps={{
                    rightElement: this.renderDropDown(),
                  }}
                  popoverProps={{
                    usePortal: false,
                    position: 'bottom-left',
                    className: '',
                    popoverClassName: 'bp3-minimal',
                  }}
                >
                  <Button text={this.renderButtonText()} disabled={this.props.TemplateStore.statusFlags.length === 0} />
                </EditStatusFlagSelect>
                <Flexbox flexGrow={0} flexDirection="column" marginLeft="10px">
                  <Button
                    text={I18n.t('js.manage_status_flags')}
                    icon="flag"
                    onClick={this.toggleManaging.bind(this)}
                    active={this.state.isManaging}
                  />
                </Flexbox>
              </Flexbox>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column">{this.renderManageStatusFlags()}</Flexbox>
      </Flexbox>
    );
  }
}
