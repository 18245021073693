// To add:
// type - horizontalBar, bar
// scales - axes, stacked, true

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, HTMLSelect, Button, Popover, Classes, Intent } from '@blueprintjs/core';
import ColorSelector from '../../shared/ColorSelector';
import Utilities from '../../../utils/Utilities';
import Flexbox from 'flexbox-react';
import { NonIdealState } from '@blueprintjs/core';
import nanoid from 'nanoid';

@inject('AppStore', 'DataViewStore', 'ToastStore')
@observer
export default class ChartBarConfiguration extends React.Component {
  handleAttrChange(e) {
    const value = e.target.value;
    const attribute = e.target.name;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attribute] = value;
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleToggleBool(attr) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig[attr] = !newDataConfig[attr];
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleFillToggle(dataset) {
    dataset.fill = !dataset.fill;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.datasets = _.merge(newDataConfig.datasets, dataset);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleDatasetValChange(e, dataset) {
    dataset[e.target.name] = e.target.value;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.datasets = _.merge(newDataConfig.datasets, dataset);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleChangeColor(value, dataset) {
    dataset.color = value;
    dataset.backgroundColor = `rgba(${Utilities.hexToRgb(value)}, 0.5)`;
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.datasets = _.merge(newDataConfig.datasets, dataset);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  handleAddDataset() {
    const colorObj = Utilities.getRandomColor();
    const newDataset = {
      id: nanoid(),
      name: I18n.t('js.new_dataset'),
      elementId: 'item_attributes#created_at',
      color: colorObj.code,
      borderColor: colorObj.code,
      borderWidth: 1,
      backgroundColor: `rgba(${Utilities.hexToRgb(colorObj.code)}, 0.5)`,
      fill: true,
    };
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.datasets = _.unionBy([newDataset], newDataConfig.datasets, 'id').reverse();
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  removeDataset(dataset) {
    const { activeChart } = this.props.DataViewStore;
    let newDataConfig = activeChart.dataConfig;
    newDataConfig.datasets = _.filter(newDataConfig.datasets, o => o.id != dataset.id);
    this.props.DataViewStore.updateActiveChart(newDataConfig, 'dataConfig');
  }

  renderDataset(dataset) {
    return (
      <Flexbox flexDirection="column" style={{ border: '1px dashed lightgray' }} padding="10px" marginBottom="10px">
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexDirection="column" flexGrow={1}>
            <div className="bp3-form-group">
              <label className="bp3-label" htmlFor="name">
                {I18n.t('js.name')}
                <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
              </label>
              <div className="bp3-form-content">
                <div className="bp3-input-group">
                  <input
                    autoComplete="off"
                    className="bp3-input bp3-fill"
                    type="text"
                    name="name"
                    value={dataset.name}
                    onChange={e => this.handleDatasetValChange(e, dataset)}
                    dir="auto"
                  />
                </div>
              </div>
            </div>
          </Flexbox>
          <Flexbox flexDirection="column" marginLeft="20px" flexGrow={0}>
            <Popover
              portalContainer={document.body}
              content={
                <Flexbox flexDirection="column" width="760px" padding="20px">
                  <h5 className={Classes.HEADING}>{I18n.t('js.select_color')}</h5>
                  <ColorSelector activeColor={dataset.color} handleChangeColor={value => this.handleChangeColor(value, dataset)} />
                </Flexbox>
              }
              position="left"
            >
              <div className="bp3-form-group">
                <label className="bp3-label">{I18n.t('js.select_color')}</label>
                <Flexbox>
                  <Button>
                    <span
                      className="bp3-cursor-pointer bp3-icon bp3-icon-full-circle fa-lg"
                      style={{
                        color: dataset.color,
                      }}
                    />
                    <span
                      className="bp3-cursor-pointer"
                      style={{
                        marginLeft: '5px',
                      }}
                    >
                      {dataset.color.toUpperCase()}
                    </span>
                  </Button>
                </Flexbox>
              </div>
            </Popover>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column" marginRight="20px">
          <label className="bp3-label" htmlFor="elementId">
            {I18n.t('js.select_data_element')}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <div className="bp3-form-content">
            <div className="bp3-input-group">
              <HTMLSelect
                className="push-10-r"
                name="elementId"
                onChange={e => this.handleDatasetValChange(e, dataset)}
                value={dataset.elementId}
              >
                {_.map(this.props.DataViewStore.availableElements, element => {
                  return (
                    <option key={element.id} id={element.id} value={element.id}>
                      {element.name}
                    </option>
                  );
                })}
              </HTMLSelect>
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="row" justifyContent="space-between" marginTop="20px">
          <Flexbox flexDirection="column">
            <Switch
              className="bp3-control-no-margin"
              checked={dataset.fill}
              label={I18n.t('js.fill_area')}
              onChange={() => this.handleFillToggle(dataset)}
            />
          </Flexbox>
          <Button icon="cross" text={I18n.t('js.remove_dataset')} onClick={() => this.removeDataset(dataset)} />
        </Flexbox>
      </Flexbox>
    );
  }

  renderNoDatasets() {
    const { activeChart } = this.props.DataViewStore;
    const { datasets } = activeChart.dataConfig;
    if (datasets.length === 0) {
      return (
        <Flexbox flexDirection="column" style={{ border: '1px dashed lightgray' }} padding="20px" marginBottom="10px">
          <NonIdealState
            title={I18n.t('js.no_datasets')}
            description={I18n.t('js.click_the_button_below_to_add_a_dataset')}
            icon="bring-data"
            className="bp3-text-muted"
          />
        </Flexbox>
      );
    }
  }

  render() {
    const { activeChart, availableNumberElements } = this.props.DataViewStore;
    return (
      <Flexbox flexDirection="column" marginTop="20px">
        <Flexbox flexDirection="column" marginRight="20px">
          <label className="bp3-label" htmlFor="type">
            {I18n.t('js.orientation')}
            <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
          </label>
          <div className="bp3-form-content">
            <div className="bp3-input-group">
              <HTMLSelect className="push-10-r" name="type" onChange={this.handleAttrChange.bind(this)} value={activeChart.dataConfig.type}>
                <option value="bar" id="bar">
                  {I18n.t('js.vertical')}
                </option>
                <option value="horizontalBar" id="horizontalBar">
                  {I18n.t('js.horizontal')}
                </option>
              </HTMLSelect>
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexDirection="column" flexGrow={1}>
            <h3>{I18n.t('js.y_axis')}</h3>
            <Flexbox flexDirection="row">
              <Flexbox flexDirection="column" marginRight="20px">
                <label className="bp3-label" htmlFor="yAxisElementId">
                  {I18n.t('js.y_axis_attribute')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div className="bp3-input-group">
                    <HTMLSelect
                      className="push-10-r"
                      name="yAxisElementId"
                      onChange={this.handleAttrChange.bind(this)}
                      value={activeChart.dataConfig.yAxisElementId}
                    >
                      {this.props.DataViewStore.dataViewLocation === 'app' ? (
                        <option value="item_attributes#id" id="item_attributes#id">
                          {this.props.AppStore.activeApp.itemPlural} ({I18n.t('js.default').toLowerCase()})
                        </option>
                      ) : (
                        <option value="user_attributes#id" id="user_attributes#id">
                          {I18n.t('js.users')} ({I18n.t('js.default').toLowerCase()})
                        </option>
                      )}
                      {_.map(availableNumberElements, element => {
                        return (
                          <option key={element.id} value={element.id}>
                            {element.name}
                          </option>
                        );
                      })}
                    </HTMLSelect>
                  </div>
                </div>
              </Flexbox>
              <Flexbox flexDirection="column" marginRight="20px">
                <label className="bp3-label" htmlFor="method">
                  {I18n.t('js.aggregate_data')}
                  <span className="bp3-text-muted push-5-l">({I18n.t('js.required')})</span>
                </label>
                <div className="bp3-form-content">
                  <div className="bp3-input-group">
                    <HTMLSelect
                      className="push-10-r"
                      name="method"
                      onChange={this.handleAttrChange.bind(this)}
                      value={activeChart.dataConfig.method}
                    >
                      <option value="default" id="default">
                        {I18n.t('js.default')}
                      </option>
                      <option value="average" id="average">
                        {I18n.t('js.average')}
                      </option>
                      <option value="cumulative" id="cumulative">
                        {I18n.t('js.cumulative')}
                      </option>
                    </HTMLSelect>
                  </div>
                </div>
              </Flexbox>
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <Switch
                className="bp3-control-no-margin"
                checked={activeChart.dataConfig.yAxisEnabled}
                label={I18n.t('js.y_axis_enabled')}
                onChange={() => this.handleToggleBool('yAxisEnabled')}
              />
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <Switch
                className="bp3-control-no-margin"
                disabled={!activeChart.dataConfig.yAxisEnabled}
                checked={activeChart.dataConfig.yAxisLabelEnabled}
                label={I18n.t('js.y_axis_label_enabled')}
                onChange={() => this.handleToggleBool('yAxisLabelEnabled')}
              />
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <Switch
                className="bp3-control-no-margin"
                checked={activeChart.dataConfig.yAxisGridEnabled}
                label={I18n.t('js.y_axis_grid_enabled')}
                onChange={() => this.handleToggleBool('yAxisGridEnabled')}
              />
            </Flexbox>
            <Flexbox flexDirection="column" marginTop="20px">
              <div className="bp3-form-group">
                <label className="bp3-label" htmlFor="yAxisLabel">
                  {I18n.t('js.y_axis_label')}
                </label>
                <div className="bp3-form-content">
                  <div className="bp3-input-group">
                    <input
                      autoComplete="off"
                      disabled={!activeChart.dataConfig.yAxisEnabled || !activeChart.dataConfig.yAxisLabelEnabled}
                      className="bp3-input bp3-fill"
                      type="text"
                      name="yAxisLabel"
                      value={activeChart.dataConfig.yAxisLabel}
                      onChange={this.handleAttrChange.bind(this)}
                      dir="auto"
                    />
                  </div>
                </div>
              </div>
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column" marginTop="20px">
          <h3>{I18n.t('js.datasets')}</h3>
          <Flexbox flexDirection="column" marginBottom="20px">
            <Switch
              disabled={activeChart.dataConfig.datasets.length <= 1}
              className="bp3-control-no-margin"
              checked={activeChart.dataConfig.stacked}
              label={I18n.t('js.stacked')}
              onChange={() => this.handleToggleBool('stacked')}
            />
          </Flexbox>
          {_.map(activeChart.dataConfig.datasets, dataset => {
            return (
              <Flexbox flexDirection="column" key={dataset.id}>
                {this.renderDataset(dataset)}
              </Flexbox>
            );
          })}
          {this.renderNoDatasets()}
          <Flexbox>
            <Button text={I18n.t('js.add_dataset')} icon="bring-data" onClick={this.handleAddDataset.bind(this)} />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
