import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import ReportScheduleStore from '../../stores/ReportScheduleStore';
import ReportSchedule from './ReportSchedule';
import { Drawer, Button, Position, Classes, NonIdealState, Intent, Spinner } from '@blueprintjs/core';
import moment from 'moment';

@observer
export default class ReportSchedules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    ReportScheduleStore.resetStartingAttributes();
    ReportScheduleStore.reportTemplate = this.props.reportTemplate;
    if (this.props.reportTemplate.isPersisted) {
      ReportScheduleStore.fetchReportTemplateSchedules()
        .then(response => {
          this.setState({ isLoading: false });
        })
        .catch(error => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  }

  toggleDrawer() {
    if (!this.state.drawerOpen) {
      this.fetchData();
    }
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  changeAction(action) {
    ReportScheduleStore.setAction(action);
  }

  handleEditSchedule(e, schedule) {
    ReportScheduleStore.setActiveReportTemplateSchedule(schedule);
    this.changeAction('edit');
  }

  renderSchedules() {
    switch (ReportScheduleStore.action) {
      case 'index':
        return (
          <Flexbox flexDirection="column">
            <Flexbox marginBottom="20px">
              <Button
                text={I18n.t('js.new_report_schedule')}
                icon="timeline-events"
                onClick={() => this.changeAction('new')}
                intent={Intent.PRIMARY}
              ></Button>
            </Flexbox>
            {ReportScheduleStore.reportTemplateSchedules.length > 0 ? (
              <Flexbox>
                {this.state.isLoading ? (
                  <NonIdealState
                    title={I18n.t('js.loading')}
                    description={I18n.t('js.fetching_data')}
                    icon={<Spinner />}
                    className="bp3-text-muted"
                  />
                ) : (
                  <Flexbox flexDirection="row" flexGrow={1} className="bp3-card bp3-card-no-padding">
                    <table className="bp3-html-table bp3-html-table-striped bp3-small full-table">
                      <thead>
                        <tr>
                          <th>{I18n.t('js.frequency')}</th>
                          <th>{I18n.t('js.start_date')}</th>
                          <th>{I18n.t('js.message')}</th>
                          <th>{I18n.t('js.action')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ReportScheduleStore.reportTemplateSchedules.map(schedule => {
                          return (
                            <tr key={schedule.id}>
                              <td>{_.capitalize(schedule.frequency)}</td>
                              <td>{moment(schedule.startDate).format('MMMM Do YYYY, h:mm a')}</td>
                              <td>{_.truncate(schedule.message, { length: 30 })}</td>
                              <td>
                                <Button text={I18n.t('js.edit')} icon="edit" onClick={e => this.handleEditSchedule(e, schedule)}></Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Flexbox>
                )}
              </Flexbox>
            ) : (
              <Flexbox className="bp3-card">
                <NonIdealState
                  title={I18n.t('js.no_reports_are_schedules')}
                  description={I18n.t('js.there_are_no_report_schedules_for_this_report_template')}
                  icon="time"
                  className="bp3-text-muted"
                />
              </Flexbox>
            )}
          </Flexbox>
        );
      case 'new':
        return (
          <Flexbox flexDirection="column">
            <Flexbox marginBottom="20px">
              <Button text={I18n.t('js.back')} icon="arrow-left" onClick={() => this.changeAction('index')}></Button>
            </Flexbox>
            <ReportSchedule />
          </Flexbox>
        );
      case 'edit':
        return (
          <Flexbox flexDirection="column">
            <Flexbox marginBottom="20px">
              <Button text={I18n.t('js.back')} icon="arrow-left" onClick={() => this.changeAction('index')}></Button>
            </Flexbox>
            <ReportSchedule />
          </Flexbox>
        );
    }
  }
  render() {
    return (
      <Flexbox flexDirection="column">
        <Button
          text={I18n.t('js.schedule_report')}
          disabled={this.props.disabled}
          icon="time"
          onClick={this.toggleDrawer.bind(this)}
          className="push-10-r"
        />
        <Drawer
          portalContainer={document.body}
          position={Position.RIGHT}
          onClose={this.toggleDrawer.bind(this)}
          isOpen={this.state.drawerOpen}
          icon="time"
          title={I18n.t('js.report_schedules')}
        >
          <Flexbox className={Classes.DRAWER_BODY} flexDirection="column" padding="20px">
            {this.renderSchedules()}
          </Flexbox>
        </Drawer>
      </Flexbox>
    );
  }
}
