/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import Flexbox from 'flexbox-react';
import FormElementDisplay from '../form_elements/FormElementDisplay';
import FormElementTitle from '../form_elements/FormElementTitle';
import FormElementDescription from '../form_elements/FormElementDescription';
import FormElementRichTextareaDisplay from '../form_elements/FormElementRichTextareaDisplay';
import FormElementImage from '../form_elements/FormElementImage';
import FormElementHyperlink from '../form_elements/FormElementHyperlink';
import FormElementFileDownload from '../form_elements/FormElementFileDownload';
import FormElementTextInput from '../form_elements/FormElementTextInput';
import FormElementTextarea from '../form_elements/FormElementTextarea';
import FormElementRichTextarea from '../form_elements/FormElementRichTextarea';
import FormElementEmail from '../form_elements/FormElementEmail';
import FormElementMultiline from '../form_elements/FormElementMultiline';
import FormElementSelect from '../form_elements/FormElementSelect';
import FormElementGrid from '../form_elements/FormElementGrid';
import FormElementObjectSelectUser from '../form_elements/FormElementObjectSelectUser';
import FormElementObjectSelectTeam from '../form_elements/FormElementObjectSelectTeam';
import FormElementObjectSelectItem from '../form_elements/FormElementObjectSelectItem';
import FormElementNumber from '../form_elements/FormElementNumber';
import FormElementCurrency from '../form_elements/FormElementCurrency';
import FormElementPercentage from '../form_elements/FormElementPercentage';
import FormElementSlider from '../form_elements/FormElementSlider';
import FormElementRangeSlider from '../form_elements/FormElementRangeSlider';
import FormElementDate from '../form_elements/FormElementDate';
import FormElementDateRange from '../form_elements/FormElementDateRange';
import FormElementDateTime from '../form_elements/FormElementDateTime';
import FormElementTime from '../form_elements/FormElementTime';
import FormElementHyperlinkInput from '../form_elements/FormElementHyperlinkInput';
import FormElementImageUpload from '../form_elements/FormElementImageUpload';
import FormElementFileInput from '../form_elements/FormElementFileInput';
import FormElementLocationSelect from '../form_elements/FormElementLocationSelect';
import _ from 'lodash';

@inject('ItemStore', 'CurrentUserStore')
@observer
export default class FormElement extends React.Component {
  @observable
  hasError = false;

  @computed
  get showError() {
    return this.hasError && this.props.ItemStore.submissionAttempted;
  }

  @computed
  get errors() {
    return _.filter(this.props.ItemStore.itemErrors, o => o.elementId === this.props.element.id);
  }

  validateInput(input) {
    if (this.props.element.isEditable && this.props.sectionExposed) {
      this.props.ItemStore.resetCountDown();
      if (this.props.element.elementType === 'select') {
        this.validationSwitch(input);
      } else {
        if (!this.props.element.validateLength(input)) {
          const errorObj = {
            elementId: this.props.element.id,
            errorMessage: this.props.element.validateLengthMessage(input, false),
            errorMessageFull: this.props.element.validateLengthMessage(input, true),
          };
          this.hasError = true;
          this.props.ItemStore.addError(errorObj);
        } else {
          this.validationSwitch(input);
        }
      }
    }
  }

  validationSwitch(input) {
    switch (this.props.element.elementType) {
      case 'email':
        this.validateEmail(input);
        break;
      case 'date_range':
        this.validateDateRange(input);
        break;
      case 'select':
        this.validateSelect(input);
        break;
      case 'grid':
        this.validateGrid(input);
        break;
      case 'number':
      case 'currency':
      case 'percentage':
        this.validateNumber(input);
        break;
      case 'hyperlink_input':
        this.validateHyperlink(input);
        break;
      default:
        this.validateRequired(input);
    }
  }

  validateRequired(input) {
    if (!this.props.element.validateRequired(input)) {
      const errorObj = {
        elementId: this.props.element.id,
        errorMessage: this.props.element.validateRequiredMessage(input, false),
        errorMessageFull: this.props.element.validateRequiredMessage(input, true),
      };
      this.hasError = true;
      this.props.ItemStore.addError(errorObj);
    } else {
      this.clearErrors();
    }
  }

  validateEmail(input) {
    if (!this.props.element.validateEmail(input) && this.props.element.isRequired) {
      const errorObj = {
        elementId: this.props.element.id,
        errorMessage: this.props.element.validateEmailMessage(input, false),
        errorMessageFull: this.props.element.validateEmailMessage(input, true),
      };
      this.hasError = true;
      this.props.ItemStore.addError(errorObj);
    } else {
      this.validateRequired(input);
    }
  }

  validateHyperlink(input) {
    if (!this.props.element.validateHyperlink(input) && this.props.element.isRequired) {
      const errorObj = {
        elementId: this.props.element.id,
        errorMessage: this.props.element.validateHyperlinkMessage(input, false),
        errorMessageFull: this.props.element.validateHyperlinkMessage(input, true),
      };
      this.hasError = true;
      this.props.ItemStore.addError(errorObj);
    } else {
      this.validateRequired(input);
    }
  }

  validateSelect() {
    let responsePresent = false;
    _.map(this.props.element.formValues, formValue => {
      if (formValue.booleanValue) {
        responsePresent = true;
      }
    });
    _.map(this.props.ItemStore.formValuesToCreate, formValue => {
      if (formValue.boolean_value) {
        responsePresent = true;
      }
    });
    _.map(this.props.ItemStore.formValuesToChange, formValue => {
      if (formValue.boolean_value) {
        responsePresent = true;
      }
    });
    if (!responsePresent && this.props.element.isRequired) {
      const errorObj = {
        elementId: this.props.element.id,
        errorMessage: I18n.t('js.please_select_an_option'),
        errorMessageFull: I18n.t('js.please_select_an_option_full', {
          name: this.props.element.elementSelect.name,
        }),
      };
      this.hasError = true;
      this.props.ItemStore.addError(errorObj);
    } else {
      this.clearErrors();
    }
  }

  validateGrid(input) {
    _.map(this.props.element.elementGrid.elementGridRows, row => {
      let responsePresent = false;
      _.map(this.props.element.elementGrid.elementGridColumns, column => {
        const findValue = _.find(input, o => o.elementGridRowId === row.id && o.elementGridColumnId === column.id);
        if (findValue.booleanValue) {
          responsePresent = true;
        }
      });
      if (!responsePresent && this.props.element.isEditable && this.props.element.isRequired) {
        const errorObj = {
          elementId: this.props.element.id,
          errorMessage: I18n.t('js.please_respond_to_all_questions_in_this_grid'),
          errorMessageFull: I18n.t('js.please_respond_to_all_questions_in_this_grid_full', { name: this.props.element.elementGrid.name }),
        };
        this.hasError = true;
        this.props.ItemStore.addError(errorObj);
      } else {
        this.clearErrors();
      }
    });
  }

  validateNumber(input) {
    if (!this.props.element.elementNumber.validateNumberRange(input)) {
      const errorObj = {
        elementId: this.props.element.id,
        errorMessage: this.props.element.elementNumber.validateNumberRangeMessage(input, false),
        errorMessageFull: this.props.element.elementNumber.validateNumberRangeMessage(input, true),
      };
      this.hasError = true;
      this.props.ItemStore.addError(errorObj);
    } else {
      this.validateRequired(input);
    }
  }

  validateDateRange(input) {
    if (!(input[0] && input[1]) && this.props.element.isRequired) {
      const errorObj = {
        elementId: this.props.element.id,
        errorMessage: I18n.t('js.please_enter_a_valid_date_range'),
        errorMessageFull: I18n.t('js.please_enter_a_valid_date_range_full', { name: this.props.element.elementDate.name }),
      };
      this.hasError = true;
      this.props.ItemStore.addError(errorObj);
    } else {
      this.validateRequired(input);
    }
  }

  clearErrors() {
    this.hasError = false;
    this.props.ItemStore.clearErrorsForElement(this.props.element.id);
  }

  renderElement() {
    switch (this.props.element.elementType) {
      case 'title':
        return (
          <FormElementTitle
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'description':
        return (
          <FormElementDescription
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'rich_textarea_display':
        return (
          <FormElementRichTextareaDisplay
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'image':
        return (
          <FormElementImage
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'hyperlink':
        return (
          <FormElementHyperlink
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'file_download':
        return (
          <FormElementFileDownload
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'text_input':
        return (
          <FormElementTextInput
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'textarea':
        return (
          <FormElementTextarea
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'rich_textarea':
        return (
          <FormElementRichTextarea
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'email':
        return (
          <FormElementEmail
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'multiline':
        return (
          <FormElementMultiline
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'select':
        return (
          <FormElementSelect
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'grid':
        return (
          <FormElementGrid
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'user_select':
        return (
          <FormElementObjectSelectUser
            store={this.props.ItemStore}
            methodToAddOption="addFormValueToCreate"
            methodToDeleteOption="addFormValueToDelete"
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'team_select':
        return (
          <FormElementObjectSelectTeam
            store={this.props.ItemStore}
            methodToAddOption="addFormValueToCreate"
            methodToDeleteOption="addFormValueToDelete"
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'item_select':
        return (
          <FormElementObjectSelectItem
            store={this.props.ItemStore}
            methodToAddOption="addFormValueToCreate"
            methodToDeleteOption="addFormValueToDelete"
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'number':
        return (
          <FormElementNumber
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'currency':
        return (
          <FormElementCurrency
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'percentage':
        return (
          <FormElementPercentage
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'slider':
        return (
          <FormElementSlider
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'range_slider':
        return (
          <FormElementRangeSlider
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'date':
        return (
          <FormElementDate
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'date_range':
        return (
          <FormElementDateRange
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'date_time':
        return (
          <FormElementDateTime
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'time':
        return (
          <FormElementTime
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'hyperlink_input':
        return (
          <FormElementHyperlinkInput
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'image_upload':
        return (
          <FormElementImageUpload
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'file_upload':
        return (
          <FormElementFileInput
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'location_select':
        return (
          <FormElementLocationSelect
            storeType="item"
            element={this.props.element}
            validateInput={this.validateInput.bind(this)}
            hasError={this.hasError}
            showError={this.showError}
            errors={this.errors}
          />
        );
      case 'calculation':
      case 'profile_value':
        return <FormElementDisplay element={this.props.element} showLabels={true} />;
      default:
        return (
          <Flexbox marginTop="10px">
            <p className="bp3-text-muted">
              <em>This {this.props.element.elementType} element has not yet been defined.</em>
            </p>
          </Flexbox>
        );
    }
  }

  render() {
    if (this.props.element.isDisplayElement || this.props.element.useItemElementToDisplay) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" marginBottom="10px" minHeight="40px" className={`element_${this.props.element.id}`}>
          {this.renderElement()}
        </Flexbox>
      );
    }
    if (!this.props.ItemStore.isWebformView) {
      if (this.props.findItemSectionInvite) {
        if (this.props.findItemSectionInvite.user) {
          if (this.props.findItemSectionInvite.user.id != this.props.CurrentUserStore.currentUser.id) {
            return (
              <Flexbox flexGrow={1} flexDirection="column" minHeight="40px" className={`element_${this.props.element.id}`}>
                <FormElementDisplay element={this.props.element} stopOpeningMoreDrawers={this.props.stopOpeningMoreDrawers} />
              </Flexbox>
            );
          }
        }
        if (this.props.findItemSectionInvite.external_email) {
          if (this.props.findItemSectionInvite.external_email != this.props.CurrentUserStore.currentUser.email) {
            return (
              <Flexbox flexGrow={1} flexDirection="column" minHeight="40px" className={`element_${this.props.element.id}`}>
                <FormElementDisplay element={this.props.element} stopOpeningMoreDrawers={this.props.stopOpeningMoreDrawers} />
              </Flexbox>
            );
          }
        }
      }
    }
    if (this.props.stopOpeningMoreDrawers) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" minHeight="40px" className={`element_${this.props.element.id}`}>
          <FormElementDisplay element={this.props.element} stopOpeningMoreDrawers={this.props.stopOpeningMoreDrawers} />
        </Flexbox>
      );
    }
    if (!this.props.element.isEditable && !this.props.element.isDisplayElement && !this.props.element.useItemElementToDisplay) {
      return (
        <Flexbox flexGrow={1} flexDirection="column" minHeight="40px" className={`element_${this.props.element.id}`}>
          <FormElementDisplay element={this.props.element} stopOpeningMoreDrawers={this.props.stopOpeningMoreDrawers} />
        </Flexbox>
      );
    }
    return (
      <Flexbox flexGrow={1} flexDirection="column" marginBottom="10px" minHeight="40px" className={`element_${this.props.element.id}`}>
        {this.renderElement()}
      </Flexbox>
    );
  }
}
