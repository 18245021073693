import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Classes, Intent, Menu, MenuItem, Tag } from '@blueprintjs/core';
import { convertFromRaw, EditorState } from 'draft-js';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import RichTextDisplay from '../shared/RichTextDisplay';
import Utilities from '../../utils/Utilities';

@inject('CurrentUserStore', 'ToastStore')
@observer
export default class StoreTemplateWidget extends React.Component {
  @observable
  targetUrl = '';

  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  componentDidMount() {
    if (this.props.storeCategoryView) {
      this.targetUrl = `/store/categories/${this.props.storeCategory.fields.slug}/templates/${this.props.storeTemplate.slug}`;
    } else {
      this.targetUrl = `/store-templates/${this.props.storeTemplate.id}`;
    }
  }

  renderDraftTag() {
    if (this.props.storeTemplate.status === 'draft') {
      return (
        <Tag className="push-10-l bp3-minimal" intent={Intent.WARNING}>
          {I18n.t('js.draft_store_template')}
        </Tag>
      );
    }
    return undefined;
  }

  renderMenu() {
    return (
      <Menu>
        <MenuItem
          icon="edit"
          onClick={() => Utilities.navigate(`/store-templates/${this.props.storeTemplate.id}?tab=edit`)}
          text={I18n.t('js.edit_store_template')}
        />
      </Menu>
    );
  }

  renderImage() {
    if (this.props.storeTemplate.imageFileName.length > 0) {
      const imgClass = classNames('store-template-img-small');
      return (
        <Flexbox flexGrow={0} flexDirection="column" maxWidth="400px" marginRight="20px" marginTop="10px">
          <img src={this.props.storeTemplate.imageFileName} alt="store template cover" className={imgClass} />
        </Flexbox>
      );
    }
    return undefined;
  }

  render() {
    const widgetClass = classNames('store-category-store-template-widget');
    const cardClass = classNames('bp3-card bp3-interactive bp3-elevation-1 bp3-card-no-padding bp3-cursor');
    let cardStyle = { borderLeft: '5px solid #0F9960' };
    if (this.props.storeTemplate.templatableObjectType === 'Workspace') {
      cardStyle = { borderLeft: '5px solid #137CBD' };
    }
    if (this.props.storeTemplate.templatableObjectType === 'ProfileTemplate') {
      cardStyle = { borderLeft: '5px solid #7157D9' };
    }
    let storeTemplateClass = classNames('store-template-text-overflow');
    const descriptionPlain = EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.storeTemplate.description)))
      .getCurrentContent()
      .getPlainText();
    // eslint-disable-next-line no-magic-numbers
    if (descriptionPlain.length < 100) {
      storeTemplateClass = classNames('');
    }
    if (this.props.storeTemplate.status === 'draft') {
      cardStyle = { borderLeft: '5px solid #FFB366' };
    }
    return (
      <Flexbox flexDirection="column" className={widgetClass} marginBottom="20px" marginRight="20px">
        <Flexbox
          className={cardClass}
          style={cardStyle}
          flexGrow={1}
          flexDirection="row"
          onClick={() => (this.props.clickHandler ? this.props.clickHandler() : Utilities.navigate(this.targetUrl))}
        >
          <Flexbox flexDirection="column" padding="20px" flexGrow={1}>
            <Flexbox flexDirection="column" marginBottom="20px" flexGrow={0}>
              <Flexbox flexDirection="column" flexGrow={1}>
                <Flexbox flexDirection="row" flexGrow={1} alignContent="center">
                  <Flexbox flexDirection="row">
                    <h4 className={Classes.HEADING}>{this.props.storeTemplate.title}</h4>
                    <Flexbox flexDirection="column" flexGrow={0}>
                      {this.renderDraftTag()}
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
                <Flexbox marginTop="-5px">
                  <p className="bp3-text-muted">{this.props.storeTemplate.subtitle}</p>
                </Flexbox>
              </Flexbox>
              <Flexbox flexGrow={1} flexDirection="row">
                {this.renderImage()}
                <Flexbox flexGrow={1} className="position-relative" flexDirection="column">
                  <Flexbox flexGrow={1} className={storeTemplateClass} maxHeight="180px">
                    <RichTextDisplay content={this.props.storeTemplate.description} />
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox flexGrow={1} alignItems="flex-end" />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    );
  }
}
