import { action, computed, observable } from 'mobx';
import Utilities from '../utils/Utilities';
import Cookies from 'js-cookie';
import axios from 'axios';
import _ from 'lodash';

class DocumentationStore {
  @observable
  isLoading = true;
  @observable
  selectedDocumentation = '';
  @observable
  selectedDocumentationType = '';
  @observable
  activeApi = '';
  @observable
  apis = [];
  @observable
  apiGuides = [];
  @observable
  navTree = [];

  @computed
  get hasNoData() {
    return this.apis.length === 0 && this.apiGuides.length === 0;
  }

  @computed
  get basePath() {
    return Cookies.get('apiEnv').split('/v2');
  }

  @action
  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  @action
  setActiveApi(id) {
    this.activeApi = this.apis[id];
  }

  @action
  buildNavTree() {
    return new Promise(resolve => {
      const navTree = [];
      const guideObj = {
        id: 'guideTop',
        icon: 'book',
        label: I18n.t('js.guides'),
        isExpanded: true,
        type: 'guide',
      };
      const guideNodes = [];
      _.map(this.apiGuides, apiGuide => {
        const apiGuideObj = {
          id: apiGuide.fields.slug,
          icon: 'book',
          label: apiGuide.fields.shortName,
          isExpanded: false,
          type: 'guide',
        };
        guideNodes.push(apiGuideObj);
      });
      guideObj.childNodes = guideNodes;
      navTree.push(guideObj);
      _.map(this.apis, (api, index) => {
        const apiObj = {
          id: index,
          icon: 'git-repo',
          label: api.info.title,
          isExpanded: true,
          type: 'api',
        };
        const childNodes = [];
        _.map(api.tags, tag => {
          const childNode = {
            id: tag.name,
            icon: 'document',
            label: Utilities.humanize(tag.name),
            type: 'model',
            childNodes: [],
          };
          _.map(api.paths, path => {
            _.mapKeys(path, operation => {
              if (operation.tags[0] === tag.name) {
                const pathNode = {
                  id: operation.operationId,
                  icon: 'git-commit',
                  label: operation.description,
                  type: 'path',
                };
                childNode.childNodes.push(pathNode);
              }
            });
          });
          childNodes.push(childNode);
        });
        apiObj.childNodes = childNodes;
        navTree.push(apiObj);
      });
      this.navTree = navTree;
      resolve();
    });
  }

  @action
  navTreeUnselectAll() {
    const navTree = this.navTree;
    _.map(navTree, apiObj => {
      apiObj.isSelected = false;
      _.map(apiObj.childNodes, child => {
        child.isSelected = false;
        _.map(child.childNodes, chil => {
          chil.isSelected = false;
        });
      });
    });
    this.navTree = navTree;
  }

  @action
  setSelectedDocumentation(id, type) {
    this.selectedDocumentationType = type;
    if (type === 'model') {
      _.mapKeys(this.activeApi.definitions, (definition, key) => {
        if (id === 'metrics') {
          this.selectedDocumentation = {
            name: I18n.t('js.nothing_to_show'),
            description: I18n.t(
              'js.there_is_nothing_to_show_right_now'
            ),
            properties: [],
          };
        } else {
          if (`${_.snakeCase(key)}s` === id) {
            this.selectedDocumentation = definition;
            this.selectedDocumentation.name = id;
          } else {
            if (key === 'Activity') {
              this.selectedDocumentation = definition;
              this.selectedDocumentation.name = id;
            }
          }
        }
      });
    }
    if (type === 'path') {
      _.mapKeys(this.activeApi.paths, (path, pathKey) => {
        _.mapKeys(path, (method, methodKey) => {
          if (method.operationId === id) {
            method.path = pathKey;
            method.method = methodKey;
            return (this.selectedDocumentation = method);
          }
          return undefined;
        });
      });
    }
    if (type === 'guide') {
      const findGuide = _.find(
        this.apiGuides,
        o => o.fields.slug === id
      );
      this.selectedDocumentation = findGuide;
      Utilities.navigate(`/developers/${findGuide.fields.slug}`);
    }
  }

  @action
  addApi(api) {
    const tags = _.orderBy(api.tags, ['name'], ['asc']);
    api.tags = tags;
    this.apis.push(api);
    this.activeApi = api;
  }

  @action
  addApiGuide(apiGuide) {
    this.apiGuides = _.unionBy([apiGuide], this.apiGuides, 'sys.id');
  }

  @action
  parseApiDocumentation() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/documentation`)
        .then(response => {
          this.addApi(response.data);
          resolve(response);
        })
        .catch(reject);
    });
  }

  @action
  resetStartingAttributes() {
    this.isLoading = true;
    this.selectedDocumentation = '';
    this.selectedDocumentationType = '';
    this.activeApi = '';
    this.apis = [];
    this.apiGuides = [];
    this.navTree = [];
  }
}

const store = new DocumentationStore();
export default store;
