import React from 'react';
import Flexbox from 'flexbox-react';
import { Button, Popover, Menu, MenuItem, MenuDivider, Position, Intent } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import { inject, observer } from 'mobx-react';
import AppItemsNewItemDialog from './AppItemsNewItemDialog';
import AppItemsSelectUsers from './AppItemsSelectUsers';
import AppItemsSelectUsersByRelationship from './AppItemsSelectUsersByRelationship';
import AppItemsSelectTeams from './AppItemsSelectTeams';
import _ from 'lodash';
import Utilities from '../../utils/Utilities';

@inject('AppStore', 'AppItemStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppItemsNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isAddingNewItem: false,
      isAddingNewUsers: false,
      isAddingNewUsersByRelationship: false,
      isAddingNewTeams: false,
      draftItemId: null,
      activeRelationship: null,
      whitelistUsers: [],
    };
  }

  toggleLoading = () => {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  };

  toggleAddNewItem = () => {
    this.setState({ isSubmitting: true });
    const itemObj = {};
    itemObj.app_id = this.props.AppStore.activeApp.id;
    itemObj.user_id = this.props.CurrentUserStore.currentUser.id;
    itemObj.name = I18n.t('js.new_item_with_name', {
      item: this.props.AppStore.activeApp.item,
      name: this.props.CurrentUserStore.currentUser.fullName,
    });
    itemObj.is_draft = true;
    this.props.ItemActions.createItem(itemObj).then(response => {
      const item = response.data;
      this.setState({
        isSubmitting: false,
        draftItemId: item.id,
        isAddingNewItem: !this.state.isAddingNewItem,
      });
    });
  };

  handleDraftCancel() {
    this.setState({ isAddingNewItem: false });
    this.props.ItemActions.deleteItem(this.state.draftItemId);
  }

  toggleAddNewUsers = () => {
    this.setState({
      isAddingNewUsers: !this.state.isAddingNewUsers,
    });
  };

  handleAddNewUsersByRelationship = relationship => {
    this.setState({
      isAddingNewUsersByRelationship: !this.state.isAddingNewUsersByRelationship,
      activeRelationship: relationship,
    });
    this.props.AppStore.fetchUserRelationships(relationship.id, this.props.CurrentUserStore.currentUser.id).then(() => {
      let whitelistUsers = [];
      _.map(this.props.AppStore.userRelationships, userRelationship => {
        if (userRelationship.user_id != this.props.CurrentUserStore.currentUser.id) {
          whitelistUsers.push(userRelationship.user);
        }
      });
      this.setState({ whitelistUsers });
    });
  };

  hideAddNewUsersByRelationship = () => {
    this.setState({
      isAddingNewUsersByRelationship: false,
      activeRelationship: null,
    });
  };

  toggleAddNewTeams = () => {
    this.setState({
      isAddingNewTeams: !this.state.isAddingNewTeams,
    });
  };

  handleCreateNewItemForCurrentUser() {
    this.toggleLoading();
    const itemObj = {};
    itemObj.app_id = this.props.AppStore.activeApp.id;
    itemObj.user_id = this.props.CurrentUserStore.currentUser.id;
    itemObj.name = I18n.t('js.new_item_with_name', {
      item: this.props.AppStore.activeApp.item,
      name: this.props.CurrentUserStore.currentUser.fullName,
    });
    this.props.ItemActions.createItem(itemObj)
      .then(response => {
        Utilities.navigate(`/items/${response.data.id}`);
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderUserIcon() {
    return <Avatar src={this.props.CurrentUserStore.currentUser.imageFileName} size={20} round={true} className="push-5-r" />;
  }

  renderAppNewItemMenu() {
    return (
      <Menu>
        <MenuItem
          icon={this.renderUserIcon()}
          text={I18n.t('js.new_draft_item', { item: this.props.AppStore.activeApp.item })}
          onClick={this.toggleAddNewItem.bind(this)}
        />
        <MenuDivider />
        <MenuItem
          icon="people"
          text={I18n.t('js.select_users')}
          disabled={!this.props.AppStore.activeApp.userCanEdit}
          onClick={this.toggleAddNewUsers.bind(this)}
        />
        <MenuItem
          icon="send-to-graph"
          text={I18n.t('js.select_users_by_relationship')}
          disabled={this.props.AppStore.relationshipPermissions.length === 0}
        >
          {_.map(this.props.AppStore.relationshipPermissions, relationshipPermission => {
            return (
              <MenuItem
                icon="people"
                key={relationshipPermission.id}
                text={`${relationshipPermission.relationship.name} / ${relationshipPermission.relationship.counterpartName}`}
                onClick={() => this.handleAddNewUsersByRelationship(relationshipPermission.relationship)}
              />
            );
          })}
        </MenuItem>
        <MenuItem
          icon="layout-hierarchy"
          text={I18n.t('js.select_team_of_users')}
          disabled={!this.props.AppStore.activeApp.userCanEdit}
          onClick={this.toggleAddNewTeams.bind(this)}
        />
      </Menu>
    );
  }

  renderNewIcon() {
    return <span className={`fa fa-fw fab ${this.props.AppStore.activeApp.icon}`}></span>;
  }

  render() {
    const isDisabled =
      this.props.AppStore.activeApp.userPermissionLevel.name === 'view_only' ||
      this.props.AppStore.activeApp.userPermissionLevel.name === 'access_denied';

    return (
      <Flexbox flexGrow={0} flexDirection="row">
        <Flexbox flexGrow={1} flexDirection="column">
          <Popover
            content={this.renderAppNewItemMenu()}
            position={Position.RIGHT}
            portalContainer={document.body}
            disabled={isDisabled}
            autoFocus={false}
          >
            <Button
              icon={this.renderNewIcon()}
              large={false}
              intent={Intent.PRIMARY}
              className="push-10-r"
              text={I18n.t('js.new_item', {
                item: this.props.AppStore.activeApp.item,
              })}
              loading={this.state.isSubmitting}
              disabled={isDisabled}
            />
          </Popover>
          <AppItemsNewItemDialog
            toggleDialog={this.toggleAddNewItem.bind(this)}
            handleCancel={this.handleDraftCancel.bind(this)}
            postType="create"
            dialogOpen={this.state.isAddingNewItem}
            itemId={this.state.draftItemId}
          />
          <AppItemsSelectUsers
            toggleDialog={this.toggleAddNewUsers.bind(this)}
            postType="create"
            dialogOpen={this.state.isAddingNewUsers}
          />
          {this.state.activeRelationship ? (
            <AppItemsSelectUsersByRelationship
              toggleDialog={this.hideAddNewUsersByRelationship.bind(this)}
              relationship={this.state.activeRelationship}
              whitelistUsers={this.state.whitelistUsers}
              postType="create"
              dialogOpen={this.state.isAddingNewUsersByRelationship}
            />
          ) : null}
          <AppItemsSelectTeams
            toggleDialog={this.toggleAddNewTeams.bind(this)}
            postType="create"
            dialogOpen={this.state.isAddingNewTeams}
          />
        </Flexbox>
      </Flexbox>
    );
  }
}
