import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Checkbox, FormGroup, Intent, Radio } from '@blueprintjs/core';
import Utilities from '../../utils/Utilities';
import classNames from 'classnames';
import _ from 'lodash';

@inject(
  'CurrentUserStore',
  'ToastStore',
  'AppStore',
  'ItemStore',
  'ItemActions',
  'UserProfileStore',
  'UserProfileTemplateStore',
  'TemplateActions'
)
@observer
export default class FormElementGrid extends React.Component {
  @observable
  store = '';
  @observable
  actions = '';
  @observable
  intersectionValues = [];

  componentDidMount() {
    this.setStoreAndActions();
    _.map(this.props.element.elementGrid.elementGridColumns, column => {
      _.map(this.props.element.elementGrid.elementGridRows, row => {
        const findValue = _.find(
          this.props.element.formValues,
          formValue => formValue.elementGridColumnId === column.id && formValue.elementGridRowId === row.id
        );
        if (findValue) {
          this.intersectionValues = this.intersectionValues.concat(findValue);
        } else {
          const tempValue = {
            id: Utilities.makeId(),
            elementGridRowId: row.id,
            elementGridColumnId: column.id,
            booleanValue: false,
            temp: true,
          };
          this.intersectionValues = this.intersectionValues.concat(tempValue);
        }
      });
    });
    this.mountDisplayValuesInFlight();
    this.props.validateInput(this.intersectionValues);
  }

  mountDisplayValuesInFlight() {
    _.map(this.store.formValuesToCreate, formValue => {
      if (formValue.element_id === this.props.element.id) {
        if (formValue.boolean_value) {
          _.map(this.intersectionValues, o => {
            if (o.elementGridRowId === formValue.element_grid_row_id) {
              if (o.elementGridColumnId === formValue.element_grid_column_id) {
                o.booleanValue = true;
              } else {
                o.booleanValue = false;
              }
            }
          });
        }
      }
    });
  }

  setStoreAndActions() {
    this.newId = this.props.element.id;
    switch (this.props.storeType) {
      case 'profile':
        this.store = this.props.UserProfileTemplateStore;
        this.actions = this.props.TemplateActions;
        break;
      default:
        this.store = this.props.ItemStore;
        this.actions = this.props.ItemActions;
        break;
    }
  }

  appendAttributes(object) {
    switch (this.props.storeType) {
      case 'profile':
        object.user_id = this.props.UserProfileStore.user.id;
        object.profile_template_id = this.props.UserProfileTemplateStore.activeProfileTemplate.id;
        object.profile_value_type = 'boolean';
        return object;
      default:
        object.item_id = this.store.item.id;
        object.item_value_type = 'boolean';
        return object;
    }
  }

  handleRadioChange(intersectionValue) {
    intersectionValue.booleanValue = !intersectionValue.booleanValue;
    const findFormValue = _.find(
      this.props.element.formValues,
      o => o.elementGridColumnId === intersectionValue.elementGridColumnId && o.elementGridRowId === intersectionValue.elementGridRowId
    );
    if (findFormValue) {
      if (intersectionValue.booleanValue == true) {
        const changedFormValue = {
          id: findFormValue.id,
          boolean_value: true,
        };
        this.store.addFormValueToChange(changedFormValue);
      } else {
        const deleteFormValue = {
          id: findFormValue.id,
        };
        this.store.addFormValueToDelete(deleteFormValue);
      }
    } else {
      if (intersectionValue.booleanValue == true) {
        let newFormValue = {
          id: Utilities.makeId(),
          element_id: this.props.element.id,
          element_grid_column_id: intersectionValue.elementGridColumnId,
          element_grid_row_id: intersectionValue.elementGridRowId,
          boolean_value: true,
        };
        newFormValue = this.appendAttributes(newFormValue);
        this.store.addFormValueToCreate(newFormValue);
      }
    }
    // set other profile values to false for that row
    _.map(this.props.element.formValues, formValue => {
      if (formValue.elementGridRowId === intersectionValue.elementGridRowId && formValue.booleanValue) {
        if (formValue.id !== intersectionValue.id) {
          const deleteFormValue = {
            id: formValue.id,
          };
          this.store.addFormValueToDelete(deleteFormValue);
        }
      }
    });
    this.store.formValuesToCreate = _.filter(this.store.formValuesToCreate, o => {
      if (o.element_grid_row_id === intersectionValue.elementGridRowId) {
        if (o.element_grid_column_id === intersectionValue.elementGridColumnId) {
          if (intersectionValue.booleanValue == true) {
            return true;
          }
          return false;
        }
        return false;
      }
      return true;
    });
    _.map(this.intersectionValues, o => {
      if (o.elementGridRowId === intersectionValue.elementGridRowId && o.booleanValue) {
        if (o.id !== intersectionValue.id) {
          o.booleanValue = false;
        }
      }
    });
    this.props.validateInput(this.intersectionValues);
  }

  handleCheckChange(intersectionValue) {
    intersectionValue.booleanValue = !intersectionValue.booleanValue;
    const findFormValue = _.find(
      this.props.element.formValues,
      o => o.elementGridColumnId === intersectionValue.elementGridColumnId && o.elementGridRowId === intersectionValue.elementGridRowId
    );
    if (findFormValue) {
      const changedFormValue = {
        id: findFormValue.id,
        boolean_value: intersectionValue.booleanValue,
      };
      this.store.addFormValueToChange(changedFormValue);
    } else {
      const findFormValueToCreate = _.find(
        this.store.formValuesToCreate,
        o =>
          o.element_grid_column_id === intersectionValue.elementGridColumnId && o.element_grid_row_id === intersectionValue.elementGridRowId
      );
      if (findFormValueToCreate) {
        this.store.formValuesToCreate = _.filter(this.store.formValuesToCreate, o => o.id != findFormValueToCreate.id);
      }
      if (!findFormValueToCreate && intersectionValue.booleanValue == true) {
        const newFormValue = {
          id: Utilities.makeId(),
          item_id: this.store.item.id,
          element_id: this.props.element.id,
          element_grid_column_id: intersectionValue.elementGridColumnId,
          element_grid_row_id: intersectionValue.elementGridRowId,
          item_value_type: 'boolean',
          boolean_value: intersectionValue.booleanValue,
        };
        this.store.addFormValueToCreate(newFormValue);
      }
    }
    this.props.validateInput(this.intersectionValues);
  }

  renderDisplay() {
    if (this.props.element.elementGrid.displayType === 'radio') {
      return (
        <tbody>
          {_.map(this.props.element.elementGrid.elementGridRows, row => (
            <tr key={row.id}>
              <td>{row.name}</td>
              {_.map(this.props.element.elementGrid.elementGridColumns, column => {
                const foundIntersectionValue = this.intersectionValues.find(
                  o => o.elementGridRowId === row.id && o.elementGridColumnId === column.id
                );
                if (this.props.element.isEditable) {
                  return (
                    <td key={column.id} className="grid-td">
                      <Flexbox justifyContent="center">
                        <Radio
                          checked={foundIntersectionValue ? foundIntersectionValue.booleanValue : false}
                          onChange={() => null}
                          onClick={() => this.handleRadioChange(foundIntersectionValue)}
                        />
                      </Flexbox>
                    </td>
                  );
                }
                if (foundIntersectionValue) {
                  if (foundIntersectionValue.booleanValue) {
                    return (
                      <td key={column.id} className="grid-td">
                        <Flexbox justifyContent="center">
                          <span className="bp3-icon bp3-icon-full-circle bp3-intent-primary" />
                        </Flexbox>
                      </td>
                    );
                  }
                  return (
                    <td key={column.id} className="grid-td">
                      <Flexbox justifyContent="center" />
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      );
    }
    return (
      <tbody>
        {_.map(this.props.element.elementGrid.elementGridRows, row => (
          <tr key={row.id}>
            <td>{row.name}</td>
            {_.map(this.props.element.elementGrid.elementGridColumns, column => {
              const foundIntersectionValue = _.find(
                this.intersectionValues,
                o => o.elementGridRowId === row.id && o.elementGridColumnId === column.id
              );
              if (this.props.element.isEditable) {
                return (
                  <td key={column.id} className="grid-td">
                    <Flexbox justifyContent="center">
                      <Checkbox
                        checked={foundIntersectionValue ? foundIntersectionValue.booleanValue : false}
                        onChange={() => this.handleCheckChange(foundIntersectionValue)}
                        disabled={!this.props.element.isEditable}
                      />
                    </Flexbox>
                  </td>
                );
              }
              if (foundIntersectionValue) {
                const { booleanValue } = foundIntersectionValue;
                if (booleanValue) {
                  return (
                    <td key={column.id} className="grid-td">
                      <Flexbox justifyContent="center">
                        <span className="bp3-icon bp3-icon-tick bp3-intent-primary" />
                      </Flexbox>
                    </td>
                  );
                }
              }
              return (
                <td key={column.id} className="grid-td">
                  <Flexbox justifyContent="center" />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  }

  renderGrid() {
    const tableClass = classNames('bp3-html-table bp3-html-table-striped bp3-small full-table');
    return (
      <div className="bp3-form-content">
        <Flexbox flexDirection="column" flexGrow={1}>
          <table className={tableClass}>
            <thead>
              <tr>
                <th className="td-width-30" />
                {_.map(this.props.element.elementGrid.elementGridColumns, column => (
                  <th key={column.id} className="td-width-10 grid-th">
                    <small>{column.name}</small>
                  </th>
                ))}
              </tr>
            </thead>
            {this.renderDisplay()}
          </table>
        </Flexbox>
      </div>
    );
  }

  renderHelperText() {
    if (this.props.showError) {
      return (
        <Flexbox flexDirection="column">
          {_.map(this.props.errors, error => (
            <span key={error.errorMessage}>{error.errorMessage}</span>
          ))}
        </Flexbox>
      );
    }
    return undefined;
  }

  renderPrivacyTag() {
    if (this.props.element.privateView && !this.props.sectionPrivateView) {
      return <ElementTag tagType="private" tagIcon="eye-on" tagTooltip={I18n.t('js.private_element_description')} />;
    }
  }

  renderProtectedTag() {
    if (this.props.element.protectedView && !this.props.sectionProtectedView) {
      return <ElementTag tagType="protected" tagIcon="lock" tagTooltip={I18n.t('js.protected_element_description')} />;
    }
  }

  renderLabel(name) {
    return (
      <label>
        <Flexbox flexDirection="row" alignItems="center" alignContent="center" flexGrow={0}>
          <span className="push-10-r">{name}</span>
          {this.props.element.isRequired ? <span className="bp3-text-muted">{`(${I18n.t('js.required')})`}</span> : null}
          {this.renderPrivacyTag()}
          {this.renderProtectedTag()}
        </Flexbox>
      </label>
    );
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <FormGroup
          label={this.renderLabel(this.props.element.elementGrid.name)}
          labelFor={this.props.element.id}
          intent={this.props.showError ? Intent.DANGER : null}
          helperText={this.renderHelperText()}
        >
          {this.renderGrid()}
        </FormGroup>
      </Flexbox>
    );
  }
}
