// @flow
import { computed } from 'mobx';
import moment from 'moment';
import User from './UserModel';

export default class ReportModel {
  id: string;
  name: string;
  reportTemplateId: string;
  userId: string;
  data: string;
  csvUrl: string;
  xlsUrl: string;
  status: string;
  chartConfiguration: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
  user: User;

  // $FlowFixMe
  constructor({
    id,
    name,
    report_template_id,
    user_id,
    data,
    csv_url,
    xls_url,
    status,
    chart_configuration,
    deleted_at,
    created_by,
    updated_by,
    deleted_by,
    updated_at,
    created_at,
    user,
  }) {
    this.id = id;
    this.name = name;
    this.reportTemplateId = report_template_id;
    this.userId = user_id;
    this.data = data;
    this.csvUrl = csv_url;
    this.xlsUrl = xls_url;
    this.status = status;
    this.chartConfiguration = chart_configuration === '' ? '' : JSON.parse(chart_configuration);
    this.createdAt = created_at;
    this.updatedAt = updated_at;
    this.deletedAt = deleted_at;
    this.createdBy = created_by;
    this.updatedBy = updated_by;
    this.deletedBy = deleted_by;
    this.user = new User(
      user.id,
      user.email,
      user.first_name,
      user.last_name,
      user.full_name,
      user.account_id,
      user.human_friendly_id,
      user.is_current_user,
      user.is_account_owner,
      user.image_file_name,
      user.background_image_file_name,
      user.last_sign_in_at,
      user.locale,
      user.theme,
      user.created_at,
      user.updated_at,
      user.deleted_at,
      user.created_by,
      user.updated_by,
      user.deleted_b
    );
  }

  @computed
  get translatedStatus() {
    switch (this.status) {
      case 'generating_data':
        // $FlowFixMe
        return I18n.t('js.running');
      case 'complete':
        // $FlowFixMe
        return I18n.t('js.complete');
      case 'failed':
        // $FlowFixMe
        return I18n.t('js.failed');
      default:
        return this.status;
    }
  }

  @computed
  get createdAtToDate() {
    return moment(this.createdAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get createdAtToDateShort() {
    return moment.utc(this.createdAt).format('DD/MM/YYYY');
  }

  @computed
  get deletedAtToDate() {
    return moment(this.deletedAt).format('MMMM Do YYYY, h:mm a');
  }

  @computed
  get updatedAtToDate() {
    return moment(this.updatedAt).format('MMMM Do YYYY, h:mm a');
  }
}
