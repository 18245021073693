import { action, computed, observable } from 'mobx';
import _ from 'lodash';
import Cookies from 'js-cookie';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import nanoid from 'nanoid';
import Team from '../models/TeamModel';
import User from '../models/UserModel';
import UserTeam from '../models/UserTeamModel';
import ToastStore from './ToastStore';
import TeamsStore from './TeamsStore';
import CurrentUserStore from './CurrentUserStore';
import ColumnDefinitions from '../static/ColumnDefinitions';
import Paginator from '../models/PaginatorModel';
import Utilities from '../utils/Utilities';

axiosCancel(axios);

class TeamUsersStore {
  @observable
  activeTeam = new Team();
  @observable
  activeRequest = nanoid();
  @observable
  searchUsers = [];
  @observable
  userTeams = [];
  @observable
  newUserTeams = [];
  @observable
  pagination = new Paginator();
  @observable
  userTeamColumns = _.clone(ColumnDefinitions.userTeamColumns);
  @observable
  newUserTeamColumns = _.clone(ColumnDefinitions.newUserTeamColumns);

  @action
  createPaginator(headers) {
    const newPagination = new Paginator(
      headers['x-next-page'],
      headers['x-offset'],
      headers['x-page'],
      headers['x-per-page'],
      headers['x-prev-page'],
      headers['x-total'],
      headers['x-total-pages']
    );
    this.pagination = newPagination;
  }

  @action
  addTeam(data) {
    const newTeam = new Team(
      data.id,
      data.account_id,
      data.is_default_account,
      data.name,
      data.description,
      data.image_file_name,
      data.background_image_file_name,
      data.private,
      data.is_current_user_team_owner,
      data.is_current_user_team_member,
      data.row_order,
      data.user_count,
      data.comment_count,
      data.import_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    this.activeTeam = _.clone(newTeam);
  }

  @action
  addUserTeam(data) {
    const newUserTeam = new UserTeam(
      data.id,
      data.team_id,
      data.user_id,
      data.is_primary,
      data.is_owner,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    const newUser = new User(
      data.user.id,
      data.user.email,
      data.user.first_name,
      data.user.last_name,
      data.user.full_name,
      data.user.account_id,
      data.user.human_friendly_id,
      data.user.is_current_user,
      data.user.is_account_owner,
      data.user.image_file_name,
      data.user.background_image_file_name,
      data.user.last_sign_in_at,
      data.user.locale,
      data.user.theme,
      data.user.created_at,
      data.user.updated_at,
      data.user.deleted_at,
      data.user.created_by,
      data.user.updated_by,
      data.user.deleted_by
    );

    newUserTeam.user = newUser;
    this.userTeams = _.unionBy(this.userTeams, [newUserTeam], 'id');
    this.sortUserTeams();
  }

  @action
  removeUserTeam(data) {
    this.userTeams = _.filter(this.userTeams, o => o.id !== data.id);
  }

  @action
  addNewUserTeam(user) {
    const newUserTeam = new UserTeam(Utilities.makeId(), TeamsStore.activeTeam.id, user.id);
    newUserTeam.user = user;
    this.newUserTeams = _.concat(this.newUserTeams.slice(), newUserTeam);
    this.newUserTeams = _.unionBy(this.newUserTeams, [newUserTeam], 'userId');
  }

  @action
  removeNewUserTeam(userTeam) {
    this.newUserTeams = _.filter(this.newUserTeams, o => o.id !== userTeam.id);
  }

  @action
  fetchTeam(teamId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/teams/${teamId}`)
        .then(response => {
          this.addTeam(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  addSearchUser(data) {
    const newSearchUser = new User(
      data.id,
      data.email,
      data.first_name,
      data.last_name,
      data.full_name,
      data.account_id,
      data.human_friendly_id,
      data.is_current_user,
      data.is_account_owner,
      data.image_file_name,
      data.background_image_file_name,
      data.last_sign_in_at,
      data.locale,
      data.theme,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.created_by,
      data.updated_by,
      data.deleted_by
    );

    this.searchUsers = _.unionBy([newSearchUser], this.searchUsers, 'id');
    this.sortSearchUsers();
  }

  @action
  fetchUsersQuery(query, requestId) {
    return new Promise((resolve, reject) => {
      this.activeRequest = requestId;
      axios
        .get(`${Cookies.get('apiEnv')}/users?query=${query}`, {
          requestId: 'userSearch',
        })
        .then(response => {
          let total = response.data.length;
          _.map(response.data, user => {
            if (this.activeRequest === requestId) {
              this.addSearchUser(user);
            }
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
            reject(error);
          }
        });
    });
  }

  @action
  resetSearchUsers() {
    return new Promise(resolve => {
      this.searchUsers = [];
      resolve();
    });
  }

  @action
  fetchUserTeams(teamId, xPage, xPerPage, xOffset) {
    this.userTeams = [];
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/user_teams?team_id=${teamId}&type=show_user&page=${xPage}&per_page=${xPerPage}&offset=${xOffset}`)
        .then(response => {
          this.createPaginator(response.headers);
          _.map(response.data, userTeam => {
            this.addUserTeam(userTeam);
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createUserTeam(userId, teamId, isPrimary, isOwner) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/user_teams/`, {
          user_id: userId,
          team_id: teamId,
          is_primary: isPrimary,
          is_owner: isOwner,
        })
        .then(response => {
          this.addUserTeam(response.data);
          this.pagination.xTotal++;
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeUserTeam(userTeamObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/user_teams/${userTeamObj.id}`, userTeamObj)
        .then(response => {
          this.addUserTeam(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteUserTeam(userTeamId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/user_teams/${userTeamId}`)
        .then(response => {
          this.removeUserTeam(response.data);
          ToastStore.showToast(I18n.t('js.user_removed_from_team'), 'success');
          resolve(response);
          this.pagination.xTotal--;
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteUserTeamCurrentUser(teamId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/user_teams/${Utilities.makeId()}?team_id=${teamId}&`)
        .then(response => {
          this.removeUserTeam(response.data);
          this.pagination.xTotal--;
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  sortSearchUsers() {
    let newSearchUsers = this.searchUsers;
    newSearchUsers = _.orderBy(newSearchUsers, ['firstName'], ['asc']);
    this.searchUsers = newSearchUsers;
  }

  @action
  setNewUserTeamSortParams(param) {
    const newNewUserTeamColumns = this.newUserTeamColumns;
    _.map(newNewUserTeamColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.newUserTeamColumns = newNewUserTeamColumns;
    this.sortNewUserTeams();
  }

  @action
  sortNewUserTeams() {
    let newNewUserTeams = this.newUserTeams;
    const activeColumn = _.find(this.newUserTeamColumns, o => o.active);
    if (activeColumn.id === 'fullName') {
      newNewUserTeams = _.orderBy(newNewUserTeams, e => e.user.fullName, [activeColumn.sortDir]);
    } else {
      newNewUserTeams = _.orderBy(newNewUserTeams, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.newUserTeams = newNewUserTeams;
  }

  @action
  setUserTeamSortParams(param) {
    const newUserTeamColumns = this.userTeamColumns;
    _.map(newUserTeamColumns, column => {
      if (column.id === param) {
        column.active = true;
        if (column.sortDir === 'asc') {
          column.sortDir = 'desc';
        } else {
          column.sortDir = 'asc';
        }
      } else {
        column.active = false;
      }
    });
    this.userTeamColumns = newUserTeamColumns;
    this.sortUserTeams();
  }

  @action
  sortUserTeams() {
    let newUserTeams = this.userTeams;
    const activeColumn = _.find(this.userTeamColumns, o => o.active);
    if (activeColumn.id === 'fullName') {
      newUserTeams = _.orderBy(newUserTeams, e => e.user.fullName, [activeColumn.sortDir]);
    } else {
      newUserTeams = _.orderBy(newUserTeams, [activeColumn.id], [activeColumn.sortDir]);
    }
    this.userTeams = newUserTeams;
  }

  @action
  resetStartingAttributes() {
    this.searchUsers = [];
    this.userTeams = [];
    this.newUserTeams = [];
  }
}

const store = new TeamUsersStore();
export default store;
