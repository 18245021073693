import { action, computed, observable } from 'mobx';
import ToastStore from './ToastStore';
import Account from '../models/AccountModel';
import Invitation from '../models/InvitationModel';
import Cookies from 'js-cookie';
import AuthorizedAccountRequest from '../models/AuthorizedAccountRequestModel';
import axios from 'axios';

class SetupStore {
  @observable
  newAccount = new Account();
  @observable
  newInvitation = new Invitation();
  @observable
  newRequest = new AuthorizedAccountRequest();
  @observable
  invitations = [];
  @observable
  activeSetup = 'none';
  @observable
  activeStep = 1;
  @observable
  // eslint-disable-next-line no-magic-numbers
  totalSteps = 4;

  @action
  resetNewAccount() {
    this.newAccount = new Account();
  }

  @action
  setActiveStep(step) {
    this.activeStep = step;
  }

  @action
  setActiveSetup(setup) {
    this.activeSetup = setup;
  }

  @action
  addInvitation(data) {
    const newInvitation = new Invitation(
      data.id,
      data.accepted,
      data.account_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.email,
      data.updated_at,
      data.updated_by,
      data.user_id
    );

    this.invitations.push(newInvitation);
  }

  @computed
  get step1Valid() {
    return this.newAccount.nameValid && this.newAccount.accountPurposeValid;
  }

  @computed
  get step2Valid() {
    return (
      this.newAccount.ownerRoleValid &&
      this.newAccount.ownerPhoneValid &&
      this.newAccount.accountSizeValid &&
      this.newAccount.implementationTimeValid
    );
  }

  @action
  updateNewAccount(data, attribute) {
    let newData = data;
    if (attribute === 'subdomain') {
      // lazy fix for legacy code
      // eslint-disable-next-line no-magic-numbers
      if (newData.length > 50) {
        // eslint-disable-next-line no-magic-numbers
        newData = newData.substring(0, 50);
      }
      newData = newData.replace(/[^a-zA-Z0-9-_]/gu, '').toLowerCase();
    }
    this.newAccount[attribute] = newData;
  }

  @action
  updateNewInvitation(data, attribute) {
    this.newInvitation[attribute] = data;
  }

  @action
  updateNewRequest(data, attribute) {
    this.newRequest[attribute] = data;
  }

  @action
  createAccount() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/accounts`, {
          name: this.newAccount.name,
          subdomain: this.newAccount.subdomain,
          account_purpose: this.newAccount.accountPurpose,
          account_purpose_other: this.newAccount.accountPurposeOther,
          owner_role: this.newAccount.ownerRole,
          owner_phone: this.newAccount.ownerPhone,
          account_size: this.newAccount.accountSize,
          implementation_time: this.newAccount.implementationTime,
          referral_source: this.newAccount.referralSource,
          referral_source_other: this.newAccount.referralSourceOther,
        })
        .then(response => {
          resolve(response);
          ToastStore.showToast(I18n.t('js.account_created'), 'success');
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  createInvitation() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/invitations`, {
          email: this.newInvitation.email,
        })
        .then(response => {
          resolve(response);
          ToastStore.showToast(
            I18n.t('js.invitation_has_been_sent', {
              email: this.newInvitation.email,
            }),
            'success'
          );
          this.addInvitation(response.data);
          this.newInvitation = new Invitation();
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }

  @action
  createAuthorizedAccountRequest() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/authorized_account_requests`, { subdomain: this.newRequest.accountSubdomain })
        .then(response => {
          resolve(response);
          ToastStore.showToast(I18n.t('js.request_has_been_sent'), 'success');
          this.newRequest = new AuthorizedAccountRequest();
        })
        .catch(error => {
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
          reject(error);
        });
    });
  }
}

const store = new SetupStore();
export default store;
