import { observable } from 'mobx';
export default class Paginator {
  @observable
  xNextPage;
  @observable
  xOffset;
  @observable
  xPage;
  @observable
  xPerPage;
  @observable
  xPrevPage;
  @observable
  xTotal;
  @observable
  xTotalPages;
  constructor(
    xNextPage = 0,
    xOffset = 0,
    xPage = 1,
    xPerPage = 25,
    xPrevPage = 0,
    xTotal = 0,
    xTotalPages = 0
  ) {
    this.xNextPage = parseInt(xNextPage);
    this.xOffset = parseInt(xOffset);
    this.xPage = parseInt(xPage);
    this.xPerPage = parseInt(xPerPage);
    this.xPrevPage = parseInt(xPrevPage);
    this.xTotal = parseInt(xTotal);
    this.xTotalPages = parseInt(xTotalPages);
  }
}
