import React from 'react';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import { InputGroup } from '@blueprintjs/core';
import _ from 'lodash';

@inject('AppStore', 'AppItemStore', 'ItemActions', 'CurrentUserStore', 'ToastStore')
@observer
export default class AppItemsFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { query: '', isQuerying: false, requestId: '' };
  }

  toggleQuerying() {
    this.setState({
      isQuerying: !this.state.isQuerying,
    });
  }

  handleSearchChange(e) {
    const query = e.target.value;
    this.setState({ query });
    if (query.length === 0) {
      this.handleCancel();
    }
    if (query.length > 1) {
      this.toggleQuerying();
      this.props.toggleSearching();
      this.props.ItemActions.searchAppItems(this.props.AppStore.activeApp.id, query)
        .then(response => {
          this.props.AppItemStore.createPaginator(response.headers);
          this.props.AppItemStore.items = [];
          _.map(response.data, item => {
            this.props.AppItemStore.addItem(item);
          });
          this.toggleQuerying();
          this.props.toggleSearching();
        })
        .catch(() => {
          this.toggleQuerying();
          this.props.toggleSearching();
        });
    }
  }

  handleCancel() {
    this.setState({ query: '' });
    this.toggleQuerying();
    this.props.toggleSearching();
    this.props.AppItemStore.items = [];
    const page = 1;
    const perPage = 25;
    const offset = 0;
    this.props.ItemActions.fetchAppItems(this.props.AppStore.activeApp.id, page, perPage, offset, null)
      .then(response => {
        this.props.AppItemStore.createPaginator(response.headers);
        _.map(response.data, item => {
          this.props.AppItemStore.addItem(item);
        });
        this.props.toggleSearching();
        this.toggleQuerying();
      })
      .catch(() => {
        this.props.toggleSearching();
        this.toggleQuerying();
      });
  }

  renderQuerying() {
    if (this.state.query.length > 0) {
      return <button type="button" className="bp3-button bp3-icon-cross bp3-minimal" onClick={this.handleCancel.bind(this)} />;
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="row" flexGrow={1}>
        <InputGroup
          autoComplete="off"
          large={false}
          className="max-width"
          value={this.state.query}
          placeholder={I18n.t('js.search_items_name', {
            name: this.props.AppStore.activeApp.itemPlural.toLowerCase(),
          })}
          onChange={this.handleSearchChange.bind(this)}
          leftIcon="search"
          rightElement={this.renderQuerying()}
        />
      </Flexbox>
    );
  }
}
