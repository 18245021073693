/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import { action } from 'mobx';
import Cookies from 'js-cookie';
import axios from 'axios';
import _ from 'lodash';
import ToastStore from '../stores/ToastStore';
import TemplateStore from '../stores/TemplateStore';
import UserProfileTemplateStore from '../stores/UserProfileTemplateStore';

class TemplateActions {
  @action
  fetchParticipants(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/participants?app_id=${appId}`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, participant => {
            TemplateStore.addParticipant(participant, 'all');
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchRelationships() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/relationships`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, relationship => {
            TemplateStore.addRelationship(relationship, 'all');
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchProfileTemplates() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/profile_templates`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchProfileTemplate(profileTemplateId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/profile_templates/${profileTemplateId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchProfileValues(userId, profileTemplateId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/profile_values?user_id=${userId}&profile_template_id=${profileTemplateId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchSections(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/sections?app_id=${appId}&type=full&`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, section => {
            if (section.section_type === 'app') {
              TemplateStore.addSection(section, 'all');
            } else {
              UserProfileTemplateStore.addSection(section, 'all');
            }
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          console.log(error);
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchStates(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/states?app_id=${appId}&`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, state => {
            TemplateStore.addState(state, 'all', false);
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  fetchStatusFlags(appId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Cookies.get('apiEnv')}/status_flags?app_id=${appId}&`)
        .then(response => {
          let total = response.data.length;
          _.map(response.data, status => {
            TemplateStore.addStatusFlag(status);
            total--;
          });
          if (total === 0) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createProfileTemplate(profileTemplateObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/profile_templates`, profileTemplateObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createSection(sectionObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/sections`, sectionObj)
        .then(response => {
          TemplateStore.addSection(response.data, 'all');
          UserProfileTemplateStore.addSection(response.data, 'all');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  copySection(sectionId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/sections/${sectionId}/copy`)
        .then(response => {
          TemplateStore.addSection(response.data, 'all');
          UserProfileTemplateStore.addSection(response.data, 'all');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  copyProfileTemplate(profileTemplateId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/profile_templates/${profileTemplateId}/copy`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createParticipant(participantObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/participants`, participantObj)
        .then(response => {
          TemplateStore.addParticipant(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createState(stateObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/states`, stateObj)
        .then(response => {
          TemplateStore.addState(response.data, 'single', true);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createStatusFlag(statusFlagObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/status_flags`, statusFlagObj)
        .then(response => {
          TemplateStore.addStatusFlag(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeProfileTemplate(profileTemplateObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/profile_templates/${profileTemplateObj.id}`, profileTemplateObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeSection(sectionObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/sections/${sectionObj.id}`, sectionObj)
        .then(response => {
          TemplateStore.addSection(response.data, 'all');
          UserProfileTemplateStore.addSection(response.data, 'all');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeParticipant(participantObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/participants/${participantObj.id}`, participantObj)
        .then(response => {
          TemplateStore.addParticipant(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeState(stateObj, doAnim) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/states/${stateObj.id}`, stateObj)
        .then(response => {
          TemplateStore.addState(response.data, 'single', doAnim);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStatusFlag(statusFlagObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/status_flags/${statusFlagObj.id}`, statusFlagObj)
        .then(response => {
          TemplateStore.addStatusFlag(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteProfileTemplate(profileTemplateId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/profile_templates/${profileTemplateId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteSection(sectionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/sections/${sectionId}`)
        .then(response => {
          TemplateStore.removeSection(response.data, 'all');
          UserProfileTemplateStore.removeSection(response.data, 'all');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteParticipant(participantId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/participants/${participantId}`)
        .then(response => {
          TemplateStore.removeParticipant(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteState(stateId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/states/${stateId}`)
        .then(response => {
          TemplateStore.removeState(response.data, 'single');
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteStatusFlag(statusFlagId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/status_flags/${statusFlagId}`)
        .then(response => {
          TemplateStore.removeStatusFlag(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createElement(elementObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/elements`, elementObj)
        .then(response => {
          TemplateStore.addElement(response.data);
          UserProfileTemplateStore.addElement(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  copyElement(elementId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/elements/${elementId}/copy`)
        .then(response => {
          TemplateStore.addElement(response.data);
          TemplateStore.setActiveArea(response.data.id);
          UserProfileTemplateStore.addElement(response.data);
          UserProfileTemplateStore.setActiveArea(response.data.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createElementSelectOption(elementSelectOptionObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/element_select_options`, elementSelectOptionObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createElementMultilineInput(elementMultilineInputObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/element_multiline_inputs`, elementMultilineInputObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createElementGridColumn(elementGridColumnObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/element_grid_columns`, elementGridColumnObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createElementGridRow(elementGridRowObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/element_grid_rows`, elementGridRowObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeElement(elementObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/elements/${elementObj.id}`, elementObj)
        .then(response => {
          TemplateStore.addElement(response.data);
          UserProfileTemplateStore.addElement(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeSubElement(subElementObj, apiPath) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/${apiPath}/${subElementObj.id}`, subElementObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeElementImage(id, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/element_images/${id}`, formData)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeElementFileDownload(id, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/element_file_downloads/${id}`, formData)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeElementSelectOption(elementSelectOptionObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/element_select_options/${elementSelectOptionObj.id}`, elementSelectOptionObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeElementMultilineInput(elementMultilineInputObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/element_multiline_inputs/${elementMultilineInputObj.id}`, elementMultilineInputObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeElementGridColumn(elementGridColumnObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/element_grid_columns/${elementGridColumnObj.id}`, elementGridColumnObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeElementGridRow(elementGridRowObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/element_grid_rows/${elementGridRowObj.id}`, elementGridRowObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteElement(elementId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/elements/${elementId}`)
        .then(response => {
          resolve(response);
          TemplateStore.removeElement(response.data);
          UserProfileTemplateStore.removeElement(response.data);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteElementSelectOption(elementSelectOptionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/element_select_options/${elementSelectOptionId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteElementMultilineInput(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/element_multiline_inputs/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteElementGridColumn(elementGridColumnId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/element_grid_columns/${elementGridColumnId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteElementGridRow(elementGridRowId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/element_grid_rows/${elementGridRowId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStateElement(stateElementObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/state_elements/${stateElementObj.id}`, stateElementObj)
        .then(response => {
          TemplateStore.addStateElement(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeStateSection(stateSectionObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/state_sections/${stateSectionObj.id}`, stateSectionObj)
        .then(response => {
          TemplateStore.addStateSection(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createElementObjectSelectItemApp(itemAppObj) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/element_object_select_item_apps`, itemAppObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteElementObjectSelectItemApp(itemAppId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/element_object_select_item_apps/${itemAppId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  createProfileValue(profileValueObj) {
    if (!profileValueObj.row_order_position) {
      profileValueObj.row_order_position = 0;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/profile_values`, profileValueObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  changeProfileValue(profileValueObj) {
    if (typeof profileValueObj.attachment === 'object' || typeof profileValueObj.image === 'object') {
      const formData = new FormData();
      if (typeof profileValueObj.attachment === 'object') {
        formData.append('attachment', profileValueObj.attachment);
      }
      if (typeof profileValueObj.image === 'object') {
        formData.append('image', profileValueObj.image);
      }
      return new Promise((resolve, reject) => {
        axios
          .put(`${Cookies.get('apiEnv')}/profile_values/${profileValueObj.id}`, formData)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
            const errors = error.response.data.error.join(', ');
            ToastStore.showToast(errors, 'danger');
          });
      });
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`${Cookies.get('apiEnv')}/profile_values/${profileValueObj.id}`, profileValueObj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }

  @action
  deleteProfileValue(profileValueId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Cookies.get('apiEnv')}/profile_values/${profileValueId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          const errors = error.response.data.error.join(', ');
          ToastStore.showToast(errors, 'danger');
        });
    });
  }
}

const store = new TemplateActions();
export default store;
