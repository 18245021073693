import React from 'react';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import { Dialog, AnchorButton, Alignment } from '@blueprintjs/core';
import Avatar from 'react-avatar';
import _ from 'lodash';
import DynamicUserView from '../dynamic_view/DynamicUserView';

@inject(
  'AppStore',
  'DashboardStore',
  'UserProfileTemplateStore',
  'UserProfileStore',
  'TemplateActions',
  'ItemActions',
  'CurrentUserStore',
  'ToastStore',
  'DynamicViewStore'
)
@observer
export default class DashboardUserItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  handleAppChange(e) {
    if (this.props.DynamicViewStore.activeApp === null) {
      const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
      this.props.DynamicViewStore.setActiveApp(app);
    } else {
      if (this.props.DynamicViewStore.activeApp.id !== e.target.value) {
        const app = _.find(this.props.AppStore.apps, o => o.id === e.target.value);
        this.props.DynamicViewStore.setActiveApp(app);
      }
    }
  }

  handleParticipantChange(e) {
    const participant = _.find(this.props.DynamicViewStore.participants, o => o.id === e.target.value);
    this.props.DynamicViewStore.setActiveParticipant(participant);
  }

  handleStatusFlagChange(e) {
    const statusFlag = _.find(this.props.DynamicViewStore.statusFlags, o => o.id === e.target.value);
    this.props.DynamicViewStore.setActiveStatusFlag(statusFlag);
  }

  handleUserClick() {
    this.props.DynamicViewStore.items = [];
    this.props.DynamicViewStore.isLoadingItemsAsOwner = true;
    this.props.DynamicViewStore.setFilteredUser(this.props.user);
    this.setState({ dialogOpen: true });
  }

  closeDialog() {
    this.setState({ dialogOpen: false });
  }

  render() {
    const { user } = this.props;
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <AnchorButton
          minimal={true}
          small={true}
          onClick={this.handleUserClick.bind(this)}
          alignText={Alignment.LEFT}
          key={user.id}
          className="animated fadeIn"
        >
          <Flexbox flexDirection="row" key={user.id} className="bp3-cursor-pointer" padding="5px">
            <Flexbox marginRight="10px">
              <Avatar src={user.imageFileName} round={true} size={40} />
            </Flexbox>
            <Flexbox flexDirection="column">
              <h5 className="push-10-l bp3-label-no-margin">{user.fullName}</h5>
              <small className="bp3-text-muted">{user.email}</small>
            </Flexbox>
          </Flexbox>
        </AnchorButton>
        <Dialog
          portalContainer={document.body}
          isOpen={this.state.dialogOpen}
          onClose={this.closeDialog.bind(this)}
          title={I18n.t('js.items_for_user', { user: user.fullName })}
          icon={<Avatar src={user.imageFileName} round={true} size={20} className="push-10-r" />}
          className=""
          inline={false}
          style={{ width: '70%' }}
        >
          <Flexbox className="bp3-dialog-body" flexGrow={1} flexDirection="column">
            <DynamicUserView
              user={this.props.user}
              allowAppSelect={true}
              allowParticipantSelect={false}
              allowStatusFlagSelect={false}
              allowMultiSelect={false}
              handleAppChange={this.handleAppChange.bind(this)}
              handleParticipantChange={this.handleParticipantChange.bind(this)}
              handleStatusFlagChange={this.handleStatusFlagChange.bind(this)}
            />
          </Flexbox>
        </Dialog>
      </Flexbox>
    );
  }
}
