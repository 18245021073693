/* Legacy code - ignore complexity errors */
/* eslint complexity: 0 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import Flexbox from 'flexbox-react';
import classNames from 'classnames';
import {
  AnchorButton,
  Collapse,
  HTMLSelect,
} from '@blueprintjs/core';
import CountryDefinitions from '../../static/CountryDefinitions';
import _ from 'lodash';

@inject('AccountBillingStore', 'AccountBillingActions', 'ToastStore')
@observer
export default class AccountBillingAddressSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isBlurred: [], addingDiscount: false };
  }

  toggleDiscountCode() {
    this.setState({ addingDiscount: !this.state.addingDiscount });
  }

  handleInputChange(e) {
    this.props.AccountBillingStore.updateNewSubscription(
      e.target.value,
      e.target.name
    );
  }

  handleBlurChange(e) {
    if (!_.includes(this.state.isBlurred, e.target.name)) {
      const newBlurState = this.state.isBlurred;
      newBlurState.push(e.target.name);
      this.setState({ isBlurred: newBlurState });
    }
  }

  renderErrors(field) {
    if (
      _.includes(this.state.isBlurred, field) &&
      !this.props.AccountBillingStore.newSubscription[`${field}Valid`]
    ) {
      return (
        <div className="bp3-form-helper-text">
          {
            this.props.AccountBillingStore.newSubscription[
              `${field}Error`
            ]
          }
        </div>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Flexbox flexDirection="column" flexGrow={1}>
        <Flexbox flexDirection="column">
          <div
            className={
              _.includes(this.state.isBlurred, 'cardHoldersName') &&
              !this.props.AccountBillingStore.newSubscription
                .addressLine1Valid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <div className="bp3-form-content">
              <div
                className={
                  _.includes(
                    this.state.isBlurred,
                    'cardHoldersName'
                  ) &&
                  !this.props.AccountBillingStore.newSubscription
                    .addressLine1Valid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="text"
                  placeholder={I18n.t('js.card_holders_name')}
                  name="cardHoldersName"
                  value={
                    this.props.AccountBillingStore.newSubscription
                      .cardHoldersName
                  }
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </div>
              {this.renderErrors('cardHoldersName')}
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="column">
          <div
            className={
              _.includes(this.state.isBlurred, 'addressLine1') &&
              !this.props.AccountBillingStore.newSubscription
                .addressLine1Valid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <div className="bp3-form-content">
              <div
                className={
                  _.includes(this.state.isBlurred, 'addressLine1') &&
                  !this.props.AccountBillingStore.newSubscription
                    .addressLine1Valid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="text"
                  placeholder={I18n.t('js.address_line_1')}
                  name="addressLine1"
                  value={
                    this.props.AccountBillingStore.newSubscription
                      .addressLine1
                  }
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </div>
              {this.renderErrors('addressLine1')}
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="column">
          <div
            className={
              _.includes(this.state.isBlurred, 'addressLine2') &&
              !this.props.AccountBillingStore.newSubscription
                .addressLine2Valid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <div className="bp3-form-content">
              <div
                className={
                  _.includes(this.state.isBlurred, 'addressLine2') &&
                  !this.props.AccountBillingStore.newSubscription
                    .addressLine2Valid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="text"
                  placeholder={I18n.t('js.address_line_2')}
                  name="addressLine2"
                  value={
                    this.props.AccountBillingStore.newSubscription
                      .addressLine2
                  }
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </div>
              {this.renderErrors('addressLine2')}
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox
            flexDirection="column"
            flexGrow={1}
            paddingRight="10px"
          >
            <div
              className={
                _.includes(this.state.isBlurred, 'city') &&
                !this.props.AccountBillingStore.newSubscription
                  .cityValid
                  ? classNames('bp3-form-group bp3-intent-danger')
                  : classNames('bp3-form-group')
              }
            >
              <div className="bp3-form-content">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'city') &&
                    !this.props.AccountBillingStore.newSubscription
                      .cityValid
                      ? classNames(
                          'bp3-input-group bp3-intent-danger'
                        )
                      : classNames('bp3-input-group')
                  }
                >
                  <input
                    autoComplete="off"
                    className="bp3-input bp3-fill"
                    type="text"
                    placeholder={I18n.t('js.city')}
                    name="city"
                    value={
                      this.props.AccountBillingStore.newSubscription
                        .city
                    }
                    onChange={this.handleInputChange.bind(this)}
                    onBlur={this.handleBlurChange.bind(this)}
                    dir="auto"
                  />
                </div>
                {this.renderErrors('city')}
              </div>
            </div>
          </Flexbox>
          <Flexbox flexDirection="column" flexGrow={1}>
            <div className="bp3-form-group">
              <div className="bp3-form-content">
                <div className={'bp3-input-group'}>
                  <input
                    autoComplete="off"
                    className="bp3-input bp3-fill"
                    type="text"
                    placeholder={I18n.t('js.state_county')}
                    name="stateCounty"
                    value={
                      this.props.AccountBillingStore.newSubscription
                        .stateCounty
                    }
                    onChange={this.handleInputChange.bind(this)}
                    onBlur={this.handleBlurChange.bind(this)}
                    dir="auto"
                  />
                </div>
                {this.renderErrors('stateCounty')}
              </div>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="row">
          <div
            className={
              _.includes(this.state.isBlurred, 'zip') &&
              !this.props.AccountBillingStore.newSubscription.zipValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <div className="bp3-form-content">
              <div
                className={
                  _.includes(this.state.isBlurred, 'zip') &&
                  !this.props.AccountBillingStore.newSubscription
                    .zipValid
                    ? classNames('bp3-input-group bp3-intent-danger')
                    : classNames('bp3-input-group')
                }
              >
                <input
                  autoComplete="off"
                  className="bp3-input"
                  type="text"
                  placeholder={I18n.t('js.zip')}
                  name="zip"
                  value={
                    this.props.AccountBillingStore.newSubscription.zip
                  }
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </div>
              {this.renderErrors('zip')}
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="column" flexGrow={1}>
          <div
            className={
              _.includes(this.state.isBlurred, 'country') &&
              !this.props.AccountBillingStore.newSubscription
                .countryValid
                ? classNames('bp3-form-group bp3-intent-danger')
                : classNames('bp3-form-group')
            }
          >
            <div className="bp3-form-content">
              <div
                className={
                  _.includes(this.state.isBlurred, 'country') &&
                  !this.props.AccountBillingStore.newSubscription
                    .countryValid
                    ? classNames(
                        'bp3-select bp3-fill bp3-intent-danger'
                      )
                    : classNames('bp3-select bp3-fill')
                }
              >
                <HTMLSelect
                  name="country"
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                >
                  <option value="" defaultValue>
                    {I18n.t('js.choose_country')}
                  </option>
                  {_.map(CountryDefinitions.countries, country => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </HTMLSelect>
              </div>
              {this.renderErrors('country')}
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="row" flexGrow={1}>
          <Flexbox flexDirection="column" flexGrow={1}>
            <div
              className={
                _.includes(this.state.isBlurred, 'companyName') &&
                !this.props.AccountBillingStore.newSubscription
                  .companyNameValid
                  ? classNames('bp3-form-group bp3-intent-danger')
                  : classNames('bp3-form-group')
              }
            >
              <div className="bp3-form-content">
                <div
                  className={
                    _.includes(this.state.isBlurred, 'companyName') &&
                    !this.props.AccountBillingStore.newSubscription
                      .companyNameValid
                      ? classNames(
                          'bp3-input-group bp3-intent-danger'
                        )
                      : classNames('bp3-input-group')
                  }
                >
                  <input
                    autoComplete="off"
                    className="bp3-input bp3-fill"
                    type="text"
                    placeholder={I18n.t('js.company_name')}
                    name="companyName"
                    value={
                      this.props.AccountBillingStore.newSubscription
                        .companyName
                    }
                    onChange={this.handleInputChange.bind(this)}
                    onBlur={this.handleBlurChange.bind(this)}
                    dir="auto"
                  />
                </div>
                {this.renderErrors('companyName')}
              </div>
            </div>
          </Flexbox>
        </Flexbox>
        <Flexbox flexDirection="column">
          <div className="bp3-form-group">
            <div className="bp3-form-content">
              <div className="bp3-input-group">
                <input
                  autoComplete="off"
                  className="bp3-input bp3-fill"
                  type="text"
                  placeholder={I18n.t('js.tax_number')}
                  name="taxNumber"
                  value={
                    this.props.AccountBillingStore.newSubscription
                      .taxNumber
                  }
                  onChange={this.handleInputChange.bind(this)}
                  onBlur={this.handleBlurChange.bind(this)}
                  dir="auto"
                />
              </div>
              {this.renderErrors('taxNumber')}
            </div>
          </div>
        </Flexbox>
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="row" flexGrow={0}>
            <AnchorButton
              text={I18n.t('js.add_a_discount_code')}
              icon="percentage"
              onClick={this.toggleDiscountCode.bind(this)}
            />
          </Flexbox>
          <Collapse isOpen={this.state.addingDiscount}>
            <div className="bp3-form-group push-10-t">
              <div className="bp3-form-content">
                <div className="bp3-input-group">
                  <input
                    autoComplete="off"
                    className="bp3-input bp3-fill"
                    type="text"
                    placeholder={I18n.t('js.discount_code')}
                    name="coupon"
                    value={
                      this.props.AccountBillingStore.newSubscription
                        .coupon
                    }
                    onChange={this.handleInputChange.bind(this)}
                    onBlur={this.handleBlurChange.bind(this)}
                    dir="auto"
                  />
                </div>
                {this.renderErrors('coupon')}
              </div>
            </div>
          </Collapse>
        </Flexbox>
      </Flexbox>
    );
  }
}
