import React from 'react';
import { observable } from 'mobx';
import Flexbox from 'flexbox-react';
import CommentStore from '../../stores/CommentStore';
import CurrentUserStore from '../../stores/CurrentUserStore';
import ToastStore from '../../stores/ToastStore';
import RichTextEditor from '../shared/RichTextEditor';

export default class EditComment extends React.Component {
  @observable
  isLoading = false;
  @observable
  oldBody = '';

  componentDidMount() {
    this.oldBody = this.props.comment.body;
  }

  toggleLoading = () => {
    this.isLoading = !this.isLoading;
  };

  handleCancel() {
    this.props.comment.body = this.oldBody;
    this.props.handleCancel();
  }

  handleInputChange(content) {
    this.props.comment.body = content;
  }

  handleSubmit() {
    this.toggleLoading();
    const commentObj = {
      id: this.props.comment.id,
      user_id: CurrentUserStore.currentUser.id,
      body: this.props.comment.body,
      commentable_id: this.props.commentableId,
      commentable_type: this.props.commentableType,
    };
    CommentStore.changeComment(commentObj)
      .then(response => {
        CommentStore.addComment(response.data).then(comment => {
          this.props.afterChange(comment);
          ToastStore.showToast(
            I18n.t('js.your_comment_has_been_updated'),
            'success'
          );
          this.toggleLoading();
        });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }

  renderInput() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        <RichTextEditor
          content={this.props.comment.body}
          rows={4}
          handleChange={content => this.handleInputChange(content)}
          handleSubmit={this.handleSubmit.bind(this)}
          handleCancel={this.handleCancel.bind(this)}
          cancelResetsContent={false}
          placeholder={I18n.t('js.start_typing')}
          disabled={false}
          inputValid={true}
          showSubmissionButtons={true}
          submitIcon="comment"
          submitText={I18n.t('js.edit_comment')}
          submitLoading={this.isLoading}
          cancelIcon="trash"
          cancelText={I18n.t('js.cancel')}
          placeholderDisabled={true}
        />
      </Flexbox>
    );
  }

  render() {
    return (
      <Flexbox flexGrow={1} flexDirection="column">
        {this.renderInput()}
      </Flexbox>
    );
  }
}
