// TODO $pt-elevation-shadow-0: 0 0 0 1px $pt-divider-black,
// TODO                         0 0 0 rgba($black, 0),
// TODO                         0 0 0 rgba($black, 0) !default;
// TODO $pt-elevation-shadow-1: border-shadow($pt-border-shadow-opacity),
// TODO                         0 0 0 rgba($black, 0),
// TODO                         0 1px 1px rgba($black, $pt-drop-shadow-opacity) !default;
// TODO $pt-elevation-shadow-2: border-shadow($pt-border-shadow-opacity),
// TODO                         0 1px 1px rgba($black, $pt-drop-shadow-opacity),
// TODO                         0 2px 6px rgba($black, $pt-drop-shadow-opacity) !default;
// TODO $pt-elevation-shadow-3: border-shadow($pt-border-shadow-opacity),
// TODO                         0 2px 4px rgba($black, $pt-drop-shadow-opacity),
// TODO                         0 8px 24px rgba($black, $pt-drop-shadow-opacity) !default;
// TODO $pt-elevation-shadow-4: border-shadow($pt-border-shadow-opacity),
// TODO                         0 4px 8px rgba($black, $pt-drop-shadow-opacity),
// TODO                         0 18px 46px 6px rgba($black, $pt-drop-shadow-opacity) !default;
// TODO
// TODO $pt-dark-elevation-shadow-0: 0 0 0 1px $pt-dark-divider-black,
// TODO                              0 0 0 rgba($black, 0),
// TODO                              0 0 0 rgba($black, 0) !default;
// TODO $pt-dark-elevation-shadow-1: border-shadow($pt-dark-border-shadow-opacity),
// TODO                              0 0 0 rgba($black, 0),
// TODO                              0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity) !default;
// TODO $pt-dark-elevation-shadow-2: border-shadow($pt-dark-border-shadow-opacity),
// TODO                              0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity),
// TODO                              0 2px 6px rgba($black, $pt-dark-drop-shadow-opacity) !default;
// TODO $pt-dark-elevation-shadow-3: border-shadow($pt-dark-border-shadow-opacity),
// TODO                              0 2px 4px rgba($black, $pt-dark-drop-shadow-opacity),
// TODO                              0 8px 24px rgba($black, $pt-dark-drop-shadow-opacity) !default;
// TODO                              0 18px 46px 6px rgba($black, $pt-dark-drop-shadow-opacity) !default;

const borderShadowOpacity = 0.1;
const dropShadowOpacity = 0.2;

// all shadow lists must be the same length to avoid flicker in transitions.

export default {
  borderShadowOpacity,
  dropShadowOpacity,
  // eslint-disable-next-line no-magic-numbers
  darkBorderShadowOpacity: borderShadowOpacity * 2,
  // eslint-disable-next-line no-magic-numbers
  darkDropShadowOpacity: dropShadowOpacity * 2,
  elevationShadow0: '',
};
