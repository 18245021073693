import { action, computed, extendObservable, observable } from 'mobx';
import CurrentUserStore from './CurrentUserStore';
import ItemActions from '../actions/ItemActions';
import ToastStore from './ToastStore';
import AppStore from './AppStore';
import Item from '../models/ItemModel';
import User from '../models/UserModel';
import Team from '../models/TeamModel';
import StatusFlag from '../models/StatusFlagModel';
import Participant from '../models/ParticipantModel';
import ItemParticipant from '../models/ItemParticipantModel';
import ItemValue from '../models/ItemValueModel';
import State from '../models/StateModel';
import Section from '../models/SectionModel';
import ElementMappingActions from '../actions/ElementMappingActions';
import NetworkGraph from '../models/NetworkGraphModel';
import Utilities from '../utils/Utilities';
import ActionCable from 'actioncable';
import Cookies from 'js-cookie';
import _ from 'lodash';

class ItemDrawerStore {
  @observable isShowingParticipants = true;
  @observable item = new Item();
  @observable newItem = new Item();
  @observable activeItem = new Item();
  @observable itemParticipantGraph = new NetworkGraph();
  @observable sections = [];
  @observable exposedSections = [];
  @observable readOnlySections = [];
  @observable states = [];
  @observable participants = [];
  @observable statusFlags = [];
  @observable itemParticipants = [];
  @observable formValuesToCreate = [];
  @observable formValuesToChange = [];
  @observable formValuesToDelete = [];
  @observable itemParticipantsToChange = [];
  @observable submissionAttempted = false;
  @observable itemErrors = [];
  @observable countDown = 10;
  @observable isSaving = false;
  @observable isLoading = false;
  @observable isCountingDown = false;
  @observable webformFullName = '';
  @observable webformEmail = '';

  @action
  toggleBool(value) {
    this[`${value}`] = !this[`${value}`];
  }

  @action
  setActiveItem(id) {
    this.resetStartingAttributes();
    this.isLoading = true;
    ItemActions.fetchItem(id)
      .then(response => {
        this.addItem(response.data);
        this.isLoading = false;
      })
      .catch(err => {
        console.log(err);
        this.isLoading = false;
      });
  }

  @computed
  get hasChanges() {
    return this.formValuesToCreate.length > 0 || this.formValuesToChange.length > 0 || this.formValuesToDelete.length > 0;
  }

  @computed
  get itemHasError() {
    return this.itemErrors.length > 0;
  }

  @computed
  get userIsOwner() {
    if (CurrentUserStore.currentUser.id === this.item.userId || this.item.isWebform) {
      return true;
    }
    if (AppStore.activeApp.userCanEdit) {
      return true;
    }
    return false;
  }

  @computed
  get currentState() {
    return _.find(this.states, state => state.id === this.item.stateId);
  }

  @computed
  get currentFullState() {
    return _.find(this.fullStates, o => o.isCurrent);
  }

  @computed
  get nextState() {
    if (this.currentState.id === _.last(this.states).id) {
      return this.currentState;
    }
    return this.states[this.states.indexOf(this.currentState) + 1];
  }

  @computed
  get currentStateIsLast() {
    return this.currentState.id === this.nextState.id;
  }

  @computed
  get nextFullState() {
    return _.find(this.fullStates, o => o.id === this.nextState.id);
  }

  @computed
  get nextStateRequiresItemParticipant() {
    return !this.nextFullState.itemParticipant.userId.length > 0;
  }

  @computed
  get activeStateNumber() {
    return this.states.indexOf(this.currentState) + 1;
  }

  @computed
  get totalStateNumber() {
    return this.states.length;
  }

  @computed
  get currentStateProgress() {
    return this.activeStateNumber / this.totalStateNumber;
  }

  @computed
  get itemHasCalculation() {
    const sectionsWithCalculations = _.filter(this.sections, {
      elements: [{ elementType: 'calculation' }],
    });
    return sectionsWithCalculations.length > 0;
  }

  @computed
  get hasUnsetParticipants() {
    let result = false;
    _.map(this.fullStates, state => {
      if (state.itemParticipant) {
        if (state.itemParticipant.userId === '') {
          result = true;
        }
      }
    });
    return result;
  }

  @computed
  get visibleSections() {
    let visibleSections = [];
    _.map(this.sections, section => {
      let sectionHasData = false;
      section.elements.map(element => {
        if (element.formValues.length > 0 || element.isDisplayElement) {
          sectionHasData = true;
        }
      });
      if (sectionHasData) {
        visibleSections.push(section);
      } else {
        if (!_.find(this.readOnlySections, o => o.section_id == section.id)) {
          visibleSections.push(section);
        }
      }
    });
    return visibleSections;
  }

  @computed
  get fullStates() {
    let fullStates = [];
    _.map(this.states, state => {
      const newState = _.clone(state);
      if (state.id === this.currentState.id) {
        newState.isCurrent = true;
      } else {
        newState.isCurrent = false;
      }
      const findParticipant = _.find(this.participants, o => o.id === state.participantId);
      if (findParticipant) {
        newState.participant = findParticipant;
        const findItemParticipant = _.find(this.itemParticipants, o => o.participantId === findParticipant.id);
        if (findItemParticipant) {
          newState.itemParticipant = findItemParticipant;
        }
      }
      fullStates = _.unionBy([newState], fullStates, 'id');
    });
    fullStates = _.orderBy(fullStates, ['rowOrder'], ['asc']);
    return fullStates;
  }

  @action
  updateItem(data, attribute) {
    this.newItem[attribute] = data;
  }

  @action
  toggleSaving() {
    this.isSaving = !this.isSaving;
  }

  @action
  setNextItemParticipant(userId) {
    const nextItemParticipantId = this.nextFullState.itemParticipant.id;
    const nextItemParticipant = _.clone(_.find(this.itemParticipants, o => o.id === nextItemParticipantId));
    nextItemParticipant.userId = userId;
    this.itemParticipantsToChange = _.unionBy([nextItemParticipant], this.itemParticipantsToChange, 'id');
  }

  @action
  updateActiveItem(data, attribute) {
    this.activeItem[attribute] = data;
  }

  @action
  addItem(data) {
    const newItem = new Item(
      data.id,
      data.app_id,
      data.state_id,
      data.user_id,
      data.status_flag_id,
      data.name,
      data.item_type,
      data.item_icon,
      data.item_color,
      data.item_layout,
      data.image_file_name,
      data.comment_count,
      data.is_done_for_current_user,
      data.is_webform,
      data.import_id,
      data.webform_user_email,
      data.webform_user_full_name,
      data.webform_user_image_file_name,
      data.dataview,
      data.simple_dataview,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );

    const itemUser = new User(
      data.user.id,
      data.user.email,
      data.user.first_name,
      data.user.last_name,
      data.user.full_name,
      data.user.account_id,
      data.user.human_friendly_id,
      data.user.is_current_user,
      data.user.is_account_owner,
      data.user.image_file_name,
      data.user.background_image_file_name,
      data.user.last_sign_in_at,
      data.user.locale,
      data.user.theme,
      data.user.created_at,
      data.user.updated_at,
      data.user.deleted_at,
      data.user.created_by,
      data.user.updated_by,
      data.user.deleted_by
    );
    newItem.user = itemUser;

    const statusFlag = new StatusFlag(
      data.status_flag.id,
      data.status_flag.app_id,
      data.status_flag.name,
      data.status_flag.color,
      data.is_archived,
      data.status_flag.created_at,
      data.status_flag.created_by,
      data.status_flag.deleted_at,
      data.status_flag.deleted_by,
      data.status_flag.updated_at,
      data.status_flag.updated_by
    );
    newItem.statusFlag = statusFlag;

    this.item = newItem;
    this.newItem = _.clone(newItem);
    this.activeItem = _.cloneDeep(newItem);

    if (data.app) {
      _.map(data.app.states, state => {
        this.addState(state);
      });
      _.map(data.app.participants, participant => {
        this.addParticipant(participant);
      });
    }
    _.map(data.item_participants, itemParticipant => {
      this.addItemParticipant(itemParticipant);
    });
    _.map(data.sections, section => {
      this.addSection(section);
    });
    _.map(data.elements, element => {
      this.addElement(element);
    });
    _.map(data.item_values, itemValue => {
      this.addItemValue(itemValue);
    });
  }

  @action
  addError(errorObj) {
    const newItemErrors = _.filter(this.itemErrors, error => error.elementId !== errorObj.elementId);
    newItemErrors.push(errorObj);
    this.itemErrors = newItemErrors;
  }

  @action
  clearErrorsForElement(elementId) {
    this.itemErrors = _.filter(this.itemErrors, o => o.elementId !== elementId);
  }

  @action
  addFormValueToChange(formValueObj) {
    this.formValuesToChange = _.unionBy([formValueObj], this.formValuesToChange, 'id');
  }

  @action
  addFormValueToCreate(formValueObj) {
    this.formValuesToCreate = _.unionBy([formValueObj], this.formValuesToCreate, 'id');
  }

  @action
  addFormValueToDelete(formValueObj) {
    this.formValuesToDelete = _.unionBy([formValueObj], this.formValuesToDelete, 'id');
  }

  @action
  removeFormValueToChange(id) {
    this.formValuesToChange = _.filter(this.formValuesToChange, o => o.id !== id);
  }

  @action
  removeFormValueToCreate(id) {
    this.formValuesToCreate = _.filter(this.formValuesToCreate, o => o.id !== id);
  }

  @action
  removeFormValueToDelete(id) {
    this.formValuesToDelete = _.filter(this.formValuesToDelete, o => o.id !== id);
  }

  @action
  addParticipant(data) {
    const newParticipant = new Participant(
      data.id,
      data.app_id,
      data.is_owner,
      data.name,
      data.visibility_level,
      data.relationship_id,
      data.user_id,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.participants = _.unionBy([newParticipant], this.participants, 'id');
  }

  @action
  addItemParticipant(data) {
    const newItemParticipant = new ItemParticipant(
      data.id,
      data.item_id,
      data.participant_id,
      data.user_id,
      data.is_done,
      data.email,
      data.full_name,
      data.gravatar_url,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    if (data.user) {
      const user = new User(
        data.user.id,
        data.user.email,
        data.user.first_name,
        data.user.last_name,
        data.user.full_name,
        data.user.account_id,
        data.user.human_friendly_id,
        data.user.is_current_user,
        data.user.is_account_owner,
        data.user.image_file_name,
        data.user.background_image_file_name,
        data.user.last_sign_in_at,
        data.user.locale,
        data.user.theme,
        data.user.created_at,
        data.user.updated_at,
        data.user.deleted_at,
        data.user.created_by,
        data.user.updated_by,
        data.user.deleted_by
      );
      newItemParticipant.user = user;
    }
    this.itemParticipants = _.unionBy([newItemParticipant], this.itemParticipants, 'id');
    if (!this.hasUnsetParticipants) {
      this.itemErrors = _.filter(this.itemErrors, o => o.elementId !== 'item-participant-chart');
    }
  }

  @action
  addItemValue(data) {
    _.map(this.sections, section => {
      _.map(section.elements, element => {
        if (element.id === data.element_id) {
          const newItemValue = new ItemValue(
            data.id,
            data.item_id,
            data.element_id,
            data.item_value_type,
            data.string_value,
            data.number_value,
            data.decimal_value,
            data.boolean_value,
            data.date_value,
            data.image_file_name,
            data.attachment_file_name,
            data.element_select_option_id,
            data.element_object_select_item_id,
            data.element_object_select_user_id,
            data.element_object_select_team_id,
            data.element_grid_column_id,
            data.element_grid_row_id,
            data.element_multiline_input_id,
            data.row_order,
            data.created_at,
            data.created_by,
            data.deleted_at,
            data.deleted_by,
            data.updated_at,
            data.updated_by
          );

          if (data.reference_user) {
            newItemValue.referenceUser = new User(
              data.reference_user.id,
              data.reference_user.email,
              data.reference_user.first_name,
              data.reference_user.last_name,
              data.reference_user.full_name,
              data.reference_user.account_id,
              data.reference_user.human_friendly_id,
              data.reference_user.is_current_user,
              data.reference_user.is_account_owner,
              data.reference_user.image_file_name,
              data.reference_user.background_image_file_name,
              data.reference_user.last_sign_in_at,
              data.reference_user.locale,
              data.reference_user.theme,
              data.reference_user.created_at,
              data.reference_user.updated_at,
              data.reference_user.deleted_at,
              data.reference_user.created_by,
              data.reference_user.updated_by,
              data.reference_user.deleted_by
            );
          }
          if (data.reference_team) {
            newItemValue.referenceTeam = new Team(
              data.reference_team.id,
              data.reference_team.account_id,
              data.reference_team.is_default_account,
              data.reference_team.name,
              data.reference_team.description,
              data.reference_team.image_file_name,
              data.reference_team.background_image_file_name,
              data.reference_team.private,
              data.reference_team.is_current_user_team_owner,
              data.reference_team.is_current_user_team_member,
              data.reference_team.row_order,
              data.reference_team.user_count,
              data.reference_team.comment_count,
              data.reference_team.import_id,
              data.reference_team.created_at,
              data.reference_team.created_by,
              data.reference_team.deleted_at,
              data.reference_team.deleted_by,
              data.reference_team.updated_at,
              data.reference_team.updated_by
            );
          }
          if (data.reference_item) {
            newItemValue.referenceItem = new Item(
              data.reference_item.id,
              data.reference_item.app_id,
              data.reference_item.state_id,
              data.reference_item.user_id,
              data.reference_item.status_flag_id,
              data.reference_item.name,
              data.reference_item.item_type,
              data.reference_item.item_icon,
              data.reference_item.item_color,
              data.reference_item.item_layout,
              data.reference_item.image_file_name,
              data.reference_item.comment_count,
              data.reference_item.is_done_for_current_user,
              data.reference_item.is_webform,
              data.reference_item.import_id,
              data.reference_item.webform_user_email,
              data.reference_item.webform_user_full_name,
              data.reference_item.webform_user_image_file_name,
              data.reference_item.dataview,
              data.reference_item.simple_dataview,
              data.reference_item.created_at,
              data.reference_item.created_by,
              data.reference_item.deleted_at,
              data.reference_item.deleted_by,
              data.reference_item.updated_at,
              data.reference_item.updated_by
            );
          }
          if (element.elementType === 'calculation') {
            element.formValues = [newItemValue];
          } else {
            let newFormValues = _.unionBy([newItemValue], element.formValues, 'id');
            if (element.repeatable) {
              newFormValues = _.orderBy(newFormValues, ['rowOrder'], ['asc']);
            } else {
              newFormValues = _.orderBy(newFormValues, ['updatedAt'], ['desc']);
            }
            element.formValues = newFormValues;
          }
        }
      });
    });
  }

  @action
  removeItemValue(formValueId) {
    _.map(this.sections, section => {
      _.map(section.elements, element => {
        element.formValues = _.filter(element.formValues, o => o.id !== formValueId);
      });
    });
  }

  @action
  addStatusFlag(data) {
    const newStatusFlag = new StatusFlag(
      data.id,
      data.app_id,
      data.name,
      data.color,
      data.is_archived,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.deleted_at,
      data.deleted_by
    );
    this.statusFlags = _.unionBy([newStatusFlag], this.statusFlags, 'id');
  }

  @action
  addState(data) {
    const newState = new State(
      data.id,
      data.app_id,
      data.participant_id,
      data.status_flag_id,
      data.name,
      data.all_visible,
      data.all_editable,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    this.states = _.unionBy([newState], this.states, 'id');
    this.sortStates();
  }

  @action
  addSection(data) {
    const newSection = new Section(
      data.id,
      data.section_type,
      data.app_id,
      data.profile_template_id,
      data.title,
      data.private_view,
      data.protected_view,
      data.account_owner_only_view,
      data.default_collapsed,
      data.optional,
      data.row_order,
      data.created_at,
      data.created_by,
      data.deleted_at,
      data.deleted_by,
      data.updated_at,
      data.updated_by
    );
    extendObservable(newSection, { elements: [] });
    this.sections = _.unionBy([newSection], this.sections, 'id');
    this.addStateSections(data.state_sections);
    this.sortSections();
  }

  @action
  addStateSections(state_sections) {
    _.map(state_sections, state_section => {
      if (!state_section.editable && state_section.state_id == this.currentState.id) {
        this.readOnlySections = _.unionBy([state_section], this.readOnlySections, 'id');
      }
    });
  }

  @action
  addElement(data) {
    const findSection = _.find(this.sections, o => o.id === data.section_id);
    const newElements = ElementMappingActions.mapElements([data]);
    _.map(newElements, element => {
      if (!this.userIsOwner) {
        element.isEditable = false;
      }
    });
    _.map(newElements, element => {
      extendObservable(element, { formValues: [] });
    });
    if (findSection) {
      findSection.elements = _.unionBy(newElements, findSection.elements, 'id');
      findSection.elements = _.orderBy(findSection.elements, ['rowOrder'], ['asc']);
    }
  }

  @action
  sortSections() {
    this.sections = _.orderBy(this.sections, ['rowOrder'], ['asc']);
  }

  @action
  sortStates() {
    this.states = _.orderBy(this.states, ['rowOrder'], ['asc']);
  }

  @action
  resetStartingAttributes() {
    this.activeTab = 'details';
    this.isShowingParticipants = true;
    this.item = new Item();
    this.newItem = new Item();
    this.activeItem = new Item();
    this.itemParticipantGraph = new NetworkGraph(
      true,
      // lazy fix for legacy code
      // eslint-disable-next-line no-magic-numbers
      250,
      // eslint-disable-next-line no-magic-numbers
      300,
      'RL',
      'hubsize',
      true
    );
    this.sections = [];
    this.exposedSections = [];
    this.readOnlySections = [];
    this.states = [];
    this.participants = [];
    this.statusFlags = [];
    this.itemParticipants = [];
    this.formValuesToCreate = [];
    this.formValuesToChange = [];
    this.formValuesToDelete = [];
    this.itemParticipantsToChange = [];
    this.submissionAttempted = false;
    this.itemErrors = [];
    this.countDown = 10;
    this.isLoading = false;
    this.isSaving = false;
    this.isCountingDown = false;
    this.webformFullName = '';
    this.webformEmail = '';
  }
}

const store = new ItemDrawerStore();
export default store;
