/* Legacy code - ignore this errors */
/* eslint class-methods-use-this: 0 */
import React from 'react';
import Flexbox from 'flexbox-react';
import { Spinner, Tab, Tabs, Intent, Tag } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

@inject('AppStore', 'TemplateStore', 'TemplateActions')
@observer
export default class TemplateTabs extends React.Component {
  renderTabs() {
    return (
      <Flexbox>
        <Tabs
          id="templateTabs"
          selectedTabId={this.props.TemplateStore.activeSubsection}
          vertical={false}
          onChange={tab => this.props.TemplateStore.setActiveSubsection(tab)}
        >
          <Tab id="template" title={I18n.t('js.edit_template')} />
          <Tab id="state" title={I18n.t('js.configure_state')} />
          <Tab id="rules" title={I18n.t('js.set_rules')} />
          <Tabs.Expander />
        </Tabs>
      </Flexbox>
    );
  }

  render() {
    return (
      <nav className="bp3-navbar bp3-navbar-bordered">
        <Flexbox className="bp3-navbar-group bp3-align-left" flexGrow={1} paddingLeft="30px">
          {this.renderTabs()}
        </Flexbox>
      </nav>
    );
  }
}
